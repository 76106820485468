enum LocationAccessType {
  ELEVATOR = 'ELEVATOR',
  LOADING_DOCK = 'LOADING_DOCK',
  OTHER = 'OTHER',
}

const LocationAccessTypeName = {
  [LocationAccessType.ELEVATOR]: 'Elevator',
  [LocationAccessType.LOADING_DOCK]: 'Loading Dock',
  [LocationAccessType.OTHER]: 'Other',
};

export { LocationAccessType, LocationAccessTypeName };
