import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  DistanceUnit,
  DistanceUnitLabel,
  ElromcoCircularProgress,
  HeaderSmallText,
  MoveUnit,
  MoveUnitLabel,
  PhoneInput,
  Radio,
  RadioGroup,
  Select,
  TextInput,
  addressAPI,
  emailYUP,
  useCurrentDistanceUnits,
  useCurrentMoveUnits,
  useCurrentUser,
  useUpdateEffect,
  zipCodeSyncYUP,
} from '@elromcoinc/react-shared';
import { Box, Divider, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { string } from 'yup';

import companyAPI from 'admin/api/SettingsAPI';
import { setCompanyInfo as updateCompanyInfoInStore } from 'admin/autodux/CompanyInfoAutodux';
import settingsAPI from 'admin/components/Settings/api/settingsAPI';
import SettingName from 'admin/constants/SettingName';
import { trimValue } from 'admin/utils/trimValue';

import Form from './FormWrapper';

const DividerWithMargin = styled(Divider)`
  && {
    margin-bottom: 16px;
  }
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: '100%',
  },
  formLabel: {
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
  },
  radioGroup: {
    flexDirection: 'row',
  },
  smallInputBox: {
    maxWidth: 120,
  },
}));

const stateAbbreviationRegex =
  /(^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])$)|(^(AB|BC|MB|N[BLTSU]|ON|PE|QC|SK|YT)*$)/g;

const supportedCountriesNames = {
  USA: 'USA',
  Canada: 'Canada',
};
const supportedCountriesOptions = Object.keys(supportedCountriesNames).map((key) => [
  key,
  supportedCountriesNames[key],
]);

const addressFields = ['country', 'postalCode', 'city', 'state', 'street1', 'street2'];
const moveTypeFields = ['gci.acceptsResidentialOrders', 'gci.acceptsCommercialOrders', 'gci.acceptsMilitaryOrders'];
const CONVERSION_RATE_SETTING = 'gci.cuft_to_pounds';
const zipCodeSchema = zipCodeSyncYUP();
const emailSchema = emailYUP();

const GenericBasicSection = () => {
  const classes = useStyles();
  const isSuperUser = useCurrentUser()?.id < 1;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { currentMoveUnit, setMoveUnits } = useCurrentMoveUnits();
  const { currentDistanceUnit, setDistanceUnits } = useCurrentDistanceUnits();
  const [inFlight, setInFlight] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);

  const [settings, setSettings] = useState(null);
  const [initialSettings, setInitialSettings] = useState(null);
  const [customerHostError, setCustomerHostError] = useState(null);
  const [inEdit, setInEdit] = useState(null);
  const [useSameAddress, setUseSameAddress] = useState(false);
  const [error, setError] = useState({});

  const fetchSettings = () => {
    setInFlight(true);

    Promise.all([
      settingsAPI.getDataFromQuery({ queries: [SettingName.GENERAL_COMPANY_INFO, SettingName.GOOGLE_API_KEY] }),
      companyAPI.getCompanyInfo(),
    ])
      .then((data) => {
        setInFlight(false);
        setSettings(data[0]);
        setInitialSettings(data[0]);
        setCompanyInfo(data[1]);
        setUseSameAddress(!data[1].parkingAddress);
      })
      .catch(() => {
        setInFlight(false);
      });
  };

  const updateSettings = (data) => {
    setInFlight(true);
    settingsAPI
      .updateSettingNode(data.name, data.value)
      .then(() => {
        setInFlight(false);
        setInitialSettings(settings);
        setInEdit(null);
      })
      .catch(() => {
        setInFlight(false);
      });
  };

  const updateCompanyInfo = (data) => {
    setInFlight(true);
    companyAPI
      .updateCompanyInfo(data)
      .then(() => {
        setInFlight(false);
        setInEdit(null);
        setCompanyInfo(data);
      })
      .catch((err) => {
        let tempError = {};

        err.errors.forEach((err) => {
          // don't have access to error status code here, therefore, if there is no fieldPath in the error response, don't update error
          if (!err.fieldPath) {
            return;
          }
          const currentName = err.fieldPath;
          const [nodeName, name] = currentName.split('.');
          tempError[nodeName] = {
            ...error[nodeName],
            [name]: `Invalid value "${err.offendingValue}"`,
          };
          tempError[currentName] = `Invalid value "${err.offendingValue}"`;
        });

        setError({ ...error, ...tempError });

        setInFlight(false);
      });
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useUpdateEffect(() => {
    if (useSameAddress) {
      // if parking address is the same as the company address, parkingAddress can be set to null
      let updatedCompanyInfo = { ...companyInfo, parkingAddress: null };
      saveCompanyField(updatedCompanyInfo, useSameAddress);
    } else {
      saveCompanyField(companyInfo, useSameAddress);
    }
  }, [useSameAddress]);

  const save = () => {
    if (!inEdit) {
      return;
    }

    const isEqual = JSON.stringify(initialSettings[inEdit.name]) === JSON.stringify(inEdit.value);

    if (!isEqual) {
      updateSettings({
        name: inEdit.name,
        value: typeof inEdit.value === 'boolean' ? inEdit.value?.toString() : inEdit.value,
      });
      setInEdit(null);
    }
  };

  function validateCompanyFields(companyInfoData = companyInfo, isSameAddress = useSameAddress) {
    let returnValue = true;

    const isValidEmail = emailSchema.isValidSync(companyInfoData.basicInfo.email);
    returnValue = returnValue && isValidEmail;

    const validateBasicInfo = () => {
      let errorSet = {};

      if ((companyInfoData?.basicInfo?.companyName?.trim() ?? '') === '') {
        errorSet = { ...errorSet, 'basicInfo.companyName': 'Company name must not be blank' };
        returnValue = false;
      } else if (companyInfoData.basicInfo.companyName.trim().length > 256) {
        errorSet = { ...errorSet, 'basicInfo.companyName': 'Company name must not be longer then 256 characters' };
        returnValue = false;
      }

      // companyInfoData.basicInfo.legalName is not returned by the API if this field was never set by the user
      if (
        typeof companyInfoData?.basicInfo?.legalName === 'string' &&
        companyInfoData.basicInfo.legalName.trim().length > 128
      ) {
        errorSet = { ...errorSet, 'basicInfo.legalName': 'Company name must not be longer then 128 characters' };
        returnValue = false;
      }

      if ((companyInfoData?.basicInfo?.firstName?.trim() ?? '') === '') {
        errorSet = { ...errorSet, 'basicInfo.firstName': 'First name must not be blank' };
        returnValue = false;
      } else if (companyInfoData.basicInfo.firstName.trim().length > 32) {
        errorSet = { ...errorSet, 'basicInfo.firstName': 'First name must not be longer then 32 characters' };
        returnValue = false;
      }

      if ((companyInfoData?.basicInfo?.lastName?.trim() ?? '') === '') {
        errorSet = { ...errorSet, 'basicInfo.lastName': 'Last name must not be blank' };
        returnValue = false;
      } else if (companyInfoData.basicInfo.lastName.trim().length > 32) {
        errorSet = { ...errorSet, 'basicInfo.lastName': 'Last name must not be longer then 32 characters' };
        returnValue = false;
      }

      if ((companyInfoData?.basicInfo?.phone?.trim() ?? '') === '') {
        errorSet = { ...errorSet, 'basicInfo.phone': 'Phone must not be blank' };
        returnValue = false;
      } else if (companyInfoData.basicInfo.phone.trim().length < 10) {
        errorSet = { ...errorSet, 'basicInfo.phone': 'Phone number must not be less than 10 digits' };
        returnValue = false;
      }

      if ((companyInfoData?.basicInfo?.url?.trim() ?? '') === '') {
        errorSet = { ...errorSet, 'basicInfo.url': 'Site url must not be blank' };
        returnValue = false;
      } else if (!string().url().isValidSync(companyInfoData?.basicInfo?.url?.trim())) {
        errorSet = { ...errorSet, 'basicInfo.url': 'Site url must be valid DNS name, ex: https://example.com' };
      } else if (companyInfoData.basicInfo.url.trim().length > 256) {
        errorSet = { ...errorSet, 'basicInfo.url': 'Site url name must not be longer then 256 characters' };
        returnValue = false;
      }

      return errorSet;
    };

    let basicInfoErrors = validateBasicInfo();

    const tempErrorSet = ['parkingAddress', 'address'].reduce((accumulator, addressSetting) => {
      if (isSameAddress && addressSetting === 'parkingAddress') {
        return accumulator;
      }

      return {
        ...accumulator,
        ...addressFields.reduce((acc, field) => {
          const currentSetting = companyInfoData[addressSetting] || {};

          let errMsg;
          let isValid;
          const value = currentSetting[field] ? currentSetting[field].trim() : currentSetting[field];

          if (field === 'country') {
            isValid = !!value;
            errMsg = 'Please select country';
          } else if (field === 'postalCode') {
            isValid = !!value && zipCodeSchema.isValidSync(value); // if postalCode is undefined or empty, mark it as invalid
            errMsg = supportedCountriesNames[currentSetting.country]
              ? `Please enter valid ${supportedCountriesNames[currentSetting.country]} zip code`
              : `Please enter valid ${supportedCountriesNames.USA} or ${supportedCountriesNames.Canada} zip code`;
          } else if (field === 'city') {
            isValid = !!value;
            errMsg = 'City is a required field';

            if (!!value && value.length >= 64) {
              isValid = false;
              errMsg = 'City field must be less than 64 characters long';
            }
          } else if (field === 'state') {
            if (!value?.match(stateAbbreviationRegex)) {
              isValid = false;
              errMsg = 'Please enter valid abbreviation for state';
            } else {
              isValid = !!value && value.length === 2;
              errMsg = 'State is a required field and must use 2 character state abbreviation';
            }
          } else if (field === 'street1') {
            isValid = !!value;
            errMsg = 'Street is a required field';

            if (!!value && value.length >= 64) {
              isValid = false;
              errMsg = 'Street field must be less than 64 characters long';
            }
          } else if (field === 'street2') {
            isValid = true;
            errMsg = '';

            if (!!value && value.length >= 64) {
              isValid = false;
              errMsg = 'Street field must be less than 64 characters long';
            }
          } else {
            isValid = !!value;
            errMsg = 'Please enter valid info';
          }

          returnValue = returnValue && isValid;

          return { ...acc, [`${addressSetting}.${field}`]: !isValid ? errMsg : null };
        }, {}),
      };
    }, {});

    setError({ ...tempErrorSet, ...basicInfoErrors });

    if (!isValidEmail) {
      const currentName = 'basicInfo.email';
      const [nodeName, name] = currentName.split('.');

      setError({
        ...error,
        ...tempErrorSet,
        [nodeName]: {
          ...error[nodeName],
          [name]: 'Please enter valid email',
        },
        [currentName]: 'Please enter valid email',
      });
    }

    return returnValue;
  }

  const saveCompanyField = (companyInfoData = companyInfo, isSameAddress = useSameAddress) => {
    if (!validateCompanyFields(companyInfoData, isSameAddress)) {
      // Let the user know that the form hasn't been saved because there are errors in either Company Address or Parking Address fields
      enqueueSnackbar(`Please fix all form errors to save changes`, { variant: 'warning' });
      return;
    }

    updateCompanyInfo(companyInfoData);
    if (inEdit?.name === 'companyName') {
      dispatch(updateCompanyInfoInStore(companyInfoData));
    }
  };

  const handleFieldChange = ({ target: { name, value, checked, type } }) => {
    if (
      !checked &&
      moveTypeFields.some((fieldName) => fieldName === name) &&
      moveTypeFields.every((fieldName) => (fieldName === name ? !checked : !settings[fieldName]))
    )
      return;

    setInEdit({ name, value: type === 'checkbox' ? checked : value });
    setSettings({ ...settings, [name]: type === 'checkbox' ? checked : value });
  };

  function fillOtherAddressFieldsByPostalCode(postalCode, currentName) {
    const [node] = currentName.split('.');
    setInFlight(true);

    addressAPI
      .lookUpAddressesByZipCode(postalCode)
      .then((address) => {
        if (address.length === 0) {
          return Promise.reject();
        }

        return address[0];
      })
      .then((address) => {
        const { city, country, state } = address;
        const tempAddressFieldsObj = { city, state, country };
        const validAddressFields = Object.keys(tempAddressFieldsObj).reduce(
          (acc, key) => (tempAddressFieldsObj[key] ? { ...acc, [key]: tempAddressFieldsObj[key] } : acc),
          {},
        );
        const newCompanyData = {
          ...companyInfo,
          [node]: {
            ...companyInfo[node],
            ...validAddressFields,
            postalCode,
          },
        };

        setError({ ...error, [currentName]: null });
        saveCompanyField(newCompanyData);
        setCompanyInfo(newCompanyData);

        return Promise.resolve();
      })
      .catch(() => {
        const countryCode = companyInfo.address.country;

        setError({
          ...error,
          [currentName]: `Please enter valid ${supportedCountriesNames[countryCode]} zip code`,
        });
      })
      .then(() => setInFlight(false));
  }

  const handleCompanyFieldChange = ({ target: { name: currentName, value: currentValue } }) => {
    let value = currentValue.trimStart(); // don't trim end if the user wants to add a space to add multiple words
    const [nodeName, name] = currentName.split('.');

    // The API throws an error if the state abbreviation is lowercase
    if (name === 'state') {
      value = currentValue.trim().toUpperCase();
    }

    setInEdit({ name, value });
    setCompanyInfo({ ...companyInfo, [nodeName]: { ...companyInfo[nodeName], [name]: value } });

    if (name === 'postalCode' && zipCodeSchema.isValidSync(value)) {
      fillOtherAddressFieldsByPostalCode(value.toString(), currentName);
    }
  };

  function handleUseSameAddress() {
    setUseSameAddress((previousValue) => !previousValue);
  }

  if (!settings || !companyInfo) {
    return <ElromcoCircularProgress />;
  }

  const commonInputProps = {
    onChange: handleCompanyFieldChange,
    onBlur: () => saveCompanyField(companyInfo),
    onFocusOut: () => saveCompanyField(companyInfo),
    fullWidth: true,
    formErrors: error,
  };

  function handleCustomerHostDNS({ target: { name, value } }) {
    if (!value || string().url().isValidSync(value)) {
      setCustomerHostError(null);
      updateSettings({ name, value: value || '' });
    } else {
      setCustomerHostError('Please provide valid DNS.');
    }
  }

  return (
    <Form>
      <div className={classes.formControl}>
        <HeaderSmallText className={classes.formLabel} data-testid="basicInfoTitle">
          Basic Info
        </HeaderSmallText>
        <TextInput
          disabled={inFlight}
          name="basicInfo.companyName"
          label="Company Name"
          objectValue={companyInfo}
          data-testid="basicInfo.companyName"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="basicInfo.legalName"
          label="Company Legal Name"
          objectValue={companyInfo}
          data-testid="basicInfo.legalName"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="basicInfo.email"
          label="Email"
          objectValue={companyInfo}
          data-testid="basicInfo.email"
          {...commonInputProps}
          onChangeMiddleware={trimValue}
        />
        <TextInput
          disabled={inFlight}
          name="basicInfo.firstName"
          label="First Name"
          objectValue={companyInfo}
          data-testid="basicInfo.firstName"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="basicInfo.lastName"
          label="Last Name"
          objectValue={companyInfo}
          data-testid="basicInfo.lastName"
          {...commonInputProps}
        />
        <PhoneInput
          disabled={inFlight}
          name="basicInfo.phone"
          label="Phone"
          objectValue={companyInfo}
          data-testid="basicInfo.phone"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="basicInfo.url"
          label="Site"
          objectValue={companyInfo}
          data-testid="basicInfo.url"
          {...commonInputProps}
        />
      </div>
      <DividerWithMargin />
      <div className={classes.formControl}>
        <HeaderSmallText className={classes.formLabel}>Address</HeaderSmallText>
        <Select
          disabled={inFlight || companyInfo.address.postalCode}
          name="address.country"
          label="Country"
          objectValue={companyInfo}
          options={supportedCountriesOptions}
          data-testid="address.country"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="address.postalCode"
          label="Postal Code"
          objectValue={companyInfo}
          data-testid="address.postalCode"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="address.city"
          label="City"
          objectValue={companyInfo}
          data-testid="address.city"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="address.state"
          label="State"
          objectValue={companyInfo}
          data-testid="address.state"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="address.street1"
          label="Street1"
          objectValue={companyInfo}
          data-testid="address.street1"
          {...commonInputProps}
        />
        <TextInput
          disabled={inFlight}
          name="address.street2"
          label="Street2"
          objectValue={companyInfo}
          data-testid="address.street2"
          {...commonInputProps}
        />
      </div>
      <DividerWithMargin />

      <div className={classes.formControl}>
        <HeaderSmallText className={classes.formLabel}>Parking Address</HeaderSmallText>
        <Checkbox
          color="primary"
          checked={useSameAddress}
          onChange={handleUseSameAddress}
          disabled={inFlight}
          data-testId="useSameAddressForParking"
          label="Use same address for parking"
        />
        {!useSameAddress && (
          <>
            <Select
              disabled={inFlight || useSameAddress}
              name="parkingAddress.country"
              label="Country"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              options={supportedCountriesOptions}
              data-testid="parkingAddress.country"
              {...commonInputProps}
            />
            <TextInput
              disabled={inFlight || useSameAddress}
              name="parkingAddress.postalCode"
              label="Postal Code"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              data-testid="parkingAddress.postalCode"
              {...commonInputProps}
            />
            <TextInput
              disabled={inFlight || useSameAddress}
              name="parkingAddress.city"
              label="City"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              data-testid="parkingAddress.city"
              {...commonInputProps}
            />
            <TextInput
              disabled={inFlight || useSameAddress}
              name="parkingAddress.state"
              label="State"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              data-testid="parkingAddress.state"
              {...commonInputProps}
            />
            <TextInput
              disabled={inFlight || useSameAddress}
              name="parkingAddress.street1"
              label="Street1"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              data-testid="parkingAddress.street1"
              {...commonInputProps}
            />
            <TextInput
              disabled={inFlight || useSameAddress}
              name="parkingAddress.street2"
              label="Street2"
              InputLabelProps={{ shrink: true }}
              objectValue={companyInfo}
              data-testid="parkingAddress.street2"
              {...commonInputProps}
            />
          </>
        )}
      </div>
      <DividerWithMargin />

      <div className={classes.formControl}>
        <HeaderSmallText className={classes.formLabel}>Customer Account Domain</HeaderSmallText>
        <TextInput
          disabled={inFlight}
          fullWidth
          name={SettingName.CUSTOMER_HOST_DNS_NAME}
          value={settings[SettingName.CUSTOMER_HOST_DNS_NAME]}
          onChange={handleFieldChange}
          label="Domain URL"
          placeholder="https://www.customerwebsite.com"
          formError={customerHostError || ''}
          onBlur={handleCustomerHostDNS}
        />
      </div>
      <DividerWithMargin />
      <TextInput
        name="gci.us.dot.license"
        label="US Dot License"
        value={settings['gci.us.dot.license']}
        onChange={handleFieldChange}
        onBlur={save}
        onFocusOut={save}
        fullWidth
        disabled={inFlight}
      />
      <TextInput
        name="gci.mc.license"
        label="MC License"
        value={settings['gci.mc.license']}
        onChange={handleFieldChange}
        onBlur={save}
        onFocusOut={save}
        fullWidth
        disabled={inFlight}
      />
      <TextInput
        name="gci.local.license"
        label="Local License"
        value={settings['gci.local.license']}
        onChange={handleFieldChange}
        onBlur={save}
        onFocusOut={save}
        fullWidth
        disabled={inFlight}
      />
      <DividerWithMargin />
      <Box display="flex" justifyContent="space-between">
        <RadioGroup
          label="Move Unit"
          aria-label="move unit"
          name={SettingName.MOVE_UNIT}
          value={currentMoveUnit}
          onChange={(_, value) => setMoveUnits(+value)}
          direction="row"
        >
          <Radio
            color="primary"
            value={MoveUnit.CUBIC_FEET}
            label={MoveUnitLabel[MoveUnit.CUBIC_FEET]}
            disabled={inFlight}
          />
          <Radio color="primary" value={MoveUnit.POUNDS} label={MoveUnitLabel[MoveUnit.POUNDS]} disabled={inFlight} />
        </RadioGroup>
        <TextInput
          name={CONVERSION_RATE_SETTING}
          label="Conversion Rate"
          value={settings[CONVERSION_RATE_SETTING]}
          type="number"
          onChange={handleFieldChange}
          disabled={inFlight}
          onBlur={save}
          className={classes.smallInputBox}
          inputProps={{ min: 0 }}
        />
      </Box>
      <DividerWithMargin />
      <RadioGroup
        label="Distance Unit"
        aria-label="distance unit"
        name="gci.distanceUnit"
        value={currentDistanceUnit}
        onChange={(_, value) => setDistanceUnits(+value)}
        direction="row"
      >
        <Radio
          color="primary"
          value={DistanceUnit.MILES}
          label={DistanceUnitLabel[DistanceUnit.MILES]}
          disabled={inFlight}
        />
        <Radio
          color="primary"
          value={DistanceUnit.KILOMETERS}
          label={DistanceUnitLabel[DistanceUnit.KILOMETERS]}
          disabled={inFlight}
        />
      </RadioGroup>
      <DividerWithMargin />
      <Box display="flex" flexDirection="column">
        <Checkbox
          name="gci.acceptsResidentialOrders"
          label="Accept Residential Orders"
          checked={settings['gci.acceptsResidentialOrders']}
          onChange={handleFieldChange}
          color="primary"
          onBlur={save}
          onFocusOut={save}
          fullWidth
          disabled={inFlight}
        />
        <Checkbox
          name="gci.acceptsCommercialOrders"
          label="Accept Commercial Orders"
          checked={settings['gci.acceptsCommercialOrders']}
          onChange={handleFieldChange}
          color="primary"
          onBlur={save}
          onFocusOut={save}
          fullWidth
          disabled={inFlight}
        />
        <Checkbox
          name="gci.acceptsMilitaryOrders"
          label="Accept Military Orders"
          checked={settings['gci.acceptsMilitaryOrders']}
          onChange={handleFieldChange}
          color="primary"
          onBlur={save}
          onFocusOut={save}
          fullWidth
          disabled={inFlight}
        />
      </Box>
      {isSuperUser && (
        <>
          <DividerWithMargin />
          <TextInput
            name={SettingName.GOOGLE_API_KEY}
            label="Google API Key"
            value={settings[SettingName.GOOGLE_API_KEY]}
            onChange={handleFieldChange}
            onBlur={save}
            onFocusOut={save}
            fullWidth
            disabled={inFlight}
          />
        </>
      )}
      {inFlight && <ElromcoCircularProgress />}
    </Form>
  );
};

export default GenericBasicSection;
