import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  BodyBigText,
  BodyText,
  CreditCardPaymentProfileDto,
  CurrencyInput,
  Modal,
  OnlinePaymentTransactionDto,
  TextInput,
  cardTypeIcon,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { AMOUNT, NOTES, labels } from '../CreateCharge/Common';

const schema = object({
  [NOTES]: string().nullable().max(1000),
});

interface RefundCreditCardOnlineProps {
  onSave: () => void;
  onCancel: () => void;
  payment: OnlinePaymentTransactionDto;
}

const RefundCreditCardOnline = ({ payment, onCancel, onSave }: RefundCreditCardOnlineProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(true);
  const [isVoidable, setIsVoidable] = useState(false);
  const methods = useForm<{ [NOTES]: string; [AMOUNT]: number }>({
    resolver: yupResolver(schema),
    defaultValues: {
      [AMOUNT]: payment.amount,
    },
  });

  useEffect(() => {
    paymentActionsApi
      .isCreditCardPaymentVoidable(payment.id)
      .catch(() => false)
      .then(setIsVoidable)
      .then(() => setInFlight(false));
  }, [payment.id]);

  const onConfirm = useCallback(() => {
    if (isVoidable) {
      setInFlight(true);
      paymentActionsApi
        .voidCreditCardPayment(payment.id)
        .catch(() => {
          enqueueSnackbar('There was an error processing request to void payment.', { variant: 'error' });
        })
        .then(() => {
          setInFlight(false);
          onSave();
        });
    } else {
      methods.handleSubmit((data) => {
        setInFlight(true);
        paymentActionsApi
          .refundCreditCard({
            paymentId: payment.id,
            amount: data[AMOUNT],
            note: data[NOTES],
          })
          .catch(() => {
            enqueueSnackbar('There was an error processing refund request payment.', { variant: 'error' });
          })
          .then(() => {
            setInFlight(false);
            onSave();
          });
      })();
    }
  }, [payment, isVoidable, onSave]);

  const actions = useMemo(
    () => [
      { label: 'Cancel', onClick: onCancel },
      {
        label: isVoidable ? 'Void' : 'Refund',
        onClick: onConfirm,
        loading: inFlight,
      },
    ],
    [inFlight, onCancel, onConfirm],
  );

  function renderCreditCardInfo() {
    const paymentMethod: CreditCardPaymentProfileDto = payment.paymentMethod as CreditCardPaymentProfileDto;
    return (
      <>
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* @ts-ignore*/}
          <Box mr={1}>{cardTypeIcon[(paymentMethod.cardType || 'Visa').toUpperCase()]}</Box>
          <BodyText>
            <b>{paymentMethod.cardType}</b>
          </BodyText>
          <BodyText>&nbsp;ending in {paymentMethod.lastFourDigits},&nbsp;</BodyText>
          <BodyText>Expires&nbsp;{format(parseISO(paymentMethod.expiration), 'MM/yyyy')}</BodyText>
        </Box>
      </>
    );
  }

  function renderPaymentInfo() {
    return (
      <Grid item xs={12}>
        <Box my={1}>
          {renderCreditCardInfo()}
          <BodyText paragraph>
            {payment.paymentMethod.firstName} {payment.paymentMethod.lastName}
          </BodyText>
          <BodyText>
            <b>Payment Amount:</b> {`$${formatCurrency(payment.amount)}`}
          </BodyText>
        </Box>
      </Grid>
    );
  }

  function renderVoid() {
    if (!isVoidable) return null;
    return (
      <Grid container spacing={2}>
        {renderPaymentInfo()}
        <Grid item xs={12}>
          <Box mb={1} p={1} borderRadius={4} bgcolor="rgb(255 120 63 / 15%)">
            <BodyBigText color="secondary">You can void this transaction, because it was not settled yet.</BodyBigText>
          </Box>
        </Grid>
      </Grid>
    );
  }

  function renderRefund() {
    if (isVoidable) return null;
    return (
      /** @ts-ignore */
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          {renderPaymentInfo()}
          <Grid item xs={12}>
            <CurrencyInput
              /** @ts-ignore */
              label="Refund Amount"
              fullWidth
              name={AMOUNT}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput label={labels[NOTES]} fullWidth name={NOTES} />
          </Grid>
        </Grid>
      </FormProvider>
    );
  }

  return (
    <Modal
      open
      color="grey"
      title="Refund Credit Card Payment"
      onClose={onCancel}
      disabledInProcessing={inFlight}
      actions={actions}
      maxWidth="sm"
    >
      {renderRefund()}
      {renderVoid()}
    </Modal>
  );
};

export { RefundCreditCardOnline };
