import { ServiceType, UI_DATE_FORMAT, UI_LOCALIZED_TIME_FORMAT } from '@elromcoinc/react-shared';
import { format } from 'date-fns';
import { Record } from 'immutable';

import { DONT_SEND_AUTOMATICALLY_ID } from 'admin/components/Settings/components/NewTemplateBuilder/TemplateBuilderConstants';
import { CommunicationModality } from 'common-types/CommunicationModality';
import { EmailDto } from 'common-types/EmailDto';
import { MessageSenderType } from 'common-types/MessageSenderType';
import { SortableTemplateBlock, TemplateBlock } from 'common-types/template-block';
import { makeTemplateReadyToEdit, makeTemplateReadyToSend } from 'common/utils';

import { TemplateMetadata } from './template-builder';

interface TemplateDTO {
  id?: number;
  name: string;
  subject: string;
  body: string;
  structureJson: string;
  active: boolean;
  templateFolder: TemplateFolder | null;
  automationRuleId: number | null;
  modality: CommunicationModality;
  sequenceOrder: number;
  deleted: boolean;
  created: string;
  metadata: TemplateMetadata;
}

const getDefaultMetaData = (): TemplateMetadata => ({
  useBackgroundColor: false,
  backgroundColor: '#f0f8ff',
  useCompanyFooter: false,
  useCompanyLogo: false,
  blocks: [],
});

interface TemplateEntity extends TemplateDTO {
  id?: number;
  name: string;
  subject: string;
  body: string;
  structureJson: string;
  active: boolean;
  modality: CommunicationModality;
  sequenceOrder: number;
  deleted: boolean;
  created: string;
  generalServiceType?: ServiceType | null;
  createdDate: string;
  description: string;
  folder: number;
}

type TemplateEntityKey = keyof TemplateEntity;

const filterKeys = (template: TemplateEntity, skipKeys: TemplateEntityKey[]) => {
  return Object.keys(template).reduce(
    // @ts-ignore
    (accumulator, key: any) => (skipKeys.includes(key) ? accumulator : { ...accumulator, [key]: template[key] }),
    {} as TemplateDTO,
  );
};

interface ToDTOOptions {
  wrapTemplateInDiv?: boolean;
  useExistingBody?: boolean;
}

class Template extends Record<TemplateEntity>({
  id: 0,
  name: '',
  subject: '',
  body: '',
  structureJson: '',
  active: true,
  templateFolder: null,
  automationRuleId: 0,
  modality: CommunicationModality.EMAIL,
  generalServiceType: null,
  sequenceOrder: 1,
  deleted: false,
  created: '',
  createdDate: '',
  description: '',
  folder: 0,
  metadata: getDefaultMetaData(),
}) {
  constructor(data: Partial<TemplateDTO> = {}) {
    let parsedJSON = getDefaultMetaData();

    try {
      parsedJSON = JSON.parse(data.structureJson || '{}');
    } catch (e) {}

    const metadata = parsedJSON.hasOwnProperty('useBackgroundColor') ? parsedJSON : getDefaultMetaData();

    const body = makeTemplateReadyToEdit(data.body || '');

    super({
      ...data,
      body,
      createdDate: data.created ? format(new Date(data.created), `${UI_DATE_FORMAT} ${UI_LOCALIZED_TIME_FORMAT}`) : '',
      folder: data.templateFolder?.id ?? 0,
      metadata,
      automationRuleId: data.automationRuleId || DONT_SEND_AUTOMATICALLY_ID,
    });
  }

  convertToBlocksTemplate(headerBlock: SortableTemplateBlock, footerBlock: SortableTemplateBlock): Template {
    // if we don't have blocks in metadata it means that we have old template
    // we need to convert it automatically to new format
    if (!this.metadata.blocks?.length) {
      const metadata = this.metadata;

      const bodyBlock = new TemplateBlock({
        body: this.body,
      }).toSortableTemplateBlock();

      metadata.blocks = [
        metadata.useCompanyLogo && headerBlock,
        bodyBlock,
        metadata.useCompanyFooter && footerBlock,
      ].filter(Boolean) as SortableTemplateBlock[];

      return this.set('metadata', metadata);
    }

    return this;
  }

  toDTO(options: Partial<ToDTOOptions> = {}): TemplateDTO {
    const { wrapTemplateInDiv = true } = options;
    const result = filterKeys(this.toJS() as TemplateEntity, ['createdDate', 'description', 'folder', 'metadata']);
    return {
      ...result,
      body: makeTemplateReadyToSend({
        metadata: this.metadata,
        useTemplateBuilderStyles: true,
        wrapTemplateInDiv,
        modality: this.modality,
        body: this.body,
        useExistingBody: options.useExistingBody,
      }),
      structureJson: JSON.stringify(this.metadata),
      automationRuleId: result.automationRuleId === DONT_SEND_AUTOMATICALLY_ID ? null : result.automationRuleId,
    };
  }

  toEmailDto(emailDto: EmailDto): EmailDto {
    return {
      ...emailDto,
      body: this.body,
      subject: this.subject,
      senderType: MessageSenderType.DIRECT,
      customerAddressed: true,
    };
  }

  isBlocksTemplate(): boolean {
    return !!this.metadata?.blocks;
  }
}

export { Template };

export type { TemplateDTO };
