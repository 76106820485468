import { JobLoadingUnloadingTimeDto, Waypoint, parseISO } from '@elromcoinc/react-shared';
import { differenceInSeconds } from 'date-fns';
import { List } from 'immutable';

const startTimeName = 'startDateTime';
const endTimeName = 'endDateTime';

export const calculateLaborTimeForJob = (
  times: JobLoadingUnloadingTimeDto<string>[],
  isCalculateDrivingTimeAsLabor: boolean,
  waypoints: List<Waypoint>,
  completedStatusTime: Date | null,
) => {
  if (isCalculateDrivingTimeAsLabor && times.length >= 1) {
    const timeOffTotal = times.reduce((accumulator, t) => accumulator + (t.timeOffSeconds ?? 0), 0);
    const startDateString = times[0]?.[startTimeName]!;
    let endDateString = times[times.length - 1]?.[endTimeName]! ?? (completedStatusTime ?? new Date()).toISOString();

    if (waypoints.size - 2 > times.length) {
      endDateString = (completedStatusTime ?? new Date()).toISOString();
    }

    const startDate = parseISO(startDateString)!;
    const endDate = parseISO(endDateString)!;
    return Math.max(Math.abs(differenceInSeconds(endDate, startDate)) - timeOffTotal, 0);
  }

  return times.reduce((acc, time) => {
    return acc + time.totalTimeSeconds!;
  }, 0);
};
