import axios from 'axios';

import { EmailConnection } from 'common-types';

interface SmtpConnectionTestResult {
  success: boolean;
  errorMessage: string;
}

class EmailAPI {
  emailIncomingTest(data: any) {
    return axios.put(`/api/admin/email/imap/test`, {}, { params: data });
  }

  emailOutgoingTest(data: any) {
    return axios.put(`/api/admin/email/smtp/test`, {}, { params: data });
  }

  connectionTest(connection: EmailConnection) {
    return axios.post<SmtpConnectionTestResult, SmtpConnectionTestResult>(
      '/api/communications/email/sender/connectionTest',
      connection,
    );
  }

  connectionTestForCompany(companyId = 1) {
    return axios.post<SmtpConnectionTestResult, SmtpConnectionTestResult>(
      `/api/communications/email/sender/connectionTest/company/${companyId}`,
      {},
    );
  }

  connectionTestForEmployee(employeeId: number) {
    return axios.post<SmtpConnectionTestResult, SmtpConnectionTestResult>(
      `/api/communications/email/sender/connectionTest/employee/${employeeId}`,
      {},
    );
  }

  sendEmailFromCompany(formData: FormData) {
    return axios.post('/api/communications/email/sender/company/', formData);
  }

  sendEmailFromCurrentEmployee(formData: FormData) {
    return axios.post('/api/communications/email/sender/employee/', formData);
  }
}
export default new EmailAPI();
