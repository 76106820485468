import React, { useState } from 'react';

import { BodyText, Link, Order, Product } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import PackingModal from 'admin/components/OrderWindow/modals/Materials';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

enum Modals {
  PACKING = 'PACKING',
}

export const ClosingMaterials = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const classes = useStyles();
  const quote = order.getServiceQuote(serviceIndex);
  const { onChange } = useOrderChangeSet();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('packingProducts');
  const packingProducts = order.getIn(name.split('.')) as List<Product>;

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  const handlePackingChanges = (value: List<Product>) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.PACKING)}>
          Packing/Unpacking
        </Link>
        <BodyText>
          {order.closingOrderDetail?.getMaterialsTotal(serviceIndex, isSelectedAllServices)?.asHumanReadableString()}
        </BodyText>
      </Box>
      {Modals.PACKING === openModal && (
        <PackingModal
          open
          onSave={handlePackingChanges}
          onChange={onChange}
          onCancel={toggleOpenModal(null)}
          moveType={order.moveType}
          serviceType={quote.serviceType}
          packingProducts={packingProducts}
        />
      )}
    </Grid>
  );
};
