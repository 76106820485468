import { ServiceType } from '@elromcoinc/react-shared';

import AutomationRuleName from 'common-types/template-block/AutomationRuleName';

const AutomationRuleByGeneralServiceTypeQuoteOff = {
  [ServiceType.LOCAL_MOVING]: AutomationRuleName.WHEN_A_NEW_LOCAL_MOVE_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.OVERNIGHT_STORAGE]: AutomationRuleName.WHEN_A_NEW_OVERNIGHT_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.LOADING_HELP]: AutomationRuleName.WHEN_A_NEW_LOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.UNLOADING_HELP]: AutomationRuleName.WHEN_A_NEW_UNLOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.MOVING_AND_STORAGE]: AutomationRuleName.WHEN_A_NEW_MOVING_AND_STORAGE_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.PACKING_DAY]: null, // don't have such automation rule
  // [ServiceType.JUNK_REMOVAL]: null, // don't have such automation rule
  [ServiceType.LD_INTERSTATE]: AutomationRuleName.WHEN_A_NEW_LD_INTERSTATE_QUOTE_OFF_LEAD_IS_CREATED,
  [ServiceType.LD_INTRASTATE]: AutomationRuleName.WHEN_A_NEW_LD_INTRASTATE_QUOTE_OFF_LEAD_IS_CREATED,
};

export { AutomationRuleByGeneralServiceTypeQuoteOff };
