enum OrderWindowModal {
  MOVE_DETAILS,
  ADD_DAY,
  SCHEDULE_TRUCK,
  START_TIME_WINDOW,
  TRAVEL_RATE,
  LABOR_RATE,
  MIN_LABOR_HOURS,
  CREW,
  TRUCK,
  MIN_SIZE,
  DELIVERY_DAYS_WINDOW,
}

export { OrderWindowModal };
