import React, { FC } from 'react';

import { BodyBigText } from '@elromcoinc/react-shared';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

interface CheckedProps {
  value: boolean;
  label?: string;
}

const Checked: FC<CheckedProps> = ({ value, label = null }) => {
  return (
    <>
      {value ? (
        <CheckIcon style={{ marginRight: 8 }} color="primary" fontSize="small" />
      ) : (
        <CloseIcon style={{ marginRight: 8 }} color="secondary" fontSize="small" />
      )}
      {label && <BodyBigText>{label}</BodyBigText>}
    </>
  );
};

export { Checked };
