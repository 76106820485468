import React, { useEffect, useState } from 'react';

import { BodyBigText, JobDto, Waypoint, toDateString, uuid } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { List } from 'immutable';

import { useTimeLineData } from 'admin/components/CrewStatusLog/hooks';
import useElementSize from 'admin/components/OrderWindow/hooks/useElementSize';

import { ActualBlock } from './ActualBlock';
import { EstimatedBlock } from './EstimatedBlock';
import { HorizontalTimeLines } from './HorizontalTimeLines';
import { IconGroups } from './IconGroups';
import { TimeLine } from './TimeLine';
import { TimeOffBlock } from './TimeOffBlock';
import { TravelTimeBlock } from './TravelTimeBlock';

const timeLineWidth = 70;

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: 'hidden',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    height: theme.spacing(18),
  },
  paperBar: {
    minWidth: ({ timeLineWidth }: { timeLineWidth: number }) => timeLineWidth,
    height: theme.spacing(16),
    width: '100%',
    display: 'flex',
    position: 'relative',
    marginBottom: theme.spacing(3.5),
    outline: `1px solid ${theme.palette.grey[300]}`,
    outlineOffset: '-1px',
  },
  hour: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRight: '1px solid #E0E0E0',
    boxSizing: 'border-box',
    paddingBottom: theme.spacing(1),
    minWidth: timeLineWidth,
  },
}));

interface CrewStatusLogProps {
  job: JobDto | null;
  startTime?: string;
  durationInSeconds: number;
  date: Date;
  isCalculateDrivingTimeAsLabor: boolean;
  whiteIcons?: boolean;
  waypoints: List<Waypoint>;
  finalBillableTravelTimeInMinutes: number;
  estimatedFinalBillableTravelTimeInMinutes: number;
  totalTimeInMinutes: number;
}

export const CrewStatusLog = ({
  startTime,
  durationInSeconds,
  date,
  job,
  isCalculateDrivingTimeAsLabor,
  whiteIcons = false,
  waypoints,
  finalBillableTravelTimeInMinutes,
  estimatedFinalBillableTravelTimeInMinutes,
  totalTimeInMinutes,
}: CrewStatusLogProps) => {
  const [tick, updateTick] = useState(0);
  const [widthCoefficient, setWidthCoefficient] = useState(1);
  const [ref, width] = useElementSize() as [React.RefObject<HTMLDivElement>, number];
  const {
    startTimeInMinutes,
    actualTIme,
    overtime,
    timeLine,
    endOfDayInMinutes,
    startDayOffsetInMinutes,
    groupedStatusesByClosestTime,
    startTimeOff,
    timeOffMinutes,
    isTimeDone,
    travelTimes,
    timeLines,
  } = useTimeLineData({
    job,
    isCalculateDrivingTimeAsLabor,
    durationInSeconds,
    startTime,
    waypoints,
    finalBillableTravelTimeInMinutes,
    estimatedFinalBillableTravelTimeInMinutes,
    totalTimeInMinutes,
  });

  const classes = useStyles({
    timeLineWidth: timeLineWidth * timeLine.length,
  });

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        setWidthCoefficient((ref.current?.offsetWidth ?? 100) / (endOfDayInMinutes - startDayOffsetInMinutes + 60));
      });
    }
  }, [ref.current, width]);

  return (
    <Box className={classes.root} key={widthCoefficient + tick}>
      <Paper elevation={0} className={classes.paperBar} ref={ref}>
        <TimeLine
          date={date}
          startDayOffsetInMinutes={startDayOffsetInMinutes}
          widthCoefficient={widthCoefficient}
          onUpdate={updateTick}
        />
        <EstimatedBlock
          start={startTimeInMinutes - startDayOffsetInMinutes}
          width={durationInSeconds / 60}
          actualWidth={actualTIme / 60}
          widthCoefficient={widthCoefficient}
          isTimeDone={isTimeDone}
        />
        {!!startTimeOff && (
          <TimeOffBlock
            start={startTimeOff - startDayOffsetInMinutes}
            width={timeOffMinutes}
            widthCoefficient={widthCoefficient}
          />
        )}
        {travelTimes.map((travelTime) => (
          <TravelTimeBlock
            key={travelTime.time}
            start={travelTime.time - startDayOffsetInMinutes}
            width={travelTime.durationInMinutes}
            overtimeStart={travelTime.overtimeStart}
            overtime={travelTime.overtimeInMinutes}
            widthCoefficient={widthCoefficient}
            startDayOffsetInMinutes={startDayOffsetInMinutes}
            bordered={travelTime.bordered}
          />
        ))}
        <HorizontalTimeLines
          startDayOffsetInMinutes={startDayOffsetInMinutes}
          timeLines={timeLines}
          widthCoefficient={widthCoefficient}
        />
        {!!overtime && (
          <ActualBlock
            start={startTimeInMinutes + durationInSeconds / 60 - startDayOffsetInMinutes}
            width={overtime / 60}
            widthCoefficient={widthCoefficient}
          />
        )}
        {timeLine.map((hour) => {
          const formated = toDateString(hour, 'h a') as string;
          const time = hour.getTime();

          return (
            <Box
              width={`calc(100% / ${timeLine.length - 1})`}
              id={`timer-time-${time}`}
              key={hour.getTime()}
              className={classes.hour}
              data-testid={`time-${formated}`}
            >
              <BodyBigText>
                <b>{formated}</b>
              </BodyBigText>
            </Box>
          );
        })}
        {groupedStatusesByClosestTime.map((statuses) => (
          <IconGroups
            key={uuid()}
            startDayOffsetInMinutes={startDayOffsetInMinutes}
            widthCoefficient={widthCoefficient}
            statuses={statuses}
            whiteIcons={whiteIcons}
          />
        ))}
      </Paper>
    </Box>
  );
};
