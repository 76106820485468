import React, { FC } from 'react';

import { BodyBigText, BodyText } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

interface TitleWithDescProps {
  label: string;
  desc?: string;
}

export const TitleWithDesc: FC<TitleWithDescProps> = ({ label, desc }) => {
  return (
    <Box display="flex">
      <BodyBigText>
        <b>{label}</b>
      </BodyBigText>
      {desc && <BodyText>{`(${desc})`}</BodyText>}
    </Box>
  );
};
