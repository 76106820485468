import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { IconProps } from './IconProps';

const StorageIcon = (props: IconProps) => (
  <SvgIcon {...props}>
    <svg viewBox="0 0 243.000000 207.000000">
      <g transform="translate(0.000000,207.000000) scale(0.100000,-0.100000)" fill={props.color} stroke="none">
        <path d="M1109 1797 c-81 -40 -191 -93 -245 -119 -55 -26 -183 -88 -286 -137 l-188 -91 0 -697 0 -698 160 0 160 0 0 568 0 567 590 0 590 0 0 -565 0 -564 155 -3 155 -3 0 698 0 698 -57 25 c-32 14 -76 34 -98 44 -144 66 -217 100 -220 100 -2 0 -41 17 -87 39 -46 21 -99 45 -118 54 -19 8 -71 32 -115 52 -172 81 -228 105 -239 104 -6 0 -77 -33 -157 -72z" />
        <path d="M810 670 l0 -80 100 0 100 0 0 80 0 80 -100 0 -100 0 0 -80z" />
        <path d="M1070 670 l0 -80 95 0 95 0 0 80 0 80 -95 0 -95 0 0 -80z" />
        <path d="M810 423 l0 -83 100 0 100 0 0 83 0 82 -100 0 -100 0 0 -82z" />
        <path d="M1070 423 l0 -83 95 0 95 0 0 83 0 82 -95 0 -95 0 0 -82z" />
        <path d="M1320 423 l0 -83 100 0 100 0 0 83 0 82 -100 0 -100 0 0 -82z" />
        <path d="M810 175 l0 -85 100 0 100 0 0 85 0 85 -100 0 -100 0 0 -85z" />
        <path d="M1070 175 l0 -85 95 0 95 0 0 85 0 85 -95 0 -95 0 0 -85z" />
        <path d="M1320 175 l0 -85 100 0 100 0 0 85 0 85 -100 0 -100 0 0 -85z" />
        <path d="M1580 180 l0 -80 95 0 95 0 0 80 0 80 -95 0 -95 0 0 -80z" />
      </g>
    </svg>
  </SvgIcon>
);

export { StorageIcon };
