import { ArrivalWindowEnum } from 'admin/constants/StartEndTimes';

export const ArrivalWindowToStartEndTime = {
  [ArrivalWindowEnum.ANY]: null,
  [ArrivalWindowEnum.EIGHT_TEN_AM]: {
    start: '08:00:00',
    end: '10:00:00',
  },
  [ArrivalWindowEnum.TWELVE_THREE_PM]: {
    start: '12:00:00',
    end: '15:00:00',
  },
  [ArrivalWindowEnum.ONE_FOUR_PM]: {
    start: '13:00:00',
    end: '16:00:00',
  },
  [ArrivalWindowEnum.THREE_SEVEN_PM]: {
    start: '15:00:00',
    end: '19:00:00',
  },
} as const;
