import { createContext, useContext } from 'react';

import { useCurrentDistanceUnits, useCurrentMoveSizeUnit } from '@elromcoinc/react-shared';

type OrderSettingUnitsContextProps = Omit<ReturnType<typeof useCurrentDistanceUnits>, 'setDistanceUnits'> &
  ReturnType<typeof useCurrentMoveSizeUnit>;
export const OrderSettingUnitsContext = createContext<OrderSettingUnitsContextProps>(
  {} as OrderSettingUnitsContextProps,
);

export const useOrderSettingUnits = () => useContext(OrderSettingUnitsContext);
