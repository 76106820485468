import { useEffect, useRef, useState } from 'react';

import settingsAPI from 'admin/components/Settings/api/settingsAPI';

const delay = 800;

function useSettingsApi<T = any>(...nodeNames: string[]) {
  const [state, setState] = useState<T | null>(null);
  const [inFlight, setInFlight] = useState(true);
  const timerRef = useRef<any>();

  useEffect(() => () => clearTimeout(timerRef.current), [timerRef]);

  function mergeState(newState: Partial<T> | T) {
    setState((latestState) => ({ ...(latestState || {}), ...newState } as T));
    setInFlight(false);
  }

  function setTimer() {
    if (!timerRef.current) {
      timerRef.current = setTimeout(() => setInFlight(true), delay);
    }
  }

  function clearTimer() {
    clearTimeout(timerRef.current);
    timerRef.current = null;
    setInFlight(false);
  }

  function handleError(name: keyof T, value: any) {
    return (res: { status: number }) => {
      clearTimeout(timerRef.current);
      if (res.status > 300) {
        mergeState({ [name]: value } as T);
      }
    };
  }

  function saveObject(name: keyof T, value: any, callback = () => true) {
    if (!state) {
      return Promise.resolve();
    }
    setTimer();
    const oldValue = state[name];
    mergeState({ [name]: value } as T);
    return settingsAPI
      .updateSettingNode(name as string, value)
      .then(clearTimer)
      .then(callback)
      .catch(handleError(name, oldValue));
  }
  function saveObjectAllValues(newState: T) {
    setTimer();
    mergeState(newState);
    return settingsAPI // @ts-ignore
      .updateSettingNodes(Object.entries(newState).map(([name, value]) => ({ name, value })))
      .then(clearTimer);
  }

  // @ts-ignore
  function saveValue({ name: n, value: v, target: { name: targetName, value: targetValue, type, checked } = {} }) {
    if (!state) {
      return Promise.resolve();
    }
    const name: keyof T = n !== undefined ? n : targetName;
    const currentTargetValue = type === 'checkbox' ? checked : targetValue;
    const value = v !== undefined ? v : currentTargetValue;

    setTimer();
    const oldValue = state[name];
    const trimmedValue = typeof value === 'string' ? `${value}`.trim() : value;
    mergeState({ [name]: trimmedValue } as T);
    const { promise } = settingsAPI.updateSettingNodeCancel(name as string, value !== null ? trimmedValue : null);
    return promise.then(clearTimer).catch(handleError(name, oldValue));
  }

  // @ts-ignore
  const staging = ({ name, value, target: { name: targetName, value: targetValue } = {} }) => {
    setState({ ...state, [name || targetName]: value || targetValue } as T);
  };

  function refresh() {
    setInFlight(true);
    return settingsAPI
      .getDataFromQuery({ queries: nodeNames })
      .then((response: T) => {
        setState(response);
        return response;
      })
      .catch(() => {})
      .then((response) => {
        setInFlight(false);
        return response as T;
      });
  }

  // @ts-ignore
  useEffect(refresh, [...nodeNames]);

  return [state, inFlight, saveValue, saveObject, staging, refresh, saveObjectAllValues];
}

export default useSettingsApi;
