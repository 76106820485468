import React, { FC, useEffect } from 'react';

import { HeaderSmallText, Modal, Radio, RadioGroup, Select, TextInput, Waypoint } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { convertLocalTimeToSeconds } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import useTimeDuration from 'admin/components/OrderWindow/hooks/useTimeDuration';
import { convertLocalizedTimeToLocalTime } from 'admin/components/OrderWindow/modals/FullAddressesModal';
import {
  ACCESS_RESTRICTION_INFO,
  COMMENT,
  ELEVATOR,
  END_TIME,
  LOADING_DOCK,
  LOCATION_ACCESS_TYPE,
  OTHER,
  START_TIME,
  labels,
} from 'admin/components/OrderWindow/modals/FullAddressesModal/FullAddressesLabels';
import { getLabel } from 'admin/utils/getLabel';

const locationTypePath = `${ACCESS_RESTRICTION_INFO}.${LOCATION_ACCESS_TYPE}`;
const elevatorTypePath = `${ACCESS_RESTRICTION_INFO}.${LOCATION_ACCESS_TYPE}.${ELEVATOR}`;
const loadingDockTypePath = `${ACCESS_RESTRICTION_INFO}.${LOCATION_ACCESS_TYPE}.${LOADING_DOCK}`;
const otherTypePath = `${ACCESS_RESTRICTION_INFO}.${LOCATION_ACCESS_TYPE}.${OTHER}`;
const startTimePath = `${ACCESS_RESTRICTION_INFO}.${START_TIME}`;
const endTimePath = `${ACCESS_RESTRICTION_INFO}.${END_TIME}`;
const commentPath = `${ACCESS_RESTRICTION_INFO}.${COMMENT}`;

const restrictionSchema = object().shape({
  [ACCESS_RESTRICTION_INFO]: object().shape({
    [LOCATION_ACCESS_TYPE]: string().oneOf(
      [ELEVATOR, LOADING_DOCK, OTHER],
      'Please select one of location access type',
    ),
    [START_TIME]: string().label(labels[ACCESS_RESTRICTION_INFO][START_TIME]).nullable().required(),
    [END_TIME]: string().label(labels[ACCESS_RESTRICTION_INFO][END_TIME]).nullable().required(),
    [COMMENT]: string().label(labels[ACCESS_RESTRICTION_INFO][COMMENT]).nullable().max(999),
  }),
});

interface TimeWindowRestrictionProps {
  onSave: (twr: Waypoint) => void;
  onCancel: () => void;
  open: boolean;
  data: Waypoint | null;
}

export const TimeWindowRestriction: FC<TimeWindowRestrictionProps> = ({ onSave, onCancel, open, data }) => {
  const { isCompleted } = useOrderClosingContext();
  const formMethods = useForm<FieldValues>({
    defaultValues: {
      ...data,
      [ACCESS_RESTRICTION_INFO]: {
        ...data?.accessRestrictionInfo,
        [START_TIME]: convertLocalizedTimeToLocalTime(data?.accessRestrictionInfo?.startTime),
        [END_TIME]: convertLocalizedTimeToLocalTime(data?.accessRestrictionInfo?.endTime),
      },
    },
    resolver: yupResolver(restrictionSchema),
    mode: 'onChange',
  });

  const { control, handleSubmit, setValue, watch } = formMethods;

  const startTime = watch(`${ACCESS_RESTRICTION_INFO}.${START_TIME}`);
  const endTime = watch(`${ACCESS_RESTRICTION_INFO}.${END_TIME}`);

  const [timeOptions, adjustedTimeOptions] = useTimeDuration(startTime);

  useEffect(() => {
    if (convertLocalTimeToSeconds(startTime) > convertLocalTimeToSeconds(endTime)) {
      setValue(`${ACCESS_RESTRICTION_INFO}.${END_TIME}`, startTime, { shouldValidate: true });
    }
  }, [startTime, endTime]);

  const handleSubmitForm = (data: Waypoint) => {
    onSave(data);
  };

  return (
    <Modal
      open={open}
      title="Time Window Restriction"
      onClose={onCancel}
      color="grey"
      maxWidth="sm"
      actions={[
        { label: 'cancel', onClick: onCancel, color: 'default' },
        { label: 'save', onClick: handleSubmit(handleSubmitForm as any), color: 'primary', disabled: isCompleted },
      ]}
    >
      <FormProvider {...(formMethods as any)}>
        <Box mb={1}>
          <RadioGroup direction="row" name={locationTypePath}>
            <Radio
              value={ELEVATOR}
              label={getLabel(elevatorTypePath, labels)}
              disabled={isCompleted}
              sameUncheckedColor
              color="primary"
            />
            <Radio
              disabled={isCompleted}
              value={LOADING_DOCK}
              label={getLabel(loadingDockTypePath, labels)}
              sameUncheckedColor
              color="primary"
            />
            <Radio
              disabled={isCompleted}
              value={OTHER}
              label={getLabel(otherTypePath, labels)}
              sameUncheckedColor
              color="primary"
            />
          </RadioGroup>
        </Box>
        <Box mb={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box maxWidth="10rem">
                <Select
                  fullWidth
                  disabled={isCompleted}
                  options={timeOptions}
                  name={startTimePath}
                  label={getLabel(startTimePath, labels)}
                  primaryBackgroundOnSelectedItem
                  control={control}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box maxWidth="10rem">
                <Select
                  fullWidth
                  disabled={isCompleted}
                  options={adjustedTimeOptions}
                  name={endTimePath}
                  label={getLabel(endTimePath, labels)}
                  primaryBackgroundOnSelectedItem
                  control={control}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={1}>
          <HeaderSmallText>
            <b>Comment</b>
          </HeaderSmallText>
        </Box>
        <TextInput
          fullWidth
          disabled={isCompleted}
          name={commentPath}
          control={control as any}
          multiline
          rows="4"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </FormProvider>
    </Modal>
  );
};
