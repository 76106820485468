import React, { useEffect, useState } from 'react';

import { ActivitySourceType, BodyText, FileDTO, Quote } from '@elromcoinc/react-shared';
import { SignaturesBlocks, getSignatureBlockLabel } from '@elromcoinc/shared-bol';
import { Box, useTheme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import fileAPI from 'admin/api/FileAPI';
import { useOrderState } from 'admin/components/OrderWindow/context';

export const BillOfLadingSignedPriorToPickup = () => {
  const theme = useTheme();
  const { order } = useOrderState();
  const quote = order?.getServiceQuote()! as Quote;
  const jobId = quote?.dispatchJob?.id;
  const [signatures, setSignatures] = useState<FileDTO[]>([]);
  const signatureAtLeastThreeDaysBeforePickupDate = signatures.find(
    (sign) => sign.label === getSignatureBlockLabel(SignaturesBlocks.SIGNATURE_AT_LEAST_THREE_DAYS_BEFORE_PICKUP_DATE),
  );

  useEffect(() => {
    if (jobId) {
      fileAPI
        .getAllFiles(jobId, 0, 100, [], ActivitySourceType.JOB_SIGNATURE)
        .then(({ pageElements }) => setSignatures(pageElements))
        .catch(() => setSignatures([]));
    }
  }, [jobId]);

  return (
    <Box display="flex" alignItems="center" alignContent="center" my={1}>
      {!!signatureAtLeastThreeDaysBeforePickupDate ? (
        <Box display="flex" color={theme.palette.success.main}>
          <CheckBoxIcon color="inherit" />
        </Box>
      ) : (
        <CheckBoxOutlineBlankIcon />
      )}
      <BodyText>
        <b>BOL Signed Prior to Pickup</b>
      </BodyText>
    </Box>
  );
};
