import React, { useEffect, useState } from 'react';

import { DateRangePicker, Select } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import { endOfMonth, format, startOfMonth, startOfWeek, sub } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { fetchDashboardStats } from 'admin/autodux/DashboardAutodux';
import { getReloadAmount } from 'admin/autodux/ReloadAutodux';
import { customDateName, periods } from 'admin/constants/DashboardPeriods';

const formatDate = (date = new Date()) => format(date, 'yyyy-MM-dd');
const preparePeriod = (startDate = new Date(), endDate = new Date()) => ({
  startDate: formatDate(startDate),
  endDate: formatDate(endDate),
  previousStartDate: formatDate(sub(startDate, { years: 1 })),
  previousEndDate: formatDate(sub(endDate, { years: 1 })),
});

const dateByPeriod = {
  0: () => preparePeriod(),
  1: () => preparePeriod(sub(new Date(), { days: 1 }), sub(new Date(), { days: 1 })),
  2: () => preparePeriod(startOfWeek(new Date(), { weekStartsOn: 1 })),
  3: () => preparePeriod(sub(new Date(), { weeks: 1 }), sub(new Date(), { days: 1 })),
  4: () => preparePeriod(startOfMonth(new Date())),
  5: () => preparePeriod(startOfMonth(sub(new Date(), { months: 1 })), endOfMonth(sub(new Date(), { months: 1 }))),
  default(startDate, endDate) {
    return preparePeriod(startDate, endDate);
  },
};

const pastWeekIndex = 3;

export default function DashboardPeriod() {
  const dispatch = useDispatch();
  const [selectedPeriod, setSelectedPeriod] = useState(periods[0][0]);
  const [dateRange, setDateRange] = useState({
    startDate: sub(new Date(), { months: 1 }),
    endDate: new Date(),
  });
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  const handleChangeDate = (value) => {
    setDateRange({ ...(value || {}) });
  };
  const handleChange = (e) => setSelectedPeriod(e.target.value);
  const reloadAmount = useSelector(getReloadAmount);

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      const periodFunc = dateByPeriod[selectedPeriod];
      const periodName = periods[selectedPeriod][1];
      const period =
        (periodFunc && periodFunc()) ||
        dateByPeriod.default(dateRange.startDate || new Date(), dateRange.endDate || new Date());
      dispatch(fetchDashboardStats(period, dateByPeriod[pastWeekIndex](), periodName, true));

      const intervalId = setInterval(() => {
        dispatch(fetchDashboardStats(period, dateByPeriod[pastWeekIndex](), periodName));
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [selectedPeriod, dateRange, reloadAmount, isSessionExpired, isLogout]);

  return (
    <Box display="flex" flexWrap="wrap">
      {periods[selectedPeriod][1] === customDateName && (
        <Box display="flex" alignItems="flex-end" paddingTop="15.5px" marginRight="5px">
          <DateRangePicker
            inputFormat="P"
            onChange={handleChangeDate}
            placeholder="mm/dd/yyyy"
            variant="input"
            value={dateRange}
            hiddenLabel
            size="small"
          />
        </Box>
      )}
      <Box width={260}>
        <Select options={periods} label="Showing" value={selectedPeriod} onChange={handleChange} fullWidth />
      </Box>
    </Box>
  );
}
