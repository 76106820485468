import styled from 'styled-components';

export const Flex = styled.div<{ $wrap?: boolean; $manual?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: ${(props) => {
    return props.$wrap ? 'wrap' : 'nowrap';
  }};
  align-items: center;
  input,
  p {
    ${(props) => {
      return props.$manual ? 'color:#FF5B5B !important;' : '';
    }}
  }
  fieldset {
    border: none;
  }
  svg {
    display: none;
  }
`;
