import { useEffect } from 'react';

import { useUpdateEffect } from '@elromcoinc/react-shared';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { fetchManagers, getManagerList, setManagerList } from 'admin/autodux/UsersAutodux';
import storageUtil from 'admin/utils/storageUtil';
import { SalesPerson } from 'common-types';

export const useFetchManagers = () => {
  const salesPersonList: List<SalesPerson> = useSelector(getManagerList);
  const dispatch = useDispatch<DispatchPromise>();
  const companyId = storageUtil.getTenantId();

  useEffect(() => {
    if (salesPersonList.size === 0) {
      dispatch(fetchManagers());
    }
  }, [salesPersonList.size]);

  useUpdateEffect(() => {
    dispatch(setManagerList(List()));
  }, [companyId]);
};
