import { OrderStatuses, statusIds, statusesById } from '@elromcoinc/react-shared';

const {
  CANCELLED,
  NEW,
  NEW_DATE,
  NEW_LEAD_TRUCK,
  NEW_LEAD_INFO,
  FOLLOW_UP,
  CAN_BOOK_ONLINE,
  RESERVED,
  WE_ARE_NOT_AVAILABLE,
  BOOKED,
  DEAD_LEAD,
  EXPIRED,
  BAD_LEAD,
  SPAM,
  ARCHIVED,
  NOT_INTERESTED,
  POSTPONED,
  NEW_LEAD_PAST,
} = statusIds;

const allStatuses = [
  NEW,
  FOLLOW_UP,
  CAN_BOOK_ONLINE,
  RESERVED,
  BOOKED,
  POSTPONED,
  CANCELLED,
  WE_ARE_NOT_AVAILABLE,
  EXPIRED,
  DEAD_LEAD,
  BAD_LEAD,
  SPAM,
  NOT_INTERESTED,
  ARCHIVED,
];

export const allNewStatus = [NEW_DATE, NEW_LEAD_TRUCK, NEW_LEAD_INFO, NEW_LEAD_PAST];

export function listOfStatuses(currentStatus) {
  return OrderStatuses.filter((status) => status.id !== 'CANCELLED' || currentStatus === 'BOOKED');
}

export function listOfAvailableStatuses(currentStatus) {
  const result = [...allStatuses];

  if (allNewStatus.includes(currentStatus)) {
    result.splice(1, 0, currentStatus);
  }

  return result.map((it) => statusesById[it]);
}
export function statusColor(status) {
  return (statusesById[status] || {}).color || '#000';
}
export function statusById(status) {
  return statusesById[status] || {};
}
export function statusByName(name) {
  return OrderStatuses.find((s) => s.label === name);
}

export function isReservedOrBooked(status) {
  return status === BOOKED || status === RESERVED;
}
