const ITEM_NAME = 'name';
const DESCRIPTION = 'description';
const UNIT_COST = 'unitCost';
const QUANTITY = 'quantity';
const DISCOUNT = 'discount';
const AMOUNT = 'amount';
const TYPE = 'type';
const TERMS = 'terms';
const TAX = 'tax';
const TOTAL = 'total';
const NOTES = 'message';
const ITEMS = 'items';
const STATUS = 'status';
const PAID_AMOUNT = 'paidAmount';
const ISSUE_DATE = 'issueDate';
const ADDRESS = 'address';
const DUE_DATE = 'dueDate';

const InvoicesItemLabels = {
  [ITEM_NAME]: 'Item Name',
  [DESCRIPTION]: 'Description',
  [UNIT_COST]: 'Unit Cost',
  [QUANTITY]: 'Quantity',
  [DISCOUNT]: 'Discount',
  [TAX]: 'Tax',
  [TOTAL]: 'Line Total',
  [TERMS]: 'Terms',
  [NOTES]: 'Notes',
  [STATUS]: 'Status',
  [ISSUE_DATE]: 'Date of Issue',
  [DUE_DATE]: 'Due Date',
};

export {
  InvoicesItemLabels,
  ITEM_NAME,
  DESCRIPTION,
  UNIT_COST,
  QUANTITY,
  DISCOUNT,
  TERMS,
  TAX,
  TOTAL,
  NOTES,
  ITEMS,
  STATUS,
  PAID_AMOUNT,
  TYPE,
  AMOUNT,
  ISSUE_DATE,
  ADDRESS,
  DUE_DATE,
};
