import React, { useState } from 'react';

import {
  ActivitySourceType,
  BodyText,
  ElromcoCircularProgress,
  Modal,
  Radio,
  RadioGroup,
  UI_DATE_TIME_FORMAT,
} from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import accountAPI from 'admin/api/AccountAPI';
import ordersAPI from 'admin/api/OrdersAPI';
import { EXPORT } from 'admin/components/Accounts/AccountsActionType';

const EXPORT_BY = 'EXPORT_BY';
const SELECTED_ELEMENTS = 'SELECTED_ELEMENTS';
const ALL_ELEMENTS = 'ALL_ELEMENTS';

const activitySourceName = {
  [ActivitySourceType.ORDER]: 'ORDER(S)',
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'ACCOUNT(S)',
};

const CSVFileFormat = 'CSV';

export const ExportModal = ({ title, onClose, rowsChecked, source, activitySource, selectedActivityIds, filters }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [exportRadio, setExportRadio] = useState(CSVFileFormat);
  const [isProcessing, setIsProcessing] = useState(false);
  const [exportOption, setExportOption] = useState(SELECTED_ELEMENTS);

  const handleExportRadioChange = ({ target: { value } }) => setExportRadio(value);
  const handleExportOptionRadioChange = ({ target: { value } }) => {
    setExportOption(value);
  };

  const ids =
    activitySource === ActivitySourceType.ORDER
      ? selectedActivityIds
      : selectedActivityIds.map(({ sourceId }) => sourceId);

  const handleExport = () => {
    setIsProcessing(true);
    (activitySource === ActivitySourceType.ORDER
      ? ordersAPI.downloadOrdersReport(exportOption === ALL_ELEMENTS ? { ...filters } : { id: ids })
      : accountAPI.downloadAccountsReport(exportOption === ALL_ELEMENTS ? { ...filters } : { id: ids })
    )
      .then((res) => {
        fileDownload(res, `${activitySourceName[activitySource]} ${format(new Date(), UI_DATE_TIME_FORMAT)}.csv`);
        enqueueSnackbar(
          `The CSV file with the ${activitySourceName[activitySource].toLowerCase()} has been successfully downloaded`,
          { variant: 'success' },
        );
        onClose();
      })
      .catch(() => {
        enqueueSnackbar(`Can't download a CSV file with data`, {
          variant: 'error',
        });
      })
      .then(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Modal
      actions={[
        { label: 'cancel', onClick: onClose },
        { label: 'save', onClick: handleExport },
      ]}
      hideActionControls={isProcessing}
      onClose={onClose}
      title={title}
      open
      maxWidth="xs"
    >
      {isProcessing ? (
        <Box display="flex" flexDirection="column" alignItems="center" overflow="hidden" mt={1}>
          <BodyText color="primary">
            <b>
              {exportOption === ALL_ELEMENTS
                ? `Exporting all ${source}...`
                : `Exporting ${rowsChecked.length} ${source}...`}
            </b>
          </BodyText>
          <Box pt={1} position="relative">
            <ElromcoCircularProgress />
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <RadioGroup aria-label="export" onChange={handleExportRadioChange} name={EXPORT} value={exportRadio}>
            <Radio value={CSVFileFormat} label={`As ${CSVFileFormat}`} variant="checkbox" color="primary" />
          </RadioGroup>
          <RadioGroup
            label="Export As"
            aria-label="export by"
            onChange={handleExportOptionRadioChange}
            name={EXPORT_BY}
            value={exportOption}
          >
            <Radio
              value={SELECTED_ELEMENTS}
              label={`Selected ${activitySource === ActivitySourceType.ORDER ? 'order(s)' : 'account(s)'}`}
              variant="checkbox"
              color="primary"
            />
            <Radio
              value={ALL_ELEMENTS}
              label={`All ${activitySource === ActivitySourceType.ORDER ? 'orders' : 'accounts'}`}
              variant="checkbox"
              color="primary"
            />
          </RadioGroup>
        </Box>
      )}
    </Modal>
  );
};

ExportModal.propTypes = {
  title: pt.string.isRequired,
  onClose: pt.func.isRequired,
  rowsChecked: pt.arrayOf(pt.number),
  source: pt.string,
  actionForSelected: pt.string,
  selectedActivityIds: pt.arrayOf(pt.number).isRequired,
  activitySource: pt.string.isRequired,
  filters: pt.object,
};

ExportModal.defaultProps = {
  rowsChecked: [],
  source: null,
};
