import React from 'react';
import { renderHour, Row } from 'admin/components/OrderWindow/SchedulerBox/common';
import { addHours, format, isSameDay, startOfDay } from 'date-fns';
import { toDate } from '@elromcoinc/react-shared';
import { EstimationWorkView } from 'admin/components/OrderWindow/SurveySchedulerBox';

const hours = new Array(24).fill(0).map((_, idx) => format(addHours(startOfDay(new Date()), idx), 'h a'));

export const renderEstimation =
  (
    dense,
    estimatorWorks,
    order,
    date,
    currentSurveyValue,
    handleOnWorkClick,
    handleCloseModal,
    selectedWork,
    estimators,
  ) =>
  (estimator, idx) => {
    const height = Math.max(...(estimatorWorks || []));

    const currentEstimatorWorks = estimatorWorks.filter(
      (it) => it.estimatorId === estimator.id && isSameDay(toDate(it.surveyDate), date),
    );

    return (
      <Row key={estimator.id} grey={idx % 2 === 0} dense={dense} heightSize={estimators?.length < 3 ? 1 : height}>
        {hours.map(renderHour(true))}
        {currentEstimatorWorks.map((ew) => (
          <EstimationWorkView
            dense={dense}
            work={ew}
            key={ew.uuid}
            estimatorId={estimator.id}
            currentSurveyValue={currentSurveyValue}
            handleOnWorkClick={handleOnWorkClick}
            handleCloseModal={handleCloseModal}
            selectedWork={selectedWork}
          />
        ))}
      </Row>
    );
  };
