import React, { FC } from 'react';

import { Form, Modal, Order, PhoneInput, TextInput } from '@elromcoinc/react-shared';
import { AdditionalContact, AdditionalContactProps } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getIn } from 'immutable';
import { FormProvider, useForm } from 'react-hook-form';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import {
  ADDITIONAL_CONTACT,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE,
  SEND_NOTIFICATIONS,
} from 'admin/constants/FieldNames';

const getNameValue = (name: string, value: string) => ({ name, value });
const asArray = (path: string) => path.split('.');

const additionalContactFirstNamePath = `${FIRST_NAME}`;
const additionalContactLastNamePath = `${LAST_NAME}`;
const additionalContactEmailPath = `${EMAIL}`;
const additionalContactPhonePath = `${PHONE}`;

const firstNameLabel = 'First Name';
const lastNameLabel = 'Last Name';
const emailAddressLabel = 'Email Address';
const phoneLabel = 'Phone';

const labels = {
  [FIRST_NAME]: firstNameLabel,
  [LAST_NAME]: lastNameLabel,
  [EMAIL]: emailAddressLabel,
  [PHONE]: phoneLabel,
  [SEND_NOTIFICATIONS]: 'Receive Emails',
};

const schema = AdditionalContact.getValidationSchema(labels);

interface AddAdditionalContactsModalProps {
  onClose: () => void;
  onSave: () => void;
  onChange: (data: any) => void;
  order: Order;
}

const AddAdditionalContactsModal: FC<AddAdditionalContactsModalProps> = ({ onClose, order, onSave, onChange }) => {
  const { isClosing } = useOrderClosingContext();
  const formMethods = useForm<AdditionalContactProps>({
    resolver: yupResolver(schema),
    defaultValues: order.additionalContact.toJS() as AdditionalContactProps,
    mode: 'onChange',
  });
  const disableInputs = isClosing;

  const { control, handleSubmit } = formMethods;
  const commonProps = { control };

  const onSubmit = (data: any) => {
    const newAddContact = order.get(ADDITIONAL_CONTACT).mergeDeep(data);
    const changeList = [
      !newAddContact.equals(order.get(ADDITIONAL_CONTACT)) && getNameValue(ADDITIONAL_CONTACT, newAddContact),
    ];
    changeList.filter(Boolean).forEach(onChange);
    onSave();
  };

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
    },
    {
      label: 'save',
      onClick: handleSubmit(onSubmit as any),
      color: 'primary',
    },
  ];

  return (
    <Modal open onClose={onClose} actions={actions} title="Add additional contacts">
      <FormProvider {...(formMethods as any)}>
        <Form>
          <Box>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={disableInputs}
                  fullWidth
                  label={getIn(labels, asArray(additionalContactFirstNamePath), '')}
                  name={additionalContactFirstNamePath}
                  {...commonProps}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={disableInputs}
                  fullWidth
                  label={getIn(labels, asArray(additionalContactLastNamePath), '')}
                  name={additionalContactLastNamePath}
                  {...commonProps}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={disableInputs}
                  fullWidth
                  label={getIn(labels, asArray(additionalContactEmailPath), '')}
                  name={additionalContactEmailPath}
                  {...commonProps}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PhoneInput
                  disabled={disableInputs}
                  fullWidth
                  label={getIn(labels, asArray(additionalContactPhonePath), '')}
                  name={additionalContactPhonePath}
                  {...commonProps}
                />
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddAdditionalContactsModal;
