import React from 'react';

import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';

const AntTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
    backgroundColor: 'transparent',
    minHeight: theme.spacing(4),
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
    height: '4px',
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 42,
    minHeight: theme.spacing(4),
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    padding: theme.spacing(1),
    '&:hover': {
      color: theme.palette.common.black,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold,
    },
    '&:focus': {
      color: theme.palette.common.black,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const TabsComponent = ({ tabs, value, onChange }) => (
  <AntTabs value={value} onChange={onChange} indicatorColor="primary" textColor="primary">
    {React.Children.toArray(tabs.map((name) => <AntTab label={name} data-testid={`${name}-tab`} />))}
  </AntTabs>
);
export default TabsComponent;
