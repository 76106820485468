import { SettingNames } from '@elromcoinc/react-shared';

const { ACCEPT_RESIDENTIAL_ORDERS, ACCEPT_COMMERCIAL_ORDERS, ACCEPT_MILITARY_ORDERS, MOVE_TYPES } = SettingNames;

const getEnabledMoveTypes = (settings: { [key: string]: any }) => {
  const enabledMoveTypes =
    [settings?.[ACCEPT_RESIDENTIAL_ORDERS], settings?.[ACCEPT_COMMERCIAL_ORDERS], settings?.[ACCEPT_MILITARY_ORDERS]] ??
    [];

  return MOVE_TYPES?.filter((item, index) => enabledMoveTypes[index]);
};

export { getEnabledMoveTypes };
