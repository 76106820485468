import React, { useEffect, useState } from 'react';

import { Badge, BodyText } from '@elromcoinc/react-shared';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PropTypes from 'prop-types';

import fileAPI from 'admin/api/FileAPI';
import { IconLabelButton } from 'common/components/Widgets';

import AttachModal from '../modals/Attach';

export default function Attachments({ disabled, orderId, sourceId, activitySource }) {
  const [totalFiles, setTotalFiles] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleTotalFiles = (payload) => setTotalFiles(payload.totalElements || 0) || payload;
  const handleOpenShowModal = () => setShowModal(true);

  const loadFilesCount = () => {
    if (sourceId) {
      fileAPI.getFileContent(sourceId, activitySource).then(handleTotalFiles);
    }
  };

  useEffect(() => {
    loadFilesCount();
  }, [sourceId]);

  useEffect(() => {
    setTotalFiles(0);
  }, [sourceId]);

  const handleCloseShowModal = () => {
    setShowModal(false);
    loadFilesCount();
  };

  return (
    <>
      <IconLabelButton
        color={totalFiles ? 'primary' : 'default'}
        startIcon={<AttachFileIcon />}
        data-testid="attachmentsButton"
        onClick={handleOpenShowModal}
        disabled={disabled}
      >
        <Badge badgeContent={totalFiles} />
        <BodyText>Attach</BodyText>
      </IconLabelButton>
      {showModal && (
        <>
          <AttachModal sourceId={sourceId} activitySource={activitySource} onClose={handleCloseShowModal} open />
        </>
      )}
    </>
  );
}

Attachments.propTypes = {
  sourceId: PropTypes.number.isRequired,
  activitySource: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
