import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Set } from 'immutable';
import { Box, makeStyles } from '@material-ui/core';
import { BodyBigText, BodySmallText, BodyText, Link, Select } from '@elromcoinc/react-shared';
import getActivityIcon from './getActivityIcon';

const useStyles = makeStyles((theme) => ({
  listLog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '& > div:nth-child(2)': {
      paddingTop: '8px',
    },
    '& > div + div': {
      paddingTop: 0,
    },
  },
  listHeader: {
    minWidth: 'calc(100% - 2rem)',
    padding: '4px 16px',
    backgroundColor: theme.palette.grey[200],
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > div > div > div': {
      backgroundColor: 'transparent !important',
    },
  },
  listItem: {
    width: '100%',
    padding: '8px 0',
    marginBottom: 0,
    position: 'relative',
    '& > p': {
      marginRight: '24px',
    },
  },
  message: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: '5px',
    padding: '8px 12px',
    '& > p:last-child': {
      textAlign: 'right',
      marginTop: '6px',
    },
  },
}));

function TitleWithDesc({ label, desc }) {
  return (
    <Box display="flex">
      <BodyBigText>
        <b>{label}</b>
      </BodyBigText>
      {desc && <BodyText>{`(${desc})`}</BodyText>}
    </Box>
  );
}

TitleWithDesc.propTypes = {
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
};

TitleWithDesc.defaultProps = {
  desc: null,
};

function Message({ triggeredBy, desc, dateTime, activityType }) {
  const classes = useStyles();
  return (
    <Box className={classes.message}>
      <Box display="flex" alignItems="center">
        <Box marginRight="4px" display="flex">
          {getActivityIcon(activityType)}
        </Box>
        <TitleWithDesc label={triggeredBy} />
      </Box>
      <Box display="flex" justifyContent="flex-end" flexWrap="wrap" alignItems="center">
        <Box width="100%">
          <BodyText wordBreak>{desc}</BodyText>
        </Box>
        <BodySmallText>
          &nbsp;
          {format(dateTime, 'Ppp')}
        </BodySmallText>
      </Box>
    </Box>
  );
}

Message.propTypes = {
  triggeredBy: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  activityType: PropTypes.string.isRequired,
  dateTime: PropTypes.instanceOf(Date).isRequired,
};

export default function OrderLogView({ orderLogs, onExpand, expanded }) {
  const classes = useStyles();
  const [type, setType] = useState(0);
  const processedLogs = orderLogs.filter((it) => it.status === 'PROCESSED');
  const filteredLog = type ? processedLogs.filter(({ activityType }) => activityType === type) : processedLogs;
  const NUMBER_OF_LOGS_TO_DISPLAY = expanded ? Number.MAX_SAFE_INTEGER : 6;
  const isEmpty = filteredLog.size === 0;
  const itemsToRender = filteredLog.slice(0, NUMBER_OF_LOGS_TO_DISPLAY);
  return (
    <Box className={classes.listLog}>
      <Box className={classes.listHeader}>
        <Box className={classes.selectContainer}>
          <Box pl={1} width={100}>
            <Select
              label="Display"
              fullWidth
              onChange={({ target: { value } }) => setType(value)}
              value={type}
              options={Set(processedLogs.map(({ activityType }) => activityType)).reduce(
                (acc, activityType) => [...acc, [activityType, activityType]],
                [[0, 'All Types']],
              )}
              size="small"
            />
          </Box>
        </Box>
      </Box>
      {itemsToRender.map((item) => (
        <Box className={classes.listItem} mb={1.5}>
          <Message {...item.toJS()} />
        </Box>
      ))}
      {isEmpty && (
        <Box className={classes.listItem} mb={1.5}>
          <BodyText>There are no any logs to display.</BodyText>
        </Box>
      )}
      {filteredLog.size > NUMBER_OF_LOGS_TO_DISPLAY && (
        <Box component="span" mt={0.5} pl={1} width="100%" boxSizing="border-box">
          <Link size="small" onClick={onExpand}>{`+ ${processedLogs.size - NUMBER_OF_LOGS_TO_DISPLAY} more`}</Link>
        </Box>
      )}
    </Box>
  );
}

OrderLogView.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  orderLogs: PropTypes.arrayOf(PropTypes.any),
};

OrderLogView.defaultProps = {
  orderLogs: null,
};
