import React from 'react';

import { BodyText, Link, formatCurrency } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { OrderFiltersProps } from 'admin/components/Reports/GeneralStatisticsOrdersModal';
import { SalesByEmployeeStats } from 'admin/components/Reports/SalesByEmployeeTab/SalesByEmployeeTab';
import { DataTableColumnAttributes, DataTableColumnNames, renderBoundaryValues } from 'admin/components/Reports/config';
import { toDateString } from 'admin/utils/DateUtils';
import { DateRange } from 'common-types/statistic/DateRange';

const totalsEmployeeId = -1;
const isTotalEmployeeId = (employeeId: number) => employeeId === totalsEmployeeId;

interface EmployeeColumnProps {
  setSelectedOrderFilters: (filters: Partial<OrderFiltersProps> | null) => void;
  isMobile?: boolean;
  dateRange?: DateRange;
}

const makeSalesByEmployeeColumns = (
  rowsData: SalesByEmployeeStats[],
  { setSelectedOrderFilters, isMobile, dateRange }: EmployeeColumnProps,
) => [
  {
    name: DataTableColumnAttributes.NAME,
    label: DataTableColumnNames[DataTableColumnAttributes.NAME],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { employeeName, employeeId } = rowsData[rowIndex];
        const isTotal = isTotalEmployeeId(employeeId);
        return (
          <Box
            className={isTotal ? 'total-column' : ''}
            style={{ textTransform: 'capitalize' }}
            minWidth={isMobile ? '100px' : 'inherit'}
          >
            <BodyText>{employeeName}</BodyText>
          </Box>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersInPeriod, employeeId } = rowsData[rowIndex];
        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalOrdersInPeriod > 0 && employeeId) {
            setSelectedOrderFilters({ 'assignedTo.id': employeeId });
          }
        };

        if (isTotalEmployeeId(employeeId)) {
          return (
            <BodyText className="total-column">
              <b>{totalOrdersInPeriod}</b>
            </BodyText>
          );
        }

        return (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalOrdersInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_ESTIMATE_MIN,
    label: DataTableColumnNames[DataTableColumnAttributes.TOTAL_ESTIMATE_MIN],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalEstimateMin, totalEstimateMax } = rowsData[rowIndex];
        return <BodyText>{renderBoundaryValues(totalEstimateMin, totalEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_JOBS,
    label: DataTableColumnNames[DataTableColumnAttributes.BOOKED_JOBS],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedInPeriod, employeeId } = rowsData[rowIndex];
        const handleClick = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalBookedInPeriod > 0) {
            setSelectedOrderFilters({
              'assignedTo.id': employeeId,
              status: ['BOOKED'],
              created: [toDateString(dateRange?.startDate), toDateString(dateRange?.endDate)],
            });
          }
        };
        return (
          <Link onClick={handleClick} underline="none">
            <BodyText>
              <b>{totalBookedInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_MIN,
    label: DataTableColumnNames[DataTableColumnAttributes.BOOKED_MIN],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedEstimateMin, totalBookedEstimateMax } = rowsData[rowIndex];
        return <BodyText>{renderBoundaryValues(totalBookedEstimateMin, totalBookedEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.LOST_JOBS,
    label: DataTableColumnNames[DataTableColumnAttributes.LOST_JOBS],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalLostOrdersInPeriod, employeeId } = rowsData[rowIndex];
        const handleClick = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalLostOrdersInPeriod > 0) {
            setSelectedOrderFilters({
              'assignedTo.id': employeeId,
              status: ['CANCELLED', 'ARCHIVED', 'BAD_LEAD', 'DEAD_LEAD'],
            });
          }
        };
        return (
          <Link onClick={handleClick} underline="none">
            <BodyText>
              <b>{totalLostOrdersInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_CLOSING,
    label: DataTableColumnNames[DataTableColumnAttributes.TOTAL_CLOSING],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { closingTotal } = rowsData[rowIndex];
        return <BodyText>{`$${formatCurrency(closingTotal, 2)}`}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.CLOSING_GAP,
    label: DataTableColumnNames[DataTableColumnAttributes.CLOSING_GAP],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { closingGap } = rowsData[rowIndex];
        const { humanReadable } = closingGap;
        return <BodyText>{humanReadable}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.VALUATION_SOLD,
    label: DataTableColumnNames[DataTableColumnAttributes.VALUATION_SOLD],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalValuation } = rowsData[rowIndex];
        return (
          <BodyText>{totalValuation ? `$${formatCurrency(totalValuation, 2)}` : `$${formatCurrency(0, 2)}`}</BodyText>
        );
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.PACKING_SOLD,
    label: DataTableColumnNames[DataTableColumnAttributes.PACKING_SOLD],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalPackingChargesInPeriod } = rowsData[rowIndex];
        return (
          <BodyText>
            {totalPackingChargesInPeriod
              ? `$${formatCurrency(totalPackingChargesInPeriod, 2)}`
              : `$${formatCurrency(0, 2)}`}
          </BodyText>
        );
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.COMMISSION_PAID,
    label: DataTableColumnNames[DataTableColumnAttributes.COMMISSION_PAID],
    options: {
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.CONVERSION,
    label: DataTableColumnNames[DataTableColumnAttributes.CONVERSION],
    options: {
      customBodyRender: (_: SalesByEmployeeStats[], { rowIndex }: { rowIndex: number }) => {
        const { conversionRateForPeriod } = rowsData[rowIndex];
        const { asPercentage } = conversionRateForPeriod;
        return <BodyText>{asPercentage}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makeSalesByEmployeeColumns };
