import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { BodyText, SurveyStatusTypeName, SurveyTypeName } from '@elromcoinc/react-shared';
import { formatTime } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import pt from 'prop-types';

export const EstimationTooltip = ({ work, toAddress, endTime, estimatorId }) => {
  const {
    status,
    totalDuration = '',
    serviceType,
    startTime = '',
    orderFirstName,
    orderLastName,
    orderNumber,
    orderId,
    orderServiceType,
  } = work;

  return (
    <Box p={1.5} key={estimatorId}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <BodyText>Order ID:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{orderId}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Order Number:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{`#${orderNumber}`}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Order Name:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <Box style={{ textTransform: 'capitalize' }}>
            <BodyText>
              <b>{`${orderFirstName} ${orderLastName}`}</b>
            </BodyText>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Service Type:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{serviceType.name}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Estimation Type:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{SurveyTypeName[orderServiceType]}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Start Time Window:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{`${formatTime(startTime)}${endTime ? `- ${formatTime(endTime)}` : ''}`}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Total Duration:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{totalDuration}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>Status:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{SurveyStatusTypeName[status]}</b>
          </BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>To Address:</BodyText>
        </Grid>
        <Grid item xs={6}>
          <BodyText>
            <b>{toAddress}</b>
          </BodyText>
        </Grid>
      </Grid>
    </Box>
  );
};

EstimationTooltip.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  work: pt.object.isRequired,
  endTimeFormatter: pt.string,
  toAddress: pt.string,
  estimatorId: pt.number.isRequired,
};

EstimationTooltip.defaultProps = {
  endTimeFormatter: null,
  toAddress: null,
};
