import React from 'react';

import { BodyText, OrderStatuses, Select } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import pt from 'prop-types';

import { ALL_STATUSES, AccountsLabels } from 'admin/components/Accounts/AccountsLabels';
import { allNewStatus } from 'admin/constants/OrderStatus';

export const SelectStatuses = ({ currentStatus, setCurrentStatus, disabled }) => {
  const handleChangeStatus = ({ target: { value } }) => {
    setCurrentStatus(value);
  };

  const statusesList = OrderStatuses.filter((statusItem) => !allNewStatus.includes(statusItem.id) && statusItem).map(
    (item) => [
      item.id,
      <Box color={item.color}>
        <BodyText>{`${item.label}`}</BodyText>
      </Box>,
    ],
  );

  return (
    <Select
      options={statusesList}
      fullWidth
      value={currentStatus || []}
      name={ALL_STATUSES}
      allowToClear
      onChange={handleChangeStatus}
      primaryBackgroundOnSelectedItem
      disabled={disabled}
      defaultValue={[]}
      data-testid="selectStatus"
      size="small"
      label={AccountsLabels[ALL_STATUSES]}
      SelectProps={{
        multiple: true,
        renderValue: (selected) =>
          OrderStatuses.filter((item) => selected.includes(item.id)).map((it, index, arr) => (
            <>
              <Box color={it.color} component="span" key={it.id}>
                <BodyText display="inline">{it.label}</BodyText>
              </Box>
              {index < arr.length - 1 ? ',' : ''}
              &nbsp;
            </>
          )),
      }}
      InputProps={{
        disableUnderline: true,
      }}
    />
  );
};

SelectStatuses.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentStatus: pt.array,
  setCurrentStatus: pt.func,
  disabled: pt.bool,
};

SelectStatuses.defaultProps = {
  currentStatus: null,
  setCurrentStatus: null,
  disabled: false,
};
