import React, { useState } from 'react';
import pt from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { SALES_PERSON } from 'admin/components/Accounts/AccountsLabels';
import { BodyText, ElromcoCircularProgress, Modal, Radio, RadioGroup } from '@elromcoinc/react-shared';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import orderApi from 'admin/api/OrderAPI';

const schema = object().shape({
  [SALES_PERSON]: string().label('Sales person').nullable().required('Please select one manager from the list'),
});

export const AssignSalesPersonModal = ({ title, onClose, onSave, rowsChecked, orderIds, source, isFlight }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const salesPersonOptions = useSelector(getManagerList).toJS();

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChangeSalesPerson = (data) => {
    if (source === 'order(s)') {
      setIsProcessing(true);
      const assignedTo = salesPersonOptions.find(({ id }) => id === +data.manager);

      Promise.all(orderIds.map((orderId) => orderApi.assignEmployee(orderId, assignedTo.id)))
        .then(() => {
          onSave && onSave();
          enqueueSnackbar('Employee assigned successfully', { variant: 'success' });
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(`Can't update employee`, { variant: 'error' });
        })
        .then(() => {
          setIsProcessing(false);
        });
    } else {
      onSave(data);
    }
  };

  return (
    <Modal
      onClose={onClose}
      title={title}
      open
      hideActionControls={isProcessing || isFlight}
      fullWidth
      maxWidth="xs"
      actions={[
        { label: 'cancel', onClick: onClose },
        {
          label: 'save',
          onClick: handleSubmit(handleChangeSalesPerson),
        },
      ]}
    >
      {isProcessing || isFlight ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <BodyText color="primary">
            <b>
              Assigning a sales person for {rowsChecked.length} {source}...
            </b>
          </BodyText>
          <Box pt={1}>
            <ElromcoCircularProgress />
          </Box>
        </Box>
      ) : (
        <RadioGroup aria-label="sales person list" name={SALES_PERSON} control={control}>
          {salesPersonOptions.map((item, index) => (
            <Radio
              key={item.id}
              value={item.id.toString()}
              label={`${item.firstName} ${item.lastName}`}
              variant="checkbox"
              sameUncheckedColor
              color="primary"
              autoFocus={index === 0}
            />
          ))}
        </RadioGroup>
      )}
    </Modal>
  );
};

AssignSalesPersonModal.propTypes = {
  title: pt.string.isRequired,
  onClose: pt.func.isRequired,
  onSave: pt.func.isRequired,
  isProcessing: pt.bool,
  rowsChecked: pt.arrayOf(pt.number),
  source: pt.string,
  orderIds: pt.arrayOf(pt.number),
  isFlight: pt.bool,
  onChangeAccountSalePerson: pt.func,
};

AssignSalesPersonModal.defaultProps = {
  isProcessing: false,
  rowsChecked: [],
  source: null,
  orderIds: [],
  isFlight: false,
  onChangeAccountSalePerson: null,
};
