import React, { useState } from 'react';

import { Link, Order } from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { Taxes as TaxesModal } from 'admin/components/OrderWindow/modals/Taxes';
import { TaxInfo } from 'common-types/TaxInfo';

enum Modals {
  TAXES = 'TAXES',
}

export const Taxes = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('taxInfo');
  const taxInfo = order.getIn(name.split('.')) as TaxInfo;
  const { isClosing } = useOrderClosingContext();

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <HighlightedTableRow data-testId="taxes">
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.TAXES)}>
          Taxes
        </Link>
        {Modals.TAXES === openModal && <TaxesModal open onClose={toggleOpenModal(null)} taxInfo={taxInfo} />}
      </TableCell>
      <TableCell>
        <Text
          value={(isClosing ? order.closingOrderDetail : order)
            .getTaxesTotal(serviceIndex, isSelectedAllServices)
            ?.asHumanReadableString()}
          data-testId={'taxesValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
