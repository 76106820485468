import axios from 'axios';

export const SearchDomains = Object.freeze({
  ALL: 'ALL',
  ORDERS: 'ORDERS',
  ACCOUNTS: 'ACCOUNTS',
});

interface SearchApiResult {
  orders: PageDTO<{
    orderId: number;
    sourceId: number;
    orderNumber: string;
    contactInfo: {
      firstName: string;
      lastName: string;
    };
  }>;
  accounts: PageDTO<{
    id: number;
    contactName: string;
  }>;
}

function searchApi(
  searchTerm: string,
  domain: keyof typeof SearchDomains = SearchDomains.ALL,
  pageIndex = 0,
  pageSize = 5,
  sortBy = 'id',
  sortOrder = 'desc',
) {
  const params = { searchTerm, pageSize, pageIndex, sortOrder, sortBy };
  return axios.get<SearchApiResult, SearchApiResult>(`/api/moving/search/domain/${domain}/`, { params });
}

export default searchApi;
