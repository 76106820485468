import React, { useState } from 'react';

import { Button } from '@elromcoinc/react-shared';
import { Box, Menu, MenuItem } from '@material-ui/core';

const Selector = ({ value, onSelect, adjust = null, options = [], disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = ({ target }) => setAnchorEl(target);
  const handleTimeSelect = (time) => () => {
    onSelect(time);
    setAnchorEl(null);
  };

  const timeOptionsToRender = adjust
    ? options.reduce((acc, [key, label]) => (acc.length || key === adjust ? [...acc, [key, label]] : acc), [])
    : options;

  const valueToRender = timeOptionsToRender.filter(([key]) => key === value)[0];
  return (
    <>
      <Box display="inline">
        <Button size="small" variant="text" display="inline" onClick={handleClick} disabled={disabled}>
          {valueToRender ? valueToRender[1] : 'N/A'}
        </Button>
      </Box>
      {Boolean(anchorEl) && (
        <Menu id="time-menu" anchorEl={anchorEl} keepMounted open onClose={handleClose}>
          {timeOptionsToRender.map(([key, label]) => (
            <MenuItem button selected={key === value} key={key} onClick={handleTimeSelect(key)}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default Selector;
