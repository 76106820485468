import React from 'react';

import { BodyBigText, TitleText } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns';

import { SpacerColumn, TruckRow } from 'admin/components/OrderWindow/SchedulerBox/common';

const useStyles = makeStyles((theme) => ({
  dayCell: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
      marginTop: '2px',
    },
  },
}));

export const renderDay = (day, idx) => (estimators) => {
  const classes = useStyles();
  const key = format(day, 'd');
  const height = estimators.length < 3 ? 3 : estimators.length - 1;

  return (
    <TruckRow
      key={key}
      heightSize={estimators.length === 1 ? 1 : height}
      dense
      grey={idx % 2 === 0}
      className={classes.dayCell}
    >
      <SpacerColumn $width={100}>
        <BodyBigText>
          <b>{format(day, 'EEEE')}</b>
        </BodyBigText>
        <TitleText>{format(day, 'd')}</TitleText>
      </SpacerColumn>
    </TruckRow>
  );
};
