import React, { useContext, useState } from 'react';

import {
  AccountsPermissions,
  BodyText,
  Countries,
  Link,
  PhoneCarrierIcon,
  formatPhoneNumber,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Grid, Tooltip, makeStyles } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import FaceIcon from '@material-ui/icons/Face';

import { AdditionalInfoView } from 'admin/components/AccountWindow/components';
import { SummaryAccountContext } from 'admin/components/AccountWindow/config';
import { CustomerInformationModal } from 'admin/components/AccountWindow/modals';
import { useUnsubscribeMessage } from 'admin/components/OrderWindow/context';
import { ComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail';
import { AccountTypeName } from 'admin/constants/AccountType';
import Card from 'common/components/Card';

const useStyles = makeStyles(({ spacing }) => ({
  email: {
    lineHeight: '1rem',
    paddingRight: '10px',
    marginRight: '10px',
  },
  indicatorIcon: {
    position: 'absolute',
    left: spacing(13),
    top: '-2px',
  },
}));

const getStateOrProvince = (country) => {
  if (country === Countries.CA) {
    return 'Province';
  } else {
    return 'State';
  }
};

export const AccountOverview = ({ ...rest }) => {
  const { customer, setCustomer } = useContext(SummaryAccountContext);
  const {
    id,
    name,
    ownerCustomerInfo,
    type,
    additionalContactFirstName,
    additionalContactLastName,
    additionalContactPhoneNumber,
    additionalContactEmail,
    contactBestTime,
    contactBestWay,
    additionalInfo,
  } = customer;
  const classes = useStyles();
  const { firstName, lastName, email, primaryPhone, address } = ownerCustomerInfo;
  const [openModal, setOpenModal] = useState(null);
  const [openEmailModal, setOpenEmailModal] = useState(null);
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);
  const { isUnsubscribe } = useUnsubscribeMessage();

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleSendEmail = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(null);

  const onSubmit = (data) => {
    setCustomer(data);
  };

  return (
    <Box mt={1}>
      {openEmailModal && (
        <ComposeEmail onCancel={handleCloseEmailModal} onSave={handleCloseEmailModal} open email={email} />
      )}
      <Card
        mode="EDIT"
        title="Account Overview"
        dataTestId="editAccountOverviewButton"
        icon={<FaceIcon />}
        disabled={!canEditOtherUsersAccounts}
        onAction={handleOpenModal}
        {...rest}
      >
        <Box my={1} ml={4}>
          <Grid container spacing={2}>
            <Grid item lg={2} xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={4} lg={3}>
                  <BodyText>ID:</BodyText>
                </Grid>
                <Grid item xs={8} lg={9}>
                  <BodyText>
                    <b>{id}</b>
                  </BodyText>
                </Grid>
                <Grid item xs={4} lg={3}>
                  <BodyText>Type:</BodyText>
                </Grid>
                <Grid item xs={8} lg={9}>
                  <BodyText data-testid={`currentAccountType-${AccountTypeName[type]}`}>
                    <b>{AccountTypeName[type]}</b>
                  </BodyText>
                </Grid>
                <Grid item xs={4} lg={3}>
                  <BodyText>Name:</BodyText>
                </Grid>
                <Grid item xs={8} lg={9}>
                  <Box style={{ textTransform: 'capitalize' }} maxWidth={200}>
                    <BodyText wordBreak noWrap>
                      <b data-testid={`currentAccountName-${name}`}>{name || 'None'}</b>
                    </BodyText>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {address && (
              <Grid item lg={4} xs={12} sm={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4} lg={3}>
                    <BodyText>Address:</BodyText>
                  </Grid>
                  <Grid item xs={8} lg={9}>
                    <BodyText data-testid="ownerCustomerInfo.address.street1">
                      <b>{address.street1}</b>
                    </BodyText>
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <BodyText>City:</BodyText>
                  </Grid>
                  <Grid item xs={8} lg={9}>
                    <BodyText data-testid="ownerCustomerInfo.address.city">
                      <b>{address.city}</b>
                    </BodyText>
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <BodyText data-testid="ownerCustomerInfo.address.country">
                      {getStateOrProvince(address.country)}
                    </BodyText>
                  </Grid>
                  <Grid item xs={8} lg={9}>
                    <BodyText data-testid="ownerCustomerInfo.address.state">
                      <b>{address.state}</b>
                    </BodyText>
                  </Grid>
                  <Grid item xs={4} lg={3}>
                    <BodyText>Zip:</BodyText>
                  </Grid>
                  <Grid item xs={8} lg={9}>
                    <BodyText data-testid="ownerCustomerInfo.address.postalCode">
                      <b>{address.postalCode}</b>
                    </BodyText>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item lg={4} xs={12} sm={6}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4}>
                  <BodyText>Contact Name:</BodyText>
                </Grid>
                <Grid item xs={8}>
                  <Box style={{ textTransform: 'capitalize' }}>
                    <BodyText data-testid="ownerFullName">
                      <b>{`${firstName} ${lastName}`}</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <BodyText>Email:</BodyText>
                </Grid>
                <Grid item xs={8}>
                  <Tooltip title={email}>
                    <Box component="span" display="flex">
                      <Link onClick={handleSendEmail}>
                        <Box maxWidth={200}>
                          <BodyText data-testid="ownerEmail" className={classes.email} noWrap>
                            <b>{email}</b>
                          </BodyText>
                        </Box>
                      </Link>
                    </Box>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <BodyText>Phone:</BodyText>
                </Grid>
                <Grid item xs={8}>
                  <Box position="relative">
                    <BodyText data-testid="ownerPhoneNumber">
                      <b>
                        <a href={`tel:${formatPhoneNumber(primaryPhone.number)}`}>
                          {formatPhoneNumber(primaryPhone.number)}
                        </a>
                      </b>
                    </BodyText>
                    {!!primaryPhone.number && (
                      <>
                        <Box className={classes.indicatorIcon}>
                          <PhoneCarrierIcon
                            carrierType={primaryPhone.phoneCarrierType}
                            smsCapability={primaryPhone.smsCapability}
                          />
                        </Box>
                        {isUnsubscribe && primaryPhone && (
                          <Box className={classes.indicatorIcon}>
                            <Tooltip title="Customer has been unsubscribed">
                              <BlockIcon fontSize="small" color="error" />
                            </Tooltip>
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <BodyText>Best Contact:</BodyText>
                </Grid>
                <Grid item xs={8}>
                  <BodyText data-testid="ownerBestWayTime">
                    <b>{contactBestWay || 'None'}</b>
                    <b>{contactBestTime ? ` / ${contactBestTime}` : null}</b>
                  </BodyText>
                </Grid>
                <Grid item xs={4}>
                  <BodyText>Info:</BodyText>
                </Grid>
                <Grid item xs={8} data-testid="ownerAdditionalInfo">
                  {additionalInfo && additionalInfo.length > 50 ? (
                    <AdditionalInfoView onSubmit={onSubmit} customer={customer} />
                  ) : (
                    <Box maxWidth={200}>
                      <BodyText wordBreak>
                        <b>{additionalInfo || 'None'}</b>
                      </BodyText>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {additionalContactFirstName &&
              additionalContactLastName &&
              additionalContactEmail &&
              additionalContactPhoneNumber && (
                <Grid item lg={2} xs={12} sm={6} ml={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <BodyText>
                        <b>Additional Contact</b>
                      </BodyText>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <BodyText>Name:</BodyText>
                    </Grid>
                    <Grid item xs={8} lg={9}>
                      <Box style={{ textTransform: 'capitalize' }} pr="10px" mr="10px" maxWidth="150px">
                        <BodyText noWrap data-testid="ownerAdditionalContactFullName">
                          <b>{`${additionalContactFirstName} ${additionalContactLastName}`}</b>
                        </BodyText>
                      </Box>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <BodyText>Phone:</BodyText>
                    </Grid>
                    <Grid item xs={8} lg={9}>
                      <BodyText data-testid="ownerAdditionalContactPhoneNumber">
                        <b>
                          <a href={`tel:${formatPhoneNumber(additionalContactPhoneNumber)}`}>
                            {formatPhoneNumber(additionalContactPhoneNumber)}
                          </a>
                        </b>
                      </BodyText>
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <BodyText>Email:</BodyText>
                    </Grid>
                    <Grid item xs={8} lg={9}>
                      <Tooltip title={additionalContactEmail}>
                        <Box component="span" display="flex">
                          <Link onClick={handleSendEmail}>
                            <Box maxWidth={150}>
                              <BodyText className={classes.email} noWrap data-testid="ownerAdditionalContactEmail">
                                <b>{additionalContactEmail}</b>
                              </BodyText>
                            </Box>
                          </Link>
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Box>
        {openModal && (
          <CustomerInformationModal
            handleCloseModal={handleCloseModal}
            onSubmit={onSubmit}
            customer={customer}
            setCustomer={setCustomer}
            open
          />
        )}
      </Card>
    </Box>
  );
};
