import React, { ChangeEvent, useEffect, useState } from 'react';

import { Order, roundNumberToFixedDigits } from '@elromcoinc/react-shared';

import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { getServicePropertyName, getServiceRosterClosingPropertyName } from 'admin/utils';

import { RemoveManualChangesButton } from './RemoveManualChangesButton';

const propertyName = 'manualGrandTotal';

export const GrandTotalLongDistanceInput = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail : order)!;
  const service = orderDetail.services.get(serviceIndex);
  const { quote } = service;
  const serviceGrandTotalMaxValue = roundNumberToFixedDigits(orderDetail.getServiceGrandTotal(serviceIndex).maxValue);
  const defaultValue = quote?.manualGrandTotal || serviceGrandTotalMaxValue;
  const [grandTotalManual, setGrandTotalManual] = useState<string | number>(defaultValue);

  useEffect(() => {
    setGrandTotalManual(defaultValue);
  }, [quote?.manualGrandTotal, serviceGrandTotalMaxValue]);

  const handleOnBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;
    if (+defaultValue !== +value) {
      onChange({ name, value });
    }
  };

  const name = isClosing
    ? getServiceRosterClosingPropertyName(serviceIndex, propertyName)
    : getServicePropertyName(serviceIndex, propertyName);

  const onChangeGrandTotal = (grandTotal: string) => setGrandTotalManual(grandTotal);

  const onCancel = () => {
    setGrandTotalManual(serviceGrandTotalMaxValue);
    onChange({ name, value: null });
  };

  return (
    <RemoveManualChangesButton manual={quote?.manualGrandTotal} onClick={onCancel}>
      <CurrencyInput
        value={grandTotalManual}
        onChange={onChangeGrandTotal}
        onBlur={handleOnBlur(+grandTotalManual)}
        name={name}
      />
    </RemoveManualChangesButton>
  );
};
