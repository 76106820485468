import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import {
  BodyText,
  Button,
  ElromcoCircularProgress,
  EstimateTypeName,
  GeneralService,
  HeaderText,
  LaborType,
  MoveTypeName,
  MoveTypeTypes,
  Order,
  Select,
  SettingNames,
  TextInput,
  Waypoint,
  WaypointInsertMode,
  isLocalMove,
  isLongDistanceService,
  isMovingAndStorageService,
  isOvernightService,
  toBackEndDate,
  toDate,
  useConfirm,
} from '@elromcoinc/react-shared';
import {
  Box,
  ListItem,
  ListItemText,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import clsx from 'clsx';
import { add } from 'date-fns';
import { List, Map, getIn } from 'immutable';
import { useSnackbar } from 'notistack';

import orderApi from 'admin/api/OrderAPI';
import {
  ESTIMATE_TYPE,
  GENERAL_SERVICE,
  MOVE_SIZE,
  MOVE_TYPE,
  QUOTE,
  SERVICES,
} from 'admin/components/OrderWindow/OrderWindowLabels';
import MoveSizeRow from 'admin/components/OrderWindow/blocks/MoveSizeRow';
import { MultiDayAllDayMode } from 'admin/components/OrderWindow/blocks/MultiDayAllDayMode';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useValidateStorageOnBe,
} from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import { EditOrderDetailsModal } from 'admin/components/OrderWindow/modals/EditOrderDetailsModal';
import FullAddressesModal from 'admin/components/OrderWindow/modals/FullAddressesModal/FullAddressesModal';
import { SecondDateModal } from 'admin/components/OrderWindow/modals/SecondDateModal';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { THIS_FUNCTIONAL_IS_COMING_SOON } from 'admin/constants';
import GeneralServiceTypes from 'admin/constants/GeneralServiceTypes';
import { longDistanceMethodOptions } from 'admin/constants/LongDistanceMethod';
import { UnitSizeSpec } from 'common-types';
import Card from 'common/components/Card';
import { FullWidthBox, OverviewInfoTable, WhiteInput } from 'common/components/Widgets';

import { FlexWithNegativeMargin, ValueTableCell } from './CommonComponents';
import SalesLead from './SalesLead';

const useStyles = makeStyles((theme) => ({
  roomsHighlight: {
    borderRadius: 0,
    backgroundColor: theme.palette.action.hover,
  },
  tableCell: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  table: {
    '& tbody tr td': {
      height: theme.spacing(2.5),
    },
    '& tbody tr td input, & tbody tr td .MuiSelect-root': {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  },
  warning: {
    color: theme.palette.error.main,
    fontSize: '2rem',
  },
  warningMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  errorIcon: {
    fontSize: '4rem',
  },
}));

const { ACCEPT_RESIDENTIAL_ORDERS, ACCEPT_COMMERCIAL_ORDERS, ACCEPT_MILITARY_ORDERS, GENERAL_SERVICES, OVERNIGHT } =
  SettingNames;

const moveTypeKeys = [ACCEPT_RESIDENTIAL_ORDERS, ACCEPT_COMMERCIAL_ORDERS, ACCEPT_MILITARY_ORDERS];

const estimateTypesOptions = Object.entries(EstimateTypeName);

const extractServiceType = (
  order: Order,
  serviceIndex: number,
  settings: ReturnType<typeof useOrderWindowSettings>,
  inEdit = false,
) => {
  const { generalService, serviceType } = order.getServiceQuote(serviceIndex);
  const serviceTypes = (settings && settings[GENERAL_SERVICES]) || [];
  const serviceTypeObject =
    serviceTypes.filter((it) =>
      generalService.id !== null ? it.id === generalService.id : it.serviceType === serviceType,
    )[0] || generalService;
  return inEdit ? serviceTypeObject.id : serviceTypeObject.name;
};

const extractMoveType = (
  order: Order,
  serviceIndex: number,
  settings: ReturnType<typeof useOrderWindowSettings>,
  inEdit = false,
) => {
  const { moveType } = order.getServiceQuote(serviceIndex);
  return inEdit ? moveType : MoveTypeName[moveType as MoveTypeTypes];
};

enum Modals {
  ORDER_DETAILS = 0,
}

const twoAddressesServiceTypes = [isLocalMove, isMovingAndStorageService, isOvernightService, isLongDistanceService];

const firstServiceDatePath = [SERVICES, 0, 'date'];

interface OrderDetailsProps {
  width: string;
  order: Order;
  onChange: (change: { name: string; value: any }) => void;
}

const OrderDetails: FC<OrderDetailsProps> = ({ order, onChange, ...rest }) => {
  const settings = useOrderWindowSettings();
  const { changeSet, showSaveDialog, fetchOrderInfo } = useOrderChangeSet();
  const maxOvernigthDaysCount = settings?.[OVERNIGHT.MAX_DAYS[order.moveType as MoveTypeTypes]] ?? 1;
  const changeSetRef = useRef(changeSet);
  changeSetRef.current = changeSet;
  const { serviceIndex } = useOrderServiceIndex();
  const classes = useStyles();
  const service = order.services.get(serviceIndex);
  const { quote } = service;
  const { longDistanceTariffDetails } = quote;
  const isLongDistance = isLongDistanceService(service.getType());
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const [roomsSelectOpen, setRoomsSelectOpen] = useState(false);
  const [secondaryId, setSecondaryId] = useState(order.secondaryId);
  const { enqueueSnackbar } = useSnackbar();
  const { closingTitle, isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const defaultGeneralServicesOptions = [{ id: quote.generalService.id, name: service.serviceTypeName }];
  const {
    currentSite,
    setCurrentMoveSite,
    getMoveSizeAndDescription,
    siteOptions,
    currentRooms,
    setCurrentRooms,
    roomOptions,
    siteName,
  } = useOrderSizing(order);
  const { validateStorageMoveSizeOnBe } = useValidateStorageOnBe();
  const [error, setError] = useState<Partial<{ secondaryId: string | null }>>({});
  const serviceTypeColor = quote?.generalService.rgbColor;
  const [inFlightChangeServiceType, setInFlightChangeServiceType] = useState(false);
  const { confirm: confirmChangeServiceType, renderDialog: renderChangeServiceTypeDialog } = useConfirm({
    title: <span className={classes.warning}>Warning</span>,
    message: (
      <Box className={classes.warningMessage}>
        <WarningIcon color="error" className={classes.errorIcon} />
        <HeaderText>
          You will lose any added quote details such as materials, additional services, valuation, discounts and taxes.
          Do you wish to confirm?
        </HeaderText>
      </Box>
    ),
    centeredTitle: true,
  });
  const [newWaypointPromise, setNewWaypointPromise] = useState<{ resolve: (v: Waypoint | null) => void } | null>(null);
  const [newWaypointModalProps, setNewWaypointModalProps] = useState<
    Partial<{ isDestination: boolean; isStart: boolean; waypoint: Waypoint }>
  >({});
  const [secondDatePromise, setSecondDatePromise] = useState<{ resolve: (v: string | null) => void } | null>(null);
  const disableInputs = isClosing || isCompleted || isLockSales;

  useEffect(() => {
    setSecondaryId(order.secondaryId);
  }, [order.secondaryId]);

  useEffect(() => {
    setError({
      secondaryId: secondaryId && secondaryId.length > 32 ? 'Secondary ID must be at most 32 characters' : null,
    });
  }, [secondaryId]);

  const serviceOptions =
    settings?.[GENERAL_SERVICES]?.filter((it) => it.serviceType !== GeneralServiceTypes.JUNK_REMOVAL) || [];

  const closeModal = () => {
    setOpenModal(null);
  };

  const handleOrderDetailsSave = (data = []) => {
    data.forEach(([name, value]) => {
      if (name === 'moveSize') {
        validateStorageMoveSizeOnBe(value);
      }
      onChange({ name, value });
    });
    closeModal();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = event;
    if (name.includes(MOVE_TYPE)) {
      enqueueSnackbar('Under construction', { variant: 'warning' });
      return;
    }

    onChange({ name, value });
  };

  const handleChangeServiceType = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value: rawValue, name },
    } = event;

    if (!changeSetRef.current?.isEmpty()) {
      enqueueSnackbar('Please save changes.', { variant: 'warning' });
      showSaveDialog();
      return true;
    }

    let value = GeneralService.createGeneralService(settings[GENERAL_SERVICES].find((it) => it.id === +rawValue));
    const oldServiceType = order.getIn(name.split('.')) as GeneralService;

    const waypoints = order.getServiceWaypoints();

    if (!(await confirmChangeServiceType())) {
      return true;
    }

    let extraWaypoint = null;
    let isNewWaypointStart = false;

    if (
      (!waypoints.some(Waypoint.isUnloading) ||
        !waypoints.some((w) => w.laborType === LaborType.LOADING) ||
        isMovingAndStorageService(oldServiceType?.serviceType)) &&
      twoAddressesServiceTypes.some((checkServiceType) => checkServiceType(value.serviceType)) &&
      !order.get('linkedOrderId') &&
      order.services.size < 2
    ) {
      enqueueSnackbar('Please add second address to change service type', { variant: 'warning' });

      const hasUnloadingWaypoint = !!order.getCustomerWaypoints().find(Waypoint.isUnloading);

      setNewWaypointModalProps((state) => ({
        ...state,
        isDestination: !hasUnloadingWaypoint,
        isStart: hasUnloadingWaypoint,
        waypoint: new Waypoint({ laborType: hasUnloadingWaypoint ? LaborType.LOADING : LaborType.UNLOADING }),
      }));
      extraWaypoint = await new Promise<Waypoint | null>((resolve) => {
        setNewWaypointPromise({ resolve });
      });

      if (!extraWaypoint) {
        return true;
      }
    }

    const date = order.getIn(firstServiceDatePath) as Date;

    let secondServiceDate =
      isOvernightService(value.serviceType) && maxOvernigthDaysCount === 1
        ? toBackEndDate(add(toDate(date)!, { days: 1 }))
        : null;

    if (isOvernightService(value.serviceType) && maxOvernigthDaysCount > 1) {
      secondServiceDate = await new Promise<string | null>((resolve) => {
        setSecondDatePromise({ resolve });
      });

      if (!secondServiceDate) {
        return true;
      }
    }

    if (!value.equals(oldServiceType)) {
      setInFlightChangeServiceType(true);

      const addedWaypoint = extraWaypoint
        ? {
            waypoint: extraWaypoint.toJS(),
            insertMode: isNewWaypointStart ? WaypointInsertMode.FIRST : WaypointInsertMode.LAST,
          }
        : {};

      orderApi
        .updateServiceType(order.orderId, +rawValue, addedWaypoint, secondServiceDate)
        .then(() => {
          return fetchOrderInfo();
        })
        .catch(() => {
          enqueueSnackbar('Problem with updating service type', { variant: 'error' });
        })
        .then(() => {
          setInFlightChangeServiceType(false);
        });
    }
  };

  const renderRooms = (rooms: UnitSizeSpec[]) => {
    if (rooms.length === 0) {
      return <BodyText color="textSecondary">Select Rooms</BodyText>;
    }

    return List(rooms)
      .map((room) => room.name)
      .sort()
      .join(', ');
  };

  const updateSize = (moveSize: number, sizeDescription: string) => {
    setRoomsSelectOpen(false);
    if (moveSize > 0) {
      onChange({ name: 'moveSize', value: moveSize });
      onChange({ name: 'sizeDescription', value: sizeDescription });
      validateStorageMoveSizeOnBe(moveSize);
    }
  };

  const handleMoveSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const [moveSize, sizeDescription] = setCurrentMoveSite(+value);
    updateSize(moveSize, sizeDescription);
  };

  const handleRoomsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setCurrentRooms(Map((value as unknown as UnitSizeSpec[]).filter(Boolean).map((room) => [room.id, room])));
  };

  const handleRoomsDoneClick = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const [moveSize, sizeDescription] = getMoveSizeAndDescription();
    updateSize(moveSize, sizeDescription);
  };

  const handleChangeSecondaryId = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSecondaryId(value);
  };

  const handleBlurSecondaryId = (event: ChangeEvent<HTMLInputElement>) => {
    if (!error['secondaryId']) {
      const {
        target: { name, value },
      } = event;
      onChange({ name, value });
    }
  };

  const handleAddWaypoint = (w: Waypoint | null = null) => {
    newWaypointPromise?.resolve(w);
    setNewWaypointPromise(null);
  };

  const handleChooseSecondDate = (date: string | null = null) => {
    secondDatePromise?.resolve(date);
    setSecondDatePromise(null);
  };

  return (
    <>
      {order && (
        <Card
          {...rest}
          mode="EDIT"
          title={`Order Details${closingTitle}`}
          icon={<AssignmentIcon />}
          className="use-input-fixes"
          onAction={() => setOpenModal(Modals.ORDER_DETAILS)}
        >
          {!disableInputs && <VideoButton position="absolute" video="myhvnFyYvk8" bottom={0} left={2} />}
          <MultiDayAllDayMode />
          <FullWidthBox my={2}>
            {/** @ts-ignore */}
            <OverviewInfoTable firstCellWidth="105px" className={classes.table}>
              <TableBody>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Order ID</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <Box ml="-2px" data-testid="orderIdMoveDetailsValue">
                      <Text value={order.orderNumber} />
                    </Box>
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    size="small"
                    padding="none"
                    className={clsx({ [classes.tableCell]: error['secondaryId'] })}
                  >
                    <BodyText>Secondary ID</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <FlexWithNegativeMargin>
                      <Box ml="-2px">
                        <TextInput
                          hiddenLabel
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={secondaryId}
                          placeholder="Secondary ID"
                          name="secondaryId"
                          onChange={handleChangeSecondaryId}
                          onBlur={handleBlurSecondaryId}
                          className="fix-input-margin"
                          inputProps={{ className: clsx(error['secondaryId'] && 'error') }}
                          formErrors={error}
                          disabled={disableInputs}
                        />
                      </Box>
                    </FlexWithNegativeMargin>
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Branch</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <Box ml="-2px">
                      <Text value={order.companyName} />
                    </Box>
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Move Type</BodyText>
                  </TableCell>
                  <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON} placement="left">
                    <ValueTableCell>
                      <FlexWithNegativeMargin>
                        <Select
                          value={extractMoveType(order, serviceIndex, settings, true)}
                          name={`${SERVICES}.${serviceIndex}.${QUOTE}.${MOVE_TYPE}`}
                          onChange={handleChange}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          fullWidth
                          disabled={disableInputs}
                          hiddenLabel
                        >
                          {Object.entries(MoveTypeName).map(([moveType, label], index) => {
                            return (
                              (settings[moveTypeKeys[index]] || order.moveType === moveType) && (
                                <MenuItem value={moveType} key={moveType} disabled={!settings[moveTypeKeys[index]]}>
                                  {label}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                      </FlexWithNegativeMargin>
                    </ValueTableCell>
                  </Tooltip>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Service Type</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <FlexWithNegativeMargin>
                      <Select
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={extractServiceType(order, serviceIndex, settings, true)}
                        color={serviceTypeColor}
                        name={`${SERVICES}.${serviceIndex}.${QUOTE}.${GENERAL_SERVICE}`}
                        onChange={handleChangeServiceType}
                        disabled={serviceIndex > 0 || inFlightChangeServiceType || disableInputs}
                        options={
                          (serviceOptions || defaultGeneralServicesOptions).map((item) => [
                            item.id,
                            item.name,
                          ]) as SelectOptions
                        }
                        hiddenLabel
                        fullWidth
                      />
                      {inFlightChangeServiceType && <ElromcoCircularProgress />}
                    </FlexWithNegativeMargin>
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Estimate Type</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <FlexWithNegativeMargin>
                      <Select
                        InputProps={{
                          disableUnderline: true,
                        }}
                        value={getIn(order, ['services', serviceIndex, 'quote', 'estimateType'], null)}
                        name={`${SERVICES}.${serviceIndex}.${QUOTE}.${ESTIMATE_TYPE}`}
                        onChange={handleChange}
                        options={estimateTypesOptions}
                        hiddenLabel
                        fullWidth
                        disabled={disableInputs}
                      />
                    </FlexWithNegativeMargin>
                  </ValueTableCell>
                </TableRow>
                {isLongDistance && (
                  <TableRow>
                    <TableCell size="small" padding="none">
                      <BodyText>Tariff Type</BodyText>
                    </TableCell>
                    <ValueTableCell>
                      <FlexWithNegativeMargin>
                        <Select
                          InputProps={{
                            disableUnderline: true,
                          }}
                          value={longDistanceTariffDetails.calculationMethod || 'None'}
                          hiddenLabel
                          fullWidth
                          onChange={onChange}
                          disabled={disableInputs}
                          name={`services.${serviceIndex}.quote.longDistanceTariffDetails.calculationMethod`}
                        >
                          <MenuItem value="None" key="None" disabled style={{ display: 'none' }}>
                            None
                          </MenuItem>
                          {longDistanceMethodOptions.map(([key, label]) => (
                            <MenuItem key={key} value={key}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FlexWithNegativeMargin>
                    </ValueTableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Size of Move</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <FlexWithNegativeMargin>
                      <Select
                        SelectProps={{ renderValue: () => (currentSite && currentSite.name) || siteName }}
                        value={getIn(currentSite, ['id'], siteName)}
                        hiddenLabel
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name={MOVE_SIZE}
                        onBlur={updateSize}
                        onChange={handleMoveSizeChange}
                        options={siteOptions}
                        disabled={disableInputs}
                      />
                    </FlexWithNegativeMargin>
                  </ValueTableCell>
                </TableRow>
                <TableRow>
                  <TableCell size="small" padding="none">
                    <BodyText>Rooms</BodyText>
                  </TableCell>
                  <ValueTableCell>
                    <FlexWithNegativeMargin>
                      <Select
                        value={currentRooms.valueSeq().toArray()}
                        autoFocus
                        hiddenLabel
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                        }}
                        name="rooms"
                        SelectProps={{
                          classes: { root: classes.roomsHighlight },
                          displayEmpty: true,
                          onClose: handleRoomsDoneClick,
                          placeholder: 'Click to select rooms',
                          open: roomsSelectOpen,
                          onOpen: () => setRoomsSelectOpen(true),
                          MenuProps: { variant: 'menu' },
                          multiple: true,
                          renderValue: renderRooms,
                        }}
                        disabled={disableInputs}
                        onChange={handleRoomsChange}
                      >
                        {roomOptions.map((room) => (
                          // @ts-ignore
                          <MenuItem key={room.id} value={room} dense>
                            {/** @ts-ignore */}
                            <WhiteInput type="checkbox" value={currentRooms.has(room.id)} hideBorders={false} />
                            <ListItemText primary={room.name} />
                          </MenuItem>
                        ))}
                        {/** @ts-ignore */}
                        <ListItem dense onClick={handleRoomsDoneClick} value={null}>
                          <Box width="100%" textAlign="right">
                            <Button color="primary" size="small">
                              Done
                            </Button>
                          </Box>
                        </ListItem>
                      </Select>
                    </FlexWithNegativeMargin>
                  </ValueTableCell>
                </TableRow>
                <MoveSizeRow />
              </TableBody>
            </OverviewInfoTable>
          </FullWidthBox>
          <Box>
            <SalesLead onChange={onChange} />
          </Box>
          {openModal === Modals.ORDER_DETAILS && (
            <EditOrderDetailsModal open onSave={handleOrderDetailsSave} data={order} onClose={closeModal} />
          )}
          {renderChangeServiceTypeDialog()}
          {newWaypointPromise && (
            // @ts-ignore
            <FullAddressesModal
              {...newWaypointModalProps}
              onCancel={handleAddWaypoint}
              onSave={handleAddWaypoint}
              open
            />
          )}
          {secondDatePromise && <SecondDateModal onClose={handleChooseSecondDate} onSave={handleChooseSecondDate} />}
        </Card>
      )}
    </>
  );
};

export default OrderDetails;
