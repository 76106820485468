import { UI_DATE_FORMAT_SHORT, UI_LOCALIZED_TIME_FORMAT } from '@elromcoinc/react-shared';
import { format } from 'date-fns';

const getExportedInvoiceName = () => {
  const downloadDate = format(new Date(), UI_DATE_FORMAT_SHORT);
  const downloadTime = format(new Date(), UI_LOCALIZED_TIME_FORMAT);

  return `invoice(s)-from-${downloadDate}-at-${downloadTime}.csv`;
};
export { getExportedInvoiceName };
