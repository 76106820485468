/** @deprecated please use it from react-shared */
const LeadTemperature = Object.freeze({
  COLD: 'COLD',
  WARM: 'WARM',
  HOT: 'HOT',
});

/** @deprecated please use it from react-shared */
const LeadTemperatureOptions = Object.entries({
  [LeadTemperature.COLD]: 'Cold',
  [LeadTemperature.WARM]: 'Warm',
  [LeadTemperature.HOT]: 'Hot',
});

/** @deprecated please use it from react-shared */
const LeadTemperatureColor = Object.freeze({
  [LeadTemperature.COLD]: '#00B0D7',
  [LeadTemperature.WARM]: '#FFD63F',
  [LeadTemperature.HOT]: '#FF783F',
});

export default LeadTemperature;

export { LeadTemperatureColor, LeadTemperatureOptions };
