import { getValue } from 'admin/utils/getValue';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const moverCount = (name: 'numberOfMovers' | 'numberOfDeliveryMovers') =>
  ((fieldPath, oldOrder, newOrder) => {
    if (fieldPath.indexOf(name) === -1) {
      return null;
    }

    const oldValue = getValue(oldOrder.getValueIn(fieldPath))?.toString() ?? '';
    const newValue = getValue(newOrder.getValueIn(fieldPath))?.toString() ?? '';

    if (oldValue === newValue) {
      return null;
    }

    const deliveryLabel = name === 'numberOfDeliveryMovers' ? 'Delivery ' : '';

    return [
      createUpdatedValueDto(
        oldValue,
        newValue,
        `${deliveryLabel}Crew Size`,
        extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
      ),
    ];
  }) as EventProcessor;

export default moverCount;
