import { formatPhoneNumber } from '@elromcoinc/react-shared';
import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import AdditionalContact from 'admin/entities/AdditionalContact';
import { UpdatedValueDto } from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import { ADDITIONAL_CONTACT } from 'admin/constants/FieldNames';
import { EventProcessor } from '../EventProcessor';

const additionalContact: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf(ADDITIONAL_CONTACT) === -1) {
    return null;
  }

  const { firstName, lastName, phoneNumber, email } = oldOrder.additionalContact;
  const {
    firstName: newFirstName,
    lastName: newLastName,
    phoneNumber: newPhone,
    email: newEmail,
  } = newOrder.additionalContact;

  const isOldEmpty = new AdditionalContact().equals(oldOrder.additionalContact);
  const isNewEmpty = new AdditionalContact().equals(newOrder.additionalContact);
  if (isOldEmpty && !isNewEmpty) {
    // looks like new additional contact was added
    return [
      createUpdatedValueDto(
        '',
        `Name: ${newFirstName} ${newLastName} Email: ${newEmail} Phone: ${formatPhoneNumber(newPhone || '')}`,
        'Additional Contact',
      ),
    ];
  }
  if (isNewEmpty && !isOldEmpty) {
    // looks like additional contact was removed
    return [
      createUpdatedValueDto(
        `Name: ${firstName} ${lastName} Email: ${email} Phone: ${formatPhoneNumber(phoneNumber || '')}`,
        '',
        'Additional Contact',
      ),
    ];
  }
  if (!isNewEmpty && !isOldEmpty) {
    const changes: UpdatedValueDto[] = [];
    if (firstName !== newFirstName && lastName !== newLastName) {
      changes.push(
        createUpdatedValueDto(`${firstName} ${lastName}`, `${newFirstName} ${newLastName}`, 'Additional Contact name'),
      );
    } else if (firstName !== newFirstName) {
      changes.push(createUpdatedValueDto(firstName, newFirstName, 'Additional Contact first name'));
    } else if (lastName !== newLastName) {
      changes.push(createUpdatedValueDto(lastName, newLastName, 'Additional Contact last name'));
    }
    if (phoneNumber !== newPhone) {
      changes.push(
        createUpdatedValueDto(
          formatPhoneNumber(phoneNumber),
          formatPhoneNumber(newPhone),
          'Additional Contact phone number',
        ),
      );
    }
    if (email !== newEmail) {
      changes.push(createUpdatedValueDto(email, newEmail, 'Additional Contact email'));
    }
    return changes;
  }
  return null;
};

export default additionalContact;
