import React, { useEffect, useState } from 'react';

import { BACKEND_DATE_FORMAT, Modal, SettingNames, useConfirm } from '@elromcoinc/react-shared';
import { Box, LinearProgress } from '@material-ui/core';
import { add, format, startOfDay } from 'date-fns';
import { Record, set } from 'immutable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import orderApi from 'admin/api/OrderAPI';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import CustomerInformation from 'admin/components/CreateOrder/CustomerInformation';
import MoveDetails from 'admin/components/CreateOrder/MoveDetails';
import OrderDetails from 'admin/components/CreateOrder/OrderDetails';
import { useSendingTemplates } from 'admin/components/OrderWindow/context';
import { sendMessagesToOrder } from 'admin/components/OrderWindow/sendMessagesToOrder';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import useSettingsApi from 'admin/components/Settings/hooks/useSettingsApi';
import { NewOrder } from 'admin/entities/NewOrder';
import {
  getElevationReduction,
  getEnabledLeadSources,
  getEnabledMoveTypes,
  getMoveSizesWithAllTags,
  getParkingDistanceOptions,
  getRates,
  getRooms,
  getServiceOptions,
} from 'admin/selectors';

const {
  GENERAL_SERVICES,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  COMPANY_CALENDAR,
  UNIT_SIZE_SPEC,
  LEAD_SOURCE,
  MOVERS_SPEED_REDUCTION,
  MOVERS_SPEED_PARKING_DISTANCE_REDUCTION,
  MOVE_TYPES,
  OVERNIGHT: { MAX_DAYS },
} = SettingNames;

const settingQueries = [
  GENERAL_SERVICES,
  COMPANY_CALENDAR,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  UNIT_SIZE_SPEC,
  MOVERS_SPEED_REDUCTION,
  LEAD_SOURCE,
  MOVERS_SPEED_PARKING_DISTANCE_REDUCTION,
  ...MOVE_TYPES.map((type) => MAX_DAYS[type]),
  'calculator.quote_display',
];

const CreateOrder = ({ onClose, requestingAccountId }) => {
  const dispatch = useDispatch();
  const { sendingTemplates } = useSendingTemplates();
  const { enqueueSnackbar } = useSnackbar();
  const [step, setStep] = useState(0);
  const [data, setData] = useState(new NewOrder({ requestingAccountId }));
  const [settings] = useSettingsApi(...settingQueries);
  const [inFlight, setInFlight] = useState(false);
  const [inFlightSendingTemplates, setInFlightSendingTemplates] = useState(false);
  const [formCustomerInfo, setFormCustomerInfo] = useState(null);
  const currentEmployee = useSelector(getCurrentEmployee);
  const serviceOptions = getServiceOptions(settings);
  const referredViaOptions = getEnabledLeadSources(settings);
  const moveSizes = getMoveSizesWithAllTags(settings);
  const rooms = getRooms(settings).toJS();
  const elevationReduction = getElevationReduction(settings);
  const parkingDistanceOptions = getParkingDistanceOptions(settings);
  const rates = getRates(settings);
  const { confirm: confirmCreateNewOrder, renderDialog: renderConfirmCreateNewOrder } = useConfirm({
    title: 'Order for existing customer found',
    message: 'Do you want to create one more order for this customer?',
    confirmTitle: 'Yes',
    cancelTitle: 'No. Close dialog',
  });

  const handleNext = (updates, customerInfo) => {
    setData(() => new NewOrder(Record.isRecord(updates) ? updates.toJS() : updates));

    if (customerInfo) {
      setFormCustomerInfo(customerInfo);
    }

    setStep((state) => state + 1);
  };

  const handleBack = (value) => {
    setData(() => new NewOrder(Record.isRecord(value) ? value.toJS() : value));
    setStep((state) => state - 1);
  };

  const checkCreateNewOrderForCustomerToday = (orderData) => {
    return orderApi
      .getOrderSummariesWithFilters({
        contactEmail: orderData.customerInfo.email,
        created: [
          format(startOfDay(new Date()), BACKEND_DATE_FORMAT),
          format(startOfDay(add(new Date(), { days: 1 })), BACKEND_DATE_FORMAT),
        ],
        pageSize: 1,
      })
      .promise.then(async (response) => {
        if (!response.pageElements.length) {
          return Promise.resolve();
        }

        const confirm = await confirmCreateNewOrder();

        if (confirm) {
          return Promise.resolve();
        }

        onClose();
        return Promise.reject();
      });
  };

  const handleCreateOrder = (orderData) => {
    if (!inFlight && !inFlightSendingTemplates) {
      setInFlight(true);
      setData(() => orderData);

      checkCreateNewOrderForCustomerToday(orderData)
        .then(() => {
          const orderDto = orderData.toDTO(serviceOptions);
          orderApi
            .generateOrder(orderDto)
            .then((order) => {
              setInFlightSendingTemplates(true);

              if (sendingTemplates.length) {
                enqueueSnackbar('Sending messages to order...', { variant: 'info' });
              }

              // interface OrderInfo
              const orderInfo = {
                orderId: order.orderId,
                email: order?.contactInfo?.email,
                phoneNumber: order?.contactInfo?.primaryPhone?.number,
              };

              sendMessagesToOrder(
                orderInfo,
                sendingTemplates,
                !!currentEmployee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL],
              )
                .then(() => {
                  if (sendingTemplates.length) {
                    enqueueSnackbar('Templates were sent to order', { variant: 'success' });
                  }
                })
                .catch(() => {}) // ignore errors. we need to open order if it created
                .then(() => {
                  if (order.orderId) {
                    dispatch(openOrder(order.orderId));
                    onClose();
                  }

                  setInFlightSendingTemplates(false);
                });
            })
            .catch((error) => {
              enqueueSnackbar(`Error occurred during creating lead. ${error?.errors?.[0]?.message ?? ''}`, {
                variant: 'error',
              });
            })
            .then(() => {
              setInFlight(false);
            });
        })
        .catch(() => {
          setInFlight(false);
        });
    }
  };

  const { moveType } = data;

  const elevationReductionOptionsWithStringIds = (elevationReduction[moveType] || []).map(([id, ...rest]) => [
    id.toString(),
    ...rest,
  ]);

  useEffect(() => {
    if (settings) {
      const enabledMoveTypes = getEnabledMoveTypes(settings);
      const newValue = set(data, 'moveType', enabledMoveTypes[0]);
      setData(() => new NewOrder(Record.isRecord(newValue) ? newValue.toJS() : newValue));
    }
  }, [settings]);

  return (
    <Modal
      open
      maxWidth={step ? 'sm' : 'md'}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      title="Create New Lead"
      color="primary"
      onClose={onClose}
    >
      <CustomerInformation
        hidden={step !== 0}
        onSave={handleNext}
        onBack={handleBack}
        data={set(data, 'customerInfo', formCustomerInfo || data.customerInfo)}
        onClose={onClose}
      />
      {step === 1 && (
        <OrderDetails
          data={data}
          onBack={handleBack}
          onSave={handleNext}
          rates={rates}
          serviceOptions={serviceOptions}
          settings={settings}
        />
      )}
      {step === 2 && (
        <MoveDetails
          data={data}
          onSave={handleCreateOrder}
          onBack={handleBack}
          moveSizes={moveSizes}
          rooms={rooms}
          settings={settings}
          elevationReductionOptions={elevationReductionOptionsWithStringIds}
          parkingDistanceOptions={parkingDistanceOptions[moveType]}
          referredViaOptions={referredViaOptions}
          inFlight={inFlight || inFlightSendingTemplates}
        />
      )}
      <Box height={8} mt={1}>
        {(inFlight || inFlightSendingTemplates) && <LinearProgress />}
      </Box>
      {renderConfirmCreateNewOrder()}
    </Modal>
  );
};

CreateOrder.propTypes = {
  onClose: PropTypes.func.isRequired,
  requestingAccountId: PropTypes.number,
};

CreateOrder.defaultProps = {
  requestingAccountId: null,
};

export default CreateOrder;
