import React, { FC, useState } from 'react';

import * as chartjs from 'chart.js';
import { ElromcoCircularProgress, IconButton, formatCurrency } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { ChartData, HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { getDashboardStats, getInFlightStatistics } from 'admin/autodux/DashboardAutodux';
import DashboardCardTitle from 'admin/components/Dashboard/DashboardCardTitle';
import { useChartSalesPerformanceOptions } from 'admin/components/Dashboard/DashboardSalesPerformance/useChartSalesPerformanceOptions';
import { SalesPerformanceDTO } from 'common-types';
import { getPagination } from 'common/utils/getPagination';

import { SelectedPage, firstPage, mainPeriodTitle, maximumItemsInStatistics } from '../DashboardLabel';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '320px',
    position: 'relative',

    '&.MuiPaper-rounded': {
      borderRadius: spacing(0.75),
    },
  },
  arrowButton: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    top: '-45px',
  },
}));

const DashboardSalesPerformance: FC = () => {
  const theme = useTheme();
  const classes = useStyles();
  const options = useChartSalesPerformanceOptions();
  const bookedColor = theme.palette.primary.main;
  const [pageNumber, setPageNumber] = useState<number>(firstPage);
  const isFetching = useSelector(getInFlightStatistics);
  const dashboard = useSelector(getDashboardStats);
  const { selectedPeriod } = dashboard;
  const stats = dashboard.salesPerformance ?? [];

  const selectedPeriodRow = `(${selectedPeriod?.split('/')[mainPeriodTitle].toLowerCase()})`;

  const { items, previousPage, nextPage } = getPagination(stats, maximumItemsInStatistics, pageNumber);

  const handleChangePageNumber = (selectPage: SelectedPage) => () => {
    selectPage === SelectedPage.NEXT_PAGE
      ? setPageNumber((prevPageNumber) => prevPageNumber + 1)
      : setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const getChartData = (): ChartData<chartjs.ChartData> => {
    return {
      labels: items?.map(({ employeeName }: SalesPerformanceDTO) => employeeName),
      datasets: [
        {
          label: 'Total Bookings (Total Revenue)',
          backgroundColor: bookedColor,
          borderColor: bookedColor,
          minBarLength: 2,
          maxBarThickness: 34,
          hoverBackgroundColor: bookedColor,
          hoverBorderColor: bookedColor,
          data: items?.map(({ totalBookedInPeriod }: SalesPerformanceDTO) => totalBookedInPeriod),
          //@ts-ignore because custom prop
          labels: items?.map(
            ({ totalBookedInPeriod, totalBookedEstimateMax }: SalesPerformanceDTO) =>
              `${totalBookedInPeriod} ($${formatCurrency(totalBookedEstimateMax, 2)})`,
          ),
        },
      ],
    };
  };

  const chartData = getChartData();

  const horizontalBarPreviewOptions: chartjs.ChartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        ...options?.plugins?.datalabels,
        font: {
          size: stats?.length > maximumItemsInStatistics ? 10 : 14,
        },
      },
    },
  };

  return (
    <Box height="320px" display="flex" flexDirection="column" marginBottom="25px">
      <Box display="flex">
        <DashboardCardTitle title="Sales Performance">
          <Box component="span" fontSize="1rem" fontWeight={300} ml={1}>
            {selectedPeriodRow}
          </Box>
        </DashboardCardTitle>
      </Box>
      {isFetching ? (
        <Box position="relative" minHeight="320px" width="100%">
          <ElromcoCircularProgress />
        </Box>
      ) : (
        <Paper elevation={1} classes={{ root: classes.paper }}>
          <HorizontalBar data={chartData} options={horizontalBarPreviewOptions} />
          {stats?.length > maximumItemsInStatistics && (
            <Box className={classes.arrowButton}>
              <IconButton
                color="primary"
                disabled={!previousPage}
                size="medium"
                onClick={handleChangePageNumber(SelectedPage.PREVIOUS_PAGE)}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                color="primary"
                size="medium"
                disabled={!nextPage}
                onClick={handleChangePageNumber(SelectedPage.NEXT_PAGE)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default DashboardSalesPerformance;
