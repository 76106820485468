import { MoveType } from '@elromcoinc/react-shared';

export const getUniqueByMoveType = (values) => {
  const uniqueByMoveType = Object.keys(MoveType).reduce(
    (accumulator, moveType) => ({
      ...accumulator,
      [moveType]: values.find((it) => it.moveType === moveType),
    }),
    {},
  );
  return Object.values(uniqueByMoveType).filter(Boolean);
};
