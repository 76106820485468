import React, { useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';

import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { fetchCustomerOnlineCount, getCustomerOnlineCount } from 'admin/autodux/CustomerOnlineAutodux';
import CustomerOnlinePopper from 'admin/components/CustomerOnline/CustomerOnlinePopper';
import SupervisorAccountIconStyled from 'common/SupervisorAccountIconStyled';

const CustomerOnline = () => {
  const dispatch = useDispatch();
  const customerOnlineCount = useSelector(getCustomerOnlineCount);
  const isSessionExpired = useSelector(getIsSessionExpired);

  useEffect(() => {
    if (!isSessionExpired) {
      setTimeout(() => {
        dispatch(fetchCustomerOnlineCount());
      }, 1000);

      const interval = setInterval(() => {
        dispatch(fetchCustomerOnlineCount());
      }, 15e3);

      return () => clearInterval(interval);
    }
  }, [isSessionExpired]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" size="small" data-testid="customersOnline" onClick={handleClick}>
        <SupervisorAccountIconStyled />
        <Box fontWeight="300" color="inherit">
          {customerOnlineCount}
        </Box>
      </IconButton>
      <CustomerOnlinePopper anchorEl={anchorEl} onClose={handleClose} />
    </>
  );
};

export default CustomerOnline;
