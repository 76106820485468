import React, { FC } from 'react';

import { BodySmallText, BodyText } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';

import getActivityIcon from 'admin/components/OrderWindow/blocks/ActivityLog/getActivityIcon';
import { TitleWithDesc } from 'admin/components/OrderWindow/blocks/ChangeLog/TitleWithDesc';

const useStyles = makeStyles(({ palette, spacing }) => ({
  message: {
    backgroundColor: palette.grey[200],
    borderRadius: spacing(0.75),
    padding: '8px 12px',
    '& > p:last-child': {
      textAlign: 'right',
      marginTop: '6px',
    },
  },
}));

interface ActivityMessageProps {
  triggeredBy: string;
  desc: string;
  dateTime: Date;
  activityType: string;
}

export const ActivityMessage: FC<ActivityMessageProps> = ({ triggeredBy, desc, dateTime, activityType }) => {
  const classes = useStyles();
  return (
    <Box className={classes.message}>
      <Box display="flex" alignItems="center">
        <Box marginRight="4px" display="flex">
          {getActivityIcon(activityType, triggeredBy === 'System')}
        </Box>
        <TitleWithDesc label={triggeredBy} />
      </Box>
      <Box display="flex" justifyContent="flex-end" flexWrap="wrap" alignItems="center">
        <Box width="100%">
          <BodyText wordBreak>{desc}</BodyText>
        </Box>
        <BodySmallText>
          &nbsp;
          {format(dateTime, 'Ppp')}
        </BodySmallText>
      </Box>
    </Box>
  );
};
