import React from 'react';
import { Spacer, TruckRow } from 'admin/components/OrderWindow/SchedulerBox/common';
import { isSameDay } from 'date-fns';
import { BodyBigText, toDate } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

export const renderEstimatorName =
  (
    onEstimatorClick,
    dense,
    estimatorWorks,
    currentEstimatorId,
    date,
    currentSurveyValue,
    isWeekMode = false,
    estimators,
  ) =>
  (estimator, idx) => {
    const estimatorWork = estimatorWorks.filter((it) => it.estimatorId === estimator.id);
    const heightMode = isWeekMode && estimators.length < 3 ? [1] : [];

    return (
      <TruckRow
        key={estimator.id}
        grey={idx % 2 === 0}
        dense={dense}
        $width={150}
        $selected={currentEstimatorId === estimator.id && isSameDay(toDate(currentSurveyValue.surveyDate), date)}
        heightSize={Math.max(...(estimatorWork.length ? estimatorWork : heightMode))}
      >
        <Spacer
          actionable
          onClick={() => {
            onEstimatorClick(estimator.id, date);
          }}
        >
          <Box textAlign="center">
            <BodyBigText className="truck-name">{`${estimator.firstName} ${estimator.lastName}`}</BodyBigText>
          </Box>
        </Spacer>
      </TruckRow>
    );
  };
