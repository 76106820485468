import { formatCurrency } from '@elromcoinc/react-shared';
import { Theme } from '@material-ui/core';

export const formatAverageTotal = (min: number, max: number) => {
  const underpayment = min > 0 || max > 0;
  const overpayment = min < 0 || max < 0;
  const coefficient = overpayment ? -1 : 1;
  const prefix = overpayment ? '+' : '-';
  const minValue = formatCurrency(min * coefficient, 2);
  const maxValue = formatCurrency(max * coefficient, 2);
  const isMinEqualMax = min === max;

  if (overpayment || underpayment) {
    return isMinEqualMax ? `${prefix}$${minValue}` : `${prefix}$${minValue} - ${prefix}$${maxValue}`;
  }

  return `$${minValue}`;
};

export const getColorForAverageTotal = (min: number, max: number, theme: Theme) => {
  const underpayment = min > 0 || max > 0;
  const overpayment = min < 0 || max < 0;

  if (overpayment) {
    return `${theme.palette.success.main}`;
  } else if (underpayment) {
    return `${theme.palette.error.main}`;
  }
  return `${theme.palette.text.primary}`;
};
