import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  error: {
    minWidth: 'unset',
    backgroundColor: theme.palette.error.main
  },
  warning: {
    minWidth: 'unset',
    backgroundColor: theme.palette.secondary.main
  },
  success: {
    minWidth: 'unset',
    backgroundColor: theme.palette.success.main
  },
  notification: {
    minWidth: 'unset',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`
  },
  closeButton: {
    color: '#363747', // todo: use theme!
    position: 'absolute',
    top: 0,
    right: 0
  },
  icon: {
    fontSize: 20
  }
}));

/* @deprecated will be moved to react ui kit */
const CustomizedSnackbar = ({ message, onClose, type, autoHideDuration, ...rest }) => {
  const classes = useStyles();
  const handleClose = event => onClose(null, event);

  return (
    <Snackbar
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open
      ContentProps={{
        'aria-describedby': `${type}-message`,
        classes: { root: classes[type] }
      }}
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeButton}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...rest}
    />
  );
};

CustomizedSnackbar.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'success', 'notification']),
  autoHideDuration: PropTypes.number
};

CustomizedSnackbar.defaultProps = {
  autoHideDuration: 5000
};

CustomizedSnackbar.defaultProps = {
  type: 'error'
};

export default CustomizedSnackbar;
