const customEmailTemplateStyles = `
@media only screen and (min-width: 620px) {
      .u-row {
        width: 600px !important;
      }
      .u-row .u-col {
        vertical-align: top;
      }

      .u-row .u-col-33p33 {
        width: 199.98px !important;
      }

      .u-row .u-col-50 {
        width: 300px !important;
      }

      .u-row .u-col-100 {
        width: 600px !important;
      }

    }

    @media (max-width: 620px) {
      .u-row-container {
        max-width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
      .u-row .u-col {
        min-width: 320px !important;
        max-width: 100% !important;
        display: block !important;
      }
      .u-row {
        width: calc(100% - 40px) !important;
      }
      .u-col {
        width: 100% !important;
      }
      .u-col > div {
        margin: 0 auto;
      }
    }
    body {
      margin: 0;
      padding: 0;
    }

    table,
    tr,
    td {
      vertical-align: top;
      border-collapse: collapse;
    }

    p {
      margin: 0;
    }

    .ie-container table,
    .mso-container table {
      table-layout: fixed;
    }

    * {
      line-height: inherit;
    }

    a[x-apple-data-detectors='true'] {
      color: inherit !important;
      text-decoration: none !important;
    }

    table, td { color: #000000; } #u_body a { color: #0000ee; text-decoration: underline; } @media (max-width: 480px) { #u_content_image_1 .v-src-width { width: auto !important; } #u_content_image_1 .v-src-max-width { max-width: 58% !important; } #u_content_text_8 .v-text-align { text-align: center !important; } #u_content_text_9 .v-text-align { text-align: center !important; } #u_content_button_4 .v-text-align { text-align: center !important; } }
.template-builder-inventory-table {
  background: transparent !important;
}

.template-builder-inventory-table tr td {
  min-width: 20px;
}

.no-padding {
  padding: 0!important;
}

.no-padding-left {
  padding-left: 0!important;
}

.template-builder-box-root {
  box-sizing: border-box;
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

.template-builder-Table-Body-root tr:nth-child(2n + 1) {
  background-color: #fafafa;
}

.template-builder-Table-Body-root tr td {
  border: none;
  padding: 5px 4px;
}

.template-builder-typography-body1 {
  font-size: 0.875rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 100%;
}

.template-builder-typography-root {
  margin: 0;
}

.template-builder-rectangle {
  width: 9px;
  height: 9px;
  margin-top: 3px;
}

.template-builder-packed-by-customer {
  margin-left: 8px;
  margin-right: 4px;
  background-color: rgba(0, 0, 0, 0.87);
}

.template-builder-typography-packed-by-customer {
  color: rgba(0, 0, 0, 0.87);
}

.template-builder-typography-drop-off {
  color: #ffd63f;
}

.template-builder-drop-off {
  margin-left: 8px;
  margin-right: 4px;
  background-color: rgb(255, 214, 63);
}

.template-builder-not-going {
  margin-left: 8px;
  margin-right: 4px;
  background-color: #ff783f;
}

.template-builder-not-shipped-text {
  color: #ff783f;
}

.template-builder-packed-by-carrier {
  margin-left: 8px;
  margin-right: 4px;
  background-color: rgb(93, 206, 131);
}

.template-builder-packed-by-carrier-text {
  color: #5dce83;
}

 .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      position: relative;
      min-height: 1px;
    }
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      float: left;
    }
    .col-12 {
      width: 100%;
    }
    .col-11 {
      width: 91.66666667%;
    }
    .col-10 {
      width: 83.33333333%;
    }
    .col-9 {
      width: 75%;
    }
    .col-8 {
      width: 66.66666667%;
    }
    .col-7 {
      width: 58.33333333%;
    }
    .col-6 {
      width: 50%;
    }
    .col-5 {
      width: 41.66666667%;
    }
    .col-4 {
      width: 33.33333333%;
    }
    .col-3 {
      width: 25%;
    }
    .col-2 {
      width: 16.66666667%;
    }
    .col-1 {
      width: 8.33333333%;
    }

.left {
  float: left;
}
.m-r-10 {
  margin-right: 10px;
}
.m-l-10 {
  margin-left: 10px;
}

.width-95-percent {
  width: 95%;
}

.template-builder-table {
        background: transparent !important;
    }

    .template-builder-table tr:not(:last-child) {
        border-bottom: 1px dotted #aaaaaa;
    }

    .template-builder-table tr td {
        min-width: 20px;
    }

    .template-builder-typography.template-builder-header-big-text {
      font-size: 1.5rem;
    }

    .template-builder-typography {
        color: rgba(0, 0, 0, 0.87);
        font-size: 0.875rem;
        font-family: 'Lato', sans-serif;
        font-weight: 400;
        line-height: 20px;
    }
    .text-center {
      text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .text-left {
        text-align: left;
    }
    .vertical-align-middle {
        vertical-align: middle;
    }
    .border-right {
      border-right: 1px dotted #AAAAAA;
    }
    .word-break-normal {
      word-break: normal;
    }
    .border-bottom-none {
      border-bottom: none!important;
    }
    .padding-top-10 {
      padding-top: 10px;
    }
.width-25-percent {
  width: 25%;
}
.width-50-percent {
  width: 50%;
}
.width-75-percent {
  width: 75%;
}
`;

export default customEmailTemplateStyles;
