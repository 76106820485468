import { MoveType, SettingNames } from '@elromcoinc/react-shared';
import { List, Map } from 'immutable';

import { MoveUnitType, UnitSizeSpec } from 'common-types';

const { UNIT_SIZE_SPEC } = SettingNames;

const getRooms = (settings: { [key: string]: any }) =>
  (settings?.[UNIT_SIZE_SPEC] ?? [])
    ?.filter((it: UnitSizeSpec) => it.moveUnitType === MoveUnitType.ROOM)
    ?.reduce(
      (map: any, i: any) => map.set(i.moveType, map.get(i.moveType, List()).push(i)),
      Map({
        [MoveType.RESIDENTIAL]: List(),
        [MoveType.COMMERCIAL]: List(),
        [MoveType.MILITARY]: List(),
      }),
    );

export { getRooms };
