import React, { FC } from 'react';

import { BodyBigText, BodyText, UI_DATE_TIME_FORMAT } from '@elromcoinc/react-shared';
import { Box, Button, Divider, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { format } from 'date-fns';

import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';

const useStyles = makeStyles((theme) => ({
  listLog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div:nth-child(2)': {
      paddingTop: '8px',
    },
    '& > div + div': {
      paddingTop: 0,
    },
  },
  height: {
    height: theme.spacing(115.5),
  },
  overflowYAuto: {
    overflowY: 'auto',
    width: '100%',
  },
  listItem: {
    padding: '8px 0',
    marginBottom: 0,
    position: 'relative',
    '& > p': {
      marginRight: '24px',
    },
  },
  message: {
    borderRadius: '5px',
    padding: '8px 12px',
    '& > p:last-child': {
      textAlign: 'right',
      marginTop: '6px',
    },
  },
  closingLogHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  closeLogBody: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

interface ClosingLogsListProps {
  inModal?: boolean;
}

export const ClosingLogsList: FC<ClosingLogsListProps> = ({ inModal }) => {
  const classes = useStyles();
  const { closingOrderLogs, uniqueClosingActivities, totalClosingActivities, loadMore } = useActivityLogContext();
  const isEmpty = closingOrderLogs.size === 0;

  return (
    <Box className={clsx(classes.listLog, { [classes.height]: !inModal })}>
      {isEmpty ? (
        <Box className={classes.listItem} mb={1.5}>
          <BodyText>There are no any logs to display.</BodyText>
        </Box>
      ) : (
        <Box className={classes.overflowYAuto}>
          {closingOrderLogs.map((item) => (
            <Box key={item.id} className={classes.listItem} mb={1.5}>
              <Box className={classes.message}>
                <Box className={classes.closingLogHeader}>
                  <BodyBigText color="primary">
                    <b>{item?.triggeredBy}</b>
                  </BodyBigText>
                  <BodyText>{format(item?.dateTime!, UI_DATE_TIME_FORMAT)}</BodyText>
                </Box>
                <Box className={classes.closeLogBody} mt={1}>
                  <Box width="100%">
                    <BodyText wordBreak>{item?.desc}</BodyText>
                  </Box>
                </Box>
                <Divider />
              </Box>
            </Box>
          ))}
          {totalClosingActivities > 20 && uniqueClosingActivities.length !== totalClosingActivities && (
            <Box className={classes.listItem} mb={1.5}>
              <Button onClick={loadMore} variant="outlined" color="primary">
                Load More
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
