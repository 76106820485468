enum ArrivalWindowType {
  ANY = 'ANY',
  EIGHT_TEN_AM = 'EIGHT_TEN_AM',
  TWELVE_THREE_PM = 'TWELVE_THREE_PM',
  ONE_FOUR_PM = 'ONE_FOUR_PM',
  THREE_SEVEN_PM = 'THREE_SEVEN_PM',
}

const ArrivalWindowNames = {
  [ArrivalWindowType.ANY]: 'Any Time',
  [ArrivalWindowType.EIGHT_TEN_AM]: '8AM - 10AM',
  [ArrivalWindowType.TWELVE_THREE_PM]: '12PM - 3PM',
  [ArrivalWindowType.ONE_FOUR_PM]: '1PM - 4PM',
  [ArrivalWindowType.THREE_SEVEN_PM]: '3PM - 7PM',
};

export { ArrivalWindowType, ArrivalWindowNames };
