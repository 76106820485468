import React, { useEffect, useState } from 'react';

import {
  BodyText,
  Order,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  getServicePropertyName,
} from '@elromcoinc/react-shared';
import { Box, TableCell } from '@material-ui/core';
import { List, Map } from 'immutable';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const pickupNames = Map({
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'pickupCreditCardFees',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'pickupCashDiscounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'pickupCustomFees',
});

const names = Map({
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'Credit Card Fees',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'Cash Discounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'Custom Fees',
});

interface ClosingAdditionalChargeProps {
  mainType: PaymentAdjustmentType;
}

export const AdditionalCharge = ({ mainType }: ClosingAdditionalChargeProps) => {
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const currentPaymentAdjustmentsName = getServicePropertyName(serviceIndex, 'paymentAdjustmentDtos');
  const paymentAdjustments = (
    (order.getIn(currentPaymentAdjustmentsName.split('.')) ?? List()) as List<PaymentAdjustmentDTO>
  ).filter((pa) => pa.paymentAdjustmentType === mainType);
  const currentPickupName = getServicePropertyName(serviceIndex, pickupNames.get(mainType) ?? '');
  const currentValue = (order.getIn(currentPickupName.split('.')) as number) ?? 0;
  const [manual, setManual] = useState<string | number>(currentValue);

  useEffect(() => {
    setManual(currentValue);
  }, [currentValue]);

  const handleOnBlur = (value: number, name: string) => () => {
    onChange({ name, value });
  };

  if (paymentAdjustments.isEmpty()) {
    return <></>;
  }

  const onChangeTips = (grandTotal: string) => setManual(grandTotal);

  const nameToDisplay = paymentAdjustments.get(0)?.name ?? names.get(mainType) ?? '';

  const cashDiscountPrefix = manual && mainType === PaymentAdjustmentType.CASH_DISCOUNT ? '-$' : '$';

  return (
    <HighlightedTableRow data-testId={`${mainType}-charge-row`}>
      <TableCell size="small" padding="none">
        <BodyText>{nameToDisplay}</BodyText>
      </TableCell>
      <TableCell>
        <Box ml={-1.25} width={128}>
          <CurrencyInput
            disabled={isSelectedAllServices}
            value={manual}
            onChange={onChangeTips}
            onBlur={handleOnBlur(+manual, currentPickupName)}
            name={currentPickupName}
            data-testId={`${mainType}-input`}
            prefix={cashDiscountPrefix}
          />
        </Box>
      </TableCell>
    </HighlightedTableRow>
  );
};
