import {
  JobStatusMetadata,
  JobType,
  LaborType,
  Waypoint,
  parseISO,
  roundNumberToFixedDigits,
} from '@elromcoinc/react-shared';
import { addMinutes } from 'date-fns';
import { List } from 'immutable';

import { finishStatuses, firstTwoStatuses } from 'admin/components/CrewStatusLog/CrewStatusLogConstants';
import { CrewLogStatus, StatusesInformation } from 'admin/components/CrewStatusLog/types';
import { convertLocalTimeToMinutes } from 'admin/components/CrewStatusLog/utils/convertLocalTimeToMinutes';
import { processLoggedTime } from 'admin/components/CrewStatusLog/utils/processLoggedTime';

export const makeStatusInformation = (
  jobStatuses: JobStatusMetadata[],
  times: ReturnType<typeof processLoggedTime>,
  waypoints: List<Waypoint>,
  firstLoggedStartTime: Date,
  currentLaborTime: number,
  isCalculateDrivingTimeAsLabor: boolean,
  isTimeDone: boolean,
) => {
  const firstTwoJobStatusesToDisplay = jobStatuses.filter(
    (s) => firstTwoStatuses.includes(s.jobStatus) && s.jobType === JobType.PICK_UP,
  );
  const finishJobStatusesToDisplay = jobStatuses.filter(
    (s) => finishStatuses.includes(s.jobStatus) && s.jobType === JobType.PICK_UP,
  );

  const statusesInformation: StatusesInformation[] = firstTwoJobStatusesToDisplay.map((status) => {
    const date = parseISO(status.updated || status.created)!;
    const time = convertLocalTimeToMinutes(date);

    return {
      date,
      time,
      status: status.jobStatus,
    };
  });

  if (isCalculateDrivingTimeAsLabor) {
    const timeWithoutWaypoint = times.some((time) => !time.waypointNumber);

    times.forEach((time, index) => {
      const isLast = index === times.length - 1;
      const startDate = parseISO(time.startDateTime)!;
      const endDate = parseISO(time.endDateTime);
      const waypoint = waypoints.find((_, index) => index === time.waypointNumber);
      const isLastCustomerWaypoint = timeWithoutWaypoint || waypoints.get(waypoints.size - 2)?.id === waypoint?.id;
      const isLoading = waypoint?.laborType === LaborType.LOADING;
      const isLoadingUnloading = waypoint?.laborType === LaborType.LOADING_AND_UNLOADING;

      if (startDate) {
        let status = isLoading || isLoadingUnloading ? CrewLogStatus.LOADING : CrewLogStatus.UNLOADING;

        if (status === CrewLogStatus.UNLOADING && isLast) {
          status = CrewLogStatus.UNLOADING_STARTED;
        }

        if (timeWithoutWaypoint) {
          status = CrewLogStatus.LOADING;
        }

        statusesInformation.push({
          date: startDate,
          time: convertLocalTimeToMinutes(startDate, firstLoggedStartTime),
          status,
          title: isLast ? 'Started' : '',
        });
      }

      if (endDate) {
        let status = CrewLogStatus.UNLOADING;

        if (isLoading && !isLastCustomerWaypoint) {
          status = CrewLogStatus.TO_DESTINATION;
        } else if (isLoadingUnloading && isLast && isLastCustomerWaypoint) {
          status = CrewLogStatus.UNLOADING;
        }

        if (status === CrewLogStatus.UNLOADING && isLast) {
          status = CrewLogStatus.UNLOADING_COMPLETED;
        }

        if (timeWithoutWaypoint) {
          status = CrewLogStatus.UNLOADING;
        }

        statusesInformation.push({
          date: endDate,
          time: convertLocalTimeToMinutes(endDate, firstLoggedStartTime),
          status,
          title: isLast && isLastCustomerWaypoint ? 'Completed' : '',
        });
      }
    });
  } else if (currentLaborTime) {
    const firstTime = times[0];

    if (firstTime) {
      const startDate = parseISO(firstTime.startDateTime)!;
      const endDate = addMinutes(startDate, roundNumberToFixedDigits(currentLaborTime / 60, 0))!;
      const status = CrewLogStatus.LOADING;

      statusesInformation.push({
        date: startDate,
        time: convertLocalTimeToMinutes(startDate, firstLoggedStartTime),
        status,
      });

      if (isTimeDone) {
        statusesInformation.push({
          date: endDate,
          time: convertLocalTimeToMinutes(endDate, firstLoggedStartTime),
          status: CrewLogStatus.UNLOADING_COMPLETED,
        });
      }
    }
  }

  finishJobStatusesToDisplay.forEach((status) => {
    const date = parseISO(status.updated || status.created)!;
    const time = convertLocalTimeToMinutes(date);

    statusesInformation.push({
      date,
      time,
      status: status.jobStatus,
    });
  });

  statusesInformation.sort((a, b) => a.date.getTime() - b.date.getTime());

  return statusesInformation;
};
