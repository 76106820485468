import React from 'react';

import { Paper, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux-conf';
import styled from 'styled-components';

import { getSelectedWindow, openOrder } from 'admin/autodux/WindowsAutodux';
import { WindowType } from 'admin/constants/WidowType';
import { useMobile } from 'admin/hooks/useMobile';

const Window = styled(Paper)`
  z-index: ${({ $zIndex, theme }) => ($zIndex !== null ? $zIndex : theme.zIndex.drawer + 50)};
  max-width: 1600px;
  width: ${({ $width }) => $width};
  position: fixed;
  top: ${({ $offsetCoefficient }) => `calc(5vh * ${$offsetCoefficient})`};
  left: ${({ $offsetCoefficient }) => `calc((100vw - 95vw) * ${$offsetCoefficient})`};
  @media (min-width: 1700px) {
    left: ${({ $offsetCoefficient }) => `calc((100vw - 1600px) * ${$offsetCoefficient})`};
  }
  &.fullScreen {
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    transform: none !important;
  }
  &.minimized {
    position: relative;
    transform: none !important;
    align-self: flex-end;
    max-width: 300px;
    margin-right: 16px;
    margin-top: 4px;
    top: unset;
    left: unset;
    z-index: ${({ theme }) => theme.zIndex.modal + 50};
  }
  &.MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.grey[100]};
  }
`;

const useStyles = makeStyles(() => ({
  root: {
    '&.jodit_fullsize-box_true.react-draggable': {
      transform: 'none !important',
    },
  },
}));

function OrderWindowContainer({ orderId, children, zIndex, width, minimized, offsetCoefficient }) {
  const isMobile = useMobile();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const windows = useSelector(getSelectedWindow);

  const handleOnWindowClick = () => {
    const lastWindow = windows.last();
    if (lastWindow?.id !== orderId && lastWindow?.type !== WindowType.ORDER) {
      dispatch(openOrder(orderId));
    }
  };

  return (
    <Draggable handle=".draggable-handle">
      <Window
        square
        elevation={5}
        $offsetCoefficient={offsetCoefficient}
        $minimized={minimized}
        id={`order-window-${orderId}`}
        className={classNames({ fullScreen: isMobile && !minimized, minimized }, classes.root, 'applyRadius')}
        $zIndex={zIndex}
        onClick={handleOnWindowClick}
        $width={width}
      >
        {children}
      </Window>
    </Draggable>
  );
}

OrderWindowContainer.propTypes = {
  orderId: PropTypes.number,
  zIndex: PropTypes.number,
  minimized: PropTypes.bool,
  offsetCoefficient: PropTypes.number.isRequired,
  // height: PropTypes.number,
  width: PropTypes.string,
  children: PropTypes.element,
};

OrderWindowContainer.defaultProps = {
  orderId: null,
  minimized: false,
  zIndex: null,
  width: '100%',
  children: null,
};

export default OrderWindowContainer;
