import { createAsyncThunk } from '@reduxjs/toolkit';
import { LeadScoreSettings } from 'redux-conf/leadScoreSettings/leadScoreSettings-state';

import eventsApi from 'admin/api/eventsApi';

export const getLeadScoreSettings = createAsyncThunk<LeadScoreSettings, undefined, { rejectValue: string }>(
  'leadScoreSettings/getLeadScoreSettings',
  async (_, { rejectWithValue }) => {
    try {
      return await eventsApi.getLeadScoringSettings<LeadScoreSettings>();
    } catch (err: any) {
      return rejectWithValue(err.errors);
    }
  },
);
