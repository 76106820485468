import React from 'react';
import pt from 'prop-types';
import Box from '@material-ui/core/Box';
import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonBase: {
    width: '100%'
  },
  container: {
    width: '100%',
    backgroundColor: theme.palette.grey[100],
    textAlign: 'left',
    position: 'relative',
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200]
    }
  }
}));

const ItemBase = ({ onClick, children, ...rest }) => {
  const classes = useStyles();
  return (
    <Box my={1} {...rest}>
      <ButtonBase onClick={onClick} className={classes.buttonBase}>
        <Box className={classes.container}>{children}</Box>
      </ButtonBase>
    </Box>
  );
};

ItemBase.propTypes = {
  onClick: pt.func.isRequired
};

export default ItemBase;
