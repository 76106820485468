import { FC } from 'react';

import { IconButton } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    rowReverse: {
      flexDirection: 'row-reverse',
    },
    flexRow: {
      display: 'flex',
    },
  }),
);

interface RemoveManualChangesButtonProps {
  manual: number | null;
  onClick: () => void;
}

export const RemoveManualChangesButton: FC<RemoveManualChangesButtonProps> = ({ children, manual, onClick }) => {
  const classes = useStyles();
  const { isClosing } = useOrderClosingContext();

  return (
    <Box className={clsx(classes.flexRow, { [classes.rowReverse]: isClosing })}>
      {children}
      {manual != null && (
        <Box>
          <IconButton color="primary" size="small" onClick={onClick}>
            <ReplayIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
