import React, { useCallback, useEffect } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  PaymentActivityType,
  PaymentCashDto,
  PaymentStatus,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { CashPaymentFormInputs } from '../Forms';
import { PaymentTypeSelector } from '../Forms/PaymentTypeSelector';
import { usePaymentSourceContext } from '../PaymentsSourceContext';
import { AMOUNT, NOTES, PAID_DATE, PAYMENT_TYPE, labels } from './Common';

export interface CashPaymentDto {
  amount: number;
  message: string;
  date: Date;
  type: PaymentActivityType;
}

export const cashScema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

export interface CashPaymentProps {
  amount: number | string;
  finalAmount: number | string;
  isDepositAvailable: boolean;
  ifDepositUnavailableHint: string;
  onAmountChange?: (value: number | string) => void;
  onTypeChange?: (value: PaymentActivityType) => void;
  onMount?: (trigger: () => Promise<any>) => void;
  type: PaymentActivityType;
  activitySources: ActivitySourceDescriptor[];
}

const CashPayment = ({
  amount: initialAmount,
  finalAmount,
  ifDepositUnavailableHint,
  onMount,
  type,
  isDepositAvailable,
  onAmountChange,
  onTypeChange,
  activitySources,
}: CashPaymentProps) => {
  const { sourceId, activitySource } = usePaymentSourceContext();
  const methods = useForm<CashPaymentDto>({
    resolver: yupResolver(cashScema),
    defaultValues: { amount: initialAmount as number, [PAID_DATE]: new Date(), [PAYMENT_TYPE]: type },
  });

  const amount = methods.watch(AMOUNT);

  useEffect(() => {
    onAmountChange?.(amount);
  }, [amount]);

  useEffect(() => {
    onTypeChange?.(methods.watch(PAYMENT_TYPE));
  }, [methods.watch(PAYMENT_TYPE)]);

  const createCashPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CashPaymentDto) => {
          paymentActionsApi
            .createCashPayment(
              PaymentCashDto.createPaymentCashDto({
                date: formatISO(data[PAID_DATE]),
                amount: +finalAmount,
                message: data[NOTES],
                activitySources,
                type,
                status: PaymentStatus.PROCESSED,
                sourceId,
                activitySource,
              }),
            )
            .then(resolve)
            .catch(reject);
        }, reject)();
      }),
    [methods.handleSubmit, sourceId, activitySource, type, activitySources?.length, finalAmount],
  );

  useEffect(() => {
    onMount?.(createCashPayment);
  }, [createCashPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        {activitySource === ActivitySourceType.ORDER && (
          <PaymentTypeSelector
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            isDepositAvailable={isDepositAvailable}
          />
        )}
        <CashPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { CashPayment };
