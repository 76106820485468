import React, { Dispatch, FC, SetStateAction } from 'react';

import { CustomPaymentSettingDto } from '@elromcoinc/moveboard-setting-react';
import { Box, BoxProps } from '@material-ui/core';
import {
  LocalAtm as CashIcon,
  BorderColor as CheckIcon,
  CreditCard as CreditIcon,
  Stars as CustomPaymentIcon,
} from '@material-ui/icons';

import { useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import { PaymentType } from 'admin/dtos/PaymentType';

import PaymentTypeButton from '../PaymentTypeButton';

interface CustomPaymentSelectorProps extends Omit<BoxProps, 'onChange'> {
  value: PaymentType;
  onChange(value: PaymentType): void;
  paymentCustomSettings: CustomPaymentSettingDto | null;
  setPaymentCustomSettings: Dispatch<SetStateAction<CustomPaymentSettingDto | null>>;
}

const CustomPaymentSelector: FC<CustomPaymentSelectorProps> = ({
  value,
  onChange,
  paymentCustomSettings,
  setPaymentCustomSettings,
  ...rest
}) => {
  const settings = useOrderWindowSettings() || null;
  const customPayments = (settings?.PaymentCustomSettings ?? []).filter((cp) => cp.enabled);
  const handleChooseCategory = (category: PaymentType) => () => {
    setPaymentCustomSettings(null);
    onChange(category);
  };

  const isCashSelected = value === PaymentType.CASH;
  const isCreditCardRecord = value === PaymentType.CREDIT_CARD_RECORD;
  const isCheckSelected = value === PaymentType.CHECK;
  const isCustomSelected = value === PaymentType.CUSTOM;

  const onCustomPaymentClick = (cp: CustomPaymentSettingDto) => () => {
    setPaymentCustomSettings(cp);
    onChange(PaymentType.CUSTOM);
  };

  return (
    <Box my={1} display="flex" alignItems="center" flexWrap="wrap" {...rest}>
      <PaymentTypeButton selected={isCashSelected} icon={<CashIcon />} onClick={handleChooseCategory(PaymentType.CASH)}>
        Cash
      </PaymentTypeButton>
      <PaymentTypeButton
        selected={isCreditCardRecord}
        icon={<CreditIcon />}
        onClick={handleChooseCategory(PaymentType.CREDIT_CARD_RECORD)}
      >
        Credit Record
      </PaymentTypeButton>
      <PaymentTypeButton
        selected={isCheckSelected}
        icon={<CheckIcon />}
        onClick={handleChooseCategory(PaymentType.CHECK)}
      >
        Check
      </PaymentTypeButton>
      {customPayments.map((cp) => (
        <PaymentTypeButton
          key={cp.id}
          selected={isCustomSelected && paymentCustomSettings?.id === cp.id}
          icon={<CustomPaymentIcon />}
          onClick={onCustomPaymentClick(cp)}
        >
          {cp.name}
        </PaymentTypeButton>
      ))}
    </Box>
  );
};

export default CustomPaymentSelector;
