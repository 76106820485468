import { createContext } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';

import { AutomationRule, Template } from 'common-types';

interface ComposeEmailContextProps {
  blocks: EmailBlock[];
  groupedEmailTemplatesByTemplateFolder: [string, Template[]][];
  groupedTemplatesByTemplateFolder: [string, Template[]][];
  groupedSMSTemplatesByTemplateFolder: [string, Template[]][];
  inFlightTemplates: boolean;
  inFlightBlocks: boolean;
  templates: Template[];
  activitySource: ActivitySourceType;
  automationRules: AutomationRule[];
}

const ComposeEmailContext = createContext<ComposeEmailContextProps>({} as ComposeEmailContextProps);

export { ComposeEmailContext };
