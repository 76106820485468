import autodux from 'autodux';
import CustomerOnlineApi from 'admin/api/CustomerOnlineApi';

const {
  reducer,
  actions: { setCount },
  selectors: { getCount: getCustomerOnlineCount }
} = autodux({
  slice: 'customerOnline',
  initial: {
    count: 0
  },
  actions: {}
});

export default reducer;

const fetchCustomerOnlineCount = () => dispatch =>
  CustomerOnlineApi.getCustomerOnlineCount().then(result => {
    dispatch(setCount(result));
  });

export { fetchCustomerOnlineCount, getCustomerOnlineCount };
