import React from 'react';

import { MessageTypes } from '@elromcoinc/react-shared';
import TaskIcon from '@material-ui/icons/AssignmentTurnedIn';
import CustomIcon from '@material-ui/icons/Attachment';
import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import ImageIcon from '@material-ui/icons/Image';
import NoteIcon from '@material-ui/icons/Note';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Settings';
import SmsIcon from '@material-ui/icons/Sms';

import ActivityType from 'admin/constants/ActivityType';

const getActivityIcon = (actionType: ActivityType | MessageTypes, isAdmin = true, isImage = false) => {
  switch (actionType) {
    case MessageTypes.SMS:
    case MessageTypes.SMS_NOTIFICATION:
      return <SmsIcon color="primary" />;
    case ActivityType.MESSAGE:
    case MessageTypes.IN_APP:
      return <ChatIcon color="primary" />;
    case ActivityType.NOTE:
      return <NoteIcon color="primary" />;
    case ActivityType.CALL:
      return <PhoneIcon color="primary" />;
    case MessageTypes.EMAIL:
    case ActivityType.EMAIL:
      return <EmailIcon color="primary" />;
    case ActivityType.CUSTOM:
      return <CustomIcon color="primary" />;
    case ActivityType.TASK:
      return <TaskIcon color="primary" />;
    case ActivityType.EVENT:
      return isAdmin ? <EventIcon color="primary" /> : <PersonIcon color="primary" />;
    default:
      if (isImage) {
        return <ImageIcon color="primary" />;
      }
      return null;
  }
};

export default getActivityIcon;
