import React, { useEffect, useState } from 'react';

import { BACKEND_DATE_FORMAT, HeaderBigText, statusIds } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { format, sub } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import OrderAPI from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { MovesTable, SelectStatuses } from 'admin/components/AccountWindow/components';
import { allNewStatus } from 'admin/constants/OrderStatus';

const useStyles = makeStyles((theme) => ({
  selectBox: {
    width: '295px',
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      minWidth: '9rem',
      maxWidth: '18rem',
      marginLeft: '2rem',
    },
  },
}));

export const PastMoves = ({ summaryAccount }) => {
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = useState([]);
  const { id } = summaryAccount;
  const [orders, setOrders] = useState([]);
  const [isFlight, setIsFlight] = useState(false);
  const startDate = format(sub(new Date(), { years: 100 }), BACKEND_DATE_FORMAT);
  const endDate = format(sub(new Date(), { days: 1 }), BACKEND_DATE_FORMAT);
  const [sortBy, setSortBy] = useState('moveStartDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordersCount, setOrdersCount] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const isSessionExpired = useSelector(getIsSessionExpired);

  const onColumnSortChange = (changedColumn, direction) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const getPastMoves = () => {
    setIsFlight(true);

    const queryFilters = {
      'account.id': id,
      moveStartDate: [startDate, endDate],
      status: currentStatus.includes(statusIds.NEW) ? [...currentStatus, ...allNewStatus] : currentStatus,
    };

    OrderAPI.getOrderSummariesWithFilters({
      pageSize: 1000,
      sortBy,
      sortOrder,
      ...queryFilters,
    })
      .promise.then(({ pageElements, totalElements }) => {
        setOrders([...pageElements]);
        setOrdersCount(totalElements);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get orders for current account`, { variant: 'error' });
      })
      .then(() => {
        setIsFlight(false);
      });
  };

  useEffect(() => {
    if (!isSessionExpired) {
      getPastMoves();
      const interval = setInterval(getPastMoves, 3e4);
      return () => clearInterval(interval);
    }
  }, [sortBy, sortOrder, startDate, endDate, currentStatus, isSessionExpired]);

  useEffect(() => {
    setPage(1);
  }, [currentStatus]);

  return (
    <>
      <Box display="flex">
        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" px="24px" py={2}>
          <Box>
            <HeaderBigText>
              <b>Past Moves</b>
            </HeaderBigText>
          </Box>
          <Box className={classes.selectBox}>
            <SelectStatuses currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} />
          </Box>
        </Box>
      </Box>
      <MovesTable
        orders={orders}
        isFlight={isFlight}
        onColumnSortChange={onColumnSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        ordersCount={ordersCount}
        rowsPerPage={rowsPerPage}
        page={page}
      />
    </>
  );
};

PastMoves.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  summaryAccount: pt.object.isRequired,
};
