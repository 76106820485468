import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, FlatRateQuoteDetailConfig, Order, OverridableValue, formatCurrency } from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { ratePerSizeUnitProperty } from 'admin/components/OrderWindow/OrderWindowContants';
import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';

const useStyles = makeStyles((theme) =>
  createStyles({
    displayIcon: {
      display: 'block !important',
    },
  }),
);

const resetDecimalManual = (property: OverridableValue) => {
  return property?.setManual(property?.manual ? +property?.manual || 0 : null);
};

const getFlatRateTooltipArray = (flatRateQuoteDetailConfig: FlatRateQuoteDetailConfig | null) => {
  if (flatRateQuoteDetailConfig?.ratePerSizeUnitBase === null) {
    return [];
  }

  const calculatedRatePerSize = flatRateQuoteDetailConfig?.ratePerSizeUnitBase?.calculated;

  return [
    flatRateQuoteDetailConfig?.ratePerSizeUnitTotal?.manual !== null && 'Overridden',
    `Base rate per size unit $${formatCurrency(calculatedRatePerSize)}`,
    `Increase rate per entrance type ${flatRateQuoteDetailConfig?.flatRateElevationSurcharge?.humanReadable}`,
    `Increase rate per distance to parking ${flatRateQuoteDetailConfig?.flatRateDistanceToParkingSurcharge?.humanReadable}`,
    `Discount $${formatCurrency(flatRateQuoteDetailConfig?.appliedVolumeDiscountPerSizeUnit || 0)}`,
    `Total $${formatCurrency(flatRateQuoteDetailConfig?.ratePerSizeUnitTotal?.calculated)}`,
  ].filter(Boolean) as string[];
};

export const RatePerMoveSizeUnitFlatRate = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const getServicePropertyName = useGetServicePropertyName();
  const flatRateQuoteDetailConfigName = getServicePropertyName('flatRateQuoteDetailConfig');
  const flatRateQuoteDetailConfig = order.getIn(flatRateQuoteDetailConfigName.split('.')) as FlatRateQuoteDetailConfig;
  const [ratePerSizeUnit, setRatePerSizeUnit] = useState<OverridableValue>(
    flatRateQuoteDetailConfig?.ratePerSizeUnitTotal,
  );

  useEffect(() => {
    setRatePerSizeUnit(flatRateQuoteDetailConfig?.ratePerSizeUnitTotal);
  }, [flatRateQuoteDetailConfig?.ratePerSizeUnitTotal]);

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  const flatRateTooltipArray = getFlatRateTooltipArray(flatRateQuoteDetailConfig);
  const name = getServicePropertyName(ratePerSizeUnitProperty);

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{`Rate per ${moveUnitLabelsShort}`}</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin $manual={ratePerSizeUnit?.manual != null}>
          <Box width={60}>
            <CurrencyInput
              disabled={isCompleted || isLockSales}
              onChange={(value: string) =>
                setRatePerSizeUnit(ratePerSizeUnit.setManual(value !== '' ? (value as any) : null))
              }
              value={ratePerSizeUnit?.value() ?? 0}
              name={name}
              onBlur={handleOnBlur(resetDecimalManual(ratePerSizeUnit))}
            />
          </Box>
          {!!flatRateTooltipArray.length && (
            <Tooltip
              title={
                <ul>
                  {flatRateTooltipArray.map((rule) => (
                    <li key={rule}>
                      <BodyText>{rule}</BodyText>
                    </li>
                  ))}
                </ul>
              }
            >
              <HelpOutlineIcon color="primary" classes={{ root: classes.displayIcon }} />
            </Tooltip>
          )}
        </FlexWithNegativeMargin>
      </ValueTableCell>
    </TableRow>
  );
};
