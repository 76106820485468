import { Dispatch, SetStateAction, createContext } from 'react';

interface OrderServiceIndexContextProps {
  selectedServiceIndex: number;
  serviceIndex: number;
  setServiceIndex: Dispatch<SetStateAction<number>>;
  isSelectedAllServices: boolean;
}

export const OrderServiceIndexContext = createContext<OrderServiceIndexContextProps>(
  {} as OrderServiceIndexContextProps,
);
