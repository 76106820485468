import { CompanyCalendar } from '@elromcoinc/moveboard-setting-react';
import { MoveType, MoveTypeTypes, SettingNames } from '@elromcoinc/react-shared';

const { COMPANY_CALENDAR } = SettingNames;

const getRates = (settings: { CompanyCalendar: CompanyCalendar[] }) =>
  Object.keys(MoveType).reduce((accumulator, moveType) => {
    return {
      ...accumulator,
      [moveType as MoveTypeTypes]: settings?.[COMPANY_CALENDAR]?.reduce((acc, it) => {
        if (it.moveType === moveType) {
          return {
            ...acc,
            [it.date as string]: it.rateType,
          };
        }
        return acc;
      }, {}),
    };
  }, {} as { [key in MoveType]: { [key: string]: number } });

export { getRates };
