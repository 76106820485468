import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, getHours, getMinutes, isSameHour, isSameMinute, isValid } from 'date-fns';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import GroupIcon from '@material-ui/icons/Group';
import AccessTime from '@material-ui/icons/AccessTime';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { hexToRgb, textColorForBackground } from 'admin/utils/colorUtils';
import { statusColor } from 'admin/constants/OrderStatus';

const StyledJobComponent = styled.div`
  ${({ $selected, color }) => ($selected ? `border: 2px dashed ${textColorForBackground(hexToRgb(color))};` : '')}
  margin: ${({ dense = false }) => (dense ? 2 : 3)}px 0;
  height: ${({ expanded = false, dense = false }) => (dense && !expanded ? 20 : 51)}px;
  top: ${({ dense = false, level = 0 }) => (dense ? 24 : 56) * level}px;
  left: ${({ numHours = 16, startTime = 0 }) => `calc((100% / ${numHours} / 60) * ${startTime})`};
  position: absolute;
  min-width: 17px;
  width: ${({ numHours = 16, durationMin = 15 }) => `calc((100% / ${numHours} / 60) * ${durationMin})`};
  background-color ${({ color }) => color};
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'row' : 'column')};
  justify-content: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'flex-start' : 'center')};
  align-items: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'center' : 'flex-start')};
  padding: 0 8px 0 24px;
  ${({ dense = false, expanded = false }) => (dense || expanded ? 'cursor: pointer;' : '')}
  ${({ expanded = false, theme }) => (expanded ? `z-index: ${theme.zIndex.modal};` : '')}
`;

const DescriptionRow = styled(Typography)`
  && {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-style: normal;
    font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    margin-right: 4px;
    ${({ dense = false }) => (dense ? `&::after { content: ' | '; }` : '')}
  }
`;

const Description = styled.span`
  font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
  &:not(:last-child) {
    &:after {
      content: ' | ';
    }
  }
`;

const ServiceType = styled.span`
  position: absolute;
  width: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color ${({ $color = 'transparent' }) => $color};
`;

const formatTime = time => format(time, 'h:mm a');

const iconSize = { fontSize: '0.75rem' };

const renderStartTime = (earliest, latest) => {
  if (!isValid(latest)) return formatTime(earliest);
  if (!isSameHour(earliest, latest) || !isSameMinute(earliest, latest))
    return `${formatTime(earliest)} - ${formatTime(latest)}`;
  return formatTime(earliest);
};

const Job = ({ job, truckId, dense, serviceId }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleJobClick = () => {
    if (dense) setExpanded(d => !d);
  };
  const startTimeMins = getHours(job.startTimeEarliest) * 60 + getMinutes(job.startTimeEarliest);
  const endTimeMins = getHours(job.endTime) * 60 + getMinutes(job.endTime);
  const customerName = `${job.customerFirstName} ${job.customerLastName}`;
  return (
    <StyledJobComponent
      dense={dense}
      level={job.level[truckId]}
      key={job.uuid}
      color={statusColor(job.orderStatus)}
      $selected={job.serviceId === serviceId}
      numHours={24}
      onClick={handleJobClick}
      expanded={expanded}
      durationMin={endTimeMins - startTimeMins}
      startTime={startTimeMins}>
      <ServiceType $color={job.color} />
      <DescriptionRow dense={dense && !expanded}>
        <Description $isBold>{`#${job.orderNumber}`}</Description>
        <Description>
          <PersonOutline style={iconSize} />
          &nbsp;
          {customerName}
        </Description>
        <Description>{job.customerPhone}</Description>
      </DescriptionRow>
      <DescriptionRow dense={dense && !expanded}>
        <Description>
          <GroupIcon style={iconSize} />
          &nbsp;
          {job.numberOfMovers}
        </Description>
        <Description>
          <AccessTime style={iconSize} />
          &nbsp;
          {renderStartTime(job.startTimeEarliest, job.startTimeLatest)}
        </Description>
        <Description>{job.sizeDescription}</Description>
        <Description>
          <AccessTime style={iconSize} />
          &nbsp;
          {job.jobTime}
        </Description>
      </DescriptionRow>
      <DescriptionRow dense={dense && !expanded}>
        {job.fromAddress}
        &nbsp;
        <ArrowForward style={iconSize} />
        &nbsp;
        {job.toAddress}
      </DescriptionRow>
    </StyledJobComponent>
  );
};

Job.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  job: PropTypes.object.isRequired,
  truckId: PropTypes.number.isRequired,
  serviceId: PropTypes.number.isRequired,
  dense: PropTypes.bool
};

Job.defaultProps = {
  dense: false
};

export default Job;
