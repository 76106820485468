import { ServiceType, StorageMoveStage, isMovingAndStorageService, toDate } from '@elromcoinc/react-shared';
import { differenceInDays } from 'date-fns';

const averageDaysInMonth = 30.44;

export const checkMaxPeriodInStorage = (
  storageMoveStage: StorageMoveStage,
  currentDate: string,
  storageStartDate: string,
  storageEndDate: string,
  maxMonthsInStorage: number,
  serviceType: ServiceType,
) => {
  const startDate = storageMoveStage === StorageMoveStage.IN ? currentDate : storageStartDate;
  const endDate = storageMoveStage === StorageMoveStage.IN ? storageEndDate : currentDate;

  if (!startDate || !endDate || !isMovingAndStorageService(serviceType)) {
    return false;
  }

  const differenceInDaysCount = differenceInDays(toDate(endDate)!, toDate(startDate)!);

  return differenceInDaysCount / averageDaysInMonth > Number(maxMonthsInStorage);
};
