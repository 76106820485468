import { PriceAdjustmentType } from '@elromcoinc/react-shared';

import { OrderPayrollTotals } from 'admin/components/OrderWindow/modals/Payroll/OrderPayrollTotals';
import { PositionTypes } from 'admin/constants/PositionTypes';
import { EmployeeCommissionType, EmployeeJobPayrollCompensationDto } from 'common-types/payroll';

interface Options {
  positionType?: PositionTypes;
  hasHourlyRateAsHelper: boolean;
}

export const getCommissionByType = (
  type: EmployeeCommissionType,
  payroll: OrderPayrollTotals,
  { positionType, hasHourlyRateAsHelper }: Options,
): EmployeeJobPayrollCompensationDto | null => {
  if (
    type === EmployeeCommissionType.HOURLY_RATE &&
    ((positionType !== PositionTypes.HELPER && positionType !== PositionTypes.DRIVER) || !hasHourlyRateAsHelper)
  ) {
    const amount = 0;

    return {
      type,
      rate: { amount: payroll?.numberOfHours ?? 0, type: PriceAdjustmentType.ABSOLUTE },
      value: amount,
      total: payroll.numberOfHours * amount,
    };
  }

  if (
    (type === EmployeeCommissionType.HOURLY_RATE_AS_HELPER || type === EmployeeCommissionType.HOURLY_RATE) &&
    (positionType === PositionTypes.HELPER || positionType === PositionTypes.DRIVER)
  ) {
    const amount = 0;

    return {
      type: EmployeeCommissionType.HOURLY_RATE,
      rate: { amount: payroll.numberOfHours ?? 0, type: PriceAdjustmentType.ABSOLUTE },
      value: amount,
      total: payroll.numberOfHours * amount,
    };
  }

  if (type === EmployeeCommissionType.PACKING_COMMISSION) {
    return {
      type,
      rate: { amount: 0, type: PriceAdjustmentType.PERCENT },
      value: payroll.salesPackingTotal,
      total: 0,
    };
  }

  if (type === EmployeeCommissionType.ADDITIONAL_SERVICES_COMMISSION) {
    return {
      type,
      rate: { amount: 0, type: PriceAdjustmentType.PERCENT },
      value: payroll.salesAdditionalServicesTotal,
      total: 0,
    };
  }

  if (type === EmployeeCommissionType.COMMISSION_FROM_THE_TOTAL) {
    return {
      type,
      rate: { amount: 0, type: PriceAdjustmentType.PERCENT },
      value: payroll.salesTotal,
      total: 0,
    };
  }

  if (type === EmployeeCommissionType.PER_JOB_BONUS) {
    return {
      type,
      rate: { amount: 1, type: PriceAdjustmentType.ABSOLUTE },
      value: 1,
      total: 1,
    };
  }

  if (type === EmployeeCommissionType.TIP) {
    return {
      type,
      rate: { amount: 0, type: PriceAdjustmentType.PERCENT },
      value: payroll.tips ?? 0,
      total: 0,
    };
  }

  if (
    type === EmployeeCommissionType.DAILY_RATE ||
    type === EmployeeCommissionType.PER_DAY_BONUS ||
    type === EmployeeCommissionType.HOURLY_RATE_AS_HELPER
  ) {
    return null;
  }

  throw new Error('Unknown commission type');
};
