import { useEffect, useRef } from 'react';

// was taken from https://github.com/streamich/react-use/blob/master/src/usePrevious.ts
/*
@deprecated use it from react-ui-kit
 */
export default function usePrevious(state) {
  const ref = useRef();

  useEffect(() => {
    ref.current = state;
  });

  return ref.current;
}
