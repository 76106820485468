const DEFAULT_TEMPLATE_NAME = 'Default';
const DONT_SEND_AUTOMATICALLY_NAME = "Don't send automatically";
const DEFAULT_TEMPLATE_FOLDER_ID = 0;
const DONT_SEND_AUTOMATICALLY_FOLDER_ID = -1;

const DONT_SEND_AUTOMATICALLY_ID = -1;
const NEW_TEMPLATE_RULE_ID = -2;
const USE_COMPANY_LOGO = 'useCompanyLogo';
const USE_COMPANY_FOOTER = 'useCompanyFooter';

export {
  DEFAULT_TEMPLATE_NAME,
  DONT_SEND_AUTOMATICALLY_ID,
  USE_COMPANY_LOGO,
  USE_COMPANY_FOOTER,
  DEFAULT_TEMPLATE_FOLDER_ID,
  NEW_TEMPLATE_RULE_ID,
  DONT_SEND_AUTOMATICALLY_NAME,
  DONT_SEND_AUTOMATICALLY_FOLDER_ID,
};
