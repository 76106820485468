import axios from 'axios';
import { format } from 'date-fns';

import storageUtil from 'admin/utils/storageUtil';

const browserTimeZoneOffset = format(new Date(), 'xxx');

const requestInterceptor: Parameters<typeof axios.interceptors.request.use>[0] = (config) => {
  const result = { ...config };
  // @ts-ignore
  result.credentials = 'same-origin';
  // @ts-ignore
  result.resolveWithFullResponse = true;
  result.headers['X-TenantID'] = storageUtil.getTenantId() || 1;
  result.headers['X-TimeZone'] = browserTimeZoneOffset;

  // this should not be working on prod
  // if (process.env.NODE_ENV !== 'production') {
  //   result.headers['ngrok-skip-browser-warning'] = 'true'; // Set the ngrok-skip-browser-warning header
  //   result.headers['User-Agent'] = 'custom/non-standard'; // Set the custom User-Agent header
  // }

  return result;
};

export { requestInterceptor };
