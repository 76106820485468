import React from 'react';

import { BodyText, Duration, Order, Select, TravelTimeManagementConfig } from '@elromcoinc/react-shared';
import { MenuItem, TableCell, TableRow, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import useDurations from 'admin/components/OrderWindow/hooks/useDurations';
import settingName from 'admin/constants/SettingName';
import { getServiceClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles((theme) =>
  createStyles({
    displayIcon: {
      display: 'block !important',
    },
  }),
);

const propertyName = 'doubleDrivingTimeOverride';
const propertyNameOriginalSecounds = `${propertyName}.originalSeconds`;

export const DoubleDriveTimeRow = () => {
  const classes = useStyles();
  const settings = useOrderWindowSettings();
  const getServicePropertyName = useGetServicePropertyName();
  const { serviceIndex } = useOrderServiceIndex();
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const [, travelTimeDuration15mins] = useDurations(null, 6);
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const travelTimeManagementConfigName = getServicePropertyName('travelTimeManagementConfig');
  const travelTimeManagementConfig = order.getIn(
    travelTimeManagementConfigName.split('.'),
  ) as TravelTimeManagementConfig;
  const name = isClosing
    ? getServiceClosingPropertyName(serviceIndex, propertyNameOriginalSecounds)
    : getServicePropertyName(propertyNameOriginalSecounds);
  const doubleDrivingTimeOverrideName = isClosing
    ? getServiceClosingPropertyName(serviceIndex, propertyName)
    : getServicePropertyName(propertyName);
  const doubleDrivingTimeOverride = order.getIn(doubleDrivingTimeOverrideName.split('.')) as Duration;
  const doubleDrivingTimeName = getServicePropertyName('doubleDrivingTime');
  const doubleDrivingTime = order.getIn(doubleDrivingTimeName.split('.')) as Duration;
  const actualDoubleDrivingTime = isClosing ? null : doubleDrivingTime.getRoundedSeconds();

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{settings[settingName.DRIVING_DOUBLE_TRAVEL_NAME]}</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin>
          <Select
            disabled={isCompleted || isLockSales}
            fullWidth={false}
            value={doubleDrivingTimeOverride?.getRoundedSeconds()}
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            name={name}
            onChange={onChange}
          >
            {travelTimeDuration15mins.map(([value, label]) => (
              <MenuItem key={value} value={value}>
                <BodyText
                  color={actualDoubleDrivingTime != null && value !== actualDoubleDrivingTime ? 'error' : 'default'}
                >
                  <b>{label}</b>
                </BodyText>
              </MenuItem>
            ))}
          </Select>
          <Tooltip
            title={
              <ul>
                {travelTimeManagementConfig?.calculationRules.map((rule: string) => (
                  <li key={rule}>
                    <BodyText>{rule}</BodyText>
                  </li>
                ))}
              </ul>
            }
          >
            <HelpOutlineIcon color="primary" classes={{ root: classes.displayIcon }} />
          </Tooltip>
        </FlexWithNegativeMargin>
      </ValueTableCell>
    </TableRow>
  );
};
