// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import dashboardApi from 'admin/api/DashboardApi';
import reportsAPI from 'admin/api/ReportsAPI';
import { periods } from 'admin/constants/DashboardPeriods';
import { SalesPerformanceDTO } from 'common-types';
import { DashboardStatistics } from 'common-types/statistic';

// TODO need to refactor this code we don't need redux for dashboard stats
const {
  reducer,
  selectors: { getInFlight: getInFlightStatistics },
  actions: { setStatistics, setInFlight, setPastWeek, setSubmissionCount, setSalesPerformance },
} = autodux({
  slice: 'dashboard',
  initial: {
    current: {},
    pastWeek: {},
    selectedPeriod: periods[0][1],
    inFlight: false,
    submissionCount: 0,
    salesPerformance: [],
  },
  actions: {
    setStatistics: (state: any, payload: any) => ({
      ...state,
      current: payload.current,
      selectedPeriod: payload.selectedPeriod,
      inFlight: false,
    }),
  },
});

interface StatisticPeriod {
  startDate: string;
  endDate: string;
  previousStartDate: string;
  previousEndDate: string;
}
export function fetchDashboardStats(
  { startDate, endDate, previousStartDate, previousEndDate }: StatisticPeriod,
  { startDate: pastWeekStartDate, endDate: pastWeekEndDate }: StatisticPeriod,
  selectedPeriod: string,
  shouldIndicateRequest = false,
) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    shouldIndicateRequest && dispatch(setInFlight(true));

    dashboardApi
      .getStats({ startDate: pastWeekStartDate, endDate: pastWeekEndDate })
      .then((pastWeek) => dispatch(setPastWeek(pastWeek)));

    dashboardApi
      .getSubmissionStatistic(startDate, endDate)
      .then((submissionCount) => dispatch(setSubmissionCount(submissionCount)));

    reportsAPI
      .getSalesPerformanceStatistic({ startDate, endDate })
      .then((salesPerformance) => dispatch(setSalesPerformance(salesPerformance.stats)));

    return dashboardApi
      .getStats({
        startDate,
        endDate,
        compareToRange: { startDate: previousStartDate, endDate: previousEndDate },
      })
      .then((current) => dispatch(setStatistics({ current, selectedPeriod })));
  };
}

export interface DashboardState {
  current: Partial<DashboardStatistics>;
  pastWeek: Partial<DashboardStatistics>;
  selectedPeriod: string;
  inFlight: boolean;
  submissionCount: number;
  salesPerformance: SalesPerformanceDTO[];
}

export const getDashboardStats = (state: any) => state.dashboard as DashboardState;

export { getInFlightStatistics };

export default reducer;
