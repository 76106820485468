import React from 'react';

import {
  BodyText,
  Order,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  formatCurrency,
  longDistanceMethods,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputWrapper: {
      maxWidth: '85px',
    },
    input: {
      textAlign: 'right',
    },
  }),
);

export const AdditionalWeight = () => {
  const classes = useStyles();
  const { serviceIndex } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const { order } = useOrderState() as { order: Order };
  const paymentAdjustmentDtos: List<PaymentAdjustmentDTO> =
    order?.closingOrderDetail?.serviceRosterClosingsDto?.get(serviceIndex)?.paymentAdjustmentDtos ??
    List<PaymentAdjustmentDTO>();
  const isLongDistanceMileageTariff =
    order?.isLongDistance(serviceIndex) &&
    order?.getServiceQuote(serviceIndex)?.longDistanceTariffDetails?.calculationMethod === longDistanceMethods.MILEAGE;
  const additionalWeight = paymentAdjustmentDtos.filter(
    (pad) => pad.paymentAdjustmentType === PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE,
  );

  if (!isClosing || paymentAdjustmentDtos.isEmpty() || !isLongDistanceMileageTariff) {
    return <></>;
  }

  const total = additionalWeight?.reduce((acc, pad) => acc + pad.total, 0);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <BodyText>{`Additional ${moveUnitLabelsShort}`}</BodyText>
        <Text value={`$${formatCurrency(total ?? 0)}`} data-testId="additionalVolumeValue" />
      </Box>
    </Grid>
  );
};
