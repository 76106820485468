import { FC, useEffect, useMemo, useState } from 'react';

import { ActivitySourceType, Order, storageUtil, useSettings } from '@elromcoinc/react-shared';
import { useSelector } from 'react-redux';

import communicationAPI from 'admin/api/CommunicationsAPI';
import templateApi from 'admin/api/templateApi';
import { getIsDisabledSms } from 'admin/autodux/MessageAutodux';
import { useUnsubscribeMessage } from 'admin/components/OrderWindow/context';
import {
  DEFAULT_TEMPLATE_NAME,
  DONT_SEND_AUTOMATICALLY_FOLDER_ID,
  DONT_SEND_AUTOMATICALLY_ID,
  DONT_SEND_AUTOMATICALLY_NAME,
} from 'admin/components/Settings/components/NewTemplateBuilder/TemplateBuilderConstants';
import SettingName from 'admin/constants/SettingName';
import { AutomationRule, CommunicationModality, Template, TemplateBlock, TemplateDTO } from 'common-types';

import { ComposeEmailContext } from './ComposeEmailContext';

const settingsQuery = [SettingName.TEMPLATE_FOLDER];

const groupTemplateByTemplateFolder = (templateFolder: TemplateFolder[], templates: Template[]) =>
  templateFolder
    .map((folder) => [
      folder.name,
      templates.filter((template) => {
        if (folder.id === DONT_SEND_AUTOMATICALLY_FOLDER_ID) {
          return template.automationRuleId === DONT_SEND_AUTOMATICALLY_ID;
        }

        return (template.templateFolder?.id ?? null) === folder.id;
      }),
    ])
    .filter(([, templates]) => templates.length) as [string, Template[]][];

const filterSMSTemplatesIfItDisabled = (isDisableSms: boolean) => (t: TemplateDTO) =>
  !isDisableSms || t.modality !== CommunicationModality.SMS;

interface ComposeEmailProviderProps {
  activitySource?: ActivitySourceType;
  sourceId?: number;
  order?: Order;
}

const isAvailableTemplatesAndAutomationRules = [
  ActivitySourceType.ORDER,
  ActivitySourceType.INVOICE,
  ActivitySourceType.CREW_SCHEDULE_DATE,
];

const cacheTemplates = {
  templates: [] as Template[],
  companyId: storageUtil.getTenantId(),
};

const cachedRules = {
  rules: [] as AutomationRule[],
  companyId: storageUtil.getTenantId(),
};

const cachedBlocks = {
  blocks: [] as EmailBlock[],
  companyId: storageUtil.getTenantId(),
};

const ComposeEmailProvider: FC<ComposeEmailProviderProps> = ({
  children,
  activitySource = ActivitySourceType.ORDER,
  sourceId,
  order,
}) => {
  const settings = useSettings(settingsQuery);
  const isDisableSms = useSelector(getIsDisabledSms) as boolean;
  const [inFlightTemplates, setInFlightTemplates] = useState(false);
  const [inFlightBlocks, setInFlightBlocks] = useState(false);
  const [isDisableSMSForSource, setIsDisableSMSForSource] = useState(false);
  const [automationRules, setAutomationRules] = useState<AutomationRule[]>([]);
  const [blocks, setBlocks] = useState<EmailBlock[]>([]);
  const [allTemplates, setAllTemplates] = useState<Template[]>([]);
  const templates = useMemo(
    () => allTemplates.filter(filterSMSTemplatesIfItDisabled(isDisableSms || isDisableSMSForSource)),
    [allTemplates, isDisableSms, isDisableSMSForSource],
  );
  const { isUnsubscribe } = useUnsubscribeMessage();
  const primaryPhone = order?.contactInfo?.primaryPhone;
  const templateFolder = useMemo(
    () => [
      ...((settings.get(SettingName.TEMPLATE_FOLDER) || []) as TemplateFolder[]).sort(
        (firstFolder, secondFolder) => firstFolder.order - secondFolder.order,
      ),
      { id: DONT_SEND_AUTOMATICALLY_FOLDER_ID, order: 0, name: DONT_SEND_AUTOMATICALLY_NAME },
      { id: null, order: 0, name: DEFAULT_TEMPLATE_NAME },
    ],
    [settings],
  );
  const companyId = storageUtil.getTenantId();
  const groupedTemplatesByTemplateFolder = useMemo(
    () => groupTemplateByTemplateFolder(templateFolder, templates),
    [templates, templateFolder],
  );
  const groupedEmailTemplatesByTemplateFolder = useMemo(
    () =>
      groupTemplateByTemplateFolder(
        templateFolder,
        templates.filter((t) => t.modality === CommunicationModality.EMAIL),
      ),
    [templates, templateFolder],
  );
  const groupedSMSTemplatesByTemplateFolder = useMemo(
    () =>
      groupTemplateByTemplateFolder(
        templateFolder,
        allTemplates.filter((t) => t.modality === CommunicationModality.SMS),
      ),
    [allTemplates, templateFolder],
  );

  useEffect(() => {
    if (activitySource === ActivitySourceType.ORDER && sourceId && primaryPhone) {
      if (isUnsubscribe) {
        setIsDisableSMSForSource(true);
        return;
      }

      communicationAPI
        .checkConditionsBeforeSendSms(activitySource, sourceId, primaryPhone)
        .then(() => {
          setIsDisableSMSForSource(false);
        })
        .catch(() => {
          setIsDisableSMSForSource(true);
        });
    }
  }, [sourceId, primaryPhone, isUnsubscribe]);

  useEffect(() => {
    if (isAvailableTemplatesAndAutomationRules.includes(activitySource)) {
      if (cachedRules.rules.length && cachedRules.companyId === companyId) {
        setAutomationRules(cachedRules.rules);
        return;
      }

      templateApi.getAutomationRules().then((rules) => {
        cachedRules.rules = rules;
        cachedRules.companyId = companyId;
        setAutomationRules(cachedRules.rules);
      });
    }
  }, [activitySource]);

  useEffect(() => {
    if (isAvailableTemplatesAndAutomationRules.includes(activitySource)) {
      if (cacheTemplates.templates.length && cacheTemplates.companyId === companyId) {
        setAllTemplates(cacheTemplates.templates);
        return;
      }

      setInFlightTemplates(true);

      templateApi.getAllTemplates().then((response) => {
        cacheTemplates.templates = response.map((t) => new Template(t));
        cacheTemplates.companyId = companyId;
        setAllTemplates(cacheTemplates.templates);
        setInFlightTemplates(false);
      });
    }
  }, [activitySource]);

  useEffect(() => {
    if (activitySource === ActivitySourceType.ORDER) {
      if (cachedBlocks.blocks.length && cachedBlocks.companyId === companyId) {
        setBlocks(cachedBlocks.blocks);
        return;
      }

      setInFlightBlocks(true);
      templateApi
        .getAllBlocks()
        .then((result) => {
          cachedBlocks.blocks = result.map((block) => new TemplateBlock(block).toEmailBlock()).filter((b) => b.data);
          cachedBlocks.companyId = companyId;
          setBlocks(cachedBlocks.blocks);
        })
        .catch(() => {})
        .then(() => setInFlightBlocks(false));
    }
  }, [activitySource]);

  return (
    <ComposeEmailContext.Provider
      value={{
        groupedTemplatesByTemplateFolder,
        groupedEmailTemplatesByTemplateFolder,
        groupedSMSTemplatesByTemplateFolder,
        inFlightTemplates,
        inFlightBlocks,
        blocks,
        templates,
        automationRules,
        activitySource,
      }}
    >
      {children}
    </ComposeEmailContext.Provider>
  );
};

export { ComposeEmailProvider };
