import { Record } from 'immutable';

export default class Product extends Record({
  description: null,
  id: null,
  price: null,
  quantity: null,
  name: null
}) {
  static createProduct(data) {
    return new Product(data);
  }
}
