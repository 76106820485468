import { uuid } from '@elromcoinc/react-shared';
import { Record } from 'immutable';

import { TemplateBlockType } from 'admin/constants/TemplateBlockType';
import { capitalizeWord } from 'admin/utils';
import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { BlockDTO } from 'common-types/template-block/BlockDTO';
import { BlockEntity } from 'common-types/template-block/BlockEntity';
import { SortableTemplateBlock } from 'common-types/template-block/SortableTemplateBlock';

class TemplateBlock extends Record<BlockEntity>({
  id: 0,
  name: '',
  structureJson: '',
  body: '',
  type: TemplateBlockType.HEADER,
  metadata: { logo: null },
}) {
  constructor(data: Partial<BlockDTO> = {}) {
    let metadata = { logo: null };

    try {
      metadata = data.structureJson ? JSON.parse(data.structureJson) : { logo: null };
    } catch (e) {}

    super({ ...data, body: convertTemplateBuilderVariables(data.body || '', true), metadata });
  }

  toDTO(): BlockDTO {
    const { metadata, ...rest } = this.toJS();

    return {
      ...(rest as BlockDTO),
      body: convertTemplateBuilderVariables((rest.body as string) || ''),
      structureJson: JSON.stringify(metadata),
    };
  }

  toSortableTemplateBlock(): SortableTemplateBlock {
    const { name, body, id } = this.toJS() as BlockEntity;
    return { body, name, id: uuid(), backendId: id! };
  }

  toEmailBlock(): EmailBlock {
    const { type: category, name, body } = this;
    return { data: body, category: capitalizeWord(category), name, tags: [name] };
  }
}

export { TemplateBlock };
