import React, { useEffect, useState } from 'react';

import {
  AdminPermissions,
  BACKEND_DATE_FORMAT,
  Button,
  HeaderBigText,
  UI_DATE_FORMAT_SHORT,
  statusIds,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { add, format, parse, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import OrderAPI from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { MovesTable, SelectStatuses } from 'admin/components/AccountWindow/components';
import CreateOrder from 'admin/components/CreateOrder';
import { AccountLoginModal } from 'admin/components/OrderWindow/blocks/HistoryOverview/AccountLoginModal';
import { ConvertAccountModal } from 'admin/components/OrderWindow/modals/ConvertAccountModal';
import { AccountType } from 'admin/constants/AccountType';
import { allNewStatus } from 'admin/constants/OrderStatus';

const useStyles = makeStyles((theme) => ({
  selectBox: {
    minWidth: '12rem',
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      minWidth: '9rem',
      maxWidth: '18rem',
      marginLeft: '2rem',
    },
  },
}));

export const CurrentMovesHistory = ({ accountId, account, setAccount, orderWindowId, setCurrentPage, currentPage }) => {
  const classes = useStyles();
  const [isFlight, setIsFlight] = useState(false);
  const [orderSummaries, setOrderSummaries] = useState([]);
  const [openConvertAccountModal, setOpenConvertAccountModal] = useState(false);
  const canUpdateEmailAndPassword = useHasPermission(AdminPermissions.PERM_CAN_UPDATE_LOGIN_PASSWORD);
  const startDate = format(new Date(), BACKEND_DATE_FORMAT);
  const endDate = format(add(new Date(), { years: 100 }), BACKEND_DATE_FORMAT);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordersCount, setOrdersCount] = useState(0);
  const [sortBy, setSortBy] = useState('moveStartDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const { enqueueSnackbar } = useSnackbar();
  const [currentStatus, setCurrentStatus] = useState([]);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [openAccountLoginModal, setOpenAccountLoginModal] = useState(false);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const handleOpenConvertAccountModal = () => setOpenConvertAccountModal(true);
  const handleCloseConvertAccountModal = () => setOpenConvertAccountModal(false);

  const handleOpenCreateOrder = () => setOpenCreateOrderModal(true);
  const handleCloseCreateOrderModal = () => setOpenCreateOrderModal(false);

  const getOrdersForHistoryOverview = () => {
    const queryFilter = {
      'account.id': accountId,
      moveStartDate: [startDate, endDate],
      status: currentStatus.includes(statusIds.NEW) ? [...currentStatus, ...allNewStatus] : currentStatus,
    };

    if (accountId) {
      setIsFlight(true);
      OrderAPI.getOrderSummariesWithFilters({
        pageSize: 1000,
        sortBy,
        sortOrder,
        ...queryFilter,
      })
        .promise.then(({ pageElements, totalElements }) => {
          setOrderSummaries(
            pageElements.map((item) => ({
              ...item,
              moveStartDate: format(parse(item.startDate, BACKEND_DATE_FORMAT, new Date()), UI_DATE_FORMAT_SHORT),
              lastUpdate: format(parseISO(item.updatedDate), UI_DATE_FORMAT_SHORT),
            })),
          );
          setOrdersCount(totalElements);
          setIsFlight(false);
        })
        .catch(() => {
          enqueueSnackbar(`Can't get orders by current account`, { variant: 'error' });
          setIsFlight(false);
        });
    }
  };

  useEffect(() => {
    if (!isSessionExpired) {
      getOrdersForHistoryOverview();
      const interval = setInterval(getOrdersForHistoryOverview, 30e3);
      return () => clearInterval(interval);
    }
  }, [sortBy, sortOrder, startDate, endDate, currentStatus, isSessionExpired]);

  useEffect(() => {
    setPage(1);
  }, [currentStatus]);

  const onColumnSortChange = (changedColumn, direction) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const onSubmit = (data) => {
    setAccount(data);
  };

  const toggleOpenAccountLoginModal = (value) => () => {
    setOpenAccountLoginModal(value);
  };

  return (
    <>
      <Box mb={1}>
        <Box display="flex" flexWrap="wrap" alignItems="center" my={1} justifyContent="space-between">
          <Box display="flex" flexWrap="wrap" alignItems="center" my={1} justifyContent={isMobile && 'space-between'}>
            <>
              {account.type === AccountType.PRIVATE ? (
                <Box mb={1}>
                  <Button rounded color="primary" onClick={handleOpenConvertAccountModal}>
                    convert
                  </Button>
                  {openConvertAccountModal && (
                    <ConvertAccountModal
                      account={account}
                      onClose={handleCloseConvertAccountModal}
                      onSubmit={onSubmit}
                    />
                  )}
                </Box>
              ) : (
                <Box mb={1}>
                  <Button rounded color="primary" onClick={handleOpenCreateOrder}>
                    add new order
                  </Button>
                  {openCreateOrderModal && (
                    <CreateOrder requestingAccountId={accountId} onClose={handleCloseCreateOrderModal} />
                  )}
                </Box>
              )}
            </>
            <Box className={classes.selectBox}>
              <SelectStatuses currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} />
            </Box>
          </Box>
          <Button
            variant="contained"
            rounded
            color="primary"
            onClick={toggleOpenAccountLoginModal(true)}
            disabled={!canUpdateEmailAndPassword}
          >
            Account Login
          </Button>
        </Box>
        <HeaderBigText>
          <b>Current Moves</b>
        </HeaderBigText>
      </Box>
      <MovesTable
        isFlight={isFlight}
        orders={orderSummaries}
        onColumnSortChange={onColumnSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        ordersCount={ordersCount}
        rowsPerPage={rowsPerPage}
        page={page}
        orderWindowId={orderWindowId}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      {openAccountLoginModal && <AccountLoginModal onClose={toggleOpenAccountLoginModal(false)} />}
    </>
  );
};

CurrentMovesHistory.propTypes = {
  accountId: pt.number,
  setAccount: pt.func,
  // eslint-disable-next-line react/forbid-prop-types
  account: pt.object,
  orderWindowId: pt.number.isRequired,
  setCurrentPage: pt.func.isRequired,
  currentPage: pt.string.isRequired,
};

CurrentMovesHistory.defaultProps = {
  accountId: null,
  setAccount: null,
  account: null,
};
