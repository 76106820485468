import { SettingNames } from '@elromcoinc/react-shared';

const { MOVERS_SPEED_PARKING_DISTANCE_REDUCTION } = SettingNames;

const getParkingDistanceOptions = (settings: { [key: string]: any }) =>
  (settings?.[MOVERS_SPEED_PARKING_DISTANCE_REDUCTION] ?? [])?.reduce(
    (resultObj: any, item: any) => ({
      ...resultObj,
      [item.moveType]: resultObj[item.moveType]
        ? [...resultObj[item.moveType], [item.id, item.distanceLabel]]
        : [[item.id, item.distanceLabel]],
    }),
    {},
  );

export { getParkingDistanceOptions };
