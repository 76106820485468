import React, { useEffect, useState } from 'react';

import {
  AccountsPermissions,
  BodyBigText,
  BodySmallText,
  BodyText,
  CircularProgress,
  IconButton,
  Link,
  OrderStatuses,
  statusesById,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemText, Popover, TablePagination, Tooltip, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useDispatch } from 'react-redux';

import OrderAPI from 'admin/api/OrderAPI';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { MultiDayIndicator } from 'admin/components/MultiDayIndicator';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    maxHeight: '24rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  wrapperNotFound: {
    maxHeight: '4.18rem',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleOrdersMenu: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '9px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    flex: '0 1 auto',
    [theme.breakpoints.up('sm')]: {
      minWidth: '22rem',
    },
  },
  totalOrdersList: {
    flex: '1 1 auto',
    position: 'relative',
    height: '100%',
  },
  totalOrdersListInner: {
    position: 'absolute',
    overflow: 'auto',
    overflowX: 'hidden',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  totalOrdersPagination: {
    flex: '0 1 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  iconButton: {
    padding: '9px',
  },
  listItemOrderInfo: {
    padding: '.2rem 1rem',
    cursor: 'pointer',
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemStatuses: {
    backgroundColor: theme.palette.grey[100],
    padding: '.2rem 1rem',
  },
  ordersInfo: {
    display: 'grid',
    gridTemplateColumns: '45% 30% 25%',
    minWidth: '20rem',
    [theme.breakpoints.up('md')]: {
      minWidth: '21rem',
    },
  },
  selectedItem: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: '12px',
  },
  pagination: {
    fontSize: `${theme.typography.body1.fontSize}`,
  },
  inputPagination: {
    marginLeft: 0,
    marginRight: '9px',
    fontSize: `${theme.typography.body1.fontSize}`,
  },
  actionPagination: {
    marginLeft: '10px',
    '& > .MuiIconButton-root': {
      marginLeft: 0,
      padding: '7px',
    },
    '& > .MuiButtonBase-root': {
      cursor: 'pointer',
      pointerEvents: 'inherit',
    },
  },
  toolbarPagination: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    minHeight: '25px',
    '& > .MuiInputBase-root > .MuiSelect-root': {
      backgroundColor: `${theme.palette.common.white}`,
    },
  },
}));

export const TotalOrdersView = ({ accountStatistics, id, isFlight }) => {
  const { totalOrders, statusCounts = [] } = accountStatistics;
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const dispatch = useDispatch();
  const [orderStatistics, setOrderStatistics] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);

  useEffect(() => {
    if (statusCounts.length) {
      OrderAPI.getOrderSummariesWithFilters({
        pageSize,
        pageIndex,
        'account.id': id,
        sortOrder: 'desc',
      })
        .promise.then(({ pageElements, totalElements }) => {
          setOrderStatistics(
            OrderStatuses.map(({ id: statusId }) => statusCounts.find((it) => it.status === statusId))
              .filter(Boolean)
              .map((item) => ({
                ...item,
                orders: pageElements.filter(({ status }) => status === item.status),
              }))
              .filter((it) => it.orders.length > 0),
          );
          setCount(totalElements);
        })
        .catch(() => {
          enqueueSnackbar(`Can't get orders for current account`, { variant: 'error' });
        });
    }
  }, [pageSize, pageIndex, statusCounts]);

  const handleOpenTotalOrdersListPopover = ({ currentTarget }) => setAnchorEl(currentTarget);

  const handleCloseTotalOrdersList = () => setAnchorEl(null);

  const open = Boolean(anchorEl);

  const handleChangePage = (event, newPage) => setPageIndex(newPage);

  const handleChangeRowsPerPage = ({ target: { value } }) => setPageSize(parseInt(value, 10));

  const handleListItemClick = (order) => setSelectedOrder(order);

  const handlerOpenOrder = (order) => {
    handleCloseTotalOrdersList();
    dispatch(openOrder(order.orderId));
  };

  return (
    <>
      <Tooltip title="Show more">
        <Link
          underline="none"
          data-testid="totalOrders"
          disabled={!canEditOtherUsersAccounts}
          onClick={handleOpenTotalOrdersListPopover}
        >
          <Box display="flex" alignItems="center" position="relative">
            <BodyText>Total Orders:&nbsp;</BodyText>
            {isFlight ? (
              <CircularProgress size={18} />
            ) : (
              <BodyText>
                <b>{totalOrders}</b>
              </BodyText>
            )}
          </Box>
        </Link>
      </Tooltip>
      <Popover
        id="total-orders-popover"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={anchorEl}
        classes={totalOrders === 0 ? { paper: classes.wrapperNotFound } : { paper: classes.wrapper }}
        onClose={handleCloseTotalOrdersList}
      >
        <Box display="flex" flexFlow="column" height="100%">
          <Box className={classes.titleOrdersMenu}>
            <Box className={classes.titleBox}>
              <Box my={1}>
                <BodyBigText color="initial">
                  <b>Total Orders</b>
                </BodyBigText>
              </Box>
              <Tooltip title="Close list" arrow placement="bottom">
                <IconButton color="primary" size="small" onClick={handleCloseTotalOrdersList}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className={classes.totalOrdersList}>
              <Box className={classes.totalOrdersListInner}>
                <List disablePadding>
                  {totalOrders > 0 ? (
                    orderStatistics.map((item) => (
                      <>
                        <ListItem key={item.status} classes={{ root: classes.listItemStatuses }}>
                          <ListItemText
                            primary={
                              <>
                                <BodySmallText>
                                  <b>{statusesById[item.status].label}</b>
                                </BodySmallText>
                              </>
                            }
                          />
                        </ListItem>
                        {item.orders.map((order) => (
                          <ListItem
                            classes={{ root: classes.listItemOrderInfo, selected: classes.selectedItem }}
                            selected={selectedOrder && selectedOrder.orderId === order.orderId}
                            onClick={() => {
                              handleListItemClick(order);
                              selectedOrder && selectedOrder.orderId === order.orderId && handlerOpenOrder(order);
                            }}
                            key={order.orderId}
                          >
                            <ListItemText
                              primary={
                                <>
                                  <Box className={classes.ordersInfo}>
                                    <BodySmallText>Order #: {order.orderNumber}</BodySmallText>
                                    {
                                      order.serviceSummaries.map((it) => (
                                        <>
                                          <BodySmallText key={it.serviceName}>
                                            {it.serviceName}
                                            <MultiDayIndicator visible={order.serviceSummaries.length > 1} />
                                          </BodySmallText>
                                          <BodySmallText key={it.serviceDate}>
                                            {format(parseISO(`${it.serviceDate}`), 'P')}
                                          </BodySmallText>
                                        </>
                                      ))[0]
                                    }
                                  </Box>
                                </>
                              }
                            />
                          </ListItem>
                        ))}
                      </>
                    ))
                  ) : (
                    <ListItem classes={{ root: classes.listItemOrderInfo }}>
                      <ListItemText primary="No matching records founds" classes={{ root: classes.notFound }} />
                    </ListItem>
                  )}
                </List>
              </Box>
            </Box>
            <Box className={classes.totalOrdersPagination}>
              {!!totalOrders && (
                <TablePagination
                  classes={{
                    root: classes.pagination,
                    toolbar: classes.toolbarPagination,
                    input: classes.inputPagination,
                  }}
                  component="div"
                  count={count}
                  page={pageIndex}
                  onChangePage={handleChangePage}
                  rowsPerPage={pageSize}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

TotalOrdersView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  accountStatistics: pt.object.isRequired,
  id: pt.number.isRequired,
};
