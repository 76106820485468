import axios from 'axios';

import EmployeeRecord from 'admin/entities/Employee';
import { BasicEmployeeDto, EmployeeCommission, EmployeeQuery } from 'common-types';

class UserAPI {
  createUserAPI(u: EmployeeRecord) {
    return axios.post('/api/admin/employee', u);
  }

  updateUserAPI(u: EmployeeRecord) {
    return axios.put('/api/admin/employee', u);
  }

  getEmployeeProfile(id: number) {
    return axios.get<EmployeeProfileDTO, EmployeeProfileDTO>(`/api/admin/employees/${id}/profile`);
  }

  updateEmployeeProfile(employeeProfile: EmployeeProfileDTO) {
    return axios.put(`/api/admin/employee/${employeeProfile.employeeId}/profile`, employeeProfile);
  }

  listAllManagersAPI() {
    return axios.get(`/api/admin/employees/names`, {
      params: { pageSize: 1000, active: true },
    });
  }

  listAllUsersAPI(query: Partial<EmployeeQuery> = {}) {
    return axios.get<PageDTO<BasicEmployeeDto>, PageDTO<BasicEmployeeDto>>(`/api/admin/employees`, {
      params: { pageSize: 1000, ...query },
    });
  }

  listElromcoPermissions(accessGroup: string) {
    return axios.get(`/api/admin/company/permissions/${accessGroup}`);
  }

  retrieveEmployee(employeeId: number) {
    return axios.get<EmployeeRecord, EmployeeRecord>(`/api/admin/employees/${employeeId}`);
  }

  getEmployeeCommissions(employeeId: number) {
    return axios.get<EmployeeCommission[], EmployeeCommission[]>(`/api/admin/employee/${employeeId}/commissions`);
  }

  checkExistUsername(username: string) {
    return axios.get<boolean, boolean>(`/api/admin/employee/username/${username}/exists`);
  }
}

export default new UserAPI();
