import { ServicesPermissions, useHasPermission } from '@elromcoinc/react-shared';

const useInHomeEstimatePermissions = () => {
  const canEditInHomeEstimate = useHasPermission(ServicesPermissions.PERM_CAN_EDIT_IN_HOME_ESTIMATE);
  const canAssignInHomeEstimate = useHasPermission(ServicesPermissions.PERM_CAN_ASSIGN_IN_HOME_ESTIMATE);
  const canChangeStatusInHomeEstimate = useHasPermission(
    ServicesPermissions.PERM_CAN_CHANGE_THE_STATUS_OF_AN_IN_HOME_ESTIMATE,
  );
  const canCancelInHomeEstimate = useHasPermission(ServicesPermissions.PERM_CAN_CANCEL_AN_IN_HOME_ESTIMATE);

  return {
    canEdit: canEditInHomeEstimate,
    canAssign: canAssignInHomeEstimate,
    canChangeStatus: canChangeStatusInHomeEstimate,
    canCancel: canCancelInHomeEstimate,
  };
};

export default useInHomeEstimatePermissions;
