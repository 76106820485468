import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { TimeSlotCellHeight, TimeSlotCellWidth } from 'admin/components/Calendar/common/CalendarConstants';

export const TruckRow = styled(Box)<{
  $selected?: boolean;
  $height?: number;
  $minHeight?: number;
  grey?: boolean;
  $width?: number;
  $minWidth?: number;
}>`
  && {
    ${({ grey = false, theme }) => (grey ? `background-color: ${theme.palette.grey[100]};` : '')}
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    width: ${({ $width = TimeSlotCellWidth }) => $width}px;
    min-width: ${({ $minWidth = TimeSlotCellWidth }) => $minWidth}px;
    ${({ $selected }) => ($selected ? 'border: 2px dashed;' : '')};
    min-height: ${({ $minHeight = TimeSlotCellHeight }) => $minHeight}px;
    height: ${({ $height = TimeSlotCellHeight }) => $height}px;
  }
`;
