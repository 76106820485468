import { ServiceType } from '@elromcoinc/react-shared';

import { AutomationRule } from 'common-types';

/** @deprecated Please use ServiceType from react-shared instead */
const services = Object.freeze({
  LOCAL_MOVING: 'LOCAL_MOVING',
  OVERNIGHT_STORAGE: 'OVERNIGHT_STORAGE',
  LOADING_HELP: 'LOADING_HELP',
  UNLOADING_HELP: 'UNLOADING_HELP',
  MOVING_AND_STORAGE: 'MOVING_AND_STORAGE',
  PACKING_DAY: 'PACKING_DAY',
  JUNK_REMOVAL: 'JUNK_REMOVAL',
  LD_INTERSTATE: 'LD_INTERSTATE',
  LD_INTRASTATE: 'LD_INTRASTATE',
});

export default services;

enum ServiceTypeNames {
  LOCAL_MOVING = 'Local Moving',
  OVERNIGHT_STORAGE = 'Overnight Storage',
  LOADING_HELP = 'Loading Help',
  UNLOADING_HELP = 'Unloading Help',
  MOVING_AND_STORAGE = 'Moving & Storage',
  PACKING_DAY = 'Packing Day',
  LD_INTERSTATE = 'Long Distance Interstate Move',
  LD_INTRASTATE = 'Long Distance Intrastate Move',
}

const ServiceTypeOptions: [ServiceType, ServiceTypeNames][] = [
  ServiceType.LOCAL_MOVING,
  ServiceType.OVERNIGHT_STORAGE,
  ServiceType.LOADING_HELP,
  ServiceType.UNLOADING_HELP,
  ServiceType.MOVING_AND_STORAGE,
  ServiceType.PACKING_DAY,
  ServiceType.LD_INTERSTATE,
  ServiceType.LD_INTRASTATE,
].map((st) => [st, ServiceTypeNames[st]]);

const serviceTypeNotificationMap = {
  [ServiceType.LOCAL_MOVING]: 'LOCAL_MOVE',
  [ServiceType.OVERNIGHT_STORAGE]: 'OVERNIGHT',
  [ServiceType.LOADING_HELP]: '_LOADING_',
  [ServiceType.UNLOADING_HELP]: 'UNLOADING_HELP',
  [ServiceType.MOVING_AND_STORAGE]: '_MOVING_',
  [ServiceType.PACKING_DAY]: 'PACKING_DAY',
  [ServiceType.LD_INTERSTATE]: 'LD_INTERSTATE',
  [ServiceType.LD_INTRASTATE]: 'LD_INTRASTATE',
};

const serviceTypeMapNotificationLongDistance: Partial<Record<ServiceType, string>> = {
  [ServiceType.LD_INTERSTATE]: 'LONG_DISTANCE_INTERSTATE',
  [ServiceType.LD_INTRASTATE]: 'LONG_DISTANCE_INTRASTATE',
};

const isMatchNotificationByName = (automationRule: AutomationRule, serviceType: ServiceType): boolean => {
  return (
    automationRule.shortName.includes(serviceTypeNotificationMap[serviceType]) ||
    (!!serviceTypeMapNotificationLongDistance[serviceType] &&
      automationRule.shortName.includes(serviceTypeMapNotificationLongDistance[serviceType]!))
  );
};

export { ServiceTypeNames, ServiceTypeOptions, isMatchNotificationByName };

/** @deprecated please use it from react-shared */
export function isLongDistanceService(name: ServiceType) {
  return name === ServiceType.LD_INTERSTATE || name === ServiceType.LD_INTRASTATE;
}

/** @deprecated please use it from react-shared */
export function isLoadingService(name: ServiceType) {
  return name === ServiceType.LOADING_HELP;
}

/** @deprecated please use it from react-shared */
export function isLocalMove(name: ServiceType) {
  return name === ServiceType.LOCAL_MOVING;
}

/** @deprecated please use it from react-shared */
export function isUnloadingOrPackingService(name: ServiceType) {
  return name === ServiceType.PACKING_DAY || name === ServiceType.UNLOADING_HELP;
}

/** @deprecated please use it from react-shared */
export function isLoadingOrPackingService(name: ServiceType) {
  return name === ServiceType.PACKING_DAY || name === ServiceType.LOADING_HELP;
}

/** @deprecated please use it from react-shared */
export function isPackingService(name: ServiceType) {
  return name === ServiceType.PACKING_DAY;
}

/** @deprecated please use it from react-shared */
export function isUnloadingService(name: ServiceType) {
  return name === ServiceType.UNLOADING_HELP;
}

/** @deprecated please use it from react-shared */
export function isMovingAndStorageService(name: ServiceType) {
  return name === ServiceType.MOVING_AND_STORAGE;
}

/** @deprecated please use it from react-shared */
export function isOvernightService(name: ServiceType) {
  return name === ServiceType.OVERNIGHT_STORAGE;
}
