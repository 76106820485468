import React, { useRef } from 'react';

import { BACKEND_DATE_FORMAT, Truck, toDate } from '@elromcoinc/react-shared';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { addDays, format, startOfWeek } from 'date-fns';
import { List } from 'immutable';

import {
  Container,
  HeaderRow,
  HoursContainer,
  Spacer,
  SpacerColumn,
  SpacerWithoutBorder,
  TruckHeaderRow,
  TruckRow,
  filterJobByDate,
  getJobForOrderAndDate,
  getJobForOrderAndDeliveryDate,
  getTrucksToRender,
  renderHours,
  renderTruck,
  renderTruckName,
} from '../common';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import useScrollTo7Am from 'admin/components/OrderWindow/SchedulerBox/hooks/useScrollTo7Am';

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

const useStyles = makeStyles((theme) => ({
  dayCell: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
      marginTop: '2px',
    },
  },
  trucksNamesPerDay: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
    },
  },
  trucksPerDay: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
      minWidth: '1632px',
    },
  },
}));

interface WeekViewProps {
  trucks: Truck[];
  jobs: Job[];
  date: Date;
  dense?: boolean;
  orderId?: number;
  renderJobTooltip?: (job: Job, jobComponent: JSX.Element) => JSX.Element;
  onTruckClick: any;
}

const dateFormat = BACKEND_DATE_FORMAT;

function WeekView({ trucks, onTruckClick, date, jobs, dense = true, orderId = -1, renderJobTooltip }: WeekViewProps) {
  const classes = useStyles();
  const scrollRef = useRef<HTMLElement | null>(null);
  useScrollTo7Am(scrollRef);

  const jobsByDate = jobs.reduce((map, job) => {
    const day = format(job.date as Date, dateFormat);
    return { ...map, [day]: [...(map[day] || []), job.checkIfDeliveryJob(job.date as Date)] };
  }, {} as { [key: string]: Job[] });

  jobs.forEach((job) => {
    job.getDeliveryDays().forEach((day) => {
      const dayStr = format(day, dateFormat);
      const dayJobs = jobsByDate[dayStr] || [];

      if (!dayJobs.find((it) => it.orderId === job.orderId)) {
        jobsByDate[dayStr] = [...(jobsByDate[dayStr] || []), job.checkIfDeliveryJob(day)];
      }
    });
  });

  const jobsToTruck = (day: string) =>
    trucks.reduce(
      (acc, truck) => ({
        ...acc,
        [truck.id!]: (jobsByDate[day] || []).filter(filterJobByDate(toDate(day, dateFormat)!, truck)),
      }),
      {} as { [key: string]: Job[] },
    );

  const combineJobsByDateAndTruck = (result: { [key: string]: { [key: string]: Job[] } }, day: string) => ({
    ...result,
    [day]: jobsToTruck(day),
  });
  const jobsByDateAndTruck = Object.keys(jobsByDate).reduce(
    combineJobsByDateAndTruck,
    {} as { [key: string]: { [key: string]: Job[] } },
  );

  const weekDays = daysOfWeek.map((day) => addDays(startOfWeek(date), day));
  const truckJobsPerDay = weekDays.map((day) => format(day, dateFormat)).map((day) => jobsByDateAndTruck[day] || {});
  const weeKDayKeys = weekDays.map((day) => format(day, dateFormat));

  const trucksToRender = getTrucksToRender(trucks);

  const renderDay = (day: Date, idx: number) => {
    const key = format(day, dateFormat);
    const availableTrucks = trucksToRender.filter((truck) => truck.isAvailable(day));
    return (
      <TruckRow
        key={key}
        heightSize={availableTrucks.length - 1}
        dense
        grey={idx % 2 === 0}
        className={classes.dayCell}
      >
        {/* @ts-ignore */}
        <SpacerColumn $width={100}>
          <Typography>{format(day, 'EEEE')}</Typography>
          <Typography>{format(day, 'LLLL, d')}</Typography>
        </SpacerColumn>
      </TruckRow>
    );
  };

  if (!trucks.length) {
    return null;
  }

  const selectedTrucks = weekDays.map(
    (day) => (jobs.filter(getJobForOrderAndDate(orderId, day))[0] || {}).truckIds || List<number>(),
  );
  const selectedDeliveryTrucks = weekDays.map(
    (day) => (jobs.filter(getJobForOrderAndDeliveryDate(orderId, day))[0] || {}).deliveryTruckIds || List<number>(),
  );

  return (
    <Container>
      <div>
        <TruckHeaderRow>
          {/* @ts-ignore */}
          <SpacerWithoutBorder $width={100} />
        </TruckHeaderRow>
        {weekDays.map(renderDay)}
      </div>
      <div>
        <TruckHeaderRow>
          <Spacer />
        </TruckHeaderRow>
        {truckJobsPerDay.map((truckJobs, idx) => {
          return (
            <Box key={weeKDayKeys[idx]} className={classes.trucksNamesPerDay}>
              {trucksToRender
                .filter((truck) => truck.isAvailable(weekDays[idx]))
                .map((truck) =>
                  renderTruckName({
                    onTruckClick,
                    dense,
                    truckJobs,
                    selectedTrucks: selectedTrucks[idx],
                    selectedDeliveryTrucks: selectedDeliveryTrucks[idx],
                    date: weekDays[idx],
                  })(truck, idx),
                )}
            </Box>
          );
        })}
      </div>
      {/* @ts-ignore */}
      <HoursContainer ref={scrollRef}>
        <HeaderRow>{renderHours()}</HeaderRow>
        {truckJobsPerDay.map((truckJobs, idx) => {
          return (
            <Box key={weeKDayKeys[idx]} className={classes.trucksPerDay}>
              {trucksToRender
                .filter((truck) => truck.isAvailable(weekDays[idx]))
                .map((truck) => renderTruck(dense, truckJobs, orderId, renderJobTooltip)(truck, idx))}
            </Box>
          );
        })}
      </HoursContainer>
    </Container>
  );
}

export default WeekView;
