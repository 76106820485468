import React, { FC } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';

import { TaskStatus } from 'common-types/Task';

import { TaskStatusLabel } from './TaskStatusLabel';

const useStyles = makeStyles(({ palette }) => ({
  completedTask: {
    color: palette.success.main,
  },
}));

interface TaskStatusProps {
  status: TaskStatus;
}

const TaskStatusRow: FC<TaskStatusProps> = ({ status }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" mr={1}>
        {status === TaskStatus.UNREAD && <RadioButtonUnchecked fontSize="small" color="primary" />}
        {status === TaskStatus.READ && <CheckCircle fontSize="small" color="primary" />}
        {status === TaskStatus.COMPLETED && <CheckCircle fontSize="small" className={classes.completedTask} />}
      </Box>
      <Box display="flex">
        <BodyText>{TaskStatusLabel[status as keyof typeof TaskStatusLabel]}</BodyText>
      </Box>
    </Box>
  );
};

export default TaskStatusRow;
