import { useEffect } from 'react';

import { MoveType, ServiceType, isMovingAndStorageService, isOvernightService } from '@elromcoinc/react-shared';
import { FieldValues, UnpackNestedValue } from 'react-hook-form';

import {
  DESIRED_DELIVERY_DATE,
  MOVE_TYPE,
  SERVICE_ID,
  SERVICE_NAME,
} from 'admin/components/Settings/components/TestCalculator/TestCalculatorLabel';
import { getServiceOptions } from 'admin/selectors';
import { MoveInfo } from 'common-types';

interface useUpdateServiceTypeProps {
  isOvernightServiceType: () => boolean;
  isMovingAndStorageServiceType: () => boolean;
  isInStorageDays: () => boolean;
  reset: (values: UnpackNestedValue<FieldValues>) => void;
  trigger: (values: keyof MoveInfo) => Promise<boolean>;
}

const useUpdateServiceType = (
  service: ServiceType,
  values: Record<string, any>,
  moveType: MoveType,
  serviceOptions: ReturnType<typeof getServiceOptions>,
  options: useUpdateServiceTypeProps,
) => {
  const { reset, trigger, isOvernightServiceType, isInStorageDays, isMovingAndStorageServiceType } = options;
  const getResetCallback = (value: UnpackNestedValue<FieldValues>) => reset(value);
  const getTriggerCallback = (fieldName: keyof MoveInfo) => trigger(fieldName);

  useEffect(() => {
    const [newServiceId, , newServiceName] = serviceOptions?.find((item: any) => isOvernightService(item[2])) ?? [];

    if (isOvernightServiceType()) {
      const newFormValues = {
        ...values,
        [MOVE_TYPE]: moveType,
        [SERVICE_ID]: newServiceId?.toString(),
        [SERVICE_NAME]: newServiceName as ServiceType,
      };
      getResetCallback(newFormValues);
    }

    if (isInStorageDays()) {
      getTriggerCallback(DESIRED_DELIVERY_DATE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOvernightServiceType, isInStorageDays, reset, trigger, values, moveType, serviceOptions]);

  useEffect(() => {
    const [newStorageServiceId, , newStorageServiceName] =
      serviceOptions?.find((item: any) => isMovingAndStorageService(item[2])) ?? [];

    if (isMovingAndStorageServiceType()) {
      const newFormValues = {
        ...values,
        [MOVE_TYPE]: moveType,
        [SERVICE_ID]: newStorageServiceId?.toString(),
        [SERVICE_NAME]: newStorageServiceName as ServiceType,
      };
      getResetCallback(newFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMovingAndStorageServiceType, reset, values, moveType, serviceOptions]);
};

export { useUpdateServiceType };
