import React from 'react';

import { Data, useDndContext, useDroppable } from '@dnd-kit/core';
import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { DragAndDropType } from 'common-types';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    width: '100%',
  },
  draggingStart: ({ borderColor }: BorderColorProps) => ({
    border: `1px solid ${borderColor}`,
  }),
  over: {
    border: `1px solid ${palette.primary.dark}`,
  },
  transparentBorder: {
    border: `1px solid transparent`,
  },
}));

interface DroppableBlockProps {
  children: React.ReactNode;
  id: number | string;
  left?: string;
  data: Data | null;
  isDraggingStart: boolean;
  borderColor?: BorderColorProps;
  minWidth?: number | null;
  className?: string;
  isNotOverCondition?: string;
  highlighting?: HighlightingClassProps;
  transparentBorder?: boolean;
  type?: DragAndDropType;
  disabled?: boolean;
  'data-testid'?: string;
}

interface HighlightingClassProps {
  draggingStart?: string;
  over?: string;
}

interface BorderColorProps {
  borderColor: string;
}

export const DroppableBlock = ({
  children,
  id,
  data,
  isDraggingStart,
  borderColor,
  minWidth = null,
  className = '',
  isNotOverCondition = '',
  highlighting,
  transparentBorder = false,
  type,
  disabled = false,
  'data-testid': dataTestId,
}: DroppableBlockProps) => {
  const theme = useTheme();
  const defaultBorderColor = borderColor || { borderColor: theme.palette.grey[600] };
  const classes = useStyles(defaultBorderColor);
  const { active } = useDndContext();
  const { isOver, setNodeRef } = useDroppable({
    id: id,
    data: type
      ? {
          type,
          data: data ?? {},
        }
      : data ?? {},
    disabled: disabled || !active?.data?.current?.supports?.includes(type),
  });

  const draggingStart = highlighting?.draggingStart || classes.draggingStart;
  const over = highlighting?.over || classes.over;

  return (
    <Box
      key={id}
      data-testid={dataTestId}
      minWidth={minWidth}
      /* @ts-ignore */
      ref={setNodeRef}
      className={clsx(classes.root, 'el-droppable-block', className, {
        [draggingStart]: isDraggingStart && !isOver,
        [over]: isOver,
        [isNotOverCondition]: !isOver,
        [classes.transparentBorder]: transparentBorder,
      })}
    >
      {children}
    </Box>
  );
};
