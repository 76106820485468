import React from 'react';

import {
  ActivitySourceType,
  BACKEND_DATE_FORMAT,
  BodySmallText,
  BodyText,
  Checkbox,
  CreditCardPaymentProfileDto,
  PaymentActivityDto,
  PaymentPermissions,
  PaymentStatus,
  UI_DATE_TIME_FORMAT,
  formatCurrency,
  toDate,
  toDateString,
  useHasPermission,
} from '@elromcoinc/react-shared';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { parseISO } from 'date-fns';

import { makePaymentDescription } from 'admin/utils/makePaymentDescription';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    zIndex: 1,
    marginTop: 8,
    '& table tbody': {
      '& > td': {
        border: 'none',
      },
      '& tr': {
        cursor: 'pointer',
        height: '45px',
      },
      '& tr:nth-child(even)': {
        backgroundColor: '#f5f5f6',
      },
      '& tr:hover': {
        backgroundColor: palette.grey[200],
      },
      '& tr.selected': {
        backgroundColor: palette.primary.light,
      },
    },
    '& table thead': {
      backgroundColor: palette.primary.main,
      border: 'none',
      height: '45px',
      '& p': {
        color: palette.common.white,
      },
    },
  },
  borderTopLeftRadius: {
    borderTopLeftRadius: spacing(0.75),
  },
  borderTopRightRadius: {
    borderTopRightRadius: spacing(0.75),
  },
}));

interface PaymentsTableProps {
  data: PaymentActivityDto[];
  togglePending: (paymentId: number) => () => void;
  selectedRow?: number;
  onRowClick(rowIdx: number): void;
  isClosing?: boolean;
  allowToMakePaymentAvailableOnBOL?: boolean;
  toggleShowOnBOL?: (paymentId: number) => () => void;
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({
  data,
  togglePending,
  onRowClick,
  selectedRow,
  isClosing = false,
  allowToMakePaymentAvailableOnBOL = false,
  toggleShowOnBOL,
}) => {
  const classes = useStyles();
  const canMarkAsPending = useHasPermission(PaymentPermissions.PERM_CAN_MARK_PAYMENT_AS_PENDING);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  function renderAdditionalInfo(dto: PaymentActivityDto) {
    if (dto.activityType === 'PaymentCheck') {
      return <BodySmallText>Check number:&nbsp;{dto.checkNumber}</BodySmallText>;
    }
    if (dto.activityType === 'PaymentCreditCardRecord') {
      return (
        <BodySmallText>
          XXXX{dto.lastFourDigits}&nbsp;(exp: {toDateString(toDate(dto.expiration, BACKEND_DATE_FORMAT)!, 'MM/yy')})
        </BodySmallText>
      );
    }
    if (dto.activityType === 'OnlinePaymentTransaction') {
      const paymentMethod = dto.paymentMethod as CreditCardPaymentProfileDto;
      return (
        <>
          <BodySmallText>
            {paymentMethod.firstName}&nbsp;{paymentMethod.lastName}&nbsp;XXXX{paymentMethod.lastFourDigits}
            &nbsp;(exp:&nbsp;
            {toDateString(toDate(paymentMethod.expiration, BACKEND_DATE_FORMAT)!, 'MM/yy')})
          </BodySmallText>
          {dto.refundAmount > 0 && (
            <>
              <BodySmallText>
                Transaction amount: <b>{`$${formatCurrency(dto.amount)}`}</b>
              </BodySmallText>
              <BodySmallText>
                Refunded amount: <b>{`$${formatCurrency(dto.refundAmount)}`}</b>
              </BodySmallText>
            </>
          )}
        </>
      );
    }
    if (dto.activityType === 'PaymentCustom') {
      return <BodySmallText>{dto.paymentCustomSettings?.name}</BodySmallText>;
    }
    return null;
  }
  function renderClosingPaymentInformation(dto: PaymentActivityDto) {
    if (!isClosing) {
      return '';
    }

    let placeInformation = 'Sales';

    if (dto.activitySources.find((as) => as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING)) {
      placeInformation = 'Bill of lading';
    } else if (dto.activitySources.find((as) => as.activitySource === ActivitySourceType.ORDER_CLOSING)) {
      placeInformation = 'Closing';
    }

    return `(${placeInformation})`;
  }

  function renderStatus(dto: PaymentActivityDto) {
    if (dto.activityType === 'OnlinePaymentTransaction' && dto.refundAmount > 0) {
      return <BodyText>Refund</BodyText>;
    }
    switch (dto.status) {
      case PaymentStatus.REFUND:
        return <BodyText>Refund</BodyText>;
      case PaymentStatus.VOIDED:
        return <BodyText>Voided</BodyText>;
      default:
        return (
          <Checkbox
            color="primary"
            size="small"
            disabled={!canMarkAsPending}
            checked={dto.status === PaymentStatus.PENDING}
            onClick={(e) => e.stopPropagation()}
            onChange={togglePending(dto.id)}
          />
        );
    }
  }

  return (
    <Paper square className={clsx(classes.root, 'applyRadius')}>
      <Table size="small">
        <TableHead className="applyTopRadius">
          <TableRow>
            <TableCell className={classes.borderTopLeftRadius}>
              <BodyText color="inherit">
                <b>Date</b>
              </BodyText>
            </TableCell>
            {!isMobile && (
              <TableCell>
                <BodyText color="inherit">
                  <b>Details</b>
                </BodyText>
              </TableCell>
            )}
            <TableCell align="right">
              <BodyText color="inherit">
                <b>Amount</b>
              </BodyText>
            </TableCell>
            <TableCell
              className={!(allowToMakePaymentAvailableOnBOL && toggleShowOnBOL) ? classes.borderTopRightRadius : ''}
            >
              <BodyText color="inherit">
                <b>Pending</b>
              </BodyText>
            </TableCell>
            {allowToMakePaymentAvailableOnBOL && toggleShowOnBOL && (
              <TableCell>
                <BodyText color="inherit">
                  <b>Show on BOL</b>
                </BodyText>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <BodyText align="center">No payments have been recorded...</BodyText>
              </TableCell>
            </TableRow>
          )}
          {data.map((dto, rowIdx) => (
            <TableRow
              key={dto.id}
              className={clsx({ selected: selectedRow === rowIdx })}
              onClick={() => onRowClick(rowIdx)}
            >
              <TableCell>
                <BodyText>{toDateString(parseISO(dto.date as string), UI_DATE_TIME_FORMAT)}</BodyText>
              </TableCell>
              {!isMobile && (
                <TableCell>
                  <BodyText style={{ textTransform: 'capitalize' }}>{`${makePaymentDescription(dto)} ${
                    dto.type === 'DEPOSIT' ? '(Deposit)' : ''
                  } ${renderClosingPaymentInformation(dto)}`}</BodyText>
                  {renderAdditionalInfo(dto)}
                  {!!dto.message && <BodySmallText>Note:&nbsp;{dto.message}</BodySmallText>}
                </TableCell>
              )}
              <TableCell align="right">{`$${formatCurrency(dto.amount)}`}</TableCell>
              <TableCell>{renderStatus(dto)}</TableCell>
              {allowToMakePaymentAvailableOnBOL && !!toggleShowOnBOL && (
                <TableCell>
                  <Checkbox
                    color="primary"
                    size="small"
                    disabled={
                      dto.status === PaymentStatus.VOIDED ||
                      !dto.activitySources.some((as) => as.activitySource === ActivitySourceType.ORDER_CLOSING)
                    }
                    checked={dto.activitySources.some(
                      (as) => as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING,
                    )}
                    onClick={(e) => e.stopPropagation()}
                    onChange={toggleShowOnBOL(dto.id)}
                  />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default PaymentsTable;
