import React from 'react';

import JobEntity from 'admin/components/OrderWindow/SchedulerBox/Job';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import { Job as JobComponent } from 'admin/components/OrderWindow/SchedulerBox/common/index';

interface RenderJobProps {
  truckId: number;
  dense: boolean;
  orderId: number;
  renderJobTooltip?: (job: Job, jobComponent: JSX.Element) => JSX.Element;
  hasSelectedJob: boolean;
  selectedJobLevel: number;
  isUnassignedTruck: boolean;
}

export const renderJob =
  ({
    truckId,
    dense: denseProp,
    orderId,
    renderJobTooltip,
    hasSelectedJob,
    selectedJobLevel,
    isUnassignedTruck,
  }: RenderJobProps) =>
  (job: JobEntity) => {
    const jobComponent = (
      <JobComponent
        key={job.uuid}
        isUnassignedTruck={isUnassignedTruck}
        orderId={orderId}
        truckId={truckId}
        job={job}
        dense={denseProp}
        hasSelectedJob={hasSelectedJob}
        selectedJobLevel={selectedJobLevel}
      />
    );
    return renderJobTooltip ? renderJobTooltip(job, jobComponent) : jobComponent;
  };
