import React, { useState } from 'react';

import {
  AdminPermissions,
  BodyText,
  Button,
  IconButton,
  JobType,
  LONG_DISTANCE_PLANNER,
  Link,
  isLongDistanceService,
  statusIds,
  storageUtil,
  useConfirm,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Divider, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { useSnackbar } from 'notistack';

import { CloseOrder } from 'admin/components/OrderWindow/blocks/Closing/CloseOrder';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { IframeModal } from 'admin/components/OrderWindow/modals/IframeModal';
import foremanPage from 'admin/config/ForemanPage';
import { getServiceRosterClosingPropertyName } from 'admin/utils';
import { TripPlanerType } from 'common-types';
import { getUrl } from 'common/utils';

const useStyles = makeStyles(() => ({
  closingButton: {
    width: '200px',
  },
}));

export const ClosingBar = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { fetchOrderInfo } = useOrderChangeSet();
  const { onChange } = useOrderChangeSet();
  const classes = useStyles();
  const [inFlight, setInFlight] = useState(false);
  const { order } = useOrderState();
  const { isClosing, setIsClosing, hasAvailableContracts, showClosingButton } = useOrderClosingContext();
  const [foremanUrl, setForemanUrl] = useState('');
  const canSeeClosingTab = useHasPermission(AdminPermissions.PERM_CLOSING_TAB) as boolean;
  const quote = order?.getServiceQuote(serviceIndex)!;
  const tripPlanerType = order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.get(LONG_DISTANCE_PLANNER);
  const { enqueueSnackbar } = useSnackbar();
  const isLongDistance = isLongDistanceService(order?.getServiceType(serviceIndex));
  const { confirm: confirmWarning, renderDialog: renderDialogConfirmationWarning } = useConfirm({
    title: 'Warning',
    message:
      'You are about to activate closing. This will lock your sales and any future changes would be done in closing. Do you wish to proceed?',
    confirmTitle: 'Ok',
    cancelTitle: 'Cancel',
  });

  if (!order?.closingOrderDetail || !canSeeClosingTab || order.status !== statusIds.BOOKED) {
    return null;
  }

  const clickUnderConstruction = () => {
    enqueueSnackbar('Under Construction', { variant: 'info' });
  };

  const handleChangeMode = (event: any, value: boolean) => setIsClosing(value);

  const handleReloadOrderInfo = async () => {
    try {
      setInFlight(true);
      await fetchOrderInfo();
    } catch {
    } finally {
      setInFlight(false);
    }
  };

  const handleOpenBillOfLading = (jobType: JobType) => () => {
    const jobsPath = jobType === JobType.PICK_UP ? 'jobs' : 'delivery-jobs';
    const jwt = storageUtil.getJwt()!;
    const orderId = order?.orderId;
    const url = getUrl(
      foremanPage.foremanPageUrl,
      jwt,
      orderId,
      `manager/order-jobs/order/${orderId}/${jobsPath}/${quote.dispatchJob?.id!}/move-details/contract`,
      true,
    );
    setForemanUrl(url);
  };

  const handleCloseModal = () => {
    setForemanUrl('');
  };

  const handleShowClosing = async () => {
    const confirm = await confirmWarning();

    if (confirm) {
      onChange({
        name: getServiceRosterClosingPropertyName(serviceIndex, 'showClosingInOrderWindow'),
        value: true,
      });
    }
  };

  return (
    <Box mx={0.5} className="print-remove">
      {showClosingButton ? (
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="end">
              <Box display="flex" ml={1}>
                <Link
                  onClick={handleOpenBillOfLading(JobType.PICK_UP)}
                  disabled={!quote?.dispatchJob?.id || !hasAvailableContracts}
                >
                  <BodyText>Bill of lading</BodyText>
                </Link>
              </Box>
              <Box display="flex" ml={1}>
                <Link onClick={handleShowClosing}>
                  <BodyText>Activate Closing</BodyText>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <Grid container alignItems="center">
            <Grid item>
              <Box mr={2}>
                <Button
                  className={classes.closingButton}
                  onClick={(event) => handleChangeMode(event, !isClosing)}
                  variant="outlined"
                  color={isClosing ? 'primary' : 'default'}
                  rounded
                >
                  <Box>
                    <BodyText>{isClosing ? 'View By: Closing Info' : 'View By: Sales Info'}</BodyText>
                  </Box>
                </Button>
              </Box>
            </Grid>

            <Divider orientation="vertical" flexItem />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexGrow={1}
              position="relative"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Box ml={1} flexGrow={1}>
                <Grid container alignItems="center">
                  <CloseOrder />
                  {isLongDistance && tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
                    <CloseOrder type={JobType.DELIVERY} />
                  )}
                  {/*Hide on mobile, because we don't have functional*/}
                  {isClosing && !isMobile && (
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        rounded
                        disabled
                        fullWidth={isMobile}
                        onClick={clickUnderConstruction}
                      >
                        Email receipt
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {isClosing && (
                <Box ml={1}>
                  <Grid container alignItems="center">
                    {/*Hide on mobile, because we don't have functional*/}
                    {!isMobile && (
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          rounded
                          disabled
                          fullWidth={isMobile}
                          onClick={clickUnderConstruction}
                        >
                          Items
                        </Button>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant="text"
                        color="primary"
                        rounded
                        disabled={!quote?.dispatchJob?.id || !hasAvailableContracts}
                        fullWidth={isMobile}
                        onClick={handleOpenBillOfLading(JobType.PICK_UP)}
                      >
                        Bill of lading
                      </Button>
                    </Grid>
                    {!isMobile && (
                      <Grid item>
                        <IconButton color="primary" disabled={inFlight} onClick={handleReloadOrderInfo}>
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      )}
      <IframeModal open={!!foremanUrl.length} onCancel={handleCloseModal} title="Foreman Page" src={foremanUrl} />
      {renderDialogConfirmationWarning()}
    </Box>
  );
};
