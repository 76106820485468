import { InventoryItem, Order } from '@elromcoinc/react-shared';
import { List } from 'immutable';

export const getInventorySize = (order: Order, cuFtToPounds: number) => {
  // we store unloading help items under pickup inventory items
  const itemsGroupName = 'pickUpInventoryItems';

  return order
    .getAllServicesWaypoints()
    .filter((wp) => !wp.hidden)
    .map((wp) => wp.get(itemsGroupName))
    .reduce((acc, items) => acc.merge(items), List() as List<InventoryItem>)
    .reduce(
      (accumulator, item) => {
        const [accCuFt, accWeight] = accumulator;
        const size = item.getSize();
        const { quantity, weight } = item;

        return [accCuFt + size * quantity, accWeight + (weight || size * cuFtToPounds) * quantity];
      },
      [0, 0],
    );
};
