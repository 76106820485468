// it mostly the same as EmployeeCommissionType but extra compensation types are added
export enum PayrollCompensationType {
  HOURLY_RATE = 'HOURLY_RATE',
  // OVERTIME_RATE = 'OVERTIME_RATE',
  PACKING_COMMISSION = 'PACKING_COMMISSION',
  ADDITIONAL_SERVICES_COMMISSION = 'ADDITIONAL_SERVICES_COMMISSION',
  COMMISSION_FROM_TOTAL = 'COMMISSION_FROM_TOTAL',
  PER_JOB_BONUS = 'PER_JOB_BONUS',
  TIP = 'TIP',
  RECEIPT = 'RECEIPT',
}
