// @ts-ignore
import * as Dropzone from 'dropzone';
import { ActivitySourceType } from '@elromcoinc/react-shared';

const fileToFormData = (
  file: Dropzone.DropzoneFile,
  options: Partial<{ name: string; type: string; sourceId: number; activitySource: ActivitySourceType }> = {},
): FormData => {
  const { name: originalFileName, type: contentType } = file;
  const { activitySource, sourceId } = options;
  const fd = new FormData();
  fd.append('file', file);
  fd.append(
    'dto',
    new Blob(
      [
        JSON.stringify({
          contentType: options.type ?? contentType,
          originalFileName: options.name ?? originalFileName,
          activitySource,
          sourceId,
          activitySources: [{ activitySource, referencedEntityId: sourceId }],
        }),
      ],
      {
        type: 'application/json',
      },
    ),
  );

  return fd;
};

export { fileToFormData };
