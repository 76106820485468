import { FC, useEffect, useRef, useState } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';
import { useSelector } from 'react-redux';

import communicationsAPI from 'admin/api/CommunicationsAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';

import { UnreadMessagesContext } from './UnreadMessagesContext';

interface OrderWindowUnreadMessagesProviderProps {
  sourceId: number;
  activitySource: ActivitySourceType;
}

const UnreadMessagesProvider: FC<OrderWindowUnreadMessagesProviderProps> = ({
  children,
  sourceId,
  activitySource = ActivitySourceType.ORDER,
}) => {
  const [unreadMessages, setUnreadMessages] = useState(0);
  const messageRef = useRef(null);
  const messageRequestRef = useRef<ReturnType<typeof communicationsAPI.getTotalEmployeeUnseenMessages> | null>(null);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  const reloadUnreadMessages = () => {
    if (sourceId) {
      if (messageRequestRef.current) {
        messageRequestRef.current?.cancel('cancelled');
      }

      messageRequestRef.current = communicationsAPI.getTotalEmployeeUnseenMessages({
        'activitySources.sourceId': sourceId,
        'activitySources.activitySource': activitySource,
      });

      messageRequestRef.current.promise.then(setUnreadMessages).catch(() => {});
    }
  };

  useEffect(() => {
    if (!isSessionExpired) {
      reloadUnreadMessages();
      const interval = setInterval(reloadUnreadMessages, 60e3);
      return () => clearInterval(interval);
    }
  }, [sourceId, isSessionExpired]);

  return (
    <UnreadMessagesContext.Provider value={{ unreadMessages, setUnreadMessages, reloadUnreadMessages, messageRef }}>
      {children}
    </UnreadMessagesContext.Provider>
  );
};

export { UnreadMessagesProvider };
