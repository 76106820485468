import React, { FC, useEffect, useState } from 'react';

import { ActivitySourceType, Badge, BodyText } from '@elromcoinc/react-shared';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSelector } from 'react-redux';

import invoiceAPI from 'admin/api/InvoiceAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { ActivitySourceQueryParams } from 'common-types/ActivitySourceQueryParams';
import { IconLabelButton } from 'common/components/Widgets';

interface InvoicesButtonProps {
  onClick: () => void;
  sourceId: number;
  activitySource: ActivitySourceType;
}

const InvoicesButton: FC<InvoicesButtonProps> = ({ onClick, sourceId, activitySource }) => {
  const [totalInvoices, setTotalInvoices] = useState<number>(0);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  const fetchInvoices = () => {
    const params = {
      pageSize: 1,
    } as ActivitySourceQueryParams;

    if (activitySource === ActivitySourceType.ORDER) {
      params['order.id'] = sourceId;
    } else if (activitySource === ActivitySourceType.CUSTOMER_ACCOUNT) {
      params['account.id'] = sourceId;
    } else {
      params['activitySources.sourceId'] = sourceId;
      params['activitySources.activitySource'] = activitySource;
    }

    invoiceAPI.getInvoices(params).then(({ totalElements }) => setTotalInvoices(totalElements));
  };

  useEffect(() => {
    if (!isSessionExpired) {
      fetchInvoices();
      const loadInvoicesInterval = setInterval(fetchInvoices, 30e3);
      return () => loadInvoicesInterval && clearInterval(loadInvoicesInterval);
    }
  }, [sourceId, isSessionExpired]);

  return (
    <IconLabelButton
      color={totalInvoices > 0 ? 'primary' : 'default'}
      onClick={onClick}
      startIcon={<DescriptionIcon />}
    >
      <Badge badgeContent={totalInvoices} />
      <BodyText>Invoices</BodyText>
    </IconLabelButton>
  );
};

export default InvoicesButton;
