import React, { useEffect, useState } from 'react';

import {
  AccountsPermissions,
  Button,
  Form,
  Modal,
  PhoneFormGroup,
  Select,
  TextInput,
  phoneYUP,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment, LinearProgress } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import AccountsIcon from '@material-ui/icons/FolderShared';
import TruckOutlineIcon from '@material-ui/icons/LocalShippingOutlined';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { object, string } from 'yup';

import AccountAPI from 'admin/api/AccountAPI';
import { openAccount } from 'admin/autodux/WindowsAutodux';
import useSettingsApi from 'admin/components/Settings/hooks/useSettingsApi';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import FieldNames from 'admin/constants/FieldNames';
import SettingName from 'admin/constants/SettingName';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';
import { getEnabledLeadSources } from 'admin/selectors';

const { PHONE_NUMBER, PHONE_TYPE, PHONE_EXTENSION } = FieldNames;
const { LEAD_SOURCE } = SettingName;
const settingQueries = [LEAD_SOURCE];

const ACCOUNT_NAME = 'accountName';
const FIRST_NAME = 'contactFirstName';
const LAST_NAME = 'contactLastname';
const EMAIL = 'contactEmail';
const ACCOUNT_TYPE = 'accountType';
const REFERRED_VIA = 'referredVia';
const NAME = 'name';
const CONTACT_PHONE = 'contactPhone';
const SMS_CAPABILITY = 'smsCapability';
const PHONE_CARRIER_TYPE = 'phoneCarrierType';

const referenceViaPath = `${REFERRED_VIA}.${NAME}`;
const smsCapabilityPath = `${CONTACT_PHONE}.${SMS_CAPABILITY}`;
const phoneCarrierTypePath = `${CONTACT_PHONE}.${PHONE_CARRIER_TYPE}`;

const phoneLabels = {
  [PHONE_NUMBER]: 'Account Contact Phone',
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_EXTENSION]: 'Ext.',
};

const labels = {
  [ACCOUNT_NAME]: 'Account Name',
  [FIRST_NAME]: 'Contact First Name',
  [LAST_NAME]: 'Contact Last Name',
  [EMAIL]: 'Account Contact Email',
  [ACCOUNT_TYPE]: 'Account Type',
  [REFERRED_VIA]: 'How did you hear about us?',
  [CONTACT_PHONE]: {
    [PHONE_NUMBER]: phoneLabels[PHONE_NUMBER],
    [PHONE_TYPE]: phoneLabels[PHONE_TYPE],
    [PHONE_EXTENSION]: phoneLabels[PHONE_EXTENSION],
  },
};

const emailSchema = string()
  .transform((v) => v?.trim() ?? null)
  .email()
  .required()
  .min(5)
  .max(255)
  .nullable();

const schema = object().shape({
  [FIRST_NAME]: string().label(labels[FIRST_NAME]).required().nullable().min(2).max(32),
  [LAST_NAME]: string().label(labels[LAST_NAME]).required().nullable().min(2).max(32),
  [ACCOUNT_NAME]: string().label(labels[ACCOUNT_NAME]).required().nullable().max(62).min(2),
  [EMAIL]: emailSchema.label(labels[EMAIL]).test({
    name: EMAIL,
    message: 'This email already used',
    test: (value) =>
      emailSchema.isValidSync(value)
        ? AccountAPI.getByEmail(value).then((response) => !response)
        : Promise.resolve(true),
  }),
  [CONTACT_PHONE]: phoneYUP(phoneLabels),
  [ACCOUNT_TYPE]: string().label(labels[ACCOUNT_TYPE]).required().nullable(),
  [REFERRED_VIA]: object({
    [NAME]: string().label('Source').required().nullable(),
  }),
});

const accountOptions = Object.entries(AccountTypeName);

const CreateAccount = ({ onClose, fullScreen, ...rest }) => {
  const [redirect, setRedirect] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [settings] = useSettingsApi(settingQueries);
  const referredViaOptions = getEnabledLeadSources(settings);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const canCreateAffiliateAccount = useHasPermission(AccountsPermissions.PERM_CAN_CREATE_AN_AFFILIATE_ACCOUNT);
  const canCreateCorporateAccount = useHasPermission(AccountsPermissions.PERM_CAN_CREATE_A_CORPORATE_ACCOUNT);

  const accountTypeOptions = accountOptions.filter(
    ([id]) =>
      (canCreateAffiliateAccount && id === AccountType.AFFILIATE) ||
      (canCreateCorporateAccount && id === AccountType.CORPORATE) ||
      id === AccountType.PERSONAL,
  );

  const formMethods = useForm({
    resolver: yupResolver(schema),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
  } = formMethods;

  const phoneNumberValue = watch(`${CONTACT_PHONE}.${PHONE_NUMBER}`);

  useUpdatePhoneNumberType(phoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(phoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(smsCapabilityPath, value),
  });

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const handleSubmitForm = (data) => {
    setInFlight(true);

    AccountAPI.create(data)
      .then((response) => {
        if (response) {
          setRedirect(true);
          dispatch(openAccount(response.id));
          onClose();
        }
      })
      .then(() => {
        setInFlight(false);
        enqueueSnackbar('An account successfully created', { variant: 'success' });
      })
      .catch(() => {
        setInFlight(false);
        enqueueSnackbar('Problem with creating new account', { variant: 'error' });
      });
  };

  const commonParts = { control };

  return (
    <Modal
      color="primary"
      fullWidth={fullScreen}
      title="New Account"
      disabledInProcessing={inFlight}
      onClose={onClose}
      open
      maxWidth="sm"
      {...rest}
    >
      {redirect && <Redirect to="/accounts" />}
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                name={ACCOUNT_NAME}
                data-testid="accountName"
                label={labels[ACCOUNT_NAME]}
                {...commonParts}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountsIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                fullWidth
                name={FIRST_NAME}
                data-testid="contactFirstName"
                label={labels[FIRST_NAME]}
                {...commonParts}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                fullWidth
                name={LAST_NAME}
                data-testid="contactLastName"
                label={labels[LAST_NAME]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                name={EMAIL}
                data-testid="accountContactEmail"
                label={labels[EMAIL]}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneFormGroup labels={phoneLabels} name={CONTACT_PHONE} displayPhoneCarrierIcon />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                name={ACCOUNT_TYPE}
                data-testid="accountType"
                label={labels[ACCOUNT_TYPE]}
                primaryBackgroundOnSelectedItem
                options={accountTypeOptions}
                {...commonParts}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountsIcon color="primary" />{' '}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                name={referenceViaPath}
                data-testid="howDidYouHearAboutUs"
                label="How did you hear about us?"
                options={referredViaOptions}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TruckOutlineIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                {...commonParts}
              />
            </Grid>
          </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="create"
              rounded
              loading={inFlight}
              disabled={inFlight}
            >
              create
            </Button>
          </Box>
          {inFlight && (
            <Box mt={1}>
              <LinearProgress />
            </Box>
          )}
        </Form>
      </FormProvider>
    </Modal>
  );
};

CreateAccount.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateAccount;
