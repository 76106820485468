import { List, Record } from 'immutable';

export default class MoveSize extends Record({
  id: null,
  moveType: null,
  moveUnitType: null,
  name: null,
  isDefault: false,
  size: 0,
  tags: new List()
}) {
  static createMoveSize(moveSize = {}) {
    return new MoveSize(moveSize || {}).set(
      'tags',
      new List(((moveSize || {}).tags || []).map(it => MoveSize.createMoveSize(it)))
    );
  }
}
