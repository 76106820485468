import { Truck } from '@elromcoinc/react-shared';

import Job from 'admin/components/OrderWindow/SchedulerBox/Job';

export const filterJobByDate = (date: Date, truck: Truck) => (job: Job) => {
  if (job.isSameMoveDate(date) && !job.deliveryJob) {
    return job.truckIds.includes(truck.id!);
  }

  if (job.isIncludesDeliveryDate(date) && job.deliveryJob) {
    return job.deliveryTruckIds.includes(truck.id!);
  }

  return false;
};
