import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const CompleteUnloadingIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.10000,-0.10000)" fill="#000000" stroke="none">
          <path d="M545 4899 c-203 -30 -388 -166 -479 -354 -70 -143 -66 -36 -66 -1844 l0 -1631 215 0 215 0 0 -101 c0 -129 21 -222 75 -334 97 -200 260 -335 480 -396 108 -30 267 -30 378 0 261 69 470 283 538 552 8 30 14 104 14 167 l0 112 646 0 645 0 -4 -84 c-15 -281 155 -567 412 -691 120 -58 205 -78 336 -78 288 0 539 158 665 418 54 112 75 205 75 334 l0 101 215 0 215 0 0 998 c0 1096 0 1095 -61 1268 -126 363 -443 634 -816 699 -61 11 -160 15 -348 15 l-262 0 -6 163 c-6 181 -18 234 -74 347 -51 104 -169 222 -273 273 -155 77 -42 71 -1430 73 -685 1 -1272 -2 -1305 -7z m2509 -431 c53 -16 118 -81 134 -134 9 -32 12 -363 12 -1443 l0 -1401 -1385 0 -1385 0 0 1415 c0 1374 1 1417 19 1453 11 20 32 49 48 64 64 61 -14 57 1307 58 928 0 1218 -3 1250 -12z m1086 -848 c103 -17 186 -48 265 -102 96 -64 167 -143 216 -243 58 -119 69 -175 69 -352 l0 -153 -530 0 -530 0 0 430 0 430 223 0 c122 0 251 -5 287 -10z m550 -1700 l0 -430 -530 0 -530 0 0 430 0 430 530 0 530 0 0 -430z m-3209 -877 c17 -44 6 -171 -20 -221 -33 -65 -87 -118 -149 -149 -77 -38 -190 -39 -269 -4 -129 58 -211 212 -183 346 l11 55 300 0 299 0 11 -27z m2779 -28 c20 -96 -11 -194 -85 -274 -64 -69 -128 -95 -230 -96 -68 0 -89 4 -137 28 -62 31 -116 84 -149 149 -26 50 -37 177 -20 221 l11 27 299 0 300 0 11 -55z" />
          <path d="M2097 3222 l-447 -447 -285 285 -285 285 -150 -150 -150 -150 297 -298 c164 -164 316 -311 339 -327 125 -87 313 -93 454 -13 46 27 970 938 970 958 0 6 -66 77 -148 158 l-147 147 -448 -448z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
