import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';

import { DataOfBillingHistory } from 'admin/components/PlanSettings/utils/DataOfBillingHistory';

const makeBillingHistoryColumns = (billingHistoryData: DataOfBillingHistory[]) => [
  {
    name: 'date',
    label: 'Date',
    options: {
      customBodyRender: (_: DataOfBillingHistory[], { rowIndex }: { rowIndex: number }) => {
        const { date } = billingHistoryData[rowIndex];
        return <BodyText color="text">{date}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'amount',
    label: 'Amount',
    options: {
      customBodyRender: (_: DataOfBillingHistory[], { rowIndex }: { rowIndex: number }) => {
        const { amount } = billingHistoryData[rowIndex];
        return <BodyText color="text">{`$${amount}`}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      customBodyRender: (_: DataOfBillingHistory[], { rowIndex }: { rowIndex: number }) => {
        const { description } = billingHistoryData[rowIndex];
        return <BodyText color="text">{description}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
];

export { makeBillingHistoryColumns };
