import React, { useState } from 'react';

import { Duration, Select } from '@elromcoinc/react-shared';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import Modal from 'common/components/Modal';

import useDurations from '../hooks/useDurations';

const MinLaborHours = ({ onSave, onCancel, open, minLabourHours }) => {
  const [durationOptions30mins] = useDurations();
  const [manual, setManual] = useState(minLabourHours.value() || Duration.createDuration({ originalSeconds: 0 }));
  const { closingTitle } = useOrderClosingContext();

  const hasChanges = !minLabourHours.value().equals(manual);

  const handleOnSave = () => {
    onSave(minLabourHours.calculated.originalSeconds !== manual.originalSeconds ? manual : null);
  };

  return (
    <Modal
      open={open}
      title={`Edit Minimum Hours${closingTitle}`}
      onClose={onCancel}
      actions={[
        { label: 'cancel', action: onCancel },
        { label: 'save', action: handleOnSave, disabled: !hasChanges, color: hasChanges ? 'primary' : 'default' },
      ]}
      size="small"
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {`Current Minimum Hours: ${minLabourHours.calculated.humanReadable}`}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              fullWidth
              autoFocus
              variant="filled"
              label="New Rate"
              value={manual.originalSeconds}
              onChange={({ target: { value: originalSeconds } }) =>
                setManual(Duration.createDuration({ originalSeconds }))
              }
            >
              {durationOptions30mins.map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Typography color={value === minLabourHours.calculated.originalSeconds ? 'default' : 'error'}>
                    {label}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

MinLaborHours.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  minLabourHours: PropTypes.shape({
    calculated: PropTypes.object.isRequired,
    manual: PropTypes.object.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
};

export default MinLaborHours;
