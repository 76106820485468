import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DataTable, JobStatus, statusIds, useUpdateEffect } from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import OrderApi from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getInFlight, getReloadAmount, setInFlight } from 'admin/autodux/ReloadAutodux';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { allNewStatuses } from 'admin/components/Calendar/orders/utils';
import { STATUS_QUERY_PARAM_NAME, makeBookedFilters, makeColumns } from 'admin/components/Orders/config';
import {
  DASHBOARD_TAB_NAME,
  ORDERS_TAB_NAME,
  PAGE_QUERY_NAME,
  ROWS_PER_PAGE_QUERY_NAME,
  SORT_BY_QUERY_NAME,
  SORT_ORDER_QUERY_NAME,
  TAB_QUERY_PARAM_NAME,
} from 'admin/components/Orders/config/OrdersConstants';
import { DATE_TYPE, LEAD_SCORE } from 'admin/components/Orders/config/OrdersLabels';
import { OrdersTypeSummary } from 'admin/constants';
import OrderSummary from 'admin/entities/OrderSummary';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';
import { useQuery } from 'admin/hooks/useQuery';

const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    '& .el-datatable': {
      '& .MuiPaper-root': {
        borderRadius: spacing(0, 0, 0.75, 0.75),
      },

      '& .MuiTable-root': {
        borderRadius: spacing(0, 0, 0.75, 0.75),

        '& .MuiTableFooter-root': {
          borderRadius: spacing(0, 0, 0.75, 0.75),

          '& tr': {
            borderRadius: spacing(0, 0, 0.75, 0.75),

            '& td': {
              borderRadius: spacing(0, 0, 0.75, 0.75),
            },
          },
        },
      },
    },
  },
  flexContainer: {
    color: palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  select: {
    '& > div': {
      backgroundColor: `transparent !important`,
      '& > div': {
        backgroundColor: `transparent !important`,
      },
    },
  },
  tableCell: {
    border: 'none',
    padding: '1px',
  },
  rowHatching: {
    backgroundImage: 'repeating-linear-gradient(-45deg, transparent 0 12px, #d4f0f9 10px 30px)',
  },
}));

const sortByBookingDate = 'bookingDate';
const sortById = 'id';

const OrdersTable = ({
  options,
  setSelectedMessageOrder,
  setOpenEmailModal,
  setOpenFlagsModal,
  filters,
  leadScoreSettings,
  branch,
  ordersRef,
  selectedBookedOption,
  selectedButton,
  setRowsChecked,
  useLocalInFlight,
  setIsReload,
  useSortByQuery,
  type,
  ...props
}) => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const pageRef = useRef(+query.get(PAGE_QUERY_NAME) || 1);
  const ordersCountRef = useRef(0);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const defaultSortBy = filters?.dateType === sortByBookingDate ? sortByBookingDate : 'id';
  const sortByQuery = useSortByQuery ? query.get(SORT_BY_QUERY_NAME) : null;
  const [sortBy, setSortBy] = useState(sortByQuery || defaultSortBy);
  const [sortOrder, setSortOrder] = useState(query.get(SORT_ORDER_QUERY_NAME) || 'desc');
  const salesPersonList = useSelector(getManagerList);
  const salesPersonOptions = salesPersonList.toJS().map((item) => [item.id, `${item.firstName} ${item.lastName}`]);
  const [rowsPerPage, setRowsPerPage] = useState(+query.get(ROWS_PER_PAGE_QUERY_NAME) || 25);
  const currentTab = query.get(TAB_QUERY_PARAM_NAME) || props.currentTab || DASHBOARD_TAB_NAME;
  const { enqueueSnackbar } = useSnackbar();
  const currentOrders = useRef();
  const reloadAmount = useSelector(getReloadAmount);
  const [localInFlight, setLocalInFlight] = useState(false);
  const inFlight = useSelector(getInFlight);
  const isSessionExpired = useSelector(getIsSessionExpired);

  useFetchManagers();

  useEffect(() => {
    if (
      query.get(STATUS_QUERY_PARAM_NAME) === statusIds.BOOKED ||
      filters[STATUS_QUERY_PARAM_NAME] === statusIds.BOOKED
    ) {
      setSortBy(sortByBookingDate);
    } else {
      setSortBy(sortById);
    }
  }, [query.get(STATUS_QUERY_PARAM_NAME)]);

  useUpdateEffect(() => {
    pageRef.current = 1;
  }, [filters]);

  const setUpdate = (value) => {
    if (useLocalInFlight) {
      setLocalInFlight(value);
      setIsReload(value);
    } else {
      dispatch(setInFlight(value));
    }
  };

  const loadOrders = () => {
    setUpdate(true);

    const newSearch = queryString.stringify({
      ...Object.fromEntries(query),
      page: pageRef.current,
      sortBy,
      sortOrder,
      rowsPerPage,
    });

    if (location.search !== `?${newSearch}`) {
      history.replace({
        search: newSearch,
      });
    }

    let allowedFilters =
      currentTab === ORDERS_TAB_NAME
        ? { ...filters }
        : {
            status: filters.status,
            leadScoreMin: filters.leadScoreMin || null,
            leadScoreMax: filters.leadScoreMax || null,
          };

    delete allowedFilters[LEAD_SCORE];

    if (filters.status === statusIds.BOOKED && currentTab === DASHBOARD_TAB_NAME) {
      allowedFilters = makeBookedFilters(selectedBookedOption, allowedFilters);
    }

    delete allowedFilters[DATE_TYPE];

    Object.keys(allowedFilters).forEach((property) => {
      if (!allowedFilters[property]) {
        delete allowedFilters[property];
      }
    });

    const status =
      allowedFilters.status === statusIds.NEW && currentTab === DASHBOARD_TAB_NAME
        ? allNewStatuses
        : allowedFilters.status;

    if (allowedFilters['assignedTo.id'] === 'null') {
      delete allowedFilters['assignedTo.id'];
      allowedFilters.unassigned = 'true';
    }

    currentOrders.current =
      type === OrdersTypeSummary.ALL_ORDERS
        ? OrderApi.getOrderSummariesWithFilters({
            pageIndex: pageRef.current - 1,
            pageSize: rowsPerPage,
            sortBy,
            sortOrder,
            ...allowedFilters,
            status,
          })
        : OrderApi.getOrdersSubmissionsSummaries(
            {
              pageIndex: pageRef.current - 1,
              pageSize: rowsPerPage,
              sortBy,
              sortOrder,
              ...allowedFilters,
              status,
            },
            { startDate: filters.created[0], endDate: filters.created[1] },
          );

    currentOrders.current?.promise
      .then(({ pageElements, totalElements }) => {
        currentOrders.current = null;
        ordersRef.current = List(pageElements.map((it) => OrderSummary.createOrderSummary(it)));
        ordersCountRef.current = totalElements;
        setUpdate(false);
      })
      .catch(() => {}); // need to handle the catch since the promise is canceled on component unmount. Not handling the catch here will throw an "Uncaught (in promise) Cancel" error in the console
  };

  useEffect(() => {
    if (!isSessionExpired) {
      loadOrders();
      const interval = setInterval(loadOrders, 5e4);

      return () => {
        if (currentOrders.current) {
          currentOrders.current?.cancel('component unmount');
        }
        clearInterval(interval);
      };
    }
  }, [
    pageRef.current,
    rowsPerPage,
    sortBy,
    sortOrder,
    filters,
    currentTab,
    reloadAmount,
    selectedBookedOption,
    isSessionExpired,
  ]);

  useUpdateEffect(() => {
    pageRef.current = 1;
  }, [selectedButton]);

  const onColumnSortChange = (changedColumn, direction) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
    setRowsChecked([]);
  };

  const onChangeOrderSales =
    (rowIndex, orderId) =>
    ({ target: { value } }) => {
      const assignedTo = salesPersonList.toJS().find(({ id }) => id === value);

      if (!assignedTo) {
        return;
      }

      setUpdate(true);

      OrderApi.assignEmployee(orderId, assignedTo.id)
        .then(() => {
          ordersRef.current = ordersRef.current.map((order, index) =>
            index === rowIndex ? order.set('assignedTo', assignedTo) : order,
          );
          enqueueSnackbar('Employee saved successfully', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(`Can't update employee`, { variant: 'error' });
        })
        .then(() => {
          setUpdate(false);
        });
    };

  const columns = makeColumns(ordersRef.current, {
    setSelectedMessageOrder,
    setOpenEmailModal,
    setOpenFlagsModal,
    leadScoreSettings,
    branch,
    salesPersonOptions: [[0, 'Assign Sales'], ...salesPersonOptions],
    onChangeOrderSales,
    isMobile,
    classes,
    displayBookedColumn: filters.status === statusIds.BOOKED || currentTab !== DASHBOARD_TAB_NAME,
  });

  const onRowSelectionChangeOverridden = ([rowIndex]) => {
    if (typeof rowIndex !== 'undefined' && selectedRowIndex === rowIndex) {
      const order = ordersRef.current.get(rowIndex);
      dispatch(openOrder(order.id));
    } else {
      setSelectedRowIndex(rowIndex);
    }
  };

  const tableOptions = {
    ...options,
    setRowProps: (row, rowIndex) => {
      const order = ordersRef.current.get(rowIndex)?.toJS();
      const jobStatus = order?.serviceSummaries[order?.serviceSummaries.length - 1]?.dispatchJob?.jobStatus;
      return {
        className: classNames({
          [classes.rowHatching]: jobStatus === JobStatus.COMPLETED,
        }),
      };
    },
    tableBodyHeight: '100%',
    smallPadding: true,
    serverSide: true,
    page: pageRef.current - 1,
    rowsPerPage,
    onChangeRowsPerPage: (rpp) => {
      pageRef.current = 1;
      setRowsPerPage(rpp);
      setRowsChecked([]);
    },
    onColumnSortChange,
    sortOrder: {
      name: sortBy,
      direction: sortOrder,
    },
    inFlight: useLocalInFlight ? localInFlight : inFlight,
    count: ordersCountRef.current,
    onChangePage: (p) => {
      pageRef.current = p + 1;
      setRowsChecked([]);
    },
    onRowSelectionChangeOverridden,
    alternateTableRowColors: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const currentRow = ordersRef.current.get(rowMeta.rowIndex);
      const messagesColumn = columns.find((item) => item.name === 'messages');
      return (
        <tr>
          <td />
          <td colSpan={100}>
            <Box position="absolute" right="10%">
              {messagesColumn.options.customBodyRender(undefined, { rowData, rowIndex: rowMeta.rowIndex })}
            </Box>
            <Table aria-label={`${currentRow.name} account information`}>
              <TableBody>
                {columns
                  .filter((column) => column.options && column.options.showOnMobileAsExpanded && column.label.trim())
                  .map(({ label, name, options: { customBodyRender } }) => (
                    <TableRow key={name + label}>
                      <TableCell scope="row" classes={{ root: classes.tableCell }}>
                        <Box fontWeight="bold" maxWidth="160px">
                          {label}
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        {customBodyRender
                          ? customBodyRender(currentRow[name], { rowData, rowIndex: rowMeta.rowIndex })
                          : currentRow[name]}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </td>
        </tr>
      );
    },
  };

  const handleOrderClick = ({ orderId }) => {
    dispatch(openOrder(orderId));
  };

  const data = useMemo(() => ordersRef.current.toJS(), [ordersRef.current]);

  return (
    <Box className={classes.container}>
      <DataTable
        data={data}
        columns={columns}
        isMobile={isMobile}
        onRowClick={handleOrderClick}
        options={tableOptions}
      />
    </Box>
  );
};

OrdersTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  leadScoreSettings: PropTypes.object.isRequired,
  branch: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filters: PropTypes.object.isRequired,
  setSelectedMessageOrder: PropTypes.func.isRequired,
  setOpenEmailModal: PropTypes.func.isRequired,
  setOpenFlagsModal: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  ordersRef: PropTypes.object.isRequired,
  selectedBookedOption: PropTypes.string,
  selectedButton: PropTypes.string,
  currentTab: PropTypes.string,
  setRowsChecked: PropTypes.func,
  useLocalInFlight: PropTypes.bool,
  setIsReload: PropTypes.func,
  useSortByQuery: PropTypes.bool,
  type: PropTypes.string,
};

OrdersTable.defaultProps = {
  selectedButton: null,
  selectedBookedOption: null,
  currentTab: null,
  setRowsChecked: null,
  setOpenFlagsModal: null,
  useSortByQuery: true,
  type: OrdersTypeSummary.ALL_ORDERS,
};

export default OrdersTable;
