import React, { useEffect, useState } from 'react';

import { ActivitySourceType, Badge } from '@elromcoinc/react-shared';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import { taskAPI } from 'admin/api';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getLastUpdateDate } from 'admin/autodux/TaskAutodux';
import { TaskStatus } from 'common-types/Task';
import { IconLabelButton } from 'common/components/Widgets';

const TasksButton = ({ disabled, onClick, sourceId, activitySource }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const lastUpdateDate = useSelector(getLastUpdateDate);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const fetchUncompletedTaskCount = () =>
    taskAPI
      .getAllForCurrent({
        'activitySources.sourceId': sourceId,
        'avtivitySources.activitySource': activitySource,
        status: [TaskStatus.READ, TaskStatus.UNREAD],
        pageSize: 1,
      })
      .then(({ totalElements }) => setUnreadCount(totalElements));

  useEffect(() => {
    if (!isSessionExpired) {
      let loadUncompletedTasksInterval = null;
      fetchUncompletedTaskCount();

      loadUncompletedTasksInterval = setInterval(fetchUncompletedTaskCount, 30e3);

      return () => loadUncompletedTasksInterval && clearInterval(loadUncompletedTasksInterval);
    }
  }, [sourceId, lastUpdateDate, isSessionExpired]);

  return (
    <IconLabelButton
      color={unreadCount > 0 ? 'primary' : 'default'}
      startIcon={<AssignmentTurnedInIcon />}
      onClick={onClick}
      data-testid="taskButton"
      disabled={disabled}
    >
      {(unreadCount || null) && <Badge badgeContent={unreadCount} />}
      Tasks
    </IconLabelButton>
  );
};

TasksButton.propTypes = {
  onClick: pt.func.isRequired,
  sourceId: pt.number.isRequired,
  activitySource: pt.string,
  disabled: pt.bool,
};

TasksButton.defaultProps = {
  activitySource: ActivitySourceType.ORDER,
  disabled: false,
};

export default TasksButton;
