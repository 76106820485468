import React, { FC, useEffect, useRef, useState } from 'react';

import {
  ActivitySourceType,
  BodyText,
  Button,
  Message,
  MessageTypes,
  Phone,
  PhoneType,
  RecipientTypes,
  SmsCapability,
  formatPhoneNumber,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, ListSubheader, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Map, getIn } from 'immutable';
import { OptionsObject, useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object } from 'yup';

import communicationAPI from 'admin/api/CommunicationsAPI';
import templateApi from 'admin/api/templateApi';
import { getIsDisabledSms } from 'admin/autodux/MessageAutodux';
import { MessageInput } from 'admin/components/Communication/MessageTab/Messages';
import { CreateFollowUpForm } from 'admin/components/OrderWindow/blocks/ActivityManager/CreateFollowUpForm';
import { createFollowUpTask } from 'admin/components/OrderWindow/blocks/ActivityManager/createFollowUpTask';
import { followUpSchema } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useDefaultFollowUpValues } from 'admin/components/OrderWindow/blocks/ActivityManager/useDefaultFollowUpValues';
import { useOrderChangeSet, useOrderState, useUnsubscribeMessage } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { CommunicationModality, Template, TemplateDTO } from 'common-types';
import { TemplateBuilderTokens } from 'common-types/TemplateBuilderTokens';

interface NewMessageProps {
  activitySource: ActivitySourceType;
  sourceId: number;
}

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
  },
}));

const errorMessageOptions: OptionsObject = { variant: 'error' };

const messageTypeMap = Map({
  [ActivitySourceType.ORDER]: 'order',
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account',
});

const schema = object({
  ...followUpSchema,
});
const successMessage: OptionsObject = { variant: 'success' };

const unsubscribeWords = ['stop', 'stopall', 'unsubscribe', 'cancel', 'end', 'quid'];

export const NewMessage: FC<NewMessageProps> = ({ sourceId, activitySource }) => {
  const { order } = useOrderState();
  const { changeSet, showSaveDialog } = useOrderChangeSet();
  const changeSetRef = useRef(changeSet);
  changeSetRef.current = changeSet;
  const classes = useStyles();
  const { reload, pastActions, newMessageDefault, setNewMessageDefault } = useActivityLogContext();
  const defaultFollowUp = useDefaultFollowUpValues();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: newMessageDefault ? newMessageDefault : defaultFollowUp,
  });
  const { handleSubmit, reset } = methods;
  const isDisableSms = useSelector(getIsDisabledSms);
  const [isCanSendSMS, setIsCanSendSMS] = useState<boolean>(false);
  const [text, setText] = useState(newMessageDefault?.text ?? '');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isLoadingSms, setIsLoadingSms] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { groupedSMSTemplatesByTemplateFolder, inFlightTemplates } = useComposeEmail();
  const currentSMSCapability = order?.contactInfo?.primaryPhone?.smsCapability;
  const toPhoneNumber = order?.contactInfo?.primaryPhone?.number;
  const currentPhoneCarrierType = order?.contactInfo?.primaryPhone?.phoneCarrierType;
  const phoneType = order?.contactInfo?.primaryPhone?.phoneType ?? PhoneType.MOBILE;
  const { setIsUnsubscribe } = useUnsubscribeMessage();
  const { enqueueSnackbar } = useSnackbar();
  const textRef = useRef(text);
  textRef.current = text;

  useEffect(() => {
    return () => {
      setNewMessageDefault({ ...methods.getValues(), text: textRef.current });
    };
  }, []);

  const openApplyTemplate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeApplyTemplate = () => {
    setAnchorEl(null);
  };

  const prepareTemplate = (template: string) => {
    if (
      TemplateBuilderTokens.some(({ simpleToken }) => template.includes(simpleToken)) &&
      activitySource === ActivitySourceType.ORDER
    ) {
      return templateApi.preview(sourceId!, activitySource, {
        name: 'some',
        body: convertTemplateBuilderVariables(template),
        modality: CommunicationModality.EMAIL,
      } as TemplateDTO);
    }

    return Promise.resolve(template);
  };

  const ifHasUnsavedChangesAndContainVariable = (text: string) => {
    if (
      !!showSaveDialog &&
      !changeSetRef.current?.isEmpty() &&
      TemplateBuilderTokens.some(({ simpleToken }) => text?.includes?.(simpleToken))
    ) {
      enqueueSnackbar('Please save changes.', { variant: 'warning' });
      showSaveDialog();
      return true;
    }

    return false;
  };

  const sendMsg = (data: any) => {
    if (ifHasUnsavedChangesAndContainVariable(text)) {
      return;
    }

    createFollowUpTask(data, activitySource, sourceId)
      .then(() => {
        enqueueSnackbar('Follow up created successfully', successMessage);

        reset(defaultFollowUp);
      })
      .catch(() => {});

    if (!sourceId) {
      enqueueSnackbar('Under construction', errorMessageOptions);
    }

    if (!text || text.trim().length === 0 || !sourceId) {
      return;
    }

    setIsLoadingMessage(true);

    prepareTemplate(text)
      .then((processedTemplate) =>
        communicationAPI.sendMessage(
          sourceId,
          processedTemplate,
          messageTypeMap.get(activitySource) as 'order' | 'account',
        ),
      )
      .then((res) => {
        setText('');
        reload();
      })
      .catch(() => {
        enqueueSnackbar('Message was not sent. Try again', errorMessageOptions);
      })
      .then(() => setIsLoadingMessage(false));
  };

  const sendSms = (data: any) => {
    if (ifHasUnsavedChangesAndContainVariable(text)) {
      return;
    }

    createFollowUpTask(data, activitySource, sourceId).then(() => {
      enqueueSnackbar('Follow up created successfully', successMessage);
      reset(defaultFollowUp);
    });

    if (!sourceId) {
      enqueueSnackbar('Under construction', errorMessageOptions);
    }

    if (!text || text.trim().length === 0 || !sourceId || !toPhoneNumber) {
      return;
    }

    setIsLoadingSms(true);

    prepareTemplate(text)
      .then((processedTemplate) =>
        communicationAPI.sendSms({
          sourceDescriptor: { referencedEntityId: sourceId, activitySource: activitySource },
          body: processedTemplate,
          toPhone: toPhoneNumber,
        }),
      )
      .then((res) => {
        reload();
        setText('');
      })
      .catch(() => {
        enqueueSnackbar('SMS was not sent. Try again', errorMessageOptions);
      })
      .then(() => {
        setIsLoadingSms(false);
      });
  };

  useEffect(() => {
    if (sourceId) {
      // need to find last SMS sent by customer
      const lastSms = pastActions.find((item) => {
        const message = item?.activity as unknown as Message;

        return (
          message?.type === MessageTypes.SMS &&
          message?.recipient === RecipientTypes.COMPANY &&
          !message.sentAutomatically
        );
      });

      if (lastSms && unsubscribeWords.includes((lastSms?.activity as unknown as Message)?.text?.toLowerCase?.())) {
        setIsUnsubscribe?.(true);
        setIsCanSendSMS(false);
        setErrorMessage('Customer has been unsubscribed');
        return;
      }

      setIsUnsubscribe?.(false);

      if (toPhoneNumber) {
        communicationAPI
          .checkConditionsBeforeSendSms(activitySource, sourceId, {
            number: toPhoneNumber,
            type: phoneType,
            smsCapability: currentSMSCapability,
            phoneCarrierType: currentPhoneCarrierType,
          } as Phone)
          .then(() => {
            setIsCanSendSMS(true);
            setErrorMessage('');
          })
          .catch((error) => {
            setIsCanSendSMS(false);
            const notParsedMessage = getIn(error, ['errors', 0, 'message'], null) as string;
            const message = notParsedMessage?.match(/:(.*)\n/)?.[1]?.trim() ?? notParsedMessage;
            setErrorMessage(message);
          });
      }
    } else {
      setErrorMessage('');
    }
  }, [sourceId, isDisableSms, toPhoneNumber, pastActions?.size]);

  const handleChooseTemplate = (template: Template) => () => {
    setText(template.body);
    closeApplyTemplate();
  };

  const applyTemplate = (
    <Box display="flex" justifyContent="flex-end">
      <Button
        color="primary"
        variant="outlined"
        onClick={openApplyTemplate}
        loading={inFlightTemplates}
        disabled={inFlightTemplates}
      >
        Apply Template
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeApplyTemplate}>
        {groupedSMSTemplatesByTemplateFolder.map(([folderName, templates]) => [
          <ListSubheader
            aria-controls={`${folderName}-content`}
            id={`${folderName}-header`}
            classes={{ sticky: classes.subheader }}
          >
            {folderName}
          </ListSubheader>,
          templates.map((item) => (
            <MenuItem key={item.id} onClick={handleChooseTemplate(item)}>
              {item.name}
            </MenuItem>
          )),
        ])}
      </Menu>
    </Box>
  );

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Box my={1} minHeight={150}>
        <Box mx={2} my={1} display="flex" justifyContent="space-between" alignItems="center">
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              <Box ml={1}>
                <BodyText>{`To: ${order?.contactInfo?.firstName} ${order?.contactInfo?.lastName} <${formatPhoneNumber(
                  order?.contactInfo?.primaryPhone?.number,
                )}>`}</BodyText>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              {!!groupedSMSTemplatesByTemplateFolder.length &&
                activitySource === ActivitySourceType.ORDER &&
                applyTemplate}
            </Grid>
          </Grid>
        </Box>
        <MessageInput
          sendMsg={handleSubmit(sendMsg as any)}
          setText={setText}
          sendSms={handleSubmit(sendSms as any)}
          text={text}
          isLoadingMessage={isLoadingMessage}
          isLoadingSms={isLoadingSms}
          isCanSendSMS={isCanSendSMS && currentSMSCapability === SmsCapability.CAPABLE_ON}
          errorMessage={errorMessage}
          minRows={5}
          displayButtonsInsideInputs={false}
        >
          <CreateFollowUpForm />
        </MessageInput>
      </Box>
    </FormProvider>
  );
};
