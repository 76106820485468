import { Order } from '@elromcoinc/react-shared';
import { List, Map } from 'immutable';

import Waypoint from 'admin/entities/Waypoint';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto, { UpdatedValueDto } from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const propertyName = 'Address';

const getWaypointIdToAddressMap = (order: Order): Map<string, string> =>
  (order.getAddressWaypointsBasedOnService() as List<Waypoint>).reduce(
    (map, wp) => (wp.id !== null ? map.set(wp.id, wp.address.fullAddressLine()) : map),
    Map<string, string>(),
  );

const deleteAddress: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('waypoints') > -1) {
    const oldAddressesMap = getWaypointIdToAddressMap(oldOrder);
    const newAddressesMap = getWaypointIdToAddressMap(newOrder);
    return oldAddressesMap
      .filter((address, wpId) => !newAddressesMap.has(wpId))
      .keySeq()
      .map<UpdatedValueDto>((id) =>
        createUpdatedValueDto(
          oldAddressesMap.get(id) || '',
          newAddressesMap.get(id) || '',
          propertyName,
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      )
      .toArray();
  }
  return null;
};

export default deleteAddress;
