import axios from 'axios';
import { axiosRetryConfig } from 'axios/axiosRetryConfig';
import { requestInterceptor } from 'axios/requestInterceptor';
import { responseErrorInterceptor } from 'axios/responseErrorInterceptor';
import { responseInterceptor } from 'axios/responseInterceptor';
import qs from 'query-string';

axiosRetryConfig(axios);

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
axios.defaults.paramsSerializer = (params) => qs.stringify(params);
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
