import TableCell from '@material-ui/core/TableCell';
import styled from 'styled-components';

export const ValueTableCell = styled(TableCell)`
  && {
    > p {
      width: 100%;
      min-height: 22px;
    }
  }
`;
export const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: ${({ $wrap = false }) => ($wrap ? 'wrap' : 'nowrap')};
  align-items: center;
  input,
  p {
    margin-top: 0;
    ${({ $manual = false }) => ($manual ? 'color: #FF5B5B !important' : '')};
    border-width: 2px;
    border-radius: 4px;
    border-style: groove;
    border-color: transparent;
    &:focus {
      border-color: #17d5ff;
    }
    &.error {
      border-color: ${({ theme }) => theme.palette.error.main};
    }
  }
  fieldset {
    border: none;
  }
  div {
    ${({ $textColor }) => ($textColor ? `color: ${$textColor} !important;` : '')};
    ${({ $fontWeight }) => ($fontWeight ? `font-weight: ${$fontWeight} !important;` : '')};
  }
  svg.MuiSelect-icon {
    ${({ $showSvg }) => (!$showSvg ? 'display: none;' : '')}
  }
`;
export const FlexWithNegativeMargin = styled(Flex)`
  margin-left: -10px;
`;
