export const SEND_MASS_EMAIL = 'SEND_MASS_EMAIL';
export const SEND_MASS_TEXT = 'SEND_MASS_TEXT';
export const CHANGE_STATUS = 'CHANGED_STATUS';
export const ASSIGN_SALES_PERSON = 'ASSIGN_SALES_PERSON';
export const ADD_FLAG = 'ADD_FLAG';
export const EXPORT = 'EXPORT';

export const OrdersActionType = {
  [SEND_MASS_EMAIL]: 'Send Mass Email',
  [SEND_MASS_TEXT]: 'Send Mass Text',
  [CHANGE_STATUS]: 'Change Status',
  [ASSIGN_SALES_PERSON]: 'Assign Sales Person',
  [ADD_FLAG]: 'Add Flag',
  [EXPORT]: 'Export',
};
