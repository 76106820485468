import React from 'react';

import { BodyText, Order } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(({ palette }) =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    totalDue: {
      border: `1px solid ${palette.grey[300]}`,
      padding: '20px 0px',
    },
  }),
);

export const ClosingBalance = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.costBreakdownItem, classes.totalDue)}>
        <BodyText>Total Due</BodyText>
        <BodyText>
          {(order.isLongDistance(serviceIndex)
            ? order.closingOrderDetail?.getLDBalanceDue(serviceIndex, isSelectedAllServices)
            : order.closingOrderDetail?.getBalanceDue(serviceIndex, isSelectedAllServices)
          )?.asHumanReadableString()}
        </BodyText>
      </Box>
    </Grid>
  );
};
