import React from 'react';

import { IconButton } from '@elromcoinc/react-shared';
import { Badge, Box, useTheme, withStyles } from '@material-ui/core';
import Chat from '@material-ui/icons/ChatBubble';

import { useOrderClosingContext, useUnreadMessages } from 'admin/components/OrderWindow/context';

const StyledBadge = withStyles((theme) => ({
  badge: {
    border: `1px solid ${theme.palette.background.paper}`,
  },
}))(Badge);

const halfSecond = 500;

const UnreadMessagesButton = () => {
  const theme = useTheme();
  const { isClosing, setIsClosing } = useOrderClosingContext();
  const { unreadMessages, messageRef } = useUnreadMessages();

  const scrollToMessagesSection = () => {
    messageRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const goToMessageSection = () => {
    if (isClosing) {
      setIsClosing(false);
      setTimeout(scrollToMessagesSection, halfSecond);
      return;
    }
    scrollToMessagesSection();
  };

  return (
    <Box color={theme.palette.common.white}>
      <IconButton size="medium" color="inherit" onClick={goToMessageSection}>
        <StyledBadge badgeContent={unreadMessages} max={99} color="error">
          <Chat fontSize="inherit" color="inherit" />
        </StyledBadge>
      </IconButton>
    </Box>
  );
};

export { UnreadMessagesButton };
