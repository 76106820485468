import { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  BodyBigText,
  BodyText,
  Button,
  Checkbox,
  ElromcoCircularProgress,
  HeaderBigText,
  Link,
  Modal,
  PaymentActivityDto,
  PaymentActivityType,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  Tab,
  TabPanel,
  Tabs,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { List } from 'immutable';

import orderAPI from 'admin/api/OrderAPI';
import { useOrderState } from 'admin/components/OrderWindow/context';
import { ConfirmationSignature, ConfirmationSignatureType } from 'common-types';
import payImg from 'common/imgs/pay_image.png';

import Banner from './Banner';

function a11yProps(index: number, label: string) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}-${label}`,
  };
}

const useStyles = makeStyles(() => ({
  agreeText: {
    display: 'flex',
    alignItems: 'baseline',
  },
}));

interface OrderForServiceModalProps {
  onClose: () => void;
}

export const OrderForServiceModal: FC<OrderForServiceModalProps> = ({ onClose }) => {
  const classes = useStyles();
  const { order } = useOrderState();
  const [inFlight, setInFlight] = useState(false);
  const [templates, setTemplates] = useState<ConfirmationSignature[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(0);

  const serviceQuote = order?.getServiceQuote();
  const paymentAdjustments = (serviceQuote?.paymentAdjustmentDtos ?? List()) as List<PaymentAdjustmentDTO>;
  const payments = (serviceQuote?.payments ?? List()) as List<PaymentActivityDto>;
  const calculatedDeposit = payments.reduce((total, payment) => {
    if (payment.type === PaymentActivityType.DEPOSIT && payment.settledAmount) {
      const adjustmentsTotal = paymentAdjustments
        .filter((pa) => pa.paymentId === payment.id)
        .reduce(
          (accumulator, pa) =>
            pa.paymentAdjustmentType === PaymentAdjustmentType.CASH_DISCOUNT
              ? accumulator + pa.total
              : accumulator - pa.total,
          0,
        );
      return total + payment.settledAmount + adjustmentsTotal;
    }

    return total;
  }, 0);
  const totalDepositPaid = paymentAdjustments.isEmpty() ? serviceQuote?.totalDepositPaid || 0 : calculatedDeposit;
  const currentDeposit = (serviceQuote?.reservationAmountNeeded || 0) - totalDepositPaid;
  const deposit = currentDeposit > 0 ? currentDeposit : 0;

  useEffect(() => {
    setInFlight(true);

    orderAPI
      .getConfirmationSignatureTemplates(order?.orderId!)
      .then((templates) => {
        const filteredTemplates = templates.filter((t) => t.type === ConfirmationSignatureType.CONFIRMATION);
        setTemplates(filteredTemplates);
        setSelectedTemplateId(filteredTemplates[0].id! || 0);
      })
      .catch(() => {})
      .then(() => {
        setInFlight(false);
      });
  }, []);

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
    },
  ];

  const handleChangeSelectedTemplateId: any = (event: ChangeEvent<{}>, value: any) => {
    setSelectedTemplateId(value);
  };

  const noTemplatesText = inFlight ? 'Loading...' : 'No order for service found for current order';

  return (
    <Modal open onClose={onClose} actions={actions} title="Order for Service" maxWidth={templates.length ? 'md' : 'xs'}>
      <Box minHeight={templates.length ? 500 : 0}>
        {templates.length > 1 && (
          <Tabs
            indicatorColor="primary"
            inheritLightBackground
            value={selectedTemplateId}
            onChange={handleChangeSelectedTemplateId!}
          >
            {templates.map((template, index) => (
              <Tab {...a11yProps(template.id!, template.name)} value={template.id} label={template.name || index + 1} />
            ))}
          </Tabs>
        )}
        {inFlight && <ElromcoCircularProgress />}
        {templates.length ? (
          templates.map((t) => (
            <TabPanel key={t.id} value={selectedTemplateId} index={t.id!}>
              <Box m={2}>
                <Banner>
                  <BodyBigText color="error">Please review your move confirmation, and scroll down to book</BodyBigText>
                </Banner>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: t.body,
                  }}
                />
              </Box>
            </TabPanel>
          ))
        ) : (
          <BodyBigText>{noTemplatesText}</BodyBigText>
        )}
        {!inFlight && !!templates.length && (
          <Box>
            <Box display="flex" justifyContent="center" width="100%" mt={2} alignItems="center">
              <Checkbox
                disabled
                checked
                size="small"
                label={
                  <BodyText className={classes.agreeText}>
                    <span>I agree with </span>
                    <span>{order?.companyName}</span>&nbsp;
                    <Link disabled>Company Policies</Link>&nbsp;
                    <span>and </span>
                    <span>{order?.companyName}</span>&nbsp;
                    <Link disabled>Cancellation Policy</Link>
                  </BodyText>
                }
              />
            </Box>
            {!!deposit && deposit > 0 && (
              <Box display="flex" justifyContent="center" width="100%" mt={2} alignItems="center">
                <Box mr={2}>
                  <HeaderBigText>
                    <b>{`Deposit: $${formatCurrency(deposit)}`}</b>
                  </HeaderBigText>
                </Box>
                <img src={payImg} alt="pay" width="226" />
              </Box>
            )}
            <Box display="flex" justifyContent="center" width="100%" mt={2} alignItems="center">
              <Button color="secondary" disabled loading={inFlight} rounded>
                Sign order
                {!!deposit && ' and pay deposit'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
