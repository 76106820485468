import {
  ACCOUNT_TYPE,
  DATE_TYPE,
  ESTIMATE_TYPE,
  FLAGS,
  FROM_STATE,
  INVENTORY,
  MOVE_SIZE,
  MOVE_TYPE,
  SALES_PERSON,
  SERVICE_TYPE,
  SOURCE,
  STATUS,
  TO_STATE,
} from 'admin/components/Orders/config/OrdersLabels';

export const MESSAGES = 'messages';
export const DEFAULT_DATE_TYPE = 'created';

export const dateTypes = {
  [DEFAULT_DATE_TYPE]: 'Creation Date',
  moveStartDate: 'Move Date',
  updated: 'Update Date',
  statusUpdated: 'Status Updated',
  bookingDate: 'Booked Date',
};

export const inventoryFilter = {
  true: 'Submitted',
  false: 'Not Submitted',
};

export const filtersInputNames = [
  STATUS,
  MOVE_TYPE,
  SERVICE_TYPE,
  ESTIMATE_TYPE,
  ACCOUNT_TYPE,
  SALES_PERSON,
  SOURCE,
  MOVE_SIZE,
  FROM_STATE,
  TO_STATE,
  FLAGS,
  INVENTORY,
  DATE_TYPE,
];

const BOOKED_THIS_MONTH = 'BOOKED_THIS_MONTH';
const MOVES_THIS_MONTH = 'MOVES_THIS_MONTH';
const ALL_MOVES = 'ALL_MOVES';
const ALL_UPCOMING_MOVES = 'ALL_UPCOMING_MOVES';

const bookedOptionsLabels = {
  [BOOKED_THIS_MONTH]: 'Booked this month',
  [MOVES_THIS_MONTH]: 'Moves this month',
  [ALL_MOVES]: 'All moves',
  [ALL_UPCOMING_MOVES]: 'All upcoming moves',
};
const bookedOptionsLabelsSmall = {
  [BOOKED_THIS_MONTH]: 'BTM',
  [MOVES_THIS_MONTH]: 'MTM',
  [ALL_MOVES]: 'AM',
  [ALL_UPCOMING_MOVES]: 'AUM',
};
const bookedOptions = Object.entries(bookedOptionsLabels);

export {
  BOOKED_THIS_MONTH,
  MOVES_THIS_MONTH,
  ALL_UPCOMING_MOVES,
  bookedOptionsLabels,
  bookedOptions,
  bookedOptionsLabelsSmall,
};

export const STATUS_QUERY_PARAM_NAME = 'status';
export const TAB_QUERY_PARAM_NAME = 'tab';
export const MESSAGES_PATH_NAME = '/orders/messages';
export const DASHBOARD_TAB_NAME = 'dashboard';
export const ORDERS_TAB_NAME = 'orders';
export const ORDERS_PATH_NAME = '/orders';
export const DASHBOARD_TAB_INDEX = 0;
export const ORDERS_TAB_INDEX = 1;
export const SORT_BY_QUERY_NAME = 'sortBy';
export const SORT_ORDER_QUERY_NAME = 'sortOrder';
export const ROWS_PER_PAGE_QUERY_NAME = 'rowsPerPage';
export const PAGE_QUERY_NAME = 'page';
export const DISPATCH_PATH_NAME = '/dispatch';
