import { FC } from 'react';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 2,
    bottom: theme.spacing(1),
    height: theme.spacing(10),
    borderRadius: 5,
    backgroundColor: theme.palette.grey[300],
    pointerEvents: 'none',
    boxSizing: 'border-box',
  },
}));

interface TimeOffBlockProps {
  start: number;
  width: number;
  widthCoefficient: number;
}

export const TimeOffBlock: FC<TimeOffBlockProps> = ({ start, width: widthInPixels, widthCoefficient }) => {
  const classes = useStyles();
  const width = widthInPixels * widthCoefficient;
  const left = start * widthCoefficient;

  return <Box left={left} width={width} className={classes.estimatedBlock}></Box>;
};
