import { useEffect } from 'react';

import orderApi from 'admin/api/OrderAPI';

const useLockOrder = (orderId: number) => {
  useEffect(() => {
    orderApi.lockOrder(orderId);
    return () => {
      orderApi.unlockOrder(orderId);
    };
  }, [orderId]);
};

export { useLockOrder };
