import { useEffect, useState } from 'react';

import { ActivitySourceType, InvoiceDto } from '@elromcoinc/react-shared';
import { useSnackbar } from 'notistack';

import invoiceAPI from 'admin/api/InvoiceAPI';
import { InvoiceModalContext } from 'admin/components/OrderWindow/modals/Invoices/InvoiceModalContext';
import Invoices from 'admin/components/OrderWindow/modals/Invoices/Invoices';
import { AccountDto } from 'common-types';
import { ActivitySourceQueryParams } from 'common-types/ActivitySourceQueryParams';
import { SortOrder } from 'common-types/SortOrder';

interface InvoiceModalProviderProps {
  open: boolean;
  onCancel: () => void;
  sourceId: number;
  activitySource: ActivitySourceType;
  account?: AccountDto;
}

const InvoiceModalProvider = (props: InvoiceModalProviderProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('id');
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
  const [inFlight, setInFLight] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceDto[]>([]);
  const [invoicesCount, setInvoicesCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchInvoices = () => {
    setInFLight(true);

    const params = {
      pageIndex: page - 1,
      pageSize: rowsPerPage,
      sortBy,
      sortOrder,
    } as ActivitySourceQueryParams;

    if (props.activitySource === ActivitySourceType.ORDER) {
      params['order.id'] = props.sourceId;
    } else if (props.activitySource === ActivitySourceType.CUSTOMER_ACCOUNT) {
      params['account.id'] = props.sourceId;
    } else {
      params['activitySources.sourceId'] = props.sourceId;
      params['activitySources.activitySource'] = props.activitySource;
    }

    invoiceAPI
      .getInvoices<InvoiceDto>(params)
      .then(({ pageElements, totalElements }) => {
        setInvoicesCount(totalElements);
        setInFLight(false);
        setInvoices(pageElements);
      })
      .catch(() => {
        setInFLight(false);
        enqueueSnackbar('Unable to retrieve invoices for current order. Please try later.', { variant: 'error' });
      });
  };

  useEffect(() => {
    fetchInvoices();
  }, [page, sortBy, sortOrder, rowsPerPage]);

  return (
    <InvoiceModalContext.Provider
      value={{
        page,
        setPage,
        sortBy,
        setSortBy,
        sortOrder,
        setSortOrder,
        inFlight,
        invoices,
        invoicesCount,
        rowsPerPage,
        setRowsPerPage,
        fetchInvoices,
        sourceId: props.sourceId,
        activitySource: props.activitySource,
        account: props.account,
      }}
    >
      <Invoices {...props} />
    </InvoiceModalContext.Provider>
  );
};

export default InvoiceModalProvider;
