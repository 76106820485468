import { useEffect, useState } from 'react';

import { UI_LOCALIZED_TIME_FORMAT } from '@elromcoinc/react-shared';
import { addHours, addMinutes, format, startOfDay } from 'date-fns';

import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';

const MINUTES_IN_DAY = 24 * 60;
const INCREMENT_STEP = 30;
const INCREMENT_STEP_15 = 15;
const startOfTheDay = startOfDay(new Date());
const AMOUNT_OF_HOURS = 6;

const renderTimeToAdjust = (incrementStep) =>
  new Array(MINUTES_IN_DAY / incrementStep)
    .fill(0)
    .map((_, i) => i * incrementStep)
    .map((it) => [
      format(addMinutes(startOfTheDay, it), TIME_STRING_FORMAT),
      format(addMinutes(startOfTheDay, it), UI_LOCALIZED_TIME_FORMAT),
    ]);

const renderStartTimeToAdjust = (incrementStep) =>
  new Array(MINUTES_IN_DAY / incrementStep)
    .fill(0)
    .map((_, i) => i * incrementStep)
    .map((it) => [
      format(addMinutes(addHours(startOfTheDay, AMOUNT_OF_HOURS), it), TIME_STRING_FORMAT),
      format(addMinutes(addHours(startOfTheDay, AMOUNT_OF_HOURS), it), UI_LOCALIZED_TIME_FORMAT),
    ]);

const useTimeDuration = (timeToAdjust) => {
  const [timeOptions, setTimeOptions] = useState([]);
  const [timeOptions15mins, setTimeOptions15mins] = useState([]);
  const [adjustedTimeOptions, setAdjustedTimeOptions] = useState([]);
  const [adjustedTimeOptions15mins, setAdjustedTimeOptions15mins] = useState([]);

  useEffect(() => {
    setTimeOptions(renderTimeToAdjust(INCREMENT_STEP));
  }, []);

  useEffect(() => {
    setTimeOptions15mins(renderStartTimeToAdjust(INCREMENT_STEP_15));
  }, []);

  useEffect(() => {
    setAdjustedTimeOptions(
      !timeToAdjust
        ? timeOptions
        : timeOptions.reduce((acc, item) => (acc.length || item[0] === timeToAdjust ? [...acc, item] : acc), []),
    );
  }, [timeOptions, timeToAdjust]);

  useEffect(() => {
    setAdjustedTimeOptions15mins(
      !timeToAdjust
        ? timeOptions15mins
        : timeOptions15mins.reduce((acc, item) => (acc.length || item[0] === timeToAdjust ? [...acc, item] : acc), []),
    );
  }, [timeOptions15mins, timeToAdjust]);

  return [timeOptions, adjustedTimeOptions, timeOptions15mins, adjustedTimeOptions15mins];
};

export default useTimeDuration;
