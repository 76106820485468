import { Record } from 'immutable';

export interface UpdatedValueDto {
  serviceNumber?: number | null;
  propertyName?: string | null;
  oldValue: string;
  newValue: string;
}

const defaultUpdatedValueProps: UpdatedValueDto = {
  newValue: '',
  oldValue: '',
  propertyName: null,
  serviceNumber: null,
};

class UpdatedValue extends Record(defaultUpdatedValueProps) {
  public constructor(values: Partial<UpdatedValueDto> = {}) {
    super(values);
  }
}

const createUpdatedValueDto = (
  oldValue: string,
  newValue: string,
  propertyName?: string,
  serviceNumber?: number | null,
): UpdatedValueDto => new UpdatedValue({ oldValue, newValue, propertyName, serviceNumber });

export default createUpdatedValueDto;
