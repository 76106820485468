import { MoveType, SettingNames } from '@elromcoinc/react-shared';
import { Map } from 'immutable';

import { getRooms } from 'admin/selectors/getRooms';
import { MoveUnitType, UnitSizeSpec } from 'common-types';

const { UNIT_SIZE_SPEC, MOVE_TYPES } = SettingNames;

const getMoveSizes = (settings: { UnitSizeSpec: UnitSizeSpec[] }) => {
  const moveSizeOptions = settings?.[UNIT_SIZE_SPEC] ?? [];

  const roomsMap = getRooms(settings).map((values: UnitSizeSpec[]) =>
    values.reduce((map: any, room: any) => map.set(room.id, room), Map() as Map<number, UnitSizeSpec>),
  );

  return MOVE_TYPES.reduce(
    (result, moveType) =>
      result.set(
        moveType as MoveType,
        moveSizeOptions
          .filter((it: UnitSizeSpec) => it.moveUnitType === MoveUnitType.SITE && it.moveType === moveType)
          .reduce(
            (map: any, site: any) =>
              map.set(site.id, {
                ...site,
                tags: (site.tags || '')
                  .split(',')
                  .filter(Boolean)
                  .map((tagId: string) => [+tagId.replace(/\D/g, ''), tagId[0] === 'd'])
                  .map(([tagId, isDefault]: any) => ({ ...roomsMap.get(moveType, Map()).get(tagId, {}), isDefault })),
              }),
            Map<number, UnitSizeSpec>(),
          ),
      ),
    Map<MoveType, Map<number, UnitSizeSpec>>(),
  );
};

export { getMoveSizes };
