import { BodyText } from '@elromcoinc/react-shared';
import { Box, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';

import { useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: '50%',
      border: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: theme.spacing(1),
      bottom: theme.spacing(1),
    },
  }),
);

interface ServiceWaypointNumberProps {
  index: number;
}

export const ServiceWaypointNumber = ({ index }: ServiceWaypointNumberProps) => {
  const { order } = useOrderState();
  const classes = useStyles();

  if (order?.services?.size! < 2) {
    return null;
  }

  return (
    <Tooltip title={`Waypoint of Service ${index + 1}`} placement="top" arrow>
      <Box className={classes.root}>
        <BodyText>{index + 1}</BodyText>
      </Box>
    </Tooltip>
  );
};
