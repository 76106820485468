import { EstimateType, ServiceType, isPackingService } from '@elromcoinc/react-shared';

import {
  AutomationRuleByGeneralServiceTypeBinding,
  AutomationRuleByGeneralServiceTypeFlatRate,
  AutomationRuleByGeneralServiceTypeNonBinding,
} from 'admin/constants';
import { AutomationRuleByGeneralServiceTypeQuoteOff } from 'admin/constants/AutomationRuleByGeneralServiceTypeQuoteOff';
import { AutomationRuleName } from 'common-types';

export const resolveShortNameByServiceTypeAndSettingsCondition = (
  serviceType: ServiceType,
  displayQuoteOnCustomerPage: boolean,
  estimateType: EstimateType,
) => {
  if (displayQuoteOnCustomerPage) {
    if (isPackingService(serviceType)) {
      return AutomationRuleName.WHEN_A_NEW_PACKING_DAY_HOURLY_LEAD_IS_CREATED;
    }

    switch (estimateType) {
      case EstimateType.BINDING:
        return AutomationRuleByGeneralServiceTypeBinding[serviceType];
      case EstimateType.NON_BINDING:
        return AutomationRuleByGeneralServiceTypeNonBinding[serviceType];
      case EstimateType.FLAT_RATE:
        return AutomationRuleByGeneralServiceTypeFlatRate[serviceType];
    }
  }

  return AutomationRuleByGeneralServiceTypeQuoteOff[serviceType];
};
