import { ServiceType, isLongDistanceService } from '@elromcoinc/react-shared';

import { getServiceOptions } from 'admin/selectors';
import { DistanceType } from 'common-types';

const filterServiceOptionsByDistanceType = (
  serviceOptions: ReturnType<typeof getServiceOptions>,
  distanceType: DistanceType,
) => {
  if (!distanceType) {
    return serviceOptions;
  }
  switch (distanceType) {
    case DistanceType.LOCAL:
      return serviceOptions.filter((option: (string | number)[]) => !isLongDistanceService(option[2]));
    case DistanceType.INTRASTATE:
      return serviceOptions.filter((option: (string | number)[]) => ServiceType.LD_INTRASTATE === option[2]);
    case DistanceType.INTERSTATE:
      return serviceOptions.filter((option: (string | number)[]) => ServiceType.LD_INTERSTATE === option[2]);
    case DistanceType.NOT_SUPPORTED:
    default:
      return null;
  }
};

export { filterServiceOptionsByDistanceType };
