import React from 'react';

import { Radio, RadioGroup } from '@elromcoinc/react-shared';
import { Box, Grid, Tooltip } from '@material-ui/core';

import { PAYMENT_TYPE, labels } from '../CreateCharge/Common';

interface PaymentTypeSelectorProps {
  isDepositAvailable: boolean;
  ifDepositUnavailableHint: string;
}

export const PaymentTypeSelector = ({ isDepositAvailable, ifDepositUnavailableHint }: PaymentTypeSelectorProps) => {
  return (
    <Grid item xs={12}>
      <RadioGroup direction="row" aria-label="payment-type" name={PAYMENT_TYPE} label={labels[PAYMENT_TYPE]}>
        <Tooltip title={!isDepositAvailable ? ifDepositUnavailableHint : ''}>
          <Box>
            <Radio value="DEPOSIT" label="Deposit" color="primary" disabled={!isDepositAvailable} />
          </Box>
        </Tooltip>
        <Radio value="REGULAR" label="Regular" color="primary" />
      </RadioGroup>
    </Grid>
  );
};
