import { ActivitySourceType, PaymentActivityType, PaymentStatus } from '@elromcoinc/react-shared';

import { PaymentTypeName } from 'admin/constants/PaymentType';

export enum PaymentLabels {
  PAYMENT_TYPE = 'paymentType',
  PAYMENT_BY = 'activitySources',
  PAYMENT_FLAG = 'paymentStatus',
  PAYMENT_ACTIVITY_TYPE = 'paymentActivityType',
  PAYMENT_USER_TYPE = 'userType',
}

export const PaymentLabelName: Record<PaymentLabels, string> = {
  [PaymentLabels.PAYMENT_TYPE]: 'Payment Type',
  [PaymentLabels.PAYMENT_BY]: 'Payment Source',
  [PaymentLabels.PAYMENT_FLAG]: 'Payment Flag',
  [PaymentLabels.PAYMENT_ACTIVITY_TYPE]: 'Payment For',
  [PaymentLabels.PAYMENT_USER_TYPE]: 'Payment By',
};

export const paymentFiltersInputName: PaymentLabels[] = [
  PaymentLabels.PAYMENT_TYPE,
  PaymentLabels.PAYMENT_FLAG,
  PaymentLabels.PAYMENT_ACTIVITY_TYPE,
  PaymentLabels.PAYMENT_USER_TYPE,
];

const paymentTypeOptions = Object.entries(PaymentTypeName);
const paymentByOptions = [
  [ActivitySourceType.ORDER, 'Order'],
  [ActivitySourceType.INVOICE, 'Invoice'],
  [ActivitySourceType.CUSTOMER, 'Customer'],
  [ActivitySourceType.ORDER_CLOSING, 'Contract'],
];
const paymentFlagOptions = [
  [PaymentStatus.PROCESSED, 'Processed'],
  [PaymentStatus.PENDING, 'Pending'],
  [PaymentStatus.VOIDED, 'Voided'],
  [PaymentStatus.REFUND, 'Refund'],
];
const paymentActivityType = [
  [PaymentActivityType.DEPOSIT, 'Deposit'],
  [PaymentActivityType.REGULAR, 'Regular'],
];
const paymentUserType = [
  ['EMPLOYEE', 'Employee'],
  ['CUSTOMER', 'Customer'],
];
export const usePaymentCollectedFilterOption = () => {
  return {
    [PaymentLabels.PAYMENT_TYPE]: paymentTypeOptions,
    [PaymentLabels.PAYMENT_FLAG]: paymentFlagOptions,
    [PaymentLabels.PAYMENT_BY]: paymentByOptions,
    [PaymentLabels.PAYMENT_ACTIVITY_TYPE]: paymentActivityType,
    [PaymentLabels.PAYMENT_USER_TYPE]: paymentUserType,
  };
};
