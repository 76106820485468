const storage = localStorage;
const JWT = 'JWT';
const TENANT_ID = 'TENANT_ID';

class StorageUtil {
  set(jwt, tenantId = 1) {
    this.setJwt(jwt);
    this.setTenantId(tenantId);
  }

  getTenantId() {
    return storage.getItem(TENANT_ID);
  }

  getJwt() {
    return storage.getItem(JWT);
  }

  hasJwt() {
    return !!this.getJwt();
  }

  setJwt(jwt = null) {
    storage.setItem(JWT, jwt);
  }

  setTenantId(tenantId = 1) {
    storage.setItem(TENANT_ID, `${tenantId}`);
  }

  clear() {
    storage.removeItem(JWT);
    storage.removeItem(TENANT_ID);
  }
}

export default new StorageUtil();
