import React from 'react';

import { addHours, format, startOfDay } from 'date-fns';

import JobEntity from 'admin/components/OrderWindow/SchedulerBox/Job';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import { renderHour } from 'admin/components/OrderWindow/SchedulerBox/common/renderHour';
import { renderJob } from 'admin/components/OrderWindow/SchedulerBox/common/renderJob';

import { Row } from './Rows';

const hours = new Array(24).fill(0).map((_, idx) => format(addHours(startOfDay(new Date()), idx), 'h a'));

export const renderTruck =
  (
    dense: boolean,
    truckJobs: { [key: string]: JobEntity[] },
    orderId: number,
    renderJobTooltip?: (job: Job, jobComponent: JSX.Element) => JSX.Element,
  ) =>
  (truck: any, idx: number) => {
    const currentTruckJobs = truckJobs[truck.id] || [];
    const height = Math.max(...currentTruckJobs.map((it) => it.level[truck.id]));
    const hasSelectedJob = currentTruckJobs.some((job) => job.orderId === orderId);
    const selectedJobLevel =
      (hasSelectedJob ? currentTruckJobs.find((job) => job.orderId === orderId)?.level[truck.id] : null) || 0;
    const isUnassignedTruck = truck.capacity === 0;

    return (
      <Row key={truck.id} grey={idx % 2 === 0} dense={dense} heightSize={isUnassignedTruck ? height : 0}>
        {hours.map(renderHour(true))}
        {currentTruckJobs.map(
          renderJob({
            truckId: truck.id,
            orderId,
            dense,
            renderJobTooltip,
            hasSelectedJob,
            selectedJobLevel,
            isUnassignedTruck,
          }),
        )}
      </Row>
    );
  };
