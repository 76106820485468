const MileageTariffType = Object.freeze({
  BY_WEIGHT_LBS: 'BY_WEIGHT_LBS',
  BY_VOLUME_CUFT: 'BY_VOLUME_CUFT',
});
export default MileageTariffType;

const mileageTariffTypeName = Object.freeze({
  [MileageTariffType.BY_VOLUME_CUFT]: 'Cu/Ft',
  [MileageTariffType.BY_WEIGHT_LBS]: 'Lbs',
});

export { mileageTariffTypeName };

export const DISCOUNT_PER_CUFT_END_LABEL = 'Per/Cuft';
export const DISCOUNT_PER_POUND_END_LABEL = 'Per/Lbs';
