import React, { FC } from 'react';
import { BodySmallText, IconButton } from '@elromcoinc/react-shared';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { DestinationBlockProps } from 'admin/components/OrderWindow/blocks/Addresses';

export const MapBlock: FC<DestinationBlockProps> = ({
  showLastAddStop,
  onSetAddressIndex,
  openRoute,
  index,
  totalDistance,
}) => {
  return (
    <>
      {showLastAddStop ? (
        <IconButton size="tiny" onClick={onSetAddressIndex(index!)} variant="default" color="primary">
          <AddIcon />
        </IconButton>
      ) : (
        <IconButton size="small" color="primary" onClick={openRoute}>
          <ArrowForwardIcon />
        </IconButton>
      )}
      {totalDistance && <BodySmallText>{totalDistance}</BodySmallText>}
    </>
  );
};
