enum DashboardLabels {
  UNASSIGNED_LEADS = 'Unassigned leads',
  POSTPONED = 'Postponed',
  CANCELED_JOBS = 'Canceled jobs',
  INVENTORY_SUBMISSIONS = 'Inventory Submissions',
}

const tooltipLabels = {
  FOR_TIME_PERIOD_SELECTED: 'For time period selected',
  ALL_TIME_DATA: 'All time data',
};
export { DashboardLabels, tooltipLabels };
