import React, { ChangeEvent, FC, useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  BACKEND_DATE_FORMAT,
  BodySmallText,
  BodyText,
  Button,
  DELIVERY_DATE_END,
  DELIVERY_DATE_START,
  DELIVERY_DAYS_COUNT,
  DELIVERY_TIME_EARLIEST,
  DatePicker,
  DateRangePicker,
  EstimateType,
  LONG_DISTANCE_PLANNER,
  Link,
  MoveTypeTypes,
  Order,
  PackingPricingType,
  QuoteRequest,
  REQUESTED_DELIVERY_DATE_START,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TruckProps,
  isLoadingService,
  isLocalMove,
  isLongDistanceService,
  isPackingService,
  isUnloadingService,
  longDistanceMethods,
  toDate,
  useConfirm,
} from '@elromcoinc/react-shared';
import { ESTIMATED_DELIVERY_DATE } from '@elromcoinc/react-shared/dist/types/FieldNames';
import { Box, MenuItem, TableBody, TableCell, TableRow, createStyles, makeStyles } from '@material-ui/core';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { Alert } from '@material-ui/lab';
import { addBusinessDays, format, isSameDay, isValid, parse } from 'date-fns';
import { List, getIn } from 'immutable';
import { MaterialUiPickersDate } from 'material-ui-pickers';
import { DateRange } from 'materialui-daterange-picker';
import { useSnackbar } from 'notistack';

import { ADD_SERVICE_KEY } from 'admin/components/OrderWindow/OrderWindowConstants';
import { AdditionalLongDistanceRatePerSizeUnit } from 'admin/components/OrderWindow/blocks/MoveDetails/AdditionalLongDistanceRatePerSizeUnit';
import ClosingMoveSize from 'admin/components/OrderWindow/blocks/MoveDetails/ClosingMoveSize';
import { CrewSizeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/CrewSizeRow';
import { DoubleDriveTimeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/DoubleDriveTimeRow';
import { HourlyRateRow } from 'admin/components/OrderWindow/blocks/MoveDetails/HourlyRateRow';
import { LaborTimeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/LaborTimeRow';
import { LongDistanceRatePerSizeUnit } from 'admin/components/OrderWindow/blocks/MoveDetails/LongDistanceRatePerSizeUnit';
import { MinimumHoursRow } from 'admin/components/OrderWindow/blocks/MoveDetails/MinimumHoursRow';
import { MinimumSizeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/MinimumSizeRow';
import { RatePerDistanceUnitRow } from 'admin/components/OrderWindow/blocks/MoveDetails/RatePerDistanceUnitRow';
import { RatePerMoveSizeUnitFlatRate } from 'admin/components/OrderWindow/blocks/MoveDetails/RatePerMoveSizeUnitFlatRate';
import { TotalEstimatedTimeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/TotalEstimatedTimeRow';
import { TravelRateRow } from 'admin/components/OrderWindow/blocks/MoveDetails/TravelRateRow';
import { TravelTimeRow } from 'admin/components/OrderWindow/blocks/MoveDetails/TravelTimeRow';
import { TruckCountRow } from 'admin/components/OrderWindow/blocks/MoveDetails/TruckCountRow';
import { WeightFactorRow } from 'admin/components/OrderWindow/blocks/MoveDetails/WeightFactorRow';
import { MultiDayAllDayMode } from 'admin/components/OrderWindow/blocks/MultiDayAllDayMode';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderWindowEditModal,
} from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import useTimeDuration from 'admin/components/OrderWindow/hooks/useTimeDuration';
import Selector from 'admin/components/OrderWindow/modals/DateSpreadSelector';
import { DeliveryModal } from 'admin/components/OrderWindow/modals/DeliveryModal';
import MoveDetailsModal from 'admin/components/OrderWindow/modals/MoveDetails';
import StartTimeWindow from 'admin/components/OrderWindow/modals/StartTimeWindow';
import TruckCalendarModal from 'admin/components/OrderWindow/modals/TruckCalendar';
import { checkMaxOvernightDaysWarning, checkMaxPeriodInStorage } from 'admin/components/OrderWindow/utils';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { statusColor } from 'admin/constants/OrderStatus';
import settingName from 'admin/constants/SettingName';
import SettingName from 'admin/constants/SettingName';
import { getRates } from 'admin/selectors';
import { getServiceClosingPropertyName } from 'admin/utils';
import { MovingAndStorageSetting, OrderWindowModal, TripPlanerType } from 'common-types';
import { TruckSchedulerWindowPlacement } from 'common-types/TruckSchedulerWindowPlacement';
import Card from 'common/components/Card';
import { FullWidthBox, OverviewInfoTable } from 'common/components/Widgets';

import { AddService } from '../../modals/AddService';
import { BillOfLadingSignedPriorToPickup } from './BillOfLadingSignedPriorToPickup';
import DateWrapper from './Date';
import PickupDeliverySwitch, { MoveDetailsMode } from './PickupDeliverySwitch';
import { CompactRadioGroup, FlexWithNegativeMargin, ValueTableCell } from './refactoring';

const useStyles = makeStyles((theme) =>
  createStyles({
    table: {
      '& tbody tr td': {
        height: theme.spacing(2.5),
      },
      '& tbody tr td input, & tbody tr td .MuiSelect-root': {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        marginTop: '0 !important',
        marginBottom: '0 !important',
      },
      '& .fixed-row-height': {
        height: theme.spacing(2.5),
      },
    },
    estimatedDeliveryDateSelected: ({ statusColor }: { statusColor: string }) => ({
      color: statusColor,
    }),
    estimatedDeliveryDate: {
      fontFamily: 'Proxima Nova sans-serif',
      fontWeight: 500,
      fontSize: '13px',
    },
    moveSize: {
      padding: '10px',
      marginBottom: '20px',
      width: '200px',
    },
    moveSizeInput: {
      '& .MuiInputBase-input': {
        fontSize: '22px',
        textAlign: 'center',
      },
    },
  }),
);

const deliveryDaysOptions = new Array(45).fill(0).map((_, idx) => idx);

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;
const { MOVING_AND_STORAGE_SETTINGS } = SettingName;

const getEndDate = (dateString: string, count: number) => {
  const date = parse(dateString, 'P', new Date());
  return isValid(date) ? format(addBusinessDays(date, count), 'P') : null;
};

interface MoveDetailsProps {
  order: Order;
  onChange: (newValue: ChangeEvent<HTMLInputElement> | { value: any; name: string }) => void;
}

const MoveDetails: FC<MoveDetailsProps> = ({ order, onChange, ...rest }) => {
  const { serviceIndex } = useOrderServiceIndex();
  const { closingTitle, isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const classes = useStyles({ statusColor: statusColor('BOOKED') });
  const settings = useOrderWindowSettings();
  const service = isClosing ? order.closingOrderDetail.services.get(serviceIndex) : order.services.get(serviceIndex);
  const getServicePropertyName = useGetServicePropertyName();
  const isPacking = isPackingService(service.getType());
  const isLongDistance = isLongDistanceService(service.getType());
  const isLongDistanceMoveSize =
    isLongDistanceService(order?.getServiceType()) &&
    order?.getServiceQuote()?.longDistanceTariffDetails?.calculationMethod === longDistanceMethods.MILEAGE;
  const isLongDistanceAndClosing = isLongDistanceMoveSize && isClosing;
  const isLocalMoveType = isLocalMove(service.getType());
  const isUnloading = isUnloadingService(service.getType());
  const isLoading = isLoadingService(service.getType());
  const { quote } = service;
  const { generalService } = quote;
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const [detailsMode, setDetailsMode] = useState(MoveDetailsMode.PICKUP);
  const [timeOptions, adjustedTimeOptions] = useTimeDuration(quote.startTimeEarliest);
  const isFlatRate = order.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;
  const rates = getRates(settings)[order.moveType as MoveTypeTypes] || {};
  const { openEditModal, setOpenEditModal } = useOrderWindowEditModal();
  const truckScheduleWindowPlacement =
    settings?.[settingName.ORDERS_TRUCK_SCHEDULE_WINDOW_PLACEMENT] || TruckSchedulerWindowPlacement.POP_OUT;
  const maxDaysSettingNames = settingName.OVERNIGHT.MAX_DAYS[order.moveType as MoveTypeTypes];
  const maxOvernightDays = settings?.[maxDaysSettingNames] || 0;
  const disableInputs = isClosing || isCompleted || isLockSales;
  const disablePickupDateInput =
    disableInputs || (isLongDistance && detailsMode === MoveDetailsMode.DELIVERY && !isClosing);
  const maxOvernightDaysWarning = checkMaxOvernightDaysWarning(order, maxOvernightDays);
  const truckAssignments = getIn(order, `services.${serviceIndex}.truckAssignments`.split('.'), []) as List<TruckProps>;
  const showLaborOnlyServices = (isLocalMoveType || isUnloading || isLoading) && generalService.allowLaborOnlyPerOrder;
  const tripPlanerType = quote.get(LONG_DISTANCE_PLANNER);
  const deliveryDaysCountName = getServicePropertyName(`${DELIVERY_DAYS_COUNT}`);
  const requestedDeliveryDateStartName = getServicePropertyName(`${REQUESTED_DELIVERY_DATE_START}`);
  const estimatedDeliveryDateName = isClosing
    ? getServiceClosingPropertyName(serviceIndex, ESTIMATED_DELIVERY_DATE)
    : getServicePropertyName(ESTIMATED_DELIVERY_DATE);
  const arrivalTimeEarliestName = getServicePropertyName('arrivalTimeEarliest');
  const arrivalTimeLatestName = getServicePropertyName('arrivalTimeLatest');
  const deliveryTimeEarliesName = getServicePropertyName(DELIVERY_TIME_EARLIEST);
  const deliveryTruckReturnTimeName = getServicePropertyName('deliveryTruckReturnTime');
  const longDistancePlannerName = getServicePropertyName(LONG_DISTANCE_PLANNER);
  const storageQuote = order?.getServiceStorageQuote(serviceIndex);
  const currentStorageSetting = ((settings || {})[MOVING_AND_STORAGE_SETTINGS] || []).find(
    (serviceSettings: MovingAndStorageSetting) => serviceSettings.moveType === order?.moveType,
  );

  const { confirm: confirmStorageWarning, renderDialog: renderConfirmStorageWarning } = useConfirm({
    title: 'Warning',
    message:
      'The time in storage is more than the maximum allotted number of months for a storage tenant from your current settings. Do you wish to proceed?',
    maxWidth: 'sm',
    confirmTitle: 'Yes Proceed',
    cancelTitle: 'Don’t Proceed',
  });

  const { confirm: confirmWarning, renderDialog: renderConfirmationWarningDialog } = useConfirm({
    title: 'Warning',
    message: 'By selecting a new move date, you will need to reassign your truck to this move',
    confirmTitle: 'Ok',
    cancelTitle: 'Cancel',
  });

  const { enqueueSnackbar } = useSnackbar();

  const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];

  const handleOnMoveDetailsSave = (data: [string, any][]) => {
    data.forEach(([name, value]) => {
      onChange({ name, value });
    });
    setOpenModal(null);
    setOpenEditModal((oldSet) => oldSet.delete(OrderWindowModal.START_TIME_WINDOW));
  };

  const handleDeliveryDetailsSave = (data: [string, any][]) => {
    data.forEach(([name, value]) => onChange({ name, value }));
    setOpenModal(null);
    setOpenEditModal((oldSet) => oldSet.delete(OrderWindowModal.DELIVERY_DAYS_WINDOW));
  };

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOpenEditModal = (modalName: OrderWindowModal) => () => {
    setOpenEditModal((oldSet) => (oldSet.has(modalName) ? oldSet.delete(modalName) : oldSet.add(modalName)));
  };

  const handleCloseEditModal = (modalName: OrderWindowModal) => () => {
    setOpenEditModal((oldSet) => {
      return oldSet.has(modalName) ? oldSet.delete(modalName) : oldSet;
    });
  };

  const setDeliveryDates = (dates: DateRange) => {
    const { startDate, endDate } = dates;

    return [
      [DELIVERY_DATE_START, startDate],
      [DELIVERY_DATE_END, endDate],
    ].forEach(([name, value]) => onChange({ name: `services.${serviceIndex}.quote.${name}`, value }));
  };

  const updateDeliveryTime = (name: string) => (value: any) => onChange({ name: name, value });

  const handleChangeLaborOnly = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { checked: value, name },
    } = event;
    onChange({ name, value });
  };

  const renderStartTimeWindow = () => {
    if (quote.startTimeEarliest === null) {
      return <Text value="TBD" />;
    }

    return (
      <FlexWithNegativeMargin $wrap>
        <Select
          disabled={disableInputs}
          hiddenLabel
          value={quote.startTimeEarliest}
          name={`services.${serviceIndex}.quote.startTimeEarliest`}
          onChange={onChange}
          options={timeOptions}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <span>-</span>
        <Select
          disabled={disableInputs}
          value={quote.startTimeLatest}
          hiddenLabel
          name={`services.${serviceIndex}.quote.startTimeLatest`}
          onChange={onChange}
          options={adjustedTimeOptions}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </FlexWithNegativeMargin>
    );
  };

  const handleAddDay = (value: QuoteRequest) => {
    onChange({ value, name: ADD_SERVICE_KEY });
  };

  const handleDateChange = (name: string) => async (value: MaterialUiPickersDate) => {
    const allMoveDates = order?.services.map(({ date }) => date);
    const formatValue = format(value, BACKEND_DATE_FORMAT);

    if (
      name === `services.${serviceIndex}.date` &&
      checkMaxPeriodInStorage(
        order?.storageMoveStage,
        formatValue,
        storageQuote?.start,
        storageQuote?.end,
        currentStorageSetting?.maxMonthsInStorage!,
        service?.getType(),
      )
    ) {
      if (!(await confirmStorageWarning())) {
        return;
      }
    }

    if (
      (name.includes(`.${REQUESTED_DELIVERY_DATE_START}`) || name.includes(`.${ESTIMATED_DELIVERY_DATE}`)) &&
      !!value
    ) {
      onChange({ name, value: formatValue });
      return;
    }

    if (allMoveDates.some((date) => isSameDay(toDate(value)!, toDate(date)!))) {
      enqueueSnackbar(
        "The selected date for the service you're requesting is unavailable. Please select an alternative date.",
        {
          variant: 'error',
        },
      );
      return;
    }

    if (truckAssignments?.toJS().length) {
      const answer = await confirmWarning();
      if (!!value && answer) {
        onChange({ name, value: formatValue });
      }
    } else if (!!value) {
      onChange({ name, value: formatValue });
    }
  };

  const renderCrewSizeAndTrucks = (
    <>
      <CrewSizeRow name={detailsMode === MoveDetailsMode.DELIVERY ? 'numberOfDeliveryMovers' : 'numberOfMovers'} />
      <TruckCountRow />
    </>
  );

  const pickupDateRow = (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{!isLongDistance ? 'Move Date' : 'Pickup Date'}</BodyText>
      </TableCell>
      <ValueTableCell>
        <DateWrapper disabled={disablePickupDateInput}>
          <DatePicker
            rates={rates}
            name={`services.${serviceIndex}.date`}
            value={toDate(service?.date)}
            fullWidth
            data-testid="moveDate"
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            onChange={handleDateChange(`services.${serviceIndex}.date`)}
          />
        </DateWrapper>
      </ValueTableCell>
    </TableRow>
  );

  const renderPickUp = () => {
    if (isLongDistance && detailsMode === MoveDetailsMode.DELIVERY) {
      return null;
    }

    return (
      <OverviewInfoTable className={classes.table}>
        <TableBody>
          {pickupDateRow}
          {maxOvernightDaysWarning && (
            <TableRow>
              <TableCell colSpan={2} size="small" padding="none">
                <Alert icon={false} severity="error">
                  {maxOvernightDaysWarning}
                </Alert>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell size="small" padding="none" style={{ minWidth: '7.5rem' }}>
              <Link
                disabled={disableInputs}
                onClick={handleOpenEditModal(OrderWindowModal.START_TIME_WINDOW)}
                data-testid="arrivalWindow"
              >
                Arrival Window
              </Link>
            </TableCell>
            <ValueTableCell>{renderStartTimeWindow()}</ValueTableCell>
          </TableRow>
          {renderCrewSizeAndTrucks}
          {!isLongDistance && !isFlatRate && <HourlyRateRow />}
          <LaborTimeRow />
          {isFlatRate && !isLongDistance && <RatePerMoveSizeUnitFlatRate />}
          {!isLongDistance && !isFlatRate && !isTravelRateTheSameAsLaborRate && <TravelRateRow />}
          <TravelTimeRow />
          {quote.doubleDrivingTimeEnabled && <DoubleDriveTimeRow />}
          {isFlatRate && !isLongDistance && <RatePerDistanceUnitRow />}
          {isLongDistance && (
            <>
              <LongDistanceRatePerSizeUnit />
              {quote.longDistanceTariffDetails.isRegionTariff() && (
                <>
                  <AdditionalLongDistanceRatePerSizeUnit />
                  <MinimumSizeRow />
                </>
              )}
              <WeightFactorRow />
            </>
          )}
          {!isLongDistance && <TotalEstimatedTimeRow />}
          {!isLongDistance && !isFlatRate && <MinimumHoursRow />}
        </TableBody>
      </OverviewInfoTable>
    );
  };

  const renderRequestedDeliveryInnerComponent = (dateInputProps: any) => {
    const { value, onClick } = dateInputProps;
    const daysCount = quote.get(DELIVERY_DAYS_COUNT) || 0;
    const endDate = getEndDate(value, daysCount);
    return (
      <Button variant="text" size="small" onClick={onClick}>
        {`${value || 'UNSET'}${daysCount > 0 && endDate ? ` - ${endDate}` : ''}`}
      </Button>
    );
  };

  const renderDelivery = () => {
    if (!isLongDistance || detailsMode !== MoveDetailsMode.DELIVERY) {
      return null;
    }

    return (
      <OverviewInfoTable className={classes.table}>
        <TableBody>
          {pickupDateRow}
          <TableRow>
            <TableCell size="small" padding="none">
              <BodyText>First Available Delivery Date</BodyText>`
            </TableCell>
            <ValueTableCell data-testid="firstAvailableDeliveryDate">
              <FlexWithNegativeMargin className="fixed-row-height">
                <DatePicker
                  disablePast
                  autoOk
                  okLabel=""
                  cancelLabel="Cancel"
                  minDateMessage=""
                  showCalendarIcon={false}
                  TextFieldComponent={renderRequestedDeliveryInnerComponent}
                  name={requestedDeliveryDateStartName}
                  value={toDate(quote.get(REQUESTED_DELIVERY_DATE_START))}
                  onChange={handleDateChange(requestedDeliveryDateStartName) as any}
                />
              </FlexWithNegativeMargin>
            </ValueTableCell>
          </TableRow>
          {quote.get(DELIVERY_DAYS_COUNT) > 0 && (
            <TableRow>
              <TableCell size="small" padding="none">
                <BodyText>Estimated Delivery Date</BodyText>
              </TableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin className="fixed-row-height" data-testid="estimatedDeliveryDate">
                  <DatePicker
                    name={estimatedDeliveryDateName}
                    value={toDate(
                      quote.get(ESTIMATED_DELIVERY_DATE) ?? toDate(quote.get(REQUESTED_DELIVERY_DATE_START)),
                    )}
                    disablePast
                    autoOk
                    okLabel=""
                    cancelLabel="Cancel"
                    minDateMessage=""
                    showCalendarIcon={false}
                    TextFieldComponent={(dateInputProps: any) => {
                      const { value, onClick } = dateInputProps;
                      return (
                        <Button variant="text" size="small" onClick={onClick}>
                          <Box className={classes.estimatedDeliveryDateSelected}>
                            <BodySmallText
                              className={classes.estimatedDeliveryDate}
                              color={!quote.get(ESTIMATED_DELIVERY_DATE) ? 'error' : 'inherit'}
                            >
                              <b>{value}</b>
                            </BodySmallText>
                          </Box>
                        </Button>
                      );
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    onChange={handleDateChange(estimatedDeliveryDateName as any)}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell size="small" padding="none">
              <BodyText>Delivery Days</BodyText>
            </TableCell>
            <ValueTableCell>
              <FlexWithNegativeMargin>
                <Select
                  data-testid="deliveryDays"
                  fullWidth={false}
                  value={quote.get(DELIVERY_DAYS_COUNT) || 0}
                  hiddenLabel
                  InputProps={{
                    disableUnderline: true,
                  }}
                  SelectProps={{
                    renderValue: (number: number) => `${number} (${+number ? 'Business' : 'Guaranteed'} days)`,
                  }}
                  name={deliveryDaysCountName}
                  onChange={onChange}
                >
                  {deliveryDaysOptions.map((value) => (
                    <MenuItem key={value} value={value}>
                      <BodyText>{value}</BodyText>
                    </MenuItem>
                  ))}
                </Select>
              </FlexWithNegativeMargin>
            </ValueTableCell>
          </TableRow>
          {tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
            <TableRow>
              <TableCell size="small" padding="none">
                {/* Truck Leave Time - get to the customer */}
                <BodyText>Arrival Time Window</BodyText>
              </TableCell>
              <ValueTableCell>
                <FlexWithNegativeMargin className="fixed-row-height">
                  <Selector
                    onSelect={updateDeliveryTime(arrivalTimeEarliestName)}
                    value={quote.arrivalTimeEarliest}
                    options={timeOptions}
                  />
                  &nbsp;-&nbsp;
                  <Selector
                    onSelect={updateDeliveryTime(arrivalTimeLatestName)}
                    value={quote.arrivalTimeLatest}
                    options={timeOptions}
                    adjust={quote.arrivalTimeEarliest}
                  />
                </FlexWithNegativeMargin>
              </ValueTableCell>
            </TableRow>
          )}
          {tripPlanerType !== TripPlanerType.TRIP_PLANNER && renderCrewSizeAndTrucks}
          {tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
            <>
              <TableRow>
                <TableCell size="small" padding="none">
                  <BodyText>Truck Block Dates</BodyText>
                </TableCell>
                <ValueTableCell>
                  <FlexWithNegativeMargin className="fixed-row-height" data-testid="truckBlockDates">
                    <DateWrapper disabled={isClosing}>
                      <DateRangePicker
                        onChange={setDeliveryDates}
                        definedRanges={[]}
                        placeholder="Unset"
                        size="small"
                        value={quote.getDeliveryDateWindow()}
                        variant="button"
                      />
                    </DateWrapper>
                  </FlexWithNegativeMargin>
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" padding="none">
                  {/* Truck Leave Time - leave the office */}
                  <BodyText>Truck Leave Time</BodyText>
                </TableCell>
                <ValueTableCell>
                  <FlexWithNegativeMargin className="fixed-row-height" data-testid="truckLeaveTime">
                    <Selector
                      disabled={isClosing}
                      onSelect={updateDeliveryTime(deliveryTimeEarliesName)}
                      value={quote.deliveryTimeEarliest}
                      options={timeOptions}
                    />
                  </FlexWithNegativeMargin>
                </ValueTableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" padding="none">
                  {/* Truck Leave Time - return the office */}
                  <BodyText>Truck Return Time</BodyText>
                </TableCell>
                <ValueTableCell>
                  <FlexWithNegativeMargin className="fixed-row-height" data-testid="truckReturnTime">
                    <Selector
                      disabled={isClosing}
                      onSelect={updateDeliveryTime(deliveryTruckReturnTimeName)}
                      value={quote.deliveryTruckReturnTime}
                      options={timeOptions}
                    />
                  </FlexWithNegativeMargin>
                </ValueTableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </OverviewInfoTable>
    );
  };

  const handleOnChangeRadioButton = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    onChange({ value, name });
  };

  return (
    <Card
      {...rest}
      mode="EDIT"
      title={`Move Details${closingTitle}`}
      icon={<LocalShippingOutlinedIcon />}
      onAction={handleOpenModal(OrderWindowModal.MOVE_DETAILS)}
      minHeight="320px"
      className="use-input-fixes"
    >
      {!disableInputs && <VideoButton position="absolute" video="b_Grt0plp-o" bottom={0} left={2} />}
      <MultiDayAllDayMode />
      <FullWidthBox my={2}>
        {isLongDistance && (
          <>
            <PickupDeliverySwitch value={detailsMode} onChange={setDetailsMode} />
            <BillOfLadingSignedPriorToPickup />
            {detailsMode === MoveDetailsMode.DELIVERY && (
              <Box mt="auto" display="flex" justifyContent="space-around">
                <CompactRadioGroup>
                  <RadioGroup
                    direction="row"
                    aria-label="trip-planer-type"
                    name={longDistancePlannerName}
                    value={quote.get(LONG_DISTANCE_PLANNER)}
                    onChange={handleOnChangeRadioButton}
                    style={{ alignItems: 'baseline' }}
                  >
                    <Radio
                      color="primary"
                      value={TripPlanerType.TRIP_PLANNER}
                      label="Trip Planner"
                      size="small"
                      data-testid="tripPlanner"
                    />
                    <Radio
                      color="primary"
                      value={TripPlanerType.DAILY_SCHEDULE}
                      label="Daily Schedule"
                      size="small"
                      data-testid="dailySchedule"
                    />
                  </RadioGroup>
                </CompactRadioGroup>
              </Box>
            )}
          </>
        )}
        {renderPickUp()}
        {renderDelivery()}
      </FullWidthBox>
      <Box display="flex" justifyContent="space-around">
        {detailsMode === MoveDetailsMode.PICKUP && !isClosing && (
          <Link disabled={disableInputs} onClick={handleOpenModal(OrderWindowModal.ADD_DAY)}>
            + Add Day
          </Link>
        )}
        {truckScheduleWindowPlacement === TruckSchedulerWindowPlacement.POP_OUT && (
          <Link onClick={handleOpenEditModal(OrderWindowModal.SCHEDULE_TRUCK)} data-testid="scheduleTruck">
            Schedule Date/Truck
          </Link>
        )}
      </Box>
      {showLaborOnlyServices && !isClosing && (
        <Box marginBottom="32px" textAlign="center">
          <Box alignItems="center" display="flex" width="100%" justifyContent="center">
            <Switch
              color="primary"
              checked={getIn(order, ['services', serviceIndex, 'quote', 'laborOnly'], false) as boolean}
              labelPlacement="start"
              label="Labor Only"
              name={`services.${serviceIndex}.quote.laborOnly`}
              disabled={isLockSales}
              onChange={handleChangeLaborOnly}
            />
          </Box>
        </Box>
      )}
      {isLongDistanceAndClosing && tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
        <Box m={2} display="flex" justifyContent="space-around">
          <ClosingMoveSize order={order} />
        </Box>
      )}
      {isPacking && !isClosing && (
        <Box marginTop="auto" marginBottom="30px" display="flex" justifyContent="space-around">
          <RadioGroup
            direction="row"
            aria-label="packing-charge-type"
            name={`services.${serviceIndex}.quote.packingPricingType`}
            value={quote.packingPricingType}
            onChange={handleOnChangeRadioButton}
            style={{ alignItems: 'baseline' }}
          >
            <Radio disabled={isLockSales} color="primary" value={PackingPricingType.HOURLY} label="Hourly" />
            <Radio disabled={isLockSales} color="primary" value={PackingPricingType.PER_BOX} label="By Box" />
          </RadioGroup>
        </Box>
      )}
      {openModal === OrderWindowModal.MOVE_DETAILS && (
        <MoveDetailsModal
          rates={getRates(settings)}
          onSave={handleOnMoveDetailsSave}
          onCancel={handleOpenModal(null)}
        />
      )}
      {openEditModal.has(OrderWindowModal.START_TIME_WINDOW) && (
        <StartTimeWindow
          title="Arrival Window"
          order={order}
          onSave={handleOnMoveDetailsSave}
          onCancel={handleCloseEditModal(OrderWindowModal.START_TIME_WINDOW)}
          open
        />
      )}
      {openEditModal.has(OrderWindowModal.DELIVERY_DAYS_WINDOW) && (
        <DeliveryModal
          title="Delivery Window"
          onSave={handleDeliveryDetailsSave}
          onClose={handleCloseEditModal(OrderWindowModal.DELIVERY_DAYS_WINDOW)}
        />
      )}
      {openModal === OrderWindowModal.ADD_DAY && <AddService onAdd={handleAddDay} onClose={handleOpenModal(null)} />}
      {openEditModal.has(OrderWindowModal.SCHEDULE_TRUCK) && (
        <TruckCalendarModal
          longDistancePlanner={quote.get(LONG_DISTANCE_PLANNER)}
          deliveryDate={quote.get(REQUESTED_DELIVERY_DATE_START)}
          onSave={handleCloseEditModal(OrderWindowModal.SCHEDULE_TRUCK)}
          onCancel={handleCloseEditModal(OrderWindowModal.SCHEDULE_TRUCK)}
          open
          moveDate={service?.date}
          numberOfTrucks={service?.quote.numberOfTrucks}
          orderId={order.orderId}
          startTimeEarliest={service?.quote.startTimeEarliest}
          arrivalWindow={service?.quote.arrivalWindow}
          onChange={onChange}
        />
      )}
      {renderConfirmationWarningDialog()}
      {renderConfirmStorageWarning()}
    </Card>
  );
};

export default MoveDetails;
