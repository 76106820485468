import React, { FC } from 'react';

import { IconButton, IconButtonProps } from '@elromcoinc/react-shared';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { useDispatch, useSelector } from 'react-redux';

import { getInFlight, increaseReloadAmount } from 'admin/autodux/ReloadAutodux';

interface ReloadButtonProps extends Pick<IconButtonProps, 'size'> {
  needToBeReloaded?: () => Promise<any>;
}

const ReloadButton: FC<ReloadButtonProps> = ({ size, needToBeReloaded = () => Promise.resolve() }) => {
  const dispatch = useDispatch();
  const inFlight: boolean = useSelector(getInFlight);
  const handleReloadTable = () => {
    needToBeReloaded().then(() => {
      dispatch(increaseReloadAmount());
    });
  };

  return (
    <IconButton color="primary" disabled={inFlight} onClick={handleReloadTable} size={size} data-testid="reloadButton">
      <RefreshIcon />
    </IconButton>
  );
};

export { ReloadButton };
