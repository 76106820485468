import { useState } from 'react';
import { setIn, getIn, set } from 'immutable';

/**
 * @deprecated need to replace using react-hook-form
 */
const useForm = (initialFValues, validateOnChange = false, validationSchema) => {
  const [values, setValues] = useState(initialFValues);
  const [errors, setErrors] = useState({});
  const [validationInProgress, setValidationInProgress] = useState({});

  const validate = (fieldValues = values, context = {}, silent = false) =>
    validationSchema
      .validate(fieldValues, { abortEarly: false, context })
      .then(valid => {
        if (!silent) {
          setErrors({});
        }

        return valid;
      })
      .catch(({ inner }) => {
        if (!silent) {
          setErrors(() => inner.reduce((accumulator, { path, message }) => set(accumulator, path, message), {}));
        }
      });

  const validateAt = (field, fieldValues) => {
    setValidationInProgress(state => set(state, field, true));

    return validationSchema
      .validateAt(field, fieldValues)
      .then(() => {
        setErrors(state => set(state, field, ''));
        setValidationInProgress(state => set(state, field, false));
      })
      .catch(({ message }) => {
        setErrors(state => set(state, field, message));
        setValidationInProgress(state => set(state, field, false));
      });
  };

  const handleInputChange = event => {
    const { name, value } = event.target || event;

    setValues(state => setIn(state, name.split('.'), value));

    if (validateOnChange) {
      validateAt(name, setIn(values, name.split('.'), value));
    }
  };

  const handleInputChangeWithRelatedFieldsValidation = (fields = [], validateIfNull = false) => event => {
    handleInputChange(event);
    const { name, value } = event.target || event;
    const lastValues = setIn(values, name.split('.'), value);

    fields.forEach(fieldName => {
      const fieldValue = getIn(lastValues, fieldName.split('.'), null);

      if (!validateIfNull && fieldValue === null) {
        return;
      }

      validateAt(fieldName, lastValues);
    });
  };

  const resetForm = () => {
    setValues(initialFValues);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    validationInProgress,
    handleInputChangeWithRelatedFieldsValidation,
    validate,
    validateAt,
    handleInputChange,
    resetForm
  };
};

export default useForm;
