export const main = '/dashboard';
export const ratesSettings = '/settings/general/rates';
export const truckSettings = '/settings/general/trucks';
export const serviceSettings = '/settings/services/services';
export const moversCount = '/settings/calculator/m_count';
export const travelTime = '/settings/calculator/time';
export const quoteDisplay = '/settings/calculator/quote_display';
export const orderNumbering = '/settings/orders/order_numbering';
export const reservationDeposit = '/settings/orders/res_deposit';
export const valuation = '/settings/orders/valuation';
export const companyPolicy = '/settings/customer_account/company_policy';
export const certificateOfInsurance = '/settings/customer_account/COI';

export const routes = [
  main,
  ratesSettings,
  truckSettings,
  serviceSettings,
  moversCount,
  travelTime,
  quoteDisplay,
  orderNumbering,
  reservationDeposit,
  valuation,
  companyPolicy,
  certificateOfInsurance,
] as const;
