import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import leadScoreSettings from 'redux-conf/leadScoreSettings/leadScoreSettings';
import orderWindowSettings from 'redux-conf/orderWindowSettings/orderWindowSettings';

import accountInformation from 'admin/autodux/AccountInformationAutodux';
import auth from 'admin/autodux/AuthAutodux';
import autoAssignment from 'admin/autodux/AutoAssignmentAutodux';
import companyInfo from 'admin/autodux/CompanyInfoAutodux';
import currentEmployee from 'admin/autodux/CurrentEmployeeAutodux';
import customerOnline from 'admin/autodux/CustomerOnlineAutodux';
import dashboard from 'admin/autodux/DashboardAutodux';
import message from 'admin/autodux/MessageAutodux';
import notifications from 'admin/autodux/NotificationAutodux';
import notificationSettings from 'admin/autodux/NotificationSettingsAutodux';
import playVideo from 'admin/autodux/PlayVideoAutodux';
import reload from 'admin/autodux/ReloadAutodux';
import settings from 'admin/autodux/SettingsAutodux';
import task from 'admin/autodux/TaskAutodux';
import userDepartments from 'admin/autodux/UserDepartmentAutodux';
import departmentUsers from 'admin/autodux/UsersAutodux';
import window from 'admin/autodux/WindowsAutodux';
import wizard from 'admin/autodux/WizardAutodux';

const store = configureStore({
  reducer: {
    window,
    auth,
    autoAssignment,
    customerOnline,
    wizard,
    currentEmployee,
    task,
    reload,
    playVideo,
    companyInfo,
    userDepartments,
    departmentUsers,
    dashboard,
    message,
    accountInformation,
    notificationSettings,
    notifications,
    settings,
    orderWindowSettings,
    leadScoreSettings,
  },
});

setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
