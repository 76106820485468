import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    height: '100%',
    '&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)': {
      backgroundColor: '#5DCE83',
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: '#5DCE83',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#5DCE83',
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      backgroundImage: ({ dontShowBuffer }: { dontShowBuffer: boolean }) =>
        !dontShowBuffer ? 'radial-gradient(#5DCE83 0%, #5DCE83 16%, transparent 42%)' : 'none',
      animationDirection: 'reverse',
    },
  },
});

interface LinearDeterminateProps {
  value: number;
  dontShowBuffer: boolean;
}

export const LinearDeterminate = ({ value, dontShowBuffer }: LinearDeterminateProps) => {
  const classes = useStyles({
    dontShowBuffer,
  });

  return (
    <LinearProgress
      variant="buffer"
      color="primary"
      valueBuffer={0}
      value={value}
      classes={{
        root: classes.root,
      }}
    />
  );
};
