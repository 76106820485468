import React, { useCallback, useEffect, useState } from 'react';

import {
  BodyBigText,
  Button,
  CurrencyInput,
  Form,
  Modal,
  Switch,
  formatCurrency,
  usePrevious,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControlLabel } from '@material-ui/core';
import { set } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { debounce } from 'throttle-debounce';
import { number, object } from 'yup';

import smsAPI from 'admin/api/SmsAPI';
import { setSmsSettings } from 'admin/autodux/MessageAutodux';
import SettingName from 'admin/constants/SettingName';

const { SMS_AUTO_CHARGE_AMOUNT, SMS_ENABLE_AUTO_CHARGE, AMOUNT_TO_CHARGE } = SettingName;

const labels = {
  [AMOUNT_TO_CHARGE]: 'Amount to charge',
  [SMS_AUTO_CHARGE_AMOUNT]: 'Amount to charge with auto charge',
  [SMS_ENABLE_AUTO_CHARGE]: 'Auto charge',
};

const schemaChargeAmount = number().label(labels[AMOUNT_TO_CHARGE]).required();

const schemaAmountAutoCharge = object().shape({
  [AMOUNT_TO_CHARGE]: schemaChargeAmount,
  [SMS_AUTO_CHARGE_AMOUNT]: number(),
});

export const ChargeAmountForm = ({ onSubmit, defaultValues, allSmsSettings }) => {
  const [openNoticeModal, setOpenNoticeModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleOpenNoticeModal = () => setOpenNoticeModal(true);
  const handleCloseNoticeModal = () => setOpenNoticeModal(false);

  const { handleSubmit, control, watch, setValue, getValues } = useForm({
    resolver: yupResolver(schemaAmountAutoCharge),
    mode: 'onChange',
    defaultValues: { ...defaultValues, [AMOUNT_TO_CHARGE]: defaultValues[SMS_AUTO_CHARGE_AMOUNT] || 0 },
  });

  const chargeAmountValue = watch(AMOUNT_TO_CHARGE);
  const autoChargeAmountValue = +watch(SMS_AUTO_CHARGE_AMOUNT) || 0;
  const enableAutoChargeValue = watch(SMS_ENABLE_AUTO_CHARGE);

  const previousWatchAutoChargeAmount = usePrevious(autoChargeAmountValue);
  const previousWatchAutoCharge = usePrevious(enableAutoChargeValue);
  const previousWatchChargeAmount = usePrevious(chargeAmountValue);

  useEffect(() => {
    if (previousWatchChargeAmount !== undefined && previousWatchChargeAmount !== chargeAmountValue) {
      setValue(SMS_AUTO_CHARGE_AMOUNT, chargeAmountValue);
    }
  }, [chargeAmountValue]);

  const middleSubmit = (data) => {
    if (chargeAmountValue > 1000) {
      handleOpenNoticeModal();
    } else {
      onSubmit(data);
    }
  };

  const actions = [
    {
      label: 'CANCEL',
      color: 'default',
      onClick: handleCloseNoticeModal,
    },
    {
      label: 'CONTINUE',
      color: 'primary',
      onClick: () => {
        onSubmit(getValues());
      },
    },
  ];

  const saveSmsAutoCharge = useCallback(
    debounce(1000, false, (enableAutoCharge, autoChargeAmount) => {
      setIsProcessing(true);
      smsAPI
        .saveSmsAutoCharge({
          ...allSmsSettings,
          enableAutoCharge,
          autoChargeAmount,
        })
        .catch(() => {
          enqueueSnackbar('Auto charge was not saved', { variant: 'error' });
        })
        .then(() => {
          setIsProcessing(false);
        });
    }),
    [],
  );

  useEffect(() => {
    if (
      (previousWatchAutoChargeAmount !== undefined && previousWatchAutoChargeAmount !== autoChargeAmountValue) ||
      (previousWatchAutoCharge !== undefined && previousWatchAutoCharge !== enableAutoChargeValue)
    ) {
      let smsSettingsAmountToCharge = set(allSmsSettings, SMS_ENABLE_AUTO_CHARGE, enableAutoChargeValue);
      smsSettingsAmountToCharge = set(
        smsSettingsAmountToCharge,
        SMS_AUTO_CHARGE_AMOUNT,
        Math.max(50, autoChargeAmountValue),
      );
      dispatch(setSmsSettings(smsSettingsAmountToCharge));

      saveSmsAutoCharge(enableAutoChargeValue, autoChargeAmountValue);
    }
  }, [enableAutoChargeValue, autoChargeAmountValue]);

  return (
    <Form onSubmit={handleSubmit(middleSubmit)}>
      <CurrencyInput
        label={labels[AMOUNT_TO_CHARGE]}
        name={AMOUNT_TO_CHARGE}
        fullWidth
        defaultValue="50"
        control={control}
      />
      <Box mt="15px">
        <FormControlLabel
          control={<Switch name={SMS_ENABLE_AUTO_CHARGE} color="primary" control={control} disabled={isProcessing} />}
          label="Auto Charge when balance less than $10"
        />
      </Box>
      {enableAutoChargeValue && (
        <CurrencyInput
          label={labels[SMS_AUTO_CHARGE_AMOUNT]}
          name={SMS_AUTO_CHARGE_AMOUNT}
          defaultValue="50"
          fullWidth
          control={control}
        />
      )}
      <Box mt={2}>
        <Button size="small" color="primary" type="submit" fullWidth loading={isProcessing} disabled={isProcessing}>
          Payment info
        </Button>
        {openNoticeModal && (
          <Modal open actions={actions} maxWidth="xs" title="Warning" onClose={handleCloseNoticeModal}>
            <Box display="flex" justifyContent="center">
              <BodyBigText>
                Do you really want to charge amount <b>{`$ ${formatCurrency(chargeAmountValue, 2)}`}</b>
              </BodyBigText>
            </Box>
          </Modal>
        )}
      </Box>
    </Form>
  );
};

ChargeAmountForm.propTypes = {
  onSubmit: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: pt.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allSmsSettings: pt.object.isRequired,
};
