import { toDate } from '@elromcoinc/react-shared';
import { format } from 'date-fns';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const moveDateChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('date') > -1 && oldOrder.getValueIn(fieldPath) && newOrder.getValueIn(fieldPath)) {
    const oldValue = format(toDate(oldOrder.getValueIn(fieldPath) as string)!, 'P');
    const newValue = format(toDate(newOrder.getValueIn(fieldPath) as string)!, 'P');
    if (oldValue !== newValue) {
      return [
        createUpdatedValueDto(
          oldValue,
          newValue,
          'Move Date',
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      ];
    }
  }
  return null;
};

export default moveDateChange;
