import React, { useState } from 'react';

import { BodyBigText, Checkbox, Modal, TextInput } from '@elromcoinc/react-shared';
import { Box, Grid } from '@material-ui/core';
import pt from 'prop-types';

import useNotePermissions from 'admin/hooks/useNotePermissions';

const MAXIMUM_NOTE_SIZE = 2000;

const Note = ({ onSave, onCancel, onDelete, note, isDisabledInSave, isDisabledInDelete }) => {
  const getNotePermissions = useNotePermissions();
  const { canDelete, canEdit } = getNotePermissions(note);
  const [content, setContent] = useState(note.content || '');
  const [involvesCustomer, setInvolvesCustomer] = useState(note.involvesCustomer);
  const [involvesForeman, setInvolvesForeman] = useState(note.involvesForeman);
  const [involvesDispatch, setInvolvesDispatch] = useState(note.involvesDispatch);
  const [involvesSales, setInvolvesSales] = useState(note.involvesSales);
  const [error, setError] = useState({});
  const [openNoticeModal, setOpenNoticeModal] = useState(false);

  const isEdit = !!note && !!note.id;
  const actions = [{ label: 'Cancel', onClick: onCancel }];

  const handleOpenNoticeModal = () => setOpenNoticeModal(true);
  const handleCloseNoticeModal = () => setOpenNoticeModal(false);

  const saveHandler = () => {
    const hasContent = content && content.trim().length > 0;
    if (!hasContent) {
      setError({ note: 'Note is required' });
      return;
    }
    onSave({
      ...(note || {}),
      content,
      involvesCustomer,
      involvesSales,
      involvesForeman,
      involvesDispatch,
    });
  };

  if (isEdit) {
    actions.push({
      label: 'Delete',
      disabled: !canDelete,
      onClick: handleOpenNoticeModal,
      color: 'secondary',
    });
  }

  actions.push({
    label: isEdit ? 'Save' : 'Add',
    onClick: saveHandler,
    disabled: (isEdit && !canEdit) || !!error.note,
    loading: isDisabledInSave,
    color: 'primary',
  });

  const handleCheckbox =
    (func) =>
    ({ target: { checked } }) =>
      func(checked);

  const handleNoteChange = ({ target: { value } }) => {
    if (value?.length >= MAXIMUM_NOTE_SIZE) {
      setError({ note: 'Maximum symbols - 2000' });
      return;
    }

    setContent(value);
    if (error.note) {
      setError({});
    }
  };

  const actionsNoticeModal = [
    {
      label: 'cancel',
      color: 'default',
      onClick: handleCloseNoticeModal,
    },
    {
      label: 'continue',
      color: 'primary',
      onClick: onDelete,
      loading: isDisabledInDelete,
    },
  ];

  return (
    <Modal
      title={`${isEdit ? 'Edit' : 'Add'} Note`}
      onClose={onCancel}
      actions={actions}
      open
      maxWidth="sm"
      disabledInProcessing={isDisabledInSave || isDisabledInDelete}
      color="grey"
    >
      <Box mx={2} minHeight="auto">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Checkbox
              color="primary"
              disabled={isEdit && !canEdit}
              label="Sales"
              checked={involvesSales}
              onChange={handleCheckbox(setInvolvesSales)}
            />
            <Checkbox
              color="primary"
              label="Customer"
              disabled={(isEdit && !canEdit) || note?.createdByCustomer}
              checked={involvesCustomer}
              onChange={handleCheckbox(setInvolvesCustomer)}
            />
            <Checkbox
              color="primary"
              label="Foreman"
              disabled={(isEdit && !canEdit) || note?.createdByCustomer}
              checked={involvesForeman}
              onChange={handleCheckbox(setInvolvesForeman)}
            />
            <Checkbox
              color="primary"
              label="Dispatch"
              disabled={(isEdit && !canEdit) || note?.createdByCustomer}
              checked={involvesDispatch}
              onChange={handleCheckbox(setInvolvesDispatch)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              fullWidth
              multiline
              rows={8}
              label="Note"
              name="note"
              disabled={(isEdit && !canEdit) || note?.createdByCustomer}
              value={content}
              formErrors={error}
              onChange={handleNoteChange}
            />
          </Grid>
        </Grid>
      </Box>
      {openNoticeModal && (
        <Modal
          open
          actions={actionsNoticeModal}
          disabledInProcessing={isDisabledInDelete}
          maxWidth="xs"
          title="Warning"
          onClose={handleCloseNoticeModal}
        >
          <Box display="flex" justifyContent="center">
            <BodyBigText>Do you really want to delete this note</BodyBigText>
          </Box>
        </Modal>
      )}
    </Modal>
  );
};

Note.propTypes = {
  onSave: pt.func.isRequired,
  onDelete: pt.func.isRequired,
  onCancel: pt.func.isRequired,
  note: pt.shape({
    createdByName: pt.string,
  }).isRequired,
  isDisabledInSave: pt.bool,
  isDisabledInDelete: pt.bool,
};

Note.defaultProps = {
  isDisabledInSave: false,
  isDisabledInDelete: false,
};

export default Note;
