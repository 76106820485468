import React, { FC, useMemo } from 'react';

import { JoditTextEditor, Modal, ModalActions } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { getTemplateBuilderVariables } from 'common/utils';
import { appendTemplateBuilderStyles } from 'common/utils/appendTemplateBuilderStyles';

const useStyles = makeStyles((theme) => ({
  editor: {
    '& .ck-editor .ck-content': {
      minHeight: 200,
    },
    '& .ck.ck-sticky-panel .ck-sticky-panel__content_sticky': {
      [theme.breakpoints.down('sm')]: {
        top: '80px',
      },
      [theme.breakpoints.between('sm', 'xl')]: {
        top: '85px ',
      },
    },
  },
}));

const defaultSchema = object().shape({
  template: string().label('Template').required(),
});

const variables = getTemplateBuilderVariables();

interface EditTemplateModalProps {
  template: string;
  schema?: any;
  onClose: () => void;
  onSave: (template: string) => void;
  title?: string;
  useTemplateBuilderVariables?: boolean;
}

export const EditTemplateModal: FC<EditTemplateModalProps> = ({
  template,
  schema = defaultSchema,
  onClose,
  onSave,
  title = 'Edit Template',
  useTemplateBuilderVariables = false,
}) => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm({
    defaultValues: { template },
    resolver: yupResolver(schema),
  });
  const editorConfig = useMemo(() => ({ variables: useTemplateBuilderVariables ? variables : undefined }), []);

  const handleSave = (data: any) => {
    onSave(appendTemplateBuilderStyles(data.template || ''));
  };

  const actions: ModalActions[] = [
    {
      onClick: onClose,
      color: 'primary',
      label: 'Cancel',
    },
    {
      onClick: handleSubmit(handleSave as any),
      color: 'primary',
      label: 'Save',
    },
  ];

  return (
    <Modal open title={title} actions={actions} maxWidth="md" fullWidth disableEnforceFocus>
      <Box className={classes.editor}>
        <JoditTextEditor name="template" control={control} config={editorConfig} />
      </Box>
    </Modal>
  );
};
