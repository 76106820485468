import {
  JobDto,
  JobStatus,
  JobType,
  TIME_STRING_FORMAT,
  Waypoint,
  roundNumberToFixedDigits,
  toDate,
} from '@elromcoinc/react-shared';
import { addMinutes, differenceInMinutes, startOfDay } from 'date-fns';
import { List } from 'immutable';

import { finishStatuses, timeStatuses } from 'admin/components/CrewStatusLog/CrewStatusLogConstants';
import { StatusesInformation } from 'admin/components/CrewStatusLog/types';
import {
  calculateLaborTimeForJob,
  convertLocalTimeToMinutes,
  makeStatusInformation,
  makeTimeLine,
  makeTravelTime,
  normalizeFirstLoggedTime,
  normalizeJobStatusesDates,
  processLoggedTime,
} from 'admin/components/CrewStatusLog/utils';
import { makeTrackerTimeLine } from 'admin/components/CrewStatusLog/utils/makeTrackerTimeLine';

interface CrewStatusLogProps {
  job: JobDto | null;
  isCalculateDrivingTimeAsLabor: boolean;
  durationInSeconds: number;
  startTime?: string;
  waypoints: List<Waypoint>;
  finalBillableTravelTimeInMinutes: number;
  estimatedFinalBillableTravelTimeInMinutes: number;
  totalTimeInMinutes: number;
}

export const useTimeLineData = ({
  job,
  isCalculateDrivingTimeAsLabor,
  durationInSeconds,
  startTime,
  waypoints,
  finalBillableTravelTimeInMinutes,
  estimatedFinalBillableTravelTimeInMinutes,
}: CrewStatusLogProps) => {
  const times = processLoggedTime(job);

  const jobStatuses = normalizeJobStatusesDates(job, startTime);
  const firstLoggedTime = times[0];
  const firstStatusDateAsString = jobStatuses?.[0]?.created ?? jobStatuses?.[0]?.updated;
  const firstLoggedStartTime = normalizeFirstLoggedTime(firstLoggedTime?.startDateTime, firstStatusDateAsString);
  const completedStatusTime = normalizeFirstLoggedTime(
    job?.jobStatuses?.find((s) => s.jobStatus === JobStatus.COMPLETED && s.jobType === JobType.PICK_UP)?.created,
    firstStatusDateAsString,
  ) as Date | null;
  const currentLaborTime = calculateLaborTimeForJob(
    times,
    isCalculateDrivingTimeAsLabor,
    waypoints,
    completedStatusTime,
  );

  const isTimeDone = finishStatuses.includes(job?.jobStatus);
  const hasOverTime = currentLaborTime - durationInSeconds > 0;
  const actualTIme = hasOverTime ? durationInSeconds : currentLaborTime;
  const overtime = hasOverTime ? currentLaborTime - durationInSeconds : 0;
  const statusesInformation = makeStatusInformation(
    jobStatuses,
    times,
    waypoints,
    firstLoggedStartTime,
    currentLaborTime,
    isCalculateDrivingTimeAsLabor,
    isTimeDone,
  );

  const groupedStatusesByClosestTime = statusesInformation.reduce((acc, status) => {
    if (!acc.length) {
      acc.push([status]);
      return acc;
    }

    const lastGroup = acc[acc.length - 1];
    const lastStatus = lastGroup[lastGroup.length - 1];

    if (differenceInMinutes(status.date, lastStatus.date) < 10) {
      lastGroup.push(status);
    } else {
      acc.push([status]);
    }

    return acc;
  }, [] as StatusesInformation[][]);

  const startDate = toDate(firstLoggedStartTime || toDate(startTime, TIME_STRING_FORMAT))!;
  const lastLaborDate = isCalculateDrivingTimeAsLabor
    ? [...statusesInformation].reverse().find((s) => timeStatuses.includes(s.status))?.date || null
    : addMinutes(startDate, currentLaborTime / 60);

  const startTimeInMinutes = convertLocalTimeToMinutes(firstLoggedStartTime || startTime);

  const currentTimeOrEstimatedTime = currentLaborTime ? currentLaborTime / 60 : durationInSeconds / 60 + overtime / 60;

  const { travelTimes, firstTravelTimeInMinutes, lastWaypointTravelTimeInMinutes } = makeTravelTime({
    startTime: startDate,
    endTime: isTimeDone ? lastLaborDate : addMinutes(startDate, currentTimeOrEstimatedTime),
    isTimeDone,
    hasAnyTime: times.length > 0,
    waypoints,
    finalBillableTravelTimeInMinutes,
    estimatedFinalBillableTravelTimeInMinutes,
  });
  const endTime = normalizeFirstLoggedTime(
    statusesInformation?.[statusesInformation.length - 1]?.date,
    firstStatusDateAsString,
  );
  const timeLine = makeTimeLine(
    startDate,
    endTime,
    durationInSeconds,
    1 + Math.max(roundNumberToFixedDigits(firstTravelTimeInMinutes / 60, 0), 1),
    1 + Math.max(roundNumberToFixedDigits(lastWaypointTravelTimeInMinutes / 60, 0), 1),
  );
  const startDayOffsetInMinutes = convertLocalTimeToMinutes(timeLine[0]);
  const endOfDayInMinutes = convertLocalTimeToMinutes(timeLine[timeLine.length - 1], timeLine[0]);

  const timeOffMinutes = times.reduce((acc, time) => acc + (time.timeOffSeconds || 0), 0) / 60;
  const lastLaborTime = [...statusesInformation].reverse().find((s) => timeStatuses.includes(s.status))?.time || null;
  const startTimeOff =
    timeOffMinutes && lastLaborTime
      ? convertLocalTimeToMinutes(
          addMinutes(startOfDay(firstLoggedStartTime), lastLaborTime - timeOffMinutes),
          firstLoggedStartTime,
        )
      : null;

  const timeLines = makeTrackerTimeLine({
    firstLaborTime: convertLocalTimeToMinutes(startDate),
    actualTIme: actualTIme / 60,
    durationInMinutes: durationInSeconds / 60,
    lastLaborTime,
    times,
    overtime: overtime / 60,
    firstLoggedStartTime,
    travelTimes,
    completedStatusTime,
    hasAnyTime: times.length > 0,
    isCalculateDrivingTimeAsLabor,
  });

  return {
    times,
    actualTIme,
    overtime,
    startTimeInMinutes,
    statusesInformation,
    timeLine,
    endOfDayInMinutes,
    startDayOffsetInMinutes,
    groupedStatusesByClosestTime,
    timeOffMinutes,
    startTimeOff,
    isTimeDone,
    travelTimes,
    timeLines,
  };
};
