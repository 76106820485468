// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import settingsAPI from 'admin/api/SettingsAPI';
import settingName from 'admin/constants/SettingName';

const { CONFIG_WIZARD_DONE } = settingName;

const {
  reducer,
  actions: { setStep: setWizardStep, setRun: setWizardRun, setActive: setWizardActive },
  selectors: { getStep: getWizardStep, getRun: getWizardRun, getActive: getWizardActive },
} = autodux({
  slice: 'wizard',
  initial: {
    step: 0,
    run: false,
    active: true,
  },
});

export default reducer;

const fetchIsEnableWizard = () => (dispatch: ReturnType<typeof useDispatch>) => {
  return settingsAPI.getDataFromQuery({ queries: [CONFIG_WIZARD_DONE] }).then((data) => {
    const storedValue = (data as unknown as any)?.[CONFIG_WIZARD_DONE];
    const result = !storedValue;
    dispatch(setWizardRun(result));
    return result;
  });
};

export {
  setWizardStep,
  getWizardStep,
  setWizardRun,
  getWizardRun,
  getWizardActive,
  setWizardActive,
  fetchIsEnableWizard,
};
