import React, { useState } from 'react';

import { BodyText, Link, Order, OrdersPermissions, PriceAdjustment, useHasPermission } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, Tooltip, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { FuelSurcharge as FuelSurchargeModal } from 'admin/components/OrderWindow/modals';
import { getServiceClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles<Theme>(({ palette }) =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    depositReceived: {
      color: palette.success.main,
    },
  }),
);

enum Modals {
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
}

export const ClosingFuelSurcharge = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const service = order.closingOrderDetail?.services?.get(serviceIndex);
  const { quote } = service;
  const canEditFuelSurchargeAmount = useHasPermission(OrdersPermissions.PERM_CAN_EDIT_FUEL_SURCHARGE_AMOUNT);
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const classes = useStyles();

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  const handleChangeFuelSurcharge = (value: PriceAdjustment | null) => {
    onChange({
      name: getServiceClosingPropertyName(serviceIndex, 'fuelChargeEmployeeAdjusted'),
      value,
    });
    setOpenModal(null);
  };

  const total = order.closingOrderDetail?.getServiceFuelTotal(serviceIndex, isSelectedAllServices);

  const fuelChargeComputed = quote.fuelChargeComputed;
  const fuelChargeComputedNote = quote.fuelChargeComputedNote;
  const fuelChargeEmployeeAdjusted = quote.fuelChargeEmployeeAdjusted;
  const fuelChargeMovingCost = quote.fuelChargeMovingCost;

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Tooltip title={!canEditFuelSurchargeAmount ? "You don't have a permission to edit this field" : ''}>
          <Link
            disabled={!canEditFuelSurchargeAmount || isSelectedAllServices}
            onClick={toggleOpenModal(Modals.FUEL_SURCHARGE)}
          >
            Fuel Surcharge
          </Link>
        </Tooltip>
        <BodyText>{total?.asHumanReadableString()}</BodyText>
      </Box>
      {Modals.FUEL_SURCHARGE === openModal && (
        <FuelSurchargeModal
          open
          onSave={handleChangeFuelSurcharge}
          onCancel={toggleOpenModal(null)}
          fuelChargeComputed={fuelChargeComputed}
          fuelChargeComputedNote={fuelChargeComputedNote}
          fuelChargeEmployeeAdjusted={fuelChargeEmployeeAdjusted}
          fuelChargeMovingCost={fuelChargeMovingCost}
        />
      )}
    </Grid>
  );
};
