import { Dispatch, SetStateAction, createContext } from 'react';

interface UnsubscribeMessageContextProps {
  isUnsubscribe: boolean;
  setIsUnsubscribe: Dispatch<SetStateAction<boolean>>;
}

const UnsubscribeMessageContext = createContext<UnsubscribeMessageContextProps>({} as UnsubscribeMessageContextProps);

export { UnsubscribeMessageContext };
