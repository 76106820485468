import { FC, useState } from 'react';

import { Waypoint } from '@elromcoinc/react-shared';

import { OrderWindowEditAddressIndexContext } from 'admin/components/OrderWindow/context/OrderWindowEditAddressIndexContext';

const OrderWindowEditAddressIndexProvider: FC = ({ children }) => {
  const [editWaypoint, setEditWaypoint] = useState<Waypoint | null>(null);

  return (
    <OrderWindowEditAddressIndexContext.Provider value={{ editWaypoint, setEditWaypoint }}>
      {children}
    </OrderWindowEditAddressIndexContext.Provider>
  );
};

export { OrderWindowEditAddressIndexProvider };
