import React, { useEffect, useState } from 'react';

import { SettingName } from '@elromcoinc/moveboard-setting-react';
import {
  Button,
  Checkbox,
  EMAIL,
  Link,
  Select,
  ServiceType,
  isLoadingService,
  isUnloadingOrPackingService,
  useSettings,
} from '@elromcoinc/react-shared';
import { Box, Grid, InputAdornment, ListItemText, MenuItem } from '@material-ui/core';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import TruckOutlineIcon from '@material-ui/icons/LocalShippingOutlined';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { List, Map, getIn, set } from 'immutable';
import PropTypes from 'prop-types';
import { number, object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import { getDisplayQuoteOnCustomerPage } from 'admin/components/CreateOrder/getDisplayQuoteOnCustomerPage';
import { resolveShortNameByServiceTypeAndSettingsCondition } from 'admin/components/CreateOrder/resolveShortNameByServiceTypeAndSettingsCondition';
import { useSendingTemplates } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { ManageTemplates } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/ManageTemplates';
import useServiceEstimateTypeSetting from 'admin/components/Settings/hooks/useServiceEstimateTypeSetting';
import findDuplicates from 'admin/utils/findDuplicates';
import { AutomationRuleName } from 'common-types';
import Form from 'common/components/Form';
import useForm from 'common/hooks/useForm';

const MOVE_SIZE = 'moveSize';
const MOVE_SIZE_EXTRA = 'moveSizeExtra';
const FROM_ELEVATION_ID = 'fromElevationId';
const TO_ELEVATION_ID = 'toElevationId';
const PARKING_DISTANCE_FROM = 'parkingDistanceFrom';
const PARKING_DISTANCE_TO = 'parkingDistanceTo';
const LEAD_SOURCE = 'leadSource';
const REFERRED_VIA = 'referredVia';
const NAME = 'name';
const SERVICE_NAME = 'serviceName';
const ESTIMATE_TYPE = 'estimateType';

const referenceViaPathArray = [LEAD_SOURCE, REFERRED_VIA, NAME];
const referenceViaPath = `${LEAD_SOURCE}.${REFERRED_VIA}.${NAME}`;

const labels = {
  [MOVE_SIZE]: 'Move Size',
  [MOVE_SIZE_EXTRA]: 'Rooms',
  [FROM_ELEVATION_ID]: 'From Entrance Type',
  [TO_ELEVATION_ID]: 'To Entrance Type',
  [PARKING_DISTANCE_FROM]: 'From Parking Distance',
  [PARKING_DISTANCE_TO]: 'To Parking Distance',
};

const validateNumberSchema = number().required().nullable();
const validateStringSchema = string().required().nullable();

const schema = object().shape({
  [SERVICE_NAME]: string(),
  [MOVE_SIZE]: object()
    .label(labels[MOVE_SIZE])
    .test('size', 'Move size must be more than 0', (value) => +value.size > 0)
    .test('id', 'Move size is required', (value) => Boolean(value.id)),
  [MOVE_SIZE_EXTRA]: object(),
  [FROM_ELEVATION_ID]: number()
    .label(labels[FROM_ELEVATION_ID])
    .nullable()
    .when(SERVICE_NAME, {
      is: (value) => !isUnloadingOrPackingService(value),
      then: validateNumberSchema,
    }),
  [TO_ELEVATION_ID]: number()
    .label(labels[TO_ELEVATION_ID])
    .nullable()
    .when(SERVICE_NAME, {
      is: (value) => !isLoadingService(value),
      then: validateNumberSchema,
    }),
  [PARKING_DISTANCE_FROM]: string()
    .label(labels[PARKING_DISTANCE_FROM])
    .nullable()
    .when(SERVICE_NAME, {
      is: (value) => !isUnloadingOrPackingService(value),
      then: validateStringSchema,
    }),
  [PARKING_DISTANCE_TO]: string()
    .label(labels[PARKING_DISTANCE_TO])
    .nullable()
    .when(SERVICE_NAME, {
      is: (value) => !isLoadingService(value),
      then: validateStringSchema,
    }),
  [LEAD_SOURCE]: object({
    [REFERRED_VIA]: object({
      [NAME]: string().label('Lead source').required().nullable(),
    }),
  }),
});

const { UNIT_SIZE_SPEC, SERVICE_ESTIMATE_TYPE_SETTING } = SettingName;
const allSettings = [SERVICE_ESTIMATE_TYPE_SETTING];

const MoveDetails = ({
  data,
  moveSizes,
  rooms,
  onSave,
  onBack,
  elevationReductionOptions,
  parkingDistanceOptions,
  referredViaOptions,
  settings,
  inFlight,
}) => {
  const { setSendingTemplates } = useSendingTemplates();
  const { values, setValues, errors, validate, validateAt, handleInputChange } = useForm(data, true, schema);
  const { moveType } = data;
  const { serviceName, serviceId, customerInfo } = values;
  const { templates, automationRules } = useComposeEmail();
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [isNewUser, setIsNewUser] = useState(false);
  const isLoadingOrder = isLoadingService(serviceName);
  const isUnloadingOrder = isUnloadingOrPackingService(serviceName);
  const moveSizeExtraArray = Object.values(values[MOVE_SIZE_EXTRA]?.toJS());
  const moveSizeOptions =
    settings?.[UNIT_SIZE_SPEC]?.filter((it) => it.moveUnitType === 'SITE' && it.moveType === moveType)?.map((it) => [
      it.id,
      it.name,
    ]) ?? [];
  const state = useSettings(allSettings).toJS();
  const [estimateType] = useServiceEstimateTypeSetting(state, () => {}, moveType, ServiceType[data.serviceName]);
  const displayQuoteOnCustomerPage = getDisplayQuoteOnCustomerPage(settings, serviceId, moveType);

  useEffect(() => {
    if (estimateType) {
      setValues(values.set(ESTIMATE_TYPE, estimateType));
    }
  }, [estimateType]);

  useEffect(() => {
    if (customerInfo?.[EMAIL]) {
      accountAPI.getByEmail(customerInfo?.[EMAIL]).then((response) => {
        setIsNewUser(!response);
      });
    }
  }, [customerInfo?.[EMAIL]]);

  useEffect(() => {
    const shortNameWhenCreateLeadByServiceType = resolveShortNameByServiceTypeAndSettingsCondition(
      serviceName,
      displayQuoteOnCustomerPage,
      estimateType,
    );
    const automationRuleShortNames = [shortNameWhenCreateLeadByServiceType];

    if (isNewUser) {
      automationRuleShortNames.push(
        AutomationRuleName.WHEN_A_NEW_CUSTOMER_IS_CREATED_MOVEBOARD,
        AutomationRuleName.WHEN_A_NEW_CUSTOMER_IS_CREATED,
      );
    }

    const newLeadAutomationRules = automationRules.filter((ar) => automationRuleShortNames.includes(ar.shortName));
    const filteredTemplatesByEventType = templates.filter(
      (t) => t.active && newLeadAutomationRules.find((it) => it.id === t.automationRuleId),
    );

    setDefaultTemplates(filteredTemplatesByEventType);
    setSendingTemplates(filteredTemplatesByEventType);
  }, [templates, automationRules, estimateType, isNewUser]);

  const handleChangeMoveSize = ({ target: { value } }) => {
    const { [MOVE_SIZE]: moveSize } = values;
    const { id, tags, size, name } = moveSizes[moveType][value];
    const updatedMoveSize = moveSize
      .set('id', id)
      .set('tags', List(tags.map((tag) => ({ ...tag }))))
      .set('size', size)
      .set('name', name);

    let newSizeExtra = new Map();

    tags.forEach((tag) => {
      if (tag.isDefault) {
        newSizeExtra = newSizeExtra.set(tag.id.toString(), tag);
      }
    });

    setValues(values.set(MOVE_SIZE_EXTRA, newSizeExtra).set(MOVE_SIZE, updatedMoveSize));
    validateAt(MOVE_SIZE, { [MOVE_SIZE]: updatedMoveSize });
  };

  const handleOnSave = (event) => {
    event.preventDefault();

    validate().then((valid) => {
      if (valid) {
        onSave(values, event);
      }
    });
  };

  const handleBack = () => {
    onBack(values);
  };

  const renderRoomsValue = (selected) => selected.map((it) => it.name).join(', ');

  const handleRoomsChange = (event) => {
    const {
      target: { value },
    } = event;
    const ids = value.map((item) => item.id);
    const duplicateIds = findDuplicates(ids);

    handleInputChange({
      name: MOVE_SIZE_EXTRA,
      value: Map(
        value
          .filter((item) => !duplicateIds.includes(item.id))
          .reduce((result, current) => set(result, current.id, current), {}),
      ),
    });
  };

  return (
    <Form onSubmit={handleOnSave}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Select
            fullWidth
            label={labels[MOVE_SIZE]}
            value={values[MOVE_SIZE].id || ''}
            formErrors={errors}
            name={MOVE_SIZE}
            data-testid="moveSize"
            options={moveSizeOptions}
            onChange={handleChangeMoveSize}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TruckOutlineIcon color={!moveType ? 'disabled' : 'primary'} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        {!!values[MOVE_SIZE].tags && !!values[MOVE_SIZE].tags.size ? (
          <Grid item xs={12}>
            <Select
              fullWidth
              label={labels[MOVE_SIZE_EXTRA]}
              value={moveSizeExtraArray}
              onChange={handleRoomsChange}
              SelectProps={{
                MenuProps: { variant: 'menu' },
                multiple: true,
                renderValue: renderRoomsValue,
              }}
              renderValue={renderRoomsValue}
            >
              {rooms?.[moveType]?.map((it) => (
                <MenuItem key={it.id} value={it} dense>
                  <Checkbox checked={values[MOVE_SIZE_EXTRA].has(it.id.toString())} color="primary" />
                  <ListItemText primary={it.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}
        {!isUnloadingOrder && (
          <Grid item xs={6}>
            <Select
              fullWidth
              options={elevationReductionOptions || []}
              label={labels[FROM_ELEVATION_ID]}
              value={values[FROM_ELEVATION_ID]}
              onChange={handleInputChange}
              name={FROM_ELEVATION_ID}
              formErrors={errors}
              data-testid="fromElevationId"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MeetingRoomIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {!isLoadingOrder && (
          <Grid item xs={6}>
            <Select
              fullWidth
              options={elevationReductionOptions || []}
              label={labels[TO_ELEVATION_ID]}
              name={TO_ELEVATION_ID}
              value={values[TO_ELEVATION_ID]}
              onChange={handleInputChange}
              formErrors={errors}
              data-testid="toElevationId"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MeetingRoomIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {!isUnloadingOrder && (
          <Grid item xs={6}>
            <Select
              fullWidth
              options={parkingDistanceOptions || []}
              label={labels[PARKING_DISTANCE_FROM]}
              name={PARKING_DISTANCE_FROM}
              value={values[PARKING_DISTANCE_FROM]}
              onChange={handleInputChange}
              formErrors={errors}
              data-testid="fromParkingDistance"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsWalkIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {!isLoadingOrder && (
          <Grid item xs={6}>
            <Select
              fullWidth
              options={parkingDistanceOptions || []}
              label={labels[PARKING_DISTANCE_TO]}
              name={PARKING_DISTANCE_TO}
              value={values[PARKING_DISTANCE_TO]}
              onChange={handleInputChange}
              formErrors={errors}
              data-testid="toParkingDistance"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DirectionsWalkIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Select
            fullWidth
            name={referenceViaPath}
            label="How did you hear about us?"
            value={getIn(values, referenceViaPathArray, '')}
            onChange={handleInputChange}
            options={referredViaOptions}
            formErrors={errors}
            data-testid="howDidYouHearAboutUs"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <TruckOutlineIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <ManageTemplates defaultTemplates={defaultTemplates} />
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Link data-testid="backToMoveInfo" onClick={handleBack} disabled={inFlight}>
          &lt; Back to Move Info
        </Link>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          data-testid="createOrder"
          rounded
          disableBoxShadow
          loading={inFlight}
          disabled={inFlight}
        >
          create order
        </Button>
      </Box>
    </Form>
  );
};

MoveDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  referredViaOptions: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  moveSizes: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  rooms: PropTypes.object,
  elevationReductionOptions: PropTypes.arrayOf(PropTypes.array),
  parkingDistanceOptions: PropTypes.arrayOf(PropTypes.array),
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  inFlight: PropTypes.bool.isRequired,
};

MoveDetails.defaultProps = {
  parkingDistanceOptions: [],
  elevationReductionOptions: [],
  data: {},
  moveSizes: {},
  rooms: {},
  settings: {},
};

export default MoveDetails;
