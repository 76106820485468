import React, { FC, useEffect, useState } from 'react';

import {
  ActivitySourceType,
  HeaderBigText,
  IconButton,
  MessageTypes,
  Modal,
  Tab,
  Tabs,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { Close as CloseIcon, OpenInNew as ExpandIcon } from '@material-ui/icons';
import { Map } from 'immutable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import communicationsAPI from 'admin/api/CommunicationsAPI';
import activityApi from 'admin/api/activityApi';
import { getEdit, setEdit } from 'admin/autodux/TaskAutodux';
import { ActivityLogView } from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityLogView';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useUnreadMessages } from 'admin/components/OrderWindow/context';
import ManageActivity from 'admin/components/OrderWindow/modals/ManageActivity';
import NoteModal from 'admin/components/OrderWindow/modals/Note';
import ACTIVITY_TYPE from 'admin/constants/ActivityType';
import ActivityType from 'admin/constants/ActivityType';
import Activity from 'admin/entities/ManualActivity';
import OrderLog from 'admin/entities/OrderLog';
import { Notes } from 'common-types';
import { Task } from 'common-types/Task';

const useStyles = makeStyles((theme) => ({
  tabsRoot: {
    minWidth: 0,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

interface ActivityLogProps {
  sourceId: number;
  activitySource: ActivitySourceType;
  orderNumber?: string;
}

function a11yProps(index: string | null) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const activityBySource = Map({
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account',
  [ActivitySourceType.ORDER]: 'order',
});

const Call = 'Call';

const activityLogFilters = [
  [null, 'All'],
  [ActivityType.NOTE, 'Notes'],
  [MessageTypes.EMAIL, 'Emails'],
  [Call, 'Calls'],
  [ActivityType.MESSAGE, 'Messages'],
  [ActivityType.TASK, 'Tasks'],
];

export const ActivityLog: FC<ActivityLogProps> = ({ sourceId, activitySource, orderNumber }) => {
  const classes = useStyles();
  const { messageRef } = useUnreadMessages();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [inProgressEditTask, setInProgressEditTask] = useState<boolean>(false);
  const editTask = useSelector(getEdit);
  const { pastActions, nextSteps, reload } = useActivityLogContext();
  const [currentNote, setCurrentNote] = useState<Notes | null>(null);
  const [isDisabledInDelete, setIsDisabledInDelete] = useState(false);
  const [isDisabledInSave, setIsDisabledInSave] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<
    ActivityType.NOTE | MessageTypes.EMAIL | typeof Call | ActivityType.MESSAGE | ActivityType.TASK | null
  >(null);
  const [expanded, setExpanded] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState<OrderLog | null>(null);
  const { setShowAlert, alertProps } = useAlert({
    cancelTitle: 'Close',
    onCancel: () => setExpanded(false),
  });
  const handleChangeFilter = (event: React.ChangeEvent<{}>, newValue: string | null) => {
    setSelectedFilter(newValue as any);
  };
  useEffect(() => {
    if (editTask && inProgressEditTask) {
      setInProgressEditTask(false);
      reload();
    }
  }, [editTask]);

  const handleOnExpand = () => {
    if (!alertProps.open) {
      setShowAlert(true);
      return;
    }

    setExpanded(true);
  };

  const handleEdit = (id: number, activityType: ACTIVITY_TYPE) => {
    const activity = nextSteps.concat(pastActions).find((item) => item.id === id);

    if (!activity) {
      return;
    }

    if (activityType === ACTIVITY_TYPE.MANUALLY_ENTERED_ACTIVITY) {
      setEditActivity(activity);
      setShowAddActivityModal(true);
    }

    if (activityType === ACTIVITY_TYPE.TASK) {
      dispatch(setEdit(new Task({ ...(activity?.activity as unknown as Task), orderNumber })));
      setInProgressEditTask(true);
    }

    if (activityType === ACTIVITY_TYPE.NOTE) {
      setCurrentNote(activity.activity);
    }
  };

  const handleCloseEditCurrentNote = () => setCurrentNote(null);
  const handleSaveNote = (note: Partial<Notes> = {}) => {
    setIsDisabledInSave(true);
    const newNote: Notes = {
      ...note,
      activitySource,
      sourceId,
      activitySources: [{ activitySource, referencedEntityId: sourceId }],
    } as Notes;

    const isUpdate = !!newNote.id;

    (isUpdate ? communicationsAPI.updateNote(newNote) : communicationsAPI.saveNote(newNote))
      .then(reload)
      .then(() => {
        handleCloseEditCurrentNote();
        setIsDisabledInSave(false);
        enqueueSnackbar(
          `Note for current ${activityBySource.get(activitySource)} ${isUpdate ? 'updated' : 'saved'} successfully`,
          { variant: 'success' },
        );
      })
      .catch(() => {
        enqueueSnackbar(`Can't ${isUpdate ? 'update' : 'save'} note for this ${activityBySource.get(activitySource)}`, {
          variant: 'error',
        });
      })
      .then(() => {
        setIsDisabledInSave(false);
      });
  };

  const handleNoteDelete = () => {
    if (!currentNote?.id) {
      return;
    }

    setIsDisabledInDelete(true);
    communicationsAPI
      .updateNote({ ...currentNote, deleted: true })
      .then(reload)
      .catch(() => {
        enqueueSnackbar(`Can't delete note for this ${activityBySource.get(activitySource)}`, { variant: 'error' });
      })
      .then(() => {
        handleCloseEditCurrentNote();
        setIsDisabledInDelete(false);
        enqueueSnackbar(`Note for current ${activityBySource.get(activitySource)} deleted successfully`, {
          variant: 'success',
        });
      });
  };

  const onCancelSaveActivity = () => {
    setShowAddActivityModal(false);
    setEditActivity(null);
  };

  const handleManualActivitySave = (activity: Activity) => {
    const activityToSave = activity.setActivitySource(activitySource, sourceId);

    const doActionOnActivity = activity.deleted
      ? activityApi.deleteManuallyEnteredActivity(activity.id!) // @ts-ignore
      : activityApi.saveManuallyEnteredActivity(activityToSave?.toDTO());

    doActionOnActivity.then(reload);

    onCancelSaveActivity();
  };

  const filterActions = (item: OrderLog) => {
    return (
      !selectedFilter ||
      item.type === selectedFilter ||
      item.activityType === selectedFilter ||
      item.activity === selectedFilter
    );
  };

  const filteredPastActions = pastActions.filter(filterActions);
  const filteredNextSteps = nextSteps.filter(filterActions);

  return (
    // @ts-ignore added because of ref
    <Box overflow="auto" height="100%" ref={messageRef}>
      <Box display="flex" mb={0.5} alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <HeaderBigText>
          <b>Activity</b>
        </HeaderBigText>
        <Box display="flex" flexWrap="wrap">
          <Tabs
            indicatorColor="primary"
            value={selectedFilter} /** @ts-ignore **/
            onChange={handleChangeFilter}
            variant="scrollable"
            scrollButtons="off"
          >
            {activityLogFilters.map(([type, label]) => (
              <Tab classes={{ root: classes.tabsRoot }} key={type} value={type} label={label} {...a11yProps(type)} />
            ))}
          </Tabs>
          <IconButton
            color="primary"
            data-testid={expanded ? 'closeActivityLogExpand' : 'openActivityLogExpand'}
            onClick={handleOnExpand}
            className="print-remove"
          >
            {expanded ? <CloseIcon /> : <ExpandIcon />}
          </IconButton>
        </Box>
      </Box>
      {showAddActivityModal && (
        <ManageActivity
          onCancel={onCancelSaveActivity}
          onSave={handleManualActivitySave} // @ts-ignore
          activity={editActivity ? editActivity?.activity : null}
          open
        />
      )}
      {currentNote !== null && (
        <NoteModal
          onCancel={handleCloseEditCurrentNote}
          onDelete={handleNoteDelete}
          onSave={handleSaveNote}
          note={currentNote}
          isDisabledInSave={isDisabledInSave}
          isDisabledInDelete={isDisabledInDelete}
        />
      )}
      <ActivityLogView pastActions={filteredPastActions} nextSteps={filteredNextSteps} onEdit={handleEdit} />
      <Modal title="Activity" maxWidth="md" {...alertProps} color="white">
        <ActivityLogView pastActions={filteredPastActions} nextSteps={filteredNextSteps} onEdit={handleEdit} />
      </Modal>
    </Box>
  );
};
