import { PayrollCompensationType } from 'common-types/payroll/PayrollCompensationType';

export const EmployeeCompensationNames = {
  [PayrollCompensationType.HOURLY_RATE]: 'Hourly Rate',
  [PayrollCompensationType.PACKING_COMMISSION]: 'Packing Commission',
  [PayrollCompensationType.ADDITIONAL_SERVICES_COMMISSION]: 'Additional Services Commission',
  [PayrollCompensationType.COMMISSION_FROM_TOTAL]: 'Commission from Total',
  [PayrollCompensationType.PER_JOB_BONUS]: 'Per Job Bonus',
  [PayrollCompensationType.TIP]: 'Tip',
  [PayrollCompensationType.RECEIPT]: 'Receipt',
};
