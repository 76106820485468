import React, { useCallback, useEffect } from 'react';

import { CustomPaymentSettingDto } from '@elromcoinc/moveboard-setting-react';
import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  PaymentActivityType,
  PaymentCustomDto,
  PaymentStatus,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';
import {
  AMOUNT,
  NOTES,
  PAID_DATE,
  PAYMENT_TYPE,
  labels,
} from 'admin/components/OrderWindow/modals/Payments/CreateCharge/Common';
import { CashPaymentFormInputs } from 'admin/components/OrderWindow/modals/Payments/Forms';
import { PaymentTypeSelector } from 'admin/components/OrderWindow/modals/Payments/Forms/PaymentTypeSelector';
import { usePaymentSourceContext } from 'admin/components/OrderWindow/modals/Payments/PaymentsSourceContext';

export const customPaymentScema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

export interface CustomPaymentDto {
  amount: number;
  message: string;
  date: Date;
  type: PaymentActivityType;
}

export interface CustomPaymentProps {
  amount: number | string;
  finalAmount: number | string;
  isDepositAvailable: boolean;
  ifDepositUnavailableHint: string;
  onAmountChange?: (value: number | string) => void;
  onTypeChange?: (value: PaymentActivityType) => void;
  onMount?: (trigger: () => Promise<any>) => void;
  type: PaymentActivityType;
  activitySources: ActivitySourceDescriptor[];
  paymentCustomSettings: CustomPaymentSettingDto;
}

export const CustomPayment = ({
  amount: initialAmount,
  finalAmount,
  ifDepositUnavailableHint,
  onMount,
  type,
  isDepositAvailable,
  onAmountChange,
  onTypeChange,
  activitySources,
  paymentCustomSettings,
}: CustomPaymentProps) => {
  const { sourceId, activitySource } = usePaymentSourceContext();
  const methods = useForm<CustomPaymentDto>({
    resolver: yupResolver(customPaymentScema),
    defaultValues: { amount: initialAmount as number, [PAID_DATE]: new Date(), [PAYMENT_TYPE]: type },
  });

  const amount = methods.watch(AMOUNT);

  useEffect(() => {
    onAmountChange?.(amount);
  }, [amount]);

  useEffect(() => {
    onTypeChange?.(methods.watch(PAYMENT_TYPE));
  }, [methods.watch(PAYMENT_TYPE)]);

  const createCustomPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CustomPaymentDto) => {
          paymentActionsApi
            .createCustomPayment(
              PaymentCustomDto.createPaymentCustomDto({
                date: formatISO(data[PAID_DATE]),
                amount: +finalAmount,
                message: data[NOTES],
                activitySources,
                type,
                status: PaymentStatus.PROCESSED,
                sourceId,
                activitySource,
                paymentCustomSettings,
              }),
            )
            .then(resolve)
            .catch(reject);
        }, reject)();
      }),
    [
      methods.handleSubmit,
      sourceId,
      activitySource,
      type,
      activitySources?.length,
      paymentCustomSettings?.id,
      finalAmount,
    ],
  );

  useEffect(() => {
    onMount?.(createCustomPayment);
  }, [createCustomPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        {activitySource === ActivitySourceType.ORDER && (
          <PaymentTypeSelector
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            isDepositAvailable={isDepositAvailable}
          />
        )}
        {/* So far custom payment form is the same as cash payment form */}
        <CashPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};
