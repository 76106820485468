import { JobStatus as JobStatusType } from '@elromcoinc/react-shared';

export const JobStatusName = {
  [JobStatusType.NOT_ASSIGNED]: 'Not Assigned',
  [JobStatusType.ASSIGNED]: 'Assigned',
  [JobStatusType.RECEIVED]: 'Received',
  [JobStatusType.EN_ROUTE]: 'En Route',
  [JobStatusType.ARRIVED]: 'Arrived',
  [JobStatusType.LOADING]: 'Loading',
  [JobStatusType.TO_DESTINATION]: 'On The Way',
  [JobStatusType.UNLOADING]: 'Unloading',
  [JobStatusType.PAYMENT]: 'Payment',
  [JobStatusType.COMPLETED]: 'Completed',
} as const;
