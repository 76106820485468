import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { localStoragePolyfill, theme } from '@elromcoinc/react-shared';
import { MuiThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Chart from 'chart.js';
// TODO not remove ChartDataLabels import. It used to show labels on charts
// ts-ignore
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { GlobalProvider } from 'context/GlobalProvider';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import { ThemeProvider } from 'styled-components';

import AdminRouter from 'admin/components/AdminRouter';

import './axios/axios';
import './index.css';

localStoragePolyfill();

Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
  display: false,
  color: '#FFFFFF',
  font: {
    size: '14',
  },
  align: 'center',
  anchor: 'center',
});

Chart.helpers.merge(Chart.defaults, {
  doughnut: {
    cutoutPercentage: 70,
  },
});

Chart.helpers.merge(Chart.defaults.global, {
  maintainAspectRatio: false,
  legend: {
    position: 'bottom',
    labels: {
      fontSize: 16,
      usePointStyle: true,
      boxWidth: 12.5,
    },
  },
  layout: {
    padding: {
      left: 0,
    },
  },
  tooltips: {
    titleFontSize: 14,
    bodyFontSize: 14,
  },
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
                <GlobalProvider>
                  <AdminRouter />
                </GlobalProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </Provider>,
  document.getElementById('root'),
);
