import React, { useState } from 'react';

import { EstimatedPackingType, IconButton, Link, Order, Product } from '@elromcoinc/react-shared';
import { TableCell, Tooltip } from '@material-ui/core';
import { List } from 'immutable';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import PackingModal from 'admin/components/OrderWindow/modals/Materials';
import { EstimatedPackingTypeNames } from 'admin/constants';
import { PackageCloseIcon, PackageOpenIcon } from 'common/components/icons';

enum Modals {
  PACKING = 'PACKING',
}

export const Materials = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const quote = order.getServiceQuote(serviceIndex);
  const { onChange } = useOrderChangeSet();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const { isClosing } = useOrderClosingContext();
  const name = getServicePropertyName('packingProducts');
  const packingProducts = order.getIn(name.split('.')) as List<Product>;

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);
  const handlePackingChanges = (value: List<Product>) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  return (
    <HighlightedTableRow data-testId="materials">
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.PACKING)}>
          {isClosing ? 'Packing/Unpacking' : 'Materials'}
        </Link>
        {Modals.PACKING === openModal && (
          <PackingModal
            open
            onSave={handlePackingChanges}
            onChange={onChange}
            onCancel={toggleOpenModal(null)}
            moveType={order.moveType}
            serviceType={quote.serviceType}
            packingProducts={packingProducts}
          />
        )}
      </TableCell>
      <TableCell>
        <Text
          value={
            <>
              {(isClosing ? order.closingOrderDetail : order)
                ?.getMaterialsTotal(serviceIndex, isSelectedAllServices)
                ?.asHumanReadableString()}
              {!isClosing && order.estimatedPackingType !== EstimatedPackingType.NONE && (
                <Tooltip
                  title={EstimatedPackingTypeNames[order.estimatedPackingType as keyof typeof EstimatedPackingType]}
                >
                  <IconButton color="primary" disableRipple>
                    {order.estimatedPackingType === EstimatedPackingType.PARTIAL && <PackageOpenIcon />}
                    {order.estimatedPackingType === EstimatedPackingType.FULL && <PackageCloseIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </>
          }
          data-testId={'materialsValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
