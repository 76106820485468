enum CreditCardType {
  VISA = 'VISA',
  MASTERCARD = 'MASTERCARD',
  AMERICANEXPRESS = 'AMERICANEXPRESS',
  DISCOVER = 'DISCOVER',
  DINERS = 'Diners',
  DINERS_CARTE_BLANCHE = 'Diners - Carte Blanche',
  JCB = 'JCB',
  VISA_ELECTRON = 'Visa Electron',
}

export { CreditCardType };
