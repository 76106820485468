import { formatCurrency } from '@elromcoinc/react-shared';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const updateDepositAmount: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('reservationAmountNeeded') > -1) {
    const oldDeposit = oldOrder.getValueIn(fieldPath) || 0;
    const newDeposit = newOrder.getValueIn(fieldPath) || 0;
    const oldValue = oldDeposit ? `$${formatCurrency(+oldDeposit)}` : '';
    const newValue = newDeposit ? `$${formatCurrency(+newDeposit)}` : '';
    return [
      createUpdatedValueDto(
        oldValue,
        newValue,
        'Deposit',
        extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
      ),
    ];
  }
  return null;
};

export default updateDepositAmount;
