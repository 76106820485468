import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const JobPaidIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.10000,-0.10000)" fill="#000000" stroke="none">
          <path d="M699 5101 c-109 -35 -191 -111 -235 -219 -23 -55 -24 -67 -24 -333 0 -255 1 -277 18 -292 27 -24 85 -22 108 4 17 19 19 44 24 294 l5 273 30 43 c19 27 50 54 84 71 l53 28 1610 0 c1178 0 1621 -3 1649 -11 50 -16 105 -61 132 -112 21 -40 22 -54 25 -349 2 -170 1 -308 -2 -308 -3 0 -41 10 -83 22 -111 32 -296 32 -406 0 -354 -101 -587 -406 -587 -767 0 -361 233 -666 587 -767 110 -32 295 -32 406 0 42 12 79 22 82 22 3 0 5 -391 5 -870 0 -857 0 -870 20 -890 30 -30 83 -27 109 6 21 27 21 31 21 933 l0 906 41 30 c101 74 205 211 256 335 71 178 71 412 0 590 -50 124 -154 260 -255 335 l-41 30 -3 360 c-3 349 -4 362 -26 417 -33 81 -108 160 -190 200 l-67 33 -1645 2 c-1559 2 -1648 1 -1701 -16z m3318 -1027 c194 -40 351 -159 443 -335 22 -42 46 -105 55 -140 19 -82 19 -226 0 -308 -41 -171 -176 -341 -335 -418 -141 -69 -273 -87 -417 -57 -196 40 -357 163 -443 339 -52 105 -70 179 -70 290 0 110 18 185 69 290 126 256 416 397 698 339z" />
          <path d="M3855 3939 c-33 -19 -45 -41 -45 -81 0 -24 -5 -38 -13 -38 -24 0 -90 -63 -108 -104 -26 -58 -25 -141 4 -188 30 -50 74 -81 179 -124 95 -40 113 -58 103 -104 -15 -67 -112 -92 -195 -50 -22 11 -49 20 -60 20 -31 0 -70 -44 -70 -79 0 -38 55 -87 116 -103 40 -11 44 -15 44 -44 0 -17 9 -43 21 -58 44 -55 129 -19 129 55 0 31 6 39 52 69 74 49 111 113 116 199 3 60 0 71 -30 120 -35 57 -48 66 -200 135 -66 30 -78 39 -78 59 0 13 8 31 18 40 26 24 108 22 150 -3 43 -26 68 -25 97 5 47 46 21 96 -70 140 -51 23 -55 28 -55 59 0 59 -58 101 -105 75z" />
          <path d="M1003 4640 c-45 -18 -55 -72 -22 -114 l20 -26 329 0 c298 0 330 2 349 18 33 26 31 79 -3 109 l-27 23 -312 -1 c-172 0 -322 -4 -334 -9z" />
          <path d="M985 4275 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 l24 -25 636 0 636 0 24 25 c32 31 32 69 0 100 l-24 25 -636 0 -636 0 -24 -25z" />
          <path d="M457 4012 c-16 -17 -17 -161 -17 -1865 0 -1843 0 -1846 21 -1902 41 -110 135 -196 250 -229 50 -15 218 -16 1695 -14 l1639 3 67 33 c83 41 158 120 189 200 20 49 23 80 27 232 4 167 3 176 -18 202 -24 32 -73 38 -106 14 -17 -13 -19 -31 -24 -193 -5 -158 -8 -183 -27 -220 -27 -50 -81 -96 -132 -112 -28 -8 -471 -11 -1649 -11 l-1610 0 -53 28 c-34 17 -65 44 -84 71 l-30 43 -5 1848 c-5 1799 -5 1848 -24 1869 -25 27 -86 29 -109 3z" />
          <path d="M981 3724 c-26 -33 -27 -65 -2 -95 l19 -24 860 -3 861 -2 27 23 c34 30 36 83 3 109 -20 17 -76 18 -884 18 l-864 0 -20 -26z" />
          <path d="M985 3305 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 l24 -25 850 0 c614 0 857 3 875 11 34 16 47 57 31 96 -7 16 -20 33 -30 37 -9 3 -401 6 -871 6 l-855 0 -24 -25z" />
          <path d="M985 2885 c-14 -13 -25 -36 -25 -50 0 -14 11 -37 25 -50 l24 -25 855 0 c470 0 862 3 871 6 10 4 23 21 30 37 16 39 3 80 -31 96 -18 8 -261 11 -875 11 l-850 0 -24 -25z" />
          <path d="M1003 2480 c-45 -18 -55 -72 -22 -114 l20 -26 864 0 c808 0 864 1 884 18 33 26 31 79 -3 109 l-27 23 -847 -1 c-466 0 -857 -4 -869 -9z" />
          <path d="M1173 1990 c-52 -12 -122 -59 -153 -101 -56 -77 -55 -71 -55 -644 l0 -530 24 -48 c29 -61 83 -113 141 -141 l45 -21 1205 0 1205 0 50 23 c63 29 100 64 133 127 l27 50 3 524 c3 612 5 600 -82 686 -91 91 7 85 -1335 84 -647 -1 -1190 -4 -1208 -9z m2402 -154 c19 -8 44 -30 55 -48 19 -32 20 -49 20 -531 0 -542 0 -545 -56 -582 -28 -20 -57 -20 -1199 -23 -1099 -2 -1172 -1 -1204 15 -19 10 -44 31 -55 46 -21 28 -21 38 -21 535 0 485 1 508 19 539 41 66 -38 63 1244 63 990 0 1168 -2 1197 -14z" />
          <path d="M1453 1580 c-12 -5 -26 -18 -32 -29 -7 -13 -11 -122 -11 -305 0 -273 1 -287 20 -306 30 -30 83 -27 109 6 17 22 21 41 21 110 l0 84 43 0 c141 0 247 97 247 225 0 84 -45 156 -125 198 -32 17 -61 22 -145 24 -58 2 -115 -1 -127 -7z m227 -165 c12 -11 20 -31 20 -51 0 -45 -38 -74 -96 -74 l-44 0 0 76 0 77 50 -5 c28 -3 59 -13 70 -23z" />
          <path d="M2123 1580 c-12 -5 -29 -19 -37 -32 -25 -39 -209 -531 -209 -560 0 -44 30 -69 77 -66 37 3 43 7 61 45 l20 42 118 1 117 0 12 -27 c21 -48 39 -63 78 -63 44 0 70 24 70 66 0 39 -188 541 -214 570 -25 27 -62 37 -93 24z m61 -334 c14 -37 26 -72 26 -77 0 -5 -25 -9 -55 -9 -30 0 -55 3 -55 8 0 11 52 153 56 150 1 -2 14 -34 28 -72z" />
          <path d="M2603 1580 c-12 -5 -26 -18 -32 -29 -7 -13 -11 -122 -11 -303 0 -272 1 -285 20 -306 30 -32 82 -30 109 4 20 26 21 39 21 305 0 152 -3 285 -6 294 -6 15 -54 46 -71 44 -4 0 -18 -4 -30 -9z" />
          <path d="M2886 1557 l-26 -26 0 -279 c0 -351 -12 -327 157 -327 113 0 123 2 179 30 213 109 213 486 -1 598 -32 17 -63 22 -162 25 -120 4 -122 4 -147 -21z m251 -147 c39 -23 50 -44 63 -110 13 -71 -3 -143 -44 -187 -26 -29 -40 -35 -88 -40 l-58 -6 0 182 0 181 48 0 c29 0 59 -7 79 -20z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
