import { Waypoint, roundNumberToFixedDigits } from '@elromcoinc/react-shared';
import { addMinutes } from 'date-fns';
import { List } from 'immutable';

import { TravelTimeLog } from 'admin/components/CrewStatusLog/types';
import { convertLocalTimeToMinutes } from 'admin/components/CrewStatusLog/utils/convertLocalTimeToMinutes';
import { durationToMinutes } from 'admin/utils';

interface AdjustWaypointsTravelTimeProps {
  firstTravelTimeInMinutes: number;
  lastWaypointTravelTimeInMinutes: number;
  finalBillableTravelTimeInMinutes: number;
}

const adjustWaypointsTravelTimeToGivenTravelTime = ({
  firstTravelTimeInMinutes,
  lastWaypointTravelTimeInMinutes,
  finalBillableTravelTimeInMinutes,
}: AdjustWaypointsTravelTimeProps) => {
  const sumOfTimes = firstTravelTimeInMinutes + lastWaypointTravelTimeInMinutes;
  if (finalBillableTravelTimeInMinutes > sumOfTimes && finalBillableTravelTimeInMinutes - sumOfTimes > 15) {
    new Array(roundNumberToFixedDigits((finalBillableTravelTimeInMinutes - sumOfTimes) / 15, 0))
      .fill(0)
      .forEach((_, index) => {
        if (index % 2 === 1) {
          firstTravelTimeInMinutes += 15;
        } else {
          lastWaypointTravelTimeInMinutes += 15;
        }
      });
  } else if (finalBillableTravelTimeInMinutes < sumOfTimes && sumOfTimes - finalBillableTravelTimeInMinutes > 15) {
    new Array(roundNumberToFixedDigits((sumOfTimes - finalBillableTravelTimeInMinutes) / 15, 0))
      .fill(0)
      .forEach((_, index) => {
        if (index % 2 === 1) {
          if (firstTravelTimeInMinutes > 15) {
            firstTravelTimeInMinutes -= 15;
          } else {
            lastWaypointTravelTimeInMinutes -= 15;
          }
        } else {
          if (lastWaypointTravelTimeInMinutes > 15) {
            lastWaypointTravelTimeInMinutes -= 15;
          } else {
            firstTravelTimeInMinutes -= 15;
          }
        }
      });
  }

  return {
    firstTravelTimeInMinutes,
    lastWaypointTravelTimeInMinutes,
  };
};

interface MakeTravelTimeProps {
  startTime: Date;
  endTime: Date | null;
  isTimeDone: boolean;
  hasAnyTime: boolean;
  waypoints: List<Waypoint>;
  finalBillableTravelTimeInMinutes: number;
  estimatedFinalBillableTravelTimeInMinutes: number;
}

export const makeTravelTime = ({
  startTime,
  endTime,
  isTimeDone,
  hasAnyTime,
  waypoints,
  finalBillableTravelTimeInMinutes,
  estimatedFinalBillableTravelTimeInMinutes,
}: MakeTravelTimeProps) => {
  const result: TravelTimeLog[] = [];

  if (waypoints.isEmpty()) {
    return { travelTimes: result, firstTravelTimeInMinutes: 0, lastWaypointTravelTimeInMinutes: 0 };
  }

  const firstWaypoint = waypoints.get(1);
  const lastWaypoint = waypoints.last();
  const {
    firstTravelTimeInMinutes: originalFirstTravelTimeInMinutes,
    lastWaypointTravelTimeInMinutes: originalLastWaypointTravelTimeInMinutes,
  } = adjustWaypointsTravelTimeToGivenTravelTime({
    firstTravelTimeInMinutes: durationToMinutes(firstWaypoint?.travelDurationFromPrevious),
    lastWaypointTravelTimeInMinutes: durationToMinutes(lastWaypoint?.travelDurationFromPrevious),
    finalBillableTravelTimeInMinutes: estimatedFinalBillableTravelTimeInMinutes,
  });
  const { firstTravelTimeInMinutes, lastWaypointTravelTimeInMinutes } = adjustWaypointsTravelTimeToGivenTravelTime({
    firstTravelTimeInMinutes: originalFirstTravelTimeInMinutes,
    lastWaypointTravelTimeInMinutes: originalLastWaypointTravelTimeInMinutes,
    finalBillableTravelTimeInMinutes,
  });

  const overtimeInMinutes = firstTravelTimeInMinutes - originalFirstTravelTimeInMinutes;
  const date = addMinutes(startTime, firstTravelTimeInMinutes * -1);
  const time = convertLocalTimeToMinutes(date, startTime) + overtimeInMinutes;
  const overtimeStart = time - overtimeInMinutes;
  const startTotalTimeline = overtimeStart;
  result.push({
    date,
    durationInMinutes: originalFirstTravelTimeInMinutes,
    time,
    overtimeInMinutes,
    overtimeStart,
    startTotalTimeline,
    bordered: !hasAnyTime,
  });

  if (endTime && lastWaypointTravelTimeInMinutes) {
    const time = convertLocalTimeToMinutes(endTime, startTime);
    const overtimeInMinutes = lastWaypointTravelTimeInMinutes - originalLastWaypointTravelTimeInMinutes;
    const startTotalTimeline = time;
    const isNotEnoughTime = overtimeInMinutes < 0;
    const overtimeStart = time + originalLastWaypointTravelTimeInMinutes + (isNotEnoughTime ? overtimeInMinutes : 0);

    result.push({
      date: endTime,
      durationInMinutes: isNotEnoughTime
        ? originalLastWaypointTravelTimeInMinutes + overtimeInMinutes
        : originalLastWaypointTravelTimeInMinutes,
      time,
      overtimeInMinutes,
      overtimeStart,
      startTotalTimeline,
      bordered: !isTimeDone,
    });
  }

  return { travelTimes: result, firstTravelTimeInMinutes, lastWaypointTravelTimeInMinutes };
};
