import { add, format, isAfter } from 'date-fns';
import { boolean, date } from 'yup';

import { DUE } from 'admin/components/OrderWindow/modals/Tasks';

const CREATE_A_FOLLOW_UP = 'createAFollowUp';

const labels = {
  [CREATE_A_FOLLOW_UP]: 'Create a follow-up',
};

export const followUpSchema = {
  [CREATE_A_FOLLOW_UP]: boolean(),
  [DUE]: date()
    .label('Remind date/time')
    .when(CREATE_A_FOLLOW_UP, (createAFollowUp: boolean, schema: any) => {
      if (createAFollowUp) {
        const currentDatePlus5Minutes = add(new Date(), { minutes: 5 });
        const errorText = `Please choose time more than ${format(currentDatePlus5Minutes, 'hh:mm a')}`;
        return schema
          .required()
          .test('is-valid-date', errorText, (value: Date) => isAfter(value, currentDatePlus5Minutes));
      }

      return schema;
    }),
};

export { CREATE_A_FOLLOW_UP, labels };
