import React, { useCallback, useEffect } from 'react';

import { PaymentCheckDto, PaymentStatus } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { CheckPaymentDto as CheckPaymentForm } from '../CreateCharge/CheckPayment';
import { AMOUNT, CHECK_NUMBER, NOTES, PAID_DATE, labels } from '../CreateCharge/Common';
import { CheckPaymentFormInputs } from '../Forms';

const schema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [CHECK_NUMBER]: string().label(labels[CHECK_NUMBER]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

interface RefundCheckPaymentProps {
  payment: PaymentCheckDto;
  onMount?: (trigger: () => Promise<any>) => void;
}

const RefundCheckPayment = ({ payment, onMount }: RefundCheckPaymentProps) => {
  const methods = useForm<CheckPaymentForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      [AMOUNT]: payment.amount,
      [CHECK_NUMBER]: payment.checkNumber,
      [PAID_DATE]: new Date(),
    },
  });

  const createCheckPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CheckPaymentForm) => {
          paymentActionsApi
            .createCheckPayment({
              ...payment,
              ...data,
              date: formatISO(data.date),
              status: PaymentStatus.REFUND,
              filePhotoId: null,
              fileSignatureId: null,
            })
            .then(resolve)
            .catch(reject);
        })();
      }),
    [methods.handleSubmit],
  );

  useEffect(() => {
    onMount?.(createCheckPayment);
  }, [createCheckPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <CheckPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { RefundCheckPayment };
