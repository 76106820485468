import { useEffect, useState } from 'react';

import { ActivitySourceType, Flag } from '@elromcoinc/react-shared';
import { useSnackbar } from 'notistack';

import { flagsApi } from 'admin/api';

const sortFlagsDataBySequence = (data: Flag[]) => data.sort((a, b) => a.sequenceOrder - b.sequenceOrder);

export const useFetchFlags = (sourceType: ActivitySourceType) => {
  const [flagsOptions, setFlagsOptions] = useState<Flag[]>([]);
  const [inFlight, setInFlight] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInFlight(true);
    flagsApi
      .getFlagsByActivitySource(sourceType)
      .then((data) => {
        const uniqueByNameSortedFlags: Flag[] = [];
        sortFlagsDataBySequence(data).forEach((item) => {
          if (!uniqueByNameSortedFlags.find((usf) => usf.name === item.name)) {
            uniqueByNameSortedFlags.push(item);
          }
        });
        setFlagsOptions(uniqueByNameSortedFlags);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get available flags`, { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }, [enqueueSnackbar]);

  return {
    flags: flagsOptions,
    inFlight,
  };
};
