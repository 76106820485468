import { Box } from '@material-ui/core';

import { hours } from './CalendarConstants';
import { TruckRow } from './Rows';

export const renderContentRowForWeekServices =
  (entriesPerDay: number, className: string) => (data: string, idx: number) =>
    (
      <Box display="flex" key={data} className={className}>
        {hours.map((hour) => (
          <TruckRow key={hour} grey={idx % 2 === 0} flexDirection="column" $height={50 * entriesPerDay}>
            {Array(entriesPerDay)
              .fill(0)
              .map((_, idx) => (
                <Box
                  key={idx}
                  height={`calc(100% / ${entriesPerDay})`}
                  borderBottom="1px solid #d7d7da;"
                  borderRight="1px solid #d7d7da;"
                />
              ))}
          </TruckRow>
        ))}
      </Box>
    );
