import { EmailDto } from 'common-types';
import { EmailFormDto } from 'common-types/EmailFormDto';

import { toEmailList } from './toEmailList';

export const toEmailDTO = (form: Partial<EmailFormDto>): EmailDto => {
  return {
    ...form,
    recipients: toEmailList(form?.recipients!),
    ccList: toEmailList(form?.ccList!),
    bccList: toEmailList(form?.bccList!),
  } as EmailDto;
};
