import React, { FC, useState } from 'react';

import { BodyText, CurrencyInput, IconButton, PriceAdjustmentType } from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useFormContext } from 'react-hook-form';

import { AMOUNT, TYPE } from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';

const DISCOUNT_TYPE_NAME = {
  [PriceAdjustmentType.ABSOLUTE]: 'Absolute',
  [PriceAdjustmentType.PERCENT]: 'Percent',
};

interface InvoicesDiscountProps {
  name: string;
}
const AdjustmentSubtotalItem: FC<InvoicesDiscountProps> = ({ name }) => {
  const [anchorElOfDiscountType, setAnchorElOfDiscountType] = useState<Element | null>(null);
  const { watch, setValue } = useFormContext();
  const selectedDiscountType = watch(`${name}.${TYPE}`);

  const handleDiscountTypeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOfDiscountType(event.target as Element);
  };
  const handleClosePopover = () => {
    setAnchorElOfDiscountType(null);
  };

  const handleInvoiceClose = (type: PriceAdjustmentType) => () => {
    setValue(`${name}.${TYPE}`, type);
    setAnchorElOfDiscountType(null);
  };

  return (
    <Box display="flex" justifyContent="spaceBetween" alignItems="center">
      <CurrencyInput
        data-testid="discount"
        name={`${name}.${AMOUNT}`}
        size="small"
        defaultValue="0"
        hiddenLabel
        amountType={selectedDiscountType || PriceAdjustmentType.PERCENT}
        inputProps={{ prefix: '', allowNegativeValue: true }}
      />
      <IconButton size="tiny" onClick={handleDiscountTypeClick} color="default">
        <ArrowDropDown fontSize="small" />
      </IconButton>
      <Popover
        open={Boolean(anchorElOfDiscountType)}
        anchorEl={anchorElOfDiscountType}
        id="services-list"
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List disablePadding>
          <ListItem button dense component="li" onClick={handleInvoiceClose(PriceAdjustmentType.ABSOLUTE)}>
            <ListItemText
              primary={
                <>
                  <BodyText>{DISCOUNT_TYPE_NAME[PriceAdjustmentType.ABSOLUTE]}</BodyText>
                </>
              }
            />
          </ListItem>
          <ListItem button dense component="li" onClick={handleInvoiceClose(PriceAdjustmentType.PERCENT)}>
            <ListItemText
              primary={
                <>
                  <BodyText>{DISCOUNT_TYPE_NAME[PriceAdjustmentType.PERCENT]}</BodyText>
                </>
              }
            />
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

export { AdjustmentSubtotalItem };
