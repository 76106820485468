import React, { FC, useState } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  BodyText,
  Button,
  ElromcoCircularProgress,
  Modal,
  SmsCapability,
  formatPhoneNumber,
} from '@elromcoinc/react-shared';
import { Box, ListSubheader, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { Map } from 'immutable';
import { OptionsObject, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import communicationAPI from 'admin/api/CommunicationsAPI';
import templateApi from 'admin/api/templateApi';
import { getIsDisabledSms } from 'admin/autodux/MessageAutodux';
import { MessageInput } from 'admin/components/Communication/MessageTab/Messages';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { CommunicationModality, Template, TemplateDTO } from 'common-types';
import { TemplateBuilderTokens } from 'common-types/TemplateBuilderTokens';

const sourceMap = Map({
  [ActivitySourceType.ORDER]: 'order(s)',
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account(s)',
});

const successMessage: OptionsObject = { variant: 'success' };
const errorMessage: OptionsObject = { variant: 'error' };
const warningMessage: OptionsObject = { variant: 'warning' };

const useStyles = makeStyles((theme) => ({
  subheader: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
  },
}));

interface SendMassTextModalProps {
  title: string;
  onClose: () => void;
  sources: { sourceId: number; phoneNumber: string; smsCapability?: SmsCapability; sourceName?: string }[];
  activitySource: ActivitySourceType;
}

const messageTypeMap = {
  [ActivitySourceType.ORDER]: 'order',
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account',
};

export const SendMassTextModal: FC<SendMassTextModalProps> = ({ title, onClose, sources, activitySource }) => {
  const [text, setText] = useState('');
  const isDisableSms = useSelector(getIsDisabledSms);
  const [isProcessing, setIsProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const source = sourceMap.get(activitySource);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { groupedSMSTemplatesByTemplateFolder = [], inFlightTemplates = false } = useComposeEmail() || {};
  const [errorSendingSms, setErrorSendingSms] = useState<string[]>([]);

  const prepareTemplate = (template: string, sourceId: number) => {
    if (
      TemplateBuilderTokens.some(({ simpleToken }) => template.includes(simpleToken)) &&
      activitySource === ActivitySourceType.ORDER
    ) {
      return templateApi.preview(sourceId!, activitySource, {
        name: 'some',
        body: convertTemplateBuilderVariables(template),
        modality: CommunicationModality.EMAIL,
      } as TemplateDTO);
    }

    return Promise.resolve(template);
  };

  const handleSendSMS = () => {
    if (!text || text.trim().length === 0) {
      return;
    }

    setIsProcessing(true);

    Promise.allSettled(
      sources.map(({ sourceId, phoneNumber }) =>
        prepareTemplate(text, sourceId).then((processedTemplate) =>
          communicationAPI.sendSms({
            sourceDescriptor: { referencedEntityId: sourceId, activitySource },
            body: processedTemplate,
            toPhone: phoneNumber,
          }),
        ),
      ),
    )
      .then((result) => {
        if (result.some(({ status }) => status === 'rejected')) {
          enqueueSnackbar(`SMS was not sent for some ${source}. Try again`, errorMessage);
          setErrorSendingSms(
            sources
              .filter((_, index) => result[index].status === 'rejected')
              .map(
                ({ sourceName, phoneNumber }) =>
                  `SMS was not send for ${sourceName} phone number ${formatPhoneNumber(phoneNumber)}`,
              ),
          );
        } else {
          setText('');
          enqueueSnackbar('SMS sent successfully', successMessage);
          onClose();
        }
      })
      .then(() => {
        setIsProcessing(false);
      });
  };

  const handleSendMessage = () => {
    if (activitySource !== ActivitySourceType.ORDER && activitySource !== ActivitySourceType.CUSTOMER_ACCOUNT) {
      enqueueSnackbar('Under Construction', warningMessage);
      return;
    }

    if (!text || text.trim().length === 0) {
      return;
    }

    setIsProcessing(true);

    Promise.all(
      sources.map(({ sourceId }) =>
        prepareTemplate(text, sourceId).then((processedTemplate) =>
          communicationAPI.sendMessage(
            sourceId,
            processedTemplate,
            messageTypeMap[activitySource] as 'order' | 'account',
          ),
        ),
      ),
    )
      .then(() => {
        setText('');
        enqueueSnackbar('Message sent successfully', successMessage);
        onClose();
      })
      .catch(() => {
        enqueueSnackbar('Message was not sent. Try again', errorMessage);
      })
      .then(() => {
        setIsProcessing(false);
      });
  };

  const openApplyTemplate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeApplyTemplate = () => {
    setAnchorEl(null);
  };

  const handleChooseTemplate = (template: Template) => () => {
    setText(template.body);
    closeApplyTemplate();
  };

  return (
    <Modal onClose={onClose} title={title} open hideActionControls={isProcessing}>
      {!!errorSendingSms.length && (
        <Box>
          Problem with sending SMS:
          {React.Children.toArray(errorSendingSms.map((error) => <BodyBigText>{error}</BodyBigText>))}
        </Box>
      )}
      {activitySource === ActivitySourceType.ORDER && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="outlined"
            onClick={openApplyTemplate}
            loading={inFlightTemplates}
            disabled={inFlightTemplates}
          >
            Apply Template
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeApplyTemplate}>
            {groupedSMSTemplatesByTemplateFolder.map(([folderName, templates]) => [
              <ListSubheader
                aria-controls={`${folderName}-content`}
                id={`${folderName}-header`}
                classes={{ sticky: classes.subheader }}
              >
                {folderName}
              </ListSubheader>,
              templates.map((item) => (
                <MenuItem key={item.id} onClick={handleChooseTemplate(item)}>
                  {item.name}
                </MenuItem>
              )),
            ])}
          </Menu>
        </Box>
      )}
      {isProcessing ? (
        <Box position="relative" height={50}>
          <ElromcoCircularProgress />
          <Box pt={1} display="flex" flexDirection="column" alignItems="center">
            <BodyText color="primary">
              <b>
                Sending mass text for {sources.length} {source}...
              </b>
            </BodyText>
          </Box>
        </Box>
      ) : (
        <Box pt="25px">
          <MessageInput
            sendMsg={handleSendMessage}
            setText={setText}
            sendSms={handleSendSMS}
            text={text}
            isLoadingSms={isProcessing}
            isCanSendSMS={!isDisableSms && !errorSendingSms.length}
          />
        </Box>
      )}
    </Modal>
  );
};
