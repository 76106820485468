import React, { useCallback, useEffect, useMemo } from 'react';

import {
  ActivitySourceType,
  CreditCardType,
  CurrencyInput,
  TextInput,
  cardExpirationYUP,
  cardNumberYUP,
  cardTypeIcon,
  getCardType,
  toBackEndDate,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { StringSchema, number, object, string } from 'yup';

import paymentActionsApi, { CustomerPaymentProfileDto, PaymentProfileDto } from 'admin/api/PaymentActionsApi';

import { usePaymentClientKey } from '../../../context';
import { PaymentTypeSelector } from '../Forms/PaymentTypeSelector';
import { usePaymentSourceContext } from '../PaymentsSourceContext';
import { convertExpirationStringToDate } from '../utils';
import { CashPaymentDto, CashPaymentProps } from './CashPayment';
import {
  AMOUNT,
  CARDHOLDER_NAME,
  CARD_NUMBER,
  CCV_CODE,
  DESCRIPTION,
  EXPIRATION,
  NOTES,
  PAYMENT_TYPE,
  POSTAL_CODE,
  SELECTED_CC,
  labels,
} from './Common';
import CreditCardForm from './CreditCardForm';
import RadioControlCreditCards from './RadioControlCreditCards';

export interface CreditCardOnlineForm extends Omit<CashPaymentDto, 'date'> {
  'cc-name': string;
  'cc-number': string;
  'cc-exp': string;
  'cc-csc': string;
  postalCode: string;
  description: string;
  selectedCC?: string;
  makeDefault?: boolean;
}

const addNewCardId: number = -1;
const isSelectedAddNewCard = (value: string | number): boolean => {
  return Number(value) === addNewCardId;
};

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    creditCardWrapper: {
      display: 'flex',
      '& svg': {
        fontSize: '35px',
        padding: '7px 10px 7px 0px',
      },
    },
  }),
);

const schema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [SELECTED_CC]: number().label('Credit Card'),
  [NOTES]: string().nullable().max(1000),
  [CARDHOLDER_NAME]: string()
    .nullable()
    .when(SELECTED_CC, {
      is: isSelectedAddNewCard,
      then: (s) => {
        return s.label(labels[CARDHOLDER_NAME]).nullable().required().min(3);
      },
    }),
  [EXPIRATION]: string()
    .nullable()
    .when(SELECTED_CC, {
      is: isSelectedAddNewCard,
      then: (s) => {
        return cardExpirationYUP({ label: labels[EXPIRATION], baseSchema: s as StringSchema }).required();
      },
    }),
  [CARD_NUMBER]: string()
    .nullable()
    .when(SELECTED_CC, {
      is: isSelectedAddNewCard,
      then: (s) => {
        return cardNumberYUP({ label: labels[CARD_NUMBER], baseSchema: s as StringSchema });
      },
    }),
  [CCV_CODE]: string()
    .label(labels[CCV_CODE])
    .nullable() // @ts-ignore
    .when([SELECTED_CC, CARD_NUMBER], (selectedCC: string, cardNumber: string, currentSchema: StringSchema) => {
      if (isSelectedAddNewCard(selectedCC)) {
        const cvcSize = getCardType(cardNumber) === CreditCardType.AMERICANEXPRESS ? 4 : 3;
        return currentSchema.required().min(cvcSize).max(cvcSize);
      }

      return currentSchema;
    }),
  [POSTAL_CODE]: string()
    .nullable()
    .when(SELECTED_CC, {
      is: isSelectedAddNewCard,
      then: () => zipCodeAsyncYUP(POSTAL_CODE).label(labels[POSTAL_CODE]),
    }),
});

interface CreditCardOnlineProps extends CashPaymentProps {
  customerPaymentProfile: null | CustomerPaymentProfileDto;
  description: string;
  cardHolder?: string;
  customerId: number;
}

const CreditCardOnline = ({
  amount: initialAmount,
  finalAmount,
  description,
  onMount,
  customerPaymentProfile,
  ifDepositUnavailableHint,
  isDepositAvailable,
  cardHolder = '',
  onAmountChange,
  onTypeChange,
  customerId,
  type,
  activitySources,
}: CreditCardOnlineProps) => {
  const classes = useStyles();
  const publicClientKey = usePaymentClientKey();
  const { sourceId, activitySource } = usePaymentSourceContext();
  const methods = useForm<CreditCardOnlineForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: initialAmount as number,
      description,
      [CARDHOLDER_NAME]: cardHolder,
      [PAYMENT_TYPE]: type,
      selectedCC: `${customerPaymentProfile?.defaultPaymentMethodId || addNewCardId}`,
    },
  });

  const cardNumber = methods.watch(CARD_NUMBER) || '';
  const cardType = getCardType(cardNumber);

  const amount = methods.watch(AMOUNT);

  useEffect(() => {
    onAmountChange?.(amount);
  }, [amount]);

  useEffect(() => {
    onTypeChange?.(methods.watch(PAYMENT_TYPE));
  }, [methods.watch(PAYMENT_TYPE)]);

  const createPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CreditCardOnlineForm) => {
          const selectedPaymentMethod = Number(methods.watch(SELECTED_CC));

          if (isSelectedAddNewCard(selectedPaymentMethod)) {
            const expDate = convertExpirationStringToDate(data[EXPIRATION]);
            const cardData = {
              cardNumber: data['cc-number'],
              month: format(expDate, 'MM'),
              year: format(expDate, 'yyyy'),
              cardCode: data['cc-csc'],
            };
            const authData = {
              clientKey: publicClientKey?.clientKey,
              apiLoginID: publicClientKey?.apiLoginId,
            };
            const secureData = {
              authData,
              cardData,
            };
            // @ts-ignore
            Accept.dispatchData(secureData, (response) => {
              if (response.messages.resultCode === 'Error') {
                reject(response.messages.message);
                return;
              }
              paymentActionsApi
                .processNewCardPayment({
                  paymentToken: response.opaqueData.dataValue as string,
                  amount: +finalAmount,
                  message: data[NOTES],
                  billingAddress: {
                    postalCode: data.postalCode,
                  },
                  cardholderName: data[CARDHOLDER_NAME],
                  type,
                  sourceId: sourceId!,
                  activitySource,
                  activitySources,
                  customerId,
                  expiration: toBackEndDate(expDate)!,
                  lastFourDigits: data[CARD_NUMBER].slice(-4),
                  paymentDescription: data[DESCRIPTION],
                })
                .then(resolve)
                .catch(reject);
            });
          } else {
            paymentActionsApi
              .chargePaymentMethod(selectedPaymentMethod, {
                amount: +finalAmount,
                message: data[NOTES],
                type,
                sourceId: sourceId!,
                activitySource,
                activitySources,
                customerId,
                paymentDescription: data.description,
              })
              .then(resolve)
              .catch(reject);
          }
        }, reject)();
      }),
    [methods.handleSubmit, sourceId, activitySource, type, activitySources?.length, finalAmount],
  );

  useEffect(() => {
    onMount?.(createPayment);
  }, [createPayment]);

  const existingCreditCards: PaymentProfileDto[] = useMemo(
    () => [{ id: -1 } as PaymentProfileDto, ...(customerPaymentProfile?.paymentMethods || [])],
    [customerPaymentProfile?.paymentMethods],
  );
  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        {activitySource === ActivitySourceType.ORDER && (
          <PaymentTypeSelector
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            isDepositAvailable={isDepositAvailable}
          />
        )}
        <Grid item xs={12}>
          <CurrencyInput
            /** @ts-ignore */
            label={labels[AMOUNT]}
            fullWidth
            name={AMOUNT}
          />
        </Grid>
        <RadioControlCreditCards name={SELECTED_CC} data={existingCreditCards} />
        {isSelectedAddNewCard(methods.watch(SELECTED_CC)!) && (
          <>
            <Grid item xs={12}>
              <Box className={classes.creditCardWrapper}>
                {cardType
                  ? cardTypeIcon[cardType as keyof typeof cardTypeIcon]
                  : Object.keys(CreditCardType).map((card) => cardTypeIcon[card as keyof typeof cardTypeIcon])}
              </Box>
            </Grid>
            <CreditCardForm />
          </>
        )}
        <Grid item xs={12}>
          <TextInput label={labels[DESCRIPTION]} fullWidth name={DESCRIPTION} />
        </Grid>
        <Grid item xs={12}>
          <TextInput label={labels[NOTES]} fullWidth name={NOTES} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export { CreditCardOnline };
