import React from 'react';
import { Box } from '@material-ui/core';
import { BodyText, Button, ButtonProps } from '@elromcoinc/react-shared';

interface PaymentTypeButtonProps extends ButtonProps {
  selected: boolean;
  icon: React.ReactElement;
}

const PaymentTypeButton: React.FC<PaymentTypeButtonProps> = ({ selected, children, icon, ...rest }) => {
  return (
    <Box maxWidth={80} maxHeight={80} py={1}>
      <Button color={selected ? 'primary' : 'default'} size="small" variant="text" textTransform="none" {...rest}>
        <Box
          height={64}
          width={70}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="inherit"
          alignItems="center"
        >
          {icon}
          <BodyText>{children}</BodyText>
        </Box>
      </Button>
    </Box>
  );
};

export default PaymentTypeButton;
