import React, { useEffect } from 'react';

import { PaymentCheckDto } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO, parseISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { CheckPaymentDto as CheckPaymentForm } from '../CreateCharge/CheckPayment';
import { AMOUNT, CHECK_NUMBER, NOTES, PAID_DATE, labels } from '../CreateCharge/Common';
import { CheckPaymentFormInputs } from '../Forms';

const schema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [CHECK_NUMBER]: string().label(labels[CHECK_NUMBER]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

interface EditCheckPaymentProps {
  payment: PaymentCheckDto;
  onMount?: (trigger: () => Promise<any>) => void;
}

const EditCheckPayment = ({ payment, onMount }: EditCheckPaymentProps) => {
  const methods = useForm<CheckPaymentForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      [NOTES]: payment.message,
      [AMOUNT]: payment.amount,
      [CHECK_NUMBER]: payment.checkNumber,
      [PAID_DATE]: parseISO(payment.date as string),
    },
  });

  useEffect(() => {
    onMount?.(
      () =>
        new Promise((resolve, reject) => {
          methods.handleSubmit((data: CheckPaymentForm) => {
            paymentActionsApi
              .updateCheckPayment(payment.id, {
                ...payment,
                ...data,
                date: formatISO(data.date),
              })
              .then(resolve)
              .catch(reject);
          })();
        }),
    );
  }, []);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <CheckPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { EditCheckPayment };
