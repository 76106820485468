import React, { FC, useState } from 'react';

import { isMovingAndStorageService } from '@elromcoinc/react-shared';

import { settingsAPI } from 'admin/api';
import { useOrderServiceIndex } from 'admin/components/OrderWindow/context/useOrderServiceIndex';
import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';

import { ValidateStorageOnBeContext } from './ValidateStorageOnBeContext';

export const ValidateStorageOnBeProvider: FC = ({ children }) => {
  const [storageErrors, setStorageErrors] = useState<string[]>([]);
  const { order } = useOrderState();
  const { serviceIndex } = useOrderServiceIndex();
  const service = order?.services?.get(serviceIndex);
  const storageQuote = order?.getServiceStorageQuote(serviceIndex);
  const isMovingAndStorage = isMovingAndStorageService(service?.getType());

  const handleCloseWarningModal = () => {
    setStorageErrors([]);
  };

  const validateStorageMoveSizeOnBe = (moveSize: number) => {
    if (isMovingAndStorage) {
      settingsAPI
        .getOrderStorageErrors(storageQuote?.start, storageQuote?.end, order?.moveType, moveSize)
        .then(setStorageErrors)
        .catch(() => {});
    }
  };

  return (
    <ValidateStorageOnBeContext.Provider
      value={{ storageErrors, validateStorageMoveSizeOnBe, handleCloseWarningModal }}
    >
      {children}
    </ValidateStorageOnBeContext.Provider>
  );
};
