import React from 'react';
import pt from 'prop-types';
import styled from 'styled-components';
import LeadTemperature, { LeadTemperatureColor } from 'admin/constants/LeadTemperature';

function getScoreColor(score, { warmThreshold, hotThreshold }) {
  if (score > hotThreshold) {
    return LeadTemperatureColor[LeadTemperature.HOT];
  }

  if (score > warmThreshold) {
    return LeadTemperatureColor[LeadTemperature.WARM];
  }

  return LeadTemperatureColor[LeadTemperature.COLD];
}

const Score = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${({ $bgColor }) => $bgColor};
  color: white;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
`;
function LeadScore({ score, leadScoreSettings }) {
  return <Score $bgColor={getScoreColor(score, leadScoreSettings)}>{score}</Score>;
}

LeadScore.propTypes = {
  score: pt.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  leadScoreSettings: pt.object.isRequired
};

export default LeadScore;
