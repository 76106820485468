import React, { FC } from 'react';

import { BodyBigText, Button, DatePicker, IconButton } from '@elromcoinc/react-shared';
import { Box, InputAdornment, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import { MaterialUiPickersDate } from 'material-ui-pickers';

interface CompareMonthProps {
  compareMonths: boolean;
  currentDate: Date | null;
  setCurrentDate: (date: MaterialUiPickersDate) => void;
  previousDate: Date | null;
  setPreviousDate: (date: MaterialUiPickersDate) => void;
  handleCompareMonthsClick: () => void;
  isLoading: boolean;
  isFlight: boolean;
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    datePicker: {
      padding: 0,
    },
  }),
);

export const CompareMonth: FC<CompareMonthProps> = ({
  compareMonths,
  currentDate,
  setCurrentDate,
  previousDate,
  setPreviousDate,
  handleCompareMonthsClick,
  isLoading,
  isFlight,
}) => {
  const classes = useStyles();
  const handleCloseComparison = () => {
    handleCompareMonthsClick();
    setPreviousDate(null);
  };

  return (
    <Box display="flex">
      <Box display="flex" flexGrow={1} maxWidth="11rem">
        <DatePicker
          openTo="year"
          views={['year', 'month']}
          format="MMMM yyyy"
          label={currentDate ? 'Current Month' : ' '}
          value={currentDate}
          emptyLabel="Current Month"
          onChange={setCurrentDate}
          InputProps={{
            disableUnderline: true,
            disabled: isFlight,
          }}
          clearable
        />
      </Box>
      {compareMonths ? (
        <Box maxWidth="10.5rem">
          <DatePicker
            openTo="year"
            views={['year', 'month']}
            format="MMMM yyyy"
            label={previousDate ? 'Previous Month' : ' '}
            emptyLabel="Previous Month"
            value={previousDate}
            onChange={setPreviousDate}
            InputProps={{
              disableUnderline: true,
              disabled: isFlight,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" size="tiny" onClick={handleCloseComparison} disabled={isLoading}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
              classes: { root: classNames(classes.datePicker) },
            }}
            clearable
          />
        </Box>
      ) : (
        <Box ml={0.5} display="flex" alignItems="end">
          <Button
            size="medium"
            rounded
            textTransform="capitalize"
            variant="text"
            color="primary"
            disabled={isFlight}
            onClick={handleCompareMonthsClick}
          >
            <BodyBigText>
              <b>Compare months</b>
            </BodyBigText>
          </Button>
        </Box>
      )}
    </Box>
  );
};
