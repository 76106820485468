import {
  AdditionalService,
  CompanyCalendar,
  ContractTemplate,
  CustomPaymentSettingDto,
  LaborOnlySetting,
  PaymentFeeSettingDto,
} from '@elromcoinc/moveboard-setting-react';
import { GeneralService, PropertyTypeDto } from '@elromcoinc/react-shared';

import { MovingAndStorageSetting, PackingSetting, UnitSizeSpec } from 'common-types';
import { TruckSchedulerWindowPlacement } from 'common-types/TruckSchedulerWindowPlacement';

export interface OrderWindowSettings {
  GeneralService: GeneralService[];
  'orders.overnight.max_days.res': number;
  'orders.overnight.max_days.com': number;
  'orders.overnight.max_days.mil': number;
  UnitSizeSpec: UnitSizeSpec[];
  'gcm.google_api_key': string;
  'gci.acceptsResidentialOrders': boolean;
  'gci.acceptsCommercialOrders': boolean;
  'gci.acceptsMilitaryOrders': boolean;
  'gci.customerHost': string;
  'gr.travelTimeRatesSameAsLaborRates': boolean;
  'orders.TruckScheduleWindowPlacement': TruckSchedulerWindowPlacement;
  'calculator.travel_time.name': string;
  'calculator.travel_time.double_travel.name': string;
  'orders.extra_fee.res.name': string;
  'orders.extra_fee.com.name': string;
  'orders.extra_fee.mil.name': string;
  'calculator.travel_time.enabled': boolean;
  'calculator.travel_time.travel_time_in_dollar_amount': boolean;
  CompanyCalendar: CompanyCalendar[];
  PackingServiceType: PackingSetting[];
  LaborOnlySetting: LaborOnlySetting[];
  PropertyType: PropertyTypeDto[];
  AdditionalService: AdditionalService[];
  PaymentCustomSettings: CustomPaymentSettingDto[];
  ProcessingFeeSettings: PaymentFeeSettingDto[];
  ContractTemplate: ContractTemplate[];
  MovingAndStorageSetting: MovingAndStorageSetting[];
}

export interface OrderWindowSettingsState {
  inFlight: boolean;
  error?: string | null;
  settings: OrderWindowSettings;
}

export const initialState: OrderWindowSettingsState = {
  inFlight: true,
  error: null,
  settings: {} as OrderWindowSettings,
};
