import { JobDto, TIME_STRING_FORMAT, parseISO, toDate } from '@elromcoinc/react-shared';
import { addDays, addMinutes, isAfter, isSameDay, startOfDay } from 'date-fns';

import { convertLocalTimeToMinutes } from 'admin/components/CrewStatusLog/utils/convertLocalTimeToMinutes';

export const normalizeJobStatusesDates = (job: JobDto | null, startTime?: string) => {
  const firstJobStatusDate =
    parseISO(job?.jobStatuses?.[0]?.created ?? job?.jobStatuses?.[0]?.updated) ||
    toDate(startTime, TIME_STRING_FORMAT)!;

  return (job?.jobStatuses || []).map((s) => {
    if (s.updated) {
      const parsedDate = parseISO(s.updated)!;

      if (isAfter(firstJobStatusDate, parsedDate) && !isSameDay(firstJobStatusDate, parsedDate)) {
        const time = convertLocalTimeToMinutes(parsedDate);
        return { ...s, updated: addMinutes(startOfDay(addDays(parsedDate, 1)), time).toISOString() };
      }
    }

    if (s.created) {
      const parsedDate = parseISO(s.created)!;

      if (isAfter(firstJobStatusDate, parsedDate) && !isSameDay(firstJobStatusDate, parsedDate)) {
        const time = convertLocalTimeToMinutes(parsedDate);
        return { ...s, created: addMinutes(startOfDay(addDays(parsedDate, 1)), time).toISOString() };
      }
    }

    return s;
  });
};
