// This input only for order. Please don't use it in other places.
import React, { FC } from 'react';

import { SimpleCurrencyInput, TextInput, TextInputProps } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    '& > div > div': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.h5.fontSize,
      whiteSpace: 'normal',
    },
    '& input': {
      padding: '3px 8px',
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

interface CurrencyInputProps extends Omit<TextInputProps, 'onChange'> {
  onChange?: (value: string) => void;
  prefix?: string;
}

export const CurrencyInput: FC<CurrencyInputProps> = ({ onChange, className, prefix = '$', ...rest }) => {
  const classes = useStyles();

  return (
    <TextInput
      fullWidth
      className={clsx(classes.root, className)}
      InputProps={{
        disableUnderline: true,
        inputComponent: SimpleCurrencyInput,
        onChange: onChange,
      }}
      inputProps={{
        allowNegativeValue: false,
        prefix,
      }}
      {...rest}
    />
  );
};
