export const getPagination = <T = any>(array: T[], pageSize: number, pageNumber: number) => {
  const offset = pageSize * (pageNumber - 1);
  const totalPages = Math.ceil(array.length / pageSize);
  const paginatedItems = array.slice(offset, pageSize * pageNumber);

  return {
    previousPage: pageNumber - 1 ? pageNumber - 1 : null,
    nextPage: totalPages > pageNumber ? pageNumber + 1 : null,
    total: array.length,
    totalPages: totalPages,
    items: paginatedItems,
  };
};
