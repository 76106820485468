import { FC, useState } from 'react';

import { GlobalContext } from 'context/GlobalContext';

export const GlobalProvider: FC = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        isSessionExpired,
        setIsSessionExpired,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
