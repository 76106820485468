import { formatCurrency } from '@elromcoinc/react-shared';

import OverridableValue from 'admin/entities/OverridableValue';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const LABOR_RATE_OVERRIDE = 'laborRateOverride';

const laborRateChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf(LABOR_RATE_OVERRIDE) > -1) {
    const key = fieldPath.replace(/laborRateOverride.*$/, LABOR_RATE_OVERRIDE);
    const oldLaborRate = oldOrder.getValueIn(key) as OverridableValue;
    const newLaborRate = newOrder.getValueIn(key) as OverridableValue;

    if (!oldLaborRate || !newLaborRate) {
      return null;
    }

    const oldValue = oldLaborRate ? `$${formatCurrency(+(oldLaborRate.value() || 0))}` : '';
    const newValue = newLaborRate ? `$${formatCurrency(+(newLaborRate.value() || 0))}` : '';
    return [
      createUpdatedValueDto(
        oldValue,
        newValue,
        undefined,
        extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
      ),
    ];
  }
  return null;
};

export default laborRateChange;
