import React, { useState } from 'react';

import { SurveyType, surveyStatusesById } from '@elromcoinc/react-shared';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import ArrowForward from '@material-ui/icons/ArrowForward';
import HomeIcon from '@material-ui/icons/Home';
import PersonOutline from '@material-ui/icons/PersonOutline';
import VideocamIcon from '@material-ui/icons/Videocam';
import pt from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openOrder } from 'admin/autodux/WindowsAutodux';
import { EstimationTooltip } from 'admin/components/OrderWindow/SurveySchedulerBox';
import {
  convertDurationToMinutes,
  convertLocalTimeToMinutes,
  convertLocalTimeToSeconds,
  convertSecondsToLocalTime,
  formatTime,
} from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { hexToRgb, textColorForBackground } from 'admin/utils/colorUtils';

const StyledBox = styled(Box)`
  ${({ $currentWork, $color }) =>
    $currentWork ? `border: 2px dashed ${textColorForBackground(hexToRgb($color))};` : ''}
  ${({ $selectedWork, theme }) => ($selectedWork ? `outline: 2px dashed ${theme.palette.primary.main};` : '')}
  ${({ $selectedCurrentWork, theme }) =>
    $selectedCurrentWork ? `border: 2px dashed ${theme.palette.primary.main};` : ''}
  margin: ${({ $dense = false }) => ($dense ? 2 : 3)}px 0;
  height: ${({ $expanded = false, $dense = false }) => ($dense && !$expanded ? 20 : 51)}px;
  top: ${({ $dense = false, level = 0 }) => ($dense ? 24 : 56) * level}px;
  left: ${({ $numHours = 16, $startTime = 0 }) => `calc((100% / ${$numHours} / 60) * ${$startTime})`};
  position: absolute;
  min-width: 17px;
  width: ${({ $numHours = 16, $durationMin = 15 }) => `calc((100% / ${$numHours} / 60) * ${$durationMin})`};
  background-color ${({ $color }) => $color};
  color: ${({ theme }) => `${theme.palette.common.white}`};
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: ${({ $expanded = false, $dense = false }) => ($dense && !$expanded ? 'row' : 'column')};
  justify-content: ${({ $expanded = false, $dense = false }) => ($dense && !$expanded ? 'flex-start' : 'center')};
  align-items: ${({ $expanded = false, $dense = false }) => ($dense && !$expanded ? 'center' : 'flex-start')};
  padding: 0 8px 0 24px;
  ${({ $expanded = false }) => ($expanded ? 'z-index: 1300;' : '')}
  cursor: pointer;
`;

const ServiceType = styled.span`
  position: absolute;
  width: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ $color = 'transparent' }) => $color};
`;

const DescriptionRow = styled(Box)`
  && {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-style: normal;
    font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => `${theme.palette.common.white}`};
    margin-right: 4px;
    ${({ $dense = false }) => ($dense ? `&::after { content: ' | '; }` : '')}
  }
`;

const Description = styled.span`
  font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
  &:not(:last-child) {
    &:after {
      content: ' | ';
    }
  }
`;

const iconSize = { fontSize: '0.75rem' };
const TWENTY_FOUR_HOURS = 24;
const ONE_HOUR_IN_MINUTES = 60;

const getEndTime = (startTime, totalDuration) => {
  const startTimeInSeconds = startTime && convertLocalTimeToSeconds(startTime);
  const durationInSeconds = totalDuration && convertLocalTimeToSeconds(totalDuration);
  return startTimeInSeconds && durationInSeconds && convertSecondsToLocalTime(startTimeInSeconds + durationInSeconds);
};

export const EstimationWorkView = ({
  dense,
  work,
  estimatorId,
  currentSurveyValue,
  handleOnWorkClick,
  handleCloseModal,
  selectedWork,
}) => {
  const {
    status,
    totalDuration = '',
    serviceType,
    startTime = '',
    orderAddress = '',
    orderFirstName,
    orderLastName,
    orderNumber,
    orderId,
    orderServiceType,
    uuid,
  } = work;
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const surveyStatusColor = (surveyStatus) =>
    (surveyStatusesById[surveyStatus] || {}).color || `${theme.palette.common.black}`;

  const handleExpanded = () => {
    if (dense) {
      setIsExpanded((d) => !d);
    }
  };

  const toAddress = orderAddress.split(',').slice(0, 3).join(',');

  const endTime = getEndTime(startTime, totalDuration);
  const convertStartTimeToMinutes = convertLocalTimeToMinutes(startTime);

  const openOrderWindow = () => {
    if (orderId === currentSurveyValue.orderId) {
      handleCloseModal();
    } else {
      openOrder(orderId)(dispatch);
    }
  };

  const handleWorkClick = () => {
    handleExpanded();
    handleOnWorkClick(uuid);
    selectedWork === uuid && openOrderWindow();
  };

  return (
    <>
      <Tooltip
        title={
          <>
            <EstimationTooltip
              key={uuid}
              work={work}
              toAddress={toAddress}
              endTime={endTime}
              estimatorId={estimatorId}
            />
          </>
        }
        placement="top"
      >
        <StyledBox
          $dense={dense}
          key={uuid}
          $color={surveyStatusColor(status)}
          $currentWork={orderId === currentSurveyValue.orderId && uuid === currentSurveyValue.uuid}
          $numHours={24}
          onClick={handleWorkClick}
          $selectedWork={uuid !== currentSurveyValue.uuid && selectedWork === uuid}
          $selectedCurrentWork={
            orderId === currentSurveyValue.orderId && uuid === currentSurveyValue.uuid && selectedWork === uuid
          }
          $expanded={isExpanded}
          $durationMin={
            convertDurationToMinutes(totalDuration) + convertStartTimeToMinutes >
            TWENTY_FOUR_HOURS * ONE_HOUR_IN_MINUTES
              ? TWENTY_FOUR_HOURS * ONE_HOUR_IN_MINUTES - convertStartTimeToMinutes
              : convertDurationToMinutes(totalDuration)
          }
          $startTime={convertStartTimeToMinutes}
        >
          <ServiceType $color={serviceType.rgbColor} />
          <Box component="span" position="absolute" left="2px" top={0} color={theme.palette.common.white}>
            {orderServiceType === SurveyType.VIRTUAL_ESTIMATE ? (
              <VideocamIcon style={iconSize} />
            ) : (
              <HomeIcon style={iconSize} />
            )}
          </Box>
          <DescriptionRow $dense={dense && !isExpanded}>
            <Description $isBold>{`# ${orderNumber}`}</Description>
            <Description style={{ textTransform: 'capitalize' }}>
              <PersonOutline style={iconSize} />
              &nbsp;
              {`${orderFirstName} ${orderLastName}`}
            </Description>
          </DescriptionRow>
          <DescriptionRow $dense={dense && !isExpanded}>
            <Description>
              <AccessTime style={iconSize} />
              &nbsp;
              {`${formatTime(startTime)}${endTime ? `- ${formatTime(endTime)}` : ''}`}
            </Description>
          </DescriptionRow>
          <DescriptionRow $dense={dense && !isExpanded}>
            <Description>
              <ArrowForward style={iconSize} />
              &nbsp;
              {toAddress}
            </Description>
          </DescriptionRow>
        </StyledBox>
      </Tooltip>
    </>
  );
};

EstimationWorkView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  work: pt.object.isRequired,
  dense: pt.bool,
  estimatorId: pt.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSurveyValue: pt.object.isRequired,
  handleOnWorkClick: pt.func,
  handleCloseModal: pt.func,
  selectedWork: pt.string,
};

EstimationWorkView.defaultProps = {
  dense: false,
  handleOnWorkClick: null,
  handleCloseModal: null,
  selectedWork: null,
};
