import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  border: 1px solid #d7d7da;
  & * {
    box-sizing: border-box;
  }
`;

export default Container;
