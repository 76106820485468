import React, { ChangeEvent, useEffect, useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import { BodyText, Link, Order, OverridableValue, numberToCurrency } from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import RateChange from 'admin/components/OrderWindow/modals/RateChange';
import { OrderWindowModal } from 'common-types';

const resetDecimalManual = (property: OverridableValue) => {
  return property?.setManual(property?.manual ? +property?.manual || 0 : null);
};

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

const laborRateProperty = 'laborRateOverride';
const travelRateProperty = 'travelRateOverride';

const closingLaborRate = 'laborRate';
const closingTravelRate = 'travelRate';

const useStyles = makeStyles(() => ({
  bloomingDaleDiscount: {
    textDecorationLine: 'line-through',
  },
}));

export const HourlyRateRow = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const settings = useOrderWindowSettings();
  const getServicePropertyName = useGetServicePropertyName();
  const { serviceIndex } = useOrderServiceIndex();
  const { closingTitle, isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const name = getServicePropertyName(laborRateProperty, closingLaborRate);
  const quoteLaborRateOverride = order.getIn(name.split('.')) as OverridableValue;
  const discountLaborRate = order.beforeDiscountServiceQuotes.get(serviceIndex)?.[laborRateProperty].value() ?? 0;

  const [laborRateOverride, setLaborRateOverride] = useState<OverridableValue>(quoteLaborRateOverride);

  useEffect(() => {
    setLaborRateOverride(quoteLaborRateOverride);
  }, [quoteLaborRateOverride]);

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });

        if (isTravelRateTheSameAsLaborRate) {
          onChange({
            name: getServicePropertyName(travelRateProperty, closingTravelRate),
            value,
          });
        }
      }
    };

  const handleOnMoveDetailsSave = (data: [string, any][]) => {
    const result = data;

    if (isTravelRateTheSameAsLaborRate && result[0]?.[0]?.includes(closingLaborRate)) {
      result.push([getServicePropertyName(travelRateProperty, closingTravelRate), data[0][1]]);
    }

    result.forEach(([name, value]) => onChange({ name, value }));
    setOpenModal(null);
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Link disabled={isCompleted || isLockSales} onClick={handleOpenModal(OrderWindowModal.LABOR_RATE)}>
          {!isTravelRateTheSameAsLaborRate ? 'Labor Hourly Rate' : 'Hourly Rate'}
        </Link>
        {openModal === OrderWindowModal.LABOR_RATE && (
          <RateChange
            name={name}
            title={`${!isTravelRateTheSameAsLaborRate ? 'Edit Labor Rate' : 'Edit Hourly Rate'}${closingTitle}`}
            onSave={handleOnMoveDetailsSave}
            onCancel={handleOpenModal(null)}
            open
            rate={laborRateOverride}
            discountRate={discountLaborRate}
          />
        )}
      </TableCell>
      <ValueTableCell>
        <Box width={140} display="flex" alignItems="center">
          <FlexWithNegativeMargin $manual={!!laborRateOverride.manual}>
            <CurrencyInput
              disabled={!order?.disableAutoCalculation || isCompleted || isLockSales}
              fullWidth={false}
              onChange={(value: string) =>
                setLaborRateOverride((tr) => tr.setManual(value === '' ? null : (value as any)).setExpiration())
              }
              value={laborRateOverride.value()}
              name={name}
              onBlur={handleOnBlur(resetDecimalManual(laborRateOverride))}
            />
          </FlexWithNegativeMargin>
          {!!discountLaborRate && !isClosing && (
            <BodyText color="textSecondary" className={classes.bloomingDaleDiscount}>
              {numberToCurrency(discountLaborRate)}
            </BodyText>
          )}
        </Box>
      </ValueTableCell>
    </TableRow>
  );
};
