import React, { forwardRef, useState } from 'react';

import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AccessTime from '@material-ui/icons/AccessTime';
import ArrowForward from '@material-ui/icons/ArrowForward';
import GroupIcon from '@material-ui/icons/Group';
import PersonOutline from '@material-ui/icons/PersonOutline';
import { format, isSameHour, isSameMinute, isValid } from 'date-fns';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { openOrder } from 'admin/autodux/WindowsAutodux';
import JobEntity from 'admin/components/OrderWindow/SchedulerBox/Job';
import { DeliveryTruckColor } from 'admin/components/OrderWindow/SchedulerBox/common/ScheduleBoxConstants';
import { statusColor } from 'admin/constants/OrderStatus';
import { hexToRgb, textColorForBackground } from 'admin/utils/colorUtils';

const Job = styled.div<{
  $selected: boolean;
  $isUnassignedTruck?: boolean;
  dense?: boolean;
  expanded?: boolean;
  hasSelectedJob?: boolean;
  level?: number;
  numHours?: number;
  startTime?: number;
  durationMin?: number;
  color: string;
  selectedJobLevel?: number;
}>`
  ${({ $selected, color }) => ($selected ? `border: 2px dashed ${textColorForBackground(hexToRgb(color))};` : '')}
  margin: ${({ dense = false }) => (dense ? 2 : 3)}px 0;
  height: ${({ expanded = false, dense = false }) => (dense && !expanded ? 20 : 51)}px;
  top: ${({ hasSelectedJob = false, dense = false, level = 0, selectedJobLevel = 0 }) =>
    hasSelectedJob && level < selectedJobLevel ? (dense ? 24 : 56) * (level + 1) : (dense ? 24 : 56) * level}px;
  top: ${({ $selected, $isUnassignedTruck, level = 0 }) => (($selected && level !== 0) || !$isUnassignedTruck) && 0}px;
  left: ${({ numHours = 16, startTime = 0 }) => `calc((100% / ${numHours} / 60) * ${startTime})`};
  position: absolute;
  min-width: 17px;
  width: ${({ numHours = 16, durationMin = 15 }) => `calc((100% / ${numHours} / 60) * ${durationMin})`};
  background-color ${({ color }) => color};
  color: ${({ color, theme }) => (color === DeliveryTruckColor ? theme.palette.text.primary : 'white')};
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'row' : 'column')};
  justify-content: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'flex-start' : 'center')};
  align-items: ${({ expanded = false, dense = false }) => (dense && !expanded ? 'center' : 'flex-start')};
  padding: 0 8px 0 24px;
  ${({ dense = false, expanded = false }) => (dense || expanded ? 'cursor: pointer;' : '')}
  ${({ expanded = false }) => (expanded ? 'z-index: 1300;' : '')}
`;

const DescriptionRow = styled(Typography)<{ dense?: boolean; $isBold?: boolean }>`
  && {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-style: normal;
    font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
    font-size: 16px;
    line-height: 16px;
    color: inherit;
    margin-right: 4px;
    ${({ dense = false }) => (dense ? `&::after { content: ' | '; }` : '')}
  }
`;

const Description = styled.span<{ $isBold?: boolean }>`
  font-weight: ${({ $isBold = false }) => ($isBold ? 700 : 400)};
  &:not(:last-child) {
    &:after {
      content: ' | ';
    }
  }
`;

const ServiceType = styled.span<{ $color?: string }>`
  position: absolute;
  width: 16px;
  top: 0;
  bottom: 0;
  left: 0;
  background-color ${({ $color = 'transparent' }) => $color};
`;

const formatTime = (time: Date) => format(time, 'h:mm a');

const iconSize = { fontSize: '0.75rem' };

const renderStartTime = (earliest: Date, latest: Date) => {
  if (!isValid(latest)) {
    return formatTime(earliest);
  }
  if (!isSameHour(earliest, latest) || !isSameMinute(earliest, latest)) {
    return `${formatTime(earliest)} - ${formatTime(latest)}`;
  }
  return formatTime(earliest);
};
interface JobComponentProps {
  job: JobEntity;
  truckId: number;
  dense: boolean;
  orderId: number;
  hasSelectedJob?: boolean;
  selectedJobLevel?: number;
  isUnassignedTruck?: boolean;
}

const JobComponent = forwardRef<JobComponentProps, JobComponentProps>((props, ref) => {
  const { job, truckId, dense, orderId, hasSelectedJob, selectedJobLevel, isUnassignedTruck } = props;
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const handleJobClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.detail === 1 && dense) setExpanded((d) => !d);

    if (event.detail === 2) {
      dispatch(openOrder(job.orderId));
    }
  };

  const customerName = `${job.contactFirstName} ${job.contactLastName}`;
  const color = job.deliveryJob ? DeliveryTruckColor : statusColor(job.orderStatus);

  return (
    <Job
      {...props}
      // @ts-ignore
      ref={ref}
      dense={dense}
      level={job.level[truckId]}
      key={job.uuid}
      color={color}
      $selected={job.orderId === orderId}
      $isUnassignedTruck={isUnassignedTruck}
      numHours={24}
      onClick={handleJobClick}
      expanded={expanded}
      durationMin={job.getJobDurationMinutes()}
      startTime={job.getStartTimeMinutes()}
      hasSelectedJob={hasSelectedJob}
      selectedJobLevel={selectedJobLevel}
    >
      <Box ml={job.deliveryJob && !job.deliveryJobStart && !job.deliveryJobEnd ? 7 * 8 : 0}>
        <ServiceType $color={job.color} />
        <DescriptionRow dense={dense && !expanded}>
          <Description $isBold data-testid={`identifier-${job.orderNumber}`}>{`#${job.orderNumber}`}</Description>
          <Description>
            <PersonOutline style={iconSize} />
            &nbsp;
            {customerName}
          </Description>
          <Description>{job.contactPhone}</Description>
        </DescriptionRow>
        <DescriptionRow dense={dense && !expanded}>
          <Description>
            <GroupIcon style={iconSize} />
            &nbsp;
            {job.numberOfMovers}
          </Description>
          {job.deliveryJob ? (
            <>
              <Description>Delivery {job.customerName}</Description>
              {!!job.deliveryTimeEarliest && (
                <Description>
                  <AccessTime style={iconSize} />
                  &nbsp;
                  {renderStartTime(job.deliveryTimeEarliest as Date, job.deliveryTimeEarliest as Date)}
                </Description>
              )}
              <Description>{job.sizeDescription}</Description>
              {!!job.arrivalTimeEarliest && (
                <Description>
                  <AccessTime style={iconSize} />
                  &nbsp;
                  {renderStartTime(job.arrivalTimeEarliest as Date, job.arrivalTimeLatest as Date)}
                </Description>
              )}
            </>
          ) : (
            <>
              {!!job.startTimeEarliest && (
                <Description>
                  <AccessTime style={iconSize} />
                  &nbsp;
                  {renderStartTime(job.startTimeEarliest as Date, job.startTimeLatest as Date)}
                </Description>
              )}
              <Description>{job.sizeDescription}</Description>
            </>
          )}
        </DescriptionRow>
        <DescriptionRow dense={dense && !expanded}>
          {job.fromAddress}
          &nbsp;
          <ArrowForward style={iconSize} />
          &nbsp;
          {job.toAddress}
        </DescriptionRow>
      </Box>
    </Job>
  );
});

export default JobComponent;
