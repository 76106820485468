import autodux from 'autodux';
import { List, Map, set } from 'immutable';

import userApi from 'admin/api/UserAPI';
import { AccessGroupSortingOrder } from 'admin/constants/AccessGroupName';

const {
  reducer,
  actions: {
    setList,
    setManagerList,
    setIsFetching,
    addUser,
    updateUserInList,
    setIsFetchingPermissions,
    setUserPermissions,
  },
  selectors: { getList, getManagerList, getIsFetching, getIsFetchingPermissions, getUserPermissions },
} = autodux({
  slice: 'departmentUsers',
  initial: {
    list: List(),
    managerList: List(),
    isFetching: false,
    isFetchingPermissions: false,
    userPermissions: Map(),
  },
  actions: {
    updateUserInList: (state, payload) =>
      set(
        state,
        'list',
        state.list.map((item) => (item.id === payload.id ? payload : item)),
      ),
    addUser: (state, payload) => set(state, 'list', state.list.push(payload)),
    setUserPermissions: (state, { name, response }) =>
      set(state, 'userPermissions', state.userPermissions.set(name, response)),
  },
});

export default reducer;

const makeFullName = (it) => ({ ...it, fullName: `${it?.firstName} ${it?.lastName}` });

const fetchUsers = () => (dispatch) => {
  dispatch(setIsFetching(true));

  return userApi
    .listAllUsersAPI()
    .catch(() => {})
    .then((response) => {
      dispatch(setIsFetching(false));

      if (response) {
        dispatch(setList(List(response.pageElements.map(makeFullName))));
      }
    });
};

const fetchManagers = () => (dispatch) => {
  dispatch(setIsFetching(true));

  return userApi
    .listAllManagersAPI()
    .then((response) => {
      dispatch(setIsFetching(false));
      if (response) {
        dispatch(setManagerList(List(response)));
      }
    })
    .catch(() => {});
};

const createUser = (user) => (dispatch) => {
  dispatch(setIsFetching(true));

  return userApi
    .createUserAPI(user)
    .catch(() => {})
    .then((response) => {
      dispatch(setIsFetching(false));

      if (response) {
        dispatch(addUser(makeFullName(response)));
      }

      return response;
    });
};

const updateUser = (user) => (dispatch) => {
  dispatch(setIsFetching(true));

  return userApi
    .updateUserAPI(user)
    .catch(() => {})
    .then((response) => {
      dispatch(setIsFetching(false));

      if (response) {
        dispatch(updateUserInList(makeFullName(response)));
      }

      return response;
    });
};

const fetchPermissions = (tenant, name) => (dispatch) => {
  dispatch(setIsFetchingPermissions(true));

  return userApi.listElromcoPermissions(name).then((response) => {
    dispatch(setIsFetchingPermissions(false));
    dispatch(setUserPermissions({ name, response }));
  });
};

const fetchAllPermissions = () => (dispatch) => {
  dispatch(setIsFetchingPermissions(true));

  return Promise.all(AccessGroupSortingOrder.map((name) => userApi.listElromcoPermissions(name)))
    .catch(() => {})
    .then((response) => {
      if (response) {
        AccessGroupSortingOrder.forEach((name, index) => {
          dispatch(setUserPermissions({ name, response: response[index] || [] }));
        });
      }

      dispatch(setIsFetchingPermissions(false));
    });
};

const getBasicEmployee = getList;

export {
  getList,
  getBasicEmployee,
  getManagerList,
  getIsFetching,
  getIsFetchingPermissions,
  getUserPermissions,
  fetchUsers,
  fetchManagers,
  fetchPermissions,
  fetchAllPermissions,
  createUser,
  updateUser,
  setManagerList,
};
