import { PaymentActivityDto } from '@elromcoinc/react-shared';

export const makePaymentDescription = (dto: PaymentActivityDto) => {
  switch (dto.activityType) {
    case 'PaymentCash':
      return 'Cash';
    case 'PaymentCheck':
      return 'Check';
    case 'PaymentCreditCardRecord':
      return 'Credit Card Record';
    case 'OnlinePaymentTransaction':
      return 'Credit Card Payment';
    case 'PaymentCustom':
      return 'Custom Payment';
    default:
      return 'Payment';
  }
};
