import { FC, RefObject, useEffect, useRef } from 'react';

import { Address } from '@elromcoinc/react-shared';

import { useGoogleApiContext } from 'admin/components/OrderWindow/context';
import { CITY, POSTAL_CODE, STATE, STREET } from 'admin/components/OrderWindow/modals/FullAddressesModal';

type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;
type Autocomplete = google.maps.places.Autocomplete;

interface GoogleAutoCompleteProps {
  country?: 'USA' | 'Canada';
  renderInput: (props: RefObject<HTMLInputElement>) => JSX.Element;
  onChangeAddress: (address: Partial<Address>) => void;
}

// ISO 3166-1 alpha-2
const CountryTwoLetterCode = {
  USA: 'US',
  Canada: 'CA',
};

const GOOGLE_STREET_NUMBER = 'street_number';
const GOOGLE_ROUTE = 'route';
const GOOGLE_POSTAL_CODE = 'postal_code';
const GOOGLE_ADMINISTRATIVE_AREA = 'administrative_area_level_1';
const GOOGLE_ADMINISTRATIVE_SUBAREA = 'administrative_area_level_2';
const GOOGLE_LOCALITY = 'locality';

const GoogleAutoComplete: FC<GoogleAutoCompleteProps> = ({ country = 'USA', renderInput, onChangeAddress }) => {
  const { google, loadedGoogle } = useGoogleApiContext();
  const autoCompleteRef = useRef<Autocomplete | null>(null);
  const streetAddressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (loadedGoogle && streetAddressRef.current && 'google' in window && google) {
      const autoComplete = new google.maps.places.Autocomplete(streetAddressRef.current, {
        types: ['address'],
        componentRestrictions: { country: CountryTwoLetterCode.USA },
      });

      autoComplete.addListener('place_changed', function fillInAddress() {
        let place = autoComplete.getPlace();
        const addressComponents = (place.address_components as GeocoderAddressComponent[]) || [];
        const streetNumber =
          addressComponents.find((field) => field?.types?.[0] === GOOGLE_STREET_NUMBER)?.long_name ?? '';
        const route = addressComponents.find((field) => field?.types?.[0] === GOOGLE_ROUTE)?.long_name ?? '';
        const localityFound = !!addressComponents.find((field) => field?.types?.[0] === GOOGLE_LOCALITY);

        const address = addressComponents.reduce(
          (accumulator, field) => {
            switch (field?.types?.[0]) {
              case GOOGLE_LOCALITY:
                accumulator[CITY] = field.short_name;
                break;
              case GOOGLE_ADMINISTRATIVE_AREA:
                accumulator[STATE] = field.short_name || field.long_name;
                break;
              case GOOGLE_ADMINISTRATIVE_SUBAREA:
                if (!localityFound) {
                  accumulator[CITY] = field.short_name;
                }

                break;
              case GOOGLE_POSTAL_CODE:
                accumulator[POSTAL_CODE] = field.short_name;
                break;
              default:
                break;
            }
            return accumulator;
          },
          {
            [STREET]: `${streetNumber} ${route}`,
          } as any,
        );

        onChangeAddress(address);
        autoCompleteRef.current = autoComplete;
      });
    }

    return () => {
      if (autoCompleteRef.current) {
        autoCompleteRef.current = null;
      }
    };
  }, [loadedGoogle, streetAddressRef.current, country]);

  useEffect(() => {
    if (autoCompleteRef.current && country) {
      autoCompleteRef.current.setComponentRestrictions({
        country: CountryTwoLetterCode[country],
      });
    }
  }, [autoCompleteRef.current, country]);

  return renderInput(streetAddressRef);
};

export default GoogleAutoComplete;
