enum AutomationRuleName {
  NONE = 'NONE',
  WHEN_A_NEW_CUSTOMER_IS_CREATED = 'WHEN_A_NEW_CUSTOMER_IS_CREATED',
  WHEN_A_NEW_CUSTOMER_IS_CREATED_PARSING = 'WHEN_A_NEW_CUSTOMER_IS_CREATED_PARSING',
  WHEN_A_NEW_CUSTOMER_IS_CREATED_WEBSITE = 'WHEN_A_NEW_CUSTOMER_IS_CREATED_WEBSITE',
  WHEN_A_NEW_CUSTOMER_IS_CREATED_MOVEBOARD = 'WHEN_A_NEW_CUSTOMER_IS_CREATED_MOVEBOARD',
  WHEN_EXISTING_CUSTOMER_SUBMIT_A_QUOTE = 'WHEN_EXISTING_CUSTOMER_SUBMIT_A_QUOTE',
  WHEN_A_NEW_LOCAL_MOVE_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LOCAL_MOVE_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LOCAL_MOVE_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LOCAL_MOVE_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LOCAL_MOVE_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_LOCAL_MOVE_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_LOCAL_MOVE_LABOR_ONLY_LEAD_IS_CREATED = 'WHEN_A_NEW_LOCAL_MOVE_LABOR_ONLY_LEAD_IS_CREATED',
  WHEN_A_NEW_LOCAL_MOVE_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_LOCAL_MOVE_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTRASTATE_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTRASTATE_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTRASTATE_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTRASTATE_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTRASTATE_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTRASTATE_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTRASTATE_VOLUME_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTRASTATE_VOLUME_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTRASTATE_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTRASTATE_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTERSTATE_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTERSTATE_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTERSTATE_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTERSTATE_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTERSTATE_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTERSTATE_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTERSTATE_VOLUME_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTERSTATE_VOLUME_LEAD_IS_CREATED',
  WHEN_A_NEW_LD_INTERSTATE_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_LD_INTERSTATE_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_MOVING_AND_STORAGE_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_MOVING_AND_STORAGE_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_MOVING_AND_STORAGE_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_MOVING_AND_STORAGE_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_MOVING_AND_STORAGE_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_MOVING_AND_STORAGE_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_MOVING_AND_STORAGE_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_MOVING_AND_STORAGE_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_LOADING_HELP_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LOADING_HELP_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LOADING_HELP_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_LOADING_HELP_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_LOADING_HELP_LABOR_ONLY_LEAD_IS_CREATED = 'WHEN_A_NEW_LOADING_HELP_LABOR_ONLY_LEAD_IS_CREATED',
  WHEN_A_NEW_LOADING_HELP_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_LOADING_HELP_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_LOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_LOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_UNLOADING_HELP_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_UNLOADING_HELP_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_UNLOADING_HELP_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_UNLOADING_HELP_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_UNLOADING_HELP_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_UNLOADING_HELP_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_UNLOADING_HELP_LABOR_ONLY_LEAD_IS_CREATED = 'WHEN_A_NEW_UNLOADING_HELP_LABOR_ONLY_LEAD_IS_CREATED',
  WHEN_A_NEW_UNLOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_UNLOADING_HELP_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_PACKING_DAY_HOURLY_LEAD_IS_CREATED = 'WHEN_A_NEW_PACKING_DAY_HOURLY_LEAD_IS_CREATED',
  WHEN_A_NEW_PACKING_DAY_BY_BOX_LEAD_IS_CREATED = 'WHEN_A_NEW_PACKING_DAY_BY_BOX_LEAD_IS_CREATED',
  WHEN_A_NEW_OVERNIGHT_NON_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_OVERNIGHT_NON_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_OVERNIGHT_BINDING_LEAD_IS_CREATED = 'WHEN_A_NEW_OVERNIGHT_BINDING_LEAD_IS_CREATED',
  WHEN_A_NEW_OVERNIGHT_FLAT_RATE_LEAD_IS_CREATED = 'WHEN_A_NEW_OVERNIGHT_FLAT_RATE_LEAD_IS_CREATED',
  WHEN_A_NEW_OVERNIGHT_QUOTE_OFF_LEAD_IS_CREATED = 'WHEN_A_NEW_OVERNIGHT_QUOTE_OFF_LEAD_IS_CREATED',
  WHEN_A_NEW_JUNK_REMOVAL_BY_TRUCK_LEAD_IS_CREATED = 'WHEN_A_NEW_JUNK_REMOVAL_BY_TRUCK_LEAD_IS_CREATED',
  WHEN_A_NEW_JUNK_REMOVAL_BY_ITEM_LEAD_IS_CREATED = 'WHEN_A_NEW_JUNK_REMOVAL_BY_ITEM_LEAD_IS_CREATED',
  ORDER_REMINDER_NO_ACTIVITY_STATUS_NEW = 'ORDER_REMINDER_NO_ACTIVITY_STATUS_NEW',
  ORDER_REMINDER_NO_ACTIVITY_STATUS_FOLLOW_UP = 'ORDER_REMINDER_NO_ACTIVITY_STATUS_FOLLOW_UP',
  ORDER_REMINDER_NO_ACTIVITY_STATUS_CAN_BOOK_ONLINE = 'ORDER_REMINDER_NO_ACTIVITY_STATUS_CAN_BOOK_ONLINE',
  ORDER_RESERVATION_REMINDER = 'ORDER_RESERVATION_REMINDER',
  ORDER_REMINDER_BOOKED_1_DAY_BEFORE_MOVE = 'ORDER_REMINDER_BOOKED_1_DAY_BEFORE_MOVE',
  ORDER_REMINDER_BOOKED_5_DAYS_BEFORE_MOVE = 'ORDER_REMINDER_BOOKED_5_DAYS_BEFORE_MOVE',
  ORDER_REMINDER_COMPLETED_AFTER_MOVE = 'ORDER_REMINDER_COMPLETED_AFTER_MOVE',
  ORDER_INVOICE_REMINDER = 'ORDER_INVOICE_REMINDER',
  INVOICE_REMINDER = 'INVOICE_REMINDER',
  INVOICE_OVERDUE_REMINDER = 'INVOICE_OVERDUE_REMINDER',
  ORDER_REMINDER_INHOME_SURVEY_1_DAY_BEFORE_ESTIMATE = 'ORDER_REMINDER_INHOME_SURVEY_1_DAY_BEFORE_ESTIMATE',
  ORDER_REMINDER_VIRTUAL_SURVEY_1_DAY_BEFORE_ESTIMATE = 'ORDER_REMINDER_VIRTUAL_SURVEY_1_DAY_BEFORE_ESTIMATE',
  WHEN_AN_IN_HOME_ESTIMATE_STATUS_CHANGED_TO_SCHEDULED = 'WHEN_AN_IN_HOME_ESTIMATE_STATUS_CHANGED_TO_SCHEDULED',
  WHEN_AN_VIRTUAL_SURVEY_STATUS_CHANGED_TO_SCHEDULED = 'WHEN_AN_VIRTUAL_SURVEY_STATUS_CHANGED_TO_SCHEDULED',
  WHEN_INHOME_SURVEY_IS_BOOKED = 'WHEN_INHOME_SURVEY_IS_BOOKED',
  WHEN_VIRTUAL_SURVEY_IS_BOOKED = 'WHEN_VIRTUAL_SURVEY_IS_BOOKED',
  WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_JUNK_REMOVAL_BY_TRUCK_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_JUNK_REMOVAL_BY_TRUCK_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_JUNK_REMOVAL_BY_ITEM_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_JUNK_REMOVAL_BY_ITEM_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_PACKING_DAY_HOURLY_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_PACKING_DAY_HOURLY_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_PACKING_DAY_BY_BOX_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_PACKING_DAY_BY_BOX_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_JUNK_REMOVAL_BY_TRUCK_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_JUNK_REMOVAL_BY_TRUCK_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_JUNK_REMOVAL_BY_ITEM_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_JUNK_REMOVAL_BY_ITEM_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_PACKING_DAY_HOURLY_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_PACKING_DAY_HOURLY_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_PACKING_DAY_BY_BOX_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_PACKING_DAY_BY_BOX_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_ORDER_STATUS_CHANGES_TO_EXPIRED = 'WHEN_ORDER_STATUS_CHANGES_TO_EXPIRED',
  WHEN_ORDER_STATUS_CHANGES_TO_CANCELED = 'WHEN_ORDER_STATUS_CHANGES_TO_CANCELED',
  WHEN_ORDER_STATUS_CHANGES_TO_NOT_AVAILABLE = 'WHEN_ORDER_STATUS_CHANGES_TO_NOT_AVAILABLE',
  WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_PAST = 'WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_PAST',
  WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_INFO = 'WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_INFO',
  WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_TRUCK = 'WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_TRUCK',
  WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_PAST_TO_CAN_BOOK_ONLINE = 'WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_PAST_TO_CAN_BOOK_ONLINE',
  WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_INFO_TO_CAN_BOOK_ONLINE = 'WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_INFO_TO_CAN_BOOK_ONLINE',
  WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_TRUCK_TO_CAN_BOOK_ONLINE = 'WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_TRUCK_TO_CAN_BOOK_ONLINE',
  WHEN_A_CUSTOMER_SENDS_A_MESSAGE = 'WHEN_A_CUSTOMER_SENDS_A_MESSAGE',
  WHEN_EMPLOYEE_SENDS_A_MESSAGE = 'WHEN_EMPLOYEE_SENDS_A_MESSAGE',
  WHEN_EMPLOYEE_UPLOADS_FILE = 'WHEN_EMPLOYEE_UPLOADS_FILE',
  WHEN_A_NEW_CORPORATE_ACCOUNT_IS_CREATED = 'WHEN_A_NEW_CORPORATE_ACCOUNT_IS_CREATED',
  WHEN_A_NEW_AFFILIATE_ACCOUNT_IS_CREATED = 'WHEN_A_NEW_AFFILIATE_ACCOUNT_IS_CREATED',
  WHEN_AN_ORDER_ATTACHES_TO_ACCOUNT = 'WHEN_AN_ORDER_ATTACHES_TO_ACCOUNT',
  WHEN_A_NEW_LEAD_IS_CREATED_BY_AN_AFFILIATE_ACCOUNT = 'WHEN_A_NEW_LEAD_IS_CREATED_BY_AN_AFFILIATE_ACCOUNT',
  WHEN_A_NEW_LEAD_IS_CREATED_BY_A_CORPORATE_ACCOUNT = 'WHEN_A_NEW_LEAD_IS_CREATED_BY_A_CORPORATE_ACCOUNT',
  WHEN_THE_PAYMENT_IS_COMPLETED = 'WHEN_THE_PAYMENT_IS_COMPLETED',
  WHEN_ORDER_STATUS_CHANGES_TO_RESERVED = 'WHEN_ORDER_STATUS_CHANGES_TO_RESERVED',
  WHEN_A_EMPLOYEE_CREATES_AN_INVOICE = 'WHEN_A_EMPLOYEE_CREATES_AN_INVOICE',
  WHEN_A_CUSTOMER_PAYS_AN_INVOICE = 'WHEN_A_CUSTOMER_PAYS_AN_INVOICE',
  WHEN_A_EMPLOYEE_TAKES_PAYMENT_ON_AN_INVOICE = 'WHEN_A_EMPLOYEE_TAKES_PAYMENT_ON_AN_INVOICE',
  WHEN_EMPLOYEE_WAS_ASSIGNED_TO_JOB = 'WHEN_EMPLOYEE_WAS_ASSIGNED_TO_JOB',
  WHEN_CREW_CREATED = 'WHEN_CREW_CREATED',
  WHEN_CREW_UPDATED = 'WHEN_CREW_UPDATED',
  WHEN_CREW_DELETED = 'WHEN_CREW_DELETED',
}

export default AutomationRuleName;
