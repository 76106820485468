import React, { FC } from 'react';

import { Message, MessageElement, MessageStatusTypes } from '@elromcoinc/react-shared';
import { Managers } from '@elromcoinc/react-shared/dist/types/Managers';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import communicationAPI from 'admin/api/CommunicationsAPI';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useOrderState } from 'admin/components/OrderWindow/context';

interface MessageActivityLogProps {
  message: Message;
}

export const MessageActivityLog: FC<MessageActivityLogProps> = ({ message }) => {
  const { order } = useOrderState();
  const toPhoneNumber = order?.contactInfo?.primaryPhone?.number;
  const { reload } = useActivityLogContext();
  const { enqueueSnackbar } = useSnackbar();
  const managersData = (useSelector(getManagerList) as List<Managers>).toJS();

  const onForwardTo = (id: number, managers: Managers[]) => {
    return communicationAPI
      .forwardMessageTo(
        id,
        managers.map(({ id: managerId }) => managerId),
      )
      .then(() => {
        enqueueSnackbar('You have pinged', { variant: 'success' });
        reload();
      })
      .catch(() => {
        enqueueSnackbar('Failed to ping. Try again', { variant: 'error' });
        return Promise.reject();
      });
  };

  const onEditMessage = (id: number, text: string) => {
    return communicationAPI
      .updateMessage(id, text)
      .then(reload)
      .catch(() => {
        enqueueSnackbar('Failed to edit. Try again', { variant: 'error' });
        return Promise.reject();
      });
  };

  const onResend = (message: Message) => {
    const activitySource = message?.activitySources?.[0];

    return communicationAPI
      .sendSms({
        sourceDescriptor: activitySource as any,
        body: message.text,
        toPhone: toPhoneNumber,
      })
      .then(reload)
      .catch(() => {
        enqueueSnackbar('SMS was not sent. Try again', { variant: 'error' });
      });
  };

  const toggleRead = (message: Message) => {
    const newStatus = message.status === MessageStatusTypes.SEEN ? MessageStatusTypes.UNSEEN : MessageStatusTypes.SEEN;

    return communicationAPI
      .toggleMessageStatus(message.id!, newStatus)
      .then(reload)
      .catch(() => {
        enqueueSnackbar(`Can't make message read/unread . Try again`, { variant: 'error' });
      });
  };

  return (
    <MessageElement
      item={message}
      managers={managersData}
      onEditMessage={onEditMessage}
      onForwardTo={onForwardTo}
      onResend={onResend}
      toggleRead={toggleRead}
      mode="admin"
    />
  );
};
