import React, { FC, MouseEventHandler, useState } from 'react';
import useNotePermissions from 'admin/hooks/useNotePermissions';
import { useMobile } from 'admin/hooks/useMobile';
import { format, parseISO } from 'date-fns';
import { Box, ListItem, ListItemText, makeStyles, Popover } from '@material-ui/core';
import { ActivitySourceType, BodyText, IconButton, Link } from '@elromcoinc/react-shared';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

interface NoteType {
  id: number;
  activitySource: ActivitySourceType;
  sourceId: number;
  activitySources: { activitySource: ActivitySourceType; referencedEntityId: number }[];
  deleted: boolean;
  dateCreated: string;
  dateUpdated: string;
  createdByName: string;
  lastUpdatedByName: string;
  involvesForeman: boolean;
  involvesCustomer: boolean;
  involvesDispatch: boolean;
  involvesSales: boolean;
  content: string;
  activityType: string;
}
interface NoteItemProps {
  item: NoteType;
  handleOpenCurrentNote: (item: NoteType) => MouseEventHandler<HTMLButtonElement> | undefined;
  noteType: string;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: '4px',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  popover: {
    minWidth: '27rem',
    maxWidth: '27rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '19rem',
      minWidth: '19rem',
    },
  },
  icons: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  linkButton: {
    width: '100%',
  },
  noteContent: {
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '28px',
    textAlign: 'justify',
    paddingRight: '12px',
    '&::before': {
      position: 'absolute',
      display: 'block',
      content: '"..."',
      right: 0,
      bottom: 0,
    },
    '&::after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      right: 0,
      width: '1rem',
      height: '1rem',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

export const NoteItem: FC<NoteItemProps> = ({ item, handleOpenCurrentNote, noteType }) => {
  const classes = useStyles();
  const getNotePermissions = useNotePermissions();
  const [anchorElOfViewNote, setAnchorElOfViewNote] = useState<Element | null>(null);
  const isMobile = useMobile();
  const { canEdit, canDelete } = getNotePermissions(item);
  const contentLength = item.content.length >= 134;
  const mobileContentLength = isMobile && item.content.length >= 83;

  const handleOpenViewNote = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElOfViewNote(event.target as Element);
  };

  const handleCloseViewNote = () => setAnchorElOfViewNote(null);

  const description = (item: NoteType, name: string, date: string, isFull: boolean) => {
    const types = [
      item.involvesSales && 'Sales',
      item.involvesForeman && 'Foreman',
      item.involvesDispatch && 'Dispatch',
      item.involvesCustomer && 'Customer',
    ].filter(Boolean);
    const prefix = types.length === 4 ? 'Note for All' : `${types.join(', ')} Note`;
    return (
      <>
        {types.length !== 0 && isFull ? <b>{prefix}</b> : 'Note'}&nbsp;by&nbsp;{name || 'System'}&nbsp;on&nbsp;
        {format(parseISO(date), 'Pp')}
      </>
    );
  };

  return (
    <React.Fragment key={item.id}>
      <ListItem classes={{ root: classes.listItem }}>
        <ListItemText
          primary={
            <>
              <Box display="flex" justifyContent="space-between" alignItems="flex-start" width="100%">
                <Link
                  color="inherit"
                  onClick={handleOpenViewNote}
                  align="justify"
                  classes={{ root: classes.linkButton }}
                >
                  <Box className={clsx((contentLength || mobileContentLength) && classes.noteContent)}>
                    <BodyText>{item.content}</BodyText>
                  </Box>
                </Link>
                <IconButton
                  disabled={!(canEdit || canDelete)}
                  size="small"
                  onClick={handleOpenCurrentNote(item as NoteType)}
                  color="primary"
                >
                  <CreateIcon />
                </IconButton>
              </Box>
            </>
          }
          secondary={
            <>
              <Box style={{ textAlign: 'right' }} mr="24px">
                {description(item, item.createdByName, item.dateCreated, noteType === 'ALL')}
              </Box>
            </>
          }
        />
      </ListItem>
      {anchorElOfViewNote && (
        <Popover
          id="note-popover"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          open={Boolean(anchorElOfViewNote)}
          anchorEl={anchorElOfViewNote}
          classes={{ paper: classes.popover }}
          onClose={handleCloseViewNote}
        >
          <Box m={1} display="flex">
            <Box display="flex" className={classes.icons}>
              <IconButton
                disabled={!(canEdit || canDelete)}
                size="small"
                onClick={handleOpenCurrentNote(item as NoteType)}
                color="primary"
              >
                <CreateIcon fontSize="medium" />
              </IconButton>
              <IconButton color="primary" size="small" onClick={handleCloseViewNote}>
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Box>
            <Box mt={2.5} style={{ textAlign: 'justify' }}>
              <BodyText>{item.content}</BodyText>
            </Box>
          </Box>
        </Popover>
      )}
    </React.Fragment>
  );
};
