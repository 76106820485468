import React, { useMemo } from 'react';

import { AdminPermissions, BodyText, useHasPermission, useUpdateEffect } from '@elromcoinc/react-shared';
import { useTheme } from '@material-ui/core';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import settingsAPI from 'admin/api/SettingsAPI';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { getWizardRun, getWizardStep, setWizardActive, setWizardRun, setWizardStep } from 'admin/autodux/WizardAutodux';
import settingName from 'admin/constants/SettingName';
import EmployeeRecord from 'admin/entities/Employee';
import { useMobile } from 'admin/hooks/useMobile';

import { BeaconComponent } from './BeaconComponent';
import { moversCount, routes } from './WizardConstants';

const { CONFIG_WIZARD_DONE } = settingName;

const steps: Step[] = [
  {
    target: '#root',
    content:
      'Welcome to Elromco’s Moveboard software! We are excited for you to get started. We have provided you with default settings however we do have a few that we suggest you take a look at for your company. On each page you will also find a video with more information about how that page works. If you choose to skip the set up wizard, you can always re-visit it prior to completion by clicking on your name in the upper right hand corner and selecting “Setup Wizard.”',
    disableBeacon: true,
  },
  {
    target: '.rates-settings-wizard',
    content:
      'Here you will find your company’s rates for both labor and travel time. You can select or deselect the available rate types as well as the number of mover’s and trucks. Please fill out your rates for hourly moves for both labor and/or travel time if they differ from one another. Please refer to the video for any questions.',
  },
  {
    target: '.truck-settings-wizard',
    content:
      'Here you can define the number of trucks your company has available for moves, as well as decide where you would like your truck scheduler on the order window. We also have the option of an unassigned truck which you can use if you do not want to block a specific truck for a move. Please refer to the video for any questions.',
  },
  {
    target: '.company-services',
    content:
      'Here you can define the services that your company offers. You can create new services as well as adjust the already created services to fit your company’s needs. The type of calculation will allow you to select how a service is priced out based on one of the core service types. You can also choose to show a service to the customer or if it should remain only an option to be used by a Moveboard user. If you are no longer offering a service you can also deactivate it. Please refer to the video for any questions.',
  },
  {
    target: '.movers-count-setting',
    content:
      'Here you can define your company’s minimum hours, minimum movers and if you include travel time in your minimums. We have provided industry standard mover’s count settings but you can also review those if you need to adjust your calculated labor time. When making changes to these settings, you can test the changes by using your test calculator. Please refer to the video for any questions.',
  },
  {
    target: '.travel-time-setting',
    content:
      'Here you can define the way your company calculates and charges for travel time. If travel time is calculated from the company address to the origin plus the destination back to the company address (and the travel from origin to destination is included in the move time), then you want to enable the travel time charge and select “Use Actual Travel Time. ”If you are in California, you will want to select “Use Double Drive Time.” Please refer to the video for any questions or any other configurations of travel time that you may use.',
  },
  {
    target: '.quote-display-setting',
    content:
      'Here you can define when and where a customer will see the quote instantly upon submission of the forms from your website. You can specify on a service type basis if the customer will see the quote immediately on the forms after they submit a quote, as well as if the quote is visible on their customer account page. If the service type is toggled on, then the customer will instantly see the estimated quote. Please refer to the video for any questions. ',
  },
  {
    target: '.order-numbering-setting',
    content:
      'Here you can define the number that would like your orders to start at, as well as a move specific Prefix. Only numerical digits are allowed for the order number, however you can define a prefix either for ALL moves, or a specific pre-fix for each move type. Please refer to the video for any questions.',
  },
  {
    target: '.reservation-deposit-setting',
    content:
      'Here you can define how your company charges reservations/deposits for your customer to reserve a move. Reservations can be set up for hourly moves, flat rate moves, and long distance moves. You can also go thru the settings for automatic increases as the move date approaches as well as settings to charge per truck and per day. Please refer to the video for any questions.',
  },
  {
    target: '.valuation-setting',
    content:
      'Here you can set up the valuation/protection plans that your company offers. There are 3 options that can be set up thru the system: released value protection, full value protection, and a third option (which is a read only option for you to describe a plan outside of the other two). You can set up valuation based on both hourly moves as well as long distance and customize what service types they are offered for, create your descriptions so your customers better understand the meaning of valuation, and customize your plan for full value. Please refer to the video for any questions.',
  },
  {
    target: '.company-policy-setting',
    content:
      'Here you can define your company policy for your customers to view at the time of booking. Your company policy is presented to the customer when they are signing their order for service so please review our provided company policy template and adjust to match your company’s needs. Please refer to the video for any questions.',
  },
  {
    target: '.certificate-of-insurance-setting',
    content:
      'Here you can define your Certificate of Insurance settings for the customer account page. Moveboard users will always have the functionality to select the need for a COI at any given address, but this setting will control whether or not the option is presented to your customers. You can display the option to request it and also decide whether or not the customer can input the information as to where to send it. Please refer to the video for any questions.',
  },
]
  .map((it, index) => (index > 0 ? { ...it, placementBeacon: 'top' } : it))
  .map((it) => ({ ...it, content: <BodyText>{it.content}</BodyText> })) as Step[];

const ownerDepartmentId = 1;
const locale = { last: 'Done' };

const Wizard = () => {
  const isMobile = useMobile();
  const userHasPermissionToSeeSetting = useHasPermission(AdminPermissions.PERM_CAN_SEE_SETTINGS_TAB);
  const dispatch = useDispatch();
  const currentEmployee = useSelector(getCurrentEmployee) as EmployeeRecord | null;
  const run = useSelector(getWizardRun) as boolean;
  const stepIndex = useSelector(getWizardStep) as number;
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const isSupportedPath = routes.includes(location.pathname as any);
  const moversCountIndex = routes.findIndex((r) => r === moversCount);
  const defaultOptions = useMemo(
    () => ({
      arrowColor: theme.palette.common.white,
      backgroundColor: theme.palette.common.white,
      beaconSize: 36,
      overlayColor: theme.palette.action.active,
      primaryColor: theme.palette.primary.main,
      width: isMobile ? 400 : 800,
    }),
    [isMobile],
  );

  const isRun = isSupportedPath && run;

  useUpdateEffect(() => {
    if (stepIndex !== 0) {
      dispatch(setWizardRun(false));
    }
  }, [location.pathname]);

  if (!userHasPermissionToSeeSetting || currentEmployee?.departmentId !== ownerDepartmentId || !isSupportedPath) {
    return null;
  }

  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;
    const isNextAction = action === 'next';
    const isPrevAction = action === 'prev';
    const isSkip = action === 'skip';
    const isTypeStepAfter = type === 'step:after';

    if (isTypeStepAfter && (isNextAction || isPrevAction) && !(isNextAction && index === routes.length - 1)) {
      if (isNextAction) {
        dispatch(setWizardRun(false));
        history.push(routes[index + 1]);
        dispatch(setWizardStep(index + 1));
      }

      if (isPrevAction) {
        if (index - 1 > 0) {
          dispatch(setWizardRun(false));
        } else {
          dispatch(setWizardStep(0));
        }

        history.push(routes[index - 1]);
        dispatch(setWizardStep(index - 1));
      }
    } else if ((action === 'reset' || lifecycle === 'complete') && (index === routes.length - 1 || index === 0)) {
      dispatch(setWizardActive(false));
      dispatch(setWizardStep(0));
      dispatch(setWizardRun(false));

      if (index === routes.length - 1) {
        settingsAPI.updateSettingNode({ name: CONFIG_WIZARD_DONE, value: true });
      }
    } else if (isSkip) {
      dispatch(setWizardRun(false));
    }
  };

  return (
    <Joyride
      run={isRun}
      disableScrolling
      disableOverlay={stepIndex !== moversCountIndex}
      showProgress
      showSkipButton
      stepIndex={stepIndex}
      callback={handleCallback}
      continuous
      steps={steps}
      locale={locale}
      beaconComponent={BeaconComponent}
      styles={{
        options: defaultOptions,
      }}
    />
  );
};

export default Wizard;
