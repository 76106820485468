import React, { ChangeEvent, FC } from 'react';

import { BodyBigText, Checkbox, Modal } from '@elromcoinc/react-shared';
import { Box, LinearProgress } from '@material-ui/core';

import { Template } from 'common-types';

import { useComposeEmail } from './useComposeEmail';

interface TemplateModalProps {
  onClose: () => void;
  handleChooseTemplate: (template: Template) => (event: ChangeEvent<HTMLInputElement>) => void;
}

const TemplateModal: FC<TemplateModalProps> = ({ onClose, handleChooseTemplate }) => {
  const { groupedEmailTemplatesByTemplateFolder, inFlightTemplates } = useComposeEmail();

  return (
    <Modal open onClose={onClose} title="Select Template" maxWidth="md">
      <Box mb={-1}>{inFlightTemplates && <LinearProgress color="primary" />}</Box>
      {groupedEmailTemplatesByTemplateFolder.map(([folderName, templates]) => (
        <Box key={folderName} display="flex" flexDirection="column" maxWidth="100%" py={1}>
          <BodyBigText>
            <b>{folderName}</b>
          </BodyBigText>
          {templates.map((template) => (
            <Checkbox
              onChange={handleChooseTemplate(template)}
              key={template.id}
              sameUncheckedColor
              label={template.name}
              variant="circle-outlined"
              color="primary"
            />
          ))}
        </Box>
      ))}
    </Modal>
  );
};

export default TemplateModal;
