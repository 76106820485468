import { SettingNames } from '@elromcoinc/react-shared';

import GeneralServiceTypes from 'admin/constants/GeneralServiceTypes';

const { GENERAL_SERVICES } = SettingNames;

const getServiceOptions = (settings: { [key: string]: any }) =>
  (settings?.[GENERAL_SERVICES] ?? [])
    ?.filter((item: GeneralServiceType) => item.serviceType !== GeneralServiceTypes.JUNK_REMOVAL)
    ?.map((item: GeneralServiceType) => [item.id, item.name, item.serviceType]);

export { getServiceOptions };
