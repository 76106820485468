import React, { useEffect, useRef, useState } from 'react';

import {
  ActivitySourceType,
  BodyText,
  Button,
  MessageStatusTypes,
  MessageTypes,
  MessagesView,
  PhoneType,
  RecipientTypes,
  SmsCapability,
} from '@elromcoinc/react-shared';
import { Box, ListSubheader, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';
import { debounce } from 'throttle-debounce';

import communicationAPI from 'admin/api/CommunicationsAPI';
import templateApi from 'admin/api/templateApi';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getIsDisabledSms } from 'admin/autodux/MessageAutodux';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { MessageInput } from 'admin/components/Communication/MessageTab/Messages';
import { useUnsubscribeMessage } from 'admin/components/OrderWindow/context';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { getAuthUser } from 'admin/selectors/auth';
import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { CommunicationModality } from 'common-types';
import { TemplateBuilderTokens } from 'common-types/TemplateBuilderTokens';
import Loader from 'common/components/ElromcoCircularProgress';

const useStyles = makeStyles((theme) => ({
  messagesList: {
    flex: '1 1 auto',
    position: 'relative',
    height: '100%',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.common.black,
    justifyContent: 'flex-end',
    minHeight: '100%',
  },
  messagesListInner: {
    position: 'absolute',
    overflow: 'auto',
    overflowX: 'hidden',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: '100%',
  },
  subheader: {
    backgroundColor: theme.palette.common.white,
    textTransform: 'capitalize',
  },
}));

const messageTypeMap = {
  [ActivitySourceType.ORDER]: 'order',
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account',
};

export const Messages = ({
  sourceId,
  sourceType,
  onToggleReadMessage,
  orderName,
  order,
  phoneNumber,
  disabled,
  smsCapability,
  phoneCarrierType,
}) => {
  const classes = useStyles();
  const isDisableSms = useSelector(getIsDisabledSms);
  const [messages, setMessages] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(false);
  const [inFlight, setInFlight] = useState(false);
  const managersData = useSelector(getManagerList);
  const [text, setText] = useState('');
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);
  const [isLoadingSms, setIsLoadingSms] = useState(false);
  const wrapperRef = useRef({});
  const { enqueueSnackbar } = useSnackbar();
  const [isCanSendSMS, setIsCanSendSMS] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const errorMessageOptions = { variant: 'error' };
  const authUser = useSelector(getAuthUser).toJS();
  const unsubscribeWords = ['stop', 'stopall', 'unsubscribe', 'cancel', 'end', 'quid'];
  const toPhoneNumber = phoneNumber || order?.contactInfo?.primaryPhone?.number;
  const currentSMSCapability = smsCapability || order?.contactInfo?.primaryPhone?.smsCapability;
  const currentPhoneCarrierType = phoneCarrierType || order?.contactInfo?.primaryPhone?.phoneCarrierType;
  const phoneType = order?.contactInfo?.primaryPhone?.phoneType ?? PhoneType.MOBILE;
  const { isUnsubscribe, setIsUnsubscribe } = useUnsubscribeMessage();
  const [anchorEl, setAnchorEl] = useState(null);
  const { groupedSMSTemplatesByTemplateFolder = [], inFlightTemplates = false } = useComposeEmail() || {};
  const isSessionExpired = useSelector(getIsSessionExpired);

  const getAllMessages = () => {
    if (sourceId) {
      setInFlight(true);

      const params = sourceType === ActivitySourceType.ORDER ? { 'order.id': sourceId } : { 'account.id': sourceId };

      communicationAPI
        .getMessages(params)
        .catch(() => {
          enqueueSnackbar('Something went wrong. Try again', errorMessageOptions);
          return {};
        })
        .then(({ pageElements, pageIndex }) => {
          setInFlight(false);

          const filteredMessages = pageElements
            .filter(
              (message) =>
                (!message.sentAutomatically || message.recipient !== RecipientTypes.COMPANY) &&
                !message?.subject?.includes?.('New email notification'),
            )
            // TODO map bellow should be removed after merge with master
            .map((message) => ({
              ...message,
              recipient: message.type === MessageTypes.EMAIL ? RecipientTypes.EMAIL_OUT : message.recipient,
            }));

          if (filteredMessages) {
            setMessages((oldMessages) => {
              setShouldScroll(
                filteredMessages.some((it) => !oldMessages.filter((current) => current.id === it.id).length),
              );
              return pageIndex === 0 ? filteredMessages : [...filteredMessages, ...oldMessages];
            });
          }
        });
    }
  };

  const debounceFunc = debounce(1000, getAllMessages);

  useEffect(() => {
    if (sourceId || isSessionExpired) {
      debounceFunc();
    }
  }, [sourceId]);

  useEffect(() => {
    if (sourceId || !isSessionExpired) {
      const interval = setInterval(debounceFunc, 10 * 1000);

      return () => clearInterval(interval);
    }
  }, [sourceId, isSessionExpired]);

  useEffect(() => {
    if (sourceId) {
      setIsCanSendSMS(true);
    }
  }, [sourceId]);

  useEffect(() => {
    if (sourceId) {
      setErrorMessage('');
    }
  }, [sourceId]);

  useEffect(() => {
    if (shouldScroll && wrapperRef.current) {
      wrapperRef.current.scrollTop = wrapperRef.current.scrollHeight;
      setShouldScroll(false);
    }
  }, [shouldScroll, wrapperRef.current]);

  const toggleRead = (message) => {
    const newStatus = message.status === MessageStatusTypes.SEEN ? MessageStatusTypes.UNSEEN : MessageStatusTypes.SEEN;
    setMessages((state) =>
      state.map((item) =>
        item.id === message.id ? { ...item, status: newStatus, dateUpdated: new Date().toISOString() } : item,
      ),
    );

    if (onToggleReadMessage) {
      onToggleReadMessage();
    }

    return communicationAPI.toggleMessageStatus(message.id, newStatus).catch(() => {
      enqueueSnackbar(`Can't make message read/unread . Try again`, errorMessageOptions);
    });
  };

  const onEditMessage = (id, message) => {
    setMessages((oldMessages) =>
      oldMessages.map((item) =>
        item.id === id ? { ...item, text: message, bodyLastChanged: new Date().toISOString() } : item,
      ),
    );
    debounceFunc();
    return communicationAPI.updateMessage(id, message).catch(() => {
      enqueueSnackbar('Failed to edit. Try again', errorMessageOptions);
    });
  };

  const onForwardTo = (id, managers) => {
    const forwardedData = {
      when: new Date().toISOString(),
      forwardedTo: managers.map(({ firstName, lastName, id: managerId }) => ({
        fullName: `${firstName} ${lastName}`,
        id: managerId,
      })),
    };
    setMessages((state) =>
      state.map((item) =>
        item.id === id
          ? {
              ...item,
              forwardedToLogEntries: [forwardedData, ...(item.forwardedToLogEntries || [])],
            }
          : item,
      ),
    );
    return communicationAPI
      .forwardMessageTo(
        id,
        managers.map(({ id: managerId }) => managerId),
      )
      .then(() => {
        enqueueSnackbar('You have pinged', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Failed to ping. Try again', errorMessageOptions);
        return Promise.reject();
      });
  };

  const prepareTemplate = (template) => {
    if (
      TemplateBuilderTokens.some(({ simpleToken }) => template.includes(simpleToken)) &&
      sourceType === ActivitySourceType.ORDER
    ) {
      return templateApi.preview(sourceId, sourceType, {
        name: 'some',
        body: convertTemplateBuilderVariables(template),
        modality: CommunicationModality.EMAIL,
      });
    }

    return Promise.resolve(template);
  };

  const sendMsg = () => {
    if (!sourceId) {
      enqueueSnackbar('Under construction', errorMessageOptions);
    }

    if (!text || text.trim().length === 0 || !sourceId) {
      return;
    }

    setIsLoadingMessage(true);

    prepareTemplate(text)
      .then((processedTemplate) =>
        communicationAPI.sendMessage(sourceId, processedTemplate, messageTypeMap[sourceType]).then((res) => {
          setMessages([...messages, res]);
          setText('');
          setShouldScroll(true);
        }),
      )
      .catch(() => {
        enqueueSnackbar('Message was not sent. Try again', errorMessageOptions);
      })
      .then(() => setIsLoadingMessage(false));
  };

  useEffect(() => {
    if (sourceId) {
      const lastSms = [...messages].reverse().find((item) => item.type === MessageTypes.SMS);

      if (lastSms && unsubscribeWords.includes(lastSms?.text?.toLowerCase?.())) {
        setIsUnsubscribe?.(true);
        setIsCanSendSMS(false);
        setErrorMessage('Customer has been unsubscribed');
        return;
      }
      if (isUnsubscribe) {
        setIsUnsubscribe?.(false);
        setErrorMessage('');
      }
      if (toPhoneNumber) {
        communicationAPI
          .checkConditionsBeforeSendSms(sourceType, sourceId, {
            number: toPhoneNumber,
            type: phoneType,
            smsCapability: currentSMSCapability,
            phoneCarrierType: currentPhoneCarrierType,
          })
          .then(() => {
            setIsCanSendSMS(true);
            setErrorMessage('');
          })
          .catch((error) => {
            setIsCanSendSMS(false);
            const notParsedMessage = getIn(error, ['errors', 0, 'message'], null);
            const message = notParsedMessage?.match(/:(.*)\n/)?.[1]?.trim() ?? notParsedMessage;
            setErrorMessage(message);
          });
      }
    } else {
      setErrorMessage('');
      setIsUnsubscribe?.(false);
    }
  }, [sourceId, messages, isDisableSms, toPhoneNumber]);

  const sendSms = () => {
    if (!sourceId) {
      enqueueSnackbar('Under construction', errorMessageOptions);
    }

    if (!text || text.trim().length === 0 || !sourceId || !toPhoneNumber) {
      return;
    }

    setIsLoadingSms(true);

    prepareTemplate(text)
      .then((processedTemplate) =>
        communicationAPI.sendSms({
          sourceDescriptor: { referencedEntityId: sourceId, activitySource: sourceType },
          body: processedTemplate,
          toPhone: toPhoneNumber,
        }),
      )
      .then((res) => {
        setMessages([
          ...messages,
          {
            ...res,
            orderId: sourceId,
            text,
            type: MessageTypes.SMS,
            dateCreated: new Date().toISOString(),
            recipient: RecipientTypes.CUSTOMER,
            employeeFullName: `${authUser.firstName} ${authUser.lastName}`,
          },
        ]);
        setShouldScroll(true);
        setText('');
      })
      .catch(() => {
        enqueueSnackbar('SMS was not sent. Try again', errorMessageOptions);
      })
      .then(() => {
        setIsLoadingSms(false);
      });
  };

  const onResend = (message) => {
    setIsLoadingSms(true);

    setMessages((state) =>
      state.map((item) => (item.id === message.id ? { ...item, dateUpdated: new Date().toISOString() } : item)),
    );

    return communicationAPI
      .sendSms({
        sourceDescriptor: { referencedEntityId: sourceId, activitySource: sourceType },
        body: message.text,
        toPhone: toPhoneNumber,
      })
      .then((res) => {
        setMessages([
          ...messages,
          {
            ...res,
            sourceId,
            text: message.text,
            type: MessageTypes.SMS,
            dateCreated: new Date().toISOString(),
            recipient: RecipientTypes.CUSTOMER,
            employeeFullName: `${authUser.firstName} ${authUser.lastName}`,
          },
        ]);
        setShouldScroll(true);
      })
      .catch(() => {
        enqueueSnackbar('SMS was not sent. Try again', errorMessageOptions);
      })
      .then(() => {
        setIsLoadingSms(false);
      });
  };

  const openApplyTemplate = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeApplyTemplate = () => {
    setAnchorEl(null);
  };

  const handleChooseTemplate = (template) => () => {
    setText(template.body);
    closeApplyTemplate();
  };

  return (
    <>
      {sourceType === ActivitySourceType.ORDER && (
        <Box display="flex" justifyContent="flex-end" mb={1} mr={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={openApplyTemplate}
            loading={inFlightTemplates}
            disabled={inFlightTemplates}
          >
            Apply Template
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeApplyTemplate}>
            {groupedSMSTemplatesByTemplateFolder.map(([folderName, templates]) => [
              <ListSubheader
                aria-controls={`${folderName}-content`}
                id={`${folderName}-header`}
                classes={{ sticky: classes.subheader }}
              >
                {folderName}
              </ListSubheader>,
              templates.map((item) => (
                <MenuItem key={item.id} onClick={handleChooseTemplate(item)}>
                  {item.name}
                </MenuItem>
              )),
            ])}
          </Menu>
        </Box>
      )}
      <Box className={classes.messagesList}>
        {!messages.length && !inFlight && <BodyText align="center">No messages to display...</BodyText>}
        {!messages.length && inFlight && <Loader />}
        <Box ref={wrapperRef} className={classes.messagesListInner}>
          <Box className={classes.containerMessage}>
            <MessagesView
              mode="admin"
              toggleRead={toggleRead}
              messages={messages}
              orderName={orderName}
              onEditMessage={onEditMessage}
              onForwardTo={onForwardTo}
              managers={managersData.toJS()}
              onResend={onResend}
            />
          </Box>
        </Box>
      </Box>
      <MessageInput
        sendMsg={sendMsg}
        setText={setText}
        sendSms={sendSms}
        disabled={disabled}
        text={text}
        isLoadingMessage={isLoadingMessage}
        isLoadingSms={isLoadingSms}
        isCanSendSMS={isCanSendSMS && currentSMSCapability === SmsCapability.CAPABLE_ON}
        errorMessage={errorMessage}
      />
    </>
  );
};

Messages.propTypes = {
  sourceId: pt.number,
  order: pt.object,
  onToggleReadMessage: pt.func,
  orderName: pt.string,
  setMessagesCount: pt.func,
  getAllMessages: pt.func,
  disabled: pt.bool,
  sourceType: pt.oneOfType([ActivitySourceType.ORDER, ActivitySourceType.CUSTOMER_ACCOUNT]),
  smsCapability: pt.string,
  phoneCarrierType: pt.string,
};

Messages.defaultProps = {
  onToggleReadMessage: null,
  orderName: null,
  setMessagesCount: null,
  getAllMessages: null,
  sourceId: null,
  disabled: false,
  order: null,
  sourceType: ActivitySourceType.ORDER,
  smsCapability: null,
  phoneCarrierType: null,
};
