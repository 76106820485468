import {
  Duration,
  EstimateType,
  EstimateTypeName,
  EstimatedPackingType,
  Flag,
  JobDto,
  JobStatus,
  MoveTypeName,
  MoveTypeTypes,
  Order,
  OrderStatusIdType,
  PackingPricingType,
  PaymentOptions,
  Phone,
  PhoneType,
  PhoneTypeName,
  PriceAdjustment,
  PriceAdjustmentType,
  SizingStrategy,
  StorageMoveStage,
  StorageQuote,
  Truck,
  UI_DATE_FORMAT_SHORT,
  UI_LOCALIZED_TIME_FORMAT,
  Valuation,
  ValuationType,
  Waypoint,
  formatCurrency,
  formatPhoneNumber,
  isLongDistanceService,
  longDistanceMethodName,
  longDistanceMethods,
  numberToPercent,
  statusesById,
  toDate,
  toDateString,
} from '@elromcoinc/react-shared';
import { format, parse } from 'date-fns';
import { List, Map, Record, get } from 'immutable';

import { ratePerDistanceProperty, ratePerSizeUnitProperty } from 'admin/components/OrderWindow/OrderWindowContants';
import { EstimatedPackingTypeNames, PaymentOptionNames } from 'admin/constants';
import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import { PackingPriceLabels } from 'admin/constants/PackingPriceLabels';
import { SizingStrategyNames } from 'admin/constants/SizingStrategyNames';
import {
  durationToHoursMinutes,
  getMinHoursValue,
  getServicePropertyName,
  getServiceRosterClosingPropertyName,
  getValue,
} from 'admin/utils';
import { JobStatusName, LongDistancePlannerNames, LongDistancePlannerType } from 'common-types';

const NONE_VALUE = 'None';
const ZERO_PRICE = '$0.00';
const contactInfoLabels = {
  bestTimeToContact: 'Best Time To Contact',
  bestWayToContact: 'Best Way To Contact',
  email: 'Email',
  firstName: 'First Name',
  lastName: 'Last Name',
  otherPhone: 'Other phone',
  primaryPhone: 'Primary phone',
  sendNotifications: 'Send notification',
};

const locationStorage = {
  [StorageMoveStage.OUT]: 'Pickup',
  [StorageMoveStage.IN]: 'Drop Off',
};

const formatDate = (value: Date | string) => toDateString(toDate(value)!, UI_DATE_FORMAT_SHORT);

const formatTime = (value: string | null) =>
  value ? format(parse(value, TIME_STRING_FORMAT, new Date()), UI_LOCALIZED_TIME_FORMAT) : 'TBD';

const formatDuration = (value: string | null) => (value ? durationToHoursMinutes(value, true) : 'TBD');

const getPriceAdjustmentValue = (priceAdjustment: PriceAdjustment) => {
  if (!priceAdjustment) {
    return ZERO_PRICE;
  }

  return priceAdjustment.type === PriceAdjustmentType.ABSOLUTE
    ? `$${formatCurrency(+priceAdjustment.amount)}`
    : `${priceAdjustment.amount}%`;
};

const getFormatPhoneNumber = (phone: Phone) => {
  if (phone.number) {
    return `${PhoneTypeName[phone.type as PhoneType]} ${formatPhoneNumber(phone.number)}${
      phone.type === PhoneType.OFFICE ? '(' + phone.extension + ')' : ''
    }`;
  }

  return NONE_VALUE;
};

const getDiffInList = (firstList: List<Waypoint>, secondList: List<Waypoint>) => {
  const added = secondList.filter(
    (secondWaypoint) => !firstList.find((firstWaypoint) => firstWaypoint.id === secondWaypoint.id),
  );
  const deleted = firstList.filter(
    (firstWaypoint) => !secondList.find((secondWaypoint) => firstWaypoint.id === secondWaypoint.id),
  );
  const changed = firstList
    .map((firstWaypoint) => {
      const secondWaypoint = secondList.find(
        (secondWaypoint) => secondWaypoint?.id === firstWaypoint?.id && !secondWaypoint.equals(firstWaypoint),
      );
      return secondWaypoint ? [firstWaypoint, secondWaypoint] : null;
    })
    .filter(Boolean) as List<[Waypoint, Waypoint]>;

  return {
    added,
    changed,
    deleted,
  };
};

const customerFields = ['additionalContact', 'customerInfo', 'contactInfo', 'additionalInfo'];

interface makeOrderChangeLogOptions {
  valuationNames: { [key: string]: string };
  managersList: List<{ fullName: string; id: number }>;
  serviceIndex: number;
  order: Order;
  originalOrder: Order;
  isTravelRateTheSameAsLaborRate: boolean;
  currentDistanceUnitLabel: string;
  moveUnitLabelsShort: string;
}

const makeOrderChangeLog = (
  changeSet: Map<string, any>,
  {
    valuationNames,
    managersList,
    serviceIndex,
    order,
    originalOrder,
    isTravelRateTheSameAsLaborRate,
    currentDistanceUnitLabel,
    moveUnitLabelsShort,
  }: makeOrderChangeLogOptions,
) => {
  const isLongDistance = isLongDistanceService(originalOrder.getServiceType(0));
  const changes = changeSet
    .toArray()
    .filter(
      ([key]) =>
        ((!key.includes('services') && serviceIndex === 0) ||
          key.includes('sizeDescription') ||
          key.includes('moveSize') ||
          key.includes('valuation') ||
          key.includes(`services.${serviceIndex}`)) &&
        !key.includes('closingOrderDetail'),
    )
    .map(([key, value]) => {
      const from = originalOrder.getIn(key.split('.'));
      const fromHours = originalOrder.getIn(key.split('.').filter((el) => el !== 'manual'));

      if (from === value || (!from && !value) || customerFields.includes(key)) {
        return null;
      }

      if (key.includes('.date')) {
        const moveDateLabel = !isLongDistance ? 'Move Date' : 'Pickup Date';

        return [moveDateLabel, `${formatDate(from as Date)} to ${formatDate(value)}`];
      }

      if (key.includes('.storageQuote')) {
        return null;
      }

      // @ts-ignore
      if (key.includes('.dispatchJob') && get(from as JobDto, 'jobStatus') !== get(value as JobDto, 'jobStatus')) {
        const fromJob = from as JobDto;
        const toJob = value as JobDto;
        // @ts-ignore
        const fromStatus = (get(fromJob, 'jobStatus')! || JobStatus.NOT_ASSIGNED) as JobStatus;
        // @ts-ignore
        const toStatus = (get(toJob, 'jobStatus')! || JobStatus.NOT_ASSIGNED) as JobStatus;

        return ['Job Status', `${JobStatusName[fromStatus]} to ${JobStatusName[toStatus]}`];
      }

      if (
        key.includes('.truckAssignments') &&
        (from as List<Truck>).map((it) => it.id).join(',') !== (value as List<Truck>).map((it) => it.id).join(',')
      ) {
        const truckLabel = 'Truck';

        return [
          truckLabel,
          `${(from as List<Truck>).map((it) => it?.name).join(', ') || NONE_VALUE} to ${
            (value as List<Truck>).map((it) => it?.name).join(', ') || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('.truckAssignments')) {
        return null;
      }

      if (key.includes('.requestedDeliveryDateStart')) {
        return [
          'First Available Delivery Date',
          `${formatDate(from as Date) || NONE_VALUE} to ${formatDate(value) || NONE_VALUE}`,
        ];
      }

      if (key.includes('.estimatedDeliveryDate')) {
        return [
          'Estimated Delivery Date',
          `${formatDate(from as Date) || NONE_VALUE} to ${formatDate(value) || NONE_VALUE}`,
        ];
      }

      if (key.includes(ratePerSizeUnitProperty)) {
        return [
          `Rate Per ${moveUnitLabelsShort}`,
          `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`,
        ];
      }

      if (key.includes('.pickupCashDiscounts')) {
        return ['Cash Discounts', `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`];
      }

      if (key.includes('.pickupCreditCardFees')) {
        return ['Credit Card Fees', `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`];
      }

      if (key.includes('.pickupCustomFees')) {
        return ['Custom Fees', `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`];
      }

      if (key.includes('.laborHourlyTaxRate')) {
        return ['Labor Hourly Tax', `${numberToPercent(getValue(from)!)} to ${numberToPercent(getValue(value)!)}`];
      }

      if (key.includes('.travelHourlyTaxRate')) {
        return ['Travel Hourly Tax', `${numberToPercent(getValue(from)!)} to ${numberToPercent(getValue(value)!)}`];
      }

      if (key.includes('.longDistanceLineHaulTaxRate')) {
        return [
          'Long Distance Line Haul Tax',
          `${numberToPercent(getValue(from)!)} to ${numberToPercent(getValue(value)!)}`,
        ];
      }

      if (key.includes('.packingMaterialsTaxRate')) {
        return ['Packing Materials Tax', `${numberToPercent(getValue(from)!)} to ${numberToPercent(getValue(value)!)}`];
      }

      if (key.includes('.orderTotalTaxRate')) {
        return ['Order Total Tax', `${numberToPercent(getValue(from)!)} to ${numberToPercent(getValue(value)!)}`];
      }

      if (key.includes(ratePerDistanceProperty)) {
        return [
          `Rate Per ${currentDistanceUnitLabel}`,
          `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`,
        ];
      }

      if (key.includes('.flatRateTotalManual')) {
        const initialValue = from === null ? order.getServiceGrandTotal(serviceIndex).maxValue : from;

        return [
          'Override Grand Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes('.manualGrandTotal')) {
        const initialValue = from === null ? order.getServiceGrandTotal(serviceIndex).maxValue : from;

        return [
          'Override Grand Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes('.manualLinehaulCharge')) {
        const initialValue =
          from === null ? order.getServiceQuote(serviceIndex).baseQuoteWithoutTaxes.minValue ?? 0 : from;

        return [
          'Override Linehaul Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes('.linehaulTotalManual')) {
        const initialValue =
          from === null ? order.getServiceQuote(serviceIndex).getFlatRateLinehaulCharge() ?? 0 : from;

        return [
          'Override Linehaul Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes('.deliveryDateStart')) {
        return [
          'Delivery Date Start',
          `${formatDate(from as Date) || NONE_VALUE} to ${formatDate(value) || NONE_VALUE}`,
        ];
      }

      if (key.includes('.longDistanceTariffDetails.minSize')) {
        return ['Min Size', `${from || 0} to ${value || 0}`];
      }

      if (key.includes('.longDistanceTariffDetails.basePrice')) {
        return ['Base Price', `$${formatCurrency((from as number) || 0)} to $${formatCurrency(value || 0)}`];
      }

      if (key.includes('.deliveryDateEnd')) {
        return ['Delivery Date End', `${formatDate(from as Date) || NONE_VALUE} to ${formatDate(value) || NONE_VALUE}`];
      }

      if (key.includes('startTimeEarliest')) {
        return ['Arrival Window From', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('deliveryTimeEarliest')) {
        return ['Truck Leave Time Start', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('deliveryTruckReturnTime')) {
        return ['Truck Return Time', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('arrivalTimeEarliest')) {
        return ['Arrival Time Window Start', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('arrivalTimeEarliest')) {
        return ['Arrival Time Window End', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('startTimeLatest')) {
        return ['Arrival Window To', `${formatTime(from as string)} to ${formatTime(value)}`];
      }

      if (key.includes('disableAutoCalculation')) {
        return ['Calculator', !value ? 'On' : 'Off'];
      }

      if (key.includes('laborOnly')) {
        return ['Labor Only', value ? 'On' : 'Off'];
      }

      if (key.includes('.durationMin.originalSeconds')) {
        return ['Labor Time Min', `${formatDuration(from as string)} to ${formatDuration(value)}`];
      }

      if (key.includes('.durationMax.originalSeconds')) {
        return ['Labor Time Max', `${formatDuration(from as string)} to ${formatDuration(value)}`];
      }

      if (key.includes('laborRateOverride')) {
        const label = !isTravelRateTheSameAsLaborRate ? 'Labor Rate' : 'Hourly Rate';

        return [label, `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`];
      }

      if (key.includes('.travelRateOverride') && isTravelRateTheSameAsLaborRate) {
        return null;
      }

      if (key.includes('.travelRateOverride')) {
        return ['Travel Rate', `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`];
      }

      if (key.includes('numberOfMovers')) {
        return ['Crew Size', `${getValue(from)} to ${getValue(value)}`];
      }

      if (key.includes('numberOfDeliveryMovers')) {
        return ['Delivery Crew Size', `${getValue(from)} to ${getValue(value)}`];
      }

      if (key.includes('numberOfTrucks')) {
        return ['Trucks', `${getValue(from)} to ${getValue(value)}`];
      }

      if (key.includes('finalBillableTravelTimeForQuote.originalSeconds')) {
        const path = `services.${serviceIndex}.quote.finalBillableTravelTimeForQuote`.split('.');
        const oldTravelTimeValue = (originalOrder.getIn(path) as Duration).getRoundedSeconds() as any;
        return ['Travel Time', `${formatDuration(oldTravelTimeValue as string)} to ${formatDuration(value)}`];
      }

      if (key.includes('doubleDrivingTimeOverride.originalSeconds')) {
        const path = `services.${serviceIndex}.quote.doubleDrivingTimeOverride`.split('.');
        const oldDoubleDrivingTimeValue = (originalOrder.getIn(path) as Duration).getRoundedSeconds() as any;
        return [
          'Double Driving Time',
          `${formatDuration(oldDoubleDrivingTimeValue as string)} to ${formatDuration(value)}`,
        ];
      }

      if (key.includes('.minLaborHours')) {
        return ['Min Labor Hours', `${getMinHoursValue(fromHours)} to ${getMinHoursValue(value)}`];
      }

      if (key.includes('fuelChargeEmployeeAdjusted')) {
        return [
          'Fuel Changed By Employee',
          `${getPriceAdjustmentValue(from as PriceAdjustment)} to ${getPriceAdjustmentValue(value)}`,
        ];
      }

      if (key.includes('valuation')) {
        const fromValuation = from as Valuation;
        const toValuation = value as Valuation;

        const costChanges =
          toValuation.type === ValuationType.FULL
            ? `, $${formatCurrency(fromValuation.cost)} to $${formatCurrency(toValuation.cost)}`
            : '';

        return [
          'Valuation',
          `${valuationNames[fromValuation.type]} to ${valuationNames[toValuation.type]}${costChanges}`,
        ];
      }

      if (key.includes('overriddenReservationAmount')) {
        const reservationAmountNeeded = originalOrder.getIn(
          getServicePropertyName(serviceIndex, 'reservationAmountNeeded').split('.'),
        ) as number;
        return ['Reservation', `$${formatCurrency(reservationAmountNeeded ?? 0)} to $${formatCurrency(+value || 0)}`];
      }

      if (key.includes('cuFtToPounds')) {
        return ['Weight Factor', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('paymentType')) {
        return [
          'Payment Option',
          `${PaymentOptionNames[from as PaymentOptions] || NONE_VALUE} to ${
            PaymentOptionNames[value as PaymentOptions] || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('estimatedPackingType')) {
        return [
          'Packing Type',
          `${EstimatedPackingTypeNames[from as EstimatedPackingType] || NONE_VALUE} to ${
            EstimatedPackingTypeNames[value as EstimatedPackingType] || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('sizingStrategy')) {
        return [
          'Sizing Strategy',
          `${SizingStrategyNames[from as SizingStrategy] || NONE_VALUE} to ${
            SizingStrategyNames[value as SizingStrategy] || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('overriddenCuFt')) {
        return ['Overridden Weight', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('deliveryDaysCount')) {
        return ['Delivery Days', `${from} to ${value}`];
      }

      if (key.includes('longDistancePlanner')) {
        return [
          'Long Distance Planner',
          `${LongDistancePlannerNames[from as LongDistancePlannerType] || NONE_VALUE} to ${
            LongDistancePlannerNames[value as LongDistancePlannerType] || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('calculationMethod')) {
        return [
          'Tariff Type',
          `${longDistanceMethodName[from as longDistanceMethods] || NONE_VALUE} to ${
            longDistanceMethodName[value as longDistanceMethods] || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('longDistanceRatePerUnitOverride')) {
        return [
          'Rate per size unit',
          `${formatCurrency(from as number, 2, ZERO_PRICE) || ZERO_PRICE} to $${
            formatCurrency(+value, 2, ZERO_PRICE) || ZERO_PRICE
          }`,
        ];
      }

      if (key.includes('additionalRatePerMoveSize')) {
        return [
          'Add. Rate per size unit',
          `${formatCurrency(from as number, 2, ZERO_PRICE) || ZERO_PRICE} to $${
            formatCurrency(+value, 2, ZERO_PRICE) || ZERO_PRICE
          }`,
        ];
      }

      if (key.includes('additionalServices')) {
        const path = `services.${serviceIndex}.quote.totalAdditionalServicesQuote`.split('.');

        return [
          'Additional Services Total',
          `$${formatCurrency(originalOrder.getIn(path) as number)} to $${formatCurrency(order.getIn(path) as number)}`,
        ];
      }

      if (key.includes('packingProducts')) {
        const path = `services.${serviceIndex}.quote.basePackingQuote`.split('.');

        return [
          'Packing total',
          `$${formatCurrency(originalOrder.getIn(path) as number)} to $${formatCurrency(order.getIn(path) as number)}`,
        ];
      }

      if (key.includes('packingPricingType')) {
        return [
          'Packing pricing type',
          `From ${PackingPriceLabels[from as PackingPricingType] || NONE_VALUE} to ${
            PackingPriceLabels[value as PackingPricingType]
          }`,
        ];
      }

      if (key.includes('discount')) {
        return ['Discount', `${getPriceAdjustmentValue(from as PriceAdjustment)} to ${getPriceAdjustmentValue(value)}`];
      }

      if (key.includes('fees')) {
        const path = `services.${serviceIndex}.quote.fees`.split('.');
        const oldTotalFees = (originalOrder.getIn(path) as Array<any>)
          .filter((it: any) => it.enabled)
          .reduce((total: number, fee: any) => total + fee.amount, 0);
        const newTotalFees = (order.getIn(path) as Array<any>)
          .filter((it: any) => it.enabled)
          .reduce((total: number, fee: any) => total + fee.amount, 0);

        return [
          'Other Fees Total',
          `$${formatCurrency(oldTotalFees as number)} to $${formatCurrency(newTotalFees as number)}`,
        ];
      }

      if (key.includes('secondaryId')) {
        return ['Secondary Id', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('moveType')) {
        return ['Move Type', `${MoveTypeName[from as MoveTypeTypes]} to ${MoveTypeName[value as MoveTypeTypes]}`];
      }

      if (key.includes('generalService')) {
        return ['Service Type', `${(from as GeneralServiceType).name} to ${(value as GeneralServiceType).name}`];
      }

      if (key.includes('estimateType')) {
        return [
          'Estimate Type',
          `${EstimateTypeName[from as EstimateType]} to ${EstimateTypeName[value as EstimateType]}`,
        ];
      }

      if (key.includes('sizeDescription')) {
        return ['Move Size', `${from} to ${value}`];
      }

      if (key.includes('firstName')) {
        return ['First Name', `${from} to ${value}`];
      }

      if (key.includes('lastName')) {
        return ['Last Name', `${from} to ${value}`];
      }

      if (key.includes('email')) {
        return ['Email', `${from} to ${value}`];
      }

      if (key.includes('primaryPhone')) {
        return ['Primary phone', `${getFormatPhoneNumber(from as Phone)} to ${getFormatPhoneNumber(value)}`];
      }

      if (key.includes('otherPhone')) {
        return ['Other Phone', `${getFormatPhoneNumber(from as Phone)} to ${getFormatPhoneNumber(value)}`];
      }

      if (key.includes('bestTimeToContact')) {
        return ['Best Time To Contact', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('bestWayToContact')) {
        return ['Best Way To Contact', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('enableSmsCustom')) {
        return ['SMS Status', value ? 'On' : 'Off'];
      }

      if (key.includes('quoteVisibleToCustomer')) {
        return ['Show Quote', value ? 'On' : 'Off'];
      }

      if (key.includes('chargeProcessingFeeSettings.takingDeposit')) {
        return ['Processing Fee Deposit', value ? 'On' : 'Off'];
      }

      if (key.includes('chargeProcessingFeeSettings.sales')) {
        return ['Processing Fee Sales', value ? 'On' : 'Off'];
      }

      if (key.includes('chargeProcessingFeeSettings.closing')) {
        return ['Processing Fee Closing', value ? 'On' : 'Off'];
      }

      if (key.includes('chargeProcessingFeeSettings.billOfLading')) {
        return ['Processing Fee Bill of Lading', value ? 'On' : 'Off'];
      }

      if (key.includes('chargeProcessingFeeSettings.invoice')) {
        return ['Processing Fee Invoice', value ? 'On' : 'Off'];
      }

      if (key.includes('displaySpreadDailyEstimatedJobTimeToCustomer')) {
        return ['Display Daily Estimated Job Time to Customer', value ? 'On' : 'Off'];
      }

      if (key.includes('moveSize')) {
        return ['Move Size', `${formatCurrency(+(from as number))} to ${formatCurrency(+value)}`];
      }

      if (key.includes('waypoints') || key.includes('arrivalWindow')) {
        return null;
      }

      if (key.includes('assignedTo')) {
        const fromId = originalOrder.get(key as 'assignedTo');
        const fromSales = managersList.find((it) => it.id === +fromId!);
        const toSales = managersList.find((it) => it.id === +value!);

        return ['Assign To', `${fromSales?.fullName || NONE_VALUE} to ${toSales?.fullName || NONE_VALUE}`];
      }

      if (key.includes('leadSource')) {
        return ['Lead Source', `${from || NONE_VALUE} to ${value || NONE_VALUE}`];
      }

      if (key.includes('status')) {
        return [
          'Status',
          `${statusesById[from as OrderStatusIdType].label} to ${statusesById[value as OrderStatusIdType].label}`,
        ];
      }

      if (key.includes('flags')) {
        return [
          'Flags',
          `${
            (from as List<Flag>)
              .map((it) => it.name)
              .toArray()
              .join(', ') || NONE_VALUE
          } to ${
            (value as List<Flag>)
              .map((it) => it.name)
              .toArray()
              .join(', ') || NONE_VALUE
          }`,
        ];
      }

      if (key.includes('storageMoveStage')) {
        return [
          'Location of Storage ',
          `${locationStorage[from as keyof typeof locationStorage]} to ${
            locationStorage[value as keyof typeof locationStorage]
          }`,
        ];
      }

      return [key, value];
    })
    .filter(Boolean) as [string, string][];

  changeSet
    .toArray()
    .filter(([key]) => customerFields.some((fieldName) => fieldName === key))
    .forEach(([key, value]) => {
      const from = originalOrder.getIn(key.split('.'));
      const newValue = Record.isRecord(value) ? value?.toJS() : value;
      const oldValue = Record.isRecord(from) ? from?.toJS() : (from as any);
      const newPhone = new Phone(newValue?.primaryPhone);
      const oldPhone = new Phone(oldValue?.primaryPhone);
      const newOtherPhone = new Phone(newValue?.otherPhone);
      const oldOtherPhone = new Phone(oldValue?.otherPhone);

      if (key === 'additionalInfo' && !Record.isRecord(value)) {
        return changes.push(['Additional Info', `${from || NONE_VALUE} to ${value || NONE_VALUE}`]);
      }

      if (key === 'customerInfo') {
        changes.push(['Customer info', 'has changes']);
      }

      if (!newPhone.equals(oldPhone) && key === 'contactInfo') {
        changes.push([
          'Primary phone',
          `${getFormatPhoneNumber(oldValue.primaryPhone as Phone)} to ${getFormatPhoneNumber(newValue.primaryPhone)}`,
        ]);
      }

      if (!newOtherPhone.set('number', newOtherPhone.number || '').equals(oldOtherPhone) && key === 'contactInfo') {
        changes.push([
          'Other Phone',
          `${getFormatPhoneNumber(oldValue.otherPhone as Phone)} to ${getFormatPhoneNumber(newValue.otherPhone)}`,
        ]);
      }

      if (newValue?.phoneNumber !== oldValue?.phoneNumber) {
        changes.push([
          'Phone number (Additional info)',
          `${formatPhoneNumber(oldValue.phoneNumber) || NONE_VALUE} to ${
            formatPhoneNumber(newValue.phoneNumber) || NONE_VALUE
          }`,
        ]);
      }

      Object.keys(newValue).forEach((info) => {
        if (
          info !== 'primaryPhone' &&
          info !== 'otherPhone' &&
          info !== 'phoneNumber' &&
          contactInfoLabels[info as keyof typeof contactInfoLabels]
        ) {
          if (value[info] !== oldValue[info]) {
            changes.push([
              `${contactInfoLabels[info as keyof typeof contactInfoLabels]} ${
                key !== 'contactInfo' ? '(Additional info)' : ``
              }`,
              `${oldValue[info] || NONE_VALUE} to ${value[info]}`,
            ]);
          }
        }
      });
    });

  if (changeSet.has(`services.${serviceIndex}.quote.waypoints`)) {
    changes.push(['Waypoints or Inventory', 'Was changed']);

    const originalWaypoints = originalOrder.getCustomerWaypoints(serviceIndex);
    const destinationWaypoints = order.getCustomerWaypoints(serviceIndex);

    const result = getDiffInList(originalWaypoints, destinationWaypoints);

    if (!result.added.isEmpty()) {
      changes.push([
        'Added Waypoints',
        result.added
          .map((it) => it.address.fullAddressLine())
          .toArray()
          .join(', '),
      ]);
    }

    const waypointChanges = result.changed
      .map(([firstWaypoint, secondWaypoint]) =>
        firstWaypoint.address.fullAddressLine() !== secondWaypoint.address.fullAddressLine()
          ? `${firstWaypoint.address.fullAddressLine()} to ${secondWaypoint.address.fullAddressLine()}`
          : null,
      )
      .toArray()
      .filter(Boolean)
      .join('\n');

    if (!result.changed.isEmpty() && waypointChanges) {
      changes.push(['Changed Waypoints', waypointChanges]);
    }

    if (!result.deleted.isEmpty()) {
      changes.push([
        'Deleted Waypoints',
        result.deleted
          .map((it) => it.address.fullAddressLine())
          .toArray()
          .join(', '),
      ]);
    }
  }

  if (changeSet.has(`services.${serviceIndex}.quote.storageQuote`)) {
    const originalStorageQuote = originalOrder.services.get(serviceIndex).quote.storageQuote;
    const newStorageQuote = order.services.get(serviceIndex).quote.storageQuote;

    if (originalStorageQuote?.displayOnCustomerPage !== newStorageQuote?.displayOnCustomerPage) {
      changes.push(['Storage quote display on customer page', newStorageQuote?.displayOnCustomerPage ? 'On' : 'Off']);
    }

    if (originalStorageQuote?.includeInQuote !== newStorageQuote?.includeInQuote) {
      changes.push(['Storage quote include in quote', newStorageQuote?.includeInQuote ? 'On' : 'Off']);
    }
  }

  const closingDetailsChanges = changeSet
    .toArray()
    .filter(
      ([key]) =>
        key.includes(`closingOrderDetail.serviceRosterClosingsDto.${serviceIndex}`) ||
        key.includes(`closingOrderDetail.services.${serviceIndex}`),
    );

  if (closingDetailsChanges.length) {
    closingDetailsChanges.forEach(([key, value]) => {
      const from = originalOrder.getIn(key.split('.'));
      const fromHours = originalOrder.getIn(key.split('.').filter((el) => el !== 'manual'));

      if (from === value || (!from && !value) || customerFields.includes(key)) {
        return null;
      }

      if (key.includes('.crewSize')) {
        changes.push(['Closing Crew Size', `${getValue(from)} to ${getValue(value)}`]);
      }

      if (key.includes('.deliveryCrewSize')) {
        changes.push(['Closing Delivery Crew Size', `${getValue(from)} to ${getValue(value)}`]);
      }

      if (key.includes('.numberOfTrucks')) {
        changes.push(['Closing Trucks', `${getValue(from)} to ${getValue(value)}`]);
      }

      if (key.includes('.laborRate')) {
        const label = !isTravelRateTheSameAsLaborRate ? 'Labor Rate' : 'Hourly Rate';
        changes.push([label, `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`]);
      }

      if (key.includes('.laborTime')) {
        changes.push(['Closing Labor Time', `${formatDuration(fromHours as string)} to ${formatDuration(value)}`]);
      }

      if (key.includes('doubleDrivingTimeOverride.originalSeconds')) {
        const path = `closingOrderDetail.services.${serviceIndex}.quote.doubleDrivingTimeOverride`.split('.');
        const oldDoubleDrivingTimeValue = (originalOrder.getIn(path) as Duration).getRoundedSeconds() as any;
        changes.push([
          'Closing Double Driving Time',
          `${formatDuration(oldDoubleDrivingTimeValue as string)} to ${formatDuration(value)}`,
        ]);
      }

      if (key.includes('finalBillableTravelTimeForQuote.originalSeconds')) {
        const path = `closingOrderDetail.services.${serviceIndex}.quote.finalBillableTravelTimeForQuote`.split('.');
        const oldTravelTimeValue = (originalOrder.getIn(path) as Duration).getRoundedSeconds() as any;
        changes.push([
          'Closing Travel Time',
          `${formatDuration(oldTravelTimeValue as string)} to ${formatDuration(value)}`,
        ]);
      }

      if (key.includes('fuelChargeEmployeeAdjusted')) {
        return [
          'Closing Fuel Changed By Employee',
          `${getPriceAdjustmentValue(from as PriceAdjustment)} to ${getPriceAdjustmentValue(value)}`,
        ];
      }

      if (key.includes('valuation')) {
        const fromValuation = from as Valuation;
        const toValuation = value as Valuation;

        const costChanges =
          toValuation.type === ValuationType.FULL
            ? `, $${formatCurrency(fromValuation.cost)} to $${formatCurrency(toValuation.cost)}`
            : '';

        return [
          'Closing Valuation',
          `${valuationNames[fromValuation.type]} to ${valuationNames[toValuation.type]}${costChanges}`,
        ];
      }

      if (key.includes('.storageQuote')) {
        return null;
      }

      if (key.includes('packingProducts')) {
        return ['Closing Packing', `was changed`];
      }

      if (key.includes('additionalServices')) {
        return ['Closing Additional Services', `was changed`];
      }

      if (key.includes('fees')) {
        const path = getServiceRosterClosingPropertyName(serviceIndex, 'fees').split('.');
        const oldTotalFees = (originalOrder.getIn(path) as Array<any>)
          .filter((it: any) => it.enabled)
          .reduce((total: number, fee: any) => total + fee.amount, 0);
        const newTotalFees = (order.getIn(path) as Array<any>)
          .filter((it: any) => it.enabled)
          .reduce((total: number, fee: any) => total + fee.amount, 0);

        return [
          'Closing Other Fees Total',
          `$${formatCurrency(oldTotalFees as number)} to $${formatCurrency(newTotalFees as number)}`,
        ];
      }

      if (key.includes('discount')) {
        changes.push([
          'Closing Discount',
          `${getPriceAdjustmentValue(from as PriceAdjustment)} to ${getPriceAdjustmentValue(value)}`,
        ]);
      }

      if (key.includes('.flatRateTotalManual')) {
        const initialValue =
          from === null ? order.closingOrderDetail.getServiceGrandTotal(serviceIndex).maxValue : from;

        return [
          'Closing Override Grand Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes('.linehaulTotalManual')) {
        const initialValue =
          from === null
            ? order.closingOrderDetail.getServiceQuote(serviceIndex).getFlatRateLinehaulCharge() ?? 0
            : from;

        return [
          'Closing Override Linehaul Total',
          `$${formatCurrency(initialValue as number)} to $${formatCurrency(+value as number)}`,
        ];
      }

      if (key.includes(ratePerSizeUnitProperty)) {
        return [
          `Closing Rate Per ${moveUnitLabelsShort}`,
          `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`,
        ];
      }

      if (key.includes(ratePerDistanceProperty)) {
        return [
          `Closing Rate Per ${currentDistanceUnitLabel}`,
          `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`,
        ];
      }

      if (key.includes('.minLaborHours')) {
        changes.push(['Closing Min Labor Hours', `${getMinHoursValue(fromHours)} to ${getMinHoursValue(value)}`]);
      }

      if (key.includes('.pickupTips')) {
        changes.push([`Tips`, `$${formatCurrency(getValue(from))} to $${formatCurrency(getValue(value))}`]);
      }
    });
  }

  const closingStorageQuoteName = getServiceRosterClosingPropertyName(serviceIndex, 'storageQuote');

  if (changeSet.has(closingStorageQuoteName)) {
    const originalStorageQuote = originalOrder.getIn(closingStorageQuoteName.split('.')) as StorageQuote;
    const newStorageQuote = order.getIn(closingStorageQuoteName.split('.')) as StorageQuote;

    if (originalStorageQuote?.displayOnCustomerPage !== newStorageQuote?.displayOnCustomerPage) {
      changes.push([
        'Closing Storage quote display on customer page',
        newStorageQuote?.displayOnCustomerPage ? 'On' : 'Off',
      ]);
    }

    if (originalStorageQuote?.includeInQuote !== newStorageQuote?.includeInQuote) {
      changes.push(['Closing Storage quote include in quote', newStorageQuote?.includeInQuote ? 'On' : 'Off']);
    }
  }

  changes.sort(([firstLabel], [secondLabel]) => (firstLabel || '').localeCompare(secondLabel));

  return changes;
};

export { makeOrderChangeLog };
