import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Modal from 'common/components/Modal';
import Card from 'common/components/Card';
import { ColoredCircleLabel } from 'common/components/Widgets';
import { Link } from '@elromcoinc/react-shared';

const HeaderField = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
  }
`;

const BodyField = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: black;
  }
`;

const HistoryOverviewList = styled(List)`
  && {
    width: 100%;
    li {
      align-items: center;
      justify-content: space-between;
      padding-top: 14px;
      padding-bottom: 10px;
      :nth-child(2n + 1) {
        background-color: #f5f5f6;
      }
      :first-child {
        background-color: white;
        border-bottom: 1px solid #d7d7da;
      }
      > div > button {
        margin-top: 8px;
        margin-bottom: 8px;
      }
      > p:nth-child(1),
      > div:nth-child(1) {
        min-width: 70px;
      }
      > p:nth-child(2),
      > div:nth-child(2) {
        min-width: 110px;
      }
      > p:nth-child(3),
      > div:nth-child(3) {
        min-width: 170px;
      }
      > p:nth-child(4),
      > div:nth-child(4) {
        min-width: 120px;
      }
      > p:nth-child(5),
      > div:nth-child(5) {
        min-width: 70px;
      }
      > p:nth-child(6),
      > div:nth-child(6) {
        min-width: 70px;
      }
    }
  }
`;
const FieldNames = ['Status', 'Date Opened', 'Subject', 'Opened By', 'Priority', 'Actions'];

const MockData = [
  {
    status: 'Open',
    dateOpened: '01/102020',
    subject: 'Update an account page',
    openedBy: 'Sarah Nordblom',
    priority: 'Medium',
    actions: 'View',
  },
  {
    status: 'Closed',
    dateOpened: '01/102020',
    subject: 'Update an account page',
    openedBy: 'Sarah Nordblom',
    priority: 'Medium',
    actions: 'View',
  },
];

function getCircleColorByStatus(status) {
  switch (status) {
    case 'Open':
      return '#CD324A';
    case 'Closed':
      return '#5DCE83';
    default:
      return '#5DCE83';
  }
}

const Claims = ({ onSave, onCancel, open }) => (
  <Modal
    open={open}
    title="Claims"
    onClose={onCancel}
    actions={[
      { label: 'cancel', action: onCancel },
      { label: 'save', action: onSave, disabled: false },
    ]}
  >
    <Card mode="ADD">
      <HistoryOverviewList>
        <ListItem>
          {FieldNames.map((fieldName) => (
            <HeaderField>{fieldName}</HeaderField>
          ))}
        </ListItem>
        {MockData.map((item) => (
          <ListItem>
            <Box>
              <ColoredCircleLabel
                title={item.status}
                circleColor={getCircleColorByStatus(item.status)}
                labelColor="black"
              />
            </Box>
            <Box>
              <BodyField>{item.dateOpened}</BodyField>
            </Box>
            <Box>
              <BodyField>{item.subject}</BodyField>
            </Box>
            <Box>
              <BodyField>{item.openedBy}</BodyField>
            </Box>
            <Box>
              <BodyField>{item.priority}</BodyField>
            </Box>
            <Box>
              <BodyField>
                <Link>{item.actions}</Link>
              </BodyField>
            </Box>
          </ListItem>
        ))}
      </HistoryOverviewList>
    </Card>
  </Modal>
);

Claims.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Claims;
