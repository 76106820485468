import { Record } from 'immutable';

export default class GeneralService extends Record({
  updated: null,
  id: null,
  rgbColor: null,
  serviceType: null,
  showValuationOnAccount: false,
  showValuationOnConfirmationPage: false,
  showCustomer: false,
  name: null
}) {
  static createGeneralService(data) {
    if (data) {
      return new GeneralService(data);
    }
    return new GeneralService();
  }
}
