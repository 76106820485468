import React from 'react';

import {
  BodyText,
  CurrencyInput,
  IconButton,
  PaymentAdjustmentType,
  TextInput,
  currencyInputAdapter,
  formatCurrency,
  roundNumberToFixedDigits,
} from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { usePaymentAdjustment } from 'admin/components/OrderWindow/hooks';

const useStyles = makeStyles(({ spacing }) => ({
  withoutBorder: {
    borderBottom: 'none',
  },
  smallInput: {
    '& input': {
      padding: `${spacing(0, 0.5)}!important`,
      height: spacing(3),
    },
  },
}));

const weightAdjustment = [PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE];

export const AddSizeUnitTable = () => {
  const classes = useStyles();
  const { isCompleted } = useOrderClosingContext();
  const { order } = useOrderState();
  const {
    paymentAdjustments,
    deletePaymentAdjustment,
    renderDeleteDialog,
    add: addAdditionalWeight,
    save: saveAdditionalWeight,
    onChangeProperties,
    inFlightUpdate,
    inFlight,
  } = usePaymentAdjustment({ adjustmentsToManage: weightAdjustment });
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const { serviceIndex } = useOrderServiceIndex();
  const closingQuote = order?.closingOrderDetail?.getServiceQuote(serviceIndex);
  const baseWeight = (order?.moveUnit === 'CUBIC_FEET' ? order?.activeCuFt : order?.activeWeight) ?? 0;

  const total =
    closingQuote?.totalLinehaulCharge?.minValue + paymentAdjustments.reduce((acc, pa) => acc + (pa.total ?? 0), 0);
  const totalWeight = baseWeight + paymentAdjustments.reduce((acc, pa) => acc + (+pa.rate.amount ?? 0), 0);

  const disabled = isCompleted || inFlight || inFlightUpdate;

  return (
    <Table>
      <TableBody>
        {paymentAdjustments.map((pa) => (
          <TableRow key={pa.id}>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" alignItems="center" width={230}>
                <BodyText>Add.:</BodyText>
                <Box width={80}>
                  <TextInput
                    disabled={disabled}
                    className={classes.smallInput}
                    size="small"
                    hiddenLabel
                    name="additionalWeight"
                    value={pa?.rate?.amount}
                    onChange={onChangeProperties(pa.id)}
                    onBlur={saveAdditionalWeight(pa.id)}
                  />
                </Box>
                <BodyText>{moveUnitLabelsShort}@</BodyText>
                <Box width={80}>
                  <CurrencyInput
                    className={classes.smallInput}
                    disabled={disabled}
                    size="small"
                    hiddenLabel
                    name="rate"
                    value={pa?.quote}
                    onChange={currencyInputAdapter(onChangeProperties(pa.id))}
                    onBlur={saveAdditionalWeight(pa.id)}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <BodyText>{`$${formatCurrency(pa.total ?? 0)}`}</BodyText>
                <Box mr={-3}>
                  <IconButton
                    color="secondary"
                    className="print-hide"
                    disabled={disabled}
                    onClick={deletePaymentAdjustment(pa)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        ))}
        {!paymentAdjustments.isEmpty() && (
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>{`Total ${roundNumberToFixedDigits(totalWeight)} ${moveUnitLabelsShort}`}</BodyText>
            </TableCell>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>${formatCurrency(total)}</BodyText>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell size="small" className={classes.withoutBorder} colSpan={2}>
            <IconButton color="primary" className="print-hide" disabled={disabled} onClick={addAdditionalWeight()}>
              <AddIcon />
            </IconButton>
            {renderDeleteDialog()}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
