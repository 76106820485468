import { JobDto, JobLoadingUnloadingTimeDto, parseISO } from '@elromcoinc/react-shared';
import { differenceInSeconds } from 'date-fns';

const firstCrewNumber = 1;

export const processLoggedTime = (job: JobDto | null) => {
  const laborTimes = job
    ? [...job?.loadingTimeList, ...job?.unloadingTimeList]
        .sort((a, b) => (a.waypointNumber ?? 0) - (b.waypointNumber ?? 0))
        .filter((t) => t.crewNumber === firstCrewNumber)
    : [];

  const hasTimeForWithoutWaypoint = laborTimes.some((t) => t.waypointNumber === null);

  return laborTimes
    .reduce((accumulator, item) => {
      const lastItem = accumulator[accumulator.length - 1];

      if (
        lastItem?.waypointNumber !== item.waypointNumber &&
        ((hasTimeForWithoutWaypoint && !item.waypointNumber) || (!hasTimeForWithoutWaypoint && item.waypointNumber))
      ) {
        accumulator.push(item);
      }

      return accumulator;
    }, [] as JobLoadingUnloadingTimeDto<string>[])
    .map((t) => {
      // || new Date().toISOString() is used to avoid null values and calculate until current time
      const endDateTime = t.endDateTime || new Date().toISOString();

      const timeOffSeconds = t.timeOffSeconds || 0;

      const totalTimeSeconds =
        endDateTime && t.startDateTime
          ? Math.abs(differenceInSeconds(parseISO(endDateTime)!, parseISO(t.startDateTime)!)) - timeOffSeconds!
          : 0;

      return {
        ...t,
        totalTimeSeconds,
      };
    });
};
