import React from 'react';

import { Data, useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@material-ui/core';
import clsx from 'clsx';

import { DragAndDropType } from 'common-types';

interface DraggableBlockProps {
  children: React.ReactNode;
  isDragging?: boolean;
  id: number | string;
  data: Data;
  isDisableDrag?: boolean;
  supports?: DragAndDropType[];
  classNames?: string;
  disabled?: boolean;
  'data-testid'?: string;
}

export const DraggableBlock = ({
  children,
  id,
  data,
  isDisableDrag,
  supports,
  classNames = '',
  disabled = false,
  'data-testid': dataTestId,
}: DraggableBlockProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: supports ? { data, supports } : data,
    disabled,
  });
  const style =
    transform && !isDisableDrag
      ? {
          position: 'relative',
          transform: CSS.Translate.toString(transform),
          top: 'auto !important',
          left: 'auto !important',
          cursor: 'grab',
          zIndex: 1000,
        }
      : undefined;

  return (
    /* @ts-ignore added because of ref */
    <Box
      className={clsx(classNames, 'el-draggable-block')}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      key={id}
      id={id}
      data-testid={dataTestId}
    >
      {children}
    </Box>
  );
};
