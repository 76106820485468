import { SettingNames } from '@elromcoinc/react-shared';

import { ReferredViaDTO } from 'common-types';

const { LEAD_SOURCE } = SettingNames;

const getEnabledLeadSources = (settings: { [key: string]: any }) =>
  (settings?.[LEAD_SOURCE] ?? [])
    ?.filter((it: ReferredViaDTO) => it.enabled && !it.externalLeadDeliveryType)
    ?.map((it: ReferredViaDTO) => [it.name, it.name]) || [];

export { getEnabledLeadSources };
