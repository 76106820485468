import React, { useEffect, useState } from 'react';
import { SurveySchedulerPanel } from 'admin/components/OrderWindow/SurveySchedulerBox';
import pt from 'prop-types';
import { endOfWeek, format, isSameDay, startOfDay, startOfWeek } from 'date-fns';
import { BACKEND_DATE_FORMAT, BodyBigText, Modal, Order, SurveyType, useAlert } from '@elromcoinc/react-shared';
import servicesAPI from 'admin/api/ServicesAPI';
import { useSnackbar } from 'notistack';
import { ESTIMATOR_ID, SURVEY_DATE } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { Box } from '@material-ui/core';
import useInHomeEstimatePermissions from 'admin/hooks/useInHomeEstimatePermissions';

const getEstimators = (item, currentSurveyValue) => {
  const works = {};
  Object.entries(item.works).forEach(([key, value]) => {
    works[key] = value
      .map((it) => ({ ...it, estimatorId: item.employeeSummary.id, surveyDate: key }))
      .filter(
        (it) =>
          it.orderId !== currentSurveyValue.orderId ||
          (it.orderId === currentSurveyValue.orderId && it.orderServiceType !== currentSurveyValue.orderServiceType),
      );
  });

  return { ...item, works };
};

export const SurveySchedulerBox = ({
  dense,
  estimateDate,
  order,
  currentSurveyValue,
  setValue,
  trigger,
  isLoadingSurveyService,
  handleCloseModal,
  existingEstimateService,
  surveyType,
}) => {
  const [dayMode, setDayMode] = useState(true);
  const [date, setDate] = useState(estimateDate);
  const [availableEstimatorsByDate, setAvailableEstimatorsByDate] = useState([]);
  const [availableEstimatorsByWeek, setAvailableEstimatorsByWeek] = useState([]);
  const [isLoadingEstimators, setIsLoadingEstimators] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [changeDay, setChangeDay] = useState(null);
  const [changeEstimator, setChangeEstimator] = useState(null);
  const { canEdit, canAssign } = useInHomeEstimatePermissions();
  const canChooseEstimator = (!existingEstimateService && !canEdit && canAssign) || (canEdit && canAssign);

  useEffect(() => {
    if (estimateDate) {
      setDate(estimateDate);
    } else {
      setDate(startOfDay(new Date()));
    }
  }, [estimateDate]);

  useEffect(() => {
    setIsLoadingEstimators(true);

    if (date) {
      const currentDate = format(date, BACKEND_DATE_FORMAT);
      const startDate = format(startOfWeek(date), BACKEND_DATE_FORMAT);
      const endDate = format(endOfWeek(date), BACKEND_DATE_FORMAT);

      Promise.all([
        servicesAPI.getAvailableEstimatorsByDay({
          responsibility: surveyType === SurveyType.IN_HOME_ESTIMATE ? 'IN_HOME_ESTIMATOR' : 'VIRTUAL_ESTIMATOR',
          date: currentDate,
        }),
        servicesAPI.getAvailableEstimatorsByWeek({
          responsibility: surveyType === SurveyType.IN_HOME_ESTIMATE ? 'IN_HOME_ESTIMATOR' : 'VIRTUAL_ESTIMATOR',
          date_from: startDate,
          date_to: endDate,
        }),
      ])
        .then(([estimatorsByDay, estimatorsByWeek]) => {
          setAvailableEstimatorsByDate(estimatorsByDay.map((item) => getEstimators(item, currentSurveyValue)));
          setAvailableEstimatorsByWeek(estimatorsByWeek.map((item) => getEstimators(item, currentSurveyValue)));
        })
        .catch(() => {
          enqueueSnackbar(`Can't get available estimators for current date`, { variant: 'error' });
        })
        .then(() => {
          setIsLoadingEstimators(false);
        });
    }
  }, [date]);

  const toggleViewMode = () => {
    setDayMode((mode) => !mode);
  };

  const handleChangeSurveyDate = (onClose) => {
    setValue(ESTIMATOR_ID, changeEstimator, { shouldValidate: true });
    setValue(SURVEY_DATE, changeDay, { shouldValidate: true });
    onClose();
  };

  const { setShowAlert, alertProps } = useAlert({
    onConfirm: handleChangeSurveyDate,
  });

  const handleEstimatorClick = (id, day) => {
    if (canChooseEstimator) {
      if (!isSameDay(day, estimateDate)) {
        setShowAlert(true);
        setChangeDay(day);
        setChangeEstimator(id);
      } else {
        setValue(ESTIMATOR_ID, id, { shouldValidate: true });
      }
      trigger();
    }
  };

  return (
    <>
      <SurveySchedulerPanel
        date={date}
        dayMode={dayMode}
        onDateChange={setDate}
        toggleViewMode={toggleViewMode}
        availableEstimatorsByDate={availableEstimatorsByDate}
        availableEstimatorsByWeek={availableEstimatorsByWeek}
        dense={dense}
        handleEstimatorClick={handleEstimatorClick}
        isLoadingEstimators={isLoadingEstimators}
        order={order}
        currentSurveyValue={currentSurveyValue}
        isLoadingSurveyService={isLoadingSurveyService}
        handleCloseModal={handleCloseModal}
      />
      <Modal {...alertProps} title="Warning">
        <Box display="flex" justifyContent="center">
          <BodyBigText>
            Do you really want to change survey date and assign an available estimator for that date?
          </BodyBigText>
        </Box>
      </Modal>
    </>
  );
};

SurveySchedulerBox.propTypes = {
  estimateDate: pt.instanceOf(Date),
  dense: pt.bool,
  order: pt.instanceOf(Order).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSurveyValue: pt.object.isRequired,
  setValue: pt.func.isRequired,
  trigger: pt.func,
  isLoadingSurveyService: pt.bool,
  handleCloseModal: pt.func,
  existingEstimateService: pt.object,
};

SurveySchedulerBox.defaultProps = {
  estimateDate: new Date(),
  dense: true,
  trigger: null,
  isLoadingSurveyService: false,
  handleCloseModal: null,
  existingEstimateService: null,
};
