import React, { useCallback, useEffect, useState } from 'react';

import { ActivitySourceType, BodyText, Select } from '@elromcoinc/react-shared';
import { Box, LinearProgress, List, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import communicationsAPI from 'admin/api/CommunicationsAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { NoteItem } from 'admin/components/OrderWindow/blocks/NoteItem';
import Card from 'common/components/Card';

import NoteModal from '../modals/Note';

const useStyles = makeStyles((theme) => ({
  absoluteContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    right: '16px',
  },
  notesList: {
    maxHeight: '26rem',
    minHeight: '15rem',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5rem 0 .5rem',
    '& > li:nth-child(even)': {
      backgroundColor: theme.palette.grey[200],
    },
    '& > li:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
}));

const ACTIVITY_SOURCES = { CUSTOMER_ACCOUNT: ActivitySourceType.CUSTOMER_ACCOUNT, ORDER: ActivitySourceType.ORDER };

const activity = {
  [ACTIVITY_SOURCES.CUSTOMER_ACCOUNT]: 'account',
  [ACTIVITY_SOURCES.ORDER]: 'order',
};

const errorMessage = { variant: 'error' };
const successMessage = { variant: 'success' };

export default function Notes({ sourceId, activitySource, disabled, ...rest }) {
  const classes = useStyles();
  const [noteType, setNoteType] = useState('ALL');
  const [isDisabledInDelete, setIsDisabledInDelete] = useState(false);
  const [isDisabledInSave, setIsDisabledInSave] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(false);
  const [notes, setNotes] = useState([]);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const handleChangeNoteType = ({ target: { value } }) => setNoteType(value);
  const handleOpenCurrentNote = useCallback((item) => () => setCurrentNote(item), [notes]);
  const handleCloseCurrentNote = () => setCurrentNote(null);

  const noteTypeOptions = {
    options: [
      ['ALL', 'All'],
      ['SALES', 'Sales'],
      ['DISPATCH', 'Dispatch'],
      ['FOREMAN', 'Foreman'],
      ['CUSTOMER', 'Customer'],
    ].filter((option) =>
      activitySource === ActivitySourceType.CUSTOMER_ACCOUNT
        ? !(option[0] === 'DISPATCH' || option[0] === 'FOREMAN')
        : option,
    ),
  };

  const filterNote = (it) => {
    return (
      noteType === 'ALL' ||
      (noteType === 'SALES' && it.involvesSales) ||
      (noteType === 'FOREMAN' && it.involvesForeman) ||
      (noteType === 'DISPATCH' && it.involvesDispatch) ||
      (noteType === 'CUSTOMER' && it.involvesCustomer)
    );
  };

  const fetchNotesById = useCallback(
    (id) => {
      communicationsAPI
        .getNotes(id, activitySource)
        .then(({ pageElements }) => {
          setNotes(pageElements.filter(filterNote).reverse());
        })
        .catch(() => {
          enqueueSnackbar(`Can't get notes for this ${activity[activitySource]}`, errorMessage);
        })
        .then(() => {
          setInFlight(false);
        });
    },
    [activitySource, enqueueSnackbar, noteType],
  );

  useEffect(() => {
    if (!sourceId) return () => {};
    if (!isSessionExpired) {
      setInFlight(true);
      fetchNotesById(sourceId);
      const interval = setInterval(() => fetchNotesById(sourceId), 30e3);
      return () => clearInterval(interval);
    }
  }, [sourceId, fetchNotesById, isSessionExpired]);

  const addNewNote = (note) => setNotes([note, ...notes]);
  const updateCurrentNote = (note) => setNotes(notes.map((it) => (it.id === note.id ? note : it)));

  const handleResponse = (isUpdate) => (note) => {
    setCurrentNote(null);
    setInFlight(false);
    isUpdate ? updateCurrentNote(note) : addNewNote(note);
  };

  const handleSaveNote = (note = {}) => {
    setInFlight(true);
    setIsDisabledInSave(true);
    const newNote = {
      ...note,
      activitySource,
      sourceId,
      activitySources: [{ activitySource, referencedEntityId: sourceId }],
    };

    const isUpdate = !!newNote.id;
    (isUpdate ? communicationsAPI.updateNote(newNote) : communicationsAPI.saveNote(newNote))
      .then(handleResponse(isUpdate))
      .then(() => {
        enqueueSnackbar(
          `Note for current ${activity[activitySource]} ${isUpdate ? 'updated' : 'saved'} successfully`,
          successMessage,
        );
      })
      .catch(() => {
        enqueueSnackbar(
          `Can't ${isUpdate ? 'update' : 'save'} note for this ${activity[activitySource]}`,
          errorMessage,
        );
      })
      .then(() => {
        setIsDisabledInSave(false);
      });
  };

  const handleDeleteResponse = (id) => () => {
    setCurrentNote(null);
    setInFlight(false);
    setNotes(notes.filter((it) => it.id !== id));
  };

  const handleNoteDelete = () => {
    if (!currentNote.id) return;
    setInFlight(true);
    setIsDisabledInDelete(true);
    communicationsAPI
      .updateNote({ ...currentNote, deleted: true })
      .then(handleDeleteResponse(currentNote.id))
      .catch(() => {
        enqueueSnackbar(`Can't delete note for this ${activity[activitySource]}`, errorMessage);
      })
      .then(() => {
        setIsDisabledInDelete(false);
        enqueueSnackbar(`Note for current ${activity[activitySource]} deleted successfully`, successMessage);
      });
  };

  return (
    <Card
      mode="ADD"
      title="Notes"
      minWidth="290px"
      dataTestId="addNotesButton"
      disabled={disabled}
      onAction={() => setCurrentNote({})}
      {...rest}
    >
      <Box className={classes.absoluteContainer}>
        {!inFlight && (
          <>
            <BodyText>Display:&nbsp;</BodyText>
            <Box minWidth={110}>
              <Select
                {...noteTypeOptions}
                fullWidth
                hiddenLabel
                primaryBackgroundOnSelectedItem
                value={noteType}
                disabled={disabled}
                onChange={handleChangeNoteType}
                name="noteType"
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          </>
        )}
      </Box>
      {inFlight && <LinearProgress />}
      {!notes.length && (
        <Box mt={4}>
          <BodyText align="center">No notes to display...</BodyText>
        </Box>
      )}
      <List classes={{ root: classes.notesList }}>
        {notes.map((item) => (
          <NoteItem key={item.id} item={item} handleOpenCurrentNote={handleOpenCurrentNote} noteType={noteType} />
        ))}
      </List>
      {currentNote !== null && (
        <NoteModal
          onCancel={handleCloseCurrentNote}
          onDelete={handleNoteDelete}
          onSave={handleSaveNote}
          open
          note={currentNote}
          isDisabledInSave={isDisabledInSave}
          isDisabledInDelete={isDisabledInDelete}
        />
      )}
    </Card>
  );
}

Notes.propTypes = {
  sourceId: pt.number,
  activitySource: pt.string.isRequired,
  disabled: pt.bool,
};

Notes.defaultProps = {
  sourceId: null,
  disabled: false,
};
