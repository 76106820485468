import React from 'react';

import { BodyBigText, EstimateType, Order, isLongDistanceService } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { GrandTotalFlatRateInput, GrandTotalLongDistanceInput } from 'admin/components/OrderWindow/common';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

export const ClosingGrandTotal = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail : order)!;
  const service = orderDetail.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const isFlatRate = orderDetail.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <BodyBigText>
          <b>Total Cost</b>
        </BodyBigText>
        <Box>
          {(isFlatRate || isLongDistance) && !isSelectedAllServices && order.disableAutoCalculation ? (
            <Box mr={-1}>{isLongDistance ? <GrandTotalLongDistanceInput /> : <GrandTotalFlatRateInput />}</Box>
          ) : (
            <Text
              value={orderDetail.getGrandTotal(serviceIndex, isSelectedAllServices).asHumanReadableString()}
              data-testId={'grandTotalValue'}
            />
          )}
        </Box>
      </Box>
    </Grid>
  );
};
