import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';

interface CompanyInfo {
  basicInfo: { companyName: string };
}

const useCompanyBranchName = () => {
  return useSelector<any, CompanyInfo>(getCompanyInfo)?.basicInfo?.companyName ?? '';
};

export { useCompanyBranchName };
