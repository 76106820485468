import styled from 'styled-components';

import { TimeSlotCellWidth } from './CalendarConstants';

export const Hour = styled.div<{ numHours?: number }>`
  min-width: ${TimeSlotCellWidth}px;
  width: calc(150% / ${({ numHours = 24 }) => numHours});
  border-right: 1px solid #d7d7da;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
`;
