import React, { FC } from 'react';

import { PaymentAdjustmentType } from '@elromcoinc/react-shared';
import { TableBody, createStyles, makeStyles } from '@material-ui/core';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import clsx from 'clsx';

import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import Card from 'common/components/Card';
import { FullWidthBox, OverviewInfoTable } from 'common/components/Widgets';

import { AdditionalCharge } from './AdditionalCharge';
import { AdditionalFees } from './AdditionalFees';
import { AdditionalServices } from './AdditionalServices';
import { AutoCalculate } from './AutoCalculate';
import { Balance } from './Balance';
import { Discount } from './Discount';
import { FuelSurcharge } from './FuelSurcharge';
import { GrandTotal } from './GrandTotal';
import { Materials } from './Materials';
import { MovingCharge } from './MovingCharge';
import { OrderDeposit } from './OrderDeposit';
import { Payment } from './Payment';
import { Storage } from './Storage';
import { Taxes } from './Taxes';
import { Valuation } from './Valuation';

interface QuoteDetailsProps {
  width?: string | null;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    inputWithColorBorder: {
      '& .el-text_input input': {
        border: `1px solid ${theme.palette.primary.main}!important`,
        borderRadius: '0px!important',
      },
    },
  }),
);

const QuoteDetails: FC<QuoteDetailsProps> = ({ width }) => {
  const classes = useStyles();
  return (
    <Card
      title="Quote Details"
      icon={<MonetizationOnIcon />}
      className={clsx('use-input-fixes', classes.inputWithColorBorder)}
      width={width}
    >
      <VideoButton position="absolute" video="SI7fho4WV5M" bottom={0} left={2} />
      <FullWidthBox my={2} data-testId="quoteDetailsSection">
        <OrderDeposit />
        <OverviewInfoTable>
          <TableBody>
            <MovingCharge />
            <FuelSurcharge />
            <Valuation />
            <Storage />
            <Materials />
            <AdditionalServices />
            <AdditionalFees />
            <Discount />
            <Taxes />
            <AdditionalCharge mainType={PaymentAdjustmentType.CREDIT_CARD_FEE} />
            <AdditionalCharge mainType={PaymentAdjustmentType.CASH_DISCOUNT} />
            <AdditionalCharge mainType={PaymentAdjustmentType.CUSTOM_FEE} />
            <GrandTotal />
            <Payment />
            <Balance />
          </TableBody>
        </OverviewInfoTable>
      </FullWidthBox>
      <AutoCalculate />
    </Card>
  );
};

export default QuoteDetails;
