import React from 'react';

import { AuthUserContext, RoleName, hasAnyRole, isAdminRole, useUpdateEffect } from '@elromcoinc/react-shared';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, setIsSessionExpired } from 'admin/autodux/AuthAutodux';
import ApplicationBar from 'admin/components/ApplicationBar';
import { GlobalWindow } from 'admin/components/GlobalWindow';
import { NotificationSnackbar } from 'admin/components/Notifications/NotificationSnackbar';
import { OpeningOrderWindow } from 'admin/components/OrderWindow/SurveySchedulerBox';
import TaskEdit from 'admin/components/Tasks/TaskEdit';
import TaskReminderSnackbar from 'admin/components/Tasks/TaskReminderSnackbar';
import VideoPlayer from 'admin/components/VideoPlayer';
import { managersPositions } from 'admin/constants/PositionTypes';
import { HaveNoPermissionsModal } from 'admin/containers/HaveNoPermissionsModa';
import LoginBackModal from 'admin/containers/LoginBackModal';
import LoginForm from 'admin/containers/LoginForm';

import { useGetGlobalIsSessionExpired } from '../../GlobalVar';
import Wizard from './Wizard';

const AdminRouter = () => {
  const { userDto } = useSelector(({ auth }) => auth);
  const isSessionExpired = useGetGlobalIsSessionExpired();
  const dispatch = useDispatch();
  const isLogout = useSelector(getIsLogout);

  useUpdateEffect(() => {
    dispatch(setIsSessionExpired(isSessionExpired));
  }, [isSessionExpired]);

  const canLoginByPositionType =
    managersPositions.some((role) => userDto?.positionTypes.includes(role)) || isAdminRole(userDto?.role);

  const hasAccess =
    userDto &&
    hasAnyRole(
      userDto,
      RoleName.ROLE_ELROMCO_ADMIN,
      RoleName.ROLE_ELROMCO_USER,
      RoleName.ROLE_COMPANY_ADMIN,
      RoleName.ROLE_COMPANY_EMPLOYEE,
    );

  return (
    <AuthUserContext.Provider value={userDto}>
      <Wizard />
      {(isLogout || !isSessionExpired) && <LoginForm />}
      {!isLogout && isSessionExpired && <LoginBackModal isSessionExpired={isSessionExpired} />}
      <HaveNoPermissionsModal />
      <VideoPlayer />
      <GlobalWindow />
      <OpeningOrderWindow />
      <TaskEdit />
      {!isSessionExpired && <TaskReminderSnackbar />}
      {!isSessionExpired && <NotificationSnackbar />}
      {((hasAccess && canLoginByPositionType) || isSessionExpired) && <ApplicationBar />}
    </AuthUserContext.Provider>
  );
};

export default AdminRouter;
