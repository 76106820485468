enum Rates {
  DISCOUNT = 'DISCOUNT',
  REGULAR = 'REGULAR',
  SUB_PEAK = 'SUB_PEAK',
  PEAK = 'PEAK',
  HIGH_PEAK = 'HIGH_PEAK',
  BLOCKED = 'BLOCKED',
}

export const RatesType: Record<Rates, string> = {
  [Rates.DISCOUNT]: 'DISCOUNT',
  [Rates.REGULAR]: 'REGULAR',
  [Rates.SUB_PEAK]: 'SUB_PEAK',
  [Rates.PEAK]: 'PEAK',
  [Rates.HIGH_PEAK]: 'HIGH_PEAK',
  [Rates.BLOCKED]: 'BLOCKED',
};

export const getRateColor = (rateType: string) => {
  switch (rateType) {
    case Rates.DISCOUNT:
      return '#1FB89A';
    case Rates.REGULAR:
      return '#2196F6';
    case Rates.SUB_PEAK:
      return '#EEBE74';
    case Rates.PEAK:
      return '#FFA500';
    case Rates.HIGH_PEAK:
      return '#E76B6B';
    case Rates.BLOCKED:
      return '#000';
    default:
      return '#FFFFFF';
  }
};

export const getSettingName = (rateType: string) => {
  switch (rateType) {
    case Rates.DISCOUNT:
      return 'gr.res.discount';
    case Rates.REGULAR:
      return 'gr.res.regular';
    case Rates.SUB_PEAK:
      return 'gr.res.sub_peak';
    case Rates.PEAK:
      return 'gr.res.peak';
    case Rates.HIGH_PEAK:
      return 'gr.res.high_peak';
    // case Rates.BLOCKED:
    //   return 'gr.res.blocked';
    default:
      return '';
  }
};

export const getName = (rateType: string) => {
  switch (rateType) {
    case Rates.DISCOUNT:
      return 'Discount';
    case Rates.REGULAR:
      return 'Regular';
    case Rates.SUB_PEAK:
      return 'Subpeak';
    case Rates.PEAK:
      return 'Peak';
    case Rates.HIGH_PEAK:
      return 'Highpeak';
    case Rates.BLOCKED:
      return 'Blocked';
    default:
      return null;
  }
};

export const getIndex = (rateType: string) => {
  switch (rateType) {
    case Rates.DISCOUNT:
      return 1;
    case Rates.REGULAR:
      return 2;
    case Rates.SUB_PEAK:
      return 3;
    case Rates.PEAK:
      return 4;
    case Rates.HIGH_PEAK:
      return 5;
    case Rates.BLOCKED:
      return 6;
    default:
      return null;
  }
};

export const getByIndex = (rateType?: number) => {
  switch (rateType) {
    case 1:
      return Rates.DISCOUNT;
    case 2:
      return Rates.REGULAR;
    case 3:
      return Rates.SUB_PEAK;
    case 4:
      return Rates.PEAK;
    case 5:
      return Rates.HIGH_PEAK;
    case 6:
      return Rates.BLOCKED;
    default:
      return '';
  }
};

export default Rates;
