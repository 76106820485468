/* eslint-disable quote-props */
import React from 'react';

import { BACKEND_DATE_FORMAT, DatePicker } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import Rates, { getByIndex, getRateColor } from 'admin/components/Settings/constants/Rates';

const buildStyle = (color) => ({
  backgroundColor: color,
  color: 'white!important',
  '&:hover': {
    backgroundColor: `${color}!important`,
    opacity: 0.7,
  },
});

export const useStyles = makeStyles((theme) => ({
  day: {
    width: 38,
    height: 38,
    fontSize: theme.typography.caption.fontSize,
    margin: 1,
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    borderRadius: 0,
    padding: 0,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  current: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  daySelected: {
    border: `2px solid ${theme.palette.common.black}`,
    opacity: 0.7,
    borderRadius: '30%',
  },
  dayDisabled: {
    pointerEvents: 'none',
    color: theme.palette.text.hint,
  },
  [Rates.DISCOUNT]: buildStyle(getRateColor(getByIndex(1))),
  [Rates.REGULAR]: buildStyle(getRateColor(getByIndex(2))),
  [Rates.SUB_PEAK]: buildStyle(getRateColor(getByIndex(3))),
  [Rates.PEAK]: buildStyle(getRateColor(getByIndex(4))),
  [Rates.HIGH_PEAK]: buildStyle(getRateColor(getByIndex(5))),
  [Rates.BLOCKED]: buildStyle(getRateColor(getByIndex(6))),
  errorLabel: {
    color: `${theme.palette.error.main} !important`,
    borderColor: `${theme.palette.error.main} !important`,
  },
  datePickerErrorAfter: {
    '&:after': {
      transform: 'scaleX(1)',
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
  },
}));

const isDisabled = (day, rates) => getByIndex(rates[format(day, BACKEND_DATE_FORMAT)]) === Rates.BLOCKED;

const DateInput = ({ rates, ...props }) => {
  const { error = {} } = props;

  return (
    <DatePicker
      {...props}
      format="P"
      fullWidth
      okLabel=""
      cancelLabel="Cancel"
      shouldDisableDate={(day) => isDisabled(day, rates)}
      rates={rates}
      InputProps={{
        className: 'new-order-move-date-input',
        disabled: props.disabled,
      }}
      formErrors={error}
    />
  );
};
DateInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rates: PropTypes.object.isRequired,
};

DateInput.defaultProps = {};

export default DateInput;
