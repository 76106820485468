import React, { useEffect, useState } from 'react';

import { Button, HeaderText, usePrevious } from '@elromcoinc/react-shared';
import { Box, Tooltip } from '@material-ui/core';
import { add, isAfter } from 'date-fns';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getUncompleted, updateTask } from 'admin/autodux/TaskAutodux';
import NativeNotification from 'admin/components/Notifications/NativeNotification';
import useWindowFocus from 'admin/hooks/useWindowFocus';
import { getIsOpenNotificationDrawer } from 'admin/selectors';
import timeToTextFormat from 'admin/utils/timeToTextFormat';
import { Task, TaskStatus } from 'common-types/Task';
import CustomizedSnackbar from 'common/components/CustomizedSnackbar';
import playNotificationSound from 'common/components/PlayNotificationSound';

import TaskReminder from './TaskReminder';
import TaskSnoozePopover from './TaskSnoozePopover';

const TaskReminderSnackbar = () => {
  const focus = useWindowFocus();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [, setTick] = useState(0);
  const [snoozeButtonElement, setSnoozeButtonElement] = useState<HTMLElement | null>(null);
  const isOpenNotificationDrawer = useSelector(getIsOpenNotificationDrawer);
  const tasks = (useSelector(getUncompleted) as List<Task>).filter(
    ({ remindMe, remindTime, snoozedUntil, dueTime, isRead }) =>
      !isRead && isAfter(new Date(), (snoozedUntil || (remindMe ? remindTime : dueTime)) as Date),
  );
  const previousTasks = usePrevious<List<Task>>(tasks, tasks)!;
  const isShowNotification = (previousTasks && tasks && previousTasks.isEmpty() && !tasks.isEmpty()) || null;
  const isClearedNotification = (previousTasks && tasks && !previousTasks.isEmpty() && tasks.isEmpty()) || null;
  const newTasks = tasks.filter((t) => !previousTasks.find((pt) => t.id === pt.id));
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  useEffect(() => {
    if (isShowNotification) {
      playNotificationSound();
    }
  }, [previousTasks, tasks]);

  useEffect(() => {
    if (!isSessionExpired) {
      const remindInterval = setInterval(() => {
        setTick((state) => state + 1);
      }, 30e3);
      return () => clearInterval(remindInterval);
    }
  }, [tasks, isSessionExpired]);

  const handleCloseSnackBar = (_: any, event: any) => setSnoozeButtonElement(event.currentTarget);
  const handleSnoozeClose = () => setSnoozeButtonElement(null);

  useEffect(() => {
    if (isClearedNotification) {
      handleSnoozeClose();
    }
  }, [previousTasks, tasks]);

  const handleSnoozeAll = (snoozeUntil: number) => {
    tasks.forEach((t) => dispatch(updateTask(t.set('snoozedUntil', add(new Date(), { seconds: snoozeUntil })))));
    enqueueSnackbar(`Tasks are snoozed for ${timeToTextFormat({ minutes: snoozeUntil / 60 })}`, {
      variant: 'success',
      autoHideDuration: 1000,
    });
    handleSnoozeClose();
  };
  const handleShowNotification = () => playNotificationSound();

  const handleDismissAll = () => {
    tasks.forEach((t) => dispatch(updateTask(t.setStatus(TaskStatus.READ))));
    enqueueSnackbar('All Tasks are dismissed successfully', { variant: 'success', autoHideDuration: 1000 });
  };

  return (
    <>
      {((!focus && !newTasks.isEmpty()) || null) &&
        newTasks.map((t) => (
          <NativeNotification key={t.id} title={t.subject} body={t.notes || ''} onShow={handleShowNotification} />
        ))}
      {tasks.size ? (
        <>
          <TaskSnoozePopover
            id="tasks-snooze-popover"
            onSnooze={handleSnoozeAll}
            onClose={handleSnoozeClose}
            snoozeElement={snoozeButtonElement}
          />
          <CustomizedSnackbar
            autoHideDuration={null}
            message={
              <>
                <Box display="flex" justifyContent="space-between" alignItems="baseline" sx={{ mr: '1.5rem' }}>
                  <HeaderText color="textPrimary">{`Tasks due (${tasks.size})`}</HeaderText>
                  <Tooltip title="Dismiss All" placement="top">
                    <Box component="span">
                      <Button
                        color="primary"
                        variant="outlined"
                        rounded
                        size="small"
                        textTransform="capitalize"
                        onClick={handleDismissAll}
                      >
                        Dismiss All
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
                <Box maxHeight={400} overflow="auto" px={2} mx={-2}>
                  {tasks.map((t) => (
                    <TaskReminder key={t.id} task={t} />
                  ))}
                </Box>
              </>
            }
            onClose={handleCloseSnackBar}
            type="notification"
            ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: isOpenNotificationDrawer ? 'left' : 'right',
            }}
          />
        </>
      ) : null}
    </>
  );
};

export default TaskReminderSnackbar;
