import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';

const Root = styled(Box)`
  width: 100%;
  align-items: flex-start;
  margin-top: 50px;
  display: flex;
  justify-content: center;
`;

const Form = styled(Paper)<{ maxWidth?: number }>`
  && {
    position: relative;
    margin: 1rem auto;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    min-width: 400px;
    width: 100%;
    border-radius: 6px
    max-width: ${({ maxWidth = 800 }) => maxWidth}px;
    @media (max-width: 600px) {
      margin: 1rem;
      min-width: 250px;
      width: 95%;
    }
  }
`;
const Row = styled(Box)`
  && {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    border-top: 1px solid rgba(224, 224, 224, 1);
    @media (max-width: 400px) {
      flex-direction: column;
    }
    @media (min-width: 400px) {
      align-items: center;
    }
  }
`;

// @ts-ignore
Form.Row = Row;
// @ts-ignore
Form.Root = Root;

export default Form;
