import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, Order, OrdersPermissions, formatCurrency, useHasPermission } from '@elromcoinc/react-shared';
import { Box, TableBody, TableCell, Tooltip, useTheme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styled from 'styled-components';

import { FlexWithNegativeMargin } from 'admin/components/OrderWindow/blocks/CommonComponents';
import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { CurrencyInput, Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { getServicePropertyName } from 'admin/utils';
import { OverviewInfoTable } from 'common/components/Widgets';

const DepositTable = styled(OverviewInfoTable)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  margin: 0 0 10px -5px;
  td:first-child {
    padding: 0 5px;
    width: 165px !important;
  }
`;

export const OrderDeposit = () => {
  const theme = useTheme();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const quote = order.getServiceQuote(serviceIndex);
  const { onChange } = useOrderChangeSet();
  const { isClosing } = useOrderClosingContext();
  const existingDeposit =
    quote.totalDepositPaid || quote.overriddenReservationAmount || quote.reservationAmountNeeded || 0;
  const [deposit, setDeposit] = useState<number | string>(existingDeposit);
  const canEditDepositAmount = useHasPermission(OrdersPermissions.PERM_CAN_EDIT_DEPOSIT_AMOUNT);
  const name = getServicePropertyName(serviceIndex, 'overriddenReservationAmount');

  useEffect(() => {
    setDeposit(existingDeposit);
  }, [existingDeposit]);

  const onChangeDeposit = (deposit: string) => setDeposit(deposit);

  const handleOnBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;
    if (+existingDeposit !== +value) {
      onChange({ name, value });
    }
  };

  const totalDeposit = order.services.reduce(
    (acc, s) =>
      acc + (s.quote.totalDepositPaid || s.quote.overriddenReservationAmount || s.quote.reservationAmountNeeded || 0),
    0,
  );

  const readOnlyDepositAmount = (isSelectedAllServices ? order.totalDepositPaid : quote.totalDepositPaid) || 0;

  return (
    <DepositTable>
      <TableBody>
        <HighlightedTableRow>
          <TableCell size="small" padding="none">
            <BodyText>Deposit</BodyText>
          </TableCell>
          <TableCell size="small" padding="none">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              {quote.totalDepositPaid || isSelectedAllServices ? (
                <Text
                  color={readOnlyDepositAmount ? theme.palette.success.main : theme.palette.common.black}
                  value={`$${formatCurrency(isSelectedAllServices ? totalDeposit : (readOnlyDepositAmount as number))}`}
                />
              ) : (
                <Tooltip title={!canEditDepositAmount ? "You don't have a permission to edit this field" : ''}>
                  <FlexWithNegativeMargin>
                    <Box display="flex" alignItems="center">
                      <CurrencyInput
                        value={deposit!}
                        disabled={!canEditDepositAmount || isClosing}
                        onChange={onChangeDeposit}
                        onBlur={handleOnBlur(+deposit)}
                        name={name}
                      />
                    </Box>
                  </FlexWithNegativeMargin>
                </Tooltip>
              )}
              <Box mr="-3px">
                <Tooltip
                  title={
                    order.orderConfirmationRecordDto.length > 0
                      ? 'Order for service signed by client'
                      : 'Order for service not signed by client'
                  }
                >
                  {order.orderConfirmationRecordDto.length > 0 ? (
                    <Box color={theme.palette.success.main}>
                      <CheckBoxIcon color="inherit" />
                    </Box>
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </Tooltip>
              </Box>
            </Box>
          </TableCell>
        </HighlightedTableRow>
      </TableBody>
    </DepositTable>
  );
};
