import React, { useEffect, useMemo, useState } from 'react';

import {
  BodyBigText,
  BodyText,
  CurrencyInput,
  Modal,
  Product,
  TextInput,
  formatCurrency,
  uuid,
} from '@elromcoinc/react-shared';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOrderClosingContext, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import SettingName from 'admin/constants/SettingName';
import { ContentContainer, ContentTableContainer, TagTableContainer } from 'common/components/Widgets';
import { currencyInputAdapter, maxNumberValueAdapter } from 'common/utils';

const { ADDITIONAL_SERVICE } = SettingName;

const TableCellButton = styled(Button)`
  && {
    text-transform: none;
    background-color: #f5f5f6;
    width: calc(100% - 8px);
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    justify-content: flex-start;
    text-align: left;
  }
`;

const TotalLabel = styled(BodyText)`
  && {
    width: 50px;
  }
`;

const HeaderTitleRow = styled(TableRow)`
  && {
    th:nth-child(1) {
      min-width: 280px;
    }
    th:nth-child(2) {
      min-width: 120px;
    }
    th:nth-child(3) {
      min-width: 80px;
    }
    th:nth-child(4) {
      min-width: 100px;
    }
  }
`;

const useStyles = makeStyles(({ spacing }) => ({
  table: {
    '&.MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

const customServiceName = 'Custom Service';
const customServiceId = -1;
const errorMessage = 'Item is a required field';

const AdditionalServices = ({ onSave, onCancel, open, moveType, serviceType, additionalServices }) => {
  const classes = useStyles();
  const state = useOrderWindowSettings();
  const { closingTitle, isCompleted, isLockSales } = useOrderClosingContext();
  const [additionalServicesByServiceTypeAndMoveType, setAdditionalServicesByServiceTypeAndMoveType] = useState(null);
  const [bodyTableData, setBodyTableData] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isLocalMove, setIsLocalMove] = useState(null);
  const [errorObj, setErrorObj] = useState({});
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);

  useEffect(() => {
    if (additionalServicesByServiceTypeAndMoveType && additionalServices) {
      const priceReadOnlyArray = additionalServicesByServiceTypeAndMoveType.filter(
        (additionalService) => additionalService.priceReadOnly,
      );
      const quantityReadOnlyArray = additionalServicesByServiceTypeAndMoveType.filter(
        (additionalService) => additionalService.quantityReadOnly,
      );
      const tempBodyTableData = additionalServices.toJS().map(
        (item) =>
          ({
            ...item,
            lineTotal: item.price * item.quantity,
            priceReadOnly: priceReadOnlyArray.some((i) => i.id === item.id),
            quantityReadOnly: quantityReadOnlyArray.some((i) => i.id === item.id),
          } || []),
      );
      setBodyTableData(tempBodyTableData);
      setGrandTotal(tempBodyTableData.reduce((sum, it) => sum + it.lineTotal, 0));
    }
  }, [additionalServices, additionalServicesByServiceTypeAndMoveType]);

  useEffect(() => {
    if (moveType && serviceType && state) {
      const additionalServiceData = state[ADDITIONAL_SERVICE].filter((item) => {
        return item.moveType === moveType;
      });
      setAdditionalServicesByServiceTypeAndMoveType([
        { id: customServiceId, name: customServiceName },
        ...additionalServiceData,
      ]);
      setIsLocalMove(serviceType !== 'LONG_DISTANCE_MOVE');
    }
  }, [moveType, serviceType, state]);

  const addAdditionalServiceRow = (item) => () => {
    const unitCostPerServiceType = isLocalMove ? item.cost : item.cost;
    const isExisting = bodyTableData.find((row) => row.id === item.id && row.name === item.name);
    if (item.name === customServiceName) {
      setShouldAutoFocus(true);
    }
    const newBodyTableData = isExisting
      ? bodyTableData.map((row) =>
          row.id === item.id && row.name === item.name
            ? {
                ...row,
                quantity: (Number(row.quantity) || 0) + 1,
                lineTotal: ((Number(row.quantity) || 0) + 1) * (Number(row.price) || 0),
              }
            : row,
        )
      : [
          ...bodyTableData,
          {
            id: item.id,
            name: item.name === customServiceName ? '' : item.name,
            price: item.cost || 0,
            quantity: item.quantity || 1,
            lineTotal: (unitCostPerServiceType || 0) * (item.quantity || 0),
            priceReadOnly: item.priceReadOnly,
            quantityReadOnly: item.quantityReadOnly,
            representsAddedFee: item.representsAddedFee,
          },
        ];
    setBodyTableData(newBodyTableData);
    setGrandTotal(newBodyTableData.reduce((sum, it) => sum + it.lineTotal, 0));
  };

  const validateValue = (value) => {
    const regex = /^-?\d{1,6}(\.\d{0,2})?$/;
    return regex.test(value);
  };

  const changeAdditionalServiceDataHandler =
    (index) =>
    ({ target: { name, value } }) => {
      setShouldAutoFocus(true);
      if (name === 'id') {
        setBodyTableData(bodyTableData.map((item, ix) => (ix === index ? { ...item, name: value } : item)));
      } else {
        if (value !== '' && !validateValue(value)) {
          return;
        }

        const modifiedRowCategoryData = { ...bodyTableData[index], [name]: value };
        const lineTotal = Number(modifiedRowCategoryData.quantity) * (modifiedRowCategoryData.price || 0);
        const newRow = {
          ...modifiedRowCategoryData,
          lineTotal,
        };
        const newBodyTableData = bodyTableData.map((item, ix) => (ix === index ? newRow : item));
        setBodyTableData(newBodyTableData);
        setGrandTotal(newBodyTableData.reduce((sum, item) => sum + item.lineTotal, 0));
      }
    };

  const deleteAdditionalServiceRow = (index) => () => {
    const newBodyTableData = bodyTableData.filter((item, ix) => index !== ix);
    setBodyTableData(newBodyTableData);
    setGrandTotal(newBodyTableData.reduce((sum, item) => sum + item.lineTotal, 0));
  };

  function getFormattedData() {
    if (!bodyTableData?.length) {
      return [];
    }

    return bodyTableData.map(
      (row) =>
        new Product({
          description: row.name,
          id: row.id,
          price: +row.price || 0,
          quantity: +row.quantity || 0,
          name: row.name,
          representsAddedFee: row.representsAddedFee,
        }),
    );
  }

  const validationCustomService = () => {
    if (getFormattedData().some((item) => item.toJS()?.name === '')) {
      setErrorObj(
        getFormattedData().reduce(
          (acc, item, index) => ({ ...acc, [index]: item.name === '' ? errorMessage : null }),
          {},
        ),
      );

      return false;
    }
    setErrorObj({});
    return true;
  };
  const saveHandler = () => {
    if (validationCustomService()) {
      const data = getFormattedData();
      onSave(List(data));
    }
  };

  const rowKeys = useMemo(() => bodyTableData?.map(uuid) ?? [], [bodyTableData?.length]);

  const actions = [
    { label: 'cancel', onClick: onCancel },
    { label: 'save', onClick: saveHandler },
  ];

  return (
    <Modal open={open} title={`Additional Services${closingTitle}`} onClose={onCancel} actions={actions} maxWidth="md">
      <Box display="flex">
        <TagTableContainer component={Paper} className="applyRadius" elevation={4}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="applyTopRadius" colSpan={3}>
                  <BodyBigText>Click to Add</BodyBigText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {additionalServicesByServiceTypeAndMoveType?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <TableCellButton disabled={isCompleted || isLockSales} onClick={addAdditionalServiceRow(item)}>
                      {item.name}
                    </TableCellButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TagTableContainer>
        <ContentContainer display="flex" flexDirection="column" ml={1}>
          <ContentTableContainer component={Paper} square elevation={4} className={classes.table}>
            <Table aria-label="simple table">
              <TableHead className="applyRadius">
                <HeaderTitleRow>
                  <TableCell className="applyLeftRadius">
                    <BodyBigText>Item</BodyBigText>
                  </TableCell>
                  <TableCell>
                    <BodyBigText>Unit Cost</BodyBigText>
                  </TableCell>
                  <TableCell>
                    <BodyBigText>Qty</BodyBigText>
                  </TableCell>
                  <TableCell>
                    <BodyBigText>Line Total</BodyBigText>
                  </TableCell>
                  <TableCell className="applyRightRadius" />
                </HeaderTitleRow>
              </TableHead>
              <TableBody>
                {bodyTableData?.map((item, index) => (
                  <TableRow key={rowKeys[index]}>
                    <TableCell>
                      {item.representsAddedFee ? (
                        <BodyText>{item.name}</BodyText>
                      ) : (
                        <TextInput
                          size="small"
                          autoFocus={shouldAutoFocus}
                          hiddenLabel
                          fullWidth
                          formError={errorObj[index]}
                          name="id"
                          value={item.name}
                          disabled={isCompleted || isLockSales}
                          onChange={changeAdditionalServiceDataHandler(index)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.representsAddedFee ? (
                        <BodyText>{`$${formatCurrency(item.price || 0)}`}</BodyText>
                      ) : (
                        <CurrencyInput
                          size="small"
                          hiddenLabel
                          fullWidth
                          name="price"
                          value={item.price}
                          InputProps={{
                            min: 0,
                          }}
                          disabled={item.priceReadOnly || isCompleted || isLockSales}
                          onChange={currencyInputAdapter(changeAdditionalServiceDataHandler(index))}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.representsAddedFee ? (
                        <BodyText>{item.quantity}</BodyText>
                      ) : (
                        <CurrencyInput
                          size="small"
                          hiddenLabel
                          fullWidth
                          value={item.quantity}
                          name="quantity"
                          allowNegativeValue={false}
                          maxLength={10}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"> </InputAdornment>,
                            maxLength: 10,
                          }}
                          inputProps={{ maxLength: 10 }}
                          disabled={isCompleted || isLockSales}
                          onChange={currencyInputAdapter(
                            maxNumberValueAdapter(changeAdditionalServiceDataHandler(index), '9999999999'),
                          )}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <TotalLabel>{`$${formatCurrency(item.lineTotal || 0)}`}</TotalLabel>
                    </TableCell>
                    <TableCell>
                      {!item.representsAddedFee && (
                        <IconButton
                          disabled={isCompleted || isLockSales}
                          size="small"
                          onClick={deleteAdditionalServiceRow(index)}
                        >
                          <DeleteIcon fontSize="default" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ContentTableContainer>
          {bodyTableData && bodyTableData.length > 0 && (
            <Paper elevation={4} className="totalTag">
              <BodyText>
                <b>Total</b>
              </BodyText>
              <BodyText>{`$${formatCurrency(grandTotal)}`}</BodyText>
            </Paper>
          )}
        </ContentContainer>
      </Box>
    </Modal>
  );
};

AdditionalServices.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  serviceType: PropTypes.string,
  moveType: PropTypes.string,
  additionalServices: PropTypes.object.isRequired,
};

AdditionalServices.defaultProps = {
  serviceType: 'LONG_DISTANCE_MOVE',
  moveType: 'RESIDENTIAL',
};

export default AdditionalServices;
