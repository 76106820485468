import React, { useEffect, useState } from 'react';

import { AdminPermissions, useHasPermission } from '@elromcoinc/react-shared';
import { Box, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import customerOnlineApi from 'admin/api/CustomerOnlineApi';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { useOrderState } from 'admin/components/OrderWindow/context';
import Circle from 'common/components/Circle';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1.75),
    boxShadow: '2px 2px 2px rgb(0 0 0 / 10%)',
  },
}));

export const OrderCustomerOnline = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { order } = useOrderState();
  const canSeeCustomerOnline = useHasPermission(AdminPermissions.PERM_CAN_SEE_CUSTOMERS_ONLINE) as boolean;
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  const [customerActive, setCustomerActive] = useState(false);

  useEffect(() => {
    if (!isSessionExpired) {
      const findCurrentOrder = () => {
        if (order?.customerInfo?.customerId) {
          customerOnlineApi.getCustomerOnlineById(order.customerInfo?.customerId).then((res) => {
            setCustomerActive(!!res);
          });
        }
      };

      setTimeout(findCurrentOrder, 1000);
      const interval = setInterval(findCurrentOrder, 30000);
      return () => clearInterval(interval);
    }
  }, [isSessionExpired]);

  return (
    canSeeCustomerOnline && (
      <Tooltip title={customerActive ? 'Customer is online' : 'Customer is offline'}>
        <Box ml={-1} mr={2} className={classes.root}>
          <Circle
            color={customerActive ? theme.palette.success.main : theme.palette.grey.A100}
            width="10px"
            height="10px"
          />
        </Box>
      </Tooltip>
    )
  );
};
