const RESIDENTIAL = 'RESIDENTIAL';
const COMMERCIAL = 'COMMERCIAL';
const MILITARY = 'MILITARY';

/**
 * @deprecated please use it from react-ui-kit
 */
const MoveType = Object.freeze({
  [RESIDENTIAL]: 'RESIDENTIAL',
  [COMMERCIAL]: 'COMMERCIAL',
  [MILITARY]: 'MILITARY'
});

/**
 * @deprecated please use it from react-ui-kit
 */
const MoveTypeShort = Object.freeze({
  [RESIDENTIAL]: 'res',
  [COMMERCIAL]: 'com',
  [MILITARY]: 'mil'
});

/**
 * @deprecated please use it from react-ui-kit
 */
const MoveTypeName = Object.freeze({
  [RESIDENTIAL]: 'Residential',
  [COMMERCIAL]: 'Commercial',
  [MILITARY]: 'Military'
});

export default MoveType;

export { MoveType, MoveTypeName, MoveTypeShort };
