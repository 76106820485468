import { useQuery } from 'admin/hooks/useQuery';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { useDispatch } from 'react-redux';
import { ORDER_ID_QUERY_PARAM } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { useEffect } from 'react';

export const OpeningOrderWindow = () => {
  const dispatch = useDispatch();
  const query = useQuery();
  const orderId = query.get(ORDER_ID_QUERY_PARAM);

  useEffect(() => {
    if (orderId) {
      dispatch(openOrder(orderId));
    }
  }, []);

  return null;
};
