import React, { useEffect, useRef, useState } from 'react';

import { ActivitySourceType, DataTable, DataTableColumnDef, useConfirm } from '@elromcoinc/react-shared';
import { endOfDay, endOfWeek, startOfDay, startOfWeek, subYears } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { getReloadAmount, increaseReloadAmount, setInFlight } from 'admin/autodux/ReloadAutodux';
import { deleteTask, fetchTasksByQuery, getTasksByQuery, setEdit, updateTask } from 'admin/autodux/TaskAutodux';
import { openAccount, openOrder } from 'admin/autodux/WindowsAutodux';
import { EMPLOYEE_ID, STATUS, TaskFilters } from 'admin/components/Tasks/TaskStatusLabel';
import { useMobile } from 'admin/hooks/useMobile';
import { Task, TaskStatus } from 'common-types/Task';

import { TaskType } from './TaskConstants';
import { makeColumns } from './makeColumns';

interface TaskQuery extends FilterParams {
  due?: string[];
  [EMPLOYEE_ID]?: number;
  [STATUS]?: TaskStatus[];
}

interface TasksTableProps {
  type: TaskType;
  taskFilters: TaskFilters;
}

export const TasksTable = ({ type, taskFilters }: TasksTableProps) => {
  const isMobile = useMobile();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<DispatchPromise>();
  const pageRef = useRef(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rowsChecked, setRowsChecked] = React.useState<string[]>([]);
  const [sortBy, setSortBy] = useState('due');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const tasksByQuery = useSelector(getTasksByQuery) as PageDTO<Task>;
  const onRowCheckChange = (value: string[]) => setRowsChecked(value);
  const reloadAmount = useSelector(getReloadAmount) as number;
  const tasks = tasksByQuery.pageElements as Task[];
  const { confirm, renderDialog } = useConfirm({
    title: 'Remove task',
    message: 'You are about to delete this task. Are you sure you will like to continue?',
    confirmTitle: 'Yes',
    cancelTitle: 'No',
    maxWidth: 'xs',
  });

  const setPage = (page: number) => {
    pageRef.current = page < 1 ? 1 : page;
  };

  const handleDeleteTask = (task: Task) => async () => {
    const confirmed = await confirm();

    if (!confirmed) {
      return;
    }

    dispatch(deleteTask(task)).then(() => {
      dispatch(increaseReloadAmount());
    });
  };

  const handleEditTask = (task: Task) => () => {
    dispatch(setEdit(task));
  };

  const handleOpenOrderOrAccount = (entityId: number, activeSource: ActivitySourceType) => {
    if (activeSource === ActivitySourceType.ORDER) {
      dispatch(openOrder(entityId));
    } else {
      dispatch(openAccount(entityId));
    }
  };

  const handleToggleCompleted = (task: Task) => () => {
    const newTask = task.setStatus(task.isCompleted ? TaskStatus.READ : TaskStatus.COMPLETED);

    dispatch(updateTask(newTask, task))
      .then(() => {
        enqueueSnackbar('Task is updated successfully', {
          variant: 'success',
        });
      })
      .then(() => {
        dispatch(increaseReloadAmount());
      });
  };

  const columns = makeColumns(tasks, {
    onDeleteTask: handleDeleteTask,
    onEditTask: handleEditTask,
    onToggleCompleted: handleToggleCompleted,
    handleOpenOrderOrAccount,
  }) as DataTableColumnDef[];

  useEffect(() => {
    setPage(tasksByQuery.pageIndex);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [type]);

  useEffect(() => {
    const query: TaskQuery = {
      pageIndex: pageRef.current - 1,
      pageSize: rowsPerPage,
      sortBy,
      sortOrder,
    };

    if (type === TaskType.DUE_TODAY) {
      query.due = [startOfDay(new Date()).toISOString(), endOfDay(new Date()).toISOString()];
    }

    if (type === TaskType.DUE_THIS_WEEK) {
      query.due = [
        startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
        endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
      ];
    }

    if (type === TaskType.OVERDUE) {
      query.due = [subYears(new Date(), 10).toISOString(), new Date().toISOString()];
    }

    if (taskFilters?.[EMPLOYEE_ID]) {
      query[EMPLOYEE_ID] = taskFilters?.[EMPLOYEE_ID];
    }

    if (type !== TaskType.COMPLETED) {
      query.status = taskFilters?.[STATUS] ? taskFilters?.[STATUS] : [TaskStatus.READ, TaskStatus.UNREAD];
    }

    if (type === TaskType.COMPLETED) {
      query.status = [TaskStatus.COMPLETED];
    }

    dispatch(setInFlight(true));
    dispatch(fetchTasksByQuery({ ...taskFilters, ...query })).then(() => {
      dispatch(setInFlight(false));
    });
  }, [type, reloadAmount, pageRef.current, rowsPerPage, sortBy, sortOrder, dispatch, taskFilters]);

  const onColumnSortChange = (changedColumn: string, direction: 'asc' | 'desc') => {
    setSortBy(changedColumn);
    setSortOrder(direction);
    setRowsChecked([]);
  };

  const tableOptions = {
    onRowCheckChange,
    showExpandableRowsOnMobile: true,
    selectableRowsHideCheckboxesCustom: true,
    rowsChecked,
    selectableRows: 'none',
    tableBodyHeight: '100%',
    smallPadding: isMobile,
    serverSide: true,
    page: pageRef.current - 1,
    rowsPerPage,
    onChangeRowsPerPage: (rpp: number) => {
      setPage(1);
      setRowsPerPage(rpp);
      setRowsChecked([]);
    },
    onColumnSortChange,
    sortOrder: {
      name: sortBy,
      direction: sortOrder,
    },
    inFlight: false,
    count: tasksByQuery.totalElements,
    onChangePage: (p: number) => {
      setPage(p + 1);
      setRowsChecked([]);
    },
    alternateTableRowColors: true,
  };

  return (
    <>
      {renderDialog()}
      <DataTable options={tableOptions} columns={columns} data={tasks} />
    </>
  );
};
