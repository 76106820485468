import React from 'react';

import { DataTable, HeaderSmallText, IconButton } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { makeLicensedUsersColumns } from 'admin/components/PlanSettings/utils/makeLicensedUsersColumns';
import { dataOfLicensedUsers } from 'admin/components/PlanSettings/utils/mockTableData';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingBottom: '30px',
  },
  accountText: {
    margin: 0,
    marginBottom: theme.spacing(3),
  },
  accountBoldText: {
    fontWeight: 'bold',
  },
  accountButton: {
    marginTop: theme.spacing(3),
    fontSize: '0.875rem',
    borderRadius: '22px',
  },
  addIcon: {
    position: 'absolute',
    bottom: 10,
    right: -15,
    marginTop: theme.spacing(4.125),
    [theme.breakpoints.down('xs')]: {
      right: -10,
    },
  },
}));

const AccountUsersTab = () => {
  const classes = useStyles();
  const options = {
    tableHeadPrimaryColor: true,
    selectableRowsHideCheckboxesCustom: true,
    smallPadding: true,
    selectableRows: 'none',
  };

  const columns = makeLicensedUsersColumns(dataOfLicensedUsers);
  const handleClick = () => {};

  return (
    <Box className={classes.root}>
      <HeaderSmallText className={classes.accountText}>
        <b>4 Users&nbsp;</b>licensed to use this software
      </HeaderSmallText>
      <DataTable data={dataOfLicensedUsers} columns={columns} options={options} />
      <Box className={classes.addIcon}>
        <IconButton color="primary" variant="default" size="medium" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export { AccountUsersTab };
