import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const Circle = styled(Box)`
  border-radius: 50%;
  width: ${({ width }) => width || '20px'};
  height: ${({ height }) => height || '20px'};
  background-color: ${({ color }) => color};
`;

export default Circle;
