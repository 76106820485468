import { MoveType, MoveTypeShort } from '@elromcoinc/react-shared';

export const getDisplayQuoteOnCustomerPage = (settings: any, serviceId: number, moveType: MoveType) => {
  const calculatorQuoteDisplayName = `calculator.quote_display.${MoveTypeShort[moveType]}`;

  if (settings[calculatorQuoteDisplayName]) {
    let calculatorQuoteSetting = { showOnForm: true, showOnCustomerPage: true };
    try {
      calculatorQuoteSetting = JSON.parse(settings[calculatorQuoteDisplayName])[serviceId];
    } catch (e) {}

    return calculatorQuoteSetting?.showOnCustomerPage ?? true;
  }

  return true;
};
