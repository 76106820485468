import SettingNames from 'admin/constants/SettingName';
import { EstimateTypeName } from 'admin/constants/EstimateType';

const { SERVICE_FLAT_RATE_ENABLED_SETTING } = SettingNames;

const getArrayOfEstimateTypeSettings = settings => (settings || {})[SERVICE_FLAT_RATE_ENABLED_SETTING] || [];

const has = (moveType, serviceType) => setting => setting.moveType === moveType && setting.serviceType === serviceType;

const getEstimateTypeByMoveAndServiceTypes = (settings, moveType, serviceType) => {
  const estimateTypeSettings = getArrayOfEstimateTypeSettings(settings).filter(has(moveType, serviceType))[0];
  return estimateTypeSettings ? estimateTypeSettings.estimateType : null;
};

const defaultEstimateTypeOptions = Object.entries(EstimateTypeName);

const useServiceEstimateTypeSetting = (settings, saveValue, moveType, serviceType) => {
  const value = getEstimateTypeByMoveAndServiceTypes(settings, moveType, serviceType);
  const updateValue = estimateType => {
    const oldValues = getArrayOfEstimateTypeSettings(settings);
    if (!oldValues.length) {
      return;
    }
    const newServiceFlatRateSetting = oldValues.map(setting =>
      has(moveType, serviceType)(setting) ? { ...setting, estimateType } : setting
    );
    saveValue({ name: SERVICE_FLAT_RATE_ENABLED_SETTING, value: newServiceFlatRateSetting });
  };

  return [value, updateValue, defaultEstimateTypeOptions];
};

export default useServiceEstimateTypeSetting;
