import { JobType } from '@elromcoinc/react-shared';
import axios from 'axios';

import {
  DepartmentPayroll,
  JobPayrollDto,
  PayrollApplyPaymentDto,
  PayrollEmployeePaymentDto,
} from 'common-types/payroll';
import { EmployeeOfDepartmentPayrollDto } from 'common-types/payroll/EmployeeOfDepartmentPayrollDto';
import { EmployeePayrollDto } from 'common-types/payroll/EmployeePayrollDto';

class PayrollApi {
  createJobPayroll(data: JobPayrollDto) {
    return axios.post<JobPayrollDto, JobPayrollDto>('/api/dispatch/payroll', data);
  }

  updateJobPayroll(data: JobPayrollDto) {
    return axios.put<JobPayrollDto, JobPayrollDto>(`/api/dispatch/payroll/${data.id}`, data);
  }

  getJobPayroll(jobId: number, type: JobType) {
    return axios.get<JobPayrollDto, JobPayrollDto>(`/api/dispatch/payroll/job/${jobId}`, {
      params: { type },
    });
  }

  getJobPayrolls(jobId: number) {
    return axios.get<JobPayrollDto[], JobPayrollDto[]>(`/api/dispatch/payroll/job/payrolls/${jobId}`);
  }

  getPayrollDepartments(
    params: Pick<PageParams, 'pageIndex' | 'pageSize' | 'sortBy' | 'sortOrder' | 'startDate' | 'endDate'>,
  ) {
    return axios.get<DepartmentPayroll[], DepartmentPayroll[]>('/api/dispatch/payroll/departments', { params });
  }

  getPayrollEmployeesByDepartment(departmentId: number, params: Pick<PageParams, 'startDate' | 'endDate'>) {
    return axios.get<EmployeeOfDepartmentPayrollDto[], EmployeeOfDepartmentPayrollDto[]>(
      `/api/dispatch/payroll/departments/${departmentId}`,
      { params },
    );
  }

  getPayrollByEmployee(employeeId: number, params: Pick<PageParams, 'startDate' | 'endDate'>) {
    return axios.get<EmployeePayrollDto[], EmployeePayrollDto[]>(`/api/dispatch/payroll/employees/${employeeId}`, {
      params,
    });
  }

  getPayrollEmployeePayments(employeeId: number, params: Pick<PageParams, 'startDate' | 'endDate'>) {
    return axios.get<PayrollEmployeePaymentDto[], PayrollEmployeePaymentDto[]>(
      `/api/dispatch/payroll/payrollEmployeePayments/employees/${employeeId}`,
      { params },
    );
  }

  getPayrollEmployeePaymentById(id: number) {
    return axios.get<PayrollEmployeePaymentDto, PayrollEmployeePaymentDto>(
      `/api/dispatch/payroll/payrollEmployeePayments/${id}`,
    );
  }

  deleteEmployeeFromPayroll(payrollId: number, employeeId: number) {
    return axios.delete(`/api/dispatch/payroll/${payrollId}/employees/${employeeId}`);
  }

  savePayrollEmployeePayment(data: PayrollEmployeePaymentDto) {
    return data.id
      ? axios.put<PayrollEmployeePaymentDto, PayrollEmployeePaymentDto>(
          `/api/dispatch/payroll/payrollEmployeePayments/${data.id}`,
          data,
        )
      : axios.post<PayrollEmployeePaymentDto, PayrollEmployeePaymentDto>(
          '/api/dispatch/payroll/payrollEmployeePayments',
          data,
        );
  }

  deletePayrollEmployeePayment(id: number) {
    return axios.delete(`/api/dispatch/payroll/payrollEmployeePayments/${id}`);
  }

  getPayrollApplyPayment(employeeId: number, params: Pick<PageParams, 'startDate' | 'endDate'>) {
    return axios.get<PayrollApplyPaymentDto[], PayrollApplyPaymentDto[]>(
      `/api/dispatch/payroll/applyPayment/employees/${employeeId}`,
      { params },
    );
  }

  getPayrollApplyPaymentById(id: number) {
    return axios.get<PayrollApplyPaymentDto, PayrollApplyPaymentDto>(`/api/dispatch/payroll/applyPayment/${id}`);
  }

  savePayrollApplyPayment(data: PayrollApplyPaymentDto) {
    return data.id
      ? axios.put<PayrollApplyPaymentDto, PayrollApplyPaymentDto>(`/api/dispatch/payroll/applyPayment/${data.id}`, data)
      : axios.post<PayrollApplyPaymentDto, PayrollApplyPaymentDto>('/api/dispatch/payroll/applyPayment', data);
  }

  deletePayrollApplyPayment(id: number) {
    return axios.delete(`/api/dispatch/payroll/applyPayment/${id}`);
  }
}

export default new PayrollApi();
