import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  100% { transform: rotate(1turn); }
`;

const Container = styled.div`
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 18px);
  left: calc(50% - 18px);
  background-image: url(/elromco_logo.png);
  background-size: cover;
  animation-name: ${animation};
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0;
`;

export default Container;
