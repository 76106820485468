import React, { FC } from 'react';

import { Button } from '@elromcoinc/react-shared';
import { Box, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    buttonGroup: {
      boxShadow: 'none',
      backgroundColor: theme.palette.grey[100],
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(1),
      display: 'flex',
    },
    activeButton: {
      backgroundColor: theme.palette.common.white,
    },
    button: {
      borderRadius: theme.spacing(1),
    },
  }),
);

enum MoveDetailsMode {
  PICKUP = 'PICKUP',
  DELIVERY = 'DELIVERY',
}

interface PickupDeliverySwitchProps {
  value: MoveDetailsMode;
  onChange: (newValue: MoveDetailsMode) => void;
}

const PickupDeliverySwitch: FC<PickupDeliverySwitchProps> = ({ value, onChange }) => {
  const classes = useStyles();
  const handleButtonClick = (mode: MoveDetailsMode) => () => {
    onChange(mode);
  };

  return (
    <Box my={1} mt={-2} display="flex">
      <Box className={classes.buttonGroup}>
        <Box mr={0.5}>
          <Button
            size="small"
            variant="text"
            textTransform="capitalize"
            onClick={handleButtonClick(MoveDetailsMode.PICKUP)}
            className={clsx({ [classes.activeButton]: value === MoveDetailsMode.PICKUP }, classes.button)}
            color="default"
            data-testid="pickupMode"
          >
            Pickup
          </Button>
        </Box>
        <Box ml={0.5}>
          <Button
            data-testid="deliveryMode"
            size="small"
            variant="text"
            textTransform="capitalize"
            onClick={handleButtonClick(MoveDetailsMode.DELIVERY)}
            className={clsx({ [classes.activeButton]: value === MoveDetailsMode.DELIVERY }, classes.button)}
            color="default"
          >
            Delivery
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PickupDeliverySwitch;
export { MoveDetailsMode };
