import axios from 'axios';

class GeoApi {
  searchByZip<T = any>(zip: string) {
    return axios.get<T, T>(`/api/geo/address/${zip}`);
  }

  getGeoAddress<T = any>(addressQuery: string) {
    return axios.get<T, T>(`/api/geo/address/${addressQuery}`);
  }
}

export default new GeoApi();
