export const ACTION_FOR_SELECTED = 'actionForSelected';
export const ACCOUNT_TYPE = 'type';
export const SALES_PERSON = 'manager';
export const SOURCE = 'leadSource';
// (not MVP) export const FLAGS = 'customer.flags';
export const BASED_STATE = 'customer.address.state';
export const ALL_STATUSES = 'allStatuses';
export const SEARCH_CUSTOMER_ACCOUNTS = 'searchCustomerAccounts';
export const STATUS = 'active';
export const CREATED = 'created';

export const AccountsLabels = {
  [ACTION_FOR_SELECTED]: 'Action for selected items',
  [ACCOUNT_TYPE]: 'Account Type',
  [SALES_PERSON]: 'Sales Person',
  [SOURCE]: 'Source',
  // (not MVP) [FLAGS]: 'Flags',
  [BASED_STATE]: 'Based State',
  [ALL_STATUSES]: 'All statuses',
  [SEARCH_CUSTOMER_ACCOUNTS]: 'Search Customer Accounts',
  [STATUS]: 'Status',
  [CREATED]: 'Created date',
};

export const ACCOUNT_TYPE_CORPORATE_TAB_INDEX = 0;
export const ACCOUNT_TYPE_AFFILIATE_TAB_INDEX = 1;
export const ACCOUNT_TYPE_PERSONAL_TAB_INDEX = 2;

// while implementing Flags filter don't forget to add FLAGS to accountFilterInputName export
export const accountFilterInputName = [ACCOUNT_TYPE, SALES_PERSON, SOURCE, BASED_STATE, STATUS];
