import React, { useState } from 'react';

import { BodyText, Link, Order } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { Taxes as TaxesModal } from 'admin/components/OrderWindow/modals/Taxes';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

enum Modals {
  TAXES = 'TAXES',
}

export const ClosingTaxes = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const taxInfo = order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.taxInfo;

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.TAXES)}>
          Taxes
        </Link>
        <BodyText>
          {order.closingOrderDetail?.getTaxesTotal(serviceIndex, isSelectedAllServices)?.asHumanReadableString()}
        </BodyText>
      </Box>
      {Modals.TAXES === openModal && <TaxesModal open onClose={toggleOpenModal(null)} taxInfo={taxInfo} />}
    </Grid>
  );
};
