import React, { useEffect, useMemo, useState } from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodyBigText,
  DELIVERY_DATE_END,
  DELIVERY_DATE_START,
  Modal,
  MoveUnit,
  OverridableValue,
  SettingNames,
  TruckAssignmentDto,
  isLongDistanceService,
  statusIds,
  toDate,
  useAlert,
  useConfirm,
} from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { addDays, addSeconds, format, isSameDay, parse, startOfWeek } from 'date-fns';
import isWithinInterval from 'date-fns/isWithinInterval';
import { List, getIn } from 'immutable';
import { useSnackbar } from 'notistack';

import SchedulerBox from 'admin/components/OrderWindow/SchedulerBox/SchedulerBox';
import { useSchedulerContext } from 'admin/components/OrderWindow/SchedulerBox/SchedulerManager';
import { useAllJobsWithCurrentOrder } from 'admin/components/OrderWindow/SchedulerBox/hooks/useAllJobsWithCurrentOrder';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
  useOrderWindowEditModal,
} from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import { OrderWindowModal, TripPlanerType } from 'common-types';
import { isNoOverlapTime } from 'common/utils';

import useOrderSizing from '../hooks/useOrderSizing';

const { GENERAL_SERVICES } = SettingNames;

const daysOfWeek = [0, 1, 2, 3, 4, 5, 6];

export interface TruckSchedulerBoxProps {
  dense?: boolean;
  longDistancePlanner?: TripPlanerType;
  deliveryDate?: string;
  moveDate?: string;
  startTimeEarliest?: string;
  arrivalWindow: string;
  orderId?: number;
  numberOfTrucks: OverridableValue;
  onChange: (change: { name: string; value: any }) => void;
  isOnModal?: boolean;
}

const TruckSchedulerBox = ({
  moveDate,
  orderId,
  numberOfTrucks,
  longDistancePlanner,
  deliveryDate,
  dense = false,
  startTimeEarliest,
  onChange,
  arrivalWindow,
  isOnModal = false,
}: TruckSchedulerBoxProps) => {
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const { serviceIndex } = useOrderServiceIndex();
  const { order: o, originalOrder } = useOrderState();
  const order = o!;
  const settings = {
    [GENERAL_SERVICES]: useOrderWindowSettings()[GENERAL_SERVICES] || [],
  };
  const { trucks, fetchJobs, fetchTrucks, dayMode, setDayMode, isLoadingJobs, isLoadingTrucks, isDeliveryMode } =
    useSchedulerContext();
  const [date, setDate] = useState(toDate(moveDate)!);
  const weekDays = dayMode ? [] : daysOfWeek.map((day) => addDays(startOfWeek(date), day));
  const allJobs = useAllJobsWithCurrentOrder(serviceIndex, date, weekDays);
  const { setOpenEditModal } = useOrderWindowEditModal();
  const { activeCuFt, activeWeight } = useOrderSizing(order!);
  const { moveUnit } = useOrderSettingUnits();
  const { changeSet } = useOrderChangeSet();
  const { enqueueSnackbar } = useSnackbar();
  const isSettingsReady = (settings[GENERAL_SERVICES] || []).length > 0 || !orderId;
  const inFlight = isLoadingTrucks || isLoadingJobs || !isSettingsReady;
  const { confirm: confirmAddTruck, renderDialog } = useConfirm({
    title: 'Warning',
    message:
      "This move's CuFt is larger than the amount of CuFt allotted for this truck.  Please either select an alternate truck or change the truck count.",
    confirmTitle: 'Proceed',
    cancelTitle: 'Cancel',
  });

  const handleChangeMoveDate = (onClose: () => void) => {
    onChange({ name: `services.${serviceIndex}.date`, value: format(date, BACKEND_DATE_FORMAT) });
    onClose();
  };

  const isLongDistance = isLongDistanceService(order.getServiceType(serviceIndex));
  const quote = order.getServiceQuote(serviceIndex);
  const hasDeliveryDates = quote.deliveryDateStart && quote.deliveryDateEnd;
  const isAvailableDeliveryDates = isLongDistance;

  const activeWeightByMoveUnit = moveUnit === MoveUnit.CUBIC_FEET ? activeCuFt : activeWeight;

  const { setShowAlert, alertProps } = useAlert({
    onConfirm: handleChangeMoveDate,
  });

  const assignPickupTruck = async (truckId: number, currentDate: Date) => {
    if (!startTimeEarliest) {
      setOpenEditModal((oldSet) => oldSet.add(OrderWindowModal.START_TIME_WINDOW));
      setDate(currentDate);
      return;
    }

    const allMoveDates = order?.services.map(({ date }) => date);

    if (
      allMoveDates.some((date) => isSameDay(currentDate, toDate(date)!)) &&
      !isSameDay(currentDate, toDate(moveDate)!)
    ) {
      enqueueSnackbar(
        "The selected date for the service you're requesting is unavailable. Please select an alternative date.",
        {
          variant: 'error',
        },
      );
      return;
    }

    if (!isSameDay(currentDate, toDate(moveDate)!)) {
      setShowAlert(true);
      setDate(currentDate);
      return;
    }

    const truckFromSetting = TruckAssignmentDto.createTruckAssignmentDto({
      ...(trucks.find((t) => t.id === truckId)?.toJS() as any),
      delivery: false,
    });
    const schedulerDate = currentDate || date;
    const parsedStartTimeEarliest = parse(startTimeEarliest, TIME_STRING_FORMAT, date);
    const endTimeJob = addSeconds(
      parsedStartTimeEarliest,
      order.services.get(serviceIndex)?.quote.actualJobDuration.durationMax.originalSeconds,
    );

    const isUnassignedTruck = truckFromSetting.capacity === 0;

    const bookedJobsWithCurrentTruck = !isUnassignedTruck
      ? allJobs.filter(
          (job) =>
            (job?.orderStatus === statusIds.BOOKED || job?.orderStatus === statusIds.RESERVED) &&
            job?.truckIds.includes(truckId) &&
            isNoOverlapTime({
              startTimeEarliest: job?.startTimeEarliest as Date,
              startTimeEarliestToCompare: endTimeJob,
              endTime: job?.endTime as Date,
              endTimeToCompare: parsedStartTimeEarliest,
            }),
        )
      : [];

    if (isSameDay(currentDate, toDate(moveDate)!)) {
      const truckAssignments = getIn(
        order,
        `services.${serviceIndex}.truckAssignments`.split('.'),
        List<TruckAssignmentDto>(),
      ) as List<TruckAssignmentDto>;
      const truck =
        originalOrder?.services
          .get(serviceIndex)
          ?.truckAssignments.find((t: TruckAssignmentDto) => !t.delivery && t.id === truckId) || truckFromSetting;
      const newTruckIds = truckAssignments.find((truck) => !truck.delivery && truck.id === truckId)
        ? truckAssignments.filter((truck) => truck.delivery || truck.id !== truckId)
        : truckAssignments.push(truck);

      const pickupTrucks = newTruckIds.filter((truck) => !truck.delivery);

      if (pickupTrucks.size > numberOfTrucks?.value()!) {
        enqueueSnackbar(
          `Can not assign more than ${numberOfTrucks.value()} truck(s) to this order on ${format(schedulerDate, 'P')}`,
          { variant: 'warning' },
        );
        return;
      }
      const isLastAvailableTruck = pickupTrucks.size === numberOfTrucks.value();
      const bookedWeight = truckAssignments.reduce((acc, truck) => acc + truck.capacity, 0);
      const isLessCapacity = activeWeightByMoveUnit - bookedWeight > truckFromSetting?.capacity;

      if (
        !truckAssignments.find((truck) => truck.id === truckId) &&
        isLastAvailableTruck &&
        isLessCapacity &&
        !isUnassignedTruck
      ) {
        if (!(await confirmAddTruck())) return;
      }

      if (bookedJobsWithCurrentTruck.length && !truckAssignments.find((truck) => truck.id === truckId)) {
        enqueueSnackbar(`Sorry, the current truck is already scheduled for this time`, { variant: 'error' });
        return;
      }

      onChange({
        name: `services.${serviceIndex}.truckAssignments`,
        value: newTruckIds,
      });
    }
  };

  const assignDeliveryTruck = (truckId: number) => {
    const hasDeliveryTime = quote.deliveryTimeEarliest;
    const hasArrivalTime = quote.deliveryTruckReturnTime;

    if (!hasDeliveryDates || !hasDeliveryTime || !hasArrivalTime) {
      setOpenEditModal((oldSet) => oldSet.add(OrderWindowModal.DELIVERY_DAYS_WINDOW));
      enqueueSnackbar('Please select your arrival window before assigning your truck(s).', {
        variant: 'warning',
      });
      return;
    }

    const truckFromSetting = TruckAssignmentDto.createTruckAssignmentDto({
      ...(trucks.find((t) => t.id === truckId)?.toJS() as any),
      delivery: true,
    });

    const truckAssignments = getIn(
      order,
      `services.${serviceIndex}.truckAssignments`.split('.'),
      List<TruckAssignmentDto>(),
    ) as List<TruckAssignmentDto>;
    const truck =
      originalOrder?.services
        .get(serviceIndex)
        ?.truckAssignments.find((t: TruckAssignmentDto) => t.delivery && t.id === truckId) || truckFromSetting;
    const newTruckIds = truckAssignments.find((truck) => truck.delivery && truck.id === truckId)
      ? truckAssignments.filter((truck) => !truck.delivery || truck.id !== truckId)
      : truckAssignments.push(truck);

    onChange({
      name: `services.${serviceIndex}.truckAssignments`,
      value: newTruckIds,
    });
  };

  const handleTruckClick = async (truckId: number, currentDate: Date) => {
    if (!orderId) {
      return;
    }

    if (
      isDeliveryMode &&
      isAvailableDeliveryDates &&
      hasDeliveryDates &&
      isWithinInterval(currentDate, { start: toDate(quote.deliveryDateStart)!, end: toDate(quote.deliveryDateEnd)! })
    ) {
      assignDeliveryTruck(truckId);
    } else {
      assignPickupTruck(truckId, currentDate);
    }
  };

  const toggleViewMode = () => {
    setDayMode((mode: boolean) => !mode);
  };

  useEffect(() => {
    setDate(moveDate ? toDate(moveDate)! : new Date());
  }, [moveDate]);

  const warningMessage = useMemo(() => {
    if (!startTimeEarliest) {
      return 'Please select your arrival window before assigning your truck(s)';
    }

    return null;
  }, [date, changeSet, arrivalWindow, moveDate, startTimeEarliest]);

  useEffect(() => {
    if (isSettingsReady && trucks.length) {
      const handler = setTimeout(fetchJobs(dayMode, date), 300);
      return () => clearTimeout(handler);
    }
  }, [date, dayMode, trucks.length, isSettingsReady]);

  useEffect(() => {
    const handler = setTimeout(fetchTrucks(dayMode, date), 300);
    return () => clearTimeout(handler);
  }, [date, dayMode]);

  const onDateChange = (date: Date) => {
    if (date) {
      setDate(date);
    }
    if (!date && isAvailableDeliveryDates) {
      setOpenEditModal((oldSet) => oldSet.add(OrderWindowModal.DELIVERY_DAYS_WINDOW));
    }
  };

  return (
    <>
      <SchedulerBox
        longDistancePlanner={longDistancePlanner}
        deliveryDate={deliveryDate}
        dense={dense}
        date={date}
        dayMode={dayMode}
        onDateChange={onDateChange}
        toggleViewMode={toggleViewMode}
        moveDate={moveDate}
        numberOfTrucks={trucks.filter((it) => it.capacity).length}
        orderId={orderId}
        onTruckClick={isClosing || isCompleted || isLockSales ? () => {} : handleTruckClick}
        trucks={trucks}
        jobs={allJobs}
        inFlight={inFlight}
        warningMessage={warningMessage}
        isClosing={isClosing || isCompleted}
        isAvailableDeliveryDates={isAvailableDeliveryDates}
        deliveryDateStart={quote.get(DELIVERY_DATE_START)}
        deliveryDateEnd={quote.get(DELIVERY_DATE_END)}
        isOnModal={isOnModal}
      />
      <Modal {...alertProps} title="Warning">
        <Box display="flex" justifyContent="center">
          <BodyBigText>Do you really want to change move date assign an available truck for that date?</BodyBigText>
        </Box>
      </Modal>
      {renderDialog()}
    </>
  );
};

export default TruckSchedulerBox;
