import { ActivitySourceType, MessageStatusTypes, MessageTypes, Phone } from '@elromcoinc/react-shared';
import axios from 'axios';
import formatStringByPattern from 'format-string-by-pattern';
import { setIn } from 'immutable';

import { NotificationStatusDto } from 'admin/constants/NotificationStatusDto';
import { ActivitySourceDescriptor, Notes } from 'common-types';

interface TotalMessagesQuery {
  'activitySources.activitySource': ActivitySourceType;
  'activitySources.sourceId': number;
  'order.id'?: number;
  'account.id'?: number;
}

class CommunicationsAPI {
  getEmailFoldersAPI() {
    return axios.get('/api/communications/email/folders');
  }

  getEmailConversationSummariesAPI(folderName: string) {
    return axios.get(`/api/communications/email/conversations/${folderName}`);
  }

  getEmailConversationsAPI(latestId?: string | number) {
    return axios.get(`/api/communications/email/conversation/${latestId}`);
  }

  getMessages<T>(params: Partial<PageParams & { 'order.id': number; 'account.id': number }> = {}) {
    const url = `/api/moving/messages/domain/${params['order.id'] ? 'ORDERS' : 'ACCOUNTS'}`;
    return axios
      .get<PageDTO<T>, PageDTO<T>>(url, {
        params: { pageIndex: 0, pageSize: 100, sortOrder: 'DESC', ...params },
      })
      .then((response) => setIn(response, ['pageElements'], response.pageElements.reverse()));
  }

  sendMessage(orderId: number, body: string, type: 'order' | 'account' = 'order') {
    return axios.post(`/api/moving/messages/${type}/${orderId}/new`, { body });
  }

  sendSms(body: {
    sourceDescriptor: ActivitySourceDescriptor;
    body: string;
    toPhone: string;
    activitySources?: ActivitySourceDescriptor[];
    automationRuleId?: number | null;
  }) {
    return axios.post('/api/communications/sms/message/', {
      ...body,
      toPhone: `+1${body.toPhone}`,
    });
  }

  checkConditionsBeforeSendSms(source: ActivitySourceType, id: number, phone: Phone) {
    return axios.post(`/api/moving/smsPossibilityCheck/activity/${source}/id/${id}`, phone);
  }

  getNotes(sourceId: number, activitySource: ActivitySourceType = ActivitySourceType.ORDER) {
    return axios.get<PageDTO<Notes>, PageDTO<Notes>>(
      `/api/communications/notes/activitySourceType/${activitySource}/entity/${sourceId}`,
      {
        params: {
          pageSize: 10000,
        },
      },
    );
  }

  saveNote(note: Notes) {
    return axios.post<Notes, Notes>('/api/communications/note', note);
  }

  updateNote(note: Notes) {
    return axios.put<Notes, Notes>('/api/communications/note', note);
  }

  getOrdersWithNewMessages<T>(pageIndex: number = 0, pageSize: number = 10) {
    return axios.get<PageDTO<T>, PageDTO<T>>(
      `/api/moving/messages/orders/messagesSummaries/unseen?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );
  }

  getOrdersWithMessages<T>(pageIndex: number = 0, pageSize: number = 10) {
    return axios.get<PageDTO<T>, PageDTO<T>>(
      `/api/moving/messages/orders/messagesSummaries/?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );
  }

  toggleMessageStatus(id: number, status: MessageStatusTypes) {
    return axios.put(`/api/moving/messages/${id}/status/${status}`, {});
  }

  markAllActivityMessagesAsSeen(id: number, type: 'order' | 'account') {
    return axios.put(`/api/moving/messages/${type}/${id}/to/company/status/SEEN`, {});
  }

  forwardMessageTo(id: number, body: number[]) {
    return axios.post(`/api/moving/messages/forwarded/${id}`, body);
  }

  getTotalEmployeeUnseenMessages(query: Partial<TotalMessagesQuery> = {}) {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const status = MessageStatusTypes.UNSEEN;
    const recipient = 'COMPANY';

    const activitySource = query['activitySources.activitySource'] || ActivitySourceType.ORDER;
    const isOrderRelated = activitySource === ActivitySourceType.ORDER;
    const url = `/api/moving/messages/domain/${isOrderRelated ? 'ORDERS' : 'ACCOUNTS'}/count`;
    delete query['activitySources.activitySource'];

    if (query['activitySources.sourceId']) {
      if (isOrderRelated) {
        query['order.id'] = query['activitySources.sourceId'];
      } else {
        query['account.id'] = query['activitySources.sourceId'];
      }
      delete query['activitySources.sourceId'];
    }

    return {
      promise: axios.get<number, number>(url, {
        params: {
          type: [MessageTypes.IN_APP, MessageTypes.SMS],
          status,
          recipient,
          sentAutomatically: false,
          ...query,
        },
        cancelToken: source.token,
      }),
      cancel: (message: string) => source.cancel(message),
    };
  }

  markAllActivitiesAsSeenByCompany(type: 'accounts' | 'orders') {
    return axios.put(`/api/moving/messages/${type}/to/company/status/SEEN`, {});
  }

  updateMessage(id: number, body: string) {
    return axios.put(`/api/moving/messages/${id}`, { body });
  }

  getAllSms(dateBefore: string, dateAfter: string) {
    return axios.get(`/api/communications/sms/sub_account/all_sms/dateAfter/${dateAfter}/dateBefore/${dateBefore}`);
  }

  getPhoneNumberType(phoneNumber: string) {
    const formattedPhoneNumber = `+1${formatStringByPattern('9999999999', phoneNumber)}`;

    return axios.get(`/api/communications/sms/phone/lookup/phoneNumber/${formattedPhoneNumber}/`, {
      // @ts-ignore
      fullResponse: true,
    });
  }

  getNotificationsByDate(startDate: string) {
    return axios.get<{ startDate: string }, NotificationStatusDto[]>(
      `/api/communications/notifications/service/startDate/${startDate}`,
    );
  }

  postNotification(notification: NotificationStatusDto) {
    return axios.post('/api/communications/notifications/notification', notification);
  }
}

export default new CommunicationsAPI();
