import styled from 'styled-components';

import { TimeSlotCellHeight } from './CalendarConstants';

export const SpacerWithoutBorder = styled.div<{ $width?: number; actionable: boolean }>`
  width: ${({ $width = TimeSlotCellHeight }) => $width}px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ actionable = false }) => (actionable ? 'cursor: pointer;' : '')}
`;
