import React, { useState } from 'react';

import { Modal } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const FilePreview = ({ viewFile, fileUrl, onClose }) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(false);
  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf?.numPages);
  };

  return (
    <Modal onClose={onClose} maxWidth="md" open={viewFile} color="grey">
      <Document className={classes.root} file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from({ length: numPages }, (_, i) => (
          <Page key={i} pageNumber={i + 1} />
        ))}
      </Document>
    </Modal>
  );
};

export default FilePreview;
