enum ActivityType {
  MESSAGE = 'Message',
  NOTE = 'Note',
  CALL = 'Call',
  CUSTOM = 'Custom',
  EMAIL = 'Email',
  TASK = 'Task',
  EVENT = 'Event',
  MANUALLY_ENTERED_ACTIVITY = 'ManuallyEnteredActivity',
}

export default ActivityType;
