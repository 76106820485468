import axios from 'axios';

class SettingsAPI {
  readSettingNode(nodeName = null) {
    return axios.get(`/api/settings/node/${nodeName}`);
  }

  updateSettingNode(name: string, value: any) {
    return axios.put('/api/settings/node', { name, value });
  }

  updateSettingNodeCancel(name: string, value: any) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    return {
      promise: axios.put(
        '/api/settings/node',
        { name, value },
        {
          cancelToken: source.token,
        },
      ),
      cancel(message: string) {
        source.cancel(message);
      },
    };
  }

  getGeoAddress(addressQuery: string) {
    return axios.get(`/api/geo/address/${addressQuery}`);
  }

  getGeoAddresses(addressQuery: string) {
    return axios.get(`/api/geo/addresses/${addressQuery}`);
  }

  getDataFromQuery<T = any>({ companyId = 1, queries }: { companyId?: number; queries: string[] }) {
    const queryString = queries.map((item) => `nodes=${item}`).join('&');
    const apiString = `/api/settings/nodes/branch/${companyId}?${queryString}`;
    return axios.get<T, T>(apiString);
  }

  /**
   * update list of settings with structure { name, value }
   * @param data array of { name, value }
   * @returns Promise
   */
  updateSettingNodes(data: { name: string; value: any }[]) {
    return axios.put('/api/settings/nodes', data);
  }
}

export default new SettingsAPI();
