import React, { useEffect, useState } from 'react';

import { BodyText, IconButton } from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemIcon, ListItemText, Paper, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FlagIcon from '@material-ui/icons/Flag';
import { useAutocomplete } from '@material-ui/lab';
import clsx from 'clsx';
import pt from 'prop-types';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: '4.3rem',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    '& input': {
      boxSizing: 'border-box',
      outline: 0,
      border: 0,
      width: 0,
      margin: 0,
      minWidth: '10px',
      flexGrow: 1,
    },
  },
  optionsItem: {
    position: 'absolute',
    zIndex: 999,
    overflow: 'auto',
    maxHeight: '450px',
  },
  checkbox: {
    padding: '2px',
  },
  list: {
    '& li[aria-selected=false]': {
      '& svg': {
        color: theme.palette.common.white,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '3px',
        width: '15px',
        height: '15px',
      },
    },
    '& li[aria-selected=true]': {
      backgroundColor: theme.palette.primary.light,
      '& svg': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: '3px',
        width: '15px',
        height: '15px',
      },
    },
    '& li[aria-selected]:hover': {
      backgroundColor: theme.palette.grey[200],
      cursor: 'pointer',
      '& svg': {
        color: theme.palette.grey[200],
      },
    },
  },
  listItemIcon: {
    minWidth: 0,
  },
  listItemText: {
    marginLeft: '5px',
  },
}));

const useStylesAccount = makeStyles({
  input: {
    minHeight: '45.6px',
  },
});

const useStylesOrder = makeStyles({
  input: {
    minHeight: '38px',
    boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
  },
});

const InputValue = ({ label, onDelete, value, index }) => (
  <Box display="flex" component="div">
    <Box display="flex" pl={0.5} alignItems="center" component="span">
      <BodyText wordBreak noWrap>
        {label}
      </BodyText>
      <IconButton color="default" size="tiny" onClick={onDelete}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Box>
    {index < value.length - 1 ? ' ' : ''}
  </Box>
);

InputValue.propTypes = {
  label: pt.string,
  onDelete: pt.func,
  // eslint-disable-next-line react/forbid-prop-types
  value: pt.array,
  index: pt.number,
};

InputValue.defaultProps = {
  label: null,
  onDelete: null,
  value: null,
  index: null,
};

export const FlagsAutocomplete = ({ data, onChange, mode, defaultValue }) => {
  const accountFlags = mode === 'account';
  const classes = useStyles();
  const classesAccount = useStylesAccount();
  const classesOrder = useStylesOrder();
  const classesMode = accountFlags ? classesAccount : classesOrder;
  const [dropDownFlags, setDropDownFlags] = useState([]);
  const [anchorElForActivity, setAnchorElForActivity] = useState(null);

  useEffect(() => {
    if (data) {
      setDropDownFlags(data);
    }
  }, [data]);

  const onOpen = ({ currentTarget }) => {
    if (anchorElForActivity) {
      setAnchorElForActivity(null);
    }
    setAnchorElForActivity(currentTarget);
  };

  const onClose = () => {
    setAnchorElForActivity(null);
  };

  const { getRootProps, getInputProps, getTagProps, getListboxProps, getOptionProps, groupedOptions, value } =
    useAutocomplete({
      id: 'account-flags',
      defaultValue: defaultValue || [],
      multiple: true,
      options: dropDownFlags,
      getOptionLabel: (option) => option.name,
      disableCloseOnSelect: true,
      onChange,
      getOptionSelected: (option, value1) => option.id === value1.id,
      onOpen,
      onClose,
      open: Boolean(anchorElForActivity),
    });

  return (
    <>
      <Box {...getRootProps()}>
        <Box ref={anchorElForActivity} className={clsx(classes.input, classesMode.input)}>
          <Box pl="2px">
            <FlagIcon />
          </Box>
          <Box display="flex" flexWrap="wrap">
            {value.map((option, index) => (
              <InputValue
                value={value}
                index={index}
                label={
                  <>
                    <Box color={option.rgbColor} component="span">
                      <BodyText>{option.name}</BodyText>
                    </Box>
                  </>
                }
                {...getTagProps({ index })}
              />
            ))}
            <input {...getInputProps()} />
          </Box>
          <IconButton color="inherit" onClick={onOpen}>
            <ArrowDropDownIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {groupedOptions.length > 0 ? (
        <Paper classes={{ root: classes.optionsItem }}>
          <List {...getListboxProps()} dense role="list" classes={{ root: classes.list }}>
            {groupedOptions.map((option, index) => {
              const labelId = `option-label-${option.id}`;
              return (
                <ListItem key={option.id} {...getOptionProps({ option, index })} component="li">
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <CheckIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      <>
                        <Box color={option.rgbColor} style={{ cursor: 'pointer' }} component="span">
                          <BodyText>{option.name}</BodyText>
                        </Box>
                      </>
                    }
                    classes={{ primary: classes.listItemText }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      ) : null}
    </>
  );
};

FlagsAutocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: pt.array,
  onChange: pt.func,
  mode: pt.string,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: pt.array.isRequired,
};

FlagsAutocomplete.defaultProps = {
  data: [],
  onChange: null,
  mode: null,
};
