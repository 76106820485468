import React, { useEffect } from 'react';
import { makeStyles, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Switch } from '@elromcoinc/react-shared';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function SwitchList(props) {
  const { onChange, value } = props;
  const classes = useStyles();

  const [checked, setChecked] = React.useState(value || false);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleToggle = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <List className={classes.root}>
      <ListItem role={undefined} dense button onClick={handleToggle}>
        <ListItemIcon>
          <Switch color="primary" checked={checked} tabIndex={-1} disableRipple />
        </ListItemIcon>
        <ListItemText primary={checked ? 'Turn off all' : 'Turn on all'} />
      </ListItem>
    </List>
  );
}

SwitchList.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool
};

SwitchList.defaultProps = {
  value: null
};
