import { useEffect, useState } from 'react';

import {
  GeneralService,
  Order,
  OrderStatusIdType,
  Service,
  SettingNames,
  Truck,
  TruckAssignmentDto,
  toDate,
} from '@elromcoinc/react-shared';
import { isSameDay } from 'date-fns';

import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import { useSchedulerContext } from 'admin/components/OrderWindow/SchedulerBox/SchedulerManager';
import { calculateVerticalLevel } from 'admin/components/OrderWindow/SchedulerBox/common';
import { useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import { canShowDeliveryTruck } from 'admin/utils';

const { GENERAL_SERVICES } = SettingNames;

const toJob = (
  order: Order,
  orderStatus: OrderStatusIdType,
  serviceIndex: number,
  settings: any,
  deliveryJob = false,
) => {
  const services = (settings[GENERAL_SERVICES] || []) as GeneralService[];
  const service: Service = order?.services.get(serviceIndex);
  const color = services.find((s) => s.serviceType === service.quote.serviceType)?.rgbColor;

  return Job.create(
    {
      orderId: order.orderId,
      orderStatus,
      date: service.date!,
      numberOfMovers: service.quote.numberOfMovers.value(),
      numberOfTrucks: service.quote.numberOfTrucks.value(),
      orderNumber: order.orderNumber,
      contactFirstName: order.contactInfo.firstName,
      contactLastName: order.contactInfo.lastName,
      contactPhone: order.contactInfo.primaryPhone.number,
      sizeDescription: order.sizeDescription,
      moveType: order.moveType,
      fromAddress: order.fromAddress.getCityStateZip(),
      toAddress: order.toAddress.getCityStateZip(),
      serviceType: service.serviceTypeName!,
      startTimeEarliest: service.quote.startTimeEarliest,
      startTimeLatest: service.quote.startTimeLatest,
      jobDuration: service.quote.jobDuration?.durationMax?.originalSeconds,
      actualJobDuration: service.quote.actualJobDuration?.durationMax?.originalSeconds,
      jobTime: service.quote.jobTime.humanReadable,
      trucks: (service.truckAssignments.toArray() ?? []) as TruckAssignmentDto[],
      endTime: service.quote.endTime,
      deliveryDateStart: service.quote.deliveryDateStart,
      deliveryDateEnd: service.quote.deliveryDateEnd,
      deliveryTimeEarliest: service.quote.deliveryTimeEarliest,
      arrivalTimeEarliest: service.quote.arrivalTimeEarliest,
      arrivalTimeLatest: service.quote.arrivalTimeLatest,
      deliveryTruckReturnTime: service.quote.deliveryTruckReturnTime,
      deliveryJob,
    },
    color,
  );
};

const useAllJobsWithCurrentOrder = (serviceIndex: number, currentDate: Date, weekDays: Date[]) => {
  const windowSettings = useOrderWindowSettings();
  const { order, originalOrder } = useOrderState();
  const orderId = order?.orderId;
  const { trucks, jobs } = useSchedulerContext();
  const [allJobs, setAllJobs] = useState<Job[]>([]);
  const service: Service = order?.services.get(serviceIndex);
  const truckAssignmentAsString = order?.services
    ?.get(serviceIndex)
    ?.truckAssignments.map((it: Truck) => it.id)
    .join();

  useEffect(() => {
    if (!(trucks as []).length) {
      return;
    }

    const result = (jobs as Job[]).filter((job) => job.get('orderId') !== orderId);
    const currentService = order?.services.find((s) => isSameDay(toDate(s.date!)!, currentDate)) || service;
    const serviceDate = toDate(currentService?.date);
    const hasStartTime = currentService.quote.startTimeEarliest && currentService.quote.startTimeLatest;
    const index = order?.services.findIndex((s) => s.id === currentService.id) ?? -1;

    if (
      isSameDay(currentDate, serviceDate!) &&
      hasStartTime &&
      !currentService.truckAssignments.filter((ta: TruckAssignmentDto) => !ta.delivery).isEmpty() &&
      index > -1
    ) {
      result.push(toJob(order!, originalOrder?.status, index, windowSettings));
    }

    if (
      canShowDeliveryTruck(order!, serviceIndex, currentDate) ||
      weekDays.some((d) => canShowDeliveryTruck(order!, serviceIndex, d))
    ) {
      result.push(toJob(order!, originalOrder?.status, index, windowSettings, true));
    }

    setAllJobs(calculateVerticalLevel(trucks)(result));
  }, [
    jobs,
    trucks,
    originalOrder?.status,
    service.quote.startTimeEarliest,
    service.quote.startTimeLatest,
    truckAssignmentAsString,
    service.quote.actualJobDuration?.durationMax?.originalSeconds,
  ]);

  return allJobs;
};

export { useAllJobsWithCurrentOrder };
