import React, { useState } from 'react';

import { BodyText, Link, Order, PaymentAdjustmentType, numberToCurrency } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { LocalAtm as CashIcon, CreditCard as CreditIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Map } from 'immutable';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { TipsModal } from 'admin/components/OrderWindow/modals/TipsModal/TipsModal';
import { getServiceRosterClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const pickupNames = Map({
  [PaymentAdjustmentType.TIP]: 'pickupTips',
  [PaymentAdjustmentType.CASH_TIP]: 'pickupCashTips',
});

const deliveryNames = Map({
  [PaymentAdjustmentType.TIP]: 'deliveryTips',
  [PaymentAdjustmentType.CASH_TIP]: 'deliveryCashTips',
});

export const ClosingTips = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const currentPickupCashTipsName = getServiceRosterClosingPropertyName(
    serviceIndex,
    pickupNames.get(PaymentAdjustmentType.CASH_TIP) ?? '',
  );
  const currentPickupTipsName = getServiceRosterClosingPropertyName(
    serviceIndex,
    pickupNames.get(PaymentAdjustmentType.TIP) ?? '',
  );
  const currentDeliveryCashTipsName = getServiceRosterClosingPropertyName(
    serviceIndex,
    deliveryNames.get(PaymentAdjustmentType.CASH_TIP) ?? '',
  );
  const currentDeliveryTipsName = getServiceRosterClosingPropertyName(
    serviceIndex,
    deliveryNames.get(PaymentAdjustmentType.TIP) ?? '',
  );
  const pickupCashTipsValue = (order.getIn(currentPickupCashTipsName.split('.')) as number) ?? 0;
  const pickupTipsValue = (order.getIn(currentPickupTipsName.split('.')) as number) ?? 0;
  const deliveryCashTipsValue = (order.getIn(currentDeliveryCashTipsName.split('.')) as number) ?? 0;
  const deliveryTipsValue = (order.getIn(currentDeliveryTipsName.split('.')) as number) ?? 0;
  const currentTotalValue = pickupCashTipsValue + pickupTipsValue;
  const isLongDistance = order?.isLongDistance(serviceIndex);
  const valueToDisplay = isLongDistance
    ? deliveryCashTipsValue + deliveryTipsValue + +currentTotalValue
    : currentTotalValue;
  const IconToDisplay =
    (pickupCashTipsValue !== 0 || deliveryCashTipsValue !== 0) && (pickupTipsValue === 0 || deliveryTipsValue === 0)
      ? CashIcon
      : CreditIcon;
  const hideIcon = !pickupCashTipsValue && !pickupTipsValue && !deliveryCashTipsValue && !deliveryTipsValue;
  const showBothIcons =
    (pickupCashTipsValue !== 0 && pickupTipsValue !== 0) ||
    (deliveryCashTipsValue !== 0 && deliveryTipsValue !== 0) ||
    (pickupCashTipsValue !== 0 && deliveryTipsValue !== 0) ||
    (deliveryCashTipsValue !== 0 && pickupTipsValue !== 0);

  const toggleShowTips =
    (value = true) =>
    () => {
      setShowModal(value);
    };

  const formattedValueToDisplay = numberToCurrency(+valueToDisplay || 0);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleShowTips()}>
          <BodyText>Tips</BodyText>
        </Link>
        <Box mr={-1} display="flex" justifyContent="center" alignItems="center">
          {!hideIcon && !showBothIcons && <IconToDisplay />}
          {!hideIcon && showBothIcons && (
            <>
              <CashIcon />
              <CreditIcon />
            </>
          )}
          <Box mr={1} ml={1}>
            <BodyText data-testId={`tipsValue`}>{formattedValueToDisplay}</BodyText>
          </Box>
        </Box>
        {showModal && (
          <TipsModal
            pickupCashTipsName={currentPickupCashTipsName}
            pickupTipsName={currentPickupTipsName}
            deliveryCashTipsName={currentDeliveryCashTipsName}
            deliveryTipsName={currentDeliveryTipsName}
            onClose={toggleShowTips(false)}
            isLongDistance={isLongDistance}
          />
        )}
      </Box>
    </Grid>
  );
};
