import React from 'react';

import { SvgIcon } from '@material-ui/core';

const ParkingIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-parking"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <rect x="4" y="4" width="16" height="16" rx="2"></rect>
      <path d="M9 16v-8h4a2 2 0 0 1 0 4h-4"></path>
    </svg>
  </SvgIcon>
);
export { ParkingIcon };
