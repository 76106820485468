import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  BodyText,
  CurrencyInput,
  ElromcoCircularProgress,
  Modal,
  PriceAdjustmentType,
  formatCurrency,
} from '@elromcoinc/react-shared';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';

import { useOrderChangeSet, useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { TaxInfo } from 'common-types/TaxInfo';
import { ContentTableContainer } from 'common/components/Widgets';

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    paddingBottom: '16px',
    position: 'relative',
  },
  taxesModal: {
    '& .MuiDialogContent-root': { paddingTop: 0 },
  },
  taxesTable: {
    width: 390,
    zIndex: 1,
    '& table thead': {
      backgroundColor: palette.primary.main,
      border: 'none',
      '& p': {
        color: palette.common.white,
      },
    },
    '&.MuiPaper-rounded': { borderRadius: spacing(0.75) },
  },
  total: {
    zIndex: 0,
    position: 'relative',
    bottom: 0,
    width: '140px',
    padding: '16px 8px 16px 32px',
    left: '210px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '20px',
    alignItems: 'flex-end',
  },
  borderTopLeftRadius: {
    borderTopLeftRadius: spacing(0.75),
  },
  borderTopRightRadius: {
    borderTopRightRadius: spacing(0.75),
  },
}));

interface TaxesProps {
  open: boolean;
  onClose(): void;
  taxInfo: TaxInfo;
}

const Taxes: React.FC<TaxesProps> = ({ open, onClose, taxInfo }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { onChange, inFlight } = useOrderChangeSet();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { closingTitle } = useOrderClosingContext();
  const getServicePropertyName = useGetServicePropertyName();
  const [laborHourlyTaxRate, setLaborHourlyTaxRate] = useState(taxInfo.laborHourlyTaxRate ?? 0);
  const [travelHourlyTaxRate, setTravelHourlyTaxRate] = useState(taxInfo.travelHourlyTaxRate ?? 0);
  const [longDistanceLineHaulTaxRate, setLongDistanceLineHaulTaxRate] = useState(
    taxInfo.longDistanceLineHaulTaxRate ?? 0,
  );
  const [packingMaterialsTaxRate, setPackingMaterialsTaxRate] = useState(taxInfo.packingMaterialsTaxRate ?? 0);
  const [orderTotalTaxRate, setOrderTotalTaxRate] = useState(taxInfo.orderTotalTaxRate ?? 0);

  useEffect(() => {
    if (taxInfo.laborHourlyTaxRate !== laborHourlyTaxRate) {
      setLaborHourlyTaxRate(taxInfo.laborHourlyTaxRate);
    }
  }, [taxInfo.laborHourlyTaxRate]);

  useEffect(() => {
    if (taxInfo.travelHourlyTaxRate !== travelHourlyTaxRate) {
      setTravelHourlyTaxRate(taxInfo.travelHourlyTaxRate);
    }
  }, [taxInfo.travelHourlyTaxRate]);

  useEffect(() => {
    if (taxInfo.longDistanceLineHaulTaxRate !== longDistanceLineHaulTaxRate) {
      setLongDistanceLineHaulTaxRate(taxInfo.longDistanceLineHaulTaxRate);
    }
  }, [taxInfo.longDistanceLineHaulTaxRate]);

  useEffect(() => {
    if (taxInfo.packingMaterialsTaxRate !== packingMaterialsTaxRate) {
      setPackingMaterialsTaxRate(taxInfo.packingMaterialsTaxRate);
    }
  }, [taxInfo.packingMaterialsTaxRate]);

  useEffect(() => {
    if (taxInfo.orderTotalTaxRate !== orderTotalTaxRate) {
      setOrderTotalTaxRate(taxInfo.orderTotalTaxRate);
    }
  }, [taxInfo.orderTotalTaxRate]);

  const laborHourlyTaxRateName = getServicePropertyName('taxInfo.laborHourlyTaxRate');
  const travelHourlyTaxRateName = getServicePropertyName('taxInfo.travelHourlyTaxRate');
  const longDistanceLineHaulTaxRateName = getServicePropertyName('taxInfo.longDistanceLineHaulTaxRate');
  const packingMaterialsTaxRateName = getServicePropertyName('taxInfo.packingMaterialsTaxRate');
  const orderTotalTaxRateName = getServicePropertyName('taxInfo.orderTotalTaxRate');

  const handleChange = (value: number, name: string) => {
    if (name === laborHourlyTaxRateName) {
      setLaborHourlyTaxRate(value);
    } else if (name === travelHourlyTaxRateName) {
      setTravelHourlyTaxRate(value);
    } else if (name === longDistanceLineHaulTaxRateName) {
      setLongDistanceLineHaulTaxRate(value);
    } else if (name === packingMaterialsTaxRateName) {
      setPackingMaterialsTaxRate(value);
    } else if (name === orderTotalTaxRateName) {
      setOrderTotalTaxRate(value);
    }
  };

  const handleBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;

    const changes: { name: string; value: number }[] = [];

    if (name === laborHourlyTaxRateName && value !== laborHourlyTaxRate) {
      changes.push({ name: laborHourlyTaxRateName, value: laborHourlyTaxRate });
      changes.push({ name: orderTotalTaxRateName, value: 0 });
    } else if (name === travelHourlyTaxRateName && value !== travelHourlyTaxRate) {
      changes.push({ name: travelHourlyTaxRateName, value: travelHourlyTaxRate });
      changes.push({ name: orderTotalTaxRateName, value: 0 });
    } else if (name === longDistanceLineHaulTaxRateName && value !== longDistanceLineHaulTaxRate) {
      changes.push({ name: longDistanceLineHaulTaxRateName, value: longDistanceLineHaulTaxRate });
      changes.push({ name: orderTotalTaxRateName, value: 0 });
    } else if (name === packingMaterialsTaxRateName && value !== packingMaterialsTaxRate) {
      changes.push({ name: packingMaterialsTaxRateName, value: packingMaterialsTaxRate });
      changes.push({ name: orderTotalTaxRateName, value: 0 });
    } else if (name === orderTotalTaxRateName && value !== orderTotalTaxRate) {
      changes.push({ name: orderTotalTaxRateName, value: orderTotalTaxRate });
      [
        laborHourlyTaxRateName,
        travelHourlyTaxRateName,
        longDistanceLineHaulTaxRateName,
        packingMaterialsTaxRateName,
      ].forEach((name) => {
        changes.push({ name, value: 0 });
      });
    }

    if (changes.length) {
      onChange(changes);
    }
  };

  return (
    <Modal
      color="grey"
      maxWidth="xs"
      open={open}
      title={`Taxes${closingTitle}`}
      onClose={onClose}
      className={classes.taxesModal}
    >
      {inFlight && <ElromcoCircularProgress />}
      <Box display="flex" flexDirection="column" className={classes.root}>
        <ContentTableContainer className={classes.taxesTable}>
          <Table>
            <TableHead className="applyTopRadius">
              <TableRow>
                {!isMobile && (
                  <TableCell className={classes.borderTopLeftRadius} align="right">
                    <BodyText>
                      <b>Item</b>
                    </BodyText>
                  </TableCell>
                )}
                <TableCell className={isMobile ? classes.borderTopLeftRadius : ''}>
                  <BodyText>
                    <b>Rate</b>
                  </BodyText>
                </TableCell>
                <TableCell className={!isMobile ? classes.borderTopRightRadius : ''}>
                  <BodyText>
                    <b>Line Total</b>
                  </BodyText>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell height="30px">
                  <BodyText>Labor Hourly Tax</BodyText>
                </TableCell>
                <TableCell align="right">
                  <Box width={100}>
                    <CurrencyInput
                      size="small"
                      hiddenLabel
                      amountType={PriceAdjustmentType.PERCENT}
                      name={laborHourlyTaxRateName}
                      value={laborHourlyTaxRate}
                      onChange={handleChange}
                      onBlur={handleBlur(taxInfo.laborHourlyTaxRate)}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <BodyText>{`$${formatCurrency(taxInfo.laborHourlyTax)}`}</BodyText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell height="30px">
                  <BodyText>Travel Hourly Tax</BodyText>
                </TableCell>
                <TableCell align="right">
                  <Box width={100}>
                    <CurrencyInput
                      size="small"
                      hiddenLabel
                      amountType={PriceAdjustmentType.PERCENT}
                      name={travelHourlyTaxRateName}
                      value={travelHourlyTaxRate}
                      onChange={handleChange}
                      onBlur={handleBlur(taxInfo.travelHourlyTaxRate)}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <BodyText>{`$${formatCurrency(taxInfo.travelHourlyTax)}`}</BodyText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell height="30px">
                  <BodyText>Long Distance Linehaul Tax</BodyText>
                </TableCell>
                <TableCell align="right">
                  <Box width={100}>
                    <CurrencyInput
                      size="small"
                      hiddenLabel
                      amountType={PriceAdjustmentType.PERCENT}
                      name={longDistanceLineHaulTaxRateName}
                      value={longDistanceLineHaulTaxRate}
                      onChange={handleChange}
                      onBlur={handleBlur(taxInfo.longDistanceLineHaulTaxRate)}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <BodyText>{`$${formatCurrency(taxInfo.longDistanceLineHaulTax)}`}</BodyText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell height="30px">
                  <BodyText>Packing Materials Tax</BodyText>
                </TableCell>
                <TableCell align="right">
                  <Box width={100}>
                    <CurrencyInput
                      size="small"
                      hiddenLabel
                      amountType={PriceAdjustmentType.PERCENT}
                      name={packingMaterialsTaxRateName}
                      value={packingMaterialsTaxRate}
                      onChange={handleChange}
                      onBlur={handleBlur(taxInfo.packingMaterialsTaxRate)}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <BodyText>{`$${formatCurrency(taxInfo.packingMaterialsTax)}`}</BodyText>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell height="30px">
                  <BodyText>Order Total Tax</BodyText>
                </TableCell>
                <TableCell align="right">
                  <Box width={100}>
                    <CurrencyInput
                      size="small"
                      hiddenLabel
                      amountType={PriceAdjustmentType.PERCENT}
                      name={orderTotalTaxRateName}
                      value={orderTotalTaxRate}
                      onChange={handleChange}
                      onBlur={handleBlur(taxInfo.orderTotalTaxRate)}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right">
                  <BodyText>{`$${formatCurrency(taxInfo.orderTotalTax)}`}</BodyText>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ContentTableContainer>
        <Paper square className={clsx(classes.total, 'applyBottomRadius')}>
          <BodyText>
            <b>Total:</b>
          </BodyText>
          <BodyText>${formatCurrency(taxInfo.totalTaxes)}</BodyText>
        </Paper>
      </Box>
    </Modal>
  );
};

export { Taxes };
