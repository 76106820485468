import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const ArrivedIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M1123 4699 c-287 -25 -552 -154 -756 -368 -213 -223 -326 -501 -329 -806 0 -81 5 -132 20 -192 79 -311 323 -698 743 -1177 165 -189 386 -413 414 -420 38 -10 72 13 175 120 583 604 949 1141 1026 1507 29 139 12 345 -43 512 -59 181 -154 336 -293 475 -255 257 -595 381 -957 349z m287 -620 c178 -57 310 -187 373 -367 27 -75 31 -101 31 -187 0 -167 -54 -297 -174 -416 -283 -280 -756 -196 -929 164 -87 180 -78 377 24 553 72 124 228 236 375 269 84 19 214 12 300 -16z" />
          <path d="M2649 3767 c-43 -33 -46 -89 -7 -124 18 -16 46 -19 267 -23 l246 -5 3 -1317 2 -1318 -724 0 -724 0 -11 46 c-27 108 -111 220 -212 285 -152 97 -372 93 -527 -11 -95 -63 -183 -189 -198 -283 l-7 -37 -216 2 -216 3 -5 782 -5 782 -22 22 c-31 32 -80 30 -110 -5 l-23 -27 0 -786 c0 -781 0 -787 21 -831 13 -26 38 -54 62 -70 l40 -27 238 -3 237 -3 5 -34 c11 -65 67 -165 127 -225 133 -133 331 -178 510 -115 140 49 265 186 301 329 l11 46 898 0 899 0 16 -57 c43 -151 161 -271 315 -323 72 -24 199 -29 270 -10 163 44 304 183 345 340 l11 45 140 5 c118 5 145 9 181 28 66 35 110 78 140 140 l28 57 3 440 c2 300 0 456 -8 492 -8 39 -83 168 -316 550 -168 274 -317 511 -332 526 -14 15 -53 41 -86 57 l-59 30 -418 0 -418 0 -3 258 -3 259 -27 40 c-16 24 -44 49 -70 62 -42 20 -60 21 -299 21 -184 -1 -258 -4 -270 -13z m1521 -812 c22 -19 90 -118 90 -130 0 -3 -125 -5 -277 -5 -304 0 -311 -1 -350 -59 -23 -34 -23 -36 -23 -375 0 -309 2 -345 19 -381 22 -48 44 -69 94 -90 31 -13 111 -15 557 -15 l520 0 0 -407 c0 -389 -1 -409 -20 -441 -37 -61 -70 -73 -198 -70 l-113 3 -22 65 c-45 134 -127 226 -256 288 -67 32 -84 36 -180 40 -90 4 -116 1 -170 -18 -154 -52 -273 -173 -316 -322 l-16 -58 -95 0 -94 0 0 1000 0 1001 414 -3 c398 -3 415 -4 436 -23z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
