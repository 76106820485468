import React from 'react';

import {
  BodyBigText,
  BodyText,
  Button,
  IconButton,
  PriceAdjustmentType,
  Select,
  formatCurrency,
  getFormErrorMessages,
  roundNumberToFixedDigits,
  useConfirm,
  useHoverTooltip,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { Box, Grid, ListSubheader, MenuItem, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { List } from 'immutable';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getBasicEmployee } from 'admin/autodux/UsersAutodux';
import { OrderPayrollTotals } from 'admin/components/OrderWindow/modals/Payroll/OrderPayrollTotals';
import { OrderPayroll } from 'admin/components/OrderWindow/modals/Payroll/Payroll';
import {
  EmployeeCompensationName,
  PAYROLL_ERROR_PER_EMPLOYEE_CLASS_NAME,
  PayrollPositionTypesByNamesToChoose,
  payrollForeman,
  payrollHelper,
  payrollName,
  payrollSales,
  payrolls,
} from 'admin/components/OrderWindow/modals/Payroll/PayrollConstants';
import { getCommission } from 'admin/components/OrderWindow/modals/Payroll/getCommission';
import { getCommissionByType } from 'admin/components/OrderWindow/modals/Payroll/getCommissionByType';
import { PositionTypes } from 'admin/constants/PositionTypes';
import { BasicEmployeeDto } from 'common-types';
import { BaseDepartmentDto } from 'common-types/department';
import { EmployeeCommissionNames, EmployeeCommissionType, EmployeeJobPayrollDto } from 'common-types/payroll';

import { PayrollEmployeeCompensation } from './PayrollEmployeeCompensation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
    arrow: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
    },
    subheader: {
      backgroundColor: theme.palette.common.white,
      textTransform: 'capitalize',
      pointerEvents: 'none',
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
  }),
);

const hiddenCommissions = [
  EmployeeCommissionType.PER_DAY_BONUS,
  EmployeeCommissionType.DAILY_RATE,

  // we'll implement it later
  EmployeeCommissionType.RECEIPT,

  // we don't display it. this only for setting up on employee setting
  EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
];

const employeeCommissionWithOptions = Object.entries(EmployeeCommissionNames).filter(
  ([key]) => !hiddenCommissions.includes(key as EmployeeCommissionType),
) as SelectOptions<EmployeeCommissionType>;

interface PayrollEmployeeProps {
  index: number;
  name: typeof payrollSales | typeof payrollForeman | typeof payrollHelper;
  remove: (index: number) => void;
  totals: OrderPayrollTotals;
  departments: BaseDepartmentDto[];
}

const OWNER_DEPARTMENT_ID = 1;

export const PayrollEmployee = ({ index: employeeIndex, remove, totals, name, departments }: PayrollEmployeeProps) => {
  const { control, watch, formState, setValue, getValues } = useFormContext<OrderPayroll>();
  const employees = useSelector(getBasicEmployee) as List<BasicEmployeeDto>;
  const classes = useStyles();
  const newCommissionTooltip = useHoverTooltip();
  const { confirm: confirmDeleteCommissionPayroll, renderDialog: renderDeleteCommissionPayrollDialog } = useConfirm({
    title: 'Are you sure you want to delete this commission from payroll?',
    maxWidth: 'xs',
    confirmTitle: 'Delete',
    cancelTitle: 'Cancel',
  });
  const employeePathName = `${name}.${employeeIndex}` as const;
  const currentEmployeePayroll = watch(employeePathName);
  const commissionsName = `${employeePathName}.${EmployeeCompensationName}` as const;
  const commissions = watch(commissionsName) || [];
  const { confirm: confirmDeleteEmployeePayroll, renderDialog: renderDeleteEmployeePayrollDialog } = useConfirm({
    title: 'Are you sure you want to delete this employee from payroll?',
    maxWidth: 'xs',
    confirmTitle: 'Delete',
    cancelTitle: 'Cancel',
  });
  const {
    fields: compensationFields,
    append,
    remove: removeCommision,
    update,
  } = useFieldArray({
    control,
    name: commissionsName,
    keyName: 'uid',
  });
  const currentEmployee = employees.find((e) => e.id === currentEmployeePayroll?.employeeId);

  const formValue = getValues();
  const allEmployees = payrolls.reduce(
    (accumulator, p) => [...accumulator, ...formValue[p]],
    [] as EmployeeJobPayrollDto[],
  );

  const activeEmployeeAndNotOwners = employees.filter(
    (employee) =>
      employee.active &&
      ((employee.departmentId !== OWNER_DEPARTMENT_ID &&
        !allEmployees.find((field) => field.employeeId === employee.id)) ||
        currentEmployee?.id === employee.id),
  );

  const currentPositionTypes = PayrollPositionTypesByNamesToChoose[payrollName[name]];

  const options = departments
    .map((department) => ({
      name: department.name,
      employees: activeEmployeeAndNotOwners
        .filter((employee) => employee.departmentId === department.id)
        .sort((a, b) => a.firstName.localeCompare(b.firstName)),
      positionType: currentPositionTypes[0],
    }))
    .filter((opt) => opt.employees.size);

  const hasDailyRateCommission = !!currentEmployee?.employeeCommissions.some(
    (c) => c.commissionType === EmployeeCommissionType.DAILY_RATE,
  );
  const hasDailyBonusCommission = !!currentEmployee?.employeeCommissions.some(
    (c) => c.commissionType === EmployeeCommissionType.PER_DAY_BONUS,
  );

  const errors = getFormErrorMessages(formState.errors);
  const formError = errors?.[commissionsName];

  const availableCommissions = employeeCommissionWithOptions.filter(([key]) => {
    return !commissions.find((c) => c.type === key);
  });

  const handleDeleteCommissionPayroll = (index: number) => async () => {
    if (!(await confirmDeleteCommissionPayroll())) {
      return;
    }

    removeCommision(index);
  };

  useUpdateEffect(() => {
    commissions.forEach((com, comIndex) => {
      const commissionByType =
        com.type === EmployeeCommissionType.HOURLY_RATE &&
        (currentPositionTypes[0] === PositionTypes.HELPER || currentPositionTypes[0] === PositionTypes.DRIVER) &&
        !!currentEmployee?.employeeCommissions.find(
          (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
        )
          ? EmployeeCommissionType.HOURLY_RATE_AS_HELPER
          : com.type;

      const employeeCommission = currentEmployee?.employeeCommissions.find(
        (c) => c.commissionType === commissionByType,
      );
      const hasHourlyRateAsHelper = (currentEmployee?.employeeCommissions || []).some(
        (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
      );
      const newCommission = (
        employeeCommission
          ? getCommission(employeeCommission, totals, {
              positionType: currentEmployeePayroll.positionType,
              manualAddCommission: true,
              hasHourlyRateAsHelper,
            })
          : getCommissionByType(com.type, totals, {
              positionType: currentEmployeePayroll.positionType,
              hasHourlyRateAsHelper,
            })
      )!;

      if (employeeCommission && newCommission) {
        if (com.type === EmployeeCommissionType.PER_JOB_BONUS) {
          newCommission.value = employeeCommission.rate.amount;
        } else if (com.type === EmployeeCommissionType.HOURLY_RATE) {
          newCommission.value = employeeCommission.rate.amount;
        } else if (com.type !== EmployeeCommissionType.TIP) {
          newCommission.rate = { ...employeeCommission.rate };
        }

        const coefficient =
          newCommission.rate.type === PriceAdjustmentType.PERCENT
            ? newCommission.rate.amount / 100
            : newCommission.rate.amount;
        newCommission.total = roundNumberToFixedDigits(coefficient * newCommission.value);
      }

      const amountAsNumber = +newCommission.rate.amount || 0;
      const amount = newCommission.rate.type === PriceAdjustmentType.ABSOLUTE ? amountAsNumber : amountAsNumber / 100;
      const newComissionValue =
        com.type === EmployeeCommissionType.PER_JOB_BONUS || com.type === EmployeeCommissionType.HOURLY_RATE
          ? +newCommission?.value
          : +com.value;

      if (newCommission) {
        update(comIndex, {
          ...newCommission,
          value: newComissionValue,
          total: roundNumberToFixedDigits((newComissionValue || 0) * amount),
        });
      }
    });
  }, [currentEmployee?.id]);

  const onClickAddNewCommission = (commission: EmployeeCommissionType) => () => {
    const commissionByType =
      commission === EmployeeCommissionType.HOURLY_RATE &&
      (currentPositionTypes[0] === PositionTypes.HELPER || currentPositionTypes[0] === PositionTypes.DRIVER) &&
      !!currentEmployee?.employeeCommissions.find(
        (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
      )
        ? EmployeeCommissionType.HOURLY_RATE_AS_HELPER
        : commission;
    const employeeCommission = currentEmployee?.employeeCommissions.find((c) => c.commissionType === commissionByType);

    const hasHourlyRateAsHelper = (currentEmployee?.employeeCommissions || []).some(
      (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
    );
    const newCommission = (
      employeeCommission
        ? getCommission(employeeCommission, totals, {
            positionType: currentEmployeePayroll.positionType,
            manualAddCommission: true,
            hasHourlyRateAsHelper,
          })
        : getCommissionByType(commission, totals, {
            positionType: currentEmployeePayroll.positionType,
            hasHourlyRateAsHelper,
          })
    )!;

    if (employeeCommission && newCommission) {
      if (commission === EmployeeCommissionType.PER_JOB_BONUS) {
        newCommission.value = employeeCommission.rate.amount;
      } else if (commission === EmployeeCommissionType.HOURLY_RATE) {
        newCommission.value = employeeCommission.rate.amount;
      } else if (commission !== EmployeeCommissionType.TIP) {
        newCommission.rate = { ...employeeCommission.rate };
      }

      const coefficient =
        newCommission.rate.type === PriceAdjustmentType.PERCENT
          ? newCommission.rate.amount / 100
          : newCommission.rate.amount;
      newCommission.total = roundNumberToFixedDigits(coefficient * newCommission.value);
    }

    if (newCommission) {
      append(newCommission);
    }

    newCommissionTooltip.tooltipProps.onClose();
  };

  const newCommissionButtons = newCommissionTooltip.renderTooltip(
    <Box display="flex" flexDirection="column" p={1}>
      {availableCommissions.map(([key, value]) => (
        <Box key={key} my={1}>
          <Button onClick={onClickAddNewCommission(key)} color="primary" variant="outlined">
            {value}
          </Button>
        </Box>
      ))}
    </Box>,
  );

  const handleDeleteEmployeePayroll = async () => {
    if (!(await confirmDeleteEmployeePayroll())) {
      return;
    }

    remove(employeeIndex);
  };

  const total = roundNumberToFixedDigits(
    (currentEmployeePayroll?.employeeJobPayrollCompensations ?? []).reduce((acc, curr) => acc + curr.total, 0),
  );

  useUpdateEffect(() => {
    if (currentEmployeePayroll) {
      setValue(`${employeePathName}.total`, total);
    }
  }, [total]);

  const handleClickEmployee = (employee: BasicEmployeeDto, positionType: PositionTypes) => () => {
    setValue(`${employeePathName}.employeeId`, employee.id);
    setValue(`${employeePathName}.positionType`, positionType);
  };

  const matchingEmployee = !!options.find(({ employees }) =>
    employees.find((e) => e.id === currentEmployeePayroll?.employeeId),
  );

  return (
    <>
      <Grid container item xs={12} spacing={0} alignItems="center">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <Select
            fullWidth
            name={`${employeePathName}.employeeId`}
            value={matchingEmployee ? currentEmployeePayroll?.employeeId : null}
            hiddenLabel
            skipControll
            size="small"
          >
            {options.map((option) => [
              <ListSubheader classes={{ sticky: classes.subheader }}>{option.name}</ListSubheader>,
              option.employees.map((e) => (
                <MenuItem value={e.id} key={e.id} onClick={handleClickEmployee(e, option.positionType)}>
                  {e.fullName}
                </MenuItem>
              )),
            ])}
          </Select>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={1}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton color="primary" onClick={handleDeleteEmployeePayroll}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      {!compensationFields.length && (
        <Grid item xs={12}>
          <BodyBigText className="mt-1">
            <b>Department type not applicable for selected employee</b>
          </BodyBigText>
        </Grid>
      )}
      {!!formError && (
        <Alert className={clsx('mt-h', PAYROLL_ERROR_PER_EMPLOYEE_CLASS_NAME)} severity="error">
          <BodyText color="inherit">{formError}</BodyText>
        </Alert>
      )}
      {hasDailyRateCommission && (
        <Grid item xs={12}>
          <BodyBigText className="mt-1">
            <b>Employee has daily rate</b>
          </BodyBigText>
        </Grid>
      )}
      {hasDailyBonusCommission && (
        <Grid item xs={12}>
          <BodyBigText className="mt-1">
            <b>Employee has daily bonus</b>
          </BodyBigText>
        </Grid>
      )}
      <Grid item xs={12}>
        <Paper elevation={1} square>
          {compensationFields.map((field, index) => (
            <PayrollEmployeeCompensation
              key={field.uid}
              employeeIndex={employeeIndex}
              index={index}
              remove={handleDeleteCommissionPayroll(index)}
              name={name}
            />
          ))}
        </Paper>
      </Grid>
      <Grid item xs={11}>
        <Box display="flex" justifyContent="flex-end">
          <Box display="flex" flexDirection="column">
            <Box>Total: ${formatCurrency(total)}</Box>
            <Box>
              <Tooltip
                {...newCommissionTooltip.tooltipProps}
                enterTouchDelay={0}
                leaveTouchDelay={10000}
                title={newCommissionButtons}
                arrow
                classes={classes}
              >
                <Button variant="text" color="primary">
                  ADD
                </Button>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        {renderDeleteCommissionPayrollDialog()}
        {renderDeleteEmployeePayrollDialog()}
      </Grid>
    </>
  );
};
