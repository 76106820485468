import { FC, useMemo } from 'react';

import { Box, Fab, useTheme } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getEnableVideoTutorials, setVideos } from 'admin/autodux/PlayVideoAutodux';
import SettingStructure from 'admin/components/Settings/constants/SettingsStructure';

const findVideo = (path: string, structure = SettingStructure) => {
  let videos: string[] | null = null;

  structure.forEach((it) => {
    const childSetting = it?.children?.find(
      (childSettings) => childSettings?.videos && path.includes(childSettings?.urlPostFix),
    );

    if (childSetting?.videos) {
      videos = childSetting?.videos;
    }

    if (it.children) {
      videos = findVideo(path, it?.children as unknown as typeof SettingStructure) || videos;
    }
  });

  return videos;
};

interface VideoButtonProps {
  video?: string;
  position?: 'absolute';
  bottom?: number;
  top?: number;
  left?: number;
}

export const VideoButton: FC<VideoButtonProps> = ({ video, position, bottom, top, left }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const enableVideoTutorials = useSelector(getEnableVideoTutorials);
  const pageVideos = useMemo(() => (video ? [video] : null), [video]);
  const videos = pageVideos || findVideo(location.pathname);

  const handlePlayVideos = () => {
    dispatch(setVideos(videos));
  };

  if (!videos || !enableVideoTutorials) {
    return null;
  }

  return (
    <Box
      position={position || 'fixed'}
      bottom={bottom !== undefined ? bottom : 10}
      top={top || null}
      left={left !== undefined ? left : 10}
      zIndex={theme.zIndex.drawer + 1}
    >
      <Fab color="primary" size="small" aria-label="play video" onClick={handlePlayVideos}>
        <PlayArrowIcon fontSize="large" />
      </Fab>
    </Box>
  );
};
