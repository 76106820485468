// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import companyAPI from 'admin/api/SettingsAPI';

const {
  reducer,
  actions: { setCompanyInfo, setInFlight, setUploadCompanyLogoDate },
  selectors: { getCompanyInfo, getInFlight: getInFlightCompanyInfo, getUploadCompanyLogoDate },
} = autodux({
  slice: 'companyInfo',
  initial: {
    companyInfo: null,
    inFlight: false,
    uploadCompanyLogoDate: null,
  },
});

const fetchCompanyInfo = () => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(setInFlight(true));
  return companyAPI
    .getCompanyInfo()
    .then((data) => {
      dispatch(setCompanyInfo(data));
    })
    .finally(() => dispatch(setInFlight(false)));
};

const getCompanyEmail = (state: any) => {
  return getCompanyInfo(state) ? getCompanyInfo(state)?.basicInfo?.email : null;
};

const getCompanyName = (state: any) => {
  return (getCompanyInfo(state) ? getCompanyInfo(state)?.basicInfo?.companyName : null) ?? '';
};

export default reducer;
export {
  getCompanyInfo,
  getInFlightCompanyInfo,
  getCompanyEmail,
  getCompanyName,
  fetchCompanyInfo,
  setCompanyInfo,
  setUploadCompanyLogoDate,
  getUploadCompanyLogoDate,
};
