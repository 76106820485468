// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import notificationsAPI from 'admin/api/notificationsAPI';
import { NotificationCategories } from 'admin/constants/NotificationCategories';

const {
  reducer,
  actions: { setNotificationSettings, setInFlight, fetchNotificationSettingsSuccess },
  selectors: { getNotificationSettings, getInFlight: getInFlightNotificationSettings },
} = autodux({
  slice: 'notificationSettings',
  initial: {
    inFlight: false,
    notificationSettings: [],
  },
  actions: {
    fetchNotificationSettingsSuccess: (state: any, data: any) => ({
      ...state,
      notificationSettings: data,
      inFlight: false,
    }),
  },
});

export default reducer;

const fetchNotificationSettings = () => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(setInFlight(true));

  return Promise.all(
    Object.keys(NotificationCategories).map((grop) =>
      notificationsAPI.getNotificationsBy(grop as NotificationCategories),
    ),
  )
    .catch(() => {})
    .then((response) => {
      if (response) {
        dispatch(
          fetchNotificationSettingsSuccess(
            // @ts-ignore
            [].concat(...response).filter((n) => n.notificationsEnabled && !n.description.includes('junk removal')),
          ),
        );
        return response;
      }

      dispatch(setInFlight(false));
    });
};

export { fetchNotificationSettings, setNotificationSettings, getNotificationSettings, getInFlightNotificationSettings };
