import React, { useState } from 'react';

import { AccountsPermissions, ActivitySourceType, CircularProgress, useHasPermission } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import accountAPI from 'admin/api/AccountAPI';
import {
  AccountManager, // (not MVP) FlagsAutocomplete,
  AccountOverview,
  CurrentMoves,
  PastMoves,
  SelectActivity,
  TotalOrders,
} from 'admin/components/AccountWindow/components';
import { PAYMENT_OPTION } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { AccountSettingsModal } from 'admin/components/AccountWindow/modals';
import ActivityLog from 'admin/components/OrderWindow/blocks/ActivityLog';
import Attachments from 'admin/components/OrderWindow/blocks/Attachments';
import { Messages } from 'admin/components/OrderWindow/blocks/Messages';
import Notes from 'admin/components/OrderWindow/blocks/Notes';
import { InvoicesButton, TasksButton } from 'admin/components/OrderWindow/buttons';
import Invoices from 'admin/components/OrderWindow/modals/Invoices/index.ts';
import TasksModal from 'admin/components/OrderWindow/modals/Tasks/Tasks';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { AccountType } from 'admin/constants/AccountType';
import { useCompanyBranchName } from 'admin/hooks';
import { IconLabelButton } from 'common/components/Widgets';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: `${theme.palette.grey[100]}`,
    height: 'calc(95vh - 51px)',
    overflowY: 'auto',
    width: '100%',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
      padding: '12px',
      height: 'calc(100vh - 51px)',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column-reverse',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      flexDirection: 'row',
    },
  },
  totalBar: {
    display: 'flex',
    flexBasis: 'auto',
    flexWrap: 'noWrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: '100%',
    },
  },
  buttonPanel: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  branchName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    height: '38px',
  },
}));

const activitySource = ActivitySourceType.CUSTOMER_ACCOUNT;

const AccountModals = {
  INVOICES: 'INVOICES',
  TASKS: 'TASKS',
  SETTINGS: 'SETTINGS',
};

export const MainAccountPanel = ({ summaryAccount }) => {
  const classes = useStyles();
  const sourceId = (summaryAccount || {}).id;
  const [openModal, setOpenModal] = useState();
  const [accountDiscount, setAccountDiscount] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const branch = useCompanyBranchName();
  const isPrivateAccount = summaryAccount.type === AccountType.PRIVATE;
  const { firstName, lastName } = (summaryAccount || {}).ownerCustomerInfo;
  const canSeeAccountDiscount = useHasPermission(AccountsPermissions.PERM_CAN_SEE_ACCOUNT_DISCOUNT);
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);
  const canSeeAccountPaymentOption = useHasPermission(AccountsPermissions.PERM_CAN_SEE_ACCOUNT_PAYMENT_OPTION);
  const canSeeAccountSettings = canSeeAccountDiscount || canSeeAccountPaymentOption;
  const taskSource = {
    activitySource,
    contactInfo: { firstName, lastName },
    sourceId,
    activitySources: [{ activitySource, referencedEntityId: sourceId }],
  };
  // (not MVP)
  // const [statusFlagsData, setStatusFlagsData] = useState(null);
  //
  // // TODO: connect flags to accounts when BE be ready
  // useEffect(() => {
  //   sourceId &&
  //     accountAPI.getAllAvailableFlagsForAccount(sourceId)
  //       .then(setStatusFlagsData)
  //       .catch(() => {
  //         enqueueSnackbar(`Can't get flags`, { variant: 'error' });
  //       });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sourceId]);
  //
  // if (!summaryAccount) {
  //   return null;
  // }

  const handleOpenSettingsModal = () => {
    Promise.all([
      canSeeAccountDiscount ? accountAPI.getAccountDiscount(sourceId) : Promise.resolve(),
      canSeeAccountPaymentOption ? accountAPI.getAccountPaymentOption(sourceId) : Promise.resolve(),
    ])
      .then(([discount, paymentOption]) => {
        setAccountDiscount({ ...discount, [PAYMENT_OPTION]: paymentOption?.[PAYMENT_OPTION] });
      })
      .catch(() => {
        enqueueSnackbar(`Can't get pricing configuration for this account`, { variant: 'error' });
      })
      .then(() => setOpenModal(AccountModals.SETTINGS));
  };

  const handleOpenModal = (modalName) => () => {
    setOpenModal(modalName);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  //TODO: Finished it, when we have ticket about account invoice
  const onChange = () => {};

  return (
    <Box px={2} pb={2} pt={1.5} className={classes.wrapper}>
      <VideoButton position="absolute" top={220} video="clNqvUoHo6Y" />
      <Box className={classes.container}>
        {!isPrivateAccount && (
          <Box className={classes.totalBar}>
            <Box className={classes.toolbar}>
              <SelectActivity />
              <Box fontWeight="bold" p={0.5} className={classes.branchName}>
                {branch ? branch : <CircularProgress size={20} color="primary" />}
              </Box>
              <TotalOrders summaryAccount={summaryAccount} />
            </Box>
            {/* (not MVP)
            <Box p={0.5}>
              <FlagsAutocomplete data={statusFlagsData} mode="account" defaultValue={[]} />
            </Box>
          */}
          </Box>
        )}
        {isPrivateAccount && <Box />}
        <Box className={classes.buttonPanel}>
          {!isPrivateAccount && (
            <>
              <TasksButton
                disabled={!canEditOtherUsersAccounts}
                onClick={handleOpenModal(AccountModals.TASKS)}
                sourceId={sourceId}
                activitySource={activitySource}
              />
              <Attachments disabled={!canEditOtherUsersAccounts} sourceId={sourceId} activitySource={activitySource} />
              <InvoicesButton
                sourceId={sourceId}
                activitySource={activitySource}
                onClick={handleOpenModal(AccountModals.INVOICES)}
              />
            </>
          )}
          {canSeeAccountSettings && (
            <IconLabelButton
              disabled={!canEditOtherUsersAccounts}
              onClick={handleOpenSettingsModal}
              data-testid="settingsButton"
              color="default"
              startIcon={<BuildIcon />}
            >
              Settings
            </IconLabelButton>
          )}
        </Box>
      </Box>
      {!isPrivateAccount && <AccountOverview />}
      <Box mt={1} p={0.5}>
        <Paper>
          <CurrentMoves summaryAccount={summaryAccount} />
          <PastMoves summaryAccount={summaryAccount} />
        </Paper>
      </Box>
      {!isPrivateAccount && (
        <>
          <Box mt={1} display="flex" flexWrap="wrap">
            <Notes
              disabled={!canEditOtherUsersAccounts}
              width="33%"
              sourceId={sourceId}
              activitySource={activitySource}
            />
            <Messages
              sourceId={summaryAccount.id}
              disabled={!canEditOtherUsersAccounts}
              width="33%"
              activitySource={activitySource}
              account={summaryAccount}
            />
            <ActivityLog
              disabled={!canEditOtherUsersAccounts}
              width="33%"
              sourceId={sourceId}
              activitySource={activitySource}
            />
          </Box>
          <AccountManager />
          {openModal === AccountModals.TASKS && (
            <TasksModal
              onSave={handleCloseModal}
              onCancel={handleCloseModal}
              open
              source={taskSource}
              activitySource={activitySource}
            />
          )}
          {openModal === AccountModals.INVOICES && (
            <Invoices
              open
              onCancel={handleCloseModal}
              sourceId={sourceId}
              activitySource={activitySource}
              onChange={onChange}
              account={summaryAccount}
            />
          )}
          {openModal === AccountModals.SETTINGS && (
            <AccountSettingsModal
              onCancel={handleCloseModal}
              open
              accountDiscount={accountDiscount}
              sourceId={sourceId}
            />
          )}
        </>
      )}
    </Box>
  );
};

MainAccountPanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  summaryAccount: pt.object.isRequired,
};
