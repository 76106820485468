import { ReactNode, useCallback, useRef } from 'react';

export default function useRefWithCallback(onMount: (node: ReactNode) => void, onUnmount?: (node: ReactNode) => void) {
  const nodeRef = useRef(null);

  const setRef = useCallback(
    (node) => {
      if (nodeRef.current) {
        onUnmount?.(nodeRef.current);
      }

      nodeRef.current = node;

      if (nodeRef.current) {
        onMount(nodeRef.current as ReactNode);
      }
    },
    [onMount, onUnmount],
  );

  return setRef;
}
