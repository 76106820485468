interface PrintHtmlOptions {
  styles?: string;
  target?: string;
}

// if you need this print some other place please make it common
const printHtml = (html: string, { styles = '', target = '' }: Partial<PrintHtmlOptions> = {}) => {
  const onload =
    'window.opener = null;' +
    'window.document.close(); /*necessary for IE >= 10*/' +
    'window.focus(); /*necessary for IE >= 10*/' +
    'window.print();';

  const openWindow = window.open('', target || '_blank', 'width=800,height=600');

  if (openWindow) {
    openWindow.document.write(
      `<html>
        <head>
        <style>
        ${styles}
        </style>
        </head>
        <body>
        ${html}
        </body>
        <script>
        ${onload}
        </script>
        </html>`,
    );
  }
};

export { printHtml };
