import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  conditionText: {
    textAlign: 'left',
  },
  link: {
    color: palette.primary.main,
    textDecoration: 'none',

    '&:visited': {
      color: palette.primary.main,
    },

    '&:hover': {
      textDecoration: 'underline',
      color: palette.primary.main,
    },
  },
}));

export const Conditions = () => {
  const classes = useStyles();

  return (
    <BodyText className={classes.conditionText}>
      By logging in, you're agreeing to the{' '}
      <a
        className={classes.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.elromco.com/moving-software-user-agreement/"
      >
        Terms and Conditions
      </a>
      , including to not share this account with any Elromco competitors or provide Elromco product information to third
      parties without Elromco's written consent.
    </BodyText>
  );
};
