import React, { FC } from 'react';

import { BodyBigText, Button } from '@elromcoinc/react-shared';
import { Box, Tooltip, makeStyles } from '@material-ui/core';

import { DashboardLabels, tooltipLabels } from './DashboardLabels';

const useStyles = makeStyles((theme) => ({
  rootBlueCard: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    padding: '10px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '50px',
    minHeight: '110px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  buttonBlueCard: {
    height: '50px',
    textAlign: 'center',
  },
  colorText: {
    color: theme.palette.common.white,
  },
  text: {
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 300,
  },
  amount: {
    [theme.breakpoints.down('xs')]: {
      bottom: '20px',
    },
  },
  title: {
    top: '9px',
  },
  titleButton: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface DashboardBlueCardProps {
  amount: number;
  title: string;
  onClick: () => void;
}

const DashboardBlueCard: FC<DashboardBlueCardProps> = ({ amount, title, onClick }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      title={
        title === DashboardLabels.INVENTORY_SUBMISSIONS || title === DashboardLabels.CANCELED_JOBS
          ? tooltipLabels.FOR_TIME_PERIOD_SELECTED
          : tooltipLabels.ALL_TIME_DATA
      }
    >
      <Box className={classes.rootBlueCard}>
        <Box className={classes.buttonBlueCard}>
          <Button className={classes.amount} variant="text" disableRipple onClick={onClick}>
            <BodyBigText className={classes.text}> {amount} </BodyBigText>
          </Button>
        </Box>
        <Box className={classes.titleButton}>
          <Button className={classes.title} variant="text" disableRipple onClick={onClick}>
            <BodyBigText className={classes.colorText}>{title}</BodyBigText>
          </Button>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default DashboardBlueCard;
