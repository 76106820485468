import React, { useEffect, useState } from 'react';

import { Modal, Radio, RadioGroup, TextInput } from '@elromcoinc/react-shared';
import { Box, FormControl, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { RadioContentWrapper } from 'common/components/Widgets';
import { formatCurrency } from 'common/utils/Formatter';

const ABSOLUTE = 'ABSOLUTE';
const getHumanReadable = (type, amount) =>
  type === ABSOLUTE ? `$${formatCurrency(amount || 0)}` : `${formatCurrency(amount || 0)}%`;

const Discount = ({ discount, onSave, onCancel, open }) => {
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const [type, setType] = useState(ABSOLUTE);
  const [amount, setAmount] = useState({ ABSOLUTE: null, PERCENT: null });

  const amountChangeHandler = ({ target: { name, value } }) => {
    setAmount({ ...amount, [name]: value });
  };

  const typeChangeHandler = (event) => {
    setType(event.target.value);
  };

  const saveHandler = () => {
    onSave(
      discount
        .set('type', type)
        .set('amount', amount[type] || 0)
        .set('humanReadable', getHumanReadable(type, amount[type])),
    );
  };

  useEffect(() => {
    if (discount != null) {
      setType(discount.type || ABSOLUTE);
      setAmount({ ...amount, [discount.type]: discount.amount });
    }
  }, [discount]);

  return (
    <Modal
      open={open}
      title="Discount"
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: saveHandler },
      ]}
      maxWidth="xs"
    >
      <Box mb={2}>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="discount-setting"
            name="discountSetting"
            value={type}
            onChange={typeChangeHandler}
            row
            disabled={isCompleted || isLockSales}
          >
            <Radio color="primary" value="ABSOLUTE" disabled={isCompleted || isLockSales} label="Money Discount" />
            <RadioContentWrapper display="flex" alignItems="center" ml={4} mb={2}>
              <Box width="120px">
                <TextInput
                  fullWidth
                  disabled={type !== ABSOLUTE || isCompleted || isLockSales}
                  type="number"
                  inputProps={{ min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  label="Amount"
                  name="ABSOLUTE"
                  value={amount.ABSOLUTE}
                  onChange={amountChangeHandler}
                />
              </Box>
            </RadioContentWrapper>
            <Radio color="primary" value="PERCENT" disabled={isCompleted || isLockSales} label="Percentage Discount" />
            <RadioContentWrapper display="flex" alignItems="center" ml={4} mb={2}>
              <Box width="120px">
                <TextInput
                  fullWidth
                  disabled={type !== 'PERCENT' || isCompleted || isLockSales}
                  type="number"
                  inputProps={{ max: '100', min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  label="Percent"
                  name="PERCENT"
                  value={amount.PERCENT}
                  onChange={amountChangeHandler}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Box>
            </RadioContentWrapper>
          </RadioGroup>
        </FormControl>
      </Box>
    </Modal>
  );
};

Discount.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  discount: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default Discount;
