import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { getHours, getMinutes, isToday } from 'date-fns';
import { useSelector } from 'react-redux';

import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';

const MINUTES_IN_HOUR = 60;

const useStyles = makeStyles(({ spacing, palette }) => ({
  point: {
    position: 'absolute',
    zIndex: 3,
    top: 0,
    borderRadius: '50%',
    width: spacing(1.5),
    height: spacing(1.5),
    marginLeft: spacing(-0.625),
    marginTop: spacing(-0.25),
    background: palette.error.main,
    pointerEvents: 'none',
  },
  line: {
    width: '2px',
    position: 'absolute',
    zIndex: 3,
    top: 0,
    bottom: 0,
    background: palette.error.main,
    pointerEvents: 'none',
  },
}));

interface TimeLineProps {
  date: Date;
  widthCoefficient: number;
  onUpdate: Dispatch<SetStateAction<number>>;
  startDayOffsetInMinutes: number;
}

export const TimeLine: FC<TimeLineProps> = ({ date, widthCoefficient, onUpdate, startDayOffsetInMinutes = 0 }) => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const isDateToday = isToday(date);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  const handleTick = () => {
    const hours = getHours(new Date());
    const minutes = getMinutes(new Date());
    const totalMinutes = hours * MINUTES_IN_HOUR + minutes - startDayOffsetInMinutes;
    const newOffset = totalMinutes * widthCoefficient;
    setOffset(newOffset);
    onUpdate(newOffset);
  };

  useEffect(() => {
    if (!isSessionExpired) {
      const interval = setInterval(handleTick, 60000);
      handleTick();

      return () => {
        clearInterval(interval);
      };
    }
  }, [isSessionExpired, widthCoefficient]);

  if (!isDateToday) {
    return null;
  }

  return (
    <>
      <Box left={offset} className={classes.point} />
      <Box className={classes.line} left={offset} />
    </>
  );
};
