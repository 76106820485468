import { BodyText, Order, numberToPercent } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

export const BloomingDalesDiscountGrandTotal = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();

  return (
    <Box>
      <BodyText>
        {`Grand total before promotion: ${order
          .getGrandTotalBeforeDiscount(serviceIndex, isSelectedAllServices)
          .asHumanReadableString()}`}
      </BodyText>
      <BodyText>
        {`You save: ${order
          .getSavedGrandTotal(serviceIndex, isSelectedAllServices)
          .asHumanReadableString()} (${numberToPercent(
          order.getSavedPercentage(serviceIndex, isSelectedAllServices),
        )})`}
      </BodyText>
    </Box>
  );
};
