import React from 'react';

import { BodyText, Order } from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

export const Balance = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();

  return (
    <HighlightedTableRow data-testId="balance" highlight>
      <TableCell size="small" padding="none">
        <BodyText>{isClosing ? 'Total Due' : 'Balance'}</BodyText>
      </TableCell>
      <TableCell>
        <Text
          value={(isClosing ? order.closingOrderDetail : order)
            ?.getBalanceDue(serviceIndex, isSelectedAllServices)
            ?.asHumanReadableString()}
          data-testId={'balanceValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
