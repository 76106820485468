import { User } from '@elromcoinc/react-shared';
import axios from 'axios';

import storageUtil from 'admin/utils/storageUtil';

class AuthApi {
  readCurrentUser() {
    //@ts-ignore because ignoreSessionCheck error
    return axios.get<User, User>('/api/auth/me', { ignoreSessionCheck: true });
  }

  login(username: string, password: string, companyId: number, forceLogin = false) {
    if (companyId) storageUtil.setTenantId(companyId);
    return axios.post(
      '/api/auth/login',
      { username, password },
      //@ts-ignore because ignoreSessionCheck error
      { ignoreSessionCheck: true, fullResponse: true, headers: forceLogin ? { 'X-Auth-Invalidate-Old': true } : {} },
    );
  }

  logout() {
    return axios.post('/api/auth/logout');
  }

  isEmailUsed(email: string) {
    return axios.get(`/api/admin/account/exists?email=${encodeURIComponent(email)}`);
  }

  createCustomerReadOnlyJwt(email: string) {
    return axios.put<string, string>(`/api/auth/customer/readOnlyToken?email=${encodeURIComponent(email)}`, null, {
      headers: { Accept: 'text/plain' },
    });
  }
}

export default new AuthApi();
