import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import { EstimateTypeName } from 'admin/constants/EstimateType';

import { EventProcessor } from '../EventProcessor';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const estimateType: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('estimateType') === -1) {
    return null;
  }
  // @ts-ignore
  const oldValue = EstimateTypeName[oldOrder.getValueIn(fieldPath)] || '';
  // @ts-ignore
  const newValue = EstimateTypeName[newOrder.getValueIn(fieldPath)] || '';
  if (oldValue === newValue) {
    return null;
  }
  return [
    createUpdatedValueDto(
      oldValue,
      newValue,
      'Estimate Type',
      extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
    ),
  ];
};

export default estimateType;
