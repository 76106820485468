import React, { FC, useState } from 'react';

import { Box, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { CrewLogStatus } from 'admin/components/CrewStatusLog/types';

const useStyles = makeStyles(({ spacing, palette }) => ({
  line: {
    width: '2px',
    position: 'absolute',
    zIndex: 4,
    top: spacing(2),
    bottom: spacing(0),
    background: palette.primary.main,
    pointerEvents: 'none',
    transition: 'all 0.3s',
  },
  icon: {
    fontSize: spacing(3),
    padding: spacing(0.5),
    transition: 'all 0.3s',
    '&:hover': {
      zIndex: 100,
      fontSize: spacing(4),
      marginLeft: -spacing(0.5),
      cursor: 'pointer',
    },
  },
  smallerIconSize: {
    marginLeft: spacing(0.5),
    fontSize: spacing(2.5),
    '&:hover': {
      marginLeft: 0,
      fontSize: spacing(3.5),
    },
  },
  onTheWayIcon: {
    marginBottom: spacing(-0.5),
  },
  hoveredLine: {
    background: palette.secondary.main,
    zIndex: 100,
  },
  white: {
    color: palette.common.white,
  },
}));

const smallerIconStatuses = [
  CrewLogStatus.LOADING,
  CrewLogStatus.UNLOADING,
  CrewLogStatus.UNLOADING_STARTED,
  CrewLogStatus.UNLOADING_COMPLETED,
  CrewLogStatus.PAYMENT,
  CrewLogStatus.COMPLETED,
];

interface IconWithTitleProps {
  title: string;
  lineOffsetLeft: number;
  zIndex?: number;
  length: number;
  index: number;
  whiteIcons: boolean;
  status: CrewLogStatus;
}

export const IconWithTitle: FC<IconWithTitleProps> = ({
  children: icon,
  title,
  lineOffsetLeft,
  zIndex = 1,
  length,
  index = 0,
  whiteIcons,
  status,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isGrouped = length > 1;
  const [hovered, setHovered] = useState(false);
  const onMouseLeave = () => {
    setHovered(false);
  };
  const onMouseEnter = () => {
    setHovered(true);
  };

  let x = -theme.spacing(2);
  let y = -theme.spacing(4.5);

  if (isGrouped) {
    const angle = ((index + 1) / (length + 1)) * Math.PI * -1;
    const radius = 40;
    x = Math.cos(angle) * radius * -1 - theme.spacing(2);
  }

  return (
    <>
      <Box
        className={clsx(classes.line, {
          [classes.hoveredLine]: hovered,
        })}
        left={lineOffsetLeft}
      />
      <Box
        display="flex"
        alignItems="center"
        className={clsx(classes.icon, {
          [classes.smallerIconSize]: smallerIconStatuses.includes(status),
          [classes.onTheWayIcon]: status === CrewLogStatus.TO_DESTINATION,
        })}
        left={x}
        bottom={y}
        position="absolute"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        zIndex={zIndex + 5}
      >
        <Tooltip title={title} enterTouchDelay={0} leaveTouchDelay={10000}>
          <Box
            className={clsx({
              [classes.white]: whiteIcons,
            })}
          >
            {icon}
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};
