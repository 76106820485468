import axios from 'axios';

class CustomerOnlineApi {
  getCustomerOnline({ pageSize = 20 } = {}) {
    return axios.get('/api/moving/customersOnline/', { params: { pageSize } });
  }

  getCustomerOnlineCount() {
    return axios.get('/api/moving/customersOnline/count/');
  }

  getCustomerOnlineById(id) {
    return axios.get(`/api/moving/customersOnline/active/customerId/${id}/`);
  }
}

export default new CustomerOnlineApi();
