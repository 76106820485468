import React, { useEffect, useState } from 'react';

import { Button, Modal, PhoneFormGroup, PhoneType, Select, TextInput, phoneYUP } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { getIn, mergeDeep, setIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import {
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  OWNER_CUSTOMER_INFO,
} from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { AccountTypeName } from 'admin/constants/AccountType';
import {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  PHONE_EXTENSION,
  PHONE_NUMBER,
  PHONE_TYPE,
  PRIMARY_PHONE,
} from 'admin/constants/FieldNames';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';

const firstNameLabel = 'First Name';
const lastNameLabel = 'Last Name';
const emailAddressLabel = 'Email Address';
const SMS_CAPABILITY = 'smsCapability';
const PHONE_CARRIER_TYPE = 'phoneCarrierType';

const primaryPhoneLabels = {
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_NUMBER]: 'Account Contact Phone',
  [PHONE_EXTENSION]: 'Ext',
};

const labels = {
  [ACCOUNT_TYPE]: 'Account Type',
  [ACCOUNT_NAME]: 'Account Name',
  [OWNER_CUSTOMER_INFO]: {
    [FIRST_NAME]: `Account ${firstNameLabel}`,
    [LAST_NAME]: `Account ${lastNameLabel}`,
    [EMAIL]: `Account Contact ${emailAddressLabel}`,
    [PRIMARY_PHONE]: {
      [PHONE_TYPE]: primaryPhoneLabels[PHONE_TYPE],
      [PHONE_NUMBER]: primaryPhoneLabels[PHONE_NUMBER],
      [PHONE_EXTENSION]: primaryPhoneLabels[PHONE_EXTENSION],
    },
  },
};

const accountTypePath = `${ACCOUNT_TYPE}`;
const accountNamePath = `${ACCOUNT_NAME}`;
const ownerCustomerInfoFirstNamePath = `${OWNER_CUSTOMER_INFO}.${FIRST_NAME}`;
const ownerCustomerInfoLastNamePath = `${OWNER_CUSTOMER_INFO}.${LAST_NAME}`;
const ownerCustomerInfoPrimaryPhonePath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}`;
const ownerCustomerInfoEmailPath = `${OWNER_CUSTOMER_INFO}.${EMAIL}`;
const smsCapabilityPath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${SMS_CAPABILITY}`;
const phoneCarrierTypePath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${PHONE_CARRIER_TYPE}`;

const schemaPrimaryPhone = phoneYUP(primaryPhoneLabels);

const schema = object().shape({
  [ACCOUNT_TYPE]: string().label(labels[ACCOUNT_TYPE]).required(),
  [ACCOUNT_NAME]: string().label(labels[ACCOUNT_NAME]).nullable().required().max(62).min(2),
  [OWNER_CUSTOMER_INFO]: object().shape({
    [FIRST_NAME]: string()
      .label(labels[FIRST_NAME])
      .required(`${firstNameLabel} is a required field`)
      .min(2, `${firstNameLabel} must be at least 2 characters`)
      .max(32, `${firstNameLabel} must be at most 32 characters`),
    [LAST_NAME]: string()
      .label(labels[LAST_NAME])
      .required(`${lastNameLabel} is a required field`)
      .min(2, `${lastNameLabel} must be at least 2 characters`)
      .max(32, `${lastNameLabel} must be at most 32 characters`),
    [EMAIL]: string()
      .required(`${emailAddressLabel} is a required field`)
      .min(5, `${emailAddressLabel} must be at least 5 characters`)
      .max(255, `${emailAddressLabel} must be at most 255 characters`)
      .email(`Please enter the correct ${emailAddressLabel}`)
      .label(labels[EMAIL]),
    [PRIMARY_PHONE]: schemaPrimaryPhone,
  }),
});

const asArray = (path) => path.split('.');
const accountTypeOptions = Object.entries(AccountTypeName);

export const ConvertAccountModal = ({ onClose, account, onSubmit }) => {
  const { ownerCustomerInfo } = account;
  const [hasChanges, setHasChanges] = useState(false);
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...account,
      ownerCustomerInfo: {
        ...ownerCustomerInfo,
        address: ownerCustomerInfo.address || {},
        otherPhone: ownerCustomerInfo.otherPhone || { type: PhoneType.OTHER },
      },
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const phoneNumberValue = watch(`${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${PHONE_NUMBER}`);

  useUpdatePhoneNumberType(phoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(phoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(smsCapabilityPath, value),
  });

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const commonParts = { control };

  const handleSubmitForm = (data) => {
    let updateCustomer = mergeDeep(account, data);

    const hasOtherPhoneNumber = getIn(updateCustomer, ['ownerCustomerInfo', 'otherPhone', 'number'], '');

    if (!hasOtherPhoneNumber) {
      updateCustomer = setIn(updateCustomer, ['ownerCustomerInfo', 'otherPhone'], null);
    }

    const hasPostalCode = getIn(updateCustomer, ['ownerCustomerInfo', 'address', 'postalCode'], '');

    if (!hasPostalCode) {
      updateCustomer = setIn(updateCustomer, ['ownerCustomerInfo', 'address'], null);
    }

    onSubmit(updateCustomer);
    setIsDisabledInProcessing(true);

    accountAPI
      .updateAccountInfo(updateCustomer)
      .then(() => {
        enqueueSnackbar('Customer information saved successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(`Can't update customer information`, { variant: 'error' });
      })
      .then(() => {
        onClose();
        setIsDisabledInProcessing(false);
      });
  };

  const childrenAction = (
    <Box width="100%">
      <Box display="flex" justifyContent="center">
        <Button
          color="secondary"
          rounded
          disabled={!hasChanges || isDisabledInProcessing}
          loading={isDisabledInProcessing}
          onClick={handleSubmit(handleSubmitForm)}
        >
          create
        </Button>
      </Box>
    </Box>
  );

  return (
    <Modal
      title="Convert"
      maxWidth="xs"
      color="primary"
      open
      onClose={onClose}
      childrenAction={childrenAction}
      disabledInProcessing={isDisabledInProcessing}
    >
      <FormProvider {...formMethods}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              label={getIn(labels, asArray(accountNamePath), '')}
              name={accountNamePath}
              {...commonParts}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              label={getIn(labels, asArray(ownerCustomerInfoFirstNamePath), '')}
              name={ownerCustomerInfoFirstNamePath}
              {...commonParts}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              fullWidth
              label={getIn(labels, asArray(ownerCustomerInfoLastNamePath), '')}
              name={ownerCustomerInfoLastNamePath}
              {...commonParts}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              label={getIn(labels, asArray(ownerCustomerInfoEmailPath), '')}
              name={ownerCustomerInfoEmailPath}
              {...commonParts}
            />
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            <PhoneFormGroup
              labels={primaryPhoneLabels}
              name={ownerCustomerInfoPrimaryPhonePath}
              displayPhoneCarrierIcon
            />
          </Grid>
          <Grid item sm={12} xs={12}>
            <Select
              fullWidth
              label={getIn(labels, asArray(accountTypePath), '')}
              name={accountTypePath}
              {...commonParts}
              options={accountTypeOptions}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};

ConvertAccountModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: pt.object.isRequired,
  onClose: pt.func,
  onSubmit: pt.func,
};

ConvertAccountModal.defaultProps = {
  onClose: null,
  onSubmit: null,
};
