import React, { useEffect, useState } from 'react';

import { DateTimePicker, Modal, Select, TextInput, useConfirm } from '@elromcoinc/react-shared';
import { Box, Grid } from '@material-ui/core';
import { isBefore, parseISO } from 'date-fns';
import { getIn } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getManagerList } from 'admin/autodux/UsersAutodux';
import ManualActivityType from 'admin/constants/ManualActivityType';
import Activity from 'admin/entities/ManualActivity';

const MAXIMUM_NOTE_SIZE = 2000;

const ManageActivity = ({ onCancel, onSave, activity, open }) => {
  const [currentActivity, setCurrentActivity] = useState(Activity.create(activity || undefined));
  const [error, setError] = useState();
  const managersList = useSelector(getManagerList);
  const employees = managersList.map((it) => [it.id, it.fullName]);
  const currentUserId = useSelector((state) => getIn(state, ['auth', 'user', 'id'], null));
  const { confirm: confirmDelete, ConfirmationDialog: ConfirmationDeleteDialog } = useConfirm({
    title: 'Warning',
    message: 'Do you really want to delete this activity?',
    confirmTitle: 'Continue',
    cancelTitle: 'Cancel',
  });

  const handleOpenDeleteDialog = async () => {
    const answer = await confirmDelete();

    if (answer) {
      onSave(currentActivity.set('deleted', true));
    }
  };

  useEffect(() => {
    if (!currentActivity.performerId && currentUserId) {
      setCurrentActivity((latest) => latest.set('performerId', currentUserId));
    }
  }, [currentUserId]);

  const handleChangeDate = (name) => (value) => {
    setCurrentActivity((old) => old.set(name, value));
  };

  const handleOnInputChange = ({ target: { name, value } }) => {
    if (name === 'notes' && value?.length >= MAXIMUM_NOTE_SIZE) {
      setError('Maximum symbols - 2000');
      return;
    }
    setError(null);
    setCurrentActivity((old) => old.set(name, value));
  };
  const handleOnSave = () => onSave(currentActivity);

  const actions = [
    { label: 'cancel', onClick: onCancel, color: 'primary' },
    {
      label: currentActivity.id ? 'save' : 'add',
      onClick: handleOnSave,
      color: 'primary',
      disabled: !!error,
    },
  ];

  if (currentActivity.id) {
    const dateTime = parseISO(activity.performed);
    const isPastActivity = isBefore(dateTime, new Date());

    actions.unshift({
      label: 'delete',
      onClick: handleOpenDeleteDialog,
      color: 'secondary',
      disabled: isPastActivity,
    });
  }

  return (
    <Modal
      open={open}
      title={`${currentActivity.id ? 'Save' : 'Add'} Activity`}
      onClose={onCancel}
      maxWidth="md"
      color="grey"
      actions={actions}
    >
      <Box mx={2}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Select
              fullWidth
              label="Activity Type"
              name="manuallyEnteredActivityType"
              value={currentActivity.manuallyEnteredActivityType}
              onChange={handleOnInputChange}
              options={ManualActivityType.options}
            />
          </Grid>
          <Grid item xs={4}>
            <DateTimePicker
              fullWidth
              value={currentActivity.performed}
              onChange={handleChangeDate('performed')}
              name="performed"
              label="Activity Date"
              disableFuture
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              fullWidth
              label="Sales Person"
              name="performerId"
              value={currentActivity.performerId}
              onChange={handleOnInputChange}
              options={employees}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fullWidth
              label="Person Contacted"
              name="contactedName"
              value={currentActivity.contactedName}
              onChange={handleOnInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              fullWidth
              label="Contact Email or Phone"
              name="contactedEmailOrPhone"
              value={currentActivity.contactedEmailOrPhone}
              onChange={handleOnInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              label="Note"
              name="notes"
              value={currentActivity.notes}
              formError={error}
              onChange={handleOnInputChange}
              multiline
              rows={8}
            />
          </Grid>
        </Grid>
      </Box>
      {ConfirmationDeleteDialog()}
    </Modal>
  );
};

ManageActivity.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  activity: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

ManageActivity.defaultProps = {
  open: false,
};

export default ManageActivity;
