import React from 'react';

import { BodyBigText, JobType, LONG_DISTANCE_PLANNER, isLongDistanceService } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { CrewOrPayroll } from 'admin/components/OrderWindow/blocks/AssignedCrews/CrewOrPayroll';
import { useOrderServiceIndex, useOrderState, usePayrollContext } from 'admin/components/OrderWindow/context';
import { TripPlanerType } from 'common-types';
import Card from 'common/components/Card';

export const AssignedCrews = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const { jobPayroll } = usePayrollContext();
  const serviceType = order?.getServiceType(serviceIndex);
  const isLongDistance = isLongDistanceService(serviceType);
  const tripPlanerType = order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.get(LONG_DISTANCE_PLANNER);

  return (
    <Box width="100%">
      <Card title={`${!!jobPayroll ? 'Payroll' : 'Crew Members'}`}>
        <Box>
          <CrewOrPayroll />
        </Box>
        {isLongDistance && tripPlanerType !== TripPlanerType.TRIP_PLANNER && (
          <Box className="mt-1">
            <BodyBigText>Delivery</BodyBigText>
            <CrewOrPayroll type={JobType.DELIVERY} />
          </Box>
        )}
      </Card>
    </Box>
  );
};
