import React from 'react';

import { Modal } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';

import SchedulerBox from 'admin/components/OrderWindow/SchedulerBox';
import { TruckSchedulerBoxProps } from 'admin/components/OrderWindow/SchedulerBox/TruckSchedulerBox';
import { useMobile } from 'admin/hooks/useMobile';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    '& .MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

interface TruckCalendarProps extends TruckSchedulerBoxProps {
  onSave: () => void;
  onCancel: () => void;
  open: boolean;
}

const TruckCalendar = ({ onSave, onCancel, open, ...rest }: TruckCalendarProps) => {
  const isMobile = useMobile();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      title="Truck Calendar"
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: onSave },
      ]}
      fullWidth
      fullScreen={isMobile}
      minimizeContent={isMobile}
      minimalPaddingTitle={!isMobile}
      maxWidth="xl"
      className={classes.root}
    >
      <Box mx={isMobile ? 0 : 2}>
        <SchedulerBox {...rest} isOnModal />
      </Box>
    </Modal>
  );
};

export default TruckCalendar;
