import React from 'react';
import pt from 'prop-types';
import { Box } from '@material-ui/core';
import { HeaderBigText, Modal } from '@elromcoinc/react-shared';

export const UnsavedChanges = ({ open, onSave, onCancel, onDiscard, inFlight }) => {
  const errorMessage = `You haven't saved the changes!`;

  const actionsNoticeModal = [
    {
      label: 'cancel',
      onClick: onCancel,
      disabled: inFlight,
    },
    {
      label: 'close without saving',
      onClick: onDiscard,
      disabled: inFlight,
    },
    {
      label: 'save & close',
      onClick: onSave,
      disabled: inFlight,
    },
  ];

  return (
    <Modal open={open} title="Warning" onClose={onCancel} actions={actionsNoticeModal} disabledInProcessing={inFlight}>
      <Box display="flex" justifyContent="center">
        <HeaderBigText>{errorMessage}</HeaderBigText>
      </Box>
    </Modal>
  );
};

UnsavedChanges.propTypes = {
  open: pt.bool.isRequired,
  onSave: pt.func.isRequired,
  onCancel: pt.func.isRequired,
  onDiscard: pt.func.isRequired,
  inFlight: pt.bool.isRequired,
};
