import React from 'react';

import {
  Badge,
  BodySmallText,
  BodyText,
  HeaderBigText,
  MoveUnit,
  Order,
  formatCurrency,
  theme,
} from '@elromcoinc/react-shared';
import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ListComponent from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useOrderSettingUnits } from 'admin/components/OrderWindow/context';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import InventoryItem from 'admin/entities/InventoryItem';
import { IconLabelButton } from 'common/components/Widgets';

const SpecialHandlingContainer = styled(Box)`
  min-width: 20rem;
  max-width: 100vw;
  max-height: 50vh;
  padding: 1rem;
`;
const HighlightedItem = styled(ListItem)`
  margin-top: 4px;
  background-color: ${theme.palette.action.hover};
`;

function generateName(item) {
  const postfix = item.quantity > 1 ? ` (${item.quantity} items)` : '';
  return `${item.name}${postfix}`;
}

function generateDescription(item) {
  return [
    [item.cratingRequired || 0, 'crating'],
    [item.assemblyRequired || 0, 'disassembly/assembly'],
    [item.wallDismountingRequired || 0, 'wall dismounting'],
  ]
    .filter(([count]) => count > 0)
    .reduce((acc, [count, name]) => acc.set(count, acc.get(count, List()).push(name)), Map())
    .map((names, count) => `${count} require ${names.join(', ')}`)
    .join(', ');
}

export default function InventorySettingButton({ order, onClick }) {
  const { inventoryCuFt, inventoryWeight } = useOrderSizing(order);
  const { moveUnitLabelsShort, moveUnit } = useOrderSettingUnits();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [itemsWithSpecialHandling, setItemsWithSpecialHandling] = React.useState(List());
  const [numberOfItemsWithSpecialHandling, setNumberOfItemsWithSpecialHandling] = React.useState(0);
  const theme = useTheme();
  const colorInventoryButton = theme.palette.error.main;

  React.useEffect(() => {
    const inventoryList = order.services.reduce(
      (accumulator, service, index) => accumulator.concat(order.getInventoryList(index)),
      List(),
    );
    const itemsWithSpecialHandlingNeeds = inventoryList.filter(InventoryItem.hasSpecialHandling);
    setItemsWithSpecialHandling(itemsWithSpecialHandlingNeeds);
    setNumberOfItemsWithSpecialHandling(
      itemsWithSpecialHandlingNeeds
        .map(InventoryItem.getNumberOfItemsWithSpecialHandling)
        .reduce((sum, quantity) => sum + quantity),
    );
  }, [order.getServiceWaypoints()]);

  const handleOnBadgeClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasInventory = !!inventoryCuFt;
  const TextComponent = hasInventory ? BodySmallText : BodyText;
  const weight = moveUnit === MoveUnit.CUBIC_FEET ? inventoryCuFt : inventoryWeight;
  const formattedWeight = weight ? `${formatCurrency(weight, 0)} ${moveUnitLabelsShort} ` : '';

  return (
    <>
      <IconLabelButton
        data-testid="InventoryButton"
        color={hasInventory ? colorInventoryButton : 'default'}
        startIcon={<LocalShippingOutlinedIcon />}
        onClick={onClick}
      >
        <Badge onClick={handleOnBadgeClick} badgeContent={numberOfItemsWithSpecialHandling} />
        <TextComponent textAlign="center">{`${formattedWeight} Inventory`}</TextComponent>
      </IconLabelButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SpecialHandlingContainer>
          <HeaderBigText>
            <strong>Special Handling</strong>
          </HeaderBigText>
          <ListComponent>
            {itemsWithSpecialHandling.map((item) => (
              <HighlightedItem key={item.id}>
                <ListItemText
                  primaryTypographyProps={{ color: 'error' }}
                  primary={generateName(item)}
                  secondary={generateDescription(item)}
                />
              </HighlightedItem>
            ))}
          </ListComponent>
        </SpecialHandlingContainer>
      </Popover>
    </>
  );
}

InventorySettingButton.propTypes = {
  order: PropTypes.instanceOf(Order).isRequired,
  onClick: PropTypes.func.isRequired,
};
