import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { IconProps } from './IconProps';

const CanadaFlagIcon = (props: IconProps) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <circle cx="256" cy="256" r="256" fill="#F0F0F0" />
    <path
      d="M512,256c0-101.494-59.065-189.19-144.696-230.598v461.195C452.935,445.19,512,357.494,512,256z"
      fill="#D80027"
    />
    <path d="M0,256c0,101.494,59.065,189.19,144.696,230.598V25.402C59.065,66.81,0,154.506,0,256z" fill="#D80027" />
    <polygon
      points="300.522,289.391 345.043,267.13 322.783,256 322.783,233.739 278.261,256 300.522,211.478
		278.261,211.478 256,178.087 233.739,211.478 211.478,211.478 233.739,256 189.217,233.739 189.217,256 166.957,267.13
		211.478,289.391 200.348,311.652 244.87,311.652 244.87,345.043 267.13,345.043 267.13,311.652 311.652,311.652 	"
      fill="#D80027"
    />
  </SvgIcon>
);

export { CanadaFlagIcon };
