import React from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodyBigText,
  BodyText,
  CreditCardType,
  IconButton,
  Radio,
  cardTypeIcon,
} from '@elromcoinc/react-shared';
import { Box, ButtonBase, Paper, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { format, parse } from 'date-fns';

import { PaymentProfileDto } from 'admin/api/PaymentActionsApi';

const useStyles = makeStyles((theme) => ({
  creditCard: {
    height: 180,
    width: 250,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: 8,
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
  },
  cardNumber: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
    fontSize: '1.5rem',
    '& p': {
      fontSize: '1.7rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.3rem',
      },
    },
    '& svg': {
      [theme.breakpoints.up('sm')]: {
        width: '4rem',
        height: '3rem',
      },
    },
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface CardsOnFileModalProps {
  value: PaymentProfileDto;
  useByDefault: boolean;
  canEdit?: boolean;
  onDelete(): void;
  onEdit(): void;
  onSetDefault(): void;
}

const getCardType = (type: string): CreditCardType => {
  return (
    Object.keys(CreditCardType)
      .filter((it) => it === type?.toUpperCase())
      .map((key) => key as CreditCardType)[0] || CreditCardType.VISA
  );
};

const CreditCard: React.FC<CardsOnFileModalProps> = ({
  value,
  onDelete,
  onSetDefault,
  useByDefault,
  onEdit,
  canEdit = false,
}) => {
  const classes = useStyles();
  return (
    <ButtonBase disableRipple component={Paper} className={classes.creditCard}>
      <Box className={classes.flexRow} flexGrow={1}>
        <Radio label="Default" color="primary" checked={useByDefault} onChange={onSetDefault} />
        <Box>
          <IconButton onClick={onDelete} color="default" size="medium">
            <DeleteIcon />
          </IconButton>
          {canEdit && (
            <IconButton onClick={onEdit} color="default" size="medium">
              <EditIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box className={classes.cardNumber} flexGrow={1} mt={2} mb={2} px={1}>
        <BodyText>
          &#42;&#42;&#42;&#42;&nbsp;&#42;&#42;&#42;&#42;&nbsp;&#42;&#42;&#42;&#42;&nbsp;{value.lastFourDigits}
        </BodyText>
        {cardTypeIcon[getCardType(value.cardType)]}
      </Box>
      <Box className={classes.flexRow} flexGrow={1}>
        <Box>
          <BodyBigText>CARD HOLDER</BodyBigText>
          <BodyText>
            {value.firstName} {value.lastName}
          </BodyText>
        </Box>
        <Box display="flex" flexDirection="row">
          <Box mr={1}>
            <BodyBigText>EXP DATE</BodyBigText>
            <BodyText>{format(parse(value.expiration, BACKEND_DATE_FORMAT, new Date()), 'MM/yyyy')}</BodyText>
          </Box>
          <Box>
            <BodyBigText>ZIP</BodyBigText>
            <BodyText>{value.postalCode}</BodyText>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  );
};

export { CreditCard };
