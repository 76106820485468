export enum EmployeeCommissionType {
  /**
   * This will be a specified rate per hour. This means that however many hours the worker worked,
   * they will be paid that rate per hour.
   */
  HOURLY_RATE = 'HOURLY_RATE',
  /**
   * This will function the same way as the hourly rate, but this will only be applied if the employee is on a job
   * listed as a helper.
   */
  HOURLY_RATE_AS_HELPER = 'HOURLY_RATE_AS_HELPER',
  /**
   * This will be a specified rate per day and the employee will be paid that rate for the day,
   * regardless of how many hours they work.
   * In this instance, if they are either assigned to any job on that date or clocked in or out
   * of the tracker (once that is functional), then they will be paid that amount for that day,
   * regardless of how many hours it was.`
   */
  DAILY_RATE = 'DAILY_RATE',
  /**
   * This is a % based type of pay and it is taken from the total of  In order to calculate the packing commission,
   * we would take the total of the packing and pay a percentage off of that packing cost.
   */
  PACKING_COMMISSION = 'PACKING_COMMISSION',
  /**
   * This works just like the packing commission but instead it is taken from the total of the additional services. It is a percentage.
   */
  ADDITIONAL_SERVICES_COMMISSION = 'ADDITIONAL_SERVICES_COMMISSION',
  /**
   * This is a % based type of pay, and it is taken from the job total.
   */
  COMMISSION_FROM_THE_TOTAL = 'COMMISSION_FROM_THE_TOTAL',
  /**
   * This is a daily bonus that will be added to the employee’s pay each day that they are paid for working.
   */
  PER_DAY_BONUS = 'PER_DAY_BONUS',
  /**
   * This is a bonus that will be added to the employees pay for each job that they are assigned to.
   */
  PER_JOB_BONUS = 'PER_JOB_BONUS',

  TIP = 'TIP',

  RECEIPT = 'RECEIPT',
}
