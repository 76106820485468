import React, { useRef, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import {
  HeaderRow,
  HoursContainer,
  renderHours,
  Spacer,
  TruckHeaderRow,
} from 'admin/components/OrderWindow/SchedulerBox/common';
import pt from 'prop-types';
import useScrollTo7Am from 'admin/components/OrderWindow/SchedulerBox/hooks/useScrollTo7Am';
import { BACKEND_DATE_FORMAT, Order } from '@elromcoinc/react-shared';
import { format } from 'date-fns';
import {
  getEstimatorsToRender,
  getEstimatorWorks,
  renderEstimation,
  renderEstimatorName,
} from 'admin/components/OrderWindow/SurveySchedulerBox/config';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    '& *': {
      boxSizing: 'border-box',
    },
  },
});

export const DayView = ({
  estimators,
  dense,
  handleEstimatorClick,
  date,
  order,
  currentSurveyValue,
  handleCloseModal,
}) => {
  const classes = useStyles();
  const scrollRef = useRef({});
  useScrollTo7Am(scrollRef);
  const surveyDate = date && format(date, BACKEND_DATE_FORMAT);
  const estimatorWorks = getEstimatorWorks(estimators, currentSurveyValue, surveyDate, order);
  const estimatorsToRender = getEstimatorsToRender(estimators);
  const [selectedWork, setSelectedWork] = useState(null);

  const handleOnWorkClick = (workId) => {
    setSelectedWork(workId);
  };

  return (
    <Box className={classes.wrapper}>
      <Box>
        <TruckHeaderRow>
          <Spacer />
        </TruckHeaderRow>
        {estimatorsToRender.map(
          renderEstimatorName(
            handleEstimatorClick,
            dense,
            estimatorWorks,
            currentSurveyValue.estimatorId,
            date,
            currentSurveyValue,
          ),
        )}
      </Box>
      <HoursContainer ref={scrollRef}>
        <HeaderRow>{renderHours(false)}</HeaderRow>
        {estimatorsToRender.map(
          renderEstimation(
            dense,
            estimatorWorks,
            order,
            date,
            currentSurveyValue,
            handleOnWorkClick,
            handleCloseModal,
            selectedWork,
          ),
        )}
      </HoursContainer>
    </Box>
  );
};

DayView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  estimators: pt.array,
  dense: pt.bool,
  handleEstimatorClick: pt.func,
  date: pt.instanceOf(Date).isRequired,
  order: pt.instanceOf(Order).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSurveyValue: pt.object.isRequired,
  handleCloseModal: pt.func,
};

DayView.defaultProps = {
  estimators: [],
  dense: false,
  handleEstimatorClick: null,
  handleCloseModal: null,
};
