import { theme } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export default styled(Box)`
  && {
    background-color: ${theme.palette.grey[100]};
    height: calc(95vh - 51px);
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 6px;
    @media (max-width: 768px) {
      padding: 12px;
      max-height: calc(100vh - 51px);
      height: calc(100vh - 51px);
    }
  }
`;
