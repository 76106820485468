import React, { useEffect, useState } from 'react';

import { AdminPermissions, useHasPermission } from '@elromcoinc/react-shared';
import { Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import communicationAPI from 'admin/api/CommunicationsAPI';
import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getReloadAmount } from 'admin/autodux/ReloadAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import DashboardTable from 'admin/components/Dashboard/DashboardTable';
import { OrderMessageModal } from 'admin/components/Orders/modals';
import { getExpandableRow } from 'admin/components/Reports/config';
import { CommunicationActivities } from 'common-types';

import { makeColumns } from './config/makeColumns';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    activityContainer: {
      flexWrap: 'nowrap',
      '& > .circle ': {
        marginRight: '5px',
      },
    },
    tableCell: {
      fontSize: '12px !important',
      border: 'none',
      padding: '1px 1rem',
    },
    badge: {
      fontSize: '10px',
      height: '15px',
      minWidth: '15px',
    },
    capitalize: {
      textTransform: 'capitalize',
    },
    smsIcon: {
      fontSize: '1rem',
    },
  }),
);

const DashboardCommunicationActivity = () => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  const canSeeCustomerOnline = useHasPermission(AdminPermissions.PERM_CAN_SEE_CUSTOMERS_ONLINE);
  const [orders, setOrders] = useState<CommunicationActivities[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | null>(null);
  const [selectedMessageOrder, setSelectedMessageOrder] = useState(null);
  const reloadAmount = useSelector(getReloadAmount);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);
  const isLogout: boolean = useSelector(getIsLogout);

  const downloadOrders = () => {
    communicationAPI.getOrdersWithMessages<CommunicationActivities>().then(({ pageElements }) => {
      setOrders(pageElements);
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setIsFetching(true);
      downloadOrders();
      const updateData = setInterval(downloadOrders, 30000);
      return () => clearInterval(updateData);
    }
  }, [reloadAmount, isSessionExpired, isLogout]);

  const onRowSelectionChangeOverridden = ([rowIndex]: [rowIndex: number]) => {
    const { orderId } = orders[rowIndex] || {};
    if (currentOrderId && orderId === currentOrderId) {
      dispatch(openOrder(orderId));
    } else {
      setCurrentOrderId(orderId!);
    }
  };

  const onViewAllClick = () => setRedirect(true);

  const handleCloseOrderMessageModal = () => {
    setSelectedMessageOrder(null);
  };

  const columns = makeColumns(orders, { canSeeCustomerOnline, theme, classes, setSelectedMessageOrder });

  const options = {
    onRowSelectionChangeOverridden,
    showExpandableRowsOnMobile: true,
    alternateTableRowColors: true,
    inFlight: isFetching,
    tableBodyHeight: '100%',
    smallPadding: isMobile,
    sort: false,
    renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      return getExpandableRow(orders, rowData, rowMeta, columns, classes);
    },
  };

  return (
    <>
      {redirect && <Redirect to="/communications" />}
      {selectedMessageOrder && (
        <OrderMessageModal onClose={handleCloseOrderMessageModal} order={selectedMessageOrder} />
      )}
      <DashboardTable
        title="Recent Communication"
        data={orders}
        columns={columns}
        options={options}
        onViewAllClick={onViewAllClick}
      />
    </>
  );
};

export default DashboardCommunicationActivity;
