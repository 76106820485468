import React from 'react';

import {
  BodyText,
  Button,
  DatePicker,
  IconButton,
  UI_DATE_AND_YEAR_IN_NOMINATIVE_SINGULAR_FORMAT,
  UI_FULL_DATE_IN_NOMINATIVE_SINGULAR_FORMAT,
  UI_MONTH_AND_DATE_IN_NOMINATIVE_SINGULAR_FORMAT,
  toDate,
} from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classNames from 'classnames';
import { addDays, addWeeks, endOfWeek, format, isEqual, isSameMonth, startOfWeek, subDays, subWeeks } from 'date-fns';
import styled from 'styled-components';

import { useSchedulerContext } from 'admin/components/OrderWindow/SchedulerBox/SchedulerManager';
import { TripPlanerType } from 'common-types';

const Wrapper = styled(Box)`
  && {
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 8px;
    @media (max-width: 599.95px) {
      display: block;
    }
  }
`;

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    padding: 0,
    alignItems: 'center',
    [breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  datePicker: {
    width: '230px',
    margin: 0,
    marginLeft: spacing(1),
    '& .MuiIconButton-root': {
      marginTop: spacing(-2),
    },
    '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
      border: 'none',
    },
  },
  datePickerInput: {
    padding: 0,
    cursor: 'pointer',
  },
}));

export const formatWeekRange = (weekDay: Date) => {
  const start = startOfWeek(weekDay);
  const end = endOfWeek(weekDay);
  if (isSameMonth(start, end)) {
    return `${format(start, UI_MONTH_AND_DATE_IN_NOMINATIVE_SINGULAR_FORMAT)} - ${format(
      end,
      UI_DATE_AND_YEAR_IN_NOMINATIVE_SINGULAR_FORMAT,
    )}`;
  }
  return `${format(start, UI_FULL_DATE_IN_NOMINATIVE_SINGULAR_FORMAT)} - ${format(
    end,
    UI_FULL_DATE_IN_NOMINATIVE_SINGULAR_FORMAT,
  )}`;
};

interface HeaderProps {
  date: Date;
  onDateChange: (date: Date) => void;
  onMoveDate: (date: Date) => void;
  toggleViewMode: () => void;
  moveDate?: string;
  numberOfTrucks: number | null;
  dayMode: boolean;
  deliveryDate?: string;
  longDistancePlanner?: TripPlanerType;
  isAvailableDeliveryDates?: boolean;
  deliveryDateStart?: string;
  deliveryDateEnd?: string;
}

export default function Header(props: HeaderProps) {
  const {
    toggleViewMode,
    numberOfTrucks,
    date,
    dayMode,
    onDateChange,
    onMoveDate,
    moveDate,
    isAvailableDeliveryDates = false,
    deliveryDateStart,
  } = props;
  const showMoveDateBtn = !!moveDate;
  const classes = useStyles();
  const buttonData = ['Day', 'Week'] as const;
  const currentOption = buttonData[dayMode ? 0 : 1];
  const selectDate = moveDate;
  const { setIsDeliveryMode } = useSchedulerContext() || {};
  const clickHandler = (option: typeof currentOption) => () => {
    if (currentOption === option) {
      return;
    }

    toggleViewMode();
  };

  const isDayMode = currentOption === buttonData[0];
  const currentDate = isDayMode ? date : endOfWeek(date);

  const handleMoveDate = (mDate: Date) => () => {
    setIsDeliveryMode(false);
    onMoveDate(mDate);
  };

  const handleDeliveryDate = (mDate: Date) => () => {
    if (mDate) {
      setIsDeliveryMode(true);
    }
    onMoveDate(mDate);
  };

  function renderMoveDateBtn(mDate: Date) {
    return (
      <Box ml={2} mb={1}>
        <Button
          onClick={handleMoveDate(mDate)}
          variant="outlined"
          color={isEqual(date, mDate) ? 'primary' : 'default'}
          rounded
          data-testid="moveDate"
        >
          <Box>
            <BodyText>Move Date</BodyText>
            <BodyText>{`(${format(mDate, 'P')})`}</BodyText>
          </Box>
        </Button>
      </Box>
    );
  }

  function renderDeliveryDateButton(mDate: Date | null) {
    const label = mDate ? format(mDate, 'P') : 'Not set';

    return (
      <Box ml={2} mb={1} key={label}>
        <Button
          onClick={handleDeliveryDate(mDate!)}
          variant="outlined"
          color={mDate && isEqual(date, mDate) ? 'primary' : 'default'}
          rounded
          data-testid="truckBlockDate"
        >
          <Box>
            <BodyText>Truck block date</BodyText>
            <BodyText>{`(${label})`}</BodyText>
          </Box>
        </Button>
      </Box>
    );
  }

  function renderMoveDateSelector() {
    return renderMoveDateBtn(toDate(selectDate)!);
  }

  const handleDateChange = (days: (date: Date | number, amount: number) => Date) => () => {
    onDateChange(days(currentDate, 1));
  };

  return (
    <Wrapper>
      <Box display="flex" justifyContent="center" alignItems="center">
        {numberOfTrucks !== null && (
          <BodyText>
            Trucks Available:&nbsp;
            {numberOfTrucks}
          </BodyText>
        )}
      </Box>
      <div className={classes.grow} />
      <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap">
        <Box display="flex" justifyContent="center" alignItems="center">
          <IconButton
            color="primary"
            variant="default"
            size="small"
            data-testid="leftArrowButtonTruckSchedule"
            onClick={handleDateChange(isDayMode ? subDays : subWeeks)}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <Box minWidth={230}>
            {isDayMode && (
              <Box display="flex" justifyContent="center">
                <DatePicker
                  fullWidth={false}
                  data-testid="datePicker"
                  value={currentDate || new Date()}
                  format="EEEE, MMM do, yyyy"
                  className={classes.datePicker}
                  onChange={onDateChange as any}
                  InputProps={{
                    classes: { input: classNames(classes.datePickerInput) },
                  }}
                />
              </Box>
            )}
            {!isDayMode && (
              <BodyText align="center" style={{ minWidth: '200px' }}>
                <b>{formatWeekRange(currentDate)}</b>
              </BodyText>
            )}
          </Box>
          <IconButton
            size="small"
            color="primary"
            variant="default"
            data-testid="rightArrowButtonTruckSchedule"
            onClick={handleDateChange(isDayMode ? addDays : addWeeks)}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Box>
        {showMoveDateBtn && renderMoveDateSelector()}
        {showMoveDateBtn && isAvailableDeliveryDates && renderDeliveryDateButton(toDate(deliveryDateStart!)!)}
      </Box>
      <div className={classes.grow} />
      <Box display="flex" justifyContent="center" alignItems="center" width={150}>
        {buttonData.map((item) => (
          <Box mr={1} key={item}>
            <Button
              rounded
              disableElevation
              color="primary"
              variant={currentOption === item ? 'contained' : 'text'}
              onClick={clickHandler(item)}
            >
              {item}
            </Button>
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
}
