import React, { useMemo, useState } from 'react';

import {
  BACKEND_DATE_FORMAT,
  Button,
  DateRangePicker,
  MoveTypeTypes,
  RangeValue,
  Select,
  UI_DATE_FORMAT,
  UI_DATE_FORMAT_SHORT,
  UI_LOCALIZED_TIME_FORMAT,
} from '@elromcoinc/react-shared';
import { Box, Paper, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns';
import fileDownload from 'js-file-download';
import { DateRange } from 'materialui-daterange-picker';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import reportsApi from 'admin/api/ReportsAPI';
import { getInFlight } from 'admin/autodux/ReloadAutodux';
import { CompareMonth } from 'admin/components/Reports/IncomeTab';
import { IncomeTable } from 'admin/components/Reports/IncomeTab/IncomeTable';
import { ClassNameMap } from 'admin/components/Reports/config';
import EstimateType from 'admin/constants/EstimateType';
import { ReportFileName, ReportsType } from 'admin/constants/ReportsType';
import { GeneralServiceDTO } from 'common-types/GeneralServiceDTO';
import { ReloadButton } from 'common/components/ReloadButton';

const useStyles = makeStyles<Theme>(({ breakpoints, palette, typography, spacing }) =>
  createStyles({
    tableCell: {
      border: 'none',
      padding: '1px 1rem',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      [breakpoints.down('sm')]: {
        flexBasis: '100%',
      },
    },
    tableItem: {
      [breakpoints.up('sm')]: {
        breakInside: 'avoid-column',
        pageBreakInside: 'avoid',
      },
      paddingBottom: '10px',
    },
    container: {
      '& .el-datatable': {
        '& .MuiPaper-root': {
          borderRadius: spacing(0, 0, 0.75, 0.75),
        },

        '& .MuiTable-root': {
          borderRadius: spacing(0, 0, 0.75, 0.75),

          '& .MuiTableFooter-root': {
            borderRadius: spacing(0, 0, 0.75, 0.75),

            '& tr': {
              borderRadius: spacing(0, 0, 0.75, 0.75),

              '& td': {
                borderRadius: spacing(0, 0, 0.75, 0.75),
              },
            },
          },
        },
      },
    },
  }),
);

interface LeadSource {
  name: string;
  id: number;
}

export interface CompanyIncomeReport extends OrderSummary {
  lineHaulCharge: RangeValue;
  laborCharge: RangeValue;
  flatRateCharge: number;
  travelCharge: number;
  fuelCharge: RangeValue;
  valuation: number;
  packingCharge: number;
  additionalServicesCharge: number;
  additionalFees: number;
  taxes: number;
  grandTotal: RangeValue;
  closingGrandTotal: RangeValue;
  numericGrandTotal: number;
  completed: boolean;
  paymentReceived: number;
  balance: RangeValue;
  estimateType?: EstimateType;
  moveType?: MoveTypeTypes;
  moveDate: string;
  generalService?: GeneralServiceDTO;
  leadSource?: LeadSource;
}

// TODO connect all branches with BE
const mockBranches = [['0', 'Current branch']] as SelectOptions;

const errorVariant: OptionsObject = { variant: 'error' };
const successVariant: OptionsObject = { variant: 'success' };

export const IncomeTab = () => {
  const classes: ClassNameMap = useStyles();
  const theme = useTheme<Theme>();
  const isMobileRefresh = useMediaQuery(theme.breakpoints.down('xs'));
  const [compareMonths, setCompareMonths] = useState(false);
  const { enqueueSnackbar } = useSnackbar() as ProviderContext;
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [previousDate, setPreviousDate] = useState<Date | null>(null);
  const [allBranches, setAllBranches] = useState('0');
  const [isDownloading, setIsDownloading] = useState(false);
  const inFlight = useSelector(getInFlight) as boolean;
  const isLoading = inFlight;
  const memorizedPreviousDate = useMemo(() => (previousDate ? previousDate : subMonths(new Date(), 1)), [previousDate]);

  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });

  const handleChangeDate = (value: DateRange) => {
    setDateRange({ ...(value || {}) });
  };

  const handleCompareMonthsClick = () => {
    setCompareMonths(!compareMonths);
  };

  const handleChangeBranch = ({ target: { value } }: { target: HTMLTextAreaElement | HTMLInputElement }) => {
    setAllBranches(value);
  };

  const { startDate, endDate } = dateRange;
  const startDateToLocalDate = format(startDate!, BACKEND_DATE_FORMAT);
  const endDateToLocalDate = format(endDate!, BACKEND_DATE_FORMAT);

  const reportStartDate = format(startDate!, UI_DATE_FORMAT_SHORT);
  const reportEndDate = format(endDate!, UI_DATE_FORMAT_SHORT);
  const downloadTime = format(new Date(), UI_LOCALIZED_TIME_FORMAT);
  const startDateInPeriod = format(startDate!, UI_DATE_FORMAT);
  const endDateInPeriod = format(endDate!, UI_DATE_FORMAT);

  const handleDownloadReport = () => {
    setIsDownloading(true);
    reportsApi
      .downloadCompanyIncomeReport({
        dateRange: {
          startDate: startDateToLocalDate!,
          endDate: endDateToLocalDate!,
        },
      })
      .then((res) => {
        fileDownload(
          res,
          `${ReportFileName[ReportsType.INCOME]}-from-${reportStartDate}-to-${reportEndDate}-at-${downloadTime}.csv`,
        );
        enqueueSnackbar(
          `The CSV file with the company income report for period from ${startDateInPeriod} to ${endDateInPeriod} has been successfully downloaded to file`,
          successVariant,
        );
      })
      .catch(() => {
        enqueueSnackbar(`Can't download a CSV file with the report for the company income`, errorVariant);
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  return (
    <Box className={classes.dataTable}>
      <Paper elevation={0} square className="applyTopRadius">
        <Box py={1} px={0.5} display="flex" justifyContent="space-between" flexWrap="wrap">
          <Box className={classes.actions}>
            <Box display="flex" minWidth="160px">
              <Select
                InputProps={{
                  disableUnderline: true,
                }}
                fullWidth
                label="Branch"
                name="BRANCH"
                options={mockBranches}
                value={allBranches}
                onChange={handleChangeBranch}
                disabled={inFlight}
              />
            </Box>
            {isMobileRefresh && (
              <Box display="flex" justifyContent="flex-end">
                <ReloadButton />
              </Box>
            )}
            <CompareMonth
              compareMonths={compareMonths}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              previousDate={previousDate}
              setPreviousDate={setPreviousDate}
              handleCompareMonthsClick={handleCompareMonthsClick}
              isLoading={isLoading}
              isFlight={inFlight}
            />
          </Box>
          <Box className={classes.actions}>
            <DateRangePicker
              onChange={handleChangeDate}
              placeholder={UI_DATE_FORMAT_SHORT}
              value={dateRange}
              size="small"
            />
            <Box mx={1} my={1}>
              <Button
                size="medium"
                rounded
                textTransform="uppercase"
                variant="contained"
                color="primary"
                disabled={isDownloading || inFlight}
                loading={isDownloading}
                onClick={handleDownloadReport}
              >
                Export
              </Button>
            </Box>
            {!isMobileRefresh && (
              <Box display="flex" justifyContent="flex-end">
                <ReloadButton />
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
      <Paper elevation={0} square className={!compareMonths ? classes.container : ''}>
        <IncomeTable title={!compareMonths ? '' : 'Current Month'} dateRange={dateRange} currentDate={currentDate!} />
        {compareMonths && (
          <Box className={classes.container} pt={1.5}>
            <IncomeTable title="Previous Month" dateRange={dateRange} currentDate={memorizedPreviousDate} />
          </Box>
        )}
      </Paper>
    </Box>
  );
};
