import React from 'react';

import {
  BACKEND_DATE_FORMAT,
  ExpirationInput,
  Modal,
  TextInput,
  toBackEndDate,
  toDate,
  toDateString,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import paymentActionsApi, { PaymentProfileDto } from 'admin/api/PaymentActionsApi';

import { convertExpirationStringToDate } from '../utils';
import { CARDHOLDER_NAME, EXPIRATION, POSTAL_CODE, editCreditCard, labels } from './Common';

interface EditCardProps {
  onClose: (dto?: PaymentProfileDto | null) => void;
  customerId: number;
  card: PaymentProfileDto;
}

interface UpdateCreditCardForm {
  [CARDHOLDER_NAME]: string;
  [EXPIRATION]: string;
  [POSTAL_CODE]: string;
}

const EditCard: React.FC<EditCardProps> = ({ onClose, customerId, card }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = React.useState(false);
  const methods = useForm<UpdateCreditCardForm>({
    resolver: yupResolver(editCreditCard),
    defaultValues: {
      [CARDHOLDER_NAME]: `${card.firstName} ${card.lastName}`,
      [POSTAL_CODE]: card.postalCode,
      [EXPIRATION]: card.expiration
        ? (toDateString(toDate(card.expiration, BACKEND_DATE_FORMAT)!, 'MM/yy') as string)
        : '',
    },
  });

  function updatePaymentMethod(data: UpdateCreditCardForm) {
    setInFlight(true);
    const name = data[CARDHOLDER_NAME];
    const nameSplitIndex = name.lastIndexOf(' ');
    paymentActionsApi
      .updatePaymentMethod(customerId, card.id, {
        firstName: name.substring(0, nameSplitIndex).trim(),
        lastName: name.substring(nameSplitIndex).trim(),
        billingAddress: {
          postalCode: data[POSTAL_CODE],
        },
        expiration: toBackEndDate(convertExpirationStringToDate(data[EXPIRATION]))!,
      })
      .then((res) => {
        enqueueSnackbar('Successfully update credit card information', { variant: 'success' });
        onClose(res);
      })
      .catch(() => {
        enqueueSnackbar('There was an error during credit card update. Please try later.', { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open
      color="grey"
      title="Edit Card"
      onClose={handleClose}
      disabledInProcessing={inFlight}
      actions={[
        { label: 'Close', onClick: handleClose, variant: 'text' },
        {
          label: 'Update Card',
          loading: inFlight,
          onClick: methods.handleSubmit(updatePaymentMethod),
        },
      ]}
    >
      {/** @ts-ignore */}
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              autocomplete="cc-name"
              label={labels[CARDHOLDER_NAME]}
              name={CARDHOLDER_NAME}
              inputProps={{
                autocomplete: CARDHOLDER_NAME,
                autocorrect: 'off',
                spellcheck: false,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ExpirationInput
              fullWidth
              label={labels[EXPIRATION]}
              placeholder="MM/YY"
              name={EXPIRATION}
              inputProps={{
                inputmode: 'numeric',
                autocomplete: EXPIRATION,
                autocorrect: 'off',
                spellcheck: false,
                maxlength: 5,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput name={POSTAL_CODE} fullWidth label={labels[POSTAL_CODE]} />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};

export default EditCard;
