export function normalizeId(label = '') {
  return label
    .toLowerCase()
    .trim()
    .replace(/[-_]+/g, ' ')
    .replace(/[^\w\s]/g, '')
    .replace(/\s(.)/g, $1 => $1.toUpperCase())
    .replace(/\s/g, '');
}

export const normalizeCurrency = str => (str && str.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')) || '0.00';
export const spacesToNonBreaking = str => (str && str.replace(/\s+/g, '\xa0')) || '';
