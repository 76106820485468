import React, { SyntheticEvent } from 'react';

import { ActivitySourceType, BodyText, Button } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { format } from 'date-fns';

import TaskReminderIcon from 'admin/components/Tasks/TaskReminderIcon';
import TaskStatusRow from 'admin/components/Tasks/TaskStatusRow';
import { EditTaskButtons } from 'admin/components/TasksPage/EditTaskButtons';
import { Task } from 'common-types/Task';

interface makeColumnsOptions {
  onEditTask: (t: Task) => () => void;
  onDeleteTask: (t: Task) => () => void;
  onToggleCompleted: (t: Task) => () => void;
  handleOpenOrderOrAccount: (entityId: number, activeSource: ActivitySourceType) => void;
}

export const makeColumns = (
  rows: Task[],
  { onEditTask, onDeleteTask, onToggleCompleted, handleOpenOrderOrAccount }: makeColumnsOptions,
) => [
  {
    name: 'status',
    label: 'STATUS',
    options: {
      showOnMobileAsExpanded: true,
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];
        const { status } = task;

        return (
          <Box mb={-1} mt={-0.625} display="flex">
            <TaskStatusRow status={status} />
          </Box>
        );
      },
    },
  },
  {
    name: 'openEntity',
    label: 'OPEN',
    options: {
      showOnMobileAsExpanded: true,
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];
        const firstElement = 0;
        const { orderNumber, activitySources } = task;
        const { referencedEntityId, activitySource } = activitySources[firstElement];

        const handleOpen = (event: SyntheticEvent): void => {
          event.stopPropagation();
          handleOpenOrderOrAccount(referencedEntityId, activitySource);
        };

        if (activitySource !== ActivitySourceType.ORDER && activitySource !== ActivitySourceType.CUSTOMER_ACCOUNT) {
          return;
        }

        return (
          <Box display="flex" alignItems="center">
            <Box component="span">
              <Button startIcon={<VisibilityIcon color="primary" />} variant="text" onClick={handleOpen}>
                <BodyText>
                  #{activitySource === ActivitySourceType.ORDER ? orderNumber : `AC-${referencedEntityId}`}
                </BodyText>
              </Button>
            </Box>
          </Box>
        );
      },
    },
  },
  {
    name: 'title',
    label: 'TITLE',
    options: {
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];
        return <BodyText>{task.subject}</BodyText>;
      },
    },
  },
  {
    name: 'assign',
    label: 'ASSIGNED',
    options: {
      showOnMobileAsExpanded: true,
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const { employeeName } = rows[rowIndex];
        return <BodyText>{employeeName}</BodyText>;
      },
    },
  },
  {
    name: 'due',
    label: 'DUE DATE',
    options: {
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];
        return (
          <Box display="flex" alignItems="center">
            {format(task.dueTime!, 'Pp')}
            <TaskReminderIcon task={task} />
          </Box>
        );
      },
    },
  },
  {
    name: 'notes',
    label: 'NOTES',
    options: {
      showOnMobileAsExpanded: true,
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];
        return <BodyText>{task.notes}</BodyText>;
      },
    },
  },
  {
    name: 'actions',
    label: 'Actions',
    options: {
      sort: false,
      customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
        const task = rows[rowIndex];

        return (
          <EditTaskButtons
            task={task}
            onEditTask={onEditTask(task)}
            onDeleteTask={onDeleteTask(task)}
            onToggleCompleted={onToggleCompleted(task)}
          />
        );
      },
    },
  },
];
