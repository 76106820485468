enum BillingCycleType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BI_WEEKLY = 'Biweekly',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  SEMI_ANNUALLY = 'Semiannually',
  ANNUALLY = 'Annually',
}

export { BillingCycleType };
