import styled from 'styled-components';

export const Hour = styled.div`
  min-width: 68px;
  width: calc(150% / ${(props: any) => props?.numHours ?? 24});
  border-right: 1px solid #d7d7da;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
`;
