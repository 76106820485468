import { MutableRefObject, createContext } from 'react';

interface UpdateOrderContextProps {
  applyOrderChangeSetRef: MutableRefObject<Promise<any> | null>;
  saveOrderRef: MutableRefObject<Promise<any> | null>;
}

const UpdateOrderContext = createContext<UpdateOrderContextProps>({} as UpdateOrderContextProps);

export { UpdateOrderContext };
