import { DataOfBillingHistory } from 'admin/components/PlanSettings/utils/DataOfBillingHistory';
import { DataOfCreditCards } from 'admin/components/PlanSettings/utils/DataOfCreditCards';
import { DataOfLicensedUsers } from 'admin/components/PlanSettings/utils/DataOfLicensedUsers';

export const dataOfCreditCards: DataOfCreditCards[] = [
  {
    id: '1',
    name: 'Frank Harris',
    code: '3456',
    expiration: '04/21',
    zip: '02345',
  },
  {
    id: '2',
    name: 'Frank Harris',
    code: '3456',
    expiration: '04/21',
    zip: '02345',
  },
  {
    id: '3',
    name: 'Sarah Johnson',
    code: '3456',
    expiration: '04/21',
    zip: '02345',
  },
];
export const dataOfBillingHistory: DataOfBillingHistory[] = [
  {
    date: '04/21',
    amount: '456',
    description: 'Subscription Fee',
  },
  {
    date: '09/21',
    amount: '345',
    description: 'Additional License',
  },
  {
    date: '01/21',
    amount: '675',
    description: 'Initial Purchase',
  },
];
export const dataOfLicensedUsers: DataOfLicensedUsers[] = [
  {
    assigned: 'Someone 1',
    lastSeen: '09/21',
    status: 'Active',
  },
  {
    assigned: 'Someone 2',
    lastSeen: '09/22',
    status: 'Active',
  },
  {
    assigned: 'Someone 3',
    lastSeen: '01/22',
    status: 'Unactive',
  },
  {
    assigned: 'Someone 4',
    lastSeen: '02/23',
    status: 'Unactive',
  },
];
