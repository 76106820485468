import { PositionTypes } from 'admin/constants/PositionTypes';

export const EmployeesName = 'employeeJobPayrolls';
export const EmployeeCompensationName = 'employeeJobPayrollCompensations';

export const payrollSales = 'payrollSales' as const;
export const payrollForeman = 'payrollForeman' as const;
export const payrollHelper = 'payrollHelper' as const;

export const payrolls = [payrollSales, payrollForeman, payrollHelper];

export const payrollName = {
  [payrollSales]: 'Sales',
  [payrollForeman]: 'Foreman',
  [payrollHelper]: 'Helper',
} as const;

export const OWNER_DEPARTMENT_ID = 1;

export const PayrollPositionTypeNames = {
  [PositionTypes.SALES]: 'Sales',
  [PositionTypes.FOREMAN]: 'Foreman',
  [PositionTypes.HELPER]: 'Helper',
  [PositionTypes.DRIVER]: 'Driver',
  [PositionTypes.CUSTOMER_SERVICE]: 'Customer Service',
  [PositionTypes.MANAGER]: 'Manager',
  [PositionTypes.FINANCE]: 'Finance',
  [PositionTypes.DISPATCHER]: 'Dispatcher',
};

export const PayrollPositionTypesByNames = {
  Sales: [
    PositionTypes.SALES,
    PositionTypes.CUSTOMER_SERVICE,
    PositionTypes.MANAGER,
    PositionTypes.FINANCE,
    PositionTypes.DISPATCHER,
  ],
  Foreman: [PositionTypes.FOREMAN],
  Helper: [PositionTypes.HELPER, PositionTypes.DRIVER],
};

export const PayrollPositionTypesByNamesToChoose = {
  Sales: [
    PositionTypes.SALES,
    PositionTypes.CUSTOMER_SERVICE,
    PositionTypes.MANAGER,
    PositionTypes.FINANCE,
    PositionTypes.DISPATCHER,
  ],
  Foreman: [PositionTypes.FOREMAN],
  Helper: [PositionTypes.HELPER, PositionTypes.DRIVER, PositionTypes.FOREMAN],
};

export const PAYROLL_ERROR_PER_EMPLOYEE_CLASS_NAME = 'payroll-error-per-employee';
