import { storageUtil } from '@elromcoinc/react-shared';
import axios from 'axios';

const responseInterceptor: Parameters<typeof axios.interceptors.response.use>[0] = (response) => {
  if (response.headers?.authorization) {
    // this token must be used only for login on foreman page
    storageUtil.setJwt(response.headers.authorization);
  }

  // @ts-ignore
  if (!response.config || response.config.fullResponse) {
    return response;
  }

  return response.data;
};

export { responseInterceptor };
