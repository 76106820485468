import React from 'react';

import { CurrencyInput, Modal, Switch, TextInput } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { boolean, number, object } from 'yup';

import { useOrderSettingUnits } from 'admin/components/OrderWindow/context';

const enabledName = 'enabled';
const minSizeName = 'minSize';
const basePriceName = 'basePrice';

const schema = object({
  [enabledName]: boolean(),
  [minSizeName]: number()
    .nullable()
    .label('Min size')
    .transform((v) => v || 0)
    .min(0),
  [basePriceName]: number()
    .nullable()
    .label('Base price')
    .transform((v) => v || 0)
    .min(0)
    .when(enabledName, (enabled, s) => (enabled ? s.required() : s)),
});

const LongDistanceMinSize = ({ onClose, onChange, namePrefix, minSize: originMinSize, basePrice: originBasePrice }) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [minSizeName]: originMinSize,
      [basePriceName]: originBasePrice,
      [enabledName]: originBasePrice > 0,
    },
  });
  const enabled = watch(enabledName);
  const { moveUnitLabel } = useOrderSettingUnits();

  const handleSave = ({ enabled, minSize, basePrice }) => {
    const changes = { minSize: minSize > 0 ? minSize : 0, basePrice: enabled ? basePrice : 0 };
    onChange(Object.keys(changes).map((key) => ({ name: `${namePrefix}.${key}`, value: changes[key] })));
    onClose();
  };

  return (
    <Modal
      open
      title={`Minimum ${moveUnitLabel}`}
      onClose={onClose}
      actions={[
        { label: 'close', onClick: onClose },
        {
          label: 'Save',
          onClick: handleSubmit(handleSave),
          disabled: !isDirty,
        },
      ]}
      maxWidth="xs"
    >
      <Box mb={1}>
        <Grid container>
          <Grid item xs={12}>
            <Switch label="Enable Minimum Price" name={enabledName} color="primary" control={control} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              autoFocus
              name={minSizeName}
              type="number"
              inputProps={{ min: 0 }}
              label={`Min ${moveUnitLabel}`}
              control={control}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyInput name={basePriceName} label="Price for min" disabled={!enabled} control={control} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

LongDistanceMinSize.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  minSize: PropTypes.number.isRequired,
  basePrice: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LongDistanceMinSize;
