import { durationToHoursMinutes } from 'admin/utils/timeToTextFormat';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const doubleDrivingTime: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('doubleDrivingTimeOverride') === -1) {
    return null;
  }

  const oldValue = durationToHoursMinutes(oldOrder.getValueIn(fieldPath));
  const newValue = durationToHoursMinutes(newOrder.getValueIn(fieldPath));

  if (oldValue === newValue) {
    return null;
  }

  return [
    createUpdatedValueDto(
      oldValue,
      newValue,
      'Double Driving Time',
      extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
    ),
  ];
};

export default doubleDrivingTime;
