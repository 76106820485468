import React from 'react';

import { BodyText, MoveTypeName, formatCurrency } from '@elromcoinc/react-shared';
import { Box, Theme, Tooltip } from '@material-ui/core';

import { CompanyIncomeReport, formatAverageTotal, getColorForAverageTotal } from 'admin/components/Reports/IncomeTab';
import { DataTableColumnAttributes, DataTableColumnNames } from 'admin/components/Reports/config';
import { EstimateTypeName } from 'admin/constants/EstimateType';
import { ServiceTypeNames } from 'admin/constants/GeneralServiceTypes';
import { renderDate } from 'admin/utils/renderDate';

const totalLabel = 'Totals';
const isTotalIncome = (moveDate: string) => moveDate === totalLabel;

const makeIncomeTabColumns = (rowsData: CompanyIncomeReport[], { theme }: { theme: Theme }) => {
  return [
    {
      name: DataTableColumnAttributes.ORDER_NUMBER,
      label: DataTableColumnNames[DataTableColumnAttributes.ORDER_NUMBER],
      options: {
        customBodyRender: (_: CompanyIncomeReport[], { rowIndex }: { rowIndex: number }) => {
          const { orderNumber } = rowsData[rowIndex];

          return (
            <BodyText color="primary">
              <b>{orderNumber}</b>
            </BodyText>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: DataTableColumnAttributes.MOVE_TYPE,
      label: DataTableColumnNames[DataTableColumnAttributes.MOVE_TYPE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { moveType } = rowsData[rowIndex];

          return <BodyText>{MoveTypeName[moveType as keyof typeof MoveTypeName]}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.SERVICE_TYPE_NAME,
      label: DataTableColumnNames[DataTableColumnAttributes.SERVICE_TYPE_NAME],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { generalService } = rowsData[rowIndex];

          return <BodyText>{ServiceTypeNames[generalService?.serviceType as keyof typeof ServiceTypeNames]}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.ESTIMATE_TYPE,
      label: DataTableColumnNames[DataTableColumnAttributes.ESTIMATE_TYPE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { estimateType } = rowsData[rowIndex];

          return <BodyText>{EstimateTypeName[estimateType as keyof typeof EstimateTypeName]}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.COMPANY_NAME,
      label: DataTableColumnNames[DataTableColumnAttributes.COMPANY_NAME],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { companyName } = rowsData[rowIndex];

          return <BodyText>{companyName}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.LEAD_SOURCE,
      label: DataTableColumnNames[DataTableColumnAttributes.LEAD_SOURCE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { leadSource } = rowsData[rowIndex];

          return (
            <Tooltip title={leadSource!}>
              <Box maxWidth={125} style={{ cursor: 'pointer' }}>
                <BodyText noWrap>{leadSource}</BodyText>
              </Box>
            </Tooltip>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.START_DATE,
      label: DataTableColumnNames[DataTableColumnAttributes.START_DATE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { moveDate } = rowsData[rowIndex];
          const isTotal = isTotalIncome(moveDate);

          return isTotal ? (
            <BodyText align="center" className="total-column">
              {moveDate}
            </BodyText>
          ) : (
            renderDate(moveDate)
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: DataTableColumnAttributes.LINE_HAUL_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.LINE_HAUL_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { lineHaulCharge } = rowsData[rowIndex];

          return <BodyText>{lineHaulCharge.asHumanReadableString()}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.LABOR_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.LABOR_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { laborCharge } = rowsData[rowIndex];

          return <BodyText>{laborCharge.asHumanReadableString()}</BodyText>;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: DataTableColumnAttributes.FLAT_RATE_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.FLAT_RATE_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { flatRateCharge } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(flatRateCharge, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.TRAVEL_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.TRAVEL_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { travelCharge } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(travelCharge, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: DataTableColumnAttributes.FUEL_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.FUEL_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { fuelCharge } = rowsData[rowIndex];

          return <BodyText>{fuelCharge.asHumanReadableString()}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.VALUATION,
      label: DataTableColumnNames[DataTableColumnAttributes.VALUATION],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { valuation } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(valuation, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.PACKING_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.PACKING_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { packingCharge } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(packingCharge, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.ADDITIONAL_SERVICES_CHARGE,
      label: DataTableColumnNames[DataTableColumnAttributes.ADDITIONAL_SERVICES_CHARGE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { additionalServicesCharge } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(additionalServicesCharge, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
    {
      name: DataTableColumnAttributes.FEES,
      label: DataTableColumnNames[DataTableColumnAttributes.FEES],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { additionalFees } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(additionalFees, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.TAX_INFO,
      label: DataTableColumnNames[DataTableColumnAttributes.TAX_INFO],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { taxes } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(taxes, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: DataTableColumnAttributes.ESTIMATED_GRAND_TOTAL,
      label: 'ESTIMATED TOTAL',
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { grandTotal } = rowsData[rowIndex];

          return (
            <BodyText>
              <b>{grandTotal.asHumanReadableString()}</b>
            </BodyText>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: DataTableColumnAttributes.GRAND_TOTAL,
      label: 'CLOSING TOTAL',
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { closingGrandTotal } = rowsData[rowIndex];

          return (
            <BodyText>
              <b>{closingGrandTotal.asHumanReadableString()}</b>
            </BodyText>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: DataTableColumnAttributes.PAYMENT_RECEIVED,
      label: DataTableColumnNames[DataTableColumnAttributes.PAYMENT_RECEIVED],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { paymentReceived } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(paymentReceived, 2)}`}</BodyText>;
        },
        showOnMobileAsExpanded: false,
        sort: false,
      },
    },
    {
      name: DataTableColumnAttributes.BALANCE,
      label: DataTableColumnNames[DataTableColumnAttributes.BALANCE],
      options: {
        customBodyRender: (_: CompanyIncomeReport, { rowIndex }: { rowIndex: number }) => {
          const { balance } = rowsData[rowIndex];
          const { minValue, maxValue } = balance;

          return (
            <Box color={getColorForAverageTotal(minValue!, maxValue!, theme)}>
              <BodyText color="inherit">{formatAverageTotal(minValue!, maxValue!)}</BodyText>
            </Box>
          );
        },
        showOnMobileAsExpanded: false,
        sort: false,
      },
    },
  ];
};

export { makeIncomeTabColumns };
