import { FC } from 'react';

import CreateOrder from 'admin/components/CreateOrder/CreateOrder';
import { SendingTemplatesProvider } from 'admin/components/OrderWindow/context/SendingTemplatesProvider';
import { ComposeEmailProvider } from 'admin/components/OrderWindow/modals/ComposeEmail/ComposeEmailProvider';

interface CreateOrderProvidersProps {
  onClose: () => void;
  requestingAccountId?: number;
}

export const CreateOrderProviders: FC<CreateOrderProvidersProps> = (props) => {
  return (
    <ComposeEmailProvider>
      <SendingTemplatesProvider>
        <CreateOrder {...props} />
      </SendingTemplatesProvider>
    </ComposeEmailProvider>
  );
};
