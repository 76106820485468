import { Dispatch, SetStateAction, createContext } from 'react';

import { List } from 'immutable';
import { FieldValues } from 'react-hook-form';

import OrderLog from 'admin/entities/OrderLog';
import { Template } from 'common-types';

interface ActivityLogContextProps {
  reload: () => void;
  pastActions: List<OrderLog>;
  nextSteps: List<OrderLog>;
  orderLogs: List<OrderLog>;
  closingOrderLogs: List<OrderLog>;
  newNoteDefault: FieldValues | null;
  setNewNoteDefault: Dispatch<SetStateAction<FieldValues | null>>;
  newMessageDefault: FieldValues | null;
  setNewMessageDefault: Dispatch<SetStateAction<FieldValues | null>>;
  newEmailDefault: FieldValues | null;
  setNewEmailDefault: Dispatch<SetStateAction<FieldValues | null>>;
  newCallDefault: FieldValues | null;
  setNewCallDefault: Dispatch<SetStateAction<FieldValues | null>>;
  selectedTemplateDTO: Template | null;
  setSelectedTemplateDTO: Dispatch<SetStateAction<Template | null>>;
  loadMore: () => void;
  totalClosingActivities: number;
  totalActivities: number;
  uniqueActivities: OrderLog[];
  uniqueClosingActivities: OrderLog[];
}

export const ActivityLogContext = createContext<ActivityLogContextProps>({} as ActivityLogContextProps);
