export enum JobTrackerLevels {
  WHOLE_JOB = 0,
  LABOR_TIME = 1,
  LABOR_TIME_OVERLAP = 2,
  OTHER = 3,
  TRAVEL_TIME = 4,
  OVERTIME_TRAVEL_TIME = 5,
  TRAVEL_TIME_OVERLAP = 6,
  OVERTIME_TRAVEL_TIME_LESS = 7,
  OVER_ESTIMATED_LABOR_TIME = 8,
}
