import { MoveType, SettingNames } from '@elromcoinc/react-shared';

import { MoveUnitType, UnitSizeSpec } from 'common-types';

const { UNIT_SIZE_SPEC, MOVE_TYPES } = SettingNames;

const getMoveSizesWithAllTags = (settings: { [key: string]: any }) => {
  const moveSizeOptions = settings?.[UNIT_SIZE_SPEC] ?? [];

  const roomOptions = moveSizeOptions?.filter((it: UnitSizeSpec) => it.moveUnitType === MoveUnitType.ROOM);
  const tagsByMoveType: Record<MoveType, UnitSizeSpec[]> = MOVE_TYPES.reduce(
    (result, moveType) => ({
      ...result,
      [moveType]: moveSizeOptions?.filter(
        (it: UnitSizeSpec) => it.moveUnitType === MoveUnitType.SITE && it.moveType === moveType,
      ),
    }),
    {} as Record<MoveType, UnitSizeSpec[]>,
  );

  return MOVE_TYPES?.reduce(
    (result, moveType) => ({
      ...result,
      [moveType]: tagsByMoveType[moveType].reduce((acc, site) => {
        const tags = ((site.tags || '') as string)
          .split(',')
          .filter(Boolean)
          .map((tagId) => [+tagId.replace(/\D/g, ''), tagId[0] === 'd']);

        return {
          ...acc,
          [site.id]: {
            ...site,
            tags: roomOptions.map((option: UnitSizeSpec) => {
              const [, isDefault = false] = tags.find(([tagId]) => option.id === tagId) || [];

              return { ...option, isDefault };
            }),
          },
        };
      }, {} as { [key: string]: UnitSizeSpec }),
    }),
    {} as { [key: string]: { [key: string]: UnitSizeSpec } },
  );
};

export { getMoveSizesWithAllTags };
