import React, { useState } from 'react';

import { ActivitySourceType, Button, HeaderBigText } from '@elromcoinc/react-shared';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Close as CloseIcon, OpenInNew as ExpandIcon } from '@material-ui/icons';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import { THIS_FUNCTIONAL_IS_COMING_SOON } from 'admin/constants';
import { IconBlueFab } from 'common/components/Widgets';

import ActivityLogView from './ActivityLogView';
import OrderLogView from './OrderLogView';
import ViewMode from './ViewMode';

export default function LogComponent({
  pastActions,
  nextSteps,
  onExpand,
  expanded,
  onAddAction,
  startMode,
  orderLogs,
  onEdit,
  activitySource,
  disabled,
}) {
  const [mode, setMode] = useState(startMode || ViewMode.ACTIVITY_LOG);

  const clickModeHandler = (pMode) => () => {
    setMode(pMode);
  };

  const handleOnExpand = () => {
    onExpand(mode);
  };

  return (
    <Box overflow="auto" height="100%">
      <Box display="flex" width="100%" mb={0.5} justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Button
            variant="text"
            textTransform="capitalize"
            disabled={disabled}
            color={mode === ViewMode.ACTIVITY_LOG ? 'primary' : 'default'}
            onClick={clickModeHandler(ViewMode.ACTIVITY_LOG)}
          >
            <HeaderBigText>
              <b>{activitySource === ActivitySourceType.ORDER ? 'Activity Log' : 'Activity'}</b>
            </HeaderBigText>
          </Button>
          <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
            <Box>
              <Button
                disabled={activitySource === ActivitySourceType.CUSTOMER_ACCOUNT || disabled}
                variant="text"
                textTransform="capitalize"
                color={mode === ViewMode.SOURCE_LOG ? 'primary' : 'default'}
                className="print-remove"
                onClick={clickModeHandler(ViewMode.SOURCE_LOG)}
              >
                <HeaderBigText>
                  <b>{activitySource === ActivitySourceType.ORDER ? 'Order Log' : 'Account Log'}</b>
                </HeaderBigText>
              </Button>
            </Box>
          </Tooltip>
        </Box>
        <IconButton
          color="primary"
          data-testid={expanded ? 'closeActivityLogExpand' : 'openActivityLogExpand'}
          onClick={handleOnExpand}
          disabled={disabled}
          className="print-remove"
        >
          {expanded ? <CloseIcon /> : <ExpandIcon />}
        </IconButton>
      </Box>
      {mode === ViewMode.ACTIVITY_LOG && (
        <ActivityLogView
          pastActions={pastActions}
          nextSteps={nextSteps}
          expanded={expanded}
          onExpand={handleOnExpand}
          onEdit={onEdit}
        />
      )}
      {mode === ViewMode.SOURCE_LOG && (
        <OrderLogView orderLogs={orderLogs} expanded={expanded} onExpand={handleOnExpand} />
      )}
      {mode === ViewMode.ACTIVITY_LOG && (
        <Box position="absolute" bottom={0} right={0} className="print-remove">
          <IconBlueFab
            data-testid="addActivityLogButton"
            mode="add"
            disabled={disabled}
            onClick={onAddAction}
            size="small"
          />
        </Box>
      )}
    </Box>
  );
}

LogComponent.propTypes = {
  startMode: PropTypes.oneOf([ViewMode.ACTIVITY_LOG, ViewMode.ORDER_LOG]),
  onExpand: PropTypes.func.isRequired,
  onAddAction: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  orderLogs: PropTypes.instanceOf(List).isRequired,
  pastActions: PropTypes.instanceOf(List).isRequired,
  nextSteps: PropTypes.instanceOf(List).isRequired,
  activitySource: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

LogComponent.defaultProps = {
  startMode: null,
  disabled: false,
};
