import styled from 'styled-components';

export const SpacerWithoutBorder = styled.div<{ $width?: number; actionable?: boolean }>`
  width: ${(props: any) => props?.$width ?? 150}px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props: any) => (props?.actionable ?? false ? 'cursor: pointer;' : '')}
`;
