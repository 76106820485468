const maxNumberValueAdapter =
  (fn: (event: { target: { name: string; value: string } }) => void, maxValue: string) =>
  (event: { target: { name: string; value: string } }) => {
    if (+event.target.value > +maxValue) {
      event.target.value = maxValue;
    }
    fn(event);
  };

export { maxNumberValueAdapter };
