import { BodyText, Checkbox, DateTimePicker, Select } from '@elromcoinc/react-shared';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { List } from 'immutable';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { getManagerList } from 'admin/autodux/UsersAutodux';
import { CREATE_A_FOLLOW_UP, labels } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { DUE, EMPLOYEE_ID, TASK_TYPE, taskTypeOptions } from 'admin/components/OrderWindow/modals/Tasks';

const useStyles = makeStyles((theme) =>
  createStyles({
    minSelectWidth: {
      minWidth: theme.spacing(10),
      marginTop: theme.spacing(0.5),
    },
  }),
);

export const CreateFollowUpForm = () => {
  const classes = useStyles();
  const managersList = useSelector(getManagerList) as List<{ id: number; fullName: string }>;
  const employees = managersList.map((it) => [it.id, it.fullName]).toJS() as SelectOptions;

  const { watch } = useFormContext();

  const createFollowUp = watch(CREATE_A_FOLLOW_UP);

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Checkbox color="primary" label={labels[CREATE_A_FOLLOW_UP]} name={CREATE_A_FOLLOW_UP} />
      {createFollowUp && (
        <>
          <Box className={clsx(classes.minSelectWidth)} ml={-1}>
            <Select
              name={TASK_TYPE}
              options={taskTypeOptions}
              hiddenLabel
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          </Box>
          <BodyText>Assigned To</BodyText>
          <Box className={clsx(classes.minSelectWidth)}>
            <Select
              name={EMPLOYEE_ID}
              options={employees}
              hiddenLabel
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          </Box>
          <BodyText>For</BodyText>
          <Box>
            <DateTimePicker name={DUE} hiddenLabel InputProps={{ disableUnderline: true }} size="small" disablePast />
          </Box>
        </>
      )}
    </Box>
  );
};
