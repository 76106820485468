import React, { ChangeEvent, FC, useState } from 'react';

import { BodyText, Select } from '@elromcoinc/react-shared';
import { Box, Button, Tooltip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { List, Set } from 'immutable';

import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { ActivityMessage } from 'admin/components/OrderWindow/blocks/ChangeLog/ActivityMessage';
import { THIS_FUNCTIONAL_IS_COMING_SOON } from 'admin/constants';
import OrderLog from 'admin/entities/OrderLog';

const useStyles = makeStyles((theme) => ({
  listLog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div:last-child': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
    '& > div:nth-child(2)': {
      paddingTop: '8px',
    },
    '& > div + div': {
      paddingTop: 0,
    },
  },
  height: {
    height: theme.spacing(115.5),
  },
  overflowYAuto: {
    overflowY: 'auto',
  },
  listHeader: {
    minWidth: 'calc(100% - 2rem)',
    padding: '4px 16px',
    backgroundColor: theme.palette.grey[200],
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > div > div > div': {
      backgroundColor: 'transparent !important',
    },
  },
  listItem: {
    width: '100%',
    padding: '8px 0',
    marginBottom: 0,
    position: 'relative',
    '& > p': {
      marginRight: '24px',
    },
  },
  select: {
    '& .MuiSelect-root': {
      padding: '20px 10px 5px 15px!important',
    },
  },
}));

interface OrderLogsListProps {
  orderLogs: List<OrderLog>;
  inModal?: boolean;
}

export const OrderLogsList: FC<OrderLogsListProps> = ({ orderLogs, inModal }) => {
  const classes = useStyles();
  const { totalActivities, uniqueActivities, loadMore } = useActivityLogContext();
  const [type, setType] = useState<number | string>(0);
  const processedLogs = orderLogs.filter((it) => it.status === 'PROCESSED');
  const filteredLog = type ? processedLogs.filter(({ activityType }) => activityType === type) : processedLogs;
  const isEmpty = filteredLog.size === 0;
  const handleChangeType = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setType(value);
  };

  return (
    <Box className={clsx(classes.listLog, { [classes.height]: !inModal })}>
      <Box className={clsx(classes.listHeader, 'applyRadius')}>
        <Box className={classes.selectContainer}>
          <Box pl={1} width={100}>
            <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
              <Box>
                <Select
                  label="Display"
                  fullWidth
                  className={classes.select}
                  onChange={handleChangeType}
                  value={type}
                  options={Set(processedLogs.map(({ activityType }) => activityType)).reduce(
                    (acc, activityType) => [...acc, [activityType, activityType]],
                    [[0, 'All Types']] as [number | string, string][],
                  )}
                  size="small"
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {isEmpty ? (
        <Box className={classes.listItem} mb={1.5}>
          <BodyText>There are no any logs to display.</BodyText>
        </Box>
      ) : (
        <Box className={classes.overflowYAuto} width="100%">
          {filteredLog.map((item) => (
            <Box key={item.id} className={classes.listItem} mb={1.5}>
              <ActivityMessage
                triggeredBy={item.triggeredBy}
                desc={item.desc}
                dateTime={item.dateTime as unknown as Date}
                activityType={item.activityType}
              />
            </Box>
          ))}
        </Box>
      )}
      {totalActivities > 100 && uniqueActivities.length !== totalActivities && (
        <Box display="flex" justifyContent="center" width="100%">
          <Button variant="outlined" color="primary" onClick={loadMore}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};
