import { InvoiceDto, InvoicesStatusType } from '@elromcoinc/react-shared';
import axios from 'axios';

import { FlagsAssignmentsDto } from 'common-types';
import { ActivitySourceQueryParams } from 'common-types/ActivitySourceQueryParams';

class InvoiceAPI {
  getInvoices<T>(params: ActivitySourceQueryParams) {
    return axios.get<PageDTO<T>, PageDTO<T>>(`/api/moving/invoices`, { params });
  }

  create<T = any>(body: T) {
    return axios.post<T, T>('/api/moving/invoices/invoice', body);
  }

  getInvoiceById<T = any>(invoiceId: number) {
    return axios.get<T, T>(`/api/moving/invoices/id/${invoiceId}`);
  }

  createInvoices<T = InvoiceDto[]>(body: T) {
    return axios.post<T, T>('/api/moving/invoices', body);
  }

  update<T = any>(body: T) {
    return axios.put<T, T>('/api/moving/invoices/invoice', body);
  }

  delete(id: number) {
    return axios.delete(`/api/moving/invoices/${id}`);
  }

  setFlags(flagAssignments: FlagsAssignmentsDto) {
    return axios.put(`/api/moving/invoices/flags`, flagAssignments);
  }

  changeInvoicesStatus(status: InvoicesStatusType, ids: number[]) {
    return axios.put<InvoiceDto[], InvoiceDto[]>(`/api/moving/invoices/status/${status}`, ids);
  }

  downloadOrderInvoice(params: InvoiceParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/invoices/file/', {
      params: { sortBy: 'id', sortOrder: 'desc', ...params },
      responseType: 'blob',
    });
  }

  syncInvoices(ids: number[]) {
    return axios.post(`/api/moving/invoices/sync-payment-by-employee`, ids);
  }
}

export default new InvoiceAPI();
