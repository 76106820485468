export interface AddressInfo {
  street1?: string;
  street2?: string;
  postalCode?: string;
  lat?: number;
  lng?: number;
  city?: string;
  state?: string;
  country: 'USA';
  asSingleString?: string;
}

export const getFullAddress = (data: AddressInfo) => {
  return [data.street1, data.street2, data.city, data.state, data.postalCode, data.country].filter(Boolean).join(', ');
};
