import React from 'react';

import { Waypoint } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context/useOrderWindowEditAddressIndex';
import { IconBlueFab } from 'common/components/Widgets';

import StyledAddressBlock from './StyledAddressBlock';

interface EmptyAddressBlockProps {
  index: number;
}

const EmptyAddressBlock = ({ index }: EmptyAddressBlockProps) => {
  const { setEditWaypoint } = useOrderWindowEditAddressIndex();

  const handleAddNewAddress = () => {
    setEditWaypoint(
      new Waypoint({ waypointIndex: 0, originalWaypointIndex: 0, sequenceOrder: 0, serviceIndex: index }),
    );
  };

  return (
    <StyledAddressBlock display="inline-flex">
      <Box className="distanceBox" alignItems="center" alignSelf="center">
        {/*@ts-ignore*/}
        <IconBlueFab size="small" iconSize="16px" buttonSize="20px" disableBoxShadow onClick={handleAddNewAddress} />
      </Box>
    </StyledAddressBlock>
  );
};

export default EmptyAddressBlock;
