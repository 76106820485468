import { TIME_STRING_FORMAT, toDate } from '@elromcoinc/react-shared';
import { getHours, getMinutes, isSameDay } from 'date-fns';

const DEFAULT_TIME_VALUE = '00:00:00';
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * HOURS_IN_DAY;

export const convertLocalTimeToMinutes = (time?: Date | string | null, currentDate: Date | null = null) => {
  const dateTime = toDate(time || DEFAULT_TIME_VALUE, TIME_STRING_FORMAT)!;

  const timeInMinutes = getHours(dateTime) * MINUTES_IN_HOUR + getMinutes(dateTime);

  return !currentDate || isSameDay(dateTime, currentDate) ? timeInMinutes : timeInMinutes + MINUTES_IN_DAY;
};
