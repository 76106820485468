import React, { useState } from 'react';

import { Link, Order, Product } from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';
import { List } from 'immutable';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import AdditionalServicesModal from 'admin/components/OrderWindow/modals/AdditionalServices';

enum Modals {
  ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
}

export const AdditionalServices = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const { isClosing } = useOrderClosingContext();
  const name = getServicePropertyName('additionalServices');
  const quote = order.getServiceQuote(serviceIndex);
  const additionalServices = order.getIn(name.split('.')) as List<Product>;
  const handleAdditionalServicesChanges = (value: List<Product>) => {
    onChange({ name, value });
    setOpenModal(null);
  };
  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <HighlightedTableRow data-testId="additionalServices">
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.ADDITIONAL_SERVICES)}>
          Additional Services
        </Link>
        {Modals.ADDITIONAL_SERVICES === openModal && (
          <AdditionalServicesModal
            open
            onSave={handleAdditionalServicesChanges}
            onCancel={toggleOpenModal(null)}
            moveType={order.moveType}
            serviceType={quote.serviceType}
            additionalServices={additionalServices}
          />
        )}
      </TableCell>
      <TableCell>
        <Text
          value={(isClosing ? order.closingOrderDetail : order)
            ?.getAdditionalServicesTotal(serviceIndex, isSelectedAllServices)
            ?.asHumanReadableString()}
          data-testId={'additionalServicesValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
