import React, { MouseEvent, useState } from 'react';

import { ActivitySourceType, BodyBigText, BodyText, Button, Checkbox } from '@elromcoinc/react-shared';
import { Box, IconButton, Popover, Theme, Tooltip } from '@material-ui/core';
import { MoreVert as MoreVertIcon, Undo as UndoIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { setEdit } from 'admin/autodux/TaskAutodux';
import { openAccount, openOrder } from 'admin/autodux/WindowsAutodux';
import ConfirmationDialog from 'admin/components/modal/ConfirmationDialog';
import { Task, TaskTypeName } from 'common-types/Task';

import { COMPLETED_VARIANT, DisplayTaskVariant } from './DisplayTaskVariant';
import TaskPriority from './TaskPriority';
import TaskReminderIcon from './TaskReminderIcon';

const getBackgroundColorByVariant = ({ $variant, theme }: { $variant: DisplayTaskVariant; theme: Theme }) => {
  if ($variant === COMPLETED_VARIANT) {
    return theme.palette.background.paper;
  }

  return theme.palette.grey[100];
};

const getColorByVariant = ({ theme }: { theme: Theme }) => theme.palette.text.primary;
const getButtonColorByVariant = ({ theme }: { theme: Theme }) => theme.palette.primary.main;

const StyledBodyBigText = styled(BodyBigText)`
  line-height: 1.1rem;
`;

const TaskBox = styled(Box)<{ $variant: DisplayTaskVariant }>`
  background-color: ${getBackgroundColorByVariant};
  color: ${getColorByVariant};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(1)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: ${({ theme }) => theme.typography.fontSize};
  margin-bottom: ${({ theme }) => theme.spacing(1.2)}px;
`;

const CloseIconButton = styled(IconButton)<{ $variant: DisplayTaskVariant }>`
  padding: 5px;
  color: ${getButtonColorByVariant};
  margin-right: -7px;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.light};
  }
  & .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
`;

const ThreeDotsStyled = styled(IconButton)`
  padding: 5px;
`;

const ReadCheckboxStyled = styled(Checkbox)<{ $variant: DisplayTaskVariant }>`
  padding: 5px;
  color: ${getButtonColorByVariant};
`;

const LongTextStyled = styled(Box)`
  max-width: 315px;
  white-space: pre-wrap;
`;

interface TaskDrawerItemProps {
  task: Task;
  toggleCompleteStatus: (task: Task) => void;
  disabled: boolean;
  variant: DisplayTaskVariant;
  onDelete: (task: Task) => void;
}
const TaskDrawerItem = ({ task, toggleCompleteStatus, disabled, variant, onDelete }: TaskDrawerItemProps) => {
  const dispatch = useDispatch();
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { sourceId, orderNumber, activitySource, customerName, subject, notes, isCompleted, dueTime } = task;

  const handleClickOrderNumber = () => {
    if (activitySource === ActivitySourceType.ORDER && sourceId) {
      dispatch(openOrder(sourceId));
    }

    if (activitySource === ActivitySourceType.CUSTOMER_ACCOUNT && sourceId) {
      dispatch(openAccount(sourceId));
    }
  };
  const toggleComplete = () => toggleCompleteStatus(task);
  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleDelete = () => {
    onDelete(task);
  };
  const toggleOpenRemoveDialog = (value: boolean) => () => {
    setRemoveModalOpen(value);

    if (value) {
      handleClose();
    }
  };
  const handleEditTask = () => {
    dispatch(setEdit(task));
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'task-actions-popover' : undefined;

  return (
    <TaskBox position="relative" $variant={variant}>
      {removeModalOpen && (
        <ConfirmationDialog
          title="Remove task"
          actions={
            <>
              <Button onClick={handleDelete} color="primary">
                Yes
              </Button>
              <Button onClick={toggleOpenRemoveDialog(false)} color="secondary">
                No
              </Button>
            </>
          }
        >
          <BodyText>You are about to delete this task. Are you sure you will like to continue?</BodyText>
        </ConfirmationDialog>
      )}
      <Box display="flex" justifyContent="space-between" mt={0.5} position="relative">
        <Box>
          {activitySource === ActivitySourceType.ORDER && (
            <Box ml={-1} mb={-1} mt={-0.625} display="flex">
              <Button color="primary" variant="text" onClick={handleClickOrderNumber}>
                {`#${orderNumber}`}
              </Button>
              <Box display="flex" flexDirection="column" justifyContent="center">
                {customerName}
              </Box>
            </Box>
          )}
          {activitySource === ActivitySourceType.CUSTOMER_ACCOUNT && (
            <Box ml={-1} mb={-1} mt={-0.625} display="flex">
              <Button color="primary" variant="text" onClick={handleClickOrderNumber}>
                {`#${sourceId}`}
              </Button>
              <Box display="flex" flexDirection="column" justifyContent="center">
                {customerName}
              </Box>
            </Box>
          )}
        </Box>
        <Box position="absolute" right={isCompleted ? 0 : -4} top={-10}>
          {isCompleted ? (
            <Tooltip title="Mark as incomplete" placement="top">
              <Box component="span">
                <CloseIconButton
                  $variant={variant}
                  aria-label="Undo"
                  size="small"
                  onClick={toggleComplete}
                  disabled={disabled}
                >
                  <UndoIcon fontSize="inherit" />
                </CloseIconButton>
              </Box>
            </Tooltip>
          ) : (
            <Tooltip title="Mark as complete" placement="top">
              <Box component="span">
                <ReadCheckboxStyled
                  $variant={variant}
                  variant="circle-outlined"
                  color="default"
                  checked={isCompleted}
                  onChange={toggleComplete}
                  disabled={disabled}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
      <LongTextStyled>
        <StyledBodyBigText wordBreak>
          <b>{`Subject: ${subject}`}</b>
        </StyledBodyBigText>
      </LongTextStyled>
      {notes && (
        <LongTextStyled>
          <BodyText wordBreak>{`Notes: ${notes}`}</BodyText>
        </LongTextStyled>
      )}
      <Box display="flex" alignItems="center">
        {`Due Date: ${format(dueTime!, 'Pp')}`}
        <TaskReminderIcon variant={variant} task={task} />
      </Box>
      <Box display="flex" alignItems="center">
        <Box mr={0.5}>{`Type: ${TaskTypeName[task.taskType]}`}</Box>
        <TaskPriority priority={task.priority} />
      </Box>
      <Box position="absolute" right={4} bottom={8}>
        <ThreeDotsStyled color="primary" aria-describedby={id} onClick={handleClick}>
          <MoreVertIcon />
        </ThreeDotsStyled>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Button variant="text" onClick={handleEditTask} disabled={disabled}>
            Edit
          </Button>
          <Button variant="text" onClick={toggleOpenRemoveDialog(true)} disabled={disabled}>
            Delete
          </Button>
        </Box>
      </Popover>
    </TaskBox>
  );
};

export default TaskDrawerItem;
