import React from 'react';

import { SvgIcon } from '@material-ui/core';

import { IconProps } from './IconProps';

const CompanyLogo = (props: IconProps) => (
  <SvgIcon viewBox="0 0 19 22" {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="23px"
      height="23px"
      viewBox="0 0 590.000000 539.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,539.000000) scale(0.100000,-0.100000)" fill={props.color} stroke="none">
        <path d="M4495 4920 c-153 -24 -294 -97 -405 -210 -89 -90 -132 -159 -173 -278 -27 -78 -30 -100 -31 -207 0 -110 3 -127 31 -200 53 -139 134 -294 224 -427 22 -31 39 -62 39 -67 0 -8 -98 -11 -305 -11 l-305 0 0 214 0 214 29 5 c45 9 99 63 112 110 6 23 9 79 7 125 -5 88 -17 116 -72 156 -27 21 -31 21 -1408 24 l-1380 2 -36 -21 c-63 -35 -77 -63 -81 -162 -1 -48 0 -104 3 -123 9 -45 65 -101 111 -110 l35 -6 -2 -1772 -3 -1771 -47 -3 c-37 -2 -52 -9 -68 -29 -25 -33 -25 -54 1 -87 l20 -26 2289 0 2289 0 21 27 c25 32 20 69 -12 96 -17 15 -42 17 -179 17 l-159 0 0 1350 c0 1278 1 1351 18 1356 51 16 83 37 109 70 25 33 28 47 31 124 3 73 1 94 -17 128 -27 53 -84 92 -133 92 -21 0 -38 4 -38 8 0 5 13 27 28 48 80 113 183 301 232 424 143 361 -94 800 -487 901 -92 23 -208 31 -288 19z m234 -155 c146 -35 300 -152 369 -282 37 -71 72 -196 72 -261 0 -152 -143 -430 -390 -757 -146 -193 -161 -209 -191 -202 -48 14 -354 437 -457 636 -72 137 -102 233 -102 323 0 151 52 271 170 389 142 144 335 199 529 154z m-1309 -2590 l0 -1775 -280 0 -280 0 0 508 c0 571 2 552 -77 624 -65 57 -90 60 -588 56 l-440 -3 -48 -28 c-26 -15 -60 -47 -75 -69 l-27 -42 -3 -523 -3 -523 -284 0 -285 0 0 1775 0 1775 1195 0 1195 0 0 -1775z m1478 -422 l-3 -1348 -662 -3 -663 -2 0 1350 0 1350 665 0 665 0 -2 -1347z" />
        <path d="M4467 4576 c-147 -61 -232 -188 -230 -346 2 -132 80 -255 200 -315 53 -27 67 -30 158 -30 88 0 107 3 157 27 246 115 279 450 60 618 -94 72 -235 91 -345 46z" />
        <path d="M1283 3781 c-68 -31 -77 -59 -81 -247 -4 -182 4 -227 48 -269 l31 -30 197 -3 c182 -2 199 -1 229 17 60 37 65 60 61 276 -3 190 -3 194 -28 221 -44 47 -75 54 -252 54 -138 0 -169 -3 -205 -19z" />
        <path d="M2025 3786 c-17 -7 -43 -28 -58 -45 l-27 -33 0 -192 c0 -184 1 -195 23 -227 38 -57 51 -60 270 -57 l199 3 34 37 34 38 0 195 c0 226 -5 245 -73 276 -36 16 -66 19 -207 18 -115 0 -174 -4 -195 -13z" />
        <path d="M2755 3786 c-16 -7 -41 -26 -55 -41 -25 -26 -25 -30 -28 -215 -5 -296 -1 -300 276 -300 215 0 239 6 272 74 18 38 20 62 20 212 0 186 -8 218 -63 258 -26 19 -44 21 -210 24 -138 1 -189 -1 -212 -12z" />
        <path d="M1273 3030 c-64 -39 -68 -53 -68 -265 l0 -192 28 -36 c37 -49 72 -57 261 -57 139 0 165 3 201 20 70 34 75 51 75 267 0 169 -2 191 -20 220 -35 57 -58 63 -262 63 -164 0 -186 -2 -215 -20z" />
        <path d="M2003 3030 c-58 -35 -63 -58 -63 -267 0 -167 2 -191 19 -219 34 -56 82 -66 282 -62 259 5 259 4 259 291 0 182 -1 196 -21 221 -40 51 -63 56 -261 56 -164 0 -186 -2 -215 -20z" />
        <path d="M2732 3030 c-57 -35 -64 -68 -60 -280 l3 -189 30 -31 c43 -44 86 -52 276 -48 151 3 170 5 196 24 55 40 63 72 63 257 0 182 -6 213 -54 257 -27 25 -30 25 -224 28 -184 2 -199 1 -230 -18z" />
        <path d="M1283 2291 c-68 -31 -77 -59 -81 -247 -4 -182 4 -227 48 -269 l31 -30 197 -3 c182 -2 199 -1 229 17 60 37 65 60 61 276 -3 190 -3 194 -28 221 -44 47 -75 54 -252 54 -138 0 -169 -3 -205 -19z" />
        <path d="M2015 2290 c-70 -35 -75 -52 -75 -267 0 -169 2 -191 20 -220 36 -60 59 -65 276 -61 l196 3 34 37 34 38 0 195 c0 226 -5 245 -73 276 -36 16 -67 19 -207 19 -145 0 -170 -2 -205 -20z" />
        <path d="M2755 2296 c-16 -7 -41 -26 -55 -41 -25 -26 -25 -30 -28 -215 -4 -212 3 -245 60 -280 31 -19 46 -20 230 -18 l197 3 31 30 c45 43 52 80 48 272 -3 155 -5 174 -24 200 -41 55 -72 63 -259 63 -119 -1 -179 -5 -200 -14z" />
        <path d="M3781 2916 c-44 -24 -50 -50 -51 -213 l0 -153 29 -32 29 -33 145 -3 c237 -6 251 8 245 246 l-3 144 -33 29 -32 29 -153 0 c-107 -1 -159 -5 -176 -14z" />
        <path d="M4341 2916 c-44 -24 -50 -50 -51 -213 l0 -153 29 -32 29 -33 144 -3 c238 -6 252 8 246 246 l-3 144 -33 29 -32 29 -153 0 c-107 -1 -159 -5 -176 -14z" />
        <path d="M3783 2290 c-17 -10 -37 -28 -42 -39 -15 -28 -14 -303 1 -336 21 -47 53 -55 217 -55 l151 0 32 29 33 29 3 144 c6 234 -7 248 -230 248 -114 0 -138 -3 -165 -20z" />
        <path d="M4354 2300 c-12 -4 -31 -21 -43 -36 -19 -25 -21 -39 -21 -175 0 -226 3 -229 229 -229 l151 0 32 29 33 29 3 144 c6 234 -7 248 -230 247 -73 0 -143 -4 -154 -9z" />
        <path d="M3759 1651 l-29 -29 0 -162 0 -162 29 -29 29 -29 165 0 c159 0 165 1 190 24 34 31 40 69 36 226 l-4 132 -33 29 -32 29 -161 0 -161 0 -29 -29z" />
        <path d="M4323 1656 l-28 -24 -3 -166 -3 -166 30 -30 29 -30 165 0 c164 0 164 0 190 26 35 34 39 65 35 223 l-3 133 -33 29 -32 29 -160 0 c-154 0 -160 -1 -187 -24z" />
        <path d="M3765 1027 l-35 -35 0 -156 0 -156 29 -32 29 -33 154 -3 c172 -3 192 2 221 58 15 30 18 56 15 182 -3 146 -3 148 -31 175 -27 28 -28 28 -188 31 l-160 3 -34 -34z" />
        <path d="M4338 1043 c-42 -26 -48 -53 -48 -213 l0 -150 29 -32 29 -33 154 -3 c172 -3 193 3 220 58 14 28 18 61 18 165 0 104 -4 137 -18 165 -26 53 -49 60 -212 60 -118 -1 -150 -4 -172 -17z" />
      </g>
    </svg>
  </SvgIcon>
);

export { CompanyLogo };
