import { emailYUP, phoneNumberYUP } from '@elromcoinc/react-shared';
import { Record } from 'immutable';
import { lazy, object, string } from 'yup';

import { EMAIL, FIRST_NAME, LAST_NAME, PHONE, SEND_NOTIFICATIONS } from 'admin/constants/FieldNames';

/** @deprecated please use it from react-shared */
export default class AdditionalContact extends Record({
  [FIRST_NAME]: null,
  [LAST_NAME]: null,
  [EMAIL]: null,
  [PHONE]: null,
  [SEND_NOTIFICATIONS]: false,
}) {
  static createAdditionalContact(info) {
    return new AdditionalContact(info || {});
  }

  static getValidationSchema(labels) {
    return lazy((contact) => {
      const hasData = Object.values(contact || {}).filter(Boolean).length > 0;
      const nameSchema = string()
        .transform((value) => (!value ? null : value))
        .nullable()
        .min(2)
        .max(32);
      const emailSchema = emailYUP({ name: EMAIL, label: labels[EMAIL] });
      const phoneSchema = phoneNumberYUP(labels[PHONE]);
      return object().shape({
        [FIRST_NAME]: (hasData ? nameSchema.required() : nameSchema).label(labels[FIRST_NAME]),
        [LAST_NAME]: (hasData ? nameSchema.required() : nameSchema).label(labels[LAST_NAME]),
        [EMAIL]: hasData ? emailSchema : emailSchema.notRequired(),
        [PHONE]: hasData ? phoneSchema.required() : phoneSchema,
      });
    });
  }
}
