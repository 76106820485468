import { useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { fetchSettingsByQueries } from 'admin/autodux/SettingsAutodux';

const useSettings = function (...nodeNames) {
  const dispatch = useDispatch();
  const settings = useSelector((state) => {
    const settingsData = state.settings.data;
    return Object.keys(settingsData).reduce(
      (acc, name) =>
        nodeNames.find((settingName) => name.includes(settingName)) ? { ...acc, [name]: settingsData[name] } : acc,
      {},
    );
  }, shallowEqual);

  useEffect(() => {
    fetchSettingsByQueries({ queries: nodeNames })(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return settings;
};

export default useSettings;
