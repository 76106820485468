import React, { FC, useEffect } from 'react';

import { ActivitySourceType, IconButton, useAlert } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { array, object, string } from 'yup';

import { useSendingTemplates } from 'admin/components/OrderWindow/context';
import { CommunicationButton } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/CommunicationButton';
import { EditTemplatesDialog } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/EditTemplatesDialog';
import { CommunicationModality, Template } from 'common-types';

const scheme = object({
  templates: array(
    object({
      subject: string().when(['modality'], (modality, s) =>
        modality === CommunicationModality.EMAIL ? s.required() : s,
      ),
      body: string().required(),
      modality: string().oneOf(Object.keys(CommunicationModality)),
    }),
  ),
});

interface ManageTemplatesProps {
  sourceId?: number;
  defaultTemplates: Template[];
}

export const ManageTemplates: FC<ManageTemplatesProps> = ({ sourceId, defaultTemplates }) => {
  const { sendingTemplates, setSendingTemplates } = useSendingTemplates();
  const methods = useForm({
    resolver: yupResolver(scheme),
  });
  const { remove } = useFieldArray({
    name: 'templates',
    control: methods.control,
  });

  useEffect(() => {
    methods.reset({
      templates: defaultTemplates.map((t) => t.toJS()),
    });
  }, [defaultTemplates]);

  const { alertProps: editTemplatesModalProps, setShowAlert } = useAlert({
    cancelTitle: 'Cancel',
    confirmTitle: 'Save',
    handleSubmit: methods.handleSubmit,
    onConfirm: (onClose, data) => {
      onClose();
      setSendingTemplates(data.templates.map((t: Template) => new Template(t)));
    },
    onCancel: () => {
      methods.reset({
        templates: sendingTemplates.map((t) => t.toJS()),
      });
    },
  });

  const handleDeleteTemplate = (index: number) => () => {
    setSendingTemplates((selectedTemplates) => selectedTemplates.filter((_, templateIndex) => templateIndex !== index));
    remove(index);
  };

  const editTemplates = () => {
    setShowAlert(true);
  };

  return (
    <>
      <Box display="flex" flexWrap="wrap">
        {sendingTemplates.map((template, index) => (
          <CommunicationButton key={template.name} template={template} onClick={handleDeleteTemplate(index)} />
        ))}
        <Tooltip title="Add or change templates">
          <Box>
            <IconButton color="primary" size="medium" onClick={editTemplates}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      {/** @ts-ignore */}
      <FormProvider {...methods}>
        {editTemplatesModalProps.open && (
          <EditTemplatesDialog
            modalProps={editTemplatesModalProps}
            sourceId={sourceId}
            activitySource={ActivitySourceType.ORDER}
          />
        )}
      </FormProvider>
    </>
  );
};
