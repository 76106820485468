import { ActivitySourceDescriptor, ActivitySourceType, PaymentAdjustmentDTO } from '@elromcoinc/react-shared';
import axios from 'axios';

class PaymentAdjustmentApi {
  createPaymentAdjustment(paymentAdjustment: PaymentAdjustmentDTO) {
    return axios.post<PaymentAdjustmentDTO, PaymentAdjustmentDTO>('/api/moving/paymentAdjustment/', paymentAdjustment);
  }

  updatePaymentAdjustment(paymentAdjustment: PaymentAdjustmentDTO) {
    return (
      paymentAdjustment.activitySources?.some(
        (as: ActivitySourceDescriptor) =>
          as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING ||
          as.activitySource === ActivitySourceType.SERVICE_BILL_OF_LADING,
      )
        ? this.splitClosingAndBOL(paymentAdjustment.id!)
        : Promise.resolve(paymentAdjustment)
    ).then((pa) => {
      pa.total = paymentAdjustment.total;
      pa.rate = paymentAdjustment.rate;
      pa.quote = paymentAdjustment.quote;
      return axios.put<PaymentAdjustmentDTO, PaymentAdjustmentDTO>(
        `/api/moving/paymentAdjustment/${paymentAdjustment.id}`,
        paymentAdjustment,
      );
    });
  }

  delete(paymentAdjustment: PaymentAdjustmentDTO) {
    return (
      paymentAdjustment.activitySources?.some(
        (as: ActivitySourceDescriptor) =>
          as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING ||
          as.activitySource === ActivitySourceType.SERVICE_BILL_OF_LADING,
      )
        ? this.splitClosingAndBOL(paymentAdjustment.id!)
        : Promise.resolve(paymentAdjustment)
    ).then((pa) => {
      return axios.delete(`/api/moving/paymentAdjustment/${pa.id}`);
    });
  }

  splitClosingAndBOL(id: number) {
    return axios.put<PaymentAdjustmentDTO, PaymentAdjustmentDTO>(
      `/api/moving/paymentAdjustment/${id}/split-closing-and-bol`,
      {},
    );
  }
}

export default new PaymentAdjustmentApi();
