import { useEffect } from 'react';

function internalHandler(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.returnValue = '';
  return 'Changes you made may not be saved.';
}

const usePreventPageClose = (shouldPrevent: boolean) => {
  useEffect(() => {
    if (!shouldPrevent) {
      return;
    }
    window.onbeforeunload = internalHandler;
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldPrevent]);
};

export { usePreventPageClose };
