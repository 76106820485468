import React from 'react';

import { Button, DataTable, ElromcoCircularProgress } from '@elromcoinc/react-shared';
import { Box, Paper } from '@material-ui/core';
import pt from 'prop-types';
import styled from 'styled-components';

import DashboardCardTitle from 'admin/components/Dashboard/DashboardCardTitle';

const TableContainer = styled(Box)`
  height: 490px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  & :first-child {
    max-height: 455px;
  }
  .MuiToolbar-root {
    display: none;
  }
  .MuiPaper-root > :nth-child(2) {
    top: 0;
  }
  .MuiTableRow-head .MuiTableCell-root {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    background: #fafafb;
    padding: 0.4rem 1rem;
  }
  .MuiTableCell-root {
    font-size: 14px;
    line-height: 14px;
  }
  .MuiPaper-root {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  .Mui-selected .white-color-when-select {
    color: white;
  }
  .MuiTableCell-root .MuiButton-root {
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const ViewAllStyled = styled(Box)`
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const DashboardTable = ({ title, data, columns, options, onViewAllClick, children }) => {
  const commonOptions = {
    ...options,
    serverSide: true,
    alternateTableRowColors: true,
    selectableRowsHideCheckboxesCustom: true,
    pagination: false,
    rowsPerPage: 8,
    inFlight: false,
    isRounded: true,
  };

  return (
    <>
      <DashboardCardTitle title={title} />
      <TableContainer>
        {options.inFlight && <ElromcoCircularProgress />}
        {children}
        <DataTable data={data} options={commonOptions} columns={columns} />
        <ViewAllStyled>
          <Paper>
            <Button variant="text" color="primary" textTransform="initial" onClick={onViewAllClick}>
              View all
            </Button>
          </Paper>
        </ViewAllStyled>
      </TableContainer>
    </>
  );
};

DashboardTable.propTypes = {
  title: pt.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: pt.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columns: pt.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  options: pt.object.isRequired,
  children: pt.node,
  onViewAllClick: pt.func,
};

DashboardTable.defaultProps = {
  onViewAllClick: null,
  children: null,
};

export default DashboardTable;
