import { Order, isOvernightService, toDate } from '@elromcoinc/react-shared';
import { differenceInDays } from 'date-fns';
import { getIn } from 'immutable';

export const checkMaxOvernightDaysWarning = (order: Order, maxOvernightDays: number) => {
  const isMultiDayOvernight = order.services.size > 1 && isOvernightService(order.services.get(0).getType());
  const firstServiceDate = toDate(getIn(order, ['services', 0, 'date'], null) as Date);
  const secondServiceDate = toDate(getIn(order, ['services', 1, 'date'], null) as Date);
  const differenceBetweenServiceDays =
    secondServiceDate && firstServiceDate ? differenceInDays(secondServiceDate, firstServiceDate) : 0;

  return isMultiDayOvernight && differenceBetweenServiceDays > maxOvernightDays
    ? `Maximum days for overnight storage is limited to ${maxOvernightDays} days`
    : null;
};
