export enum EmployeeCommissionType {
  HOURLY_RATE = 'HOURLY_RATE',
  HOURLY_RATE_AS_HELPER = 'HOURLY_RATE_AS_HELPER',
  DAILY_RATE = 'DAILY_RATE',
  PACKING_COMMISSION = 'PACKING_COMMISSION',
  ADDITIONAL_SERVICES_COMMISSION = 'ADDITIONAL_SERVICES_COMMISSION',
  COMMISSION_FROM_THE_TOTAL = 'COMMISSION_FROM_THE_TOTAL',
  PER_DAY_BONUS = 'PER_DAY_BONUS',
  PER_JOB_BONUS = 'PER_JOB_BONUS',
}
