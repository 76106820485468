import React from 'react';
import { format, parseISO } from 'date-fns';
import { BodySmallText } from '@elromcoinc/react-shared';
import { CoiSentsInfo } from 'admin/components/OrderWindow/modals/FullAddressesModal';

export const getCoiMessage = (label: string, coi: CoiSentsInfo) => {
  const date = parseISO(coi.whenSent!);
  return (
    <>
      <BodySmallText>
        &#40;<b>{label}</b>
        {`: ${coi.employeeName || ''} ${format(date, "PP' at 'p")})`}
      </BodySmallText>
    </>
  );
};
