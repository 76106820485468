import { formatCurrency } from '@elromcoinc/react-shared';

import { EventProcessor } from 'admin/components/OrderWindow/audit/EventProcessor';
import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';

import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const ratePerDistance: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('ratePerDistance') === -1) {
    return null;
  }

  const oldValue = oldOrder.getValueIn(fieldPath);
  const newValue = newOrder.getValueIn(fieldPath);

  if (oldValue === newValue) {
    return null;
  }

  return [
    createUpdatedValueDto(
      `$${formatCurrency(+oldValue)}`,
      `$${formatCurrency(+newValue)}`,
      'Rate Per Distance',
      extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
    ),
  ];
};

export default ratePerDistance;
