const regex = new RegExp('services\\.(\\d+)\\..+', 'i');

export const extractServiceIndexUtil = (fieldPath: string | null | undefined, isMultiday = false): number | null => {
  if (!fieldPath || !isMultiday) {
    return null;
  }

  const result = regex.exec(fieldPath);
  if (result && result.length > 1) {
    return +result[1] + 1;
  }
  return null;
};
