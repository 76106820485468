import { formatCurrency } from '@elromcoinc/react-shared';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

interface Adjustment {
  type: 'ABSOLUTE' | 'PERCENT';
  amount: number;
}

const toValue = (adjustment: Adjustment) => {
  if (!adjustment?.amount) {
    return '';
  }
  return adjustment.type === 'PERCENT' ? `${adjustment.amount}%` : `$${formatCurrency(adjustment.amount)}`;
};

const fuelCharge: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('fuelChargeEmployeeAdjusted') === -1) {
    return null;
  }

  const oldValue = toValue(oldOrder.getValueIn(fieldPath) as Adjustment);
  const newValue = toValue(newOrder.getValueIn(fieldPath) as Adjustment);

  if (oldValue === newValue) {
    return null;
  }

  return [
    createUpdatedValueDto(
      oldValue,
      newValue,
      'Fuel Surcharge',
      extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
    ),
  ];
};

export default fuelCharge;
