import React, { useState } from 'react';

import {
  BodyText,
  Link,
  Order,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { AdditionalFeeCharge } from 'admin/components/OrderWindow/modals/AdditionalFeeCharge';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    inputWrapper: {
      maxWidth: '85px',
    },
    input: {
      textAlign: 'right',
    },
  }),
);

const paymentAdjustmentTypes = [PaymentAdjustmentType.CUSTOM_FEE];

export const CustomFees = () => {
  const classes = useStyles();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const { order } = useOrderState() as { order: Order };
  const paymentAdjustmentDtos: List<PaymentAdjustmentDTO> =
    order?.closingOrderDetail?.serviceRosterClosingsDto?.get(serviceIndex)?.paymentAdjustmentDtos ??
    List<PaymentAdjustmentDTO>();
  const [showModal, setShowModal] = useState(false);

  const customFees = paymentAdjustmentDtos.filter(
    (pad) => pad.paymentAdjustmentType === PaymentAdjustmentType.CUSTOM_FEE,
  );

  if (!isClosing || paymentAdjustmentDtos.isEmpty()) {
    return <></>;
  }

  const toggleShowCustomFees =
    (value = true) =>
    () =>
      setShowModal(value);

  return (
    <>
      {customFees.map((cf, index) => (
        <Grid item xs={12}>
          <Box className={classes.costBreakdownItem}>
            {index === 0 ? (
              <Link disabled={isSelectedAllServices} onClick={toggleShowCustomFees()}>
                <BodyText>{cf.name}</BodyText>
              </Link>
            ) : (
              <BodyText>{cf.name}</BodyText>
            )}
            <Text value={`$${formatCurrency(cf.total ?? 0)}`} data-testId={`customFeesValue.${index}`} />
          </Box>
        </Grid>
      ))}
      {showModal && (
        <AdditionalFeeCharge
          mainType={PaymentAdjustmentType.CUSTOM_FEE}
          onClose={toggleShowCustomFees(false)}
          types={paymentAdjustmentTypes}
        />
      )}
    </>
  );
};
