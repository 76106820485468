import React, { useEffect, useState } from 'react';

import { BodyText, CurrencyInput, Modal, Order } from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { useOrderChangeSet, useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(({ spacing }) => ({
  withoutBorder: {
    borderBottom: 'none',
  },
  smallInput: {
    '& input': {
      padding: `${spacing(0, 0.5)}!important`,
      height: spacing(3),
    },
  },
}));

interface AdditionalChargeProps {
  onClose: () => void;
  nameToDisplay: string;
  pickupName: string;
  deliveryName: string;
}

export const AdditionalCharge = ({ onClose, pickupName, nameToDisplay, deliveryName }: AdditionalChargeProps) => {
  const classes = useStyles();
  const { isCompleted } = useOrderClosingContext();

  const { onChange } = useOrderChangeSet();
  const { order } = useOrderState() as { order: Order };
  const pickupChargeValue = (order.getIn(pickupName.split('.')) as number) ?? 0;
  const [pickupChargeManual, setPickupChargeManual] = useState<string | number>(pickupChargeValue);

  useEffect(() => {
    setPickupChargeManual(pickupChargeValue);
  }, [pickupChargeValue]);

  const deliveryChargeValue = (order.getIn(deliveryName.split('.')) as number) ?? 0;
  const [deliveryChargeManual, setDeliveryChargeManual] = useState<string | number>(deliveryChargeValue);

  useEffect(() => {
    setDeliveryChargeManual(deliveryChargeValue);
  }, [deliveryChargeValue]);

  const handleOnBlur = (value: number, name: string) => () => {
    onChange({ name, value });
  };

  const onChangeTips = (grandTotal: string) => setPickupChargeManual(grandTotal);
  const onDeliveryChangeTips = (grandTotal: string) => setDeliveryChargeManual(grandTotal);

  const disabled = isCompleted;

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
    },
  ];

  return (
    <Modal title={`Manage ${nameToDisplay}`} open actions={actions} onClose={onClose}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" alignItems="center">
                <Box width={180}>
                  <BodyText>{`Pickup ${nameToDisplay}:`}</BodyText>
                </Box>
                <Box width={180}>
                  <CurrencyInput
                    className={classes.smallInput}
                    disabled={disabled}
                    size="small"
                    hiddenLabel
                    value={pickupChargeManual}
                    onChange={onChangeTips}
                    onBlur={handleOnBlur(+pickupChargeManual, pickupName)}
                    name={pickupName}
                    data-testId={pickupName}
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" alignItems="center">
                <Box width={180}>
                  <BodyText>{`Delivery ${nameToDisplay}:`}</BodyText>
                </Box>
                <Box width={180}>
                  <CurrencyInput
                    className={classes.smallInput}
                    disabled={disabled}
                    size="small"
                    hiddenLabel
                    value={deliveryChargeManual}
                    onChange={onDeliveryChangeTips}
                    onBlur={handleOnBlur(+deliveryChargeManual, deliveryName)}
                    name={deliveryName}
                    data-testId={deliveryName}
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  );
};
