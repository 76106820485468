import { TableRow } from '@material-ui/core';
import styled from 'styled-components';

// @deprecated need to refactor and remove styled component
export const HighlightedTableRow = styled(TableRow)<{ highlight?: boolean }>`
  && {
    td {
      height: 22px;
      background-color: ${(props) => {
        return props.highlight && props.theme.palette.grey[100];
      }};
    }
    td:first-child {
      width: 165px !important;
    }
  }
`;
