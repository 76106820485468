import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import RoomIcon from '@material-ui/icons/Room';
import { sortableHandle } from 'react-sortable-hoc';

const DragHandle = sortableHandle(({ index, disabled, disabledDraggable, icon, showIcon = true }) => {
  const IconToRender = icon || RoomIcon;
  return (
    <Box className="draggerBox">
      {showIcon && <IconToRender color="primary" />}
      {!disabled ? <BodyText align="center">{String.fromCharCode(65 + index)}</BodyText> : <div />}
      {!disabledDraggable ? <DragIndicatorIcon /> : <div />}
    </Box>
  );
});

export default DragHandle;
