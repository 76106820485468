import { useEffect, useState } from 'react';

export class GlobalVar {
  private static globalObj: { [key: string]: any } = {
    isSessionExpired: false,
  };

  private static callBacks: Function[] = [];

  public static subscribe(callback: Function): void {
    this.callBacks.push(callback);
  }

  public static unsubscribe(callback: Function): void {
    this.callBacks = this.callBacks.filter((cb) => cb !== callback);
  }

  public static setValue(key: string, value: any): void {
    this.globalObj[key] = value;
    this.callBacks.forEach((cb) => cb());
  }

  public static getValue(key: string): any {
    return this.globalObj[key];
  }
}

export const useGetGlobalIsSessionExpired = () => {
  const [, setValue] = useState(0);

  useEffect(() => {
    const func = () => {
      setValue((prev) => prev + 1);
    };

    GlobalVar.subscribe(func);

    return () => {
      GlobalVar.unsubscribe(func);
    };
  }, []);

  return GlobalVar.getValue('isSessionExpired');
};
