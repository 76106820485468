import { GeneralService, SettingNames, toDate, toDateString } from '@elromcoinc/react-shared';
import { Map, Set } from 'immutable';

import { EventProcessor } from './EventProcessor';
import { UpdatedValueDto } from './UpdatedValueDto';

const getServiceName = (settings: Map<string, any>, serviceId: number) =>
  (Map(settings || {}).get(SettingNames.GENERAL_SERVICES, []) as GeneralService[])?.filter(
    (service) => service.id === serviceId,
  )[0]?.name || null;

const addAdditionalDay: (settings: Map<string, any>) => EventProcessor =
  (settings: Map<string, any>) => (fieldPath, oldOrder, newOrder, changeSet?: Map<string, any>) => {
    if (changeSet && changeSet.has('add_service') && changeSet.get('add_service').moveDate) {
      const serviceName = getServiceName(settings, changeSet.get('add_service').serviceId);
      const moveDate = toDateString(changeSet.get('add_service').moveDate, 'P');
      return [{ oldValue: '', newValue: `${moveDate}`, propertyName: serviceName }];
    }
    if (oldOrder.services.size < newOrder.services.size) {
      const oldServiceKeys = oldOrder.services.reduce(
        (sum, service) => sum.add(`${service.date}-${service.serviceTypeName}`),
        Set<string>(),
      );
      const newServices: UpdatedValueDto[] = newOrder.services
        .filter((it) => !oldServiceKeys.has(`${it.date}-${it.serviceTypeName}`))
        .map(
          (it) =>
            ({
              oldValue: '',
              newValue: `${toDateString(toDate(it.date)!, 'P')}`,
              propertyName: it.serviceTypeName,
            } as UpdatedValueDto),
        )
        .toArray();
      return newServices;
    }
    return null;
  };

export default addAdditionalDay;
