import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 1,
    bottom: theme.spacing(1),
    height: theme.spacing(10),
    background: theme.palette.error.light,
    borderRadius: 5,
    pointerEvents: 'none',
  },
}));

interface ActualBlockProps {
  start: number;
  width: number;
  widthCoefficient: number;
}

export const ActualBlock = ({ start, width: widthInPixels, widthCoefficient }: ActualBlockProps) => {
  const classes = useStyles();
  const offset = start * widthCoefficient;
  const width = widthInPixels * widthCoefficient;

  return <Box left={offset} width={width} className={classes.estimatedBlock} />;
};
