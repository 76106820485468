import React, { useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  BodyText,
  EstimateType,
  Link,
  Order,
  Service,
  formatCurrency,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';
import { List } from 'immutable';

import { FlexWithNegativeMargin } from 'admin/components/OrderWindow/blocks/CommonComponents';
import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { LinehaulChargeFlatRateInput, LinehaulChargeLongDistanceInput } from 'admin/components/OrderWindow/common';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import LinehaulCharge from 'admin/components/OrderWindow/modals/LinehaulCharge';
import SettingName from 'admin/constants/SettingName';

const MOVING_CHARGE = 'Moving Charge';

const { TRAVEL_TIME_ENABLED, TRAVEL_TIME_IN_DOLLAR } = SettingName;

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

const formatMoney = (money: number) => `$${formatCurrency(money)}`;

enum Modals {
  LINEHAUL_CHARGE = 'LINEHAUL_CHARGE',
}

export const MovingCharge = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = isClosing ? order.closingOrderDetail : order;
  const services = orderDetail.services as List<Service>;
  const service = services.get(serviceIndex)!;
  const { quote } = service;
  const settings = useOrderWindowSettings();
  const isLongDistance = isLongDistanceService(service.getType());
  const isFlatRate = orderDetail.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  if (isFlatRate && !isLongDistance) {
    const totalFlatRate = isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.getFinalFlatRateLinehaulCharge(), 0)
      : quote.getFinalFlatRateLinehaulCharge();
    const totalFlatRateTravelTimeCharge = isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.getFlatRateTravelCharge(), 0)
      : quote.getFlatRateTravelCharge();

    return (
      <>
        <HighlightedTableRow>
          <TableCell>
            <BodyText data-testId="flatRateLinehaulCharge">Linehaul Charge</BodyText>
          </TableCell>
          <TableCell>
            {isFlatRate && !isSelectedAllServices && order.disableAutoCalculation ? (
              <FlexWithNegativeMargin>
                <LinehaulChargeFlatRateInput />
              </FlexWithNegativeMargin>
            ) : (
              <Text data-testId="flatRateLinehaulChargeValue" value={`$${formatCurrency(totalFlatRate)}`} />
            )}
          </TableCell>
        </HighlightedTableRow>
        <HighlightedTableRow>
          <TableCell>
            <BodyText data-testId="travelTimeCharge">Travel Time Charge</BodyText>
          </TableCell>
          <TableCell>
            <Text data-testId="travelTimeChargeValue" value={`$${formatCurrency(totalFlatRateTravelTimeCharge)}`} />
          </TableCell>
        </HighlightedTableRow>
      </>
    );
  }

  if (isLongDistance) {
    const label = 'Linehaul Charge';
    const totalLinehaulCharge = (isSelectedAllServices ? order : quote).baseQuoteWithoutTaxes.asHumanReadableString();

    return (
      <HighlightedTableRow>
        <TableCell>
          {quote.longDistanceTariffDetails.isRegionTariff() ? (
            <Link onClick={toggleOpenModal(Modals.LINEHAUL_CHARGE)}>{label}</Link>
          ) : (
            <BodyText data-testId={label}>{label}</BodyText>
          )}
          {Modals.LINEHAUL_CHARGE === openModal && (
            <LinehaulCharge
              onClose={toggleOpenModal(null)}
              moveSize={quote.activeMoveSize}
              linehaulCharge={quote.totalLinehaulCharge.humanReadable}
              longDistanceDetails={quote.longDistanceTariffDetails}
            />
          )}
        </TableCell>
        <TableCell>
          {order.disableAutoCalculation ? (
            <FlexWithNegativeMargin>
              <LinehaulChargeLongDistanceInput />
            </FlexWithNegativeMargin>
          ) : (
            <Text data-testId="lineHaulChargeValue" value={totalLinehaulCharge} />
          )}
        </TableCell>
      </HighlightedTableRow>
    );
  }

  const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];

  const shouldDisplayTravelCharge =
    settings[TRAVEL_TIME_ENABLED] && settings[TRAVEL_TIME_IN_DOLLAR] && !quote.isFlatRate();

  if (isTravelRateTheSameAsLaborRate || !shouldDisplayTravelCharge) {
    const movingCharge = (isSelectedAllServices ? order : quote).baseQuoteWithoutTaxes.asHumanReadableString();

    return (
      <HighlightedTableRow>
        <TableCell>
          <BodyText data-testId="movingCharge">{MOVING_CHARGE}</BodyText>
        </TableCell>
        <TableCell>
          <Text value={movingCharge} />
        </TableCell>
      </HighlightedTableRow>
    );
  }

  const totalLaborQuote = (isSelectedAllServices ? order : quote).totalLaborQuote.asHumanReadableString();
  const baseQuote = (isSelectedAllServices ? order : quote).baseQuoteWithoutTaxes.asHumanReadableString();
  const label = shouldDisplayTravelCharge ? 'Labor Charge' : MOVING_CHARGE;
  const value = shouldDisplayTravelCharge ? totalLaborQuote : baseQuote;
  const totalTravelQuote = formatMoney(
    isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.totalTravelQuote, 0)
      : quote.totalTravelQuote,
  );

  return (
    <>
      <HighlightedTableRow>
        <TableCell>
          <BodyText date-testId={shouldDisplayTravelCharge ? 'laborCharge' : 'movingCharge'}>{label}</BodyText>
        </TableCell>
        <TableCell>
          <Text data-testId={shouldDisplayTravelCharge ? 'laborChargeValue' : 'movingChargeValue'} value={value} />
        </TableCell>
      </HighlightedTableRow>
      {shouldDisplayTravelCharge && (
        <HighlightedTableRow>
          <TableCell>
            <BodyText data-testId="travelTimeCharge">Travel Time Charge</BodyText>
          </TableCell>
          <TableCell>
            <Text data-testId="travelTimeChargeValue" value={totalTravelQuote} />
          </TableCell>
        </HighlightedTableRow>
      )}
    </>
  );
};
