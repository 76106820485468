import { FC } from 'react';

import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import { statusByName } from 'admin/constants/OrderStatus';

interface StatusProps {
  label: string;
  title: string;
  size?: string;
  disabled?: boolean;
}

const ChipStyled = styled(Chip)`
  color: white !important;
  background-color: ${({ label }) => {
    const status = statusByName(label);
    return `${status?.color}!important`;
  }};
  width: 80px;
  text-transform: uppercase;
  .MuiChip-label {
    font-size: 10px;
  }
`;

const Status: FC<StatusProps> = ({ label, title, disabled = false }) => {
  return (
    <ChipStyled disabled={disabled} label={label.replace(/_/g, ' ')} title={title.replace(/_/g, ' ')} size="small" />
  );
};

export default Status;
