import { FC, useEffect, useState } from 'react';

import { GoogleAPI, GoogleApiWrapper } from 'google-maps-react';

import { GoogleApiContext } from 'admin/components/OrderWindow/context/GoogleApiContext';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import SettingName from 'admin/constants/SettingName';

interface GoogleAPISettersProps {
  google: GoogleAPI;
  setGoogle: (google: GoogleAPI) => void;
  loaded: boolean;
  setLoaded: (loaded: boolean) => void;
}

const GoogleAPISetters = ({ google, setGoogle, loaded, setLoaded }: GoogleAPISettersProps) => {
  useEffect(() => {
    setGoogle(google);
    setLoaded(loaded);
  }, [loaded]);

  return null;
};

const LoadingGoogle = GoogleApiWrapper((props) => ({
  apiKey: props.apiKey,
}))(GoogleAPISetters);

interface GoogleApiWrapperProviderProps {
  setLoaded: (loaded: boolean) => void;
  setGoogle: (google: GoogleAPI) => void;
}

const GoogleApiWrapperProvider: FC<GoogleApiWrapperProviderProps> = ({ setLoaded, setGoogle }) => {
  const settings = useOrderWindowSettings();
  const apiKey = settings[SettingName.GOOGLE_API_KEY];

  // @ts-ignore
  return apiKey ? <LoadingGoogle apiKey={apiKey} setLoaded={setLoaded} setGoogle={setGoogle} /> : null;
};

const GoogleApiProvider: FC = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [google, setGoogle] = useState<GoogleAPI | null>(null);

  return (
    <GoogleApiContext.Provider value={{ loadedGoogle: loaded, google }}>
      {children}
      <GoogleApiWrapperProvider setLoaded={setLoaded} setGoogle={setGoogle} />
    </GoogleApiContext.Provider>
  );
};

export default GoogleApiProvider;
