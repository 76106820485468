// @ts-ignore
import autodux from 'autodux';
import { compareDesc, parseISO } from 'date-fns';
import { List, Set, setIn } from 'immutable';
import { useDispatch } from 'react-redux';

import { taskAPI } from 'admin/api';
import { Task, TaskQuery, TaskStatus } from 'common-types/Task';

const updateTaskInList = (list: List<Task>, task: Task) => {
  const taskIndex = list.findIndex((item) => item.id === task.id);

  return taskIndex >= 0 ? list.set(taskIndex, task) : list.push(task);
};

const updateTaskFN = (payload: Task) => (task: Task) => task.id === payload.id ? payload : task;
const isNotCompleted = (task: Task) => !task.isCompleted;
const sortTasks = (firstTask: Task, secondTask: Task) =>
  firstTask.isCompleted
    ? compareDesc(firstTask.get('completedDate')!, secondTask.get('completedDate')!)
    : compareDesc(firstTask.getRemindDate()!, secondTask.getRemindDate()!);

const TASKS_PAGE_SIZE = 100;

const {
  reducer,
  actions: {
    updateTaskLocally,
    requestUncompletedTasks,
    requestUncompletedTasksSuccess,
    requestUncompletedFailure,
    requestUpdateTask,
    updateTaskSuccess,
    updateTaskFailure,
    saveTask,
    requestDeleteTask,
    taskDeleteSuccess,
    setEdit,
    reassignTask,
    setLastDeletedTime,
    setTasksByQuery,
  },
  selectors: { getUncompleted, getUncompletedCount, getIsUpdateInProgress, getEdit, getTasksByQuery },
} = autodux({
  slice: 'task',
  initial: {
    uncompleted: List(),
    uncompletedCount: 0,
    isFetchingUncompleted: false,
    isUpdateInProgress: Set(),
    edit: null,
    lastDeletedTime: 0,
    tasksByQuery: { pageIndex: 1, totalPages: 0, totalElements: 0, pageElements: [] } as PageDTO<Task>,
  },
  actions: {
    requestUncompletedTasks: (state: any) => ({
      ...state,
      isFetchingUncompleted: true,
    }),
    requestUncompletedTasksSuccess: (state: any, { totalElements, pageElements, pageIndex }: PageDTO<Task>) => ({
      ...state,
      uncompletedCount: totalElements || 0,
      uncompleted:
        pageIndex === 0
          ? List(pageElements || []).map((item) => new Task(item))
          : state.uncompleted.concat(pageElements.map((item) => new Task(item))),
      isFetchingUncompleted: false,
    }),
    requestUncompletedFailure: (state: any) => ({
      ...state,
      isFetchingUncompleted: false,
    }),
    updateTaskLocally: (state: any, { task, previousVersion }: { task: Task; previousVersion?: Task }) => {
      const changeCompletedStatus = previousVersion && task.isCompleted !== previousVersion.isCompleted;
      let updateCompletedCount = 0;

      if (changeCompletedStatus) {
        updateCompletedCount = task.isCompleted ? 1 : -1;
      }

      const updateUncompletedCount = updateCompletedCount * -1;

      return {
        ...state,
        uncompleted: updateTaskInList(state.uncompleted, task)
          .map(updateTaskFN(task))
          .filter(isNotCompleted)
          .sort(sortTasks),
        uncompletedCount: state.uncompletedCount + updateUncompletedCount,
      };
    },
    saveTask: (state: any, payload: Task) => ({
      ...state,
      uncompleted: updateTaskInList(state.uncompleted, payload)
        .map(updateTaskFN(payload))
        .filter(isNotCompleted)
        .sort(sortTasks),
      uncompletedCount: state.uncompletedCount + 1,
    }),
    requestUpdateTask: (state: any, payload: { id: number }) => ({
      ...state,
      isUpdateInProgress: state.isUpdateInProgress.add(payload.id),
    }),
    updateTaskSuccess: (state: any, payload: { id: number }) => ({
      ...state,
      isUpdateInProgress: state.isUpdateInProgress.remove(payload.id),
    }),
    updateTaskFailure: (state: any, payload: { task: Task }) => ({
      ...state,
      isUpdateInProgress: state.isUpdateInProgress.remove(payload.task.id),
    }),
    requestDeleteTask: (state: any, payload: { id: number }) => ({
      ...state,
      isUpdateInProgress: state.isUpdateInProgress.add(payload.id),
    }),
    reassignTask: (state: any, payload: Task) => ({
      ...state,
      ...(payload.isCompleted
        ? {}
        : {
            uncompleted: (state.uncompleted as List<Task>).filter((item) => item.id !== payload.id),
            uncompletedCount: state.uncompletedCount - 1,
          }),
      isUpdateInProgress: state.isUpdateInProgress.remove(payload.id),
    }),
    taskDeleteSuccess: (state: any, payload: Task) => ({
      ...state,
      ...(payload.isCompleted
        ? {}
        : {
            uncompleted: (state.uncompleted as List<Task>).filter((item) => item.id !== payload.id),
            uncompletedCount: state.uncompletedCount - 1,
          }),
      isUpdateInProgress: state.isUpdateInProgress.remove(payload.id),
    }),
  },
});

const fetchUncompletedTasks =
  (options: Partial<TaskQuery> = {}) =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestUncompletedTasks());
    return taskAPI
      .getAllForCurrent({
        ...options,
        status: [TaskStatus.READ, TaskStatus.UNREAD],
        pageSize: TASKS_PAGE_SIZE,
      })
      .then((tasks) => {
        dispatch(requestUncompletedTasksSuccess(tasks));
      })
      .catch((error) => dispatch(requestUncompletedFailure(error)));
  };

const fetchTasksByQuery =
  (query: Partial<TaskQuery> = {}) =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    return taskAPI.getAllForCurrent(query).then((response) => {
      response = setIn(response, ['pageElements'], response.pageElements?.map((t) => new Task(t)) ?? []);
      dispatch(setTasksByQuery(response));
    });
  };

const updateTask = (task: Task, previousVersion?: Task) => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(updateTaskLocally({ task, previousVersion }));
  dispatch(requestUpdateTask(task));

  return taskAPI
    .update(task.toDTO())
    .then((response) => {
      dispatch(updateTaskSuccess(task));
      dispatch(updateTaskLocally({ task: new Task(response), previousVersion: task }));
    })
    .catch((error) => {
      dispatch(updateTaskFailure({ error, task }));

      if (previousVersion) {
        dispatch(updateTaskLocally({ task: previousVersion, previousVersion: task }));
      }
    });
};
const markAllAsCompleted = (dispatch: ReturnType<typeof useDispatch>) => {
  return taskAPI
    .markAllAsCompleted()
    .catch(() => false)
    .then(() => {
      fetchUncompletedTasks()(dispatch);
    });
};
const deleteTask = (task: Task) => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(requestDeleteTask(task));
  return taskAPI.delete(task.id!).then(() => {
    dispatch(taskDeleteSuccess(task));
    dispatch(setLastDeletedTime(new Date().getTime()));
  });
};

const getLastUpdateDate = ({
  task: { uncompleted, lastDeletedTime },
}: {
  task: { uncompleted: List<Task>; lastDeletedTime: number };
}) =>
  Math.max(
    ...(uncompleted
      .map((item) =>
        item.dateUpdated || item.dateCreated ? parseISO((item.dateUpdated || item.dateCreated) as string).getTime() : 0,
      )
      .toJS() as number[]),
    lastDeletedTime,
  );

export default reducer;

export {
  getUncompleted,
  getUncompletedCount,
  getIsUpdateInProgress,
  getTasksByQuery,
  getEdit,
  setEdit,
  reassignTask,
  fetchUncompletedTasks,
  updateTask,
  markAllAsCompleted,
  deleteTask,
  TASKS_PAGE_SIZE,
  saveTask,
  sortTasks,
  getLastUpdateDate,
  fetchTasksByQuery,
};
