import React, { FC, useEffect, useState } from 'react';

import { BACKEND_DATE_FORMAT, BodyBigText, DataTable, DataTableColumnDef, RangeValue } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { DateRange } from 'materialui-daterange-picker';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import reportsApi from 'admin/api/ReportsAPI';
import { getInFlight, getReloadAmount, setInFlight } from 'admin/autodux/ReloadAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { CompanyIncomeReportAdditionalProperties } from 'admin/components/Reports/IncomeTab/CompanyIncomeReportAdditionalProperties';
import { CompanyIncomeReport } from 'admin/components/Reports/IncomeTab/IncomeTab';
import {
  SortOrderDirectionType,
  getExpandableRow,
  getTotalCompanyIncomeValues,
  makeIncomeTabColumns,
} from 'admin/components/Reports/config';

const useStyles = makeStyles<Theme>(({ breakpoints, palette, typography }) =>
  createStyles({
    dataTable: {
      [breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child *': {
        backgroundColor: palette.grey[50],
        fontWeight: typography.fontWeightBold,
      },
      '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child, & .MuiTable-root .MuiTableBody-root tr td .MuiTable-root .MuiTableRow-root td:last-child *':
        {
          textAlign: 'left',
        },
      '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child *, & .MuiTable-root .MuiTableBody-root tr td .MuiTable-root .MuiTableRow-root td:first-child *':
        {
          fontWeight: typography.fontWeightBold,
          pointerEvents: 'none',
          textTransform: 'uppercase',
        },
      [breakpoints.down('sm')]: {
        '& .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) *': {
          backgroundColor: palette.primary.light,
        },
        '& .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) .MuiButtonBase-root .MuiTouchRipple-root':
          {
            backgroundColor: 'transparent!important',
          },
      },
      '& .MuiTable-root .MuiTableBody-root tr td .MuiTable-root .MuiTableRow-root *': {
        fontWeight: typography.fontWeightRegular,
      },
      '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root:last-child *': {
        backgroundColor: 'transparent!important',
      },
      '& .MuiTable-root .MuiTableBody-root .MuiTableRow-root:last-child .MuiButtonBase-root': {
        pointerEvents: 'all',
      },
      '& .MuiTable-root .MuiTableBody-root tr:last-child td .MuiTableRow-root:nth-child(-n + 5)': {
        display: 'none',
      },
      '& .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) *': {
        fontWeight: typography.fontWeightBold,
      },
      '& .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) > td:nth-child(3) *': {
        textTransform: 'uppercase',
        textAlign: 'center',
      },
      '& .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) > td:nth-child(n+8), & .MuiTable-root[tabindex] > .MuiTableBody-root > .MuiTableRow-root:has(.total-column) > td:nth-child(n+8) *':
        {
          backgroundColor: palette.primary.light,
        },
    },
  }),
);

const errorVariant: OptionsObject = { variant: 'error' };

interface IncomeTableProps {
  currentDate: Date;
  dateRange: DateRange;

  title?: string;
}

export const IncomeTable: FC<IncomeTableProps> = ({ currentDate, dateRange, title }) => {
  const theme = useTheme<Theme>();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const inFlight = useSelector(getInFlight) as boolean;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortBy, setSortBy] = useState('startDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [incomeReportsCount, setIncomeReportsCount] = useState(0);
  const reloadAmount = useSelector(getReloadAmount);
  const { enqueueSnackbar } = useSnackbar() as ProviderContext;
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [companyIncomeReports, setCompanyIncomeReports] = useState<CompanyIncomeReport[]>([]);

  const { startDate, endDate } = dateRange;
  const startDateToLocalDate = format(startDate!, BACKEND_DATE_FORMAT);
  const endDateToLocalDate = format(endDate!, BACKEND_DATE_FORMAT);

  const currentStartDateToLocalDate = currentDate && format(startOfMonth(currentDate), BACKEND_DATE_FORMAT);
  const currentEndDateToLocalDate = currentDate && format(endOfMonth(currentDate), BACKEND_DATE_FORMAT);

  const getIncomeReports = () => {
    dispatch(setInFlight(true));
    reportsApi
      .getCompanyIncomeReport<CompanyIncomeReport, CompanyIncomeReportAdditionalProperties>(
        {
          dateRange: {
            startDate: (currentDate ? currentStartDateToLocalDate : startDateToLocalDate)!,
            endDate: (currentDate ? currentEndDateToLocalDate : endDateToLocalDate)!,
          },
        },
        { pageIndex: page - 1, pageSize: rowsPerPage, sortBy, sortOrder },
      )
      .then(({ additionalProperties, pageElements, totalElements }) => {
        const { overallNotCompletedTotals, overallCompletedTotals } = additionalProperties!;
        setIncomeReportsCount(totalElements);
        try {
          setCompanyIncomeReports([
            ...pageElements.map((report) => ({
              ...report,
              lineHaulCharge: RangeValue.createRangeValue(report.lineHaulCharge),
              laborCharge: RangeValue.createRangeValue(report.laborCharge),
              fuelCharge: RangeValue.createRangeValue(report.fuelCharge),
              totalPrice: RangeValue.createRangeValue(report.grandTotal),
              grandTotal: RangeValue.createRangeValue(report.grandTotal),
              closingGrandTotal: RangeValue.createRangeValue(report.closingGrandTotal),
            })),
            getTotalCompanyIncomeValues(overallNotCompletedTotals, overallCompletedTotals),
          ]);
        } catch (error) {
          console.log(error);
        }
      })
      .catch(() => {
        enqueueSnackbar(`Can't get the company income report`, errorVariant);
      })
      .then(() => {
        dispatch(setInFlight(false));
      });
  };

  useEffect(() => {
    getIncomeReports();
  }, [dateRange.endDate, dateRange.startDate, page, rowsPerPage, sortBy, sortOrder, currentDate, reloadAmount]);

  const columns = makeIncomeTabColumns(companyIncomeReports, { theme }) as DataTableColumnDef[];

  const onColumnSortChange = (changedColumn: string, direction: SortOrderDirectionType) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const onRowSelectionChangeOverridden = ([rowIndex]: number[]) => {
    if (typeof rowIndex !== 'undefined' && selectedRowIndex === rowIndex) {
      const order = companyIncomeReports[rowIndex];

      if (order?.id) {
        dispatch(openOrder(order.id));
      }
    } else {
      setSelectedRowIndex(rowIndex);
    }
  };

  const options = {
    showExpandableRowsOnMobile: true,
    alternateTableRowColors: true,
    tableBodyHeight: '100%',
    smallPadding: isMobile,
    selectableRowsHideCheckboxesCustom: true,
    inFlight: inFlight,
    count: incomeReportsCount,
    serverSide: true,
    page: page - 1,
    rowsPerPage,
    onColumnSortChange,
    onRowSelectionChangeOverridden,
    sortOrder: {
      name: sortBy,
      direction: sortOrder,
    },
    onChangeRowsPerPage: (rpp: number) => {
      setPage(1);
      setRowsPerPage(rpp);
    },
    onChangePage: (p: number) => {
      setPage(p + 1);
    },
    renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      return getExpandableRow(companyIncomeReports, rowData, rowMeta, columns, classes!);
    },
  };

  return (
    <Box display="flex" flexDirection="column" className={classes.dataTable}>
      {!!title && (
        <Box px={2} pb={1}>
          <BodyBigText>
            <b>{title}</b>
          </BodyBigText>
        </Box>
      )}
      <Box>
        <DataTable data={companyIncomeReports} columns={columns} options={options} />
      </Box>
    </Box>
  );
};
