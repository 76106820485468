import React from 'react';

import { Button, Modal } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import pt from 'prop-types';

import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { UploaderTitle } from 'admin/constants';

import FileSection from './Section';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& > div:first-child': {
        borderRight: `1px solid ${theme.palette.grey[400]}`,
      },
    },
  },
}));

const Attachments = ({ onClose, open, sourceId, activitySource }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      title="Attachments"
      onClose={onClose}
      maxWidth="lg"
      color="grey"
      childrenAction={
        <>
          <Button color="primary" variant="text" onClick={onClose}>
            Done
          </Button>
        </>
      }
    >
      <VideoButton position="absolute" video="7_bd-zztmgg" />
      <Box className={classes.container}>
        <FileSection
          description="Drag Photo here or"
          sourceId={sourceId}
          title={UploaderTitle.PHOTOS}
          acceptedFiles=".png,.gif,.jpg,.jpeg"
          extensions={['PNG', 'GIF', 'JPG', 'JPEG']}
          activitySource={activitySource}
        />
        <FileSection
          sourceId={sourceId}
          title={UploaderTitle.DOCUMENTS}
          acceptedFiles=".doc,.docx,.xlsx,.csv,.pdf"
          extensions={['DOC', 'DOCX', 'XLSX', 'CSV', 'PDF']}
          activitySource={activitySource}
        />
      </Box>
    </Modal>
  );
};

Attachments.propTypes = {
  sourceId: pt.number.isRequired,
  onClose: pt.func.isRequired,
  open: pt.bool.isRequired,
  activitySource: pt.string.isRequired,
};

export default Attachments;
