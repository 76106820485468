import React from 'react';

import TaskIcon from '@material-ui/icons/AssignmentTurnedIn';
import CustomIcon from '@material-ui/icons/Attachment';
import ChatIcon from '@material-ui/icons/Chat';
import EmailIcon from '@material-ui/icons/Email';
import NoteIcon from '@material-ui/icons/Note';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EventIcon from '@material-ui/icons/Settings';

import ActivityType from 'admin/constants/ActivityType';

const getActivityIcon = (actionType, isAdmin = true) => {
  switch (actionType) {
    case ActivityType.MESSAGE:
      return <ChatIcon color="primary" />;
    case ActivityType.NOTE:
      return <NoteIcon color="primary" />;
    case ActivityType.CALL:
      return <PhoneIcon color="primary" />;
    case ActivityType.EMAIL:
      return <EmailIcon color="primary" />;
    case ActivityType.CUSTOM:
      return <CustomIcon color="primary" />;
    case ActivityType.TASK:
      return <TaskIcon color="primary" />;
    case ActivityType.EVENT:
      return isAdmin ? <EventIcon color="primary" /> : <PersonIcon color="primary" />;
    default:
      return null;
  }
};

export default getActivityIcon;
