import React, { FC } from 'react';

import { Modal } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  innerModalContainer: {
    height: '100vh',
  },
  modal: {
    '& .MuiDialog-paperScrollPaper': {
      maxHeight: '100%',
    },
  },
});

interface IframeModalProps {
  open: boolean;
  onCancel: () => void;
  title: string;
  src: string;
}

export const IframeModal: FC<IframeModalProps> = ({ open, title, onCancel, src }) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} maxWidth="lg" open={open} title={title} onClose={onCancel}>
      <Box className={classes.innerModalContainer}>
        <iframe src={src} width="100%" height="100%" title={title} />
      </Box>
    </Modal>
  );
};
