import React, { useContext } from 'react';

import { AccountsPermissions, BodyBigText, HeaderBigText, Select, useHasPermission } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import AccountAPI from 'admin/api/AccountAPI';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { SummaryAccountContext } from 'admin/components/AccountWindow/config';
import { SALES_PERSON, SOURCE } from 'admin/components/Accounts/AccountsLabels';
import { useAccountsFiltersOptions } from 'admin/components/Accounts/config';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 24px',
  },
  createdByInfo: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '31% 70%',
    },
  },
  assignBox: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexBasis: 'initial',
    },
  },
}));

export const AccountManager = () => {
  const classes = useStyles();
  const managersList = useSelector(getManagerList);
  const { customer, setCustomer } = useContext(SummaryAccountContext);
  const { enqueueSnackbar } = useSnackbar();
  const { createdBy, created, updated, updatedBy } = customer;
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);

  useFetchManagers();

  const managersOptions = useAccountsFiltersOptions();
  const salesOptions = [['null', 'Unassigned'], ...managersOptions[SALES_PERSON]];

  const handleChangeSalesPerson = ({ target: { value } }) => {
    const selectedManager = managersList.toJS().find(({ id }) => id === value);

    AccountAPI.updateEmployee(customer.id, selectedManager.id)
      .then(() => {
        setCustomer((state) => (state.id === customer.id ? { ...state, manager: selectedManager } : state));
        enqueueSnackbar('Employee updated successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(`Can't updated employee`, { variant: 'error' });
      });
  };

  const handleChangeLeadSource = ({ target: { value } }) => {
    const selectedSource = managersOptions[SOURCE].find((item) => item[0] === value)[0];

    AccountAPI.updateLeadSource(customer.id, selectedSource)
      .then(() => {
        setCustomer((state) => (state.id === customer.id ? { ...state, source: selectedSource } : state));
        enqueueSnackbar('Lead source updated successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(`Can't updated lead source`, { variant: 'error' });
      });
  };

  return (
    <Box mt={1} p={0.5}>
      <Paper classes={{ root: classes.wrapper }}>
        <HeaderBigText>
          <b>Account Manager</b>
        </HeaderBigText>
        <Box className={classes.assignBox}>
          <BodyBigText>Assign to:</BodyBigText>
          <Box minWidth="150px">
            <Select
              name={SALES_PERSON}
              hiddenLabel
              fullWidth
              size="small"
              options={salesOptions}
              onChange={handleChangeSalesPerson}
              disabled={!canEditOtherUsersAccounts}
              value={customer.manager ? customer.manager.id.toString() : ''}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>
        <Box className={classes.assignBox}>
          <BodyBigText>Lead Source:</BodyBigText>
          <Box minWidth="150px">
            <Select
              hiddenLabel
              name={SOURCE}
              fullWidth
              disabled={!canEditOtherUsersAccounts}
              options={managersOptions[SOURCE]}
              onChange={handleChangeLeadSource}
              size="small"
              value={customer.source ? customer.source : ''}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>
        <Box>
          <Box className={classes.createdByInfo}>
            <BodyBigText>Created By:</BodyBigText>
            <BodyBigText>
              <b>
                {createdBy} &nbsp;
                {format(parseISO(created), 'Pp')}
              </b>
            </BodyBigText>
            <BodyBigText>Last Modified By:</BodyBigText>
            <BodyBigText>
              <b>
                {updatedBy} &nbsp;
                {format(parseISO(updated), 'Pp')}
              </b>
            </BodyBigText>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
