import { Dispatch, SetStateAction, createContext, useContext } from 'react';

import { ActivitySourceType, InvoiceDto } from '@elromcoinc/react-shared';

import { AccountDto } from 'common-types';
import { SortOrder } from 'common-types/SortOrder';

interface InvoiceModalContextProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  sortOrder: SortOrder;
  setSortOrder: Dispatch<SetStateAction<SortOrder>>;
  inFlight: boolean;
  invoices: InvoiceDto[];
  invoicesCount: number;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  fetchInvoices: () => void;
  sourceId: number;
  activitySource: ActivitySourceType;
  account?: AccountDto;
}

export const InvoiceModalContext = createContext<InvoiceModalContextProps>({} as InvoiceModalContextProps);

export const useInvoiceModalContext = () => useContext(InvoiceModalContext);
