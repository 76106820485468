import { createContext, Dispatch, SetStateAction } from 'react';
import { OrderWindowModal } from 'common-types';
import { Set } from 'immutable';

interface OrderWindowEditModalProps {
  openEditModal: Set<OrderWindowModal>;
  setOpenEditModal: Dispatch<SetStateAction<Set<OrderWindowModal>>>;
}

const OrderWindowEditModalContext = createContext<OrderWindowEditModalProps>({} as OrderWindowEditModalProps);

export { OrderWindowEditModalContext };
