import React, { ChangeEvent } from 'react';

import { Radio, RadioGroup } from '@elromcoinc/react-shared';
import { Box, Popover } from '@material-ui/core';

import timeToTextFormat from 'admin/utils/timeToTextFormat';

const snoozeOptions = [5, 10, 15, 30, 45, 60, 120, 180, 240, 300, 360, 1440, 2880, 5760, 10080].reduce(
  (accumulator, minutes) => {
    const seconds = minutes * 60;

    return [...accumulator, [seconds, timeToTextFormat({ minutes })]] as [number, string][];
  },
  [] as [number, string][],
);

interface TaskSnoozePopoverProps {
  id?: string;
  onSnooze: (snoozeTime: number) => void;
  onClose: () => void;
  snoozeElement: HTMLElement | null;
}

const TaskSnoozePopover = ({ onSnooze, onClose, snoozeElement, id }: TaskSnoozePopoverProps) => {
  const handleChangeSelectedSnoozeOption = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    onSnooze(+value);
  };

  const open = Boolean(snoozeElement);

  return (
    <Popover
      id={id}
      open={open}
      container={snoozeElement}
      anchorEl={snoozeElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box m={2}>
        <RadioGroup aria-label="snooze-task-option" name="snoozeTaskOption" onChange={handleChangeSelectedSnoozeOption}>
          {snoozeOptions.map(([key, label], index) => (
            <Radio key={key} value={key} autoFocus={index === 0} label={label} color="primary" />
          ))}
        </RadioGroup>
      </Box>
    </Popover>
  );
};

export default TaskSnoozePopover;
