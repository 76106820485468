import React from 'react';

import { CreditCardForm, Modal, ModalProps } from '@elromcoinc/react-shared';

interface AddCardModalProps {
  alertProps: ModalProps;
}

const AddCardModal = ({ alertProps }: AddCardModalProps) => {
  return (
    <Modal title="Add Card" maxWidth="sm" onClose={() => {}} {...alertProps}>
      <CreditCardForm initialData={{}} autoFocus onMount={() => {}} />
    </Modal>
  );
};

export { AddCardModal };
