enum LongDistanceMethods {
  MILEAGE = 'MILEAGE',
  REGIONS = 'REGIONS',
}
export default LongDistanceMethods;

const longDistanceMethodName: Record<LongDistanceMethods, string> = {
  [LongDistanceMethods.MILEAGE]: 'Mileage',
  [LongDistanceMethods.REGIONS]: 'Area Code',
};

const longDistanceMethodOptions = Object.entries(longDistanceMethodName);

export { longDistanceMethodName, longDistanceMethodOptions };
