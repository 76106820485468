import { useEffect } from 'react';

import { storageUtil, useUpdateEffect } from '@elromcoinc/react-shared';
import { List } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';

import { fetchUsers, getBasicEmployee } from 'admin/autodux/UsersAutodux';
import { BasicEmployeeDto } from 'common-types';

export const useFetchUsers = () => {
  const allEmployees = useSelector(getBasicEmployee) as List<BasicEmployeeDto>;
  const dispatch = useDispatch();
  const companyId = storageUtil.getTenantId();

  useEffect(() => {
    if (allEmployees.size === 0) {
      dispatch(fetchUsers());
    }
  }, [allEmployees.size]);

  useUpdateEffect(() => {
    if (companyId) {
      dispatch(fetchUsers());
    }
  }, [companyId]);
};
