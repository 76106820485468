import React, { useContext } from 'react';

import { HeaderSmallText, IconButton } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/CallMadeTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import classNames from 'classnames';
import pt from 'prop-types';

import { SummaryAccountContext } from 'admin/components/AccountWindow/config';
import { UnreadMessagesButton } from 'admin/components/OrderWindow/buttons';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import { useMobile } from 'admin/hooks/useMobile';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '51px',
    backgroundColor: `${theme.palette.common.black}`,
    color: `${theme.palette.common.white}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    '&.draggable-handle': {
      cursor: 'move',
    },
    '&.minimize .hide-button': {
      display: `none !important`,
    },
  },
  accountInfo: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textTransform: 'capitalize',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const TitlePanel = ({ handleCloseAccountWindow, minimized, onMinimize, parentWidth }) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const { customer } = useContext(SummaryAccountContext);
  const { id, name, type } = customer;
  const fullScreen = parentWidth < 850 && !minimized;
  const isPrivateAccount = type === AccountType.PRIVATE;

  return (
    <Box
      className={classNames(classes.wrapper, !isMobile && !minimized && 'draggable-handle', minimized && 'minimize')}
    >
      {!isPrivateAccount && (
        <Box>
          <UnreadMessagesButton />
        </Box>
      )}
      <Box className={classes.accountInfo}>
        <HeaderSmallText display="inline">
          <b>{id}</b>&nbsp;({AccountTypeName[type]}){name ? <b>&nbsp;-&nbsp;{name}</b> : null}
        </HeaderSmallText>
      </Box>
      {fullScreen ? (
        <Box>
          <IconButton color="inherit" onClick={handleCloseAccountWindow}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      ) : (
        <Box display="flex">
          <IconButton color="inherit" onClick={onMinimize} onTouchStart={onMinimize} disableRipple>
            {minimized ? <MaximizeIcon fontSize="large" /> : <MinimizeIcon fontSize="large" />}
          </IconButton>
          <IconButton
            color="inherit"
            onClick={handleCloseAccountWindow}
            onTouchStart={handleCloseAccountWindow}
            data-testid="CloseAccountButton"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

TitlePanel.propTypes = {
  handleCloseAccountWindow: pt.func.isRequired,
  onMinimize: pt.func,
  minimized: pt.bool,
  parentWidth: pt.string,
};

TitlePanel.defaultProps = {
  onMinimize: null,
  minimized: false,
  parentWidth: null,
};
