import {
  EstimateType,
  MileageTariffType,
  Order,
  OrderStatusIdType,
  PackingPricingType,
  ServiceType,
  statusIds,
} from '@elromcoinc/react-shared';

import { AutomationRuleName } from 'common-types';

const estimateTypeLocalMoveBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeLoadingHelpBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeUnloadingHelpBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeMovingAndStorageBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeLDInterstateBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeLDIntrastateBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};
const estimateTypeOverNightStorageBooked = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_BOOKED,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_BOOKED,
};

const estimateTypeLocalMoveCanBookOnline = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LOCAL_MOVE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LOCAL_MOVE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LOCAL_MOVE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeLoadingHelpCanBookOnline = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeUnloadingHelpCanBookOnline = {
  [EstimateType.NON_BINDING]:
    AutomationRuleName.WHEN_UNLOADING_HELP_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_UNLOADING_HELP_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_UNLOADING_HELP_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeMovingAndStorageCanBookOnline = {
  [EstimateType.NON_BINDING]:
    AutomationRuleName.WHEN_MOVING_WITH_STORAGE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_MOVING_WITH_STORAGE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]:
    AutomationRuleName.WHEN_MOVING_WITH_STORAGE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeLDInterstateCanBookOnline = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LD_INTERSTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LD_INTERSTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LD_INTERSTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeLDIntrastateCanBookOnline = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_LD_INTRASTATE_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_LD_INTRASTATE_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_LD_INTRASTATE_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};
const estimateTypeOverNightStorageCanBookOnline = {
  [EstimateType.NON_BINDING]: AutomationRuleName.WHEN_OVERNIGHT_NON_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.BINDING]: AutomationRuleName.WHEN_OVERNIGHT_BINDING_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
  [EstimateType.FLAT_RATE]: AutomationRuleName.WHEN_OVERNIGHT_FLAT_RATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE,
};

const resolveAutomationRuleNameWhenStatusChangedFromCanBookOnlineToBooked = (
  order: Order,
): AutomationRuleName | null => {
  const generalServiceType = order.getServiceType(0);
  const laborOnly = order.getServiceQuote(0).laborOnly;

  switch (generalServiceType) {
    // TODO need to add logic for junk removal service type here when it is on
    // case ServiceType.JUNK_REMOVAL:
    case ServiceType.LOCAL_MOVING:
      if (laborOnly) {
        return AutomationRuleName.WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      return estimateTypeLocalMoveBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.OVERNIGHT_STORAGE:
      return estimateTypeOverNightStorageBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.LOADING_HELP:
      if (laborOnly) {
        return AutomationRuleName.WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      return estimateTypeLoadingHelpBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.UNLOADING_HELP:
      if (laborOnly) {
        return AutomationRuleName.WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      return estimateTypeUnloadingHelpBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.MOVING_AND_STORAGE:
      return estimateTypeMovingAndStorageBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.PACKING_DAY:
      const packingPricingType = order.getServiceQuote(0)?.packingPricingType;
      if (packingPricingType === PackingPricingType.HOURLY) {
        return AutomationRuleName.WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_BOOKED;
      } else if (packingPricingType === PackingPricingType.PER_BOX) {
        return AutomationRuleName.WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      break;
    case ServiceType.LD_INTERSTATE:
      if (order.getServiceQuote(0)?.longDistanceTariffDetails?.unitType === MileageTariffType.BY_VOLUME_CUFT) {
        return AutomationRuleName.WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      return estimateTypeLDInterstateBooked[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.LD_INTRASTATE:
      if (order.getServiceQuote(0)?.longDistanceTariffDetails?.unitType === MileageTariffType.BY_VOLUME_CUFT) {
        return AutomationRuleName.WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_BOOKED;
      }

      return estimateTypeLDIntrastateBooked[order.getServiceQuote(0).estimateType as EstimateType];
  }

  return null;
};

const resolveAutomationRuleNameWhenStatusChangedFromNewToCanBookOnline = (order: Order): AutomationRuleName | null => {
  const generalServiceType = order.getServiceType(0);
  const laborOnly = order.getServiceQuote(0).laborOnly;

  switch (generalServiceType) {
    // TODO need to add logic for junk removal service type here when it is on
    // case ServiceType.JUNK_REMOVAL:
    case ServiceType.LOCAL_MOVING:
      if (laborOnly) {
        return AutomationRuleName.WHEN_LOCAL_MOVE_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      return estimateTypeLocalMoveCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.OVERNIGHT_STORAGE:
      return estimateTypeOverNightStorageCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.LOADING_HELP:
      if (laborOnly) {
        return AutomationRuleName.WHEN_LOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      return estimateTypeLoadingHelpCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.UNLOADING_HELP:
      if (laborOnly) {
        return AutomationRuleName.WHEN_UNLOADING_HELP_LABOR_ONLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      return estimateTypeUnloadingHelpCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.MOVING_AND_STORAGE:
      return estimateTypeMovingAndStorageCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.PACKING_DAY:
      const packingPricingType = order.getServiceQuote(0)?.packingPricingType;
      if (packingPricingType === PackingPricingType.HOURLY) {
        return AutomationRuleName.WHEN_PACKING_DAY_HOURLY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      } else if (packingPricingType === PackingPricingType.PER_BOX) {
        return AutomationRuleName.WHEN_PACKING_DAY_BY_BOX_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      break;
    case ServiceType.LD_INTERSTATE:
      if (order.getServiceQuote(0)?.longDistanceTariffDetails?.unitType === MileageTariffType.BY_VOLUME_CUFT) {
        return AutomationRuleName.WHEN_LD_INTERSTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      return estimateTypeLDInterstateCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
    case ServiceType.LD_INTRASTATE:
      if (order.getServiceQuote(0)?.longDistanceTariffDetails?.unitType === MileageTariffType.BY_VOLUME_CUFT) {
        return AutomationRuleName.WHEN_LD_INTRASTATE_VOLUME_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE;
      }

      return estimateTypeLDIntrastateCanBookOnline[order.getServiceQuote(0).estimateType as EstimateType];
  }

  return null;
};

const getChangesStatusAutomationRule = (order: Order, previousStatus: OrderStatusIdType): AutomationRuleName | null => {
  if (order.status === statusIds.BOOKED) {
    return resolveAutomationRuleNameWhenStatusChangedFromCanBookOnlineToBooked(order);
  }

  if (order.status === statusIds.CAN_BOOK_ONLINE) {
    switch (previousStatus) {
      case statusIds.NEW_LEAD_PAST:
        return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_PAST_TO_CAN_BOOK_ONLINE;
      case statusIds.NEW_LEAD_INFO:
        return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_INFO_TO_CAN_BOOK_ONLINE;
      case statusIds.NEW_LEAD_TRUCK:
        return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_FROM_NEW_LEAD_TRUCK_TO_CAN_BOOK_ONLINE;
      default:
        return resolveAutomationRuleNameWhenStatusChangedFromNewToCanBookOnline(order);
    }
  }

  switch (order.status) {
    case statusIds.CANCELLED:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_CANCELED;
    case statusIds.EXPIRED:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_EXPIRED;
    case statusIds.NEW_LEAD_PAST:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_PAST;
    case statusIds.NEW_LEAD_INFO:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_INFO;
    case statusIds.NEW_LEAD_TRUCK:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_NEW_LEAD_TRUCK;
    case statusIds.WE_ARE_NOT_AVAILABLE:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_NOT_AVAILABLE;
    case statusIds.RESERVED:
      return AutomationRuleName.WHEN_ORDER_STATUS_CHANGES_TO_RESERVED;
  }

  return null;
};

export { getChangesStatusAutomationRule };
