enum EventName {
  WHEN_LOCAL_MOVE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOCAL_MOVE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOADING_HELP_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LOADING_HELP_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_UNLOADING_HELP_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_UNLOADING_HELP_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_MOVING_WITH_STORAGE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_MOVING_WITH_STORAGE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LONG_DISTANCE_INTRASTATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LONG_DISTANCE_INTRASTATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LONG_DISTANCE_INTERSTATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_LONG_DISTANCE_INTERSTATE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_OVERNIGHT_STORAGE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_OVERNIGHT_STORAGE_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_PACKING_DAY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_PACKING_DAY_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_JUNK_REMOVAL_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE = 'WHEN_JUNK_REMOVAL_ORDER_STATUS_CHANGES_TO_CAN_BOOK_ONLINE',
  WHEN_LOCAL_MOVE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOCAL_MOVE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LOADING_HELP_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LOADING_HELP_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_UNLOADING_HELP_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_UNLOADING_HELP_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_MOVING_WITH_STORAGE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_MOVING_WITH_STORAGE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LONG_DISTANCE_INTRASTATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LONG_DISTANCE_INTRASTATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_LONG_DISTANCE_INTERSTATE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_LONG_DISTANCE_INTERSTATE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_OVERNIGHT_STORAGE_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_OVERNIGHT_STORAGE_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_PACKING_DAY_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_PACKING_DAY_ORDER_STATUS_CHANGES_TO_BOOKED',
  WHEN_JUNK_REMOVAL_ORDER_STATUS_CHANGES_TO_BOOKED = 'WHEN_JUNK_REMOVAL_ORDER_STATUS_CHANGES_TO_BOOKED',
}

export { EventName };
