import axios from 'axios';

class OrdersApi {
  getNextOrderNumberForGivenBranch(branchId) {
    return axios.get(`/api/moving/orders/branchId/${branchId}/nextNumber/`);
  }

  setNextOrderNumberForGivenBranch(branchId, nextOrderNumber) {
    return axios.put(`/api/moving/orders/branchId/${branchId}/nextNumber/${nextOrderNumber}/`, {});
  }

  downloadOrdersReport(params) {
    return axios.get('/api/moving/orders/summaries/export/', {
      params,
      responseType: 'blob',
    });
  }
}

export default new OrdersApi();
