import React, { useEffect, useState } from 'react';
import { Box, Popover, Tooltip, makeStyles, Grid } from '@material-ui/core';
import { BodyText, Button, Link, TextInput, IconButton } from '@elromcoinc/react-shared';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { getIn, mergeDeep } from 'immutable';
import { yupResolver } from '@hookform/resolvers/yup';
import { ADDITIONAL_INFO } from 'admin/constants/FieldNames';
import { object, string } from 'yup';
import AccountAPI from 'admin/api/AccountAPI';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  popover: {
    minWidth: '31rem',
    [theme.breakpoints.down('sm')]: {
      minWidth: '19rem',
    },
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    right: 8,
    top: 6,
  },
  iconButton: {
    padding: 6,
  },
}));

const labels = {
  [ADDITIONAL_INFO]: 'Additional Info',
};

const schema = object().shape({
  [ADDITIONAL_INFO]: string().nullable().label(labels[ADDITIONAL_INFO]).max(999),
});

const additionalInfoPath = `${ADDITIONAL_INFO}`;
const asArray = (path) => path.split('.');

export const AdditionalInfoView = ({ customer, onSubmit }) => {
  const { ownerCustomerInfo, additionalInfo } = customer;
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [hasChanges, setHasChanges] = useState(false);
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleOpenAdditionalInfoPopover = ({ currentTarget }) => setAnchorEl(currentTarget);
  const handleCloseAdditionalInfoPopover = () => setAnchorEl(null);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...customer,
      ownerCustomerInfo: {
        ...ownerCustomerInfo,
      },
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const handleSubmitForm = (data) => {
    const updateCustomer = mergeDeep(customer, data);
    onSubmit(updateCustomer);
    setIsDisabledInProcessing(true);

    AccountAPI.updateAccountInfo({ ...updateCustomer, updated: null, created: null })
      .then(() => {
        enqueueSnackbar('Customer information saved successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(`Can't update customer information`, { variant: 'error' });
      })
      .then(() => {
        handleCloseAdditionalInfoPopover();
        setIsDisabledInProcessing(false);
      });
  };

  return (
    <>
      <Tooltip title="View info">
        <Box display="flex" maxWidth={200}>
          <Link onClick={handleOpenAdditionalInfoPopover} style={{ overflow: 'hidden' }} color="initial">
            <BodyText noWrap>
              <b>{additionalInfo}</b>
            </BodyText>
          </Link>
        </Box>
      </Tooltip>
      <Popover
        id="additional-info-popover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        anchorEl={anchorEl}
        classes={{ paper: classes.popover }}
        onClose={handleCloseAdditionalInfoPopover}
      >
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.closeIcon}>
                <Tooltip title="Close list" arrow placement="bottom">
                  <IconButton
                    color="primary"
                    classes={{ root: classes.iconButton }}
                    onClick={handleCloseAdditionalInfoPopover}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
              <TextInput
                fullWidth
                label={getIn(labels, asArray(additionalInfoPath), '')}
                name={additionalInfoPath}
                multiline
                InputProps={{
                  disableUnderline: true,
                }}
                control={control}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <Button color="primary" variant="text" size="small" onClick={handleCloseAdditionalInfoPopover}>
              cancel
            </Button>
            <Button
              color={!hasChanges ? 'default' : 'primary'}
              variant="text"
              size="small"
              disabled={!hasChanges}
              loading={isDisabledInProcessing}
              onClick={handleSubmit(handleSubmitForm)}
            >
              save
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

AdditionalInfoView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  customer: pt.object.isRequired,
  onSubmit: pt.func.isRequired,
};
