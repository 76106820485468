/* eslint-disable react/prop-types */
import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { format, isDate, parse, parseISO } from 'date-fns';

import { MultiDayIndicator } from 'admin/components/MultiDayIndicator';
import { statusById } from 'admin/constants/OrderStatus';
import { toDateString } from 'admin/utils/DateUtils';
import Status from 'common/components/Status';

const renderServiceType = ({ serviceSummaries }) => {
  const service = serviceSummaries[0];

  if (!service) {
    return null;
  }

  const { serviceName } = service;

  return (
    <>
      <BodyText>
        {serviceName}
        <MultiDayIndicator visible={serviceSummaries.length > 1} />
      </BodyText>
    </>
  );
};

const renderDetails = ({ serviceSummaries, sizeDescription }) => {
  const service = serviceSummaries[0];

  if (!service) return null;

  const { toAddress, fromAddress } = service;
  const fromAddressString =
    fromAddress && fromAddress.postalCode
      ? `${fromAddress.city}, ${fromAddress.state}, ${fromAddress.postalCode}`
      : null;
  const toAddressString =
    toAddress && toAddress.postalCode ? `${toAddress.city}, ${toAddress.state}, ${toAddress.postalCode}` : null;

  return (
    <Box display="flex" flexDirection="column">
      {fromAddressString && (
        <span>
          <BodyText>
            {toAddressString && 'From:\xa0'}
            {`${fromAddress.city}, ${fromAddress.state}, ${fromAddress.postalCode}`}
          </BodyText>
        </span>
      )}
      {toAddressString && (
        <span>
          <BodyText>
            {fromAddressString && 'To:\xa0'}
            {`${toAddress.city}, ${toAddress.state}, ${toAddress.postalCode}`}
          </BodyText>
        </span>
      )}
      {sizeDescription && (
        <span>
          <BodyText>{sizeDescription.split('(')[0]}</BodyText>
        </span>
      )}
    </Box>
  );
};

const renderDate = (date) => {
  if (!date) return null;
  if (isDate(date)) {
    return toDateString(date, 'MM/dd/yyyy');
  }

  return format(parse(date.split('T')[0], 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
};

const renderStatus = ({ status }) => {
  const statusInfo = statusById(status);

  if (!statusInfo) return null;

  return <Status label={statusInfo.label} title={status.replace(/_/g, ' ')} size="small" />;
};

export const makeColumns = (rowsData) => [
  {
    name: 'orderId',
    label: '',
    options: {
      display: false,
    },
  },
  {
    name: 'orderNumber',
    label: 'Order Number',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const row = rowsData[rowIndex];
        const { orderNumber } = row;
        const { customerName } = row;
        return (
          <Box display="flex" flexDirection="column" style={{ textTransform: 'capitalize' }}>
            <BodyText>{orderNumber}</BodyText>
            <BodyText>({customerName})</BodyText>
          </Box>
        );
      },
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const row = rowsData[rowIndex];
        return renderStatus(row);
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: 'serviceName',
    label: 'Service Type',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const row = rowsData[rowIndex];
        return renderServiceType(row);
      },
      showOnMobileAsExpanded: true,
      sort: false,
    },
  },
  {
    name: 'moveStartDate',
    label: 'Move Date',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const row = rowsData[rowIndex];
        const { startDate } = row;
        return <BodyText>{format(parseISO(startDate), 'P')}</BodyText>;
      },
    },
  },
  {
    name: 'details',
    label: 'Details',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const row = rowsData[rowIndex];
        return renderDetails(row);
      },
      showOnMobileAsExpanded: true,
      sort: false,
    },
  },
  {
    name: 'updated',
    label: 'Last Updt.',
    options: {
      customBodyRender: (_, { rowIndex }) => {
        const { updatedDate } = rowsData[rowIndex];
        return renderDate(updatedDate);
      },
      showOnMobileAsExpanded: true,
    },
  },
];
