import React, { useEffect, useState } from 'react';

import { Backdrop, Portal, makeStyles, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  closeWindow,
  getMinimizedWindow,
  getSelectedWindow,
  toggleMinimizedWindow,
} from 'admin/autodux/WindowsAutodux';
import { AccountWindow } from 'admin/components/AccountWindow';
import OrderWindow from 'admin/components/OrderWindow';
import { WindowType } from 'admin/constants/WidowType';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: max-content;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.drawer + 50};
  position: fixed;
  bottom: 0;
  left: 3.5rem;
`;

export const MultiWindowController = () => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const windows = useSelector(getSelectedWindow);
  const [selectedWindow, setSelectedWindow] = useState(windows.last() || null);
  const minimizedWindows = useSelector(getMinimizedWindow);

  useEffect(() => {
    setSelectedWindow(windows.last());
  }, [windows]);

  const onMinimizeWindow = (openWindow) => () => {
    dispatch(toggleMinimizedWindow(openWindow));
  };

  const handleOnCloseWindow = (openWindow) => () => {
    dispatch(closeWindow(openWindow));
  };

  const numberOfOpenWindows = windows.size;

  const orderWindowZIndex = theme.zIndex.drawer + 50;

  return (
    <Portal>
      <Backdrop open={numberOfOpenWindows > minimizedWindows.size} className={classes.backdrop} />
      <FlexContainer>
        {windows
          .toList()
          .map((window, index) =>
            window.type === WindowType.ACCOUNT ? (
              <AccountWindow
                minimized={minimizedWindows.find((w) => w.id === window.id && w.type === window.type)}
                width="95vw"
                key={window.type + window.id}
                accountId={window.id}
                offsetCoefficient={(1 + index) / (1 + numberOfOpenWindows)}
                onClose={handleOnCloseWindow(window)}
                onMinimize={onMinimizeWindow(window)}
                zIndex={
                  selectedWindow.id !== window.id || selectedWindow.type !== window.type ? orderWindowZIndex - 2 : null
                }
                onSelectWindow={setSelectedWindow}
                selectedWindow={selectedWindow}
              />
            ) : (
              <OrderWindow
                minimized={minimizedWindows.find((w) => w.id === window.id && w.type === window.type)}
                width="95vw"
                key={window.type + window.id}
                orderId={window.id}
                offsetCoefficient={(1 + index) / (1 + numberOfOpenWindows)}
                onClose={handleOnCloseWindow(window)}
                onMinimize={onMinimizeWindow(window)}
                zIndex={
                  selectedWindow.id !== window.id || selectedWindow.type !== window.type ? orderWindowZIndex - 2 : null
                }
                onSelectWindow={setSelectedWindow}
              />
            ),
          )}
      </FlexContainer>
    </Portal>
  );
};
