import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const DistanceBox = styled(Box)<{ disabled?: boolean }>`
  ${({ disabled }) => (disabled ? 'opacity: 0.2;' : '')}
  margin-left: 8px;
  margin-right: 8px;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  min-width: 52px;
  justify-content: space-evenly;
  margin-bottom: 8px;
  > svg + p {
    margin-bottom: 4px;
  }
`;

export default DistanceBox;
