import * as React from 'react';
import { ChangeEvent, FC, useState } from 'react';

import { ActivitySourceType, HorizontalLine, Tab, TabPanel, Tabs } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';

import { ActivityLog } from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityLog';
import { ComposeEmail } from 'admin/components/OrderWindow/blocks/ActivityManager/ComposeEmail';
import { NewCall } from 'admin/components/OrderWindow/blocks/ActivityManager/NewCall';
import { NewMessage } from 'admin/components/OrderWindow/blocks/ActivityManager/NewMessage';
import { NewNote } from 'admin/components/OrderWindow/blocks/ActivityManager/NewNote';
import getActivityIcon from 'admin/components/OrderWindow/blocks/ActivityManager/getActivityIcon';
import ActivityType from 'admin/constants/ActivityType';
import Card from 'common/components/Card';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

enum ActivityTab {
  Note,
  Message,
  Email,
  Call,
}

const tabOptions = [
  { id: ActivityTab.Note, label: 'Note', icon: getActivityIcon(ActivityType.NOTE) },
  { id: ActivityTab.Message, label: 'Message', icon: getActivityIcon(ActivityType.MESSAGE) },
  { id: ActivityTab.Email, label: 'Email', icon: getActivityIcon(ActivityType.EMAIL) },
  { id: ActivityTab.Call, label: 'Call', icon: getActivityIcon(ActivityType.CALL) },
];

interface ActivityManagerProps {
  sourceId: number;
  activitySource: ActivitySourceType;
  orderNumber?: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  roundedTab: {
    '&.MuiTab-root': {
      borderRadius: spacing(0.75, 0.75, 0, 0),
    },
  },
}));

export const ActivityManager: FC<ActivityManagerProps> = ({ sourceId, activitySource, orderNumber }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<ActivityTab>(ActivityTab.Note);

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: number) => {
    setTab(+newValue);
  };

  return (
    <Box>
      <Card minWidth="290px" dataTestId="activity-manager">
        <Tabs
          indicatorColor="primary"
          inheritLightBackground
          data-testid="activityManager"
          value={tab}
          onChange={handleChangeTab as any}
          variant="scrollable"
          scrollButtons="off"
        >
          {tabOptions.map(({ id, label, icon }) => (
            <Tab
              key={id}
              value={+id}
              data-testid={`${label}-tab`}
              className={classes.roundedTab}
              label={
                <Box component="span" display="flex">
                  {icon!} <Box ml={0.5}>{label}</Box>
                </Box>
              }
              {...a11yProps(+id)}
            />
          ))}
        </Tabs>
        <TabPanel value={tab} index={ActivityTab.Note} alwaysRenderChildren>
          <NewNote activitySource={activitySource} sourceId={sourceId} />
        </TabPanel>
        <TabPanel value={tab} index={ActivityTab.Message} alwaysRenderChildren>
          <NewMessage activitySource={activitySource} sourceId={sourceId} />
        </TabPanel>
        <TabPanel value={tab} index={ActivityTab.Email} alwaysRenderChildren>
          <ComposeEmail activitySource={activitySource} sourceId={sourceId} />
        </TabPanel>
        <TabPanel value={tab} index={ActivityTab.Call} alwaysRenderChildren>
          <NewCall activitySource={activitySource} sourceId={sourceId} />
        </TabPanel>
        <Box my={1}>
          <HorizontalLine color="primary" />
        </Box>
      </Card>
      <Card minWidth="290px" dataTestId="activity-log" pt={1}>
        <ActivityLog sourceId={sourceId} activitySource={activitySource} orderNumber={orderNumber} />
      </Card>
    </Box>
  );
};
