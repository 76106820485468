import React, { useEffect, useRef, useState } from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodyBigText,
  BodySmallText,
  BodyText,
  ElromcoCircularProgress,
  Link,
  Order,
  ServiceType,
  IconButton as SharedIconButton,
  StorageMoveStage,
  StorageQuote,
  Waypoint,
  formatCurrency,
  useConfirm,
} from '@elromcoinc/react-shared';
import { Tooltip, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { differenceInCalendarDays, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import orderAPI from 'admin/api/OrderAPI';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import DistanceBox from 'admin/components/OrderWindow/blocks/Addresses/DistanceBox';
import { useOrderState } from 'admin/components/OrderWindow/context';
import { useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context/useOrderWindowEditAddressIndex';
import { BluePenIcon, BlueRightArrorIcon, IconBlueFab } from 'common/components/Widgets';
import { StorageIcon } from 'common/components/icons';

import DragHandle from './DragHandle';
import LinkOrderAutocomplete from './LinkOrderAutocomplete';
import SortableElement from './SortableElement';

const useStyles = makeStyles({
  swapStorageIcon: {
    fontSize: '30px',
  },
  displayIcon: {
    display: 'block',
  },
});

interface StorageBlockProps {
  data: Waypoint;
  disabled?: boolean;
  storageMoveStage: StorageMoveStage;
  isDestination: boolean;
  index: number;
  title: string;
  linkedOrderId: number | null;
  distanceFromPrevious: string;
  minWidth: number;
  onBlockWidthChange: (width: number) => void;
  serviceType: ServiceType;
  storageQuote: StorageQuote | null;
  durationFromPrevious: string;
  handleSwapStorage: () => void;
  setLoadedOrder: (value: Order) => void;
}
const StorageBlock = ({
  data,
  disabled = false,
  storageMoveStage,
  isDestination,
  index,
  title,
  linkedOrderId,
  distanceFromPrevious,
  minWidth,
  onBlockWidthChange,
  serviceType,
  storageQuote,
  durationFromPrevious,
  handleSwapStorage,
  setLoadedOrder,
}: StorageBlockProps) => {
  const classes = useStyles();
  const [inFlight, setInFlight] = useState(false);
  const { order } = useOrderState();
  const { setEditWaypoint } = useOrderWindowEditAddressIndex();
  const blockRef = useRef<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const [storage, setStorage] = useState<StorageQuote>({} as StorageQuote);
  const { address } = data;
  const { enqueueSnackbar } = useSnackbar();

  const { confirm, renderDialog } = useConfirm({
    title: 'Warning',
    message: 'You are about to unlink this order. Do you wish to proceed?',
    confirmTitle: 'Yes, unlink',
    cancelTitle: 'No, Cancel',
  });

  const { start, end, price, includeInQuote } = storage;

  useEffect(() => {
    if (!storageQuote) {
      return;
    }

    setStorage(storageQuote.toJS() as StorageQuote);
  }, [storageQuote]);

  useEffect(() => {
    if (blockRef.current && blockRef.current?.clientWidth) {
      onBlockWidthChange(blockRef.current?.clientWidth);
    }
  });
  const onSetAddressIndex = (index: number) => () => {
    const newIndex = (index + 1) * -1;
    setEditWaypoint(
      new Waypoint({
        waypointIndex: newIndex,
        originalWaypointIndex: newIndex,
        sequenceOrder: newIndex,
        serviceIndex: data.serviceIndex,
      }),
    );
  };

  function openLinkedOrder() {
    if (linkedOrderId) {
      dispatch(openOrder(linkedOrderId));
    }
  }
  const getStorageDays = () => {
    if (!start || !end) {
      return null;
    }
    const startDay = parse(start, BACKEND_DATE_FORMAT, new Date());
    const endDay = parse(end, BACKEND_DATE_FORMAT, new Date());
    return `(${differenceInCalendarDays(endDay, startDay)} days)`;
  };

  const handleUnlinkOrder = async () => {
    const answer = await confirm();
    if (answer) {
      setInFlight(true);

      try {
        const updatedOrder = await orderAPI.unlinkStorageOrder(order?.orderId!);
        setLoadedOrder(Order.createOrder(updatedOrder));
        enqueueSnackbar('Order unlinked successfully', { variant: 'success' });
      } catch {
        enqueueSnackbar('Failed unlinked order', { variant: 'error' });
      } finally {
        setInFlight(false);
      }
    }
  };

  const handleLinkOrder = async (orderId: number) => {
    setInFlight(true);

    try {
      const updatedOrder = await orderAPI.linkStorageOrder(order?.orderId!, orderId);
      setLoadedOrder(Order.createOrder(updatedOrder));
      enqueueSnackbar('Order linked successfully', { variant: 'success' });
    } catch {
      enqueueSnackbar('Failed linked order', { variant: 'error' });
    } finally {
      setInFlight(false);
    }
  };

  return (
    <>
      <SortableElement display="inline-flex" key={address.id} index={index} disabled>
        <DragHandle disabled disabledDraggable index={index} icon={StorageIcon} />
        {/*@ts-ignore because of ref*/}
        <Box className="detailsBox" ref={blockRef} minWidth={minWidth} position="relative">
          <Box display="flex">
            <Box className="addressSection" flexGrow="1">
              <BodyBigText display="inline">
                <b>{title}</b>
              </BodyBigText>
              <BodyBigText align="center">
                <b>Storage</b>
              </BodyBigText>
              <Box textAlign="center">
                <StorageIcon color="primary" />
              </Box>
              <BodyText>{price ? `Estimate $${formatCurrency(price, 2)}` : null}</BodyText>
              <BodyText>{getStorageDays()}</BodyText>
              <BodyText>{includeInQuote ? 'Included in total' : 'Not included in total'}</BodyText>
            </Box>
            <Box className="actionSection" display="flex" flexDirection="column" alignItems="center">
              {serviceType !== ServiceType.MOVING_AND_STORAGE && (
                <IconButton size="small">
                  <BluePenIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box className="detailsSection">
            {Boolean(linkedOrderId) ? (
              <Box mt={2} display="flex" alignItems="center">
                <Tooltip title="Unlink order">
                  <IconButton size="small" onClick={handleUnlinkOrder}>
                    <CancelIcon color="error" />
                  </IconButton>
                </Tooltip>
                <Link onClick={openLinkedOrder}>
                  {storageMoveStage === StorageMoveStage.IN ? 'View Delivery Order' : 'View Pickup Order'}
                </Link>
              </Box>
            ) : (
              <LinkOrderAutocomplete handleLinkOrder={handleLinkOrder} />
            )}
          </Box>
          {inFlight && <ElromcoCircularProgress />}
        </Box>
      </SortableElement>
      {!isDestination && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <DistanceBox disabled={disabled} display="flex" flexDirection="column" alignItems="center" alignSelf="center">
            {/*@ts-ignore need to replace it later*/}
            <IconBlueFab
              size="small"
              iconSize="16px"
              buttonSize="20px"
              disableBoxShadow
              onClick={onSetAddressIndex(index)}
              disabled={disabled}
            />
            <BlueRightArrorIcon />
            {distanceFromPrevious && <BodySmallText>{distanceFromPrevious}</BodySmallText>}
            {durationFromPrevious && storageMoveStage === StorageMoveStage.OUT && (
              <BodySmallText>{durationFromPrevious}</BodySmallText>
            )}
            <SharedIconButton color="primary" onClick={handleSwapStorage}>
              <SwapHorizIcon className={classes.swapStorageIcon} />
            </SharedIconButton>
          </DistanceBox>
        </Box>
      )}
      {renderDialog()}
    </>
  );
};

export default StorageBlock;
