import { MoveType } from '@elromcoinc/react-shared';

export const CUSTOMER_ID = 'id';
export const ACTIVE = 'active';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const DATE_BIRTH = 'dateBirth';
export const DATE_STARTED = 'dateStarted';
export const TERMINATION_DATE = 'dateTerminated';
export const DEPARTMENT = 'departmentId';
export const USER_TYPE = 'roleName';
export const DRIVER_LICENSE = 'driversLicense';
export const SOCIAL_SECURITY_NUMBER = 'ssn';
export const ADDITIONAL_RESPONSIBILITIES = 'additionalResponsibilities';
export const POSITION_TYPES = 'positionTypes';
export const ADDRESS = 'address';
export const ADDRESS_STREET = `${ADDRESS}.street1`;
export const APARTMENT_NUMBER = `${ADDRESS}.street2`;
export const ADDRESS_CITY = `city`;
export const ADDRESS_STATE = `state`;
export const POSTAL_CODE = `postalCode`;
export const ADDRESS_POSTAL_CODE = `${ADDRESS}.postalCode`;
export const ADDRESS_COUNTRY = `${ADDRESS}.country`;
export const PHONE_TYPE = 'phoneType';
export const PHONE = 'phone';
export const PHONE_EXTENSION = 'phoneExtension';
export const ADDITIONAL_PHONE_TYPE = 'phoneOtherType';
export const ADDITIONAL_PHONE = 'phoneOther';
export const EMPLOYEE_PROFILE = 'employeeProfile';
export const EMAIL_SERVER_SETTINGS = 'emailServerSettings';
export const EMPLOYEE_CONTACT_PHONE = 'contactPhone';
export const ADDITIONAL_PHONE_EXTENSION = 'phoneOtherExtension';
export const CONTACT_EMAIL = 'email';
export const CONTACT_NAME = 'emergencyContactName';
export const RELATIONSHIP = 'emergencyContactRelationship';
export const EMERGENCY_PHONE_TYPE = 'emergencyPhoneType';
export const EMERGENCY_PHONE = 'emergencyContactPhone';
export const EMERGENCY_PHONE_EXTENSION = 'emergencyPhoneExtension';
export const USE_OUT_GOING_EMAIL = 'useOutGoingEmail';
export const SMTP_SERVER = 'smtpServer';
export const SMTP_PORT = 'smtpPort';
export const SMTP_ENCRYPTED_WITH_SSL = 'smtpEncryptedWithSSL';
export const SMTP_ENCRYPTED_WITH_TSL = 'smtpEncryptedWithTLS';
export const SMTP_EMAIL_ADDRESS = 'smtpEmailAddress';
export const SMTP_EMAIL_PASSWORD = 'smtpEmailPassword';
export const USE_INCOMING_EMAIL = 'useIncomingEmail';
export const IMAP_SERVER = 'imapServer';
export const IMAP_PORT = 'imapPort';
export const IMAP_ENCRYPTED_WITH_SSL = 'imapEncryptedWithSSL';
export const IMAP_EMAIL_ADDRESS = 'imapEmailAddress';
export const IMAP_EMAIL_PASSWORD = 'imapEmailPassword';

export const USERNAME = 'username';
export const PASSWORD = 'password';
export const CONFIRM_PASSWORD = 'confirm-password';
export const GMAIL_ADDRESS = 'gmail';
export const CALENDARS = 'calendarTypes';
export const DEFAULT_EMPLOYEE_PHOTO = '/broken-image.jpg';
export const EMPLOYEE_ID = 'employeeId';
export const EMPLOYEE_PROFILE_PHOTO = `${EMPLOYEE_PROFILE}.photoFileId`;
export const EMPLOYEE_PROFILE_SIGNATURE = `${EMPLOYEE_PROFILE}.signatureFileId`;
export const EMPLOYEE_PROFILE_CONTACT_PHONE = `${EMPLOYEE_PROFILE}.${EMPLOYEE_CONTACT_PHONE}`;
export const EMPLOYEE_ACCOUNT_MESSAGE = 'message';
export const EMPLOYEE_PROFILE_MESSAGE = `${EMPLOYEE_PROFILE}.${EMPLOYEE_ACCOUNT_MESSAGE}`;
export const INCLUDE_IN_SALES_PERFORMANCE_STATS = 'includeInSalesPerformanceStats';
export const EMPLOYEE_COMMISSIONS = 'employeeCommissions';
export const AMOUNT = 'amount';
export const COMMISSION_SOURCE = 'commissionSource';
export const COMMISSION_TYPE = 'commissionType';
export const FUTURE_AMOUNT = 'futureAmount';
export const FUTURE_AMOUNT_START_DATE = 'futureAmountStartDate';
export const RATE = 'rate';
export const TYPE = 'type';
export const IS_ADD_FUTURE_AMOUNT = 'isAddFutureAmount';

const labels = {
  [ADDITIONAL_RESPONSIBILITIES]: 'Additional Responsibilities',
  [POSITION_TYPES]: 'Position',
  [FIRST_NAME]: 'First Name',
  [LAST_NAME]: 'Last Name',
  [DATE_BIRTH]: 'Date of Birth',
  [DATE_STARTED]: 'Date Started',
  [TERMINATION_DATE]: 'Termination Date',
  [DEPARTMENT]: 'Department',
  [USER_TYPE]: 'User Type',
  [DRIVER_LICENSE]: `Driver's License`,
  [SOCIAL_SECURITY_NUMBER]: 'Social Security Number',
  [ADDRESS_STREET]: 'Address',
  [APARTMENT_NUMBER]: 'Apt #',
  [ADDRESS_CITY]: 'City',
  [ADDRESS_STATE]: 'State',
  [POSTAL_CODE]: 'Zip Code',
  [ADDRESS_COUNTRY]: 'Country',
  [PHONE_TYPE]: 'Phone Type',
  [PHONE]: 'Phone',
  [EMPLOYEE_CONTACT_PHONE]: 'Contact phone',
  [PHONE_EXTENSION]: 'Ext',
  [ADDITIONAL_PHONE_TYPE]: 'Additional Phone Type',
  [ADDITIONAL_PHONE]: 'Additional Phone',
  [ADDITIONAL_PHONE_EXTENSION]: 'Ext',
  [CONTACT_EMAIL]: 'Contact Email',
  [CONTACT_NAME]: 'Contact Name',
  [RELATIONSHIP]: 'Relationship',
  [EMERGENCY_PHONE_TYPE]: 'Phone Type',
  [EMERGENCY_PHONE]: 'Phone',
  [EMERGENCY_PHONE_EXTENSION]: 'Ext',
  [USERNAME]: 'Username',
  [PASSWORD]: 'Password',
  [GMAIL_ADDRESS]: 'Gmail Address',
  [CALENDARS]: 'Calendars',
  [USE_OUT_GOING_EMAIL]: 'Use outgoing email',
  [SMTP_SERVER]: 'Outgoing Mail Server (SMTP)',
  [SMTP_PORT]: 'Port',
  [SMTP_ENCRYPTED_WITH_SSL]: 'This server requires an encrypted connection (SSL)',
  [SMTP_ENCRYPTED_WITH_TSL]: 'This server requires an encrypted connection (TSL)',
  [SMTP_EMAIL_ADDRESS]: 'Email Address (account)',
  [SMTP_EMAIL_PASSWORD]: 'Password',
  [USE_INCOMING_EMAIL]: 'Use incoming email',
  [IMAP_SERVER]: 'Incoming Mail Server (SMTP)',
  [IMAP_PORT]: 'Port',
  [IMAP_ENCRYPTED_WITH_SSL]: 'Email Address (account)',
  [IMAP_EMAIL_ADDRESS]: 'This server requires an encrypted connection (SSL)',
  [IMAP_EMAIL_PASSWORD]: 'Password',
  [INCLUDE_IN_SALES_PERFORMANCE_STATS]: 'Include user in sales performance dashboard',
  [AMOUNT]: 'Amount',
  [COMMISSION_SOURCE]: 'Price type',
  [FUTURE_AMOUNT]: 'New Amount',
  [FUTURE_AMOUNT_START_DATE]: 'Applied After',
};

const MAP_SERVICE_CONFIGS_MOVE_TYPE = {
  [MoveType.RESIDENTIAL]: 'serviceConfigsResidential',
  [MoveType.COMMERCIAL]: 'serviceConfigsCommercial',
  [MoveType.MILITARY]: 'serviceConfigsMilitary',
};

const MAP_TIME_SLOT_CONFIGS_MOVE_TYPE = {
  [MoveType.RESIDENTIAL]: 'timeSlotsResidential',
  [MoveType.COMMERCIAL]: 'timeSlotsCommercial',
  [MoveType.MILITARY]: 'timeSlotsMilitary',
};

const MAP_MOVE_TYPE_TO_TIME_SLOT_CONFIG = {
  [MAP_TIME_SLOT_CONFIGS_MOVE_TYPE[MoveType.RESIDENTIAL]]: MoveType.RESIDENTIAL,
  [MAP_TIME_SLOT_CONFIGS_MOVE_TYPE[MoveType.COMMERCIAL]]: MoveType.COMMERCIAL,
  [MAP_TIME_SLOT_CONFIGS_MOVE_TYPE[MoveType.MILITARY]]: MoveType.MILITARY,
};

const makeEmailServerSetting = (fieldName: string) => `${EMAIL_SERVER_SETTINGS}.${fieldName}`;

export {
  labels,
  MAP_SERVICE_CONFIGS_MOVE_TYPE,
  MAP_TIME_SLOT_CONFIGS_MOVE_TYPE,
  MAP_MOVE_TYPE_TO_TIME_SLOT_CONFIG,
  makeEmailServerSetting,
};
