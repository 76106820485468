import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const JobCompletedIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.10000,-0.10000)" fill="#000000" stroke="none">
          <path d="M1185 5044 c-202 -43 -379 -185 -471 -379 -67 -140 -64 -15 -64 -2105 0 -1864 0 -1896 20 -1979 49 -202 185 -368 375 -457 143 -66 86 -64 1515 -64 1429 0 1372 -2 1515 64 175 82 299 222 363 411 l27 80 0 1945 0 1945 -27 80 c-64 189 -188 329 -363 411 -144 67 -85 64 -1522 63 -1105 -1 -1308 -3 -1368 -15z m2715 -195 c124 -28 258 -131 313 -241 60 -119 57 6 57 -2040 0 -1353 -3 -1892 -11 -1930 -27 -124 -127 -257 -238 -317 -121 -64 -46 -61 -1461 -61 -1122 0 -1293 2 -1345 15 -175 46 -317 191 -354 363 -8 38 -11 568 -11 1930 0 1595 2 1885 15 1931 44 166 169 296 330 342 55 16 159 18 1360 18 863 0 1315 -3 1345 -10z" />
          <path d="M1374 4396 c-37 -37 -43 -70 -20 -115 8 -15 26 -32 41 -39 20 -9 303 -12 1166 -12 1239 0 1181 -3 1207 55 20 44 14 75 -22 111 l-34 34 -1152 0 -1152 0 -34 -34z" />
          <path d="M1387 3809 c-22 -13 -47 -61 -47 -93 0 -14 13 -40 29 -58 l29 -33 1162 0 1162 0 29 33 c16 18 29 44 29 58 0 33 -25 81 -49 94 -27 14 -2319 13 -2344 -1z" />
          <path d="M1403 3205 c-68 -29 -83 -115 -28 -162 l26 -23 1159 0 1159 0 26 23 c56 48 40 133 -30 163 -50 21 -2264 20 -2312 -1z" />
          <path d="M2525 2691 c-74 -45 -94 -97 -95 -243 0 -131 -25 -330 -47 -375 -10 -21 -33 -44 -62 -60 -121 -68 -150 -88 -160 -110 -7 -16 -11 -184 -11 -522 0 -574 -9 -523 107 -583 85 -44 129 -54 323 -74 275 -28 499 -29 710 -4 121 14 202 29 233 43 94 39 156 152 142 256 -4 33 -3 51 5 56 7 4 23 31 36 59 24 51 30 122 15 176 -5 18 0 37 21 70 50 80 47 184 -5 262 -16 23 -21 40 -16 53 11 29 10 105 -2 149 -12 43 -77 119 -123 144 -26 14 -76 18 -327 22 -274 5 -299 6 -318 24 -20 18 -21 29 -21 204 0 213 -11 269 -66 345 -38 52 -107 101 -164 117 -58 16 -141 12 -175 -9z m151 -199 c45 -36 49 -57 54 -283 l5 -216 30 -49 c33 -53 104 -107 163 -124 21 -5 153 -10 304 -10 229 0 269 -2 282 -16 24 -23 20 -44 -16 -81 -25 -26 -33 -42 -33 -73 0 -44 31 -84 71 -94 50 -13 52 -76 2 -91 -36 -11 -67 -45 -74 -80 -7 -38 16 -89 47 -105 34 -18 21 -60 -25 -86 -69 -39 -87 -108 -41 -162 57 -68 30 -84 -175 -107 -181 -20 -340 -22 -507 -5 -59 6 -160 15 -223 19 -64 5 -132 15 -152 24 l-38 16 0 415 0 414 54 31 c161 91 207 202 223 539 4 92 10 142 17 142 6 0 21 -8 32 -18z" />
          <path d="M1464 1991 c-48 -22 -101 -82 -114 -131 -6 -20 -10 -228 -10 -496 l0 -460 25 -51 c27 -54 78 -97 136 -113 46 -13 331 -13 366 1 51 19 91 68 103 124 7 35 10 218 8 541 -3 472 -4 490 -24 524 -11 19 -35 45 -54 57 -31 22 -43 23 -214 23 -154 0 -187 -3 -222 -19z m306 -621 l0 -441 -112 3 -113 3 -3 438 -2 437 115 0 115 0 0 -440z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
