import React from 'react';
import pt from 'prop-types';
import { BodyBigText, BodyText, formatPhoneNumber } from '@elromcoinc/react-shared';
import ItemBase from 'admin/components/Search/ItemBase';

const AccountItem = ({ onClick, item }) => {
  const { id, name, contactName, contactPhone, contactEmail } = item;
  return (
    <ItemBase onClick={onClick} key={id}>
      <BodyBigText>
        <b>{`Account #${id}`}</b>
      </BodyBigText>
      <BodyText>{name}</BodyText>
      <BodyText>{contactName}</BodyText>
      <BodyText noWrap>{formatPhoneNumber(contactPhone)}</BodyText>
      <BodyText>{contactEmail}</BodyText>
    </ItemBase>
  );
};

AccountItem.propTypes = {
  onClick: pt.func.isRequired,
  item: pt.shape({
    id: pt.number.isRequired,
    name: pt.string,
    contactName: pt.string.isRequired,
    contactPhone: pt.string.isRequired,
    contactEmail: pt.string.isRequired
  }).isRequired
};

export default AccountItem;
