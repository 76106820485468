import { useEffect } from 'react';

import { PhoneCarrierType, SmsCapability, phoneNumberYUP } from '@elromcoinc/react-shared';

import communicationAPI from 'admin/api/CommunicationsAPI';

const useUpdatePhoneNumberType = (phoneNumber, { shouldUpdate, setSmsCapability, setCarrierType }) => {
  const setSmsCapabilityCallback = (value) => setSmsCapability && setSmsCapability(value);
  const setCarrierTypeCallback = (value) => setCarrierType && setCarrierType(value);

  const identifyPhoneNumber = (phone) => {
    return communicationAPI
      .getPhoneNumberType(phone)
      .then((response) => {
        const { smsCapability, phoneCarrierType } = response.data;
        const carrierType = phoneCarrierType.toUpperCase();
        setSmsCapabilityCallback(smsCapability);
        setCarrierTypeCallback(carrierType);
      })
      .catch((error) => {
        if (error.response.status === 406) {
          setCarrierTypeCallback(PhoneCarrierType.INVALID);
        } else {
          setCarrierTypeCallback(PhoneCarrierType.NONE);
        }
        setSmsCapabilityCallback(SmsCapability.NONE);
      });
  };

  useEffect(() => {
    if (shouldUpdate() && phoneNumber && phoneNumberYUP('').isValidSync(phoneNumber)) {
      identifyPhoneNumber(phoneNumber);
    }
  }, [phoneNumber]);

  return identifyPhoneNumber;
};

export default useUpdatePhoneNumberType;
