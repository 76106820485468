import * as chartjs from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';

const getAverage = (arr: number[]) => {
  return arr.length ? arr.reduce((accumulator, item) => accumulator + item, 0) / arr.length : 0;
};

const useChartSalesPerformanceOptions = () => {
  const options: chartjs.ChartOptions = {
    layout: {
      padding: {
        left: 45,
        right: 30,
        top: 30,
        bottom: 35,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            suggestedMin: 50,
            fontSize: 16,
            stepSize: 1,
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontSize: 16,
          },
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: (context: Context) => (context?.dataset?.data?.[context.dataIndex] ?? 0) > 0,
        formatter: function (value, context: Context) {
          //@ts-ignore because custom prop
          return context?.dataset.labels[context.dataIndex];
        },
        color: (context: Context) => {
          const average = getAverage((context?.dataset?.data as number[]) ?? []!);
          return (context?.dataset?.data?.[context.dataIndex] ?? 0) < average ? '#000' : '#fff';
        },
        anchor: (context: Context) => {
          const average = getAverage((context?.dataset?.data as number[]) ?? []!);
          return (context?.dataset?.data?.[context.dataIndex] ?? 0) < average ? 'end' : 'start';
        },
        align: 'end',
      },
    },
  };
  return options;
};

export { useChartSalesPerformanceOptions };
