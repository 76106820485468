import React, { useEffect, useState } from 'react';

import { BodyBigText } from '@elromcoinc/react-shared';
import { Accordion, AccordionDetails, AccordionSummary, Divider, LinearProgress, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import notificationsAPI from 'admin/api/notificationsAPI';
import {
  fetchNotificationSettings,
  getNotificationIsFetchingSettings,
  getNotificationOpenPanels,
  getNotificationSettings,
  setNotificationOpenPanels,
} from 'admin/autodux/NotificationAutodux';

import IconCheckList from './IconCheckList';
import IconSwitchList from './IconSwitchList';

const categories = ['Customer', 'Employee', 'System'];

const useStyles = makeStyles((theme) => ({
  settings: {
    zIndex: theme.zIndex.overlay,
    position: 'absolute',
    transition: theme.transitions.create(['top'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    overflowY: 'scroll',
    top: theme.mixins.toolbar.minHeight,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.mixins.toolbar.sm.minHeight}px)`,
      overflowY: 'scroll',
      top: theme.mixins.toolbar.sm.minHeight,
    },
    backgroundColor: theme.palette.background.default,
  },
  openSettings: {},
  closedSettings: {
    top: `calc(${theme.mixins.toolbar.minHeight * 2}px - 100vh)`,
    [theme.breakpoints.up('sm')]: {
      top: `calc(${theme.mixins.toolbar.sm.minHeight * 2}px - 100vh)`,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  exPanelRootExpanded: {
    '&.Mui-expanded': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  exPanelDetails: {
    padding: 0,
  },
  exPanelSummaryFirstElement: {
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: '48px',
    },
  },
  exPanelSummary: {
    alignItems: 'center',
    '&.Mui-expanded': {
      minHeight: 0,
    },
  },
  exPanelSummaryContent: {
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  divider: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function NotiSettingsDrawer(props) {
  const dispatch = useDispatch();
  const { openSettings, children } = props;
  const classes = useStyles();
  const currentUser = useSelector((s) => s.auth.user);
  const [notificationList, setNotificationList] = useState([]);
  const [inFlight, setInFlight] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const openPanels = useSelector(getNotificationOpenPanels);
  const isFetchingSettings = useSelector(getNotificationIsFetchingSettings);
  const settings = useSelector(getNotificationSettings);

  const toggleAllChecks = (status) => {
    const result = notificationList.map((category) => ({
      ...category,
      data: category.data.map((item) => ({
        ...item,
        smsEnabled: status,
        emailEnabled: status,
        inAppEnabled: status,
      })),
    }));

    if (status) {
      notificationsAPI.setNotificationSubscriptionsEnabled();
    } else {
      notificationsAPI.setNotificationSubscriptionsDisabled();
    }

    setNotificationList(result);
  };

  const checkOnOffAll = () => {
    setCheckedAll((state) => {
      toggleAllChecks(!state);

      return !state;
    });
  };

  useEffect(() => {
    if (currentUser.id) {
      dispatch(fetchNotificationSettings(currentUser.id));
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (currentUser.id) {
      const selectedSettings = settings.filter(({ selected }) => selected);
      const categorizedNotifications = categories.map((name) => ({
        category: name,
        data: selectedSettings.filter(({ category }) => category.toLowerCase() === name.toLowerCase()),
      }));
      setCheckedAll(selectedSettings.every(({ inAppEnabled }) => inAppEnabled));
      setNotificationList(categorizedNotifications.filter(({ data }) => data.length));
    }
  }, [currentUser.id, settings]);

  const handleChange = (category) => (event, isExpanded) => {
    const newOpenPanels = isExpanded ? [...openPanels, category] : openPanels.filter((item) => item !== category);
    dispatch(setNotificationOpenPanels(newOpenPanels));
  };

  const updateDataList = (id, statusName) => {
    const notification = [].concat(...notificationList.map(({ data }) => data)).find((item) => item.id === id);
    notification[statusName] = !notification[statusName];

    if (statusName === 'inAppEnabled' && !notification[statusName]) {
      notification.smsEnabled = false;
      notification.emailEnabled = false;
    }

    const { inAppEnabled, emailEnabled, smsEnabled, eventTypeId, recipientId } = notification;

    notificationsAPI.updateNotificationSubscriptions({
      inAppEnabled,
      emailEnabled,
      smsEnabled,
      eventTypeId,
      recipientId,
    });
    setNotificationList([...notificationList]);
  };

  const updateAllDateList = (statusName, checked, categoryName) => {
    setInFlight(true);
    const isAppOff = statusName === 'inAppEnabled' && !checked;
    notificationsAPI
      .updateAllNotifiSubscriptionsByCategory(
        isAppOff
          ? {
              inAppEnabled: false,
              emailEnabled: false,
              smsEnabled: false,
            }
          : {
              [statusName]: checked,
            },
        categoryName.toUpperCase(),
      )
      .then(() => {
        setNotificationList((prevState) =>
          prevState.map((notifiList) =>
            notifiList.category === categoryName
              ? {
                  ...notifiList,
                  data: notifiList.data.map((it) =>
                    isAppOff
                      ? {
                          ...it,
                          inAppEnabled: false,
                          emailEnabled: false,
                          smsEnabled: false,
                        }
                      : {
                          ...it,
                          [statusName]: checked,
                        },
                  ),
                }
              : notifiList,
          ),
        );
        setInFlight(false);
      })
      .catch(() => {
        setInFlight(false);
      });
  };

  return (
    <div className={classNames(classes.settings, openSettings ? classes.openSettings : classes.closedSettings)}>
      <Accordion expanded classes={{ root: classes.exPanelRootExpanded }}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{ expanded: classes.exPanelSummaryFirstElement, content: classes.exPanelSummaryContent }}
        >
          <BodyBigText>Settings</BodyBigText>
          {children}
        </AccordionSummary>
        <Divider className={classes.divider} />
        <AccordionDetails className={classes.exPanelDetails}>
          <IconSwitchList title="Settings" onChange={checkOnOffAll} value={checkedAll} />
        </AccordionDetails>
        {isFetchingSettings && <LinearProgress />}
      </Accordion>
      {notificationList.map((item, index) => (
        <Accordion
          key={item.category}
          expanded={openPanels.indexOf(item.category) !== -1}
          onChange={handleChange(item.category)}
          classes={{ root: classes.exPanelRootExpanded }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            classes={{ expanded: classes.exPanelSummary, content: classes.exPanelSummaryContent }}
          >
            <BodyBigText>{item.category}</BodyBigText>
          </AccordionSummary>
          <Divider className={classes.divider} />
          <AccordionDetails className={classes.exPanelDetails}>
            <IconCheckList
              title={item.category}
              dataList={item.data}
              updateDataList={updateDataList}
              updateAllDateList={updateAllDateList}
              categoryId={index}
              inFlight={inFlight}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

NotiSettingsDrawer.propTypes = {
  openSettings: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
