import { useEffect, useMemo, useState } from 'react';

import { ActivitySourceType, MoveTypeName, SettingNames, statusesById } from '@elromcoinc/react-shared';
import { useSelector } from 'react-redux';

import { flagsApi } from 'admin/api';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { dateTypes, inventoryFilter } from 'admin/components/Orders/config/OrdersConstants';
import {
  ACCOUNT_TYPE,
  DATE_TYPE,
  ESTIMATE_TYPE,
  FLAGS,
  FROM_STATE,
  INVENTORY,
  MOVE_SIZE,
  MOVE_TYPE,
  SALES_PERSON,
  SERVICE_TYPE,
  SOURCE,
  STATUS,
  TO_STATE,
} from 'admin/components/Orders/config/OrdersLabels';
import { AccountTypeName } from 'admin/constants/AccountType';
import { EstimateTypeName } from 'admin/constants/EstimateType';
import GeneralServiceTypes from 'admin/constants/GeneralServiceTypes';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';
import useSettings from 'admin/hooks/useSettings';
import { getAllLeadSources } from 'admin/selectors';
import { sortFilterInputOptions } from 'admin/utils/sortFilterInputOptions';
import states from 'common/constants/States.json';

const {
  GENERAL_SERVICES,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  LEAD_SOURCE,
  UNIT_SIZE_SPEC,
} = SettingNames;

const moveTypeKeys = [ACCEPT_RESIDENTIAL_ORDERS, ACCEPT_COMMERCIAL_ORDERS, ACCEPT_MILITARY_ORDERS];

const settingQueries = [GENERAL_SERVICES, LEAD_SOURCE, UNIT_SIZE_SPEC, ...moveTypeKeys];

const accountTypeOptions = Object.entries(AccountTypeName).sort(sortFilterInputOptions);
const statusOptions = Object.values(statusesById)
  .map((item) => [item.id, item.label])
  .sort(sortFilterInputOptions);
const estimateTypeOptions = Object.entries(EstimateTypeName).sort(sortFilterInputOptions);
const statesOptions = Object.keys(states)
  .map((it) => [it, states[it]])
  .sort(sortFilterInputOptions);
const dateTypeOptions = Object.entries(dateTypes).sort(sortFilterInputOptions);
const inventoryFilterOptions = Object.entries(inventoryFilter).sort(sortFilterInputOptions);

const sortFlagsDataBySequence = (data) => data.sort((a, b) => a.sequenceOrder - b.sequenceOrder);

export const useOrdersFiltersOptions = () => {
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const [flagsOptions, setFlagsOptions] = useState([]);
  const settings = useSettings(...settingQueries);
  const currentSettings = settings || {};
  const sourceOptions = getAllLeadSources(settings).sort(sortFilterInputOptions);
  const moveTypeOptions = Object.entries(MoveTypeName)
    ?.filter((item, index) => currentSettings?.[moveTypeKeys[index]])
    .sort(sortFilterInputOptions);

  const moveSizeOptions = useMemo(() => {
    const result = [];
    ((settings && settings[UNIT_SIZE_SPEC]) || [])
      .filter((it) => it.moveUnitType === 'SITE')
      .forEach((item) => {
        if (!result.find((ums) => ums.name === item.name)) {
          result.push(item);
        }
      });
    return result.map((item) => [item.name, item.name]);
  }, [settings]);

  useFetchManagers();

  useEffect(() => {
    flagsApi.getFlagsByActivitySource(ActivitySourceType.ORDER).then((data) => {
      const uniqueByNameSortedFlags = [];
      sortFlagsDataBySequence(data).forEach((item) => {
        if (!uniqueByNameSortedFlags.find((usf) => usf.name === item.name)) {
          uniqueByNameSortedFlags.push(item);
        }
      });
      setFlagsOptions(uniqueByNameSortedFlags.map((item) => [item.name, item.name]).sort(sortFilterInputOptions));
    });
  }, []);

  useEffect(() => {
    setServiceTypeOptions(
      (currentSettings[GENERAL_SERVICES] || [])
        .filter((settings) => settings.serviceType !== GeneralServiceTypes.JUNK_REMOVAL)
        .map((it) => [it.name, it.name])
        .sort(sortFilterInputOptions),
    );
  }, [currentSettings[GENERAL_SERVICES]]);

  const salesPersonOptions = [
    ['null', 'Unassigned'],
    ...useSelector(getManagerList)
      .toJS()
      .map((item) => [item.id, `${item.firstName} ${item.lastName}`])
      .sort(sortFilterInputOptions),
  ];

  return {
    [ACCOUNT_TYPE]: accountTypeOptions,
    [STATUS]: statusOptions,
    [MOVE_TYPE]: moveTypeOptions,
    [MOVE_SIZE]: moveSizeOptions,
    [ESTIMATE_TYPE]: estimateTypeOptions,
    [FROM_STATE]: statesOptions,
    [TO_STATE]: statesOptions,
    [DATE_TYPE]: dateTypeOptions,
    [SERVICE_TYPE]: serviceTypeOptions,
    [SALES_PERSON]: salesPersonOptions,
    [SOURCE]: sourceOptions,
    [FLAGS]: flagsOptions,
    [INVENTORY]: inventoryFilterOptions,
  };
};
