import React, { ChangeEvent, FC } from 'react';

import { Button, TextInput } from '@elromcoinc/react-shared';
import { Box, InputAdornment, Tooltip, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

// import { SmsTemplatePopover } from 'admin/components/Communication/MessageTab/Messages'; POST MVP

const useStyles = makeStyles((theme) => ({
  messagesFooter: {
    flex: '0 1 auto',
    marginBottom: '12px',
  },
  textMessage: {
    '& > .MuiFilledInput-root': {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  linkButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

interface MessageInputProps {
  text: string;
  isLoadingSms?: boolean;
  isLoadingMessage?: boolean;
  sendMsg?: () => void;
  sendSms?: () => void;
  setText?: (text: string) => void;
  isCanSendSMS?: boolean;
  errorMessage?: string | null;
  disabled?: boolean;
  minRows?: number;
  displayButtonsInsideInputs?: boolean;
}

export const MessageInput: FC<MessageInputProps> = ({
  text,
  isLoadingSms = false,
  isLoadingMessage = false,
  sendMsg = null,
  sendSms = null,
  setText = null,
  isCanSendSMS = false,
  errorMessage = null,
  disabled = false,
  minRows = 1,
  displayButtonsInsideInputs = true,
  children = null,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangeMessage = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setText?.(value as string);
  };

  const sendMessageButtons = (
    <Box display="flex" justifyContent="space-between">
      {children}
      <Box display="flex" flexDirection="column" mb={displayButtonsInsideInputs ? 3.5 : 0}>
        <Box>
          <Tooltip title={errorMessage || 'Send SMS'}>
            <Box component="span" className={classes.pointer}>
              <Button
                color="primary"
                variant="text"
                size="small"
                disabled={isLoadingSms || !isCanSendSMS || isLoadingMessage}
                onClick={sendSms!}
                loading={isLoadingSms}
              >
                {isMobile ? 'SMS' : 'SEND AS SMS'}
              </Button>
            </Box>
          </Tooltip>
          {/*<SmsTemplatePopover disabled={disabled} />*/}
        </Box>
        <Button
          onClick={sendMsg!}
          variant="text"
          color="primary"
          size="small"
          loading={isLoadingMessage}
          disabled={isLoadingMessage || isLoadingSms || disabled}
        >
          {isMobile ? 'MESSAGE' : 'SEND AS MESSAGE'}
        </Button>
      </Box>
    </Box>
  );

  const inputProps = displayButtonsInsideInputs
    ? {
        endAdornment: <InputAdornment position="start">{sendMessageButtons}</InputAdornment>,
      }
    : {};

  return (
    <Box mx={2} className={classes.messagesFooter}>
      <TextInput
        fullWidth
        skipControl
        className={classes.textMessage}
        label="Type Message"
        maxRows={isMobile ? 10 : 15}
        minRows={minRows}
        multiline
        value={text}
        onChange={handleChangeMessage}
        InputProps={inputProps}
      />
      {!displayButtonsInsideInputs && sendMessageButtons}
    </Box>
  );
};
