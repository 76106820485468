import React, { FC } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { Timeline } from '@material-ui/lab';
import { List } from 'immutable';

import { ActivityLogTimeline } from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityLogTimeline';
import ActivityType from 'admin/constants/ActivityType';
import OrderLog from 'admin/entities/OrderLog';

import ActivityItem from './ActivityItem';

const useStyles = makeStyles((theme) => ({
  listLog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div:last-of-type': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  listHeader: {
    width: '100%',
    padding: '4px 16px',
    backgroundColor: theme.palette.grey[200],
    boxSizing: 'border-box',
  },
  timeLineRoot: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));

interface ActivityListProps {
  items: List<OrderLog>;
  title: string;
  emptyLabel: string;
  onEdit: (id: number, activityType: ActivityType) => void;
}

const ActivityList: FC<ActivityListProps> = ({ items, title, emptyLabel, onEdit }) => {
  const classes = useStyles();
  const isEmpty = items.size === 0;

  return (
    <Box className={classes.listLog} mb={2}>
      <Box className={classes.listHeader}>
        <Box display="flex">
          <BodyText>
            <b>{title}</b>
          </BodyText>
        </Box>
      </Box>
      {isEmpty ? (
        <ActivityItem label={emptyLabel} />
      ) : (
        <Box width="100%">
          <Timeline classes={{ root: classes.timeLineRoot }}>
            {items.map((item) => (
              <ActivityLogTimeline key={item.id} item={item} onEdit={onEdit} />
            ))}
          </Timeline>
        </Box>
      )}
    </Box>
  );
};

export default ActivityList;
