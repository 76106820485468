// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import SettingsApi from 'admin/api/SettingsAPI';

const {
  reducer,
  actions: { setSettings, setInFlight: setInFlightSettings },
  selectors: { getData: getSettings, getInFlight: getInFlightSettings },
} = autodux({
  slice: 'settings',
  initial: {
    data: {},
    inFlight: false,
  },
  actions: {
    setSettings: (state: any, data: any) => ({
      ...state,
      data: { ...state.data, ...data },
      inFlight: false,
    }),
  },
});

export function fetchSettingsByQueries({ companyId = 1, queries }: { companyId: number; queries: string[] }) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setInFlightSettings(true));

    return SettingsApi.getDataFromQuery({ companyId, queries })
      .then((result) => {
        dispatch(setSettings(result));
      })
      .catch((error) => {
        dispatch(setInFlightSettings(false));
      });
  };
}

export { getSettings, getInFlightSettings };

export default reducer;
