import queryString from 'query-string';

import storageUtil from 'admin/utils/storageUtil';

export const getUrl = (url: string, authJwtToken: string, orderId: number, redirectUrl: string, isAdmin = false) =>
  queryString.stringifyUrl({
    url,
    query: {
      authJwtToken,
      orderId,
      companyId: storageUtil.getTenantId(),
      redirectUrl,
      isAdmin,
    },
  });
