import React from 'react';

import {
  BodyText,
  CurrencyInput,
  IconButton,
  Modal,
  PaymentAdjustmentType,
  PriceAdjustmentType,
  currencyInputAdapter,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { usePaymentAdjustment } from 'admin/components/OrderWindow/hooks';

const useStyles = makeStyles(({ spacing }) => ({
  withoutBorder: {
    borderBottom: 'none',
  },
  smallInput: {
    '& input': {
      padding: `${spacing(0, 0.5)}!important`,
      height: spacing(3),
    },
  },
}));

interface AdditionalChargeProps {
  types: PaymentAdjustmentType[];
  onClose: () => void;
  mainType: PaymentAdjustmentType;
}

const names = {
  [PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE]: 'Additional Weight Charge',
  [PaymentAdjustmentType.CUSTOM_PRICE_CHARGE]: 'Price Charge',
  [PaymentAdjustmentType.TIP]: 'Tip',
  [PaymentAdjustmentType.CASH_TIP]: 'Cash Tip',
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'Credit Card Fee',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'Cash Discount',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'Custom Fee',
};

export const AdditionalFeeCharge = ({ mainType, types, onClose }: AdditionalChargeProps) => {
  const classes = useStyles();
  const { isCompleted } = useOrderClosingContext();
  const {
    paymentAdjustments,
    deletePaymentAdjustment,
    renderDeleteDialog,
    add: addAdditionalWeight,
    save: saveAdditionalWeight,
    onChangeProperties,
    inFlightUpdate,
    inFlight,
  } = usePaymentAdjustment({ adjustmentsToManage: types });

  const total = paymentAdjustments.reduce((acc, pa) => acc + (pa.total ?? 0), 0);

  const disabled = isCompleted || inFlight || inFlightUpdate;

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
    },
  ];

  return (
    <Modal title={`Manage ${names[mainType]}`} open actions={actions} onClose={onClose}>
      <Table>
        <TableBody>
          {paymentAdjustments.map((pa) => (
            <TableRow key={pa.id}>
              <TableCell size="small" className={classes.withoutBorder}>
                <Box display="flex" alignItems="center">
                  <Box width={80}>
                    <BodyText>{`${pa.name}:`}</BodyText>
                  </Box>
                  <Box width={80}>
                    <CurrencyInput
                      className={classes.smallInput}
                      disabled={disabled}
                      size="small"
                      hiddenLabel
                      name="rate"
                      value={pa?.quote}
                      onChange={currencyInputAdapter(onChangeProperties(pa.id))}
                      onBlur={saveAdditionalWeight(pa.id)}
                    />
                  </Box>
                  {pa?.rate?.type === PriceAdjustmentType.PERCENT && (
                    <Box width={100} ml={1}>
                      <CurrencyInput
                        amountType={PriceAdjustmentType.PERCENT}
                        className={classes.smallInput}
                        disabled={disabled}
                        size="small"
                        hiddenLabel
                        name="additionalWeight"
                        value={pa?.rate?.amount}
                        onChange={currencyInputAdapter(onChangeProperties(pa.id))}
                        onBlur={saveAdditionalWeight(pa.id)}
                      />
                    </Box>
                  )}
                </Box>
              </TableCell>
              <TableCell size="small" className={classes.withoutBorder}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <BodyText>{`$${formatCurrency(pa.total ?? 0)}`}</BodyText>
                  <Box mr={-3}>
                    <IconButton
                      color="secondary"
                      className="print-hide"
                      disabled={disabled}
                      onClick={deletePaymentAdjustment(pa)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
          ))}
          {!paymentAdjustments.isEmpty() && (
            <TableRow>
              <TableCell size="small" className={classes.withoutBorder} />
              <TableCell size="small" className={classes.withoutBorder}>
                <BodyText>${formatCurrency(total)}</BodyText>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder} colSpan={2}>
              <IconButton
                color="primary"
                className="print-hide"
                disabled={disabled}
                onClick={addAdditionalWeight({
                  paymentAdjustmentType: mainType,
                  name: names[mainType] ?? 'Additional Charge',
                  quote: 0,
                  amount: 0,
                })}
              >
                <AddIcon />
              </IconButton>
              {renderDeleteDialog()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Modal>
  );
};
