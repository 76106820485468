const PHONE = 'PHONE';
const CUSTOM = 'CUSTOM';
const EMAIL = 'EMAIL';

const ManualActivityType = {
  PHONE,
  CUSTOM,
  EMAIL
};

const ManualActivityTypeName = {
  [PHONE]: 'Call',
  [CUSTOM]: 'Custom',
  [EMAIL]: 'Email'
};

ManualActivityType.getName = type => ManualActivityTypeName[type] || null;
ManualActivityType.options = [
  [PHONE, ManualActivityTypeName[PHONE]],
  [CUSTOM, ManualActivityTypeName[CUSTOM]],
  [EMAIL, ManualActivityTypeName[EMAIL]]
];

export default Object.freeze(ManualActivityType);
