import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import { CONTACT_INFO } from 'admin/constants/FieldNames';
import { EventProcessor } from './EventProcessor';

const PROPERTY_NAME = 'Customer Info';

const customerInfo: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath !== CONTACT_INFO && !oldOrder.contactInfo.equals(newOrder.contactInfo)) {
    return null;
  }
  const oldContactInfo = oldOrder.contactInfo;
  const newContactInfo = newOrder.contactInfo;

  const changes = [];

  if (newContactInfo.fullName() !== oldContactInfo.fullName()) {
    if (newContactInfo.firstName !== oldContactInfo.firstName && newContactInfo.lastName !== oldContactInfo.lastName) {
      changes.push(
        createUpdatedValueDto(oldContactInfo.fullName(), newContactInfo.fullName(), `${PROPERTY_NAME} name`),
      );
    } else {
      if (newContactInfo.firstName !== oldContactInfo.firstName) {
        changes.push(
          createUpdatedValueDto(oldContactInfo.firstName, newContactInfo.firstName, `${PROPERTY_NAME} first name`),
        );
      }
      if (newContactInfo.lastName !== oldContactInfo.lastName) {
        changes.push(
          createUpdatedValueDto(oldContactInfo.lastName, newContactInfo.lastName, `${PROPERTY_NAME} last name`),
        );
      }
    }
  }

  if (newContactInfo.bestWayToContact !== oldContactInfo.bestWayToContact) {
    changes.push(
      createUpdatedValueDto(
        oldContactInfo.bestWayToContact,
        newContactInfo.bestWayToContact,
        `${PROPERTY_NAME} way to contact`,
      ),
    );
  }

  if (newContactInfo.bestTimeToContact !== oldContactInfo.bestTimeToContact) {
    changes.push(
      createUpdatedValueDto(
        oldContactInfo.bestTimeToContact,
        newContactInfo.bestTimeToContact,
        `${PROPERTY_NAME} time to contact`,
      ),
    );
  }

  return changes;
};

export default customerInfo;
