enum TimeModsType {
  SETTINGS,
  CUSTOM,
  TBD,
}

enum TimeModsNameType {
  SETTINGS = 'DEFAULT_SETTINGS',
  CUSTOM = 'CUSTOM',
  TBD = 'TBD',
}

export { TimeModsType, TimeModsNameType };
