import React from 'react';

import { Checkbox, CreditCardInput, ExpirationInput, TextInput } from '@elromcoinc/react-shared';
import { Grid } from '@material-ui/core';

import { CARDHOLDER_NAME, CARD_NUMBER, CCV_CODE, EXPIRATION, MAKE_DEFAULT, POSTAL_CODE, labels } from './Common';

const CreditCardForm = () => {
  return (
    <>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          autocomplete="cc-name"
          label={labels[CARDHOLDER_NAME]}
          name={CARDHOLDER_NAME}
          inputProps={{
            autocomplete: CARDHOLDER_NAME,
            autocorrect: 'off',
            spellcheck: false,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CreditCardInput
          /** @ts-ignore */
          fullWidth
          label={labels[CARD_NUMBER]}
          name={CARD_NUMBER}
          placeholder="1234 5678 9012 3456"
          inputProps={{
            inputmode: 'numeric',
            autocomplete: CARD_NUMBER,
            autocorrect: 'off',
            spellcheck: false,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <ExpirationInput
          fullWidth
          label={labels[EXPIRATION]}
          placeholder="MM/YY"
          name={EXPIRATION}
          inputProps={{
            inputmode: 'numeric',
            autocomplete: EXPIRATION,
            autocorrect: 'off',
            spellcheck: false,
            maxlength: 5,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput
          fullWidth
          label={labels[CCV_CODE]}
          name={CCV_CODE}
          inputProps={{
            inputmode: 'numeric',
            autocomplete: CCV_CODE,
            autocorrect: 'off',
            spellcheck: false,
            maxlength: 4,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextInput name={POSTAL_CODE} fullWidth label={labels[POSTAL_CODE]} />
      </Grid>
      <Grid item xs={12}>
        <Checkbox color="primary" name={MAKE_DEFAULT} label={labels[MAKE_DEFAULT]} />
      </Grid>
    </>
  );
};

export default CreditCardForm;
