import {
  ActivitySourceType,
  InvoiceDto,
  Order,
  PriceAdjustment,
  PriceAdjustmentType,
  Quote,
} from '@elromcoinc/react-shared';

interface OrderPartInvoiceArguments {
  order: Order;
  quote?: Quote;
  includeTax?: boolean;
  withActivitySource?: boolean;
  isClosing?: boolean;
}

export const getOrderPartInvoice = ({
  order,
  quote,
  includeTax = false,
  withActivitySource = true,
  isClosing = false,
}: OrderPartInvoiceArguments): Partial<InvoiceDto> => {
  const activitySources: InvoiceDto['activitySources'] = [];

  if (order && withActivitySource) {
    activitySources.push({
      activitySource: ActivitySourceType.ORDER,
      referencedEntityId: order.orderId,
    });

    if (quote) {
      activitySources.push({
        activitySource: ActivitySourceType.SERVICE,
        referencedEntityId: quote.originalId,
      });
    }

    if (isClosing && order?.closingOrderDetail?.id) {
      activitySources.push({
        activitySource: ActivitySourceType.ORDER_CLOSING,
        referencedEntityId: order.closingOrderDetail.id,
      });
    }
  }

  const result = order
    ? {
        orderId: order.orderId,
        orderNumber: order.orderNumber,
        branchId: order.branchId,
        contactInfo: {
          firstName: order.contactInfo.firstName,
          lastName: order.contactInfo.lastName,
          primaryPhone: order.contactInfo.primaryPhone.number,
          email: order.contactInfo.email,
        },
        address: order.fromAddress || order.toAddress,
        discount: quote?.discount ?? 0,
        tax: PriceAdjustment.createPriceAdjustment(
          includeTax
            ? {
                type: PriceAdjustmentType.ABSOLUTE,
                amount: quote?.taxInfo?.totalTaxes ?? order?.getTaxesTotal(0, true)?.maxValue ?? 0,
              }
            : { type: PriceAdjustmentType.PERCENT, amount: 0 },
        ),
      }
    : {};

  return order && withActivitySource ? { ...result, activitySources } : result;
};
