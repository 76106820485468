import { getMoveSizeMainDesc, getMoveSizeSubDescAsArray } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto, { UpdatedValueDto } from './UpdatedValueDto';

const getListOfRoomNames = (sizeDescription: string): List<string> => List(getMoveSizeSubDescAsArray(sizeDescription));

const moveSizeChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('sizeDescription') > -1) {
    const oldSizeDescription = oldOrder.getValueIn(fieldPath);
    const newSizeDescription = newOrder.getValueIn(fieldPath);
    const oldSiteName = getMoveSizeMainDesc(oldSizeDescription);
    const newSiteName = getMoveSizeMainDesc(newSizeDescription);
    const oldRooms = getListOfRoomNames(oldSizeDescription as string);
    const newRooms = getListOfRoomNames(newSizeDescription as string);
    const addedRooms = newRooms.filter((room) => !oldRooms.includes(room)).join(', ');
    const deletedRooms = oldRooms.filter((room) => !newRooms.includes(room)).join(', ');
    const result: UpdatedValueDto[] = [];
    if (oldSiteName !== newSiteName) {
      result.push(createUpdatedValueDto(oldSiteName, newSiteName, 'Size of Move'));
    }
    if (addedRooms) {
      result.push(createUpdatedValueDto('', addedRooms, 'Rooms'));
    }
    if (deletedRooms) {
      result.push(createUpdatedValueDto(deletedRooms, '', 'Rooms'));
    }
    return result;
  }
  return null;
};

export default moveSizeChange;
