import { TaskTypeName } from 'common-types/Task';

export const DUE = 'due';
export const SKIP_DATE_TIME_VALIDATION = 'skipDateTime';
export const SKIP_DATE_TIME_VALIDATION_YUP = `$${SKIP_DATE_TIME_VALIDATION}`;
export const ORDER_ID = 'sourceId';
export const EMPLOYEE_ID = 'employeeId';
export const SUBJECT = 'subject';
export const DATE = 'dueDate';
export const TIME = 'dueTime';
export const REMIND_ME = 'remindMe';
export const REMIND_TIME = 'remindSecondsBeforeDue';
export const PRIORITY = 'priority';
export const NOTES = 'notes';
export const TASK_TYPE = 'taskType';
export const ACTIVITY_SOURCE_PROPERTY = 'activitySource';
export const taskTypeOptions = Object.entries(TaskTypeName);

export const labels = {
  [ORDER_ID]: 'Order/Account',
  [EMPLOYEE_ID]: 'Sales Person',
  [SUBJECT]: 'Subject',
  [DATE]: 'Date',
  [TIME]: 'Time',
  [REMIND_ME]: 'Remind Me',
  [REMIND_TIME]: 'Remind Time',
  [PRIORITY]: 'Priority',
  [NOTES]: 'Notes',
  [TASK_TYPE]: 'Task Type',
};
