import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import orderApi from 'admin/api/OrderAPI';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import ConfirmationDialog from 'admin/components/modal/ConfirmationDialog';
import { LabelFab } from 'common/components/Widgets';

function CloneButton(props) {
  const dispatch = useDispatch();
  const { orderId, disabled, hasInventory, component: Component, onClickComponent, ...rest } = props;
  const [showDialog, setShowDialog] = useState(false);

  const handleClone = (flag) => () => {
    orderApi.clone(orderId, flag).then((orderDetails) => dispatch(openOrder(orderDetails.orderId)));
    setShowDialog(false);
  };

  const handleShowDialog = () => {
    setShowDialog(true);
    onClickComponent && onClickComponent();
  };

  function renderActions() {
    return (
      <>
        <Button color="primary" onClick={() => setShowDialog(false)}>
          Cancel
        </Button>
        {hasInventory && (
          <>
            <Button color="primary" onClick={handleClone(true)} variant="text">
              Include Inventory
            </Button>
            <Button color="primary" onClick={handleClone(false)} variant="contained">
              Clone without Inventory
            </Button>
          </>
        )}
        {!hasInventory && (
          <Button color="primary" onClick={handleClone(false)} variant="contained">
            Clone
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <Component
        {...rest}
        variant="extended"
        backgroundColor="transparent"
        onClick={handleShowDialog}
        onTouchStart={handleShowDialog}
        disabled={disabled}
      >
        Clone
      </Component>
      {showDialog && (
        <ConfirmationDialog title="Cloning..." actions={renderActions()}>
          {hasInventory ? 'Do you want to include inventory data?' : 'Are you sure you want to clone this order?'}
        </ConfirmationDialog>
      )}
    </>
  );
}

CloneButton.propTypes = {
  orderId: PropTypes.number,
  disabled: PropTypes.bool.isRequired,
  hasInventory: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  component: PropTypes.element,
  onClickComponent: PropTypes.func,
};

CloneButton.defaultProps = {
  orderId: null,
  component: LabelFab,
  onClickComponent: null,
};

export default withRouter(CloneButton);
