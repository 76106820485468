import React from 'react';

import { Button } from '@elromcoinc/react-shared';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#b2dfdb',
  },
  barColorPrimary: {
    backgroundColor: '#00695c',
  },
})(LinearProgress);

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    right: 8px;
    top: 8px;
    color: black;
  }
`;

const StyledDialog = styled(Dialog)`
  && {
    .MuiDialog-paper {
      ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
      width: ${(props) => props.size};
      @media (max-width: 600px) {
        margin: 0;
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
      }
    }
  }
`;

const StyledDialogTitle = styled(MuiDialogTitle)`
  && {
    margin: 0;
    background-color: ${(props) => props.theme.palette.grey[100]};
    p {
      font-size: 24px;
      font-weight: bold;
    }
  }
`;

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <StyledDialogTitle disableTypography {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      ) : null}
    </StyledDialogTitle>
  );
};

const DialogContent = styled(MuiDialogContent)`
  && {
    padding: 24px;
    padding-top: 0;
    padding-bottom: ${(props) => props.paddingBottom};
    background-color: ${(props) => props.theme.palette.grey[100]};
    @media (max-width: 900px) {
      padding: 12px;
    }
    @media (max-width: 500px) {
      padding: 8px;
    }
  }
`;

const DialogActions = styled(MuiDialogActions)`
  && {
    margin: 0;
    padding: 8px;
  }
`;

/** @deprecated please use modal from react-ui-kit. this will be removed soon */
export default function Modal({
  open,
  title,
  children,
  onClose,
  size,
  paddingBottom,
  inFlight,
  minHeight,
  actions,
  ...rest
}) {
  const getPixelByName = (wsize) => {
    if (wsize === 'small') return '462px';
    if (wsize === 'medium') return '600px';
    if (wsize === 'normal') return '888px';
    if (wsize === 'big') return '1100px';
    if (wsize === 'xBig') return '1230px';
    if (wsize === 'auto') return 'auto';
    if (wsize === '90vw') return '90vw';
    return wsize || '888px';
  };

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      minHeight={minHeight}
      maxWidth={false}
      scroll="body"
      PaperProps={{ square: true }}
      size={getPixelByName(size)}
      {...rest}
    >
      {title && (
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          {title}
        </DialogTitle>
      )}
      <DialogContent paddingBottom={paddingBottom}>{children}</DialogContent>
      {inFlight && <ColorLinearProgress />}
      {actions && (
        <DialogActions>
          {actions.map((item) => (
            <Button
              data-testId={`${item.label}`}
              key={item.label}
              variant={item.variant || 'text'}
              onClick={item.action}
              color="primary"
              disabled={item.disabled || false}
            >
              {item.label}
            </Button>
          ))}
        </DialogActions>
      )}
    </StyledDialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  size: PropTypes.string,
  minHeight: PropTypes.string,
  paddingBottom: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
      disabled: PropTypes.bool,
      color: PropTypes.string,
    }),
  ),
  inFlight: PropTypes.bool,
};

Modal.defaultProps = {
  open: false,
  size: 'normal',
  title: null,
  minHeight: null,
  paddingBottom: '48px',
  inFlight: false,
  actions: null,
};
