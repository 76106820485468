import { createContext } from 'react';

import { GoogleAPI } from 'google-maps-react';

interface GoogleApiContextProps {
  google: GoogleAPI | null;
  loadedGoogle: boolean;
}

const GoogleApiContext = createContext({} as GoogleApiContextProps);

export { GoogleApiContext };
