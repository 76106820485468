import React, { FC, useRef } from 'react';

import { Truck } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import {
  Container,
  HeaderRow,
  HoursContainer,
  Spacer,
  TruckHeaderRow,
  filterJobByDate,
  getJobForOrderAndDate,
  getJobForOrderAndDeliveryDate,
  getTrucksToRender,
  renderHours,
  renderTruck,
  renderTruckName,
} from '../common';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import useScrollTo7Am from 'admin/components/OrderWindow/SchedulerBox/hooks/useScrollTo7Am';

interface DayViewProps {
  date: Date;
  trucks: Truck[];
  orderId?: number;
  jobs: Job[];
  onTruckClick: (truckId: number, date: Date) => void;
  dense?: boolean;
  renderJobTooltip?: (job: Job, jobComponent: JSX.Element) => JSX.Element;
}

const DayView: FC<DayViewProps> = ({
  date,
  trucks,
  orderId = -1,
  onTruckClick,
  jobs,
  dense = false,
  renderJobTooltip,
}) => {
  const scrollRef = useRef<HTMLElement | null>(null);
  useScrollTo7Am(scrollRef);

  const selectedTrucks = jobs.find(getJobForOrderAndDate(orderId, date))?.truckIds || List();
  const selectedDeliveryTrucks = jobs.find(getJobForOrderAndDeliveryDate(orderId, date))?.deliveryTruckIds || List();

  const truckJobs = trucks.reduce<{ [key: string]: Job[] }>((acc, truck) => {
    const jobsForTruck = jobs.filter(filterJobByDate(date, truck));

    return { ...acc, [truck.id!]: jobsForTruck.map((job) => job.checkIfDeliveryJob(date)) };
  }, {});

  const trucksToRender = getTrucksToRender(trucks, date);

  return (
    <Container>
      <div>
        <TruckHeaderRow>
          <Spacer />
        </TruckHeaderRow>
        {trucksToRender.map(
          renderTruckName({ onTruckClick, dense, truckJobs, selectedTrucks, selectedDeliveryTrucks, date }),
        )}
      </div>
      {/* @ts-ignore */}
      <HoursContainer ref={scrollRef}>
        <HeaderRow>{renderHours(false)}</HeaderRow>
        {trucksToRender.map(renderTruck(dense, truckJobs, orderId, renderJobTooltip))}
      </HoursContainer>
    </Container>
  );
};

export default DayView;
