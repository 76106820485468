import axios from 'axios';

class ValuationAPI {
  getValuationChoicesForValuedAmount(orderId, valuationAmount) {
    const amount = valuationAmount !== null ? `/valuationAmount/${valuationAmount}` : '';
    return axios.get(`/api/moving/valuation/choices/orderId/${orderId}${amount}`);
  }
}

export default new ValuationAPI();
