import React, { useEffect, useMemo, useRef, useState } from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodyText,
  DatePicker,
  HeaderSmallText,
  LocationType,
  Modal,
  MoveTypeShort,
  Order,
  Select,
  SurveyStatusType,
  SurveyStatusTypeName,
  SurveyTypeName,
  getFormErrorMessages,
  toDate,
  useCurrentUser,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress, Tooltip, makeStyles } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { addSeconds, format, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { date, number, object, string } from 'yup';

import servicesAPI from 'admin/api/ServicesAPI';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { SurveySchedulerBox } from 'admin/components/OrderWindow/SurveySchedulerBox';
import {
  ASSIGNED_BY,
  DURATION,
  ESTIMATOR_ID,
  STATUS,
  SURVEY_DATE,
  SURVEY_ID,
  SURVEY_TIME_END,
  SURVEY_TIME_START,
  convertLocalTimeToSeconds,
  convertSecondsToLocalTime,
  SurveyLabels as labels,
} from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { useOrderChangeSet, useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { useUpdateOrderContext } from 'admin/components/OrderWindow/context/useUpdateOrderContext';
import useDurations from 'admin/components/OrderWindow/hooks/useDurations';
import useTimeDuration from 'admin/components/OrderWindow/hooks/useTimeDuration';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import useInHomeEstimatePermissions from 'admin/hooks/useInHomeEstimatePermissions';
import { useMobile } from 'admin/hooks/useMobile';

const useStyles = makeStyles(() => ({
  label: {
    '& > .MuiFormLabel-root': {
      whiteSpace: 'nowrap',
    },
  },
  datePickerInput: {
    cursor: 'pointer',
  },
  errorContent: {
    justifyContent: 'center',
  },
  outlinedError: {
    color: 'none',
    border: 'none',
  },
  errorMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  tooltip: {
    textAlign: 'center',
  },
}));

const startOfTheDay = startOfDay(new Date());

let previousCheckAssignEstimator = null;

const schema = object().shape({
  [SURVEY_ID]: number().nullable(),
  [SURVEY_DATE]: date().label(labels[SURVEY_DATE]).nullable().required(),
  [SURVEY_TIME_START]: string().label(labels[SURVEY_TIME_START]).nullable().required(),
  [SURVEY_TIME_END]: string().label(labels[SURVEY_TIME_END]).nullable().required(),
  [ASSIGNED_BY]: string().label(labels[ASSIGNED_BY]).nullable().required(),
  [DURATION]: string().label(labels[DURATION]).required().nullable(),
  [STATUS]: string().label(labels[STATUS]).required(),
  [ESTIMATOR_ID]: number()
    .label(labels[ESTIMATOR_ID])
    .required()
    .nullable()
    .when(
      [SURVEY_ID, SURVEY_DATE, SURVEY_TIME_START, SURVEY_TIME_END, DURATION, `$${ESTIMATOR_ID}`, '$orderId'],
      (id, surveyDate, startTime, endTime, duration, estimatorId, orderId, s) => {
        if (surveyDate && startTime && endTime && duration && orderId && estimatorId) {
          if (previousCheckAssignEstimator) {
            previousCheckAssignEstimator.cancel();
          }
          previousCheckAssignEstimator = servicesAPI.checkAssignEstimator({
            surveyDate: format(surveyDate, BACKEND_DATE_FORMAT),
            duration: convertSecondsToLocalTime(duration),
            startTimeFrom: startTime,
            startTimeTo: endTime,
            estimatorId,
            orderId,
            id,
          });
          previousCheckAssignEstimator.promise.catch(() => {});
          return s.test(
            ESTIMATOR_ID,
            `The current estimator isn't available at the selected date/time. Try to change the input values.`,
            () => previousCheckAssignEstimator.promise.catch(() => {}).then((res) => !res),
          );
        }
        return s;
      },
    ),
});

const NO_PERMISSION_TEXT = `You don't have permission to`;

export const SurveyModal = ({
  onSave,
  onCancel,
  open,
  title,
  subtitle,
  moveType,
  currentService,
  surveyType,
  order,
  existingEstimateService,
}) => {
  const settings = useOrderWindowSettings();
  const defaultDuration = settings?.[`inhome_survey.${MoveTypeShort[moveType]}.typicalSurveyHours`];
  const { setEditWaypoint } = useOrderWindowEditAddressIndex();
  const { changeSet, showSaveDialog } = useOrderChangeSet();
  const { saveOrderRef, applyOrderChangeSetRef } = useUpdateOrderContext();
  const { orderId } = order;
  const managersList = useSelector(getManagerList);
  const managerOptions = useMemo(
    () => managersList.toJS().map((manager) => [manager.id, manager.fullName]),
    [managersList],
  );
  const waypoints = order.getMultiDayServicesWaypoints();
  const waypointsRef = useRef(waypoints);
  waypointsRef.current = waypoints;
  const changeSetRef = useRef(changeSet);
  changeSetRef.current = changeSet;
  const statusesOptions = Object.entries(SurveyStatusTypeName);
  const statusesOptionsWithoutCancelled = statusesOptions.filter(([id]) => id !== SurveyStatusType.CANCELLED);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMobile();
  const classes = useStyles();
  const [availableSurveyDate, setAvailableSurveyDate] = useState([]);
  const [isLoadingAvailableDate, setIsLoadingAvailableDate] = useState(false);
  const [isLoadingSurveyService, setIsLoadingSurveyService] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const errorMessage = { variant: 'error' };
  const successMessage = { variant: 'success' };
  const context = useMemo(() => ({ orderId }), []);
  const user = useCurrentUser();
  const isFlight = isLoadingSurveyService || isLoadingAvailableDate || isSubmitting;
  const { canEdit, canAssign, canChangeStatus, canCancel } = useInHomeEstimatePermissions();
  const canNotMakeChanges = (existingEstimateService && !canEdit) || !canAssign;

  const getTooltipText = (permission) => {
    const noPermissionsToEditAndAssignAndChangeStatus = !canEdit && !canAssign && !canChangeStatus;
    const noPermissionsToEditAndAssign = !canEdit && !canAssign && canChangeStatus;
    const noPermissionsToEditAndChangeStatus = !canEdit && canAssign && !canChangeStatus;
    const noPermissionToChangeStatus = canEdit && canAssign && !canChangeStatus;
    const noPermissionToEdit = !canEdit && canAssign && canChangeStatus;
    const noPermissionsToAssignAndChangeStatus = canEdit && !canAssign && !canChangeStatus;
    const noPermissionToAssign = canEdit && !canAssign && canChangeStatus;

    if (permission) {
      if (noPermissionsToEditAndAssign) {
        return `${NO_PERMISSION_TEXT} edit and assign ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionToAssign) {
        return `${NO_PERMISSION_TEXT} assign ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionToEdit) {
        return `${NO_PERMISSION_TEXT} edit ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionsToEditAndAssignAndChangeStatus) {
        return `${NO_PERMISSION_TEXT} edit, change status and assign ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionsToEditAndChangeStatus) {
        return `${NO_PERMISSION_TEXT} edit and change status ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionToChangeStatus) {
        return `${NO_PERMISSION_TEXT} change status ${SurveyTypeName[currentService]}`;
      }

      if (noPermissionsToAssignAndChangeStatus && canEdit) {
        return `${NO_PERMISSION_TEXT} change status and assign ${SurveyTypeName[currentService]}`;
      }
    } else {
      return '';
    }
  };

  const defaultDurationTime = defaultDuration * 3600;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    reset,
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    context,
    defaultValues: { orderServiceType: currentService, orderId, uuid: '', [DURATION]: defaultDurationTime },
  });

  context[ESTIMATOR_ID] = watch(ESTIMATOR_ID);

  const surveyTimeStart = watch(SURVEY_TIME_START);
  const surveyTimeEnd = watch(SURVEY_TIME_END);
  const surveyDateValue = watch(SURVEY_DATE);
  const durationValue = watch(DURATION);
  const estimatorValue = watch(ESTIMATOR_ID);
  const [, , timeOptions15mins, adjustedTimeOptions15mins] = useTimeDuration(surveyTimeStart);
  const [, durationOptions15mins] = useDurations();
  const parsedErrors = getFormErrorMessages(errors);

  useEffect(() => {
    if (convertLocalTimeToSeconds(surveyTimeStart) > convertLocalTimeToSeconds(surveyTimeEnd)) {
      setValue(SURVEY_TIME_END, surveyTimeStart, { shouldValidate: true });
    }
  }, [surveyTimeStart, surveyTimeEnd]);

  useEffect(() => {
    if (surveyTimeStart && surveyDateValue && surveyTimeEnd && durationValue && estimatorValue) {
      trigger(ESTIMATOR_ID);
    }
  }, [surveyTimeStart, surveyDateValue, estimatorValue, durationValue, surveyTimeEnd]);

  useEffect(() => {
    setIsLoadingSurveyService(true);

    servicesAPI
      .getSurveyService(orderId, {
        order_service_type: currentService,
      })
      .then((res) => {
        const surveyValue = {
          ...res,
          [SURVEY_DATE]: toDate(res[SURVEY_DATE]),
          [DURATION]: convertLocalTimeToSeconds(res[DURATION]),
          [ESTIMATOR_ID]: res[ESTIMATOR_ID] || null,
          orderServiceType: currentService,
          uuid: res.estimatorWork.uuid,
        };
        reset(surveyValue);
      })
      .catch(() => {})
      .then(() => {
        setIsLoadingSurveyService(false);
      });
  }, [currentService, reset]);

  useEffect(() => {
    if (managerOptions.length) {
      const hasCurrentUser = managerOptions.find(([id]) => id === user.id);

      if (hasCurrentUser) {
        setValue(ASSIGNED_BY, user.id);
      }
    }
  }, [managerOptions, user.id]);

  useEffect(() => {
    setIsLoadingAvailableDate(true);

    servicesAPI
      .getAvailableSurveyDate({
        move_type: moveType,
      })
      .then((res) => {
        setAvailableSurveyDate(res);
      })
      .catch(() => {})
      .then(() => setIsLoadingAvailableDate(false));
  }, [moveType]);

  const rates = availableSurveyDate.reduce((acc, item) => ({ ...acc, [item]: 6 }), {});

  useUpdateEffect(() => {
    if (saveOrderRef?.current) {
      setIsSubmitting(true);
      saveOrderRef?.current.then(() => {
        saveOrderRef.current = null;
        setIsSubmitting(false);
        setTimeout(() => {
          handleSubmitForm(getValues());
        }, 100);
      });
    }
  }, [saveOrderRef?.current]);

  useUpdateEffect(() => {
    if (applyOrderChangeSetRef?.current) {
      setIsSubmitting(true);
      applyOrderChangeSetRef?.current.then(() => {
        setIsSubmitting(false);
        applyOrderChangeSetRef.current = null;
        setTimeout(() => {
          handleSubmitForm(getValues());
        }, 100);
      });
    }
  }, [applyOrderChangeSetRef?.current]);

  function handleSubmitForm(values) {
    const waypointWithoutAddress = waypointsRef.current.find(
      (w) => !w.address.street1 && w.locationType === LocationType.CUSTOMER_SITE,
    );

    if (waypointWithoutAddress) {
      setEditWaypoint(waypointWithoutAddress);
      enqueueSnackbar('Please enter address to save estimate service.', { variant: 'warning' });
      return;
    }

    if (changeSetRef.current.toArray().some(([key]) => key.includes('waypoints'))) {
      enqueueSnackbar('Please save changes.', { variant: 'warning' });
      showSaveDialog();
      return;
    }

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (values.id) {
      servicesAPI
        .updateSurveyService({
          ...values,
          [DURATION]: format(addSeconds(startOfTheDay, values[DURATION]), TIME_STRING_FORMAT),
          [SURVEY_DATE]: format(values[SURVEY_DATE], BACKEND_DATE_FORMAT),
        })
        .then(() => {
          enqueueSnackbar(`${SurveyTypeName[values.orderServiceType]} service updated successfully`, successMessage);
        })
        .catch(() => {
          enqueueSnackbar(`Can't update ${SurveyTypeName[values.orderServiceType]} service. Try again`, errorMessage);
        })
        .then(() => {
          setIsSubmitting(false);
          onSave();
        });
    } else {
      servicesAPI
        .createSurveyService({
          ...values,
          [DURATION]: format(addSeconds(startOfTheDay, values[DURATION]), TIME_STRING_FORMAT),
          [SURVEY_DATE]: format(values[SURVEY_DATE], BACKEND_DATE_FORMAT),
          [ASSIGNED_BY]: +values[ASSIGNED_BY],
          orderServiceType: currentService,
          allowCustomerSchedule: false,
          orderId,
        })
        .then(() => {
          setValue(SURVEY_ID, values.id);
          enqueueSnackbar(`${SurveyTypeName[values.orderServiceType]} service created successfully`, successMessage);
        })
        .catch(() => {
          enqueueSnackbar(`Can't create ${SurveyTypeName[values.orderServiceType]} service. Try again`, errorMessage);
        })
        .then(() => {
          setIsSubmitting(false);
          onSave();
        });
    }
  }

  return (
    <Modal
      open={open}
      title={title}
      onClose={onCancel}
      maxWidth="lg"
      disabledInProcessing={isSubmitting}
      actions={[
        {
          label: 'cancel',
          onClick: onCancel,
        },
        {
          label: 'save',
          onClick: handleSubmit(handleSubmitForm),
          loading: isSubmitting,
          disabled: canNotMakeChanges,
        },
      ]}
      color="grey"
    >
      <VideoButton position="absolute" video="9kGSk8hh03Y" />
      <Box ml={isMobile ? 0 : 2} mb={3}>
        <Box height={8} mb={-1}>
          {(isLoadingSurveyService || isLoadingAvailableDate) && <LinearProgress />}
        </Box>
        <Box mr={isMobile ? 0 : 7} mb={5}>
          <Box mb={2} mt={1}>
            <HeaderSmallText>
              <b>{subtitle}</b>
            </HeaderSmallText>
          </Box>
          <Grid container spacing={2}>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={getTooltipText(canNotMakeChanges)}>
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <DatePicker
                  disabled={isFlight}
                  fullWidth
                  readOnly={canNotMakeChanges}
                  name={SURVEY_DATE}
                  rates={rates}
                  label={labels[SURVEY_DATE]}
                  control={control}
                  defaultValue={null}
                  InputProps={{
                    classes: { input: classNames(classes.datePickerInput) },
                  }}
                />
              </Grid>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={getTooltipText(canNotMakeChanges)}>
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <Select
                  disabled={isFlight}
                  fullWidth
                  name={SURVEY_TIME_START}
                  label={labels[SURVEY_TIME_START]}
                  options={timeOptions15mins}
                  className={classes.label}
                  primaryBackgroundOnSelectedItem
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                    readOnly: canNotMakeChanges,
                  }}
                  control={control}
                />
              </Grid>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={getTooltipText(canNotMakeChanges)}>
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <Select
                  disabled={isFlight}
                  fullWidth
                  name={SURVEY_TIME_END}
                  label={labels[SURVEY_TIME_END]}
                  options={adjustedTimeOptions15mins}
                  primaryBackgroundOnSelectedItem
                  className={classes.label}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                    readOnly: canNotMakeChanges,
                  }}
                  control={control}
                />
              </Grid>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={getTooltipText(canNotMakeChanges)}>
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <Select
                  disabled={isFlight}
                  fullWidth
                  name={DURATION}
                  label={labels[DURATION]}
                  options={durationOptions15mins}
                  primaryBackgroundOnSelectedItem
                  InputProps={{
                    readOnly: canNotMakeChanges,
                  }}
                  control={control}
                />
              </Grid>
            </Tooltip>
            <Tooltip classes={{ tooltip: classes.tooltip }} title={getTooltipText(canNotMakeChanges)}>
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <Select
                  disabled={isFlight}
                  fullWidth
                  name={ASSIGNED_BY}
                  label={labels[ASSIGNED_BY]}
                  options={managerOptions}
                  primaryBackgroundOnSelectedItem
                  InputProps={{
                    readOnly: canNotMakeChanges,
                  }}
                  control={control}
                />
              </Grid>
            </Tooltip>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={getTooltipText(canNotMakeChanges || !canChangeStatus)}
            >
              <Grid item lg={2} xs={6} sm={4} md={3}>
                <Select
                  disabled={isFlight}
                  fullWidth
                  name={STATUS}
                  label={labels[STATUS]}
                  options={canCancel ? statusesOptions : statusesOptionsWithoutCancelled}
                  defaultValue={SurveyStatusType.SCHEDULED}
                  primaryBackgroundOnSelectedItem
                  InputProps={{
                    readOnly: canNotMakeChanges || !canChangeStatus,
                  }}
                  control={control}
                />
              </Grid>
            </Tooltip>
          </Grid>
        </Box>
        {parsedErrors[ESTIMATOR_ID] && (
          <Alert
            severity="error"
            variant="outlined"
            classes={{
              root: classes.errorContent,
              outlinedError: classes.outlinedError,
              message: classes.errorMessage,
            }}
          >
            <BodyText color="error">{parsedErrors[ESTIMATOR_ID]}</BodyText>
          </Alert>
        )}
        <SurveySchedulerBox
          surveyType={surveyType}
          estimateDate={toDate(surveyDateValue)}
          order={order}
          currentSurveyValue={getValues()}
          setValue={setValue}
          trigger={trigger}
          isLoadingSurveyService={isLoadingSurveyService}
          handleCloseModal={onCancel}
          existingEstimateService={existingEstimateService}
        />
      </Box>
    </Modal>
  );
};

SurveyModal.propTypes = {
  onSave: pt.func.isRequired,
  onCancel: pt.func.isRequired,
  open: pt.bool.isRequired,
  title: pt.string.isRequired,
  subtitle: pt.string.isRequired,
  moveType: pt.string.isRequired,
  currentService: pt.string.isRequired,
  order: pt.instanceOf(Order).isRequired,
  existingEstimateService: pt.object,
};

SurveyModal.defaultProps = {
  existingEstimateService: null,
};
