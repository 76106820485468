import React, { useEffect, useState } from 'react';

import {
  ActivitySourceType,
  Button,
  CrewMemberDto,
  DurationRange,
  HeaderBigText,
  HeaderSmallText,
  IActivitySourceDescriptor,
  JobCrewDto,
  JobType,
  Order,
  PriceAdjustmentType,
  REQUESTED_DELIVERY_DATE_START,
  formatCurrency,
  getFormErrorMessages,
  roundNumberToFixedDigits,
  toBackEndDate,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, DialogContent, LinearProgress, Portal, makeStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import clsx from 'clsx';
import { parseISO } from 'date-fns';
import { List } from 'immutable';
import { useSnackbar } from 'notistack';
import Draggable from 'react-draggable';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { array, number, object } from 'yup';

import { payrollApi } from 'admin/api';
import { fetchDepartments } from 'admin/autodux/UserDepartmentAutodux';
import { getBasicEmployee } from 'admin/autodux/UsersAutodux';
import { useOrderServiceIndex, useOrderState, usePayrollContext } from 'admin/components/OrderWindow/context';
import { OrderPayrollTotals } from 'admin/components/OrderWindow/modals/Payroll/OrderPayrollTotals';
import { PayrollByPositionType } from 'admin/components/OrderWindow/modals/Payroll/PayrollByPositionType';
import {
  EmployeeCompensationName,
  PAYROLL_ERROR_PER_EMPLOYEE_CLASS_NAME,
  PayrollPositionTypesByNames,
  payrollForeman,
  payrollHelper,
  payrollSales,
  payrolls,
} from 'admin/components/OrderWindow/modals/Payroll/PayrollConstants';
import { getCommission } from 'admin/components/OrderWindow/modals/Payroll/getCommission';
import { PositionTypes } from 'admin/constants/PositionTypes';
import { useMobile } from 'admin/hooks/useMobile';
import { BasicEmployeeDto, EmployeeCommissionType } from 'common-types';
import { EmployeeJobPayrollCompensationDto, EmployeeJobPayrollDto, JobPayrollDto } from 'common-types/payroll';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    zIndex: theme.zIndex.modal,
    position: 'fixed',
    background: theme.palette.common.white,
  },
  fullSizeDialog: {
    top: '5%',
    left: '23%',
    width: '55%',
    height: '90%',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: theme.shadows[5],
  },
  title: {
    '&.draggable-handle-payroll': {
      cursor: 'move',
    },
  },
  fullScreen: {
    width: 'auto',
    height: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    transform: 'none !important',
  },
}));

const employeeValidation = array().of(
  object().shape({
    total: number().nullable(),
    [EmployeeCompensationName]: array()
      .of(
        object().shape({
          rate: object().shape({
            amount: number()
              .label('Rate')
              .nullable()
              .transform((value) => (!value && value !== 0 ? null : value))
              .required()
              .min(0),
          }),
          value: number()
            .label('Value')
            .nullable()
            .transform((value) => (!value && value !== 0 ? null : value))
            .required()
            .min(0),
          total: number().nullable(),
        }),
      )
      .min(1)
      .label('Commission'),
  }),
);

const schema = object().shape({
  totalEmployeesCompensation: number().nullable(),
  total: number().nullable(),
  [payrollSales]: array()
    .of(
      object().shape({
        total: number().nullable(),
        [EmployeeCompensationName]: array()
          .of(
            object().shape({
              rate: object().shape({
                amount: number()
                  .label('Rate')
                  .nullable()
                  .transform((value) => (!value && value !== 0 ? null : value))
                  .required()
                  .min(0),
              }),
              value: number()
                .label('Value')
                .nullable()
                .transform((value) => (!value && value !== 0 ? null : value))
                .required()
                .min(0),
              total: number().nullable(),
            }),
          )
          .label('Commission'),
      }),
    )
    .label('Sales'),
  [payrollForeman]: employeeValidation.label('Foreman').min(1),
  [payrollHelper]: employeeValidation.label('Helpers'),
});

export interface OrderPayroll extends Omit<JobPayrollDto, 'employeeJobPayrolls'> {
  payrollSales: EmployeeJobPayrollDto[];
  payrollForeman: EmployeeJobPayrollDto[];
  payrollHelper: EmployeeJobPayrollDto[];
}

interface PayrollProps {
  onSave: () => void;
  onClose: () => void;
  type: JobType;
}

const durationRangeToHours = (durationRange: DurationRange) => {
  if (!durationRange?.durationMax) {
    return 0;
  }

  return durationRange.durationMax.hours + durationRange.durationMax.minutes / 60;
};

const getPayrollActivitySources = (order: Order, selectedServiceIndex: number): IActivitySourceDescriptor[] => {
  const salesQuote = order.getServiceQuote(selectedServiceIndex);
  const serviceClosing = order.closingOrderDetail?.serviceRosterClosingsDto?.get(selectedServiceIndex);
  const dispatchJob =
    salesQuote?.dispatchJob ?? order?.closingOrderDetail?.getServiceQuote(selectedServiceIndex)?.dispatchJob;

  const result = [
    {
      activitySource: ActivitySourceType.JOB,
      referencedEntityId: dispatchJob?.id!,
    },
    {
      activitySource: ActivitySourceType.ORDER,
      referencedEntityId: order.orderId,
    },
    {
      activitySource: ActivitySourceType.SERVICE,
      referencedEntityId: salesQuote.originalId,
    },
    {
      activitySource: ActivitySourceType.ORDER_CLOSING,
      referencedEntityId: order.closingOrderDetail?.id!,
    },
  ] as IActivitySourceDescriptor[];

  if (serviceClosing) {
    result.push({
      activitySource: ActivitySourceType.SERVICE_CLOSING,
      referencedEntityId: serviceClosing?.id!,
    });
  }

  return result;
};

const toDto = (data: OrderPayroll): JobPayrollDto => {
  const { payrollSales, payrollForeman, payrollHelper, ...rest } = data;

  return {
    ...rest,
    employeeJobPayrolls: [...payrollSales, ...payrollForeman, ...payrollHelper],
  };
};

const fromDto = (data: JobPayrollDto): OrderPayroll => {
  const { employeeJobPayrolls, ...rest } = data;

  return {
    ...rest,
    payrollSales: employeeJobPayrolls.filter((e) => PayrollPositionTypesByNames.Sales.includes(e.positionType)),
    payrollForeman: employeeJobPayrolls.filter((e) => PayrollPositionTypesByNames.Foreman.includes(e.positionType)),
    payrollHelper: employeeJobPayrolls.filter((e) => PayrollPositionTypesByNames.Helper.includes(e.positionType)),
  };
};

const getDefaultSalesCompensation = (order: Order, employees: List<BasicEmployeeDto>, totals: OrderPayrollTotals) => {
  const assignedTo = order.assignedTo;

  const sales = employees.find((employee) => employee.id === assignedTo);

  const result: EmployeeJobPayrollDto[] = [];

  if (sales) {
    const employeeJobPayrollCompensations = sales.employeeCommissions
      .map((commission) => {
        const c = getCommission(commission, totals, {
          hasHourlyRateAsHelper: sales.employeeCommissions.some(
            (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
          ),
        });

        if (!c) {
          return null;
        }

        const coefficient = c.rate.type === PriceAdjustmentType.PERCENT ? c.rate.amount / 100 : c.rate.amount;
        c.total = roundNumberToFixedDigits(coefficient * c.value);

        return c;
      })
      .filter(Boolean) as EmployeeJobPayrollCompensationDto[];

    result.push({
      employeeId: sales?.id!,
      positionType: PositionTypes.SALES,
      employeeJobPayrollCompensations: employeeJobPayrollCompensations,
      total: 0,
    });
  }

  return result;
};

const getDefaultForemanCompensation = (
  crews: JobCrewDto[],
  employees: List<BasicEmployeeDto>,
  totals: OrderPayrollTotals,
) => {
  const foreman =
    crews?.reduce((accumulator, crew) => {
      return [
        ...accumulator,
        ...(crew?.crew?.members as CrewMemberDto[]).filter((m) => m.foreman).map((m) => m?.employee?.id!),
      ];
    }, [] as number[]) ?? [];

  const result: EmployeeJobPayrollDto[] = [];

  foreman.forEach((foremanId) => {
    const payroll: EmployeeJobPayrollDto = {
      employeeId: foremanId,
      positionType: PositionTypes.FOREMAN,
      employeeJobPayrollCompensations: [],
      total: 0,
    };

    const foreman = employees.find((employee) => employee.id === foremanId);

    if (foreman) {
      const employeeJobPayrollCompensations = foreman.employeeCommissions
        .map((commission) => {
          const c = getCommission(commission, totals, {
            hasHourlyRateAsHelper: foreman.employeeCommissions.some(
              (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
            ),
          });

          if (!c) {
            return null;
          }

          const coefficient = c.rate.type === PriceAdjustmentType.PERCENT ? c.rate.amount / 100 : c.rate.amount;
          c.total = roundNumberToFixedDigits(coefficient * c.value);

          return c;
        })
        .filter(Boolean) as EmployeeJobPayrollCompensationDto[];

      payroll.employeeJobPayrollCompensations = employeeJobPayrollCompensations;
    }

    result.push(payroll);
  });

  return result;
};

const getDefaultHelperCompensation = (
  crews: JobCrewDto[],
  employees: List<BasicEmployeeDto>,
  totals: OrderPayrollTotals,
) => {
  const helpers =
    crews?.reduce((accumulator, crew) => {
      return [
        ...accumulator,
        ...(crew?.crew?.members as CrewMemberDto[]).filter((m) => !m.foreman).map((m) => m?.employee?.id!),
      ];
    }, [] as number[]) ?? [];

  const result: EmployeeJobPayrollDto[] = [];

  helpers.forEach((helperId) => {
    const payroll: EmployeeJobPayrollDto = {
      employeeId: helperId,
      positionType: PositionTypes.HELPER,
      employeeJobPayrollCompensations: [],
      total: 0,
    };

    const helper = employees.find((employee) => employee.id === helperId);

    if (helper) {
      const employeeJobPayrollCompensations = helper.employeeCommissions
        .map((commission) => {
          const c = getCommission(commission, totals, {
            positionType: PositionTypes.HELPER,
            hasHourlyRateAsHelper: helper.employeeCommissions.some(
              (c) => c.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER,
            ),
          });

          if (!c) {
            return null;
          }

          const coefficient = c.rate.type === PriceAdjustmentType.PERCENT ? c.rate.amount / 100 : c.rate.amount;
          c.total = roundNumberToFixedDigits(coefficient * c.value);

          return c;
        })
        .filter(Boolean) as EmployeeJobPayrollCompensationDto[];

      payroll.employeeJobPayrollCompensations = employeeJobPayrollCompensations;
    }

    result.push(payroll);
  });

  return result;
};

export const Payroll = ({ onSave, onClose, type }: PayrollProps) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [inFlight, setInFlight] = useState(false);
  const [inFlightPayroll, setInFlightPayroll] = useState(false);
  const { getPayrolls } = usePayrollContext();
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const employees = useSelector(getBasicEmployee) as List<BasicEmployeeDto>;
  const quote = order?.getServiceQuote(serviceIndex)!;
  const { enqueueSnackbar } = useSnackbar();
  const totals: OrderPayrollTotals = {
    // all closing fields should be filled when closing is done
    salesTotal: order?.getServiceGrandTotal(serviceIndex)?.minValue || 0,
    salesPackingTotal: order?.getMaterialsTotal(serviceIndex)?.minValue || 0,
    salesAdditionalServicesTotal: order?.getAdditionalServicesTotal(serviceIndex)?.minValue || 0,
    numberOfHours:
      durationRangeToHours(order?.closingOrderDetail?.getServiceQuote(serviceIndex).get('totalTimeRange')) || 0,
    closingTotal: order?.closingOrderDetail?.getServiceGrandTotal(serviceIndex)?.minValue || 0,
    closingPackingTotal: order?.closingOrderDetail?.getMaterialsTotal(serviceIndex)?.minValue || 0,
    closingAdditionalServicesTotal: order?.closingOrderDetail?.getAdditionalServicesTotal(serviceIndex)?.minValue || 0,
    tips: order?.closingOrderDetail?.serviceRosterClosingsDto?.get(serviceIndex)?.pickupTips || 0,
  };

  const dispatchJob = quote?.dispatchJob ?? order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.dispatchJob;
  const crews = (dispatchJob?.crews as JobCrewDto[])?.filter((c) => c.jobType === type) ?? [];

  let date = toBackEndDate(typeof quote.date == 'string' ? parseISO(quote.date) : quote.date)!;

  if (quote?.[REQUESTED_DELIVERY_DATE_START] && type === JobType.DELIVERY) {
    date = toBackEndDate(quote[REQUESTED_DELIVERY_DATE_START])! || date;
  }

  const methods = useForm<OrderPayroll>({
    defaultValues: {
      jobId: dispatchJob?.id,
      payrollDate: {
        date,
      },
      totalEmployeesCompensation: 0,
      [payrollSales]: getDefaultSalesCompensation(order!, employees, totals),
      [payrollForeman]: getDefaultForemanCompensation(crews, employees, totals),
      [payrollHelper]: getDefaultHelperCompensation(crews, employees, totals),
      branchId: 1,
      activitySource: ActivitySourceType.JOB,
      sourceId: dispatchJob?.id,
      activitySources: getPayrollActivitySources(order!, serviceIndex),
      type,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (dispatchJob?.id) {
      setInFlightPayroll(true);
      payrollApi
        .getJobPayroll(dispatchJob?.id, type)
        .then((payroll) => {
          if (payroll) {
            methods.reset(fromDto(payroll));
          }
        })
        .catch(() => {})
        .then(() => {
          setInFlightPayroll(false);
        });
    }
  }, [dispatchJob?.id]);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);

  const values = methods?.getValues();

  const total = roundNumberToFixedDigits(
    payrolls.reduce(
      (accumulator, p) =>
        accumulator +
          values?.[p].reduce(
            (employeeAccumulator, employee) =>
              employeeAccumulator +
              employee.employeeJobPayrollCompensations.reduce(
                (compensationAccumulator, compensation) => compensationAccumulator + compensation.total,
                0,
              ),
            0,
          ) ?? 0,
      0,
    ),
  );

  const handleSave = (data: OrderPayroll) => {
    if (inFlight) {
      return;
    }

    setInFlight(true);

    const toUpdate = {
      ...toDto(data),
      totalEmployeesCompensation: total,
    } as JobPayrollDto;

    (data.id ? payrollApi.updateJobPayroll(toUpdate) : payrollApi.createJobPayroll(toUpdate))
      .then((saved) => {
        methods.reset(fromDto(saved));
        onSave();
        getPayrolls();
      })
      .catch(() => {
        enqueueSnackbar('Failed to save payroll', { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  };

  const onError = (formErrors: typeof methods.formState.errors) => {
    setTimeout(() => {
      const errors = getFormErrorMessages(formErrors);
      const errorsCount = Object.keys(errors).length;
      if (
        errorsCount &&
        Object.keys(errors).some(
          (key) => key.includes('employeeJobPayrollCompensations') && !key.includes('employeeJobPayrollCompensations.'),
        )
      ) {
        document
          .getElementsByClassName(PAYROLL_ERROR_PER_EMPLOYEE_CLASS_NAME)?.[0]
          ?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <FormProvider {...(methods as any)}>
      <Portal>
        <Draggable handle=".draggable-handle-payroll">
          <Box
            className={clsx(classes.dialog, classes.fullSizeDialog, {
              [classes.fullScreen]: isMobile,
            })}
          >
            <DialogTitle className={clsx(classes.title, 'draggable-handle-payroll')}>
              <HeaderBigText>
                <b>{`${type === JobType.DELIVERY ? 'Delivery ' : ''}Payroll`}</b>
              </HeaderBigText>
            </DialogTitle>
            <DialogContent>
              <Box mt={-1} height={8}>
                {(inFlight || inFlightPayroll) && <LinearProgress color="primary" />}
              </Box>
              {!!employees.size &&
                !inFlightPayroll &&
                payrolls.map((p) => <PayrollByPositionType key={p} name={p} totals={totals} />)}
              <Box display="flex" justifyContent="flex-end">
                <HeaderSmallText>
                  <b>{`Total Payroll: $${formatCurrency(total)}`}</b>
                </HeaderSmallText>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color="primary" variant="text" disabled={inFlight} onClick={onClose}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="text"
                disabled={inFlight || inFlightPayroll}
                onClick={methods.handleSubmit(handleSave, onError)}
              >
                {`${values.id ? 'Resubmit' : 'Submit'} Payroll`}
              </Button>
            </DialogActions>
          </Box>
        </Draggable>
      </Portal>
    </FormProvider>
  );
};
