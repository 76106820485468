import { Record } from 'immutable';

export default class UserRecord extends Record({
  id: null,
  username: null,
  password: null,
  roles: [],
  firstName: null,
  lastName: null,
  createdDate: null,
}) {
  static createUserRecord(data = {}) {
    if (!data.firstName && data.fullName) {
      const [firstName, lastName] = data.fullName.split(' ');
      return new UserRecord({ ...data, firstName, lastName });
    }
    return new UserRecord(data);
  }
}
