import { MoveType, MoveTypeTypes } from '@elromcoinc/react-shared';

const MOVE_TYPES: MoveTypeTypes[] = [MoveType.RESIDENTIAL, MoveType.COMMERCIAL, MoveType.MILITARY] as MoveTypeTypes[];
type MoveTypeShort = 'res' | 'com' | 'mil';
const MOVE_TYPE_KEYS: Readonly<Record<MoveType, MoveTypeShort>> = {
  RESIDENTIAL: 'res',
  COMMERCIAL: 'com',
  MILITARY: 'mil',
};

function appendMoveTypeKey<T>(templateFunc: (a: MoveTypeShort) => T): Record<MoveType, T> {
  return MOVE_TYPES.reduce(
    (map, key) => ({ ...map, [key]: templateFunc(MOVE_TYPE_KEYS[key]) }),
    {} as Record<MoveType, T>,
  );
}

const settingName = {
  GENERAL_SERVICES: 'GeneralService',
  GENERAL_RATES: 'general_rates',
  GENERAL_RATE_CATEGORY: 'GeneralRateCategory',
  TRAVEL_TIME_CATEGORY: 'TravelTimeRateCategory',
  MOVING_AND_STORAGE_SETTINGS: 'MovingAndStorageSetting',
  KEEP_RATES_THE_SAME: 'gr.keepRatesTheSame',
  TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES: 'gr.travelTimeRatesSameAsLaborRates',
  CALCULATOR_TRAVEL_TIME: 'calculator.travel_time',
  GENERAL_COMPANY_INFO: 'general_company_info',
  ORDERS_VALUATION: 'valuation',
  VALUATION_VISIBILITY: 'ValuationVisibility',
  ORDERS_VALUATION_FOR_LONG_DISTANCE: 'valuation.ld',
  VALUATION_AMOUNT: 'ValuationAmount',
  VALUATION_AMOUNT_LONG_DISTANCE: 'ValuationAmountLongDistance',
  UNIT_SIZE_SPEC: 'UnitSizeSpec',
  MOVE_UNIT: 'gci.moveUnit',
  CUSTOMER_HOST_DNS_NAME: 'gci.customerHost',
  SIZE_TO_WEIGHT_INDEX: 'gci.cuft_to_pounds',
  MOVERS_COUNT: 'movers_count',
  MOVERS_COUNT_ELEVATION_RULES: 'MoversCountElevationRules',
  PACKING_CALCULATOR: 'pckngCalc',
  PACKING_SERVICE: 'packing_service',
  PACKING_SERVICE_SETTING: 'orders.packing',
  PACKING_SERVICE_TYPE_NODE: 'PackingServiceType',
  PACKING_SERVICE_CLONECOSTACROSSORDERTYPES: 'packing_services.cloneCostAcrossOrderTypes',
  MOVERS_SPEED_RATE: 'MoversSpeedRate',
  MOVERS_SPEED_REDUCTION: 'MoversSpeedReduction',
  TRUCK_COUNT: 'TruckCount',
  ADDITIONAL_SERVICE: 'AdditionalService',
  INVENTORY_FORM_SETTING: 'InventoryFormSetting',
  NOT_INTERESTED_REASON: 'NotInterestedReason',
  ESTIMATE_TYPES: {
    NON_BINDING: 'Non-Binding',
    BINDING: 'Binding',
    FLAT_RATE: 'Flat Rate',
  },
  NOT_INTERESTED_REASONS: {
    USE_SAME: 'orders.not_interested.use_same',
    RESIDENTIAL: 'orders.not_interested.res.allowNotInterested',
    COMMERCIAL: 'orders.not_interested.com.allowNotInterested',
    MILITARY: 'orders.not_interested.mil.allowNotInterested',
  },
  BOL_LABOR_HOURS_BY: {
    USE_SAME: 'bol.labor_hours_by.useSame',
    RESIDENTIAL: 'bol.labor_hours_by.res',
    COMMERCIAL: 'bol.labor_hours_by.com',
    MILITARY: 'bol.labor_hours_by.mil',
  },
  ACCEPT_RESIDENTIAL_ORDERS: 'gci.acceptsResidentialOrders',
  ACCEPT_COMMERCIAL_ORDERS: 'gci.acceptsCommercialOrders',
  ACCEPT_MILITARY_ORDERS: 'gci.acceptsMilitaryOrders',
  MOVERS_SPEED_PARKING_DISTANCE_REDUCTION: 'MoversSpeedParkingDistanceReduction',
  ACCOUNT_FAQS: 'AccountFaq',
  INVENTORY: 'inventory',
  INVENTORY_ROOM_SETTING: 'InventoryRoomSetting',
  INVENTORY_ITEM_SETTING: 'InventoryItemSetting',
  INVENTORY_GROUPING_SETTING: 'InventoryGroupingSetting',
  INVENTORY_GROUPING_ROOM_SETTING: 'InventoryGroupingRoomSetting',
  INVENTORY_GROUPING_ITEM_SETTING: 'InventoryGroupingItemSetting',
  LEAD_SOURCE: 'ReferredVia',
  ADDITIONAL_STOPS_FEE_SETTING: 'AdditionalStopsFeeSetting',
  MOVEUNITS: ['cubic feet', 'square feet', 'pounds'],
  MOVE_TYPES,
  MOVE_TYPE_KEYS,
  ALLOW_MODIFY_QUOTE: {
    RESIDENTIAL: 'calculator.quote_display.res.modify',
    COMMERCIAL: 'calculator.quote_display.com.modify',
    MILITARY: 'calculator.quote_display.mil.modify',
  },
  SPEED_TYPES: ['MOVE_WITH_ELEVATION', 'PACKING', 'UNPACKING'],
  FUEL_SURCHARGE_USE_SAME: 'orders.fuel.useSame',
  FUEL_SURCHARGE_LOCAL: 'orders.fuel.local',
  FUEL_SURCHARGE_LONG_DISTANCE: 'orders.fuel.ld',
  COMPANY_CALENDAR: 'CompanyCalendar',
  TITLE_FOR_BASIC_VALUATION: 'valuation.res.titleBasicValuation',
  TITLE_FOR_BASIC_VALUATIONS: appendMoveTypeKey<`valuation.${MoveTypeShort}.titleBasicValuation`>(
    (key) => `valuation.${key}.titleBasicValuation`,
  ),
  TITLE_FOR_BASIC_VALUATIONS_LONG_DISTANCE: appendMoveTypeKey<`valuation.ld.${MoveTypeShort}.titleBasicValuation`>(
    (key) => `valuation.ld.${key}.titleBasicValuation`,
  ),
  TITLE_FOR_FULL_PROTECTION_VALUATIONS: appendMoveTypeKey<`valuation.${MoveTypeShort}.titleFullProtection`>(
    (key) => `valuation.${key}.titleFullProtection`,
  ),
  TITLE_FOR_FULL_PROTECTION_VALUATIONS_LONG_DISTANCE:
    appendMoveTypeKey<`valuation.ld.${MoveTypeShort}.titleFullProtection`>(
      (key) => `valuation.ld.${key}.titleFullProtection`,
    ),
  INVOICE_SETTING: 'finance.invoice_settings',
  ORDER_COI: 'orders.coi',
  COI_DISPLAY: 'orders.coi.displayOption.enabled',
  COI_ADDITIONAL_INFO: 'orders.coi.popupInfo.enabled',
  INVOICE_SETTING_NODE_FLAG: 'InvoiceFlag',
  ORDER_SETTING: 'orders.flags',
  TEMPLATE_FOLDER: 'TemplateFolder',
  ORDER_SETTING_NODE_FLAG: 'OrderFlag',
  PAYMENT_SETTINGS: 'payment_settings',
  PAYMENT_CUSTOM_SETTINGS: 'PaymentCustomSettings',
  PROCESSING_FEE_SETTINGS: 'ProcessingFeeSettings',
  CUSTOM_PAYMENT_OPTION: 'CustomPaymentOption',
  FLAT_RATE_SETTING: 'calculator.flat_rate',
  CALCULATOR_FLAT_RATE_SETTINGS: 'CalculatorFlatRateSetting',
  FLAT_RATE_VOLUME_DISCOUNT_AMOUNT_SETTING: 'FlatRateVolumeDiscountAmountSetting',
  FLAT_RATE_VOLUME_DISCOUNT_RATE_PER_CUBE_SETTING: 'FlatRateVolumeDiscountRatePerCubeSetting',
  RESERVATION_DEPOSIT_SETTING: 'orders.reservations',
  OVER_TIME_RATE_SETTING: 'OvertimeRateSetting',
  LABOR_TIME_SPREAD_SETTING: 'LaborTimeSpreadSetting',
  SERVICE_FLAT_RATE_ENABLED_SETTING: 'ServiceEstimateTypeSetting',
  TRAVEL_TIME: 'calculator.travel_time',
  TRAVEL_TIME_ENABLED: 'calculator.travel_time.enabled',
  TRAVEL_TIME_LABEL: 'calculator.travel_time.name',
  TRAVEL_TIME_IN_DOLLAR: 'calculator.travel_time.travel_time_in_dollar_amount',
  MINIMUM_TRAVEL_TIME: 'calculator.travel_time.minimumTravelTime',
  TRAVEL_TIME_FLAT_RATE_ENABLED: 'calculator.travel_time.flat_rate',
  TRAVEL_TIME_FLAT_RATE_ZIP: 'calculator.travel_time.flat_rate.zip',
  TRAVEL_TIME_FLAT_RATE_RADIUS: 'calculator.travel_time.flat_rate.radius',
  TRAVEL_TIME_FLAT_RATE_TIME: 'calculator.travel_time.flat_rate.time',
  DRIVING_TIME_INCLUDE: 'calculator.travel_time.origin_to_destination',
  DRIVING_TIME_ADD_TO_TRAVEL_TIME: 'calculator.travel_time.driving_time',
  DRIVING_TIME_INCLUDE_DISTANCE: 'calculator.travel_time.driving_time.miles',
  DRIVING_DOUBLE_TRAVEL: 'calculator.travel_time.double_travel',
  DRIVING_DOUBLE_TRAVEL_MIN_TIME: 'calculator.travel_time.double_travel.min_time',
  DRIVING_DOUBLE_TRAVEL_NAME: 'calculator.travel_time.double_travel.name',
  QUOTE_EXPLANATION_SETTING: 'calculator.quote_explanation',
  QUOTE_EXPLANATION_NODE: 'QuoteExplanation',
  EXTRA_FEE_SETTING: 'ExtraFeeSetting',
  DISTANCE_UNIT: 'gci.distanceUnit',
  ORDERS_EXTRA_FEE_NAME: {
    RESIDENTIAL: 'orders.extra_fee.res.name',
    COMMERCIAL: 'orders.extra_fee.com.name',
    MILITARY: 'orders.extra_fee.mil.name',
  },
  EXTRA_FEE: 'orders.extra_fee',
  SMS_ENABLE: 'sms.enable',
  SMS_ENABLE_CALL_FORWARDING: 'sms.enableCallForwarding',
  SMS_FORWARDING_NUMBER: 'sms.forwardingNumber',
  SMS_ENABLE_AUTO_CHARGE: 'enableAutoCharge',
  SMS_AUTO_CHARGE_AMOUNT: 'autoChargeAmount',
  AMOUNT_TO_CHARGE: 'amountToCharge',
  OVERNIGHT: {
    ALL_SETTINGS: 'orders.overnight',
    FLAT_RATE: appendMoveTypeKey<`orders.overnight.flat_rate.${MoveTypeShort}`>(
      (key) => `orders.overnight.flat_rate.${key}`,
    ),
    MAX_DAYS: appendMoveTypeKey<`orders.overnight.max_days.${MoveTypeShort}`>(
      (key) => `orders.overnight.max_days.${key}`,
    ),
    TRUCK_DAY: appendMoveTypeKey<`orders.overnight.truck_rate.${MoveTypeShort}`>(
      (key) => `orders.overnight.truck_rate.${key}`,
    ),
    CHARGE_PER_TRUCK: appendMoveTypeKey<`orders.overnight.charge_per_truck.${MoveTypeShort}`>(
      (key) => `orders.overnight.charge_per_truck.${key}`,
    ),
    CHARGE_PER_ORDER: appendMoveTypeKey<`orders.overnight.charge_once_per_order.${MoveTypeShort}`>(
      (key) => `orders.overnight.charge_once_per_order.${key}`,
    ),
    ADDITIONAL_NIGHT_FEE: appendMoveTypeKey<`orders.overnight.additional_night_fee.${MoveTypeShort}`>(
      (key) => `orders.overnight.additional_night_fee.${key}`,
    ),
  },
  LONG_DISTANCE: {
    ACCEPT_ALL_QUOTES: 'ld.accept_all_quotes',
  },
  LONG_DISTANCE_METHOD: {
    RESIDENTIAL: 'ld.method.res',
    COMMERCIAL: 'ld.method.com',
    MILITARY: 'ld.method.mil',
  },
  LONG_DISTANCE_ACTIVE_MILEAGE_TARIFF: {
    RESIDENTIAL: 'ld.active_mileage_tariff.res',
    COMMERCIAL: 'ld.active_mileage_tariff.com',
    MILITARY: 'ld.active_mileage_tariff.mil',
  },
  LABOR_ONLY: 'LaborOnlySetting',
  PACKING_CHARGE_TYPE: {
    RESIDENTIAL: 'company_services.packing.charge_type.res',
    COMMERCIAL: 'company_services.packing.charge_type.com',
    MILITARY: 'company_services.packing.charge_type.mil',
  },
  GOOGLE_API_KEY: 'gcm.google_api_key',
  ORDERS_TRUCK_SCHEDULE_WINDOW_PLACEMENT: 'orders.TruckScheduleWindowPlacement',
  FLOATING_MENU_SETTING: 'ci.floating_menu',
  ShowToCustomerSetting: 'ShowToCustomerSetting',
  JOB_TIME_TOOLTIP: 'jt',
  LABOR_TIME_TOOLTIP: 'lt',
  FUEL_SURCHARGE_TOOLTIP: 'fs',
  TRAVEL_TIME_TOOLTIP: 'tt',
  REMINDERS: 'reminders',
  REMINDERS_NO_ACTIVITY_NEW: 'reminders.noActivity.NEW',
  REMINDERS_NO_ACTIVITY_FOLLOW_UP: 'reminders.noActivity.FOLLOW_UP',
  REMINDERS_NO_ACTIVITY_CAN_BOOK_ONLINE: 'reminders.noActivity.CAN_BOOK_ONLINE',
  REMINDERS_AFTER_MOVE_COMPLETED: 'reminders.afterMove.COMPLETED',
  MOVEBOARD_ONE_URL: 'moveboard_one.url',
  MOVEBOARD_ONE_INTEGRATION_ENABLE: 'moveboard_one.enable',
  INHOME_SURVERY: 'inhome_survey',
  CONFIG_WIZARD_DONE: 'config_wizard_done',
  COMPANY_HTML_FONT_SIZE_SETTING_NAME: 'ci.html_font_size',
  ENABLED_GOOGLE_CALENDAR: 'calendar.enable',
  PROPERTY_TYPE: 'PropertyType',
  REMINDERS_INVOICE_OVERDUE: 'reminders.invoice.overdue',
  REMINDERS_INVOICE_REGULAR: 'reminders.invoice.regular',
  DISPATCH_DECLINE_SCHEDULE: 'dispatch.declineSchedule',
  DISPATCH_ADD_ADDITIONAL_STOPS_ON_CONTRACT: 'dispatch.addAdditionalStopsOnContract',
  DISPATCH_CONTRACT_LABOR_INTERVAL_METHOD: 'dispatch.contractLaborIntervalInSeconds',
  DISPATCH_HOURLY_RATE_CONFIRMATION: appendMoveTypeKey<`dispatch.hourly_rate_confirmation.${MoveTypeShort}`>(
    (key) => `dispatch.hourly_rate_confirmation.${key}`,
  ),
  DISPATCH_PAY_ON_DELIVERY: appendMoveTypeKey<`dispatch.payOnDelivery.${MoveTypeShort}`>(
    (key) => `dispatch.payOnDelivery.${key}`,
  ),
  DISPATCH_INVOICE_ON_DELIVERY: appendMoveTypeKey<`dispatch.invoiceOnDelivery.${MoveTypeShort}`>(
    (key) => `dispatch.invoiceOnDelivery.${key}`,
  ),
  TIME_WINDOW_RESTRICTION_DISPLAY_OPTION: {
    RESIDENTIAL: 'orders.timeWindowRestriction.displayOption.enabled.res',
    COMMERCIAL: 'orders.timeWindowRestriction.displayOption.enabled.com',
    MILITARY: 'orders.timeWindowRestriction.displayOption.enabled.mil',
  },
  TIME_WINDOW_RESTRICTION_POPUP_INFO: {
    RESIDENTIAL: 'orders.timeWindowRestriction.popupInfo.enabled.res',
    COMMERCIAL: 'orders.timeWindowRestriction.popupInfo.enabled.com',
    MILITARY: 'orders.timeWindowRestriction.popupInfo.enabled.mil',
  },
} as const;

export default settingName;
