import { AdditionalContact, OrderStatusIdType, ReferredVia, statusIds, toDate } from '@elromcoinc/react-shared';
import { List, Record } from 'immutable';

import ServiceSummary from 'admin/entities/ServiceSummary';

import RangeValue from './RangeValue';

interface OrderSummaryProps {
  id: number;
  orderId: number;
  orderNumber: string;
  status: OrderStatusIdType;
  companyId: number;
  companyName: string;
  leadScore: number;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  contactEmail: string;
  customerActive: boolean;
  createDate: string;
  leadSource: number;
  updatedDate: string;
  referredVia: ReferredVia | null;
  note: string | null;
  serviceSummaries: List<ServiceSummary>;
  flags: List<any>;
  sizeDescription: string;
  estimatedQuoteRange: RangeValue;
  startDate: ReturnType<typeof toDate>;
  moveType: string;
  crewSize: number;
  activeCuFt: number;
  activeWeight: number;
  multiDay: boolean;
  numberOfServices: number;
  quoteDateTime: string;
  totalNumberOfServices: number | null;
  updatedBy: string;
  numberOfTrucks: number;
  assignedTo: string | null;
  additionalContact: AdditionalContact;
  hasInventory: boolean;
  accountType: string;
  primaryEstimateType: string;
  lastActivityMessage: string;
  totalEmployeeUnreadMessages: number;
  bookingDate: string | null;
  storageMoveStage: string | null;
  fromAddress: string;
  toAddress: string;
  totalPaid: number;
  totalEstimatedBalance: number;
}

export default class OrderSummary extends Record<OrderSummaryProps>({
  id: 0,
  orderId: 0,
  orderNumber: '',
  status: statusIds.DRAFT,
  companyId: 1,
  companyName: '',
  leadScore: 0,
  customerName: '',
  customerPhone: '',
  customerEmail: '',
  contactEmail: '',
  customerActive: false,
  createDate: '',
  leadSource: 0,
  updatedDate: '',
  referredVia: null,
  note: null,
  serviceSummaries: List(),
  flags: List(),
  sizeDescription: '',
  estimatedQuoteRange: RangeValue.createRangeValue(),
  startDate: null,
  moveType: '',
  crewSize: 0,
  activeCuFt: 0,
  activeWeight: 0,
  multiDay: false,
  numberOfServices: 0,
  quoteDateTime: '',
  totalNumberOfServices: null,
  updatedBy: '',
  numberOfTrucks: 0,
  assignedTo: null,
  additionalContact: AdditionalContact.createAdditionalContact(),
  hasInventory: false,
  accountType: '',
  primaryEstimateType: '',
  lastActivityMessage: '',
  totalEmployeeUnreadMessages: 0,
  bookingDate: null,
  storageMoveStage: null,
  fromAddress: '',
  toAddress: '',
  totalPaid: 0,
  totalEstimatedBalance: 0,
}) {
  static createOrderSummary(summary: Partial<OrderSummaryProps> = {}) {
    const {
      serviceSummaries,
      estimatedQuoteRange,
      additionalContact,
      flags,
      fromAddress,
      toAddress,
      numberOfTrucks,
      totalEmployeeUnreadMessages,
      customerName,
      sizeDescription,
      ...rest
    } = summary;
    return new OrderSummary(rest)
      .set('id', rest.orderId as number)
      .set('startDate', toDate(rest.startDate))
      .set('fromAddress', fromAddress as string)
      .set('customerName', customerName as string)
      .set('sizeDescription', sizeDescription as string)
      .set('numberOfTrucks', numberOfTrucks as number)
      .set('totalEmployeeUnreadMessages', totalEmployeeUnreadMessages as number)
      .set('toAddress', toAddress as string)
      .set('estimatedQuoteRange', RangeValue.createRangeValue(estimatedQuoteRange))
      .set('serviceSummaries', List((serviceSummaries || []).map(ServiceSummary.createServiceSummary)))
      .set('additionalContact', AdditionalContact.createAdditionalContact(additionalContact))
      .set(
        'flags',
        List(flags || []).sortBy((it) => it.sequenceOrder),
      );
  }

  getSummary() {
    if (this.serviceSummaries.size === 1)
      return {
        ...this.serviceSummaries.get(0)?.toJS(),
        sizeDescription: this.sizeDescription,
      };
    return this.serviceSummaries.get(0);
  }
}
