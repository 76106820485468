import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { Button, Form, TextInput, zipCodeSyncYUP } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import locationAPI from 'admin/api/LocationApi';
import smsAPI from 'admin/api/SmsAPI';
import SettingName from 'admin/constants/SettingName';
import { formatCurrency } from 'common/utils/Formatter';

const FULL_NAME = 'fullName';
const POSTAL_CODE = 'postalCode';
const STATE = 'state';
const CITY = 'city';
const CARD_NUMBER = 'cardNumber';

const labels = {
  [FULL_NAME]: 'Full name',
  [POSTAL_CODE]: 'ZIP',
  [STATE]: 'State',
  [CITY]: 'City',
  [CARD_NUMBER]: 'Card number',
};

const schemaPostalCode = zipCodeSyncYUP().label(labels[POSTAL_CODE]);

const schemaEnterCardNumber = object().shape({
  [FULL_NAME]: string()
    .label(labels[FULL_NAME])
    .max(40)
    .min(5)
    .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/, 'Please enter the correct first and last name')
    .required(),
  [POSTAL_CODE]: schemaPostalCode,
  [STATE]: string()
    .label(labels[STATE])
    .when(POSTAL_CODE, {
      is: (postalCode) => schemaPostalCode.isValidSync(postalCode),
      then: string().required(),
    }),
  [CITY]: string()
    .label(labels[CITY])
    .when(POSTAL_CODE, {
      is: (postalCode) => schemaPostalCode.isValidSync(postalCode),
      then: string().required(),
    }),
});

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  const handlerElement = (element) => {
    elementRef.current = element;
  };
  return <Component onReady={handlerElement} {...props} />;
};

const { SMS_AUTO_CHARGE_AMOUNT, SMS_ENABLE_AUTO_CHARGE } = SettingName;

export const EnterCardNumberForm = ({
  onSubmit,
  defaultValues,
  firstStepValues,
  allStripeCustomers,
  handleCloseModal,
  isDisabledInProcessing,
  setIsDisabledInProcessing,
}) => {
  const [isProcessing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [checkoutError, setCheckoutError] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const errorMessageOptions = { variant: 'error' };
  const createNewPaymentMethod = allStripeCustomers.length > 0;
  const paymentMethods = getIn(allStripeCustomers, [0, 'paymentMethods'], []);

  const { handleSubmit, control, watch, setValue } = useForm({
    resolver: yupResolver(schemaEnterCardNumber),
    defaultValues,
  });

  const zipValue = watch(POSTAL_CODE);

  useEffect(() => {
    if (zipValue && schemaPostalCode.isValidSync(zipValue)) {
      setProcessing(true);
      locationAPI
        .findAddressByPostalCode(zipValue)
        .catch(() => {
          enqueueSnackbar('The postal code is incorrect. Try again', errorMessageOptions);
          setValue(STATE, '');
          setValue(CITY, '');
        })
        .then((response) => {
          if (response) {
            const { state, city } = response;
            setValue(STATE, state);
            setValue(CITY, city);
          }
          setProcessing(false);
        });
    }
  }, [zipValue]);

  const handleSubmitForm = (data, event) => {
    event.preventDefault();
    onSubmit(data);
    setIsDisabledInProcessing(true);
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const billingDetails = {
      name: data.fullName,
      address: {
        city: data.city,
        postal_code: data.postalCode,
        state: data.state,
      },
    };

    const cardElement = elements.getElement(CardElement);

    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      })
      .then(({ error, paymentMethod }) => {
        if (error) {
          return Promise.reject(error);
        }
        return paymentMethod;
      })
      .catch((error) => {
        setCheckoutError(error.message);
        enqueueSnackbar(`Failed to create a payment method. Try again`, errorMessageOptions);
      })
      .then((paymentMethod) => {
        if (!paymentMethod) {
          return Promise.resolve();
        }

        if (createNewPaymentMethod) {
          return Promise.all(
            paymentMethods.map((item) =>
              smsAPI.deletePaymentCard(item.paymentMethodId).catch(() => {
                enqueueSnackbar(`Can't delete payment card. Try again`, errorMessageOptions);
              }),
            ),
          ).then(() => {
            const payment = {
              paymentMethodId: paymentMethod.id,
              paymentType: 'SMS',
              amount: firstStepValues.amountToCharge,
              cardType: paymentMethod.card.brand,
              last4: paymentMethod.card.last4,
            };
            return smsAPI
              .createPaymentForExistedCustomer(payment)
              .then((res) => {
                enqueueSnackbar('Payment card saved successfully', { variant: 'info' });
                handleCloseModal(
                  { paymentMethods: [{ ...res, ...data, ...payment }] },
                  +firstStepValues.amountToCharge,
                );
              })
              .catch(() => {
                enqueueSnackbar('Failed to charge amount. Try again', errorMessageOptions);
              });
          });
        }

        if (!createNewPaymentMethod) {
          smsAPI
            .saveSmsAutoCharge({
              enableAutoCharge: firstStepValues[SMS_ENABLE_AUTO_CHARGE],
              autoChargeAmount: firstStepValues[SMS_AUTO_CHARGE_AMOUNT],
            })
            .then(() => {
              handleCloseModal();
              enqueueSnackbar('Credit card charge has been submitted', { variant: 'info' });
            })
            .catch(() => {
              enqueueSnackbar('Auto charge amount has not been saved. Try again', errorMessageOptions);
            });
          const payment = {
            paymentMethodId: paymentMethod.id,
            paymentType: 'SMS',
            amount: firstStepValues.amountToCharge,
            cardType: paymentMethod.card.brand,
            last4: paymentMethod.card.last4,
          };
          return smsAPI.createNewStripeCustomer(payment).catch(() => {
            enqueueSnackbar('Failed to charge amount. Try again', errorMessageOptions);
          });
        }
        return Promise.resolve();
      })
      .then(() => {
        setProcessing(false);
        setIsDisabledInProcessing(false);
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    iconStyle: 'solid',
    showIcon: true,
    hidePostalCode: true,
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} noValidate={false}>
      <Grid container spacing={2}>
        <Grid item sm={12} xs={12}>
          <TextInput
            label={labels[CARD_NUMBER]}
            name={CARD_NUMBER}
            autoFocus
            fullWidth
            formError={checkoutError}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: { component: CardElement, options: CARD_ELEMENT_OPTIONS },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <TextInput
            label={labels[FULL_NAME]}
            name={FULL_NAME}
            placeholder="First name and last name"
            fullWidth
            control={control}
          />
        </Grid>
        <Grid item sm={3} xs={3}>
          <TextInput
            label={labels[POSTAL_CODE]}
            fullWidth
            name={POSTAL_CODE}
            placeholder="Postal code"
            control={control}
            inputProps={{ min: '0', max: '99999' }}
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <TextInput
            label={labels[CITY]}
            name={CITY}
            fullWidth
            disabled
            defaultValue="City, district, suburb or town"
            control={control}
          />
        </Grid>
        <Grid item sm={3} xs={3}>
          <TextInput label={labels[STATE]} name={STATE} defaultValue="State" disabled fullWidth control={control} />
        </Grid>
      </Grid>
      {((isProcessing && zipValue) || isDisabledInProcessing) && (
        <Box mt={2}>
          <LinearProgress />
        </Box>
      )}
      <Box mt={2}>
        <Button
          size="small"
          color="primary"
          type="submit"
          disabled={!stripe || isProcessing || isDisabledInProcessing}
          fullWidth
          loading={isProcessing || isDisabledInProcessing}
        >
          {`Pay $ ${formatCurrency(firstStepValues.amountToCharge, 2)}`}
        </Button>
      </Box>
    </Form>
  );
};

EnterCardNumberForm.propTypes = {
  onSubmit: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: pt.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  firstStepValues: pt.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array,
  handleCloseModal: pt.func.isRequired,
  isDisabledInProcessing: pt.bool,
  setIsDisabledInProcessing: pt.func,
};

EnterCardNumberForm.defaultProps = {
  firstStepValues: null,
  allStripeCustomers: null,
  isDisabledInProcessing: false,
  setIsDisabledInProcessing: false,
};
