import React from 'react';

import { BodyText, Link } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { ServiceTypeStats } from 'admin/components/Reports/GeneralStatisticsTab';
import {
  DataTableColumnAttributes,
  DataTableColumnNames,
  SelectedOrderFiltersType,
  renderBoundaryValues,
} from 'admin/components/Reports/config';

const totalService = 'Totals';
const isTotalServices = (serviceName: string) => serviceName === totalService;

const makeGeneralStatisticsServiceTypeColumns = (
  rowsData: ServiceTypeStats[],
  { setSelectedOrderFilters, isMobile }: { setSelectedOrderFilters: SelectedOrderFiltersType; isMobile?: boolean },
) => [
  {
    name: 'serviceName',
    label: 'TYPE',
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { serviceName } = rowsData[rowIndex];

        return <BodyText>{serviceName}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS],
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersInPeriod, serviceName } = rowsData[rowIndex];

        if (isTotalServices(serviceName)) {
          return <BodyText className="total-column">{totalOrdersInPeriod}</BodyText>;
        }

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalOrdersInPeriod > 0) {
            setSelectedOrderFilters({ serviceType: serviceName });
          }
        };

        return totalOrdersInPeriod === 0 ? (
          <BodyText>{totalOrdersInPeriod}</BodyText>
        ) : (
          <Box minWidth={isMobile ? '50px' : 'inherit'}>
            <Link onClick={handleOpenModal} underline="none">
              <BodyText>
                <b>{totalOrdersInPeriod}</b>
              </BodyText>
            </Link>
          </Box>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_ESTIMATE_MIN,
    label: 'SALES ESTIMATE',
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalEstimateMin, totalEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalEstimateMin, totalEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_JOBS,
    label: <>BOOKED&nbsp;</>,
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedInPeriod, serviceName } = rowsData[rowIndex];
        const isTotal = isTotalServices(serviceName);

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalBookedInPeriod > 0) {
            setSelectedOrderFilters({ serviceType: serviceName, status: ['BOOKED'] });
          }
        };

        return isTotal || totalBookedInPeriod === 0 ? (
          <BodyText>{totalBookedInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalBookedInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_MIN,
    label: 'SALES BOOKED',
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedEstimateMin, totalBookedEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalBookedEstimateMin, totalBookedEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.CONVERSION,
    label: 'CONVERSION',
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { conversionRateForPeriod } = rowsData[rowIndex];
        const { asPercentage } = conversionRateForPeriod;

        return <BodyText>{asPercentage}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_MULTI_DAY_COUNT,
    label: DataTableColumnNames[DataTableColumnAttributes.TOTAL_MULTI_DAY_COUNT],
    options: {
      customBodyRender: (_: ServiceTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { multidayCount } = rowsData[rowIndex];
        return <BodyText>{multidayCount}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makeGeneralStatisticsServiceTypeColumns };
