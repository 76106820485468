import React, { Suspense, useEffect } from 'react';

import {
  AccountsPermissions,
  AdminPermissions,
  ElromcoCircularProgress,
  ReportsPermissions,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getLeadScoreSettings, getOrderWindowSettings, useAppDispatch } from 'redux-conf';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { fetchSmsSettings } from 'admin/autodux/MessageAutodux';
import Bar from 'admin/components/Bar';
import DashboardPage from 'admin/components/Dashboard';
import Navigation from 'admin/components/Navigation';
import { lazyWithRetry } from 'admin/hooks';

const useStyles = makeStyles(({ breakpoints, palette }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: palette.background.default,
  },
  progress: {
    position: 'relative',
    top: 'calc(50% - 20px)',
    left: 'calc(50% - 20px)',
  },
  contentRoot: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    height: 'calc(100vh - 56px)',
    '@media (min-width:0px) and (orientation: landscape)': {
      height: 'calc(100vh - 48px)',
    },
    [breakpoints.only('xs')]: {
      height: 'calc(100vh - 48px)',
    },
    '@media (min-width:600px)': {
      height: 'calc(100vh - 73px)',
    },
    [breakpoints.only('sm')]: {
      height: 'calc(100vh - 73px)',
    },
  },
  content: {
    flex: 1,
    backgroundColor: palette.background.default,
    position: 'relative',
    overflowY: 'scroll',
  },
}));

const SettingsPage = lazyWithRetry(() => import('admin/components/Settings'));
const OrdersPage = lazyWithRetry(() => import('admin/components/Orders'));
const AccountsPage = lazyWithRetry(() => import('admin/components/Accounts'));
const CalendarPage = lazyWithRetry(() => import('admin/components/Calendar/index'));
const CommunicationPage = lazyWithRetry(() => import('admin/components/Communication'));
const DispatchPage = lazyWithRetry(() => import('admin/components/Dispatch'));
const Accounting = lazyWithRetry(() => import('admin/components/Accounting'));
const Tasks = lazyWithRetry(() => import('admin/components/TasksPage'));
const InvoicesPage = lazyWithRetry(() => import('admin/components/Invoices'));
const ReportsPage = lazyWithRetry(() => import('admin/components/Reports'));
const SupportPage = lazyWithRetry(() => import('admin/components/Support'));

// legacy components, moving here so we can keep track, may need to check them after MVP...
// const EmailsViewPage = lazy(() => import('admin/components/EmailsView'));
// const DispatchPage = lazy(() => import('admin/components/Dispatch/Dispatch'));
// const ClaimsPage = lazy(() => import('admin/components/Claims'));
// import DescriptionIcon from '@material-ui/icons/Description';
// import ReceiptIcon from '@material-ui/icons/Receipt';
// import DispatchIcon from '@material-ui/icons/LocalShippingOutlined';
// import EmailIcon from '@material-ui/icons/MailOutline';

export default function AdminBar() {
  const classes = useStyles();
  const canSeeAccountsPage = useHasPermission(AccountsPermissions.PERM_CAN_SEE_ACCOUNTS_TAB);
  const canSeeCalendarPage = useHasPermission(AdminPermissions.PERM_CAN_SEE_CALENDAR_TAB);
  const canSeeCommunicationPage = useHasPermission(AdminPermissions.PERM_CAN_SEE_COMMUNICATION_TAB);
  const canSeeReportsPage = useHasPermission(ReportsPermissions.PERM_CAN_SEE_REPORTS_TAB_AS_ADMIN);
  const canSeeSettingsPage = useHasPermission(AdminPermissions.PERM_CAN_SEE_SETTINGS_TAB);
  const canSeeDispatchTab = useHasPermission(AdminPermissions.PERM_CAN_SEE_DISPATCH_TAB);
  const canSeePayrollTab = useHasPermission(AdminPermissions.PERM_VIEW_PAYROLL_TAB);
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setTimeout(() => {
        dispatch(fetchSmsSettings());
        dispatch(getOrderWindowSettings());
        dispatch(getLeadScoreSettings());
      }, 1000);
    }
  }, [isSessionExpired, isLogout]);

  function handleMenuOpen() {
    setOpen(true);
  }

  function handleMenuClose() {
    setOpen(false);
  }

  const renderRoute = (path, component) => <Route key={path} path={path} component={component} />;

  return (
    <div className={classes.root}>
      <Bar open={open} openNavigation={handleMenuOpen} />
      <div className={classes.contentRoot}>
        <Navigation open={open} closeNavigation={handleMenuClose} />
        <main className={classes.content}>
          <Suspense fallback={<ElromcoCircularProgress className={classes.progress} />}>
            <Switch>
              {[
                renderRoute('/dashboard', DashboardPage),
                renderRoute('/orders', OrdersPage),
                canSeeAccountsPage && renderRoute('/accounts', AccountsPage),
                canSeeCalendarPage && renderRoute('/calendar', CalendarPage),
                canSeeCommunicationPage && renderRoute('/communications', CommunicationPage),
                renderRoute('/tasks', Tasks),
                canSeeDispatchTab && renderRoute('/dispatch', DispatchPage),
                canSeePayrollTab && renderRoute('/accounting', Accounting),
                renderRoute('/invoices', InvoicesPage),
                canSeeReportsPage && renderRoute('/reports', ReportsPage),
                canSeeSettingsPage && renderRoute('/settings', SettingsPage),
                renderRoute('/support', SupportPage),
              ]}
              <Redirect to="/dashboard" push />
            </Switch>
          </Suspense>
        </main>
      </div>
    </div>
  );
}
