import React, { FC, useCallback, useEffect, useState } from 'react';

import {
  BaseAddressInputs,
  Button,
  IconButton,
  LaborType,
  LaborTypeName,
  Modal,
  Order,
  ParkingTypeOptions,
  PropertyTypeDto,
  Select,
  Switch,
  TextInput,
  Waypoint,
  usePrevious,
  zipCodeAsyncYUP,
  zipCodeSyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment, MenuItem, Theme, createStyles, makeStyles } from '@material-ui/core';
import PencilIcon from '@material-ui/icons/Create';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import ParkingIcon from '@material-ui/icons/LocalParking';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import RoomIcon from '@material-ui/icons/Room';
import { formatISO } from 'date-fns';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { boolean, number, object, string } from 'yup';

import geoApi from 'admin/api/GeoAPI';
import { COUNTRY } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { formatTime } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { usePropertyTypeOptions } from 'admin/components/OrderWindow/hooks';
import {
  ACCESS_RESTRICTION_APPLIES,
  ACCESS_RESTRICTION_INFO,
  ADDRESS,
  APARTMENT,
  BasicAddressType,
  CITY,
  COI_RECIPIENT,
  COI_SENTS,
  COMMENT,
  CertificateOfInsuranceModal,
  ELEVATOR,
  END_TIME,
  ENTRANCE_TYPE,
  LABOR_TYPE,
  LATITUDE,
  LOADING_DOCK,
  LOCATION_ACCESS_TYPE,
  LONGITUDE,
  MapBar,
  OTHER,
  PARKING_DISTANCE,
  PARKING_TYPE,
  POSTAL_CODE,
  PROPERTY_TYPE,
  REQUIRES_COI,
  START_TIME,
  STATE,
  STREET,
  TimeWindowRestriction,
  USE_CUSTOMER_FOR_COI,
  WAYPOINT_ID,
  addressFieldNames,
  addressLabels,
  apartmentAddressPath,
  fullAddressPath,
  getCoiMessage,
  labels,
  propertyTypePath,
  streetAddressPath,
} from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { GOOGLE_DIRECTION } from 'admin/constants';
import { getElevationReduction, getParkingDistanceOptions } from 'admin/selectors';
import { getAuthUser } from 'admin/selectors/auth';
import { AddressInfo, getFullAddress } from 'admin/utils';
import { getLabel } from 'admin/utils/getLabel';
import { mergeStops } from 'admin/utils/mergeStop';
import GoogleAutoComplete from 'common/components/GoogleAutoComplete';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
  }),
);

const zipCodeSchema = zipCodeSyncYUP();

const schema = object().shape({
  [WAYPOINT_ID]: string().nullable(),
  [LABOR_TYPE]: string().label(labels[LABOR_TYPE]).nullable(),
  [ADDRESS]: object().shape({
    [STREET]: string().label(labels[ADDRESS][STREET]).max(99).nullable(),
    [APARTMENT]: string().label(labels[ADDRESS][APARTMENT]).max(10).nullable(),
    [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE).label(addressLabels[POSTAL_CODE]).required().nullable(),
    [LATITUDE]: number().nullable(),
    [LONGITUDE]: number().nullable(),
  }),
  [ENTRANCE_TYPE]: string().label(labels[ENTRANCE_TYPE]).nullable(),
  [PARKING_DISTANCE]: number().label(labels[PARKING_DISTANCE]).required().nullable(),
  [PARKING_TYPE]: string().label(labels[PARKING_TYPE]).nullable(),
  [ACCESS_RESTRICTION_APPLIES]: boolean().label(labels[ACCESS_RESTRICTION_APPLIES]),
  [ACCESS_RESTRICTION_INFO]: object()
    .nullable()
    .when([ACCESS_RESTRICTION_APPLIES], (accessRestrictionApplies, s) =>
      accessRestrictionApplies
        ? s.shape({
            [LOCATION_ACCESS_TYPE]: string().oneOf(
              [ELEVATOR, LOADING_DOCK, OTHER],
              'Please select one of location access type',
            ),
            [START_TIME]: string().label(labels[ACCESS_RESTRICTION_INFO][START_TIME]).nullable(),
            [END_TIME]: string().label(labels[ACCESS_RESTRICTION_INFO][END_TIME]).nullable(),
            [COMMENT]: string().label(labels[ACCESS_RESTRICTION_INFO][COMMENT]).nullable(),
          })
        : s,
    ),
  [REQUIRES_COI]: boolean().label(labels[REQUIRES_COI]),
  [USE_CUSTOMER_FOR_COI]: boolean().label(labels[USE_CUSTOMER_FOR_COI]),
});

const allLaborTypeOptions: SelectOptions = [
  [LaborType.LOADING, LaborTypeName[LaborType.LOADING]],
  [LaborType.UNLOADING, LaborTypeName[LaborType.UNLOADING]],
  [LaborType.LOADING_AND_UNLOADING, LaborTypeName[LaborType.LOADING_AND_UNLOADING]],
];

export enum Specification {
  COI = 'COI',
  TWR = 'TWR',
}

export interface CoiSentsInfo {
  employeeId: number;
  employeeName: string;
  whenSent: string;
}

interface FullAddressesProps {
  onSave: (waypoint: Waypoint, index?: number) => void;
  onCancel: () => void;
  isStart: boolean;
  isDestination: boolean;
  waypoint: Waypoint;
  hideStopType?: boolean;
  isEdit?: boolean;
  open: boolean;
}

const FullAddressesModal: FC<FullAddressesProps> = ({
  onSave,
  onCancel,
  isStart,
  isDestination,
  waypoint,
  hideStopType = true,
  isEdit = false,
  open,
}) => {
  const { order } = useOrderState() as { order: Order };
  const { isClosing, closingTitle } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail! : order) as Order;
  const moveType = order?.moveType!;
  const orderWindowEditAddressIndex = waypoint.waypointIndex;
  const settings = useOrderWindowSettings();
  const elevationReductionOptions = getElevationReduction(settings)[moveType];
  const parkingDistanceOptions = getParkingDistanceOptions(settings)[moveType];
  const classes = useStyles();
  const [position, setPosition] = useState({});
  const [openModal, setOpenModal] = useState<Specification | null>(null);
  const [laborTypeOptions, setLaborTypeOptions] = useState(allLaborTypeOptions);
  const isOpenCOIModal = openModal === Specification.COI;
  const isOpenTWRModal = openModal === Specification.TWR;
  const user = useSelector(getAuthUser) || {};
  const propertyTypeOptions = usePropertyTypeOptions();

  const entranceOptions: SelectOptions = (elevationReductionOptions || []).map(([id, label]: [string, string]) => [
    `${id}`,
    label,
  ]);

  const formMethods = useForm<FieldValues>({
    defaultValues: waypoint.toJS(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { control, handleSubmit, setValue, watch, getValues, trigger } = formMethods;

  const data = getValues();

  const zipValue = watch(`${ADDRESS}.${POSTAL_CODE}`);
  const cityValue = watch(`${ADDRESS}.${CITY}`);
  const stateValue = watch(`${ADDRESS}.${STATE}`);
  const country = watch(`${ADDRESS}.${COUNTRY}`);
  const latitudeAddressValue = watch(`${ADDRESS}.${LATITUDE}`);
  const longitudeAddressValue = watch(`${ADDRESS}.${LONGITUDE}`);
  const restrictionValue = watch(ACCESS_RESTRICTION_APPLIES);
  const restrictionInfoValue = watch(ACCESS_RESTRICTION_INFO);
  const useCustomerCoiValue = watch(USE_CUSTOMER_FOR_COI);
  const requiresCoiValue = watch(REQUIRES_COI);
  const coiSentsValue = watch(COI_SENTS);
  const previousRequiresCoi = usePrevious(requiresCoiValue, requiresCoiValue);

  const isDisabledGoogleMapButton = !zipValue || !zipCodeSchema.isValidSync(zipValue);

  const editRequiresCoi = useCallback(() => {
    setOpenModal(Specification.COI);
  }, [setOpenModal]);

  useEffect(() => {
    if (isStart) {
      setLaborTypeOptions([allLaborTypeOptions[0]]);
    } else if (isDestination) {
      setLaborTypeOptions([allLaborTypeOptions[1]]);
    } else {
      setLaborTypeOptions([...allLaborTypeOptions]);
    }
  }, [isStart, isDestination]);

  useEffect(() => {
    if (restrictionValue && !restrictionInfoValue) {
      setOpenModal(Specification.TWR);
    }
  }, [restrictionValue, restrictionInfoValue]);

  useEffect(() => {
    if (zipValue && zipCodeSchema.isValidSync(zipValue)) {
      const fullAddress = getFullAddress(data.address);

      geoApi
        .getGeoAddress(fullAddress)
        .then((res: AddressInfo) => {
          setValue(`${ADDRESS}.${LATITUDE}`, res[LATITUDE]);
          setValue(`${ADDRESS}.${LONGITUDE}`, res[LONGITUDE]);
        })
        .catch(() => {});
    }
  }, [zipValue, cityValue, stateValue]);

  const handleChangeAddress = useCallback((address) => {
    (Object.entries(address) as [BasicAddressType, string][]).forEach(([key, value]) => {
      setValue(`${ADDRESS}.${key}`, value);
    });
  }, []);

  useEffect(() => {
    if (latitudeAddressValue && longitudeAddressValue) {
      setPosition({ lat: latitudeAddressValue, lng: longitudeAddressValue });
    }
  }, [longitudeAddressValue, latitudeAddressValue]);

  useEffect(() => {
    if (requiresCoiValue && useCustomerCoiValue) {
      setValue(REQUIRES_COI, true);
    }
  }, [requiresCoiValue, useCustomerCoiValue]);

  useEffect(() => {
    if (requiresCoiValue && !previousRequiresCoi) {
      editRequiresCoi();
    }
  }, [requiresCoiValue, previousRequiresCoi, editRequiresCoi]);

  const handleOpenCoiModal = () => {
    if (requiresCoiValue) {
      setOpenModal(Specification.COI);
    }
  };

  const handleSaveCOI = (data: Waypoint) => {
    const customerCertificate = {
      ...data.coiRecipient,
      firstName: order.contactInfo.firstName,
      lastName: order.contactInfo.lastName,
      email: order.contactInfo.email,
      phoneNumber: order.contactInfo.primaryPhone.number,
    };
    setValue(COI_RECIPIENT, !data.useCustomerForCoi ? data.coiRecipient : customerCertificate);
    setValue(USE_CUSTOMER_FOR_COI, data.useCustomerForCoi);
    setOpenModal(null);
  };

  const handleCloseCOIModal = () => {
    if (!data.useCustomerForCoi && data.coiRecipient === null) {
      setValue(REQUIRES_COI, false);
    }
    setOpenModal(null);
  };

  useEffect(() => {
    if (coiSentsValue === null) {
      setValue(COI_SENTS, []);
      trigger(COI_SENTS);
    }
  }, [coiSentsValue]);

  const handleSendCoiMessage = () => {
    const val = [
      ...data?.coiSents,
      {
        employeeId: user.id,
        employeeName: user.id ? user.username : 'System',
        whenSent: formatISO(new Date()).substring(0, 19),
      },
    ];

    setValue(COI_SENTS, val);
  };

  const handleOpenTWRModal = () => {
    setOpenModal(Specification.TWR);
  };

  const handleSaveTimeWindowRestriction = (data: Waypoint) => {
    const result = {
      ...data,
      accessRestrictionInfo: {
        ...data.accessRestrictionInfo,
        startTime: formatTime(data?.accessRestrictionInfo?.startTime),
        endTime: formatTime(data?.accessRestrictionInfo?.endTime),
      },
    };

    setValue(ACCESS_RESTRICTION_INFO, result.accessRestrictionInfo);
    setOpenModal(null);
  };

  const handleCancelTimeWindowRestriction = () => {
    if (!restrictionInfoValue) {
      setValue(ACCESS_RESTRICTION_APPLIES, false);
    }
    setOpenModal(null);
  };

  const handleSubmitForm = (data: Waypoint) => {
    onSave(new Waypoint(data));
  };

  const cancelHandler = () => {
    onCancel();
  };

  const modalActions = [
    { label: 'cancel', onClick: cancelHandler },
    {
      label: 'save',
      onClick: handleSubmit(handleSubmitForm as any),
    },
  ];

  const onClickOpenMap = () => {
    const firstDayCompanyStops = order
      .getServiceWaypoints()
      .filter(Waypoint.isCompanyStop)
      .map((w) => w.setIn(['address', 'street2'], null));
    const waypoints = orderDetail.getAddressWaypointsBasedOnService(waypoint.serviceIndex);

    const address = encodeURIComponent([cityValue, stateValue, zipValue, country].filter(Boolean).join(', '));
    const stopsWithCompanyAddresses = mergeStops(firstDayCompanyStops, waypoints);

    const path = isEdit
      ? stopsWithCompanyAddresses.map((it: Waypoint, idx: number) =>
          idx === (orderWindowEditAddressIndex || 1) + 1 ? address : encodeURIComponent(it.address.fullAddressLine()),
        )
      : stopsWithCompanyAddresses
          .map((wp) => encodeURIComponent(wp.address.fullAddressLine()))
          .insert((orderWindowEditAddressIndex || 1) * -1 + 1, address);

    window.open(`${GOOGLE_DIRECTION}${path.join('/')}`, '_blank');
  };

  const onClickOpenSingleAddressMap = () => {
    const companyAddress = order.getServiceWaypoints().filter(Waypoint.isCompanyStop).first();
    const companyAddressLine = encodeURIComponent(companyAddress?.address?.set('street2', null)?.fullAddressLine());

    const address = encodeURIComponent([cityValue, stateValue, zipValue, country].filter(Boolean).join(', '));
    const path = (
      orderWindowEditAddressIndex === 1 ? [companyAddressLine, address] : [address, companyAddressLine]
    ).filter(Boolean);

    window.open(`${GOOGLE_DIRECTION}${path.join('/')}`, '_blank');
  };

  const onChangePropertyType = (propertyType: PropertyTypeDto | null) => () => {
    setValue(PROPERTY_TYPE, propertyType);
  };

  const title = isEdit ? 'Edit Address' : 'Add Address';

  return (
    <Modal
      title={`${closingTitle}${title}`}
      onClose={onCancel}
      maxWidth="lg"
      open={open}
      color="grey"
      actions={modalActions}
    >
      {/*@ts-ignore*/}
      <FormProvider {...formMethods}>
        <Box className={classes.wrapper}>
          <MapBar position={position} />
          <Box>
            <Box mx={2} mb={2}>
              <Grid container spacing={1} alignItems="center">
                {!hideStopType && (
                  <Grid item xs={12} sm={4}>
                    <Select
                      primaryBackgroundOnSelectedItem
                      fullWidth
                      size="small"
                      options={laborTypeOptions || []}
                      label={labels[LABOR_TYPE]}
                      name={LABOR_TYPE}
                      control={control}
                    />
                  </Grid>
                )}
                <Grid container justifyContent="center" item xs={6} md={4}>
                  <Button
                    color="primary"
                    textTransform="initial"
                    size="small"
                    onClick={onClickOpenSingleAddressMap}
                    disabled={isDisabledGoogleMapButton}
                  >
                    Google Map-This Address
                  </Button>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Button
                    color="primary"
                    size="small"
                    textTransform="initial"
                    onClick={onClickOpenMap}
                    disabled={isDisabledGoogleMapButton}
                  >
                    Google Map-All Addresses
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Box mx={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={10}>
                  <GoogleAutoComplete
                    country={country}
                    onChangeAddress={handleChangeAddress}
                    renderInput={(inputRef) => (
                      <TextInput
                        fullWidth
                        label={getLabel(streetAddressPath, labels)}
                        name={streetAddressPath}
                        control={control as any}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <RoomIcon color="primary" />
                            </InputAdornment>
                          ),
                          inputRef: inputRef,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextInput
                    fullWidth
                    label={getLabel(apartmentAddressPath, labels)}
                    name={apartmentAddressPath}
                    control={control as any}
                  />
                </Grid>
                <Grid item xs={12} md={!!propertyTypeOptions.length ? 9 : 12}>
                  <BaseAddressInputs fieldNames={addressFieldNames} labels={addressLabels} name={fullAddressPath} />
                </Grid>
                {!!propertyTypeOptions.length && (
                  <Grid item xs={12} md={3}>
                    <Select
                      fullWidth
                      label={labels[PROPERTY_TYPE]}
                      name={propertyTypePath}
                      placeholder="Property Type"
                      control={control}
                      InputProps={{ disableUnderline: true }}
                      InputLabelProps={{ shrink: true }}
                      defaultValue={null}
                      SelectProps={{ displayEmpty: true }}
                    >
                      <MenuItem key={0} value={null!} onClick={onChangePropertyType(null)}>
                        Not Selected
                      </MenuItem>
                      {propertyTypeOptions.map((pt) => (
                        <MenuItem key={pt.id} value={pt.name} onClick={onChangePropertyType(pt)}>
                          {pt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <Select
                    fullWidth
                    primaryBackgroundOnSelectedItem
                    options={entranceOptions || []}
                    label={labels[ENTRANCE_TYPE]}
                    name={ENTRANCE_TYPE}
                    control={control}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MeetingRoomIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    fullWidth
                    primaryBackgroundOnSelectedItem
                    options={parkingDistanceOptions || []}
                    label={labels[PARKING_DISTANCE]}
                    name={PARKING_DISTANCE}
                    control={control}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DirectionsWalkIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    fullWidth
                    primaryBackgroundOnSelectedItem
                    options={ParkingTypeOptions as SelectOptions}
                    label={labels[PARKING_TYPE]}
                    name={PARKING_TYPE}
                    control={control}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ParkingIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box display="flex" alignItems="center" mt={2} flexWrap="nowrap">
                <Switch color="primary" label={labels[REQUIRES_COI]} name={REQUIRES_COI} />
                {requiresCoiValue && (
                  <IconButton color="primary" onClick={handleOpenCoiModal} size="small">
                    <PencilIcon color="primary" />
                  </IconButton>
                )}
                {requiresCoiValue && (
                  <Box ml={1}>
                    <Button color="primary" onClick={handleSendCoiMessage} size="small" rounded>
                      {coiSentsValue && coiSentsValue.length === 0 ? 'Mark as Send' : 'Resend'}
                    </Button>
                  </Box>
                )}
                {coiSentsValue && coiSentsValue.length > 0 && (
                  <Box ml={1} display="flex" flexDirection="column">
                    {getCoiMessage('Sent', coiSentsValue[0])}
                    {coiSentsValue &&
                      coiSentsValue.length > 1 &&
                      getCoiMessage('Resent', coiSentsValue[coiSentsValue.length - 1])}
                  </Box>
                )}
              </Box>
              <Box display="flex" alignItems="center" mt={1}>
                <Switch color="primary" label={labels[ACCESS_RESTRICTION_APPLIES]} name={ACCESS_RESTRICTION_APPLIES} />
                {restrictionValue && (
                  <Button color="primary" rounded onClick={handleOpenTWRModal} size="small">
                    Edit Info
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </FormProvider>
      {isOpenCOIModal && (
        <CertificateOfInsuranceModal
          open
          onSave={handleSaveCOI}
          onCancel={handleCloseCOIModal}
          data={data as Waypoint}
        />
      )}
      {isOpenTWRModal && (
        <TimeWindowRestriction
          open
          onSave={handleSaveTimeWindowRestriction}
          onCancel={handleCancelTimeWindowRestriction}
          data={data as Waypoint}
        />
      )}
    </Modal>
  );
};

export default FullAddressesModal;
