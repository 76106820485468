import React, { useEffect } from 'react';

import { Badge } from '@elromcoinc/react-shared';
import Chat from '@material-ui/icons/ChatBubble';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { fetchUnreadCount, getUnreadCount } from 'admin/autodux/MessageAutodux';

export const CommunicationIcon = () => {
  const dispatch = useDispatch();
  const totalUnread = useSelector(getUnreadCount);
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  const getTotalUnreadMessages = () => {
    dispatch(fetchUnreadCount());
  };

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setTimeout(getTotalUnreadMessages, 1000);
      const interval = setInterval(getTotalUnreadMessages, 40 * 1000);
      return () => clearInterval(interval);
    }
  }, [isSessionExpired, isLogout]);

  return (
    <Badge badgeContent={totalUnread}>
      <Chat fontSize="inherit" color="inherit" />
    </Badge>
  );
};
