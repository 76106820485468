import React, { FC } from 'react';

import { Template } from 'common-types';
import { EditTemplateModal } from 'common/components/EditTemplateModal';

interface EditEmailPreviewProps {
  template: Template | null;
  onClose: () => void;
  onSave: (template: Template) => void;
}

const EditEmailPreview: FC<EditEmailPreviewProps> = ({ template, onClose, onSave }) => {
  const handleSave = (body: string) => {
    if (template) {
      onSave(template.set('body', body));
    }
  };

  return (
    <EditTemplateModal
      template={template?.body || ''}
      onClose={onClose}
      onSave={handleSave}
      useTemplateBuilderVariables
    />
  );
};

export { EditEmailPreview };
