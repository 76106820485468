import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';

import fileAPI from 'admin/api/FileAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getUploadCompanyLogoDate } from 'admin/autodux/CompanyInfoAutodux';
import ImageNames from 'admin/constants/ImageNames';
import { useCompanyBranchName } from 'admin/hooks';
import defaultCompanyLogo from 'common/imgs/logo_small.png';

const useStyles = makeStyles({
  logo: {
    overflow: 'hidden',
    minWidth: '50px',
    minHeight: '50px',
    maxWidth: '50px',
    maxHeight: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
});

const CompanyLogo = () => {
  const classes = useStyles();
  const companyName = useCompanyBranchName();
  const uploadCompanyLogoDate = useSelector(getUploadCompanyLogoDate);
  const [imageSrc, setImageSrc] = useState(defaultCompanyLogo);
  const [inFlight, setInFlight] = React.useState(false);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const fetchImage = (imageName: string) => {
    setInFlight(true);

    fileAPI
      .getImage(imageName)
      .catch(() => {
        setImageSrc(defaultCompanyLogo);
      })
      .then((response) => {
        response && setImageSrc(response);
        setInFlight(false);
      });
  };

  useEffect(() => {
    if (!isSessionExpired) {
      fetchImage(ImageNames.COMPANY_LOGO);
    }
  }, [uploadCompanyLogoDate]);

  return (
    <div className={classes.logo}>
      {inFlight ? (
        <CircularProgress />
      ) : (
        <img className={classes.image} src={imageSrc} alt={`${companyName || ''} logo`} />
      )}
    </div>
  );
};

export default CompanyLogo;
