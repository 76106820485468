import { Truck } from '@elromcoinc/react-shared';
import { areIntervalsOverlapping } from 'date-fns';

import Job from '../Job';

const areSameLevelAndOverlap = (truckId: number, level: number, job: Job) => (anotherJob: Job) =>
  ((anotherJob?.level as any)[truckId] as number) === level &&
  areIntervalsOverlapping(anotherJob.getInterval() as any, job.getInterval() as any);

const calculateLevel = (truckId: number, jobs: Job[], job: Job) => {
  let level = 0;

  while (jobs.filter(areSameLevelAndOverlap(truckId, level, job)).length > 0) {
    level += 1;
  }

  return level;
};

const calculateVerticalLevel = (trucks: Truck[]) => (jobs: Job[]) =>
  jobs
    .map((it) => it.set('level', {}))
    .reduce((acc, job: Job) => {
      const level = trucks.reduce((m, t) => ({ ...m, [t.id!]: 0 }), {} as { [key: string]: number });
      const ids = job.truckIds
        .map((id) => [id, acc.filter((it) => it.truckIds.indexOf(id) >= 0)])
        .reduce(
          (l, [id, truckJobs]) => ({ ...l, [id as number]: calculateLevel(id as number, truckJobs as Job[], job) }),
          level,
        );

      return [...acc, job.set('level', ids)];
    }, [] as Job[]);

export { calculateVerticalLevel };
