import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  Button,
  ElromcoCircularProgress,
  HeaderSmallText,
  IconButton,
  InvoiceDto,
  InvoicesStatusType,
  JoditTextEditor,
  Modal,
  Select,
  TextInput,
  emailYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Paper,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';

import { emailAPI } from 'admin/api';
import invoiceAPI from 'admin/api/InvoiceAPI';
import userAPI from 'admin/api/UserAPI';
import templateApi from 'admin/api/templateApi';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { sendInvoiceEmail } from 'admin/components/OrderWindow/modals/Invoices/sendInvoiceEmail';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import { EmailForm } from 'admin/dtos/EmailFormDto';
import EmployeeRecord from 'admin/entities/Employee';
import { getAuthUser } from 'admin/selectors/auth';
import { ActivityEmail, Template } from 'common-types';
import { BlueVisibilityIcon } from 'common/components/Widgets';
import { getTemplateBuilderVariables, toEmailDTO, toEmailList } from 'common/utils';
import { appendTemplateBuilderStyles } from 'common/utils/appendTemplateBuilderStyles';

import TemplateModal from './TemplateModal';

const variables = getTemplateBuilderVariables();

const useStyles = makeStyles((theme) => ({
  menu: {
    width: '100%',
  },
  templateTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  accordionDetails: {
    padding: theme.spacing(1, 0, 2),
  },
  centeredError: {
    alignItems: 'center',
  },
  name: {
    wordBreak: 'break-word',
  },
}));

interface ComposeEmailProps {
  onSave: () => void;
  onCancel: () => void;
  open: boolean;
  sourceId?: number;
  email?: string;
  sourcesIdEmails?: ActivityEmail[];
  selectedInvoices?: InvoiceDto[];
}

enum ViewModes {
  ALERT = 'ALERT',
  EMAIL_PREVIEW = 'EMAIL_PREVIEW',
  SELECT_TEMPLATE = 'SELECT_TEMPLATE',
}

const RECIPIENTS_NAME = 'recipients';
const CC_LIST_NAME = 'ccList';
const BCC_LIST_NAME = 'bccList';
const SUBJECT_NAME = 'subject';
const BODY_NAME = 'body';

const labels = {
  [RECIPIENTS_NAME]: 'To',
  [CC_LIST_NAME]: 'CC',
  [BCC_LIST_NAME]: 'BCC',
  [SUBJECT_NAME]: 'Subject',
  [BODY_NAME]: 'Body',
};

const emailSchema = emailYUP({ label: 'email' });

const testCommaSeparatedEmails = (emails: any) =>
  !emails || toEmailList(emails).every((e) => emailSchema.isValidSync(e));

const schema = object({
  [SUBJECT_NAME]: string().label(labels[SUBJECT_NAME]).nullable().required(),
  [BODY_NAME]: string().label(labels[BODY_NAME]).nullable().min(3).required(),
  [RECIPIENTS_NAME]: string()
    .label(labels[RECIPIENTS_NAME])
    .nullable()
    .required()
    .test('invalid-recipient-name', 'Invalid recipient email', testCommaSeparatedEmails),
  [CC_LIST_NAME]: string()
    .label(labels[CC_LIST_NAME])
    .nullable()
    .test('invalid-cc-list', 'Invalid CC email', testCommaSeparatedEmails),
  [BCC_LIST_NAME]: string()
    .label(labels[BCC_LIST_NAME])
    .nullable()
    .test('invalid-bcc-name', 'Invalid BCC email', testCommaSeparatedEmails),
});

const ComposeEmail: FC<ComposeEmailProps> = ({
  onSave,
  onCancel,
  open,
  sourceId,
  email = '',
  sourcesIdEmails = null,
  selectedInvoices = [],
}) => {
  const classes = useStyles();
  const { groupedEmailTemplatesByTemplateFolder, inFlightTemplates, activitySource } = useComposeEmail();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { enqueueSnackbar } = useSnackbar();
  const [defaultTemplate, setDefaultTemplate] = useState(new Template());
  const [inFlightEmployee, setInFlightEmployee] = useState(false);
  const [managerOptions, setManagerOptions] = useState<SelectOptions>([[0, 'Company']]);
  const user = useSelector(getAuthUser);
  const [from, setFrom] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [inFlight, setInFlight] = useState(false);
  const [openModal, setOpenModal] = useState<ViewModes | null>(null);
  const [templatePreview, setTemplatePreview] = useState('');
  const [temporaryAlertTemplate, setTemporaryAlertTemplate] = useState<Template | null>(null);
  const { control, handleSubmit, getValues, setValue } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: { [RECIPIENTS_NAME]: email || '' },
  });
  const massEmail = !!sourcesIdEmails;
  const isVisibleTemplates =
    activitySource === ActivitySourceType.ORDER || activitySource === ActivitySourceType.INVOICE;
  const editorConfig = useMemo(() => ({ variables: isVisibleTemplates ? variables : undefined }), []);

  useEffect(() => {
    if (user.id) {
      setInFlightEmployee(true);
      userAPI
        .retrieveEmployee(user.id)
        .then((employee: EmployeeRecord) => {
          const isUseOutGoingEmailCurrentUser = employee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL];

          if (isUseOutGoingEmailCurrentUser) {
            setManagerOptions((state) => [...state, [user.id, `${user.firstName} ${user.lastName}`]]);
            setFrom(user.id);
          }
        })
        .catch(() => {})
        .then(() => setInFlightEmployee(false));
    }
  }, [user?.id]);

  useEffect(() => {
    if (email) {
      setValue(RECIPIENTS_NAME, email);
    }
  }, [email]);

  const continueAlertHandler = () => {
    setValue(BODY_NAME, temporaryAlertTemplate?.body);
    setValue(SUBJECT_NAME, temporaryAlertTemplate?.subject);
    setTemporaryAlertTemplate(null);
    setOpenModal(null);
  };

  const handleOpenTemplateModal = () => {
    setOpenModal(ViewModes.SELECT_TEMPLATE);
  };

  useEffect(() => {
    setOpenModal(null);
  }, [open]);

  const closeHandler = () => setOpenModal(null);

  const handleChooseTemplate = (template: Template) => () => {
    setOpenModal(ViewModes.ALERT);
    setTemporaryAlertTemplate(template);
  };

  const handleChooseTemplateModal = (template: Template) => () => {
    handleChooseTemplate(template)();
  };

  const showTemplatePreview = (template: Template) => () => {
    const id = sourcesIdEmails?.[0]?.id ?? sourceId;

    if (id) {
      setOpenModal(ViewModes.EMAIL_PREVIEW);
      setTemplatePreview('');

      templateApi
        .preview(
          id!,
          activitySource,
          (template.id ? template : template.set(BODY_NAME, getValues()[BODY_NAME])).toDTO({ useExistingBody: true }),
        )
        .then((response) => {
          setTemplatePreview(response || 'Nothing to load');
        });
    }
  };
  const handleExpand = (index: number) => () => setExpandedIndex((state) => (state === index ? -1 : index));
  const handleChangeFrom = (event: ChangeEvent<HTMLInputElement>) => setFrom(+event.target.value || 0);

  const sendEmails = (data: EmailForm, id: number) => {
    const templatesToSend = [
      defaultTemplate.set('subject', data.subject).set(BODY_NAME, appendTemplateBuilderStyles(data[BODY_NAME])),
    ];

    if (templatesToSend.some((t) => !t.body)) {
      enqueueSnackbar('Found template without a body. Please check templates.', { variant: 'warning' });
      return Promise.reject();
    }

    const form = toEmailDTO(data);

    if (activitySource === ActivitySourceType.ORDER || activitySource === ActivitySourceType.CUSTOMER_ACCOUNT) {
      return (
        activitySource === ActivitySourceType.ORDER
          ? Promise.all(
              templatesToSend.map((t) =>
                id
                  ? templateApi
                      .preview(id!, activitySource, t.toDTO({ useExistingBody: true }))
                      .then((preview) => t.set('body', preview))
                  : t,
              ),
            )
          : Promise.resolve(templatesToSend)
      )
        .then((response) =>
          response.map((template: Template) => {
            const fd = new FormData();
            fd.append(
              'emailDto',
              new Blob([JSON.stringify(template.toEmailDto({ ...form, activitySource, sourceId: id }))], {
                type: 'application/json',
              }),
            );

            return fd;
          }),
        )
        .then((emailsBody) =>
          emailsBody.map((e) => (from ? emailAPI.sendEmailFromCurrentEmployee(e) : emailAPI.sendEmailFromCompany(e))),
        );
    } else if (activitySource === ActivitySourceType.INVOICE) {
      return Promise.all(
        selectedInvoices?.map((invoice) => {
          let newStatus =
            invoice.status === InvoicesStatusType.SENT ? InvoicesStatusType.RESENT : InvoicesStatusType.RESENT;

          if (invoice.status !== InvoicesStatusType.RESENT && invoice.status !== InvoicesStatusType.SENT) {
            newStatus = InvoicesStatusType.SENT;
          }

          if (invoice.status === InvoicesStatusType.PAID) {
            newStatus = InvoicesStatusType.PAID;
          }

          return invoiceAPI
            .changeInvoicesStatus(newStatus, [invoice.id!])
            .then(() => sendInvoiceEmail({ templates: templatesToSend, emailDTO: form }, invoice));
        }),
      );
    }

    enqueueSnackbar('Coming soon!', { variant: 'warning' });

    return Promise.resolve();
  };

  const handleSaveClick = (data: EmailForm) => {
    setInFlight(true);

    Promise.all(
      sourcesIdEmails?.map(({ email, id }) => sendEmails({ ...data, [RECIPIENTS_NAME]: email }, id!)) ?? [
        sendEmails(data, sourceId!),
      ],
    )
      .then(onSave)
      .catch(() => {
        enqueueSnackbar('Problem with sending emails please check SMTP setting.', { variant: 'error' });
      })
      .then(() => {
        setDefaultTemplate(new Template());
        enqueueSnackbar('Emails was successfully sent to client.', { variant: 'success' });
      })
      .then(() => {
        setInFlight(false);
      });
  };

  return (
    <Modal
      open={open}
      title="Compose Email"
      actions={[
        { label: 'cancel', onClick: onCancel, disabled: inFlight },
        { label: 'save', onClick: onCancel, disabled: inFlight },
        { label: 'send', onClick: handleSubmit(handleSaveClick as any), disabled: inFlight },
      ]}
      onClose={onCancel}
      disabledInProcessing={inFlight}
      color="grey"
      fullWidth
      maxWidth={'md'}
      disableEnforceFocus
    >
      <Box height={8}>{inFlight && <LinearProgress color="primary" />}</Box>
      <Box display="flex">
        {isVisibleTemplates && !isMobile && (
          <Box>
            <Box width={200}>
              <Paper className={classes.templateTitle}>
                <BodyBigText>Click to Use Template</BodyBigText>
                <Box mb={-1} height={8}>
                  {inFlightTemplates && <LinearProgress color="primary" />}
                </Box>
              </Paper>
            </Box>
            {groupedEmailTemplatesByTemplateFolder.map(([folderName, templates], index) => (
              <Box key={folderName} width={200}>
                <Accordion expanded={expandedIndex === index} onChange={handleExpand(index)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${folderName}-content`}
                    id={`${folderName}-header`}
                  >
                    <BodyBigText text>{folderName}</BodyBigText>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <List component="nav" className={classes.menu}>
                      {templates.map((item) => (
                        <ListItem button key={item.id} onClick={handleChooseTemplate(item)}>
                          <BodyBigText className={classes.name}>{item.name}</BodyBigText>
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        )}
        <Box mx={isMobile ? 0 : 2}>
          <Box mb={2}>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Select
                  type="select"
                  options={managerOptions}
                  label="From"
                  fullWidth
                  value={from}
                  disabled={inFlightEmployee}
                  onChange={handleChangeFrom}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                name={RECIPIENTS_NAME}
                label={labels[RECIPIENTS_NAME]}
                control={control}
                inputProps={{ readOnly: massEmail }}
              />
            </Grid>
            {!massEmail && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextInput fullWidth name={CC_LIST_NAME} label={labels[CC_LIST_NAME]} control={control} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput fullWidth name={BCC_LIST_NAME} label={labels[BCC_LIST_NAME]} control={control} />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12}>
              <TextInput fullWidth name={SUBJECT_NAME} label={labels[SUBJECT_NAME]} control={control} />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box my={1}>
                  {isMobile ? (
                    <Button
                      color="primary"
                      textTransform="capitalize"
                      onClick={handleOpenTemplateModal}
                      variant="text"
                      style={{ padding: '5px 0px' }}
                    >
                      <BodyBigText>Select Template</BodyBigText>
                    </Button>
                  ) : (
                    <HeaderSmallText>
                      <b>Message</b>
                    </HeaderSmallText>
                  )}
                </Box>
                {!!sourceId && (
                  <Box display="flex">
                    <IconButton
                      data-testid="emailPreview"
                      color="primary"
                      aria-label="emailPreview"
                      onClick={showTemplatePreview(defaultTemplate)}
                    >
                      <BlueVisibilityIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <JoditTextEditor
                name={BODY_NAME}
                placeholder={isMobile ? 'Message' : 'Please enter your email message'}
                control={control}
                config={editorConfig}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Modal
        open={openModal === ViewModes.ALERT}
        title="Alert"
        onClose={closeHandler}
        actions={[
          { label: 'cancel', onClick: closeHandler },
          { label: 'save', onClick: continueAlertHandler },
        ]}
        maxWidth="xs"
      >
        This will remove the existing subject and message text
      </Modal>
      <Modal
        open={openModal === ViewModes.EMAIL_PREVIEW}
        title="Email Preview"
        onClose={closeHandler}
        maxWidth="md"
        actions={[{ label: 'done', onClick: closeHandler }]}
      >
        {!templatePreview ? (
          <Box display="flex" alignItems="center" justifyContent="center" height={400} position="relative" my={2}>
            <ElromcoCircularProgress />
          </Box>
        ) : (
          <Box dangerouslySetInnerHTML={{ __html: templatePreview }} />
        )}
      </Modal>
      {openModal === ViewModes.SELECT_TEMPLATE && (
        <TemplateModal handleChooseTemplate={handleChooseTemplateModal} onClose={closeHandler} />
      )}
    </Modal>
  );
};

export { ComposeEmail };
