import { durationToHoursMinutes } from 'admin/utils/timeToTextFormat';

const MINUTES_IN_DAY = 24 * 60;

interface CalculateDurationOptions {
  incrementStep: number;
  daysCount: number;
  valueMode?: 'minutes' | 'seconds';
}

export const calculateDuration = (options: CalculateDurationOptions) => {
  const { incrementStep, daysCount, valueMode = 'seconds' } = options;

  const incrementCoefficient = valueMode === 'minutes' ? 1 : 60;

  return new Array((daysCount * MINUTES_IN_DAY) / incrementStep)
    .fill(0)
    .map((_, i) => [i * incrementStep * incrementCoefficient, i * incrementStep * 60])
    .map(([key, value]) => [key, durationToHoursMinutes(value, true)]);
};
