import React, { useState } from 'react';

import { IconButton, Link, Order, OverridableDurationValue } from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow } from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';

import { Flex, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import MinLaborHours from 'admin/components/OrderWindow/modals/MinLaborHours';
import { getServiceClosingPropertyName } from 'admin/utils';
import { OrderWindowModal } from 'common-types';

const minLaborHoursProperty = 'minLaborHours';
export const MinimumHoursRow = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const quote = order.getServiceQuote(serviceIndex);
  const { isCompleted, isClosing, isLockSales } = useOrderClosingContext();
  const getServicePropertyName = useGetServicePropertyName();
  const name = isClosing
    ? getServiceClosingPropertyName(serviceIndex, minLaborHoursProperty)
    : getServicePropertyName(minLaborHoursProperty, minLaborHoursProperty);
  const minimumHours = order.getIn(name.split('.')) as OverridableDurationValue;

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleMinLaborHoursChange = (manual: number) => {
    onChange({
      name: name,
      value: minimumHours.setManual(manual),
    });
    setOpenModal(null);
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Box display="flex" alignItems="center" mt={1}>
          <Link disabled={isCompleted || isLockSales} onClick={handleOpenModal(OrderWindowModal.MIN_LABOR_HOURS)}>
            Minimum hours
          </Link>
          <IconButton
            color={quote.minLaborHours.manual ? 'secondary' : 'primary'}
            onClick={handleOpenModal(OrderWindowModal.MIN_LABOR_HOURS)}
            disabled={isCompleted || isLockSales}
          >
            <UpdateIcon />
          </IconButton>
        </Box>
        {openModal === OrderWindowModal.MIN_LABOR_HOURS && (
          <MinLaborHours
            onSave={handleMinLaborHoursChange}
            onCancel={handleOpenModal(null)}
            open
            minLabourHours={minimumHours}
          />
        )}
      </TableCell>
      <ValueTableCell>
        <Box mt={1}>
          <Flex $manual={!!minimumHours.manual}>
            <Text value={(minimumHours.value() || {}).humanReadable || 0} />
          </Flex>
        </Box>
      </ValueTableCell>
    </TableRow>
  );
};
