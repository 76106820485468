import axios from 'axios';

class AccountApi {
  create(account) {
    return axios.post('/api/moving/account/', account);
  }

  updateAccountInfo(customerInfo) {
    return axios.put('/api/moving/account/', customerInfo);
  }

  getByEmail(email) {
    return axios.get(encodeURI(`/api/moving/account/customer?email=${email}`));
  }

  getEmployees() {
    return axios.get('/api/admin/employees');
  }

  getAllAccounts(query = {}) {
    return axios.get(
      '/api/moving/accounts',
      {
        params: { pageIndex: 0, pageSize: 10, sortBy: 'id', sortOrder: 'desc', ...query },
      },
      { skipNull: true },
    );
  }

  searchPrivateAccounts(query = {}) {
    return axios.get(
      '/api/moving/accounts/private',
      {
        params: { search: '', pageIndex: 0, pageSize: 10, sortBy: 'id', sortOrder: 'desc', ...query },
      },
      { skipNull: true },
    );
  }

  getStatisticsByAccount(id) {
    return axios.get(`/api/moving/account/${id}/stats/`);
  }

  getAccountSummary(id) {
    return axios.get(`/api/moving/account/${id}/`);
  }

  updateEmployee(accountId, employeeId) {
    return axios.put(`/api/moving/account/${accountId}/manager/${employeeId}/`);
  }

  updateLeadSource(accountId, sourceName) {
    return axios.put(`/api/moving/account/${accountId}/source/${sourceName}/`);
  }

  suspendAccount(customerId) {
    return axios.put(`/api/moving/account/customer/${customerId}/suspended/`);
  }

  reactivateAccount(customerId) {
    return axios.put(`/api/moving/account/customer/${customerId}/reactivated/`);
  }

  setAccountDiscount(id, discount) {
    return axios.put(`/api/moving/account/${id}/pricing/rate_discounts/`, discount);
  }

  getAccountDiscount(id) {
    return axios.get(`/api/moving/account/${id}/pricing/rates_discount/`);
  }

  getAccountPaymentOption(id) {
    return axios.get(`/api/moving/account/${id}/pricing/paymentOption/`);
  }

  downloadAccountsReport(params) {
    return axios.get('/api/moving/accounts/export/', {
      params,
      responseType: 'blob',
    });
  }

  updateAccountPassword(userId, password) {
    return axios.put(`/api/admin/user/password`, { userId, password });
  }

  setPaymentOption(id, paymentOption) {
    return axios.put(`/api/moving/account/${id}/pricing/paymentOption/${paymentOption}/`);
  }
}

export default new AccountApi();
