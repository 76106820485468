import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const OnTheWayIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M988 4060 c-25 -26 -29 -36 -24 -63 3 -18 16 -40 27 -49 20 -17 97 -18 1295 -18 l1274 0 -5 -22 c-3 -13 -95 -536 -205 -1163 -110 -627 -202 -1150 -205 -1162 l-5 -23 -644 0 -644 0 -7 48 c-16 116 -94 221 -204 275 -87 43 -214 50 -319 18 -133 -40 -262 -151 -322 -277 l-31 -64 -200 0 c-199 0 -200 0 -224 -25 -35 -34 -34 -86 1 -114 25 -20 40 -21 214 -21 l187 0 7 -47 c9 -74 56 -162 115 -216 126 -117 304 -136 482 -51 96 46 203 152 248 247 l32 67 802 -2 802 -3 7 -47 c22 -157 148 -280 316 -309 202 -36 451 108 533 308 l22 51 131 4 c118 3 136 6 181 30 60 31 130 103 155 161 29 66 153 790 147 857 -4 43 -47 151 -197 495 -105 242 -204 456 -219 475 -15 19 -48 46 -72 60 l-44 25 -372 3 c-205 2 -373 6 -373 10 0 4 16 98 36 207 22 127 33 213 29 235 -9 49 -42 89 -89 111 -39 18 -94 19 -1324 19 l-1284 0 -30 -30z m3380 -726 c22 -15 44 -50 56 -91 l7 -23 -258 0 c-241 0 -260 -1 -291 -20 -18 -11 -39 -35 -46 -53 -8 -17 -37 -168 -66 -334 l-53 -302 24 -48 c45 -91 27 -88 557 -91 427 -3 462 -4 462 -20 0 -9 -27 -167 -59 -350 -39 -219 -66 -343 -78 -365 -11 -17 -37 -42 -59 -55 -34 -19 -51 -22 -134 -20 l-95 3 -7 53 c-16 110 -95 215 -202 267 -70 34 -194 45 -285 25 -141 -31 -277 -138 -346 -273 l-40 -77 -72 0 c-62 0 -73 3 -73 17 0 16 294 1699 305 1751 l5 22 363 0 c312 0 366 -2 385 -16z" />
          <path d="M413 3570 c-47 -19 -58 -92 -20 -128 23 -22 25 -22 479 -22 251 0 464 3 473 6 18 7 45 55 45 79 0 9 -13 30 -29 46 l-29 29 -449 -1 c-246 0 -458 -4 -470 -9z" />
          <path d="M750 3077 c-38 -19 -55 -61 -40 -98 7 -16 21 -34 31 -39 10 -6 167 -10 379 -10 l361 0 24 25 c32 31 32 66 1 102 l-24 28 -354 3 c-272 2 -359 -1 -378 -11z" />
          <path d="M213 2593 c-28 -5 -63 -49 -63 -78 0 -12 9 -34 21 -49 l20 -26 478 0 c516 0 511 -1 525 54 10 39 -10 75 -49 91 -28 12 -110 15 -472 14 -241 -1 -448 -4 -460 -6z" />
          <path d="M471 2074 c-28 -35 -26 -67 3 -101 l24 -28 212 0 212 0 24 28 c31 36 31 71 -1 102 -24 25 -24 25 -239 25 l-215 0 -20 -26z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
