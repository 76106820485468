import React, { ChangeEvent, useEffect, useState } from 'react';

import { Order } from '@elromcoinc/react-shared';

import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';

import { RemoveManualChangesButton } from './RemoveManualChangesButton';

export const GrandTotalFlatRateInput = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail : order)!;
  const service = orderDetail.services.get(serviceIndex);
  const { quote } = service;
  const serviceGrandTotalMaxValue = orderDetail.getServiceGrandTotal(serviceIndex).maxValue;
  const defaultValue = quote?.flatRateQuoteDetailConfig?.flatRateTotalManual || serviceGrandTotalMaxValue;
  const [flatRateTotalManual, setFlatRateTotalManual] = useState<string | number>(defaultValue);

  useEffect(() => {
    setFlatRateTotalManual(defaultValue);
  }, [defaultValue]);

  const handleOnBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;

    if (+defaultValue !== +value) {
      onChange({ name, value });
    }
  };

  const name = `${
    isClosing ? 'closingOrderDetail.' : ''
  }services.${serviceIndex}.quote.flatRateQuoteDetailConfig.flatRateTotalManual`;

  const onChangeGrandTotal = (grandTotal: string) => setFlatRateTotalManual(grandTotal);

  const onCancel = () => {
    setFlatRateTotalManual(serviceGrandTotalMaxValue);
    onChange({ name, value: null });
  };

  return (
    <RemoveManualChangesButton manual={quote?.flatRateQuoteDetailConfig?.flatRateTotalManual} onClick={onCancel}>
      <CurrencyInput
        value={flatRateTotalManual}
        onChange={onChangeGrandTotal}
        onBlur={handleOnBlur(+flatRateTotalManual)}
        name={name}
      />
    </RemoveManualChangesButton>
  );
};
