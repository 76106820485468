import axios from 'axios';

class SmsAPI {
  findPhoneNumber(areaCode) {
    return axios.get(`/api/communications/sms/phone/areaCode/${areaCode}/`);
  }

  getStripeKey() {
    return axios.get('/api/payments/company/key/');
  }

  createNewStripeCustomer(body) {
    return axios.post('/api/payments/company/customer/', body);
  }

  chargeRegisteredCustomer(body) {
    return axios.post('/api/payments/company/customer/charge/', body);
  }

  createPaymentForExistedCustomer(body) {
    return axios.post('/api/payments/company/customer/payment/', body);
  }

  getRegisteredTwilioAccount() {
    return axios.get('/api/communications/sms/sub_account/');
  }

  buyTwilioPhoneNumber(body) {
    return axios.post('/api/communications/sms/phone/', body, {
      fullResponse: true,
    });
  }

  deleteTwilioPhoneNumber(pathSid) {
    return axios.delete(`/api/communications/sms/phone/pathSid/${pathSid}/`);
  }

  deletePaymentCard(paymentMethodId) {
    return axios.delete(`/api/payments/company/customer/payment/${paymentMethodId}`);
  }

  saveSmsAutoCharge(body) {
    return axios.put('/api/communications/sms/sms_auto_charge_settings/', body);
  }

  getAllRegisteredStripeCustomers(branchId) {
    return axios.get(`/api/payments/company/customer/${branchId}/`);
  }

  getAllSmsSettings() {
    return axios.get('/api/communications/sms/sms_settings/');
  }

  getUsageInfo() {
    return axios.get('/api/communications/sms/sub_account/usage/');
  }

  saveEnableCallForwarding(body) {
    return axios.put('/api/settings/node/', body);
  }

  saveCallForwardingNumber(body) {
    return axios.put('/api/settings/node/', body);
  }

  setEnableSms(body) {
    return axios.put(`/api/communications/sms/sub_account/enable`, body);
  }
}

export default new SmsAPI();
