import React, { FC, useState } from 'react';
import { CommunicationModality, Template } from 'common-types';
import EmailIcon from '@material-ui/icons/Email';
import TextsmsIcon from '@material-ui/icons/Textsms';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { IconButton } from '@elromcoinc/react-shared';
import { Map } from 'immutable';
import { Box, Tooltip } from '@material-ui/core';

interface CommunicationButtonProps {
  template: Template;
  onClick: () => void;
}

const IconByModality = Map({
  [CommunicationModality.EMAIL]: <EmailIcon />,
  [CommunicationModality.SMS]: <TextsmsIcon />,
});

const CommunicationButton: FC<CommunicationButtonProps> = ({ template, onClick }) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const toggleDeleteIcon = (value: boolean) => () => setShowDeleteIcon(value);

  return (
    <Tooltip title={template.name || template.subject}>
      <Box>
        <IconButton
          color="primary"
          size="medium"
          onClick={onClick}
          onMouseLeave={toggleDeleteIcon(false)}
          onMouseEnter={toggleDeleteIcon(true)}
        >
          {showDeleteIcon ? <HighlightOffIcon /> : IconByModality.get(template.modality)}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export { CommunicationButton };
