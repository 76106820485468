import { Truck } from '@elromcoinc/react-shared';

export function getTrucksToRender(trucks: Truck[], date?: Date, withUnassignedTruck: boolean = true) {
  const allTrucks = trucks.filter((it: any) => it.capacity && it.isAvailable(date));
  const unassignedTruck = trucks.find((it: any) => it.capacity === 0) || null;

  if (unassignedTruck && withUnassignedTruck) {
    allTrucks.push(unassignedTruck);
  }

  return allTrucks;
}
