import { BACKEND_DATE_FORMAT, Order, Waypoint, toBackEndDate, toDate } from '@elromcoinc/react-shared';
import { format } from 'date-fns';
import { Map, Record, getIn, is, setIn } from 'immutable';

import orderApi from 'admin/api/OrderAPI';
import truckApi from 'admin/api/truckApi';
import { ADD_SERVICE_KEY, DELETE_SERVICE_KEY } from 'admin/components/OrderWindow/OrderWindowConstants';
import { makeChangeSetMetadata } from 'admin/components/OrderWindow/utils';

import audit from './audit';

const waypointsPath = 'services.0.quote.waypoints';
const getServicePath = (index) => `services.${index}.quote.generalService`.split('.');
const resetServiceRosterId = (quote) =>
  quote && quote.set('serviceRosterId', null).set('leadSource', null).set('valuation', null);

const regex = new RegExp('services\\.(\\d+)\\..+', 'i');

export const extractServiceIndexUtil = (fieldPath) => {
  if (!fieldPath) {
    return 0;
  }
  const result = regex.exec(fieldPath);
  if (result && result.length > 1) {
    return +result[1];
  }
  return -1;
};

export function applyChangeSetHandler(changeSet, skipWP = false) {
  return (baseOrder) => {
    return changeSet.reduce((result, value, name) => {
      if (skipWP && name === waypointsPath) {
        return result;
      }

      if (Record.isRecord(value) && value?.has('calculated')) {
        const calculatedValue = result.getIn([...name.split('.'), 'calculated']);
        return result.setIn(name.split('.'), value.set('calculated', calculatedValue));
      }

      return result.setIn(name.split('.'), value);
    }, baseOrder);
  };
}

const cleanUpChangeSet = (changeSet, oldChangeSetMetadata, newChangeSetMetadata = null) => {
  let updatedChangeSet = changeSet;

  if (changeSet.has(DELETE_SERVICE_KEY)) {
    const serviceId = changeSet.get(DELETE_SERVICE_KEY);

    const deletedChangeSetMetadata = oldChangeSetMetadata.filter(
      (ocsm) => serviceId === ocsm.serviceRosterId || serviceId === ocsm.serviceRosterId,
    );

    updatedChangeSet = updatedChangeSet.filter((_, name) => {
      const serviceIndexToBeUpdated = extractServiceIndexUtil(name);

      return (
        serviceIndexToBeUpdated === -1 || !deletedChangeSetMetadata.find((dcs) => dcs.index === serviceIndexToBeUpdated)
      );
    });
  }

  if (newChangeSetMetadata) {
    // update change set indexes
    const changedIndexes = oldChangeSetMetadata
      .map((ocsm) => {
        const newChangeSetWithDifferentIndex = newChangeSetMetadata.find(
          (ncsm) =>
            (ncsm.serviceRosterId === ocsm.serviceRosterId || ncsm.primaryRosterId === ocsm.serviceRosterId) &&
            ncsm.index !== ocsm.index,
        );

        if (newChangeSetWithDifferentIndex) {
          return [ocsm.index, newChangeSetWithDifferentIndex.index];
        }

        return null;
      })
      .filter(Boolean);

    updatedChangeSet = updatedChangeSet.mapKeys((key) => {
      const serviceIndexToBeUpdated = extractServiceIndexUtil(key);
      const [oldIndex, newIndex] = changedIndexes.find(([oldIndex]) => oldIndex === serviceIndexToBeUpdated) || [];

      if (serviceIndexToBeUpdated === oldIndex) {
        return key.replace(`services.${oldIndex}.`, `services.${newIndex}.`);
      }

      return key;
    });
  }

  return updatedChangeSet;
};

function updateChangeSetOnSuccess(changeSet) {
  return (order) => [order, changeSet.delete(ADD_SERVICE_KEY).delete(DELETE_SERVICE_KEY)];
}

const addressAsStringPath = ['address', 'asSingleString'];
const coiSents = 'coiSents';
const elevationId = 'elevationId';

const mapWaypointsToCleanDifferentValues = (w) => {
  let value = setIn(w, ['id'], null);
  value = setIn(value, addressAsStringPath, null);
  value = setIn(value, ['entityId'], null);
  value = setIn(value, [coiSents], value?.[coiSents]?.length ? value?.[coiSents] : null);
  value = setIn(value, [elevationId], value?.[elevationId] === null ? +value?.[elevationId] : null);
  return value;
};

const updateWaypoints = (lastOrder, auditData, applyChangeSet) => (order) => {
  const waypoints = order.getServiceWaypoints();
  const oldWaypoints = lastOrder.getServiceWaypoints();
  const mappedNewWaypoints = waypoints?.map(mapWaypointsToCleanDifferentValues);
  const mappedOldWaypoints = oldWaypoints.map(mapWaypointsToCleanDifferentValues);

  const isChangedWaypoints = !compareImmutableObjects(mappedNewWaypoints, mappedOldWaypoints);

  if (isChangedWaypoints) {
    return orderApi
      .updateWaypoints(order.orderId, { waypoints }, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateSizingStrategy = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (lastOrder.sizingStrategy !== order.sizingStrategy) {
    return orderApi
      .setSizingStrategy(order.orderId, order.sizingStrategy, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateOverriddenCuFt = (lastOrder, auditData, applyChangeSet) => (order) => {
  if ((order.overriddenCuFt || null) !== lastOrder.overriddenCuFt) {
    return orderApi
      .setOverriddenCuFt(order.orderId, order.overriddenCuFt || null, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateOverriddenWeight = (lastOrder, auditData, applyChangeSet) => (order) => {
  if ((order.overriddenWeight || null) !== lastOrder.overriddenWeight) {
    return orderApi
      .setOverriddenWeight(order.orderId, order.overriddenWeight || null, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const sortByFeeType = (a, b) => {
  return a?.feeType?.localeCompare(b.feeType);
};

const updateAdditionalFees = (lastOrder, auditData, applyChangeSet) => (order) =>
  order.services.reduce((result, service, idx) => {
    const serviceQuote = order.getServiceQuote(idx);
    const serviceFee = serviceQuote.fees.sort(sortByFeeType);
    const lastOrderFees = lastOrder?.getServiceByQuote(service?.quote)?.quote?.fees.sort(sortByFeeType);
    return serviceQuote
      ? serviceFee
          .filter((fee, idx) => !fee.delete('id').equals(lastOrderFees?.get(idx)?.delete('id')))
          .reduce(async (result, fee) => {
            await result;
            return orderApi
              .setFeeOnOff(order.orderId, service.id, fee.feeType, fee.enabled, auditData)
              .then(Order.createOrder)
              .then(applyChangeSet);
          }, Promise.resolve(order))
      : Promise.resolve(order);
  }, Promise.resolve(order));

const updatePaymentType = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.paymentType !== lastOrder.paymentType) {
    return orderApi
      .setPaymentType(order.orderId, order.paymentType, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateFlags = (lastOrder, auditData) => (order) => {
  if (!order.flags.equals(lastOrder.flags)) {
    orderApi.setFlagsOnOrder(
      order.orderId,
      order.flags.map((flag) => flag.id),
      auditData,
    );
  }
  return Promise.resolve(order);
};

const updateAdditionalInfoNoteOnOrder = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.additionalInfo !== lastOrder.additionalInfo) {
    return orderApi
      .setAdditionalInfoNoteOnOrder(order.orderId, order.additionalInfo, auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateSecondaryIdOnOrder = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.secondaryId !== lastOrder.secondaryId) {
    return orderApi
      .setSecondaryIdOnOrder(order.orderId, order.secondaryId || '', auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateLeadSource = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.leadSource !== lastOrder.leadSource) {
    return orderApi
      .setLeadSource(order.orderId, order.leadSource, auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateEstimatedPackingType = (lastOrder, _, applyChangeSet) => (order) => {
  if (order.estimatedPackingType !== lastOrder.estimatedPackingType) {
    return orderApi
      .updateEstimatedPackingType(order.orderId, order.estimatedPackingType)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateDisableAutoCalculation = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.disableAutoCalculation !== lastOrder.disableAutoCalculation) {
    return orderApi
      .setDisableAutoCalculation(order.orderId, order.disableAutoCalculation, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateAdditionalContactInfo = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (!order.additionalContact.equals(lastOrder.additionalContact)) {
    return orderApi
      .updateAdditionalContactInfo(order.orderId, order.additionalContact.toJS(), auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateContactInfo = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (!order.contactInfo.equals(lastOrder.contactInfo)) {
    return orderApi
      .updateContactInfo(order.orderId, order.contactInfo.toDTO(), auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateOrderQuoteVisibilityToCustomer = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.quoteVisibleToCustomer !== lastOrder.quoteVisibleToCustomer) {
    return orderApi
      .setOrderQuoteVisibilityToCustomer(order.orderId, order.quoteVisibleToCustomer, auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateOrderBySmsStatus = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (order.enableSmsCustom !== lastOrder.enableSmsCustom) {
    return orderApi
      .setOrderBySmsStatus(order.orderId, order.enableSmsCustom, auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateDepositOrder = (lastOrder, auditData, changesSet, applyChangeSet) => (order) => {
  if (
    (order.totalDepositPaid !== lastOrder.totalDepositPaid && changesSet.has('totalDepositPaid')) ||
    (order.reservationAmountNeeded !== lastOrder.reservationAmountNeeded && changesSet.has('reservationAmountNeeded'))
  ) {
    return orderApi
      .updateOrderDeposit(order.orderId, order.totalDepositPaid || order.reservationAmountNeeded, auditData)
      .then(() => (order.toJS ? order : Order.createOrder(order)))
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateCuFtToPoundsRatio = (lastOrder, auditData, applyChangeSet) => (order) => {
  if ((order.cuFtToPounds || null) !== lastOrder.cuFtToPounds) {
    return orderApi
      .setCuFtToPoundsRatio(order.orderId, order.cuFtToPounds || 0, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateChargeProcessingFee = (lastOrder, auditData, applyChangeSet) => (order) => {
  const newProcessingFeeSettings = new Map(lastOrder.chargeProcessingFeeSettings);
  const oldProcessingFeeSettings = new Map(order.chargeProcessingFeeSettings);
  if (!is(oldProcessingFeeSettings, newProcessingFeeSettings)) {
    return orderApi
      .setChargeProcessingFee(order.orderId, order.chargeProcessingFeeSettings, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

const updateValuation = (lastOrder, auditData, applyChangeSet) => (order) => {
  if (!order.valuation.equals(lastOrder.valuation)) {
    return orderApi
      .setValuationChoice(order.orderId, order.valuation, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet);
  }
  return Promise.resolve(order);
};

// todo:trick to fix prod issue
function validateQuoteRequest(quoteRequest) {
  let request = quoteRequest;
  if (!quoteRequest.laborRate) {
    request = request.set('laborRate', {});
  }
  if (!quoteRequest.travelRate) {
    request = request.set('travelRate', {});
  }
  return request;
}

const deleteServiceWithInvoiceError =
  'You are deleting service associated with an invoice. You can delete the invoice or adjust the invoice accordingly.';

const deleteService = (lastOrder, auditData, applyChangeSet, changeSet) => (order) => {
  if (changeSet.has(DELETE_SERVICE_KEY)) {
    const serviceId = changeSet.get(DELETE_SERVICE_KEY);
    return orderApi
      .deleteService(order.orderId, serviceId, auditData)
      .then(Order.createOrder)
      .then(applyChangeSet)
      .catch((exception) => {
        const errors = getIn(exception, ['errors'], []);
        const [firstError] = errors;
        let result = exception;

        if (firstError?.message?.includes('and may not be deleted')) {
          result = setIn(result, ['errors', 0, 'message'], deleteServiceWithInvoiceError);
        }

        return Promise.reject(result);
      });
  }
  return Promise.resolve(order);
};

// this its bad fix. I have no clue wy immutable equals don't work properly
const compareImmutableObjects = (firstService, secondService) => {
  const first = firstService?.toJS() || {};
  const second = secondService?.toJS() || {};

  return JSON.stringify(first) === JSON.stringify(second);
};

const updateServices = (lastOrder, auditData, originalOrder) => (order) =>
  order.services.reduce(async (result, service, idx) => {
    const previousRequestResult = await result;
    const lastQuoteRequest = lastOrder?.services.has(idx) ? lastOrder.getQuoteRequestForService(idx) : null;
    const quoteRequest = (
      lastQuoteRequest ? order.setIn(getServicePath(idx), lastOrder.getIn(getServicePath(idx))) : order
    ).getQuoteRequestForService(idx);
    const isAddedService = !lastOrder?.getServiceByQuote(service.quote);
    const lastOrderQuoteRequest = resetServiceRosterId(lastQuoteRequest);
    const resetQuoteRequest = resetServiceRosterId(quoteRequest);

    const isUpdateService =
      quoteRequest.isValid() &&
      (isAddedService || !compareImmutableObjects(resetQuoteRequest, lastOrderQuoteRequest)) &&
      !!service.id;

    if (isUpdateService) {
      return idx === 0
        ? orderApi.updateOrder(order.orderId, validateQuoteRequest(quoteRequest), auditData).then(Order.createOrder)
        : orderApi.updateService(service.id, validateQuoteRequest(quoteRequest), auditData).then(Order.createOrder);
    }
    return Promise.resolve(previousRequestResult);
  }, Promise.resolve(order));

const updateFuelCharges = (lastOrder, auditData, applyChangeSet) => (order) =>
  order.services.reduce(async (result, service) => {
    const previousRequestResult = await result;
    const { fuelChargeEmployeeAdjusted } = service.quote;
    const lastFuelCharge = lastOrder?.getServiceByQuote(service.quote)?.quote?.fuelChargeEmployeeAdjusted ?? null;
    if (!is(fuelChargeEmployeeAdjusted, lastFuelCharge)) {
      if (fuelChargeEmployeeAdjusted === null) {
        return orderApi
          .deleteOverriddenFuelCharge(order.orderId, service.date, auditData)
          .then(Order.createOrder)
          .then(applyChangeSet);
      }
      return orderApi
        .setOverriddenFuelCharge(order.orderId, service.date, fuelChargeEmployeeAdjusted, auditData)
        .then(Order.createOrder)
        .then(applyChangeSet);
    }
    return Promise.resolve(previousRequestResult);
  }, Promise.resolve(order));

const updateDiscount = (lastOrder, auditData, applyChangeSet) => (order) =>
  order.services.reduce(async (result, service, idx) => {
    const previousRequestResult = await result;
    const { discount } = service.quote;
    const lastDiscount = lastOrder?.getServiceByQuote(service.quote)?.quote?.discount ?? null;
    if (discount.amount != null && !discount.equals(lastDiscount)) {
      return orderApi
        .setDiscount(order.orderId, toBackEndDate(service.date), discount, auditData)
        .then(Order.createOrder)
        .then(applyChangeSet);
    }
    return Promise.resolve(previousRequestResult);
  }, Promise.resolve(order));

const updateTrucks =
  (lastUpdated, applyChangeSet, delivery = false) =>
  (order) => {
    return order.services
      .reduce(async (result, service, idx) => {
        await result;
        const newTruckIds = service.truckAssignments
          .filter((ta) => ta.delivery === delivery)
          .map(({ id }) => id)
          .toArray();
        const newTrucks = newTruckIds.join() || '';
        const oldService = lastUpdated?.getServiceByQuote(service.quote);
        const oldTrucks =
          oldService?.truckAssignments
            .filter((ta) => ta.delivery === delivery)
            .map(({ id }) => id)
            .join() || '';

        if ((newTrucks || oldTrucks) && newTrucks !== oldTrucks) {
          return truckApi
            .setAssignedTrucks(order.orderId, format(toDate(service.date), BACKEND_DATE_FORMAT), newTruckIds, delivery)
            .then(Order.createOrder);
        } else {
          return Promise.resolve(order);
        }
      }, Promise.resolve(order))
      .then(applyChangeSet);
  };

export const updateOrderSandbox = (originalOrder, lastUpdated, currentChangeSet, orderToBeUpdated, settings) => {
  // this code is quick fix for updating multi-day services.
  // in feature, we need to combine changeSetMetadata and changeSet. we have interface ChangeSetMetadata
  const oldChangeSetMetadata = makeChangeSetMetadata(orderToBeUpdated);
  let changeSet = cleanUpChangeSet(currentChangeSet, oldChangeSetMetadata);
  let applyChangeSet = applyChangeSetHandler(changeSet);
  const auditData = audit(changeSet, originalOrder, orderToBeUpdated, settings);
  return (
    updateDisableAutoCalculation(
      lastUpdated,
      auditData,
      applyChangeSet,
    )(orderToBeUpdated)
      .then(updateServices(lastUpdated, auditData, originalOrder))
      .then((order) => {
        // updating change set indexes if service was updated
        const newChangeSetMetadata = makeChangeSetMetadata(order);
        changeSet = cleanUpChangeSet(changeSet, oldChangeSetMetadata, newChangeSetMetadata);
        applyChangeSet = applyChangeSetHandler(changeSet);

        return applyChangeSet(order);
      })
      .then((order) => {
        if (changeSet.has(ADD_SERVICE_KEY)) {
          const request = changeSet.get(ADD_SERVICE_KEY);
          return orderApi
            .addService(request.orderId, request.set('moveDate', toBackEndDate(request.moveDate)), auditData)
            .then(Order.createOrder)
            .then((order) => {
              // updating change set indexes if service was added
              const newChangeSetMetadata = makeChangeSetMetadata(order);
              changeSet = cleanUpChangeSet(changeSet, oldChangeSetMetadata, newChangeSetMetadata);
              applyChangeSet = applyChangeSetHandler(changeSet);

              return order;
            })
            .then(applyChangeSet);
        }

        return order;
      })
      // I write arrow function here because I update link to applyChangeSet and changeSet and I need to use recent values
      .then((order) =>
        deleteService(
          lastUpdated,
          auditData,
          applyChangeSet,
          changeSet,
        )(order).catch((e) => {
          const errors = getIn(e, ['errors'], []);
          const [firstError] = errors;

          if (firstError.message.includes(deleteServiceWithInvoiceError)) {
            changeSet = changeSet.delete(DELETE_SERVICE_KEY);
          }

          return Promise.reject({ exception: e, changeSet });
        }),
      )
      .then((order) => updateTrucks(lastUpdated, applyChangeSet)(order))
      .then((order) => updateTrucks(lastUpdated, applyChangeSet, true)(order))
      .then((order) => updateOverriddenCuFt(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateOverriddenWeight(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateSizingStrategy(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updatePaymentType(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateFlags(lastUpdated, auditData)(order))
      .then((order) => updateAdditionalInfoNoteOnOrder(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateSecondaryIdOnOrder(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateAdditionalContactInfo(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateContactInfo(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateLeadSource(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateEstimatedPackingType(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateOrderQuoteVisibilityToCustomer(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateOrderBySmsStatus(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateCuFtToPoundsRatio(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateValuation(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateFuelCharges(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateDiscount(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateAdditionalFees(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateWaypoints(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => updateDepositOrder(lastUpdated, auditData, changeSet, applyChangeSet)(order))
      .then((order) => updateChargeProcessingFee(lastUpdated, auditData, applyChangeSet)(order))
      .then((order) => {
        // TODO This is bad fix because in sanbox we don't have closing functionality
        // So for now I keep in memory closing functionality
        const orderWithClosingInfo = order.set(
          'closingOrderDetail',
          order.get('closingOrderDetail') || originalOrder.get('closingOrderDetail'),
        );

        const oldWaypoints = lastUpdated.getAllServicesWaypoints().filter(Waypoint.isCustomerStop);
        const newWaypoints = order.getAllServicesWaypoints().filter(Waypoint.isCustomerStop);
        // if waypoint added/deleted will not apply change set
        const skipWaypointApplyChangeSet =
          oldWaypoints.some((ow) => !newWaypoints.find((nw) => nw.id === ow.id)) ||
          newWaypoints.some((nw) => !oldWaypoints.find((ow) => ow.id === nw.id));

        return applyChangeSetHandler(changeSet, skipWaypointApplyChangeSet)(orderWithClosingInfo);
      })
      .then((order) => {
        return updateChangeSetOnSuccess(changeSet)(order);
      })
  );
};

// this is hack because we have problem with change status immediately after update order start time window
const timeOutPromise = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const commitAndSaveOrder = async (order, originalOrder) => {
  const { orderId } = originalOrder;

  try {
    await orderApi.savePendingOrderUpdates(orderId);

    if (order.assignedTo && order.assignedTo !== originalOrder.assignedTo) {
      await orderApi.assignEmployee(order.orderId, order.assignedTo);
    }

    if (originalOrder.status !== order.status) {
      await timeOutPromise(500);
      await orderApi.updateOrderStatus(orderId, order.status);
    }

    return Promise.resolve();
  } catch (e) {
    return Promise.reject(e);
  }
};
