import axios from 'axios';

class ZIPToLocationApi {
  get(ZIP) {
    return axios.get(`/api/geo/address/${ZIP}`);
  }

  getZIPCodesByState(state) {
    return axios.get(`/api/settings/ld/tariff/region/state/${state}/postalCodes`);
  }
}

export default new ZIPToLocationApi();
