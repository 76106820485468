import React, { ChangeEvent, useState } from 'react';

import {
  BodyText,
  Button,
  ElromcoCircularProgress,
  HeaderBigText,
  Modal,
  TextInput,
  storageUtil,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box, Grid, InputAdornment, LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Business, Lock } from '@material-ui/icons';
import { GlobalVar } from 'GlobalVar';
import { useDispatch, useSelector } from 'react-redux';

import { getAccessIsTerminated, getErrors, getIsFetching, login } from 'admin/autodux/AuthAutodux';
import { Conditions } from 'admin/containers/components/Conditions';
import Form from 'common/components/Form';
import defaultCompanyLogo from 'common/imgs/logo_small.png';

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    position: 'relative',

    '& .MuiPaper-root ': {
      backgroundColor: palette.grey[50],
    },
    '& .MuiDialogTitle-root': {
      justifyContent: 'center',
    },
  },
  header: {
    marginBottom: spacing(1),
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: spacing(1, 0, 2),

    '& img': {
      width: '50px',
    },
  },
  form: {
    maxWidth: '380px',
    width: '100%',
    padding: spacing(4),
    margin: '0 auto',
    boxSizing: 'border-box',

    '&.MuiPaper-root': {
      background: palette.common.white,
    },
  },
  conditionBlock: {
    display: 'flex',
    justifyContent: 'center',
    margin: spacing(3, 'auto', 2),
    width: '100%',
    maxWidth: '380px',
  },
  sessionModal: {
    zIndex: 10,
  },
  elromcoCircularProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 12,
  },
}));

const SessionExceptionType = {
  MAX_USER_SESSION: 1,
  MAX_COMPANY_SESSION: 2,
};

const getSessionExceptionType = (response: any) => {
  if (response?.data?.message?.indexOf('Maximum') > -1) {
    return SessionExceptionType.MAX_COMPANY_SESSION;
  }
  return SessionExceptionType.MAX_USER_SESSION;
};

const LoginBackModal = ({ isSessionExpired }: { isSessionExpired: boolean }) => {
  const classes = useStyles();
  const errors = useSelector(getErrors);
  const accessIsTerminated = useSelector(getAccessIsTerminated);
  const inFlight: boolean = useSelector(getIsFetching);
  const dispatch = useDispatch();

  const [openModal] = useState(isSessionExpired);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyId, setCompanyId] = useState(storageUtil.getTenantId());
  const [sessionException, setSessionException] = useState<number | null>(null);
  const [isLoginInFlight, setIsLoginInFlight] = useState(false);

  const handleOnChange =
    (setter: (value: any) => void) =>
    ({ target: { value } }: { target: { value: any } }) =>
      setter(value);

  const markAsNoActiveSessions = () => setSessionException(null);

  const performLogin = () => {
    if (!companyId) {
      return;
    }
    setIsLoginInFlight(true);
    dispatch(login(username, password, +companyId, !!sessionException))
      // @ts-ignore
      .then(({ data }) => {
        GlobalVar.setValue('isSessionExpired', false);

        if (sessionException) {
          markAsNoActiveSessions();
        }

        return data;
      })
      .catch((response: any) => {
        if (response && response.status === 409) {
          setSessionException(getSessionExceptionType(response));
        }
      })
      .then(() => setIsLoginInFlight(false));
  };

  const handleFormSubmit = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    performLogin();
  };

  const { alertProps } = useAlert({
    onConfirm: sessionException === SessionExceptionType.MAX_USER_SESSION ? performLogin : undefined,
    confirmTitle: 'Continue',
    cancelTitle: sessionException === SessionExceptionType.MAX_USER_SESSION ? 'Cancel' : 'Close',
    onCancel: markAsNoActiveSessions,
  });

  return (
    <Modal title="Login" maxWidth="xs" open={openModal} className={classes.root}>
      <div className={classes.img}>
        <img src={defaultCompanyLogo} alt="Elromco logo" />
      </div>
      {inFlight && (
        <Box className={classes.elromcoCircularProgress}>
          <ElromcoCircularProgress />
        </Box>
      )}
      <Paper className={classes.form}>
        <HeaderBigText className={classes.header}>Sign in</HeaderBigText>
        <Form onSubmit={handleFormSubmit}>
          {!!errors && !accessIsTerminated && (
            <BodyText color="error" align="center" data-testid="loginErrorMessage">
              {errors}
            </BodyText>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                disabled={inFlight}
                type="text"
                value={username}
                onChange={handleOnChange(setUsername)}
                fullWidth
                label="Email address"
                data-testid="emailOrUserName"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                disabled={inFlight}
                type="password"
                value={password}
                fullWidth
                autoFocus
                label="Password"
                onChange={handleOnChange(setPassword)}
                data-testid="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Lock />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Business />
                    </InputAdornment>
                  ),
                }}
                disabled={inFlight}
                data-testid="companyId"
                value={companyId}
                onChange={handleOnChange(setCompanyId)}
                type="text"
                fullWidth
                autoFocus
                label="Company ID"
              />
            </Grid>
            <Grid item xs={12}>
              <Button disabled={inFlight} data-testid="signIn" type="submit" color="primary" fullWidth>
                Log in
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <div className={classes.conditionBlock}>
        <Conditions />
      </div>
      {!!sessionException && (
        <Modal
          {...alertProps}
          className={classes.sessionModal}
          color="white"
          open={!!sessionException}
          disabledInProcessing={isLoginInFlight}
          title="Active Session"
        >
          {isLoginInFlight && (
            <Box mt={-1} height={8}>
              <LinearProgress color="primary" />
            </Box>
          )}
          {sessionException === SessionExceptionType.MAX_USER_SESSION
            ? 'You currently have an active session with this user name. Select Continue to terminate the other session and proceed with this session, or click Cancel to abort this login.'
            : 'You have reached the maximum number of concurrent sessions.'}
        </Modal>
      )}
    </Modal>
  );
};

export default LoginBackModal;
