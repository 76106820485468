import { ActivitySourceType } from '@elromcoinc/react-shared';

import tasksApi from 'admin/api/TasksApi';
import { CREATE_A_FOLLOW_UP } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { useDefaultFollowUpValues } from 'admin/components/OrderWindow/blocks/ActivityManager/useDefaultFollowUpValues';
import { Task } from 'common-types/Task';

export const createFollowUpTask = (
  data: ReturnType<typeof useDefaultFollowUpValues>,
  activitySource: ActivitySourceType,
  sourceId: number,
) => {
  if (data[CREATE_A_FOLLOW_UP]) {
    const task = new Task({
      ...data,
      activitySource,
      sourceId,
    });

    return tasksApi.save(task.toDTO());
  }

  return Promise.reject();
};
