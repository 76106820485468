import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedWindow } from 'admin/autodux/WindowsAutodux';
import { MultiWindowController } from 'admin/components/GlobalWindow/MultiWindowController';

export const GlobalWindow = () => {
  const selectedWindows = useSelector(getSelectedWindow);

  if (!selectedWindows.size) {
    return null;
  }

  return <MultiWindowController />;
};
