import React from 'react';

import { Button } from '@elromcoinc/react-shared';

// it's used in third party library, so we don't know props types
export const BeaconComponent = (props: any) => {
  return (
    <Button color="primary" {...props}>
      Resume Wizard
    </Button>
  );
};
