/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';

import {
  AccountsPermissions,
  BodyText,
  Modal,
  PaymentOptions,
  Radio,
  RadioGroup,
  Select,
  SimpleCurrencyInput,
  TextInput,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, InputAdornment, useMediaQuery, useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { number, object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import {
  ACCOUNT_DISCOUNT,
  COD,
  DISCOUNT_AMOUNT,
  FLAT_FEE,
  HOURLY_RATE_DEFAULT,
  HOURLY_RATE_DISCOUNT,
  IOD,
  PAYMENT_OPTION,
  PERCENTAGE,
  labels,
} from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { DiscountType } from 'admin/components/Accounts/config';

const schemaDiscount = object().shape({
  [ACCOUNT_DISCOUNT]: string().oneOf(
    [PERCENTAGE, FLAT_FEE, HOURLY_RATE_DISCOUNT, HOURLY_RATE_DEFAULT],
    'Please select one type of account discount',
  ),
  [DISCOUNT_AMOUNT]: number()
    .transform((value) => +value || 0)
    .positive('Percent/Amount is a required field')
    .nullable()
    .label(labels[DISCOUNT_AMOUNT])
    .when(ACCOUNT_DISCOUNT, (value, schema) => ([PERCENTAGE].includes(value) ? schema.max(100) : schema)),
  [PAYMENT_OPTION]: string().oneOf(
    [PaymentOptions.IOD, PaymentOptions.COD],
    'Please select one type of payment option',
  ),
});

const updateDiscount = (data) => {
  let value;
  const newDiscount = {
    hourlyRateDiscount: { amount: 0, type: DiscountType.ABSOLUTE },
    totalDiscount: { amount: 0, type: DiscountType.ABSOLUTE },
    hourlyRate: value && value > 0,
  };
  if (data[ACCOUNT_DISCOUNT] === HOURLY_RATE_DISCOUNT) {
    newDiscount.hourlyRateDiscount.type = DiscountType.ABSOLUTE;
    newDiscount.hourlyRateDiscount.amount = data[DISCOUNT_AMOUNT];
  }
  if (data[ACCOUNT_DISCOUNT] === HOURLY_RATE_DEFAULT) {
    newDiscount.hourlyRate = data[DISCOUNT_AMOUNT];
  }
  if (data[ACCOUNT_DISCOUNT] === PERCENTAGE || data[ACCOUNT_DISCOUNT] === FLAT_FEE) {
    newDiscount.totalDiscount.type =
      data[ACCOUNT_DISCOUNT] === PERCENTAGE ? DiscountType.PERCENT : DiscountType.ABSOLUTE;
    newDiscount.totalDiscount.amount = data[DISCOUNT_AMOUNT];
  }
  return newDiscount;
};

const fromDTO = (discount) => {
  if (!discount.hourlyRateDiscount || !discount.totalDiscount) {
    return {};
  }

  const hourlyRateDiscountHasPercent = discount.hourlyRateDiscount.type === DiscountType.ABSOLUTE;
  const totalDiscountHasPercent = discount.totalDiscount.type === DiscountType.PERCENT;
  const hourlyRateDiscountHasAmount = discount.hourlyRateDiscount.amount > 0;
  const totalDiscountHasAmount = discount.totalDiscount.amount > 0;
  let amount = 0;
  let discountType = HOURLY_RATE_DEFAULT;

  if (hourlyRateDiscountHasAmount && hourlyRateDiscountHasPercent) {
    discountType = HOURLY_RATE_DISCOUNT;
    amount = discount.hourlyRateDiscount.amount;
  }

  if (totalDiscountHasAmount) {
    discountType = totalDiscountHasPercent ? PERCENTAGE : FLAT_FEE;
    amount = discount.totalDiscount.amount;
  }

  if (discount.hourlyRate) {
    discountType = HOURLY_RATE_DEFAULT;
    amount = discount.hourlyRate;
  }

  return {
    [ACCOUNT_DISCOUNT]: discountType,
    [DISCOUNT_AMOUNT]: amount,
  };
};

const totalDiscountOptions = [
  [PERCENTAGE, 'Percent'],
  [FLAT_FEE, 'Dollars'],
];
const totalDiscounts = [PERCENTAGE, FLAT_FEE];

export const AccountSettingsModal = ({ onCancel, open, accountDiscount, sourceId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const canSeeAccountDiscount = useHasPermission(AccountsPermissions.PERM_CAN_SEE_ACCOUNT_DISCOUNT);
  const canSeeAccountPaymentOption = useHasPermission(AccountsPermissions.PERM_CAN_SEE_ACCOUNT_PAYMENT_OPTION);
  const canEditAccountDiscount = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNT_DISCOUNT);
  const canEditPaymentOption = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNT_PAYMENT_OPTION);
  const [hasChanges, setHasChanges] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaDiscount),
    defaultValues: { ...fromDTO(accountDiscount), [PAYMENT_OPTION]: accountDiscount[PAYMENT_OPTION] || '' },
  });
  const currentAccountDiscount = watch(ACCOUNT_DISCOUNT);

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const handleChangeCurrentDiscount = (event) => setValue(ACCOUNT_DISCOUNT, event.target.value);

  const handleSubmitForm = async (data) => {
    setIsDisabledInProcessing(true);

    try {
      if (canEditAccountDiscount) {
        await accountAPI.setAccountDiscount(sourceId, updateDiscount(data));
      }

      if (canEditPaymentOption) {
        await accountAPI.setPaymentOption(sourceId, data[PAYMENT_OPTION]);
      }

      enqueueSnackbar('Customer information saved successfully', { variant: 'success' });
      onCancel();
    } catch (error) {
      enqueueSnackbar(`Can't update customer information`, { variant: 'error' });
    } finally {
      setIsDisabledInProcessing(false);
    }
  };

  return (
    <Modal
      open={open}
      title="Settings"
      maxWidth="sm"
      onClose={onCancel}
      color="grey"
      disabledInProcessing={isDisabledInProcessing}
      actions={[
        {
          label: 'cancel',
          onClick: onCancel,
        },
        {
          label: 'save',
          disabled: !hasChanges,
          onClick: handleSubmit(handleSubmitForm),
        },
      ]}
    >
      {canSeeAccountDiscount && (
        <>
          <RadioGroup
            control={control}
            name={ACCOUNT_DISCOUNT}
            direction={isMobile ? 'column' : 'row'}
            label={
              <>
                <BodyText color="textPrimary">
                  <b>{labels[ACCOUNT_DISCOUNT]}</b>
                </BodyText>
              </>
            }
          >
            <Radio
              value={currentAccountDiscount === PERCENTAGE ? PERCENTAGE : FLAT_FEE}
              label="Total Discount"
              sameUncheckedColor
              color="primary"
              data-testid={currentAccountDiscount === PERCENTAGE ? 'percentage' : 'flatFee'}
              size="small"
              disabled={!canEditAccountDiscount}
            />
            <Radio
              value={HOURLY_RATE_DISCOUNT}
              label={labels[HOURLY_RATE_DISCOUNT]}
              sameUncheckedColor
              data-testid="hourlyRateDiscount"
              color="primary"
              size="small"
              disabled={!canEditAccountDiscount}
            />
            <Radio
              value={HOURLY_RATE_DEFAULT}
              data-testid="hourlyRateDefault"
              label={labels[HOURLY_RATE_DEFAULT]}
              sameUncheckedColor
              color="primary"
              size="small"
              disabled={!canEditAccountDiscount}
            />
          </RadioGroup>
          <Box mt={1} display="flex" justifyContent={!isMobile && 'center'}>
            <Box width={totalDiscounts.includes(currentAccountDiscount) ? '20rem' : '13rem'} display="flex">
              <TextInput
                InputProps={{
                  // @ts-ignore
                  inputComponent: SimpleCurrencyInput,
                  ...(currentAccountDiscount === PERCENTAGE
                    ? { endAdornment: <InputAdornment position="start">%</InputAdornment> }
                    : { startAdornment: <InputAdornment position="start">$</InputAdornment> }),
                }}
                inputProps={{ prefix: '' }}
                data-testid="discountAmount"
                control={control}
                hiddenLabel
                name={DISCOUNT_AMOUNT}
                disabled={!canEditAccountDiscount}
              />
              {totalDiscounts.includes(currentAccountDiscount) && (
                <Box width="7rem" ml={1}>
                  <Select
                    hiddenLabel
                    fullWidth
                    data-testid="accountDiscount"
                    value={currentAccountDiscount}
                    onChange={handleChangeCurrentDiscount}
                    options={totalDiscountOptions}
                    disabled={!canEditAccountDiscount}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
      {canSeeAccountPaymentOption && (
        <Box mt={3}>
          <RadioGroup
            control={control}
            name={PAYMENT_OPTION}
            label={
              <>
                <BodyText color="textPrimary">
                  <b>{labels[PAYMENT_OPTION]}</b>
                </BodyText>
              </>
            }
          >
            <Radio
              label={labels[COD]}
              value={PaymentOptions.COD}
              data-testid="cod"
              sameUncheckedColor
              color="primary"
              size="small"
              disabled={!canEditPaymentOption}
            />
            <Radio
              label={labels[IOD]}
              value={PaymentOptions.IOD}
              data-testid="iod"
              sameUncheckedColor
              color="primary"
              size="small"
              disabled={!canEditPaymentOption}
            />
          </RadioGroup>
        </Box>
      )}
    </Modal>
  );
};

AccountSettingsModal.propTypes = {
  onCancel: pt.func.isRequired,
  open: pt.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  accountDiscount: pt.object.isRequired,
  sourceId: pt.number.isRequired,
};
