import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Set } from 'immutable';
import ActivityList from './ActivityList';

export default function ActivityLogView({ pastActions, nextSteps, expanded, onExpand, onEdit }) {
  const [expandedNextStepsItems, setExpandedNextStepsItems] = useState(Set());
  const [expandedNextStepsItemsSmall, setExpandedNextStepsItemsSmall] = useState(Set());
  const [expandedPastItems, setExpandedPastItems] = useState(Set());
  const [expandedPastItemsSmall, setExpandedPastItemsSmall] = useState(Set());

  const handleToggleNextStepsExpand = () => {
    const expandedFunction = expanded ? setExpandedNextStepsItems : setExpandedNextStepsItemsSmall;
    expandedFunction(state => (state.isEmpty() ? Set(nextSteps.map(({ id }) => id)) : Set()));
  };

  const handleTogglePastExpand = () => {
    const expandedFunction = expanded ? setExpandedPastItems : setExpandedPastItemsSmall;
    expandedFunction(state => (state.isEmpty() ? Set(pastActions.map(({ id }) => id)) : Set()));
  };

  return (
    <>
      <ActivityList
        title="Next Steps"
        items={nextSteps}
        maxToShow={expanded ? Number.MAX_SAFE_INTEGER : 3}
        emptyLabel="There are no next steps."
        onExpand={onExpand}
        onEdit={onEdit}
        expandedItems={expanded ? expandedNextStepsItems : expandedNextStepsItemsSmall}
        onToggleExpand={handleToggleNextStepsExpand}
      />
      <ActivityList
        title="Past Actions"
        items={pastActions}
        emptyLabel="There are no past actions."
        maxToShow={expanded ? Number.MAX_SAFE_INTEGER : 4}
        onExpand={onExpand}
        onEdit={onEdit}
        expandedItems={expanded ? expandedPastItems : expandedPastItemsSmall}
        onToggleExpand={handleTogglePastExpand}
      />
    </>
  );
}

ActivityLogView.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  pastActions: PropTypes.arrayOf(PropTypes.any),
  nextSteps: PropTypes.arrayOf(PropTypes.any)
};

ActivityLogView.defaultProps = {
  pastActions: [],
  nextSteps: []
};
