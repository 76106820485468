import React, { useState } from 'react';

import { BodyText, Link, Order, StorageQuote } from '@elromcoinc/react-shared';
import { TableCell, Tooltip, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import { StorageChargeModal } from 'admin/components/OrderWindow/modals';

const useStyles = makeStyles({
  displayIcon: {
    display: 'block',
  },
});

enum Modals {
  STORAGE = 'STORAGE',
}

export const Storage = () => {
  const classes = useStyles();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { isClosing } = useOrderClosingContext();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('storageQuote');
  const storageQuote = order.getIn(name.split('.')) as StorageQuote;
  const settingsValidationErrors = storageQuote?.settingsValidationErrors;

  const handleStorageChargeChanges = (value: StorageQuote | null) => {
    onChange({ name: getServicePropertyName('storageQuote'), value });
    setOpenModal(null);
  };
  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <HighlightedTableRow data-testId="storage">
      <TableCell size="small" padding="none">
        <Box display="flex" alignItems="center">
          <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.STORAGE)}>
            Storage
          </Link>
          {!!settingsValidationErrors?.length && (
            <Box ml={1}>
              <Tooltip
                title={
                  <ul>
                    {settingsValidationErrors?.map((errorText: string) => (
                      <li key={errorText}>
                        <BodyText>{errorText}</BodyText>
                      </li>
                    ))}
                  </ul>
                }
              >
                <HelpOutlineIcon color="error" classes={{ root: classes.displayIcon }} />
              </Tooltip>
            </Box>
          )}
        </Box>
        {Modals.STORAGE === openModal && (
          <StorageChargeModal
            onSave={handleStorageChargeChanges}
            onCancel={toggleOpenModal(null)}
            storageQuote={storageQuote}
          />
        )}
      </TableCell>
      <TableCell>
        <Text
          value={(isClosing ? order.closingOrderDetail : order)
            ?.getStorageTotal(isSelectedAllServices ? 0 : serviceIndex)
            ?.asHumanReadableString()}
          data-testId={'storageValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
