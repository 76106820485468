import React from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { sortableElement } from 'react-sortable-hoc';

const useStyles = makeStyles(({ palette, spacing }) => ({
  wrapper: {
    zIndex: 2000,
    minHeight: '8.75rem',
    margin: '10px 0',
    backgroundColor: palette.common.white,
    borderRadius: spacing(0.75),
    display: 'flex',
    '& > .draggerBox': {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: palette.action.hover,
      width: '28px',
      padding: '4px 0',
      borderRadius: spacing(0.75, 0, 0, 0.75),
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > svg': {
        '&:last-child': {
          position: 'absolute',
          top: '50%',
          marginTop: '-12px',
        },
      },
    },
    '& > .detailsBox': {
      padding: '4px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      '& > .addressSection': {
        '& > p': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      '& > .actionSection': {
        padding: '0 4px',
      },
      '& > .detailsSection': {
        '& > p': {
          padding: '1px 0',
        },
      },
    },
  },
}));

const SortableElement = sortableElement(({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Box {...props} className={classes.wrapper}>
      {children}
    </Box>
  );
});

export default SortableElement;
