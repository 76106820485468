/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { makeStyles, List, ListItem, ListItemText, Box, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UndoIcon from '@material-ui/icons/Undo';
import { useDispatch } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { BodyText, IconButton, Button, BodySmallText, Checkbox } from '@elromcoinc/react-shared';
import { openOrder } from 'admin/autodux/WindowsAutodux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    paddingTop: 0
  },
  inline: {
    display: 'inline',
    fontWeight: 'bold'
  },
  listItem: {
    position: 'relative',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.grey['100'],
    borderRadius: 5,
    marginBottom: theme.spacing(1.25)
  },
  closeButton: {
    padding: '5px',
    marginRight: '-7px',
    '&:hover': {
      backgroundColor: 'rgba(2, 156, 256, 0.08)'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  checkBox: {
    padding: '5px',
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  },
  listItemTextPrimary: {
    marginRight: theme.spacing(-1.875),
    wordBreak: 'break-word',
    display: 'block',
    fontWeight: 'bold',
    '& .MuiTypography-root': {
      fontWeight: 'bold'
    }
  },
  normalFontWeight: {
    fontWeight: 'normal',
    '& .MuiTypography-root': {
      fontWeight: 'normal'
    }
  },
  notificationTimeAgo: {
    color: theme.palette.grey[500]
  }
}));

export default function NotiMsgList(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    notiData,
    toggleReadStatus,
    toggleDismissedStatus,
    dismissed,
    isInProgressChangeStatus,
    isInProgressReadDismissAll
  } = props;

  const toggleRead = id => () => {
    toggleReadStatus(id);
  };

  const toggleDismissed = id => () => {
    toggleDismissedStatus(id);
  };

  const handleClickOrderNumber = orderId => () => {
    dispatch(openOrder(orderId));
  };

  return (
    <List className={classes.root}>
      {notiData.map(item => (
        <ListItem key={item.id} alignItems="flex-start" className={classes.listItem}>
          <ListItemText
            classes={{
              primary: classNames(classes.listItemTextPrimary, !item.read ? classes.normalFontWeight : null)
            }}
            primary={
              <>
                <Box display="flex" justifyContent="space-between" alignItems="baseline" mt={-0.5} mb={-0.5}>
                  <Box display="flex" alignItems="baseline">
                    {item.orderNumber && (
                      <Box ml={-1} mb={-1}>
                        <Button
                          color="primary"
                          size="small"
                          variant="text"
                          onClick={handleClickOrderNumber(item.orderId)}>
                          <>#</>
                          {item.orderNumber}
                        </Button>
                      </Box>
                    )}
                    <BodyText className={classes.notificationTimeAgo} gutterBottom>
                      <Box component="span" mr={0.5}>
                        &#8226;
                      </Box>
                      {`${formatDistanceToNow(new Date(item.dateTime))} ago`}
                    </BodyText>
                  </Box>
                  <Box>
                    {dismissed ? (
                      <Tooltip title="Undo notification" placement="top" arrow>
                        <Box component="span">
                          <IconButton
                            aria-label="Undo"
                            color="primary"
                            variant="text"
                            size="small"
                            className={classes.closeButton}
                            onClick={toggleDismissed(item.id)}
                            disabled={isInProgressReadDismissAll || isInProgressChangeStatus.includes(item.id)}>
                            <UndoIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Tooltip>
                    ) : (
                      <>
                        <Tooltip title="Dismiss notification" placement="top" arrow>
                          <Box component="span">
                            <IconButton
                              aria-label="dismiss"
                              color="primary"
                              variant="text"
                              size="small"
                              className={classes.closeButton}
                              onClick={toggleDismissed(item.id)}
                              disabled={isInProgressReadDismissAll || isInProgressChangeStatus.includes(item.id)}>
                              <CloseIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                        <Tooltip title={item.read ? 'Mark as unread' : 'Mark as read'} placement="top" arrow>
                          <Box component="span">
                            <Checkbox
                              className={classes.checkBox}
                              checked={item.read}
                              color="primary"
                              name="read-notification"
                              sameUncheckedColor
                              variant="circle-outlined"
                              onClick={toggleRead(item.id)}
                              disabled={isInProgressReadDismissAll || isInProgressChangeStatus.includes(item.id)}
                            />
                          </Box>
                        </Tooltip>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            }
            secondary={
              <Box mb={0.5}>
                {item.userName && (
                  <>
                    <BodySmallText component="span" gutterBottom>
                      <b>{item.userName}</b>
                    </BodySmallText>
                    <br />
                  </>
                )}
                <BodySmallText component="span" gutterBottom>
                  <b>{item.description}</b>
                </BodySmallText>
                <br />
                <BodySmallText component="span" gutterBottom>
                  <b>
                    <i>{item.formattedDateTime}</i>
                  </b>
                </BodySmallText>
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

NotiMsgList.propTypes = {
  notiData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      description: PropTypes.string.isRequired,
      read: PropTypes.bool.isRequired,
      dismissed: PropTypes.bool.isRequired
    })
  ).isRequired,
  toggleReadStatus: PropTypes.func.isRequired,
  toggleDismissedStatus: PropTypes.func.isRequired,
  isInProgressChangeStatus: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  isInProgressReadDismissAll: PropTypes.bool.isRequired,
  dismissed: PropTypes.bool.isRequired
};

NotiMsgList.defaultProps = {};
