import React, { useEffect, useState } from 'react';

import { HeaderText, IconButton } from '@elromcoinc/react-shared';
import { AppBar, Box, SwipeableDrawer, Toolbar, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import SettingsIcon from '@material-ui/icons/Settings';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getNotificationCount } from 'admin/autodux/NotificationAutodux';

import NotiSettingsDrawer from './NotiSettingsDrawer';
import NotiTabs from './NotiTabs';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  list1: {
    width: '70vw',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    overflow: 'hidden',
    overflowX: 'hidden',
  },
  appBar1: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: '#333333',
  },
  sectionDesktop1: {
    display: 'flex',
  },
  iconButton: {
    padding: theme.spacing(1),
  },
  openSettings: {
    backgroundColor: theme.palette.grey['100'],
    '&:hover': {
      backgroundColor: theme.palette.grey['100'],
    },
  },
  settingIcons: {
    color: theme.palette.primary.main,
    position: 'absolute',
    right: 0,
    [theme.breakpoints.up('sm')]: {
      right: 20,
    },
  },
  drawerZIndexFix: {
    zIndex: `${theme.zIndex.drawer} !important`,
  },
}));

export default function NotificationDrawer(props) {
  const classes = useStyles();
  const { open, toggleNotification, toggleActiveNoti, activeNoti, isFetchingActive } = props;

  const count = useSelector(getNotificationCount);
  const [openSettings, setOpenSetSettings] = useState(false);

  const toggleSettings = () => {
    setOpenSetSettings((state) => !state);
  };

  useEffect(() => {
    if (!open) {
      setOpenSetSettings(false);
    }
  }, [open]);

  const renderSettingIcons = () => (
    <Box className={classes.settingIcons}>
      <IconButton
        className={classes.iconButton}
        edge="start"
        aria-label="Toggle notifications"
        color="primary"
        variant="text"
        disabled={isFetchingActive}
        onClick={toggleActiveNoti}
      >
        {activeNoti ? <NotificationsActiveIcon /> : <NotificationsOffIcon />}
      </IconButton>
      <IconButton
        className={classNames(classes.iconButton, openSettings ? classes.openSettings : null)}
        aria-label="Settings"
        variant="text"
        color="primary"
        onClick={toggleSettings}
      >
        <SettingsIcon />
      </IconButton>
    </Box>
  );

  return (
    <SwipeableDrawer
      classes={{ root: classes.drawerZIndexFix }}
      anchor="right"
      open={open}
      onClose={toggleNotification(false)}
      onOpen={toggleNotification(true)}
      swipeAreaWidth={0}
    >
      <div className={classes.list1} role="presentation" onKeyDown={toggleNotification(false)}>
        <AppBar position="absolute" className={classNames(classes.appBar1)}>
          <Toolbar>
            <HeaderText noWrap>
              <b>{`Notifications (${count})`}</b>
            </HeaderText>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop1}>
              <IconButton
                className={classes.iconButton}
                edge="end"
                aria-label="Close notifications"
                color="inherit"
                onClick={toggleNotification(false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {openSettings && <NotiSettingsDrawer openSettings={openSettings}>{renderSettingIcons()}</NotiSettingsDrawer>}
        {!openSettings && <NotiTabs>{renderSettingIcons()}</NotiTabs>}
      </div>
    </SwipeableDrawer>
  );
}

NotificationDrawer.propTypes = {
  open: PropTypes.bool,
  toggleNotification: PropTypes.func.isRequired,
  toggleActiveNoti: PropTypes.func.isRequired,
  activeNoti: PropTypes.bool.isRequired,
  isFetchingActive: PropTypes.bool.isRequired,
};

NotificationDrawer.defaultProps = {
  open: false,
};
