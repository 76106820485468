import React from 'react';

import { ListItem, Tooltip, makeStyles } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingLeft: theme.spacing(1.7),
    paddingRight: theme.spacing(1.5),
    '& :first-child': {
      minWidth: 'auto',
    },
  },
  icon: {
    fontSize: theme.spacing(4),
    '.active & ': {
      color: 'inherit',
    },
  },
}));

const MenuItem = ({ tooltip, showTooltip, Icon, dataTestId, ...rest }) => {
  const classes = useStyles();

  return (
    <Tooltip title={tooltip} placement="right">
      <ListItem
        button
        component={NavLink}
        data-testid={dataTestId}
        {...rest}
        className={clsx(classes.menuItem, rest.className)}
      >
        <ListItemIcon className={classes.icon}>
          <Icon color="inherit" fontSize="inherit" />
        </ListItemIcon>
      </ListItem>
    </Tooltip>
  );
};

MenuItem.propTypes = {
  tooltip: PropTypes.string.isRequired,
  Icon: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  dataTestId: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
};

export default MenuItem;
