import { User as UserDto, UserProps } from '@elromcoinc/react-shared';
// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import authApi from 'admin/api/AuthAPI';
import { fetchCurrentEmployee, setCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { clearNotifications } from 'admin/autodux/NotificationAutodux';
import { clearSelectedWindow } from 'admin/autodux/WindowsAutodux';
import User from 'admin/entities/User';
import storageUtil from 'admin/utils/storageUtil';

function getAuthErrorMsg(response: any) {
  switch (response.status) {
    case 401:
    case 403:
      if (response?.data?.isTerminated) {
        return 'Your access to the software was denied. Please contact Elromco Support at 617-313-0200 for further assistance.';
      }
      return 'The information entered does not match our records.';
    case 409:
      return 'User already has an active session.';
    default:
      return 'Sorry Authorization service is unavailable right now.';
  }
}

const {
  reducer,
  actions: {
    setAccessIsTerminated,
    setIsFetching,
    fetchUserSuccess,
    onLogout,
    loginFailure,
    readCurrentUserError,
    setIsLogout,
    setIsSessionExpired,
  },
  selectors: {
    getLoggedIn,
    getAccessIsTerminated,
    getIsFetching,
    getErrors,
    getUser,
    getUserDto,
    getIsSessionExpired,
    getIsLogout,
  },
} = autodux({
  slice: 'auth',
  initial: {
    loggedIn: null,
    accessIsTerminated: false,
    isFetching: false,
    errors: null,
    user: null,
    userDto: null,
    isSessionExpired: false,
    isLogout: false,
  },
  actions: {
    fetchUserSuccess: (state: any, user: UserProps) => ({
      ...state,
      loggedIn: true,
      accessIsTerminated: false,
      isFetching: false,
      errors: null,
      user: User.createUserRecord(user),
      // @ts-ignore
      userDto: new UserDto(user, user.grantedAuthorityNames),
    }),
    onLogout: (state: any) => ({
      ...state,
      loggedIn: false,
      accessIsTerminated: false,
      isFetching: false,
      errors: null,
      user: null,
      userDto: null,
      isSessionExpired: false,
      isLogout: true,
    }),
    loginFailure: (state: any, payload: any) => ({
      ...state,
      loggedIn: false,
      isFetching: false,
      user: null,
      userDto: null,
      errors: getAuthErrorMsg(payload),
      accessIsTerminated: payload?.status === 403 && !!payload?.data?.isTerminated,
    }),
    readCurrentUserError: (state: any, payload: any) => ({
      ...state,
      loggedIn: false,
      isFetching: false,
      user: null,
      userDto: null,
      errors: null,
      accessIsTerminated: payload?.status === 403 && !!payload?.data?.isTerminated,
    }),
  },
});

export function readCurrentUser() {
  return async (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setIsFetching(true));
    try {
      const user = await authApi.readCurrentUser();
      //this logic for Elromco Admin, he doesn't have id
      if (user?.id) {
        // @ts-ignore because some problem fetchCurrentEmployee type
        const { positionTypes } = await dispatch(fetchCurrentEmployee(user.id));
        dispatch(fetchUserSuccess({ ...user, positionTypes }));
      } else {
        dispatch(fetchUserSuccess(user));
      }
    } catch (error: any) {
      dispatch(readCurrentUserError(error));
    }
  };
}

export const login = (username: string, password: string, companyId: number, sessionException: boolean | null) => {
  return async (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setIsFetching(true));
    try {
      const { data } = await authApi.login(username, password, companyId, !!sessionException);
      //this logic for Elromco Admin, he doesn't have id
      if (data?.id) {
        // @ts-ignore because some problem fetchCurrentEmployee type
        const { positionTypes } = await dispatch(fetchCurrentEmployee(data.id));
        dispatch(fetchUserSuccess({ ...data, positionTypes }));
      } else {
        dispatch(fetchUserSuccess(data));
      }

      dispatch(setIsSessionExpired(false));
      dispatch(setIsLogout(false));

      return data;
    } catch (error: any) {
      const { response } = error;

      if (response && response.status === 409) {
        return Promise.reject(response);
      }
      dispatch(loginFailure(response));
      return Promise.reject(response);
    }
  };
};

export function logout() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    storageUtil.clear();
    dispatch(clearNotifications());
    return authApi
      .logout()
      .then(() => dispatch(onLogout()))
      .then(() => dispatch(clearSelectedWindow()))
      .then(() => dispatch(setCurrentEmployee(null)));
  };
}

export {
  setAccessIsTerminated,
  getLoggedIn,
  getAccessIsTerminated,
  getIsFetching,
  getErrors,
  getUser,
  getUserDto,
  getIsSessionExpired,
  getIsLogout,
  setIsSessionExpired,
};
export default reducer;
