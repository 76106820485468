import { createSlice } from '@reduxjs/toolkit';
import { getLeadScoreSettings } from 'redux-conf/leadScoreSettings/leadScoreSettings-actions';

import { initialState } from './leadScoreSettings-state';

export const { reducer, actions } = createSlice({
  name: 'leadScoreSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLeadScoreSettings.pending, (state) => {
        state.inFlight = true;
        state.error = null;
      })
      .addCase(getLeadScoreSettings.fulfilled, (state, action) => {
        state.inFlight = false;
        state.settings = action.payload;
      })
      .addCase(getLeadScoreSettings.rejected, (state, action) => {
        state.inFlight = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action?.error?.message;
        }
      });
  },
});

export default reducer;
