import React, { useCallback, useEffect } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  CreditCardType,
  PaymentCreditCardRecordDto,
  PaymentStatus,
  cardExpirationYUP,
  cardTypeIcon,
  toBackEndDate,
  toDate,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { CreditCardRecordPaymentFormInputs } from '../Forms';
import { PaymentTypeSelector } from '../Forms/PaymentTypeSelector';
import { usePaymentSourceContext } from '../PaymentsSourceContext';
import { CashPaymentDto, CashPaymentProps, cashScema } from './CashPayment';
import {
  AMOUNT,
  CARDHOLDER_NAME,
  EXPIRATION,
  LAST_FOUR_DIGITS,
  NOTES,
  PAID_DATE,
  PAYMENT_TYPE,
  POSTAL_CODE,
  labels,
} from './Common';

export interface CreditCardRecordDto extends CashPaymentDto {
  'cc-name': string;
  lastFourDigits: string;
  postalCode: string;
  'cc-exp': string;
}

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    creditCardWrapper: {
      display: 'flex',
      '& svg': {
        fontSize: '35px',
        padding: '7px 10px 7px 0px',
      },
    },
  }),
);

const schema = cashScema.shape(
  {
    [CARDHOLDER_NAME]: string().label(labels[CARDHOLDER_NAME]).required(),
    [LAST_FOUR_DIGITS]: string().when(LAST_FOUR_DIGITS, (lastFourDigits, _schema) =>
      lastFourDigits?.length > 0
        ? _schema.label(labels[LAST_FOUR_DIGITS]).required().min(4).max(4)
        : _schema.optional(),
    ),
    [POSTAL_CODE]: string().when(POSTAL_CODE, (postalCode, _schema) =>
      postalCode?.length > 0 ? zipCodeAsyncYUP(POSTAL_CODE).label(labels[POSTAL_CODE]) : _schema.optional(),
    ),
    [EXPIRATION]: cardExpirationYUP({ label: labels[EXPIRATION] }),
  },
  [
    [POSTAL_CODE, POSTAL_CODE],
    [LAST_FOUR_DIGITS, LAST_FOUR_DIGITS],
  ],
);

interface CreditCardRecordProps extends CashPaymentProps {
  cardHolder?: string;
}

const CreditCardRecord = ({
  amount: initialAmount,
  finalAmount,
  onTypeChange,
  onAmountChange,
  type,
  isDepositAvailable,
  ifDepositUnavailableHint,
  cardHolder = '',
  onMount,
  activitySources,
}: CreditCardRecordProps) => {
  const classes = useStyles();
  const { sourceId, activitySource } = usePaymentSourceContext();
  const methods = useForm<CreditCardRecordDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: initialAmount as number,
      [PAID_DATE]: new Date(),
      [PAYMENT_TYPE]: type,
      [CARDHOLDER_NAME]: cardHolder,
    },
  });

  const amount = methods.watch(AMOUNT);

  useEffect(() => {
    onAmountChange?.(amount);
  }, [amount]);

  useEffect(() => {
    onTypeChange?.(methods.watch(PAYMENT_TYPE));
  }, [methods.watch(PAYMENT_TYPE)]);

  const createPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CreditCardRecordDto) => {
          paymentActionsApi
            .createCreditCardRecordPayment(
              PaymentCreditCardRecordDto.createPaymentCreditCardRecordDto({
                date: formatISO(data[PAID_DATE]),
                amount: +finalAmount,
                message: data[NOTES],
                type,
                status: PaymentStatus.PROCESSED,
                sourceId,
                activitySource,
                activitySources,
                postalCode: data[POSTAL_CODE],
                cardholderName: data[CARDHOLDER_NAME],
                lastFourDigits: data[LAST_FOUR_DIGITS],
                expiration: toBackEndDate(toDate(`15/${data[EXPIRATION]}`, 'dd/MM/yy'))!,
              }),
            )
            .then(resolve)
            .catch(reject);
        }, reject)();
      }),
    [methods.handleSubmit, sourceId, activitySource, type, activitySources?.length, finalAmount],
  );

  useEffect(() => {
    onMount?.(createPayment);
  }, [createPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Box mb={1} p={1} borderRadius={4} bgcolor="rgb(255 120 63 / 15%)">
        <BodyBigText color="secondary">
          <b>Note: </b>This is to record your payment only, and will NOT process any electronic payments. If you wish to
          process a credit card payment, please use “Credit Online.”
        </BodyBigText>
      </Box>
      <Grid container spacing={2}>
        {activitySource === ActivitySourceType.ORDER && (
          <PaymentTypeSelector
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            isDepositAvailable={isDepositAvailable}
          />
        )}
        <Grid item xs={12} className={classes.creditCardWrapper}>
          {Object.keys(CreditCardType).map((card) => cardTypeIcon[card as keyof typeof cardTypeIcon])}
        </Grid>
        <CreditCardRecordPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { CreditCardRecord };
