import React, { FC, ReactNode } from 'react';

import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';

interface DashboardCardTitleProps {
  title: string;
  children?: ReactNode;
}

const DashboardCardTitle: FC<DashboardCardTitleProps> = ({ title, children }) => {
  const theme = useTheme();

  return (
    <Box
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      fontSize="23px"
      fontWeight={300}
      title={title}
      color={theme.palette.text.primary}
    >
      {title}
      {children}
    </Box>
  );
};

export default DashboardCardTitle;
