import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { TextInput } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import searchApi, { SearchDomains } from 'admin/api/searchApi';
import useDebounce from 'common/hooks/useDebounce';

const useStyles = makeStyles({
  select: {
    '& .MuiAutocomplete-inputRoot': {
      padding: '0px 28px 0px 0px',
    },
    width: '200px',
  },
});

interface LinkOrderAutocompleteProps {
  handleLinkOrder: (value: number) => void;
}

const HALF_A_SECOND = 500;

const LinkOrderAutocomplete: FC<LinkOrderAutocompleteProps> = ({ handleLinkOrder }) => {
  const classes = useStyles();
  const [sourceOptions, setSourceOptions] = useState<[string, string][]>([]);
  const [sourceIdInputValue, setSourceIdInputValue] = useState<string | null>(null);
  const [selectedSourceId, setSelectedSourceId] = useState<[string, string] | null>(null);
  const [inFlightSearch, setInFlightSearch] = useState(false);

  const debouncedSourceIdInputValue = useDebounce(sourceIdInputValue, HALF_A_SECOND);

  useEffect(() => {
    setInFlightSearch(true);

    searchApi(debouncedSourceIdInputValue, SearchDomains.ORDERS, 0, 100)
      .then(({ orders: { pageElements: orders } }) => {
        setSourceOptions(
          orders.map((item) => {
            const {
              orderId,
              orderNumber,
              contactInfo: { firstName, lastName },
            } = item;
            const text = `${firstName} ${lastName} (${orderNumber})`.trim();

            return [`${orderId}`, text] as [string, string];
          }),
        );
      })
      .catch()
      .then(() => {
        setInFlightSearch(false);
      });
  }, [debouncedSourceIdInputValue]);

  const handleAutocompleteInputChange = (event: ChangeEvent<HTMLElement>, value: string) => {
    setSourceIdInputValue(value);
  };

  const handleAutocompleteChange = (event: ChangeEvent<HTMLElement>, value: [string, string]) => {
    handleLinkOrder(Number(value[0]));
    setSelectedSourceId(value);
  };

  return (
    <Autocomplete
      autoComplete
      selectOnFocus
      clearOnBlur
      includeInputInList
      disableClearable
      options={sourceOptions}
      getOptionLabel={(option) => option[1]}
      value={selectedSourceId!}
      onChange={handleAutocompleteChange as any}
      onInputChange={handleAutocompleteInputChange as any}
      loading={inFlightSearch}
      renderInput={(params) => (
        <TextInput
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          className={classes.select}
          placeholder="Link order"
          hiddenLabel
        />
      )}
    />
  );
};

export default LinkOrderAutocomplete;
