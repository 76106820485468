import axios from 'axios';

export const getLogs = (pageIndex: number, pageSize: number) => {
  return axios.get<PageDTO<IChangeLog>, PageDTO<IChangeLog>>('/api/settings/changelog/all', {
    params: {
      pageIndex,
      pageSize,
    },
  });
};
