import React, { useEffect, useState } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';
import { Dialog, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { isAfter } from 'date-fns';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import activityApi from 'admin/api/activityApi';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import ManageActivity from 'admin/components/OrderWindow/modals/ManageActivity';
import ACTIVITY_TYPE from 'admin/constants/ActivityType';
import OrderLog from 'admin/entities/OrderLog';
import Card from 'common/components/Card';

import LogComponent from './LogComponent';

const useStyle = makeStyles(({ spacing }) => ({
  paper: {
    padding: spacing(1),
  },
}));

const isInTheFuture = ({ dateTime }) => isAfter(dateTime, new Date());
const isInThePast = (item) => !isInTheFuture(item);

export default function ActivitiesAndOrderLogContainer(props) {
  const { sourceId, activitySource, disabled } = props;
  const classes = useStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [startMode, setStartMode] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [editActivity, setEditActivity] = useState(null);
  const [pastActions, setPastActions] = useState(List());
  const [nextSteps, setNextSteps] = useState(List());
  const [orderLogs, setOrderLogs] = useState(List());
  const isSessionExpired = useSelector(getIsSessionExpired);

  const fetchActivities = async (id) => {
    const orderLogItems = await (activitySource === ActivitySourceType.ORDER
      ? activityApi.getOrderLogs(id)
      : activityApi.getAccountLogs(id)
    ).then((res) =>
      List(res.pageElements)
        .map(OrderLog.fromActivity)
        .filter(Boolean)
        .sortBy((it) => -it.dateTime),
    );
    const notEventLogs = orderLogItems.filter((it) => !it.isEventLog);
    setOrderLogs(orderLogItems.filter(isInThePast).filter((it) => Boolean(it.message)));
    setPastActions(notEventLogs.filter(isInThePast));
    setNextSteps(notEventLogs.filter(isInTheFuture));
  };

  useEffect(() => {
    if (!sourceId) {
      return () => {};
    }

    if (!isSessionExpired) {
      fetchActivities(sourceId);

      const interval = setInterval(() => fetchActivities(sourceId), 10e3);
      return () => clearInterval(interval);
    }
  }, [sourceId, isSessionExpired]);

  const onCancelSaveActivity = () => {
    setShowAddActivityModal(false);
    setEditActivity(null);
  };

  const handleManualActivitySave = (activity) => {
    const activityToSave = activity.setActivitySource(activitySource, sourceId);

    const doActionOnActivity = activity.deleted
      ? activityApi.deleteManuallyEnteredActivity(activity.id)
      : activityApi.saveManuallyEnteredActivity(activityToSave.toDTO());

    doActionOnActivity.then((res) => {
      setPastActions((actions) => {
        if (activityToSave.id) {
          return activityToSave.deleted
            ? actions.filter((a) => a.id !== activityToSave.id)
            : actions.map((a) => (a.id === activityToSave.id ? OrderLog.fromActivity(res) : a));
        }

        return actions.insert(0, OrderLog.fromActivity(res));
      });
    });

    onCancelSaveActivity();
  };

  const handleOnExpand = (mode) =>
    setExpanded((flag) => {
      setStartMode(flag ? null : mode);
      return !flag;
    });

  const handleEdit = (id, activityType) => {
    const activity = nextSteps.concat(pastActions).find((item) => item.id === id);

    if (!activity) {
      return;
    }

    if (activityType === ACTIVITY_TYPE.MANUALLY_ENTERED_ACTIVITY) {
      setEditActivity(activity);
      setShowAddActivityModal(true);
    }
  };

  const activitiesAndLogProps = {
    expanded,
    onExpand: handleOnExpand,
    nextSteps,
    orderLogs,
    pastActions,
    onAddAction: () => setShowAddActivityModal(true),
    startMode,
    onEdit: handleEdit,
    activitySource,
  };

  return (
    <Card minWidth="290px" {...props}>
      <LogComponent {...activitiesAndLogProps} disabled={disabled} expanded={false} />
      {showAddActivityModal && (
        <ManageActivity
          onCancel={onCancelSaveActivity}
          onSave={handleManualActivitySave}
          activity={editActivity ? editActivity.activity : null}
          open
        />
      )}
      {expanded && (
        <Dialog fullScreen={fullScreen} open fullWidth classes={classes}>
          <LogComponent {...activitiesAndLogProps} />
        </Dialog>
      )}
    </Card>
  );
}

ActivitiesAndOrderLogContainer.propTypes = {
  sourceId: PropTypes.number.isRequired,
  activitySource: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
