import { FC, useState } from 'react';

import { AllServicesIndex } from 'admin/constants';

import { OrderServiceIndexContext } from './OrderServiceIndexContext';

export const OrderServiceIndexProvider: FC = ({ children }) => {
  const [serviceIndex, setServiceIndex] = useState(0);
  const isSelectedAllServices = serviceIndex === AllServicesIndex;
  const currentServiceIndex = serviceIndex === AllServicesIndex ? 0 : serviceIndex;

  return (
    <OrderServiceIndexContext.Provider
      value={{
        serviceIndex: currentServiceIndex,
        selectedServiceIndex: serviceIndex,
        setServiceIndex,
        isSelectedAllServices,
      }}
    >
      {children}
    </OrderServiceIndexContext.Provider>
  );
};
