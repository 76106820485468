import { UI_DATE_FORMAT_SHORT, toDateString } from '@elromcoinc/react-shared';
import { format, isDate, parseISO } from 'date-fns';

const renderDate = (date?: Date | string, formatDate = UI_DATE_FORMAT_SHORT) => {
  if (!date) {
    return null;
  }

  if (isDate(date)) {
    return toDateString(date, formatDate);
  }

  return format(parseISO(date as string), formatDate);
};

export { renderDate };
