import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import { Modal, TextInput } from '@elromcoinc/react-shared';

const EditDescription = ({ onClose, onSave, fileName, description, isDisabledInUpdate, open }) => {
  const [info, setInfo] = useState(description);
  const disabled = description === info;

  const saveHandler = () => onSave({ value: info, name: 'description' });

  const handleChangeDescription = ({ target: { value } }) => setInfo(value || null);

  return (
    <Modal
      open={open}
      disabledInProcessing={isDisabledInUpdate}
      title="Edit Description"
      onClose={onClose}
      maxWidth="sm"
      color="grey"
      actions={[
        { label: 'cancel', onClick: onClose },
        {
          label: 'save',
          onClick: saveHandler,
          disabled,
          color: !disabled ? 'primary' : 'default',
          loading: isDisabledInUpdate,
        },
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {`File name: ${fileName}`}
          </Grid>
          <Grid item xs={12}>
            <TextInput
              fullWidth
              multiline
              rows={16}
              label="Description"
              placeholder="Proved file description"
              name="note"
              value={info || ''}
              onChange={handleChangeDescription}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

EditDescription.propTypes = {
  fileName: PropTypes.string.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isDisabledInUpdate: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

EditDescription.defaultProps = {
  description: null,
  isDisabledInUpdate: false,
};

export default EditDescription;
