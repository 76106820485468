import { Record } from 'immutable';
import SpecialHandlingType from 'admin/constants/SpecialHandlingType';

export default class SpecialHandling extends Record({
  disassemblyAssemblyCharge: null,
  woodCratingCharge: null,
  wallDismountingCharge: null,
  recommended: SpecialHandlingType.NONE.toString()
}) {
  static createSpecialHandling(data) {
    return new SpecialHandling(data || {});
  }
}
