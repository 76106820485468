/* @deprecated please use it from react-ui-kit */
export const formatCurrency = (str, fractionDigits = 2, defaultValue = '') => {
  if (!Number.isFinite(str)) {
    return defaultValue;
  }

  return fractionDigits
    ? str.toFixed(fractionDigits).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : str
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        .split('.')[0];
};
