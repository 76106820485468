import React, { useEffect, useRef, useState } from 'react';

import { AdminPermissions, useHasPermission } from '@elromcoinc/react-shared';
import { Theme, createStyles, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useAppSelector } from 'redux-conf';
import { selectLeadScoreSettings } from 'redux-conf/leadScoreSettings/leadScoreSettings-selectors';

import orderApi from 'admin/api/OrderAPI';
import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getReloadAmount } from 'admin/autodux/ReloadAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { makeColumns } from 'admin/components/Dashboard/DashboardLeads/config';
import DashboardTable from 'admin/components/Dashboard/DashboardTable';
import { ClassNameMap, getExpandableRow } from 'admin/components/Reports/config';
import OrderSummary from 'admin/entities/OrderSummary';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    activityContainer: {
      flexWrap: 'nowrap',
      '& > .circle ': {
        marginRight: '5px',
      },
    },
    tableCell: {
      border: 'none',
      padding: '1px 1rem',
    },
  }),
);

const DashboardLeads = () => {
  const classes: ClassNameMap = useStyles();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const [orderSummaries, setOrderSummaries] = useState<OrderSummary[]>([]);
  const [redirect, setRedirect] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<number | null>(null);
  const currentOrders = useRef<CancelablePromise | null>(null);
  const canSeeCustomerOnline = useHasPermission(AdminPermissions.PERM_CAN_SEE_CUSTOMERS_ONLINE);
  const reloadAmount = useSelector(getReloadAmount);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);
  const isLogout: boolean = useSelector(getIsLogout);

  const [sortBy, setSortBy] = useState('created');
  const [sortOrder, setSortOrder] = useState('');

  const onColumnSortChange = (changedColumn: any, direction: any) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const downloadData = () => {
    currentOrders.current = orderApi.getOrderSummariesWithFilters({
      sortBy,
      sortOrder,
      pageSize: 10,
    }) as CancelablePromise;

    currentOrders.current.promise.then(({ pageElements }: { pageElements: OrderSummary[] }) => {
      setOrderSummaries(pageElements.map(OrderSummary.createOrderSummary));
      setIsFetching(false);
    });
  };

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setIsFetching(true);
      downloadData();
      const updateData = setInterval(downloadData, 30000);
      return () => clearInterval(updateData);
    }
  }, [reloadAmount, sortBy, sortOrder, isSessionExpired, isLogout]);

  const onViewAllClick = () => setRedirect(true);

  const onRowSelectionChangeOverridden = ([rowIndex]: [rowIndex: number]) => {
    const { orderId } = orderSummaries[rowIndex] || {};

    if (currentOrderId && orderId === currentOrderId) {
      dispatch(openOrder(orderId));
    } else {
      setCurrentOrderId(orderId!);
    }
  };
  const leadScoreSettings = useAppSelector(selectLeadScoreSettings);

  const columns = makeColumns(orderSummaries, { theme, classes, canSeeCustomerOnline, leadScoreSettings });

  const options = {
    onRowSelectionChangeOverridden,
    showExpandableRowsOnMobile: true,
    alternateTableRowColors: true,
    inFlight: isFetching,
    tableBodyHeight: '100%',
    serverSide: true,
    smallPadding: isMobile,
    onColumnSortChange,
    sortOrder: {
      name: sortBy,
      direction: sortOrder,
    },
    renderExpandableRow: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => {
      return getExpandableRow(orderSummaries, rowData, rowMeta, columns, classes!);
    },
  };

  return (
    <>
      {redirect && <Redirect to="/orders" />}
      <DashboardTable
        title="Recent Leads"
        data={orderSummaries as OrderSummary[]}
        columns={columns}
        onViewAllClick={onViewAllClick}
        options={options}
      />
    </>
  );
};

export default DashboardLeads;
