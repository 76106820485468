import { Address } from '@elromcoinc/react-shared';

import { DESTINATION_ADDRESS_TITLE, DROP_OFF_ADDRESS, START_ADDRESS_TITLE } from 'admin/constants';
import { LocationAccessType, LocationAccessTypeName } from 'admin/constants/LocationAccessType';

const WAYPOINT_ID = 'id';
const LABOR_TYPE = 'laborType';
const STREET = 'street1';
const ADDRESS = 'address';
const APARTMENT = 'street2';
const ENTRANCE_TYPE = 'elevationId';
const PARKING_DISTANCE = 'parkingToEntranceSpeedReductionId';
const PARKING_TYPE = 'parkingType';
const CITY = 'city';
const STATE = 'state';
const POSTAL_CODE = 'postalCode';
const REQUIRES_COI = 'requiresCoi';
const ACCESS_RESTRICTION_APPLIES = 'accessRestrictionApplies';
const ACCESS_RESTRICTION_INFO = 'accessRestrictionInfo';
const LATITUDE = 'lat';
const LONGITUDE = 'lng';
const COI_RECIPIENT = 'coiRecipient';
const USE_CUSTOMER_FOR_COI = 'useCustomerForCoi';
const COI_SENTS = 'coiSents';
const LOCATION_ACCESS_TYPE = 'locationAccessType';
const ELEVATOR = LocationAccessType.ELEVATOR;
const LOADING_DOCK = LocationAccessType.LOADING_DOCK;
const OTHER = LocationAccessType.OTHER;
const START_TIME = 'startTime';
const END_TIME = 'endTime';
const COMMENT = 'comment';
const INSTRUCTIONS = 'instructions';
const LAST_NAME = 'lastName';
const FIRST_NAME = 'firstName';
const EMAIL = 'email';
const PHONE_NUMBER = 'phoneNumber';
const PROPERTY_TYPE = 'propertyType';

type BasicAddressType = keyof Pick<Address, 'city' | 'state' | 'postalCode' | 'street1'>;

const addressLabels = {
  [CITY]: 'City',
  [STATE]: 'State',
  [POSTAL_CODE]: 'ZIP',
};

const addressFieldNames = {
  zipCode: POSTAL_CODE,
  city: CITY,
  state: STATE,
};

const propertyTypePath = `${PROPERTY_TYPE}.name`;
const fullAddressPath = ADDRESS;
const startEndTitles = [DESTINATION_ADDRESS_TITLE, DROP_OFF_ADDRESS, START_ADDRESS_TITLE];
const streetAddressPath = `${ADDRESS}.${STREET}`;
const apartmentAddressPath = `${ADDRESS}.${APARTMENT}`;

const labels = {
  [LABOR_TYPE]: 'Stop Type',
  [ADDRESS]: {
    [CITY]: 'City',
    [STATE]: 'State',
    [POSTAL_CODE]: 'ZIP',
    [APARTMENT]: 'Apt #',
    [STREET]: 'Address',
  },
  [ENTRANCE_TYPE]: 'Entrance Type',
  [PARKING_DISTANCE]: 'Parking Distance',
  [PARKING_TYPE]: 'Parking Type',
  [REQUIRES_COI]: 'Requires certificate of insurance',
  [ACCESS_RESTRICTION_APPLIES]: 'Location has a time window restriction',
  [ACCESS_RESTRICTION_INFO]: {
    [LOCATION_ACCESS_TYPE]: LocationAccessTypeName,
    [START_TIME]: 'Start time',
    [END_TIME]: 'End Time',
    [COMMENT]: 'Comment',
  },
  [COI_RECIPIENT]: {
    [INSTRUCTIONS]: 'Instructions',
    [LAST_NAME]: 'Last Name',
    [FIRST_NAME]: 'First Name',
    [EMAIL]: 'Email Address',
    [PHONE_NUMBER]: 'Primary Phone',
  },
  [USE_CUSTOMER_FOR_COI]: 'Send to Customer',
  [PROPERTY_TYPE]: 'Property Type',
};

export {
  labels,
  WAYPOINT_ID,
  LABOR_TYPE,
  ADDRESS,
  STREET,
  APARTMENT,
  LATITUDE,
  LONGITUDE,
  ENTRANCE_TYPE,
  PARKING_DISTANCE,
  PARKING_TYPE,
  CITY,
  STATE,
  POSTAL_CODE,
  REQUIRES_COI,
  ACCESS_RESTRICTION_APPLIES,
  ACCESS_RESTRICTION_INFO,
  COI_RECIPIENT,
  USE_CUSTOMER_FOR_COI,
  COI_SENTS,
  LOCATION_ACCESS_TYPE,
  OTHER,
  LOADING_DOCK,
  ELEVATOR,
  START_TIME,
  END_TIME,
  COMMENT,
  INSTRUCTIONS,
  LAST_NAME,
  FIRST_NAME,
  EMAIL,
  PHONE_NUMBER,
  addressLabels,
  addressFieldNames,
  fullAddressPath,
  startEndTitles,
  streetAddressPath,
  apartmentAddressPath,
  PROPERTY_TYPE,
  propertyTypePath,
};

export type { BasicAddressType };
