import axiosRetry from 'axios-retry';

const axiosRetryConfig = (axios: Parameters<typeof axiosRetry>[0]) => {
  axiosRetry(axios, {
    retries: 3,
    retryDelay: (retryCount) => {
      return (retryCount * 2 - 1) * 1000; // 1s...3s...5s
    },
    retryCondition: (error) => {
      return error.response?.status === 423;
    },
  });
};

export { axiosRetryConfig };
