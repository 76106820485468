import React, { useState } from 'react';

import { Fee, Link, Order } from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';
import { List } from 'immutable';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import AdditionalFeesModal from 'admin/components/OrderWindow/modals/AdditionalFees';

enum Modals {
  ADDITIONAL_FEES = 'ADDITIONAL_FEES',
}

export const AdditionalFees = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const { isClosing } = useOrderClosingContext();
  const name = getServicePropertyName('fees');
  const fees = order.getIn(name.split('.')) as List<Fee>;

  const handleAdditionalFeesChange = (value: List<Fee>) => {
    onChange({ name, value });
    setOpenModal(null);
  };
  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <HighlightedTableRow data-testId="additionalFees">
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.ADDITIONAL_FEES)}>
          Other Fees
        </Link>
        {Modals.ADDITIONAL_FEES === openModal && (
          <AdditionalFeesModal fees={fees} onSave={handleAdditionalFeesChange} onCancel={toggleOpenModal(null)} open />
        )}
      </TableCell>
      <TableCell>
        <Text
          value={(isClosing
            ? order.closingOrderDetail?.getFeesTotal(serviceIndex, isSelectedAllServices)
            : order.getFeesTotal(serviceIndex, isSelectedAllServices)
          ).asHumanReadableString()}
          data-testId={'additionalFeesValue'}
        />
      </TableCell>
    </HighlightedTableRow>
  );
};
