import React, { useEffect } from 'react';

import { Badge } from '@elromcoinc/react-shared';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { fetchUncompletedTasks, getUncompletedCount } from 'admin/autodux/TaskAutodux';

export const TasksIcon = () => {
  const dispatch = useDispatch();
  const count = (useSelector(getUncompletedCount) as number) || null;
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);
  const isLogout: boolean = useSelector(getIsLogout);

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setTimeout(() => {
        dispatch(fetchUncompletedTasks());
      }, 1000);

      const loadUncompletedTasksInterval = setInterval(() => {
        dispatch(fetchUncompletedTasks());
      }, 30e3);

      return () => clearInterval(loadUncompletedTasksInterval);
    }
  }, [isSessionExpired, isLogout]);

  return (
    <Badge badgeContent={count}>
      <CheckBoxIcon fontSize="inherit" color="inherit" />
    </Badge>
  );
};
