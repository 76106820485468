enum EventName {
  WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_DATE = 'WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_DATE',
  WHEN_A_EMPLOYEE_ADDS_A_DISCOUNT = 'WHEN_A_EMPLOYEE_ADDS_A_DISCOUNT',
  WHEN_A_EMPLOYEE_ADDS_VALUATION = 'WHEN_A_EMPLOYEE_ADDS_VALUATION',
  WHEN_A_EMPLOYEE_CHANGES_THE_LABOR_RATE = 'WHEN_A_EMPLOYEE_CHANGES_THE_LABOR_RATE',
  WHEN_A_EMPLOYEE_CHANGES_THE_TRAVEL_TIME_RATE = 'WHEN_A_EMPLOYEE_CHANGES_THE_TRAVEL_TIME_RATE',
  WHEN_A_EMPLOYEE_CHANGES_THE_MINIMUM_HOURS = 'WHEN_A_EMPLOYEE_CHANGES_THE_MINIMUM_HOURS',
  WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_SIZE = 'WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_SIZE',
  WHEN_A_EMPLOYEE_UPDATES_ADDRESS = 'WHEN_A_EMPLOYEE_UPDATES_ADDRESS',
  WHEN_A_EMPLOYEE_ADDS_AN_ADDITIONAL_STOP = 'WHEN_A_EMPLOYEE_ADDS_AN_ADDITIONAL_STOP',
  WHEN_A_EMPLOYEE_DELETES_AN_ADDRESS = 'WHEN_A_EMPLOYEE_DELETES_AN_ADDRESS',
  WHEN_A_EMPLOYEE_ADDS_ADDITIONAL_DAY_MULTIDAY_MOVE = 'WHEN_A_EMPLOYEE_ADDS_ADDITIONAL_DAY_MULTIDAY_MOVE',
  WHEN_AN_ORDER_IS_UPDATED = 'WHEN_AN_ORDER_IS_UPDATED',
  WHEN_AN_ORDER_CHANGES_CONTACT_INFORMATION = 'WHEN_AN_ORDER_CHANGES_CONTACT_INFORMATION',
  WHEN_A_EMPLOYEE_EDITS_CUSTOMER_INFORMATION = 'WHEN_A_EMPLOYEE_EDITS_CUSTOMER_INFORMATION',
  WHEN_A_EMPLOYEE_ADDS_AN_ORDER_SECONDARY_ID = 'WHEN_A_EMPLOYEE_ADDS_AN_ORDER_SECONDARY_ID',
  WHEN_A_EMPLOYEE_CHANGES_THE_DEPOSIT_AMOUNT = 'WHEN_A_EMPLOYEE_CHANGES_THE_DEPOSIT_AMOUNT',
}

export default EventName;
