import React, { useCallback, useEffect, useState } from 'react';

import {
  Activity,
  ActivitySourceDescriptor,
  ActivitySourceType,
  BodyBigText,
  Button,
  IActivityDto,
  Modal,
  Order,
  PaymentActivityDto,
  PaymentPermissions,
  formatCurrency,
  useAuthorize,
  useDebounce,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, LinearProgress, Paper, Tooltip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import produce from 'immer';
import { useSnackbar } from 'notistack';

import orderAPI from 'admin/api/OrderAPI';
import paymentActionsApi, { CustomerPaymentProfileDto, PaymentSearchTerms } from 'admin/api/PaymentActionsApi';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  usePaymentClientKey,
} from 'admin/components/OrderWindow/context';
import authorizeConfig from 'admin/config/Authorize';
import { PaymentStatus } from 'admin/dtos/PaymentStatus';

import { CardsOnFileModal } from './CardsOnFileModal';
import { ChargeDepositModal } from './CreateCharge';
import { EditPayment } from './EditPayment';
import { PaymentSourceProvider } from './PaymentsSourceContext';
import PaymentsTable from './PaymentsTable';
import { RefundPayment } from './Refund';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '118px',
    position: 'relative',
  },
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      flexDirection: 'row',
    },
    '& > button': {
      marginRight: 8,
      marginBottom: 16,
      height: 38,
      fontSize: 14,
      lineHeight: '14px',
    },
  },
  total: {
    zIndex: 0,
    position: 'absolute',
    bottom: '60px',
    width: '140px',
    padding: '8px 16px',
    right: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0,
    height: '50px',
    alignItems: 'flex-end',
  },
  toolTip: {
    overflow: 'visible',
  },
}));

interface PaymentsProps {
  open: boolean;
  changeStatusOnDeposit: boolean;
  sourceId: number;
  amount: number | string;
  activitySource: ActivitySourceType;
  activitySources: ActivitySourceDescriptor[];
  customerId: number;
  orderNumber: string;
  email: string;
  phoneNumber: string;
  cardHolder: string;
  reservationAmountNeeded: number;
  onClose(): void;
  disabled?: boolean;
  allowToMakePaymentAvailableOnBOL?: boolean;
}

enum Modals {
  EDIT,
  REFUND,
  CARDS_ON_FILE,
  DEPOSIT,
}

const bolActivitySourceTypes = [ActivitySourceType.ORDER_BILL_OF_LADING, ActivitySourceType.SERVICE_BILL_OF_LADING];

interface PaymentsState {
  payments: PaymentActivityDto[];
  total: number;
}

const getTotal = (payments: PaymentActivityDto[]): number =>
  payments
    .filter((it) => it.status !== PaymentStatus.PENDING && it.status !== PaymentStatus.VOIDED)
    .reduce((sum, payment) => sum + payment.settledAmount, 0);

const toolTipCardsFile = 'Unable to process cards on file. Please connect your authorize.net account.';
const Payments: React.FC<PaymentsProps> = ({
  open,
  sourceId,
  activitySource,
  activitySources,
  onClose,
  amount,
  changeStatusOnDeposit,
  email,
  customerId,
  orderNumber,
  phoneNumber,
  cardHolder,
  reservationAmountNeeded,
  disabled = false,
  allowToMakePaymentAvailableOnBOL = false,
}) => {
  const classes = useStyles();
  useAuthorize(authorizeConfig.authorizeAcceptUrl);
  const { enqueueSnackbar } = useSnackbar();
  const publicClientKey = usePaymentClientKey();
  const { order } = useOrderState() || {};
  const { isSelectedAllServices, serviceIndex } = useOrderServiceIndex() || {};
  const { closingTitle = '', isClosing = false } = useOrderClosingContext() || {};
  const onlinePaymentAvailable = publicClientKey?.clientKey && publicClientKey?.apiLoginId;
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [inFlight, setInFlight] = useState(true);
  const [hasAssignedTruck, setHasAssignedTruck] = useState(activitySource !== ActivitySourceType.ORDER);
  const [state, setState] = useState<PaymentsState>({ payments: [], total: 0 });
  const canAddCardOnFile = useHasPermission(PaymentPermissions.PERM_CAN_ADD_A_CARD_ON_FILE);
  const canAddCustomPayment = useHasPermission(PaymentPermissions.PERM_CAN_ADD_A_CUSTOM_PAYMENT);
  const canAddDeposit = useHasPermission(PaymentPermissions.PERM_CAN_CHARGE_A_RESERVATION_PAYMENT);
  const canChargeOnlinePayment = useHasPermission(PaymentPermissions.PERM_CAN_CHARGE_AN_ONLINE_PAYMENT);
  const canProcessRefund = useHasPermission(PaymentPermissions.PERM_CAN_PROCESS_A_REFUND);
  const [customerPaymentProfile, setCustomerPaymentProfile] = useState<CustomerPaymentProfileDto | null>(null);
  const isMultiDay = order?.services.size! > 1;

  const bolActivitySources: Activity[] =
    isClosing && order?.orderBillOfLadingDetail
      ? order
          ?.getBOLAndClosingPaymentActivitySources(serviceIndex)
          .filter((s) => bolActivitySourceTypes.includes(s.activitySource))
      : [];

  const refreshPaymentMethods = useCallback(() => {
    paymentActionsApi
      .getCustomerPaymentProfile(customerId)
      .then((res) => {
        setCustomerPaymentProfile(res);
      })
      .catch(() => {
        enqueueSnackbar('There was an error loading payment methods.', { variant: 'error' });
      });
  }, [customerId]);

  useEffect(() => {
    refreshPaymentMethods();
  }, []);

  const debouncedInFlight: boolean = useDebounce(inFlight, 300);

  useEffect(() => {
    if (activitySource === ActivitySourceType.ORDER) {
      orderAPI
        .getOrder(sourceId)
        .then(Order.createOrder)
        .then((order) => {
          const truckAssignments = order.services.every((s) => !s?.truckAssignments?.isEmpty());

          setHasAssignedTruck(truckAssignments);
        });
    }
  }, [activitySource, sourceId]);

  const fetchPayments = useCallback(() => {
    setInFlight(true);

    let query: PaymentSearchTerms = {
      'activitySources.activitySource': activitySource,
      'activitySources.sourceId': sourceId,
    };

    if (activitySource === ActivitySourceType.ORDER) {
      query = {
        'order.id': sourceId,
      };
    } else if (activitySource === ActivitySourceType.CUSTOMER_ACCOUNT) {
      query = {
        'account.id': sourceId,
      };
    }

    paymentActionsApi
      .searchPayments(query)
      .then((res) => {
        let payments = res.pageElements;

        if (activitySource === ActivitySourceType.ORDER) {
          const serviceQuote = order?.getServiceQuote(serviceIndex)!;
          const serviceId = serviceQuote.originalId;

          if (isClosing) {
            payments =
              isSelectedAllServices || !isMultiDay
                ? payments
                : payments.filter((p) =>
                    p.activitySources.find(
                      (as) => as.activitySource === ActivitySourceType.SERVICE && as.referencedEntityId === serviceId,
                    ),
                  );
          } else {
            const salesPayments = payments.filter(
              (it) => !it.activitySources.find((as) => as.activitySource === ActivitySourceType.JOB),
            );

            payments =
              isSelectedAllServices || !isMultiDay
                ? salesPayments
                : salesPayments.filter((p) =>
                    p.activitySources.find(
                      (as) => as.activitySource === ActivitySourceType.SERVICE && as.referencedEntityId === serviceId,
                    ),
                  );
          }
        }

        setState({ payments, total: getTotal(payments) });
      })
      .catch(() => {
        enqueueSnackbar('There was an error fetching payments information.', { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }, [activitySource, sourceId]);

  useEffect(() => {
    fetchPayments();
  }, [fetchPayments]);

  const handleModalClose = () => {
    setSelectedRow(-1);
    setOpenModal(null);
  };

  const handleModalAndRefreshClose = () => {
    fetchPayments();
    refreshPaymentMethods();
    setOpenModal(null);
    setSelectedRow(-1);
  };

  const handleButtonClick = (modal: Modals) => () => {
    setOpenModal(modal);
  };

  const handleTogglePending = (paymentId: number) => () => {
    const payment = produce(state.payments.filter((it) => it.id === paymentId)[0], (draft) => {
      draft.status = draft.status === PaymentStatus.PENDING ? PaymentStatus.PROCESSED : PaymentStatus.PENDING;
    });

    if (payment.id) {
      paymentActionsApi
        .updateStatus(payment.id, payment.status)
        .then(() => {
          fetchPayments();
        })
        .catch(() => {
          setInFlight(false);
        });
    }
  };

  const handleToggleBOL = (paymentId: number) => () => {
    const payment = produce(state.payments.filter((it) => it.id === paymentId)[0], (draft) => {
      draft.activitySources = draft.activitySources.some(
        (as) => as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING,
      )
        ? draft.activitySources.filter((as) => bolActivitySourceTypes.includes(as.activitySource))
        : [...draft.activitySources, ...(bolActivitySources as IActivityDto['activitySources'])];
    });

    if (payment.id) {
      paymentActionsApi
        .setBOLActivitySourcesOnPayment(payment.id, bolActivitySources as IActivityDto['activitySources'])
        .then(() => {
          fetchPayments();
        })
        .catch(() => {
          setInFlight(false);
        });
    }
  };

  const handleRowClick = (rowIdx: number) => {
    if (rowIdx === selectedRow) {
      setOpenModal(Modals.EDIT);
    } else {
      setSelectedRow(rowIdx);
    }
  };

  const getPaymentForRefund = () => {
    if (
      PaymentStatus.PENDING === state.payments[selectedRow]?.status ||
      PaymentStatus.PROCESSED === state.payments[selectedRow]?.status
    ) {
      return state.payments[selectedRow];
    }
    return undefined;
  };

  let ifDepositUnavailableHint = reservationAmountNeeded
    ? 'You must select a truck for this order and save your changes, then proceed to process the payment.'
    : 'Deposit already paid or you need to enter reservation amount and save your changes, then proceed to process the payment.';

  if (isClosing) {
    ifDepositUnavailableHint = '';
  }

  function renderModals() {
    switch (openModal) {
      case Modals.CARDS_ON_FILE:
        return (
          <CardsOnFileModal
            email={email}
            phoneNumber={phoneNumber}
            customerPaymentProfile={customerPaymentProfile}
            onClose={handleModalAndRefreshClose}
            customerId={customerId}
            refreshPaymentMethods={refreshPaymentMethods}
          />
        );
      case Modals.EDIT:
        return (
          <EditPayment
            payment={state.payments[selectedRow]}
            onSave={handleModalAndRefreshClose}
            onCancel={handleModalClose}
            open
          />
        );
      case Modals.REFUND:
        return (
          <RefundPayment
            payment={state.payments[selectedRow]}
            onSave={handleModalAndRefreshClose}
            onCancel={handleModalClose}
            open
          />
        );
      case Modals.DEPOSIT:
        return (
          <ChargeDepositModal
            amount={amount}
            isDepositAvailable={hasAssignedTruck && !isClosing}
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            customerPaymentProfile={customerPaymentProfile}
            customerId={customerId}
            orderNumber={orderNumber}
            onSave={handleModalAndRefreshClose}
            changeStatusOnDeposit={changeStatusOnDeposit}
            onCancel={handleModalClose}
            cardHolder={cardHolder}
            reservationAmountNeeded={reservationAmountNeeded}
            allowToMakePaymentAvailableOnBOL={allowToMakePaymentAvailableOnBOL}
            bolActivitySources={bolActivitySources}
          />
        );
      default:
        return null;
    }
  }

  return (
    <PaymentSourceProvider sourceId={sourceId} activitySource={activitySource} activitySources={activitySources}>
      <Modal open={open} title={`Payments${closingTitle}`} onClose={onClose} scroll="paper" maxWidth="md">
        <Box display="flex" flexDirection="column" className={classes.root}>
          <Box className={classes.buttonGroup}>
            <Box className={classes.buttonGroup}>
              <Tooltip
                className={classes.toolTip}
                title={!onlinePaymentAvailable ? toolTipCardsFile : ''}
                placement="bottom-start"
              >
                <Button
                  disabled={debouncedInFlight || !onlinePaymentAvailable || !canAddCardOnFile}
                  rounded
                  color="primary"
                  onClick={handleButtonClick(Modals.CARDS_ON_FILE)}
                >
                  Cards on File
                </Button>
              </Tooltip>
              <Button
                disabled={
                  !(canAddDeposit || canChargeOnlinePayment || canAddCustomPayment || !debouncedInFlight) || disabled
                }
                rounded
                color="primary"
                onClick={handleButtonClick(Modals.DEPOSIT)}
              >
                Create Charge
              </Button>
            </Box>
            <Button
              disabled={!canProcessRefund || debouncedInFlight || selectedRow === -1 || !getPaymentForRefund()}
              onClick={handleButtonClick(Modals.REFUND)}
              rounded
              color="primary"
              variant="outlined"
            >
              Refund
            </Button>
          </Box>
          {debouncedInFlight && <LinearProgress />}
          <PaymentsTable
            selectedRow={selectedRow}
            onRowClick={handleRowClick}
            data={state.payments}
            togglePending={handleTogglePending}
            isClosing={isClosing}
            allowToMakePaymentAvailableOnBOL={allowToMakePaymentAvailableOnBOL}
            toggleShowOnBOL={handleToggleBOL}
          />
          <Paper square className={clsx(classes.total, 'applyBottomRadius')}>
            <BodyBigText>
              <b>Total:</b>
            </BodyBigText>
            <BodyBigText>{`$${formatCurrency(state.total)}`}</BodyBigText>
          </Paper>
        </Box>
        {renderModals()}
      </Modal>
    </PaymentSourceProvider>
  );
};

export { Payments };
