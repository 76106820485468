enum InvoiceType {
  TOTAL_INVOICES = 'TOTAL_INVOICES',
  PAID_INVOICES = 'PAID_INVOICES',
  UNPAID_INVOICES = 'UNPAID_INVOICES',
}

type InvoiceTypes = keyof typeof InvoiceType;

type InvoiceTableType = {
  readonly [key in InvoiceTypes]: string;
};

enum InvoicesTabsName {
  TOTAL_INVOICES = 'Total Invoices',
  PAID_INVOICES = 'Paid Invoices',
  UNPAID_INVOICES = 'Unpaid Invoices',
}

export type { InvoiceTableType };
export { InvoicesTabsName, InvoiceType };
