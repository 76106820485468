import React, { ChangeEvent, FC } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  BodyText,
  InvoiceItemDto,
  InvoiceType,
  InvoicesStatusType,
  PriceAdjustmentType,
  Switch,
  asHumanReadableDiscount,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, Divider, Grid, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import { AdjustmentSubtotalItem } from 'admin/components/OrderWindow/modals/Invoices/EditInvoice/AdjustmentSubtotalItem';
import {
  AMOUNT,
  DISCOUNT,
  ITEMS,
  InvoicesItemLabels,
  PAID_AMOUNT,
  STATUS,
  TAX,
  TYPE,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';

const useStyles = makeStyles<Theme>(({ spacing }) =>
  createStyles({
    totalInfo: {
      position: 'relative',
      maxWidth: spacing(45),
      padding: spacing(2.5, 1, 1.5),
      bottom: spacing(1),
      zIndex: 0,
    },
    taxItem: {
      maxWidth: spacing(15),
    },
    flexItem: {
      display: 'flex',
      alignItems: 'center',
      minHeight: '100%',
    },
  }),
);

interface SubtotalBlockProps {
  isReadOnly: boolean;
  isCanada: boolean;
}

const SubtotalBlock: FC<SubtotalBlockProps> = ({ isReadOnly, isCanada }) => {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();
  const items = watch(ITEMS) as InvoiceItemDto[];
  const paidAmount = watch(PAID_AMOUNT) || 0;
  const discountAmount = watch(`${DISCOUNT}.${AMOUNT}`) || 0;
  const discountType = watch(`${DISCOUNT}.${TYPE}`);
  const taxType = watch(`${TAX}.${TYPE}`);
  const taxAmount = watch(`${TAX}.${AMOUNT}`) || 0;
  const type = watch(TYPE) as InvoiceType;
  const status = watch(STATUS) as InvoicesStatusType;
  const subtotal = items.reduce(
    (acc, item) => acc + ((item.total.manual !== null ? +item.total.manual : +item.total.calculated!) || 0),
    0,
  );
  const activitySources = (watch('activitySources') ?? []) as ActivitySourceDescriptor[];
  const isInvoiceForOrder = activitySources.some((source) => source.activitySource === ActivitySourceType.ORDER);

  const discountValue =
    discountType === PriceAdjustmentType.PERCENT ? (subtotal / 100) * +discountAmount : +discountAmount;

  const taxValue =
    taxType === PriceAdjustmentType.PERCENT ? ((subtotal - discountValue) / 100) * +taxAmount : +taxAmount;

  const handleChangeInvoiceType = (event: ChangeEvent<HTMLInputElement>) => {
    let type = InvoiceType.CUSTOM;

    if (event.target.checked) {
      const isInvoiceForService = activitySources.some(
        (source) => source.activitySource === ActivitySourceType.SERVICE,
      );
      const isInvoiceForClosing = activitySources.some(
        (source) => source.activitySource === ActivitySourceType.ORDER_CLOSING,
      );
      if (isInvoiceForClosing) {
        type = isInvoiceForService ? InvoiceType.ORDER_CLOSING : InvoiceType.ORDER_CLOSING_ALL_SERVICES;
      } else {
        type = isInvoiceForService ? InvoiceType.ORDER_SALES : InvoiceType.ORDER_SALES_ALL_SERVICES;
      }
    }

    setValue(TYPE, type);
  };

  return (
    <>
      <Paper elevation={2} classes={{ root: classes.totalInfo }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BodyText>Subtotal</BodyText>
          </Grid>
          <Grid item xs={6}>
            <BodyText align="right">{`$${formatCurrency(subtotal)}`}</BodyText>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.flexItem}>
              <BodyText>{InvoicesItemLabels[DISCOUNT]}</BodyText>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {!isReadOnly && <AdjustmentSubtotalItem name={DISCOUNT} />}
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.flexItem} justifyContent="flex-end">
              <BodyText>{asHumanReadableDiscount(discountValue)}</BodyText>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={classes.flexItem}>
              <BodyText>{InvoicesItemLabels[TAX]}</BodyText>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {!isReadOnly && <AdjustmentSubtotalItem name={TAX} />}
          </Grid>
          <Grid item xs={4}>
            <Box className={classes.flexItem} justifyContent="flex-end">
              <BodyText>{`$ ${formatCurrency(taxValue)}`}</BodyText>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <BodyText>
              <b>Total</b>
            </BodyText>
          </Grid>
          <Grid item xs={6}>
            <BodyText align="right">
              {/*  Discount must be applied to invoice here */}
              <b>{`$ ${formatCurrency(subtotal - discountValue + taxValue)}`}</b>
            </BodyText>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* TODO need to create an another ticket with a description of the functionality */}
          {/*<Grid item xs={5}>*/}
          {/*  <Link>Request Deposit</Link>*/}
          {/*</Grid>*/}
          {/*<Grid item xs={7}>*/}
          {/*  /!* Maybe here we can make custom payment for invoice? *!/*/}
          {/*  <Link>Enter Payment</Link>*/}
          {/*</Grid>*/}
          <Grid item xs={5}>
            <BodyText>Paid</BodyText>
          </Grid>
          <Grid item xs={7}>
            <BodyText align="right">{`$${formatCurrency(paidAmount)}`}</BodyText>
          </Grid>
          {isInvoiceForOrder && status !== InvoicesStatusType.PAID && (
            <Grid item xs={12}>
              <Box mt={-1} ml={-2}>
                <Switch
                  checked={type !== InvoiceType.CUSTOM}
                  onChange={handleChangeInvoiceType} // @ts-ignore
                  skipControl
                  color="primary"
                  label="Include Order Payments"
                  labelPlacement="start"
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={5}>
            <BodyText>
              <b>{`Balance Due (${isCanada ? 'CAD' : 'USD'})`}</b>
            </BodyText>
          </Grid>
          <Grid item xs={7}>
            <BodyText align="right">
              <b>{`$ ${formatCurrency(subtotal - paidAmount - discountValue + taxValue)}`}</b>
            </BodyText>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export { SubtotalBlock };
