import React from 'react';

import { HeaderBigText } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconBlueFab } from './Widgets';

const Panel = styled(Box)`
  && {
    box-sizing: border-box;
    display: flex;
    position: relative;
    min-width: ${(props) => props.minWidth};
    min-height: ${(props) => props.minHeight};
    ${({ width = null }) => (width ? `flex-basis: ${width};` : '')};
    flex-grow: 1;
    flex-shrink: 0;
  }
  &.use-input-fixes {
    .el-text_input input {
      font-weight: bold;
    }
    .el-text_input > div > div {
      padding: 2.5px 8px;
      font-weight: bold;
    }
    .el-select_input > div > div {
      white-space: normal;
    }
    .el-text_input.fix-input-margin {
      margin-left: ${({ theme }) => theme.spacing(-0.5)}px;
    }
  }
`;

const Spacer = styled.div`
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  position: relative;
  border-radius: 3px;
  padding: 8px;
  flex-grow: 1;
  flex-shrink: 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
`;

const TitleBar = styled(Box)`
  && {
    display: flex;
    align-items: center;
    svg {
      color: ${(props) => props.theme.palette.primary.main};
      width: 34px;
      height: 34px;
      margin-right: 8px;
    }
  }
`;

const Action = styled(Box)`
  && {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export default function Card({
  title,
  icon,
  onAction,
  mode,
  children,
  minWidth,
  width,
  minHeight,
  disabled,
  dataTestId,
  p,
  pt,
  className,
}) {
  return (
    <Panel p={p} pt={pt} minWidth={minWidth} minHeight={minHeight} width={width} className={className}>
      <Spacer className="applyRadius">
        {(title || icon) && (
          <TitleBar>
            {icon && icon}
            {title && (
              <HeaderBigText>
                <b>{title}</b>
              </HeaderBigText>
            )}
          </TitleBar>
        )}
        <>{children}</>
        {mode && (
          <Action className="print-remove">
            <IconBlueFab dataTestId={dataTestId} mode={mode} onClick={onAction} disabled={disabled} size="small" />
          </Action>
        )}
      </Spacer>
    </Panel>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.node,
  onAction: PropTypes.func,
  mode: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
  minWidth: PropTypes.string,
  minHeight: PropTypes.string,
  disabled: PropTypes.bool,
  p: PropTypes.number,
  pt: PropTypes.number,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  icon: null,
  onAction: null,
  mode: null,
  width: null,
  minWidth: '280px',
  minHeight: 'unset',
  disabled: false,
  p: 0.5,
  pt: 0.5,
  dataTestId: null,
  className: null,
};
