import React from 'react';

import { Badge, IconButton } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, Tooltip } from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';

import { ClassNameMap } from 'admin/components/Reports/config';
import { statusById } from 'admin/constants/OrderStatus';
import { renderDate } from 'admin/utils/renderDate';
import { CommunicationActivities } from 'common-types';
import Circle from 'common/components/Circle';
import Status from 'common/components/Status';

const makeColumns = (
  rowsData: CommunicationActivities[],
  {
    canSeeCustomerOnline,
    theme,
    classes,
    setSelectedMessageOrder,
  }: { canSeeCustomerOnline: boolean; theme: Theme; classes: ClassNameMap; setSelectedMessageOrder: any },
) => {
  return [
    {
      name: 'orderStatus',
      label: 'STATUS',
      options: {
        customBodyRender: (_: CommunicationActivities[], { rowIndex }: { rowIndex: number }) => {
          const { orderStatus, customerActive } = rowsData[rowIndex];
          const orderInfo = statusById(orderStatus);

          return (
            <Grid container alignItems="center" classes={{ root: classes.activityContainer }}>
              {canSeeCustomerOnline && (
                <Circle
                  className="circle"
                  color={customerActive ? theme.palette.success.main : theme.palette.grey.A100}
                  width="12px"
                  height="12px"
                />
              )}
              <Status label={orderInfo.label!} title={orderStatus!} size="small" />
            </Grid>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'orderNumber',
      label: 'ORDER #',
      options: {
        customBodyRender: (_: CommunicationActivities[], { rowIndex }: { rowIndex: number }) => {
          const { orderNumber } = rowsData[rowIndex];
          return <>{orderNumber}</>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'employeeUnseenMessagesTotal',
      label: 'TYPE',
      options: {
        customBodyRender: (_: CommunicationActivities[], { rowIndex }: { rowIndex: number }) => {
          const row = rowsData[rowIndex];
          const { employeeUnseenMessagesTotal } = row;
          const isUnreadMessage = employeeUnseenMessagesTotal > 0;
          const tooltipTitle = isUnreadMessage ? 'Read Message' : 'Send message';

          const handleSendMessages = (event: React.ChangeEvent<{}>) => {
            event.stopPropagation();
            setSelectedMessageOrder(row);
          };

          return (
            <Tooltip title={tooltipTitle}>
              <Box>
                <IconButton color="primary" onClick={handleSendMessages}>
                  <Badge badgeContent={employeeUnseenMessagesTotal} classes={{ badge: classes.badge }}>
                    {isUnreadMessage ? (
                      <SmsIcon className={classes.smsIcon} color="primary" />
                    ) : (
                      <SmsIcon className={classes.smsIcon} color="disabled" />
                    )}
                  </Badge>
                </IconButton>
              </Box>
            </Tooltip>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'customerName',
      label: 'CUSTOMER',
      options: {
        customBodyRender: (_: CommunicationActivities[], { rowIndex }: { rowIndex: number }) => {
          const { customerName } = rowsData[rowIndex];
          return <Box className={classes.capitalize}>{customerName}</Box>;
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'moveDate',
      label: 'MOVE DATE',
      options: {
        customBodyRender: (_: CommunicationActivities[], { rowIndex }: { rowIndex: number }) => {
          const { moveDate } = rowsData[rowIndex];
          return renderDate(moveDate);
        },
        showOnMobileAsExpanded: true,
      },
    },
  ];
};

export { makeColumns };
