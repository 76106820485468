const maximumItemsInStatistics = 10;
const mainPeriodTitle = 0;
const firstPage = 1;

enum SelectedPage {
  NEXT_PAGE = 'NEXT_PAGE',
  PREVIOUS_PAGE = 'PREVIOUS_PAGE',
}

export { maximumItemsInStatistics, mainPeriodTitle, firstPage, SelectedPage };
