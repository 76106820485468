import React from 'react';

import {
  BodyText,
  DataTable,
  DataTableOptions,
  InvoiceDto,
  InvoicesStatusType,
  InvoicesStatusTypeName,
  UI_DATE_FORMAT_SHORT,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, useTheme } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { InvoicesTypes } from 'admin/components/Invoices';
import { useInvoiceStatusColors } from 'admin/hooks';
import Circle from 'common/components/Circle';

interface InvoicesTableTooltipProps {
  invoices: InvoiceDto[];
}

export const InvoicesTableTooltip = ({ invoices }: InvoicesTableTooltipProps) => {
  const theme = useTheme();
  const statusColor = useInvoiceStatusColors(theme);

  const invoicesTableOptions = {
    showExpandableRowsOnMobile: false,
    smallPadding: true,
    serverSide: false,
    selectableRowsOnClick: false,
    alternateTableRowColors: true,
    selectableRowsHideCheckboxesCustom: true,
    selectableRowsHideCheckboxes: false,
    showOnMobileAsExpanded: true,
    offHorizontalScrollFooter: true,
    disableClickAway: true,
    selectableRows: 'none',
    tableBodyHeight: '300px',
    rowHover: false,
    fixedHeader: true,
    pagination: false,
    sort: false,
  };

  const columns = [
    {
      name: 'id',
      label: 'INVOICE',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { number } = invoices[rowIndex];

          return <BodyText>{number}</BodyText>;
        },
      },
    },
    {
      name: 'status',
      label: 'STATUS',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { status } = invoices[rowIndex];

          return (
            <Box display="flex" alignItems="center">
              <Circle
                className="circle"
                color={`${statusColor[status as InvoicesStatusType]}`}
                width="15px"
                minWidth="15px"
                height="15px"
                mr={1}
              />
              <BodyText color="inherit">{InvoicesStatusTypeName[status as InvoicesStatusType]}</BodyText>
            </Box>
          );
        },
      },
    },
    {
      name: 'created',
      label: 'DATE CREATED',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { dateCreated } = invoices[rowIndex];

          return <BodyText>{format(parseISO(dateCreated as string), UI_DATE_FORMAT_SHORT)}</BodyText>;
        },
      },
    },
    {
      name: 'dueDate',
      label: 'DATE DUE',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { dueDate } = invoices[rowIndex];

          return <BodyText>{format(parseISO(dueDate as string), UI_DATE_FORMAT_SHORT)}</BodyText>;
        },
      },
    },
    {
      name: 'total',
      label: 'AMOUNT',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { total } = invoices[rowIndex];

          return <BodyText>{`$${formatCurrency(total)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
  ];

  return <DataTable data={invoices} columns={columns} options={invoicesTableOptions as DataTableOptions} />;
};
