const customDateName = 'Custom Date';
const periods = [
  [0, 'Today/Same Day Last Year'],
  [1, 'Yesterday/Same Day Last Year'],
  [2, 'This Week (Mon-Today)/Same Week Last Year'],
  [3, 'Last Week (Mon-Sun)/Same Week Last Year'],
  [4, 'This Month/Same Month Last Year'],
  [5, 'Last Month/Same Month Last Year'],
  [6, customDateName]
];

export { customDateName, periods };
