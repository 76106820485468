import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, Order, OverridableValue, TextInput } from '@elromcoinc/react-shared';
import { TableCell, TableRow } from '@material-ui/core';

import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { useOrderChangeSet, useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';

export const WeightFactorRow = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const [cuFtToPounds, setCuFtToPounds] = useState(order.cuFtToPounds);

  useEffect(() => {
    setCuFtToPounds(order.cuFtToPounds);
  }, [order.cuFtToPounds]);

  const handleChangeRatePerCuFtToPound = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setCuFtToPounds(parseInt(value, 10));
  };

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>Weight Factor</BodyText>
      </TableCell>
      <ValueTableCell>
        <FlexWithNegativeMargin>
          <TextInput
            value={cuFtToPounds}
            onChange={handleChangeRatePerCuFtToPound}
            name="cuFtToPounds"
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            onBlur={handleOnBlur(cuFtToPounds)}
            disabled={isClosing || isCompleted || isLockSales}
          />
        </FlexWithNegativeMargin>
      </ValueTableCell>
    </TableRow>
  );
};
