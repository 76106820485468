import { getValue } from 'admin/utils/getValue';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const truckCount: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('numberOfTrucks') === -1) {
    return null;
  }

  const oldValue = getValue(oldOrder.getValueIn(fieldPath))?.toString() ?? '';
  const newValue = getValue(newOrder.getValueIn(fieldPath))?.toString() ?? '';

  if (oldValue === newValue) {
    return null;
  }

  return [
    createUpdatedValueDto(oldValue, newValue, 'Trucks', extractServiceIndexUtil(fieldPath, newOrder.services.size > 1)),
  ];
};

export default truckCount;
