import styled from 'styled-components';

export const TruckRow = styled.div<{
  $selected?: boolean;
  $width?: number;
  heightSize?: number;
  dense?: boolean;
  grey?: boolean;
}>`
  border-bottom: 1px solid #d7d7da;
  ${({ grey = false }) => (grey ? 'background-color: #f5f5f6;' : '')}
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  ${({ $selected }) => ($selected ? 'border: 2px dashed;' : '')}
  ${({ $width }) => ($width ? `width: ${$width}px;` : '')}
  min-height: ${({ heightSize = 0, dense }) =>
    (Number.isFinite(heightSize) ? heightSize + 1 : 1) * (dense ? 24 : 56)}px;
  .truck-name {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
