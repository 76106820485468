import { FC, useRef } from 'react';

import { UpdateOrderContext } from './UpdateOrderContext';

const UpdateOrderContextProvider: FC = ({ children }) => {
  const applyOrderChangeSetRef = useRef(null);
  const saveOrderRef = useRef(null);

  return (
    <UpdateOrderContext.Provider value={{ applyOrderChangeSetRef, saveOrderRef }}>
      {children}
    </UpdateOrderContext.Provider>
  );
};

export { UpdateOrderContextProvider };
