import React, { ChangeEvent, FC, useState } from 'react';

import {
  BodyText,
  Checkbox,
  EstimateType,
  EstimateTypeName,
  GeneralService,
  Modal,
  MoveType,
  MoveTypeName,
  Order,
  Select,
  SettingNames,
  TextInput,
  useHoverTooltip,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, MenuItem, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TruckOutlineIcon from '@material-ui/icons/LocalShippingOutlined';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import {
  COMPANY_NAME,
  ESTIMATE_TYPE,
  ESTIMATE_TYPE_CAPTION,
  ESTIMATE_TYPE_NAME,
  GENERAL_SERVICE,
  MOVE_SIZE,
  MOVE_TYPE,
  ORDER_NUMBER,
  QUOTE,
  SECONDARY_ID,
  SERVICES,
  SERVICE_ID,
  SERVICE_TYPE_NAME,
  SIZE_DESCRIPTION,
  labels,
} from 'admin/components/OrderWindow/OrderWindowLabels';
import MoveSizeInfo from 'admin/components/OrderWindow/blocks/MoveSizeInfo';
import { useOrderClosingContext, useOrderServiceIndex } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import { getLabel } from 'admin/utils/getLabel';

const {
  GENERAL_SERVICES,
  ESTIMATE_TYPES,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
} = SettingNames;

const moveTypeKeys = [ACCEPT_RESIDENTIAL_ORDERS, ACCEPT_COMMERCIAL_ORDERS, ACCEPT_MILITARY_ORDERS];
const moveTypes = Object.entries(MoveTypeName);
const estimateTypesOptions: SelectOptions = Object.entries(EstimateTypeName);

interface OrderDetailsInfoProps {
  [ORDER_NUMBER]: string;
  [SECONDARY_ID]: string;
  [COMPANY_NAME]: string;
  [MOVE_SIZE]: number;
  [SIZE_DESCRIPTION]: string;
  [SERVICES]: {
    [QUOTE]: {
      [MOVE_TYPE]: MoveType;
      [ESTIMATE_TYPE]: EstimateType;
      [ESTIMATE_TYPE_CAPTION]: string;
      [GENERAL_SERVICE]: {
        [SERVICE_ID]: number | null;
      };
    };
  }[];
}

const schema = object().shape({
  [SECONDARY_ID]: string().nullable().label(labels[SECONDARY_ID]).max(32),
});

interface OrderDetailsProps {
  data: Order;
  onSave: (data: any) => void;
  onClose: () => void;
  open: boolean;
}

export const EditOrderDetailsModal: FC<OrderDetailsProps> = ({ data, onSave, onClose, open }) => {
  const { serviceIndex } = useOrderServiceIndex();
  const { enqueueSnackbar } = useSnackbar();
  const generalServicePath = `${SERVICES}.${serviceIndex}.${QUOTE}.${GENERAL_SERVICE}`;
  const serviceTypeIdPath = `${SERVICES}.${serviceIndex}.${QUOTE}.${GENERAL_SERVICE}.${SERVICE_ID}`;
  const estimateTypePath = `${SERVICES}.${serviceIndex}.${QUOTE}.${ESTIMATE_TYPE}`;
  const estimateTypeCaptionPath = `${SERVICES}.${serviceIndex}.${QUOTE}.${ESTIMATE_TYPE_CAPTION}`;
  const settings = useOrderWindowSettings();
  const serviceTypes = settings[GENERAL_SERVICES] || [];
  const serviceTypeOptions = serviceTypes.map((st) => [st.id, st.name]) as SelectOptions;
  const service = data.services.get(serviceIndex);
  const estimateType = service.quote.estimateType;
  const secondaryId = data.secondaryId;
  const [moveType] = useState(service.quote.moveType || moveTypes[0]);
  const [serviceTypeId] = useState(service.quote.generalService.id);
  const { closingTitle, isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const tooltip = useHoverTooltip();

  const {
    siteName,
    activeCuFt,
    activeWeight,
    setCurrentMoveSite,
    toggleCurrentRooms,
    siteOptions,
    roomOptions,
    getMoveSizeAndDescription,
    currentSite,
    currentRooms,
    iconsByType,
    sizeType,
  } = useOrderSizing(data.set('moveType', moveType));

  const disableInputs = isClosing || isCompleted || isLockSales;

  const [moveSize, sizeDescription] = getMoveSizeAndDescription();

  const handleMoveTypeChange = () => {
    enqueueSnackbar('Under construction', { variant: 'warning' });

    return;
    // setMoveType(event.target.value);
    // setServiceTypeId(null);
  };

  const handleMoveSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCurrentMoveSite(+event.target.value);
  };

  const formMethods = useForm<OrderDetailsInfoProps>({
    resolver: yupResolver(schema),
    defaultValues: data.toJS() as OrderDetailsInfoProps,
    mode: 'onChange',
  });
  const { control, handleSubmit, watch } = formMethods;

  const orderNumberValue = watch(ORDER_NUMBER);
  const secondaryIdValue = watch(SECONDARY_ID);
  const serviceTypeIdValue = watch(`${SERVICES}.${serviceIndex}.${QUOTE}.${GENERAL_SERVICE}.${SERVICE_ID}`);
  const estimateTypeValue = watch(`${SERVICES}.${serviceIndex}.${QUOTE}.${ESTIMATE_TYPE}`);
  const moveTypeValue = watch(`${SERVICES}.${serviceIndex}.${QUOTE}.${MOVE_TYPE}`);

  const handleOnSave = () => {
    const changes = [];
    if (moveTypeValue !== moveType) {
      changes.push([`${SERVICES}.${serviceIndex}.${QUOTE}.${MOVE_TYPE}`, moveType]);
    }
    if (estimateTypeValue !== estimateType) {
      changes.push([estimateTypePath, estimateTypeValue]);
      changes.push([estimateTypeCaptionPath, ESTIMATE_TYPES[estimateTypeValue]]);
    }
    if (sizeDescription !== data.sizeDescription) {
      changes.push([SIZE_DESCRIPTION, sizeDescription]);
      changes.push([MOVE_SIZE, moveSize]);
    }
    if (secondaryIdValue !== secondaryId) {
      changes.push([SECONDARY_ID, secondaryIdValue]);
    }
    if (serviceTypeIdValue !== serviceTypeId) {
      changes.push([
        generalServicePath,
        GeneralService.createGeneralService(serviceTypes.filter((it: any) => it.id === serviceTypeIdValue)[0]),
      ]);
    }
    onSave(changes);
  };

  const modalActions = [
    { label: 'cancel', onClick: onClose, color: 'primary' },
    {
      label: 'save',
      onClick: handleSubmit(handleOnSave as any),
      color: 'primary',
    },
  ];

  if (!moveType) {
    return null;
  }

  return (
    <Modal
      color="grey"
      maxWidth="md"
      title={`Order Details${closingTitle}`}
      actions={modalActions}
      open={open}
      onClose={onClose}
    >
      {/*  @ts-ignore */}
      <FormProvider {...formMethods}>
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              <Box display="flex" alignItems="center" height="100%">
                <BodyText>Order ID:&nbsp;</BodyText>
                <BodyText>
                  <b>{`${orderNumberValue}`}</b>
                </BodyText>
              </Box>
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextInput
                fullWidth
                label={getLabel(SECONDARY_ID, labels)}
                name={SECONDARY_ID}
                control={control as any}
                disabled={disableInputs}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextInput
                label={getLabel(COMPANY_NAME, labels)}
                name={COMPANY_NAME}
                fullWidth
                inputProps={{ readOnly: true }}
                control={control as any}
                disabled={disableInputs}
              />
            </Grid>
            {!!moveTypes.length && (
              <Grid item sm={4} xs={12}>
                <Select
                  fullWidth
                  label={getLabel(MOVE_TYPE, labels)}
                  name={MOVE_TYPE}
                  onChange={handleMoveTypeChange}
                  value={moveType}
                  disabled={disableInputs}
                  // control={control}
                  skipControl
                >
                  {Object.entries(MoveTypeName).map(
                    ([moveType, label], index) =>
                      (settings[moveTypeKeys[index]] || data.moveType === moveType) && (
                        <MenuItem value={moveType} key={moveType} disabled={!settings[moveTypeKeys[index]]}>
                          {label}
                        </MenuItem>
                      ),
                  )}
                </Select>
              </Grid>
            )}
            <Grid item sm={4} xs={12}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                {...tooltip.tooltipProps}
                title="To change service type please close modal window and change service type under order details section"
                placement="top"
                interactive
              >
                <Box>
                  <Select
                    fullWidth
                    label={SERVICE_TYPE_NAME}
                    name={serviceTypeIdPath}
                    options={serviceTypeOptions}
                    control={control}
                    disabled
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Select
                fullWidth
                label={ESTIMATE_TYPE_NAME}
                name={estimateTypePath}
                options={estimateTypesOptions}
                control={control}
                disabled={disableInputs}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                label={getLabel(MOVE_SIZE, labels)}
                // @ts-ignore
                SelectProps={{ renderValue: () => currentSite?.name || siteName }}
                disabled={!moveType || disableInputs}
                name={MOVE_SIZE}
                onChange={handleMoveSizeChange}
                options={siteOptions as SelectOptions}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TruckOutlineIcon color={!moveType ? 'disabled' : 'primary'} />
                    </InputAdornment>
                  ),
                }}
                control={control}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Grid container>
              {roomOptions?.map((it) => (
                <Grid item xs={6} sm={3}>
                  <Checkbox
                    label={it.name}
                    key={it.id}
                    color="primary"
                    checked={currentRooms.has(it.id)}
                    onChange={toggleCurrentRooms(it)}
                    // @ts-ignore
                    skipControl
                    disabled={disableInputs}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={2}>
            <Box display="flex" alignItems="center">
              CuFt/Lbs:&nbsp;
              {/* @ts-ignore*/}
              <MoveSizeInfo size={activeCuFt} Icon={iconsByType[sizeType]} weight={activeWeight} />
            </Box>
          </Box>
        </Box>
      </FormProvider>
    </Modal>
  );
};
