import { Dispatch, SetStateAction, createContext } from 'react';

import { Waypoint } from '@elromcoinc/react-shared';

interface OrderWindowEditAddressIndex {
  editWaypoint: Waypoint | null;
  setEditWaypoint: Dispatch<SetStateAction<Waypoint | null>>;
}

const OrderWindowEditAddressIndexContext = createContext<OrderWindowEditAddressIndex>(
  {} as OrderWindowEditAddressIndex,
);

export { OrderWindowEditAddressIndexContext };
