import React from 'react';

import { BodyText, Link, MoveTypeName, MoveTypeTypes } from '@elromcoinc/react-shared';

import { MoveTypeStats } from 'admin/components/Reports/GeneralStatisticsTab';
import {
  DataTableColumnAttributes,
  DataTableColumnNames,
  SelectedOrderFiltersType,
  renderBoundaryValues,
} from 'admin/components/Reports/config';

const totalMoveType = 'Totals';
const isTotalMoveType = (moveType: MoveTypeTypes | string) => moveType === totalMoveType;

const makeGeneralStatisticsMoveTypeColumns = (
  rowsData: MoveTypeStats[],
  { setSelectedOrderFilters }: { setSelectedOrderFilters: SelectedOrderFiltersType },
) => [
  {
    name: DataTableColumnAttributes.MOVE_TYPE,
    label: 'TYPE',
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { moveType } = rowsData[rowIndex];

        return <BodyText>{MoveTypeName[moveType as keyof typeof MoveTypeName] || moveType}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS],
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersInPeriod, moveType } = rowsData[rowIndex];

        if (isTotalMoveType(moveType)) {
          return <BodyText className="total-column">{totalOrdersInPeriod}</BodyText>;
        }

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalOrdersInPeriod > 0) {
            setSelectedOrderFilters({ moveType });
          }
        };

        return totalOrdersInPeriod === 0 ? (
          <BodyText>{totalOrdersInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalOrdersInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_ESTIMATE_MIN,
    label: 'SALES ESTIMATE',
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalEstimateMin, totalEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalEstimateMin, totalEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_JOBS,
    label: <>BOOKED&nbsp;</>,
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedInPeriod, moveType } = rowsData[rowIndex];
        const isTotal = isTotalMoveType(moveType);

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalBookedInPeriod > 0) {
            setSelectedOrderFilters({ moveType, status: ['BOOKED'] });
          }
        };
        return isTotal || totalBookedInPeriod === 0 ? (
          <BodyText>{totalBookedInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalBookedInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_MIN,
    label: 'SALES BOOKED',
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedEstimateMin, totalBookedEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalBookedEstimateMin, totalBookedEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.CONVERSION,
    label: 'CONVERSION',
    options: {
      customBodyRender: (_: MoveTypeStats[], { rowIndex }: { rowIndex: number }) => {
        const { conversionRateForPeriod } = rowsData[rowIndex];
        const { asPercentage } = conversionRateForPeriod;

        return <BodyText>{asPercentage}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makeGeneralStatisticsMoveTypeColumns };
