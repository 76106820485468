import React, { ChangeEventHandler, FC, useEffect, useState } from 'react';

import {
  BodyBigText,
  Modal,
  Order,
  Radio,
  RadioGroup,
  START_TIME_EARLIEST,
  START_TIME_LATEST,
  Select,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { array, object, string } from 'yup';

import { ARRIVAL_WINDOW, QUOTE, SERVICES } from 'admin/components/OrderWindow/OrderWindowLabels';
import { convertLocalTimeToSeconds } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import { useOrderServiceIndex } from 'admin/components/OrderWindow/context';
import useTimeDuration from 'admin/components/OrderWindow/hooks/useTimeDuration';
import { ArrivalWindowByTime } from 'admin/constants/ArrivalWindowByTime';
import { ArrivalWindowToStartEndTime } from 'admin/constants/ArrivalWindowToStartEndTime';
import { getLabel } from 'admin/utils/getLabel';
import { ArrivalWindowNames, ArrivalWindowType, TimeModsNameType } from 'common-types';

const labels = {
  [SERVICES]: [
    {
      [QUOTE]: {
        [ARRIVAL_WINDOW]: 'Arrival Window',
        [START_TIME_EARLIEST]: 'From',
        [START_TIME_LATEST]: 'To',
      },
    },
  ],
};

const startTimeWindowSchema = object().shape(
  {
    [ARRIVAL_WINDOW]: string().nullable().required(),
    [START_TIME_EARLIEST]: string()
      .transform((v) => (v ? v : null))
      .nullable()
      .when(
        [START_TIME_LATEST],
        // @ts-ignore
        (endTime, _s) => (endTime ? _s.required().label(labels[SERVICES][0]?.[QUOTE][START_TIME_EARLIEST]) : _s),
      ),
    [START_TIME_LATEST]: string()
      .transform((v: string) => (v ? v : null))
      .nullable()
      .when(
        [START_TIME_EARLIEST],
        // @ts-ignore
        (startTime, _s) =>
          startTime
            ? _s
                .required()
                .label(labels[SERVICES][0]?.[QUOTE][START_TIME_LATEST])
                .test('startTimeTo', 'To must be more or equal Start Time From  ', (endTime: string) => {
                  return (
                    convertLocalTimeToSeconds(endTime) > convertLocalTimeToSeconds(startTime) ||
                    convertLocalTimeToSeconds(endTime) === convertLocalTimeToSeconds(startTime)
                  );
                })
            : _s,
      ),
  },
  [[START_TIME_LATEST, START_TIME_EARLIEST]],
);

const schema = object().shape({
  [SERVICES]: array().of(
    object({
      [QUOTE]: startTimeWindowSchema,
    }),
  ),
});

interface StartTimeWindowInfoProps {
  [SERVICES]: {
    [QUOTE]: {
      [ARRIVAL_WINDOW]: ArrivalWindowType;
      [START_TIME_EARLIEST]: string | null;
      [START_TIME_LATEST]: string | null;
    };
  }[];
}

interface StartTimeWindowProps {
  order: Order;
  onCancel: () => void;
  title: string;
  onSave: (changes: [string, any][]) => void;
  open: boolean;
}

const arrivalWindowOptions: SelectOptions = Object.keys(ArrivalWindowNames).map((key) => [
  key,
  ArrivalWindowNames[key as ArrivalWindowType],
]);

const StartTimeWindow: FC<StartTimeWindowProps> = ({ order, onCancel, title, onSave, open }) => {
  const { serviceIndex } = useOrderServiceIndex();
  const service = order.services.get(serviceIndex);
  const { quote } = service;

  const defaultArrivalWindow =
    ArrivalWindowByTime[
      quote.startTimeEarliest && quote.startTimeLatest ? quote.startTimeEarliest + quote.startTimeLatest : 'null'
    ];
  const formMethods = useForm<FieldValues>({
    defaultValues: {
      ...order,
      [SERVICES]: [
        {
          [QUOTE]: {
            ...quote,
            [ARRIVAL_WINDOW]: defaultArrivalWindow,
            [START_TIME_EARLIEST]: quote.startTimeEarliest,
            [START_TIME_LATEST]: quote.startTimeLatest,
          },
        },
      ],
    } as StartTimeWindowInfoProps,
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    control,
    watch,
    setValue,
    formState: { isDirty, isValid, isValidating },
    trigger,
  } = formMethods;

  const arrivalWindowPath = `${SERVICES}.0.${QUOTE}.${ARRIVAL_WINDOW}`;
  const startTimeEarliestPath = `${SERVICES}.0.${QUOTE}.${START_TIME_EARLIEST}`;
  const startTimeLatestPath = `${SERVICES}.0.${QUOTE}.${START_TIME_LATEST}`;

  const startTimeEarliestValue = watch(startTimeEarliestPath);
  const startTimeLatestValue = watch(startTimeLatestPath);
  const arrivalWindowValue = watch(arrivalWindowPath, ArrivalWindowType.ANY) as ArrivalWindowType;

  const [hasChanges, setHasChanges] = useState(false);
  const [timeRangeOption, setTimeRangeOption] = useState<string | null>(
    startTimeEarliestValue ? TimeModsNameType.CUSTOM : TimeModsNameType.TBD,
  );
  const [timeOptions, adjustedTimeOptions] = useTimeDuration(startTimeEarliestValue);

  useUpdateEffect(() => {
    if (startTimeEarliestValue) {
      setValue(startTimeLatestPath, startTimeLatestValue);
    }
    if (startTimeLatestValue) {
      setValue(startTimeEarliestPath, startTimeEarliestValue);
    }

    if (
      startTimeLatestValue &&
      convertLocalTimeToSeconds(startTimeEarliestValue) > convertLocalTimeToSeconds(startTimeLatestValue)
    ) {
      setValue(startTimeLatestPath, startTimeEarliestValue);
    }
    trigger();
  }, [startTimeEarliestValue, startTimeLatestValue]);

  const handleTimeRangeOptionChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setTimeRangeOption(value);
    setHasChanges(false);

    if (value === TimeModsNameType.TBD) {
      setValue(startTimeEarliestPath, null);
      setValue(startTimeLatestPath, null);
      setValue(arrivalWindowPath, ArrivalWindowType.ANY);
      setHasChanges(true);
    }
  };

  useEffect(() => {
    if (isDirty && !hasChanges && isValid && !isValidating) {
      setHasChanges(isDirty);
    }
  }, [isDirty, isValidating, isValid]);

  useUpdateEffect(() => {
    setValue(arrivalWindowPath, arrivalWindowValue);

    if (timeRangeOption === TimeModsNameType.CUSTOM) {
      setValue(startTimeEarliestPath, startTimeEarliestValue);
      setValue(startTimeLatestPath, startTimeLatestValue);
      setValue(arrivalWindowPath, ArrivalWindowType.ANY);
      return;
    }

    const time = ArrivalWindowToStartEndTime[arrivalWindowValue];

    if (time) {
      setValue(startTimeEarliestPath, time.start);
      setValue(startTimeLatestPath, time.end);
    }
  }, [arrivalWindowValue, startTimeEarliestValue, startTimeLatestValue, timeRangeOption]);

  const isStartTimeEarliestChanged = quote.startTimeEarliest !== startTimeEarliestValue;
  const isStartTimeLatestChanged = quote.startTimeLatest !== startTimeLatestValue;

  const handleSubmitForm = () => {
    const changes = [];
    if (isStartTimeEarliestChanged) {
      changes.push([`services.${serviceIndex}.quote.startTimeEarliest`, startTimeEarliestValue]);
    }
    if (isStartTimeLatestChanged) {
      changes.push([`services.${serviceIndex}.quote.startTimeLatest`, startTimeLatestValue]);
    }

    onSave(changes as [string, any][]);
  };

  const modalActions = [
    { label: 'cancel', onClick: onCancel },
    {
      label: 'save',
      onClick: handleSubmitForm,
      disabled: !hasChanges,
      color: !hasChanges ? 'default' : 'primary',
    },
  ];

  return (
    <Modal open={open} title={title} onClose={onCancel} maxWidth="sm" color="grey" actions={modalActions}>
      {/** @ts-ignore */}
      <FormProvider {...formMethods}>
        <Box mx={2} mb={1}>
          <Box mb={2}>
            <Box mb={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <Select
                    skipControl
                    fullWidth
                    label="Requested Arrival Window"
                    name={arrivalWindowPath}
                    options={arrivalWindowOptions}
                    value={quote.arrivalWindow ?? ArrivalWindowType.ANY}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <RadioGroup
              aria-label="time-setting"
              name="timeSetting"
              value={timeRangeOption}
              label={
                <BodyBigText>
                  <b>Set Time By</b>
                </BodyBigText>
              }
              onChange={handleTimeRangeOptionChange}
              // @ts-ignore
              skipControl
              direction="row"
            >
              <Radio
                data-testid="selectTimeFromSettings"
                value={TimeModsNameType.SETTINGS}
                label="Select Time from Settings"
                color="primary"
                sameUncheckedColor
              />
              <Radio
                value={TimeModsNameType.CUSTOM}
                label="Custom Time"
                color="primary"
                sameUncheckedColor
                data-testid="customTime"
              />
              <Radio value={TimeModsNameType.TBD} label="TBD" color="primary" sameUncheckedColor data-testid="TBD" />
            </RadioGroup>
          </Box>
          <Grid container spacing={2}>
            {TimeModsNameType.SETTINGS === timeRangeOption && (
              <Grid item xs={6} sm={4}>
                <Select
                  data-testid="arrivalWindowDropDown"
                  control={control}
                  fullWidth
                  label={getLabel(arrivalWindowPath, labels)}
                  name={arrivalWindowPath}
                  options={arrivalWindowOptions}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {TimeModsNameType.CUSTOM === timeRangeOption && (
              <>
                <Grid item xs={6} sm={4}>
                  <Select
                    data-testid="timeFrom"
                    control={control}
                    fullWidth
                    label={getLabel(startTimeEarliestPath, labels)}
                    name={startTimeEarliestPath}
                    options={timeOptions}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Select
                    data-testid="timeTo"
                    control={control}
                    fullWidth
                    label={getLabel(startTimeLatestPath, labels)}
                    name={startTimeLatestPath}
                    options={adjustedTimeOptions}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </FormProvider>
    </Modal>
  );
};

export default StartTimeWindow;
