import { Record } from 'immutable';

export default class RangeValue extends Record({
  minValue: null,
  maxValue: null,
  humanReadable: ''
}) {
  static createRangeValue(data = {}) {
    return new RangeValue(data);
  }
}
