import React, { FC, ReactNode } from 'react';

import { BodyBigText, BodyText, Button, IconButton, Order } from '@elromcoinc/react-shared';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';

import { DELETE_SERVICE_KEY } from 'admin/components/OrderWindow/OrderWindowConstants';
import { useOrderClosingContext, useOrderServiceIndex } from 'admin/components/OrderWindow/context';
import { AllServicesIndex } from 'admin/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    multiDayContainer: {
      backgroundColor: theme.palette.primary.light,
      borderRadius: '4px',
      margin: '4px',
      padding: '6px 12px',
      flexDirection: 'column',
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('xs')]: {
        padding: '2px',
      },
    },
    multiDayHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      padding: '5px 0',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    multiDay: {
      width: '2rem',
      height: '2rem',
      '&:not(:last-child)': {
        marginRight: '.5rem',
      },
    },
    allDayButton: {
      backgroundColor: theme.palette.grey.A100,
      borderRadius: '50%',
    },
    deleteDayBtn: {
      textTransform: 'none',
    },
  }),
);

interface MultiDayViewProps {
  children: ReactNode;
  onChange: (change: { name: string; value: any }) => void;
  order: Order;
}

const MultiDayView: FC<MultiDayViewProps> = ({ children, order, onChange }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { isClosing } = useOrderClosingContext();
  const { selectedServiceIndex, setServiceIndex, isSelectedAllServices } = useOrderServiceIndex();

  const handleChangeServiceIndex = (index: number) => () => {
    if (isClosing && !order.closingOrderDetail.serviceRosterClosingsDto?.get(index)) {
      enqueueSnackbar('Please save changes', { variant: 'error' });
      return;
    }
    setServiceIndex(index);
  };

  const onServiceDelete = (index: number) => () => {
    if (index >= 0 && order.services.get(index)?.id) {
      onChange({ name: DELETE_SERVICE_KEY, value: order.services.get(index).id });
    }
  };

  return (
    <Box className={classes.multiDayContainer} data-testId={`dayView-${selectedServiceIndex}`}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box className={classes.multiDayHeader}>
          <Box mr={2}>
            <BodyBigText>
              <b>Multi Day Move</b>
            </BodyBigText>
          </Box>
          <Box mr={2}>
            <BodyBigText>
              <b>View Day:</b>
            </BodyBigText>
          </Box>
          <Box flexDirection="row" display="flex">
            {order.services.map((service, idx) => (
              <IconButton
                classes={{ root: classes.multiDay }}
                color={idx === selectedServiceIndex ? 'primary' : 'default'}
                key={service.id}
                variant="default"
                size="small"
                onClick={handleChangeServiceIndex(idx)}
              >
                <BodyText data-testId={`dayNumber-${idx}`}>{idx + 1}</BodyText>
              </IconButton>
            ))}
            <Box className={classes.allDayButton}>
              <IconButton
                color={isSelectedAllServices ? 'primary' : 'default'}
                onClick={handleChangeServiceIndex(AllServicesIndex)}
                size="small"
                variant="default"
                classes={{ root: classes.multiDay }}
              >
                <BodyText>ALL</BodyText>
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flexDirection="row" display="flex" flexWrap="wrap">
        {children}
      </Box>
      <Box mt={1} textAlign="end">
        {!isSelectedAllServices && (
          <Button
            size="small"
            className={classes.deleteDayBtn}
            variant="text"
            color="primary"
            onClick={onServiceDelete(selectedServiceIndex)}
          >
            Delete this Day <DeleteIcon />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MultiDayView;
