import { FC, useEffect, useState } from 'react';

import paymentAPI from 'admin/api/PaymentAPI';
import { PaymentClientKeyContext } from 'admin/components/OrderWindow/context/PaymentClientKeyContext';

const PaymentClientKeyProvider: FC = ({ children }) => {
  const [publicClientKey, setPublicClientKey] = useState<AuthorizeClientPublicKey | null>(null);

  useEffect(() => {
    paymentAPI
      .getPublicClientKey()
      .then(setPublicClientKey)
      .catch(() => {});
  }, []);
  return <PaymentClientKeyContext.Provider value={publicClientKey}>{children}</PaymentClientKeyContext.Provider>;
};

export { PaymentClientKeyProvider };
