import React from 'react';
import pt from 'prop-types';
import { Popover, Paper, Box, makeStyles } from '@material-ui/core';
import { HeaderText } from '@elromcoinc/react-shared';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.grey[100]
  },
  actions: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.common.white
  }
}));

export default function Popper({ minWidth, anchorEl, onClose, children, actions, title, ...rest }) {
  const classes = useStyles();
  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      {...rest}
      open={Boolean(anchorEl)}>
      <Paper className={classes.paper}>
        <Box minWidth={minWidth}>
          <Box p={1}>
            <HeaderText>
              <b>{title}</b>
            </HeaderText>
          </Box>
          <Box p={1}>{children}</Box>
          {Boolean(actions) && <Box className={classes.actions}>{actions}</Box>}
        </Box>
      </Paper>
    </Popover>
  );
}

Popper.propTypes = {
  minWidth: pt.string,
  onClose: pt.func,
  anchorEl: pt.node.isRequired,
  actions: pt.oneOf([pt.arrayOf(pt.node), pt.node]).isRequired,
  children: pt.oneOf([pt.arrayOf(pt.node), pt.node]).isRequired,
  title: pt.string.isRequired
};

Popper.defaultProps = {
  minWidth: '320px',
  onClose: null
};
