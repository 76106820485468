import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import withMobileDialog from '@material-ui/core/withMobileDialog';

// todo: rename to make more generic and extract to shared components
const ConfirmationDialog = ({ children, actions, fullScreen, title, ...rest }) => (
  <Dialog
    fullScreen={fullScreen}
    open
    {...rest}
    aria-labelledby="confirmation-dialog-title"
    aria-describedby="confirmation-dialog-description">
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions>{actions}</DialogActions>
  </Dialog>
);

ConfirmationDialog.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.node,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', false]),
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

ConfirmationDialog.defaultProps = {
  title: null,
  fullWidth: true,
  maxWidth: 'sm'
};

export default withMobileDialog()(ConfirmationDialog);
