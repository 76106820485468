import notificationSound from 'common/audio/notification.mp3';

let audio = null;

const playNotificationSound = () => {
  try {
    if (!audio) {
      audio = new window.Audio(notificationSound);
    }

    window.focus();
    audio.play();
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export default playNotificationSound;
