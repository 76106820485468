export enum DeliveryWindow {
  ANY = 'Any Time',
  NINE_AM_TWELVE_PM = '9AM - 12AM',
  TWELVE_THREE_PM = '12PM - 3PM',
  THREE_SEVEN_PM = '3PM - 6PM',
}

export enum DeliveryWindowEnum {
  ANY = 'ANY',
  NINE_AM_TWELVE_PM = 'NINE_AM_TWELVE_PM',
  TWELVE_THREE_PM = 'TWELVE_THREE_PM',
  THREE_SEVEN_PM = 'THREE_SEVEN_PM',
}

export enum ArrivalWindow {
  ANY = 'Any Time',
  EIGHT_TEN_AM = '8AM - 10AM',
  TWELVE_THREE_PM = '12PM - 3PM',
  ONE_FOUR_PM = '1PM - 4PM',
  THREE_SEVEN_PM = '3PM - 7PM',
}

export enum ArrivalWindowEnum {
  ANY = 'ANY',
  EIGHT_TEN_AM = 'EIGHT_TEN_AM',
  TWELVE_THREE_PM = 'TWELVE_THREE_PM',
  ONE_FOUR_PM = 'ONE_FOUR_PM',
  THREE_SEVEN_PM = 'THREE_SEVEN_PM',
}
