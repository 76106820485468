import React, { Fragment, useRef, useState } from 'react';

import {
  BodySmallText,
  BodyText,
  HeaderBigText,
  LaborType,
  Order,
  Service,
  StorageMoveStage,
  Waypoint,
  WaypointLocationType,
  getServicePropertyName,
  getServiceRosterClosingPropertyName,
  isLoadingOrPackingService,
  isLocalMove,
  isLongDistanceService,
  isMovingAndStorageService,
  isOvernightService,
  isPackingService,
  isUnloadingService,
  roundNumberToFixedDigits,
  uuid,
} from '@elromcoinc/react-shared';
import { Box, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { List } from 'immutable';
// TODO this library stop supporting. We need to refactor it using @dnd-kit
// @ts-ignore
import { sortableContainer } from 'react-sortable-hoc';

import { TruckBlock } from 'admin/components/OrderWindow/blocks/Addresses';
import AddressBlock from 'admin/components/OrderWindow/blocks/Addresses/AddressBlock';
import { EditWaypointModal } from 'admin/components/OrderWindow/blocks/Addresses/EditWaypointModal';
import { SelectedServiceAddressDistance } from 'admin/components/OrderWindow/blocks/Addresses/SelectedServiceAddressDistance';
import StorageBlock from 'admin/components/OrderWindow/blocks/Addresses/StorageBlock';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context/useOrderWindowEditAddressIndex';
import { STORAGE_MOVE_STAGE } from 'admin/components/Settings/components/TestCalculator/TestCalculatorLabel';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import {
  DESTINATION_ADDRESS_TITLE,
  DROP_OFF_ADDRESS,
  GOOGLE_DIRECTION,
  PICKUP_ADDRESS_TITLE,
  START_ADDRESS_TITLE,
} from 'admin/constants';
import ConfirmationDialog from 'common/components/ConfirmationDialog';

import CompanyAddress from './CompanyAddress';
import EmptyAddressBlock from './EmptyAddressBlock';

const findNextNotHiddenWaypointIndex = (list: List<Waypoint>, currentIndex: number) =>
  list.findIndex((wp, index) => !wp.hidden && index > currentIndex);

const buildTitle = (
  waypoint: Waypoint,
  index: number,
  totalSize: number,
  serviceType: GeneralServiceType,
  isLastMultiDayService: boolean,
) => {
  if (
    waypoint.locationType === WaypointLocationType.CUSTOMER_SITE ||
    waypoint.locationType === WaypointLocationType.STORAGE
  ) {
    if (
      index === 0 &&
      !isUnloadingService(serviceType) &&
      (!isLastMultiDayService || isOvernightService(serviceType))
    ) {
      return START_ADDRESS_TITLE;
    }

    if (isLoadingOrPackingService(serviceType)) {
      return PICKUP_ADDRESS_TITLE;
    }

    if (totalSize - index > 1 && isUnloadingService(serviceType)) {
      return DROP_OFF_ADDRESS;
    }
    // eslint-disable-next-line
    if ((index >= 0 && totalSize - index > 1) || (index !== 0 && isUnloadingService(serviceType))) {
      return 'Extra Stop';
    }

    return DESTINATION_ADDRESS_TITLE;
  }

  if (waypoint.locationType === WaypointLocationType.COMPANY_SITE) {
    return 'Parking Lot';
  }

  return 'Stop';
};

// @ts-ignore
const Container = sortableContainer(({ children }) => {
  return (
    <Box position="relative" display="flex" flexWrap="wrap" justifyContent="center">
      {children}
    </Box>
  );
});

const companyWaypointIndex = 1;

const Addresses = ({
  inFlightRecalculateOrder,
  setLoadedOrder,
}: {
  inFlightRecalculateOrder: boolean;
  setLoadedOrder: (value: Order) => void;
}) => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { closingTitle, isClosing } = useOrderClosingContext();
  const { onChange } = useOrderChangeSet();
  const { currentDistanceUnitLabel, convertMetersToCurrentUnits, isInMiles } = useOrderSettingUnits();
  const { editWaypoint, setEditWaypoint } = useOrderWindowEditAddressIndex();
  const [minWidth, setMinWidth] = useState(100);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deleteWaypointIndex, setDeleteWaypointIndex] = useState<number | null>(null);
  const storageQuote = order.getServiceStorageQuote(serviceIndex);
  const waypoints = ((isClosing ? order.closingOrderDetail! : order)?.getAddressWaypointsBasedOnService(serviceIndex) ??
    List()) as List<Waypoint>;
  const storageMoveStage = order.storageMoveStage;
  const linkedOrderId = order.linkedOrderId;
  const isEdit = editWaypoint != null && editWaypoint.waypointIndex >= 0;
  const companyAddress = order.getServiceWaypoints().filter(Waypoint.isCompanyStop).first();
  const companyAddressLine = encodeURIComponent(companyAddress?.address?.set('street2', null)?.fullAddressLine());
  const orderServices = (isClosing ? order.closingOrderDetail!.services : order.services) as List<Service>;
  const uniqueWaypointIdsByService = orderServices.map((service) => service.quote.waypoints.map(uuid));
  const orderRef = useRef(order);
  orderRef.current = order;
  const isClosingRef = useRef(isClosing);
  isClosingRef.current = isClosing;
  const getServiceWaypoints = (serviceIndex = 0) => {
    const currentOrder = (isClosingRef.current ? orderRef.current.closingOrderDetail : orderRef.current) as Order;
    return currentOrder.getServiceWaypoints(serviceIndex);
  };

  const handleBlockWidth = (width: number) => {
    setMinWidth((currentMinWidth) => (currentMinWidth < width ? width : currentMinWidth));
  };

  const handleSwapStorage = () => {
    onChange({
      name: STORAGE_MOVE_STAGE,
      value: order?.storageMoveStage === StorageMoveStage.OUT ? StorageMoveStage.IN : StorageMoveStage.OUT,
    });
  };

  const validateAndSet = (newWaypoints: List<Waypoint>, waypoint: Waypoint) => {
    const serviceIndex = waypoint.serviceIndex;
    const serviceType = order.getServiceType(serviceIndex);
    const waypointPath = isClosingRef.current
      ? getServiceRosterClosingPropertyName(serviceIndex, 'waypoints')
      : getServicePropertyName(serviceIndex, 'waypoints');

    if (!isLocalMove(serviceType) && !isLongDistanceService(serviceType) && !isMovingAndStorageService(serviceType)) {
      setErrorMsg(null);
      onChange({ name: waypointPath, value: newWaypoints });
    } else if (isMovingAndStorageService(serviceType)) {
      setErrorMsg(null);
      onChange({ name: waypointPath, value: newWaypoints });
    } else if (newWaypoints.size === 4) {
      setErrorMsg(null);
      onChange({
        name: waypointPath,
        value: newWaypoints.map((w, index) => {
          if (index === 1) {
            return w.set('laborType', 'LOADING');
          }
          if (index === 2) {
            return w.set('laborType', 'UNLOADING');
          }
          return w;
        }),
      });
    } else if (newWaypoints.size >= 4 && newWaypoints.get(1)?.laborType !== 'LOADING') {
      setErrorMsg('First waypoint should be pickup!');
    } else if (newWaypoints.size >= 4 && newWaypoints.get(newWaypoints.size - 2)?.laborType !== 'UNLOADING') {
      setErrorMsg('Last waypoint should be drop-off!');
    } else {
      setErrorMsg(null);
      onChange({ name: waypointPath, value: newWaypoints });
    }
  };

  const totalInMeters = orderServices
    .map((s) => (isInMiles ? s.quote.totalCrewTravelDistance.miles : s.quote.totalCrewTravelDistance.kilometers))
    .reduce((accumulator, it) => accumulator + it, 0);

  const totalDistance =
    totalInMeters >= 0 ? `${roundNumberToFixedDigits(totalInMeters)} ${currentDistanceUnitLabel.toLowerCase()}` : '';

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    const truckIndex = waypoints.findIndex(Waypoint.isTruck);
    const isMoveTruckToRightSize = truckIndex > 1 && truckIndex > oldIndex;
    const isMoveTruckToLeftSide = truckIndex < waypoints.size - 2 && truckIndex < oldIndex;
    const isPossibleMoveOvernightTruck = truckIndex === -1 || isMoveTruckToRightSize || isMoveTruckToLeftSide;

    if (oldIndex !== newIndex && isPossibleMoveOvernightTruck) {
      const oldWaypoint = waypoints.get(oldIndex) as Waypoint;
      const newWaypoint = waypoints.get(newIndex) as Waypoint;

      if (oldWaypoint.serviceIndex === newWaypoint.serviceIndex) {
        const waypoints = getServiceWaypoints(oldWaypoint.serviceIndex);

        validateAndSet(
          waypoints
            .delete(oldIndex + companyWaypointIndex)
            .insert(newIndex + companyWaypointIndex, oldWaypoint)
            .map((w, i) =>
              w
                .set('waypointIndex', i)
                .set('originalWaypointIndex', i)
                .set('sequenceOrder', i + 1),
            ),
          oldWaypoint,
        );
      } else {
        const originWaypoints = getServiceWaypoints(oldWaypoint.serviceIndex);
        const destinationWaypoints = getServiceWaypoints(newWaypoint.serviceIndex);

        validateAndSet(
          originWaypoints.delete(oldWaypoint.waypointIndex).map((w, i) =>
            w
              .set('waypointIndex', i)
              .set('originalWaypointIndex', i)
              .set('sequenceOrder', i + 1),
          ),
          oldWaypoint,
        );

        const waypointToInsert = oldWaypoint.set('serviceIndex', newWaypoint.serviceIndex);

        validateAndSet(
          destinationWaypoints.insert(newWaypoint.waypointIndex, waypointToInsert).map((w, i) =>
            w
              .set('waypointIndex', i)
              .set('originalWaypointIndex', i)
              .set('sequenceOrder', i + 1),
          ),
          waypointToInsert,
        );
      }
    }
  };

  const closeDeleteDialog = () => {
    setDeleteWaypointIndex(null);
  };

  const closeErrorDialog = () => {
    setErrorMsg(null);
  };

  const deleteWaypoint = () => {
    const waypointToDelete = waypoints.get(deleteWaypointIndex!) as Waypoint;
    const newWaypoints = getServiceWaypoints(waypointToDelete.serviceIndex);

    validateAndSet(
      newWaypoints.delete(waypointToDelete.waypointIndex).map((w, i) =>
        w
          .set('waypointIndex', i)
          .set('originalWaypointIndex', i)
          .set('sequenceOrder', i + 1),
      ),
      waypointToDelete,
    );

    closeDeleteDialog();
  };

  const handleOnSave = (newWaypoint: Waypoint) => {
    const waypoints = getServiceWaypoints(newWaypoint.serviceIndex);
    const index = newWaypoint.waypointIndex;

    if (isEdit) {
      validateAndSet(
        waypoints.set(index, newWaypoint).map((w, i) =>
          w
            .set('waypointIndex', i)
            .set('originalWaypointIndex', i)
            .set('sequenceOrder', i + 1),
        ),
        newWaypoint,
      );
    } else {
      const insertIndex = index < 0 ? index * -1 : index || 2;
      const updatedWaypoints = waypoints.insert(insertIndex, newWaypoint).map((w, i) =>
        w
          .set('waypointIndex', i)
          .set('originalWaypointIndex', i)
          .set('sequenceOrder', i + 1),
      );
      validateAndSet(updatedWaypoints, updatedWaypoints.get(insertIndex) as Waypoint);
    }

    setEditWaypoint(null);
  };

  const handleChangeWaypoint = (newWaypoint: Waypoint) => {
    const waypoints = getServiceWaypoints(newWaypoint.serviceIndex);
    const index = newWaypoint.waypointIndex;
    validateAndSet(waypoints.set(index, newWaypoint), newWaypoint);
  };

  const handleClickOpenMap = (index: number) => () => {
    const waypoint = waypoints.get(index);
    const address = encodeURIComponent(waypoint?.address?.fullAddressLine() ?? '');
    const path = (index === 0 ? [companyAddressLine, address] : [address, companyAddressLine])
      .filter(Boolean)
      .join('/');

    window.open(`${GOOGLE_DIRECTION}${path}`, '_blank');
  };

  const renderBlock = (item: Waypoint, index: number, waypoints: List<Waypoint>) => {
    if (!item) {
      return null;
    }

    const serviceType = order.getServiceType(item.serviceIndex);

    const startDistanceFromPrevious = `${convertMetersToCurrentUnits(
      item?.distanceFromPrevious || 0,
    )} ${currentDistanceUnitLabel.toLowerCase()}`;
    const endDistanceFromPrevious = `${convertMetersToCurrentUnits(
      order.getAllServicesWaypoints().last()?.distanceFromPrevious || 0,
    )} ${currentDistanceUnitLabel.toLowerCase()}`;
    const startTravelDurationFromPrevious = item?.travelDurationFromPrevious.humanReadable;
    const endTravelDurationFromPrevious = order.getAllServicesWaypoints().last()
      ?.travelDurationFromPrevious.humanReadable;
    const isDestination = findNextNotHiddenWaypointIndex(waypoints, index) === -1;
    const showLaborType = !isLoadingOrPackingService(serviceType) && !isUnloadingService(serviceType);
    const showLastAddStop = waypoints.filter((it) => !it.hidden).size < 2;
    const isNotDraggable =
      (waypoints.filter((wp) => wp.laborType === LaborType.LOADING || wp.laborType === LaborType.UNLOADING).size < 3 &&
        isOvernightService(serviceType)) ||
      isSelectedAllServices;

    const distanceFromPrevious = `${convertMetersToCurrentUnits(
      waypoints.size > index + 1 ? waypoints.get(index + 1)?.distanceFromPrevious || 0 : 0,
    )} ${currentDistanceUnitLabel.toLowerCase()}`;

    const durationFromPrevious = `${
      waypoints.size > index + 1 ? waypoints?.get(index + 1)?.travelDurationFromPrevious.humanReadable : 0
    }`;

    const nextNotHiddenWaypoint = findNextNotHiddenWaypointIndex(waypoints, index);

    const handleOpenRoute = () => {
      const routes = waypoints.map((it) => encodeURIComponent(it.address.fullAddressLine())).join('/');

      window.open(`${GOOGLE_DIRECTION}${routes}`, '_blank');
    };

    const handleDeleteWaypoint = () => {
      setDeleteWaypointIndex(index);
    };

    const firstWaypoint = waypoints.first();
    const lastWaypoint = waypoints.last();

    const isLastOvernightWaypointService =
      firstWaypoint?.serviceIndex !== lastWaypoint?.serviceIndex && item.waypointIndex === lastWaypoint?.waypointIndex;
    const isSingleAddresses =
      isUnloadingService(serviceType) || isPackingService(serviceType) || isLoadingOrPackingService(serviceType);
    const isNoStorage = item.locationType !== WaypointLocationType.STORAGE;

    const title = buildTitle(item, index, waypoints.size, serviceType, isLastOvernightWaypointService);

    return (
      <Fragment key={item.id || uniqueWaypointIdsByService.get(item?.serviceIndex).get(item?.waypointIndex)}>
        {index === 0 && isNoStorage && !item.hidden && (
          <CompanyAddress
            travelDurationFromPrevious={startTravelDurationFromPrevious}
            distanceFromPrevious={startDistanceFromPrevious}
            marginRight={0}
            rightArrow
          />
        )}
        {item.locationType === WaypointLocationType.STORAGE && (
          <StorageBlock
            serviceType={serviceType}
            linkedOrderId={linkedOrderId}
            storageMoveStage={storageMoveStage || StorageMoveStage.IN}
            index={index}
            minWidth={minWidth}
            onBlockWidthChange={handleBlockWidth}
            data={item}
            title={title}
            isDestination={isDestination}
            distanceFromPrevious={distanceFromPrevious}
            storageQuote={storageQuote}
            durationFromPrevious={durationFromPrevious}
            handleSwapStorage={handleSwapStorage}
            setLoadedOrder={setLoadedOrder}
          />
        )}
        {item.locationType === WaypointLocationType.TRUCK && (
          <TruckBlock
            index={index}
            minWidth={minWidth}
            onBlockWidthChange={handleBlockWidth}
            data={item}
            openRoute={handleOpenRoute}
            isDestination={isDestination}
            showLastAddStop={showLastAddStop}
            totalDistance={totalDistance}
            distanceFromPrevious={distanceFromPrevious}
            durationFromPrevious={durationFromPrevious}
          />
        )}
        {item.locationType !== WaypointLocationType.STORAGE && item.locationType !== WaypointLocationType.TRUCK && (
          <AddressBlock
            serviceType={serviceType}
            index={index}
            minWidth={minWidth}
            showLaborType={showLaborType}
            onBlockWidthChange={handleBlockWidth}
            data={item}
            title={title}
            isDestination={isDestination}
            showLastAddStop={showLastAddStop}
            distanceFromPrevious={distanceFromPrevious}
            onDelete={handleDeleteWaypoint}
            onChange={handleChangeWaypoint}
            nextNotHiddenWaypoint={nextNotHiddenWaypoint}
            onClickOpenMap={handleClickOpenMap}
            durationFromPrevious={durationFromPrevious}
            dragDisabled={isNotDraggable}
            inFlightRecalculateOrder={inFlightRecalculateOrder}
            handleSwapStorage={handleSwapStorage}
          />
        )}
        {((isSingleAddresses && nextNotHiddenWaypoint < 1) ||
          (isDestination && isNoStorage && index !== 0) ||
          waypoints.size === 1) &&
          !item.hidden && (
            <CompanyAddress
              travelDurationFromPrevious={endTravelDurationFromPrevious}
              distanceFromPrevious={endDistanceFromPrevious}
              marginLeft={0}
              leftArrow
            />
          )}
      </Fragment>
    );
  };

  return (
    <Box m={0.5} width="100%" position="relative">
      <VideoButton position="absolute" video="0xckPu1C13w" />
      <Box display="flex" alignItems="baseline" my={0.5} flexWrap="wrap">
        <HeaderBigText>
          <strong>{`Addresses${closingTitle}`}</strong>
        </HeaderBigText>
        <Box ml={1}>
          <BodySmallText data-testid="addressesNotes">
            (Note: click on pins to see address on a map, or the map icon to see the full route)
          </BodySmallText>
        </Box>
      </Box>
      <Box>
        <Container onSortEnd={onSortEnd} useDragHandle axis="xy">
          {isSelectedAllServices ? (
            orderServices.map((service, index) => {
              const waypoints = (
                isClosingRef.current ? order.closingOrderDetail : order
              ).getAddressWaypointsBasedOnService(index);

              const lastWaypoint = waypoints.last();
              const serviceType = order.getServiceType(index);
              const isLastOvernightWaypoint = isOvernightService(serviceType) && lastWaypoint?.serviceIndex === index;

              if (isLastOvernightWaypoint) {
                return null;
              }

              return (
                <Fragment key={service.id}>
                  {waypoints.map(renderBlock)}
                  {waypoints.isEmpty() && <EmptyAddressBlock index={index} />}
                </Fragment>
              );
            })
          ) : (
            <Fragment key={serviceIndex}>
              {(waypoints || List()).map(renderBlock)}
              {waypoints.isEmpty() && <EmptyAddressBlock index={serviceIndex} />}
            </Fragment>
          )}
        </Container>
      </Box>
      <SelectedServiceAddressDistance />
      {editWaypoint !== null && <EditWaypointModal onSave={handleOnSave} />}
      {deleteWaypointIndex !== null && (
        <ConfirmationDialog
          actions={
            <Box mt={1}>
              <Button variant="text" color="default" aria-label="Cancel" onClick={closeDeleteDialog}>
                Cancel
              </Button>
              <Button color="primary" onClick={deleteWaypoint}>
                Delete
              </Button>
            </Box>
          }
          open
          onClose={closeDeleteDialog}
        >
          <BodyText>This waypoint will be deleted. Are you sure?</BodyText>
        </ConfirmationDialog>
      )}
      {errorMsg !== null && (
        <ConfirmationDialog
          actions={
            <Box mt={1}>
              <Button variant="text" color="default" aria-label="Cancel" onClick={closeErrorDialog}>
                Close
              </Button>
            </Box>
          }
          open
          onClose={closeErrorDialog}
          title="Error"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningIcon color="error" style={{ width: '3rem', height: '3rem', marginRight: '1rem' }} />
            <BodyText>{errorMsg}</BodyText>
          </Box>
        </ConfirmationDialog>
      )}
    </Box>
  );
};

export default Addresses;
