export enum TaskType {
  OPEN_TASKS = 'OPEN_TASKS',
  DUE_TODAY = 'DUE_TODAY',
  DUE_THIS_WEEK = 'DUE_THIS_WEEK',
  OVERDUE = 'OVERDUE',
  COMPLETED = 'COMPLETED',
}

export const tasksTypeOptions = [
  [TaskType.OPEN_TASKS, 'Open Tasks'],
  [TaskType.DUE_TODAY, 'Due Today'],
  [TaskType.DUE_THIS_WEEK, 'Due This Week'],
  [TaskType.OVERDUE, 'Overdue'],
  [TaskType.COMPLETED, 'Completed'],
];
