enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  /**
   * The credit card transaction happened else where (like a point of sale system) and the transaction is manually being recorded here.
   */
  CREDIT_CARD_RECORD = 'CREDIT_CARD_RECORD',
  CASH = 'CASH',
  CHECK = 'CHECK',
  CUSTOM = 'CUSTOM',
}

const PaymentTypeName: Record<PaymentType, string> = {
  [PaymentType.CASH]: 'Cash',
  [PaymentType.CHECK]: 'Check',
  [PaymentType.CUSTOM]: 'Custom',
  [PaymentType.CREDIT_CARD]: 'Credit Card',
  [PaymentType.CREDIT_CARD_RECORD]: 'Credit Card Record',
};

export { PaymentType, PaymentTypeName };
