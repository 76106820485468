import { LABOR_DURATION_RANGE } from 'admin/constants/FieldNames';
import { durationToHoursMinutes } from 'admin/utils/timeToTextFormat';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const toValue = (durationMin: { originalSeconds: number }, durationMax: { originalSeconds: number }) => {
  if (
    durationMax.originalSeconds === durationMin.originalSeconds ||
    durationMin.originalSeconds > durationMax.originalSeconds
  ) {
    return durationToHoursMinutes(durationMin.originalSeconds);
  }
  const from = durationToHoursMinutes(durationMin.originalSeconds);
  const to = durationToHoursMinutes(durationMax.originalSeconds);
  return `${from} - ${to}`;
};

const laborDurationRange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath !== LABOR_DURATION_RANGE) {
    return null;
  }

  const oldDuration = oldOrder.services.first().quote.laborDurationRangeOverride;
  const newDuration = newOrder.services.first().quote.laborDurationRangeOverride;

  const oldValue = toValue(oldDuration.durationMin, oldDuration.durationMax);
  const newValue = toValue(newDuration.durationMin, newDuration.durationMax);

  if (oldValue === newValue) {
    return null;
  }

  return [
    createUpdatedValueDto(
      oldValue,
      newValue,
      'Labor Time',
      extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
    ),
  ];
};

export default laborDurationRange;
