export const SURVEY_ID = 'id';
export const SURVEY_DATE = 'surveyDate';
export const SURVEY_TIME_START = 'startTimeFrom';
export const SURVEY_TIME_END = 'startTimeTo';
export const DURATION = 'duration';
export const ASSIGNED_BY = 'assignedBy';
export const STATUS = 'status';
export const ESTIMATOR_ID = 'estimatorId';

export const SurveyLabels = {
  [SURVEY_DATE]: 'Survey Date',
  [SURVEY_TIME_START]: 'Start Time Window (Start)',
  [SURVEY_TIME_END]: 'Start Time Window (End)',
  [DURATION]: 'Duration',
  [ASSIGNED_BY]: 'Assigned By',
  [STATUS]: 'Status',
  [ESTIMATOR_ID]: 'Estimator',
};

export const ORDER_ID_QUERY_PARAM = 'orderId';
