import React from 'react';

import { ActivitySourceType, Modal } from '@elromcoinc/react-shared';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import pt from 'prop-types';

import { Messages } from 'admin/components/Communication/MessageTab/Messages';

const useStyles = makeStyles((theme) => ({
  messagesWrapper: {
    position: 'absolute',
    right: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  messages: {
    height: '100%',
    position: 'relative',
    flexFlow: 'column',
  },
  messagesModal: {
    height: 'calc(100vh - 177px)',
    position: 'relative',
    flexFlow: 'column',
  },
  messagesContent: {
    height: '97%',
    minHeight: '400px',
    paddingTop: '15px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '400px',
    },
  },
}));

// TODO Make opening message globally using redux
export const OrderMessageModal = ({ onClose, order }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Modal onClose={onClose} title="Messages" open>
      <Box className={classes.messagesContent}>
        <Box className={isMobile ? classes.messages : classes.messagesModal}>
          <Box className={classes.messagesWrapper}>
            <Messages
              sourceId={order?.orderId}
              sourceType={ActivitySourceType.ORDER}
              orderNumber={order?.orderNumber}
              orderName={order?.customerName}
              phoneNumber={order?.customerPhone}
              order={order}
            />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

OrderMessageModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: pt.object.isRequired,
  onClose: pt.func.isRequired,
};
