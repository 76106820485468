import React, { FC } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { BlueRightArrorIcon } from 'common/components/Widgets';
import { CompanyLogo } from 'common/components/icons';

interface CompanyAddressProps {
  travelDurationFromPrevious?: string;
  distanceFromPrevious?: string;
  marginLeft?: number;
  marginRight?: number;
  leftArrow?: boolean;
  rightArrow?: boolean;
}

const CompanyAddress: FC<CompanyAddressProps> = ({
  travelDurationFromPrevious = false,
  distanceFromPrevious = false,
  marginRight,
  marginLeft,
  leftArrow = false,
  rightArrow = false,
}) => {
  return (
    <Box
      display="flex"
      minWidth="52px"
      minHeight="52px"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      alignSelf="center"
      mr={marginRight}
      ml={marginLeft}
      p={2}
    >
      <Box display="flex">
        {leftArrow && (
          <Box mt={1}>
            <BlueRightArrorIcon />
          </Box>
        )}
        <CompanyLogo color="primary" />
        {rightArrow && (
          <Box mt={1}>
            <BlueRightArrorIcon />
          </Box>
        )}
      </Box>
      <BodyText>{distanceFromPrevious}</BodyText>
      <BodyText>{travelDurationFromPrevious}</BodyText>
    </Box>
  );
};

export default CompanyAddress;
