import React, { useEffect, useState } from 'react';

import {
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  ElromcoCircularProgress,
  HeaderSmallText,
  Link,
  Order,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, Grid, Tooltip, makeStyles } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useDispatch } from 'react-redux';

import orderApi from 'admin/api/OrderAPI';
import { openAccount } from 'admin/autodux/WindowsAutodux';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import { OrderWindowPages } from 'admin/constants/OrderWindowPages';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    pointerEvents: 'auto',
  },
  inboxIcon: {
    fontSize: '30px',
  },
  colorButton: {
    color: ({ error }) => (error ? theme.palette.error.main : theme.palette.primary.main),
  },
}));

export const CustomerStatsButton = ({ customerId, setCurrentPage, account, order }) => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [error, setError] = useState(false);
  const [bookedJobs, setBookedJobs] = useState(0);
  const [totalPaid, setTotalPaid] = useState('$0.00');
  const { enqueueSnackbar } = useSnackbar();
  const [isFlight, setIsFlight] = useState(false);
  const isShowNotPrivateAccount = order && order.accountId && account && account.type !== AccountType.PRIVATE;
  const classes = useStyles({ error });

  useEffect(() => {
    setIsFlight(true);
    if (customerId) {
      orderApi
        .getCustomerOrderStats(customerId)
        .then((res) => {
          setTotalOrders(res.totalOrders);
          setBookedJobs(res.bookedJobs);
          setTotalPaid(`$${formatCurrency(res.totalPaid, 2)}`);
          setError(false);
        })
        .catch(() => {
          enqueueSnackbar(`Can't get stats for the given customer`, { variant: 'error' });
          setError(true);
        })
        .then(() => {
          setIsFlight(false);
        });
    }
  }, [customerId]);

  const dispatch = useDispatch();

  const handleOpenHistoryPage = () => {
    setCurrentPage(OrderWindowPages.HISTORY);
  };

  const handleOpenAccountWindow = () => {
    if (account) {
      dispatch(openAccount(account.id));
    }
  };

  const totalOrdersView = (
    <Box p={1} maxWidth="160px">
      <HeaderSmallText gutterBottom>
        <b>Totals</b>
      </HeaderSmallText>
      <Grid container>
        <Grid item xs={7}>
          <Link onClick={handleOpenHistoryPage} underline="hover">
            <BodyText gutterBottom>
              <b>Total Orders:</b>
            </BodyText>
          </Link>
        </Grid>
        <Grid item xs={5}>
          <BodyText>
            <b>{totalOrders}</b>
          </BodyText>
        </Grid>
        <Grid item xs={7}>
          <BodyText gutterBottom>Booked Jobs:</BodyText>
        </Grid>
        <Grid item xs={5}>
          <BodyText>
            <b>{bookedJobs}</b>
          </BodyText>
        </Grid>
        <Grid item xs={7}>
          <BodyText>Total Paid:</BodyText>
        </Grid>
        <Grid item xs={5}>
          <BodyText>
            <b>{totalPaid}</b>
          </BodyText>
        </Grid>
      </Grid>
      {isShowNotPrivateAccount && (
        <Grid item xs={12}>
          <Box>
            <Link color="primary" underline="none" align="left" display="inline" onClick={handleOpenAccountWindow}>
              <BodyText>
                <b>{`ID: ${account.id}`}</b>
              </BodyText>
            </Link>
            <Box mt={0.5}>
              <BodySmallText>
                <b>{AccountTypeName[account.type]}&nbsp;Account</b>
              </BodySmallText>
            </Box>
          </Box>
        </Grid>
      )}
    </Box>
  );

  return (
    <Tooltip title={totalOrdersView} arrow enterTouchDelay={0} leaveDelay={1500} classes={{ tooltip: classes.tooltip }}>
      <Box component="span">
        <Button
          className={classes.colorButton}
          disabled={isFlight}
          startIcon={
            <>
              <Box display="flex">
                <InboxIcon fontSize="large" classes={{ fontSizeLarge: classes.inboxIcon }} />
              </Box>
            </>
          }
          variant="text"
        >
          <BodyBigText>
            <b>{totalOrders}</b>
          </BodyBigText>
          {isFlight && <ElromcoCircularProgress />}
        </Button>
      </Box>
    </Tooltip>
  );
};

CustomerStatsButton.propTypes = {
  customerId: pt.number,
  setCurrentPage: pt.func,
  account: pt.object,
  order: pt.instanceOf(Order).isRequired,
};

CustomerStatsButton.defaultProps = {
  customerId: null,
  setCurrentPage: null,
  account: null,
};
