import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import { CONTACT_INFO } from 'admin/constants/FieldNames';
import { EventProcessor } from './EventProcessor';

const PROPERTY_NAME = 'Contact Info';

const contactInfoChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath !== CONTACT_INFO && !oldOrder.contactInfo.equals(newOrder.contactInfo)) {
    return null;
  }
  const oldContactInfo = oldOrder.contactInfo;
  const newContactInfo = newOrder.contactInfo;

  const changes = [];

  const oldPrimaryPhone = oldContactInfo.primaryPhone.asString();
  const newPrimaryPhone = newContactInfo.primaryPhone.asString();

  if (oldPrimaryPhone !== newPrimaryPhone) {
    changes.push(createUpdatedValueDto(oldPrimaryPhone, newPrimaryPhone, `${PROPERTY_NAME} primary phone`));
  }

  const oldOtherPhone = oldContactInfo.otherPhone.asString();
  const newOtherPhone = newContactInfo.otherPhone.asString();

  if (oldOtherPhone !== newOtherPhone) {
    changes.push(createUpdatedValueDto(oldOtherPhone, newOtherPhone, `${PROPERTY_NAME} other phone`));
  }

  if (newContactInfo.email !== oldContactInfo.email) {
    changes.push(createUpdatedValueDto(oldContactInfo.email, newContactInfo.email, `${PROPERTY_NAME} email`));
  }

  return changes;
};

export default contactInfoChange;
