import { ReactNode, useEffect, useState } from 'react';

import { JobType } from '@elromcoinc/react-shared';

import { payrollApi } from 'admin/api';
import { PayrollContext } from 'admin/components/OrderWindow/context/PayrollContext';
import { useOrderServiceIndex } from 'admin/components/OrderWindow/context/useOrderServiceIndex';
import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';
import { JobPayrollDto } from 'common-types/payroll';

export const PayrollProvider = ({ children }: { children: ReactNode }) => {
  const [jobPayroll, setJobPayroll] = useState<JobPayrollDto>();
  const [deliveryJobPayroll, setDeliveryJobPayroll] = useState<JobPayrollDto>();
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const quote = order?.getServiceQuote(serviceIndex)!;

  const getPayrolls = () => {
    if (quote?.dispatchJob?.id) {
      payrollApi
        .getJobPayrolls(quote?.dispatchJob?.id)
        .then((response) => {
          setJobPayroll(response.find((it) => it.type === JobType.PICK_UP));
          setDeliveryJobPayroll(response.find((it) => it.type === JobType.DELIVERY));
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    getPayrolls();
  }, [quote?.dispatchJob?.id]);

  return (
    <PayrollContext.Provider value={{ jobPayroll, deliveryJobPayroll, getPayrolls }}>
      {children}
    </PayrollContext.Provider>
  );
};
