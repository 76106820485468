import React, { Dispatch, FC, ReactInstance, SetStateAction } from 'react';

import {
  BodyBigText,
  Button,
  IconButton,
  InvoiceEntity,
  InvoicesStatusTypeName,
  PaymentPermissions,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import { useSnackbar } from 'notistack';
import { useReactToPrint } from 'react-to-print';

import { InvoiceActionType } from 'common-types';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: palette.common.white,
    },
    actions: {
      textAlign: 'right',
      whiteSpace: 'nowrap',
      color: palette.common.white,
      '& button:first-child': {
        marginRight: spacing(1.5),
      },
    },
  }),
);

interface InvoiceModalTitleProps {
  invoice: InvoiceEntity;
  onClose: () => void;
  invoiceRef: { current: ReactInstance | null };
  invoiceActionType?: InvoiceActionType | null;
  setInvoiceActionType: Dispatch<SetStateAction<InvoiceActionType>>;
  setOpenPaymentModal: Dispatch<SetStateAction<boolean>>;
}

const InvoiceModalTitle: FC<InvoiceModalTitleProps> = ({
  invoice,
  onClose,
  invoiceRef,
  invoiceActionType,
  setInvoiceActionType,
  setOpenPaymentModal,
}) => {
  const classes = useStyles();
  const isNew = !invoice.id;
  const mode = isNew ? 'Create' : 'Edit';
  const { enqueueSnackbar } = useSnackbar();
  const canEditInvoice = useHasPermission(PaymentPermissions.PERM_CAN_EDIT_AN_INVOICE);
  const canTakePaymentOnAnInvoice = useHasPermission(PaymentPermissions.PERM_CAN_TAKE_A_PAYMENT_ON_AN_INVOICE);

  const handlePrint = useReactToPrint({
    content: () => (invoiceRef.current ? invoiceRef.current : null),
    removeAfterPrint: true,
  });

  const handleEditMode = () => {
    if (!canEditInvoice) {
      enqueueSnackbar('You have no permission to edit invoices', { variant: 'error' });
      return;
    }
    setInvoiceActionType(InvoiceActionType.EDIT);
  };

  const handleOpenPaymentModal = () => {
    setOpenPaymentModal(true);
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Box className={classes.title}>
      <BodyBigText>
        <b>{invoiceActionType === InvoiceActionType.VIEW ? 'Invoice' : `${mode} Invoice`}</b>
      </BodyBigText>
      <BodyBigText>
        <b>
          {isNew
            ? InvoicesStatusTypeName[invoice?.status as keyof typeof InvoicesStatusTypeName]
            : `Invoice ID: ${invoice?.number} - ${
                InvoicesStatusTypeName[invoice?.status as keyof typeof InvoicesStatusTypeName]
              }`}
        </b>
      </BodyBigText>
      <Box className={classes.actions}>
        {invoiceActionType === InvoiceActionType.VIEW && (
          <>
            <Tooltip title="Print">
              <Box component="span">
                <IconButton color="inherit" size="small" onClick={handlePrint}>
                  <PrintIcon />
                </IconButton>
              </Box>
            </Tooltip>
            {canTakePaymentOnAnInvoice && (
              <Tooltip title="Pay">
                <Box component="span">
                  <Button variant="outlined" size="small" rounded color="inherit" onClick={handleOpenPaymentModal}>
                    Pay
                  </Button>
                </Box>
              </Tooltip>
            )}
            <Tooltip title="Edit Invoice">
              <Box component="span">
                <Button variant="outlined" size="small" rounded color="inherit" onClick={handleEditMode}>
                  Edit
                </Button>
              </Box>
            </Tooltip>
          </>
        )}
        <Tooltip title="Close">
          <Box component="span">
            <IconButton color="inherit" onClick={handleCloseModal} size="small">
              <CloseIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export { InvoiceModalTitle };
