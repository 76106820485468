import React, { useState } from 'react';

import { ActivitySourceType, HeaderBigText, Modal, Order } from '@elromcoinc/react-shared';
import { Box, IconButton, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import pt from 'prop-types';

import { Messages as MessagesComponent } from 'admin/components/Communication/MessageTab/Messages';
import { useUnreadMessages } from 'admin/components/OrderWindow/context';
import { ComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail';
import Card from 'common/components/Card';
import { LabelFab } from 'common/components/Widgets';

const useStyles = makeStyles((theme) => ({
  messagesWrapper: {
    position: 'absolute',
    right: 0,
    left: 0,
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  messages: {
    height: '100%',
    position: 'relative',
    flexFlow: 'column',
  },
  messagesModal: {
    height: 'calc(100vh - 177px)',
    position: 'relative',
    flexFlow: 'column',
  },
  messagesContent: {
    height: '97%',
    minHeight: '400px',
    paddingTop: '15px',
    [theme.breakpoints.down('xs')]: {
      minHeight: '400px',
    },
  },
  height: {
    height: '100%',
  },
  flex: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    paddingLeft: '10px',
  },
  iconButton: {
    [theme.breakpoints.down('md')]: {
      padding: '8px',
    },
  },
}));

export const Messages = ({ sourceId, activitySource, order, account, disabled, ...rest }) => {
  const { reloadUnreadMessages, messageRef } = useUnreadMessages() || {};
  const [openModal, setOpenModal] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const handlerOpen = () => setOpenDialog(true);
  const handlerClose = () => setOpenDialog(false);

  const handleReadMessage = () => {
    setTimeout(() => {
      reloadUnreadMessages?.();
    }, 500);
  };

  return (
    <Card minWidth="290px" {...rest}>
      <Box display="flex" alignItems="center" ref={messageRef}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box mr={1}>
            <HeaderBigText>
              <b>Messages</b>
            </HeaderBigText>
          </Box>
          <LabelFab
            variant="extended"
            disableBoxShadow
            data-testid="composeEmail"
            disabled={disabled}
            onClick={() => setOpenModal('COMPOSE_EMAIL')}
            className="print-remove"
          >
            compose email
          </LabelFab>
        </Box>
        <IconButton
          onClick={handlerOpen}
          disabled={disabled}
          color="primary"
          classes={{ root: classes.iconButton }}
          data-testid="openMessagesModalButton"
          className="print-remove"
        >
          <OpenInNewIcon color={disabled ? 'disabled' : 'primary'} />
        </IconButton>
        <Modal onClose={handlerClose} title="Messages" open={openDialog} fullScreen={isMobile}>
          <Box className={classes.messagesContent}>
            <Box className={isMobile ? classes.messages : classes.messagesModal}>
              <Box className={classes.messagesWrapper}>
                {openDialog && (
                  <MessagesComponent
                    phoneNumber={
                      activitySource === ActivitySourceType.ORDER
                        ? order?.contactInfo?.primaryPhone?.number
                        : account?.ownerCustomerInfo?.primaryPhone?.number
                    }
                    smsCapability={
                      activitySource === ActivitySourceType.ORDER
                        ? order?.contactInfo?.primaryPhone?.smsCapability
                        : account?.ownerCustomerInfo?.primaryPhone?.smsCapability
                    }
                    orderNumber={order?.orderNumber}
                    order={order}
                    sourceId={sourceId}
                    sourceType={activitySource}
                    disabled={disabled}
                    onToggleReadMessage={handleReadMessage}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box className={classes.messagesContent}>
        <Box className={classes.messages}>
          <Box className={classes.messagesWrapper}>
            {!openDialog && (
              <MessagesComponent
                phoneNumber={
                  activitySource === ActivitySourceType.ORDER
                    ? order?.contactInfo?.primaryPhone?.number
                    : account?.ownerCustomerInfo?.primaryPhone?.number
                }
                smsCapability={
                  activitySource === ActivitySourceType.ORDER
                    ? order?.contactInfo?.primaryPhone?.smsCapability
                    : account?.ownerCustomerInfo?.primaryPhone?.smsCapability
                }
                order={order}
                orderNumber={order?.orderNumber}
                sourceId={sourceId}
                disabled={disabled}
                sourceType={activitySource}
                onToggleReadMessage={handleReadMessage}
              />
            )}
          </Box>
        </Box>
      </Box>
      <ComposeEmail
        onSave={() => setOpenModal(null)}
        onCancel={() => setOpenModal(null)}
        open={openModal === 'COMPOSE_EMAIL'}
        sourceId={sourceId}
        email={order?.contactInfo?.email}
      />
    </Card>
  );
};

Messages.propTypes = {
  sourceId: pt.number.isRequired,
  order: pt.instanceOf(Order),
  account: pt.object,
  disabled: pt.bool,
};

Messages.defaultProps = {
  order: null,
  account: null,
  disabled: false,
};
