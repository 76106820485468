import React, { ChangeEvent, FC, FormEventHandler, useEffect, useMemo, useState } from 'react';

import { GeneralServicesType } from '@elromcoinc/moveboard-setting-react';
import {
  BodyBigText,
  BodyText,
  Checkbox,
  CurrencyInput,
  EstimatedPackingTotalName,
  EstimatedPackingType,
  HeaderText,
  Modal,
  MoveType,
  Order,
  Product,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextInput,
  formatCurrency,
  isLongDistanceService,
  keyPressAllowedSymbolsRegex,
  onlyDecimalNumberInputFix,
  roundNumberToFixedDigits,
  uuid,
} from '@elromcoinc/react-shared';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { List } from 'immutable';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import { useOrderClosingContext, useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import { EstimatedPackingTypeNames } from 'admin/constants';
import { PackingSetting } from 'common-types';
import { ContentContainer, ContentTableContainer, TagTableContainer } from 'common/components/Widgets';
import { PackageCloseIcon, PackageOpenIcon } from 'common/components/icons';
import { currencyInputAdapter } from 'common/utils/currencyInputAdapter';

const errorMessage = 'Item is a required field';

interface FormError {
  [index: number]: string;
}

const useStyles = makeStyles(({ palette, spacing }) => ({
  blueTabs: {
    backgroundColor: palette.primary.main,
    marginBottom: spacing(2),
    '& .MuiButtonBase-root': {
      color: palette.background.paper,
      padding: spacing(1, 4),
      fontSize: spacing(2),
    },
  },
  materialsTable: {
    width: '100%',
  },
  packingTotalCost: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: spacing(1, 4),
    backgroundColor: palette.common.white,
    width: spacing(36),
  },
  table: {
    '&.MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
    '& table tbody tr:nth-child(even)': {
      backgroundColor: '#f5f5f6',
    },
    '& tbody > tr > td': {
      backgroundColor: 'initial !important',
    },
  },
  borderTopLeftCorner: {
    borderTopLeftRadius: spacing(0.75),
  },
  borderTopRightCorner: {
    borderTopRightRadius: spacing(0.75),
  },
}));

const TableCellButton = styled(Button)`
  && {
    text-transform: none;
    background-color: #f5f5f6;
    width: calc(100% - 8px);
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    justify-content: flex-start;
    text-align: left;
  }
`;

const RightBorderTableCell = styled(TableCell)`
  && {
    border-right: 1px solid white !important;
  }
`;

const TotalLabel = styled(BodyText)`
  && {
    width: 50px;
  }
`;

const HeaderTitleRow = styled(TableRow)`
  && {
    th:nth-child(1) {
      min-width: 150px;
    }
    th:nth-child(2) {
      min-width: 60px;
    }
    th:nth-child(3) {
      min-width: 90px;
    }
    th:nth-child(4) {
      min-width: 60px;
    }
    th:nth-child(5) {
      min-width: 60px;
    }
    th:nth-child(6) {
      min-width: 90px;
    }
    th:nth-child(7) {
      min-width: 60px;
    }
    th:nth-child(8) {
      min-width: 60px;
    }
    th:nth-child(9) {
      min-width: 90px;
    }
    th:nth-child(10) {
      min-width: 60px;
    }
  }
`;

interface CategoryTotals {
  quantity: number;
  price: number;
  total: number;
}
interface PackingRow {
  id: number | null;
  name: string;
  Boxes: CategoryTotals;
  Packing: CategoryTotals;
  Unpacking: CategoryTotals;
  lineTotal: number;
  estimatedPackingType?: EstimatedPackingType;
}

const quantityName = 'quantity';

enum PackingCategory {
  Unpacking = 'Unpacking',
  Packing = 'Packing',
  Boxes = 'Boxes',
}

const EmptyPackingRow: PackingRow = {
  id: null,
  name: '',
  Boxes: { quantity: 0, price: 0, total: 0 },
  Packing: { quantity: 0, price: 0, total: 0 },
  Unpacking: { quantity: 0, price: 0, total: 0 },
  lineTotal: 0,
};

const tabs = ['MATERIALS', 'PACKING SERVICE'];
const MATERIALS_TAB_INDEX = 0;

const packingTypeOptions = [
  [EstimatedPackingType.FULL, EstimatedPackingTypeNames[EstimatedPackingType.FULL]],
  [EstimatedPackingType.PARTIAL, EstimatedPackingTypeNames[EstimatedPackingType.PARTIAL]],
  [EstimatedPackingType.NONE, EstimatedPackingTypeNames[EstimatedPackingType.NONE]],
];

const packingPriceMap = {
  [EstimatedPackingType.FULL]: 'estimatedFullPackingCost',
  [EstimatedPackingType.PARTIAL]: 'estimatedPartialPackingCost',
} as const;

const customPackingName = 'Custom Packing';

const packingTypeId = -1;
const customPackingId = -2;

interface MaterialsProps {
  serviceType: GeneralServicesType;
  moveType: MoveType;
  open: boolean;
  onSave: (products: List<Product>) => void;
  onCancel: () => void;
  onChange: (value: { name: string; value: List<Product> | any }) => void;
  packingProducts: List<Product>;
}

const Materials: FC<MaterialsProps> = ({
  onSave,
  onCancel,
  onChange,
  open,
  moveType = MoveType.RESIDENTIAL,
  serviceType = GeneralServicesType.LD_INTERSTATE,
  packingProducts,
}) => {
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();
  const { isClosing, closingTitle, isCompleted, isLockSales } = useOrderClosingContext();
  const { PackingServiceType } = useOrderWindowSettings();
  const [tab, setTab] = useState(MATERIALS_TAB_INDEX);
  const [estimatedPackingType, setEstimatedPackingType] = useState<EstimatedPackingType>(order.estimatedPackingType);
  const [packingServicesByServiceTypeAndMoveType, setPackingServicesByServiceTypeAndMoveType] = useState<
    PackingSetting[]
  >([]);
  const [bodyTableData, setBodyTableData] = useState<PackingRow[]>([]);
  const [isLocalMove, setIsLocalMove] = useState(false);
  const [showPacking, setShowPacking] = useState(
    packingProducts.some((pp) => !!pp.quantity && !!pp.price && pp.description?.includes(PackingCategory.Packing)),
  );
  const [showUnpacking, setShowUnpacking] = useState(
    packingProducts.some((pp) => !!pp.quantity && !!pp.price && pp.description?.includes(PackingCategory.Unpacking)),
  );
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const [errorObj, setErrorObj] = useState<FormError>({});
  const handleChangeTab = (event: FormEventHandler<HTMLButtonElement>, index: number) => setTab(index);
  const estimatedPackingTypeColSpan = 10 - (+!showPacking && 3) - (+!showUnpacking && 3);

  useEffect(() => {
    if (packingProducts && packingProducts.size > 0) {
      const initialPackingProducts = packingProducts.reduce((acc, row) => {
        const [rowIndex, category] = row?.description?.split?.('-');

        return {
          ...acc,
          [rowIndex]: {
            ...(acc[rowIndex] || EmptyPackingRow),
            id: row.id,
            name: row.name,
            [category]: {
              ...row,
              price: row.price,
              quantity: +row.quantity || 0,
              total: (+row.quantity || 0) * +row.price,
            },
          },
        };
      }, {} as { [key: string]: PackingRow });

      const tempInitialBodyTableData = Object.values(initialPackingProducts) as PackingRow[];
      const initialBodyTableDataWithLineTotal = tempInitialBodyTableData.map((item) => ({
        ...item,
        lineTotal: item.Boxes.total + item.Packing.total + item.Unpacking.total,
      })) as PackingRow[];

      setBodyTableData(initialBodyTableDataWithLineTotal);
    }
  }, [packingProducts]);

  useEffect(() => {
    if (moveType && serviceType && PackingServiceType) {
      const packingData = PackingServiceType.filter((item) => {
        return item.moveType === moveType;
      });
      setShouldAutoFocus(true);
      setPackingServicesByServiceTypeAndMoveType([
        { id: customPackingId, name: customPackingName } as PackingSetting,
        ...packingData,
      ]);
      setIsLocalMove(!isLongDistanceService(serviceType));
    }
  }, [moveType, serviceType, PackingServiceType]);

  const addPackingRow = (item: PackingSetting) => () => {
    const unitCostPerServiceType = roundNumberToFixedDigits(
      isLocalMove ? item.localMoveCost || 0 : (item.longDistanceMoveCost || 0) + (item.laborRate || 0),
      2,
    );
    const isExisting = bodyTableData.find((row) => row.id === item.id && row.name === item.name);

    const newBodyTableData = isExisting
      ? bodyTableData.map((row) =>
          row.id === item.id && row.name === item.name
            ? {
                ...row,
                Boxes: {
                  ...row.Boxes,
                  quantity: (+row.Boxes.quantity || 0) + 1,
                  total: roundNumberToFixedDigits(((+row.Boxes.quantity || 0) + 1) * row.Boxes.price, 2),
                },
                lineTotal: row.lineTotal + +row.Boxes.price,
              }
            : row,
        )
      : [
          ...bodyTableData,
          {
            id: item.id,
            name: item.name === customPackingName ? '' : item.name,
            Boxes: {
              quantity: 1,
              price: unitCostPerServiceType,
              total: unitCostPerServiceType,
            },
            Packing: { quantity: 0, price: 0, total: 0 },
            Unpacking: { quantity: 0, price: 0, total: 0 },
            lineTotal: unitCostPerServiceType,
          },
        ];

    setBodyTableData(newBodyTableData as PackingRow[]);
  };

  const changePackingDataHandler =
    (index: number, category?: PackingCategory) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event;
      if (name === 'id') {
        setBodyTableData(bodyTableData.map((item, ix) => (ix === index ? { ...item, name: value } : item)));
      } else {
        const quantityValue = +value < 0 ? 0 : value;
        const newValue = name === quantityName ? quantityValue : value;

        const modifiedRowCategoryData = {
          ...(bodyTableData?.[index]?.[category!] as unknown as CategoryTotals),
          [name]: newValue,
        };
        const subTotal = (+modifiedRowCategoryData.quantity || 0) * (modifiedRowCategoryData.price || 0);
        const tempRow = {
          ...bodyTableData[index],
          [category as string]: {
            ...modifiedRowCategoryData,
            total: subTotal,
          },
        };
        const newRow = { ...tempRow, lineTotal: tempRow.Boxes.total + tempRow.Packing.total + tempRow.Unpacking.total };
        const newBodyTableData = bodyTableData.map((item, ix) => (ix === index ? newRow : item));
        setBodyTableData(newBodyTableData);
      }
    };

  const deletePackingRow = (index: number, item: PackingRow) => () => {
    if (item.estimatedPackingType) {
      setEstimatedPackingType(EstimatedPackingType.NONE);
      return;
    }

    const newBodyTableData = bodyTableData.filter((item, ix) => index !== ix);
    setBodyTableData(newBodyTableData);
  };

  function getFormattedData() {
    const formattedData =
      (bodyTableData &&
        bodyTableData.length > 0 &&
        bodyTableData.reduce(
          (totalArr, row) => [
            ...totalArr,
            ...[PackingCategory.Boxes, PackingCategory.Packing, PackingCategory.Unpacking].reduce(
              (acc, category) => [
                ...acc,
                new Product({
                  description: `${totalArr.length}-${category}`,
                  id: row.id as number,
                  price: +row[category].price || 0,
                  quantity: +row[category].quantity || 0,
                  name: row.name,
                }),
              ],
              [] as Product[],
            ),
          ],
          [] as Product[],
        )) ||
      null;
    return List(formattedData || []);
  }

  const validationCustomPacking = () => {
    if (getFormattedData().some((item) => item.toJS()?.name === '')) {
      setErrorObj(
        packings.reduce((acc, item, index) => ({ ...acc, [index]: item.name === '' ? errorMessage : null }), {}),
      );

      return false;
    }
    setErrorObj({});
    return true;
  };

  const saveHandler = () => {
    if (validationCustomPacking()) {
      onSave(getFormattedData());
      if (order.estimatedPackingType !== estimatedPackingType && !isClosing) {
        onChange({ name: 'estimatedPackingType', value: estimatedPackingType });
      }
    }
  };

  const handleChangeEstimatedPacking = (event: ChangeEvent<HTMLInputElement>) =>
    setEstimatedPackingType(event.target.value as EstimatedPackingType);

  const packings = [...bodyTableData];

  if (order.estimatedPackingAvailable && !isClosing) {
    const packingTotal =
      estimatedPackingType !== EstimatedPackingType.NONE ? order.get(packingPriceMap?.[estimatedPackingType]) || 0 : 0;

    packings.push({
      estimatedPackingType,
      id: packingTypeId,
      name: EstimatedPackingTypeNames[estimatedPackingType],
      Boxes: { quantity: 1, price: packingTotal, total: packingTotal },
      Packing: { quantity: 0, price: 0, total: 0 },
      Unpacking: { quantity: 0, price: 0, total: 0 },
      lineTotal: packingTotal,
    });
  }

  const rowKeys = useMemo(() => packings.map(uuid) ?? [], [packings?.length]);
  const grandTotal = packings.reduce((sum, item) => sum + item.lineTotal, 0);

  const onChangePackingCategory = (packingCategory: PackingCategory) => (_: any, checked: boolean) => {
    if (!checked) {
      setBodyTableData((state) =>
        state.map((p) => {
          const result = {
            ...p,
            [packingCategory]: {
              ...p[packingCategory],
              quantity: 0,
              total: 0,
            },
          };

          result.lineTotal = result.Boxes.total + result.Packing.total + result.Unpacking.total;

          return result;
        }),
      );
    }

    if (packingCategory === PackingCategory.Packing) {
      setShowPacking(checked);
    }

    if (packingCategory === PackingCategory.Unpacking) {
      setShowUnpacking(checked);
    }
  };

  return (
    <Modal
      open={open}
      title={`Materials ${closingTitle}`}
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: saveHandler, disabled: false },
      ]}
      color="grey"
      fullWidth
      maxWidth="lg"
    >
      <Box className={clsx(classes.blueTabs, 'applyRadius')}>
        <Tabs indicatorColor="secondary" value={tab} onChange={handleChangeTab as any}>
          {tabs.map((tab, index) => (
            <Tab
              key={tab}
              value={index}
              label={
                index > 0 ? (
                  <Box display="flex">
                    {tab}
                    {!isClosing && estimatedPackingType === EstimatedPackingType.PARTIAL && <PackageOpenIcon />}
                    {!isClosing && estimatedPackingType === EstimatedPackingType.FULL && <PackageCloseIcon />}
                  </Box>
                ) : (
                  tab
                )
              }
              disabled={index > 0 && (!order.estimatedPackingAvailable || isClosing)}
            />
          ))}
        </Tabs>
      </Box>
      <SwipeableViews index={tab} onChangeIndex={setTab as any} disabled={!order.estimatedPackingAvailable}>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Checkbox
              color="primary"
              label="Packing"
              checked={showPacking}
              onChange={onChangePackingCategory(PackingCategory.Packing)}
              disabled={isCompleted || isLockSales}
            />
            <Checkbox
              color="primary"
              label="Unpacking"
              checked={showUnpacking}
              onChange={onChangePackingCategory(PackingCategory.Unpacking)}
              disabled={isCompleted || isLockSales}
            />
          </Box>
          <Box display="flex">
            <TagTableContainer component={Paper} className="applyRadius" elevation={4}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className="applyTopRadius" colSpan={3}>
                      <BodyBigText>Click to Add</BodyBigText>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packingServicesByServiceTypeAndMoveType &&
                    packingServicesByServiceTypeAndMoveType.map((item) => (
                      <TableRow key={item.name}>
                        <TableCell>
                          <TableCellButton disabled={isCompleted || isLockSales} onClick={addPackingRow(item)}>
                            {item.name}
                          </TableCellButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TagTableContainer>
            <ContentContainer display="flex" flexDirection="column" ml={1} className={classes.materialsTable}>
              <ContentTableContainer component={Paper} elevation={4} className={classes.table}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <RightBorderTableCell className={classes.borderTopLeftCorner} colSpan={4}>
                        <HeaderText>Boxes</HeaderText>
                      </RightBorderTableCell>
                      {showPacking && (
                        <RightBorderTableCell colSpan={3}>
                          <HeaderText>Packing</HeaderText>
                        </RightBorderTableCell>
                      )}
                      {showUnpacking && (
                        <RightBorderTableCell colSpan={3}>
                          <HeaderText>Unpacking</HeaderText>
                        </RightBorderTableCell>
                      )}
                      <TableCell colSpan={1} />
                      <TableCell className={classes.borderTopRightCorner} colSpan={1} />
                    </TableRow>
                    <HeaderTitleRow>
                      <TableCell>
                        <BodyBigText>Item</BodyBigText>
                      </TableCell>
                      <TableCell>
                        <BodyBigText>Qty</BodyBigText>
                      </TableCell>
                      <TableCell>
                        <BodyBigText>Unit Cost</BodyBigText>
                      </TableCell>
                      <RightBorderTableCell>
                        <BodyBigText>Total</BodyBigText>
                      </RightBorderTableCell>
                      {showPacking && (
                        <>
                          <TableCell>
                            <BodyBigText>Qty</BodyBigText>
                          </TableCell>
                          <TableCell>
                            <BodyBigText>Unit Cost</BodyBigText>
                          </TableCell>
                          <RightBorderTableCell>
                            <BodyBigText>Total</BodyBigText>
                          </RightBorderTableCell>
                        </>
                      )}
                      {showUnpacking && (
                        <>
                          <TableCell>
                            <BodyBigText>Qty</BodyBigText>
                          </TableCell>
                          <TableCell>
                            <BodyBigText>Unit Cost</BodyBigText>
                          </TableCell>
                          <RightBorderTableCell>
                            <BodyBigText>Total</BodyBigText>
                          </RightBorderTableCell>
                        </>
                      )}
                      <TableCell>
                        <BodyBigText>Line Total</BodyBigText>
                      </TableCell>
                      <TableCell />
                    </HeaderTitleRow>
                  </TableHead>
                  <TableBody>
                    {packings &&
                      packings.map((item, index) => {
                        return (
                          <TableRow key={rowKeys[index]}>
                            <TableCell colSpan={item.estimatedPackingType ? estimatedPackingTypeColSpan : 1}>
                              <Box width={150}>
                                <TextInput
                                  size="small"
                                  hiddenLabel
                                  fullWidth
                                  formError={errorObj[index]}
                                  autoFocus={shouldAutoFocus && item.id === customPackingId}
                                  name="id"
                                  value={item.name}
                                  disabled={isCompleted || isLockSales}
                                  InputProps={{ readOnly: !!item.estimatedPackingType }}
                                  onChange={changePackingDataHandler(index)}
                                />
                              </Box>
                            </TableCell>
                            {!item.estimatedPackingType && (
                              <>
                                <TableCell>
                                  <Box width={65}>
                                    <TextInput
                                      size="small"
                                      type="number"
                                      hiddenLabel
                                      fullWidth
                                      value={item.Boxes.quantity}
                                      name={quantityName}
                                      min={0}
                                      disabled={isCompleted || isLockSales}
                                      onChange={changePackingDataHandler(index, PackingCategory.Boxes)}
                                      onKeyPress={keyPressAllowedSymbolsRegex(/^\d$/)}
                                      {...onlyDecimalNumberInputFix}
                                    />
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <Box width={85}>
                                    <CurrencyInput
                                      size="small"
                                      hiddenLabel
                                      fullWidth
                                      name="price"
                                      disabled={isCompleted || isLockSales}
                                      value={item.Boxes.price}
                                      onChange={currencyInputAdapter(
                                        changePackingDataHandler(index, PackingCategory.Boxes),
                                      )}
                                    />
                                  </Box>
                                </TableCell>
                                <TableCell>
                                  <TotalLabel>{`$${formatCurrency(item.Boxes.total)}`}</TotalLabel>
                                </TableCell>
                                {showPacking && (
                                  <>
                                    <TableCell>
                                      <Box width={65}>
                                        <TextInput
                                          size="small"
                                          type="number"
                                          hiddenLabel
                                          fullWidth
                                          value={item.Packing.quantity}
                                          name={quantityName}
                                          min={0}
                                          disabled={isCompleted || isLockSales}
                                          onChange={changePackingDataHandler(index, PackingCategory.Packing)}
                                          onKeyPress={keyPressAllowedSymbolsRegex(/^\d$/)}
                                          {...onlyDecimalNumberInputFix}
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box width={85}>
                                        <CurrencyInput
                                          size="small"
                                          hiddenLabel
                                          fullWidth
                                          value={item.Packing.price}
                                          name="price"
                                          disabled={isCompleted || isLockSales}
                                          onChange={currencyInputAdapter(
                                            changePackingDataHandler(index, PackingCategory.Packing),
                                          )}
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <TotalLabel>{`$${formatCurrency(item.Packing.total)}`}</TotalLabel>
                                    </TableCell>
                                  </>
                                )}
                                {showUnpacking && (
                                  <>
                                    <TableCell>
                                      <Box width={65}>
                                        <TextInput
                                          size="small"
                                          type="number"
                                          hiddenLabel
                                          fullWidth
                                          value={item.Unpacking.quantity}
                                          name={quantityName}
                                          min={0}
                                          disabled={isCompleted || isLockSales}
                                          onChange={changePackingDataHandler(index, PackingCategory.Unpacking)}
                                          onKeyPress={keyPressAllowedSymbolsRegex(/^\d$/)}
                                          {...onlyDecimalNumberInputFix}
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <Box width={85}>
                                        <CurrencyInput
                                          size="small"
                                          hiddenLabel
                                          fullWidth
                                          name="price"
                                          disabled={isCompleted || isLockSales}
                                          value={item.Unpacking.price}
                                          onChange={currencyInputAdapter(
                                            changePackingDataHandler(index, PackingCategory.Unpacking),
                                          )}
                                        />
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                      <TotalLabel>{`$${formatCurrency(item.Unpacking.total)}`}</TotalLabel>
                                    </TableCell>
                                  </>
                                )}
                              </>
                            )}
                            <TableCell>
                              {item.estimatedPackingType !== EstimatedPackingType.NONE && (
                                <BodyText>{`$${formatCurrency(item.lineTotal)}`}</BodyText>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.estimatedPackingType !== EstimatedPackingType.NONE && (
                                <IconButton
                                  disabled={isCompleted || isLockSales}
                                  size="small"
                                  onClick={deletePackingRow(index, item)}
                                >
                                  <DeleteIcon fontSize="default" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </ContentTableContainer>
              {packings && packings.length > 0 && (
                <Paper square elevation={4} className="totalTag applyBottomRadius">
                  <BodyText>
                    <b>Total</b>
                  </BodyText>
                  <BodyText>{`$${formatCurrency(grandTotal)}`}</BodyText>
                </Paper>
              )}
            </ContentContainer>
          </Box>
        </Box>
        <Box px={1}>
          <Grid container spacing={1}>
            <Grid item>
              <Box pt={1}>
                <BodyBigText>
                  <b>Packing Type:</b>
                </BodyBigText>
              </Box>
            </Grid>
            <Grid item>
              <RadioGroup value={estimatedPackingType} onChange={handleChangeEstimatedPacking}>
                {packingTypeOptions.map(([packingKey, label]) => (
                  <Radio
                    disabled={isCompleted || isLockSales}
                    key={packingKey}
                    label={label}
                    value={packingKey}
                    color="primary"
                  />
                ))}
              </RadioGroup>
              {estimatedPackingType !== EstimatedPackingType.NONE && (
                <Box className={classes.packingTotalCost}>
                  <BodyBigText>
                    <b>{EstimatedPackingTotalName[estimatedPackingType]}:&nbsp;</b>
                  </BodyBigText>
                  <BodyBigText>{`$${formatCurrency(
                    order?.[packingPriceMap?.[estimatedPackingType]] || 0!,
                  )}`}</BodyBigText>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </SwipeableViews>
    </Modal>
  );
};

export default Materials;
