import { OrderPayrollTotals } from 'admin/components/OrderWindow/modals/Payroll/OrderPayrollTotals';
import { PositionTypes } from 'admin/constants/PositionTypes';
import { EmployeeCommission, EmployeeCompensationSource } from 'common-types';
import { EmployeeCommissionType, EmployeeJobPayrollCompensationDto, PaymentSourceType } from 'common-types/payroll';

const toPaymentSource = (type: EmployeeCompensationSource | null): PaymentSourceType | null => {
  if (type === EmployeeCompensationSource.CLOSING) {
    return PaymentSourceType.CLOSING;
  }

  if (type === EmployeeCompensationSource.ESTIMATED) {
    return PaymentSourceType.ESTIMATED;
  }

  return null;
};

interface Options {
  positionType?: PositionTypes;
  manualAddCommission?: boolean;
  hasHourlyRateAsHelper?: boolean;
}

export const getCommission = (
  commission: EmployeeCommission,
  totals: OrderPayrollTotals,
  { positionType, manualAddCommission = false, hasHourlyRateAsHelper = false }: Options = {},
): EmployeeJobPayrollCompensationDto | null => {
  if (
    commission.commissionType === EmployeeCommissionType.HOURLY_RATE &&
    ((positionType !== PositionTypes.HELPER && positionType !== PositionTypes.DRIVER) || !hasHourlyRateAsHelper)
  ) {
    const amount = commission.rate.amount;
    const value = totals.numberOfHours ?? 0;

    return {
      type: commission.commissionType,
      rate: { amount: value, type: commission.rate.type },
      value: amount,
      total: value * amount,
    };
  }

  if (
    (commission.commissionType === EmployeeCommissionType.HOURLY_RATE_AS_HELPER ||
      (manualAddCommission && commission.commissionType === EmployeeCommissionType.HOURLY_RATE)) &&
    (positionType === PositionTypes.HELPER || positionType === PositionTypes.DRIVER)
  ) {
    const amount = commission.rate.amount;
    const value = totals.numberOfHours ?? 0;

    return {
      type: EmployeeCommissionType.HOURLY_RATE,
      rate: { amount: value, type: commission.rate.type },
      value: amount,
      total: value * amount,
    };
  }

  if (
    (positionType === PositionTypes.FOREMAN || manualAddCommission) &&
    commission.commissionType === EmployeeCommissionType.PACKING_COMMISSION
  ) {
    const source = toPaymentSource(commission.commissionSource);
    const value = source === PaymentSourceType.ESTIMATED ? totals.salesPackingTotal : totals.closingPackingTotal;

    return {
      type: commission.commissionType,
      rate: { amount: commission.rate.amount, type: commission.rate.type },
      source,
      value: value,
      total: (value * commission.rate.amount) / 100,
    };
  }

  if (commission.commissionType === EmployeeCommissionType.ADDITIONAL_SERVICES_COMMISSION) {
    const source = toPaymentSource(commission.commissionSource);
    const value =
      source === PaymentSourceType.ESTIMATED
        ? totals.salesAdditionalServicesTotal
        : totals.closingAdditionalServicesTotal;

    return {
      type: commission.commissionType,
      rate: { amount: commission.rate.amount, type: commission.rate.type },
      source,
      value: value,
      total: (value * commission.rate.amount) / 100,
    };
  }

  if (commission.commissionType === EmployeeCommissionType.COMMISSION_FROM_THE_TOTAL) {
    const source = toPaymentSource(commission.commissionSource);
    const value = source === PaymentSourceType.ESTIMATED ? totals.salesTotal : totals.closingTotal;

    return {
      type: commission.commissionType,
      rate: { amount: commission.rate.amount, type: commission.rate.type },
      source: toPaymentSource(commission.commissionSource),
      value: value,
      total: (value * commission.rate.amount) / 100,
    };
  }

  if (commission.commissionType === EmployeeCommissionType.PER_JOB_BONUS) {
    return {
      type: commission.commissionType,
      rate: { amount: 1, type: commission.rate.type },
      value: commission.rate.amount,
      total: commission.rate.amount,
    };
  }

  return null;
};
