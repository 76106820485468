import { ActivitySourceDescriptor, ActivitySourceType } from '@elromcoinc/react-shared';
import { getHours, getMinutes, parseISO, set, sub } from 'date-fns';
import { Record } from 'immutable';

import { TaskDTO } from 'common-types/Task/TaskDTO';
import { TaskPriority } from 'common-types/Task/TaskPriority';
import { TaskStatus } from 'common-types/Task/TaskStatus';
import { TaskType } from 'common-types/Task/TaskType';
import { getUTCDateAsString } from 'common/utils';

const FIVE_MINUTES = 60 * 5;

export class Task extends Record<TaskDTO>({
  id: null,
  sourceId: null,
  orderNumber: '',
  customerName: '',
  employeeId: 0,
  subject: '',
  dueDate: null,
  dueTime: null,
  due: null,
  remindMe: true,
  remindSecondsBeforeDue: FIVE_MINUTES,
  priority: TaskPriority.NORMAL,
  notes: '',
  status: TaskStatus.UNREAD,
  isCompleted: false,
  isRead: false,
  snoozedUntil: null,
  remindTime: null,
  activitySource: ActivitySourceType.ORDER,
  type: null,
  taskType: TaskType.CUSTOM,
  dateCreated: '',
  dateUpdated: '',
  completed: null,
  completedDate: null,

  // activity source fields
  activitySources: [new ActivitySourceDescriptor()],
  branchId: 1,
  createdByName: '',
  deleted: false,
  employeeName: '',
  involvesCustomer: false,
  involvesDispatch: false,
  involvesForeman: false,
  involvesSales: false,
  lastUpdatedByName: '',
  message: '',
}) {
  constructor(data: Partial<TaskDTO> = {}) {
    let { dueDate = null, dueTime = null } = data;
    const { completed = null } = data;
    const { due, status = TaskStatus.UNREAD, remindSecondsBeforeDue, snoozedUntil } = data;

    if (due && typeof due === 'string') {
      const dueInUTC = getUTCDateAsString(due);
      dueDate = parseISO(dueInUTC);
      dueTime = parseISO(dueInUTC);
    }

    let completedDate = null;

    if (completed && typeof completed === 'string') {
      completedDate = parseISO(getUTCDateAsString(completed));
    }

    const isCompleted = status === TaskStatus.COMPLETED;
    const isRead = status === TaskStatus.READ;
    const remindTime = dueTime ? sub(dueTime, { seconds: remindSecondsBeforeDue }) : null;
    const parsedSnoozedUntil = typeof snoozedUntil === 'string' ? parseISO(snoozedUntil) : snoozedUntil;

    super({
      ...data,
      dueDate,
      dueTime,
      completedDate,
      isCompleted,
      isRead,
      remindTime,
      snoozedUntil: parsedSnoozedUntil,
      activitySources: data?.activitySources?.map(
        (activitySource) => new ActivitySourceDescriptor(activitySource) ?? [],
      ),
    });
  }

  setStatus(status: TaskStatus) {
    const isCompleted = status === TaskStatus.COMPLETED;
    const isRead = status === TaskStatus.READ;

    let updatedTask = this.set('status', status).set('isCompleted', isCompleted).set('isRead', isRead);

    if (isCompleted) {
      updatedTask = updatedTask.set('completed', new Date().toISOString()).set('completedDate', new Date());
    }

    return updatedTask;
  }

  toDTO() {
    const { due: mainDue, dueDate, dueTime, isCompleted, isRead, snoozedUntil, ...rest } = this.toJS() as TaskDTO;
    const time = (dueTime || mainDue) as Date;
    const hours = getHours(time);
    const minutes = getMinutes(time);
    const date = (dueDate || mainDue) as Date;
    const due = set(date, { hours, minutes, seconds: 0, milliseconds: 0 }).toISOString();
    const ifNotCreatedAndNoSourceID = rest.id === null && rest.sourceId === null;

    if (ifNotCreatedAndNoSourceID) {
      rest.activitySource = ActivitySourceType.EMPLOYEE;
      rest.sourceId = rest.employeeId;
      rest.activitySources = [
        new ActivitySourceDescriptor({ activitySource: rest.activitySource, referencedEntityId: rest.sourceId }),
      ];
    }

    return {
      ...rest,
      due,
      snoozedUntil: snoozedUntil instanceof Date ? snoozedUntil.toISOString() : snoozedUntil,
    } as TaskDTO;
  }

  getRemindDate() {
    const { dueTime } = this.toJS() as TaskDTO;
    return dueTime;
  }
}
