import axios from 'axios';

class LongDistanceTariffAPI {
  createTariff(data) {
    return axios.post('/api/settings/ld/tariff/', data);
  }

  getStates() {
    return axios.get('/api/settings/ld/tariff/region/states');
  }

  getRegionsByStateIdAndMoveType(stateId, moveType) {
    return axios.get(`/api/settings/ld/tariff/region/state/${stateId}/moveType/${moveType}`);
  }

  getRegionsByZoneIdAndMoveType(zoneId, moveType) {
    return axios.get(`/api/settings/ld/tariff/region/zone/${zoneId}/moveType/${moveType}`);
  }

  getActiveDiscountType(branchId, moveType) {
    return axios.get(`/api/settings/ld/region/discounts/type/branch/${branchId}/moveType/${moveType}`);
  }

  setActiveDiscountType(branchId, moveType, discountType) {
    return axios.put(`/api/settings/ld/region/discounts/type/${discountType}/branch/${branchId}/moveType/${moveType}`);
  }

  getDiscountConfigurationOptions(branchId, moveType) {
    return axios.get(`/api/settings/ld/region/discounts/options/branch/${branchId}/moveType/${moveType}`);
  }

  setLongDistanceThresholds(branchId, moveType, thresholds) {
    return axios.put(`/api/settings/ld/region/discounts/options/branch/${branchId}/moveType/${moveType}`, thresholds);
  }

  getExtraChargesConfig(branchId, moveType) {
    return axios.get(`/api/settings/ld/branch/${branchId}/moveType/${moveType}/extraChargesConfig`);
  }

  createRegion(data) {
    return axios.post('/api/settings/ld/tariff/region', data);
  }

  updateRegion(data) {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    return {
      promise: axios.put('/api/settings/ld/tariff/region', data, {
        cancelToken: source.token,
      }),
      cancel(message) {
        source.cancel(message);
      },
    };
  }

  getZones(moveType) {
    return axios.get(`/api/settings/ld/tariff/region/zones/moveType/${moveType}`);
  }

  retrieveAreaCodesForStateCode(stateCode) {
    return axios.get(`/api/settings/ld/tariff/region/state/${stateCode}/areaCodes`);
  }

  saveZone(zone) {
    return axios.post('/api/settings/ld/tariff/region/zone', zone);
  }

  updateZone(zone) {
    return axios.put('/api/settings/ld/tariff/region/zone', zone);
  }

  deleteZone(id) {
    return axios.delete(`/api/settings/ld/tariff/region/zone/${id}`);
  }

  getPeriods(branchId, moveType) {
    return axios.get(`/api/settings/ld/tariff/periods/branch/${branchId}/moveType/${moveType}`);
  }

  savePeriod(data) {
    return axios.post(`/api/settings/ld/tariff/period`, data);
  }

  savePeriodFrom(data) {
    return axios.post(`/api/settings/ld/tariff/period/child`, data);
  }

  updatePeriod(data) {
    return axios.put(`/api/settings/ld/tariff/period`, data);
  }

  deletePeriod(id) {
    return axios.delete(`/api/settings/ld/tariff/period/${id}`);
  }

  copyPricingToOtherMoveTypes(moveType) {
    return axios.put(`/api/settings/ld/tariff/region/moveType/${moveType}/all`);
  }

  checkLongDistancePrice({ size = 100, fromZip, toZip, moveDate, moveType }) {
    return axios.get(
      `/api/settings/ld/tariff/price/size/${size}/fromZip/${fromZip}/toZip/${toZip}/moveDate/${moveDate}/moveType/${moveType}`,
    );
  }
}

export default new LongDistanceTariffAPI();
