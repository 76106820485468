import React, { useEffect, useState } from 'react';

import { Switch } from '@elromcoinc/react-shared';
import { Box, FormControlLabel, IconButton, Paper, Tooltip, makeStyles } from '@material-ui/core';
import Arrow from '@material-ui/icons/ArrowRightAlt';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { set, setIn } from 'immutable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import smsAPI from 'admin/api/SmsAPI';
import {
  fetchSmsSettings,
  getSmsSettings,
  getTwilioAccount,
  setSmsSettings,
  setTwilioAccount,
  updateIsDisabledSms,
} from 'admin/autodux/MessageAutodux';
import {
  Balance,
  BuySms,
  ForwardingSettings,
  SavedCards,
  SmsPhoneNumber,
} from 'admin/components/Settings/components/SmsSettings/blocks';
import useSettingsApi from 'admin/components/Settings/hooks/useSettingsApi';
import SettingName from 'admin/constants/SettingName';
import { SmsSettingsAvailabilityTypeName } from 'admin/constants/SmsSettingsAvailabilityType';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '2rem 1.5rem',
    padding: '1rem',
  },
  arrowIcon: {
    position: 'relative',
    top: '30px',
    padding: '0 6px',
  },
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  updateIcon: {
    padding: '9px',
  },
}));

const { SMS_ENABLE } = SettingName;

const settingsQuery = [SMS_ENABLE];

const SmsSettings = () => {
  const classes = useStyles();
  const [isCheckedEnableSms, setIsCheckedEnableSms] = useState(false);
  const [settings] = useSettingsApi(settingsQuery);
  const [isDisabledEnabledSms, setIsDisabledEnabledSms] = useState(false);
  const [isLoadingTwilioAccount, setIsLoadingTwilioAccount] = useState(false);
  const [allStripeCustomers, setAllStripeCustomers] = useState(null);
  const [companyUsageInfo, setCompanyUsageInfo] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const allSmsSettings = useSelector(getSmsSettings);
  const twilioAccount = useSelector(getTwilioAccount);
  const errorMessageOptions = { variant: 'error' };
  const branchId = 1;

  useEffect(() => {
    if (settings) {
      setIsCheckedEnableSms(settings[SMS_ENABLE]);
      setIsDisabledEnabledSms(settings[SMS_ENABLE]);
    }
  }, [settings]);

  const fetchSmsInformation = () => {
    setIsLoadingTwilioAccount(true);
    dispatch(fetchSmsSettings())
      .then(() => smsAPI.getUsageInfo())
      .catch(() => {
        enqueueSnackbar(`Can't get info about company usage Twilio services for this month`, errorMessageOptions);
      })
      .then((res) => {
        if (res) {
          setCompanyUsageInfo(res);
        }
        setIsLoadingTwilioAccount(false);
      })
      .then(() => smsAPI.getAllRegisteredStripeCustomers(branchId))
      .then((res) => {
        setAllStripeCustomers(res);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get all registered customers`, errorMessageOptions);
      });
  };

  useEffect(() => {
    fetchSmsInformation();
  }, []);

  const handleBuyPhoneNumber = (phone) => {
    const updateTwilioPhoneNumber = setIn(
      twilioAccount,
      ['twilioPhoneNumbers'],
      [...twilioAccount.twilioPhoneNumbers, phone],
    );

    const updateBalance = setIn(
      updateTwilioPhoneNumber,
      ['smsAccount', 'balance'],
      twilioAccount.smsAccount.balance - 1,
    );

    dispatch(setTwilioAccount(updateBalance));
    dispatch(updateIsDisabledSms());
    setCompanyUsageInfo((state) => setIn(state, ['phoneNumbersPrice'], state.phoneNumbersPrice + 1));
  };

  const handleDeletePhoneNumber = (sid) => {
    dispatch(
      setTwilioAccount({
        ...twilioAccount,
        twilioPhoneNumbers: (twilioAccount.twilioPhoneNumbers || []).filter((item) => item.sid !== sid),
      }),
    );
    dispatch(updateIsDisabledSms());
  };

  const handleSavePaymentMethod = (paymentCard, balance) => {
    setAllStripeCustomers((state) => setIn(state, [0, 'paymentMethods'], paymentCard.paymentMethods));
    dispatch(
      setTwilioAccount(setIn(twilioAccount, ['smsAccount', 'balance'], twilioAccount.smsAccount.balance + balance)),
    );

    setTimeout(() => {
      fetchSmsInformation();
    }, 1000);
  };

  const handleToggleSwitch = (event) => {
    const targetChecked = event.target.checked;
    setIsDisabledEnabledSms(targetChecked);
    setIsCheckedEnableSms(targetChecked);
    setIsLoadingTwilioAccount(true);
    smsAPI
      .setEnableSms({ enable: targetChecked })
      .then(() => {
        dispatch(setSmsSettings(set(allSmsSettings, SmsSettingsAvailabilityTypeName.SMS_ENABLE, targetChecked)));
        dispatch(updateIsDisabledSms());
      })
      .catch(() => {
        enqueueSnackbar(`SMS settings can't be turned on/turned off`, errorMessageOptions);
      })
      .then(() => setIsLoadingTwilioAccount(false));
  };

  const handleUpdateInformation = () => {
    fetchSmsInformation();
  };

  return (
    <Paper square className="applyRadius" elevation={2} classes={{ root: classes.root }}>
      <Box display="flex" justifyContent="space-between">
        <FormControlLabel
          control={
            <>
              <Switch
                color="primary"
                disabled={isLoadingTwilioAccount}
                checked={isCheckedEnableSms}
                onChange={handleToggleSwitch}
              />
            </>
          }
          label="Enable SMS"
        />
        <Box>
          <Tooltip title="Update information">
            <IconButton color="primary" classes={{ root: classes.updateIcon }} onClick={handleUpdateInformation}>
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box className={classes.cardsWrapper}>
        <Box>
          <BuySms
            companyUsageInfo={companyUsageInfo || {}}
            isDisabledEnabledSms={!isDisabledEnabledSms}
            twilioPhoneNumbers={twilioAccount ? twilioAccount.twilioPhoneNumbers : []}
            isLoadingTwilioAccount={isLoadingTwilioAccount}
            allStripeCustomers={allStripeCustomers || []}
            onSavaPaymentMethod={handleSavePaymentMethod}
            allSmsSettings={allSmsSettings}
            onBuyPhoneNumber={handleBuyPhoneNumber}
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" mb="15px">
            <SmsPhoneNumber
              isDisabledEnabledSms={!isDisabledEnabledSms}
              twilioPhoneNumbers={twilioAccount ? twilioAccount.twilioPhoneNumbers : []}
              isLoadingTwilioAccount={isLoadingTwilioAccount}
              allStripeCustomers={allStripeCustomers || []}
              onBuyPhoneNumber={handleBuyPhoneNumber}
              onDeletePhoneNumber={handleDeletePhoneNumber}
            />
            <SavedCards allStripeCustomers={allStripeCustomers || []} isLoadingTwilioAccount={isLoadingTwilioAccount} />
          </Box>
          <Arrow fontSize="large" classes={{ root: classes.arrowIcon }} />
        </Box>
        <Box className={classes.card}>
          <ForwardingSettings
            isDisabledEnabledSms={!isDisabledEnabledSms}
            isLoadingTwilioAccount={isLoadingTwilioAccount}
            defaultValues={allSmsSettings}
          />
        </Box>
        <Box className={classes.card}>
          <Balance
            balance={twilioAccount ? twilioAccount.smsAccount.balance : 0}
            isLoadingTwilioAccount={isLoadingTwilioAccount}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default SmsSettings;
