import React, { useEffect, useState } from 'react';

import { BodyText, Button, Form, Radio, RadioGroup, TextInput } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import RefreshIcon from '@material-ui/icons/Autorenew';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import smsAPI from 'admin/api/SmsAPI';
import { CanadaFlagIcon, UnitedStatesFlagIcon } from 'common/components/icons';

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
    },
  },
  updateIcon: {
    padding: '9px',
  },
  flagIcon: {
    fontSize: '14px',
  },
  icon: {
    position: 'relative',
    left: '170px',
  },
}));

const AREA_CODE = 'areaCode';
const PHONE_NUMBER = 'phoneNumber';

const labels = {
  [AREA_CODE]: 'Area code',
  [PHONE_NUMBER]: 'Phone number',
};

const schemaAreaCode = string().label(labels[AREA_CODE]).required('Enter the area code').nullable().length(3);

const schemaAreaCodeWithPhoneNumber = object().shape({
  [AREA_CODE]: schemaAreaCode,
  [PHONE_NUMBER]: string()
    .label(labels[PHONE_NUMBER])
    .when(AREA_CODE, {
      is: (areaCode) => schemaAreaCode.isValidSync(areaCode),
      then: string().required('Please select a phone number from the list').nullable(),
    }),
});

export const SelectPhoneNumberForm = ({ onSubmit, defaultValues, isDisabledInProcessing }) => {
  const classes = useStyles();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isWrongAreaCode, setIsWrongAreaCode] = useState(false);

  const countryFlagIcon = {
    US: <UnitedStatesFlagIcon fontSize="small" classes={{ fontSizeSmall: classes.flagIcon }} />,
    CA: <CanadaFlagIcon fontSize="small" classes={{ fontSizeSmall: classes.flagIcon }} />,
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schemaAreaCodeWithPhoneNumber),
    defaultValues,
  });

  const formSubmit = (data) => {
    const selectedNumber = phoneNumbers.find((item) => item.phoneNumber.endpoint === data.phoneNumber);
    onSubmit({ ...selectedNumber, ...data });
  };

  const areaCodeValue = watch(AREA_CODE);

  const getPhoneNumberList = () => {
    if (areaCodeValue && schemaAreaCode.isValidSync(areaCodeValue)) {
      setIsLoading(true);
      setIsWrongAreaCode(true);
      return smsAPI
        .findPhoneNumber(areaCodeValue)
        .catch(() => {
          enqueueSnackbar(`Can't get phone number by this area code. Try again`, { variant: 'error' });
          return {};
        })
        .then((res) => {
          setPhoneNumbers(res);
          setIsLoading(false);
          setIsWrongAreaCode(false);
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (areaCodeValue && +areaCodeValue >= 100) {
      getPhoneNumberList();
    }
  }, [areaCodeValue]);

  const handleRefreshPhoneNumber = () => {
    setValue(PHONE_NUMBER, null);
    getPhoneNumberList();
  };

  return (
    <Form onSubmit={handleSubmit(formSubmit)} noValidate={false}>
      <Box display="flex" justifyContent="center" alignItems="center" position="relative">
        <Grid item xs={4} sm={3}>
          <TextInput
            autoFocus
            defaultValue=""
            label={labels[AREA_CODE]}
            name={AREA_CODE}
            placeholder="(123)"
            type="number"
            fullWidth
            inputProps={{ min: '100', max: '999' }}
            control={control}
          />
        </Grid>
        {phoneNumbers.length > 0 && schemaAreaCode.isValidSync(areaCodeValue) && (
          <Box position="absolute" right="0">
            <Tooltip title="Refresh phone number list">
              <IconButton color="primary" classes={{ root: classes.updateIcon }} onClick={handleRefreshPhoneNumber}>
                <RefreshIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {phoneNumbers.length > 0 && (
        <Box display="flex" justifyContent="center" pt="15px">
          <RadioGroup
            name={PHONE_NUMBER}
            className={classes.radioGroup}
            formError={getIn(errors, [PHONE_NUMBER, 'message'], '')}
            control={control}
          >
            {phoneNumbers.map((item) => (
              <Radio
                key={item.friendlyName}
                value={item.phoneNumber.endpoint}
                label={
                  <>
                    <Box display="flex" alignItems="center" justifyContent="space-between" minWidth="115px">
                      <Box>{item.friendlyName}</Box>
                      {countryFlagIcon[item.isoCountry]}
                    </Box>
                  </>
                }
                sameUncheckedColor
                color="primary"
              />
            ))}
          </RadioGroup>
        </Box>
      )}
      {isLoading && areaCodeValue && (
        <Box mt={2}>
          <LinearProgress />
        </Box>
      )}
      {!isWrongAreaCode && schemaAreaCode.isValidSync(areaCodeValue) && !phoneNumbers.length && (
        <Box display="flex" justifyContent="center" mt={2}>
          <BodyText>No phone number by this area code</BodyText>
        </Box>
      )}
      <Box mt={2}>
        <Button
          size="small"
          color="primary"
          type="submit"
          disabled={
            isLoading ||
            (!isWrongAreaCode && schemaAreaCode.isValidSync(areaCodeValue) && !phoneNumbers.length) ||
            isDisabledInProcessing
          }
          loading={isLoading || isDisabledInProcessing}
          fullWidth
        >
          Choose number for $2 per month
        </Button>
      </Box>
    </Form>
  );
};

SelectPhoneNumberForm.propTypes = {
  onSubmit: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: pt.object.isRequired,
  isDisabledInProcessing: pt.bool,
};

SelectPhoneNumberForm.defaultProps = {
  isDisabledInProcessing: false,
};
