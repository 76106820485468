// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

const ENABLE_VIDEO_TUTORIALS_NAME = 'enableVideoTutorials';

const {
  reducer,
  actions: { setVideos, setEnableVideoTutorials },
  selectors: { getVideos, getEnableVideoTutorials },
} = autodux({
  slice: 'playVideo',
  initial: {
    // it'll be null or string[]
    videos: null,
    enableVideoTutorials: true,
  },
});

const fetchEnableVideoTutorials = () => (dispatch: ReturnType<typeof useDispatch>) => {
  const storedValue = localStorage.getItem(ENABLE_VIDEO_TUTORIALS_NAME);
  dispatch(setEnableVideoTutorials(storedValue === null ? true : !!+storedValue));
};

const updateEnableVideoTutorials = (value: boolean) => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(setEnableVideoTutorials(value));
  localStorage.setItem(ENABLE_VIDEO_TUTORIALS_NAME, (+value).toString());
};

export default reducer;

export {
  getVideos,
  setVideos,
  setEnableVideoTutorials,
  getEnableVideoTutorials,
  fetchEnableVideoTutorials,
  updateEnableVideoTutorials,
};
