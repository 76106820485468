import React, { useState } from 'react';

import { Link, Order, OrdersPermissions, PriceAdjustment, useHasPermission } from '@elromcoinc/react-shared';
import { TableCell, Tooltip } from '@material-ui/core';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { FuelSurcharge as FuelSurchargeModal } from 'admin/components/OrderWindow/modals';
import { getServiceClosingPropertyName, getServicePropertyName } from 'admin/utils';

enum Modals {
  FUEL_SURCHARGE = 'FUEL_SURCHARGE',
}

export const FuelSurcharge = () => {
  const { order } = useOrderState() as { order: Order };
  const { isClosing } = useOrderClosingContext();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const service = isClosing ? order.closingOrderDetail?.services?.get(serviceIndex) : order.services.get(serviceIndex);
  const { quote } = service;
  const canEditFuelSurchargeAmount = useHasPermission(OrdersPermissions.PERM_CAN_EDIT_FUEL_SURCHARGE_AMOUNT);
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  const handleChangeFuelSurcharge = (value: PriceAdjustment | null) => {
    onChange({
      name: (isClosing ? getServiceClosingPropertyName : getServicePropertyName)(
        serviceIndex,
        'fuelChargeEmployeeAdjusted',
      ),
      value,
    });
    setOpenModal(null);
  };

  const total = (isClosing ? order.closingOrderDetail : order)?.getServiceFuelTotal(
    serviceIndex,
    isSelectedAllServices,
  );

  const fuelChargeComputed = quote.fuelChargeComputed;
  const fuelChargeComputedNote = quote.fuelChargeComputedNote;
  const fuelChargeEmployeeAdjusted = quote.fuelChargeEmployeeAdjusted;
  const fuelChargeMovingCost = quote.fuelChargeMovingCost;

  return (
    <HighlightedTableRow data-testId="fuelSurcharge">
      <Tooltip title={!canEditFuelSurchargeAmount ? "You don't have a permission to edit this field" : ''}>
        <TableCell size="small" padding="none">
          <Link
            disabled={!canEditFuelSurchargeAmount || isSelectedAllServices}
            onClick={toggleOpenModal(Modals.FUEL_SURCHARGE)}
          >
            Fuel Surcharge
          </Link>
          {Modals.FUEL_SURCHARGE === openModal && (
            <FuelSurchargeModal
              open
              onSave={handleChangeFuelSurcharge}
              onCancel={toggleOpenModal(null)}
              fuelChargeComputed={fuelChargeComputed}
              fuelChargeComputedNote={fuelChargeComputedNote}
              fuelChargeEmployeeAdjusted={fuelChargeEmployeeAdjusted}
              fuelChargeMovingCost={fuelChargeMovingCost}
            />
          )}
        </TableCell>
      </Tooltip>
      <TableCell>
        <Text value={total?.asHumanReadableString()} data-testId={'fuelSurchargeValue'} />
      </TableCell>
    </HighlightedTableRow>
  );
};
