import { useMemo } from 'react';

import { DatePicker, Modal, Select, toDateString } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { add, parseISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import useTimeDuration from 'admin/components/OrderWindow/hooks/useTimeDuration';
import { getServicePropertyName } from 'admin/utils';

interface DeliveryModalProps {
  title: string;
  onClose: () => void;
  onSave: (changes: [string, any][]) => void;
}

const deliveryDateStartName = 'deliveryDateStart';
const deliveryDateEndName = 'deliveryDateEnd';
const deliveryTimeEarliestName = 'deliveryTimeEarliest';
const deliveryTruckReturnTimeName = 'deliveryTruckReturnTime';

const labels = {
  [deliveryDateStartName]: 'Delivery Date Start',
  [deliveryDateEndName]: 'Delivery Date End',
  [deliveryTimeEarliestName]: 'Truck Leave Time',
  [deliveryTruckReturnTimeName]: 'Truck Return Time',
};

interface FormValues {
  [deliveryDateStartName]: Date | null;
  [deliveryDateEndName]: Date | null;
  [deliveryTimeEarliestName]: string | null;
  [deliveryTruckReturnTimeName]: string | null;
}

const schema = object().shape({
  [deliveryDateStartName]: date()
    .label(labels[deliveryDateStartName]) // @ts-ignore
    .when([`$${deliveryDateEndName}`], (deliveryDateEnd: Date, schema: any) =>
      deliveryDateEnd ? schema.max(deliveryDateEnd, 'Delivery Date Start must be before Delivery Date End') : schema,
    )
    .nullable()
    .required(),
  [deliveryDateEndName]: date()
    .label(labels[deliveryDateEndName]) // @ts-ignore
    .when([`$${deliveryDateStartName}`], (deliveryDateStart: Date, schema: any) =>
      deliveryDateStart ? schema.min(deliveryDateStart, 'Delivery Date End must be after Delivery Date Start') : schema,
    )
    .nullable()
    .required(),
  [deliveryTimeEarliestName]: string().label(labels[deliveryTimeEarliestName]).nullable().required(),
  [deliveryTruckReturnTimeName]: string().label(labels[deliveryTruckReturnTimeName]).nullable().required(),
});

export const DeliveryModal = ({ title, onSave, onClose }: DeliveryModalProps) => {
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const quote = order?.getServiceQuote(serviceIndex);
  const [timeOptions] = useTimeDuration();

  const startDate = add(parseISO(quote.date), { days: 1 });
  const context = useMemo(
    () =>
      ({ deliveryDateStart: null, deliveryDateEnd: null } as Pick<FormValues, 'deliveryDateStart' | 'deliveryDateEnd'>),
    [],
  );

  const formMethods = useForm<FormValues>({
    defaultValues: {
      [deliveryDateStartName]: quote?.deliveryDateStart,
      [deliveryDateEndName]: quote?.deliveryDateEnd,
      [deliveryTimeEarliestName]: quote?.deliveryTimeEarliest,
      [deliveryTruckReturnTimeName]: quote?.deliveryTruckReturnTime,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
    context,
  });

  const { handleSubmit, watch } = formMethods;

  context.deliveryDateStart = watch(deliveryDateStartName);
  context.deliveryDateEnd = watch(deliveryDateEndName);

  const handleSubmitForm = (data: FormValues) => {
    const result = Object.entries(data).reduce((acc, [key, value]) => {
      if (toDateString(value) !== toDateString(quote?.[key])) {
        acc.push([getServicePropertyName(serviceIndex, key), value]);
      }
      return acc;
    }, [] as [string, any][]);

    onSave(result);
  };

  const actions = [
    { label: 'cancel', onClick: onClose },
    {
      label: 'save',
      onClick: handleSubmit(handleSubmitForm),
      color: 'primary',
    },
  ];

  return (
    <Modal title={title} open onClose={onClose} actions={actions}>
      <FormProvider {...formMethods}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Select
              fullWidth
              name={deliveryTimeEarliestName}
              label={labels[deliveryTimeEarliestName]}
              options={timeOptions}
              data-testid="deliveryTruckLeaveTime"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <DatePicker
              minDate={startDate}
              fullWidth
              name={deliveryDateStartName}
              label={labels[deliveryDateStartName]}
              data-testid="deliveryTruckStartDate"
            />
          </Grid>
          <Grid item xs={6}>
            {/* @ts-ignore */}
            <DatePicker
              minDate={startDate}
              fullWidth
              name={deliveryDateEndName}
              label={labels[deliveryDateEndName]}
              data-testid="deliveryTruckEndDate"
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              name={deliveryTruckReturnTimeName}
              label={labels[deliveryTruckReturnTimeName]}
              options={timeOptions}
              data-testid="deliveryTruckReturnTime"
            />
          </Grid>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
