import React from 'react';

import { DataTable, DataTableColumnDef, HeaderSmallText, IconButton, useAlert } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { AddCardModal } from 'admin/components/PlanSettings/components/BillingHistory/AddCardModal';
import { CreditCardsDataTable } from 'admin/components/PlanSettings/components/BillingHistory/CreditCardsDataTable';
import { makeBillingHistoryColumns } from 'admin/components/PlanSettings/utils/makeBillingHistoryColumns';
import { makeCreditCardColumns } from 'admin/components/PlanSettings/utils/makeCreditCardColumns';
import { dataOfBillingHistory, dataOfCreditCards } from 'admin/components/PlanSettings/utils/mockTableData';

const useStyles = makeStyles((theme) => ({
  root: {},
  wrapper: {
    position: 'relative',
    paddingBottom: theme.spacing(6),

    '& fieldset': {
      width: '100%',
    },
  },
  title: {
    paddingBottom: theme.spacing(3),
  },
  addIcon: {
    position: 'absolute',
    bottom: 25,
    right: -15,
    marginTop: '-33px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-10px',
      right: -10,
    },
  },
}));

const BillingHistoryTab = () => {
  const { setShowAlert, alertProps } = useAlert({
    cancelTitle: 'Cancel',
    confirmTitle: 'Add',
    onConfirm: () => {},
  });

  const classes = useStyles();

  const columnsOfCreditCards: DataTableColumnDef[] = makeCreditCardColumns(dataOfCreditCards);
  const columnsOfBillingHistory: DataTableColumnDef[] = makeBillingHistoryColumns(dataOfBillingHistory);
  const options = {
    selectableRowsHideCheckboxesCustom: true,
    smallPadding: true,
    tableHeadPrimaryColor: true,
    selectableRows: 'none',
  };

  const handleClick = () => {
    setShowAlert(true);
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <HeaderSmallText className={classes.title}>
          <b>Credit Cards on File</b>
        </HeaderSmallText>
        <CreditCardsDataTable data={dataOfCreditCards} columns={columnsOfCreditCards} options={options} />
        <Box className={classes.addIcon}>
          <IconButton color="primary" variant="default" size="medium" onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </Box>
        {alertProps.open && <AddCardModal alertProps={alertProps} />}
      </Box>
      <Box>
        <HeaderSmallText className={classes.title}>
          <b>Billing History</b>
        </HeaderSmallText>
        <DataTable data={dataOfBillingHistory} columns={columnsOfBillingHistory} options={options} />
      </Box>
    </>
  );
};

export { BillingHistoryTab };
