import React from 'react';

import { BodyText, EstimateType, Order, isLongDistanceService } from '@elromcoinc/react-shared';
import { Box, TableCell, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import { FlexWithNegativeMargin } from 'admin/components/OrderWindow/blocks/CommonComponents';
import { BloomingDalesDiscountGrandTotal } from 'admin/components/OrderWindow/blocks/QuoteDetails/BloomingDalesDiscountGrandTotal';
import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { GrandTotalFlatRateInput, GrandTotalLongDistanceInput } from 'admin/components/OrderWindow/common';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const grandTotalLabel = 'Grand Total';

export const GrandTotal = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = (isClosing ? order.closingOrderDetail : order)!;
  const service = orderDetail.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const isFlatRate = orderDetail.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;

  return (
    <HighlightedTableRow data-testId="grandTotal" highlight>
      <TableCell size="small" padding="none">
        <Box display="flex" alignItems="center">
          <Box className="mr-q">
            <BodyText>{isClosing ? 'Total Cost' : grandTotalLabel}</BodyText>
          </Box>
          {!isClosing && !!order.savedPercentage && (
            <Tooltip title={<BloomingDalesDiscountGrandTotal />}>
              <HelpOutlineIcon color="primary" />
            </Tooltip>
          )}
        </Box>
      </TableCell>
      <TableCell>
        {(isFlatRate || isLongDistance) && !isSelectedAllServices && order.disableAutoCalculation ? (
          <FlexWithNegativeMargin>
            {isLongDistance ? <GrandTotalLongDistanceInput /> : <GrandTotalFlatRateInput />}
          </FlexWithNegativeMargin>
        ) : (
          <Text
            value={orderDetail.getGrandTotal(serviceIndex, isSelectedAllServices).asHumanReadableString()}
            data-testId={'grandTotalValue'}
          />
        )}
      </TableCell>
    </HighlightedTableRow>
  );
};
