import { RecipientTypes } from '@elromcoinc/react-shared';
import { format, parseISO } from 'date-fns';
import { Record } from 'immutable';

import ActivityType from 'admin/constants/ActivityType';
import ManualActivityType from 'admin/constants/ManualActivityType';
import { CommunicationModality } from 'common-types';

const formatDate = (date, dateFormat = 'PPp') => format(parseISO(date), dateFormat);

const ConversationMessageEmail = (activity) => {
  const { id, dateCreated, to, employeeFullName, type } = activity;
  const desc = `${employeeFullName} sent email to ${to}.`;

  // TODO. I think this is a bug. By some reason the email recepient on BE is Company. It was EMAIL_OUT. Need to create a bug for this.
  activity.recipient = RecipientTypes.EMAIL_OUT;

  return {
    activity,
    id,
    label: formatDate(dateCreated),
    desc,
    message: desc,
    dateTime: parseISO(dateCreated),
    triggeredBy: employeeFullName,
    activityType: ActivityType.EMAIL,
    hiddenContent: activity.text,
    subject: activity.subject,
    text: activity.text,
    type,
    recipient: activity.recipient,
    automationRuleId: activity.automationRuleId,
  };
};

const ConversationMessage = (activity) => {
  if (activity.type === CommunicationModality.EMAIL) {
    return ConversationMessageEmail(activity);
  }
  const { id, dateCreated, recipient, customerFullName, employeeFullName, status } = activity;
  const toCustomer = recipient === RecipientTypes.CUSTOMER;
  const desc = toCustomer
    ? `${employeeFullName} sent message to ${customerFullName}.`
    : `${customerFullName} sent message.`;
  return {
    id,
    label: formatDate(dateCreated),
    desc,
    message: desc,
    dateTime: parseISO(dateCreated),
    triggeredBy: toCustomer ? employeeFullName : customerFullName,
    activityType: ActivityType.MESSAGE,
    hiddenContent: activity.text,
    recipient,
    status,
    type: activity.type,
    automationRuleId: activity.automationRuleId,
    activity,
  };
};

const Note = (activity) => {
  const { id, dateCreated, createdByName } = activity;
  const desc = `${createdByName} left note.`;
  const involvesLabel = [
    activity.involvesSales && 'Sales',
    activity.involvesCustomer && 'Customer',
    activity.involvesForeman && 'Foreman',
    activity.involvesDispatch && 'Dispatch',
  ]
    .filter(Boolean)
    .join(', ');
  const involves = involvesLabel ? `(${involvesLabel})` : '';

  return {
    id,
    label: `${formatDate(dateCreated)} ${involves}`,
    desc,
    message: desc,
    dateTime: parseISO(dateCreated),
    triggeredBy: createdByName,
    activityType: ActivityType.NOTE,
    activity,
    hiddenContent: activity.content,
    createdByCustomer: activity.createdByCustomer,
  };
};

const getManualActivityTypeDesc = (type) => {
  switch (type) {
    case ManualActivityType.CUSTOM:
      return 'custom activity';
    default:
      return ManualActivityType.getName(type).toLowerCase();
  }
};

const ManuallyEnteredActivity = (activity) => {
  const { id, performed, manuallyEnteredActivityType, activityType, contactedName, performerFullName } = activity;
  const desc = `${performerFullName} logged a ${getManualActivityTypeDesc(manuallyEnteredActivityType)} ${
    contactedName ? `with ${contactedName}.` : ''
  }`;
  return {
    id,
    label: formatDate(performed),
    desc,
    message: desc,
    dateTime: parseISO(performed),
    triggeredBy: performerFullName,
    type: ManualActivityType.getName(manuallyEnteredActivityType),
    activityType,
    activity,
    notes: activity.notes,
    hiddenContent: activity.notes,
  };
};

const Task = (activity) => {
  const { subject, employeeName, due, id } = activity;
  const desc = `Assigned to ${employeeName} due on ${formatDate(due)}.`;
  return {
    id,
    label: subject,
    desc,
    message: desc,
    dateTime: parseISO(due),
    triggeredBy: employeeName,
    activityType: ActivityType.TASK,
    activity,
    hiddenContent: activity.description,
  };
};

const EventMetadata = (activity) => {
  const { message, id, dateCreated, createdByName, status } = activity;
  const triggeredBy = createdByName || 'System';
  return {
    id,
    label: triggeredBy,
    desc: message,
    status,
    message,
    isEventLog: true,
    dateTime: parseISO(dateCreated),
    triggeredBy,
    activityType: ActivityType.EVENT,
    activity,
  };
};

const activities = {
  EventMetadata,
  ConversationMessage,
  ManuallyEnteredActivity,
  Note,
  Task,
  BillOfLadingActivity: EventMetadata,
};

export default class OrderLog extends Record({
  id: null,
  label: '',
  desc: '',
  message: '',
  dateTime: null,
  orderId: null,
  triggeredBy: '',
  activityType: '',
  status: '',
  type: null, // used to display icon when activityType === ActivityType.MANUALLY_ENTERED_ACTIVITY
  isEventLog: false,
  activity: null,
  hiddenContent: null,
  subject: null,
  text: '',
  recipient: null,
  createdByCustomer: false,
  automationRuleId: null,
  image: false,
}) {
  static create(log = {}) {
    return new OrderLog(log);
  }

  static fromActivity(activity) {
    const parser = activities[activity.activityType] || null;

    return parser ? OrderLog.create(parser(activity)) : null;
  }
}
