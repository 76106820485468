import React, { FC } from 'react';

import {
  HeaderSmallText,
  Modal,
  PhoneInput,
  Switch,
  TextInput,
  Waypoint,
  phoneNumberYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { boolean, object, string } from 'yup';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import {
  COI_RECIPIENT,
  EMAIL,
  FIRST_NAME,
  INSTRUCTIONS,
  LAST_NAME,
  PHONE_NUMBER,
  REQUIRES_COI,
  USE_CUSTOMER_FOR_COI,
  labels,
} from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { getLabel } from 'admin/utils/getLabel';

const instructionsPath = `${COI_RECIPIENT}.${INSTRUCTIONS}`;
const firstNamePath = `${COI_RECIPIENT}.${FIRST_NAME}`;
const lastNamePath = `${COI_RECIPIENT}.${LAST_NAME}`;
const emailPath = `${COI_RECIPIENT}.${EMAIL}`;
const phoneNumberPath = `${COI_RECIPIENT}.${PHONE_NUMBER}`;

const schema = object().shape({
  [USE_CUSTOMER_FOR_COI]: boolean().label(labels[USE_CUSTOMER_FOR_COI]),
  [REQUIRES_COI]: boolean().label(labels[REQUIRES_COI]),
  [COI_RECIPIENT]: object()
    .nullable()
    .when([USE_CUSTOMER_FOR_COI], (useCustomerForCoi: boolean, s) =>
      useCustomerForCoi
        ? s.shape({
            [FIRST_NAME]: string().label(labels[COI_RECIPIENT][FIRST_NAME]).nullable(),
            [LAST_NAME]: string().label(labels[COI_RECIPIENT][LAST_NAME]).nullable(),
            [PHONE_NUMBER]: phoneNumberYUP(labels[COI_RECIPIENT][PHONE_NUMBER]),
            [EMAIL]: string().label(labels[COI_RECIPIENT][EMAIL]).nullable(),
            [INSTRUCTIONS]: string().label(labels[COI_RECIPIENT][INSTRUCTIONS]).nullable().max(999),
          })
        : s.shape({
            [FIRST_NAME]: string().label(labels[COI_RECIPIENT][FIRST_NAME]).nullable().required().min(2).max(32),
            [LAST_NAME]: string().label(labels[COI_RECIPIENT][LAST_NAME]).nullable().required().min(2).max(32),
            [PHONE_NUMBER]: phoneNumberYUP(labels[COI_RECIPIENT][PHONE_NUMBER]).required(),
            // TODO: use common email validation schema from react-ui-kit when it'll ready
            [EMAIL]: string()
              .nullable()
              .label(labels[COI_RECIPIENT][EMAIL])
              .required()
              .min(5)
              .max(255)
              .email(`Please enter the correct ${labels[COI_RECIPIENT][EMAIL]}`),
            [INSTRUCTIONS]: string().label(labels[COI_RECIPIENT][INSTRUCTIONS]).nullable().max(999),
          }),
    ),
});

interface CertificateOfInsuranceProps {
  onSave: (coi: Waypoint) => void;
  onCancel: () => void;
  data: Waypoint | null;
  open: boolean;
}

export const CertificateOfInsuranceModal: FC<CertificateOfInsuranceProps> = ({ onSave, onCancel, data, open }) => {
  const { isCompleted } = useOrderClosingContext();

  const formMethods = useForm<FieldValues>({
    defaultValues: data!,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { control, handleSubmit, watch } = formMethods;

  const sendToCustomer = watch(USE_CUSTOMER_FOR_COI);

  const handleSubmitForm = (data: Waypoint) => {
    onSave(data);
  };

  return (
    <Modal
      open={open}
      title="Certificate Of Insurance"
      onClose={onCancel}
      color="grey"
      maxWidth="sm"
      actions={[
        { label: 'cancel', onClick: onCancel, color: 'default' },
        { label: 'save', onClick: handleSubmit(handleSubmitForm as any), color: 'primary', disabled: isCompleted },
      ]}
    >
      {/** @ts-ignore */}
      <FormProvider {...formMethods}>
        <Switch
          color="primary"
          disabled={isCompleted}
          name={USE_CUSTOMER_FOR_COI}
          label={labels[USE_CUSTOMER_FOR_COI]}
        />
        {!sendToCustomer && (
          <Box mb={1}>
            <Box my={1}>
              <HeaderSmallText>
                <b>Certificate will be sent to</b>
              </HeaderSmallText>
            </Box>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={isCompleted}
                  fullWidth
                  name={firstNamePath}
                  label={getLabel(firstNamePath, labels)}
                  control={control as any}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={isCompleted}
                  fullWidth
                  name={lastNamePath}
                  label={getLabel(lastNamePath, labels)}
                  control={control as any}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextInput
                  disabled={isCompleted}
                  fullWidth
                  name={emailPath}
                  label={getLabel(emailPath, labels)}
                  type="email"
                  control={control as any}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PhoneInput
                  disabled={isCompleted}
                  // @ts-ignore
                  fullWidth
                  name={phoneNumberPath}
                  label={getLabel(phoneNumberPath, labels)}
                  control={control as any}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box my={1}>
          <HeaderSmallText>
            <b>Send Instructions</b>
          </HeaderSmallText>
        </Box>
        <TextInput
          fullWidth
          disabled={isCompleted}
          name={instructionsPath}
          control={control as any}
          multiline
          rows="4"
          InputProps={{
            disableUnderline: true,
          }}
        />
      </FormProvider>
    </Modal>
  );
};
