import { useOrderClosingContext, useOrderServiceIndex } from 'admin/components/OrderWindow/context';
import { getServicePropertyName, getServiceRosterClosingPropertyName } from 'admin/utils';

export const useGetServicePropertyName = () => {
  const { isClosing } = useOrderClosingContext();
  const { serviceIndex } = useOrderServiceIndex();

  /**
   * Returns property name for service sales or closing property path
   * @param propertyName - name of property
   * @param closingPropertyName - name of property for closing service
   * @param allowCLosing - allow to use closing service property name
   * @returns property name for service sales or closing property path
   */
  return (propertyName: string, closingPropertyName?: string, allowCLosing = true) => {
    return isClosing && allowCLosing
      ? getServiceRosterClosingPropertyName(serviceIndex, closingPropertyName || propertyName)
      : getServicePropertyName(serviceIndex, propertyName);
  };
};
