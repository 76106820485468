import autodux from 'autodux';
import { OrderedSet, Set } from 'immutable';

import { WindowType } from 'admin/constants/WidowType';

const {
  reducer,
  actions: { closeWindow, openWindow, toggleMinimizedWindow, setSelectedWindow, clearSelectedWindow },
  selectors: { getSelectedWindow, getMinimizedWindow },
} = autodux({
  slice: 'window',
  initial: {
    // selectedWindow: OrderedSet([{ id: 397, type: 'ORDER' }]),
    selectedWindow: OrderedSet([]),
    minimizedWindow: Set(),
  },
  actions: {
    openWindow: (state, window) => ({
      ...state,
      selectedWindow: state.selectedWindow.filter((w) => w.type !== window.type || w.id !== window.id).add(window),
      minimizedWindow: state.minimizedWindow.filter((w) => w.type !== window.type || w.id !== window.id),
    }),
    closeWindow: (state, window) => ({
      ...state,
      selectedWindow: state.selectedWindow.filter((w) => w.type !== window.type || w.id !== window.id),
    }),
    toggleMinimizedWindow: (state, window) => ({
      ...state,
      selectedWindow: state.minimizedWindow.find((w) => w.id === window.id && w.type === window.type)
        ? state.selectedWindow.filter((w) => w.type !== window.type || w.id !== window.id).add(window)
        : state.selectedWindow,
      minimizedWindow: state.minimizedWindow.find((w) => w.id === window.id && w.type === window.type)
        ? state.minimizedWindow.filter((w) => w.id !== window.id || w.type !== window.type)
        : state.minimizedWindow.add(window),
    }),
    clearSelectedWindow: (state) => ({
      ...state,
      selectedWindow: OrderedSet(),
    }),
  },
});

const openOrder = (id) => (dispatch) => {
  dispatch(openWindow({ id, type: WindowType.ORDER }));
};

const openAccount = (id) => (dispatch) => {
  dispatch(openWindow({ id, type: WindowType.ACCOUNT }));
};

export default reducer;
export {
  getSelectedWindow,
  closeWindow,
  openWindow,
  openOrder,
  openAccount,
  getMinimizedWindow,
  toggleMinimizedWindow,
  setSelectedWindow,
  clearSelectedWindow,
};
