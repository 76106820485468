import { format } from 'date-fns';
import { Record } from 'immutable';

import { NotificationStatus } from 'admin/constants/NotificationStatus';

interface NotificationEntityProps {
  id: number;
  dateTime: string;
  description: string;
  eventId: number;
  status: NotificationStatus;
  eventTypeDescription: string;
  read: boolean;
  dismissed: boolean;
  userName: string;
  orderNumber: string;
  orderId: number;
  formattedDateTime: string;
  isNew: boolean;
}

const defaultValues: NotificationEntityProps = {
  id: 0,
  dateTime: '',
  description: '',
  eventId: 0,
  status: NotificationStatus.PENDING,
  eventTypeDescription: '',
  read: false,
  dismissed: false,
  userName: '',
  orderNumber: '',
  orderId: 0,
  formattedDateTime: '',
  isNew: false,
};

export class NotificationEntity extends Record(defaultValues) {
  constructor(n: NotificationEntityProps) {
    super({
      ...n,
      // TODO need to show changes in some cases. Need to discuss and implement.
      description: n.description.split('\\n')[0],
      read: n.status === NotificationStatus.READ,
      dismissed: n.status === NotificationStatus.DISMISSED,
      formattedDateTime: format(new Date(n.dateTime), 'MM/dd/yyyy hh:mm a'),
    });
  }
}
