import React, { FC } from 'react';

import { BodySmallText, BodyText } from '@elromcoinc/react-shared';
import { Box, Card, CardContent, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { formatCurrency } from 'common/utils/Formatter';

const useStyles = makeStyles(({ spacing }) => ({
  content: {
    '&:last-child': {
      paddingBottom: '16px',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  },
  warningContent: {
    maxWidth: '250px',
  },
  roundedCard: {
    '&.MuiCard-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

interface BalanceProps {
  balance: number;
  isLoadingTwilioAccount: boolean;
}

export const Balance: FC<BalanceProps> = ({ balance, isLoadingTwilioAccount }) => {
  const classes = useStyles();

  return (
    <Card className={classes.roundedCard}>
      <CardContent classes={{ root: classes.content }}>
        <Box className={classes.cardContent}>
          <BodyText>Sms Balance:</BodyText>
          {isLoadingTwilioAccount ? (
            <BodySmallText color="primary">Loading...</BodySmallText>
          ) : (
            <BodyText>${formatCurrency(balance, 2)}</BodyText>
          )}
        </Box>
        <Box className={classes.warningContent}>
          <Alert color="warning">
            <BodyText>
              Please note: If we are unable to charge your card for renewal and your balance goes below 0, Twilio will
              delete your phone number in 7 days. Please ensure your billing information is up to date.
            </BodyText>
          </Alert>
        </Box>
      </CardContent>
    </Card>
  );
};
