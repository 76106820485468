import React, { FC, useState } from 'react';

import {
  BodyText,
  Button,
  OrderStatusIdType,
  OrderSummary,
  ServiceSummary,
  formatPhoneNumber,
  statusesById,
  toDate,
} from '@elromcoinc/react-shared';
import { Box, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { List } from 'immutable';
import { useDispatch } from 'react-redux';

import { openOrder } from 'admin/autodux/WindowsAutodux';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import useDebounce from 'common/hooks/useDebounce';

const useStyles = makeStyles(() => ({
  popper: {
    pointerEvents: 'unset',
  },
  tooltip: {
    cursor: 'default',
    margin: '6px 0',
    backgroundColor: '#363747',
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  arrow: {
    '&::before': {
      backgroundColor: '#363747',
    },
  },
}));

const parseAddresses = (service: ServiceSummary) => {
  const addresses = List([service.fromAddress.asSingleString, service.toAddress.asSingleString]).filter(Boolean);
  if (addresses.size === 1) {
    return [['Address', addresses.first()]];
  }
  return [
    ['From Address', addresses.first()],
    ['To Address', addresses.last()],
  ];
};

const parseSummary = (summary: OrderSummary) => [
  ['Order ID', summary.orderNumber],
  ['Status', statusesById[summary.status as OrderStatusIdType].label],
  ['Move Date', format(toDate(summary.startDate!)!, 'P')],
  ['Crew Size', summary.crewSize],
  ['Service Type', List(summary.serviceSummaries).first().serviceName],
  ['Size of Move', summary.sizeDescription],
  ['CuFt/LBS:', `${summary.activeCuFt}/${summary.activeWeight}`],
  ['Estimated Quote', summary.estimatedQuoteRange.humanReadable],
  ...parseAddresses(List(summary.serviceSummaries).first()),
  ['Customer', summary.customerName],
  ['Phone', formatPhoneNumber(summary.customerPhone)],
  ['Email', summary.customerEmail],
];
const parseJob = (job: Job) => {
  const date = job.deliveryJob
    ? ['Truck Block Dates', job.getTruckBlockDates()]
    : ['Move Date', format(job.date as Date, 'P')];

  return [
    ['Order ID', job.orderNumber],
    ['Status', statusesById[job.orderStatus!].label],
    date,
    ['Crew Size', job.numberOfMovers],
    ['Service Type', job.serviceType],
    ['Size of Move', job.sizeDescription],
    // need backend for commented fields
    // ['CuFt/LBS:', `${job.activeCuFt}/${job.activeWeight}`],
    // ['Estimated Quote', job.estimatedQuoteRange.humanReadable],
    ['From Address', job.fromAddress],
    ['To Address', job.toAddress],
    ['Contact', `${job.contactFirstName} ${job.contactLastName}`],
    ['Phone', formatPhoneNumber(job.contactPhone)],
    // ['Email', job.customerEmail]
  ];
};

interface OrderDetailsTooltipProps {
  summary?: OrderSummary | null;
  job?: Job | null;
}

const OrderDetailsTooltip: FC<OrderDetailsTooltipProps> = ({ summary = null, children, job = null, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const isOpen = useDebounce(open, 200);

  const closeTooltip = () => setOpen(false);
  const openTooltip = () => setOpen(true);

  const handleOpenOrder = (orderId: number) => () => {
    setOpen(false);
    dispatch(openOrder(orderId));
  };

  const tooltipInformation = summary ? parseSummary(summary) : parseJob(job!);

  const renderTooltip = () => (
    <Box
      className={classes.tooltipContent}
      onFocus={openTooltip}
      onBlur={closeTooltip}
      onMouseOver={openTooltip}
      onMouseLeave={closeTooltip}
    >
      <table>
        <tbody>
          {tooltipInformation.map(([label, value]) => (
            <tr key={label}>
              <td>
                <BodyText>{label}:</BodyText>
              </td>
              <td>
                <BodyText>
                  <b>{value}</b>
                </BodyText>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button onClick={handleOpenOrder(summary?.orderId ?? job?.orderId!)} variant="text" color="primary">
        Open Order
      </Button>
    </Box>
  );

  return (
    <Box my={1}>
      <Tooltip
        {...rest}
        open={isOpen}
        data-testid={`orderJob-${job?.orderNumber}`}
        onOpen={openTooltip}
        onClose={closeTooltip}
        classes={{ popper: classes.popper, tooltip: classes.tooltip, arrow: classes.arrow }}
        title={renderTooltip()}
        arrow
      >
        {/* @ts-ignore */}
        {children}
      </Tooltip>
    </Box>
  );
};

export default OrderDetailsTooltip;
