import React from 'react';

import { BodySmallText, Checkbox } from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemIcon, ListItemText, Tooltip, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  listItem: {
    paddingRight: theme.spacing(1) * 0.5,
    paddingLeft: theme.spacing(1) * 0.5,
  },
  checkLabelGroup: {
    top: '-2px',
    position: 'absolute',
    left: '10px',
    zIndex: '1',
  },
  listTitle: {
    textAlign: 'right',
  },
  emailCheckbox: {
    color: `${theme.palette.success.main} !important`,
  },
}));

export default function CheckboxList(props) {
  const { dataList, updateDataList, updateAllDateList, title, inFlight } = props;
  const classes = useStyles();

  const handleChange = (rowNo, statusName) => () => {
    updateDataList(rowNo, statusName);
  };

  const handleTurnOffOnAll =
    (statusName) =>
    ({ target: { checked } }) => {
      updateAllDateList(statusName, checked, title);
    };

  return (
    <List className={classes.root}>
      <Box display="flex" justifyContent="space-around" ml={1} width={100}>
        <BodySmallText component="div">APP</BodySmallText>
        <BodySmallText component="div">SMS</BodySmallText>
        <BodySmallText component="div">Email</BodySmallText>
      </Box>
      <ListItem dense disableGutters className={classes.listItem}>
        <ListItemIcon>
          <Tooltip title="Turn on/off all APP" placement="top" arrow>
            <span>
              <Checkbox
                checked={!dataList.some((item) => !item.inAppEnabled)}
                onChange={handleTurnOffOnAll('inAppEnabled')}
                disabled={inFlight}
                color="primary"
                sameUncheckedColor
              />
            </span>
          </Tooltip>
          <Tooltip title="Turn on/off all SMS" placement="top" arrow>
            <span>
              <Checkbox
                checked={!dataList.some((item) => !item.smsEnabled)}
                onChange={handleTurnOffOnAll('smsEnabled')}
                disabled={dataList.some((item) => !item.inAppEnabled) || inFlight}
                color="secondary"
                sameUncheckedColor
                edge="start"
              />
            </span>
          </Tooltip>
          <Tooltip title="Turn on/off all Email" placement="top" arrow>
            <span>
              <Checkbox
                checked={!dataList.some((item) => !item.emailEnabled)}
                onChange={handleTurnOffOnAll('emailEnabled')}
                disabled={dataList.some((item) => !item.inAppEnabled) || inFlight}
                color="default"
                sameUncheckedColor
                edge="start"
                className={classes.emailCheckbox}
              />
            </span>
          </Tooltip>
        </ListItemIcon>
        <ListItemText edge="start" primary="Turn on/off all" className={classes.listItem} />
      </ListItem>
      {dataList.map((item, index) => {
        const labelId = `checkbox-list-label-${index}`;
        return (
          <ListItem key={item.id} role={undefined} dense button disableGutters className={classes.listItem}>
            <ListItemIcon>
              <Tooltip title="Notification" placement="top" arrow>
                <span>
                  <Checkbox
                    checked={item.inAppEnabled}
                    tabIndex={-1}
                    color="primary"
                    sameUncheckedColor
                    inputProps={{ 'aria-labelledby': labelId }}
                    disabled={inFlight}
                    onChange={handleChange(item.id, 'inAppEnabled')}
                  />
                </span>
              </Tooltip>
              <Tooltip title="SMS" placement="top" arrow>
                <span>
                  <Checkbox
                    checked={item.smsEnabled}
                    tabIndex={-1}
                    edge="start"
                    color="secondary"
                    sameUncheckedColor
                    inputProps={{ 'aria-labelledby': labelId }}
                    onChange={handleChange(item.id, 'smsEnabled')}
                    disabled={!item.inAppEnabled || inFlight}
                  />
                </span>
              </Tooltip>
              <Tooltip title="Email" placement="top" arrow>
                <span>
                  <Checkbox
                    checked={item.emailEnabled}
                    tabIndex={-1}
                    edge="start"
                    color="default"
                    inputProps={{ 'aria-labelledby': labelId }}
                    onChange={handleChange(item.id, 'emailEnabled')}
                    disabled={!item.inAppEnabled || inFlight}
                    className={clsx({ [classes.emailCheckbox]: item.inAppEnabled })}
                  />
                </span>
              </Tooltip>
            </ListItemIcon>
            <ListItemText id={labelId} edge="start" primary={`${item.description}`} className={classes.listItem} />
          </ListItem>
        );
      })}
    </List>
  );
}

CheckboxList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataList: PropTypes.array.isRequired,
  updateDataList: PropTypes.func.isRequired,
  updateAllDateList: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  inFlight: PropTypes.bool.isRequired,
};
