import { TableCell } from '@material-ui/core';
import styled from 'styled-components';

export const ValueTableCell = styled(TableCell)`
  && {
    > p {
      min-width: 180px;
      width: 100%;
      height: 22px;
    }
  }
`;
