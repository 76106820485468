import {
  OrdersPermissions,
  CommunicationPermissions,
  useCurrentUser,
  useHasPermission,
} from '@elromcoinc/react-shared';

interface Note {
  createdByName: string;
}

function useNotePermissions() {
  const user = useCurrentUser();
  const canEditOtherUserNote = useHasPermission(OrdersPermissions.PERM_CAN_EDIT_OTHER_USERS_NOTES);
  const canEditOwnNote = useHasPermission(OrdersPermissions.PERM_CAN_EDIT_A_NOTE);
  const canDeleteOwnNote = useHasPermission(OrdersPermissions.PERM_CAN_DELETE_OWN_NOTES);
  const canDeleteOthersNote = useHasPermission(OrdersPermissions.PERM_CAN_DELETE_OTHER_USERS_NOTES);
  const canShareNote = useHasPermission(CommunicationPermissions.PERM_CAN_SHARE_A_NOTE_WITH_OTHER_USER);

  return (note: Note) => {
    const isOwn = user?.getFullName() === note.createdByName;
    return {
      canEdit: (canEditOtherUserNote && !isOwn) || (canEditOwnNote && isOwn),
      canDelete: (canDeleteOthersNote && !isOwn) || (canDeleteOwnNote && isOwn),
      canShareNote,
    };
  };
}

export default useNotePermissions;
