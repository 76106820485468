import { FC, useEffect, useState } from 'react';

import { ActivitySourceType, RecipientTypes } from '@elromcoinc/react-shared';
import { isAfter } from 'date-fns';
import { List } from 'immutable';
import { FieldValues } from 'react-hook-form/dist/types';
import { useSelector } from 'react-redux';

import activityApi from 'admin/api/activityApi';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { ActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityLogContext';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import OrderLog from 'admin/entities/OrderLog';
import { CommunicationModality, Template } from 'common-types';

interface ActivityLogProviderProps {
  sourceId: number;
  activitySource: ActivitySourceType;
}

const isInTheFuture = (item: OrderLog) => isAfter(item?.dateTime!, new Date());
const isInThePast = (item: OrderLog) => !isInTheFuture(item);

export const ActivityLogProvider: FC<ActivityLogProviderProps> = ({ children, sourceId, activitySource }) => {
  const { isClosing } = useOrderClosingContext() || {};
  const [pastActions, setPastActions] = useState<List<OrderLog>>(List());
  const [nextSteps, setNextSteps] = useState<List<OrderLog>>(List());
  const [orderLogs, setOrderLogs] = useState<List<OrderLog>>(List());
  const [closingOrderLogs, setClosingOrderLogs] = useState<List<OrderLog>>(List());
  const [newNoteDefault, setNewNoteDefault] = useState<FieldValues | null>(null);
  const [newMessageDefault, setNewMessageDefault] = useState<FieldValues | null>(null);
  const [newEmailDefault, setNewEmailDefault] = useState<FieldValues | null>(null);
  const [newCallDefault, setNewCallDefault] = useState<FieldValues | null>(null);
  const [selectedTemplateDTO, setSelectedTemplateDTO] = useState<Template | null>(null);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);
  const [totalClosingActivities, setTotalClosingActivities] = useState(0);
  const [totalActivities, setTotalActivities] = useState(0);
  const [uniqueActivities, setUniqueActivities] = useState<OrderLog[]>([]);
  const [uniqueClosingActivities, setUniqueClosingActivities] = useState<OrderLog[]>([]);

  const fetchActivities = async (id: number, pageSize = 100) => {
    if (activitySource !== ActivitySourceType.ORDER) {
      return;
    }

    const orderLogItems = await activityApi.getOrderLogs(id, 0, pageSize).then((res) => {
      setTotalActivities(res.totalElements);

      setUniqueActivities((uniqueResults) => {
        res.pageElements.forEach((item) => {
          if (!uniqueResults.some((it) => it.id === item.id)) {
            uniqueResults.push(item);
          }
        });

        return uniqueResults;
      });

      return List(res.pageElements)
        .filter(
          (activity) =>
            (!activity.sentAutomatically ||
              activity.recipient !== RecipientTypes.COMPANY ||
              (activity.sentAutomatically && activity.type === CommunicationModality.IN_APP)) &&
            !activity?.subject?.includes?.('New email notification'),
        )
        .map(OrderLog.fromActivity)
        .filter(Boolean)
        .sortBy((it) => -it?.dateTime!) as List<OrderLog>;
    });
    const notEventLogs = orderLogItems.filter((it) => !it?.isEventLog);
    setPastActions(notEventLogs.filter(isInThePast));
    setNextSteps(notEventLogs.filter(isInTheFuture));
    setOrderLogs(orderLogItems.filter(isInThePast).filter((it) => Boolean(it.message)));
  };

  const fetchClosingActivities = (pageSize = 10) => {
    if (activitySource !== ActivitySourceType.ORDER) {
      return Promise.resolve();
    }

    return activityApi
      .getOrderClosingAndBOLLogs(sourceId, 0, pageSize)
      .then((res) => {
        setTotalClosingActivities(res.totalElements);

        setUniqueClosingActivities((uniqueResults) => {
          res.pageElements.forEach((item) => {
            if (!uniqueResults.some((it) => it.id === item.id)) {
              uniqueResults.push(item);
            }
          });

          return uniqueResults;
        });

        setClosingOrderLogs((uniqueResults) => {
          res.pageElements.forEach((item) => {
            if (!uniqueResults.some((it) => it.id === item.id)) {
              const log = OrderLog.fromActivity(item);

              if (log) {
                uniqueResults = uniqueResults.push(log);
              }
            }
          });

          return uniqueResults.sortBy((it) => -it?.dateTime!) as List<OrderLog>;
        });
      })
      .catch(() => {});
  };

  const reload = () => {
    if (isClosing) {
      return fetchClosingActivities();
    } else {
      return fetchActivities(sourceId);
    }
  };

  const loadMore = () => {
    if (isClosing) {
      return fetchClosingActivities(10000);
    } else {
      return fetchActivities(sourceId, 10000);
    }
  };

  useEffect(() => {
    if (!sourceId) {
      return () => {};
    }

    if (!isSessionExpired) {
      reload();

      const interval = setInterval(reload, 15e3);
      return () => clearInterval(interval);
    }
  }, [sourceId, isClosing, isSessionExpired]);

  return (
    <ActivityLogContext.Provider
      value={{
        reload,
        pastActions,
        nextSteps,
        orderLogs,
        closingOrderLogs,
        newNoteDefault,
        setNewNoteDefault,
        newMessageDefault,
        setNewMessageDefault,
        newEmailDefault,
        setNewEmailDefault,
        newCallDefault,
        setNewCallDefault,
        selectedTemplateDTO,
        setSelectedTemplateDTO,
        loadMore,
        totalClosingActivities,
        totalActivities,
        uniqueActivities,
        uniqueClosingActivities,
      }}
    >
      {children}
    </ActivityLogContext.Provider>
  );
};
