import { FC } from 'react';

import { BodyBigText, Modal, ModalActions, StorageQuote, Switch } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';

import { useOrderClosingContext, useOrderSettingUnits } from 'admin/components/OrderWindow/context';

interface StorageChargeModalProps {
  storageQuote: StorageQuote | null;
  onSave: (value: StorageQuote) => void;
  onCancel: () => void;
}

const RATE_PER_CU_FT = 'rateByCuFt';
const RATE_BY_VAULT = 'rateByVault';
const RATE_BY_ROOM = 'rateByRoom';
const DISPLAY_RATE_TO_CUSTOMER = 'displayOnCustomerPage';
const INCLUDE_IN_QUOTE = 'includeInQuote';

const labels = {
  [RATE_PER_CU_FT]: 'By CuFt',
  [RATE_BY_VAULT]: 'By Vault',
  [RATE_BY_ROOM]: 'By Room',
  [DISPLAY_RATE_TO_CUSTOMER]: 'Display rate to Customer',
  [INCLUDE_IN_QUOTE]: 'Include in Quote',
};

const StorageChargeModal: FC<StorageChargeModalProps> = ({ storageQuote, onCancel, onSave }) => {
  const { closingTitle, isCompleted, isLockSales } = useOrderClosingContext();
  const methods = useForm<StorageQuote>({
    defaultValues: storageQuote?.toJS() as any,
  });
  const { handleSubmit } = methods;
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  labels[RATE_PER_CU_FT] = `By (${moveUnitLabelsShort})`;

  const handleSave = (data: any) => {
    onSave(new StorageQuote(data));
  };

  const actions: ModalActions[] = [
    {
      label: 'cancel',
      onClick: onCancel,
    },
    {
      label: 'save',
      onClick: handleSubmit(handleSave as any),
      disabled: isLockSales || isCompleted,
    },
  ];

  return (
    <Modal title={`Storage Charge ${closingTitle}`} open onClose={onCancel} actions={actions}>
      {/** @ts-ignore */}
      <FormProvider {...methods}>
        <Box mt={2}>
          <BodyBigText>
            <b>Display Options</b>
          </BodyBigText>
        </Box>
        <Box display="flex" flexDirection="column" flexWrap="nowrap">
          <Box>
            <Switch
              disabled={isCompleted || isLockSales}
              color="primary"
              name={DISPLAY_RATE_TO_CUSTOMER}
              label={labels[DISPLAY_RATE_TO_CUSTOMER]}
            />
          </Box>
          <Box>
            <Switch
              disabled={isCompleted || isLockSales}
              color="primary"
              name={INCLUDE_IN_QUOTE}
              label={labels[INCLUDE_IN_QUOTE]}
            />
          </Box>
        </Box>
      </FormProvider>
    </Modal>
  );
};

export { StorageChargeModal };
