import { addHours, addMinutes, format, startOfDay } from 'date-fns';

import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';

const DEFAULT_HOURS = 12;
const AM = 'AM';
const PM = 'PM';

const formatTime = (hours: number, minutes: number) => {
  return format(addMinutes(addHours(startOfDay(new Date()), hours), minutes), TIME_STRING_FORMAT);
};

export const convertLocalizedTimeToLocalTime = (time?: string | null) => {
  if (time) {
    const timeValue = time.split(' ');
    const timesOfDay = timeValue[1];
    const timeParts = timeValue[0].split(':');
    const hoursAmount = +timeParts[0];
    const minutesAmount = +timeParts[1];

    const isMidnight = timesOfDay === AM;
    const isMidday = timesOfDay === PM;

    if (isMidnight && hoursAmount === DEFAULT_HOURS) {
      return formatTime(hoursAmount + DEFAULT_HOURS, minutesAmount);
    } else if ((isMidnight && hoursAmount < DEFAULT_HOURS) || (isMidday && hoursAmount === DEFAULT_HOURS)) {
      return formatTime(hoursAmount, minutesAmount);
    }
    return formatTime(hoursAmount + DEFAULT_HOURS, minutesAmount);
  }
  return '';
};
