enum InvoiceModalType {
  ACCOUNT_INVOICE = 'ACCOUNT_INVOICE',
  ORDER_INVOICE = 'ORDER_INVOICE',
  CUSTOM_INVOICE = 'CUSTOM_INVOICE',
  CLOSING_INVOICE = 'CLOSING_INVOICE',
}

enum InvoicesPopoverName {
  ACCOUNT_INVOICE = 'Account Invoice',
  ORDER_INVOICE = 'Order Invoice',
  CUSTOM_INVOICE = 'Custom Invoice',
  CLOSING_INVOICE = 'Closing Invoice',
}

export { InvoicesPopoverName, InvoiceModalType };
