import React, { useEffect, useState } from 'react';

import { BodySmallText, BodyText, Button } from '@elromcoinc/react-shared';
import { Box, Card, CardActions, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import smsAPI from 'admin/api/SmsAPI';
import { BuySmsAndPhoneNumberModal } from 'admin/components/Settings/components/SmsSettings/modals';
import { formatCurrency } from 'common/utils/Formatter';

const useStyles = makeStyles(({ spacing }) => ({
  cardTitle: {
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  header: {
    padding: '10px',
  },
  content: {
    padding: '0 16px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(4, 1fr)',
    maxWidth: '12.5rem',
    gap: '8px 16px',
    alignItems: 'center',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  roundedCard: {
    '&.MuiCard-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

const Loading = () => <BodySmallText color="primary">Loading...</BodySmallText>;

export const BuySms = ({
  isDisabledEnabledSms,
  isLoadingTwilioAccount,
  twilioPhoneNumbers,
  companyUsageInfo,
  allStripeCustomers,
  onSavaPaymentMethod,
  allSmsSettings,
  onBuyPhoneNumber,
}) => {
  const { smsCount, phoneNumbersPrice, forwardedCallsPrice, smsPrice, lookupsPrice } = companyUsageInfo;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [stripeKey, setStripeKey] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = (res, amount, phone) => {
    if (res) {
      onSavaPaymentMethod(res, amount);
    }
    if (phone) {
      onBuyPhoneNumber(phone);
    }
    setOpenModal(false);
  };

  const moneySpent = phoneNumbersPrice + forwardedCallsPrice + smsPrice + lookupsPrice;

  useEffect(() => {
    smsAPI
      .getStripeKey()
      .then((res) => {
        setStripeKey(res);
      })
      .catch(() => {
        enqueueSnackbar('Failed key for the Stripe account', { variant: 'error' });
      });
  }, []);

  return (
    <Card className={classes.roundedCard}>
      <CardHeader
        title="Current month"
        classes={{ title: classes.cardTitle, content: classes.cardHeader, root: classes.header }}
      />
      <CardContent classes={{ root: classes.content }}>
        <Box className={classes.cardContent}>
          <BodyText>Sms Sent:</BodyText>
          {isLoadingTwilioAccount ? <Loading /> : <BodyText>{smsCount}</BodyText>}
          <BodyText>Phone number rental:</BodyText>
          {isLoadingTwilioAccount ? <Loading /> : <BodyText>{`$${formatCurrency(phoneNumbersPrice, 2)}`}</BodyText>}
          <BodyText>Forwarding call cell spent:</BodyText>
          {isLoadingTwilioAccount ? <Loading /> : <BodyText>{`$${formatCurrency(forwardedCallsPrice, 4)}`}</BodyText>}
          <BodyText>Money spent:</BodyText>
          {isLoadingTwilioAccount ? <Loading /> : <BodyText>{`$${formatCurrency(moneySpent, 4)}`}</BodyText>}
        </Box>
      </CardContent>
      <CardActions classes={{ root: classes.cardAction }}>
        <Button
          size="small"
          color="primary"
          onClick={handleOpenModal}
          disabled={isDisabledEnabledSms || isLoadingTwilioAccount}
          loading={isLoadingTwilioAccount}
        >
          BUY SMS
        </Button>
        {openModal && stripeKey && (
          <BuySmsAndPhoneNumberModal
            stripeKey={stripeKey}
            handleCloseModal={handleCloseModal}
            twilioPhoneNumbers={twilioPhoneNumbers}
            allStripeCustomers={allStripeCustomers}
            allSmsSettings={allSmsSettings}
          />
        )}
      </CardActions>
    </Card>
  );
};

BuySms.propTypes = {
  isDisabledEnabledSms: pt.bool,
  isLoadingTwilioAccount: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  twilioPhoneNumbers: pt.array,
  // eslint-disable-next-line react/forbid-prop-types
  companyUsageInfo: pt.object,
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array,
  onSavaPaymentMethod: pt.func,
  // eslint-disable-next-line react/forbid-prop-types
  allSmsSettings: pt.object.isRequired,
  onBuyPhoneNumber: pt.func,
};

BuySms.defaultProps = {
  isDisabledEnabledSms: false,
  isLoadingTwilioAccount: false,
  twilioPhoneNumbers: null,
  companyUsageInfo: null,
  allStripeCustomers: null,
  onSavaPaymentMethod: null,
  onBuyPhoneNumber: null,
};
