// @ts-ignore
import autodux from 'autodux';
import { parseISO } from 'date-fns';
import { Map } from 'immutable';
import { useDispatch } from 'react-redux';

import notificationsAPI from 'admin/api/notificationsAPI';
import { NotificationCategories } from 'admin/constants/NotificationCategories';
import { NotificationEntity } from 'admin/entities/Notification';
import { ApplicationEventType, EventTypeSelectionDto, UserApplicationEventType } from 'common-types';

const {
  reducer,
  actions: {
    setOpenPanels: setNotificationOpenPanels,
    setCount: setNotificationCount,
    requestChangeNotificationStatus,
    changeNotificationStatusSuccess,
    setIsFetchingNewNotificationCount: setNotificationIsFetchingNewNotificationCount,
    fetchNewNotificationCountSuccess,
    requestNewNotifications,
    requestDismissedNotifications,
    fetchDismissedNotificationsSuccess,
    fetchDismissedNotificationsFailure,
    setDismissed,
    setUnread,
    fetchNewNotificationsSuccess,
    fetchNewNotificationsFailure,
    requestDismissAll,
    fetchDismissAllSuccess,
    clearNotifications,
    setIsOpenDrawer: setNotificationIsOpenDrawer,
    requestMarkAllAsRead,
    requestNotificationsForEventCategory,
    setInFlightByCategory,
    fetchNotificationsForEventCategorySuccess,
    setIsNotNewNotifications,
    setIsFetchingIsMuted,
    fetchToggleIsMutedSuccess,
    fetchLatestNotificationsSuccess,
    setIsFetchingSettings,
    fetchNotificationSettingsSuccess,
    dismissNotification,
    undoDismissNotification,
    readNotification,
    unreadNotification,
  },
  selectors: {
    getOpenPanels: getNotificationOpenPanels,
    getIsFetchingNewNotificationCount: getNotificationIsFetchingNewNotificationCount,
    getIsOpenDrawer: getNotificationIsOpenDrawer,
    getInFlightByCategory: getNotificationInFlightByCategory,
    getIsFetchingIsMuted: getNotificationIsFetchingIsMuted,
    getIsFetchingSettings: getNotificationIsFetchingSettings,
    getIsFetchingNewNotifications,
    getIsFetchingDismissedNotifications,
    getIsInProgressReadDismissAll,
    getIsInProgressChangeStatus,
    getUnreadNotificationPageIndex,
    getDismissedNotificationPageIndex,
    getCount: getNotificationCount,
    getDismissed: getNotificationDismissed,
    getUnread: getNotificationUnread,
    getSettings: getNotificationSettings,
    getIsMuted: getNotificationIsMuted,
    getMaxStartDateTime: getNotificationMaxStartDateTime,
  },
} = autodux({
  slice: 'notifications',
  initial: {
    openPanels: [],
    count: 0,
    unread: [],
    dismissed: [],
    isFetchingSettings: false,
    settings: [],
    isFetchingDismissedNotifications: false,
    isFetchingNewNotifications: false,
    isFetchingNewNotificationCount: false,
    isMuted: true,
    isFetchingIsMuted: false,
    maxStartDateTime: new Date().valueOf(),
    isOpenDrawer: false,
    isInProgressChangeStatus: [],
    isInProgressReadDismissAll: false,
    dismissedNotificationPageIndex: 0,
    unreadNotificationPageIndex: 0,
    byCategory: Map(),
    inFlightByCategory: false,
  },
  actions: {
    requestChangeNotificationStatus: (state: any, id: number) => ({
      ...state,
      isInProgressChangeStatus: [...state.isInProgressChangeStatus, id],
    }),
    changeNotificationStatusSuccess: (state: any, id: number) => ({
      ...state,
      isInProgressChangeStatus: state.isInProgressChangeStatus.filter((i: number) => i !== id),
    }),
    fetchNewNotificationCountSuccess: (state: any, count: number) => ({
      ...state,
      count,
      isFetchingNewNotificationCount: false,
    }),
    requestNewNotifications: (state: any) => ({
      ...state,
      isFetchingNewNotifications: true,
    }),
    requestDismissedNotifications: (state: any) => ({
      ...state,
      isFetchingDismissedNotifications: true,
    }),
    fetchDismissedNotificationsSuccess: (
      state: any,
      { pageElements, pageIndex }: { pageElements: NotificationEntity[]; pageIndex: number },
    ) => ({
      ...state,
      dismissed: [...state.dismissed, ...pageElements],
      isFetchingDismissedNotifications: false,
      dismissedNotificationPageIndex: pageIndex,
      maxStartDateTime:
        Math.max(
          state.maxStartDateTime,
          ...(pageElements || []).map(({ dateTime }) => {
            return typeof dateTime === 'string' ? parseISO(dateTime).valueOf() : dateTime;
          }),
        ) || state.maxStartDateTime,
    }),
    fetchDismissedNotificationsFailure: (state: any) => ({
      ...state,
      isFetchingDismissedNotifications: false,
      dismissed: [],
    }),
    fetchNewNotificationsFailure: (state: any) => ({
      ...state,
      isFetchingNewNotifications: false,
      unread: [],
    }),
    fetchNewNotificationsSuccess: (
      state: any,
      { pageElements, pageIndex }: { pageElements: NotificationEntity[]; pageIndex: number },
    ) => ({
      ...state,
      unread: [...state.unread, ...pageElements],
      isFetchingNewNotifications: false,
      unreadNotificationPageIndex: pageIndex,
      maxStartDateTime:
        Math.max(
          state.maxStartDateTime,
          ...(pageElements || []).map(({ dateTime }) => {
            return typeof dateTime === 'string' ? parseISO(dateTime).valueOf() : dateTime;
          }),
        ) || state.maxStartDateTime,
    }),
    requestDismissAll: (state: any) => ({
      ...state,
      isInProgressReadDismissAll: true,
    }),
    fetchDismissAllSuccess: (state: any) => ({
      ...state,
      isInProgressReadDismissAll: false,
    }),
    clearNotifications: (state: any) => ({
      ...state,
      settings: [],
      dismissed: [],
      maxStartDateTime: new Date().valueOf(),
      unread: [],
    }),
    requestMarkAllAsRead: (state: any) => ({
      ...state,
      isInProgressReadDismissAll: true,
    }),
    requestNotificationsForEventCategory: (state: any) => ({
      ...state,
      inFlightByCategory: true,
    }),
    fetchNotificationsForEventCategorySuccess: (state: any, byCategory: { [key: string]: ApplicationEventType[] }) => ({
      ...state,
      byCategory: Map(byCategory),
      inFlightByCategory: false,
    }),
    setIsNotNewNotifications: (state: any, notifications: NotificationEntity[]) => ({
      ...state,
      unread: state.unread.map((notification: NotificationEntity) => {
        return notifications.find((n) => n.id === notification.id) ? notification.set('isNew', false) : notification;
      }),
    }),
    fetchToggleIsMutedSuccess: (state: any, isMuted: boolean) => ({
      ...state,
      isFetchingIsMuted: false,
      isMuted,
    }),
    fetchLatestNotificationsSuccess: (state: any, notifications: NotificationEntity[]) => ({
      ...state,
      unread: [...notifications, ...state.unread],
      isFetchingNewNotifications: false,
      maxStartDateTime:
        Math.max(
          state.maxStartDateTime,
          ...(notifications || []).map(({ dateTime }) => {
            return typeof dateTime === 'string' ? parseISO(dateTime).valueOf() : dateTime;
          }),
        ) || state.maxStartDateTime,
    }),
    fetchNotificationSettingsSuccess: (state: any, settings: (UserApplicationEventType & EventTypeSelectionDto)[]) => ({
      ...state,
      isFetchingSettings: false,
      settings,
    }),
    dismissNotification: (state: any, notification: NotificationEntity) => ({
      ...state,
      unread: state.unread.filter((n: NotificationEntity) => n.id !== notification.id),
      dismissed: [notification, ...state.dismissed],
    }),
    undoDismissNotification: (state: any, notification: NotificationEntity) => ({
      ...state,
      unread: [notification, ...state.unread],
      dismissed: state.dismissed.filter((n: NotificationEntity) => n.id !== notification.id),
    }),
    readNotification: (state: any, notification: NotificationEntity) => ({
      ...state,
      unread: state.unread.map((n: NotificationEntity) => (n.id === notification.id ? notification : n)),
    }),
    unreadNotification: (state: any, notification: NotificationEntity) => ({
      ...state,
      unread: state.unread.map((n: NotificationEntity) => (n.id === notification.id ? notification : n)),
    }),
  },
});

export function fetchNewNotificationCount() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setNotificationIsFetchingNewNotificationCount(true));
    return notificationsAPI
      .getNewNotificationsCount()
      .then((response) => dispatch(fetchNewNotificationCountSuccess(response)))
      .catch((error) => dispatch(setNotificationIsFetchingNewNotificationCount(false)));
  };
}

export function changeNotificationStatus(notification: NotificationEntity) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestChangeNotificationStatus(notification.id));
    return notificationsAPI
      .changeNotificationStatus(notification.id, notification.status)
      .then((response) => {
        dispatch(changeNotificationStatusSuccess(notification.id));
        fetchNewNotificationCount()(dispatch);

        if (!response) {
          fetchNewNotification()(dispatch).then(() => {
            fetchDismissedNotification()(dispatch);
          });
        }
      })
      .catch(() => {
        dispatch(changeNotificationStatusSuccess(notification.id));
      });
  };
}

export function fetchDismissedNotification({ pageIndex = 0, pageSize }: Partial<PageParams> = {}) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestDismissedNotifications());

    if (pageIndex === 0) {
      dispatch(setDismissed([]));
    }

    return notificationsAPI
      .getDismissedNotifications({ pageIndex, pageSize })
      .then(({ pageElements, pageIndex }) => {
        dispatch(
          fetchDismissedNotificationsSuccess({
            pageElements: pageElements.map((notification) => new NotificationEntity(notification)),
            pageIndex,
          }),
        );
      })
      .catch(() => {
        dispatch(fetchDismissedNotificationsFailure());
      });
  };
}

export function fetchNewNotification({ pageIndex = 0, pageSize }: Partial<PageParams> = {}) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestNewNotifications());

    if (pageIndex === 0) {
      dispatch(setUnread([]));
    }

    return notificationsAPI
      .getNewNotifications({ pageIndex, pageSize })
      .then(({ pageElements, pageIndex }) => {
        dispatch(
          fetchNewNotificationsSuccess({
            pageElements: pageElements.map((notification) => new NotificationEntity(notification)),
            pageIndex,
          }),
        );
      })
      .catch(() => {
        dispatch(fetchNewNotificationsFailure());
      });
  };
}

export function dismissAll() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestDismissAll());
    return notificationsAPI
      .markAllNotificationsDismissed()
      .then(() => {
        dispatch(fetchDismissAllSuccess());
        fetchDismissedNotification()(dispatch);
        fetchNewNotification()(dispatch);
        fetchNewNotificationCount()(dispatch);
      })
      .catch(() => dispatch(fetchDismissAllSuccess()));
  };
}

export function markAllAsRead() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestMarkAllAsRead());
    return notificationsAPI
      .markAllNotificationsDelivered()
      .then(() => {
        dispatch(fetchDismissAllSuccess());
        fetchNewNotification()(dispatch);
        fetchNewNotificationCount()(dispatch);
      })
      .catch(() => {
        dispatch(fetchDismissAllSuccess());
      });
  };
}

const categories = [NotificationCategories.CUSTOMER, NotificationCategories.EMPLOYEE, NotificationCategories.SYSTEM];

export function fetchAllNotifications() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestNotificationsForEventCategory());
    return Promise.all(categories.map((category) => notificationsAPI.getNotificationsBy(category)))
      .then((notifications) => {
        const byCategory = categories.reduce((acc, category, index) => {
          acc[category] = notifications[index].filter((it) => it.notificationsEnabled);
          return acc;
        }, {} as { [key: string]: ApplicationEventType[] });
        dispatch(fetchNotificationsForEventCategorySuccess(byCategory));
      })
      .catch(() => {
        dispatch(setInFlightByCategory(false));
      });
  };
}

export function toggleIsMute() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setIsFetchingIsMuted(true));
    return notificationsAPI
      .toggleIsMuted()
      .then((value) => dispatch(fetchToggleIsMutedSuccess(value)))
      .catch(() => {
        dispatch(setIsFetchingIsMuted(false));
      });
  };
}

export function fetchIsMuted() {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setIsFetchingIsMuted(true));
    return notificationsAPI
      .getIsMuted()
      .then((value) => {
        dispatch(fetchToggleIsMutedSuccess(value));
        return value;
      })
      .catch(() => {
        dispatch(setIsFetchingIsMuted(false));
      });
  };
}

export function fetchLatestNotification(startDateTime: number) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(requestNewNotifications());
    return notificationsAPI
      .getNewNotifications({ startDateTime: startDateTime || Date.now() })
      .then(({ pageElements }) =>
        dispatch(
          fetchLatestNotificationsSuccess(
            pageElements.map((notification) => new NotificationEntity({ ...notification, isNew: true })),
          ),
        ),
      )
      .catch((error) => dispatch(fetchNewNotificationsFailure(error)));
  };
}

export function fetchNotificationSettings(employeeId: number) {
  return (dispatch: ReturnType<typeof useDispatch>) => {
    dispatch(setIsFetchingSettings(true));
    return Promise.all([
      notificationsAPI.getEmployeeNotificationSubscriptions(employeeId),
      notificationsAPI.getNotificationSubscriptions(),
    ])
      .then(([employeeSubscriptions, subscriptions]) => {
        const result = Object.values(employeeSubscriptions)
          .filter((category) => category.length)
          .reduce((concat, value) => [...concat, ...value], [])
          .map(({ type, ...rest }) => {
            const fullItem = { ...type, ...rest, eventTypeId: type.id };
            const subscription = subscriptions.find(({ eventTypeId }) => eventTypeId === fullItem.id);

            return {
              ...subscription,
              ...fullItem,
            };
          })
          .filter((n) => n.notificationsEnabled);

        dispatch(fetchNotificationSettingsSuccess(result));
      })
      .catch(() => {
        dispatch(setIsFetchingSettings(false));
      });
  };
}

export default reducer;

export {
  setNotificationOpenPanels,
  getNotificationOpenPanels,
  setNotificationCount,
  getNotificationIsFetchingNewNotificationCount,
  clearNotifications,
  setNotificationIsOpenDrawer,
  getNotificationIsOpenDrawer,
  getNotificationInFlightByCategory,
  setIsNotNewNotifications,
  getNotificationIsFetchingIsMuted,
  setIsFetchingSettings,
  dismissNotification,
  undoDismissNotification,
  readNotification,
  unreadNotification,
  getNotificationIsFetchingSettings,
  getIsFetchingNewNotifications,
  getIsFetchingDismissedNotifications,
  getIsInProgressReadDismissAll,
  getIsInProgressChangeStatus,
  getUnreadNotificationPageIndex,
  getDismissedNotificationPageIndex,
  getNotificationCount,
  getNotificationDismissed,
  getNotificationUnread,
  getNotificationSettings,
  getNotificationIsMuted,
  getNotificationMaxStartDateTime,
};
