import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { DeliveryTruckColor } from 'admin/components/OrderWindow/SchedulerBox/common';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 2,
    bottom: theme.spacing(1),
    height: theme.spacing(10),
    borderRadius: 5,
    border: `1px solid ${DeliveryTruckColor}`,
    pointerEvents: 'none',
    boxSizing: 'border-box',
  },
  estimatedBlockBackground: {
    backgroundColor: DeliveryTruckColor,
  },
  overtime: {
    backgroundColor: theme.palette.secondary.main,
  },
  overTimeBorder: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}));

interface TravelTimeBlockProps {
  startDayOffsetInMinutes: number;
  start: number;
  width: number;
  widthCoefficient: number;
  overtimeStart: number;
  overtime: number;
  bordered: boolean;
}

export const TravelTimeBlock = ({
  startDayOffsetInMinutes,
  start,
  width: widthInPixels,
  widthCoefficient,
  overtime,
  overtimeStart,
  bordered,
}: TravelTimeBlockProps) => {
  const classes = useStyles();
  const width = widthInPixels * widthCoefficient;
  const left = start * widthCoefficient;
  const overtimeWidth = Math.abs(overtime) * widthCoefficient;
  const overtimeLeft = (overtimeStart - startDayOffsetInMinutes) * widthCoefficient;
  return !!width ? (
    <>
      <Box
        left={left}
        width={width}
        className={clsx(classes.estimatedBlock, {
          [classes.estimatedBlockBackground]: !bordered,
        })}
      />
      {!!overtime && (
        <Box
          left={overtimeLeft}
          width={overtimeWidth}
          className={clsx(classes.estimatedBlock, classes.overTimeBorder, {
            [classes.overtime]: overtime > 0 && !bordered,
          })}
        />
      )}
    </>
  ) : (
    <Box />
  );
};
