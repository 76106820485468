import React, { useEffect, useState } from 'react';
import { Button, Form, PhoneInput, Switch } from '@elromcoinc/react-shared';
import { Box, FormControlLabel, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, string } from 'yup';
import pt from 'prop-types';
import SettingName from 'admin/constants/SettingName';
import smsAPI from 'admin/api/SmsAPI';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setSmsSettings } from 'admin/autodux/MessageAutodux';

const FORWARDING_NUMBER = 'callForwardingNumber';
const TOGGLE_FORWARDING = 'enableCallForwarding';

const labels = {
  [FORWARDING_NUMBER]: 'Forwarding number',
  [TOGGLE_FORWARDING]: ' Toggle Forwarding'
};

const schemaForwardingNumber = object().shape({
  [TOGGLE_FORWARDING]: boolean().label(labels[TOGGLE_FORWARDING]),
  [FORWARDING_NUMBER]: string()
    .label(labels[FORWARDING_NUMBER])
    .when(TOGGLE_FORWARDING, {
      is: true,
      then: string()
        .matches(/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i, 'Invalid phone number')
        .transform(value => (!value ? null : value))
        .nullable()
        .required()
    })
});

const { SMS_ENABLE_CALL_FORWARDING, SMS_FORWARDING_NUMBER } = SettingName;

export const ForwardingNumberForm = ({ isDisabledEnabledSms, isLoadingTwilioAccount, defaultValues }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const errorMessageOptions = { variant: 'error' };
  const branchId = 1;

  const { handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(schemaForwardingNumber),
    defaultValues
  });

  useEffect(() => {
    setValue(TOGGLE_FORWARDING, defaultValues[TOGGLE_FORWARDING]);
    setValue(FORWARDING_NUMBER, defaultValues[FORWARDING_NUMBER]);
  }, [defaultValues]);

  const handleSubmitForm = data => {
    setIsProcessing(true);
    Promise.all([
      smsAPI
        .saveCallForwardingNumber({
          branchId,
          name: SMS_FORWARDING_NUMBER,
          value: data[FORWARDING_NUMBER] || ''
        })
        .catch(() => {
          enqueueSnackbar('Cannot save call forwarding number', errorMessageOptions);
        })
        .then(() => setIsProcessing(false)),
      smsAPI
        .saveEnableCallForwarding({
          branchId,
          name: SMS_ENABLE_CALL_FORWARDING,
          value: data[TOGGLE_FORWARDING]
        })
        .catch(() => {
          enqueueSnackbar('Cannot turned on/off enable call forwarding', errorMessageOptions);
        })
        .then(() => setIsProcessing(false))
    ]).then(() => {
      dispatch(setSmsSettings({ ...defaultValues, ...data }));
    });
  };

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} noValidate={false}>
      <Box>
        <FormControlLabel
          control={
            <>
              <Switch
                name={TOGGLE_FORWARDING}
                color="primary"
                control={control}
                disabled={isLoadingTwilioAccount || isDisabledEnabledSms}
              />
            </>
          }
          label="Enable call forwarding"
        />
      </Box>
      <Box>
        <Grid item xs={12}>
          <PhoneInput
            label={labels[FORWARDING_NUMBER]}
            name={FORWARDING_NUMBER}
            disabled={isDisabledEnabledSms || isProcessing}
            fullWidth
            control={control}
          />
        </Grid>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Button
          color="primary"
          size="small"
          type="submit"
          disabled={isDisabledEnabledSms || isLoadingTwilioAccount || isProcessing}
          loading={isLoadingTwilioAccount || isProcessing}>
          save
        </Button>
      </Box>
    </Form>
  );
};

ForwardingNumberForm.propTypes = {
  isDisabledEnabledSms: pt.bool,
  isLoadingTwilioAccount: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: pt.object.isRequired
};

ForwardingNumberForm.defaultProps = {
  isDisabledEnabledSms: false,
  isLoadingTwilioAccount: false
};
