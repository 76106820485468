import React, { useEffect, useState } from 'react';

import {
  AccountType,
  BodySmallText,
  Button,
  CircularProgress,
  IconButton,
  Order,
  OrderStatuses,
  statusIds,
  statusesById,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/CallMadeTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import MenuIcon from '@material-ui/icons/Menu';
import MinimizeIcon from '@material-ui/icons/Minimize';
import PrintIcon from '@material-ui/icons/Print';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import orderApi from 'admin/api/OrderAPI';
import { PrintOrderModal } from 'admin/components/OrderWindow/PrintOrderModal';
import { UnreadMessagesButton } from 'admin/components/OrderWindow/buttons';
import { useOrderChangeSet } from 'admin/components/OrderWindow/context';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { UnsavedChanges } from 'admin/components/UnsavedChanges';
import { OrderWindowPages } from 'admin/constants/OrderWindowPages';
import { useMobile } from 'admin/hooks/useMobile';
import { LabelFab } from 'common/components/Widgets';

import CloneButton from './blocks/CloneButton';

const Root = styled.div`
  height: 51px;
  background-color: ${({ $color }) => $color};
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  &.draggable-handle {
    cursor: move;
  }
  &.minimize .hide-button {
    display: none !important;
  }
`;

const Title = styled(Typography)`
  && {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: inherit;
  }
`;

const MiddleBox = styled.div`
  color: inherit;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const RightBox = styled.div`
  text-align: right;
  white-space: nowrap;
  & button:first-child {
    margin-right: 10px;
  }
`;

const WhiteTextButton = styled(Button)`
  && {
    color: white;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-transform: none;
  }
`;

const WhiteIconButton = styled(IconButton)`
  && {
    color: white;
  }
`;

const requireTruckStatuses = [statusIds.BOOKED, statusIds.RESERVED, statusIds.CAN_BOOK_ONLINE];

export default function TitleBar(props) {
  const { showSaveDialog } = useOrderChangeSet();
  const {
    inFlight,
    title,
    minimized,
    onMinimize,
    onClose,
    setCurrentPage,
    currentPage,
    onSave,
    saveEnabled,
    orderId,
    hasInventory,
    isLocked,
    onRelease,
    parentWidth,
    order,
    orderViewRef,
    saveStatusColor,
    inFlightRecalculateOrder,
  } = props;

  const accountType = order?.customerInfo?.accountType;

  const displayUnreadMessages =
    currentPage !== OrderWindowPages.HISTORY ||
    (currentPage === OrderWindowPages.HISTORY && accountType !== AccountType.PRIVATE);

  const fullScreen = parentWidth < 850 && !minimized;
  const isMobile = useMobile();
  const [showNotSavedDialog, setShowNotSavedDialog] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();
  const { setShowAlert, alertProps } = useAlert({
    confirmTitle: 'Print',
    onConfirm: () => ({}),
  });
  const { enqueueSnackbar } = useSnackbar();
  const [inFlightSaving, setInFlightSaving] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setBackgroundColor(
      order ? OrderStatuses.find((it) => it.id === order.status).color : statusesById[statusIds.DEAD_LEAD].color,
    );
  }, [order?.orderId]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItemClick = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    if (saveEnabled) {
      setShowNotSavedDialog(true);
    } else {
      onClose();
    }
  };

  const handleDiscard = () => {
    orderApi
      .cancelOrderUpdates(orderId)
      .catch()
      .then(() => {
        setShowNotSavedDialog(false);
        onClose();
      });
  };

  const handleShowPrint = () => {
    setShowAlert(true);
  };

  const handleSaveAndClose = async () => {
    const shouldValidateTruck = requireTruckStatuses.includes(order.status);

    if (shouldValidateTruck) {
      const allTruckAssignmentsForCurrentOrder = order?.services.reduce((acc, service) => {
        return acc + service?.truckAssignments.filter((ta) => !ta.delivery).size;
      }, 0);

      const allTrucksCount = order?.services.reduce((acc, service) => {
        return acc + service?.quote?.numberOfTrucks.value();
      }, 0);

      if (allTrucksCount > allTruckAssignmentsForCurrentOrder) {
        enqueueSnackbar(
          order?.isLongDistance()
            ? 'Please assign the correct number of trucks for both pickup and delivery'
            : `You need to assign more ${
                allTrucksCount - allTruckAssignmentsForCurrentOrder
              } trucks to save this changes`,
          { variant: 'warning' },
        );
        return;
      }

      if (allTrucksCount !== allTruckAssignmentsForCurrentOrder) {
        enqueueSnackbar('Each job’s “number of trucks” needs to match the count of “trucks assigned”.', {
          variant: 'warning',
        });
        return;
      }

      let latestOrder;
      setInFlightSaving(true);

      try {
        latestOrder = await orderApi.getOrder(orderId).then(Order.createOrder);
        setInFlightSaving(false);
      } catch {
        setInFlightSaving(false);
      }

      if (latestOrder?.services.get(0).truckAssignments.isEmpty()) {
        enqueueSnackbar('In order to save your changes, please assign a truck to your move', { variant: 'warning' });
        setShowNotSavedDialog(false);
        return;
      }
    }

    onSave();
    onClose();
  };

  const handleOpenOrderPage = () => {
    setCurrentPage(OrderWindowPages.ORDER);
  };

  const handleCloseShowNotSaveDialog = () => setShowNotSavedDialog(false);

  const releaseButton = isLocked && (
    <LabelFab
      className="hide-button"
      variant="extended"
      borderColor="secondary"
      backgroundColor={saveStatusColor ? saveStatusColor : backgroundColor}
      onClick={onRelease}
      onTouchStart={onRelease}
    >
      RELEASE
    </LabelFab>
  );

  return (
    <>
      <Root
        className={classNames(
          !isMobile && !minimized && 'draggable-handle',
          minimized && 'minimize',
          'print-remove',
          'applyTopRadius',
        )}
        $color={saveStatusColor ? saveStatusColor : backgroundColor}
      >
        {!minimized && <VideoButton position="absolute" video="XHjNkdeAeIY" />}
        {fullScreen ? (
          <>
            <IconButton className="hide-button" color="inherit" aria-label="open-menu" onClick={handleMenuClick}>
              {order && <MenuIcon />}
            </IconButton>
            {releaseButton}
            {displayUnreadMessages && <UnreadMessagesButton />}
            <MiddleBox>
              <Title display="inline">
                {!inFlight && !order ? 'ACCESS DENIED' : title}
                {isLocked && ' - LOCKED'}
              </Title>
            </MiddleBox>
            <RightBox>
              <WhiteIconButton aria-label="close" size="small" onClick={handleClose}>
                <CloseIcon fontSize="large" />
              </WhiteIconButton>
            </RightBox>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
              {currentPage === OrderWindowPages.HISTORY && (
                <MenuItem onClick={handleOpenOrderPage}>
                  <Box display="flex" flexDirection="column">
                    Back to Order
                    <BodySmallText>({`#${order.orderNumber}`})</BodySmallText>
                  </Box>
                </MenuItem>
              )}
              <CloneButton
                component={MenuItem}
                onClickComponent={menuItemClick}
                className="hide-button"
                orderId={orderId}
                disabled={saveEnabled}
                hasInventory={hasInventory}
              >
                Clone
              </CloneButton>
              {!isLocked && (
                <MenuItem onClick={showSaveDialog} disabled={!saveEnabled || inFlightRecalculateOrder}>
                  Save
                  {inFlightRecalculateOrder && <CircularProgress size={24} />}
                </MenuItem>
              )}
            </Menu>
          </>
        ) : (
          <>
            <Box textAlign="left" width="285px" className="hide-button" display="flex">
              {currentPage === OrderWindowPages.HISTORY && (
                <WhiteTextButton onTouchStart={handleOpenOrderPage} onClick={!isMobile && handleOpenOrderPage}>
                  <KeyboardArrowLeftIcon />
                  {`Order ID: ${order.orderNumber}`}
                </WhiteTextButton>
              )}
              {displayUnreadMessages && <UnreadMessagesButton />}
            </Box>
            <MiddleBox>
              <Title display="inline" data-testid="orderInfo">
                {!inFlight && !order ? 'ACCESS DENIED' : title}
                {isLocked && ' - LOCKED'}
              </Title>
            </MiddleBox>
            <RightBox>
              {currentPage === OrderWindowPages.ORDER && order && (
                <>
                  <CloneButton
                    borderColor="secondary"
                    className="hide-button"
                    orderId={orderId}
                    disabled={saveEnabled}
                    hasInventory={hasInventory}
                  />
                  {!isLocked && (
                    <LabelFab
                      data-testid="generalSaveButton"
                      className="hide-button"
                      variant="extended"
                      onClick={showSaveDialog}
                      onTouchStart={showSaveDialog}
                      disabled={!saveEnabled || inFlightRecalculateOrder}
                      borderColor="secondary"
                      backgroundColor={saveStatusColor ? saveStatusColor : backgroundColor}
                    >
                      SAVE
                      {inFlightRecalculateOrder && <CircularProgress size={24} />}
                    </LabelFab>
                  )}
                  {releaseButton}
                </>
              )}
              {order && (
                <>
                  <WhiteIconButton
                    aria-label="minimize"
                    size="small"
                    onClick={onMinimize}
                    disableRipple
                    onTouchStart={onMinimize}
                  >
                    {minimized ? <MaximizeIcon fontSize="large" /> : <MinimizeIcon fontSize="large" />}
                  </WhiteIconButton>
                  {!minimized && (
                    <WhiteIconButton
                      aria-label="print"
                      size="small"
                      onClick={handleShowPrint}
                      onTouchStart={handleShowPrint}
                    >
                      <PrintIcon fontSize="large" />
                    </WhiteIconButton>
                  )}
                </>
              )}
              <WhiteIconButton
                aria-label="close"
                size="small"
                data-testid="CloseButton"
                onClick={handleClose}
                onTouchStart={handleClose}
              >
                <CloseIcon fontSize="large" />
              </WhiteIconButton>
            </RightBox>
          </>
        )}
      </Root>
      <UnsavedChanges
        inFlight={inFlightSaving}
        open={showNotSavedDialog}
        onCancel={handleCloseShowNotSaveDialog}
        onDiscard={handleDiscard}
        onSave={handleSaveAndClose}
      />
      <PrintOrderModal setShowAlert={setShowAlert} alertProps={alertProps} order={order} orderViewRef={orderViewRef} />
    </>
  );
}

TitleBar.propTypes = {
  inFlight: PropTypes.bool.isRequired,
  title: PropTypes.string,
  orderId: PropTypes.number,
  isLocked: PropTypes.bool,
  hasInventory: PropTypes.bool,
  minimized: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onMinimize: PropTypes.func.isRequired,
  onRelease: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saveEnabled: PropTypes.bool.isRequired,
  inFlightRecalculateOrder: PropTypes.bool.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  order: PropTypes.instanceOf(Order).isRequired,
  originalOrder: PropTypes.instanceOf(Order).isRequired,
  orderViewRef: PropTypes.object.isRequired,
};

TitleBar.defaultProps = {
  title: 'Order ID: 68333',
  hasInventory: false,
  minimized: false,
  isLocked: false,
};
