import React, { MouseEvent, useState } from 'react';

import { BodyText, Button, Checkbox, Link } from '@elromcoinc/react-shared';
import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { add, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { updateTask } from 'admin/autodux/TaskAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { Task, TaskStatus, TaskTypeName } from 'common-types/Task';

import TaskPriority from './TaskPriority';
import TaskReminderIcon from './TaskReminderIcon';
import TaskSnoozePopover from './TaskSnoozePopover';

const TaskBox = styled(Box)`
  color: black;
  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
`;

const LongTextStyled = styled(Box)`
  max-width: 300px;
  white-space: pre-wrap;
`;

interface TaskReminderProps {
  task: Task;
}

const TaskReminder = ({ task }: TaskReminderProps) => {
  const dispatch = useDispatch<DispatchPromise>();
  const { enqueueSnackbar } = useSnackbar();
  const { sourceId, orderNumber, customerName, subject, notes, isCompleted, dueTime } = task;
  const [snoozeButtonElement, setSnoozeButtonElement] = useState<HTMLElement | null>(null);

  const handleClickOrderNumber = () => {
    dispatch(openOrder(sourceId));
  };

  const handleComplete = () => {
    dispatch(updateTask(task.setStatus(TaskStatus.COMPLETED), undefined)).then(() => {
      enqueueSnackbar('Task is successfully completed', { variant: 'success' });
    });
  };

  const handleSnoozeClick = (event: MouseEvent<HTMLElement>) => {
    setSnoozeButtonElement(event.currentTarget);
  };

  const handleDismiss = () => {
    dispatch(updateTask(task.setStatus(TaskStatus.READ), undefined)).then(() => {
      enqueueSnackbar('Task is successfully dismissed', { variant: 'success' });
    });
  };

  const handleSnoozeClose = () => {
    setSnoozeButtonElement(null);
  };

  const handleSnooze = (selectedSnoozeOption: number) => {
    dispatch(updateTask(task.set('snoozedUntil', add(new Date(), { seconds: selectedSnoozeOption })), undefined)).then(
      () => {
        enqueueSnackbar('Task is successfully snoozed', { variant: 'success' });
      },
    );
  };

  const snoozeId = snoozeButtonElement ? 'task-reminder-snooze-popover' : undefined;

  return (
    <TaskBox py={1}>
      <TaskSnoozePopover
        id={snoozeId}
        onSnooze={handleSnooze}
        onClose={handleSnoozeClose}
        snoozeElement={snoozeButtonElement}
      />
      <Box display="flex" justifyContent="space-between" mt={0.5} position="relative" width="300px">
        <Box my={orderNumber ? 0 : 1.5} display="flex" justifyContent="flex-start" alignItems="center">
          {orderNumber && (
            <Box display="flex" flexWrap="wrap">
              <Box mr={0.5}>
                <Link underline="none" onClick={handleClickOrderNumber}>
                  <BodyText>{`#${orderNumber}`}</BodyText>
                </Link>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center">
                {customerName}
              </Box>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title="Dismiss" placement="top">
            <Box component="span">
              <Button
                color="primary"
                variant="outlined"
                rounded
                size="small"
                textTransform="capitalize"
                onClick={handleDismiss}
              >
                Dismiss
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title="Snooze" placement="top">
            <Box ml={1} component="span">
              <Button
                aria-describedby={snoozeId}
                color="primary"
                variant="outlined"
                rounded
                size="small"
                textTransform="capitalize"
                onClick={handleSnoozeClick}
              >
                Snooze
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title="Complete" placement="top">
            <Box component="span">
              <Checkbox
                sameUncheckedColor
                variant="circle-outlined"
                color="primary"
                checked={isCompleted}
                onChange={handleComplete}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <LongTextStyled>
        <BodyText wordBreak>{subject}</BodyText>
      </LongTextStyled>
      <Box display="flex" alignItems="center" fontStyle="italic">
        <>Due Date: </>
        {`${format(dueTime!, 'Pp')}`}
        <TaskReminderIcon task={task} />
      </Box>
      <Box display="flex" alignItems="center">
        <TaskPriority priority={task.priority} />
        <Box ml={0.5}>{`Type: ${TaskTypeName[task.taskType]}`}</Box>
      </Box>
      <LongTextStyled>
        <BodyText wordBreak>{notes}</BodyText>
      </LongTextStyled>
    </TaskBox>
  );
};

export default TaskReminder;
