import React from 'react';

import { Badge, BodyText, Link, Select, UI_DATE_TIME_FORMAT, formatPhoneNumber } from '@elromcoinc/react-shared';
import { Box, IconButton, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { format, parseISO } from 'date-fns';

import Circle from 'common/components/Circle';

const useStyles = makeStyles({
  iconButton: {
    padding: 0,
  },
  select: {
    '& > div': {
      backgroundColor: `transparent !important`,
      '& > div': {
        backgroundColor: `transparent !important`,
      },
    },
  },
});

const renderDate = (date) => {
  if (!date) return null;

  return (
    <Box minWidth={100}>
      <BodyText>{format(parseISO(date), UI_DATE_TIME_FORMAT)}</BodyText>
    </Box>
  );
};

export const makeColumns = (
  rowsData,
  { setSelectedSendMessage, setOpenEmailModal, salesPersonOptions, handleChangeSalesPerson },
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme(); //todo: seems wrong to use hooks here, maybe put them up through the stack?

  return [
    {
      name: 'id',
      label: '# ACCT',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];
          const { id } = row;
          return (
            <Box minWidth={70}>
              <BodyText>{id}</BodyText>
            </Box>
          );
        },
      },
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];
          const { ownerCustomerInfo } = row;
          const { accountAccessAllowed } = ownerCustomerInfo;
          return (
            <Box minWidth={50}>
              <Tooltip title={accountAccessAllowed ? 'Active' : 'Non Active'}>
                <Circle
                  className="circle"
                  color={accountAccessAllowed ? `${theme.palette.success.main}` : `${theme.palette.error.main}`}
                  width="12px"
                  height="12px"
                />
              </Tooltip>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    /* (not MVP)
      {
      name: 'flags',
      label: 'FLAGS',
      options: {
        customBodyRender: () => (
          <Box minWidth={50}>
            <Circle className="circle" color={theme.palette.warning.main} width="12px" height="12px" />
          </Box>
        ),
        showOnMobileAsExpanded: true,
      },
    },
    */
    {
      name: 'totalOrders',
      label: '# ORD',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { totalOrders } = rowsData[rowIndex];
          return (
            <Box minWidth={70}>
              <BodyText>{totalOrders}</BodyText>
            </Box>
          );
        },
      },
    },
    {
      name: 'name',
      label: 'NAME',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { name } = rowsData[rowIndex];
          return (
            <Box style={{ textTransform: 'capitalize' }} maxWidth={155} minWidth={100}>
              <BodyText wordBreak noWrap data-testid={`accountName-${name}`}>
                {name}
              </BodyText>
            </Box>
          );
        },
      },
    },
    {
      name: 'customer.firstName',
      label: 'CONTACT',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];
          const { ownerCustomerInfo } = row;
          const { firstName, lastName } = ownerCustomerInfo;
          return (
            <Box style={{ textTransform: 'capitalize' }} maxWidth={155} minWidth={100}>
              <BodyText wordBreak noWrap>
                {`${firstName} ${lastName}`}
              </BodyText>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'customer.phone',
      label: 'PHONE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];
          const number = row?.ownerCustomerInfo?.primaryPhone?.number;

          return (
            <Box minWidth={100}>
              <a href={`tel:${formatPhoneNumber(number)}`}>{formatPhoneNumber(number)}</a>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'messages',
      label: ' ',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];

          const handleClick = (event) => {
            event.stopPropagation();
            setSelectedSendMessage(row);
          };

          return (
            <Tooltip title="Send message">
              <Badge badgeContent={row.totalEmployeeUnreadMessages || null}>
                <IconButton color="primary" onClick={handleClick} classes={{ root: classes.iconButton }}>
                  <EmailIcon />
                </IconButton>
              </Badge>
            </Tooltip>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'customer.email',
      label: 'EMAIL',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const row = rowsData[rowIndex];
          const { ownerCustomerInfo } = row;
          const { email } = ownerCustomerInfo;
          const handleClick = (event) => {
            event.stopPropagation();
            setOpenEmailModal(row);
          };
          return (
            <Tooltip title={email}>
              <Box component="span">
                <Link color="primary" underline="none" onClick={handleClick}>
                  <Box maxWidth={155} minWidth={100}>
                    <BodyText noWrap>{email}</BodyText>
                  </Box>
                </Link>
              </Box>
            </Tooltip>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'created',
      label: 'CREATION',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { created } = rowsData[rowIndex];
          return renderDate(created);
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'updated',
      label: 'LAST UPDT.',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { updated } = rowsData[rowIndex];
          return renderDate(updated);
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'leadSource',
      label: 'SOURCE',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { source } = rowsData[rowIndex];
          return <BodyText>{source}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'manager',
      label: 'SALES PERSON',
      options: {
        customBodyRender: (_, { rowIndex }) => {
          const { manager, id } = rowsData[rowIndex];
          return (
            <Box maxWidth={155} minWidth={80}>
              <Select
                className={classes.select}
                options={salesPersonOptions}
                value={manager ? manager.id.toString() : '0'}
                onChange={handleChangeSalesPerson(rowIndex, id)}
                fullWidth
                hiddenLabel
                primaryBackgroundOnSelectedItem
                InputProps={{
                  disableUnderline: true,
                }}
              />
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
  ];
};

export default makeColumns;
