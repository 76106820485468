export interface LeadScoreSettings {
  warmThreshold: number;
  hotThreshold: number;
}

export interface LeadScoreSettingsState {
  inFlight: boolean;
  error?: string | null;
  settings: LeadScoreSettings;
}

export const initialState: LeadScoreSettingsState = {
  inFlight: true,
  error: null,
  settings: { warmThreshold: 0, hotThreshold: 0 },
};
