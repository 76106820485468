import { EmployeeCommissionType } from 'common-types/employee-commission/EmployeeCommissionType';

export const EmployeeCommissionName = {
  [EmployeeCommissionType.HOURLY_RATE]: 'Hourly Rate',
  [EmployeeCommissionType.HOURLY_RATE_AS_HELPER]: 'Hourly Rate as Helper',
  [EmployeeCommissionType.DAILY_RATE]: 'Daily Rate',
  [EmployeeCommissionType.PACKING_COMMISSION]: 'Packing Commission',
  [EmployeeCommissionType.ADDITIONAL_SERVICES_COMMISSION]: 'Additional Services Commission',
  [EmployeeCommissionType.COMMISSION_FROM_THE_TOTAL]: 'Commission from Total',
  [EmployeeCommissionType.PER_DAY_BONUS]: 'Per Day Bonus',
  [EmployeeCommissionType.PER_JOB_BONUS]: 'Per Job Bonus',
};
