import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const UnloadingIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.10000,-0.10000)" fill="#000000" stroke="none">
          <path d="M830 4899 c-302 -35 -599 -217 -764 -469 -74 -112 -83 -172 -40 -259 45 -89 140 -134 237 -110 57 13 106 58 177 158 86 122 231 215 385 246 70 14 186 15 970 13 l890 -3 73 -27 c105 -38 176 -83 252 -158 75 -76 120 -147 158 -252 l27 -73 3 -1237 2 -1238 -1198 0 c-824 0 -1217 3 -1258 11 -144 27 -248 110 -322 259 -40 81 -54 98 -93 123 -26 16 -65 31 -88 34 -134 18 -256 -104 -238 -238 7 -50 62 -169 115 -246 42 -60 126 -146 198 -201 61 -47 189 -109 266 -129 l66 -17 -5 -91 c-13 -219 72 -425 242 -583 78 -73 189 -138 284 -166 360 -106 733 61 891 399 49 105 70 201 70 324 l0 101 430 0 430 0 0 -101 c0 -273 123 -497 354 -644 275 -175 649 -139 891 87 114 106 190 233 226 378 8 32 14 107 14 180 l0 125 73 24 c89 29 216 104 286 168 122 113 204 242 253 398 l28 90 3 630 c4 700 1 747 -54 915 -115 346 -389 603 -752 702 -68 19 -114 22 -391 27 l-314 6 -22 78 c-120 414 -481 717 -915 767 -105 12 -1734 11 -1840 -1z m3310 -1279 c103 -17 186 -48 265 -102 96 -64 167 -143 216 -243 58 -119 69 -175 69 -352 l0 -153 -530 0 -530 0 0 430 0 430 223 0 c122 0 251 -5 287 -10z m550 -1518 c0 -268 -6 -313 -56 -398 -31 -53 -119 -136 -173 -164 -85 -43 -154 -50 -503 -50 l-328 0 0 430 0 430 530 0 530 0 0 -248z m-2990 -1087 c20 -96 -11 -194 -85 -274 -64 -69 -128 -95 -230 -96 -68 0 -89 4 -137 28 -62 31 -116 84 -149 149 -26 50 -37 177 -20 221 l11 27 299 0 300 0 11 -55z m2341 28 c17 -44 6 -171 -20 -221 -33 -65 -87 -118 -149 -149 -77 -38 -190 -39 -269 -4 -129 58 -211 212 -183 346 l11 55 300 0 299 0 11 -27z" />
          <path d="M790 3928 c-27 -7 -113 -88 -376 -351 -372 -373 -384 -388 -407 -521 -15 -89 2 -190 46 -268 23 -42 120 -147 358 -387 180 -181 342 -337 360 -347 52 -28 143 -25 199 6 54 31 100 101 107 165 11 90 -10 120 -230 342 l-201 203 775 2 c771 3 774 3 814 25 77 41 115 105 115 193 0 84 -53 161 -132 194 -33 14 -133 16 -803 16 -421 0 -765 2 -765 4 0 2 88 93 196 202 124 125 204 213 215 239 61 134 -44 296 -191 294 -25 0 -61 -5 -80 -11z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
