import { Box, Paper, createStyles, makeStyles } from '@material-ui/core';

import { useOrderServiceIndex } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    blurQuote: {
      position: 'absolute',
      backgroundColor: palette.action.disabledBackground,
      backdropFilter: 'blur(7px)',
      width: '101%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
  }),
);

export const MultiDayAllDayMode = () => {
  const classes = useStyles();
  const { isSelectedAllServices } = useOrderServiceIndex();

  if (!isSelectedAllServices) {
    return null;
  }

  return (
    <Box className={classes.blurQuote}>
      <Paper>
        <Box p={1}>Select a Day to see details</Box>
      </Paper>
    </Box>
  );
};
