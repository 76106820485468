import React, { FC, useEffect } from 'react';

import { ActivitySourceType, Button, DateTimePicker, Select, TextInput } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { isPast } from 'date-fns';
import { List, getIn } from 'immutable';
import { OptionsObject, useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import activityApi from 'admin/api/activityApi';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import { CreateFollowUpForm } from 'admin/components/OrderWindow/blocks/ActivityManager/CreateFollowUpForm';
import { createFollowUpTask } from 'admin/components/OrderWindow/blocks/ActivityManager/createFollowUpTask';
import { followUpSchema } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useDefaultFollowUpValues } from 'admin/components/OrderWindow/blocks/ActivityManager/useDefaultFollowUpValues';
import { useOrderState } from 'admin/components/OrderWindow/context';
import ManualActivityType from 'admin/constants/ManualActivityType';
import Activity from 'admin/entities/ManualActivity';

const PERFORMED = 'performed';
const PERFORMER_ID = 'performerId';
const CONTACTED_NAME = 'contactedName';
const CONTACTED_EMAIL_OR_PHONE = 'contactedEmailOrPhone';
const NOTE = 'notes';
const CALL_TYPE = 'callType';
const ADDITIONAL_CALL_INFO = 'additionalCallInfo';

const CallTypeInbound = 'Inbound';
const CallTypeOutbound = 'Outbound';

const callTypeOptions: SelectOptions = [
  [CallTypeInbound, 'Inbound'],
  [CallTypeOutbound, 'Outbound'],
];
const additionalCallInfoOptions: SelectOptions = [
  ['No Answer', 'No Answer'],
  ['Busy', 'Busy'],
  ['Wrong Number', 'Wrong Number'],
  ['Left Live Message', 'Left Live Message'],
  ['Left Voicemail', 'Left Voicemail'],
  ['Connected', 'Connected'],
  ['Number Disconnected', 'Number Disconnected'],
];

const labels = {
  [PERFORMED]: 'Activity Date',
  [PERFORMER_ID]: 'Sales Person',
  [CONTACTED_NAME]: 'Person Contacted',
  [CONTACTED_EMAIL_OR_PHONE]: 'Contact Phone',
  [NOTE]: 'Note',
};

const schema = object({
  [PERFORMED]: date()
    .nullable()
    .label(labels[PERFORMED])
    .test('max-date', `${labels[PERFORMED]} should be in past`, (value) => isPast(value as Date))
    .required(),
  [PERFORMER_ID]: string().nullable().label(labels[PERFORMER_ID]).required(),
  [CONTACTED_NAME]: string().nullable().label(labels[CONTACTED_NAME]).required(),
  [CONTACTED_EMAIL_OR_PHONE]: string().nullable().label(labels[CONTACTED_EMAIL_OR_PHONE]),
  [NOTE]: string().label(labels[NOTE]).max(2000).required(),
  ...followUpSchema,
  [CALL_TYPE]: string().nullable().label('Call Type'),
  [ADDITIONAL_CALL_INFO]: string()
    .nullable()
    .label('Outcome')
    .when(CALL_TYPE, (callType, s) => (callType !== CallTypeInbound ? s.required() : s)),
});

const errorMessage: OptionsObject = { variant: 'error' };
const successMessage: OptionsObject = { variant: 'success' };

interface NewNoteProps {
  activitySource: ActivitySourceType;
  sourceId: number;
}

const getDefaultValues = (performerId: number, contactName: string) => ({
  manuallyEnteredActivityType: ManualActivityType.PHONE,
  performerId,
  performed: new Date(),
  [CONTACTED_NAME]: contactName,
  [CONTACTED_EMAIL_OR_PHONE]: '',
  [NOTE]: '',
  involvesSales: false,
  involvesForeman: false,
  involvesDispatch: false,
  involvesCustomer: false,
  [CALL_TYPE]: CallTypeOutbound,
  [ADDITIONAL_CALL_INFO]: null,
});

export const NewCall: FC<NewNoteProps> = ({ sourceId, activitySource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { order } = useOrderState();
  const { reload, newCallDefault, setNewCallDefault } = useActivityLogContext();
  const defaultFollowUp = useDefaultFollowUpValues();
  const managersList = useSelector(getManagerList) as List<{ id: number; fullName: string }>;
  const employees = managersList.map((it) => [it.id, it.fullName]).toJS() as SelectOptions;
  const [inFlight, setInFlight] = React.useState(false);
  const currentUserId = useSelector((state) => getIn(state, ['auth', 'user', 'id'], null)) as number;
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: newCallDefault
      ? newCallDefault
      : { ...getDefaultValues(currentUserId, order?.contactInfo?.fullName?.()), ...defaultFollowUp },
  });
  const { handleSubmit, reset, watch } = methods;
  const callType = watch(CALL_TYPE);

  useEffect(() => {
    return () => {
      setNewCallDefault(methods.getValues());
    };
  }, []);

  const onSubmit = (data: any) => {
    setInFlight(true);

    createFollowUpTask(data, activitySource, sourceId)
      .then(() => {
        enqueueSnackbar('Follow up created successfully', successMessage);
      })
      .catch(() => {});

    const callTypeInfo = callType === CallTypeInbound ? ' ' : ` ${data[ADDITIONAL_CALL_INFO]} `;
    const notes = `${data?.[CALL_TYPE]}${callTypeInfo}${data?.notes}`;
    const activityToSave = Activity.create(data).setActivitySource(activitySource, sourceId).set('notes', notes);
    const activityDTO = activityToSave?.toDTO() as unknown as Activity;

    activityApi
      .saveManuallyEnteredActivity(activityDTO)
      .then(() => {
        enqueueSnackbar('Call saved successfully', successMessage);
        reset({ ...getDefaultValues(currentUserId, order?.contactInfo?.fullName?.()), ...defaultFollowUp });
        reload();
      })
      .catch(() => {
        enqueueSnackbar('Error saving call', errorMessage);
      })
      .then(() => setInFlight(false));
  };

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Box mx={2} my={1} minHeight={250}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap">
              <Box minWidth={100}>
                <Select
                  fullWidth
                  options={callTypeOptions}
                  name={CALL_TYPE}
                  label="Type"
                  InputProps={{ disableUnderline: true }}
                />
              </Box>
              {callType !== CallTypeInbound && (
                <Box minWidth={170}>
                  <Select
                    fullWidth
                    options={additionalCallInfoOptions}
                    name={ADDITIONAL_CALL_INFO}
                    label="Select an outcome"
                    InputProps={{ disableUnderline: true }}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextInput fullWidth label={labels[CONTACTED_NAME]} name={CONTACTED_NAME} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select fullWidth label={labels[PERFORMER_ID]} name={PERFORMER_ID} options={employees} />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/*@ts-ignore*/}
            <DateTimePicker fullWidth name={PERFORMED} label={labels[PERFORMED]} disableFuture />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput fullWidth multiline rows={5} label={labels[NOTE]} name={NOTE} placeholder="Describe the call" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <CreateFollowUpForm />
              <Button
                color="primary"
                variant="text"
                onClick={handleSubmit(onSubmit as any)}
                disabled={inFlight}
                loading={inFlight}
              >
                Log Call
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};
