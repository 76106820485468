import { Valuation, formatCurrency } from '@elromcoinc/react-shared';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const valuationAdded: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('valuation') > -1) {
    const oldValuation = oldOrder.getValueIn(fieldPath) as Valuation;
    const newValuation = newOrder.getValueIn(fieldPath) as Valuation;
    if (oldValuation.cost === 0 && newValuation.cost > 0) {
      return [
        createUpdatedValueDto(
          '',
          `$${formatCurrency(newValuation.cost)}`,
          undefined,
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      ];
    }
  }
  return null;
};

export default valuationAdded;
