export enum DragAndDropType {
  SAVED_CREW_MEMBER = 'SAVED_CREW_MEMBER',
  SAVED_MEMBER_FOREMAN = 'SAVED_CREW_MEMBER_FOREMAN',
  CREW_MEMBER = 'CREW_MEMBER',
  CREW_MEMBER_FOREMAN = 'CREW_MEMBER_FOREMAN',
  AVAILABLE_CREW_MEMBER = 'AVAILABLE_CREW_MEMBER',
  AVAILABLE_MEMBER_FOREMAN = 'AVAILABLE_MEMBER_FOREMAN',
  JOB_CREW = 'JOB_CREW',
  DELIVERY_JOB_CREW = 'DELIVERY_JOB_CREW',
  JOB = 'JOB',
  TRUCK = 'TRUCK',
}
