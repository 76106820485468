import { useEffect, useState } from 'react';

import {
  AdminPermissions,
  Modal,
  RoleName,
  Switch,
  TitleText,
  hasRole,
  useAlert,
  useCurrentUser,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { AppBar, Button, Hidden, IconButton, Menu, MenuItem, Toolbar, makeStyles } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsIcon from '@material-ui/icons/Settings';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import userAPI from 'admin/api/UserAPI';
import { getIsLogout, getIsSessionExpired, logout } from 'admin/autodux/AuthAutodux';
import { fetchCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { getEnableVideoTutorials, updateEnableVideoTutorials } from 'admin/autodux/PlayVideoAutodux';
import { getWizardRun, setWizardActive, setWizardRun, setWizardStep } from 'admin/autodux/WizardAutodux';
import AddNewButtons from 'admin/components/AddNewButtons';
import CompanyLogo from 'admin/components/CompanyLogo';
import CustomerOnline from 'admin/components/CustomerOnline';
import { EmployeeProfileModal } from 'admin/components/EmployeeProfileModal';
import NotificationIcon from 'admin/components/Notifications/NotificationIcon';
import { PlanSettingsModal } from 'admin/components/PlanSettings/PlanSettingsModal';
import { SMTPErrors } from 'admin/components/SMTPErrors';
import { main } from 'admin/components/Wizard';
import { useCompanyBranchName } from 'admin/hooks';
import { getAuthUser } from 'admin/selectors/auth';

import Search from './Search';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  root: { flexGrow: 1 },
  companyLogo: {
    marginRight: spacing(1),
  },
  title: {
    color: palette.background.default,
  },
  section: {
    display: 'flex',
    flexGrow: 1,
    width: 'auto',
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: 8,
    },
  },
  sectionDesktop: {
    '& button': {
      marginRight: spacing(1),
      marginLeft: spacing(1),
    },
  },
  accountButtonLabel: {
    textTransform: 'none',
    fontSize: '1.25rem',
    fontWeight: typography.fontWeightRegular,
  },
  button: {
    margin: spacing(1),
    textTransform: 'capitalize',
    backgroundColor: palette.common.white,
    minWidth: '80px',
  },
  buttonLabel: {
    color: palette.primary.dark,
  },
  planSettingMenuContent: {
    display: 'flex',
    flexDirection: 'column',

    '& > p': {
      margin: 0,
    },
  },
  planSettingLicence: {
    marginTop: spacing(0.25),
    fontSize: '0.75rem',
  },
}));

const ownerDepartmentId = 1;

export default function Bar(props) {
  const { openNavigation } = props;
  const classes = useStyles();
  const user = useSelector(getAuthUser);
  const [profile, setProfile] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const companyName = useCompanyBranchName();
  const canSeeCustomerOnline = useHasPermission(AdminPermissions.PERM_CAN_SEE_CUSTOMERS_ONLINE);
  const isUserMenuOpen = Boolean(userMenuAnchorEl);
  const enableVideoTutorials = useSelector(getEnableVideoTutorials);
  const { setShowAlert, alertProps } = useAlert({
    cancelTitle: 'Cancel',
    confirmTitle: 'Save',
    onConfirm: () => {},
  });
  const history = useHistory();
  const userHasPermissionToSeeSetting = useHasPermission(AdminPermissions.PERM_CAN_SEE_SETTINGS_TAB);
  const currentEmployee = useSelector(getCurrentEmployee);
  const wizardRun = useSelector(getWizardRun);
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  const { setShowAlert: setShowVideoSettings, alertProps: videoSettingsAlertProps } = useAlert({
    confirmTitle: 'Close',
    displayCancel: false,
    onConfirm: (onClose) => {
      onClose();
    },
  });

  const { setShowAlert: setShowPlanSettings, alertProps: planSettingsAlertProps } = useAlert({
    cancelTitle: 'Cancel',
    confirmTitle: 'Save',
    onConfirm: () => {},
  });

  useEffect(() => {
    if (user?.id) {
      setTimeout(() => {
        userAPI.getEmployeeProfile(user.id).then(setProfile);
      }, 1000);
    } else {
      setProfile(null);
    }
  }, [user?.id]);

  const canSeeSetupWizardButton =
    userHasPermissionToSeeSetting && currentEmployee?.departmentId === ownerDepartmentId && !wizardRun;

  const openVideoSettings = () => {
    setShowVideoSettings(true);
    handleUserMenuClose();
  };

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      dispatch(fetchCompanyInfo());
    }
  }, [isSessionExpired, isLogout]);

  const onChangeEnableVideoTutorial = (event, value) => {
    dispatch(updateEnableVideoTutorials(value));
  };

  function handleProfileMenuOpen() {
    setShowAlert(true);
  }

  function handleUserMenuClose() {
    setUserMenuAnchorEl(null);
  }

  function handleUserMenuOpen(event) {
    setUserMenuAnchorEl(event.currentTarget);
  }

  const openPlanSettings = () => {
    setShowPlanSettings(true);
    handleUserMenuClose();
  };

  const hasUserId = user && !!user.id;
  const isProfileAvailableByEmployee = hasUserId && profile && profile.accessibleByEmployee;
  const isElromcoAdmin = hasRole(useCurrentUser(), RoleName.ROLE_ELROMCO_ADMIN);
  const userMenuId = 'user-menu-id';

  const startWizard = () => {
    history.push(main);
    dispatch(setWizardStep(0));
    dispatch(setWizardRun(true));
    dispatch(setWizardActive(true));
    handleUserMenuClose();
  };

  const onLogout = () => {
    dispatch(logout());
  };

  const UserMenu = () => {
    return (
      <Menu
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={userMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isUserMenuOpen}
        onClose={handleUserMenuClose}
      >
        {isProfileAvailableByEmployee && (
          <MenuItem onClick={handleProfileMenuOpen}>
            <IconButton
              aria-label="Account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <p>Profile</p>
          </MenuItem>
        )}
        {isElromcoAdmin && (
          <MenuItem onClick={openPlanSettings}>
            <IconButton color="inherit">
              <SettingsIcon />
            </IconButton>
            <div className={classes.planSettingMenuContent}>
              <p>Plan Settings</p>
              <p className={classes.planSettingLicence}>(4 users on Pro Plan)</p>
            </div>
          </MenuItem>
        )}
        {canSeeSetupWizardButton && (
          <MenuItem onClick={startWizard}>
            <IconButton color="inherit">
              <AssignmentReturnedIcon />
            </IconButton>
            <p>Setup Wizard</p>
          </MenuItem>
        )}
        {hasUserId && (
          <MenuItem onClick={openVideoSettings}>
            <IconButton color="inherit">
              <YouTubeIcon />
            </IconButton>
            <p>Videos</p>
          </MenuItem>
        )}
        <MenuItem onClick={onLogout}>
          <IconButton color="inherit">
            <ExitToAppIcon />
          </IconButton>
          <p>Logout</p>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar color="primary" position="static">
        <Toolbar>
          <Hidden smUp>
            <IconButton color="inherit" aria-label="Open drawer" onClick={openNavigation}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Link to="/" className={classes.companyLogo} data-testid="companyLogo">
            <CompanyLogo />
          </Link>
          <Hidden xsDown>
            <TitleText className={classes.title} data-testid="companyName" noWrap>
              <b>{companyName}</b>
            </TitleText>
          </Hidden>
          <Search />
          <AddNewButtons />
          <SMTPErrors />
          <div className={classes.section}>
            {canSeeCustomerOnline && <CustomerOnline />}
            <NotificationIcon />
          </div>

          <Hidden smDown>
            <div className={classes.sectionDesktop}>
              <Button
                onClick={handleUserMenuOpen}
                variant="text"
                size="small"
                color="inherit"
                aria-controls="simple-menu"
                data-testid="userName"
                aria-label="Logout"
                classes={{ label: classes.accountButtonLabel }}
              >
                {`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
                <ArrowDropDownIcon />
              </Button>
            </div>
          </Hidden>

          <Hidden mdUp>
            <IconButton
              aria-label="Show more"
              aria-controls={userMenuId}
              data-testid="profile"
              aria-haspopup="true"
              onClick={handleUserMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <UserMenu />
      {isProfileAvailableByEmployee && alertProps.open && <EmployeeProfileModal alertProps={alertProps} />}
      {isElromcoAdmin && planSettingsAlertProps.open && <PlanSettingsModal alertProps={planSettingsAlertProps} />}
      <Modal title="Video Settings" {...videoSettingsAlertProps}>
        <Switch
          label="Video Tutorials"
          color="primary"
          onChange={onChangeEnableVideoTutorial}
          checked={enableVideoTutorials}
        />
      </Modal>
    </div>
  );
}

Bar.propTypes = {
  openNavigation: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
