const CONTACT_INFO = 'contactInfo';
const CUSTOMER_INFO = 'customerInfo';
const ADDITIONAL_CONTACT = 'additionalContact';
const ENABLE_SMS_STATUS_UPDATES = 'enableSmsStatusUpdates';
const ADDITIONAL_INFO = 'additionalInfo';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const EMAIL = 'email';
const PRIMARY_PHONE = 'primaryPhone';
const OTHER_PHONE = 'otherPhone';
const BEST_WAY_TO_CONTACT = 'bestWayToContact';
const BEST_TIME_TO_CONTACT = 'bestTimeToContact';
const PHONE = 'phoneNumber';
const SEND_NOTIFICATIONS = 'sendNotifications';
const PHONE_NUMBER = 'number';
const PHONE_TYPE = 'type';
const PHONE_EXTENSION = 'extension';
const PHONE_CARRIER_TYPE = 'phoneCarrierType';
const SMS_CAPABILITY = 'smsCapability';
const DELIVERY_DATE_START = 'deliveryDateStart';
const DELIVERY_DATE_END = 'deliveryDateEnd';
const REQUESTED_DELIVERY_DATE_START = 'requestedDeliveryDateStart';
const REQUESTED_DELIVERY_DATE_END = 'requestedDeliveryDateEnd';
const DELIVERY_DAYS_COUNT = 'deliveryDaysCount';
const LONG_DISTANCE_PLANNER = 'longDistancePlanner';
const DELIVERY_TIME_EARLIEST = 'deliveryTimeEarliest';
const DISPLAY_SPREAD_DAILY_ESTIMATED_JOB_TIME_TO_CUSTOMER = 'displaySpreadDailyEstimatedJobTimeToCustomer';
const START_TIME_EARLIEST = 'startTimeEarliest';
const START_TIME_LATEST = 'startTimeLatest';
const LABOR_DURATION_RANGE = 'laborDurationRangeOverride';
const TRAVEL_RATE_OVERRIDE = 'travelRateOverride';
const SERVICE_TYPE = 'serviceType';
const JOB_DURATION = 'jobDuration';
const AUTOMATION_ONLY = 'automationOnly';

const FieldNames = Object.freeze({
  PRIMARY_PHONE,
  PHONE_NUMBER,
  PHONE_TYPE,
  PHONE_EXTENSION,
});

/** @deprecated please use it from react-shared */
export default FieldNames;

/** @deprecated please use it from react-shared */
export {
  DISPLAY_SPREAD_DAILY_ESTIMATED_JOB_TIME_TO_CUSTOMER,
  CONTACT_INFO,
  CUSTOMER_INFO,
  ADDITIONAL_CONTACT,
  ENABLE_SMS_STATUS_UPDATES,
  ADDITIONAL_INFO,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PRIMARY_PHONE,
  OTHER_PHONE,
  BEST_WAY_TO_CONTACT,
  BEST_TIME_TO_CONTACT,
  PHONE_NUMBER,
  PHONE_TYPE,
  PHONE_EXTENSION,
  PHONE,
  SEND_NOTIFICATIONS,
  SMS_CAPABILITY,
  PHONE_CARRIER_TYPE,
  DELIVERY_DATE_START,
  DELIVERY_DATE_END,
  REQUESTED_DELIVERY_DATE_START,
  REQUESTED_DELIVERY_DATE_END,
  DELIVERY_DAYS_COUNT,
  DELIVERY_TIME_EARLIEST,
  LONG_DISTANCE_PLANNER,
  START_TIME_EARLIEST,
  START_TIME_LATEST,
  LABOR_DURATION_RANGE,
  TRAVEL_RATE_OVERRIDE,
  SERVICE_TYPE,
  JOB_DURATION,
  AUTOMATION_ONLY,
};
