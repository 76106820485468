import React from 'react';

import { BodyText, formatCurrency, toDateString } from '@elromcoinc/react-shared';
import { format, isDate, parseISO } from 'date-fns';

import { SmsStatsReport } from 'admin/components/Reports/SMSStatisticsTab';
import {
  DataTableColumnAttributes,
  DataTableColumnNames,
} from 'admin/components/Reports/config/DataTableColumnAttributes';

const renderSmsStatsDate = (date: string) => {
  if (!date) {
    return null;
  }

  if (isDate(date)) {
    return toDateString(date, 'MM/yyyy');
  }

  return format(parseISO(date), 'MM/yyyy');
};

const makeSMSStatisticsColumns = (rowsData: SmsStatsReport[]) => [
  {
    name: DataTableColumnAttributes.SMS_DATE,
    label: DataTableColumnNames[DataTableColumnAttributes.SMS_DATE],
    options: {
      customBodyRender: (_: SmsStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { statisticsDate } = rowsData[rowIndex];
        return renderSmsStatsDate(statisticsDate);
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.BALANCE,
    label: DataTableColumnNames[DataTableColumnAttributes.BALANCE],
    options: {
      customBodyRender: (_: SmsStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { balance } = rowsData[rowIndex];
        return <BodyText>{`$${formatCurrency(balance, 3)}`}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.MONEY_SPENT,
    label: DataTableColumnNames[DataTableColumnAttributes.MONEY_SPENT],
    options: {
      customBodyRender: (_: SmsStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { spent } = rowsData[rowIndex];
        return <BodyText>{`$${formatCurrency(spent, 3)}`}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.SMS_COUNT,
    label: DataTableColumnNames[DataTableColumnAttributes.SMS_COUNT],
    options: {
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.FORWARDING_CALL_COUNT,
    label: DataTableColumnNames[DataTableColumnAttributes.FORWARDING_CALL_COUNT],
    options: {
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.PHONE_NUMBER_PRICE,
    label: DataTableColumnNames[DataTableColumnAttributes.PHONE_NUMBER_PRICE],
    options: {
      customBodyRender: (_: SmsStatsReport[], { rowIndex }: { rowIndex: number }) => {
        const { phoneNumbersPrice } = rowsData[rowIndex];
        return <BodyText>{`$${formatCurrency(phoneNumbersPrice, 2)}`}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makeSMSStatisticsColumns };
