import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { useOrderServiceIndex, useOrderSettingUnits, useOrderState } from 'admin/components/OrderWindow/context';
import { normalizeCurrency } from 'common/utils/String';

import { AddSizeUnitTable } from './AddSizeUnitTable';

const useStyles = makeStyles(() => ({
  withoutBorder: {
    borderBottom: 'none',
  },
}));

export const ClosingLinehaulCharge = () => {
  const classes = useStyles();
  const { order } = useOrderState();
  const { moveUnitLabelsShort } = useOrderSettingUnits();
  const { serviceIndex } = useOrderServiceIndex();
  const currentMoveSize = order?.moveSize ?? 0;
  const closingQuote = order?.closingOrderDetail?.getServiceQuote(serviceIndex);
  const longDistanceDetails = closingQuote?.longDistanceTariffDetails;
  const minSize = longDistanceDetails.minSize || 0;
  const basePrice = longDistanceDetails.basePrice || minSize * longDistanceDetails.getEffectiveRate();
  const oversize = currentMoveSize > minSize ? currentMoveSize - minSize : 0;
  const oversizePrice = oversize * longDistanceDetails.getEffectiveRate();
  const linehaulCharge = closingQuote.totalLinehaulCharge.humanReadable;

  return (
    <Box mb={1}>
      <BodyText paragraph>
        <b>Closing</b>
      </BodyText>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>{`${minSize} ${moveUnitLabelsShort}`}</BodyText>
            </TableCell>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>{`$${normalizeCurrency(minSize > 0 ? basePrice : 0)}`}</BodyText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small">
              <BodyText>
                {`${oversize} ${moveUnitLabelsShort} more inventory x $${longDistanceDetails.getEffectiveRate()}/${moveUnitLabelsShort}`}
              </BodyText>
            </TableCell>
            <TableCell size="small">
              <BodyText>{`$${normalizeCurrency(oversizePrice)}`}</BodyText>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>{`Origin ${currentMoveSize} ${moveUnitLabelsShort}`}</BodyText>
            </TableCell>
            <TableCell size="small" className={classes.withoutBorder}>
              <BodyText>{linehaulCharge}</BodyText>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <AddSizeUnitTable />
    </Box>
  );
};
