import React from 'react';

import { Box, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

export const getExpandableRow = (
  currentReport: any[],
  rowData: string[],
  rowMeta: { dataIndex: number; rowIndex: number },
  columns: any[],
  classes: ClassNameMap,
) => {
  const currentRow = currentReport[rowMeta.rowIndex];
  return (
    <tr>
      <td colSpan={20}>
        <Table aria-label="general-report">
          <TableBody>
            {columns
              .filter((column) => column.options && column.options.showOnMobileAsExpanded && column.label.trim())
              .map(({ label, name, options: { customBodyRender = null } = {} }) => (
                <TableRow>
                  <TableCell classes={{ root: classes.tableCell }}>
                    <Box>
                      <b>{label}</b>
                    </Box>
                  </TableCell>
                  <TableCell classes={{ root: classes.tableCell }}>
                    <Box minWidth="150px">
                      {customBodyRender
                        ? customBodyRender(currentRow[name], { rowData, rowIndex: rowMeta.rowIndex })
                        : currentRow[name]}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </td>
    </tr>
  );
};
