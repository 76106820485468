import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

export default class ConfirmationDialog extends Component {
  static propTypes = {
    actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    title: PropTypes.node,
    fullScreen: PropTypes.bool,
    children: PropTypes.node.isRequired
  };

  static defaultProps = {
    fullScreen: false,
    title: null
  };

  render() {
    const { children, actions, fullScreen, title, ...rest } = this.props;
    return (
      <Dialog
        fullScreen={fullScreen}
        {...rest}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description">
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}
