import { SettingNames } from '@elromcoinc/react-shared';

const { MOVERS_SPEED_REDUCTION, MOVE_TYPES, SPEED_TYPES } = SettingNames;

const getElevationReduction = (settings: { [key: string]: any }) =>
  (settings?.[MOVERS_SPEED_REDUCTION] ?? [])
    ?.filter((item: any) => item.type === SPEED_TYPES[0])
    ?.reduce(
      (acc: any, item: any) => ({ ...acc, [item.moveType]: [...acc[item.moveType], [item.id, item.name]] }),
      MOVE_TYPES?.reduce((acc, mt) => ({ ...acc, [mt]: [[0, 'Ground Floor']] }), {}),
    );

export { getElevationReduction };
