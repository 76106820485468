import React from 'react';

import { BodyText, DurationRange, Order } from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow } from '@material-ui/core';

import { ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { Text } from 'admin/components/OrderWindow/components';
import { useOrderClosingContext, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { getServiceClosingPropertyName, getServicePropertyName } from 'admin/utils';

export const TotalEstimatedTimeRow = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { isClosing } = useOrderClosingContext();
  const totalTimeRangeName = isClosing
    ? getServiceClosingPropertyName(serviceIndex, 'totalTimeRange')
    : getServicePropertyName(serviceIndex, 'totalTimeRange');
  const totalTimeRange = order.getIn(totalTimeRangeName.split('.')) as DurationRange;

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <BodyText>{isClosing ? 'Total Time' : 'Total Est. Time'}</BodyText>
      </TableCell>
      <ValueTableCell>
        <Box display="flex" alignItems="center">
          <Text value={totalTimeRange.humanReadable} />
        </Box>
      </ValueTableCell>
    </TableRow>
  );
};
