import { FC } from 'react';

import { Box, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ breakpoints, spacing }) => ({
  root: {
    position: 'relative',
    margin: spacing(2, 4),
    [breakpoints.down('md')]: {
      margin: spacing(2),
    },
  },
}));

export const Page: FC = ({ children }) => {
  const classes = useStyles();

  return <Box className={classes.root}>{children}</Box>;
};
