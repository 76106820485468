import axios from 'axios';

import { NotificationCategories } from 'admin/constants/NotificationCategories';
import { NotificationStatus } from 'admin/constants/NotificationStatus';
import { NotificationEntity } from 'admin/entities/Notification';
import { ApplicationEventType, EventTypeSelectionDto, UserApplicationEventType } from 'common-types';

class NotificationsAPI {
  getNewNotificationsCount() {
    return axios.get(`/api/notifications/new/count`);
  }

  getNewNotifications({
    pageIndex = 0,
    pageSize = 50,
    startDateTime,
  }: Partial<PageParams & { startDateTime: number }> = {}) {
    return axios.post<PageDTO<NotificationEntity>, PageDTO<NotificationEntity>>(`/api/notifications/new`, {
      pageIndex,
      pageSize,
      startDateTime,
    });
  }

  getDismissedNotifications({ pageIndex = 0, pageSize = 50 }: Partial<PageParams> = {}) {
    return axios.get<PageDTO<NotificationEntity>, PageDTO<NotificationEntity>>(
      `/api/notifications/dismissed?pageIndex=${pageIndex}&pageSize=${pageSize}`,
    );
  }

  getEmployeeNotificationSubscriptions(employeeId: number) {
    return axios.get<any, { [key: string]: EventTypeSelectionDto[] }>(
      `/api/notifications/subscriptions/eventTypes/employee/${employeeId}`,
    );
  }

  getEmployeeSubscriptions(id: number) {
    return axios.get(`/api/notifications/subscriptions/employee/${id}`);
  }

  setEmployeeSubscriptions(
    id: number,
    subscriptions: {
      eventTypeId: number;
      selected: boolean;
      notifyOnlyIfAssigned: boolean;
      onlyIfAssignedToRecipient: boolean;
    }[],
  ) {
    return axios.post(`/api/notifications/subscriptions/employee/${id}`, subscriptions);
  }

  getNotificationSubscriptions() {
    return axios.get<UserApplicationEventType[], UserApplicationEventType[]>('/api/notifications/subscriptions');
  }

  updateNotificationSubscriptions(data: {
    inAppEnabled: boolean;
    emailEnabled: boolean;
    smsEnabled: boolean;
    eventTypeId: number;
    recipientId: number;
  }) {
    return axios.put('/api/notifications/subscriptions', data);
  }

  updateAllNotifiSubscriptionsByCategory(
    data: {
      inAppEnabled: boolean;
      emailEnabled: boolean;
      smsEnabled: boolean;
    },
    notificationCategory: NotificationCategories,
  ) {
    return axios.put(`/api/notifications/subscriptions/category/${notificationCategory}`, data);
  }

  setNotificationSubscriptionsEnabled() {
    return axios.put('/api/notifications/subscriptions/all/enabled/', {});
  }

  setNotificationSubscriptionsDisabled() {
    return axios.put('/api/notifications/subscriptions/all/disabled/', {});
  }

  markAllNotificationsDelivered() {
    return axios.put(`/api/notifications/all_read`, {});
  }

  markAllNotificationsDismissed() {
    return axios.put(`/api/notifications/all_dismissed`, {});
  }

  changeNotificationStatus(notificationId: number, status: NotificationStatus) {
    return axios.put(`/api/notifications/${notificationId}/status/${status}`, {});
  }

  getIsMuted() {
    return axios.get<boolean, boolean>('/api/notifications/subscriptions/muted');
  }

  toggleIsMuted() {
    return axios.put<boolean, boolean>('/api/notifications/subscriptions/muted', {});
  }

  getNotificationsBy(accessGroup: NotificationCategories) {
    return axios.get<ApplicationEventType[], ApplicationEventType[]>(
      `/api/notifications/subscriptions/eventTypes/accessGroup/${accessGroup}`,
    );
  }
}

export default new NotificationsAPI();
