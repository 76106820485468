import React, { useEffect, useState } from 'react';

import {
  BaseAddressInputs,
  BodyBigText,
  BodyText,
  Button,
  DatePicker,
  Form,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SettingNames,
  TextInput,
  isLoadingService,
  isLongDistanceService,
  isMovingAndStorageService,
  isOvernightService,
  isUnloadingOrPackingService,
  useAlert,
  usePrevious,
  zipCodeAsyncYUP,
  zipCodeSyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { Alert } from '@material-ui/lab';
import { differenceInCalendarDays, isAfter, isEqual, isSameDay, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { date, object, string } from 'yup';

import OrderApi from 'admin/api/OrderAPI';
import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import { filterServiceOptionsByDistanceType } from 'admin/components/CreateOrder/filterServiceOptionsByDistanceType';
import { getMaxDaysCountPropertyName } from 'admin/components/OrderWindow/utils';
import { useUpdateServiceType } from 'admin/hooks';
import { getEnabledMoveTypes } from 'admin/selectors';

const { MOVE_TYPES } = SettingNames;

const MOVE_TYPE = 'moveType';
const MOVE_DATE = 'moveDate';
const DESIRED_DELIVERY_DATE = 'desiredDeliveryDate';
const ZIP_FROM = 'zipFrom';
const CITY_FROM = 'cityFrom';
const STATE_FROM = 'stateFrom';
const COUNTRY_FROM = 'countryFrom';
const ZIP_TO = 'zipTo';
const CITY_TO = 'cityTo';
const STATE_TO = 'stateTo';
const COUNTRY_TO = 'countryTo';
const SERVICE_ID = 'serviceId';
const SERVICE_NAME = 'serviceName';

const labels = {
  [MOVE_TYPE]: 'Move Type',
  [MOVE_DATE]: 'Moving Date',
  [DESIRED_DELIVERY_DATE]: 'Desired Delivery Date',
  [ZIP_FROM]: 'From Address Zip',
  [ZIP_TO]: 'To Address Zip',
  [SERVICE_ID]: 'Service Type',
};

const fromAddressFieldNames = {
  zipCode: ZIP_FROM,
  city: CITY_FROM,
  state: STATE_FROM,
  country: COUNTRY_FROM,
};

const toAddressFieldNames = {
  zipCode: ZIP_TO,
  city: CITY_TO,
  state: STATE_TO,
  country: COUNTRY_TO,
};

const validZipSync = zipCodeSyncYUP();

const useStyles = makeStyles(() =>
  createStyles({
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
    },
  }),
);

const schema = object().shape({
  [MOVE_TYPE]: string().label(labels[MOVE_TYPE]).required(),
  [MOVE_DATE]: date().label(labels[MOVE_DATE]).nullable().required(),
  [DESIRED_DELIVERY_DATE]: date()
    .label(labels[DESIRED_DELIVERY_DATE])
    .nullable()
    .when([SERVICE_NAME, MOVE_DATE], (serviceName, moveDate, _schema) => {
      if ((isMovingAndStorageService(serviceName) || isOvernightService(serviceName)) && moveDate) {
        return _schema
          .required()
          .nullable()
          .min(startOfDay(new Date()), `${labels[DESIRED_DELIVERY_DATE]} must be later than current date`)
          .test(
            'check-delivery-date',
            `${labels[DESIRED_DELIVERY_DATE]} must be later than ${labels[MOVE_DATE]}`,
            (value) => isEqual(value, moveDate) || isAfter(value, moveDate),
          )
          .test(
            'check-same-move-date',
            "The selected date for the service you're requesting is unavailable. Please select an alternative date.",
            (value) => !isSameDay(moveDate, value),
          );
      }
      return _schema
        .transform((v) => v || null)
        .nullable()
        .optional();
    }),
  [ZIP_FROM]: string()
    .nullable()
    .when(SERVICE_NAME, (serviceName, _schema) =>
      !isUnloadingOrPackingService(serviceName)
        ? zipCodeAsyncYUP(ZIP_FROM).label(labels[ZIP_FROM])
        : _schema.optional(),
    ),
  [ZIP_TO]: string()
    .nullable()
    .when(SERVICE_NAME, (serviceName, _schema) =>
      !isLoadingService(serviceName) ? zipCodeAsyncYUP(ZIP_TO).label(labels[ZIP_TO]) : _schema.optional(),
    ),
  [SERVICE_ID]: string().label(labels[SERVICE_ID]).required().nullable(),
  [SERVICE_NAME]: string().nullable(),
});

const OrderDetails = ({ data, onSave, onBack, serviceOptions, rates, settings }) => {
  const [filteredServiceOptions, setFilteredServiceOptions] = useState(serviceOptions);
  const [inFlight, setInFlight] = useState(false);
  const [selectedMoveType, setSelectedMoveType] = useState(null);
  const classes = useStyles();
  const {
    basicInfo: { phone },
  } = useSelector(getCompanyInfo);

  const methods = useForm({
    defaultValues: data.toJS(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const { watch, getValues, handleSubmit, setValue, trigger, reset, clearErrors } = methods;

  const formValues = getValues();
  const moveType = watch(MOVE_TYPE);
  const moveDate = watch(MOVE_DATE);
  const serviceId = watch(SERVICE_ID);
  const serviceName = watch(SERVICE_NAME);
  const originPostalCode = watch(ZIP_FROM);
  const destinationPostalCode = watch(ZIP_TO);
  const previousMoveType = usePrevious(moveType, moveType);
  const previousOriginPostalCode = usePrevious(originPostalCode, originPostalCode);
  const previousDestinationPostalCode = usePrevious(destinationPostalCode, destinationPostalCode);
  const desiredDeliveryDate = watch(DESIRED_DELIVERY_DATE);

  const differenceInDays =
    desiredDeliveryDate && moveDate ? differenceInCalendarDays(desiredDeliveryDate, moveDate) : null;

  const maxOvernightDaysCount = settings?.[getMaxDaysCountPropertyName(moveType)] ?? 1;

  const isChangeToOvernightServiceType =
    moveType &&
    desiredDeliveryDate &&
    isMovingAndStorageService(serviceName) &&
    moveDate &&
    differenceInDays >= 0 &&
    differenceInDays < +maxOvernightDaysCount;

  const isChangeMovingAndStorageServiceType =
    moveType &&
    desiredDeliveryDate &&
    isOvernightService(serviceName) &&
    moveDate &&
    differenceInDays >= 0 &&
    differenceInDays >= +maxOvernightDaysCount;

  const isInStorageDays =
    differenceInDays > +maxOvernightDaysCount || differenceInDays === +maxOvernightDaysCount || differenceInDays < 0;

  const handleChangeMoveTypeValue = (onClose) => {
    const moveInfoValue = {
      ...data.toJS(),
      [MOVE_TYPE]: moveType,
      [ZIP_FROM]: '',
      [ZIP_TO]: '',
      [MOVE_DATE]: null,
      [SERVICE_ID]: '',
      [SERVICE_NAME]: '',
    };
    onClose();
    setSelectedMoveType(null);
    reset(moveInfoValue);
  };

  const handleCancelChange = () => {
    setValue(MOVE_TYPE, selectedMoveType);
    setShowAlert(false);
  };

  const handleChangeServiceType = () => {
    if (serviceName) {
      setValue(DESIRED_DELIVERY_DATE, null);
    }
  };

  const { setShowAlert, alertProps } = useAlert({
    confirmTitle: 'yes',
    onConfirm: handleChangeMoveTypeValue,
    onCancel: handleCancelChange,
  });

  const isFilledFields = moveDate || serviceId || originPostalCode || destinationPostalCode || serviceName;

  useEffect(() => {
    if (previousMoveType && moveType !== previousMoveType && selectedMoveType !== moveType && isFilledFields) {
      setShowAlert(Boolean(isFilledFields));
      setSelectedMoveType(previousMoveType);
    }
  }, [moveType, reset, isFilledFields, previousMoveType, selectedMoveType, setShowAlert]);

  useEffect(() => {
    setFilteredServiceOptions(serviceOptions);
  }, [serviceOptions]);

  useEffect(() => {
    if (moveDate && isOvernightService(serviceName) && differenceInDays <= +maxOvernightDaysCount) {
      trigger(DESIRED_DELIVERY_DATE);
    }
  }, [moveDate, serviceName, trigger, differenceInDays, maxOvernightDaysCount]);

  useEffect(() => {
    if (moveDate && isMovingAndStorageService(serviceName)) {
      trigger(DESIRED_DELIVERY_DATE);
    }
  }, [moveDate, serviceName, trigger]);

  useEffect(() => {
    if (
      originPostalCode &&
      destinationPostalCode &&
      validZipSync.isValidSync(originPostalCode) &&
      validZipSync.isValidSync(destinationPostalCode) &&
      !isLongDistanceService(serviceName)
    ) {
      clearErrors(SERVICE_ID);
    }
  }, [originPostalCode, destinationPostalCode, serviceName]);

  useUpdateServiceType(serviceName, formValues, moveType, serviceOptions, {
    isOvernightServiceType: () => !!isChangeToOvernightServiceType,
    isMovingAndStorageServiceType: () => !!isChangeMovingAndStorageServiceType,
    isInStorageDays: () => isInStorageDays,
    reset: (value) => reset(value),
    trigger: (fieldName) => trigger(fieldName),
  });

  const checkServiceType = (service = serviceName) => {
    if (!destinationPostalCode || !originPostalCode) {
      setFilteredServiceOptions(serviceOptions);
    }

    if (
      validZipSync.isValidSync(originPostalCode) &&
      validZipSync.isValidSync(destinationPostalCode) &&
      !isUnloadingOrPackingService(service) &&
      !isLoadingService(service)
    ) {
      setInFlight(true);

      return OrderApi.getDistanceTypeAndAdditionalDetails({
        moveType,
        originPostalCode,
        destinationPostalCode,
      })
        .catch(() => {})
        .then((response) => {
          setInFlight(false);

          if (!response) {
            setValue(SERVICE_ID, null);

            return null;
          }

          const { distanceType } = response;
          const filteredServices = filterServiceOptionsByDistanceType(serviceOptions, distanceType);

          setFilteredServiceOptions(filteredServices);

          if (filteredServices && filteredServices.find(([id]) => id === serviceId)) {
            return serviceId;
          }

          return filteredServices ? filteredServices[0][0] : null;
        });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    const [, , newServiceName = null] = serviceOptions.find(([id]) => id === serviceId) || [];
    if (newServiceName !== serviceName) {
      checkServiceType(newServiceName);
    }

    if (newServiceName) {
      setValue(SERVICE_NAME, newServiceName);
    }
  }, [serviceId]);

  useEffect(() => {
    if (previousOriginPostalCode !== originPostalCode || previousDestinationPostalCode !== destinationPostalCode) {
      checkServiceType().then((newServiceId) => {
        if (newServiceId !== undefined) {
          setValue(SERVICE_ID, newServiceId);
        }
      });
    }
  }, [originPostalCode, destinationPostalCode, previousOriginPostalCode, previousDestinationPostalCode]);

  const handleOnSave = (formData) => {
    onSave(formData);
  };

  const handleBack = () => {
    onBack(formValues);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleOnSave)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup aria-label={labels[MOVE_TYPE]} name={MOVE_TYPE} direction="row">
              {getEnabledMoveTypes(settings).map((label) => (
                <Radio data-testid={label} key={label} value={label} label={label} sameUncheckedColor color="primary" />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <DatePicker
              fullWidth
              label={labels[MOVE_DATE]}
              name={MOVE_DATE}
              defaultValue={null}
              data-testid="movingDate"
              rates={rates[moveType] || {}}
            />
          </Grid>
          {!isUnloadingOrPackingService(serviceName) && (
            <Grid item xs={12}>
              <FormControl data-testid="fromAddress" component="fieldset">
                <FormLabel component="legend">
                  <BodyText gutterBottom>From address</BodyText>
                </FormLabel>
                <BaseAddressInputs fieldNames={fromAddressFieldNames} singleLineForStateAndZip />
              </FormControl>
            </Grid>
          )}
          {!isLoadingService(serviceName) && (
            <Grid item xs={12}>
              <FormControl data-testid="toAddress" component="fieldset">
                <FormLabel component="legend">
                  <BodyText gutterBottom>To address</BodyText>
                </FormLabel>
                <BaseAddressInputs fieldNames={toAddressFieldNames} singleLineForStateAndZip />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={-0.5} height={4}>
              {inFlight && <LinearProgress />}
            </Box>
            {filteredServiceOptions ? (
              <Select
                fullWidth
                label={labels[SERVICE_ID]}
                name={SERVICE_ID}
                onChange={handleChangeServiceType}
                data-testid="serviceId"
                options={filteredServiceOptions || []}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SettingsOutlinedIcon color={inFlight ? 'disabled' : 'primary'} />
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextInput
                fullWidth
                disabled
                skipControl
                label={labels[SERVICE_ID]}
                value="Not Supported"
                color="secondary"
                data-testid="notSupported"
              />
            )}
          </Grid>
          {!filteredServiceOptions && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                classes={{
                  message: classes.errorMessage,
                }}
              >{`Please contact us at ${phone} to see if we can accommodate your move.`}</Alert>
            </Grid>
          )}
          {(isMovingAndStorageService(serviceName) || isOvernightService(serviceName)) && (
            <Grid item xs={12}>
              <DatePicker
                fullWidth
                name={DESIRED_DELIVERY_DATE}
                label={labels[DESIRED_DELIVERY_DATE]}
                rates={rates[moveType] || {}}
                data-testid="desiredDeliveryDate"
              />
            </Grid>
          )}
        </Grid>
        <Modal {...alertProps} title="Warning">
          <Box display="flex" justifyContent="center">
            <BodyBigText>Do you really want to change the move type? Entered data will be lost!</BodyBigText>
          </Box>
        </Modal>
        <Box mt={6} display="flex" justifyContent="space-between">
          <Link data-testid="backToCustomerInfo" onClick={handleBack} disabled={inFlight}>
            &lt; Back to Customer Info
          </Link>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            data-testid="MoveInfoContinue"
            rounded
            disableBoxShadow
            loading={inFlight}
            disabled={inFlight}
          >
            continue
          </Button>
        </Box>
      </Form>
    </FormProvider>
  );
};

OrderDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  rates: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.shape({
    moveType: PropTypes.oneOf(MOVE_TYPES),
  }),
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  serviceOptions: PropTypes.arrayOf(PropTypes.array),
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.object,
};

OrderDetails.defaultProps = {
  rates: {},
  data: {},
  serviceOptions: [[1, 'Local Move']],
  settings: {},
};

export default OrderDetails;
