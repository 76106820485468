import React from 'react';

import { BodyText, CreditCardType, Radio, RadioGroup, cardTypeIcon } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { format, parseISO } from 'date-fns';

import { PaymentProfileDto } from 'admin/api/PaymentActionsApi';

interface RadioControlCreditCardsProps {
  name: string;
  data?: PaymentProfileDto[];
}

const creditTypeName = (type: CreditCardType) => {
  switch (type) {
    case CreditCardType.AMERICANEXPRESS:
      return 'American Express';
    case CreditCardType.VISA:
      return 'Visa';
    case CreditCardType.MASTERCARD:
      return 'MasterCard';
    case CreditCardType.DISCOVER:
      return 'Discover';
    default:
      return type;
  }
};

const RadioControlCreditCards: React.FC<RadioControlCreditCardsProps> = ({ data, name }) => {
  if (!data || data.length === 0) {
    return null;
  }

  function renderLabel(card: PaymentProfileDto) {
    if ((card?.id ?? -1) < 0) {
      return (
        <Box my={1}>
          <BodyText>Add New Card</BodyText>
        </Box>
      );
    }

    return (
      <Box my={1}>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          {/* @ts-ignore*/}
          <Box mr={1}>{cardTypeIcon[`${card.cardType || 'Visa'}`.toUpperCase()]}</Box>
          <BodyText>
            <b>{creditTypeName((card.cardType || 'VISA') as CreditCardType)}</b>
          </BodyText>
          <BodyText>&nbsp;ending in {card.lastFourDigits},&nbsp;</BodyText>
          <BodyText>Expires&nbsp;{format(parseISO(card.expiration), 'MM/yyyy')}</BodyText>
        </Box>
        <BodyText>
          {card.firstName} {card.lastName}
        </BodyText>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <RadioGroup name={name} label="Select Credit Card">
        {data.map((card) => (
          <Radio key={card.id} value={`${card.id}`} color="primary" label={renderLabel(card)} />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioControlCreditCards;
