import React, { useEffect, useState } from 'react';

import {
  BodyBigText,
  BodyText,
  CurrencyInput,
  Modal,
  PriceAdjustment,
  PriceAdjustmentType,
  Radio,
  RadioGroup,
  RangeValue,
  TextInput,
} from '@elromcoinc/react-shared';
import { Box, InputAdornment, Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';

const COMPANY_SETTING = 'COMPANY_SETTING';

const FuelSurcharge = ({
  onSave,
  onCancel,
  open,
  fuelChargeComputed,
  fuelChargeComputedNote,
  fuelChargeEmployeeAdjusted,
  fuelChargeMovingCost,
}) => {
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const [inFlight, setInFlight] = useState(false);
  const [type, setType] = useState(COMPANY_SETTING);
  const [amount, setAmount] = useState({ [PriceAdjustmentType.ABSOLUTE]: null, [PriceAdjustmentType.PERCENT]: null });

  useEffect(() => {
    if (fuelChargeEmployeeAdjusted && fuelChargeEmployeeAdjusted.type) {
      setAmount({ ...amount, [fuelChargeEmployeeAdjusted.type]: fuelChargeEmployeeAdjusted.amount });
      setType(fuelChargeEmployeeAdjusted.type);
    }
  }, [fuelChargeEmployeeAdjusted]);

  const percentChangeHandler = ({ target: { name, value } }) => {
    const newValue = Math.min(Math.max(value, 0), 100);
    setAmount((oldAmount) => ({ ...oldAmount, [name]: newValue }));
  };

  const amountChangeHandler = (value, name) => {
    setAmount((oldAmount) => ({ ...oldAmount, [name]: value }));
  };

  const typeChangeHandler = ({ target: { value } }) => {
    setType(value);
    setAmount({ [PriceAdjustmentType.ABSOLUTE]: null, [PriceAdjustmentType.PERCENT]: null });
  };

  const saveHandler = () => {
    setInFlight(true);
    onSave(
      type === COMPANY_SETTING
        ? null
        : (fuelChargeEmployeeAdjusted || new PriceAdjustment()).set('type', type).set('amount', amount[type]),
    );
  };

  const newTotalRange = RangeValue.createRangeValue({
    minValue: (Number(fuelChargeMovingCost.minValue || 0) * amount.PERCENT) / 100,
    maxValue: (Number(fuelChargeMovingCost.maxValue || 0) * amount.PERCENT) / 100,
  });

  return (
    <Modal
      open={open}
      className="applyRadius"
      title="Fuel Surcharge"
      onClose={onCancel}
      inFlight={inFlight}
      actions={[
        { label: 'cancel', onClick: onCancel },
        {
          label: 'save',
          onClick: saveHandler,
          disabled:
            !amount[PriceAdjustmentType.PERCENT] && !amount[PriceAdjustmentType.ABSOLUTE] && type !== COMPANY_SETTING,
        },
      ]}
    >
      <Box mb={2}>
        <RadioGroup
          aria-label="charge-setting"
          name="charge"
          value={type}
          onChange={typeChangeHandler}
          direction="column"
          skipControl
        >
          <Radio
            sameUncheckedColor
            value={COMPANY_SETTING}
            disabled={isCompleted || isLockSales}
            label={
              <>
                <Box display="flex" alignItems="center">
                  <BodyText>Company Setting</BodyText>
                  <Box display="flex" alignSelf="flex-start" ml={1}>
                    <Tooltip title={<BodyText>{fuelChargeComputedNote}</BodyText>}>
                      <ErrorIcon color="primary" />
                    </Tooltip>
                  </Box>
                </Box>
              </>
            }
            color="primary"
          />
          <Box ml={4}>
            <BodyBigText>{fuelChargeComputed && fuelChargeComputed.humanReadable}</BodyBigText>
          </Box>
          <Radio
            color="primary"
            sameUncheckedColor
            value={PriceAdjustmentType.PERCENT}
            disabled={isCompleted || isLockSales}
            label={
              <>
                <Box display="flex" alignItems="center">
                  <BodyText>Percent of Moving Charges</BodyText>
                  <Box display="flex" alignSelf="flex-start" ml={1}>
                    <Tooltip
                      title={
                        <BodyText>
                          Moving charges are computed by adding the labor costs, travel costs, additional services, and
                          materials
                        </BodyText>
                      }
                    >
                      <ErrorIcon color="primary" />
                    </Tooltip>
                  </Box>
                  <Box ml={1}>
                    <BodyText align="center">({fuelChargeMovingCost?.humanReadable})</BodyText>
                  </Box>
                </Box>
              </>
            }
          />
          {type === PriceAdjustmentType.PERCENT && (
            <Box display="flex" alignItems="center" ml={4} mb={2}>
              <Box maxWidth={190}>
                <TextInput
                  disabled={isCompleted || isLockSales}
                  label="Amount"
                  fullWidth
                  name={PriceAdjustmentType.PERCENT}
                  type="number"
                  autoFocus
                  value={amount.PERCENT || ''}
                  inputProps={{ max: '100' }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  onChange={percentChangeHandler}
                />
              </Box>
              <Box ml={2}>
                <BodyBigText>{`Total: ${newTotalRange.asHumanReadableString()}`}</BodyBigText>
              </Box>
            </Box>
          )}
          <Radio
            disabled={isCompleted || isLockSales}
            sameUncheckedColor
            value={PriceAdjustmentType.ABSOLUTE}
            label="Flat Charge"
            color="primary"
          />
          {type === PriceAdjustmentType.ABSOLUTE && (
            <Box display="flex" alignItems="center" ml={4} mb={2}>
              <CurrencyInput
                disabled={isCompleted || isLockSales}
                label="Amount"
                autoFocus
                name={PriceAdjustmentType.ABSOLUTE}
                value={amount.ABSOLUTE || ''}
                onChange={amountChangeHandler}
                inputProps={{ allowNegativeValue: false }}
              />
            </Box>
          )}
        </RadioGroup>
      </Box>
    </Modal>
  );
};

FuelSurcharge.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fuelChargeComputed: PropTypes.object.isRequired,
  fuelChargeComputedNote: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fuelChargeEmployeeAdjusted: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fuelChargeMovingCost: PropTypes.object.isRequired,
};

export { FuelSurcharge };
