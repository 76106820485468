import { MoveTypeTypes, OrderStatusIdType } from '@elromcoinc/react-shared';
import { Record } from 'immutable';

import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { OrderStatus } from 'common-types/OrderStatus';
import { appendTemplateBuilderStyles } from 'common/utils/appendTemplateBuilderStyles';

interface CustomBlockEntity extends Omit<CustomBlockDTO, 'orderStatuses' | 'serviceTypes' | 'moveTypes'> {
  orderStatuses: OrderStatusIdType[];
  serviceTypes: number[];
  moveTypes: MoveTypeTypes[];
}

class CustomBlock extends Record<CustomBlockEntity>({
  id: null,
  weight: 0,
  showOnAccount: false,
  serviceTypes: [],
  hideTitle: false,
  showOnConfirmationPage: false,
  moveTypes: [],
  orderStatuses: [],
  enabled: true,
  title: '',
  body: '',
  structureJson: '',
}) {
  constructor(data: Partial<CustomBlockDTO> = {}) {
    super({
      ...data,
      body: convertTemplateBuilderVariables(data.body || '', true),
      orderStatuses: data?.orderStatuses?.map(({ orderStatus }) => orderStatus) ?? [],
      serviceTypes: data?.serviceTypes?.map(({ serviceType: { id } }) => id) ?? [],
      moveTypes: data?.moveTypes?.map(({ moveType }) => moveType) ?? [],
    });
  }

  toDTO(serviceTypes: ServiceType[]): CustomBlockDTO {
    const result = this.toJS() as CustomBlockEntity;

    return {
      ...result,
      body: appendTemplateBuilderStyles(convertTemplateBuilderVariables(result.body)),
      structureJson: '',
      orderStatuses:
        result?.orderStatuses?.map((orderStatus: string) => ({ orderStatus, enabled: true } as OrderStatus)) ?? [],
      serviceTypes:
        result?.serviceTypes
          ?.map(
            (id: number) =>
              ({ serviceType: serviceTypes.find((item) => item.id === id), enabled: true } as CustomBlockServiceType),
          )
          .filter((it) => it.serviceType) ?? [],
      moveTypes:
        result?.moveTypes?.map((moveType: string) => ({ moveType, enabled: true } as CustomBlockMoveType)) ?? [],
    };
  }
}

interface CustomBlockServiceType {
  enabled?: boolean;
  id?: number;
  serviceType: ServiceType;
}

interface CustomBlockMoveType {
  enabled: boolean;
  id?: number;
  moveType: MoveTypeTypes;
}

interface CustomBlockDTO {
  id?: number | null;
  weight: number;
  showOnAccount: boolean;
  serviceTypes: CustomBlockServiceType[];
  hideTitle: boolean;
  showOnConfirmationPage: boolean;
  moveTypes: CustomBlockMoveType[];
  orderStatuses: OrderStatus[];
  enabled: boolean;
  title: string;
  body: string;
  structureJson: string;
}

export type { CustomBlockDTO };
export { CustomBlock };
