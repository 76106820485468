import React, { FC, useEffect, useRef } from 'react';

import { BodyText, Waypoint } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import TruckIcon from '@material-ui/icons/LocalShipping';

import { DestinationBlock } from 'admin/components/OrderWindow/blocks/Addresses';
import DragHandle from 'admin/components/OrderWindow/blocks/Addresses/DragHandle';
import SortableElement from 'admin/components/OrderWindow/blocks/Addresses/SortableElement';
import { useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context/useOrderWindowEditAddressIndex';

export interface TruckBlockProps {
  isDestination: boolean;
  index: number;
  data: Waypoint;
  distanceFromPrevious: string;
  showLastAddStop: boolean;
  totalDistance: string;
  openRoute: () => void;
  onBlockWidthChange: (width: number) => void;
  minWidth: number;
  durationFromPrevious: string;
}

export const TruckBlock: FC<TruckBlockProps> = ({
  isDestination,
  index,
  data,
  distanceFromPrevious,
  showLastAddStop,
  totalDistance,
  openRoute,
  onBlockWidthChange,
  minWidth = 140,
  durationFromPrevious,
}) => {
  const { setEditWaypoint } = useOrderWindowEditAddressIndex();
  const blockRef = useRef<HTMLDivElement>(null);
  const { address } = data;

  useEffect(() => {
    if (blockRef.current && blockRef.current.clientWidth) {
      onBlockWidthChange(blockRef.current.clientWidth!);
    }
  }, []);

  const onSetAddressIndex = () => () => {
    const newIndex = -1;
    setEditWaypoint(
      new Waypoint({
        waypointIndex: newIndex,
        originalWaypointIndex: newIndex,
        sequenceOrder: newIndex,
        serviceIndex: data.serviceIndex + 1,
      }),
    );
  };

  return (
    <>
      <SortableElement display="inline-flex" key={address.id} index={index} disabled>
        <DragHandle disabled disabledDraggable index={index} showIcon icon={TruckIcon} />
        <Box
          className="detailsBox"
          // @ts-ignore
          ref={blockRef}
          fontSize="5rem"
          alignItems="center"
          justifyContent="space-evenly"
          minWidth={minWidth}
        >
          <TruckIcon color="primary" fontSize="inherit" />
          <BodyText>Overnight in Truck</BodyText>
        </Box>
      </SortableElement>
      <DestinationBlock
        isDestination={isDestination}
        data={data}
        distanceFromPrevious={distanceFromPrevious}
        onSetAddressIndex={onSetAddressIndex}
        index={data.waypointIndex}
        durationFromPrevious={durationFromPrevious}
        showLastAddStop={showLastAddStop}
        openRoute={openRoute}
        totalDistance={totalDistance}
      />
    </>
  );
};
