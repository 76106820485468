import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { ActivitySourceType, IActivityDto } from '@elromcoinc/react-shared';

interface PaymentSourceContextProps extends Pick<IActivityDto, 'activitySource' | 'sourceId' | 'activitySources'> {}

const PaymentSourceContext = createContext<PaymentSourceContextProps>({
  activitySource: ActivitySourceType.ORDER,
  sourceId: -1,
  activitySources: [],
});

export const usePaymentSourceContext = () => useContext(PaymentSourceContext);

export const PaymentSourceProvider = ({
  children,
  sourceId,
  activitySource,
  activitySources,
}: PropsWithChildren<PaymentSourceContextProps>) => {
  const context = useMemo(
    () => ({ sourceId, activitySource, activitySources }),
    [sourceId, activitySource, activitySources],
  );
  return <PaymentSourceContext.Provider value={context}>{children}</PaymentSourceContext.Provider>;
};
