import React from 'react';

import { Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Alarm as AlarmIcon, Snooze as SnoozeIcon } from '@material-ui/icons';
import { format } from 'date-fns';

import { Task } from 'common-types/Task';

import { DisplayTaskVariant, UNCOMPLETED_VARIANT } from './DisplayTaskVariant';

interface TaskReminderIconProps {
  task: Task;
  variant?: DisplayTaskVariant;
}

const TaskReminderIcon = ({ task, variant }: TaskReminderIconProps) => {
  const { snoozedUntil, isCompleted, remindMe, remindTime } = task;

  return (
    <>
      {!isCompleted && (
        <Box ml={1}>
          {remindMe && !snoozedUntil && (
            <Tooltip title={`Remind at ${format(remindTime!, 'Pp')}`} placement="top">
              <AlarmIcon fontSize="small" color={variant !== UNCOMPLETED_VARIANT ? 'primary' : 'inherit'} />
            </Tooltip>
          )}
          {snoozedUntil && (
            <Tooltip title={`Remind at ${format(snoozedUntil as Date, 'Pp')}`} placement="top">
              <SnoozeIcon fontSize="small" color={variant !== UNCOMPLETED_VARIANT ? 'action' : 'inherit'} />
            </Tooltip>
          )}
        </Box>
      )}
    </>
  );
};

export default TaskReminderIcon;
