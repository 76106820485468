import axios from 'axios';
import { toBackEndDate } from '@elromcoinc/react-shared';

class ServicesApi {
  getSurveyService(orderId, params = {}) {
    return axios.get(`/api/moving/estimation_survey/order/${orderId}`, {
      params,
      data: {},
    });
  }

  getAvailableSurveyDate(params = {}) {
    return axios.get(`/api/moving/estimation_survey/company_blocked`, {
      params,
      data: {},
    });
  }

  getAvailableEmployees(responsibility = '') {
    return axios.get(`/api/moving/estimation_survey/available_employees`, {
      params: { responsibility },
      data: {},
    });
  }

  getAvailableEstimatorsByDay(params = {}) {
    return axios.get(`/api/moving/estimation_survey/estimators`, {
      params,
      data: {},
    });
  }

  getAvailableEstimatorsByWeek(params = {}) {
    return axios.get(`/api/moving/estimation_survey/estimators_by_dates`, {
      params,
      data: {},
    });
  }

  updateSurveyService(data) {
    return axios.put('/api/moving/estimation_survey/', data);
  }

  createSurveyService(data) {
    return axios.post(`/api/moving/estimation_survey/`, data);
  }

  getSurveyServicesForDates(dateFrom, dateTo) {
    return axios.get(`/api/moving/estimation_survey/from/${toBackEndDate(dateFrom)}/to/${toBackEndDate(dateTo)}`, {
      data: {},
    });
  }

  checkAssignEstimator(body) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    return {
      promise: axios.post('/api/moving/estimation_survey/check_assign_estimator', body, {
        cancelToken: source.token,
      }),
      cancel(message) {
        source.cancel(message);
      },
    };
  }
}

export default new ServicesApi();
