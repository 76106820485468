import React, { FC } from 'react';

import { IconButton } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Close as CloseIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    close: {
      position: 'absolute',
      right: 8,
      '& svg': {
        color: theme.palette.error.main,
      },
    },
    box: {
      position: 'relative',
      margin: -12,
      marginBottom: 16,
      borderRadius: 4,
      padding: 4,
      display: 'flex',
      justifyContent: 'center',
      height: 30,
    },
    animation: {
      animation: `1s linear 0s infinite alternate $swingTopDownAnimation`,
      marginTop: -10,
    },
    '@keyframes swingTopDownAnimation': {
      '50%': { marginTop: 0 },
    },
  }),
);

interface BannerProps {
  onClick?: () => void;
}

const Banner: FC<BannerProps> = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      {!!props.onClick && (
        <IconButton className={classes.close} aria-label="close" onClick={props.onClick} size="small" color="default">
          <CloseIcon color="inherit" />
        </IconButton>
      )}
      <Box display="flex" flexDirection="column" alignItems="center">
        {props.children}
        <Box mt={1}>
          <KeyboardArrowDownIcon className={classes.animation} color="error" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
