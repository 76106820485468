import React, { useEffect, useState } from 'react';

import { ActivitySourceType, CircularProgress } from '@elromcoinc/react-shared';
import { Box, Paper } from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import Draggable from 'react-draggable';
import styled from 'styled-components';

import AccountAPI from 'admin/api/AccountAPI';
import { MainAccountPanel, TitlePanel } from 'admin/components/AccountWindow';
import { SummaryAccountContext } from 'admin/components/AccountWindow/config';
import { UnreadMessagesProvider } from 'admin/components/OrderWindow/context/UnreadMessagesProvider';
import { UnsubscribeMessageProvider } from 'admin/components/OrderWindow/context/UnsubscribeMessageProvider';
import useWidthChange from 'admin/components/OrderWindow/hooks/useElementSize';
import { ComposeEmailProvider } from 'admin/components/OrderWindow/modals/ComposeEmail/ComposeEmailProvider';
import { WindowType } from 'admin/constants/WidowType';
import { useMobile } from 'admin/hooks/useMobile';

const Window = styled(Paper)`
  z-index: ${({ $zIndex, theme }) => ($zIndex !== null ? $zIndex : theme.zIndex.drawer + 50)};
  max-width: 1600px;
  width: ${({ $width }) => $width};
  position: fixed;
  top: ${({ $offsetCoefficient }) => `calc(5vh * ${$offsetCoefficient})`};
  left: ${({ $offsetCoefficient }) => `calc((100vw - 95vw) * ${$offsetCoefficient})`};
  @media (min-width: 1700px) {
    left: ${({ $offsetCoefficient }) => `calc((100vw - 1600px) * ${$offsetCoefficient})`};
  }
  &.fullScreen {
    width: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    transform: none !important;
  }
  &.minimized {
    position: relative;
    transform: none !important;
    align-self: flex-end;
    max-width: 300px;
    margin-right: 16px;
    margin-top: 4px;
    top: unset;
    left: unset;
    z-index: ${({ theme }) => theme.zIndex.modal + 50};
  }
`;

export const AccountWindow = ({
  onClose,
  zIndex,
  accountId,
  offsetCoefficient,
  onMinimize,
  width: wWidth,
  minimized,
  onSelectWindow,
}) => {
  const [summaryAccount, setSummaryAccount] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const isMobile = useMobile();
  const { enqueueSnackbar } = useSnackbar();
  const [rootRef, width, height] = useWidthChange(wWidth, minimized);

  useEffect(() => {
    if (!accountId) {
      return;
    }

    setInFlight(true);

    AccountAPI.getAccountSummary(accountId)
      .then((res) => {
        setSummaryAccount(res);
        setCustomer(res);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get an account for an existing customer`, { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }, [accountId]);

  useEffect(() => {
    if (customer && !customer.ownerCustomerInfo.customerId) {
      setTimeout(() => {
        AccountAPI.getAccountSummary(accountId).then((res) => {
          setSummaryAccount(res);
          setCustomer(res);
        });
      }, 1000);
    }
  }, [customer]);

  const handleOnWindowClick = () => {
    onSelectWindow && onSelectWindow({ id: accountId, type: WindowType.ACCOUNT });
  };

  const isBusy = inFlight || !summaryAccount;

  return (
    <UnreadMessagesProvider sourceId={accountId} activitySource={ActivitySourceType.CUSTOMER_ACCOUNT}>
      <ComposeEmailProvider activitySource={ActivitySourceType.CUSTOMER_ACCOUNT}>
        <Draggable handle=".draggable-handle">
          <Window
            square
            elevation={5}
            $zIndex={zIndex}
            id={`account-window-${accountId}`}
            $width={wWidth}
            height={height}
            onClick={handleOnWindowClick}
            $minimized={minimized}
            className={classNames({ fullScreen: isMobile && !minimized, minimized })}
            $offsetCoefficient={offsetCoefficient}
          >
            {isBusy ? (
              <Box position="absolute" top="50vh" width={40} height={40} left="50%">
                <CircularProgress />
              </Box>
            ) : (
              <UnsubscribeMessageProvider>
                <SummaryAccountContext.Provider value={{ customer, setCustomer }}>
                  <Box ref={rootRef}>
                    <TitlePanel
                      handleCloseAccountWindow={onClose}
                      minimized={minimized}
                      onMinimize={onMinimize}
                      parentWidth={width}
                    />
                    {!minimized && <MainAccountPanel summaryAccount={summaryAccount} />}
                  </Box>
                </SummaryAccountContext.Provider>
              </UnsubscribeMessageProvider>
            )}
          </Window>
        </Draggable>
      </ComposeEmailProvider>
    </UnreadMessagesProvider>
  );
};

AccountWindow.propTypes = {
  zIndex: pt.number,
  width: pt.string,
  accountId: pt.number.isRequired,
  onClose: pt.func.isRequired,
  offsetCoefficient: pt.number.isRequired,
  minimized: pt.bool,
  onMinimize: pt.func,
  onSelectWindow: pt.func,
};

AccountWindow.defaultProps = {
  zIndex: null,
  width: '95vw',
  minimized: false,
  onMinimize: null,
  onSelectWindow: null,
};
