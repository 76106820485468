import React from 'react';

import { BodyText, IconButton, Link } from '@elromcoinc/react-shared';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';

import ActivityItem from './ActivityItem';

const useStyles = makeStyles((theme) => ({
  listLog: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    '& > div:last-of-type': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
  listHeader: {
    width: '100%',
    padding: '4px 16px',
    backgroundColor: theme.palette.grey[200],
    boxSizing: 'border-box',
  },
}));

export default function ActivityList({
  items,
  title,
  emptyLabel,
  maxToShow,
  onExpand,
  onEdit,
  onToggleExpand,
  expandedItems,
}) {
  const classes = useStyles();
  const isEmpty = items.size === 0;
  const itemsToRender = items.slice(0, maxToShow);
  return (
    <Box className={classes.listLog} mb={2}>
      <Box className={classes.listHeader}>
        <Box display="flex">
          <Box ml={-3} my={-1} width="30px">
            {!isEmpty && (
              <Tooltip title={expandedItems.isEmpty() ? 'Collapsed All' : 'Expanded'}>
                <div>
                  <IconButton color="default" variant="text" size="small" onClick={onToggleExpand}>
                    {!expandedItems.isEmpty() ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </Box>
          <BodyText>
            <b>{title}</b>
          </BodyText>
        </Box>
      </Box>
      {itemsToRender.map((item) => (
        <ActivityItem key={item} {...item.toJS()} onEdit={onEdit} expandedItems={expandedItems} />
      ))}
      {isEmpty && <ActivityItem label={emptyLabel} />}
      {items.size > maxToShow && (
        <Box component="span" mt={0.5} pl={1} width="100%" boxSizing="border-box">
          <Link size="small" onClick={onExpand}>{`+ ${items.size - maxToShow} more`}</Link>
        </Box>
      )}
    </Box>
  );
}

ActivityList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  expandedItems: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  maxToShow: PropTypes.number.isRequired,
  emptyLabel: PropTypes.string.isRequired,
};
