import React from 'react';

import { BodyText, Link, statusIds } from '@elromcoinc/react-shared';

import { MoveSizeStats } from 'admin/components/Reports/GeneralStatisticsTab';
import {
  DataTableColumnAttributes,
  DataTableColumnNames,
  SelectedOrderFiltersType,
} from 'admin/components/Reports/config';

const totalMoveSize = 'Totals';
const isTotalMoveSize = (moveSizeDescription: string) => moveSizeDescription === totalMoveSize;

const makeGeneralStatisticsSizeOfMoveColumns = (
  rowsData: MoveSizeStats[],
  { setSelectedOrderFilters }: { setSelectedOrderFilters: SelectedOrderFiltersType },
) => [
  {
    name: 'moveSizeDescription',
    label: 'SIZE',
    options: {
      customBodyRender: (_: MoveSizeStats[], { rowIndex }: { rowIndex: number }) => {
        const { moveSizeDescription } = rowsData[rowIndex];

        return <BodyText wordBreak>{moveSizeDescription}</BodyText>;
      },
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS],
    options: {
      customBodyRender: (_: MoveSizeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersInPeriod, moveSizeDescription } = rowsData[rowIndex];
        const sizeDescription = (moveSizeDescription || '').trim();

        if (isTotalMoveSize(moveSizeDescription)) {
          return <BodyText className="total-column">{totalOrdersInPeriod}</BodyText>;
        }

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalOrdersInPeriod > 0) {
            setSelectedOrderFilters({ sizeDescription });
          }
        };

        return totalOrdersInPeriod === 0 ? (
          <BodyText>{totalOrdersInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalOrdersInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_JOBS,
    label: <>BOOKED&nbsp;</>,
    options: {
      customBodyRender: (_: MoveSizeStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedInPeriod, moveSizeDescription } = rowsData[rowIndex];
        const isTotal = isTotalMoveSize(moveSizeDescription);
        const sizeDescription = (moveSizeDescription || '').trim();

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalBookedInPeriod > 0) {
            setSelectedOrderFilters({ status: [statusIds.BOOKED], sizeDescription });
          }
        };

        return isTotal || totalBookedInPeriod === 0 ? (
          <BodyText>{totalBookedInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalBookedInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
    },
  },
  {
    name: DataTableColumnAttributes.CONVERSION,
    label: 'CONVERSION',
    options: {
      customBodyRender: (_: MoveSizeStats[], { rowIndex }: { rowIndex: number }) => {
        const { conversionRateForPeriod } = rowsData[rowIndex];
        const { asPercentage } = conversionRateForPeriod;

        return <BodyText>{asPercentage}</BodyText>;
      },
    },
  },
];

export { makeGeneralStatisticsSizeOfMoveColumns };
