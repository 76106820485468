import React, { FC, useEffect, useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  BodyBigText,
  Button,
  Modal,
  Order,
  ValuationType,
  getValuationSetting,
  isLongDistanceService,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { List, Map } from 'immutable';

import { useOrderSettingUnits, useSendingTemplates } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { useComposeEmail } from 'admin/components/OrderWindow/modals/ComposeEmail/useComposeEmail';
import { ManageTemplates } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/ManageTemplates';
import { getChangesStatusAutomationRule } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/getChangesStatusAutomationRule';
import { makeOrderChangeLog } from 'admin/components/OrderWindow/modals/UpdateOrderDialog/makeOrderChangeLog';
import { Template } from 'common-types';

interface UpdateOrderDialogProps extends Partial<ReturnType<typeof useAlert>> {
  changeSet: Map<string, any>;
  order: Order;
  originalOrder: Order;
  managersList: List<{ fullName: string; id: number }>;
}
const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

const UpdateOrderDialog: FC<UpdateOrderDialogProps> = ({
  alertProps,
  changeSet,
  originalOrder,
  order,
  managersList,
}) => {
  const { templates, automationRules } = useComposeEmail();
  const { setSendingTemplates } = useSendingTemplates();
  const { currentDistanceUnitLabel, moveUnitLabelsShort } = useOrderSettingUnits();
  const settings = useOrderWindowSettings();
  const isMultiDay = originalOrder.services.size > 1;
  const [serviceIndex, setServiceIndex] = useState(0);
  const [defaultTemplates, setDefaultTemplates] = useState<Template[]>([]);
  const serviceButtons = new Array(originalOrder.services.size).fill(0).map((_, index) => index);
  const isLongDistance = isLongDistanceService(originalOrder.getServiceType(0));
  const moveType = originalOrder.getMoveType();
  const titleBasicValuation = getValuationSetting(settings, isLongDistance, moveType, 'titleBasicValuation');
  const titleFullValueProtection = getValuationSetting(settings, isLongDistance, moveType, 'titleFullProtection');
  const titleThirdOption = getValuationSetting(settings, isLongDistance, moveType, 'titleThirdOption');
  const valuationNames: { [key: string]: string } = {
    [ValuationType.BASIC]: titleBasicValuation,
    [ValuationType.FULL]: titleFullValueProtection,
    [ValuationType.THIRD_OPTION]: titleThirdOption,
  };
  const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];
  const statusChanged = originalOrder.status !== order.status;
  const changes = makeOrderChangeLog(changeSet, {
    valuationNames,
    managersList,
    serviceIndex,
    order,
    originalOrder,
    isTravelRateTheSameAsLaborRate,
    currentDistanceUnitLabel,
    moveUnitLabelsShort,
  });

  const chooseService = (index: number) => () => setServiceIndex(index);

  useEffect(() => {
    if (statusChanged) {
      const shortName = getChangesStatusAutomationRule(order, originalOrder.status);
      const currentAutomationRule = automationRules.find((ar) => ar.shortName === shortName);
      const filteredTemplatesByEventType = templates.filter(
        (t) => t.active && t.automationRuleId === currentAutomationRule?.id,
      );
      setDefaultTemplates(filteredTemplatesByEventType);
      setSendingTemplates(filteredTemplatesByEventType);
    } else {
      setDefaultTemplates([]);
      setSendingTemplates([]);
    }
  }, [order.status, templates, automationRules, statusChanged, setSendingTemplates]);

  return (
    <Modal title="Order Updates" {...alertProps}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        {isMultiDay && (
          <Box display="flex" mb={1}>
            {serviceButtons.map((it) => (
              <Box key={it} mr={1}>
                <Button
                  color={serviceIndex === it ? 'primary' : 'default'}
                  variant="outlined"
                  onClick={chooseService(it)}
                >
                  Day {it + 1}
                </Button>
              </Box>
            ))}
          </Box>
        )}
        {changes.length ? (
          changes.map(([key, value]) => (
            <Box key={key} display="flex">
              <BodyBigText>
                <b>{key}:&nbsp;</b>
              </BodyBigText>
              <BodyBigText>{value}</BodyBigText>
            </Box>
          ))
        ) : (
          <BodyBigText>No changes for selected day</BodyBigText>
        )}
        <ManageTemplates sourceId={order.orderId} defaultTemplates={defaultTemplates} />
      </Box>
    </Modal>
  );
};

export { UpdateOrderDialog };
