enum AutomationRuleCategory {
  NEW_CUSTOMER = 'NEW_CUSTOMER',
  REPEAT_CUSTOMER = 'REPEAT_CUSTOMER',
  NEW_LEADS = 'NEW_LEADS',
  REMINDER = 'REMINDER',
  USERS = 'USERS',
  SERVICES = 'SERVICES',
  STATUS_CHANGE = 'STATUS_CHANGE',
  MISC = 'MISC',
  ACCOUNTS = 'ACCOUNTS',
  PAYMENT_RECEIPT = 'PAYMENT_RECEIPT',

  DONT_SEND_EMAIL_AUTOMATICALLY = 'DONT_SEND_EMAIL_AUTOMATICALLY',
  INVOICE = 'INVOICE',
}

export { AutomationRuleCategory };
