enum EstimateType {
  FLAT_RATE = 'FLAT_RATE',
  BINDING = 'BINDING',
  NON_BINDING = 'NON_BINDING',
}

const EstimateTypeName: Record<EstimateType, string> = {
  [EstimateType.FLAT_RATE]: 'Flat Rate',
  [EstimateType.BINDING]: 'Binding',
  [EstimateType.NON_BINDING]: 'Non-Binding',
};

export { EstimateTypeName };
export default EstimateType;
