export const SEND_MASS_EMAIL = 'SEND_MASS_EMAIL';
export const SEND_MASS_TEXT = 'SEND_MASS_TEXT';
export const ASSIGN_SALES_PERSON = 'ASSIGN_SALES_PERSON';
// (not MVP) export const ADD_FLAG = 'ADD_FLAG';
export const EXPORT = 'EXPORT';

export const AccountsActionType = {
  [SEND_MASS_EMAIL]: 'Send Mass Email',
  [SEND_MASS_TEXT]: 'Send Mass Text',
  [ASSIGN_SALES_PERSON]: 'Assign Sales Person',
  // (not MVP) [ADD_FLAG]: 'Add Flag',
  [EXPORT]: 'Export',
};
