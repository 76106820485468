import React from 'react';

import PropTypes from 'prop-types';

import { listOfAvailableStatuses } from 'admin/constants/OrderStatus';
import { CircleIconLabelDropDown } from 'common/components/Widgets';

function StatusSelector({ disabled, value, onChange, originalStatus }) {
  return (
    <CircleIconLabelDropDown
      onItemClick={onChange}
      data={listOfAvailableStatuses(originalStatus || value)}
      value={value}
      disabled={disabled}
    />
  );
}

StatusSelector.propTypes = {
  value: PropTypes.string.isRequired,
  originalStatus: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

StatusSelector.defaultProps = {
  originalStatus: '',
};

export default StatusSelector;
