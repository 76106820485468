import { Set } from 'immutable';
import { RoleName } from '@elromcoinc/react-shared';

const isAdmin = roles => {
  const setOfRoles = Set(roles);
  return (
    setOfRoles.has(RoleName.ROLE_COMPANY_ADMIN) ||
    setOfRoles.has(RoleName.ROLE_ELROMCO_ADMIN) ||
    setOfRoles.has(RoleName.ROLE_ELROMCO_USER)
  );
};

const isCompanyUser = roles => {
  const setOfRoles = Set(roles);
  return setOfRoles.has(RoleName.ROLE_COMPANY_EMPLOYEE);
};

const isAdminOrCompanyUser = roles => isAdmin(roles) || isCompanyUser(roles);

const authRuleFunctions = {
  canUseAdminApplication: isAdminOrCompanyUser,
  canUseSettings: isAdminOrCompanyUser,
  canSeeDepartments: isAdminOrCompanyUser,
  canAddDepartment: isAdminOrCompanyUser,
  canEditDepartment: isAdminOrCompanyUser,
  canManageCompanies: isAdminOrCompanyUser,
  canManageLeads: isAdminOrCompanyUser,
  canUseAccounts: isAdminOrCompanyUser,
  canUseCalendar: isAdminOrCompanyUser,
  canUseDispatch: isAdminOrCompanyUser,
  canUseSupport: isAdmin,
  canUseReports: isAdmin,
  canViewEmails: isAdminOrCompanyUser,
  canUseClaims: isAdmin,
  canUseInvoices: isAdminOrCompanyUser,
  canSeeRoot: () => true
};

const authRules = Object.assign(...Object.keys(authRuleFunctions).map(k => ({ [k]: k })));

function authRuleFunction(permissions, roles) {
  const authRule = authRuleFunctions[permissions];
  return authRule && authRule(roles);
}

const getAuthUser = s => s.auth.user;

export { authRules, authRuleFunctions, authRuleFunction, getAuthUser };
