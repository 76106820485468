import { Dispatch, SetStateAction, createContext } from 'react';

import { Order } from '@elromcoinc/react-shared';

interface OrderStateContextProps {
  order: Order | null;
  setOrder: Dispatch<SetStateAction<Order | null>>;
  lastUpdated: Order | null;
  setLastUpdated: Dispatch<SetStateAction<Order | null>>;
  originalOrder: Order | null;
  setOriginalOrder: Dispatch<SetStateAction<Order | null>>;
}

const OrderStateContext = createContext({} as OrderStateContextProps);

export { OrderStateContext };
