import axios from 'axios';
import queryString from 'query-string';

import Activity from 'admin/entities/ManualActivity';

class ActivityApi {
  getOrderActivities(orderId: number, pageIndex = 0, pageSize = 100) {
    return axios.get(
      `/api/communications/activities/order/${orderId}?${queryString.stringify({ pageIndex, pageSize })}`,
    );
  }

  getOrderLogs<T = any>(orderId: number, pageIndex = 0, pageSize = 10000) {
    return axios.get<PageDTO<T>, PageDTO<T>>(
      `/api/moving/order/${orderId}/activities?${queryString.stringify({ pageIndex, pageSize })}`,
    );
  }

  getOrderClosingAndBOLLogs<T = any>(orderId: number, pageIndex = 0, pageSize = 10000) {
    return axios.get<PageDTO<T>, PageDTO<T>>(`/api/moving/order/${orderId}/activities/closingAndBillOfLading`, {
      params: { pageIndex, pageSize },
    });
  }

  getAccountLogs(orderId: number, pageIndex = 0, pageSize = 10000) {
    return axios.get(`/api/moving/account/${orderId}/activities?${queryString.stringify({ pageIndex, pageSize })}`);
  }

  saveManuallyEnteredActivity(activity: Activity) {
    return axios.post('/api/communications/manuallyEnteredActivity/', activity);
  }

  deleteManuallyEnteredActivity(id: number) {
    return axios.delete(`/api/communications/manuallyEnteredActivity/${id}`);
  }
  getEmployeeActivities<T = any>(id: number, pageIndex = 0, pageSize = 10) {
    return axios.get<PageDTO<T>, PageDTO<T>>(
      `/api/admin/employee/${id}/activities?${queryString.stringify({ pageIndex, pageSize })}`,
    );
  }
}

export default new ActivityApi();
