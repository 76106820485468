// you can use this formats only for date-fns
/**
 * @deprecated Please use constants from react-ui-kit latest version 0.28.4
 * @type {string}
 */
export const BACKEND_DATE_FORMAT = 'yyyy-MM-dd';
export const BACKEND_TIME_FORMAT = 'p';

export const UI_DATE_FORMAT = 'MMMM dd yyyy';
export const UI_DATE_FORMAT_SHORT = 'P';
export const UI_TIME_FORMAT = 'p';
export const TIME_STRING_FORMAT = 'HH:mm:ss';
export const UI_DATE_FORMAT_SALES_LEAD = 'LL/d/yy, p';
export const BACKEND_CUSTOM_PAYMENT_FORMAT = 'yyyy-MM-dd HH:mm:ss';
