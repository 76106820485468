import React, { FC } from 'react';

import {
  DataTable,
  DataTableColumnDef,
  DataTableOptions,
  InvoiceDto,
  InvoicesStatusType,
} from '@elromcoinc/react-shared';
import { useTheme } from '@material-ui/core';

import { useInvoiceModalContext } from 'admin/components/OrderWindow/modals/Invoices/InvoiceModalContext';
import { useInvoiceStatusColors } from 'admin/hooks';
import { InvoiceActionType } from 'common-types';
import { SortOrder } from 'common-types/SortOrder';

import { makeInvoicesModalColumns } from './makeInvoicesModalColumns';

interface InvoicesTableProps {
  sendOrResendInvoice: (status: InvoicesStatusType, invoice: InvoiceDto) => void;
  handleInvoiceAction: (invoice: InvoiceDto, invoiceAction: InvoiceActionType) => void;
  inFlight: boolean;
}

const InvoicesTable: FC<InvoicesTableProps> = ({ sendOrResendInvoice, handleInvoiceAction }) => {
  const theme = useTheme();
  const {
    page,
    setPage,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    inFlight,
    invoices,
    invoicesCount,
    rowsPerPage,
    setRowsPerPage,
  } = useInvoiceModalContext();
  const statusColor = useInvoiceStatusColors(theme);

  const onColumnSortChange = (changedColumn: string, direction: SortOrder) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const invoicesTableOptions = {
    showExpandableRowsOnMobile: true,
    smallPadding: true,
    rowsPerPage,
    serverSide: true,
    page: page - 1,
    selectableRowsOnClick: false,
    alternateTableRowColors: true,
    selectableRowsHideCheckboxesCustom: true,
    selectableRowsHideCheckboxes: false,
    showOnMobileAsExpanded: true,
    offHorizontalScrollFooter: true,
    disableClickAway: true,
    selectableRows: 'none',
    tableBodyHeight: '300px',
    rowHover: false,
    sortOrder: {
      name: sortBy,
      direction: sortOrder,
    },
    fixedHeader: true,
    onColumnSortChange,
    onChangeRowsPerPage: (rpp: number) => {
      setPage(1);
      setRowsPerPage(rpp);
    },
    onChangePage: (p: number) => {
      setPage(p + 1);
    },
    count: invoicesCount,
    inFlight: inFlight,
  };

  const columns = makeInvoicesModalColumns(
    invoices,
    statusColor,
    sendOrResendInvoice,
    inFlight,
    handleInvoiceAction,
  ) as DataTableColumnDef[];

  return <DataTable data={invoices} columns={columns} options={invoicesTableOptions as DataTableOptions} />;
};

export default InvoicesTable;
