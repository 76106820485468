import { ActivitySourceType, Flag, MoveType } from '@elromcoinc/react-shared';
import axios from 'axios';

export const getFlagsByActivitySource = (activitySource: ActivitySourceType) => {
  return axios.get<Flag[], Flag[]>(`/api/moving/flags/type/${activitySource}`);
};

export const createOrUpdateFlag = (flag: Flag) => {
  return axios.put<Flag, Flag>('/api/moving/flags/flag', flag);
};

export const createOrUpdateFlags = (flags: Flag[]) => {
  return axios.put('/api/moving/flags', flags);
};

export const replicateFlagsForOtherMoveTypes = (moveType: MoveType) => {
  return axios.post(`/api/moving/flags/order/moveType/${moveType}/replicas`, {});
};

export const deleteFlag = (id: number) => {
  return axios.delete(`/api/moving/flags/${id}`);
};

export const flaggedEntitiesCount = (id: number) => {
  return axios.get<number, number>(`/api/moving/flags/${id}/flaggedCount`);
};
