import { format, sub } from 'date-fns';
import { UI_DATE_FORMAT_SHORT, UI_LOCALIZED_TIME_FORMAT } from '@elromcoinc/react-shared';
import { ReportFileName, ReportsType } from 'admin/constants/ReportsType';

export const getExportedReportName = (name: ReportsType, date: Date, defaultReportPeriodInCSVFile: number) => {
  const reportStartDate = format(sub(date, { days: defaultReportPeriodInCSVFile }), UI_DATE_FORMAT_SHORT);
  const reportEndDate = format(date, UI_DATE_FORMAT_SHORT);
  const downloadTime = format(date, UI_LOCALIZED_TIME_FORMAT);

  return `${ReportFileName[name]}-from-${reportStartDate}-to-${reportEndDate}-at-${downloadTime}.csv`;
};
