export const ACTION_FOR_SELECTED = 'actionForSelected';
export const LEAD_SCORE = 'leadScore';
export const LEAD_SCORE_MIN = 'leadScoreMin';
export const LEAD_SCORE_MAX = 'leadScoreMax';
export const ACCOUNT_TYPE = 'account.type';
export const SALES_PERSON = 'assignedTo.id';
export const SOURCE = 'leadSource';
export const FLAGS = 'flag';
export const TO_STATE = 'toState';
export const STATUS = 'status';
export const MOVE_TYPE = 'moveType';
export const SERVICE_TYPE = 'serviceType';
export const ESTIMATE_TYPE = 'estimateType';
export const MOVE_SIZE = 'sizeDescription';
export const FROM_STATE = 'fromState';
export const DATE_TYPE = 'dateType';
export const BOOKED_OPTIONS = 'bookedOptions';
export const INVENTORY = 'hasInventory';
export const MOVE_START_DATE = 'moveStartDate';
export const CREATED = 'created';
export const BOOKING_DATE = 'bookingDate';
export const STATUS_UPDATED = 'statusUpdated';

export const OrdersLabels = {
  [ACTION_FOR_SELECTED]: 'Action for selected items',
  [LEAD_SCORE]: 'Lead Score',
  [ACCOUNT_TYPE]: 'Account Type',
  [SALES_PERSON]: 'Sales Person',
  [SOURCE]: 'Source',
  [FLAGS]: 'Flags',
  [TO_STATE]: 'To State',
  [STATUS]: 'Status',
  [MOVE_TYPE]: 'Move Type',
  [SERVICE_TYPE]: 'Service Type',
  [ESTIMATE_TYPE]: 'Estimate Type',
  [MOVE_SIZE]: 'Move Size',
  [FROM_STATE]: 'From State',
  [DATE_TYPE]: 'Date Type',
  [BOOKED_OPTIONS]: 'Booked Period',
  [INVENTORY]: 'Inventory',
};
