import axios from 'axios';

class DepartmentAPI {
  createDepartmentAPI({ name }) {
    return axios.post('/api/admin/department', { name });
  }

  listAllDepartmentsAPI() {
    return axios.get(`/api/admin/departments`);
  }

  updateDepartmentAPI(body) {
    return axios.put('/api/admin/department', body);
  }

  deleteDepartment(id) {
    return axios.delete(`/api/admin/department/${id}`);
  }

  saveDepartmentNotifiableEvents(id, events) {
    return axios.put(`/api/admin/department/${id}/defaultNotifiableEventSettings`, events);
  }

  resetDepartmentNotifications(id) {
    return axios.put(`/api/admin/department/${id}/notificationDefaults`);
  }

  resetDepartmentPermissions(id) {
    return axios.put(`/api/admin/department/${id}/permissionDefaults`);
  }
}

export default new DepartmentAPI();
