import React from 'react';

import { BodySmallText, BodyText } from '@elromcoinc/react-shared';
import { Box, Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import { getIn } from 'immutable';
import pt from 'prop-types';

import { ExistCardsList } from 'admin/components/Settings/components/SmsSettings/components';

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    maxWidth: '12rem',
    minWidth: '12rem',
    marginTop: '1rem',
  },
  cardTitle: {
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  header: {
    padding: '10px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: '0 16px 8px !important',
  },
  roundedCard: {
    '&.MuiCard-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

export const SavedCards = ({ allStripeCustomers, isLoadingTwilioAccount }) => {
  const classes = useStyles();

  const paymentMethods = getIn(allStripeCustomers, [0, 'paymentMethods'], []);
  const firstPaymentMethod = paymentMethods[0];

  return (
    <Card className={classes.roundedCard} classes={{ root: classes.card }}>
      <CardHeader
        title="Saved Cards"
        classes={{ title: classes.cardTitle, content: classes.cardHeader, root: classes.header }}
      />
      {isLoadingTwilioAccount ? (
        <Box display="flex" justifyContent="center" mb={2}>
          <BodySmallText color="primary">Loading...</BodySmallText>
        </Box>
      ) : (
        <CardContent classes={{ root: classes.content }}>
          {allStripeCustomers.length > 0 ? (
            <ExistCardsList firstPaymentMethod={firstPaymentMethod} />
          ) : (
            <BodyText align="center">N/A</BodyText>
          )}
        </CardContent>
      )}
    </Card>
  );
};

SavedCards.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array,
  isLoadingTwilioAccount: pt.bool,
};

SavedCards.defaultProps = {
  allStripeCustomers: null,
  isLoadingTwilioAccount: false,
};
