import { parseISO } from '@elromcoinc/react-shared';
import { addDays, addMinutes, isAfter, isDate, isSameDay, startOfDay } from 'date-fns';

import { convertLocalTimeToMinutes } from 'admin/components/CrewStatusLog/utils/convertLocalTimeToMinutes';

export const normalizeFirstLoggedTime = (startDateTime?: Date | string | null, firstStatusDate?: string | null) => {
  if (firstStatusDate && startDateTime) {
    const parsedStatusDate = parseISO(firstStatusDate)!;
    const parsedStartDateTime = (isDate(startDateTime) ? startDateTime : parseISO(startDateTime as string)) as Date;

    if (isAfter(parsedStartDateTime, parsedStatusDate) && !isSameDay(parsedStatusDate, parsedStartDateTime)) {
      const time = convertLocalTimeToMinutes(parsedStartDateTime);

      return addMinutes(startOfDay(addDays(parsedStatusDate, 1)), time);
    }
  }

  return (isDate(startDateTime) ? startDateTime : parseISO(startDateTime as string)) as Date;
};
