// @ts-ignore
import autodux from 'autodux';

interface ReloadState {
  inFlight: boolean;
  reloadAmount: number;
}
const {
  reducer,
  actions: { setInFlight, increaseReloadAmount },
  selectors: { getInFlight, getReloadAmount },
} = autodux({
  slice: 'reload',
  initial: {
    inFlight: false,
    reloadAmount: 0,
  },
  actions: {
    increaseReloadAmount: (state: ReloadState) => ({
      ...state,
      reloadAmount: state.reloadAmount + 1,
    }),
  },
});

export default reducer;
export { getInFlight, getReloadAmount, setInFlight, increaseReloadAmount };
