import React from 'react';

import { Box } from '@material-ui/core';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons';
import pt from 'prop-types';
import styled from 'styled-components';

import { TaskPriority as TASK_PRIORITY, TaskPriorityColor, TaskPriorityName } from 'common-types/Task';

const PriorityStyled = styled(Box)<{ $color: string }>`
  color: ${({ $color }) => $color};
  height: 20px;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 50%;
  margin: 3px;
`;

const urgentTasks = [TASK_PRIORITY.URGENT, TASK_PRIORITY.HIGH];

interface TaskPriorityProps {
  priority: TASK_PRIORITY;
  showText?: boolean;
}

const TaskPriority = ({ priority, showText = true }: TaskPriorityProps) => (
  <Box display="flex" alignItems="center">
    {showText && 'Priority:'}
    <PriorityStyled component="span" $color={TaskPriorityColor[priority]}>
      {urgentTasks.includes(priority) && <ArrowUpwardIcon fontSize="small" color="inherit" />}
      {priority === TASK_PRIORITY.NORMAL && <RadioButtonUncheckedIcon fontSize="small" color="inherit" />}
      {priority === TASK_PRIORITY.LOW && <ArrowDownwardIcon fontSize="small" color="inherit" />}
    </PriorityStyled>
    {` ${TaskPriorityName[priority]}`}
  </Box>
);

TaskPriority.propTypes = {
  priority: pt.oneOf(Object.values(TASK_PRIORITY)).isRequired,
};

export default TaskPriority;
