import React, { useRef, useState } from 'react';

import { BACKEND_DATE_FORMAT, Order } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { addDays, format, startOfWeek } from 'date-fns';
import pt from 'prop-types';

import {
  HeaderRow,
  HoursContainer,
  Spacer,
  SpacerWithoutBorder,
  TruckHeaderRow,
  renderHours,
} from 'admin/components/OrderWindow/SchedulerBox/common';
import useScrollTo7Am from 'admin/components/OrderWindow/SchedulerBox/hooks/useScrollTo7Am';
import {
  getEstimatorWorks,
  getEstimatorsToRender,
  renderDay,
  renderEstimation,
  renderEstimatorName,
} from 'admin/components/OrderWindow/SurveySchedulerBox/config';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  employeeNamesPerDay: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
    },
  },
  servicePerDay: {
    '&:nth-child(n+1):not(:last-child)': {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
      minWidth: '1632px',
    },
  },
}));

const days = [0, 1, 2, 3, 4, 5, 6];

export const WeekView = ({
  estimators,
  date,
  handleEstimatorClick,
  dense,
  order,
  currentSurveyValue,
  handleCloseModal,
}) => {
  const classes = useStyles();
  const scrollRef = useRef({});
  useScrollTo7Am(scrollRef);
  const weekDays = days.map((day) => addDays(startOfWeek(date), day));
  const estimatorJobsPerDay = weekDays.map((day) => format(day, 'd')).map((day) => [day] || {});
  const estimatorsToRender = getEstimatorsToRender(estimators);
  const weekDayKeys = weekDays.map((day) => format(day, 'd'));
  const [selectedWork, setSelectedWork] = useState(null);

  const handleOnWorkClick = (workId) => {
    setSelectedWork(workId);
  };

  if (!estimators.length) {
    return null;
  }

  return (
    <Box className={classes.wrapper}>
      <div>
        <TruckHeaderRow>
          <SpacerWithoutBorder $width={100} />
        </TruckHeaderRow>
        {weekDays.map((day, idx) => renderDay(day, idx)(estimators))}
      </div>
      <div>
        <TruckHeaderRow>
          <Spacer />
        </TruckHeaderRow>
        {estimatorJobsPerDay.map((_, idx) => (
          <Box key={weekDayKeys[idx]} className={classes.employeeNamesPerDay}>
            {estimatorsToRender.map((estimator) =>
              renderEstimatorName(
                handleEstimatorClick,
                dense,
                [],
                currentSurveyValue.estimatorId,
                weekDays[idx],
                currentSurveyValue,
                true,
                estimators,
              )(estimator, idx),
            )}
          </Box>
        ))}
      </div>
      <HoursContainer ref={scrollRef}>
        <HeaderRow>{renderHours()}</HeaderRow>
        {estimatorJobsPerDay.map((_, idx) => (
          <Box key={weekDayKeys[idx]} className={classes.servicePerDay}>
            {estimatorsToRender.map((estimator) =>
              renderEstimation(
                dense,
                getEstimatorWorks(estimators, currentSurveyValue, format(weekDays[idx], BACKEND_DATE_FORMAT), order),
                order,
                weekDays[idx],
                currentSurveyValue,
                handleOnWorkClick,
                handleCloseModal,
                selectedWork,
                estimators,
              )(estimator, idx),
            )}
          </Box>
        ))}
      </HoursContainer>
    </Box>
  );
};

WeekView.propTypes = {
  date: pt.instanceOf(Date).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  estimators: pt.array,
  handleEstimatorClick: pt.func,
  dense: pt.bool,
  order: pt.instanceOf(Order).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSurveyValue: pt.object.isRequired,
  handleCloseModal: pt.func,
};

WeekView.defaultProps = {
  estimators: [],
  handleEstimatorClick: null,
  dense: true,
  handleCloseModal: null,
};
