import React, { useEffect, useRef, useState } from 'react';

import { BodyText, Button, IconButton, useDebounce, useDebounceCallback } from '@elromcoinc/react-shared';
import { Box, ClickAwayListener, InputBase, LinearProgress, Paper, Popper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import searchApi, { SearchDomains } from 'admin/api/searchApi';
import { openAccount, openOrder } from 'admin/autodux/WindowsAutodux';
import AccountItem from 'admin/components/Search/AccountItem';
import OrderItem from 'admin/components/Search/OrderItem';
import SearchList from 'admin/components/Search/SearchList';

const useStyles = makeStyles((theme) => ({
  form: {
    marginLeft: theme.spacing(1),
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 200,
    [theme.breakpoints.up('lg')]: {
      width: 300,
    },
    [theme.breakpoints.down('xs')]: {
      width: 160,
      marginLeft: 0,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  white: {
    color: theme.palette.common.white,
  },
  iconButton: {
    padding: 10,
  },
  popper: {
    marginLeft: theme.spacing(1),
    boxShadow: theme.shadows[5],
    maxHeight: '95vh',
    overflowY: 'auto',
    top: '-4px !important',
    zIndex: 1500,
  },
  popperPaper: {
    width: 308,
    boxSizing: 'border-box',
    padding: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
  searchIcon: {
    fontSize: '2.5rem',
  },
}));

const Search = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [previousSearch, setPreviousSearch] = useState('');
  const [orders, setCurrentOrders] = useState(null);
  const [accounts, setCurrentAccounts] = useState(null);
  const [inFlight, setInFlight] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [domain, setDomain] = useState(SearchDomains.ORDERS);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalsElements, setTotalElements] = useState(1);
  const debouncedSearch = useDebounce(searchTerm, 1e3);

  useDebounceCallback(() => setShowProgress(inFlight), 200);

  const handleOnClose = () => {
    setOpen(false);
    searchTerm && setPreviousSearch(searchTerm);
    setSearchTerm('');
  };

  const handleChangeDomain = (domain) => () => {
    setPageIndex(0);
    setDomain(domain);
  };

  const handleChangePageIndex = (event, page) => {
    setPageIndex(page - 1);
  };

  function handleSearchRequest(searchRequest, domain, pageIndex) {
    if (searchRequest && searchRequest.length > 0 && domain) {
      setInFlight(true);
      searchApi(searchRequest, SearchDomains.ALL, pageIndex)
        .then((searchResult) => {
          const newOrders = searchResult.orders && searchResult.orders.pageElements;
          const newAccounts = searchResult.accounts && searchResult.accounts.pageElements;
          const totalPageElements =
            domain === SearchDomains.ORDERS ? searchResult?.orders.totalPages : searchResult?.accounts.totalPages;
          setCurrentOrders(newOrders);
          setCurrentAccounts(newAccounts);
          setTotalElements(totalPageElements);
        })
        .catch(() => false)
        .then(() => {
          setInFlight(false);
          setShowProgress(false);
        });
    }
  }

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSearchRequest(searchTerm);
  };

  const handleOpenOrder =
    ({ orderId }) =>
    () => {
      handleOnClose();
      dispatch(openOrder(orderId));
    };

  const handleOpenAccount =
    ({ id }) =>
    () => {
      handleOnClose();
      dispatch(openAccount(id));
    };

  useEffect(() => {
    const searchRequest = searchTerm ? searchTerm : previousSearch;

    handleSearchRequest(searchRequest, domain, pageIndex);
  }, [debouncedSearch, domain, pageIndex]);

  function renderResult() {
    if (orders || accounts || (inFlight && showProgress)) {
      const hasOrders = orders?.length > 0;
      const hasAccounts = accounts?.length > 0;
      return (
        <Popper className={classes.popper} open={open} onClose={handleOnClose} anchorEl={ref.current}>
          <Paper className={classes.popperPaper} square>
            {inFlight && showProgress && (
              <Box height={8} mt={-1}>
                <LinearProgress color="primary" />
              </Box>
            )}
            <Box display="flex" alignItems="center" mb={1}>
              <Box mr={1}>
                <Button
                  onClick={handleChangeDomain(SearchDomains.ORDERS)}
                  color={domain === SearchDomains.ORDERS ? 'primary' : 'inherit'}
                  size="small"
                >
                  Orders
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={handleChangeDomain(SearchDomains.ACCOUNTS)}
                  color={domain === SearchDomains.ACCOUNTS ? 'primary' : 'inherit'}
                  size="small"
                >
                  Accounts
                </Button>
              </Box>
            </Box>
            {hasOrders && domain === SearchDomains.ORDERS && (
              <SearchList list={orders} ItemComponent={OrderItem} onClick={handleOpenOrder} />
            )}
            {hasAccounts && domain === SearchDomains.ACCOUNTS && (
              <SearchList list={accounts} ItemComponent={AccountItem} onClick={handleOpenAccount} />
            )}
            {((!hasOrders && domain === SearchDomains.ORDERS) ||
              (!hasAccounts && domain === SearchDomains.ACCOUNTS)) && <BodyText>Nothing was found...</BodyText>}
            <Box display="flex" justifyContent="center">
              <Pagination
                page={pageIndex + 1}
                color="primary"
                size="small"
                shape="rounded"
                count={totalsElements}
                onChange={handleChangePageIndex}
              />
            </Box>
          </Paper>
        </Popper>
      );
    }
    return null;
  }

  function renderInput() {
    if (!open) {
      return null;
    }
    return (
      <Paper className={classes.form} component="form" onSubmit={handleSearchSubmit}>
        <InputBase
          value={searchTerm}
          autoFocus
          className={classes.input}
          placeholder="Search..."
          onChange={({ target: { value } }) => setSearchTerm(value)}
        />
        <IconButton color="default" type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    );
  }

  function renderSearchButton() {
    if (open) {
      return null;
    }
    return (
      <IconButton
        className={clsx(classes.iconButton, classes.white)}
        data-testid="openSearchButton"
        aria-label="open search"
        onClick={() => setOpen(true)}
      >
        <SearchIcon className={classes.searchIcon} />
      </IconButton>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleOnClose}>
      <div ref={ref}>
        {renderInput()}
        {renderSearchButton()}
        {renderResult()}
      </div>
    </ClickAwayListener>
  );
};

export default Search;
