import { addHours } from 'date-fns';
import { getIn } from 'immutable';
import { useSelector } from 'react-redux';

import { CREATE_A_FOLLOW_UP } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { DUE, EMPLOYEE_ID, PRIORITY, SUBJECT, TASK_TYPE } from 'admin/components/OrderWindow/modals/Tasks';
import { TaskPriority, TaskType } from 'common-types/Task';

export const useDefaultFollowUpValues = () => {
  const currentUserId = useSelector((state) => getIn(state, ['auth', 'user', 'id'], null)) as number;

  return {
    [CREATE_A_FOLLOW_UP]: false,
    [DUE]: addHours(new Date(), 1),
    [EMPLOYEE_ID]: currentUserId,
    [PRIORITY]: TaskPriority.NORMAL,
    [TASK_TYPE]: TaskType.CALL,
    [SUBJECT]: 'Follow Up',
  };
};
