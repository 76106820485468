import React, { useState } from 'react';

import { BodyText, ElromcoCircularProgress, Modal, Radio, RadioGroup, statusIds } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import orderApi from 'admin/api/OrderAPI';
import { STATUS } from 'admin/components/Orders/config';
import { listOfAvailableStatuses, listOfStatuses } from 'admin/constants/OrderStatus';
import Status from 'common/components/Status';

const schema = object().shape({
  [STATUS]: string().label('Status').nullable().required('Please select one status from the list'),
});

export const ChangeStatusModal = ({
  title,
  onClose,
  onSave,
  currentStatus,
  rowsChecked,
  orderIds,
  selectedDashboardTab,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
  });

  const statusOptions = selectedDashboardTab
    ? listOfAvailableStatuses(currentStatus).filter((item) => {
        if (currentStatus === statusIds.CAN_BOOK_ONLINE) {
          return item.id !== currentStatus;
        } else {
          return item.id !== currentStatus && item.id !== statusIds.BOOKED;
        }
      })
    : listOfStatuses(currentStatus);

  const handleChangeStatus = ({ [STATUS]: status }) => {
    setIsProcessing(true);
    Promise.all(
      orderIds.map((orderId) =>
        orderApi.updateOrderStatus(orderId, status).then(() => orderApi.savePendingOrderUpdates(orderId)),
      ),
    )
      .then(() => {
        enqueueSnackbar('Status changed successfully', { variant: 'success' });
        onSave();
        onClose();
      })
      .catch(
        ({
          response: {
            json: { errors: e },
          },
        }) => {
          enqueueSnackbar(`Cannot change status: ${e.map(({ message }) => `${message}`)}`, { variant: 'error' });
        },
      )
      .then(() => {
        setIsProcessing(false);
      });
  };

  return (
    <Modal
      onClose={onClose}
      title={title}
      hideActionControls={isProcessing}
      maxWidth="xs"
      open
      size="small"
      actions={[
        { label: 'cancel', onClick: onClose, color: 'primary' },
        { label: 'save', onClick: handleSubmit(handleChangeStatus), color: 'primary' },
      ]}
    >
      {isProcessing ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <BodyText color="primary">
            <b>Сhanging status for {rowsChecked.length} order(s)...</b>
          </BodyText>
          <Box pt={1}>
            <ElromcoCircularProgress />
          </Box>
        </Box>
      ) : (
        <RadioGroup aria-label="statuses list" name={STATUS} control={control}>
          {statusOptions.map((item, index) => (
            <Radio
              color="primary"
              variant="checkbox"
              sameUncheckedColor
              key={item.id}
              value={item.id}
              label={
                <Status label={item.label.replace(/_/g, ' ')} title={item.label.replace(/_/g, ' ')} size="small" />
              }
              control={<Radio autoFocus={index === 0} />}
            />
          ))}
        </RadioGroup>
      )}
    </Modal>
  );
};

ChangeStatusModal.propTypes = {
  title: pt.string.isRequired,
  onClose: pt.func.isRequired,
  onSave: pt.func.isRequired,
  currentStatus: pt.string,
  rowsChecked: pt.arrayOf(pt.number),
  selectedDashboardTab: pt.number,
  orderIds: pt.arrayOf(pt.number),
};

ChangeStatusModal.defaultProps = {
  isProcessing: false,
  currentStatus: null,
  rowsChecked: [],
  selectedDashboardTab: null,
  orderIds: [],
};
