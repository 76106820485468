import React, { FC } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, Grid, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';

import {
  DESCRIPTION,
  DISCOUNT,
  InvoicesItemLabels,
  TOTAL,
  UNIT_COST,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';

import { InvoiceFormRow } from './InvoiceFormRow';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    form: {
      marginTop: spacing(4),
      position: 'relative',
      zIndex: 1,
    },
    title: {
      color: palette.common.white,
      backgroundColor: palette.primary.main,
      padding: spacing(1, 0),
    },
  }),
);

interface InvoiceItemProps {
  fields: Record<'id', string>[];
  remove: (index: number | number[]) => any;
  isReadOnly: boolean;
}

const InvoiceItemForm: FC<InvoiceItemProps> = ({ fields, remove, isReadOnly }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={1} square>
              <Grid container className="applyTopRadius" classes={{ root: classes.title }}>
                <Grid item xs={3}>
                  <Box ml={2}>
                    <BodyText>
                      <b>Item</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box ml={2}>
                    <BodyText>
                      <b>{InvoicesItemLabels[DESCRIPTION]}</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box ml={2}>
                    <BodyText>
                      <b>{InvoicesItemLabels[UNIT_COST]}</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box ml={5}>
                    <BodyText>
                      <b>Qty</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box ml={4}>
                    <BodyText>
                      <b>{InvoicesItemLabels[DISCOUNT]}</b>
                    </BodyText>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box ml={2}>
                    <BodyText>
                      <b>{InvoicesItemLabels[TOTAL]}</b>
                    </BodyText>
                  </Box>
                </Grid>
              </Grid>
              {fields.map(({ id }, index: number) => (
                <InvoiceFormRow key={id} id={id} index={index} remove={remove} isReadOnly={isReadOnly} />
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export { InvoiceItemForm };
