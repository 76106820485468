import React, { useState } from 'react';

import { BodyText, Select } from '@elromcoinc/react-shared';
import MenuItem from '@material-ui/core/MenuItem';
import LabelTableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import pt from 'prop-types';

import { FlexWithNegativeMargin, ValueTableCell } from './CommonComponents';

export default function BestContact({ contactInfo, onChange, disabled }) {
  const [edit, setEdit] = useState(false);
  const bestContactInfo = [contactInfo.bestWayToContact, contactInfo.bestTimeToContact].filter(Boolean).join('/');
  const onChangeContactInfo = ({ target: { value, name } }) => {
    onChange({ name, value });
  };
  const onHandleEdit = () => {
    setEdit(true);
  };
  return (
    <TableRow>
      <LabelTableCell size="small" padding="none">
        <BodyText>Best Contact</BodyText>
      </LabelTableCell>
      <ValueTableCell>
        {edit ? (
          <FlexWithNegativeMargin>
            <Select
              value={contactInfo.bestWayToContact || ''}
              autoSelect
              fullWidth
              MenuProps={{ disablePortal: true }}
              SelectProps={{
                displayEmpty: true,
              }}
              InputProps={{
                disableUnderline: true,
              }}
              hiddenLabel
              size="small"
              name="contactInfo.bestWayToContact"
              onChange={onChangeContactInfo}
              disabled={disabled}
            >
              <MenuItem disabled value="">
                None
              </MenuItem>
              <MenuItem value="Email">Email</MenuItem>
              <MenuItem value="Text">Text</MenuItem>
              <MenuItem value="Phone">Phone</MenuItem>
            </Select>
            /
            <Select
              fullWidth
              value={contactInfo.bestTimeToContact || ''}
              SelectProps={{
                displayEmpty: true,
              }}
              InputProps={{
                disableUnderline: true,
              }}
              hiddenLabel
              name="contactInfo.bestTimeToContact"
              onChange={onChangeContactInfo}
              disabled={disabled}
            >
              <MenuItem disabled value="">
                None
              </MenuItem>
              <MenuItem value="Morning">Morning</MenuItem>
              <MenuItem value="Afternoon">Afternoon</MenuItem>
              <MenuItem value="Evening">Evening</MenuItem>
            </Select>
          </FlexWithNegativeMargin>
        ) : (
          <BodyText onClick={onHandleEdit}>
            <b>{bestContactInfo || 'None'}</b>
          </BodyText>
        )}
      </ValueTableCell>
    </TableRow>
  );
}

BestContact.propTypes = {
  contactInfo: pt.shape({
    bestWayToContact: pt.string,
    bestTimeToContact: pt.string,
  }).isRequired,
  onChange: pt.func.isRequired,
  disabled: pt.bool,
};

BestContact.defaultProps = {
  disabled: false,
};
