import React from 'react';

import { BodyBigText, Button, theme } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { format, isSameDay } from 'date-fns';
import { List, Set } from 'immutable';

import { Task } from 'common-types/Task';

import { DEFAULT_VARIANT, DisplayTaskVariant } from './DisplayTaskVariant';
import TaskDrawerItem from './TaskDrawerItem';

const useStyles = makeStyles({
  oppositeContent: {
    flex: 0,
    padding: 0,
  },
  content: {
    padding: 6,
  },
  timeLineRoot: {
    padding: '6px 0',
    [theme.breakpoints.down('sm')]: {
      padding: 6,
    },
  },
});

interface TaskListProps {
  data: List<Task>;
  variant?: DisplayTaskVariant;
  toggleCompleteStatus: (task: Task) => void;
  onLoadMore: () => void;
  onDelete: (task: Task) => void;
  isUpdateInProgress: Set<number>;
  isGlobalUpdateInProgress?: boolean;
  isLoadingInProgress: boolean;
  hasMoreItems: boolean;
  noMoreTasksText?: string;
}

const TaskList = ({
  data,
  toggleCompleteStatus,
  onLoadMore,
  onDelete,
  isUpdateInProgress,
  isGlobalUpdateInProgress,
  isLoadingInProgress,
  hasMoreItems,
  variant = DEFAULT_VARIANT,
  noMoreTasksText = 'No more to do tasks',
}: TaskListProps) => {
  const classes = useStyles();

  const firstTask = data.get(0);
  const currentDateFieldName = firstTask && firstTask.isCompleted ? 'completedDate' : 'dueTime';

  return (
    <Box className={classes.timeLineRoot}>
      {data.map((item, index) => (
        <Box key={item.id} className={classes.content}>
          {item[currentDateFieldName] &&
            (index === 0 ||
              !isSameDay(item[currentDateFieldName]!, (data.get(index - 1) as Task)[currentDateFieldName] as Date)) && (
              <Box display="flex" alignItems="center" justifyContent="center" mt="-4px">
                <BodyBigText align="center" component="div">
                  <b>{format(item[currentDateFieldName]!, 'MMMM dd')}</b>
                </BodyBigText>
              </Box>
            )}
          <TaskDrawerItem
            task={item}
            toggleCompleteStatus={toggleCompleteStatus}
            variant={variant}
            onDelete={onDelete}
            disabled={isUpdateInProgress.has(item.id!) || !!isGlobalUpdateInProgress}
          />
        </Box>
      ))}
      <Box display="flex" justifyContent="center" mb={2}>
        {hasMoreItems ? (
          <Button color="primary" onClick={onLoadMore} disabled={isLoadingInProgress} loading={isLoadingInProgress}>
            Load More
          </Button>
        ) : (
          <BodyBigText align="center">{noMoreTasksText}</BodyBigText>
        )}
      </Box>
    </Box>
  );
};

export default TaskList;
