import { FC } from 'react';

import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';
import { ComposeEmailProvider } from 'admin/components/OrderWindow/modals/ComposeEmail/ComposeEmailProvider';

const OrderComposeEmailProvider: FC = ({ children }) => {
  const { order } = useOrderState();

  return (
    <ComposeEmailProvider sourceId={order?.orderId} order={order!}>
      {children}
    </ComposeEmailProvider>
  );
};

export { OrderComposeEmailProvider };
