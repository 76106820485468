import { BodySmallText } from '@elromcoinc/react-shared';

interface MultiDayIndicatorProps {
  visible: boolean;
}
export const MultiDayIndicator = ({ visible }: MultiDayIndicatorProps) => {
  if (!visible) {
    return null;
  }

  return (
    <BodySmallText component="span" color="primary">
      <b>-MD</b>
    </BodySmallText>
  );
};
