import React, { ChangeEvent, FC, useEffect } from 'react';

import {
  CurrencyInput,
  IconButton,
  PriceAdjustmentType,
  TextInput,
  onlyDecimalNumberInputFix,
  useConfirm,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useFormContext } from 'react-hook-form';

import { AdjustmentSubtotalItem } from 'admin/components/OrderWindow/modals/Invoices/EditInvoice/AdjustmentSubtotalItem';
import {
  AMOUNT,
  DESCRIPTION,
  DISCOUNT,
  ITEMS,
  ITEM_NAME,
  QUANTITY,
  TOTAL,
  TYPE,
  UNIT_COST,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    table: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    items: {
      borderRight: `1px solid ${palette.divider}`,
      borderTop: `1px solid ${palette.divider}`,
    },
    deleteButton: {
      display: 'flex',
      padding: spacing(0.53),
      justifyContent: 'center',
      boxShadow: `0px -1px 1px 0px ${palette.text.disabled} inset`,
    },
    discountItem: {
      borderRight: `1px solid ${palette.divider}`,
      borderTop: `1px solid ${palette.divider}`,
      boxShadow: `0px -1px 1px 0px ${palette.text.disabled} inset`,
    },
  }),
);

interface InvoiceFormRowProps {
  index: number;
  id: string;
  remove: (index: number | number[]) => any;
  isReadOnly: boolean;
}

export const InvoiceFormRow: FC<InvoiceFormRowProps> = ({ id, index, remove, isReadOnly }) => {
  const classes = useStyles();
  const { watch, setValue } = useFormContext();

  const { confirm: confirmDelete, renderDialog } = useConfirm({
    title: 'Warning',
    message: 'You are about to remove this item. Do you wish to proceed?',
    confirmTitle: 'Yes, Delete',
    cancelTitle: 'No, Cancel',
  });
  const manual = watch(`${ITEMS}.${index}.${TOTAL}.manual`);
  const calculated = watch(`${ITEMS}.${index}.${TOTAL}.calculated`);
  const quantity = watch(`${ITEMS}.${index}.${QUANTITY}`) || 0;
  const unitCost = watch(`${ITEMS}.${index}.${UNIT_COST}`) || 0;
  const discountType = watch(`${ITEMS}.${index}.${DISCOUNT}.${TYPE}`);
  const discountAmount = watch(`${ITEMS}.${index}.${DISCOUNT}.${AMOUNT}`) || 0;

  useEffect(() => {
    setValue(`${ITEMS}.${index}.${TOTAL}.calculated`, (+unitCost || 0) * quantity);
  }, [unitCost]);

  useEffect(() => {
    if (!discountAmount || discountAmount === 0) {
      setValue(`${ITEMS}.${index}.${TOTAL}.calculated`, (+unitCost || 0) * quantity);
    }
  }, [discountAmount, unitCost]);

  const handleChangeTotal = (value: number) => {
    setValue(`${ITEMS}.${index}.${TOTAL}.manual`, calculated !== value ? value || 0 : null);
  };

  const handleChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(`${ITEMS}.${index}.${TOTAL}.calculated`, (+event.target.value || 0) * unitCost);
  };

  const discountValue =
    discountType === PriceAdjustmentType.PERCENT
      ? (((+unitCost || 0) * quantity) / 100) * +discountAmount
      : +discountAmount;

  useEffect(() => {
    if (discountValue) {
      setValue(`${ITEMS}.${index}.${TOTAL}.calculated`, (+unitCost || 0) * quantity - discountValue);
    }
  }, [discountValue, unitCost]);

  const handleDeleteInvoiceItem = (index: number) => async () => {
    const answer = await confirmDelete();

    if (answer) {
      remove(index);
    }
  };

  return (
    <>
      <Grid container key={id} className={classes.table}>
        <Grid item xs={3}>
          <Box className={classes.items}>
            <TextInput
              fullWidth
              InputProps={{
                readOnly: isReadOnly,
              }}
              data-testid="itemName"
              name={`${ITEMS}.${index}.${ITEM_NAME}`}
              size="small"
              hiddenLabel
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box className={classes.items}>
            <TextInput
              fullWidth
              InputProps={{
                readOnly: isReadOnly,
              }}
              data-testid="description"
              name={`${ITEMS}.${index}.${DESCRIPTION}`}
              size="small"
              hiddenLabel
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box className={classes.items}>
            <CurrencyInput
              inputProps={{ prefix: '', allowNegativeValue: true }}
              size="small"
              fullWidth
              data-testid="unitCost"
              InputProps={{
                readOnly: isReadOnly,
              }}
              name={`${ITEMS}.${index}.${UNIT_COST}`}
              defaultValue="0"
              hiddenLabel
            />
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.items}>
            <TextInput
              type="number"
              size="small"
              hiddenLabel
              min={0}
              max={999}
              data-testid="quantity"
              name={`${ITEMS}.${index}.${QUANTITY}`}
              onChange={handleChangeQuantity}
              defaultValue={0}
              fullWidth
              InputProps={{
                readOnly: isReadOnly,
              }}
              {...onlyDecimalNumberInputFix}
            />
          </Box>
        </Grid>
        <Grid item xs>
          {isReadOnly ? (
            <Box className={classes.items}>
              <CurrencyInput
                data-testid="discount"
                name={`${ITEMS}.${index}.${DISCOUNT}.${AMOUNT}`}
                size="small"
                hiddenLabel
                skipControl
                InputProps={{
                  readOnly: isReadOnly,
                }}
                defaultValue="0"
                amountType={discountType || PriceAdjustmentType.PERCENT}
                inputProps={{ skipControl: true, prefix: '', allowNegativeValue: false }}
                value={discountAmount}
              />
            </Box>
          ) : (
            <Box className={classes.discountItem}>
              <AdjustmentSubtotalItem name={`${ITEMS}.${index}.${DISCOUNT}`} />
            </Box>
          )}
        </Grid>
        <Grid item xs>
          <Box className={classes.items}>
            <CurrencyInput
              size="small"
              fullWidth
              data-testid="total"
              onChange={handleChangeTotal}
              skipControl
              InputProps={{
                readOnly: isReadOnly,
              }}
              name={manual !== null ? `${ITEMS}.${index}.${TOTAL}.manual` : `${ITEMS}.${index}.${TOTAL}.calculated`}
              inputProps={{ skipControl: true, prefix: '', allowNegativeValue: true }}
              value={manual !== null ? manual : calculated}
              defaultValue="0"
              hiddenLabel
            />
          </Box>
        </Grid>
        {!isReadOnly && (
          <Grid item>
            <Box className={classes.deleteButton}>
              <Tooltip title="Delete Item">
                <Box component="span">
                  <IconButton
                    size="small"
                    color="primary"
                    data-testid="deleteItem"
                    onClick={handleDeleteInvoiceItem(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
      {renderDialog()}
    </>
  );
};
