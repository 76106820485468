import { useEffect, useRef, useState } from 'react';

const useElementSize = (defaultValue = 0, minimized) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(defaultValue);
  const [height, setHeight] = useState(defaultValue);
  const timeout = useRef(null);
  const trackSize = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const { offsetWidth, offsetHeight } = ref.current || {};
      if (offsetWidth && offsetWidth !== width) setWidth(ref.current.offsetWidth);
      if (offsetHeight && offsetHeight !== height) setHeight(ref.current.offsetHeight);
    }, 500);
  };
  useEffect(trackSize, [(ref.current || {}).offsetWidth, defaultValue, minimized]);
  useEffect(() => {
    window.addEventListener('resize', trackSize);
    return () => window.removeEventListener('resize', trackSize);
  }, []);
  return [ref, width, height];
};

export default useElementSize;
