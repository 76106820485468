import React, { useEffect, useState } from 'react';

import {
  BodyText,
  Link,
  Order,
  PaymentAdjustmentType,
  asHumanReadableDiscount,
  numberToCurrency,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Map } from 'immutable';

import { CurrencyInput } from 'admin/components/OrderWindow/components';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { AdditionalCharge } from 'admin/components/OrderWindow/modals/AdditionalCharge';
import { getServiceRosterClosingPropertyName } from 'admin/utils';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const pickupNames = Map({
  [PaymentAdjustmentType.TIP]: 'pickupTips',
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'pickupCreditCardFees',
  [PaymentAdjustmentType.CASH_TIP]: 'pickupCashTips',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'pickupCashDiscounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'pickupCustomFees',
});

const deliveryNames = Map({
  [PaymentAdjustmentType.TIP]: 'deliveryTips',
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'deliveryCreditCardFees',
  [PaymentAdjustmentType.CASH_TIP]: 'deliveryCashTips',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'deliveryCashDiscounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'deliveryCustomFees',
});

const names = Map({
  [PaymentAdjustmentType.TIP]: 'Tips',
  [PaymentAdjustmentType.CREDIT_CARD_FEE]: 'Credit Card Fees',
  [PaymentAdjustmentType.CASH_TIP]: 'Cash Tips',
  [PaymentAdjustmentType.CASH_DISCOUNT]: 'Cash Discounts',
  [PaymentAdjustmentType.CUSTOM_FEE]: 'Custom Fees',
});

interface ClosingAdditionalChargeProps {
  mainType: PaymentAdjustmentType;
}

export const ClosingAdditionalCharge = ({ mainType }: ClosingAdditionalChargeProps) => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const currentPickupName = getServiceRosterClosingPropertyName(serviceIndex, pickupNames.get(mainType) ?? '');
  const currentDeliveryName = getServiceRosterClosingPropertyName(serviceIndex, deliveryNames.get(mainType) ?? '');
  const currentDeliveryValue = (order.getIn(currentDeliveryName.split('.')) as number) ?? 0;
  const currentValue = (order.getIn(currentPickupName.split('.')) as number) ?? 0;
  const [manual, setManual] = useState<string | number>(currentValue);
  const isLongDistance = order?.isLongDistance(serviceIndex);

  const valueToDisplay = isLongDistance ? currentDeliveryValue + +manual : manual;

  useEffect(() => {
    setManual(currentValue);
  }, [currentValue]);

  const handleOnBlur = (value: number, name: string) => () => {
    onChange({ name, value });
  };

  const onChangeTips = (grandTotal: string) => setManual(grandTotal);

  const toggleShowTips =
    (value = true) =>
    () => {
      setShowModal(value);
    };

  const nameToDisplay = names.get(mainType) ?? '';
  const formattedValueToDisplay =
    mainType === PaymentAdjustmentType.CASH_DISCOUNT
      ? asHumanReadableDiscount(+valueToDisplay || 0)
      : numberToCurrency(+valueToDisplay || 0);

  const cashDiscountPrefix = manual && mainType === PaymentAdjustmentType.CASH_DISCOUNT ? '-$' : '$';

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        {!isLongDistance ? (
          <BodyText>{nameToDisplay}</BodyText>
        ) : (
          <Link disabled={isSelectedAllServices} onClick={toggleShowTips()}>
            <BodyText>{nameToDisplay}</BodyText>
          </Link>
        )}
        <Box mr={-1}>
          {isLongDistance ? (
            <Box mr={1}>
              <BodyText data-testId={`${mainType}Value`}>{formattedValueToDisplay}</BodyText>
            </Box>
          ) : (
            <CurrencyInput
              disabled={isLongDistance || isSelectedAllServices}
              value={manual}
              onChange={onChangeTips}
              onBlur={handleOnBlur(+manual, currentPickupName)}
              name={currentPickupName}
              data-testId={'tipsValue'}
              prefix={cashDiscountPrefix}
            />
          )}
        </Box>
        {showModal && (
          <AdditionalCharge
            nameToDisplay={nameToDisplay}
            pickupName={currentPickupName}
            deliveryName={currentDeliveryName}
            onClose={toggleShowTips(false)}
          />
        )}
      </Box>
    </Grid>
  );
};
