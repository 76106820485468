import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, Grid, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';

import LeadScore from 'admin/components/LeadScore';
import { MultiDayIndicator } from 'admin/components/MultiDayIndicator';
import { statusById } from 'admin/constants/OrderStatus';
import OrderSummary from 'admin/entities/OrderSummary';
import { renderDate } from 'admin/utils/renderDate';
import Circle from 'common/components/Circle';
import Status from 'common/components/Status';

export const makeColumns = (
  rowsData: OrderSummary[],
  {
    theme,
    classes,
    canSeeCustomerOnline,
    leadScoreSettings,
  }: { theme: Theme; classes: ClassNameMap; canSeeCustomerOnline: boolean; leadScoreSettings: any },
) => {
  return [
    {
      name: 'status',
      label: 'STATUS',
      options: {
        customBodyRender: (_: any, { rowIndex }: { rowIndex: number }) => {
          const { status, orderNumber, customerActive } = rowsData[rowIndex];
          const statusInfo = statusById(status);

          return (
            <Grid
              container
              alignItems="center"
              classes={{ root: classes.activityContainer }}
              data-testValue={orderNumber}
            >
              {canSeeCustomerOnline && (
                <Circle
                  className="circle"
                  color={customerActive ? theme.palette.success.main : theme.palette.grey.A100}
                  width="12px"
                  height="12px"
                />
              )}
              <Status label={statusInfo.label!} title={status!} size="small" />
            </Grid>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    ...(leadScoreSettings.enabled
      ? [
          {
            name: 'leadScore',
            label: 'LS',
            options: {
              customBodyRender: (_: OrderSummary[], { rowIndex }: { rowIndex: number }) => {
                const getRow = rowsData[rowIndex];
                const { leadScore } = getRow;

                return <LeadScore score={leadScore || 0} leadScoreSettings={leadScoreSettings} />;
              },
              showOnMobileAsExpanded: true,
            },
          },
        ]
      : []),
    {
      name: 'id',
      label: 'ORDER #',
      options: {
        customBodyRender: (_: OrderSummary[], { rowIndex }: { rowIndex: number }) => {
          const { orderNumber } = rowsData[rowIndex];
          return <>{orderNumber}</>;
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'customer.firstName,customer.lastName',
      label: 'NAME',
      options: {
        customBodyRender: (_: OrderSummary[], { rowIndex }: { rowIndex: number }) => {
          const { customerName } = rowsData[rowIndex];
          return (
            <Box style={{ textTransform: 'capitalize' }}>
              <BodyText>{customerName}</BodyText>
            </Box>
          );
        },
        showOnMobileAsExpanded: false,
      },
    },
    {
      name: 'moveStartDate',
      label: 'MOVE DATE',
      options: {
        customBodyRender: (_: OrderSummary[], { rowIndex }: { rowIndex: number }) => {
          const { startDate } = rowsData[rowIndex];
          return renderDate(startDate!);
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'serviceRosters.serviceName',
      label: 'SERVICE TYPE',
      options: {
        customBodyRender: (_: OrderSummary[], { rowIndex }: { rowIndex: number }) => {
          const { serviceSummaries } = rowsData[rowIndex];
          return (
            <>
              {serviceSummaries.get(0)?.serviceName ?? ''}
              <MultiDayIndicator visible={serviceSummaries.size > 1} />
            </>
          );
        },
        showOnMobileAsExpanded: true,
      },
    },
  ];
};
