import React, { ChangeEvent, useEffect, useState } from 'react';

import { Order, Service, roundNumberToFixedDigits } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { getServicePropertyName, getServiceRosterClosingPropertyName } from 'admin/utils';

import { RemoveManualChangesButton } from './RemoveManualChangesButton';

const propertyName = 'manualLinehaulCharge';

export const LinehaulChargeLongDistanceInput = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const { isClosing } = useOrderClosingContext();
  const name = isClosing
    ? getServiceRosterClosingPropertyName(serviceIndex, propertyName)
    : getServicePropertyName(serviceIndex, propertyName);
  const orderDetail = isClosing ? order.closingOrderDetail : order;
  const manualValue = order.getIn(name.split('.')) as number | null;
  const services = orderDetail.services as List<Service>;
  const service = services.get(serviceIndex)!;
  const { quote } = service;
  const currentFlatRateLinehaulCharge = manualValue || roundNumberToFixedDigits(quote.baseQuoteWithoutTaxes.minValue);
  const [linehaulChargeManual, setLinehaulChargeManual] = useState<string | number>(currentFlatRateLinehaulCharge);

  useEffect(() => {
    setLinehaulChargeManual(currentFlatRateLinehaulCharge);
  }, [currentFlatRateLinehaulCharge]);

  const handleOnBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;
    if (+currentFlatRateLinehaulCharge !== +value) {
      onChange({ name, value });
    }
  };

  const onChangeLinehaulChargeTotal = (charge: string) => setLinehaulChargeManual(charge);

  const onCancel = () => {
    setLinehaulChargeManual(roundNumberToFixedDigits(quote.baseQuoteWithoutTaxes.minValue));
    onChange({ name, value: null });
  };

  return (
    <RemoveManualChangesButton manual={manualValue} onClick={onCancel}>
      <CurrencyInput
        value={linehaulChargeManual}
        onChange={onChangeLinehaulChargeTotal}
        onBlur={handleOnBlur(+linehaulChargeManual)}
        name={name}
      />
    </RemoveManualChangesButton>
  );
};
