import React, { FC } from 'react';

import { BodyText, InvoiceItemDto, PriceAdjustmentType, formatCurrency } from '@elromcoinc/react-shared';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';

import {
  AMOUNT,
  DESCRIPTION,
  DISCOUNT,
  ITEM_NAME,
  QUANTITY,
  TYPE,
  UNIT_COST,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    items: {
      borderRight: `1px solid ${palette.divider}`,
      borderTop: `1px solid ${palette.divider}`,
      padding: spacing(1, 1, 1, 2),
    },
  }),
);

interface PrintInvoiceRowProps {
  key: string;
  item: InvoiceItemDto;
}
const PrintInvoiceRow: FC<PrintInvoiceRowProps> = ({ key, item }) => {
  const classes = useStyles();
  const itemsName = item?.[ITEM_NAME];
  const description = item?.[DESCRIPTION];
  const unitCost = item?.[UNIT_COST];
  const quantity = item?.[QUANTITY];
  const discountAmountValue = item?.[DISCOUNT]?.[AMOUNT];
  const total = item?.total.manual != null ? item?.total.manual : item?.total.calculated;
  const discountType = item?.[DISCOUNT]?.[TYPE];

  return (
    <Grid container key={key}>
      <Grid item xs={3} classes={{ root: classes.items }}>
        <BodyText>{itemsName}</BodyText>
      </Grid>
      <Grid item xs={2} classes={{ root: classes.items }}>
        <BodyText>{description}</BodyText>
      </Grid>
      <Grid item xs={2} classes={{ root: classes.items }}>
        <BodyText>{`$ ${formatCurrency(+unitCost, 2)}`}</BodyText>
      </Grid>
      <Grid item xs={1} classes={{ root: classes.items }}>
        <BodyText>{quantity}</BodyText>
      </Grid>
      <Grid item xs={2} classes={{ root: classes.items }}>
        {discountType === PriceAdjustmentType.PERCENT ? (
          <BodyText>{`${discountAmountValue} %`}</BodyText>
        ) : (
          <BodyText>{`$ ${discountAmountValue}`}</BodyText>
        )}
      </Grid>
      <Grid item xs={2} classes={{ root: classes.items }}>
        <BodyText>{`$ ${formatCurrency(total, 2)}`}</BodyText>
      </Grid>
    </Grid>
  );
};

export { PrintInvoiceRow };
