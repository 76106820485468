/* eslint-disable quotes */
import React, { ReactElement } from 'react';

import UsersIcon from '@material-ui/icons/AccountCircle';
import OrderIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Build';
import CommunicationIcon from '@material-ui/icons/Call';
import PluginIcon from '@material-ui/icons/DesktopMac';
import StorageIcon from '@material-ui/icons/Domain';
import AccountIcon from '@material-ui/icons/FolderShared';
import RealtorIcon from '@material-ui/icons/Home';
import ServicesIcon from '@material-ui/icons/List';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DispatchIcon from '@material-ui/icons/LocalShipping';
import ReceiptIcon from '@material-ui/icons/MonetizationOn';
import LongDistanceIcon from '@material-ui/icons/PinDrop';
import BranchesIcon from '@material-ui/icons/Share';
import IntegrationIcon from '@material-ui/icons/Sync';
import JobPortalIcon from '@material-ui/icons/Work';
import styled from 'styled-components';

import asyncComponent from 'admin/components/AsyncComponent';
import SmsSettings from 'admin/components/Settings/components/SmsSettings';
import { lazyWithRetry } from 'admin/hooks';
import { ConfirmationSignatureType } from 'common-types';
// todo: change to React.lazy MB-2558
import { CalculatorIcon } from 'common/components/icons';

import GenericBasicSection from '../components/GeneralBasicSettings';

const TwilioBrand = lazyWithRetry(() => import('admin/components/Settings/components/TwilioBrand/TwilioBrand'));
const Branding = lazyWithRetry(() => import('admin/components/Settings/components/Branding'));

const Quickbooks = lazyWithRetry(() => import('admin/components/Settings/components/Quickbooks'));
const MB1Integration = lazyWithRetry(() => import('admin/components/Settings/components/MB1Integration'));
const GoogleCalendarIntegration = lazyWithRetry(
  () => import('admin/components/Settings/components/GoogleCalendarIntegration'),
);

const ConfirmationSignature = asyncComponent(
  () => import('admin/components/Settings/components/ConfirmationSignature'),
);
const RatesSettings = asyncComponent(() => import('admin/components/Settings/components/RatesSettings'));
const TruckSettings = asyncComponent(() => import('admin/components/Settings/components/TruckSettings/index'));
const CalendarSettings = asyncComponent(
  () => import('admin/components/Settings/components/CalendarSettings/CalendarSettings'),
);
const LeadScoring = asyncComponent(() => import('admin/components/Settings/components/LeadScoring'));
const AutoQuote = asyncComponent(() => import('admin/components/Settings/components/AutoQuote'));
const RatesOvertime = asyncComponent(() => import('admin/components/Settings/components/RatesOvertime'));
const InHomeSettings = asyncComponent(() => import('admin/components/Settings/components/InHomeSettings'));
const TaxesSettings = asyncComponent(() => import('admin/components/Settings/components/TaxesSettings'));
const UsersPage = asyncComponent(() => import('admin/components/Settings/components/Users'));

const CompanyServices = asyncComponent(() => import('admin/components/Settings/components/Services'));
const LocalSettings = asyncComponent(() => import('admin/components/Settings/components/Services/LocalSettings'));
const LoadingSettings = asyncComponent(() => import('admin/components/Settings/components/Services/LoadingSettings'));
const UnloadingSettings = asyncComponent(
  () => import('admin/components/Settings/components/Services/UnloadingSettings'),
);
const Packing = asyncComponent(() => import('admin/components/Settings/components/Services/PackingSettings'));
const OvernightSettings = asyncComponent(
  () => import('admin/components/Settings/components/Services/OvernightSettings'),
);
const MovingAndStorageSettings = asyncComponent(
  () => import('admin/components/Settings/components/Services/MovingAndStorageSettings'),
);
const JunkRemovalSettings = asyncComponent(
  () => import('admin/components/Settings/components/Services/JunkRemovalSettings'),
);

const FlatRateSettings = asyncComponent(() => import('admin/components/Settings/components/FlatRateSettings/index'));
const PackingSettings = asyncComponent(() => import('admin/components/Settings/components/PackingSettings'));
const MoverSpeedSettings = asyncComponent(
  () => import('admin/components/Settings/components/MoverSpeed/MoverSpeedSettings'),
);
const MoverCountSettings = asyncComponent(() => import('admin/components/Settings/components/MoverCountSettings'));
const LaborSpreadHours = asyncComponent(() => import('admin/components/Settings/components/LaborSpreadHours'));
const TruckCountSettings = asyncComponent(() => import('admin/components/Settings/components/TruckCountSettings'));
const MoveSizeSettings = asyncComponent(() => import('admin/components/Settings/components/MoveSizeSettings'));
const FuelSurcharge = asyncComponent(
  () => import('admin/components/Settings/components/FuelSurchargeSettings/FuelSurchargeSettings'),
);
const TravelTimeSettings = asyncComponent(() => import('admin/components/Settings/components/TravelTime'));
const PackingCalculator = asyncComponent(
  () => import('admin/components/Settings/components/PackingCalculator/PackingCalculator'),
);
const ValuationPlan = asyncComponent(() => import('admin/components/Settings/components/ValuationPlan/index'));
const TestCalculator = asyncComponent(() => import('admin/components/Settings/components/TestCalculator'));
const Inventory = asyncComponent(() => import('admin/components/Settings/components/Inventory/index'));
const FAQ = asyncComponent(() => import('admin/components/Settings/components/FAQ'));
const LeadSource = asyncComponent(() => import('admin/components/Settings/components/LeadSource'));
const PropertyType = asyncComponent(() => import('admin/components/Settings/components/PropertyType/PropertyType'));
const COI = asyncComponent(() => import('admin/components/Settings/components/COI'));
const OrderFlagsSettings = asyncComponent(() => import('admin/components/Settings/components/OrderFlagsSettings'));
const ImportOrders = asyncComponent(() => import('admin/components/Settings/components/ImportOrders'));
const ConvertToMB2FromSmartMoving = asyncComponent(
  () => import('admin/components/Settings/components/ImportOrders/components/ConvertToMB2FileFromSmartMoving'),
);
const ConvertToMB2FromSuperMove = asyncComponent(
  () => import('admin/components/Settings/components/ImportOrders/components/ConvertToMB2FromSuperMove'),
);
const ConvertToMB2FromHomeAdvisor = asyncComponent(
  () => import('admin/components/Settings/components/ImportOrders/components/ConvertToMB2FromHomeAdvisor'),
);
const InvoiceSettings = asyncComponent(() => import('admin/components/Settings/components/InvoiceSettings'));
const PaymentAndProcessingFeesSettings = asyncComponent(
  () => import('admin/components/Settings/components/PaymentAndProcessingFees'),
);
const BloomingdalesDiscount = asyncComponent(
  () => import('admin/components/Settings/components/BloomingdalesDiscount'),
);
const PaymentIntegration = asyncComponent(() => import('admin/components/Settings/components/PaymentIntegration'));
const PartialSettings = asyncComponent(() => import('admin/components/Settings/components/PartialSettings'));
const PayFrequencySettings = asyncComponent(() => import('admin/components/Settings/components/PayFrequencySettings'));
const StartPayrollSettings = asyncComponent(() => import('admin/components/Settings/components/StartPayrollSettings'));

const ReservationDeposit = asyncComponent(() => import('admin/components/Settings/components/ReservationDeposit'));
const ExpirationRules = asyncComponent(() => import('admin/components/Settings/components/ExpirationRules'));

const NewLeadInfo = asyncComponent(() => import('admin/components/Settings/components/NewLeadInfo'));
const CustomBlocks = asyncComponent(() => import('admin/components/Settings/components/CustomBlocks/index'));

const OrderNumbering = asyncComponent(() => import('admin/components/Settings/components/OrderNumbering'));
const FormSettings = asyncComponent(() => import('admin/components/Settings/components/FormSettings'));
const FormFontSize = asyncComponent(() => import('admin/components/Settings/components/FormFontSize'));
const TopForm = asyncComponent(() => import('admin/components/Settings/components/FormsIntegration'));
const FloatingMenu = asyncComponent(() => import('admin/components/Settings/components/FloatingMenu'));
const GoogleTracking = asyncComponent(() => import('admin/components/Settings/components/GoogleTracking'));
const LaborOnly = asyncComponent(() => import('admin/components/Settings/components/LaborOnly'));

const QuoteExplanation = asyncComponent(() => import('admin/components/Settings/components/QuoteExplanation'));
const StartTime = asyncComponent(() => import('admin/components/Settings/components/StartTime'));
const NewTemplateBuilder = asyncComponent(() => import('admin/components/Settings/components/NewTemplateBuilder'));
const AutomationRulesTimeline = asyncComponent(
  () => import('admin/components/Settings/components/AutomationRulesTimeline'),
);
const EmailSetup = asyncComponent(() => import('admin/components/Settings/components/EmailSetup'));

const LongDistanceTravelTime = asyncComponent(
  () => import('admin/components/Settings/components/LongDistanceTravelTime'),
);
const TravelTimeCalculation = asyncComponent(
  () => import('admin/components/Settings/components/TravelTimeCalculation'),
);
const Intrastate = asyncComponent(() => import('admin/components/Settings/components/Intrastate'));
const Interstate = asyncComponent(() => import('admin/components/Settings/components/Interstate'));
const LongDistanceStatus = asyncComponent(() => import('admin/components/Settings/components/LongDistanceStatus'));
const ExtraFees = asyncComponent(() => import('admin/components/Settings/components/ExtraFees'));
const LongDistanceMethod = asyncComponent(() => import('admin/components/Settings/components/LongDistanceMethod'));
const MileageTariff = asyncComponent(() => import('admin/components/Settings/components/MileageTariff'));
const AreaCodeTariff = asyncComponent(() => import('admin/components/Settings/components/AreaCodeTariff'));
const BolSettings = asyncComponent(() => import('admin/components/Settings/components/BolSettings'));
const DeclineSchedule = asyncComponent(() => import('admin/components/Settings/components/DeclineSchedule'));
const CanForemanViewPastJobs = asyncComponent(
  () => import('admin/components/Settings/components/CanForemanViewPastJobs'),
);
const CanForemanSkipSms = asyncComponent(() => import('admin/components/Settings/components/CanForemanSkipSms'));
const PaymentSettings = asyncComponent(() => import('admin/components/Settings/components/PaymentSettings'));
const ReleaseFormsSettings = asyncComponent(() => import('admin/components/Settings/components/ReleaseFormsSettings'));
const ContractSettings = asyncComponent(() => import('admin/components/Settings/components/ContractSettings'));
const BOLTemplates = asyncComponent(() => import('admin/components/Settings/components/BOLTemplates'));

const BoxDelivery = asyncComponent(() => import('admin/components/Settings/components/BoxDelivery'));
const DispatchTrucks = asyncComponent(() => import('admin/components/Settings/components/DispatchTrucks'));
const MovingInsuranceSettings = asyncComponent(
  () => import('admin/components/Settings/components/MovingInsuranceSettings'),
);

const CompanyPolicySettings = asyncComponent(
  () => import('admin/components/Settings/components/CompanyPolicySettings'),
);
const CancellationPolicySettings = asyncComponent(
  () => import('admin/components/Settings/components/CancellationPolicySettings'),
);

const TooltipSettings = asyncComponent(() => import('admin/components/Settings/components/TooltipSettings'));
const AdditionalServicesSettings = asyncComponent(
  () => import('admin/components/Settings/components/AdditionalServicesSettings'),
);
const AdditionalStopFeesSettings = asyncComponent(
  () => import('admin/components/Settings/components/AdditionalStopFees'),
);

const EquipmentFeesSettings = asyncComponent(() => import('admin/components/Settings/components/EquipmentFees'));

const NotInterestedReasonsSettings = asyncComponent(
  () => import('admin/components/Settings/components/NotInterestedReasonsSettings'),
);
const QuoteDisplaySettings = asyncComponent(() => import('admin/components/Settings/components/QuoteDisplay'));

const Example = styled.h1`
  text-align: center;
`;

export interface ChildrenSettings {
  label: string;
  name: string;
  component: ReactElement;
  urlPostFix: string;
  isElromcoOnly?: boolean;
  videos?: string[];
  children?: ChildrenSettings[];
}

interface SettingBlock {
  label: string;
  name: string;
  urlPostFix: string;
  icon: typeof SettingsIcon;
  isUseForMVP: boolean;
  children?: ChildrenSettings[];
}

const SettingStructure: SettingBlock[] = [
  {
    label: 'General',
    name: 'general',
    urlPostFix: '/general',
    icon: SettingsIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Company Info',
        name: 'info',
        component: <GenericBasicSection />,
        urlPostFix: '/info',
        videos: ['kGdlMbNmViQ'],
      },
      {
        label: 'Branding',
        name: 'branding',
        component: <Branding />,
        urlPostFix: '/branding',
        videos: ['Dq7hSL72iHE'],
      },
      {
        label: 'Rates',
        name: 'rates_settings',
        component: <RatesSettings />,
        urlPostFix: '/rates',
        videos: ['frOjxA7mItc'],
      },
      {
        label: 'Trucks',
        name: 'trucks',
        component: <TruckSettings />,
        urlPostFix: '/trucks',
        videos: ['IFYkQfojRQo'],
      },
      {
        label: 'Calendar',
        name: 'calendar_settings',
        component: <CalendarSettings />,
        urlPostFix: '/calendar',
        videos: ['QgCwoWS_z-Q'],
      },
      {
        label: 'Inventory',
        name: 'inventory',
        component: <Inventory />,
        urlPostFix: '/inventory',
        videos: ['jcK4kbwQzbw'],
      },
      {
        label: 'Lead Scoring',
        name: 'lead_scoring_settings',
        component: <LeadScoring />,
        urlPostFix: '/lead_scoring',
        videos: ['q6qCX8y1paM'],
      },
      {
        label: 'Auto Quote',
        name: 'auto_quote',
        component: <AutoQuote />,
        urlPostFix: '/auto_quote',
      },
      {
        label: 'Rates Overtime',
        name: 'rates_overtime',
        component: <RatesOvertime />,
        urlPostFix: '/rates_overtime',
      },
      {
        label: 'In-Home Settings',
        name: 'in_home_settings',
        component: <InHomeSettings />,
        urlPostFix: '/in_home_settings',
        videos: ['lfg-7KTBpx4'],
      },
      {
        label: 'Taxes Settings',
        name: 'taxes_settings',
        component: <TaxesSettings />,
        urlPostFix: '/taxes_settings',
        videos: ['FOxZrZfK23Q'],
      },
    ],
  },
  {
    label: 'Services',
    name: 'services',
    urlPostFix: '/services',
    icon: ServicesIcon,
    isUseForMVP: true,
    children: [
      {
        label: '- Company Services',
        name: 'company_services',
        urlPostFix: '/services',
        component: <CompanyServices />,
        videos: ['4nO_uxwIAow'],
        children: [
          {
            label: 'Local Settings',
            name: 'local',
            component: <LocalSettings />,
            urlPostFix: '/local',
            videos: ['S0vkpSZgQVQ'],
          },
          {
            label: 'Loading Settings',
            name: 'loading',
            component: <LoadingSettings />,
            urlPostFix: '/loading',
            videos: ['OorunLXJmLU'],
          },
          {
            label: 'Unloading Settings',
            name: 'Unloading',
            component: <UnloadingSettings />,
            urlPostFix: '/unloading',
            videos: ['dxXBQqowIDI'],
          },
          {
            label: 'Packing Settings',
            name: 'packing',
            component: <Packing />,
            urlPostFix: '/packing_setting',
            videos: ['2cO0e-X39YQ'],
          },
          {
            label: 'Overnight Settings',
            name: 'overnight',
            component: <OvernightSettings />,
            urlPostFix: '/overnight',
            videos: ['B7MA_MAanaM'],
          },
          {
            label: 'Moving & Storage Settings',
            name: 'moving_storage',
            component: <MovingAndStorageSettings />,
            urlPostFix: '/moving_storage',
            videos: ['Y5j0B2T13gI'],
          },
          {
            label: 'Junk Removal Settings',
            name: 'junk',
            component: <JunkRemovalSettings />,
            urlPostFix: '/junk',
          },
        ],
      },
    ],
  },
  {
    label: 'Long Distance',
    name: 'long_distance',
    urlPostFix: '/long_distance',
    icon: LongDistanceIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Intrastate',
        name: 'instrastate',
        component: <Intrastate />,
        urlPostFix: '/instrastate',
        videos: ['qlG0Wp_SfjU'],
      },
      {
        label: 'Interstate',
        name: 'interstate',
        component: <Interstate />,
        urlPostFix: '/interstate',
        videos: ['tKQ2KGM55d0'],
      },
      {
        label: 'Long Distance Status',
        name: 'long_distance_status',
        component: <LongDistanceStatus />,
        urlPostFix: '/long_distance_status',
      },
      {
        label: 'Extra Fees',
        name: 'extra_fees',
        component: <ExtraFees />,
        urlPostFix: '/extra_fees',
        videos: ['2k60wa_QLvo'],
      },
      {
        label: 'Long Distance Method',
        name: 'long_distance_method',
        component: <LongDistanceMethod />,
        urlPostFix: '/long_distance_method',
        videos: ['Fh4MNhXxEYQ'],
      },
      {
        label: 'Mileage Tariff',
        name: 'mileage_tariff',
        component: <MileageTariff />,
        urlPostFix: '/mileage_tariff',
        videos: ['I-VTVzB394s'],
      },
      {
        label: 'Regions Tariff',
        name: 'regions_tariff',
        component: <AreaCodeTariff />,
        urlPostFix: '/regions_tariff',
        videos: ['ghzdBW5ERe4'],
      },
      {
        label: 'Travel Time',
        name: 'travelTime',
        component: <LongDistanceTravelTime />,
        urlPostFix: '/travel_time',
        videos: ['6zXZaDChTWI'],
      },
      {
        label: 'Travel Time Calculation',
        name: 'travel_time_calculation',
        component: <TravelTimeCalculation />,
        urlPostFix: '/travel_time_calculation',
      },
    ],
  },
  {
    label: 'Calculator',
    name: 'calculator',
    urlPostFix: '/calculator',
    icon: CalculatorIcon,
    isUseForMVP: true,
    children: [
      {
        label: "Mover's Speed",
        name: 'm_speed',
        component: <MoverSpeedSettings />,
        urlPostFix: '/m_speed',
        videos: ['Ql34EsiR8vE'],
      },
      {
        label: 'Labor Spread Hours',
        name: 'labor_spread_hours',
        component: <LaborSpreadHours />,
        urlPostFix: '/labor_spread_hours',
        videos: ['OGaXB8ajflA'],
      },
      {
        label: "Mover's Count",
        name: 'm_count',
        component: <MoverCountSettings />,
        urlPostFix: '/m_count',
        videos: ['0dQnY_x7M-M'],
      },
      {
        label: 'Truck Count',
        name: 'truck',
        component: <TruckCountSettings />,
        urlPostFix: '/t_count',
        videos: ['_QK2ULQNF78'],
      },
      {
        label: 'Move Size',
        name: 'move_size',
        component: <MoveSizeSettings />,
        urlPostFix: '/m_size',
        videos: ['5wgi6DiimEs'],
      },
      {
        label: 'Hourly Travel Time',
        name: 'travel_time',
        component: <TravelTimeSettings />,
        urlPostFix: '/time',
      },
      {
        label: 'Hourly Flat Rate Calculation',
        name: 'flat',
        component: <FlatRateSettings />,
        urlPostFix: '/flat',
        videos: ['9Of7Pq_U9hw'],
      },
      {
        label: 'Labor Only Settings',
        name: 'laborOnly',
        component: <LaborOnly />,
        urlPostFix: '/laborOnly',
      },
      {
        label: 'Test Calculator',
        name: 'test_calculator',
        component: <TestCalculator />,
        urlPostFix: '/test_calculator',
        videos: ['Cu6mU82Rp50'],
      },
      {
        label: 'Quote Display',
        name: 'quote',
        component: <QuoteDisplaySettings />,
        urlPostFix: '/quote_display',
        videos: ['tMNtnxSRK7E'],
      },
      {
        label: 'Packing Calculator',
        name: 'packing_calculator',
        component: <PackingCalculator />,
        urlPostFix: '/packing_calculator',
        videos: ['bI_G2NUyAzE'],
      },
      {
        label: 'Quote Explanation',
        name: 'quote_explanation',
        component: <QuoteExplanation />,
        urlPostFix: '/quote_explanation',
        videos: ['EzrhcDH7kG8'],
      },
    ],
  },
  {
    label: 'Orders',
    name: 'orders_settings',
    urlPostFix: '/orders',
    icon: OrderIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Order Numbering',
        name: 'order_numbering',
        component: <OrderNumbering />,
        urlPostFix: '/order_numbering',
        videos: ['7UGTPhjEbHQ'],
      },
      {
        label: 'Reservation/Deposits',
        name: 'res_deposit',
        component: <ReservationDeposit />,
        urlPostFix: '/res_deposit',
        videos: ['9UCG31dvPZ4'],
      },
      {
        label: 'Fuel Surcharge',
        name: 'fuel_surcharge',
        component: <FuelSurcharge />,
        urlPostFix: '/fuel_surcharge',
        videos: ['2vKyTFSO3xc'],
      },
      {
        label: 'Packing',
        name: 'packing_settings',
        component: <PackingSettings />,
        urlPostFix: '/packing_materials',
        videos: ['zLzVPi5PSJQ'],
      },
      {
        label: 'Additional Services',
        name: 'additional_services',
        component: <AdditionalServicesSettings />,
        urlPostFix: '/additional_services',
        videos: ['SqFqaDE_Y_Y'],
      },
      {
        label: 'Additional Stop Fees',
        name: 'additional_stop_fees',
        component: <AdditionalStopFeesSettings />,
        urlPostFix: '/additional_stop_fees',
        videos: ['RPsuMbRzmnI'],
      },
      {
        label: 'Equipment Fees',
        name: 'equipment_fees',
        component: <EquipmentFeesSettings />,
        urlPostFix: '/equipment_fees',
        videos: ['OpRVPTf31gQ'],
      },
      {
        label: 'Not Interested Reasons',
        name: 'reasons',
        component: <NotInterestedReasonsSettings />,
        urlPostFix: '/not_interested_reasons',
        videos: ['GiC82poCEw4'],
      },
      {
        label: 'Valuation',
        name: 'valuation',
        component: <ValuationPlan />,
        urlPostFix: '/valuation',
        videos: ['38zWqAqkbSI'],
      },
      {
        label: 'Start Time Setting',
        name: 'start_time',
        component: <StartTime />,
        urlPostFix: '/start_time',
      },
      {
        label: 'Expiration Rules',
        name: 'expiration_rules',
        component: <ExpirationRules />,
        urlPostFix: '/expiration_rules',
        videos: ['Y-HeztQYqqo'],
      },
      {
        label: 'Lead Source',
        name: 'lead_source',
        component: <LeadSource />,
        urlPostFix: '/lead_source',
        videos: ['y7rxZb7yALw'],
      },
      {
        label: 'Property Type',
        name: 'property_type',
        component: <PropertyType />,
        urlPostFix: '/property_type',
      },
      {
        label: 'Order Flags',
        name: 'order_flags',
        component: <OrderFlagsSettings />,
        urlPostFix: '/order_flags',
        videos: ['kjaa7hjPNCo'],
      },
      {
        label: 'Import Orders',
        name: 'import',
        component: <ImportOrders />,
        urlPostFix: '/import',
      },
      {
        label: 'From Smart Moving Export',
        name: 'smart_move_import',
        component: <ConvertToMB2FromSmartMoving />,
        urlPostFix: '/convert_to_mb2_format_from_smart_move',
        isElromcoOnly: true,
      },
      {
        label: 'From Super Move Export',
        name: 'super_move_import',
        component: <ConvertToMB2FromSuperMove />,
        urlPostFix: '/convert_to_mb2_format_from_super_move',
        isElromcoOnly: true,
      },
      {
        label: 'From Home Advisor Export',
        name: 'home_advisor_import',
        component: <ConvertToMB2FromHomeAdvisor />,
        urlPostFix: '/convert_to_mb2_format_from_home_advisor',
        isElromcoOnly: true,
      },
    ],
  },
  {
    label: 'Customer Account',
    name: 'customer_account',
    urlPostFix: '/customer_account',
    icon: AccountIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Cancellation Policy',
        name: 'cancellation_policy',
        component: <CancellationPolicySettings />,
        urlPostFix: '/cancellation_policy',
        videos: ['G08PW__u2v0'],
      },
      {
        label: 'New Lead-Info',
        name: 'new_lead_info',
        component: <NewLeadInfo />,
        urlPostFix: '/pending_info',
      },
      {
        label: 'Progress Bar',
        name: 'progress_bar',
        component: <Example>Progress Bar Settings goes here</Example>,
        urlPostFix: '/progress_bar',
      },
      {
        label: 'Cancellation Settings',
        name: 'cancellation',
        component: <Example>Cancellation Settings goes here</Example>,
        urlPostFix: '/cancellation_settings',
      },
      {
        label: 'Company Policy',
        name: 'company_policy',
        component: <CompanyPolicySettings />,
        urlPostFix: '/company_policy',
        videos: ['i44SFHn4Qaw'],
      },
      {
        label: 'Estimates Signatures',
        name: 'estimates_signatures',
        component: <ConfirmationSignature />,
        urlPostFix: '/estimates_signatures',
        videos: ['T0IAzxjVcag'],
      },
      {
        label: 'Custom Blocks',
        name: 'custom_blocks',
        component: <CustomBlocks />,
        urlPostFix: '/custom_blocks',
        videos: ['IKOCNOWu6_E'],
      },
      {
        label: 'FAQ',
        name: 'faq',
        component: <FAQ />,
        urlPostFix: '/faq',
        videos: ['eYpL2EHcXrE'],
      },
      {
        label: 'Tooltips',
        name: 'tooltips',
        component: <TooltipSettings />,
        urlPostFix: '/tooltips',
        videos: ['5ymqAv54wZc'],
      },
      {
        label: 'Moving Insurance',
        name: 'moving_insurance',
        component: <MovingInsuranceSettings />,
        urlPostFix: '/moving_insurance',
        videos: ['RYOqCZoUDOk'],
      },
      {
        label: 'Order for Service',
        name: 'order_for_service',
        component: <ConfirmationSignature type={ConfirmationSignatureType.CONFIRMATION} />,
        urlPostFix: '/order_for_service',
        videos: ['fahzJvlh0LU'],
      },
      {
        label: 'COI/Window Restrictions',
        name: 'COI_TWR_restrictions',
        component: <COI />,
        urlPostFix: '/COI_TWR_restrictions',
        videos: ['C5pd65iCsN0'],
      },
    ],
  },
  {
    label: 'Bill of Lading',
    name: 'bol',
    urlPostFix: '/bol',
    icon: OrderIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Decline Schedule',
        name: 'decline_schedule',
        component: <DeclineSchedule />,
        urlPostFix: '/decline_schedule',
      },
      {
        label: 'Contract Settings',
        name: 'contract_settings',
        component: <ContractSettings />,
        urlPostFix: '/contract_settings',
      },
      {
        label: 'Foreman can view past jobs',
        name: 'foreman_can_view_past_jobs',
        component: <CanForemanViewPastJobs />,
        urlPostFix: '/foreman_can_view_past_jobs',
      },
      {
        label: 'BOL Settings',
        name: 'bol_settings',
        component: <BolSettings />,
        urlPostFix: '/settings',
      },
      {
        label: 'Foreman can skip customer ETA SMS',
        name: 'foreman_can_skip_customer_sms',
        component: <CanForemanSkipSms />,
        urlPostFix: '/foreman_can_skip_customer_sms',
      },
      {
        label: 'Payment Settings',
        name: 'payment_settings_bol',
        component: <PaymentSettings />,
        urlPostFix: '/payment_settings_bol',
      },
      {
        label: 'Release Form',
        name: 'release_form',
        component: <ReleaseFormsSettings />,
        urlPostFix: '/release_form',
      },
      {
        label: 'Storage Agreement',
        name: 'storage_agreement',
        component: <Example>Storage Agreement Settings goes here</Example>,
        urlPostFix: '/storage_agreement',
        isElromcoOnly: true,
      },
      {
        label: 'Edit Contract',
        name: 'bol_templates',
        component: <BOLTemplates />,
        urlPostFix: '/bol_templates',
      },
    ],
  },
  {
    label: 'Communications',
    name: 'communications',
    urlPostFix: '/communications',
    icon: CommunicationIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Email Setup',
        name: 'emailSetup',
        component: <EmailSetup />,
        urlPostFix: '/email_setup',
        videos: ['btEU-oWfSpY'],
      },
      {
        label: 'Marketing Tools',
        name: 'marketing',
        component: <Example>Marketing Tools goes here</Example>,
        urlPostFix: '/marketing',
      },
      {
        label: 'Automation Timeline',
        name: 'automation_rules_timeline',
        component: <AutomationRulesTimeline />,
        urlPostFix: '/automation_rules_timeline',
      },
      {
        label: 'Template Builder',
        name: 'template_builder',
        component: <NewTemplateBuilder />,
        urlPostFix: '/template_builder',
        videos: ['TV4nVTWlxD8'],
      },
      {
        label: 'SMS Settings',
        name: 'sms_settings',
        component: <SmsSettings />,
        urlPostFix: '/sms_settings',
        videos: ['2ukhleXWVSw'],
      },
      {
        label: 'SMS Company Registration',
        name: 'sms_company_registration',
        component: <TwilioBrand />,
        urlPostFix: '/sms_company_registration',
        isElromcoOnly: true,
      },
    ],
  },
  {
    label: 'Finance',
    name: 'finance',
    urlPostFix: '/finance',
    icon: ReceiptIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Payment Integration',
        name: 'authorize',
        component: <PaymentIntegration />,
        urlPostFix: '/authorize',
        videos: ['6Q1VgyzuzcQ'],
      },
      {
        label: 'Partial Payments',
        name: 'partial_settings',
        component: <PartialSettings />,
        urlPostFix: '/partial',
        isElromcoOnly: true,
      },
      {
        label: 'Pay Frequency',
        name: 'pay_frequency',
        component: <PayFrequencySettings />,
        urlPostFix: '/pay_frequency',
        isElromcoOnly: true,
      },
      {
        label: 'Start Payroll On ',
        name: 'start_payroll_on',
        component: <StartPayrollSettings />,
        urlPostFix: '/start_payroll_on',
        isElromcoOnly: true,
      },
      {
        label: 'Payment Settings',
        name: 'payment_settings',
        component: <Example>Payment Settings goes here</Example>,
        urlPostFix: '/payment',
      },
      {
        label: 'Invoice Settings',
        name: 'invoice_settings',
        component: <InvoiceSettings />,
        urlPostFix: '/invoice',
      },
      {
        label: 'Payment & Processing Fees',
        name: 'payment_and_processing_fees_settings',
        component: <PaymentAndProcessingFeesSettings />,
        urlPostFix: '/payment_and_processing_fees_settings',
      },
    ],
  },
  {
    label: 'Marketing Tools',
    name: 'marketing_tools',
    urlPostFix: '/marketing_tools',
    icon: LocalOfferIcon,
    isUseForMVP: true,
    children: [
      {
        label: "Bloomingdale's discount",
        name: "bloomingdale's_discount",
        component: <BloomingdalesDiscount />,
        urlPostFix: '/bloomingdales_discount',
      },
    ],
  },
  {
    label: 'Integration',
    name: 'integration',
    urlPostFix: '/integration',
    icon: IntegrationIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Moveboard 1',
        name: 'mb1_integration',
        component: <MB1Integration />,
        urlPostFix: '/mb1-integration',
      },
      {
        label: 'Quickbooks',
        name: 'quickbooks',
        component: <Quickbooks />,
        urlPostFix: '/quickbooks',
      },
      {
        label: 'Zapier',
        name: 'zapier',
        component: <Example>Zapiert Settings goes here</Example>,
        urlPostFix: '/zapier',
      },
      {
        label: 'Parsing',
        name: 'parsing',
        component: <Example>Parsing Settings goes here</Example>,
        urlPostFix: '/parsing',
      },
      {
        label: 'Updater',
        name: 'updater',
        component: <Example>Updater Settings goes here</Example>,
        urlPostFix: '/updater',
      },
      {
        label: 'Phone',
        name: 'phone',
        component: <Example>Phone Settings goes here</Example>,
        urlPostFix: '/phone',
      },
      {
        label: 'Five-Nine',
        name: 'five_nine',
        component: <Example>Five-Nine Settings goes here</Example>,
        urlPostFix: '/five_nine',
      },
      {
        label: 'Speedy Inventory',
        name: 'speedy_inventory',
        component: <Example>Speedy Inventory Settings goes here</Example>,
        urlPostFix: '/speedy_inventory',
      },
      {
        label: 'Google Calendar',
        name: 'google_calendar',
        component: <GoogleCalendarIntegration />,
        urlPostFix: '/google_calendar',
        isElromcoOnly: true,
      },
    ],
  },
  {
    label: 'Users',
    name: 'users',
    urlPostFix: '/users',
    icon: UsersIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Users',
        name: 'index',
        component: <UsersPage />,
        urlPostFix: '/index',
        videos: ['jk4LnBnzisY'],
      },
      {
        label: 'Polices/Documentation',
        name: 'polices',
        component: <Example>Polices/Documentation Settings goes here</Example>,
        urlPostFix: '/polices',
      },
      {
        label: 'Mover Portal Settings',
        name: 'mover_portal',
        component: <Example>Mover Portal Settings goes here</Example>,
        urlPostFix: '/mover_portal',
      },
    ],
  },
  {
    label: 'Website Plugin',
    name: 'plugins',
    urlPostFix: '/plugins',
    icon: PluginIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Forms Integration',
        name: 'integration',
        component: <TopForm />,
        urlPostFix: '/integration',
      },
      {
        label: 'Long Form',
        name: 'long_form',
        component: <Example>Long Form Settings goes here</Example>,
        urlPostFix: '/long_form',
      },
      {
        label: 'Front Page Promo Block',
        name: 'promo',
        component: <Example>Front Page Promo Block Settings goes here</Example>,
        urlPostFix: '/promo',
      },
      {
        label: 'Bloomingdales Sales Promo',
        name: 'sales',
        component: <Example>Bloomingdales Sales Promo Settings goes here</Example>,
        urlPostFix: '/sales',
      },
      {
        label: 'Reviews',
        name: 'reviews',
        component: <Example>Reviews Settings goes here</Example>,
        urlPostFix: '/reviews',
      },
      {
        label: 'Form Settings',
        name: 'form_setting',
        component: <FormSettings />,
        urlPostFix: '/form_setting',
      },
      {
        label: 'Form Font Size',
        name: 'form_font_size',
        component: <FormFontSize />,
        urlPostFix: '/form_font_size',
        isElromcoOnly: true,
      },
      {
        label: 'Floating Menu',
        name: 'floating_menu',
        component: <FloatingMenu />,
        urlPostFix: '/floating_menu',
      },
      {
        label: 'Tracking',
        name: 'tracking',
        component: <GoogleTracking />,
        urlPostFix: '/tracking',
      },
    ],
  },
  {
    label: 'Storage',
    name: 'storage',
    urlPostFix: '/storage',
    icon: StorageIcon,
    isUseForMVP: false,
    children: [
      {
        label: 'Facility',
        name: 'facility',
        component: <Example>Facility Settings goes here</Example>,
        urlPostFix: '/facility',
      },
    ],
  },
  {
    label: 'Dispatch',
    name: 'dispatchSettings',
    urlPostFix: '/dispatch',
    icon: DispatchIcon,
    isUseForMVP: true,
    children: [
      {
        label: 'Dispatch trucks',
        name: 'dispatch_trucks',
        component: <DispatchTrucks />,
        urlPostFix: '/dispatch_trucks',
      },
      {
        label: 'Settings',
        name: 'dispatch_settings',
        component: <Example>Settings goes here</Example>,
        urlPostFix: '/settings',
        isElromcoOnly: true,
      },
      {
        label: 'GPS Settings',
        name: 'gps',
        component: <Example>GPS Settings goes here</Example>,
        urlPostFix: '/gps',
        isElromcoOnly: true,
      },
      {
        label: 'Box Deliveries',
        name: 'box',
        component: <BoxDelivery />,
        urlPostFix: '/box',
        isElromcoOnly: true,
      },
    ],
  },
  {
    label: 'Realtors',
    name: 'realtors',
    urlPostFix: '/realtors',
    icon: RealtorIcon,
    isUseForMVP: false,
    children: [
      {
        label: 'Settings',
        name: 'realtor_settings',
        component: <Example>Settings goes here</Example>,
        urlPostFix: '/settings',
      },
    ],
  },
  {
    label: 'Job Portal',
    name: 'job',
    urlPostFix: '/job',
    icon: JobPortalIcon,
    isUseForMVP: false,
    children: [
      {
        label: 'Settings',
        name: 'job_portal_settings',
        component: <Example>Settings goes here</Example>,
        urlPostFix: '/settings',
      },
    ],
  },
  {
    label: 'Branches',
    name: 'branches',
    urlPostFix: '/branches',
    icon: BranchesIcon,
    isUseForMVP: false,
    children: [
      {
        label: 'Settings',
        name: 'branch_settings',
        component: <Example>Settings goes here</Example>,
        urlPostFix: '/settings',
      },
    ],
  },
];

export default SettingStructure;
