import React, { FC, memo, useMemo } from 'react';

import { HeaderSmallText, JoditTextEditor } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { getTemplateBuilderVariables } from 'common/utils';

interface TemplateEditorProps {
  name: string;
  label?: string;
  useVariables?: boolean;
  control?: ReturnType<typeof useForm>['control'];
}

export const TemplateEditor: FC<TemplateEditorProps> = memo(({ label, name, useVariables = true, control }) => {
  const editorConfig = useMemo(() => ({ variables: useVariables ? getTemplateBuilderVariables() : undefined }), []);

  return (
    <Box display="flex" flexDirection="column" p={2}>
      <Box display="flex" justifyContent="space-between" py={2}>
        {!!label && (
          <Box my={1}>
            <HeaderSmallText>
              <b>{label}</b>
            </HeaderSmallText>
          </Box>
        )}
      </Box>
      <JoditTextEditor name={name} control={control} config={editorConfig} />
    </Box>
  );
});
