import React, { FC, useState } from 'react';

import {
  BodyText,
  CurrencyInput,
  DatePicker,
  Modal,
  OverridableValue,
  numberToCurrency,
  toDate,
} from '@elromcoinc/react-shared';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { format, isValid } from 'date-fns';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles({
  bloomingDaleDiscount: {
    textDecorationLine: 'line-through',
  },
});

interface RateChangeProps {
  title: string;
  name: string;
  onSave: (changes: [string, OverridableValue][]) => void;
  onCancel: () => void;
  rate: OverridableValue;
  open: boolean;
  discountRate: number;
}

const RateChange: FC<RateChangeProps> = ({
  title,
  onSave,
  onCancel,
  open,
  rate,
  name,
  discountRate: bloomingdaleDiscountRate,
}) => {
  const { isClosing } = useOrderClosingContext();
  const classes = useStyles();
  const [manual, setManual] = useState(rate.manual || null);
  const [expiration, setExpiration] = useState<Date | string | null>(rate.expirationDate || null);

  const handleChange = (floatValue: string) => {
    setManual(floatValue !== '' ? +floatValue : null);
  };

  const hasChanges = rate.manual !== manual || rate.expirationDate !== expiration;

  const handleExpirationChange = (value: Date | null) => {
    setExpiration(value && isValid(value) ? format(value, 'yyyy-MM-dd') : value);
  };

  const removeDiscount = () => {
    setManual(null);
    setExpiration(null);
  };

  const handleOnSave = () => {
    const changes = [[name, rate.setManual(manual || null).setExpiration(expiration as string | null)]];
    onSave(changes as [string, OverridableValue][]);
  };

  const discountRate =
    manual && rate.calculated && rate.calculated !== 0
      ? parseInt((((manual - rate.calculated) / rate.calculated) * 100).toString(10), 10)
      : null;

  return (
    <Modal
      open={open}
      title={title}
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: handleOnSave, disabled: !hasChanges, color: hasChanges ? 'primary' : 'default' },
      ]}
      maxWidth="xs"
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <Box className="mr-q">
                {!!bloomingdaleDiscountRate && !isClosing && (
                  <BodyText color="textSecondary" className={classes.bloomingDaleDiscount}>
                    {`${numberToCurrency(bloomingdaleDiscountRate)} / hour`}
                  </BodyText>
                )}
              </Box>
              <BodyText>{`Current rate: ${numberToCurrency(rate.calculated ?? 0)} / hour`}</BodyText>
            </Box>
          </Grid>
          <Grid item xs={8} sm={4}>
            <CurrencyInput
              fullWidth
              autoFocus
              onChange={handleChange}
              label="New Rate"
              name="laborRate"
              value={manual || ''}
            />
          </Grid>
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" height="100%">
              {!!manual && (
                <Typography>{`${Math.abs(discountRate!)}% ${discountRate! < 0 ? 'Discount' : 'Increase'}`}</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={10}>
            <DatePicker label="Rate Expires" value={toDate(expiration)} onChange={handleExpirationChange} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2" color="primary" onClick={removeDiscount} style={{ cursor: 'pointer' }}>
              Remove Discount
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RateChange;
