import { Record } from 'immutable';

import { AccountType } from 'admin/constants/AccountType';
import { AccountDto } from 'common-types/account/AccountDto';

const defaultValues: AccountDto = {
  additionalContactEmail: '',
  additionalContactFirstName: '',
  additionalContactLastName: '',
  additionalContactPhoneNumber: '',
  additionalContactReceiveEmails: false,
  additionalContactReceiveSms: false,
  additionalInfo: null,
  contactBestTime: '',
  contactBestWay: '',
  conversationMessages: [],
  created: '',
  createdBy: '',
  hourlyRateDiscount: null,
  manager: null,
  name: '',
  ownerCustomerInfo: null,
  source: '',
  totalDiscount: 0,
  totalEmployeeUnreadMessages: 0,
  totalOrders: 0,
  totalUnreadMessages: 0,
  type: AccountType.PRIVATE,
  updated: '',
  updatedBy: '',
  id: -1,
  companyId: 1,
  flags: [],
};

class AccountEntity extends Record<AccountDto>(defaultValues) {
  public constructor(values?: Partial<AccountDto>) {
    super(values);
  }
}

export { AccountEntity };
