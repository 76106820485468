import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import {
  fetchLatestNotification,
  getNotificationIsFetchingNewNotificationCount,
  getNotificationIsMuted,
  getNotificationMaxStartDateTime,
  getNotificationUnread,
  setIsNotNewNotifications,
} from 'admin/autodux/NotificationAutodux';
import NotifyNewNotification from 'admin/components/Notifications/NotifyNewNotification';
import usePrevious from 'admin/hooks/usePrevious';

const isNewNotification = ({ isNew }) => isNew;

const FIVE_SECONDS_TIMEOUT = 5 * 1000;

export const NotificationSnackbar = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((s) => s.auth.user) || {};
  const maxStartDateTime = useSelector(getNotificationMaxStartDateTime);
  const newNotifications = useSelector(getNotificationUnread).filter(isNewNotification);
  const isFetchingNewNotificationCount = useSelector(getNotificationIsFetchingNewNotificationCount);
  const isMuted = useSelector(getNotificationIsMuted);
  const previousNotifications = usePrevious(newNotifications);
  const isShowNotification =
    (previousNotifications && newNotifications && !previousNotifications.length && newNotifications.length) || null;
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  useEffect(() => {
    if (!isLogout && !isSessionExpired) {
      if (currentUser?.id && !isFetchingNewNotificationCount && !isMuted) {
        setTimeout(() => {
          dispatch(fetchLatestNotification(maxStartDateTime));
        }, 1000);
      }
    }
  }, [currentUser?.id, isFetchingNewNotificationCount, isMuted, maxStartDateTime, isSessionExpired, isLogout]);

  useEffect(() => {
    if (!isLogout && !isSessionExpired) {
      if (newNotifications.length && !isSessionExpired) {
        const handler = setTimeout(() => dispatch(setIsNotNewNotifications(newNotifications)), FIVE_SECONDS_TIMEOUT);
        return () => clearTimeout(handler);
      }
    }
  }, [newNotifications, isSessionExpired, isLogout]);

  return (
    <>
      {isShowNotification &&
        newNotifications.map((notification) => (
          <NotifyNewNotification key={notification.id} notification={notification} />
        ))}
    </>
  );
};
