import React, { useMemo, useState } from 'react';

import { BodySmallText, BodyText, Button, TitleBigText, formatCurrency } from '@elromcoinc/react-shared';
import { Box, Divider, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

import { OrdersListModal } from 'admin/components/Calendar/orders/OrdersListModal';

const useStyles = (isPriceAmount, isClickableDashboard) =>
  makeStyles(({ breakpoints }) => ({
    textUppercase: {
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    barElement: {
      fontWeight: 300,
      maxWidth: '130px',
    },
    nonPadding: {
      padding: '0px',
    },
    titleFontSize: {
      fontSize: isPriceAmount ? '30px' : '48px',
      padding: '0px',
    },
    titleButton: {
      height: '48px',
      marginLeft: '-12px',
    },
    statisticCardWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '0px 10px',
    },
    amount: {
      display: 'flex',
      height: '78px',
      [breakpoints.down('sm')]: {
        flexWrap: 'wrap',
      },
    },
    estimateTotal: {
      fontWeight: 300,
      fontSize: '14px',
      textAlign: 'center',
    },
    estimateTotalRow: {
      height: '40px',
      [breakpoints.down('sm')]: {
        marginTop: '32px',
      },
    },
    barWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    priceAmount: {
      fontSize: isPriceAmount ? '15px' : '30px',
      fontWeight: 300,
      marginTop: '15px',
    },
    titlePriceButton: {
      [breakpoints.down('sm')]: {
        marginTop: !isClickableDashboard ? '10px' : '0px',
      },
    },
    nonClickableAmount: {
      display: 'flex',
      [breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    divider: {
      margin: '5px 0px',
      [breakpoints.down('sm')]: {
        marginTop: '20px',
      },
    },
    actualAmount: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    emptyActualAmount: {
      height: '51px',
      [breakpoints.down('md')]: {
        height: '50px',
      },
      [breakpoints.down('sm')]: {
        height: '67px',
      },
      [breakpoints.down('xs')]: {
        marginTop: '15px',
      },
    },
  }));

const axes = [
  {
    gridLines: {
      display: false,
    },
    ticks: {
      display: false,
      beginAtZero: true,
      suggestedMin: 20,
      suggestedMax: 20,
    },
  },
];

export default function DashboardStatisticCard({
  color = '#cd324a',
  data = [0, 0, 0, 0, 0, 0, 0],
  estimatedTotal,
  title = 'Leads today',
  amount = 0,
  pastAmount,
  isPriceAmount = false,
  actualTotal,
  isGeneralReports,
  labels,
  uniquePastWeekFilter,
  uniqueFilter,
}) {
  const [modalProps, setModalProps] = useState(null);
  const isClickableDashboard = !!uniquePastWeekFilter && !!uniqueFilter;
  const classes = useStyles(isPriceAmount, isClickableDashboard)();

  const chartOptions = useMemo(
    () => ({
      legend: {
        display: false,
      },
      scales: {
        xAxes: axes,
        yAxes: axes,
      },
      tooltips: {
        enabled: false,
        custom(tooltipModel) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map((item) => item.lines);
            let innerHtml = '<thead>';
            titleLines.forEach((title) => {
              innerHtml += `<tr><th>${title}</th></tr>`;
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach((body, i) => {
              const colors = tooltipModel.labelColors[i];
              let style = `background:${colors.backgroundColor}`;
              style += `; border-color:${colors.borderColor}`;
              style += '; border-width: 2px';
              style += '; width: 10px';
              style += '; height: 10px';
              style += '; margin-right: 10px';
              style += '; display: inline-block';
              innerHtml += `<tr><td><span style="${style}"> </span> ${
                isPriceAmount ? `$${formatCurrency(+body, 2)}` : body
              }</td></tr>`;
            });
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          // eslint-disable-next-line no-underscore-dangle
          const position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style['z-index'] = 100;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
          tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
          // eslint-disable-next-line no-underscore-dangle
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
          // eslint-disable-next-line no-underscore-dangle
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },
      },
    }),
    [],
  );

  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: color,
        borderColor: color,
        barThickness: 12,
        minBarLength: 2,
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        data,
      },
    ],
  };

  const handleCloseOrderListModal = () => {
    setModalProps(null);
  };

  const handleSetModalProps = (filters) => () => {
    setModalProps(filters);
  };

  return (
    <>
      <Box className={classes.statisticCardWrapper}>
        <Box className={classes.amount}>
          {isClickableDashboard ? (
            <Button
              variant="text"
              disableRipple
              className={classNames(classes.nonPadding, classes.titleButton)}
              onClick={handleSetModalProps(uniqueFilter)}
            >
              <TitleBigText className={classes.titleFontSize}>
                {isPriceAmount ? `$${formatCurrency(amount, 0)}` : amount}
              </TitleBigText>
            </Button>
          ) : (
            <Box className={classes.nonClickableAmount}>
              <TitleBigText className={classes.titleFontSize}>
                {isPriceAmount ? `$${formatCurrency(amount, 0)}` : amount}
              </TitleBigText>
            </Box>
          )}
          {!!pastAmount && (
            <Box className={classes.priceAmount}>
              {`/${isPriceAmount ? `$${formatCurrency(pastAmount, 0)}` : pastAmount}`}
            </Box>
          )}
          <Box className={classes.titlePriceButton}>
            {isClickableDashboard ? (
              <Button
                className={classes.nonPadding}
                onClick={handleSetModalProps(uniqueFilter)}
                variant="text"
                size="large"
                alignItems="center"
                textTransform="uppercase"
                color="primary"
              >
                <BodyText>{title}</BodyText>
              </Button>
            ) : (
              <BodyText className={classes.textUppercase}>{title}</BodyText>
            )}
          </Box>
        </Box>
        {estimatedTotal.min || estimatedTotal.max ? (
          <Box className={classNames(classes.estimateTotal, classes.estimateTotalRow)}>
            {`(est. total $${formatCurrency(estimatedTotal.min, 0)} - $${formatCurrency(estimatedTotal.max, 0)})`}
          </Box>
        ) : (
          <Box className={classes.estimateTotalRow} />
        )}
        {isGeneralReports &&
          false &&
          (actualTotal ? (
            <>
              <Box className={classes.divider}>
                <Divider />
              </Box>
              <Box className={classes.actualAmount}>
                <TitleBigText className={classes.titleFontSize}>{`$${formatCurrency(actualTotal, 0)}`}</TitleBigText>
                <BodyText className={classes.textUppercase}>Actual</BodyText>
              </Box>
            </>
          ) : (
            <Box className={classes.emptyActualAmount} />
          ))}
        <Box className={classes.barWrapper}>
          {isClickableDashboard ? (
            <Button onClick={handleSetModalProps(uniquePastWeekFilter)} className={classes.nonPadding} variant="text">
              <Box className={classes.estimateTotalRow}>
                <Bar data={chartData} width={130} height={50} options={chartOptions} />
              </Box>
            </Button>
          ) : (
            <Box className={classes.estimateTotalRow}>
              <Bar data={chartData} width={130} height={50} options={chartOptions} />
            </Box>
          )}
        </Box>
        <BodySmallText className={classes.textUppercase}>Past week</BodySmallText>
      </Box>
      {!!modalProps && <OrdersListModal {...modalProps} onClose={handleCloseOrderListModal} />}
    </>
  );
}

DashboardStatisticCard.propTypes = {
  color: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  labels: PropTypes.array.isRequired,
  estimatedTotal: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }),
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  actualTotal: PropTypes.number.isRequired,
  pastAmount: PropTypes.number,
  isPriceAmount: PropTypes.bool,
  isGeneralReports: PropTypes.bool,
  uniqueFilter: PropTypes.object,
  uniquePastWeekFilter: PropTypes.object,
};

DashboardStatisticCard.defaultProps = {
  estimatedTotal: { min: 0, max: 0 },
  pastAmount: 0,
  actualTotal: 0,
  isPriceAmount: false,
  isGeneralReports: false,
};
