import { FC, useState } from 'react';

import { UnsubscribeMessageContext } from './UnsubscribeMessageContext';

const UnsubscribeMessageProvider: FC = ({ children }) => {
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);

  return (
    <UnsubscribeMessageContext.Provider value={{ isUnsubscribe, setIsUnsubscribe }}>
      {children}
    </UnsubscribeMessageContext.Provider>
  );
};

export { UnsubscribeMessageProvider };
