import autodux from 'autodux';
import AccountAPI from 'admin/api/AccountAPI';
import { Map, set } from 'immutable';

const {
  reducer,
  actions: { setAccountInformation, setAccountInformationDefault },
  selectors: { getAccountInformation }
} = autodux({
  slice: 'accountInformation',
  initial: {
    accountInformation: Map()
  },
  actions: {
    setAccountInformation: (state, payload) => {
      const result = payload.reduce((res, item) => res.set(item.orderId, item), state.accountInformation);
      return set(state, 'accountInformation', result);
    },
    setAccountInformationDefault: (state, payload) =>
      set(state, 'accountInformation', state.accountInformation.set(payload, null))
  }
});

const fetchAccountInformation = orderId => dispatch => {
  dispatch(setAccountInformationDefault(orderId));

  return AccountAPI.getAllAccounts({
    pageSize: 100,
    'orders.id': orderId
  })
    .then(({ pageElements }) => {
      dispatch(setAccountInformation(pageElements.map(state => ({ ...state, orderId }))));
    })
    .catch(() => {});
};

export { setAccountInformation, getAccountInformation, fetchAccountInformation };

export default reducer;
