import React, { useState } from 'react';

import { TrackerTimeLine } from 'admin/components/CrewStatusLog/types';

import { HorizontalTimeLine } from './HorizontalTimeLine';

interface HorizontalTimeLinesProps {
  timeLines: TrackerTimeLine[];
  startDayOffsetInMinutes: number;
  widthCoefficient: number;
}

export const HorizontalTimeLines = ({
  timeLines,
  startDayOffsetInMinutes,
  widthCoefficient,
}: HorizontalTimeLinesProps) => {
  const [hovered, setHovered] = useState<number>(-1);
  const toggleHover =
    (value = -1) =>
    () => {
      setHovered(value);
    };

  return (
    <>
      {timeLines.map((timeLine, index) => (
        <HorizontalTimeLine
          startDayOffsetInMinutes={startDayOffsetInMinutes}
          widthCoefficient={widthCoefficient}
          time={timeLine}
          key={timeLine.startTime + timeLine.endTime + index}
          isHovered={hovered !== -1}
          hovered={hovered === index}
          onMouseEnter={toggleHover(index)}
          onMouseLeave={toggleHover()}
        />
      ))}
    </>
  );
};
