import { createSlice } from '@reduxjs/toolkit';
import { getOrderWindowSettings } from 'redux-conf/orderWindowSettings/orderWindowSettings-actions';

import { initialState } from './orderWindowSettings-state';

export const { reducer, actions } = createSlice({
  name: 'orderWindowSettings',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderWindowSettings.pending, (state) => {
        state.inFlight = true;
        state.error = null;
      })
      .addCase(getOrderWindowSettings.fulfilled, (state, action) => {
        state.inFlight = false;
        state.settings = action.payload;
      })
      .addCase(getOrderWindowSettings.rejected, (state, action) => {
        state.inFlight = false;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action?.error?.message;
        }
      });
  },
});

export default reducer;
