import React, { ChangeEvent, useEffect, useState } from 'react';

import { TextInput, emailYUP } from '@elromcoinc/react-shared';
import { TargetElement } from '@testing-library/user-event';
import clsx from 'clsx';

import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { trimValue } from 'admin/utils/trimValue';

const INPUT_NAME = 'contactInfo.email';

const schema = emailYUP({ name: INPUT_NAME });

interface EmailWithValidationProps {
  value: string;
  onChange: (value: TargetElement) => void;
}

function EmailWithValidation({ value, onChange }: EmailWithValidationProps) {
  const { isClosing } = useOrderClosingContext();
  const [email, setEmail] = useState(value);
  const [error, setError] = useState(null);

  useEffect(() => {
    setEmail(value);
  }, [value]);

  const handleChange = ({ target: { value: newEmail } }: ChangeEvent<HTMLInputElement>) => {
    setEmail(newEmail);
    schema
      .validate(newEmail)
      .then(() => setError(null))
      .catch((ex) => setError(ex.message));
  };

  const handleOnBlur = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (!error) {
      onChange(target);
    }
  };

  return (
    <TextInput
      fullWidth
      hiddenLabel
      InputProps={{
        disableUnderline: true,
      }}
      value={email}
      className={clsx({ error: Boolean(error) }, 'fix-input-margin')}
      name={INPUT_NAME}
      placeholder="Email"
      onChange={handleChange}
      onChangeMiddleware={trimValue}
      error={{ [INPUT_NAME]: error }}
      onBlur={handleOnBlur}
      disabled={isClosing}
      formErrors={{ [INPUT_NAME]: error }}
    />
  );
}

export default EmailWithValidation;
