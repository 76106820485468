import React, { ChangeEvent, useEffect, useState } from 'react';

import { Order, Service } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';

import { RemoveManualChangesButton } from './RemoveManualChangesButton';

export const LinehaulChargeFlatRateInput = () => {
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex } = useOrderServiceIndex();
  const { onChange } = useOrderChangeSet();
  const { isClosing } = useOrderClosingContext();
  const orderDetail = isClosing ? order.closingOrderDetail : order;
  const services = orderDetail.services as List<Service>;
  const service = services.get(serviceIndex)!;
  const { quote } = service;
  const currentFlatRateLinehaulCharge = quote.getFinalFlatRateLinehaulCharge();
  const [linehaulChargeManual, setLinehaulChargeManual] = useState<string | number>(currentFlatRateLinehaulCharge);

  useEffect(() => {
    setLinehaulChargeManual(currentFlatRateLinehaulCharge);
  }, [currentFlatRateLinehaulCharge]);

  const name = `${
    isClosing ? 'closingOrderDetail.' : ''
  }services.${serviceIndex}.quote.flatRateQuoteDetailConfig.linehaulTotalManual`;

  const linehaulTotalManual = order.getIn(name.split('.')) as number | null;

  const handleOnBlur = (value: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;
    if (+currentFlatRateLinehaulCharge !== +value) {
      onChange({ name, value });
    }
  };

  const onChangeLinehaulChargeTotal = (charge: string) => setLinehaulChargeManual(charge);

  const onCancel = () => {
    setLinehaulChargeManual(quote.getFlatRateLinehaulCharge());
    onChange({ name, value: null });
  };

  return (
    <RemoveManualChangesButton manual={linehaulTotalManual} onClick={onCancel}>
      <CurrencyInput
        value={linehaulChargeManual}
        onChange={onChangeLinehaulChargeTotal}
        onBlur={handleOnBlur(+linehaulChargeManual)}
        name={name}
      />
    </RemoveManualChangesButton>
  );
};
