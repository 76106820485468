import React, { useEffect, useState } from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodySmallText,
  Button,
  HeaderBigText,
  IconButton,
  Order,
  UI_DATE_FORMAT_SHORT,
} from '@elromcoinc/react-shared';
import { Backdrop, Box, LinearProgress, Popover, Tooltip, makeStyles } from '@material-ui/core';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import InfoIcon from '@material-ui/icons/Info';
import { format, parse } from 'date-fns';
import pt from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import CustomerOnlineApi from 'admin/api/CustomerOnlineApi';
import OrderApi from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getCustomerOnlineCount } from 'admin/autodux/CustomerOnlineAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { ComposeEmailProvider } from 'admin/components/OrderWindow/modals/ComposeEmail/ComposeEmailProvider';
import { OrderMessageModal } from 'admin/components/Orders/modals';
import { statusById } from 'admin/constants/OrderStatus';
import Status from 'common/components/Status';

const CustomerOnlineBox = styled(Box)`
  background-color: #f5f5f6;
  padding: 8px 12px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    width: 340,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    overflowX: 'auto',
    maxHeight: '90%',
    display: 'block',
  },
}));

const CustomerOnlinePopper = ({ anchorEl, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [order, setOrder] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const customerOnlineCount = useSelector(getCustomerOnlineCount);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const loadCustomersOnline = () => {
    setInProgress(true);

    CustomerOnlineApi.getCustomerOnline()
      .then(({ pageElements }) => {
        setOrders(pageElements);
      })
      .catch(() => {})
      .then(() => setInProgress(false));
  };

  useEffect(() => {
    if (!isSessionExpired) {
      let interval = null;

      if (anchorEl) {
        loadCustomersOnline();
        interval = setInterval(loadCustomersOnline, 3e4);
      }

      return () => clearInterval(interval);
    }
  }, [anchorEl, isSessionExpired]);

  if (!anchorEl) {
    return null;
  }

  const handleOpenOrder = (orderId) => () => {
    dispatch(openOrder(orderId));
    onClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'customer-online-popover' : undefined;

  const handleCloseOrderMessageModal = () => setOrder(null);
  const handleOpenMessageModal = (orderId) => () => {
    OrderApi.getOrder(orderId).then(Order.createOrder).then(setOrder);
  };

  return (
    <Backdrop open={open} className={classes.backdrop}>
      {order && (
        <ComposeEmailProvider>
          <OrderMessageModal onClose={handleCloseOrderMessageModal} order={order} />
        </ComposeEmailProvider>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.paper}>
          <HeaderBigText id="customer-online-modal-title">{`Customers Online (${customerOnlineCount})`}</HeaderBigText>
          {inProgress && <LinearProgress color="primary" />}
          {(orders.length > 0 &&
            orders.map(
              ({
                orderId,
                orderNumber,
                status,
                contactFullName,
                customerFullName,
                moveType,
                serviceName,
                moveDate,
                customerId,
              }) => (
                <CustomerOnlineBox key={customerId}>
                  <Box>
                    <Box fontSize="14px" fontWeight="bold" lineHeight="23px" display="flex">
                      {contactFullName || customerFullName}
                      {!!contactFullName && (
                        <Tooltip title={`Logged in as a customer: ${customerFullName}`}>
                          <Box mb={-0.5} ml={0.5}>
                            <InfoIcon color="primary" fontSize="small" />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                    {orderId && (
                      <>
                        <BodySmallText component="div">
                          <Box display="flex">
                            <>Order:</>
                            <Box my={-1}>
                              <Button color="primary" variant="text" size="small" onClick={handleOpenOrder(orderId)}>
                                {`#${orderNumber}`}
                              </Button>
                            </Box>
                          </Box>
                        </BodySmallText>
                        <BodySmallText>
                          <>Move Date: </>
                          {moveDate && format(parse(moveDate, BACKEND_DATE_FORMAT, new Date()), UI_DATE_FORMAT_SHORT)}
                        </BodySmallText>
                        <BodySmallText>
                          <>Move type: </>
                          {moveType}
                        </BodySmallText>
                        <BodySmallText>
                          <>Service Type: </>
                          {serviceName}
                        </BodySmallText>
                      </>
                    )}
                  </Box>
                  {orderId && (
                    <Box display="flex">
                      <Box mr={2}>
                        <Status label={statusById(status)?.label} title={status} size="small" />
                      </Box>
                      <Box m={-0.5}>
                        <IconButton color="primary" variant="text" onClick={handleOpenMessageModal(orderId)}>
                          <ChatBubbleIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </CustomerOnlineBox>
              ),
            )) ||
            'There are no customers online'}
        </div>
      </Popover>
    </Backdrop>
  );
};

CustomerOnlinePopper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: pt.object,
  onClose: pt.func.isRequired,
};

CustomerOnlinePopper.defaultProps = {
  anchorEl: null,
};

export default CustomerOnlinePopper;
