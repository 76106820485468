import React, { FC, MouseEventHandler } from 'react';

import { BodySmallText, IconButton } from '@elromcoinc/react-shared';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { MapBlock, TruckBlockProps } from 'admin/components/OrderWindow/blocks/Addresses';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    distance: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      margin: '0 .5rem',
      backgroundColor: theme.palette.common.white,
      borderRadius: '12px',
      padding: '.5rem',
      minWidth: '52px',
      minHeight: '52px',
      justifyContent: 'space-evenly',
      '& > svg + p': {
        marginBottom: '4px',
      },
    },
  }),
);

export interface DestinationBlockProps extends Partial<TruckBlockProps> {
  onSetAddressIndex: (index: number) => MouseEventHandler<HTMLButtonElement>;
  durationFromPrevious?: string;
}

export const DestinationBlock: FC<DestinationBlockProps> = ({
  isDestination,
  distanceFromPrevious,
  onSetAddressIndex,
  index,
  showLastAddStop,
  openRoute,
  totalDistance,
  durationFromPrevious,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.distance}>
      {!isDestination ? (
        <>
          <IconButton size="tiny" onClick={onSetAddressIndex(index!)} variant="default" color="primary">
            <AddIcon />
          </IconButton>
          <ArrowForwardIcon color="primary" />
          {distanceFromPrevious && <BodySmallText>{distanceFromPrevious}</BodySmallText>}
          {durationFromPrevious && <BodySmallText>{durationFromPrevious}</BodySmallText>}
        </>
      ) : (
        <MapBlock
          showLastAddStop={showLastAddStop}
          onSetAddressIndex={onSetAddressIndex}
          openRoute={openRoute}
          index={index}
          totalDistance={totalDistance}
        />
      )}
    </Box>
  );
};
