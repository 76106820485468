import React, { useCallback, useEffect, useState } from 'react';

import {
  BodyText,
  Button,
  HeaderBigText,
  Modal,
  TextInput,
  TitleText,
  storageUtil,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box, Grid, InputAdornment, LinearProgress, Paper, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Business, Lock } from '@material-ui/icons';
import { GlobalVar } from 'GlobalVar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { login, readCurrentUser, setAccessIsTerminated } from 'admin/autodux/AuthAutodux';
import { fetchIsEnableWizard } from 'admin/autodux/WizardAutodux';
import { main } from 'admin/components/Wizard';
import { Conditions } from 'admin/containers/components/Conditions';
import { RequestDemoButton } from 'admin/containers/components/RequestDemoButton';
import { useReloadPageIfLoginUnderDifferentCompanyId } from 'admin/hooks';
import Form from 'common/components/Form';

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => {
  return {
    root: {
      zIndex: 3000,
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      background: `linear-gradient(80deg, ${palette.background.default} 0%, ${palette.background.default} 55%, ${palette.primary.dark} 55%)`,
      [breakpoints.down('md')]: {
        background: `linear-gradient(80deg, ${palette.background.default} 0%, ${palette.background.default} 40%, ${palette.primary.dark} 40%)`,
      },
    },
    rightContent: {
      zIndex: 3001,
      position: 'absolute',
      right: '2%',
      margin: 'auto',
      minWidth: 500,
      width: '30%',
      height: '60%',
      color: palette.common.white,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      '& h3': {
        opacity: 0,
      },
      [breakpoints.down('md')]: {},
      [breakpoints.down('sm')]: {
        minWidth: 430,
      },
      [breakpoints.up('sm')]: {
        '& h3': {
          opacity: 1,
        },
      },
    },
    loginForm: {
      zIndex: 3002,
      margin: 'auto',
      width: 400,
      padding: spacing(2),
      '& h4': {
        marginBottom: 24,
      },
      [breakpoints.down('sm')]: {
        width: 320,
      },
      [breakpoints.down('xs')]: {
        width: 260,
      },
      [breakpoints.up('sm')]: {
        marginLeft: '10%',
      },
    },
    logo: {
      width: 48,
      height: 48,
      top: 24,
      right: 24,
      position: 'absolute',
      backgroundImage: 'url(/elromco_logo.png)',
      backgroundSize: 'cover',
      [breakpoints.up('sm')]: {
        left: 24,
      },
    },
    requestDemoButtonContainer: {
      display: 'flex',
      justifyContent: 'end',
      maxWidth: '160px',
      marginLeft: 'auto',
    },
    sessionModal: {
      zIndex: '3003!important',
    },
  };
});

const SessionExceptionType = {
  MAX_USER_SESSION: 1,
  MAX_COMPANY_SESSION: 2,
};

const getSessionExceptionType = (response) => {
  if (response?.data?.message?.indexOf('Maximum') > -1) {
    return SessionExceptionType.MAX_COMPANY_SESSION;
  }
  return SessionExceptionType.MAX_USER_SESSION;
};

const LoginForm = () => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const { loggedIn, isFetching: inFlight, errors, accessIsTerminated } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyId, setCompanyId] = useState(storageUtil.getTenantId());
  const [sessionException, setSessionException] = useState(null);
  const [isLoginInFlight, setIsLoginInFlight] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const closeIsTerminatedModal = useCallback(() => {
    dispatch(setAccessIsTerminated(false));
  }, []);

  useReloadPageIfLoginUnderDifferentCompanyId(companyId);

  useEffect(() => {
    if (loggedIn === null) {
      dispatch(readCurrentUser());
    }
    if (loggedIn === false) {
      setUsername('');
      setPassword('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  const handleOnChange =
    (setter) =>
    ({ target: { value } }) =>
      setter(value);

  const markAsNoActiveSessions = () => setSessionException(null);

  const performLogin = () => {
    setIsLoginInFlight(true);
    dispatch(login(username, password, companyId, sessionException))
      .then(({ data }) => {
        GlobalVar.setValue('isSessionExpired', false);

        if (sessionException) {
          markAsNoActiveSessions();
        }

        return data;
      })
      .then(() => {
        dispatch(fetchIsEnableWizard()).then((result) => {
          if (result) {
            history.push(main);
          }
        });
      })
      .catch((response) => {
        if (response && response.status === 409) {
          setSessionException(getSessionExceptionType(response));
        }
      })
      .then(() => setIsLoginInFlight(false));
  };

  const { alertProps } = useAlert({
    onConfirm: sessionException === SessionExceptionType.MAX_USER_SESSION ? performLogin : undefined,
    confirmTitle: 'Continue',
    cancelTitle: sessionException === SessionExceptionType.MAX_USER_SESSION ? 'Cancel' : 'Close',
    reverseActions: true,
    onCancel: markAsNoActiveSessions,
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    performLogin();
  };

  if (loggedIn !== false) {
    return null;
  }

  return (
    <Box className={classes.root} data-testId="loginPage">
      <div className={classes.logo} />
      <Paper className={classes.loginForm}>
        <Box my={2}>
          <HeaderBigText>
            <b>Hi! Please login</b>
          </HeaderBigText>
          <Form onSubmit={handleFormSubmit}>
            {!!errors && !accessIsTerminated && (
              <BodyText color="error" align="center" data-testid="loginErrorMessage">
                {errors}
              </BodyText>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  disabled={inFlight}
                  type="text"
                  value={username}
                  onChange={handleOnChange(setUsername)}
                  fullWidth
                  label="Email address"
                  data-testid="emailOrUserName"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  disabled={inFlight}
                  type="password"
                  value={password}
                  onChange={handleOnChange(setPassword)}
                  fullWidth
                  autoFocus
                  label="Password"
                  data-testid="password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Business />
                      </InputAdornment>
                    ),
                  }}
                  disabled={inFlight}
                  data-testid="companyId"
                  type="text"
                  value={companyId}
                  onChange={handleOnChange(setCompanyId)}
                  fullWidth
                  autoFocus
                  label="Company ID"
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={inFlight}
                  data-testid="signIn"
                  loading={inFlight}
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Log in
                </Button>
              </Grid>
              {isMobile && (
                <Grid item xs={12}>
                  <Box mt={1}>
                    <RequestDemoButton buttonColor={theme.palette.primary.main} />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box mt={2}>
                  <Conditions />
                </Box>
              </Grid>
            </Grid>
          </Form>
        </Box>
      </Paper>
      <Box className={classes.rightContent}>
        <TitleText>
          <strong>Welcome to the future of moving.</strong>
        </TitleText>
        {!isMobile && (
          <Box className={classes.requestDemoButtonContainer}>
            <RequestDemoButton buttonColor={theme.palette.common.white} />
          </Box>
        )}
      </Box>
      {!!sessionException && (
        <Modal
          {...alertProps}
          className={classes.sessionModal}
          color="white"
          open={!!sessionException}
          disabledInProcessing={isLoginInFlight}
          title="Active Session"
        >
          {isLoginInFlight && (
            <Box mt={-1} height={8}>
              <LinearProgress color="primary" />
            </Box>
          )}
          {sessionException === SessionExceptionType.MAX_USER_SESSION
            ? 'You currently have an active session with this user name. Select Continue to terminate the other session and proceed with this session, or click Cancel to abort this login.'
            : 'You have reached the maximum number of concurrent sessions.'}
        </Modal>
      )}
      {!!accessIsTerminated && (
        <Modal
          actions={[
            {
              color: 'primary',
              label: 'Close',
              onClick: closeIsTerminatedModal,
            },
          ]}
          className={classes.sessionModal}
          color="white"
          open
          title="Access Forbidden"
        >
          {errors}
        </Modal>
      )}
    </Box>
  );
};

export default LoginForm;
