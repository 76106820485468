import { UI_LOCALIZED_TIME_FORMAT, toDate } from '@elromcoinc/react-shared';

import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import { START_TIME_EARLIEST } from 'admin/constants/FieldNames';
import { toDateString } from 'admin/utils/DateUtils';

import { EventProcessor } from '../EventProcessor';
import createUpdatedValueDto from '../UpdatedValueDto';
import { extractServiceIndexUtil } from '../extractServiceIndexUtil';

const toValue = (startTime: string | null, endTime: string | null) => {
  if (startTime === null || endTime === null) {
    return '';
  }
  const start = toDateString(toDate(startTime, TIME_STRING_FORMAT), UI_LOCALIZED_TIME_FORMAT);
  const end = toDateString(toDate(endTime, TIME_STRING_FORMAT), UI_LOCALIZED_TIME_FORMAT);
  return `${start} - ${end}`;
};

const startTimeWindow: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf(START_TIME_EARLIEST) === -1) {
    return null;
  }

  const serviceNumber = extractServiceIndexUtil(fieldPath, newOrder.services.size > 1);
  const oldService = oldOrder.services.get((serviceNumber || 1) - 1);
  const newService = newOrder.services.get((serviceNumber || 1) - 1);

  const oldValue = oldService ? toValue(oldService.quote.startTimeEarliest, oldService.quote.startTimeLatest) : '';
  const newValue = toValue(newService.quote.startTimeEarliest, newService.quote.startTimeLatest);

  if (oldValue === newValue) {
    return null;
  }

  return [createUpdatedValueDto(oldValue, newValue, 'Arrival Window', serviceNumber)];
};

export default startTimeWindow;
