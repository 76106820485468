import axios from 'axios';

import { ConfirmationSignatureDTO } from 'common-types';

class ConfirmationSignatureAPI {
  getAllTemplates() {
    return axios.get<ConfirmationSignatureDTO, ConfirmationSignatureDTO[]>(
      '/api/moving/confirmation-signature-template',
    );
  }

  saveConfirmationSignature(confirmationSignature: ConfirmationSignatureDTO) {
    return axios.put<ConfirmationSignatureDTO, ConfirmationSignatureDTO>(
      '/api/moving/confirmation-signature-template',
      confirmationSignature,
    );
  }
}

export default new ConfirmationSignatureAPI();
