enum DeliveryWindowType {
  ANY = 'ANY',
  NINE_AM_TWELVE_PM = 'NINE_AM_TWELVE_PM',
  TWELVE_THREE_PM = 'TWELVE_THREE_PM',
  THREE_SEVEN_PM = 'THREE_SEVEN_PM',
}

const DeliveryWindowNames = {
  [DeliveryWindowType.ANY]: 'Any Time',
  [DeliveryWindowType.NINE_AM_TWELVE_PM]: '9AM - 12AM',
  [DeliveryWindowType.TWELVE_THREE_PM]: '12PM - 3PM',
  [DeliveryWindowType.THREE_SEVEN_PM]: '3PM - 6PM',
};

export { DeliveryWindowType, DeliveryWindowNames };
