// @ts-ignore
import autodux from 'autodux';
import { useDispatch } from 'react-redux';

import userAPI from 'admin/api/UserAPI';

// this store will be runned under global SMTPErrors component
const {
  reducer,
  actions: { setEmployee: setCurrentEmployee, setInFlight },
  selectors: { getEmployee: getCurrentEmployee, inFlight: getInFlightCurrentEmployee },
} = autodux({
  slice: 'currentEmployee',
  initial: {
    inFlight: false,
    employee: null,
  },
});

export default reducer;

const fetchCurrentEmployee = (id: number) => (dispatch: ReturnType<typeof useDispatch>) => {
  dispatch(setInFlight(true));

  return userAPI
    .retrieveEmployee(id)
    .then((response) => {
      dispatch(setCurrentEmployee(response));
      return response;
    })
    .catch(() => {})
    .then((response) => {
      dispatch(setInFlight(false));
      return response;
    });
};

export { getCurrentEmployee, setCurrentEmployee, getInFlightCurrentEmployee, fetchCurrentEmployee };
