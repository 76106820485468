import autodux from 'autodux';
import AutoAssignmentAPI from 'admin/api/AutoAssignmentAPI';

const {
  reducer,
  actions: { setCosts, setSizes, setLeadSources, setIsFetching },
  selectors: { getCosts, getSizes, getLeadSources, getIsFetching }
} = autodux({
  slice: 'autoAssignment',
  initial: {
    costs: [],
    sizes: [],
    leadSources: [],
    isFetching: false
  },
  actions: {}
});

export default reducer;

const fetchSettings = () => dispatch => {
  dispatch(setIsFetching(true));

  return Promise.all([AutoAssignmentAPI.getCosts(), AutoAssignmentAPI.getSizes(), AutoAssignmentAPI.getLeadSources()])
    .then(([costs, sizes, sources]) => {
      dispatch(setCosts(costs));
      dispatch(setSizes(sizes));
      dispatch(setLeadSources(sources));
      dispatch(setIsFetching(false));
    })
    .catch(() => {
      dispatch(setIsFetching(false));
    });
};

export { setCosts, setSizes, getCosts, getSizes, getIsFetching, getLeadSources, fetchSettings };
