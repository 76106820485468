import React from 'react';

import { BodyText, IconButton, Radio } from '@elromcoinc/react-shared';
import { Box, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { DataOfCreditCards } from 'admin/components/PlanSettings/utils/DataOfCreditCards';

const makeCreditCardColumns = (creditCardsData: DataOfCreditCards[]) => [
  {
    name: 'default',
    label: 'Default',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const row = creditCardsData[rowIndex];

        return <Radio value={row.id} color="primary" />;
      },
      showOnMobileAsExpanded: false,
      sort: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const { name } = creditCardsData[rowIndex];
        return <BodyText color="text">{name}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'code',
    label: 'Last 4',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const { code } = creditCardsData[rowIndex];
        return <BodyText color="text">{code}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'expiration',
    label: 'Expiration',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const { expiration } = creditCardsData[rowIndex];
        return <BodyText color="text">{expiration}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'zip',
    label: 'Zip',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const { zip } = creditCardsData[rowIndex];
        return <BodyText color="text">{zip}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'deleteRow',
    label: ' ',
    options: {
      customBodyRender: (_: DataOfCreditCards[], { rowIndex }: { rowIndex: number }) => {
        const handleSendMessages = (event: any) => {
          event.stopPropagation();
        };

        return (
          <Tooltip title="Delete">
            <Box minWidth="40px">
              <IconButton color="primary" onClick={handleSendMessages}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Tooltip>
        );
      },
      showOnMobileAsExpanded: true,
      sort: false,
    },
  },
];

export { makeCreditCardColumns };
