import { ActivitySourceDescriptor } from '@elromcoinc/react-shared';
import { formatISO } from 'date-fns';
import { List, Record } from 'immutable';

import ManualActivityType from 'admin/constants/ManualActivityType';

export default class Activity extends Record({
  id: null,
  manuallyEnteredActivityType: ManualActivityType.PHONE,
  performed: new Date(),
  performerId: null,
  activitySource: null,
  sourceId: null,
  contactedName: null,
  contactedEmailOrPhone: null,
  note: null,
  notes: '',
  involvesCustomer: false,
  involvesForeman: false,
  involvesDispatch: false,
  involvesSales: false,
  deleted: false,
  activitySources: List(new ActivitySourceDescriptor()),
}) {
  static create(data = {}) {
    return new Activity(data).set(
      'activitySources',
      List(data?.activitySources?.map((activitySource) => new ActivitySourceDescriptor(activitySource)) ?? []),
    );
  }

  setActivitySource(activitySource, sourceId) {
    return this.set('activitySource', activitySource)
      .set('sourceId', sourceId)
      .set('activitySources', List([new ActivitySourceDescriptor({ activitySource, referencedEntityId: sourceId })]));
  }

  toDTO() {
    const performed = this.performed instanceof Date ? formatISO(this.performed) : this.performed || null;

    return {
      ...this.toJS(),
      notes: this.notes || null,
      performed: performed && performed.substr(0, 16), // need to cut off the timezone
    };
  }

  getNote() {
    const {
      note: content,
      involvesCustomer,
      involvesForeman,
      involvesDispatch,
      involvesSales,
      sourceId,
      activitySource,
      activitySources,
    } = this.toJS();

    if (!content || content.trim().length === 0) {
      return null;
    }

    return {
      content,
      involvesCustomer,
      involvesForeman,
      involvesDispatch,
      involvesSales,
      sourceId,
      activitySource,
      activitySources,
    };
  }
}
