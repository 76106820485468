import { SettingNames } from '@elromcoinc/react-shared';

import { ReferredViaDTO } from 'common-types';

const { LEAD_SOURCE } = SettingNames;

const getAllLeadSources = (settings: { [key: string]: any }) =>
  settings?.[LEAD_SOURCE]?.filter((it: ReferredViaDTO) => it.enabled)?.map((it: ReferredViaDTO) => [
    it.name,
    it.name,
  ]) || [];

export { getAllLeadSources };
