enum ReportsType {
  GENERAL_STATISTICS = 'GENERAL_STATISTICS',
  INCOME = 'INCOME',
  PAYMENTS_COLLECTED = 'PAYMENTS_COLLECTED',
  SALES_BY_EMPLOYEE = 'SALES_BY_EMPLOYEE',
  SMS_STATISTICS = 'SMS_STATISTICS',
  ASSIGNMENT = 'ASSIGNMENT',
  SIZE_OF_MOVE = 'SIZE_OF_MOVE',
  SERVICE_TYPE = 'SERVICE_TYPE',
  MOVE_TYPE = 'MOVE_TYPE',
  ESTIMATE_TYPE = 'ESTIMATE_TYPE',
  ACTIVITY = 'ACTIVITY',
  LEAD_SOURCE = 'LEAD_SOURCE',
}
type ReportTypes = keyof typeof ReportsType;

type ReportTableType = {
  readonly [key in ReportTypes]: string;
};

enum ReportsTableTabs {
  GENERAL_STATISTICS = 'GENERAL_STATISTICS',
  INCOME = 'INCOME',
  PAYMENTS_COLLECTED = 'PAYMENTS_COLLECTED',
  SALES_BY_EMPLOYEE = 'SALES_BY_EMPLOYEE',
  SMS_STATISTICS = 'SMS_STATISTICS',
}

enum ReportTabsNames {
  INCOME = 'Income',
  PAYMENTS_COLLECTED = 'Payments Collected',
  SALES_BY_EMPLOYEE = 'Sales by Employee',
  SMS_STATISTICS = 'SMS Statistics',
  GENERAL_STATISTICS = 'General Statistics',
}

enum ReportTableTitle {
  GENERAL_STATISTICS = 'General Statistics',
  INCOME = 'Income',
  PAYMENTS_COLLECTED = 'Payments Collected',
  SALES_BY_EMPLOYEE = 'Sales by Employee',
  SMS_STATISTICS = 'SMS Statistics',
  ASSIGNMENT = 'Assignment',
  SIZE_OF_MOVE = 'Size of Move',
  SERVICE_TYPE = 'Service Type',
  MOVE_TYPE = 'Move Type',
  ESTIMATE_TYPE = 'Estimate Type',
  ACTIVITY = 'Activity',
  LEAD_SOURCE = 'Source',
}

const ReportFileName: Partial<ReportTableType> = {
  [ReportsType.INCOME]: 'income-report',
  [ReportsType.PAYMENTS_COLLECTED]: 'payments-report',
  [ReportsType.SALES_BY_EMPLOYEE]: 'sales-report',
  [ReportsType.ASSIGNMENT]: 'assignments-report',
  [ReportsType.SIZE_OF_MOVE]: 'move-sizes-report',
  [ReportsType.SERVICE_TYPE]: 'service-types-report',
  [ReportsType.MOVE_TYPE]: 'move-types-report',
  [ReportsType.ESTIMATE_TYPE]: 'estimate-report',
  [ReportsType.ACTIVITY]: 'activity-report',
  [ReportsType.SMS_STATISTICS]: 'sms-report',
  [ReportsType.LEAD_SOURCE]: 'lead-source-report',
};
export type { ReportTypes, ReportTableType };

export { ReportsTableTabs, ReportFileName, ReportsType, ReportTableTitle, ReportTabsNames };
