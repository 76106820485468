import React, { useEffect, useRef, useState } from 'react';

import { Button, JobDto, JobType, isLongDistanceService, useConfirm } from '@elromcoinc/react-shared';
import { JobStatus as JobStatusType } from '@elromcoinc/react-shared/dist/types/JobStatus';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { set } from 'immutable';
import { useSnackbar } from 'notistack';

import crewDispatchApi from 'admin/api/crewDispatchApi';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';

interface CloseOrderProps {
  type?: JobType;
}

const jobLabels = {
  [JobType.PICK_UP]: 'pickup',
  [JobType.DELIVERY]: 'delivery',
};

export const CloseOrder = ({ type = JobType.PICK_UP }: CloseOrderProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const orderRef = useRef(order);
  orderRef.current = order;
  const { isClosing } = useOrderClosingContext();
  const [inFlight, setInFlight] = useState(false);
  const { fetchOrderInfo, showSaveDialog, changeSet } = useOrderChangeSet();
  const [isHoverButton, setIsHoverButton] = useState(false);
  const quote = order?.getServiceQuote(serviceIndex)!;
  const changeSetRef = useRef<typeof changeSet | null>(changeSet);
  changeSetRef.current = changeSet!;
  const pickupStatus = quote.dispatchJob?.jobStatus ?? JobStatusType.NOT_ASSIGNED;
  const deliveryStatus = quote.dispatchJob?.deliveryJobStatus ?? JobStatusType.NOT_ASSIGNED;
  const isPickup = type === JobType.PICK_UP;
  const currentStatus = isPickup ? pickupStatus : deliveryStatus;
  const disableDeliveryIfPickupNotCompleted = pickupStatus !== JobStatusType.COMPLETED && type === JobType.DELIVERY;
  const isLongDistance = isLongDistanceService(order?.getServiceType(serviceIndex));
  const currentLabel = isLongDistance ? ` ${jobLabels[type]}` : '';
  const { confirm, renderDialog } = useConfirm({
    title: 'Change status',
    message: `Are you sure you want to ${currentStatus !== JobStatusType.COMPLETED ? 'close' : 'open'} job?`,
    confirmTitle: currentStatus !== JobStatusType.COMPLETED ? 'close' : 'open',
    cancelTitle: 'Cancel',
    maxWidth: 'xs',
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    return () => {
      orderRef.current = null;
      changeSetRef.current = null;
    };
  }, []);
  const updateJobStatus = async (status: JobStatusType) => {
    if (!changeSetRef.current?.isEmpty()) {
      enqueueSnackbar('Please save changes.', { variant: 'warning' });
      showSaveDialog();
      return true;
    }

    if (!inFlight && status !== currentStatus && (await confirm())) {
      await fetchOrderInfo();
      const quote = orderRef.current?.getServiceQuote(serviceIndex)!;
      const defaultJob = {
        jobStatus: status,
        branchId: 1,
        crews: [],
        loadingTimeList: [],
        unloadingTimeList: [],
        deliveryJobStatus: JobStatusType.NOT_ASSIGNED,
        jobStatuses: [],
      } as JobDto;
      const dispatchJob = quote?.dispatchJob || defaultJob;
      const dispatchJobToUpdate = set(dispatchJob, isPickup ? 'jobStatus' : 'deliveryJobStatus', status) as JobDto;
      const s = orderRef.current?.services.first()!;

      setInFlight(true);

      (!dispatchJobToUpdate.id
        ? crewDispatchApi.assignCrew(s.id, dispatchJobToUpdate)
        : crewDispatchApi.setStatus(
            dispatchJobToUpdate.id,
            isPickup ? dispatchJobToUpdate.jobStatus : dispatchJobToUpdate.deliveryJobStatus,
            isPickup,
          )
      )
        .then(() => {
          enqueueSnackbar(`Job is ${status === JobStatusType.COMPLETED ? 'close' : 'open'}`, { variant: 'success' });
          fetchOrderInfo();
        })
        .catch(() => {
          enqueueSnackbar(`Something went wrong when ${status === JobStatusType.COMPLETED ? 'open' : 'close'} job`, {
            variant: 'error',
          });
        })
        .then(() => {
          setInFlight(false);
        });
    }
  };

  const reopenOrder = () => {
    updateJobStatus(JobStatusType.PAYMENT);
  };
  const closeOrder = () => {
    updateJobStatus(JobStatusType.COMPLETED);
  };

  const handleHoverButton = (value: boolean) => () => {
    setIsHoverButton(value);
  };

  return (
    <Grid item sm="auto">
      {currentStatus === JobStatusType.COMPLETED ? (
        <Button
          variant="text"
          color="inherit"
          disabled={inFlight || !isClosing}
          loading={inFlight}
          rounded
          onMouseOver={handleHoverButton(true)}
          onMouseLeave={handleHoverButton(false)}
          onClick={reopenOrder}
          fullWidth={isMobile}
        >
          {isHoverButton ? `Reopen${currentLabel} order` : `${currentLabel} Completed`}
          {isHoverButton ? <CloseIcon fontSize="small" /> : <CheckIcon fontSize="small" />}
        </Button>
      ) : (
        <Button
          variant="text"
          color="primary"
          disabled={inFlight || !isClosing || disableDeliveryIfPickupNotCompleted}
          loading={inFlight}
          rounded
          onClick={closeOrder}
          fullWidth={isMobile}
        >
          {`Close${currentLabel} order`}
        </Button>
      )}
      {renderDialog()}
    </Grid>
  );
};
