import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';

import { DataOfLicensedUsers } from 'admin/components/PlanSettings/utils/DataOfLicensedUsers';

const makeLicensedUsersColumns = (licensedUsersData: DataOfLicensedUsers[]) => [
  {
    name: 'assigned',
    label: 'Assigned',
    options: {
      customBodyRender: (_: DataOfLicensedUsers[], { rowIndex }: { rowIndex: number }) => {
        const { assigned } = licensedUsersData[rowIndex];
        return <BodyText color="text">{assigned}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'lastSeen',
    label: 'Last Seen',
    options: {
      customBodyRender: (_: DataOfLicensedUsers[], { rowIndex }: { rowIndex: number }) => {
        const { lastSeen } = licensedUsersData[rowIndex];
        return <BodyText color="text">{lastSeen}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (_: DataOfLicensedUsers[], { rowIndex }: { rowIndex: number }) => {
        const { status } = licensedUsersData[rowIndex];
        return <BodyText color="text">{status}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
];

export { makeLicensedUsersColumns };
