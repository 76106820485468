import { Record } from 'immutable';

/** @deprecated please use it from react-shared */
export default class Address extends Record({
  id: null,
  street1: '',
  street2: null,
  city: '',
  state: '',
  postalCode: '',
  country: 'USA',
  asSingleString: '',
  timezoneId: null,
  lat: null,
  lng: null,
}) {
  static createAddress(data = {}) {
    return new Address(data);
  }

  fullAddressLine() {
    return this.hasAddressInfo()
      ? [this.street1, this.street2, this.city, this.state, this.postalCode, this.country].filter(Boolean).join(', ')
      : '';
  }

  hasAddressInfo() {
    return [this.street1, this.street2, this.city, this.state, this.postalCode].filter(Boolean).length > 0;
  }

  geoCoords() {
    return this.lat && this.lng ? { lat: this.lat, lng: this.lng } : null;
  }

  isValid() {
    const hasPostalCode = !!this.postalCode;
    return [hasPostalCode, { postalCode: !hasPostalCode && 'ZIP must be specified.' }];
  }

  toDTO() {
    return this.toJS();
  }

  toStripeAddress() {
    const { street1: line1, street2: line2, city, state, postalCode, country } = this.toJS();
    return { line1, line2, city, state, postal_code: postalCode, country };
  }

  isEmpty() {
    return this.equals(new Address());
  }
}
