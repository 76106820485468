import React from 'react';

import { BodyText, Order, Switch } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { useOrderChangeSet, useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';
import { CalculatorIcon } from 'common/components/icons';

export const AutoCalculate = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { disableAutoCalculation } = order;
  const { isClosing, isCompleted, isLockSales } = useOrderClosingContext();

  const handleChange = (name: string, value: boolean) => onChange({ name, value });
  const handleAutoCalculationChange = (value: boolean) => handleChange('disableAutoCalculation', value);

  return (
    <Box display="flex" justifyContent="flex-end" marginTop="auto" alignItems="center">
      <BodyText display="inline">Auto calculate</BodyText>
      <CalculatorIcon />
      <Switch
        color="primary"
        disabled={isClosing || isCompleted || isLockSales}
        checked={!disableAutoCalculation}
        onChange={() => handleAutoCalculationChange(!disableAutoCalculation)}
      />
    </Box>
  );
};
