import { FC, useState, useCallback, ReactInstance, ChangeEvent, useEffect } from 'react';
import { Modal, Order, Radio, RadioGroup, useAlert } from '@elromcoinc/react-shared';
import { Box, LinearProgress } from '@material-ui/core';
import orderAPI from 'admin/api/OrderAPI';
import { ConfirmationSignatureType } from 'common-types';
import { useSnackbar } from 'notistack';
import { printHtml } from 'common/utils/printHtml';
import { useReactToPrint } from 'react-to-print';

interface PrintOrderModalProps extends ReturnType<typeof useAlert> {
  order: Order;
  orderViewRef: { current: ReactInstance | null };
}

// TODO invoice is not part of MVP
// const INVOICE = 'invoice';
const ORDER_FOR_SERVICE = 'orderForService';
const ORDER_WINDOW = 'orderWindow';

const printOptions = [
  // [INVOICE, 'Invoice'],
  [ORDER_FOR_SERVICE, 'Order for Service'],
  [ORDER_WINDOW, 'Order Window'],
];

const PrintOrderModal: FC<PrintOrderModalProps> = ({ alertProps, setShowAlert, order, orderViewRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOption, setSelectedOption] = useState(ORDER_FOR_SERVICE);
  const [inFlight, setInFlight] = useState(false);
  const onCancel = () => {
    setShowAlert(false);
  };

  useEffect(() => {
    setSelectedOption(ORDER_FOR_SERVICE);
  }, [alertProps.open]);

  const reactToPrintContent = useCallback(() => {
    return orderViewRef.current;
  }, [orderViewRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    removeAfterPrint: true,
  });

  const onPrint = () => {
    onCancel();

    if (selectedOption === ORDER_FOR_SERVICE) {
      setInFlight(true);

      orderAPI
        .getConfirmationSignatureTemplates(order.orderId!)
        .then((templates) => {
          const orderForServiceTemplates = templates.filter((t) => t.type === ConfirmationSignatureType.CONFIRMATION);

          if (orderForServiceTemplates.length) {
            orderForServiceTemplates.forEach((ofst) => {
              printHtml(ofst.body, { target: ofst.id!.toString() });
            });
            return;
          }

          enqueueSnackbar('No order for service template to print', { variant: 'warning' });
        })
        .catch(() => {})
        .then(() => {
          setInFlight(false);
        });
    }

    if (selectedOption === ORDER_WINDOW) {
      handlePrint();
    }
  };

  const actions = [
    {
      label: 'Close',
      onClick: onCancel,
      disabled: inFlight,
    },
    {
      label: 'Print',
      onClick: onPrint,
      disabled: inFlight,
      loading: inFlight,
    },
  ];

  const handleChangePrintOption = (event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    setSelectedOption(newValue);
  };

  return (
    <Modal title="Print" {...alertProps} actions={actions} maxWidth="xs" disabledInProcessing={inFlight}>
      <Box mt={-1} height={8}>
        {inFlight && <LinearProgress />}
      </Box>
      <RadioGroup value={selectedOption} onChange={handleChangePrintOption}>
        {printOptions.map(([value, label]) => (
          <Radio key={value} value={value} label={label} color="primary" disabled={inFlight} />
        ))}
      </RadioGroup>
    </Modal>
  );
};

export { PrintOrderModal };
