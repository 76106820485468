import React, { useCallback, useMemo, useState } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  BodyText,
  JobStatus,
  Link,
  Order,
  PaymentActivityDto,
  PaymentActivityType,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  Service,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import orderAPI from 'admin/api/OrderAPI';
import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { Payments } from 'admin/components/OrderWindow/modals/Payments';
import { isReservedOrBooked } from 'admin/constants/OrderStatus';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

enum Modals {
  PAYMENT = 'PAYMENT',
}

const updateOrderIfExist = (updates: Order) => (targetOrder: Order | null) => {
  if (targetOrder) {
    let updatedOrder = targetOrder
      .set('payments', updates.payments)
      .set('pendingPayments', updates.pendingPayments)
      .set('totalPaid', updates.totalPaid)
      .set('reservationAmountNeeded', updates.reservationAmountNeeded)
      .set('totalDepositPaid', updates.totalDepositPaid)
      .set('grandTotal', updates.grandTotal)
      .set(
        'services',
        targetOrder.services.map((s, index) => {
          const updatedService = updates.services.get(index) as Service;
          if (updatedService) {
            return s
              .setIn(['quote', 'pickupCashDiscounts'], updatedService.quote.pickupCashDiscounts)
              .setIn(['quote', 'pickupCreditCardFees'], updatedService.quote.pickupCreditCardFees)
              .setIn(['quote', 'pickupCustomFees'], updatedService.quote.pickupCustomFees)
              .setIn(['quote', 'paymentAdjustmentDtos'], updatedService.quote.paymentAdjustmentDtos)
              .setIn(['quote', 'totalPaid'], updatedService.quote.totalPaid)
              .setIn(['quote', 'reservationAmountNeeded'], updatedService.quote.reservationAmountNeeded)
              .setIn(['quote', 'totalDepositPaid'], updatedService.quote.totalDepositPaid);
          }
          return s;
        }),
      );
    const updatedClosingOrderDetail = updates.get('closingOrderDetail');
    if (updatedClosingOrderDetail !== null) {
      updatedOrder = updatedOrder.setIn(
        ['closingOrderDetail', 'services'],
        targetOrder.closingOrderDetail.services.map((s: Service, index: number) => {
          const updatedService = updatedClosingOrderDetail.services.get(index);
          if (updatedService) {
            return s
              .setIn(['quote', 'totalPaid'], updatedService.quote.totalPaid)
              .setIn(['quote', 'reservationAmountNeeded'], updatedService.quote.reservationAmountNeeded)
              .setIn(['quote', 'totalDepositPaid'], updatedService.quote.totalDepositPaid);
          }
          return s;
        }),
      );
      return updatedOrder;
    }
    return updatedOrder;
  }
  return null;
};

export const ClosingPayments = () => {
  const classes = useStyles();
  const { isSelectedAllServices, serviceIndex } = useOrderServiceIndex();
  const { order, setOrder, setOriginalOrder } = useOrderState();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const quote = order?.getServiceQuote(serviceIndex);
  const currentStatus = quote?.dispatchJob?.jobStatus ?? JobStatus.NOT_ASSIGNED;
  const isCanSendPaymentToBOL = currentStatus !== JobStatus.NOT_ASSIGNED;
  const activitySources: ActivitySourceDescriptor[] = useMemo(
    () => order?.getClosingPaymentActivitySources(serviceIndex)!,
    [serviceIndex, quote?.serviceRosterId],
  ) as ActivitySourceDescriptor[];

  const closePaymentModal = useCallback(() => {
    setOpenModal(null);
    if (order?.orderId) {
      orderAPI
        .getOrder(order!.orderId)
        .then(Order.createOrder)
        .then((order) => {
          // only update payment related info to keep all the order updates which were not yet saved
          setOrder(updateOrderIfExist(order));
          setOriginalOrder(updateOrderIfExist(order));
        })
        .catch(() => {});
    }
  }, [order]);

  if (!order) {
    return null;
  }

  const {
    email,
    primaryPhone: { number: phoneNumber },
  } = order.contactInfo;

  const paymentAdjustments = (quote?.paymentAdjustmentDtos ?? List()) as List<PaymentAdjustmentDTO>;
  const payments = (quote?.payments ?? List()) as List<PaymentActivityDto>;
  const calculatedDeposit = payments.reduce((total, payment) => {
    if (payment.type === PaymentActivityType.DEPOSIT && payment.settledAmount) {
      const adjustmentsTotal = paymentAdjustments
        .filter((pa) => pa.paymentId === payment.id)
        .reduce(
          (accumulator, pa) =>
            pa.paymentAdjustmentType === PaymentAdjustmentType.CASH_DISCOUNT
              ? accumulator + pa.total
              : accumulator - pa.total,
          0,
        );
      return total + payment.settledAmount + adjustmentsTotal;
    }

    return total;
  }, 0);
  const totalDepositPaid = paymentAdjustments.isEmpty() ? quote?.totalDepositPaid || 0 : calculatedDeposit;
  const currentDeposit = (quote?.reservationAmountNeeded || 0) - totalDepositPaid;
  const depositToPay = currentDeposit > 0 ? currentDeposit : 0;

  const amount =
    (order.isLongDistance(serviceIndex)
      ? order.closingOrderDetail?.getLDBalanceDue(serviceIndex, isSelectedAllServices)
      : order.closingOrderDetail?.getBalanceDue(serviceIndex, isSelectedAllServices)
    )?.maxValue ?? '';

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={() => setOpenModal(Modals.PAYMENT)}>
          Payments Made
        </Link>
        <BodyText>
          {order?.closingOrderDetail?.getTotalPaid(serviceIndex, isSelectedAllServices)?.asHumanReadableString() ?? ''}
        </BodyText>
      </Box>
      {Modals.PAYMENT === openModal && (
        <Payments
          open
          activitySources={activitySources}
          sourceId={order.orderId}
          activitySource={ActivitySourceType.ORDER}
          orderNumber={order.orderNumber}
          email={email}
          amount={amount}
          phoneNumber={phoneNumber}
          customerId={order.customerInfo.customerId}
          cardHolder={order?.contactInfo?.fullName()}
          reservationAmountNeeded={depositToPay}
          changeStatusOnDeposit={!isReservedOrBooked(order.status)}
          onClose={closePaymentModal}
          allowToMakePaymentAvailableOnBOL={isCanSendPaymentToBOL}
        />
      )}
    </Grid>
  );
};
