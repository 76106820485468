import { createContext } from 'react';

import { JobPayrollDto } from 'common-types/payroll';

interface PayrollContextProps {
  jobPayroll?: JobPayrollDto;
  deliveryJobPayroll?: JobPayrollDto;
  getPayrolls: () => void;
}

const PayrollContext = createContext({} as PayrollContextProps);

export { PayrollContext };
