import { FC, useState } from 'react';

import { Template } from 'common-types';

import { SendingTemplatesContext } from './SendingTemplatesContext';

const SendingTemplatesProvider: FC = ({ children }) => {
  const [sendingTemplates, setSendingTemplates] = useState<Template[]>([]);

  return (
    <SendingTemplatesContext.Provider value={{ sendingTemplates, setSendingTemplates }}>
      {children}
    </SendingTemplatesContext.Provider>
  );
};

export { SendingTemplatesProvider };
