import React, { Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  Address,
  BaseAddressInputs,
  BodyBigText,
  BodyText,
  Button,
  DatePicker,
  Form,
  IconButton,
  InvoiceDto,
  InvoiceEntity,
  InvoiceType,
  InvoicesStatusType,
  InvoicesStatusTypeName,
  Modal,
  MoveType,
  PhoneInput,
  PriceAdjustmentType,
  Select,
  TextInput,
  emailYUP,
  phoneNumberYUP,
  roundNumberToFixedDigits,
  toDate,
  useUpdateEffect,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Theme, Tooltip, createStyles, makeStyles, useTheme } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import clsx from 'clsx';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import { FieldValues, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { array, date, number, object, string } from 'yup';

import invoiceAPI from 'admin/api/InvoiceAPI';
import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { ADDRESS_STREET } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { COMPANY_NAME, MOVE_TYPE } from 'admin/components/OrderWindow/OrderWindowLabels';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import {
  CITY,
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  POSTAL_CODE,
  STATE,
  STREET,
} from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { InvoiceModalTitle } from 'admin/components/OrderWindow/modals/Invoices/InvoiceModalTitle';
import {
  ADDRESS,
  AMOUNT,
  DESCRIPTION,
  DISCOUNT,
  DUE_DATE,
  ISSUE_DATE,
  ITEMS,
  ITEM_NAME,
  InvoicesItemLabels,
  NOTES,
  QUANTITY,
  STATUS,
  TAX,
  TERMS,
  TOTAL,
  TYPE,
  UNIT_COST,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';
import { PrintInvoice } from 'admin/components/OrderWindow/modals/Invoices/PrintInvoice';
import { SendInvoiceEmailModal } from 'admin/components/OrderWindow/modals/Invoices/SendInvoiceEmailModal';
import { sendInvoiceEmail } from 'admin/components/OrderWindow/modals/Invoices/sendInvoiceEmail';
import { Payments } from 'admin/components/OrderWindow/modals/Payments';
import { CONTACT_INFO, PHONE, PRIMARY_PHONE } from 'admin/constants/FieldNames';
import { useInvoiceStatusColors } from 'admin/hooks';
import { useMobile } from 'admin/hooks/useMobile';
import { getRates } from 'admin/selectors';
import { getInvoiceTitle } from 'admin/utils';
import { InvoiceActionType, SendEmailDto } from 'common-types';
import { CompanyInfo } from 'common-types/CompanyInfo';
import paidStampLogo from 'common/imgs/paidStamp.png';

import { PaymentClientKeyProvider } from '../../../context/PaymentClientKeyProvider';
import { InvoiceItemForm } from './InvoiceItemForm';
import { SubtotalBlock } from './SubtotaBlock';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    terms: {
      border: `1px solid ${palette.divider}`,
      marginTop: spacing(1),
      borderRadius: '5px',
    },
    flexBlock: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    address: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      minHeight: '100%',
    },
    print: {
      padding: spacing(3),
    },
    printItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: spacing(1),
      width: '48%',
      color: palette.common.black,
      paddingTop: spacing(2),
    },
    printAddress: {
      borderBottom: `1px solid ${palette.divider}`,
      color: palette.common.black,
      paddingLeft: spacing(1),
      minWidth: '28%',
    },
    justify: {
      textAlign: 'justify',
    },
    paidLogo: {
      height: '120px',
      width: '120px',
    },
  }),
);

const useStylesStatus = makeStyles<Theme>(({ spacing }) => ({
  topBar: {
    '& .MuiDialogTitle-root': {
      backgroundColor: (props: { color: string }) => props.color,
      display: 'block',
      padding: spacing(1, 1, 1, 3),
    },

    '& .MuiPaper-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

const ADDITIONAL_CONTACT = 'additionalContact';
const CANADA = 'Canada';

const addressFieldNames = {
  zipCode: POSTAL_CODE,
  city: CITY,
  state: STATE,
};

const addressLabels = {
  [CITY]: 'City',
  [STATE]: 'State',
  [POSTAL_CODE]: 'ZIP',
};

const labels = {
  [ADDITIONAL_CONTACT]: {
    [EMAIL]: 'Additional Email',
    [PHONE]: 'Additional Phone Number',
  },
  [COMPANY_NAME]: 'Company Name',
  [CONTACT_INFO]: {
    [FIRST_NAME]: 'First Name',
    [LAST_NAME]: 'Last Name',
    [EMAIL]: 'Email',
    [PRIMARY_PHONE]: 'Phone Number',
  },
  [ADDRESS]: {
    [STREET]: 'Address',
    [CITY]: addressLabels[CITY],
    [STATE]: addressLabels[STATE],
    [POSTAL_CODE]: addressLabels[POSTAL_CODE],
  },
};

const asArray = (path: string) => path.split('.');

const contactInfoFullAddressPath = `${ADDRESS}`;
const contactInfoStreetAddressPath = `${ADDRESS}.${STREET}`;
const contactInfoFirstNamePath = `${CONTACT_INFO}.${FIRST_NAME}`;
const contactInfoLastNamePath = `${CONTACT_INFO}.${LAST_NAME}`;
const contactInfoEmailPath = `${CONTACT_INFO}.${EMAIL}`;
const contactInfoPrimaryPhoneNumberPath = `${CONTACT_INFO}.${PRIMARY_PHONE}`;
const additionalContactEmailPath = `${ADDITIONAL_CONTACT}.${EMAIL}`;
const additionalContactPhoneNumberPath = `${ADDITIONAL_CONTACT}.${PHONE}`;
const companyNamePath = `${COMPANY_NAME}`;

const schema = object().shape({
  [CONTACT_INFO]: object().shape({
    [FIRST_NAME]: string().label(labels[CONTACT_INFO][FIRST_NAME]).required().min(2).max(32),
    [LAST_NAME]: string().label(labels[CONTACT_INFO][LAST_NAME]).required().min(2).max(32),
    [EMAIL]: emailYUP({
      label: labels[CONTACT_INFO][EMAIL],
      name: EMAIL,
    }),
    [PRIMARY_PHONE]: phoneNumberYUP('Phone'),
  }),
  [ADDRESS]: object().shape(
    {
      [ADDRESS_STREET]: string().label(labels[ADDRESS][STREET]).nullable(),
      [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE)
        .label(addressLabels[POSTAL_CODE])
        .transform((v) => v || null)
        .nullable()
        .required(),
    },
    [[POSTAL_CODE, STREET]],
  ),
  [ISSUE_DATE]: date().label(InvoicesItemLabels[ISSUE_DATE]).nullable().required(),
  [DUE_DATE]: date().label(InvoicesItemLabels[DUE_DATE]).nullable().required(),
  [ITEMS]: array().of(
    object({
      [ITEM_NAME]: string().nullable().required().label(InvoicesItemLabels[ITEM_NAME]).min(2).max(32),
      [DESCRIPTION]: string().nullable().label(InvoicesItemLabels[DESCRIPTION]),
      [UNIT_COST]: string()
        .nullable()
        .required()
        .label(InvoicesItemLabels[UNIT_COST])
        .transform((v) => v || null),
      [QUANTITY]: number()
        .nullable()
        .required()
        .min(0)
        .max(999)
        .label(InvoicesItemLabels[QUANTITY])
        .transform((v) => (v ? roundNumberToFixedDigits(v, 0) : 0)),
      [DISCOUNT]: object().shape({
        [AMOUNT]: number()
          .transform((v) => v || 0)
          .required()
          .nullable(),
        [TYPE]: string()
          .oneOf([PriceAdjustmentType.PERCENT, PriceAdjustmentType.ABSOLUTE])
          .transform((value) => (value || value === '' ? value : null))
          .required()
          .nullable(),
      }),
    }),
  ),
  [DISCOUNT]: object().shape({
    [AMOUNT]: number()
      .transform((v) => v || 0)
      .required()
      .nullable(),
    [TYPE]: string()
      .oneOf([PriceAdjustmentType.PERCENT, PriceAdjustmentType.ABSOLUTE])
      .transform((value) => (value || value === '' ? value : null))
      .required()
      .nullable(),
  }),
  [TAX]: object().shape({
    [AMOUNT]: string()
      .nullable()
      .transform((v) => v || null),
    [TYPE]: string().nullable(),
  }),
});

interface InvoicesModalProps {
  invoice: InvoiceEntity;
  onClose: () => void;
  open: boolean;
  possibleAddresses?: Address[];
  onSave?: () => void;
  fetchInvoices?: () => void;
  defaultInvoiceActionType: InvoiceActionType;
  setInvoice: Dispatch<SetStateAction<InvoiceEntity | null>>;
  orderTotalPaid?: number;
}

const invoicesStatusOptions = Object.entries(InvoicesStatusTypeName);
const invoicesStatusWithoutDraftOptions = invoicesStatusOptions.filter(([name]) => name !== InvoicesStatusType.DRAFT);

const EditInvoice: FC<InvoicesModalProps> = ({
  open,
  onClose,
  invoice,
  possibleAddresses,
  onSave,
  fetchInvoices,
  defaultInvoiceActionType,
  setInvoice,
  orderTotalPaid = 0,
}) => {
  const activitySources: ActivitySourceDescriptor[] = useMemo(() => {
    return (
      invoice?.activitySources?.filter(
        (it: ActivitySourceDescriptor) =>
          it.activitySource === ActivitySourceType.ORDER || it.activitySource === ActivitySourceType.SERVICE,
      ) || []
    );
  }, [invoice]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMobile();
  const statusColor = useInvoiceStatusColors(theme);
  const color = `${statusColor[invoice?.status as keyof typeof statusColor]}`;
  const classesStatus = useStylesStatus({ color });
  const isNew = !invoice.id;
  const { amount, type } = invoice.discount;
  const isSentInvoice = invoice.status === InvoicesStatusType.SENT;
  const isDraftInvoice = invoice.status === InvoicesStatusType.DRAFT;
  const isPaidInvoice = invoice.status === InvoicesStatusType.PAID;
  const settings = useOrderWindowSettings();
  const rates = getRates(settings);
  const companyInfo = useSelector(getCompanyInfo) as CompanyInfo;
  const [inFlight, setInFlight] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [invoiceActionType, setInvoiceActionType] = useState<InvoiceActionType>(defaultInvoiceActionType);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [sendEmailInvoicePromise, setSendEmailInvoicePromise] = useState<{
    resolve: (v: SendEmailDto | null) => void;
  } | null>(null);

  const isReadOnly = invoiceActionType === InvoiceActionType.VIEW;

  const possibleAddressesLength = possibleAddresses?.filter((it) => !!it.postalCode)?.length ?? 0;
  const invoiceRef = useRef<HTMLElement | null>(null);

  const defaultValues = {
    ...invoice?.toJS(),
    dueDate: toDate(invoice?.dueDate),
    issueDate: toDate(invoice?.issueDate),
    discount: { amount, type },
  };

  const formMethods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange',
  });

  const { handleSubmit, watch, setValue } = formMethods;
  const invoiceType = watch(TYPE) as InvoiceType;
  const additionalContactEmailValue = watch(additionalContactEmailPath);
  const additionalContactPhoneNumberValue = watch(additionalContactPhoneNumberPath);
  const moveTypeValue = watch(MOVE_TYPE);

  useUpdateEffect(() => {
    if (orderTotalPaid) {
      setValue('paidAmount', invoiceType === InvoiceType.CUSTOM ? 0 : orderTotalPaid);
    }
  }, [invoiceType]);

  const { fields, remove, append } = useFieldArray({
    control: formMethods.control,
    name: ITEMS,
  });

  const handleChangeAddress = () => {
    if (selectedAddressIndex !== possibleAddresses?.length! - 1) {
      setSelectedAddressIndex(selectedAddressIndex + 1);
      setValue(ADDRESS, possibleAddresses?.[selectedAddressIndex + 1]?.toJS()!);
    } else {
      setSelectedAddressIndex(0);
      setValue(ADDRESS, possibleAddresses?.[0]?.toJS()!);
    }
  };

  const handleAddInvoiceItem = () => {
    append({
      [ITEM_NAME]: '',
      [DESCRIPTION]: '',
      [UNIT_COST]: 0,
      [QUANTITY]: 0,
      [TOTAL]: {
        manual: null,
        calculated: 0,
      },
      [DISCOUNT]: {
        [AMOUNT]: 0,
        [TYPE]: PriceAdjustmentType.PERCENT,
      },
    });
  };
  const handleSubmitForm = (data: InvoiceDto) => {
    setInFlight(true);

    const dataToSave = new InvoiceEntity(data).toDto();

    return (isNew ? invoiceAPI.create<InvoiceDto>(dataToSave) : invoiceAPI.update<InvoiceDto>(dataToSave))
      .then((response) => {
        if (isNew || dataToSave.status === invoice.status) {
          setValue('totalPaid', response.totalPaid);
          return response;
        }

        return invoiceAPI.changeInvoicesStatus(dataToSave.status, [invoice.id!]).then((response) => {
          return response[0];
        });
      })
      .then((response) => {
        enqueueSnackbar('Invoice has been saved', { variant: 'success' });
        onSave ? onSave() : fetchInvoices?.();
        if (isNew) {
          onClose();
        }
        return response;
      })
      .catch(() => {
        enqueueSnackbar(`Invoice wasn't saved. Please try again later.`, { variant: 'error' });
      })
      .then((response) => {
        setInFlight(false);
        if (!isNew) {
          setInvoice(new InvoiceEntity(response));
          setInvoiceActionType(InvoiceActionType.VIEW);
        }
        return response;
      });
  };

  const handleSaveAsDraft = (data: InvoiceDto) => {
    if (isNew) {
      data.status = InvoicesStatusType.DRAFT;
    }

    handleSubmitForm(data);
  };

  const handleSendAndSave = async (data: InvoiceDto) => {
    const resultOfSendInvoice = (await new Promise((resolve) => {
      setSendEmailInvoicePromise({ resolve });
    })) as SendEmailDto | null;

    if (!resultOfSendInvoice) {
      return;
    }
    data.status = InvoicesStatusType.SENT;

    handleSubmitForm(data).then((response) => {
      sendInvoiceEmail(resultOfSendInvoice, response!)
        .then(() => {
          enqueueSnackbar('Emails was successfully sent to client.', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('Problem with sending emails please check SMTP setting.', { variant: 'error' });
        });
    });
  };

  const handleCloseEmailModal = () => {
    sendEmailInvoicePromise?.resolve(null);
    setSendEmailInvoicePromise(null);
  };

  const handleClosPaidModal = () => {
    invoiceAPI.getInvoiceById<InvoiceDto>(invoice.id).then((response) => {
      setInvoice(new InvoiceEntity(response));
    });
    onSave ? onSave() : fetchInvoices?.();
    setOpenPaymentModal(false);
  };

  const handleSendAndCloseEmailModal = (dto?: SendEmailDto) => {
    sendEmailInvoicePromise?.resolve(dto!);
    setSendEmailInvoicePromise(null);
  };

  const title = getInvoiceTitle(invoice.toDto());

  const handleOpenOrder = () => {
    dispatch(openOrder(invoice.orderId!));
  };

  return (
    <Modal
      open={open}
      title={
        <InvoiceModalTitle
          invoice={invoice}
          onClose={onClose}
          invoiceRef={invoiceRef}
          invoiceActionType={invoiceActionType}
          setInvoiceActionType={setInvoiceActionType}
          setOpenPaymentModal={setOpenPaymentModal}
        />
      }
      hideActionControls
      maxWidth="lg"
      disabledInProcessing={inFlight}
      fullScreen={isMobile}
      minimizeContent={isMobile}
      minimalPaddingTitle={!isMobile}
      className={clsx(classesStatus.topBar, 'applyRadius')}
    >
      <FormProvider {...(formMethods as any)}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <BodyBigText>
                  <b>Send To</b>
                </BodyBigText>
                <Box>
                  {!isNew && !!invoice.orderNumber && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={handleOpenOrder}
                    >{`#${invoice.orderNumber}`}</Button>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    data-testid="firstName"
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    label={getIn(labels, asArray(contactInfoFirstNamePath), '')}
                    name={contactInfoFirstNamePath}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    fullWidth
                    data-testid="lastName"
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    label={getIn(labels, asArray(contactInfoLastNamePath), '')}
                    name={contactInfoLastNamePath}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    data-testid="email"
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    name={contactInfoEmailPath}
                    label={getIn(labels, asArray(contactInfoEmailPath), '')}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    fullWidth
                    data-testid="phoneNumber"
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    label={getIn(labels, asArray(contactInfoPrimaryPhoneNumberPath), '')}
                    name={contactInfoPrimaryPhoneNumberPath}
                    size="small"
                  />
                </Grid>
                {/* We don't have it in invoice dto need BE */}
                {!!additionalContactEmailValue && (
                  <Grid item xs={12}>
                    <TextInput
                      fullWidth
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      data-testid="additionalEmail"
                      name={additionalContactEmailPath}
                      label={getIn(labels, asArray(additionalContactEmailPath), '')}
                      size="small"
                    />
                  </Grid>
                )}
                {/* We don't have it in invoice dto need BE */}
                {!!additionalContactPhoneNumberValue && (
                  <Grid item xs={12}>
                    <PhoneInput
                      fullWidth
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      data-testid="additionalPhoneNumber"
                      label={getIn(labels, asArray(additionalContactPhoneNumberPath), '')}
                      name={additionalContactPhoneNumberPath}
                      size="small"
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={possibleAddressesLength > 1 ? 11 : 12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextInput
                            fullWidth
                            data-testid="street"
                            InputProps={{
                              readOnly: isReadOnly,
                            }}
                            label={getIn(labels, asArray(contactInfoStreetAddressPath), '')}
                            name={contactInfoStreetAddressPath}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BaseAddressInputs
                            disabled={isReadOnly}
                            fieldNames={addressFieldNames}
                            labels={addressLabels}
                            name={contactInfoFullAddressPath}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {possibleAddressesLength > 1 && !isReadOnly && (
                      <Grid item xs={1}>
                        <Box className={classes.address}>
                          <Tooltip title="Change Address">
                            <Box component="span">
                              <IconButton
                                color="primary"
                                size="small"
                                data-testId="changeAddress"
                                onClick={handleChangeAddress}
                              >
                                <SwapHorizIcon />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid item container xs={12} sm={12} spacing={1}>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    data-testid="companyName"
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    label={getIn(labels, asArray(companyNamePath), '')}
                    name={companyNamePath}
                    value={companyInfo.basicInfo?.companyName ?? ''}
                    size="small"
                    skipControl
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    fullWidth
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    data-testid="companyEmail"
                    value={companyInfo.basicInfo?.email ?? ''}
                    label="Company Email"
                    size="small"
                    skipControl
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    fullWidth
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    data-testid="companyPhone"
                    value={companyInfo.basicInfo?.phone ?? ''}
                    label="Company Phone"
                    size="small"
                    skipControl
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* @ts-ignore*/}
                  <DatePicker
                    fullWidth
                    showCalendarIcon={!isReadOnly}
                    readOnly={isReadOnly}
                    label={InvoicesItemLabels[ISSUE_DATE]}
                    name={ISSUE_DATE}
                    defaultValue={new Date()}
                    data-testid="issueDate"
                    rates={rates[moveTypeValue as MoveType] || {}}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* @ts-ignore*/}
                  <DatePicker
                    fullWidth
                    showCalendarIcon={!isReadOnly}
                    readOnly={isReadOnly}
                    label={InvoicesItemLabels[DUE_DATE]}
                    name={DUE_DATE}
                    defaultValue={new Date()}
                    data-testid="dueDate"
                    rates={rates[moveTypeValue as MoveType] || {}}
                    size="small"
                  />
                </Grid>
                {!isNew && (
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      InputProps={{
                        readOnly: isReadOnly,
                      }}
                      label={InvoicesItemLabels[STATUS]}
                      name={STATUS}
                      options={isDraftInvoice ? invoicesStatusOptions : invoicesStatusWithoutDraftOptions}
                      primaryBackgroundOnSelectedItem
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <InvoiceItemForm fields={fields} remove={remove} isReadOnly={isReadOnly} />
          <Box className={classes.flexBlock}>
            <Box mt={1} display="flex">
              {!isReadOnly && (
                <Box>
                  <Button
                    color="primary"
                    data-testid="addItem"
                    textTransform="capitalize"
                    onClick={handleAddInvoiceItem}
                    className="print-remove"
                  >
                    Add Item
                  </Button>
                </Box>
              )}
            </Box>
            {isPaidInvoice && (
              <Box display="flex" mt={5}>
                <img className={classes.paidLogo} alt="Paid Logo" src={paidStampLogo} />
              </Box>
            )}
            <SubtotalBlock isReadOnly={isReadOnly} isCanada={companyInfo?.address?.country === CANADA} />
          </Box>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
                <BodyText>
                  <b>Terms</b>
                </BodyText>
                <Box className={classes.terms}>
                  <TextInput
                    fullWidth
                    multiline
                    minRows={3}
                    name={TERMS}
                    label={InvoicesItemLabels[TERMS]}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: isReadOnly,
                    }}
                    hiddenLabel
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <BodyText>
                  <b>Notes Visible for Clients</b>
                </BodyText>
                <Box className={classes.terms}>
                  <TextInput
                    fullWidth
                    multiline
                    minRows={3}
                    name={NOTES}
                    label={InvoicesItemLabels[NOTES]}
                    hiddenLabel
                    InputProps={{
                      readOnly: isReadOnly,
                      classes: {
                        input: classes.justify,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {!isReadOnly && (
            <Box mt={2} pb={5} className="print-remove">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7}>
                  <Box display="flex" justifyContent="center" mb={1}>
                    <Button
                      data-testid="saveAsDraft"
                      color={isNew ? 'default' : 'primary'}
                      textTransform="capitalize"
                      loading={inFlight}
                      onClick={handleSubmit(handleSaveAsDraft as any)}
                    >
                      {isNew ? 'Save as Draft' : 'Save'}
                    </Button>
                  </Box>
                  {!isSentInvoice && (
                    <BodyText align="center">
                      Save this invoice as a draft. Your client will not be able <br /> to view this invoice until it is
                      sent.
                    </BodyText>
                  )}
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Box display="flex" justifyContent="center" mb={1}>
                    <Button
                      data-testid="saveInvoices"
                      color="primary"
                      textTransform="capitalize"
                      onClick={handleSubmit(handleSendAndSave as any)}
                    >
                      {isNew ? 'Proceed to Send Invoice' : 'Update and Proceed to Send Invoice'}
                    </Button>
                  </Box>
                  <BodyText align="center">Preview this invoice and send it to your client.</BodyText>
                </Grid>
              </Grid>
            </Box>
          )}
        </Form>
      </FormProvider>
      <PrintInvoice ref={invoiceRef!} values={invoice.toDto()} title={title} />
      {sendEmailInvoicePromise && (
        <SendInvoiceEmailModal
          invoiceId={invoice.id}
          onClose={handleCloseEmailModal}
          onSend={handleSendAndCloseEmailModal}
        />
      )}
      {openPaymentModal && (
        <PaymentClientKeyProvider>
          <Payments
            open
            activitySources={activitySources}
            changeStatusOnDeposit={false}
            sourceId={invoice.id}
            activitySource={ActivitySourceType.INVOICE}
            customerId={invoice.customerId}
            orderNumber={invoice.number}
            email={invoice.contactInfo.email}
            phoneNumber={invoice.contactInfo.primaryPhone}
            cardHolder={invoice.employeeFullName}
            reservationAmountNeeded={0}
            amount={invoice.balance}
            onClose={handleClosPaidModal}
          />
        </PaymentClientKeyProvider>
      )}
    </Modal>
  );
};

export default EditInvoice;
