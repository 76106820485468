import React, { useEffect, useState } from 'react';

import {
  AccountsPermissions,
  BACKEND_DATE_FORMAT,
  Button,
  HeaderBigText,
  TextInput,
  UI_DATE_FORMAT_SHORT,
  statusIds,
  toDate,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, InputAdornment, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { add, format, parseISO } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import OrderAPI from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { MovesTable, SelectStatuses } from 'admin/components/AccountWindow/components';
import CreateOrder from 'admin/components/CreateOrder';
import { AccountType } from 'admin/constants/AccountType';
import { allNewStatus } from 'admin/constants/OrderStatus';

const useStyles = makeStyles((theme) => ({
  selectBox: {
    width: '295px',
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      minWidth: '9rem',
      maxWidth: '18rem',
      marginLeft: '2rem',
    },
  },
  searchBox: {
    width: '295px',
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      minWidth: '12rem',
      maxWidth: '12rem',
    },
  },
}));

export const CurrentMoves = ({ summaryAccount }) => {
  const classes = useStyles();
  const { id } = summaryAccount;
  const [orders, setOrders] = useState([]);
  const [isFlight, setIsFlight] = useState(false);
  const startDate = format(new Date(), BACKEND_DATE_FORMAT);
  const endDate = format(add(new Date(), { years: 100 }), BACKEND_DATE_FORMAT);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordersCount, setOrdersCount] = useState(0);
  const [sortBy, setSortBy] = useState('moveStartDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [currentStatus, setCurrentStatus] = useState([]);
  const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
  const [search, setSearch] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);
  const canNotCreateNewOrderForAffiliateAccount =
    !useHasPermission(AccountsPermissions.PERM_CAN_CREATE_A_NEW_ORDER_FOR_AFFILIATE_ACCOUNT) &&
    summaryAccount.type === AccountType.AFFILIATE;
  const canNotCreateNewOrderForCorporateAccount =
    !useHasPermission(AccountsPermissions.PERM_CAN_CREATE_A_NEW_ORDER_FOR_CORPORATE_ACCOUNT) &&
    summaryAccount.type === AccountType.CORPORATE;

  const isSessionExpired = useSelector(getIsSessionExpired);

  const handleOpenCreateOrder = () => setOpenCreateOrderModal(true);

  const handleCloseCreateOrderModal = () => setOpenCreateOrderModal(false);

  const onColumnSortChange = (changedColumn, direction) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const handleChangeSearch = ({ target: { value } }) => setSearch(value);

  const getCurrentMoves = () => {
    setIsFlight(true);

    const queryFilters = {
      'account.id': id,
      moveStartDate: [startDate, endDate],
      status: currentStatus.includes(statusIds.NEW) ? [...currentStatus, ...allNewStatus] : currentStatus,
    };

    OrderAPI.getOrderSummariesWithFilters({
      pageSize: 1000,
      sortBy,
      sortOrder,
      ...queryFilters,
    })
      .promise.then(({ pageElements, totalElements }) => {
        setOrders(
          pageElements.map((item) => ({
            ...item,
            moveStartDate: format(toDate(item.startDate), UI_DATE_FORMAT_SHORT),
            lastUpdate: format(parseISO(item.updatedDate), UI_DATE_FORMAT_SHORT),
          })),
        );
        setOrdersCount(totalElements);
      })
      .catch(() => {
        enqueueSnackbar(`Can't get orders for current account`, { variant: 'error' });
      })
      .then(() => {
        setIsFlight(false);
      });
  };

  useEffect(() => {
    if (!isSessionExpired) {
      getCurrentMoves();
      const interval = setInterval(getCurrentMoves, 3e4);
      return () => clearInterval(interval);
    }
  }, [sortBy, sortOrder, startDate, endDate, currentStatus, isSessionExpired]);

  useEffect(() => {
    setPage(1);
  }, [currentStatus]);

  return (
    <Box>
      <Box px="24px" pb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center" py={2} flexWrap="wrap">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box>
              <Button
                rounded
                color="primary"
                data-testid="addNewOrderButton"
                disabled={
                  !canEditOtherUsersAccounts ||
                  canNotCreateNewOrderForAffiliateAccount ||
                  canNotCreateNewOrderForCorporateAccount
                }
                onClick={handleOpenCreateOrder}
              >
                add new order
              </Button>
              {openCreateOrderModal && <CreateOrder requestingAccountId={id} onClose={handleCloseCreateOrderModal} />}
            </Box>
            <Box className={classes.selectBox}>
              <SelectStatuses
                disabled={!canEditOtherUsersAccounts}
                currentStatus={currentStatus}
                setCurrentStatus={setCurrentStatus}
              />
            </Box>
          </Box>
          <Box alignSelf="flex-end" display="flex">
            <Box className={classes.searchBox}>
              <TextInput
                value={search || ''}
                placeholder="Order name or date"
                fullWidth
                size="small"
                disabled={!canEditOtherUsersAccounts}
                data-testid="searchOrderNameOrDate"
                hiddenLabel
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color={!canEditOtherUsersAccounts ? 'disabled' : 'primary'} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={handleChangeSearch}
              />
            </Box>
          </Box>
        </Box>
        <HeaderBigText>
          <b>Current Moves</b>
        </HeaderBigText>
      </Box>
      <MovesTable
        isFlight={isFlight}
        orders={orders}
        onColumnSortChange={onColumnSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        ordersCount={ordersCount}
        rowsPerPage={rowsPerPage}
        search={search}
        page={page}
      />
    </Box>
  );
};

CurrentMoves.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  summaryAccount: pt.object.isRequired,
};
