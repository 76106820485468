import { MoveTypeType } from '@elromcoinc/react-shared';
import { Record } from 'immutable';

import { ConfirmationSignatureType } from 'common-types/ConfirmationSignatureType';
import { makeTemplateReadyToEdit, makeTemplateReadyToSend } from 'common/utils';

import { ConfirmationSignatureDTO } from './ConfirmationSignatureDTO';

interface ConfirmationSignatureEntity extends Omit<ConfirmationSignatureDTO, 'moveTypes'> {
  services: number[];
  moveTypes: MoveTypeType[];
}

class ConfirmationSignature extends Record<ConfirmationSignatureEntity>({
  id: null,
  name: '',
  body: '',
  structureJson: '',
  enabled: true,
  type: ConfirmationSignatureType.ESTIMATE,
  showOnConfirmationPage: false,
  serviceTypes: [],
  moveTypes: [],
  services: [],
}) {
  constructor(data: Partial<ConfirmationSignatureDTO> = {}) {
    super({
      ...data,
      body: makeTemplateReadyToEdit(data?.body ?? ''),
      services: data.serviceTypes?.map((it) => it.serviceType.id) ?? [],
      moveTypes: data.moveTypes?.map((it) => it.moveType) ?? [],
    });
  }

  toDTO(serviceTypes: GeneralServiceType[]): ConfirmationSignatureDTO {
    const result = this.toJS();
    delete result.services;

    return {
      ...result,
      body: makeTemplateReadyToSend({ body: result?.body as string, useExistingBody: true, marginTop: 0 }),
      structureJson: '',
      serviceTypes: serviceTypes
        .filter((st) => this.services.includes(st.id))
        .map((serviceType) => ({ serviceType, enabled: true })),
      moveTypes: this.moveTypes.map((moveType) => ({ moveType, enabled: true })),
    } as ConfirmationSignatureDTO;
  }
}

export type { ConfirmationSignatureEntity };
export { ConfirmationSignature };
