import { List, Record } from 'immutable';
import Product from './Product';

export default class Products extends Record({
  products: new List()
}) {
  static createProducts({ products = [] }) {
    return new Products().set('products', List(products.map(Product.createProduct)));
  }
}
