import React, { FC, useState } from 'react';

import {
  ActivitySourceType,
  BodyBigText,
  BodyText,
  Checkbox,
  ElromcoCircularProgress,
  Modal,
} from '@elromcoinc/react-shared';
import {
  Box,
  List as FlagsList,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { OptionsObject, ProviderContext, useSnackbar } from 'notistack';

import invoiceAPI from 'admin/api/InvoiceAPI';
import orderApi from 'admin/api/OrderAPI';
import { useFetchFlags } from 'admin/hooks/useFetchFlags';
import { FlagsAssignmentsDto } from 'common-types';

const useStyles = makeStyles((theme) => ({
  selectedCompanyFlags: {
    backgroundColor: `${theme.palette.grey[100]} !important`,
  },
  companyFlagsList: {
    maxHeight: '70vh',
  },
  iconButton: {
    padding: '3px',
  },
  listItemIcon: {
    minWidth: '40px',
  },
}));

interface EditFlagsModalProp {
  title: string;
  onClose: () => void;
  onSave: (flags?: number[]) => void;
  isFlight?: boolean;
  sourceIds: number[];
  flags?: number[];
  activitySource?: ActivitySourceType.ORDER | ActivitySourceType.INVOICE;
}

const sourceByActivitySource = {
  [ActivitySourceType.ORDER]: 'order(s)',
  [ActivitySourceType.INVOICE]: 'invoice(s)',
};

const EditFlagsModal: FC<EditFlagsModalProp> = ({
  title,
  onClose,
  onSave,
  isFlight = false,
  sourceIds = [],
  flags = [],
  activitySource = ActivitySourceType.ORDER,
}) => {
  const [checkedFlags, setCheckedFlags] = useState<number[]>(flags);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar() as ProviderContext;
  const [removeAll, setRemoveAll] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const errorMessage: OptionsObject = { variant: 'error' };
  const { inFlight: inFlightFlags, flags: flagsOptions } = useFetchFlags(activitySource);

  const handleAddFlags = (flagIds: number[]) => {
    if (activitySource === ActivitySourceType.ORDER) {
      setIsProcessing(true);

      orderApi
        .updateOrdersFlags({ entityIds: sourceIds!, flagIds })
        .then(() => {
          const method = flagIds.length ? 'added' : 'removed';
          enqueueSnackbar(`Flags ${method} successfully`, { variant: 'success' });
          onSave();
        })
        .catch(() => {
          enqueueSnackbar(`Can't set flags on an order`, errorMessage);
        })
        .then(() => {
          setIsProcessing(false);
          onClose();
        });
    } else if (activitySource === ActivitySourceType.INVOICE) {
      setIsProcessing(true);

      const flagsDto: FlagsAssignmentsDto = { flagIds, entityIds: sourceIds };

      invoiceAPI
        .setFlags(flagsDto)
        .then(() => {
          const method = flagIds.length ? 'added' : 'removed';
          enqueueSnackbar(`Flags ${method} successfully`, { variant: 'success' });
          onSave();
        })
        .catch(() => {
          enqueueSnackbar(`Can't set flags on an invoices`, errorMessage);
        })
        .then(() => {
          setIsProcessing(false);
          onClose();
        });
    } else {
      onSave(flagIds);
    }
  };

  const handleCheckedFlags = (id: number) => () => {
    setRemoveAll(false);

    if (checkedFlags) {
      setCheckedFlags?.((state) => (state.includes(id) ? state.filter((item) => item !== id) : [...state, id]));
    }
  };

  const handleSaveFlags = () => {
    if (!checkedFlags?.length && !removeAll) {
      enqueueSnackbar('Please choose company flags', { variant: 'warning' });
      return;
    }
    handleAddFlags(checkedFlags! ?? []);
  };

  const handleClearAllFlags = () => {
    setCheckedFlags?.([]);
    setRemoveAll(true);
  };

  return (
    <Modal
      onClose={onClose}
      title={title}
      maxWidth="xs"
      hideActionControls={isProcessing || isFlight}
      open
      actions={[
        { label: 'cancel', onClick: onClose, color: 'primary' },
        { label: 'save', onClick: handleSaveFlags, color: 'primary' },
      ]}
    >
      <>
        {(isFlight || inFlightFlags) && flagsOptions.length === 0 && (
          <Box height={8} mt={-1}>
            <LinearProgress />
          </Box>
        )}
        {(isProcessing && flagsOptions.length !== 0) || isFlight ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <BodyText color="primary">
              <b>
                Сhanging flags for {sourceIds?.length} {sourceByActivitySource[activitySource]}
                ...
              </b>
            </BodyText>
            <Box pt={1}>
              <ElromcoCircularProgress />
            </Box>
          </Box>
        ) : (
          <FlagsList className={classes.companyFlagsList}>
            <ListItem
              role={undefined}
              dense
              selected={removeAll}
              classes={{ selected: classes.selectedCompanyFlags }}
              component="li"
              onClick={handleClearAllFlags}
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                <Checkbox color="primary" sameUncheckedColor checked={removeAll} className={classes.iconButton} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <Box>
                      <BodyBigText>Clear all existing flags</BodyBigText>
                    </Box>
                  </>
                }
              />
            </ListItem>
            {flagsOptions.map((item, index) => {
              const labelId = `company-flag-label-${item.id}`;
              const toggleFlags = checkedFlags.includes(item.id!);

              return (
                <ListItem
                  key={item.id}
                  role={undefined}
                  dense
                  selected={toggleFlags}
                  classes={{ selected: classes.selectedCompanyFlags }}
                  component="li"
                  onClick={handleCheckedFlags(item.id!)}
                >
                  <ListItemIcon classes={{ root: classes.listItemIcon }}>
                    <Checkbox
                      color="primary"
                      sameUncheckedColor
                      checked={toggleFlags}
                      className={classes.iconButton}
                      inputProps={{ 'aria-labelledby': labelId, autoFocus: index === 0 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={
                      <>
                        <Box color={item.rgbColor}>
                          <BodyBigText>{item.name}</BodyBigText>
                        </Box>
                      </>
                    }
                  />
                </ListItem>
              );
            })}
          </FlagsList>
        )}
      </>
    </Modal>
  );
};

export { EditFlagsModal };
