import React, { useState } from 'react';

import {
  BodyText,
  Link,
  Order,
  Valuation as ValuationEntity,
  ValuationType,
  formatCurrency,
  getValuationSetting,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import {
  useOrderChangeSet,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import ValuationModal from 'admin/components/OrderWindow/modals/Valuation';
import { ZERO_MONEY } from 'admin/constants/DefaultValues';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

const valuationLabel = 'Valuation';

enum Modals {
  VALUATION = 'VALUATION',
}

const defaultTitleBasicValuation = '60 cents per pound';

export const ClosingValuation = () => {
  const classes = useStyles();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const settings = useOrderWindowSettings();
  const service = order.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const titleBasicValuation = getValuationSetting(
    settings,
    isLongDistance,
    order.moveType,
    'titleBasicValuation',
    defaultTitleBasicValuation,
  );
  const titleThirdOption = getValuationSetting(settings, isLongDistance, order.moveType, 'titleThirdOption');
  const [openModal, setOpenModal] = useState<Modals | null>(null);

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  const handleOrderChange = (name: string, value: any) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const handleValuationChange = (value: ValuationEntity) => handleOrderChange('closingOrderDetail.valuation', value);

  function getValuationValue(titleForBasicValuation: string) {
    const valuation = order.closingOrderDetail?.valuation;

    if (ValuationType.FULL === valuation.type) {
      return `$${formatCurrency(valuation.cost)}`;
    }

    if (ValuationType.THIRD_OPTION === valuation.type) {
      return titleThirdOption;
    }

    return titleForBasicValuation || defaultTitleBasicValuation;
  }

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.VALUATION)}>
          {valuationLabel}
        </Link>
        <BodyText>{getValuationValue(titleBasicValuation || ZERO_MONEY)}</BodyText>
      </Box>
      {Modals.VALUATION === openModal && (
        <ValuationModal
          open
          onSave={handleValuationChange}
          onCancel={toggleOpenModal(null)}
          orderId={order.orderId}
          moveType={order.moveType}
          isLocal={!isLongDistance}
          valuation={order.closingOrderDetail?.valuation}
        />
      )}
    </Grid>
  );
};
