import { ChangeEvent, useState } from 'react';

import {
  ActivitySourceDescriptor,
  ActivitySourceType,
  PaymentAdjustmentDTO,
  PaymentAdjustmentType,
  PriceAdjustment,
  PriceAdjustmentType,
  ServiceRosterClosingDto,
  useConfirm,
} from '@elromcoinc/react-shared';
import { List, setIn } from 'immutable';

import { paymentAdjustmentApi } from 'admin/api';
import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { getServiceRosterClosingPropertyName } from 'admin/utils';

interface UsePaymentAdjustment {
  adjustmentsToManage: PaymentAdjustmentType[];
}

export const usePaymentAdjustment = ({ adjustmentsToManage }: UsePaymentAdjustment) => {
  const { order, setOrder } = useOrderState();
  const { fetchOrderInfo } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const [inFlight, setInFlight] = useState(false);
  const [inFlightUpdate, setInFlightUpdate] = useState(false);
  const closingServiceRoster = order?.closingOrderDetail?.serviceRosterClosingsDto?.get(
    serviceIndex,
  ) as ServiceRosterClosingDto;
  const paymentAdjustments = (closingServiceRoster?.paymentAdjustmentDtos ?? List()).filter((pad) => {
    return adjustmentsToManage.includes(pad.paymentAdjustmentType);
  });
  const closingQuote = order?.closingOrderDetail?.getServiceQuote(serviceIndex);
  const additionalRatePerMoveSize =
    closingServiceRoster?.additionalRatePerMoveSize ?? closingQuote.longDistanceTariffDetails.getEffectiveRate() ?? 0;
  const { confirm, renderDialog } = useConfirm({
    title: 'Are you sure?',
    message: 'This will delete the additional weight charge.',
    confirmTitle: 'Yes',
    cancelTitle: 'No',
    maxWidth: 'xs',
  });

  const reloadOrder =
    (disable = true) =>
    () => {
      if (disable) {
        setInFlight(true);
      }

      fetchOrderInfo()
        .catch(() => {})
        .then(() => {
          setInFlight(false);
        });
    };

  const onChangeProperties = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
    if (!order) {
      return;
    }

    const name = getServiceRosterClosingPropertyName(serviceIndex, 'paymentAdjustmentDtos');
    const orderToUpdate = order?.setIn(
      name.split('.'),
      paymentAdjustments.map((pa) => {
        if (pa.id === id) {
          let updated = pa;

          if (event.target.name === 'additionalWeight') {
            updated = setIn(updated, ['rate', 'amount'], event.target.value);
          }

          if (event.target.name === 'rate') {
            updated = setIn(updated, ['quote'], event.target.value);
          }

          let total =
            updated.paymentAdjustmentType === PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE
              ? (updated.rate.amount ?? 0) * (updated.quote ?? 0)
              : updated.quote ?? 0;

          if (
            updated.paymentAdjustmentType !== PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE &&
            updated.rate.type === PriceAdjustmentType.PERCENT
          ) {
            total = ((updated.rate.amount ?? 0) / 100) * (updated.quote ?? 0);
          }

          const cashDiscountCoefficient =
            updated.paymentAdjustmentType === PaymentAdjustmentType.CASH_DISCOUNT && total > 0 ? -1 : 1;

          return setIn(updated, ['total'], total * cashDiscountCoefficient);
        }

        return pa;
      }),
    );

    setOrder(orderToUpdate);
  };

  const save = (id: number) => () => {
    const paymentAdjustment = paymentAdjustments.find((pa) => pa.id === id);

    if (paymentAdjustment) {
      // if the payment adjustment is associated with a bill of lading, we need to split it. it'll be better to show spinner when splitting
      if (
        paymentAdjustment.activitySources?.some(
          (as: ActivitySourceDescriptor) =>
            as.activitySource === ActivitySourceType.ORDER_BILL_OF_LADING ||
            as.activitySource === ActivitySourceType.SERVICE_BILL_OF_LADING,
        )
      ) {
        setInFlightUpdate(true);
      }

      paymentAdjustmentApi
        .updatePaymentAdjustment(paymentAdjustment)
        .then(reloadOrder(false))
        .catch(() => {})
        .then(() => {
          setInFlightUpdate(false);
        });
    }
  };

  const add =
    ({
      quote = additionalRatePerMoveSize,
      paymentAdjustmentType = PaymentAdjustmentType.ADDITIONAL_WEIGHT_CHARGE,
      amount = 0,
      name = 'Additional',
    } = {}) =>
    () => {
      if (!order || inFlight) {
        return;
      }

      setInFlight(true);

      paymentAdjustmentApi
        .createPaymentAdjustment({
          id: null,
          sourceId: order.orderId!,
          activitySource: ActivitySourceType.ORDER,
          activitySources: order.getClosingPaymentActivitySources(serviceIndex),
          involvesForeman: true,
          activityType: '',
          branchId: order?.branchId,
          name,
          message: '',
          total: 0,
          quote,
          rate: PriceAdjustment.createPriceAdjustment({
            type: PriceAdjustmentType.ABSOLUTE,
            amount,
          }),
          paymentAdjustmentType,
        })
        .then(reloadOrder())
        .catch(() => {})
        .then(() => {
          setInFlight(false);
        });
    };

  const deletePaymentAdjustment = (pa: PaymentAdjustmentDTO) => async () => {
    const answer = await confirm();

    if (answer) {
      setInFlight(true);

      if (!order) {
        return;
      }

      const name = getServiceRosterClosingPropertyName(serviceIndex, 'paymentAdjustmentDtos');
      const orderToUpdate = order?.setIn(
        name.split('.'),
        paymentAdjustments.filter((opa) => opa.id !== pa.id),
      );

      setOrder(orderToUpdate);

      paymentAdjustmentApi
        .delete(pa)
        .then(reloadOrder())
        .catch(() => {})
        .then(() => {
          setInFlight(false);
        });
    }
  };

  return {
    onChangeProperties,
    save,
    add,
    deletePaymentAdjustment,
    renderDeleteDialog: renderDialog,
    inFlight,
    inFlightUpdate,
    paymentAdjustments,
  };
};
