import { getBETemplateBuilderVariable } from 'admin/utils/getBETemplateBuilderVariable';
import { getUITemplateBuilderVariable } from 'admin/utils/getUITemplateBuilderVariable';
import { TemplateBuilderTokens } from 'common-types/TemplateBuilderTokens';

const sortedTokensByLength = [...TemplateBuilderTokens].sort((a, b) => b.token.length - a.token.length);

export const convertTemplateBuilderVariables = (template: string, fromBE = false): string => {
  let result = template || '';
  sortedTokensByLength.forEach((token) => {
    const beToken = getBETemplateBuilderVariable(token.token);
    const uiToken = getUITemplateBuilderVariable(token.simpleToken);
    const [from, to] = fromBE ? [beToken, uiToken] : [uiToken, beToken];

    result = result.replaceAll(from, to);
  });
  return result;
};
