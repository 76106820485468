enum NotificationCategories {
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  SYSTEM = 'SYSTEM',
}

const NotificationCategoriesNames = {
  [NotificationCategories.CUSTOMER]: 'Customer',
  [NotificationCategories.EMPLOYEE]: 'Employee',
  [NotificationCategories.SYSTEM]: 'System',
};

export { NotificationCategories, NotificationCategoriesNames };
