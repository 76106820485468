import React from 'react';

import { PhoneCarrierType, SmsCapability } from '@elromcoinc/react-shared';
import { Tooltip, makeStyles } from '@material-ui/core';
import DialerSipIcon from '@material-ui/icons/DialerSip';
import PhoneIcon from '@material-ui/icons/Phone';
import MobilePhoneIcon from '@material-ui/icons/PhoneIphone';
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkErase';
import pt from 'prop-types';

const useStyles = makeStyles(({ palette }) => ({
  success: {
    color: palette.success.main,
  },
}));

const getIcon = (carrierType, smsCapability) => {
  if (smsCapability === SmsCapability.CAPABLE_OFF) {
    return PhonelinkEraseIcon;
  }
  switch (carrierType) {
    case PhoneCarrierType.LANDLINE:
      return PhoneIcon;
    case PhoneCarrierType.VOIP:
      return DialerSipIcon;
    case PhoneCarrierType.MOBILE:
    case PhoneCarrierType.NONE:
    default:
      return MobilePhoneIcon;
  }
};

const getTooltipText = (carrierType, smsCapability) => {
  if (carrierType === PhoneCarrierType.INVALID || carrierType === PhoneCarrierType.NONE) {
    return 'The phone number is incorrect';
  }
  if (smsCapability === SmsCapability.CAPABLE_OFF) {
    return 'SMS is disabled';
  }
  if (smsCapability === SmsCapability.CAPABLE_ON) {
    return 'SMS is enabled';
  }
  return 'The phone number is correct';
};

const isMobileOrVoip = (type) => type === PhoneCarrierType.MOBILE || type === PhoneCarrierType.VOIP;

function PhoneCarrierIcon({ carrierType, smsCapability }) {
  const classes = useStyles();
  const Icon = getIcon(carrierType, smsCapability);
  const className = isMobileOrVoip(carrierType) && smsCapability === SmsCapability.CAPABLE_ON ? classes.success : '';
  const color =
    carrierType === PhoneCarrierType.INVALID ||
    carrierType === PhoneCarrierType.NONE ||
    smsCapability === SmsCapability.CAPABLE_OFF
      ? 'error'
      : 'default';

  return (
    <Tooltip title={getTooltipText(carrierType, smsCapability)} arrow placement="top">
      <Icon className={className} fontSize="small" color={color} />
    </Tooltip>
  );
}

PhoneCarrierIcon.propTypes = {
  carrierType: pt.oneOf([
    PhoneCarrierType.MOBILE,
    PhoneCarrierType.VOIP,
    PhoneCarrierType.LANDLINE,
    PhoneCarrierType.NONE,
    PhoneCarrierType.INVALID,
  ]).isRequired,
  smsCapability: pt.oneOf([SmsCapability.CAPABLE_ON, SmsCapability.CAPABLE_OFF, SmsCapability.NONE]).isRequired,
};

export default PhoneCarrierIcon;
