import { Order } from '@elromcoinc/react-shared';
import { Map, Set } from 'immutable';

import {
  ADDITIONAL_CONTACT,
  CONTACT_INFO,
  LABOR_DURATION_RANGE,
  START_TIME_EARLIEST,
  START_TIME_LATEST,
} from 'admin/constants/FieldNames';

import EventName from './EventName';
import { EventProcessor } from './EventProcessor';
import { UpdatedValueDto } from './UpdatedValueDto';
import { ValueUpdatesTracker } from './ValueUpdatesTracker';
import addAdditionalDay from './addAdditionalDay';
import addedAdditionalStop from './addedAdditionalStop';
import contactInfoChange from './contactInfoChange';
import customerInfo from './customerInfo';
import deleteAddress from './deleteAddress';
import discountChange from './discountChange';
import laborRateChange from './laborRateChange';
import minLaborHoursChange from './minLaborHoursChange';
import moveDateChange from './moveDateChange';
import moveSizeChange from './moveSizeChange';
import orderUpdate from './orderUpdate';
import travelRateChange from './travelRateChange';
import updateDepositAmount from './updateDepositAmount';
import updateSecondaryId from './updateSecondaryId';
import updatedAddress from './updatedAddress';
import valuationAdded from './valuationAdded';

const auditProcessors = Map<EventName, EventProcessor>({
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_DATE]: moveDateChange,
  [EventName.WHEN_A_EMPLOYEE_ADDS_A_DISCOUNT]: discountChange,
  [EventName.WHEN_A_EMPLOYEE_ADDS_VALUATION]: valuationAdded,
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_LABOR_RATE]: laborRateChange,
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_TRAVEL_TIME_RATE]: travelRateChange,
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_MINIMUM_HOURS]: minLaborHoursChange,
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_MOVE_SIZE]: moveSizeChange,
  [EventName.WHEN_A_EMPLOYEE_ADDS_AN_ADDITIONAL_STOP]: addedAdditionalStop,
  [EventName.WHEN_A_EMPLOYEE_DELETES_AN_ADDRESS]: deleteAddress,
  [EventName.WHEN_AN_ORDER_IS_UPDATED]: orderUpdate,
  [EventName.WHEN_AN_ORDER_CHANGES_CONTACT_INFORMATION]: contactInfoChange,
  [EventName.WHEN_A_EMPLOYEE_EDITS_CUSTOMER_INFORMATION]: customerInfo,
  [EventName.WHEN_A_EMPLOYEE_ADDS_AN_ORDER_SECONDARY_ID]: updateSecondaryId,
  [EventName.WHEN_A_EMPLOYEE_CHANGES_THE_DEPOSIT_AMOUNT]: updateDepositAmount,
});

const getIndexOfStartTimeSuffix = (field: string) => {
  const indexOfEarliest = field.indexOf(START_TIME_EARLIEST);
  if (indexOfEarliest > -1) {
    return indexOfEarliest;
  }
  return field.indexOf(START_TIME_LATEST);
};

const squashSimilarFields = (set: Set<string>, field: string) => {
  const indexOfStartTimeSuffix = getIndexOfStartTimeSuffix(field);
  if (field.indexOf(CONTACT_INFO) > -1) {
    return set.add(CONTACT_INFO);
  } else if (field.indexOf(ADDITIONAL_CONTACT) > -1) {
    return set.add(ADDITIONAL_CONTACT);
  } else if (indexOfStartTimeSuffix > -1) {
    return set.add(field.substring(0, indexOfStartTimeSuffix) + START_TIME_EARLIEST);
  } else if (field.indexOf(LABOR_DURATION_RANGE) > -1) {
    return set.add(LABOR_DURATION_RANGE);
  } else {
    return set.add(field);
  }
};

function audit(
  changeSet: Map<string, any>,
  oldOrder: Order,
  newOrder: Order,
  settings: Map<string, any>,
): ValueUpdatesTracker {
  const updates = auditProcessors
    .set(EventName.WHEN_A_EMPLOYEE_UPDATES_ADDRESS, updatedAddress(settings))
    .set(EventName.WHEN_A_EMPLOYEE_ADDS_ADDITIONAL_DAY_MULTIDAY_MOVE, addAdditionalDay(settings))
    .map((processor) => {
      return changeSet
        .keySeq()
        .reduce(squashSimilarFields, Set())
        .reduce<Set<UpdatedValueDto>>((array, fieldPath) => {
          const values = processor(fieldPath, oldOrder, newOrder, changeSet);
          return (values === null ? array : array.concat(values))
            .groupBy((it) => `${it.propertyName}${it.newValue}${it.oldValue}`)
            .map((it) => it.first() as UpdatedValueDto)
            .toSet();
        }, Set());
    })
    .filter((values) => values.size > 0);
  const parameters = Map<string, string>({ orderNumber: `${newOrder.orderNumber}` });
  return { updates, parameters };
}

export default audit;
