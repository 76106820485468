import { ContractTemplate, CustomReleaseFormService } from '@elromcoinc/moveboard-setting-react';
import { MoveType, ReferredVia } from '@elromcoinc/react-shared';
import axios from 'axios';

import { CompanyInfo } from 'common-types/CompanyInfo';
import { MileageTariff } from 'common-types/MileageTariff';

class SettingsAPI {
  getSettingNode<T = any>(settingName: string) {
    return axios.get<T, T>(`/api/settings/node/${settingName}`);
  }

  getDataFromQuery<T = any>(options: { companyId?: number; queries: string[] }) {
    const { companyId = 1, queries } = options;

    if (queries.length > 0) {
      const queryString = queries.map((item) => `nodes=${item}`).join('&');
      const apiString = `/api/settings/nodes/branch/${companyId}?${queryString}`;
      return axios.get<T, T>(apiString);
    }
    return Promise.reject();
  }

  updateSettingNode(data: any) {
    return axios.put('/api/settings/node', data);
  }

  /**
   * update list of settings with structure { name, value }
   * @param data array of { name, value }
   * @returns Promise
   */
  updateSettingNodes(data: { name: string; value: any }[]) {
    return axios.put('/api/settings/nodes', data);
  }

  getCompanyInfo() {
    return axios.get<CompanyInfo>(`/api/admin/company`);
  }

  updateCompanyInfo(data: CompanyInfo) {
    return axios.put('/api/admin/company', data);
  }

  getAllTariffs(branchId = 1) {
    return axios.get(`/api/settings/ld/tariff/branch/${branchId}/tariffs/`);
  }

  getTariffById(tariffId: number) {
    return axios.get(`/api/settings/ld/tariff/${tariffId}/`);
  }

  getAllTariffsByBranchIdAndMoveType(pBranchId = 1, pMoveType = 'RESIDENTIAL') {
    const branchId = pBranchId === 0 ? pBranchId : pBranchId || 1;
    return axios.get(`/api/settings/ld/tariff/branch/${branchId}/moveType/${pMoveType}/tariffs/`);
  }

  createNewTariff(newTariff: MileageTariff) {
    return axios.post<MileageTariff, MileageTariff>(`/api/settings/ld/tariff/`, newTariff);
  }

  updateTariff(newTariff: MileageTariff) {
    return axios.put<MileageTariff, MileageTariff>('/api/settings/ld/tariff/', newTariff);
  }

  createUpdateReferredVia(referredVia: ReferredVia) {
    return axios.put('/api/settings/referredVia', referredVia);
  }

  enableGoogleCalendar() {
    return axios.put('/api/calendar/settings/enable', {});
  }

  disableGoogleCalendar() {
    return axios.put('/api/calendar/settings/disable', {});
  }

  saveContractTemplate(data: ContractTemplate) {
    return axios.post<ContractTemplate, ContractTemplate>('/api/moving/contractTemplate', data);
  }

  deleteContractTemplate(id: number) {
    return axios.delete(`/api/moving/contractTemplate/${id}`);
  }

  saveReleaseFormService(data: CustomReleaseFormService) {
    return data.id
      ? axios.put<CustomReleaseFormService, CustomReleaseFormService>('/api/moving/releaseform/customService', data)
      : axios.post<CustomReleaseFormService, CustomReleaseFormService>('/api/moving/releaseform/customService', data);
  }

  deleteReleaseFormService(id: number) {
    return axios.delete(`/api/moving/releaseform/customService/${id}`);
  }

  getOrderStorageErrors(startDate: string, endDate: string, moveType: MoveType, moveSize: number) {
    return axios.get<string[], string[]>('/api/settings/validateSettingsForOrderStorage', {
      params: {
        startDate,
        endDate,
        moveType,
        moveSize,
      },
    });
  }
}

export default new SettingsAPI();
