import { Record } from 'immutable';
import { Duration } from '@elromcoinc/react-shared';

export function getValue(value: any): number | null {
  return Record.isRecord(value) ? +(value as any)?.value() : +value;
}

export function getMinHoursValue(value: any): number | null {
  return Record.isRecord(value) && !(value instanceof Duration)
    ? ((value as any)?.value() as Duration | null)?.humanReadable
    : value.humanReadable;
}
