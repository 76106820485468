enum DirectionWayType {
  SHORTEST = 'SHORTEST',
  FROM_C_TO_A = 'FROM_C_TO_A',
  FROM_B_TO_C = 'FROM_B_TO_C',
  LONGEST = 'LONGEST',
}

const DirectionWayName = {
  [DirectionWayType.SHORTEST]: 'Shortest',
  [DirectionWayType.LONGEST]: 'Longest',
  [DirectionWayType.FROM_B_TO_C]: 'From B to C',
  [DirectionWayType.FROM_C_TO_A]: 'From C to A',
};

export { DirectionWayType, DirectionWayName };
