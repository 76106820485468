import React from 'react';

import { CurrencyInput, DatePicker, TextInput } from '@elromcoinc/react-shared';
import { Grid } from '@material-ui/core';

import { AMOUNT, NOTES, PAID_DATE, labels } from '../CreateCharge/Common';

const CashPaymentFormInputs = () => {
  return (
    <>
      <Grid item xs={12}>
        <CurrencyInput
          /** @ts-ignore */
          label={labels[AMOUNT]}
          fullWidth
          name={AMOUNT}
        />
      </Grid>
      <Grid item xs={12}>
        {/** @ts-ignore */}
        <DatePicker fullWidth label={labels[PAID_DATE]} name={PAID_DATE} />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={labels[NOTES]} fullWidth name={NOTES} />
      </Grid>
    </>
  );
};

export { CashPaymentFormInputs };
