import React, { FC, useEffect, useState } from 'react';

import {
  AccountsPermissions,
  Autocomplete,
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  Link,
  Modal,
  Order,
  Radio,
  TextInput,
  useAlert,
  useDebounce,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, InputAdornment, LinearProgress, RadioGroup, TableRow, Tooltip, makeStyles } from '@material-ui/core';
import LabelTableCell from '@material-ui/core/TableCell';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import accountAPI from 'admin/api/AccountAPI';
import orderAPI from 'admin/api/OrderAPI';
import { openAccount } from 'admin/autodux/WindowsAutodux';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import { AccountEntity } from 'common-types';
import Popper from 'common/components/Popper';

import { ValueTableCell } from './CommonComponents';

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"]': { paddingTop: 6, paddingRight: 30 },
  },
  linkingAccountModal: {
    '& .MuiDialogTitle-root': { paddingBottom: 0, textTransform: 'none' },
    '& .MuiDialogContent-root': { padding: 0 },
  },
});

type AccountTypeTypes = keyof typeof AccountType;

interface AccountLinkProps {
  account?: AccountEntity;
  isLoading: boolean;
  order: Order;
  setLoadedOrder: (order: Order) => void;
}

type AccountOption = [number, string];

const AccountTypeOptions: AccountTypeTypes[] = [AccountType.CORPORATE, AccountType.AFFILIATE, AccountType.PERSONAL];

const AccountLink: FC<AccountLinkProps> = ({ account = null, isLoading = false, order, setLoadedOrder }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [accountAnchorEl, setAccountAnchorEl] = useState<(EventTarget & HTMLSpanElement) | null>(null);
  const [linkAccountType, setLinkAccountType] = useState<AccountTypeTypes | null>(null);
  const [search, setSearch] = useState('');
  const [selectedAccountId, setSelectedAccountId] = useState<AccountOption | null>(null);
  const [accountsOptions, setAccountsOptions] = useState<AccountOption[]>([]);
  const [inFlight, setInFlight] = useState(false);
  const debounceSearch = useDebounce(search, 500);
  const canDetachOrderFromAccount = useHasPermission(AccountsPermissions.PERM_CAN_DETACH_AN_ORDER_FROM_AN_ACCOUNT);
  const canUnlinkAccount = !!order.accountId && canDetachOrderFromAccount;

  const isNotPrivateAccount = account?.type !== AccountType.PRIVATE;

  const dispatch = useDispatch();

  const handleOpenAccountWindow = () => {
    if (account) {
      dispatch(openAccount(account.id));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<{}>) => {
    if (event) {
      const {
        // @ts-ignore
        target: { value },
      } = event;
      setAccountsOptions([]);
      setSearch(value);
    }
  };

  useEffect(() => {
    setSearch('');
    setAccountsOptions([]);
  }, [linkAccountType]);

  useEffect(() => {
    if ((debounceSearch || '').trim()) {
      const id = +debounceSearch ? debounceSearch : null;
      const name = +debounceSearch ? null : debounceSearch;
      setInFlight(true);
      accountAPI
        .getAllAccounts({ type: linkAccountType, id, name })
        // @ts-ignore
        .then((response: { pageElements: Account[] }) => {
          const { pageElements } = response;
          setAccountsOptions(pageElements.map((item) => [item.id, `${item.name} (#${item.id})`]));
        })
        .catch(() => {})
        .then(() => setInFlight(false));
    }
  }, [debounceSearch]);

  function onConfirmLinkAccount(onClose: () => void) {
    const [accountId] = selectedAccountId || [];

    if (accountId && order.orderId) {
      setInFlight(true);
      orderAPI
        .linkOrderToAccount(order.orderId, accountId)
        .then((updatedOrder) => {
          setLoadedOrder(Order.createOrder(updatedOrder));
          enqueueSnackbar('Account linked success', { variant: 'success' });
          setAccountAnchorEl(null);
          onClose();
        })
        .catch(() => {
          enqueueSnackbar('Account linked error', { variant: 'error' });
        })
        .then(() => {
          setInFlight(false);
        });
    }
    onCancelLinkAccount();
  }

  function onCancelLinkAccount() {
    setSelectedAccountId(null);
    setSearch('');
  }

  function onConfirmUnlinkAccount(onClose: () => void) {
    setInFlight(true);
    orderAPI
      .unlinkOrderFromAccount(order.orderId!)
      .then((updatedOrder) => {
        setLoadedOrder(Order.createOrder(updatedOrder));
        enqueueSnackbar('Account unlinked success', { variant: 'success' });
        setAccountAnchorEl(null);
        onClose();
      })
      .catch(() => {
        enqueueSnackbar('Account unlinked error', { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }

  const { setShowAlert, alertProps } = useAlert({
    onConfirm: onConfirmLinkAccount,
    onCancel: onCancelLinkAccount,
    cancelTitle: 'cancel',
    disabled: inFlight,
    reverseActions: true,
  });

  const { setShowAlert: setShowUnlinkAlert, alertProps: alertUnlinkProps } = useAlert({
    onConfirm: onConfirmUnlinkAccount,
    cancelTitle: 'cancel',
    disabled: inFlight,
    reverseActions: true,
  });

  const handleUnlinkAccount = () => setShowUnlinkAlert(true);

  function handleAutocompleteChange(event: React.ChangeEvent<{}>, value: AccountOption) {
    setSelectedAccountId(value);

    if (value) {
      setShowAlert(true);
    }
  }

  const autocomplete = (
    <Autocomplete
      classes={{ root: classes.autocomplete }}
      autoComplete
      selectOnFocus
      clearOnBlur
      includeInputInList
      options={accountsOptions}
      onChange={handleAutocompleteChange}
      onInputChange={handleSearchChange}
      value={selectedAccountId}
      renderInput={(params) => (
        <TextInput
          {...params}
          hiddenLabel
          autoFocus
          placeholder="Search account name or number"
          InputProps={{
            ...(params.InputProps || {}),
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );

  const handleChangeAccountType = (event: { target: { value: string } }) =>
    setLinkAccountType(event.target.value as AccountTypeTypes);

  return (
    <TableRow>
      <LabelTableCell style={{ verticalAlign: 'baseline' }} size="small" padding="none">
        <Link onClick={({ currentTarget }) => setAccountAnchorEl(currentTarget)}>Account</Link>
      </LabelTableCell>
      <ValueTableCell>
        {order.accountId && !!account?.id ? (
          <>
            {!isLoading ? (
              isNotPrivateAccount && (
                <>
                  <Tooltip title="Click to open an Account Window" arrow placement="top-end">
                    <Box display="flex" flexDirection="column">
                      <Link
                        color="primary"
                        underline="none"
                        align="left"
                        display="inline"
                        onClick={handleOpenAccountWindow}
                      >
                        <BodyText>
                          <b>{`ID: ${account.id}`}</b>
                        </BodyText>
                        {!!account.name && (
                          <Box style={{ textTransform: 'capitalize' }}>
                            <BodyText wordBreak noWrap>
                              <b>{`Name: ${account.name}`}</b>
                            </BodyText>
                          </Box>
                        )}
                      </Link>
                      <Box mt={0.5}>
                        <BodySmallText>
                          <b>{AccountTypeName[account.type]}&nbsp;Account</b>
                        </BodySmallText>
                      </Box>
                    </Box>
                  </Tooltip>
                  {canUnlinkAccount && (
                    <Box mt={1}>
                      <Button onClick={handleUnlinkAccount} color="primary" size="small">
                        Unlink
                      </Button>
                    </Box>
                  )}
                </>
              )
            ) : (
              <BodyText color="primary">Loading...</BodyText>
            )}
          </>
        ) : (
          <BodyText color="primary">
            <b>ID: not connected</b>
          </BodyText>
        )}
      </ValueTableCell>
      <Popper
        title="Link Order"
        anchorEl={accountAnchorEl}
        onClose={() => setAccountAnchorEl(null)}
        actions={null}
        minWidth={undefined}
      >
        <Box mt={-1} height={8}>
          {inFlight && <LinearProgress color="primary" />}
        </Box>
        <Box>
          <RadioGroup name="linkAccountType" value={linkAccountType} onChange={handleChangeAccountType}>
            {AccountTypeOptions.map((at) => (
              <React.Fragment key={at}>
                <Radio
                  color="primary"
                  value={at}
                  label={<BodyBigText>{`Link to ${AccountTypeName[at]} Account`}</BodyBigText>}
                />
                {linkAccountType === at && autocomplete}
              </React.Fragment>
            ))}
          </RadioGroup>
        </Box>
      </Popper>
      <Modal
        {...alertProps}
        maxWidth="xs"
        color="white"
        className={classes.linkingAccountModal}
        title={<BodyBigText>Do you want to link this order to the selected account?</BodyBigText>}
      />
      <Modal
        {...alertUnlinkProps}
        maxWidth="xs"
        color="white"
        className={classes.linkingAccountModal}
        title={<BodyBigText>Are you sure you want to unlink this order for the account?</BodyBigText>}
      />
    </TableRow>
  );
};

export default AccountLink;
