import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import playNotificationSound from 'common/components/PlayNotificationSound';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@elromcoinc/react-shared';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import NotificationIcon from 'common/imgs/logo_small.png';
import NativeNotification from 'admin/components/Notifications/NativeNotification';

const StyledIconButton = styled(IconButton)`
  && {
    padding: 4px;
  }
`;

const StyledImage = styled.img`
  width: 80px;
  height: auto;
`;

const CustomNotificationStyled = styled(Box)`
  .notification-since-date {
    color: ${({ theme }) => theme.palette.grey[500]};
  }
  .notification-request-number {
    color: ${({ theme }) => theme.palette.primary.main};
    cursor: pointer;
  }
`;

const SnackbarBox = styled(Box)`
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

const useStyles = makeStyles(theme => ({
  customNotification: {
    minWidth: 'unset',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary
  },
  description: {
    wordBreak: 'break-all',
    whiteSpace: 'break-spaces',
    maxWidth: theme.spacing(30)
  }
}));

const FIVE_SECONDS_TIMEOUT = 5 * 1000;

const NotifyNewNotification = ({ notification }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = key => () => closeSnackbar(key);
  const content = key => (
    <SnackbarBox key={key}>
      <CustomNotificationStyled id="message-id" display="flex">
        <StyledImage src={NotificationIcon} alt="Company logo" />
        <Box>
          <Box display="flex">
            {notification.orderNumber && (
              <Box color="primary" mr={1} className="notification-request-number">
                <>#</>
                {notification.orderNumber}
              </Box>
            )}
            <Box className="notification-since-date">
              {`${formatDistanceToNow(new Date(notification.dateTime))} ago`}
            </Box>
          </Box>
          <Box className={classes.description}>{notification.description}</Box>
          <Box>{notification.formattedDateTime}</Box>
        </Box>
      </CustomNotificationStyled>
      <Box>
        <StyledIconButton key="close" aria-label="close" color="inherit" onClick={handleClose(key)}>
          <CloseIcon />
        </StyledIconButton>
      </Box>
    </SnackbarBox>
  );

  const handleOpenCustomNotification = () => {
    enqueueSnackbar('', {
      content,
      variant: 'default',
      autoHideDuration: FIVE_SECONDS_TIMEOUT
    });
    playNotificationSound();
  };
  const handleShowNotification = () => playNotificationSound();

  return (
    <>
      <NativeNotification
        body={`${notification.description}\n${formatDistanceToNow(new Date(notification.dateTime))} ago`}
        title={notification.eventTypeDescription}
        notSupported={handleOpenCustomNotification}
        onPermissionDenied={handleOpenCustomNotification}
        onShow={handleShowNotification}
      />
    </>
  );
};

export default NotifyNewNotification;
