import { EventProcessor } from '../EventProcessor';
import { UpdatedValueDto } from '../UpdatedValueDto';
import additionalContact from './additionalContact';
import doubleDrivingTime from './doubleDrivingTime';
import estimateType from './estimateType';
import fuelCharge from './fuelCharge';
import grandTotal from './grandTotal';
import laborDurationRange from './laborDurationRange';
import moverCount from './moverCount';
import ratePerDistance from './ratePerDistance';
import ratePerSizeUnit from './ratePerSizeUnit';
import startTimeWindow from './startTimeWindow';
import travelTime from './travelTime';
import truckCount from './truckCount';

const auditProcessors: EventProcessor[] = [
  additionalContact,
  estimateType,
  startTimeWindow,
  laborDurationRange,
  travelTime,
  doubleDrivingTime,
  truckCount,
  moverCount('numberOfMovers'),
  moverCount('numberOfDeliveryMovers'),
  fuelCharge,
  ratePerDistance,
  ratePerSizeUnit,
  grandTotal,
];

const orderUpdate: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  return auditProcessors
    .map((auditProcessors) => auditProcessors(fieldPath, oldOrder, newOrder))
    .reduce((arr: UpdatedValueDto[], result) => (result ? [...arr, ...result] : arr), []);
};

export default orderUpdate;
