import { ActivitySourceType } from '@elromcoinc/react-shared';
import { FileDTO } from '@elromcoinc/react-shared';
import axios from 'axios';

import { PublicImage } from 'common-types/PublicImage';
import { blobToBase64 } from 'common/utils/blobToBase64';

class FileAPI {
  getImage(imageName: string) {
    return axios
      .get<Blob, Blob>(`/api/settings/file/branch/1?filename=${imageName}`, {
        responseType: 'blob',
      })
      .then((response) => {
        return URL.createObjectURL(response);
      });
  }

  getImageAsBase64(imageName: string) {
    return axios
      .get<Blob, Blob>(`/api/settings/file/branch/1?filename=${imageName}`, {
        responseType: 'blob',
      })
      .then(blobToBase64);
  }

  getImagePreview(fileId: string) {
    return axios
      .get<Blob, Blob>(`/api/moving/file/${fileId}`, {
        responseType: 'blob',
      })
      .then((response) => {
        return URL.createObjectURL(response);
      });
  }

  setImage(image: string, name: string) {
    return axios.post(`/api/settings/file/branch/1?filename=${name}`, image, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  getFileContent(sourceId: string, activitySource = ActivitySourceType.ORDER) {
    return axios.get(`/api/moving/file/${activitySource}/${sourceId}`);
  }

  getAllFiles(
    sourceId = 0,
    pageIndex = 0,
    pageSize = 10,
    extensions: string[] = [],
    activitySource = ActivitySourceType.ORDER,
  ) {
    return axios.get<PageDTO<FileDTO>, PageDTO<FileDTO>>(
      `/api/moving/file/${activitySource}/${sourceId}?pageIndex=${pageIndex}&pageSize=${pageSize}&${extensions
        .map((it) => `extensions=${it?.toUpperCase?.()}`)
        .join('&')}`,
    );
  }

  uploadFile<T = any>(formData: FormData) {
    return axios.post<T, T>('/api/moving/file', formData);
  }

  updateFileInfo(fileId: number, fileDto: any) {
    return axios.put(`/api/moving/file/${fileId}`, fileDto);
  }

  downloadFile(fileId: number) {
    return axios.get<Blob, Blob>(`/api/moving/file/${fileId}`, {
      responseType: 'blob',
    });
  }

  generatePresignedUrl(fileId: number) {
    return axios.get(`/api/moving/file/${fileId}/signed`);
  }

  deleteFile(fileId: number) {
    return axios.delete(`/api/moving/file/${fileId}`);
  }

  uploadPublicFile(formData: FormData) {
    return axios.post<PublicImage, PublicImage>('/api/moving/file/public', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  //TODO: REMOVED IT WHEN BE FIXED PUBLIC LOGO URL
  getCompanyPublicLogo(tenantId: number | string, branchId = 1) {
    return axios
      .get<Blob, Blob>(`/api/moving/file/public/logo/${tenantId}/${branchId}`, { responseType: 'blob' })
      .then((response) => {
        return URL.createObjectURL(response);
      });
  }
}

export default new FileAPI();
