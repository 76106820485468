import React, { ReactElement } from 'react';

enum DataTableColumnAttributes {
  ORDER_NUMBER = 'order.orderNumber',
  MOVE_TYPE = 'moveType',
  SERVICE_TYPE_NAME = 'serviceRosters.serviceType.name',
  ESTIMATE_TYPE = 'serviceRosters.estimateType',
  COMPANY_NAME = 'order.company.name',
  LEAD_SOURCE = 'order.leadSource',
  START_DATE = 'startDate',
  LINE_HAUL_CHARGE = 'serviceRosters.longDistanceTariffDetails', // need to check
  LABOR_CHARGE = 'serviceRosters.laborRate', // need to check
  FLAT_RATE_CHARGE = 'serviceRosters.flatRateQuote',
  TRAVEL_CHARGE = 'serviceRosters.travelRate', // need to check
  FUEL_CHARGE = 'serviceRosters.minFuelCharge',
  VALUATION = 'valuation',
  PACKING_CHARGE = 'totalPackingCharge',
  ADDITIONAL_SERVICES_CHARGE = 'serviceRosters.additionalServices', // need to check
  FEES = 'serviceRosters.fees',
  TAX_INFO = 'serviceRosters.taxInfo',
  ESTIMATED_GRAND_TOTAL = 'totalPriceMin',
  GRAND_TOTAL = 'closingGrandTotal',
  PAYMENT_RECEIVED = 'paymentReceived', // need to check
  BALANCE = 'balance',
  PAID_DATE = 'payment.paidDate',
  CUSTOMER_NAME = 'order.customer.firstName',
  PAYMENT_TYPE = 'payment.type',
  PAYMENT_BY = 'paymentBy',
  TRANSACTION_ID = 'payment.paymentCardTransaction.transactionId',
  AMOUNT = 'payment.amount',
  NOTES = 'payment.notes',
  NAME = 'employeeName',
  ORDERS_OVERALL = 'totalOrdersOverall',
  ORDERS = 'totalOrdersInPeriod',
  TOTAL_ESTIMATE_MIN = 'totalEstimateMin',
  BOOKED_OVERALL = 'totalBookedOverall',
  BOOKED_JOBS = 'totalBookedInPeriod',
  BOOKED_MIN = 'totalBookedEstimateMin',
  LOST_JOBS = 'totalLostOrdersInPeriod',
  TOTAL_CLOSING = 'closingTotal',
  CLOSING_GAP = 'closingGap',
  VALUATION_SOLD = 'totalValuation',
  PACKING_SOLD = 'totalPackingChargesInPeriod',
  COMMISSION_PAID = 'commissionPaid',
  CONVERSION = 'conversionRateForPeriod',
  TOTAL_MULTI_DAY_COUNT = 'multidayCount',
  SMS_DATE = 'statisticsDate',
  MONEY_SPENT = 'spent',
  SMS_COUNT = 'smsCount',
  FORWARDING_CALL_COUNT = 'forwardedCallsCount',
  PHONE_NUMBER_PRICE = 'phoneNumbersPrice',
}

const DataTableColumnNames: Record<DataTableColumnAttributes, string | ReactElement> = {
  [DataTableColumnAttributes.ORDER_NUMBER]: 'ORDER #',
  [DataTableColumnAttributes.CUSTOMER_NAME]: 'CUSTOMER NAME',
  [DataTableColumnAttributes.MOVE_TYPE]: 'MOVE TYPE',
  [DataTableColumnAttributes.SERVICE_TYPE_NAME]: 'SERVICE TYPE',
  [DataTableColumnAttributes.ESTIMATE_TYPE]: 'ESTIMATE TYPE',
  [DataTableColumnAttributes.COMPANY_NAME]: 'BRANCH',
  [DataTableColumnAttributes.LEAD_SOURCE]: 'SOURCE',
  [DataTableColumnAttributes.START_DATE]: 'MOVE DATE',
  [DataTableColumnAttributes.LINE_HAUL_CHARGE]: 'LINE HAUL CHG',
  [DataTableColumnAttributes.LABOR_CHARGE]: 'LABOR CHG',
  [DataTableColumnAttributes.FLAT_RATE_CHARGE]: 'FLAT RATE CHG',
  [DataTableColumnAttributes.TRAVEL_CHARGE]: 'TRAVEL CHG',
  [DataTableColumnAttributes.FUEL_CHARGE]: 'FUEL CHG',
  [DataTableColumnAttributes.VALUATION]: 'VALUATION',
  [DataTableColumnAttributes.PACKING_CHARGE]: 'PACKING',
  [DataTableColumnAttributes.ADDITIONAL_SERVICES_CHARGE]: 'ADDL SERVICES',
  [DataTableColumnAttributes.FEES]: 'ADDL FEES',
  [DataTableColumnAttributes.TAX_INFO]: 'TAXES',
  [DataTableColumnAttributes.GRAND_TOTAL]: 'GRAND TOTAL',
  [DataTableColumnAttributes.ESTIMATED_GRAND_TOTAL]: 'ESTIMATED',
  [DataTableColumnAttributes.PAYMENT_RECEIVED]: 'PAYMENT',
  [DataTableColumnAttributes.BALANCE]: 'BALANCE',
  [DataTableColumnAttributes.PAID_DATE]: 'DATE',
  [DataTableColumnAttributes.PAYMENT_TYPE]: 'PAYMENT TYPE',
  [DataTableColumnAttributes.PAYMENT_BY]: 'PAYMENT BY',
  [DataTableColumnAttributes.TRANSACTION_ID]: 'TRANSACTION ID',
  [DataTableColumnAttributes.AMOUNT]: 'AMOUNT',
  [DataTableColumnAttributes.NOTES]: 'NOTES',
  [DataTableColumnAttributes.NAME]: 'NAME',
  [DataTableColumnAttributes.ORDERS_OVERALL]: 'ORDERS OVERALL',
  [DataTableColumnAttributes.ORDERS]: <>ORDERS&nbsp;&nbsp;&nbsp;</>,
  [DataTableColumnAttributes.TOTAL_ESTIMATE_MIN]: 'TOTAL ESTIMATES',
  [DataTableColumnAttributes.BOOKED_OVERALL]: 'BOOKED OVERALL',
  [DataTableColumnAttributes.BOOKED_JOBS]: 'BOOKED JOBS',
  [DataTableColumnAttributes.BOOKED_MIN]: 'BOOKED',
  [DataTableColumnAttributes.LOST_JOBS]: 'LOST JOBS',
  [DataTableColumnAttributes.TOTAL_CLOSING]: 'TOTAL CLOSING',
  [DataTableColumnAttributes.CLOSING_GAP]: 'CLOSING GAP',
  [DataTableColumnAttributes.VALUATION_SOLD]: 'VALUATION SOLD',
  [DataTableColumnAttributes.PACKING_SOLD]: 'PACKING SOLD',
  [DataTableColumnAttributes.COMMISSION_PAID]: 'COMMISSION PAID',
  [DataTableColumnAttributes.CONVERSION]: 'BOOKED CONV. %',
  [DataTableColumnAttributes.TOTAL_MULTI_DAY_COUNT]: 'MULTI-DAY ORDERS',
  [DataTableColumnAttributes.SMS_DATE]: 'DATE',
  [DataTableColumnAttributes.MONEY_SPENT]: 'MONEY SPENT',
  [DataTableColumnAttributes.SMS_COUNT]: 'SMS SENT',
  [DataTableColumnAttributes.FORWARDING_CALL_COUNT]: 'FORWARDING CALL COUNT',
  [DataTableColumnAttributes.PHONE_NUMBER_PRICE]: 'PHONE NUMBER RENTAL',
};

export { DataTableColumnAttributes, DataTableColumnNames };
