import React, { useEffect, useMemo, useState } from 'react';

import {
  BodyText,
  Button,
  CurrencyInput,
  Modal,
  Radio,
  RadioGroup,
  Valuation as ValuationEntity,
  ValuationStatus,
  ValuationType,
  formatCurrency,
  getFormErrorMessages,
  getValuationSetting,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { number, object, string } from 'yup';

import ValuationAPI from 'admin/api/ValuationAPI';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { CalculatorIcon } from 'common/components/icons';
import useDebounce from 'common/hooks/useDebounce';

const GridItem = styled(Grid)`
  && {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

const defaultTitleFullValueProtection = 'Full Value Protection';

const TYPE = 'type';
const MAXIMUM_VALUATED_AMOUNT = 'maximumValuedAmount';
const SELECTED_PLAN_INDEX = 'selectedPlanIndex';
const VALUATION_AMOUNT = 'amount';
const STATUS = 'status';
const DEFAULT_LIABILITY_AMOUNT = 'defaultLiabilityAmount';

const notAvailableErrorText =
  'Full value protection is not available for this amount. Please contact your administrator.';

const valuationStatusErrors = {
  [ValuationStatus.LIABILITY_AMOUNT_EXCEEDED]: 'Liability amount exceeded',
  [ValuationStatus.PLAN_HAS_BEEN_CHANGED]: 'Plan has been changed',
  [ValuationStatus.PLANS_NOT_SETUP]: 'Plans not setup',
};

const schema = object({
  [TYPE]: string().nullable().required(),
  [MAXIMUM_VALUATED_AMOUNT]: number().nullable(),
  [VALUATION_AMOUNT]: number()
    .label('Liability')
    .nullable()
    .transform((v) => (v !== 0 ? v || null : v))
    .when([MAXIMUM_VALUATED_AMOUNT, TYPE], (maximumAmount, type, s) =>
      (maximumAmount && type === ValuationType.FULL ? s.max(maximumAmount) : s).min(0).required(),
    )
    .when([STATUS, TYPE], (status, type, s) =>
      status && status !== ValuationStatus.SUCCESS && type === ValuationType.FULL
        ? s.test(VALUATION_AMOUNT, valuationStatusErrors[status], () => false)
        : s,
    ),
  [SELECTED_PLAN_INDEX]: number()
    .label('Valuation option')
    .nullable()
    .when([TYPE, '$valuationList'], (type, valuationList, s) =>
      type === ValuationType.FULL && valuationList?.length ? s.required() : s,
    )
    .when([TYPE, '$valuationList'], (type, valuationList, s) =>
      type === ValuationType.FULL && !valuationList?.length ? s.test(TYPE, notAvailableErrorText, () => false) : s,
    ),
  [STATUS]: string().nullable(),
});

const Valuation = ({ orderId, valuation, moveType, onSave, onCancel, open, isLocal }) => {
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const settings = useOrderWindowSettings();
  const [valuationList, setValuationList] = useState(null);
  const [inFlight, setInFlight] = useState(false);

  const context = useMemo(() => ({ valuationList }), []);
  context['valuationList'] = valuationList;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      ...valuation.toJS(),
      [VALUATION_AMOUNT]:
        !valuation.deductible && !valuation.cost
          ? valuation.amount || valuation.defaultLiabilityAmount
          : valuation.amount,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
    context,
  });
  const valuationType = watch(TYPE);
  const selectedPlanIndex = watch(SELECTED_PLAN_INDEX);
  const valuationAmount = watch(VALUATION_AMOUNT);
  const defaultLiabilityAmount = watch(DEFAULT_LIABILITY_AMOUNT);
  const debouncedValuedAmount = useDebounce(valuationAmount, 500);
  const titleBasicValuation = getValuationSetting(settings, !isLocal, moveType, 'titleBasicValuation');
  const titleFullValueProtection = getValuationSetting(
    settings,
    !isLocal,
    moveType,
    'titleFullProtection',
    defaultTitleFullValueProtection,
  );
  const explainBasicValuation = getValuationSetting(settings, !isLocal, moveType, 'explainBasicValuation');
  const explainFullProtection = getValuationSetting(settings, !isLocal, moveType, 'explainFullProtection');
  const titleThirdOption = getValuationSetting(settings, !isLocal, moveType, 'titleThirdOption');
  const explainThirdOption = getValuationSetting(settings, !isLocal, moveType, 'explainThirdOption');
  const parsedErrors = getFormErrorMessages(errors);

  useEffect(() => {
    if (valuationStatusErrors[valuation.status]) {
      trigger();
    }
  }, [trigger]);

  useUpdateEffect(() => {
    trigger(SELECTED_PLAN_INDEX);
  }, [valuationType]);

  const getValuationChoicesForValuedAmount = (valuedAmount) => {
    setInFlight(true);

    ValuationAPI.getValuationChoicesForValuedAmount(
      orderId,
      defaultLiabilityAmount !== valuedAmount ? valuedAmount : null,
    )
      .then((data) => {
        const { choices } = data;
        setValuationList(choices);
        const index = (choices || []).findIndex((item) => item.deductible === valuation.deductible);
        setValue(SELECTED_PLAN_INDEX, index > -1 ? index : null, { shouldValidate: true });
        setValue(MAXIMUM_VALUATED_AMOUNT, data.maximumValuedAmount, { shouldValidate: true });
        setValue(STATUS, data[STATUS], { shouldValidate: true });

        if (defaultLiabilityAmount) {
          trigger(VALUATION_AMOUNT);
        } else {
          setValue(VALUATION_AMOUNT, data.valuedAmount || 0);
          setValue(DEFAULT_LIABILITY_AMOUNT, data.valuedAmount || 0);
        }
      })
      .catch(() => {})
      .then(() => setInFlight(false));
  };

  const updateOrderWithValuationChoice = (data) => {
    const selectedValuationChoice = valuationList?.[data?.[SELECTED_PLAN_INDEX]];
    const valuationChoiceToSave =
      data[TYPE] === ValuationType.FULL
        ? {
            cost: selectedValuationChoice.cost,
            amount: debouncedValuedAmount,
            deductible: selectedValuationChoice.deductible,
          }
        : {};

    const newValuation = { ...valuation.toJS(), ...data, ...valuationChoiceToSave };
    onSave(new ValuationEntity(newValuation));
  };

  useEffect(() => {
    if (moveType) {
      getValuationChoicesForValuedAmount(debouncedValuedAmount || 0);
    }
  }, [debouncedValuedAmount]);

  const valuationPlanClickHandler = (index) => () => setValue(SELECTED_PLAN_INDEX, index, { shouldValidate: true });
  const setDefaultAmountLiability = () => setValue(VALUATION_AMOUNT, defaultLiabilityAmount);

  const options = [
    [ValuationType.BASIC, titleBasicValuation || '', explainBasicValuation],
    [ValuationType.FULL, titleFullValueProtection || '', explainFullProtection],
    [ValuationType.THIRD_OPTION, titleThirdOption, explainThirdOption],
  ];

  return (
    <Modal
      open={open}
      title="Valuation"
      color="grey"
      maxWidth="xs"
      fullWidth
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        {
          label: 'save',
          onClick: handleSubmit(updateOrderWithValuationChoice),
          disabled: inFlight || isCompleted || isLockSales,
        },
      ]}
      size="small"
    >
      <Box mb={2}>
        <RadioGroup aria-label="valuation-setting" name={TYPE} control={control}>
          {options.map(([key, title, explanation]) => (
            <React.Fragment key={key}>
              <Radio
                value={key}
                color="primary"
                disabled={isCompleted || isLockSales}
                label={
                  <>
                    <Box display="flex" alignItems="center">
                      <Box>{title}</Box>
                      {explanation && (
                        <Box alignSelf="flex-start" ml={1}>
                          <Tooltip title={<BodyText>{explanation}</BodyText>}>
                            <HelpOutlineIcon color="primary" />
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </>
                }
              />
              {key === ValuationType.FULL && valuationType === ValuationType.FULL && (
                <>
                  <Box display="flex" ml={4} mb={2} width={200}>
                    <CurrencyInput
                      disabled={isCompleted || isLockSales}
                      label="Liability"
                      name={VALUATION_AMOUNT}
                      control={control}
                    />
                    {valuationAmount !== valuation.defaultLiabilityAmount && (
                      <Box mr={-5} display="flex">
                        <Tooltip title="Calculate liability amount" arrow>
                          <Box display="flex">
                            <Button
                              disabled={isCompleted || isLockSales}
                              variant="text"
                              onClick={setDefaultAmountLiability}
                            >
                              <CalculatorIcon />
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box ml={4} width={350}>
                    <Grid container>
                      {parsedErrors[SELECTED_PLAN_INDEX] && (
                        <Grid item sm={12}>
                          <Alert severity="error">{parsedErrors[SELECTED_PLAN_INDEX]}</Alert>
                        </Grid>
                      )}
                      <Grid item sm={3}>
                        <BodyText>
                          <b>Deductible</b>
                        </BodyText>
                      </Grid>
                      <Grid item sm={5}>
                        <BodyText>
                          <b>Valuation Charge</b>
                        </BodyText>
                      </Grid>
                      <Grid item sm={4} />
                      {valuationList &&
                        valuationList.map((listItem, index) => (
                          <React.Fragment key={listItem.deductible}>
                            <GridItem item sm={3}>
                              <BodyText>{`$${formatCurrency(listItem.deductible)}`}</BodyText>
                            </GridItem>
                            <GridItem item sm={5}>
                              <BodyText>{`$${formatCurrency(listItem.cost)}`}</BodyText>
                            </GridItem>
                            <GridItem item sm={4}>
                              <Button
                                disabled={isCompleted || isLockSales}
                                size="small"
                                rounded
                                onClick={valuationPlanClickHandler(index)}
                                color={selectedPlanIndex === index ? 'secondary' : 'primary'}
                              >
                                SELECT
                              </Button>
                            </GridItem>
                          </React.Fragment>
                        ))}
                    </Grid>
                  </Box>
                </>
              )}
            </React.Fragment>
          ))}
        </RadioGroup>
      </Box>
    </Modal>
  );
};

Valuation.propTypes = {
  orderId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  valuation: PropTypes.object.isRequired,
  moveType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isLocal: PropTypes.bool.isRequired,
};

export default Valuation;
