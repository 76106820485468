import { CrewLogStatus } from 'admin/components/CrewStatusLog/types';

export const firstTwoStatuses = [CrewLogStatus.EN_ROUTE, CrewLogStatus.ARRIVED];
export const finishStatuses = [CrewLogStatus.PAYMENT, CrewLogStatus.COMPLETED];
export const timeStatuses = [
  CrewLogStatus.UNLOADING,
  CrewLogStatus.LOADING,
  CrewLogStatus.TO_DESTINATION,
  CrewLogStatus.UNLOADING_STARTED,
  CrewLogStatus.UNLOADING_COMPLETED,
];
