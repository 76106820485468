import { Dispatch, MutableRefObject, SetStateAction, createContext } from 'react';

interface OrderWindowUnreadMessagesContextProps {
  unreadMessages: number;
  setUnreadMessages: Dispatch<SetStateAction<number>>;
  reloadUnreadMessages: () => void;
  messageRef: MutableRefObject<HTMLDivElement | null>;
}

const UnreadMessagesContext = createContext<OrderWindowUnreadMessagesContextProps>(
  {} as OrderWindowUnreadMessagesContextProps,
);

export { UnreadMessagesContext };
