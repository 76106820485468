import { FC, useState } from 'react';
import { OrderWindowEditModalContext } from './OrderWindowEditModalContext';
import { OrderWindowModal } from 'common-types';
import { Set } from 'immutable';

const OrderWindowEditModalProvider: FC = ({ children }) => {
  const [openEditModal, setOpenEditModal] = useState(Set<OrderWindowModal>());

  return (
    <OrderWindowEditModalContext.Provider value={{ openEditModal, setOpenEditModal }}>
      {children}
    </OrderWindowEditModalContext.Provider>
  );
};

export { OrderWindowEditModalProvider };
