const START_ADDRESS_TITLE = 'Start Address';
const DESTINATION_ADDRESS_TITLE = 'Destination Address';
const PICKUP_ADDRESS_TITLE = 'Pickup Address';
const DROP_OFF_ADDRESS = 'Drop-Off Address';

const THIS_FUNCTIONAL_IS_COMING_SOON = 'This functionality is coming soon';

export {
  START_ADDRESS_TITLE,
  DESTINATION_ADDRESS_TITLE,
  PICKUP_ADDRESS_TITLE,
  DROP_OFF_ADDRESS,
  THIS_FUNCTIONAL_IS_COMING_SOON,
};
