import React, { FC, useMemo } from 'react';

// @ts-ignore
import * as Dropzone from 'dropzone';
import { BodyText } from '@elromcoinc/react-shared';
import DropzoneComponent, { DropzoneComponentConfig, FileCallback } from 'react-dropzone-component';
import styled from 'styled-components';

const StyledDropzone = styled(DropzoneComponent as any)`
  border: 1px dashed;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  margin-bottom: 16px;
`;

const djsConfig = {
  createImageThumbnails: false,
  autoProcessQueue: false,
  previewTemplate: '<div />',
  acceptedFiles: ['image/jpeg,image/png,image/gif'],
};

const componentConfig = {
  iconFiletypes: ['.jpg', '.png', '.gif'],
  showFiletypeIcon: true,
  postUrl: 'no-url',
};

interface DropZoneProps {
  addedfile?: FileCallback | FileCallback[];
  init?: (dropzone: Dropzone) => any;
  text?: string;
  config?: DropzoneComponentConfig;
  updatedDjsConfig?: Dropzone.DropzoneOptions;
}

const DropZone: FC<DropZoneProps> = ({
  addedfile,
  init,
  children = null,
  text = 'Drop or click here to upload company logo (preferred size: 130px in height)',
  config,
  updatedDjsConfig,
}) => {
  const eventHandlers = {
    addedfile,
    init,
  };
  const c = useMemo(() => ({ ...componentConfig, ...config }), []);
  const djsc = useMemo(() => ({ ...djsConfig, ...updatedDjsConfig }), []);

  return (
    <>
      <StyledDropzone config={c} eventHandlers={eventHandlers} djsConfig={djsc} data-testId="dropZone">
        <BodyText>{text}</BodyText>
      </StyledDropzone>
      {children}
    </>
  );
};

export { DropZone };
