import { ActivitySourceType } from '@elromcoinc/react-shared';
import autodux from 'autodux';
import { set } from 'immutable';

import communicationAPI from 'admin/api/CommunicationsAPI';
import smsAPI from 'admin/api/SmsAPI';
import { SmsSettingsAvailabilityTypeName } from 'admin/constants/SmsSettingsAvailabilityType';
import { SmsStatusType } from 'admin/constants/SmsStatusType';

const {
  reducer,
  actions: {
    setUnreadCount,
    setSmsSettings,
    setIsDisabledSms,
    setTwilioAccount,
    updateIsDisabledSms,
    setUnreadOrderMessagesCount,
    setUnreadAccountMessagesCount,
  },
  selectors: {
    getUnreadCount,
    getSmsSettings,
    getIsDisabledSms,
    getTwilioAccount,
    getUnreadOrderMessagesCount,
    getUnreadAccountMessagesCount,
  },
} = autodux({
  slice: 'message',
  initial: {
    unreadCount: 0,
    unreadOrderMessagesCount: 0,
    unreadAccountMessagesCount: 0,
    smsSettings: {},
    isDisabledSms: true,
    twilioAccount: null,
  },
  actions: {
    updateIsDisabledSms: (state) =>
      set(
        state,
        'isDisabledSms',
        !state.smsSettings[SmsSettingsAvailabilityTypeName.SMS_ENABLE] ||
          state.twilioAccount.twilioPhoneNumbers.length === 0,
      ),
  },
});

const fetchUnreadCount = () => (dispatch) => {
  return Promise.all([
    communicationAPI.getTotalEmployeeUnseenMessages().promise,
    communicationAPI.getTotalEmployeeUnseenMessages({
      'activitySources.activitySource': ActivitySourceType.CUSTOMER_ACCOUNT,
    }).promise,
  ]).then((data) => {
    dispatch(setUnreadCount((data || []).reduce((acc, i) => acc + i, 0)));
    dispatch(setUnreadOrderMessagesCount(data[0] || 0));
    dispatch(setUnreadAccountMessagesCount(data[1] || 0));
    return data;
  });
};

const fetchSmsSettings = () => (dispatch) =>
  Promise.all([smsAPI.getAllSmsSettings(), smsAPI.getRegisteredTwilioAccount()]).then(
    ([smsSettings, twilioAccount]) => {
      dispatch(setSmsSettings(smsSettings));
      dispatch(
        setIsDisabledSms(
          !smsSettings[SmsSettingsAvailabilityTypeName.SMS_ENABLE] ||
            twilioAccount.twilioPhoneNumbers.filter((item) => item.status === SmsStatusType.ACTIVE).length === 0,
        ),
      );
      dispatch(
        setTwilioAccount({
          ...twilioAccount,
          twilioPhoneNumbers: twilioAccount.twilioPhoneNumbers.filter((item) => item.status === SmsStatusType.ACTIVE),
        }),
      );
    },
  );

export {
  fetchUnreadCount,
  getUnreadCount,
  fetchSmsSettings,
  getSmsSettings,
  getIsDisabledSms,
  setSmsSettings,
  getTwilioAccount,
  setTwilioAccount,
  updateIsDisabledSms,
  getUnreadOrderMessagesCount,
  getUnreadAccountMessagesCount,
};

export default reducer;
