import React, { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';

import {
  BodyBigText,
  Order,
  TextInput,
  isLongDistanceService,
  longDistanceMethods,
  roundNumberToFixedDigits,
} from '@elromcoinc/react-shared';
import { Box, Paper, createStyles, makeStyles } from '@material-ui/core';
import { FilledInputProps } from '@material-ui/core/FilledInput';
import Grid from '@material-ui/core/Grid';

import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
} from 'admin/components/OrderWindow/context';
import useOrderSizing from 'admin/components/OrderWindow/hooks/useOrderSizing';
import { statusColor } from 'admin/constants/OrderStatus';

const useStyles = makeStyles(() =>
  createStyles({
    moveSize: {
      padding: '10px',
      marginBottom: '20px',
      width: '200px',
    },
    moveSizeInput: {
      '& .MuiInputBase-input': {
        fontSize: '22px',
        textAlign: 'center',
      },
    },
  }),
);

const cuftName = 'overriddenCuFt';
const weightName = 'overriddenWeight';

interface MoveSizeProps {
  order: Order;
}
const ClosingMoveSize: FC<MoveSizeProps> = ({ order }) => {
  const { serviceIndex } = useOrderServiceIndex();
  const { isClosing, isCompleted } = useOrderClosingContext();
  const classes = useStyles({ statusColor: statusColor('BOOKED') });
  const { onChange: onChangeMoveSize } = useOrderChangeSet();
  const service = isClosing ? order.closingOrderDetail.services.get(serviceIndex) : order.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const isLongDistanceMoveSize =
    isLongDistanceService(order?.getServiceType()) &&
    order?.getServiceQuote()?.longDistanceTariffDetails?.calculationMethod === longDistanceMethods.MILEAGE;
  const isLongDistanceAndClosing = isLongDistanceMoveSize && isClosing;
  const [weightInputInFocus, setWeightInputInFocus] = useState(false);
  const { manualWeight, manualCuFt, setManualWeight, setManualCuFt, cuFtToPounds } = useOrderSizing(order!);
  const { moveUnitLabel } = useOrderSettingUnits();
  const disableInputs = (isClosing && !isLongDistance) || isCompleted;
  const currentOverrideCuFt = manualCuFt ?? 0;
  const currentOverrideWeight = manualWeight ?? 0;

  const onFocusCuft: FilledInputProps['onFocus'] = ({ target }) => target.select();
  const onFocusPounds: FilledInputProps['onFocus'] = ({ target }) => {
    target.select();
    setWeightInputInFocus(true);
  };

  const handleCustomValueChanges =
    (setter: Dispatch<SetStateAction<number | null>>) => (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value, name },
      } = event;

      const numberValue = +value
        .toString()
        .split('.')
        .map((el, i) => (i ? el.split('').slice(0, 2).join('') : el))
        .join('.');

      if (+value && numberValue < 0) {
        return;
      }

      if (isLongDistanceAndClosing) {
        if (name === cuftName) {
          setManualWeight(roundNumberToFixedDigits(numberValue * cuFtToPounds, 2));
        } else {
          setManualCuFt(roundNumberToFixedDigits(numberValue / cuFtToPounds, 2));
        }
      }

      setter(numberValue);
    };

  const handleOnSave = () => {
    if (isLongDistanceAndClosing) {
      [
        { name: 'closingOrderDetail.manualActiveCuFt', value: currentOverrideCuFt },
        { name: 'closingOrderDetail.manualActiveWeight', value: currentOverrideWeight },
      ].forEach(onChangeMoveSize);
    }
  };

  return (
    <Paper className={classes.moveSize}>
      <Box p={1}>
        <BodyBigText>
          <b>CuFt/Lbs</b>
        </BodyBigText>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            className={classes.moveSizeInput}
            type="number"
            fullWidth
            autoFocus
            onFocus={onFocusCuft}
            onBlur={handleOnSave}
            name={cuftName}
            step="1.0"
            lang="en-US"
            shrink
            label={moveUnitLabel}
            value={currentOverrideCuFt}
            onChange={handleCustomValueChanges(setManualCuFt)}
            disabled={disableInputs}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            className={classes.moveSizeInput}
            type="number"
            shrink
            fullWidth
            label="Pounds"
            step="1.0"
            lang="en-US"
            onFocus={onFocusPounds}
            name={weightName}
            onBlur={() => {
              handleOnSave();
              setWeightInputInFocus(false);
            }}
            value={
              weightInputInFocus && currentOverrideWeight !== null
                ? currentOverrideWeight || 0
                : currentOverrideWeight || (currentOverrideCuFt ?? 0) * (cuFtToPounds ?? 0) || 0
            }
            onChange={handleCustomValueChanges(setManualWeight)}
            disabled={disableInputs}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClosingMoveSize;
