import React, { useCallback, useEffect } from 'react';

import { PaymentCashDto, PaymentStatus } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { AMOUNT, NOTES, PAID_DATE, labels } from '../CreateCharge/Common';
import { CashPaymentFormInputs } from '../Forms';

export interface RefundCashPaymentDto {
  amount: number;
  message: string;
  date: Date;
}

const schema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

interface EditCashPaymentProps {
  payment: PaymentCashDto;
  onMount?: (trigger: () => Promise<any>) => void;
}

const RefundCashPayment = ({ payment, onMount }: EditCashPaymentProps) => {
  const methods = useForm<RefundCashPaymentDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      [AMOUNT]: payment.amount,
      [PAID_DATE]: new Date(),
    },
  });

  const createCashPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: RefundCashPaymentDto) => {
          paymentActionsApi
            .createCashPayment({
              ...payment,
              date: formatISO(data[PAID_DATE]),
              amount: data[AMOUNT],
              message: data[NOTES],
              status: PaymentStatus.REFUND,
              filePhotoId: null,
              fileSignatureId: null,
            })
            .then(resolve)
            .catch(reject);
        })();
      }),
    [methods.handleSubmit],
  );

  useEffect(() => {
    onMount?.(createCashPayment);
  }, [createCashPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <CashPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { RefundCashPayment };
