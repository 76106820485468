import React from 'react';

import { Order, Waypoint, isLoadingOrPackingService, isUnloadingService } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import {
  useOrderClosingContext,
  useOrderState,
  useOrderWindowEditAddressIndex,
} from 'admin/components/OrderWindow/context';
import { FullAddressesModal } from 'admin/components/OrderWindow/modals/FullAddressesModal';

interface EditWaypointModalProps {
  onSave: (waypoint: Waypoint, index?: number) => void;
}

export const EditWaypointModal = ({ onSave }: EditWaypointModalProps) => {
  const { order } = useOrderState() as { order: Order };
  const { editWaypoint, setEditWaypoint } = useOrderWindowEditAddressIndex();
  const { isClosing } = useOrderClosingContext();
  const isEdit = editWaypoint?.waypointIndex! >= 0;
  const serviceType = order.getServiceType(editWaypoint?.serviceIndex);
  const waypointBasedOnServiceType = ((isClosing
    ? order.closingOrderDetail!
    : order
  )?.getAddressWaypointsBasedOnService(editWaypoint?.serviceIndex) ?? List()) as List<Waypoint>;

  return (
    editWaypoint && (
      <FullAddressesModal
        open
        onSave={onSave}
        onCancel={() => setEditWaypoint(null)}
        isStart={editWaypoint.waypointIndex === waypointBasedOnServiceType?.first()?.waypointIndex}
        isDestination={editWaypoint.waypointIndex === waypointBasedOnServiceType?.last()?.waypointIndex}
        hideStopType={isUnloadingService(serviceType) || isLoadingOrPackingService(serviceType)}
        waypoint={editWaypoint}
        isEdit={isEdit}
      />
    )
  );
};
