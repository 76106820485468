import { TaskStatus } from 'common-types/Task';

const STATUS = 'status';
const EMPLOYEE_ID = 'employeeId.id';
const TaskStatusLabel = {
  [TaskStatus.UNREAD]: 'Unread',
  [TaskStatus.READ]: 'Read',
  [TaskStatus.COMPLETED]: 'Completed',
};

export type TaskFilters = {
  [STATUS]?: TaskStatus[];
  [EMPLOYEE_ID]?: number;
};

export { TaskStatusLabel, STATUS, EMPLOYEE_ID };
