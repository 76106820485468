export enum CrewLogStatus {
  EN_ROUTE = 'EN_ROUTE',
  ARRIVED = 'ARRIVED',
  LOADING = 'LOADING',
  TO_DESTINATION = 'TO_DESTINATION',
  UNLOADING = 'UNLOADING',
  UNLOADING_STARTED = 'UNLOADING_STARTED',
  UNLOADING_COMPLETED = 'UNLOADING_COMPLETED',
  PAYMENT = 'PAYMENT',
  COMPLETED = 'COMPLETED',
}
