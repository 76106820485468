import { DispatchTruckDto } from '@elromcoinc/moveboard-setting-react';
import axios from 'axios';

class DispatchTrucksApi {
  toggleEnable(enable: boolean) {
    return axios.put(`/api/dispatch/truck/settings/${enable ? 'enabled' : 'disable'}`, {});
  }

  getDispatchTrucks() {
    return axios.get<DispatchTruckDto[], DispatchTruckDto[]>(`/api/dispatch/truck`, {
      data: {},
    });
  }

  createDispatchTruck(data: DispatchTruckDto) {
    return axios.post<DispatchTruckDto, DispatchTruckDto>(`/api/dispatch/truck`, data);
  }

  updateDispatchTruck(data: DispatchTruckDto) {
    return axios.put<DispatchTruckDto, DispatchTruckDto>(`/api/dispatch/truck`, data);
  }

  deleteDispatchTruck(id: number) {
    return axios.delete(`/api/dispatch/truck/${id}`, {
      data: {},
    });
  }

  isAssociatedWithCrew(id: number) {
    return axios.get<boolean, boolean>(`/api/dispatch/truck/${id}/isAssociatedWithCrew`, {
      data: {},
    });
  }
}

export default new DispatchTrucksApi();
