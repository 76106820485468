import { MutableRefObject, useEffect } from 'react';

function useScrollTo7Am(ref: MutableRefObject<HTMLElement | null>) {
  useEffect(() => {
    if (ref.current?.childNodes) {
      const nodes = ref.current.childNodes[0].childNodes;
      if (nodes.length > 7) {
        // @ts-ignore eslint-disable-next-line no-param-reassign
        ref.current.scrollLeft = nodes[7]?.offsetLeft - nodes[0]?.offsetLeft;
      }
    }
  }, [ref.current]);
}

export default useScrollTo7Am;
