import { ActivitySourceType } from '@elromcoinc/react-shared';

export enum ConversationMessageType {
  IN_APP = 'IN_APP',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum NotificationInfoType {
  CHANGED_ARRIVAL_WINDOW = 'CHANGED_ARRIVAL_WINDOW',
}

export interface NotificationStatusDto {
  id: number | null;
  notificationType: ConversationMessageType;
  source: ActivitySourceType;
  sourceId: number;
  sent: boolean;
  notificationInfoType: NotificationInfoType;
}
