import React, { useState } from 'react';

import { Modal } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import smsAPI from 'admin/api/SmsAPI';
import { SelectPhoneNumberForm } from 'admin/components/Settings/components/SmsSettings/forms';
import { SmsStatusType } from 'admin/constants/SmsStatusType';

export const BuyPhoneNumberModal = ({ handleCloseModal }) => {
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data) => {
    setIsDisabledInProcessing(true);
    smsAPI
      .buyTwilioPhoneNumber({
        phoneNumber: data.phoneNumber,
      })
      .then((res) => {
        enqueueSnackbar('Phone number purchase success', { variant: 'info' });
        handleCloseModal({ sid: res.data, status: SmsStatusType.ACTIVE, ...data });
      })
      .catch((response) => {
        enqueueSnackbar(
          response?.response?.data?.errors?.[0]?.message ??
            `Phone number purchase failed; number ${data.phoneNumber} may already be in use or reserved`,
          {
            variant: 'error',
          },
        );
      })
      .then(() => {
        setIsDisabledInProcessing(false);
      });
  };

  return (
    <Modal open title="Buy phone number" onClose={handleCloseModal} disabledInProcessing={isDisabledInProcessing}>
      <Box p="0 30px" display="flex" flexDirection="column" justifyContent="space-between">
        Please enter the area code of the number you wish to buy
        <SelectPhoneNumberForm onSubmit={onSubmit} defaultValues={{}} isDisabledInProcessing={isDisabledInProcessing} />
      </Box>
    </Modal>
  );
};

BuyPhoneNumberModal.propTypes = {
  handleCloseModal: pt.func.isRequired,
};
