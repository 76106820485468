import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { List } from 'immutable';

import { Spacer } from 'admin/components/Calendar/common';
import Job from 'admin/components/OrderWindow/SchedulerBox/Job';
import { TruckRow } from 'admin/components/OrderWindow/SchedulerBox/common/Rows';

interface RenderTruckNameProps {
  onTruckClick: (truckId: number, d: Date) => void;
  dense: boolean;
  truckJobs: { [key: string]: Job[] };
  selectedTrucks?: List<number>;
  selectedDeliveryTrucks?: List<number>;
  date: Date;
}

export const renderTruckName =
  ({
    onTruckClick,
    dense,
    truckJobs,
    selectedTrucks = List(),
    selectedDeliveryTrucks = List(),
    date,
  }: RenderTruckNameProps) =>
  (truck: any, idx: number) => {
    const isUnassignedTruck = truck.capacity === 0;

    return (
      <TruckRow
        key={truck.id}
        grey={idx % 2 === 0}
        dense={dense}
        $width={150}
        $selected={selectedTrucks.indexOf(truck.id) >= 0 || selectedDeliveryTrucks.indexOf(truck.id) >= 0}
        heightSize={
          isUnassignedTruck ? Math.max(...(truckJobs[truck.id] || []).map((it: any) => it.level[truck.id])) : 0
        }
      >
        <Spacer
          actionable
          onClick={() => {
            onTruckClick(truck.id, date);
          }}
        >
          <Box textAlign="center">
            <BodyText className="truck-name" data-testid={truck.name}>
              {truck.name}
            </BodyText>
          </Box>
        </Spacer>
      </TruckRow>
    );
  };
