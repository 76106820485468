import React, { FC, SetStateAction } from 'react';

import { Main } from 'admin/components/OrderWindow/Main';
import { OrderWindowProvider } from 'admin/components/OrderWindow/context';
import { ActivityWindow } from 'common-types';

interface OrderWindowMainProviderProps {
  orderId: number;
  minimized: boolean;
  onMinimize: () => void;
  onClose: () => void;
  width: string;
  zIndex: number | null;
  offsetCoefficient: number;
  onSelectWindow: SetStateAction<ActivityWindow | null>;
}

const OrderWindowMainProvider: FC<OrderWindowMainProviderProps> = (props) => {
  return (
    <OrderWindowProvider sourceId={props.orderId}>
      <Main {...props} />
    </OrderWindowProvider>
  );
};

export { OrderWindowMainProvider };
