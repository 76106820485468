import {
  ActivitySourceType,
  Address,
  IActivityDto,
  OnlinePaymentTransactionDto,
  PaymentActivityDto,
  PaymentCashDto,
  PaymentCheckDto,
  PaymentCreditCardRecordDto,
  PaymentCustomDto,
  PaymentStatus,
} from '@elromcoinc/react-shared';
import axios from 'axios';

export interface PaymentSearchTerms {
  'order.id'?: number;
  'account.id'?: number;
  'activitySources.activitySource'?: ActivitySourceType;
  'activitySources.sourceId'?: number;
}

export interface CashPaymentRequest extends Pick<IActivityDto, 'activitySource' | 'sourceId' | 'activitySources'> {
  date: Date | string;
  amount: string | number;
  message: string | undefined | null;
  type: 'REGULAR' | 'DEPOSIT';
  status: string;
}
export interface CreditCardPaymentRequest extends Omit<CashPaymentRequest, 'date' | 'status'> {
  paymentToken: string;
  customerId?: number;
  email?: string;
  billingAddress?: { postalCode: string };
  cardholderName: string;
  expiration: string;
  lastFourDigits: string;
  paymentDescription: string;
}
export interface ExistingCreditCardPaymentRequest extends Omit<CashPaymentRequest, 'date' | 'status'> {
  customerId: number;
  paymentDescription: string;
}

interface PaymentPageResponse {
  pageElements: PaymentActivityDto[];
  pageIndex: number;
  totalElements: number;
  totalPages: number;
}

export interface PaymentProfileDto {
  id: number;
  firstName: string;
  lastName: string;
  postalCode: string;
  lastFourDigits: string;
  cardType: string;
  expiration: string;
}

export interface CustomerPaymentProfileDto {
  id: number;
  email: string;
  defaultPaymentMethodId?: number;
  paymentMethods: PaymentProfileDto[];
}
export interface CreditCardRefundDto {
  paymentId: number;
  amount: string | number;
  note: string;
}

export interface UpdatePaymentMethodDto {
  firstName?: string;
  lastName?: string;
  expiration?: string;
  billingAddress?: Partial<Address>;
}

export interface AddPaymentMethodDto {
  paymentToken: string;
  lastFourDigits: string;
  cardholderName: string;
  expiration: string;
  email?: string;
  billingAddress?: Partial<Address>;
  makeDefault?: boolean;
  cardType?: string;
}

class PaymentApi {
  createCashPayment(dto: PaymentCashDto) {
    return axios.post<PaymentCashDto, PaymentCashDto>('/api/payments/activity/cash', dto);
  }
  updateCashPayment(paymentId: number, dto: PaymentCashDto) {
    return axios.put<PaymentCashDto, PaymentCashDto>(`/api/payments/activity/cash/${paymentId}`, dto);
  }
  createCheckPayment(dto: PaymentCheckDto) {
    return axios.post<PaymentCheckDto, PaymentCheckDto>('/api/payments/activity/check', dto);
  }
  updateCheckPayment(paymentId: number, dto: PaymentCheckDto) {
    return axios.put<PaymentCheckDto, PaymentCheckDto>(`/api/payments/activity/check/${paymentId}`, dto);
  }
  createCreditCardRecordPayment(dto: PaymentCreditCardRecordDto) {
    return axios.post<PaymentCreditCardRecordDto, PaymentCreditCardRecordDto>(
      '/api/payments/activity/credit-card-record',
      dto,
    );
  }
  updateCreditCardRecordPayment(paymentId: number, dto: PaymentCreditCardRecordDto) {
    return axios.put<PaymentCreditCardRecordDto, PaymentCreditCardRecordDto>(
      `/api/payments/activity/credit-card-record/${paymentId}`,
      dto,
    );
  }
  processNewCardPayment(dto: CreditCardPaymentRequest) {
    return axios.post<OnlinePaymentTransactionDto, OnlinePaymentTransactionDto>('/api/payments/activity/cc', dto);
  }
  updateStatus(paymentId: number, status: PaymentStatus) {
    return axios.put<PaymentActivityDto, PaymentActivityDto>(`/api/payments/activity/${paymentId}/status/${status}`);
  }
  updateNoteOnCreditCardPayment(paymentId: number, note: string) {
    return axios.put<OnlinePaymentTransactionDto, OnlinePaymentTransactionDto>(
      `/api/payments/activity/cc/payment/${paymentId}/note`,
      note,
    );
  }
  chargePaymentMethod(paymentMethodId: number, dto: ExistingCreditCardPaymentRequest) {
    return axios.post<OnlinePaymentTransactionDto, OnlinePaymentTransactionDto>(
      `/api/payments/activity/cc/customer/${dto.customerId}/payment-method/${paymentMethodId}/charge`,
      dto,
    );
  }

  getCustomerPaymentProfile(customerId: number) {
    return axios.get<CustomerPaymentProfileDto, CustomerPaymentProfileDto>(
      `/api/payments/activity/cc/customer/${customerId}/payment-methods`,
    );
  }

  addPaymentMethod(customerId: number, dto: AddPaymentMethodDto) {
    return axios.post<CustomerPaymentProfileDto, CustomerPaymentProfileDto>(
      `/api/payments/activity/cc/customer/${customerId}/payment-method`,
      dto,
    );
  }

  updatePaymentMethod(customerId: number, paymentMethodId: number, dto: UpdatePaymentMethodDto) {
    return axios.put<PaymentProfileDto, PaymentProfileDto>(
      `/api/payments/activity/cc/customer/${customerId}/payment-method/${paymentMethodId}`,
      dto,
    );
  }

  setDefaultPaymentMethod(customerId: number, paymentMethodId: number) {
    return axios.post<CustomerPaymentProfileDto, CustomerPaymentProfileDto>(
      `/api/payments/activity/cc/customer/${customerId}/payment-method/${paymentMethodId}/default`,
    );
  }

  deletePaymentMethod(customerId: number, paymentMethodId: number) {
    return axios.delete<boolean, boolean>(
      `/api/payments/activity/cc/customer/${customerId}/payment-method/${paymentMethodId}`,
    );
  }

  isCreditCardPaymentVoidable(paymentId: number) {
    return axios.get<boolean, boolean>(`/api/payments/activity/cc/payment/${paymentId}/voidable`);
  }

  voidCreditCardPayment(paymentId: number) {
    return axios.post<OnlinePaymentTransactionDto, OnlinePaymentTransactionDto>(
      `/api/payments/activity/cc/payment/${paymentId}/void`,
    );
  }

  refundCreditCard(dto: CreditCardRefundDto) {
    return axios.post<OnlinePaymentTransactionDto, OnlinePaymentTransactionDto>(
      `/api/payments/activity/cc/payment/${dto.paymentId}/refund`,
      dto,
    );
  }

  searchPayments(searchTerms: PaymentSearchTerms) {
    return axios.get<PaymentPageResponse, PaymentPageResponse>('/api/payments/activity/search', {
      params: searchTerms,
    });
  }

  createCustomPayment(dto: PaymentCustomDto) {
    return axios.post<PaymentCustomDto, PaymentCustomDto>('/api/payments/activity/custom', dto);
  }

  updateCustomPayment(paymentId: number, dto: PaymentActivityDto) {
    return axios.put<PaymentActivityDto, PaymentActivityDto>(`/api/payments/activity/custom/${paymentId}`, dto);
  }

  setBOLActivitySourcesOnPayment(paymentId: number, activitySources: IActivityDto['activitySources']) {
    return axios.put<PaymentActivityDto, PaymentActivityDto>(
      `/api/payments/activity/${paymentId}/toggle-activity-sources`,
      activitySources,
    );
  }
}

export default new PaymentApi();
