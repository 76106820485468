import React, { useState } from 'react';

import * as chartjs from 'chart.js';
import { ElromcoCircularProgress, IconButton } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles, useTheme } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Context } from 'chartjs-plugin-datalabels';
import { ChartData, HorizontalBar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { getDashboardStats, getInFlightStatistics } from 'admin/autodux/DashboardAutodux';
import DashboardCardTitle from 'admin/components/Dashboard/DashboardCardTitle';
import { SelectedPage, firstPage, maximumItemsInStatistics } from 'admin/components/Dashboard/DashboardLabel';
import { getPagination } from 'common/utils/getPagination';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '320px',
    position: 'relative',
    marginBottom: '20px',

    '&.MuiPaper-rounded': {
      borderRadius: spacing(0.75),
    },
  },
  arrowButton: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',
    top: '-45px',
  },
}));

const options: chartjs.ChartOptions = {
  layout: {
    padding: {
      left: 25,
      right: 30,
      top: 30,
      bottom: 35,
    },
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          suggestedMin: 50,
          fontSize: 16,
          stepSize: 1,
        },
        gridLines: {
          drawOnChartArea: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
          fontSize: 16,
        },
        gridLines: {
          drawOnChartArea: false,
        },
      },
    ],
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: (context: Context) => {
        return (context?.dataset?.data?.[context.dataIndex] ?? 0) > 0;
      },
    },
  },
};

const bookedColor = '#5DCE83';

const DashboardReferralSources = () => {
  const theme = useTheme();
  const classes = useStyles();
  const reservedColor = theme.palette.primary.main;
  const [pageNumber, setPageNumber] = useState<number>(firstPage);
  const dashboard = useSelector(getDashboardStats);
  const [selectedPeriod] = dashboard.selectedPeriod.split('/');

  const isFetching = useSelector(getInFlightStatistics);

  const handleChangePageNumber = (selectPage: SelectedPage) => () => {
    selectPage === SelectedPage.NEXT_PAGE
      ? setPageNumber((prevPageNumber) => prevPageNumber + 1)
      : setPageNumber((prevPageNumber) => prevPageNumber - 1);
  };

  const referralSources = dashboard.current.referralStats ?? [];

  const { items, previousPage, nextPage } = getPagination(referralSources, maximumItemsInStatistics, pageNumber);

  const getChartData = (): ChartData<chartjs.ChartData> => {
    return {
      labels: items.map(({ sourceType }) => sourceType),
      datasets: [
        {
          label: 'Orders Received',
          backgroundColor: reservedColor,
          borderColor: reservedColor,
          minBarLength: 2,
          maxBarThickness: 34,
          hoverBackgroundColor: reservedColor,
          hoverBorderColor: reservedColor,
          data: items.map(({ received }) => received),
        },
        {
          label: 'Orders Booked',
          backgroundColor: bookedColor,
          borderColor: bookedColor,
          minBarLength: 2,
          maxBarThickness: 34,
          hoverBackgroundColor: bookedColor,
          hoverBorderColor: bookedColor,
          data: items.map(({ booked }) => booked),
        },
      ],
    };
  };

  const chartData = getChartData();

  const horizontalBarPreviewOptions: chartjs.ChartOptions = {
    ...options,
    plugins: {
      ...options.plugins,
      datalabels: {
        ...options?.plugins?.datalabels,
        font: {
          size: referralSources.length > maximumItemsInStatistics ? 10 : 14,
        },
      },
    },
  };

  return (
    <Box height="320px" display="flex" flexDirection="column" marginBottom="25px">
      <Box display="flex">
        <DashboardCardTitle title="Referral Sources">
          <Box component="span" fontSize="1rem" fontWeight={300} ml={1}>{`(${selectedPeriod.toLowerCase()})`}</Box>
        </DashboardCardTitle>
      </Box>
      {isFetching ? (
        <Box position="relative" minHeight="320px" width="100%">
          <ElromcoCircularProgress />
        </Box>
      ) : (
        <Paper elevation={1} classes={{ root: classes.paper }}>
          <HorizontalBar data={chartData} options={horizontalBarPreviewOptions} />
          {referralSources?.length > maximumItemsInStatistics && (
            <Box className={classes.arrowButton}>
              <IconButton
                disabled={!previousPage}
                color="primary"
                size="medium"
                onClick={handleChangePageNumber(SelectedPage.PREVIOUS_PAGE)}
              >
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton
                disabled={!nextPage}
                color="primary"
                size="medium"
                onClick={handleChangePageNumber(SelectedPage.NEXT_PAGE)}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
          )}
        </Paper>
      )}
    </Box>
  );
};

export default DashboardReferralSources;
