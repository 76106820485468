/* eslint-disable no-bitwise */
export const validate = (validator, data) => {
  const errors = Object.keys(validator)
    .map((input) => [input, validator[input](data)])
    .filter((it) => it[1]);
  return [!errors.length, errors.reduce((map, error) => ({ ...map, [error[0]]: error[1] }), {})];
};

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const roundTo2 = (n) => (n ? Math.round((n + Number.EPSILON) * 100) / 100 : 0);

export { default as CustomerInfoValidator } from './CustomerInfoValidator';
export { default as OrderDetailsValidator } from './OrderDetailsValidator';
export { default as MoveDetailsValidator } from './MoveDetailsValidator';
export { default as WaypointValidator } from './WaypointValidator';
export * from './copySettingsToOtherMoveTypes';
export * from './getBETemplateBuilderVariable';
export * from './getUITemplateBuilderVariable';
export * from './getUniqueByMoveType';
export * from './getFullAddress';
export * from './pathToArray';
export * from './capitalizeWord';
export * from './getValue';
export * from './timeToTextFormat';
export * from './getTotalEstimatedTime';
export * from './makePaymentDescription';
export * from './getServicePropertyName';
export * from './calculateDuration';
export { getServiceRosterClosingPropertyName } from './getServiceRosterClosingPropertyName';
export { getServiceClosingPropertyName } from './getServiceClosingPropertyName';
export { getInvoiceTitle } from './getInvoiceTitle';
export { getLocalizedTimeFormat } from './getLocalizedTimeFormat';
export { isTodayOrFeature } from './isTodayOrFeature';
export * from './canAssignDeliveryTruck';
export * from './canShowDeliveryTruck';
export * from './durationToMinutes';
