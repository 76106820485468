import { FC } from 'react';

import { DatePicker, Modal, MoveType, SettingNames, toBackEndDate, toDate } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { add } from 'date-fns';
import { useForm } from 'react-hook-form';

import { SERVICES } from 'admin/components/OrderWindow/OrderWindowLabels';
import { useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';

const { OVERNIGHT } = SettingNames;

interface SecondDateModalProps {
  onSave: (date: string) => void;
  onClose: () => void;
}

const firstServiceDatePath = [SERVICES, 0, 'date'];

export const SecondDateModal: FC<SecondDateModalProps> = ({ onSave, onClose }) => {
  const { order } = useOrderState();
  const settings = useOrderWindowSettings();
  const firstServiceDate = toDate(order?.getIn(firstServiceDatePath) as string);
  const maxOvernigthDaysCount = settings?.[OVERNIGHT.MAX_DAYS[order?.moveType as MoveType]] ?? 1;
  const defaultDate = add(firstServiceDate!, { days: 1 });

  const methods = useForm({
    defaultValues: {
      date: defaultDate,
    },
  });
  const { handleSubmit } = methods;

  const handleSubmitForm = (data: any) => {
    onSave(toBackEndDate(data.date)!);
  };

  const actions = [
    { label: 'cancel', onClick: onClose },
    {
      label: 'save',
      onClick: handleSubmit(handleSubmitForm as any),
    },
  ];

  return (
    <Modal title="Please choose delivery date for second service" open onClose={onClose} actions={actions}>
      <Box display="flex" justifyContent="center">
        <DatePicker
          name="date"
          label="Delivery Date"
          minDate={defaultDate}
          maxDate={add(firstServiceDate!, { days: maxOvernigthDaysCount })}
          /** @ts-ignore */
          control={methods.control}
        />
      </Box>
    </Modal>
  );
};
