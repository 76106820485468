import React, { useEffect, useState } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { Box, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';

import LongDistanceTariffAPI from 'admin/api/LongDistanceTariffAPI';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import {
  ACCOUNT_TYPE,
  BASED_STATE, // (not MVP) FLAGS,
  SALES_PERSON,
  SOURCE,
  STATUS,
} from 'admin/components/Accounts/AccountsLabels';
import { AccountActivityType } from 'admin/constants/AccountActivityType';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import SettingName from 'admin/constants/SettingName';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';
import useSettings from 'admin/hooks/useSettings';
import { getEnabledLeadSources } from 'admin/selectors';
import { sortFilterInputOptions } from 'admin/utils/sortFilterInputOptions';
import Circle from 'common/components/Circle';

const { LEAD_SOURCE } = SettingName;
const settingQueries = [LEAD_SOURCE];

const accountOptions = Object.entries(AccountTypeName).filter(([name]) => name !== AccountType.PRIVATE);
accountOptions.sort(sortFilterInputOptions).unshift(['0', 'All']);

export const useAccountsFiltersOptions = () => {
  const settings = useSettings(...settingQueries);
  const managersList = useSelector(getManagerList);
  const [basedStates, setBasedStates] = useState([]);
  const theme = useTheme();

  const customerAccountActivity = [
    { id: '1', label: AccountActivityType.ACTIVE, color: `${theme.palette.success.main}` },
    { id: '0', label: AccountActivityType.NON_ACTIVE, color: `${theme.palette.error.main}` },
  ];

  const accountActivityOptions = customerAccountActivity.map((item) => [
    item.id,
    <Box display="flex" key={item.id}>
      <Circle color={item.color} width="12px" height="12px" mr={1} />
      <BodyText noWrap>{item.label}</BodyText>
    </Box>,
  ]);

  useFetchManagers();

  useEffect(() => {
    LongDistanceTariffAPI.getStates().then((res) => setBasedStates(res));
  }, []);

  const statesOptions = basedStates.map((item) => [item.code, item.name]).sort(sortFilterInputOptions);

  const salesPersonOptions = managersList
    .toJS()
    .map((item) => [item.id, `${item.firstName} ${item.lastName}`])
    .sort(sortFilterInputOptions);
  const sourceOptions = getEnabledLeadSources(settings).sort(sortFilterInputOptions);

  // not MVP
  // const [flagsOptions, setFlagsOptions] = useState([]);
  //
  // useEffect(() => {
  //   OrderAPI.getAllAvailableFlagsForCurrentBranch().then((data) => {
  //     const uniqueByNameSortedFlags = [];
  //     sortFlagsDataBySequence(data).forEach((item) => {
  //       if (!uniqueByNameSortedFlags.find((usf) => usf.name === item.name)) {
  //         uniqueByNameSortedFlags.push(item);
  //       }
  //     });
  //     setFlagsOptions(uniqueByNameSortedFlags.map((item) => [item.name, item.name]).sort(sortFilterInputOptions));
  //   });
  // }, []);

  return {
    [ACCOUNT_TYPE]: accountOptions,
    [SOURCE]: sourceOptions,
    [SALES_PERSON]: salesPersonOptions,
    // (not MVP) [FLAGS]: flagsOptions,
    [BASED_STATE]: statesOptions,
    [STATUS]: accountActivityOptions,
  };
};
