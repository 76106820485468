import React, { useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  BodyText,
  EstimateType,
  Link,
  Order,
  Service,
  formatCurrency,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { LinehaulChargeFlatRateInput, LinehaulChargeLongDistanceInput } from 'admin/components/OrderWindow/common';
import { useOrderServiceIndex, useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import LinehaulCharge from 'admin/components/OrderWindow/modals/LinehaulCharge';
import SettingName from 'admin/constants/SettingName';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

enum Modals {
  LINEHAUL_CHARGE = 'LINEHAUL_CHARGE',
}

const formatMoney = (money: number) => `$${formatCurrency(money)}`;

const MOVING_CHARGE = 'Moving Charge';

const { TRAVEL_TIME_ENABLED, TRAVEL_TIME_IN_DOLLAR } = SettingName;

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

export const ClosingCharges = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const orderDetail = order.closingOrderDetail;
  const services = orderDetail.services as List<Service>;
  const service = services.get(serviceIndex)!;
  const { quote } = service;
  const settings = useOrderWindowSettings();
  const isLongDistance = isLongDistanceService(service.getType());
  const isFlatRate = orderDetail.getServiceQuote(serviceIndex).estimateType === EstimateType.FLAT_RATE;
  const [openModal, setOpenModal] = useState<Modals | null>(null);

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  if (isFlatRate && !isLongDistance) {
    const totalFlatRate = isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.getFinalFlatRateLinehaulCharge(), 0)
      : quote.getFinalFlatRateLinehaulCharge();
    const totalFlatRateTravelTimeCharge = isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.getFlatRateTravelCharge(), 0)
      : quote.getFlatRateTravelCharge();

    return (
      <Grid item spacing={2} xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.costBreakdownItem}>
              <BodyText data-testId="flatRateLinehaulCharge">Linehaul Charge</BodyText>
              {isFlatRate && !isSelectedAllServices && order.disableAutoCalculation ? (
                <Box mr={-1}>
                  <LinehaulChargeFlatRateInput />
                </Box>
              ) : (
                <BodyText data-testId="flatRateLinehaulChargeValue">{`$${formatCurrency(totalFlatRate)}`}</BodyText>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.costBreakdownItem}>
              <BodyText data-testId="travelTimeCharge">Travel Time Charge</BodyText>
              <BodyText data-testId="travelTimeChargeValue">{`$${formatCurrency(
                totalFlatRateTravelTimeCharge,
              )}`}</BodyText>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (isLongDistance) {
    const label = 'Linehaul Charge';
    const totalLinehaulCharge = (
      isSelectedAllServices ? orderDetail : quote
    ).baseQuoteWithoutTaxes.asHumanReadableString();

    return (
      <Grid item xs={12}>
        <Box className={classes.costBreakdownItem}>
          {quote.longDistanceTariffDetails.isRegionTariff() ? (
            <Link onClick={toggleOpenModal(Modals.LINEHAUL_CHARGE)}>{label}</Link>
          ) : (
            <BodyText data-testId={label}>{label}</BodyText>
          )}
          {Modals.LINEHAUL_CHARGE === openModal && (
            <LinehaulCharge
              onClose={toggleOpenModal(null)}
              moveSize={quote.activeMoveSize}
              linehaulCharge={quote.totalLinehaulCharge.humanReadable}
              longDistanceDetails={quote.longDistanceTariffDetails}
            />
          )}
          {order.disableAutoCalculation ? (
            <Box mr={-1}>
              <LinehaulChargeLongDistanceInput />
            </Box>
          ) : (
            <BodyText data-testId="lineHaulChargeValue">{totalLinehaulCharge}</BodyText>
          )}
        </Box>
      </Grid>
    );
  }

  const isTravelRateTheSameAsLaborRate = settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];

  const shouldDisplayTravelCharge =
    settings[TRAVEL_TIME_ENABLED] && settings[TRAVEL_TIME_IN_DOLLAR] && !quote.isFlatRate();

  if (isTravelRateTheSameAsLaborRate || !shouldDisplayTravelCharge) {
    const movingCharge = (
      isSelectedAllServices ? order.closingOrderDetail : quote
    ).baseQuoteWithoutTaxes.asHumanReadableString();

    return (
      <Grid item xs={12}>
        <Box className={classes.costBreakdownItem}>
          <BodyText data-testId="movingCharge">{MOVING_CHARGE}</BodyText>
          <BodyText>{movingCharge}</BodyText>
        </Box>
      </Grid>
    );
  }

  const totalLaborQuote = (isSelectedAllServices ? orderDetail : quote).totalLaborQuote.asHumanReadableString();
  const baseQuote = (isSelectedAllServices ? orderDetail : quote).baseQuoteWithoutTaxes.asHumanReadableString();
  const label = shouldDisplayTravelCharge ? 'Labor Charge' : MOVING_CHARGE;
  const value = shouldDisplayTravelCharge ? totalLaborQuote : baseQuote;
  const totalTravelQuote = formatMoney(
    isSelectedAllServices
      ? services.reduce((accumulator, s) => accumulator + s.quote.totalTravelQuote, 0)
      : quote.totalTravelQuote,
  );

  return (
    <Grid item spacing={2} xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.costBreakdownItem}>
            <BodyText date-testId={shouldDisplayTravelCharge ? 'laborCharge' : 'movingCharge'}>{label}</BodyText>
            <BodyText date-testId={shouldDisplayTravelCharge ? 'laborChargeValue' : 'movingChargeValue'}>
              {value}
            </BodyText>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {shouldDisplayTravelCharge && (
            <Box className={classes.costBreakdownItem}>
              <BodyText data-testId="travelTimeCharge">Travel Time Charge</BodyText>
              <BodyText data-testId="travelTimeChargeValue">{totalTravelQuote}</BodyText>
            </Box>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
