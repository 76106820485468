import React from 'react';

import { Link } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';

import authApi from 'admin/api/AuthAPI';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import SettingName from 'admin/constants/SettingName';
import { getUrl } from 'common/utils';

interface ViewCustomerPageLinkProps {
  order: {
    orderId: number;
    customerInfo: {
      customerId?: number;
      email: string;
    };
  };
}

const ViewCustomerPageLink: React.FC<ViewCustomerPageLinkProps> = ({ order }) => {
  const settings = useOrderWindowSettings();

  if (!order.customerInfo.customerId || !settings[SettingName.CUSTOMER_HOST_DNS_NAME]) {
    return null;
  }

  const handleLinkClick = () => {
    // @ts-ignore
    authApi.createCustomerReadOnlyJwt(order.customerInfo.email).then((jwt: string) => {
      setTimeout(() => {
        const link = document.createElement('a');
        link.href = getUrl(settings[SettingName.CUSTOMER_HOST_DNS_NAME], jwt, order.orderId, '/v1/account/overview');
        link.target = '_blank';
        link.click();
        link.remove();
      });
    });
  };

  return (
    <Box marginTop="auto" mb={2} textAlign="center">
      <Link onClick={handleLinkClick}>View Customer Account Page</Link>
    </Box>
  );
};

export default ViewCustomerPageLink;
