import { BodyText } from '@elromcoinc/react-shared';
import { Box, alpha, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { JobTrackerLevels, TrackerTimeLine } from 'admin/components/CrewStatusLog/types';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 5,
    top: theme.spacing(4),
    cursor: 'pointer',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: alpha(theme.palette.common.white, 0.8),
    paddingLeft: 2,
    transition: 'all 0.3s',
    '&:hover': {
      color: theme.palette.common.white,
      zIndex: 100,
      borderBottomSize: '2px',
      '& .job-tracker-text': {
        fontWeight: 'bold',
      },
      '& .vertical-line, & .horizontal-line': {
        visibility: 'visible',
        borderColor: theme.palette.common.white,
      },
    },
  },
  level1: {
    top: 'initial',
    bottom: theme.spacing(3),
  },
  level2: {
    top: 'initial',
    bottom: theme.spacing(6),
  },
  text: {
    color: 'inherit',
  },
  verticalLine: {
    height: theme.spacing(1.5),
    marginBottom: theme.spacing(-1),
    flexGrow: 2,
    display: 'flex',
    alignItems: 'center',
  },
  verticalLineLeft: {
    visibility: 'hidden',
    borderLeft: `2px solid ${alpha(theme.palette.common.white, 0.8)}`,
  },
  verticalLineRight: {
    visibility: 'hidden',
    borderRight: `2px solid ${alpha(theme.palette.common.white, 0.8)}`,
  },
  horizontalLine: {
    visibility: 'hidden',
    borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.8)}`,
    width: '100%',
  },
  travelTime: {
    color: alpha(theme.palette.common.black, 0.3),
    '&:hover': {
      color: alpha(theme.palette.common.black, 0.5),
      '& .vertical-line, & .horizontal-line': {
        borderColor: alpha(theme.palette.common.black, 0.5),
      },
    },
  },
  noPaddingLeft: {
    paddingLeft: 0,
  },
  notHovered: {
    color: alpha(theme.palette.common.white, 0.5),
  },
  notHoveredTravelTime: {
    color: alpha(theme.palette.common.black, 0.15),
  },
}));

interface HorizontalTimeLineProps {
  time: TrackerTimeLine;
  startDayOffsetInMinutes: number;
  widthCoefficient: number;
  hovered: boolean;
  isHovered: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export const HorizontalTimeLine = ({
  time,
  startDayOffsetInMinutes,
  widthCoefficient,
  hovered,
  isHovered,
  onMouseEnter,
  onMouseLeave,
}: HorizontalTimeLineProps) => {
  const offset = (time.startTime - startDayOffsetInMinutes) * widthCoefficient;
  const width = time.width * widthCoefficient;
  const classes = useStyles();
  const isTravelTimeLevel =
    time.level === JobTrackerLevels.TRAVEL_TIME ||
    time.level === JobTrackerLevels.TRAVEL_TIME_OVERLAP ||
    time.level === JobTrackerLevels.OVERTIME_TRAVEL_TIME ||
    time.level === JobTrackerLevels.OVERTIME_TRAVEL_TIME_LESS ||
    time.level === JobTrackerLevels.OVER_ESTIMATED_LABOR_TIME;

  return (
    <Box
      className={clsx(classes.estimatedBlock, {
        [classes.noPaddingLeft]: !!time.noPaddingLeft,
        [classes.travelTime]: isTravelTimeLevel,
        [classes.notHovered]: !hovered && isHovered && !isTravelTimeLevel,
        [classes.notHoveredTravelTime]: !hovered && isHovered && isTravelTimeLevel,
        [classes.level1]:
          time.level === JobTrackerLevels.LABOR_TIME ||
          time.level === JobTrackerLevels.OTHER ||
          time.level === JobTrackerLevels.TRAVEL_TIME ||
          time.level === JobTrackerLevels.OVERTIME_TRAVEL_TIME ||
          time.level === JobTrackerLevels.OVERTIME_TRAVEL_TIME_LESS,
        [classes.level2]:
          time.level === JobTrackerLevels.LABOR_TIME_OVERLAP || time.level === JobTrackerLevels.TRAVEL_TIME_OVERLAP,
      })}
      left={offset}
      width={width}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Box className={clsx(classes.verticalLine, classes.verticalLineLeft, 'vertical-line')}>
        <Box className={clsx(classes.horizontalLine, 'horizontal-line')} />
      </Box>
      <Box mb={-1}>
        <BodyText className={clsx(classes.text, 'job-tracker-text')}>{time.title}</BodyText>
      </Box>
      <Box className={clsx(classes.verticalLine, classes.verticalLineRight, 'vertical-line')}>
        <Box className={clsx(classes.horizontalLine, 'horizontal-line')} />
      </Box>
    </Box>
  );
};
