import { InvoicesStatusType } from '@elromcoinc/react-shared';
import { Theme } from '@material-ui/core';

const useInvoiceStatusColors = (theme: Theme) => ({
  [InvoicesStatusType.PAID]: theme.palette.success.main,
  [InvoicesStatusType.SENT]: theme.palette.primary.main,
  [InvoicesStatusType.OVERDUE]: theme.palette.error.main,
  [InvoicesStatusType.RESENT]: theme.palette.secondary.main,
  [InvoicesStatusType.DRAFT]: theme.palette.info.main,
  [InvoicesStatusType.VIEWED]: theme.palette.primary.dark,
  [InvoicesStatusType.PARTIALLY_PAID]: theme.palette.warning.main,
});

export { useInvoiceStatusColors };
