import {
  CUSTOMER_INFO,
  EMAIL,
  EstimateType,
  FIRST_NAME,
  LAST_NAME,
  MoveType,
  PHONE_NUMBER,
  PHONE_TYPE,
  PRIMARY_PHONE,
} from '@elromcoinc/react-shared';

import { ExtraRooms, MoveInfo } from 'common-types';

const REFERRED_VIA = 'referredVia';
const COMPANY_ID = 'companyId';
const TO_ZIP_CODE = 'destinationAddress';
const FROM_CITY = 'originCity';
const FROM_STATE = 'originState';
const FROM_COUNTRY = 'originCountry';
const TO_CITY = 'destinationCity';
const TO_STATE = 'destinationState';
const TO_COUNTRY = 'destinationCountry';
const FROM_ELEVATION_ID = 'fromElevationId';
const TO_ELEVATION_ID = 'toElevationId';
const MOVE_SIZE = 'moveSize';
const MOVE_TYPE = 'moveType';
const MOVE_DATE = 'moveDate';
const FROM_ZIP_CODE = 'originAddress';
const SERVICE_NAME = 'serviceName';
const DESIRED_DELIVERY_DATE = 'desiredDeliveryDate';
const PICKUP_PARKING_ID = 'pickupParkingToEntranceSpeedReductionId';
const DROPOFF_PARKING_ID = 'dropoffParkingToEntranceSpeedReductionId';
const PACKING_SPEED_REDUCTION_ID = 'packingSpeedReductionId';
const UNPACKING_SPEED_REDUCTION_ID = 'unpackingSpeedReductionId';
const SERVICE_ID = 'serviceId';
const SITE_SIZE_SPEC_ID = 'siteSizeSpecId';
const ESTIMATE_TYPE = 'estimateType';
const STORAGE_MOVE_STAGE = 'storageMoveStage';
const SIZE_DESCRIPTION = 'sizeDescription';
const ARRIVAL_WINDOW = 'arrivalWindow';
const DELIVERY_WINDOW = 'deliveryWindow';
const EXTRA_ROOMS = 'extraRooms';
const MOVE_SIZE_EXTRA = 'moveSizeExtra';
const MOVE_SIZE_ID = 'moveSizeId';
const SITE_SIZE_SPEC_NAME = 'siteSizeSpecName';

const labels = {
  [MOVE_TYPE]: 'Move Type',
  [MOVE_DATE]: 'Move Date',
  [SERVICE_ID]: 'Service Type',
  [DESIRED_DELIVERY_DATE]: 'Desired Delivery Date',
  [MOVE_SIZE_EXTRA]: 'Rooms',
  [TO_ZIP_CODE]: 'To Address Zip',
  [MOVE_SIZE]: 'Size of Move',
  [FROM_ELEVATION_ID]: 'From Elevation',
  [TO_ELEVATION_ID]: 'To Elevation',
  [PICKUP_PARKING_ID]: 'From Parking Distance',
  [DROPOFF_PARKING_ID]: 'To Parking Distance',
  [PACKING_SPEED_REDUCTION_ID]: 'Packing',
  [UNPACKING_SPEED_REDUCTION_ID]: 'Unpacking',
  [FROM_ZIP_CODE]: 'Origin Address Zip',
};

const defaultTestCalcFormValue = {
  [CUSTOMER_INFO]: {
    [EMAIL]: null,
    [FIRST_NAME]: null,
    [LAST_NAME]: null,
    [REFERRED_VIA]: 'Facebook',
    [PRIMARY_PHONE]: {
      [PHONE_NUMBER]: '12345',
      [PHONE_TYPE]: null,
    },
  },
  [COMPANY_ID]: 1,
  [TO_ZIP_CODE]: '',
  [FROM_ELEVATION_ID]: null,
  [MOVE_SIZE]: 0,
  [MOVE_DATE]: null,
  [MOVE_TYPE]: MoveType.RESIDENTIAL,
  [FROM_ZIP_CODE]: '',
  [SERVICE_NAME]: '',
  [DESIRED_DELIVERY_DATE]: null,
  [TO_ELEVATION_ID]: null,
  [PICKUP_PARKING_ID]: null,
  [DROPOFF_PARKING_ID]: null,
  [PACKING_SPEED_REDUCTION_ID]: '0',
  [UNPACKING_SPEED_REDUCTION_ID]: '0',
  [MOVE_SIZE_ID]: '',
  [SERVICE_ID]: null,
  [MOVE_SIZE_EXTRA]: [] as ExtraRooms[],
  [SITE_SIZE_SPEC_ID]: '',
  [EXTRA_ROOMS]: [] || null,
  [ESTIMATE_TYPE]: EstimateType.NON_BINDING,
  [STORAGE_MOVE_STAGE]: null,
  [SITE_SIZE_SPEC_NAME]: '',
};

const fromAddressFieldNames = {
  zipCode: FROM_ZIP_CODE,
  city: FROM_CITY,
  state: FROM_STATE,
  country: FROM_COUNTRY,
};

const toAddressFieldNames = {
  zipCode: TO_ZIP_CODE,
  city: TO_CITY,
  state: TO_STATE,
  country: TO_COUNTRY,
};

interface TestCalculatorDate extends MoveInfo {
  storageMoveStage: null | string;
  companyId: number;
  packingSpeedReductionId: string;
  pickupParkingToEntranceSpeedReductionId: string;
  unpackingSpeedReductionId: string;
}

export {
  labels,
  REFERRED_VIA,
  COMPANY_ID,
  TO_ZIP_CODE,
  FROM_ELEVATION_ID,
  MOVE_SIZE,
  MOVE_TYPE,
  MOVE_DATE,
  MOVE_SIZE_ID,
  FROM_ZIP_CODE,
  SERVICE_NAME,
  DESIRED_DELIVERY_DATE,
  TO_ELEVATION_ID,
  PICKUP_PARKING_ID,
  DROPOFF_PARKING_ID,
  PACKING_SPEED_REDUCTION_ID,
  UNPACKING_SPEED_REDUCTION_ID,
  SERVICE_ID,
  SITE_SIZE_SPEC_ID,
  ESTIMATE_TYPE,
  STORAGE_MOVE_STAGE,
  SIZE_DESCRIPTION,
  ARRIVAL_WINDOW,
  DELIVERY_WINDOW,
  EXTRA_ROOMS,
  MOVE_SIZE_EXTRA,
  SITE_SIZE_SPEC_NAME,
  defaultTestCalcFormValue,
  fromAddressFieldNames,
  toAddressFieldNames,
  FROM_CITY,
  FROM_STATE,
  FROM_COUNTRY,
  TO_CITY,
  TO_STATE,
  TO_COUNTRY,
};

export type { TestCalculatorDate };
