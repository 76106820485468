// @ts-ignore
import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto from './UpdatedValueDto';
import { extractServiceIndexUtil } from './extractServiceIndexUtil';

const minLaborHoursChange: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (fieldPath.indexOf('minLaborHours') > -1) {
    const oldValue = oldOrder.getValueIn(fieldPath)?.value()?.humanReadable || '';
    const newValue = newOrder.getValueIn(fieldPath)?.value()?.humanReadable || '';
    if ((newValue || newValue) && oldValue !== newValue) {
      return [
        createUpdatedValueDto(
          oldValue,
          newValue,
          'Min Labor Hours',
          extractServiceIndexUtil(fieldPath, newOrder.services.size > 1),
        ),
      ];
    }
  }
  return null;
};

export default minLaborHoursChange;
