import { cardCodeYUP, cardExpirationYUP, cardNumberYUP, zipCodeAsyncYUP } from '@elromcoinc/react-shared';
import { date, object, string } from 'yup';

export const AMOUNT = 'amount';
export const PAYMENT_TYPE = 'type';
export const PAID_DATE = 'date';
export const NOTES = 'message';
export const CARDHOLDER_NAME = 'cc-name';
export const LAST_FOUR_DIGITS = 'lastFourDigits';
export const POSTAL_CODE = 'postalCode';
export const MAKE_DEFAULT = 'makeDefault';
export const CARD_NUMBER = 'cc-number';
export const CCV_CODE = 'cc-csc';
export const EXPIRATION = 'cc-exp';
export const SELECTED_CC = 'selectedCC';
export const DESCRIPTION = 'description';

export const CHECK_NUMBER = 'checkNumber';

export const labels = {
  [AMOUNT]: 'Amount',
  [CARD_NUMBER]: 'Card Number',
  [NOTES]: 'Additional notes',
  [PAID_DATE]: 'Paid Date',
  [PAYMENT_TYPE]: 'Payment Type',
  [CARDHOLDER_NAME]: 'Cardholder Name',
  [EXPIRATION]: 'Expiration',
  [POSTAL_CODE]: 'ZIP Code',
  [CCV_CODE]: 'CCV',
  [CHECK_NUMBER]: 'Check Number',
  [MAKE_DEFAULT]: 'Make this default payment',
  [LAST_FOUR_DIGITS]: 'Card Number (last 4)',
  [DESCRIPTION]: 'Description',
};

export const cashSchema = object({
  [AMOUNT]: string()
    .label(labels[AMOUNT])
    .nullable()
    .transform((value) => (value || '').replace(/,/g, ''))
    .required(),
  [PAID_DATE]: date().label(labels[PAID_DATE]).nullable().required(),
  [NOTES]: string().nullable().max(1000),
});

export const addCreditCard = object({
  [CARDHOLDER_NAME]: string().label(labels[CARDHOLDER_NAME]).required(),
  [CARD_NUMBER]: cardNumberYUP({ label: labels[CARD_NUMBER], isShort: false }),
  [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE).label(labels[POSTAL_CODE]),
  [CCV_CODE]: cardCodeYUP({ isShort: false, label: labels[CCV_CODE], cardNumberName: CARD_NUMBER }),
  [EXPIRATION]: cardExpirationYUP({ label: labels[EXPIRATION] }).required(),
});
export const editCreditCard = object({
  [CARDHOLDER_NAME]: string().label(labels[CARDHOLDER_NAME]).required(),
  [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE).label(labels[POSTAL_CODE]).required(),
  [EXPIRATION]: cardExpirationYUP({ label: labels[EXPIRATION] }).required(),
});
