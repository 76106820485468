import { Order } from '@elromcoinc/react-shared';

import { ChangeSetMetadata } from 'common-types';

export const makeChangeSetMetadata = (order: Order) => {
  return order.services.map(
    (service, index) =>
      ({
        serviceRosterId: service.quote.serviceRosterId,
        primaryRosterId: service.quote.primaryRosterId,
        index,
      } as ChangeSetMetadata),
  );
};
