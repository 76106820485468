import React, { FC, useState } from 'react';

import { HeaderBigText, IconButton, Modal, useAlert } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { Close as CloseIcon, OpenInNew as ExpandIcon } from '@material-ui/icons';

import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { ClosingLogsList } from 'admin/components/OrderWindow/blocks/ChangeLog/ClosingLogsList';
import { OrderLogsList } from 'admin/components/OrderWindow/blocks/ChangeLog/OrderLogsList';
import { useOrderClosingContext } from 'admin/components/OrderWindow/context';
import Card from 'common/components/Card';

export const ChangeLog: FC = () => {
  const { isClosing } = useOrderClosingContext();
  const { orderLogs } = useActivityLogContext();
  const [expanded, setExpanded] = useState(false);

  const { setShowAlert, alertProps } = useAlert({
    cancelTitle: 'Close',
    onCancel: () => setExpanded(false),
  });
  const onExpand = () => {
    if (!alertProps.open) {
      setShowAlert(true);
      return;
    }

    setExpanded(true);
  };

  return (
    <Card minWidth="290px" dataTestId="order-log">
      <Box display="flex" width="100%" mb={0.5} justifyContent="space-between">
        <Box>
          <HeaderBigText>
            <b>{isClosing ? 'Closing Log' : 'Order Log'}</b>
          </HeaderBigText>
        </Box>
        <IconButton
          color="primary"
          data-testid={expanded ? 'closeActivityLogExpand' : 'openActivityLogExpand'}
          onClick={onExpand}
          className="print-remove"
        >
          {expanded ? <CloseIcon /> : <ExpandIcon />}
        </IconButton>
      </Box>
      {isClosing ? <ClosingLogsList /> : <OrderLogsList orderLogs={orderLogs} />}
      <Modal title={isClosing ? 'Closing Log' : 'Order Log'} maxWidth="md" {...alertProps} color="white">
        {isClosing ? <ClosingLogsList inModal /> : <OrderLogsList orderLogs={orderLogs} inModal />}
      </Modal>
    </Card>
  );
};
