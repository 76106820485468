import { toDate } from '@elromcoinc/react-shared';

/**
 * Utility function that takes an expiration date string in the format 'MM/YY' and converts it into a JavaScript Date
 * object.
 *
 * @param expiration string in format 'MM/YY'
 * @return instance of Data object representing the provided expiration date.
 */
export const convertExpirationStringToDate = (expiration: string): Date => {
  return toDate(`15/${expiration.slice(0, 3)}20${expiration.slice(3)}`, 'dd/MM/yyyy')!;
};
