import React from 'react';

import { BACKEND_DATE_FORMAT, ElromcoCircularProgress, statusIds } from '@elromcoinc/react-shared';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { add, format, parse, sub } from 'date-fns';
import { useSelector } from 'react-redux';

import { getDashboardStats, getInFlightStatistics } from 'admin/autodux/DashboardAutodux';
import DashboardSalesPerformance from 'admin/components/Dashboard/DashboardSalesPerformance';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { customDateName } from 'admin/constants/DashboardPeriods';
import { ReloadButton } from 'common/components/ReloadButton';

import DashboardCommunicationActivity from './DashboardCommunicationActivity';
import DashboardCurrentMonthStatistic from './DashboardCurrentMonthStatistic';
import DashboardLeads from './DashboardLeads';
import DashboardPeriod from './DashboardPeriod';
import DashboardReferralSources from './DashboardReferralSources';
import DashboardStatisticCard from './DashboardStatisticCard';
import DashboardStatusesChart from './DashboardStatusesChart';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  statsBar: {
    padding: '1rem',
    margin: '1rem',
    backgroundColor: palette.common.white,
    boxShadow: '0 0 3px 1px #80808050',
    position: 'relative',
  },
  dashboardStatisticPanel: {
    marginTop: '10px',
    '&:nth-child(1), &:nth-child(2), &:nth-child(3)': {
      borderRight: `1px solid ${palette.grey.A100}`,
    },
    [breakpoints.down('md')]: {
      '&:nth-child(2)': {
        borderRight: 'none',
      },
    },
  },
  dashboardStatisticCard: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-25px!important',
    marginBottom: 'auto!important',
    [breakpoints.down('md')]: {
      marginTop: '0 !important',
    },
  },
}));

const panels = [
  {
    color: '#cd324a',
    amount: 8,
    data: [3, 4, 3, 0, 2, 0, 0],
  },
  {
    color: '#ffb54c',
    amount: 4,
    data: [3, 4, 3, 7, 2, 0, 0],
  },
  {
    color: '#5ecd83',
    amount: 4,
    pastAmount: 8,
    data: [3, 4, 3, 7, 2, 0, 0],
  },
  {
    color: '#2c59c4',
    amount: 4,
    pastAmount: 75,
    data: [3, 4, 3, 7, 2, 0, 0],
  },
];
const titles = ['Leads', 'Reserved', 'Booked', 'Moves'];
const getTitleByIndex = (index, periodName) => (titles[index] ? `${titles[index]} ${periodName}` : 'My commission');

const mapSnapshotCount = (value) => value.map(({ snapshotCount }) => snapshotCount);
const calculateSnapshotCount = (value) =>
  mapSnapshotCount(value).reduce((total, currentValue) => total + currentValue, 0);
const prepareResult = (
  { thisPeriod = [], comparablePeriod = [] },
  { thisPeriod: pastWeek = [] },
  labels,
  uniquePastWeekFilter,
  uniqueFilter,
) => ({
  amount: calculateSnapshotCount(thisPeriod),
  pastAmount: calculateSnapshotCount(comparablePeriod),
  estimatedTotal: {
    max: thisPeriod.map(({ totalMax }) => totalMax).reduce((accumulator, value) => accumulator + value, 0),
    min: thisPeriod.map(({ totalMin }) => totalMin).reduce((accumulator, value) => accumulator + value, 0),
  },
  data: labels.map((value) => {
    const { snapshotCount } = pastWeek.find(({ snapshotDate }) => snapshotDate === value) || { snapshotCount: 0 };
    return snapshotCount;
  }),
  labels: labels.map((date) => format(parse(date, BACKEND_DATE_FORMAT, new Date()), 'iiii MM/dd/yyyy')),
  uniquePastWeekFilter,
  uniqueFilter,
});

const makeDashboardMonthStats = (dashboard) => {
  const {
    current: { newLeads = {}, reservedLeads = {}, bookedLeads = {}, scheduledMoves = {}, dateRange = {} },
    pastWeek: {
      newLeads: pastWeekNewLeads = {},
      reservedLeads: pastWeekReservedLeads = {},
      bookedLeads: pastWeekBookedLeads = {},
      scheduledMoves: pastWeekScheduledMoves = {},
      dateRange: pastWeekDateRange,
    },
  } = dashboard;
  const { startDate } = pastWeekDateRange || {
    startDate: format(sub(new Date(), { weeks: 1 }), BACKEND_DATE_FORMAT),
  };
  const labels = [0, 1, 2, 3, 4, 5, 6].map((days) =>
    format(add(parse(startDate, BACKEND_DATE_FORMAT, new Date()), { days }), BACKEND_DATE_FORMAT),
  );
  return [
    prepareResult(
      newLeads,
      pastWeekNewLeads,
      labels,
      {
        created: [pastWeekDateRange?.startDate, pastWeekDateRange?.endDate],
      },
      {
        created: [dateRange.startDate, dateRange?.endDate],
      },
    ),
    prepareResult(
      reservedLeads,
      pastWeekReservedLeads,
      labels,
      {
        status: statusIds.RESERVED,
        reservedDate: [pastWeekDateRange?.startDate, pastWeekDateRange?.endDate],
      },
      {
        status: statusIds.RESERVED,
        reservedDate: [dateRange.startDate, dateRange?.endInstance],
      },
    ),
    prepareResult(
      bookedLeads,
      pastWeekBookedLeads,
      labels,
      {
        status: statusIds.BOOKED,
        bookingDate: [pastWeekDateRange?.startDate, pastWeekDateRange?.endDate],
      },
      {
        status: statusIds.BOOKED,
        bookingDate: [dateRange.startDate, dateRange?.endDate],
      },
    ),
    prepareResult(
      scheduledMoves,
      pastWeekScheduledMoves,
      labels,
      {
        status: statusIds.BOOKED,
        moveStartDate: [pastWeekDateRange?.startDate, pastWeekDateRange?.endDate],
      },
      {
        status: statusIds.BOOKED,
        moveStartDate: [dateRange.startDate, dateRange.endDate],
      },
    ),
  ];
};

export default function Dashboard() {
  const classes = useStyles();
  const isFetching = useSelector(getInFlightStatistics);
  const dashboard = useSelector(getDashboardStats);
  const dashboardMonthStats = makeDashboardMonthStats(dashboard);
  const [selectedPeriod] = dashboard.selectedPeriod.split('/');

  const data = dashboardMonthStats.map((value, index) => ({
    ...panels[index],
    ...value,
    title: getTitleByIndex(index, selectedPeriod === customDateName ? '' : selectedPeriod),
  }));

  return (
    <>
      <VideoButton video="kiMKuZfQ9t4" />
      <Box className={`applyRadius ${classes.statsBar}`}>
        <Grid container justifyContent="flex-end">
          <DashboardPeriod />
          <Box mt={2.5} ml={2}>
            <ReloadButton />
          </Box>
        </Grid>
        <Grid container>
          <Grid item lg={7} xs={12} className={classes.dashboardStatisticCard}>
            {[0, 1, 2, 3].map((value) => (
              <Grid lg={3} key={value} xs={6} item className={classes.dashboardStatisticPanel}>
                <DashboardStatisticCard {...data[value]} />
              </Grid>
            ))}
          </Grid>
          <DashboardCurrentMonthStatistic />
        </Grid>
        {isFetching && <ElromcoCircularProgress />}
      </Box>
      <Box m="1rem">
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <DashboardReferralSources />
          </Grid>
          <Grid item lg={4} xs={12}>
            <DashboardSalesPerformance />
          </Grid>
          <Grid item lg={4} xs={12}>
            <DashboardStatusesChart />
          </Grid>
          <Grid item md={6} xs={12} data-testid="dashBoardLeads">
            <DashboardLeads />
          </Grid>
          <Grid item md={6} xs={12}>
            <DashboardCommunicationActivity />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
