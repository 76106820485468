const ACCOUNT_DISCOUNT = 'accountDiscount';
const PAYMENT_OPTION = 'paymentOption';
const PERCENTAGE = 'totalDiscount';
const FLAT_FEE = 'flatFee';
const HOURLY_RATE_DISCOUNT = 'hourlyRateDiscount';
const HOURLY_RATE_DEFAULT = 'hourlyRate';
const DISCOUNT_AMOUNT = 'amount';
const COD = 'cod';
const IOD = 'iod';
const ACCOUNT_NAME = 'name';
const ACCOUNT_TYPE = 'type';
const ACCOUNT_ID = 'id';
const OWNER_CUSTOMER_INFO = 'ownerCustomerInfo';
const ADDITIONAL_CONTACT_FIRST_NAME = 'additionalContactFirstName';
const ADDITIONAL_CONTACT_LAST_NAME = 'additionalContactLastName';
const ADDITIONAL_CONTACT_EMAIL = 'additionalContactEmail';
const ADDITIONAL_CONTACT_PHONE_NUMBER = 'additionalContactPhoneNumber';
const ADDITIONAL_CONTACT_RECEIVE_EMAILS = 'additionalContactReceiveEmails';
const ADDITIONAL_CONTACT_RECEIVE_SMS = 'additionalContactReceiveSms';
const ENABLE_RECEIVING_EMAILS = 'enableReceivingEmails';
const ACCOUNT_ACCESS_ALLOWED = 'accountAccessAllowed';
const ADDRESS = 'address';
const CITY = 'city';
const STATE = 'state';
const POSTAL_CODE = 'postalCode';
const ADDRESS_STREET = 'street1';
const COUNTRY = 'country';
const ALL_BRANCHES = 'allBranches';
const APARTMENT_NUMBER = 'street2';
const CONTACT_BEST_WAY = 'contactBestWay';
const CONTACT_BEST_TIME = 'contactBestTime';

const labels = {
  [ACCOUNT_DISCOUNT]: 'Account Discount',
  [PERCENTAGE]: 'Percentage',
  [FLAT_FEE]: 'Flat Fee',
  [HOURLY_RATE_DISCOUNT]: 'Hourly Rate Discount',
  [HOURLY_RATE_DEFAULT]: 'Hourly Rate Default',
  [DISCOUNT_AMOUNT]: 'Percent/Amount',
  [PAYMENT_OPTION]: 'Payment Option',
  [COD]: `COD (Cash, check or credit card on delivery)`,
  [IOD]: `IOD (Invoice on delivery)`,
  [ALL_BRANCHES]: 'All Branches',
};

export {
  PERCENTAGE,
  FLAT_FEE,
  HOURLY_RATE_DISCOUNT,
  HOURLY_RATE_DEFAULT,
  DISCOUNT_AMOUNT,
  COD,
  IOD,
  ACCOUNT_DISCOUNT,
  PAYMENT_OPTION,
  labels,
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  ACCOUNT_ID,
  OWNER_CUSTOMER_INFO,
  ADDITIONAL_CONTACT_FIRST_NAME,
  ADDITIONAL_CONTACT_LAST_NAME,
  ADDITIONAL_CONTACT_EMAIL,
  ADDITIONAL_CONTACT_PHONE_NUMBER,
  ADDITIONAL_CONTACT_RECEIVE_EMAILS,
  ADDITIONAL_CONTACT_RECEIVE_SMS,
  ENABLE_RECEIVING_EMAILS,
  ACCOUNT_ACCESS_ALLOWED,
  ADDRESS,
  CITY,
  STATE,
  POSTAL_CODE,
  ADDRESS_STREET,
  COUNTRY,
  ALL_BRANCHES,
  CONTACT_BEST_WAY,
  APARTMENT_NUMBER,
  CONTACT_BEST_TIME,
};
