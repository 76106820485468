import React, { useState } from 'react';
import { Box, makeStyles, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { DataTable } from '@elromcoinc/react-shared';
import pt from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeColumns } from 'admin/components/AccountWindow/config';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import { OrderWindowPages } from 'admin/constants/OrderWindowPages';
import { useMobile } from 'admin/hooks/useMobile';

const useStyles = makeStyles({
  tableCell: {
    border: 'none',
    paddingTop: '2px',
    paddingBottom: '2px'
  }
});

export const MovesTable = ({
  orders,
  isFlight,
  onColumnSortChange,
  sortBy,
  sortOrder,
  ordersCount,
  setPage,
  setRowsPerPage,
  rowsPerPage,
  search,
  page,
  orderWindowId,
  setCurrentPage,
  currentPage
}) => {
  const classes = useStyles();
  const isMobile = useMobile();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const dispatch = useDispatch();
  const columns = makeColumns(orders);

  const onRowSelectionChangeOverridden = (skip, { data: { dataIndex } = {} }) => {
    if (typeof dataIndex !== 'undefined' && selectedRowIndex === dataIndex) {
      const order = orders[selectedRowIndex];
      if (currentPage === OrderWindowPages.HISTORY && order.orderId === orderWindowId) {
        setCurrentPage(OrderWindowPages.ORDER);
      }
      dispatch(openOrder(order.orderId));
    } else {
      setSelectedRowIndex(dataIndex);
    }
  };

  const customSearch = (searchQuery, currentRow) => {
    if (searchQuery) {
      const [orderId] = currentRow;
      const row = orders.find(item => item.orderId === orderId);
      return JSON.stringify(row)
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    }
    return true;
  };

  const customSort = data => data;

  const options = {
    page: page - 1,
    showExpandableRowsOnMobile: true,
    selectableRowsHideCheckboxesCustom: true,
    alternateTableRowColors: true,
    pagination: !!ordersCount,
    searchText: search,
    smallPadding: isMobile,
    rowsPerPage,
    inFlight: isFlight,
    onColumnSortChange,
    sortOrder: {
      name: sortBy,
      direction: sortOrder
    },
    onChangeRowsPerPage: rpp => {
      setPage(1);
      setRowsPerPage(rpp);
    },
    customSearch,
    customSort,
    onChangePage: p => setPage(p + 1),
    onRowSelectionChangeOverridden,
    count: ordersCount,
    renderExpandableRow: (rowData, rowMeta) => {
      const currentRow = orders[rowMeta.rowIndex];
      return (
        <tr>
          <td colSpan={100}>
            <Table aria-label={`${currentRow.name} current moves`}>
              <TableBody>
                {columns
                  .filter(column => column.options && column.options.showOnMobileAsExpanded && column.label.trim())
                  .map(({ label, name, options: { customBodyRender } }) => (
                    <TableRow>
                      <TableCell classes={{ root: classes.tableCell }} scope="row">
                        <Box fontWeight="bold" maxWidth="160px">
                          {label}
                        </Box>
                      </TableCell>
                      <TableCell classes={{ root: classes.tableCell }}>
                        {customBodyRender
                          ? customBodyRender(currentRow[name], { rowData, rowIndex: rowMeta.rowIndex })
                          : currentRow[name]}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </td>
        </tr>
      );
    }
  };

  return (
    <Box>
      <DataTable data={orders} columns={columns} options={options} />
    </Box>
  );
};

MovesTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  orders: pt.array,
  isFlight: pt.bool,
  onColumnSortChange: pt.func,
  sortBy: pt.string.isRequired,
  sortOrder: pt.string,
  ordersCount: pt.number.isRequired,
  setPage: pt.func,
  setRowsPerPage: pt.func,
  rowsPerPage: pt.number.isRequired,
  search: pt.string,
  page: pt.number,
  orderWindowId: pt.number,
  setCurrentPage: pt.func,
  currentPage: pt.string
};

MovesTable.defaultProps = {
  orders: null,
  isFlight: false,
  onColumnSortChange: null,
  sortOrder: null,
  setPage: null,
  setRowsPerPage: null,
  search: null,
  page: 1,
  orderWindowId: null,
  setCurrentPage: null,
  currentPage: null
};
