import { splitTemplateComment } from 'admin/constants/splitTemplateComment';
import { convertTemplateBuilderVariables } from 'admin/utils/convertTemplateBuilderVariables';
import { CommunicationModality } from 'common-types';
import { TemplateMetadata } from 'common-types/template-builder';
import customCss from 'common/css/email-template-common-css';

function getBodyAttributes(metadata: TemplateMetadata | null, extraStyles = '') {
  return `class="clean-body u_body" style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;color: #000000; background-color: ${
    metadata?.useBackgroundColor ? metadata.backgroundColor : 'transparent'
  }; ${extraStyles}"`;
}

const makeTable = (text: string) => `
<table id="template" cellspacing='0' border='0' cellpadding='0' align="center" style='margin:auto; background-color: #ffffff;'>
    <tbody>${text}</tbody>
</table>`;

const makeBlockRow = (text: string) => `<tr class="builder-block"><td>${text}</td></tr>`;

const getTopBodyAttributes = (marginTop: number) => {
  return `style='background: white; width: 640px; margin-right: auto; margin-left: auto; margin-top: ${marginTop}px; margin-bottom: 16px;'`;
};

const makeTemplate = (
  template: string,
  metadata: TemplateMetadata | null,
  wrapTemplateInDiv: boolean,
  marginTop: number,
) => {
  return `
<!DOCTYPE HTML PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
  <!--[if gte mso 9]>
  <xml>
    <o:OfficeDocumentSettings>
      <o:AllowPNG/>
      <o:PixelsPerInch>96</o:PixelsPerInch>
    </o:OfficeDocumentSettings>
  </xml>
  <![endif]-->
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <meta name="x-apple-disable-message-reformatting">
  <!--[if !mso]><!--><meta http-equiv="X-UA-Compatible" content="IE=edge"><!--<![endif]-->
  <title></title>

  <!--[if !mso]><!--><link href="https://fonts.googleapis.com/css?family=Montserrat:400,700" rel="stylesheet" type="text/css"><link href="https://fonts.googleapis.com/css?family=Playfair+Display:400,700" rel="stylesheet" type="text/css"><!--<![endif]-->
</head>

<body ${getBodyAttributes(metadata)}>
<style type="text/css">${customCss}</style>
<!--[if IE]><div class="ie-container"><![endif]-->
<!--[if mso]><div class="mso-container"><![endif]-->

${wrapTemplateInDiv ? `<div ${getBodyAttributes(metadata, 'min-height: 100%;')}>` : ''}
<div ${getTopBodyAttributes(marginTop)}>
${splitTemplateComment}
${template}
${splitTemplateComment}
</div>
${wrapTemplateInDiv ? '</div>' : ''}
<!--[if mso]></div><![endif]-->
<!--[if IE]></div><![endif]-->
</body>
</html>
`;
};

interface MakeTemplateReadyToSendOptions {
  metadata: TemplateMetadata | null;
  wrapTemplateInDiv: boolean;
  useTemplateBuilderStyles: boolean;
  convertFromBEVariables: boolean;
  modality: CommunicationModality;
  body: string;
  useExistingBody?: boolean;
  marginTop?: number;
}

export const makeTemplateReadyToSend = (options: Partial<MakeTemplateReadyToSendOptions> = {}) => {
  const {
    metadata = null,
    wrapTemplateInDiv = false,
    convertFromBEVariables = false,
    modality = CommunicationModality.EMAIL,
    body = '',
    useExistingBody = false,
    marginTop = 30,
  } = options;

  const isSmsOrInApp = modality === CommunicationModality.SMS || modality === CommunicationModality.IN_APP;

  let template = useExistingBody
    ? body
    : makeTable(
        options.metadata?.blocks
          ?.map((block) => {
            return makeBlockRow(block.body);
          })
          .join('') || '',
      );

  if (isSmsOrInApp) {
    template = body;
  }

  const withFixedVariables = convertTemplateBuilderVariables(template, convertFromBEVariables);
  return isSmsOrInApp ? withFixedVariables : makeTemplate(withFixedVariables, metadata, wrapTemplateInDiv, marginTop);
};
