import { useEffect, useState } from 'react';

import { calculateDuration } from 'admin/utils';

const useDurations = (secondsToAdjust, daysCount = 1) => {
  const [durationOptions30mins, setDurationOptions30mins] = useState([]);
  const [durationOptions15mins, setDurationOptions15mins] = useState([]);
  const [adjustedDurationOptions, setAdjustedDurationOptions] = useState([]);

  useEffect(() => {
    setDurationOptions30mins(calculateDuration({ incrementStep: 30, daysCount }));
  }, []);

  useEffect(() => {
    setDurationOptions15mins(calculateDuration({ incrementStep: 15, daysCount }));
  }, []);

  useEffect(() => {
    setAdjustedDurationOptions(durationOptions30mins.filter((it) => !secondsToAdjust || it[0] >= secondsToAdjust));
  }, [durationOptions30mins, secondsToAdjust]);

  return [durationOptions30mins, durationOptions15mins, adjustedDurationOptions];
};

export default useDurations;
