import React, { FC, useEffect, useState } from 'react';

import { ActivitySourceType, Button, Checkbox, TextInput } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { Map } from 'immutable';
import { OptionsObject, useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import communicationsAPI from 'admin/api/CommunicationsAPI';
import { CreateFollowUpForm } from 'admin/components/OrderWindow/blocks/ActivityManager/CreateFollowUpForm';
import { createFollowUpTask } from 'admin/components/OrderWindow/blocks/ActivityManager/createFollowUpTask';
import { followUpSchema } from 'admin/components/OrderWindow/blocks/ActivityManager/followUpUtils';
import { useActivityLogContext } from 'admin/components/OrderWindow/blocks/ActivityManager/useActivityLogContext';
import { useDefaultFollowUpValues } from 'admin/components/OrderWindow/blocks/ActivityManager/useDefaultFollowUpValues';

interface NewNoteProps {
  activitySource: ActivitySourceType;
  sourceId: number;
}

const defaultNote = {
  involvesSales: false,
  involvesForeman: false,
  involvesDispatch: false,
  involvesCustomer: false,
  content: '',
};

const schema = object({
  content: string().label('Note').max(2000).required(),
  ...followUpSchema,
});

const errorMessage: OptionsObject = { variant: 'error' };
const successMessage: OptionsObject = { variant: 'success' };

const activity = Map({
  [ActivitySourceType.CUSTOMER_ACCOUNT]: 'account',
  [ActivitySourceType.ORDER]: 'order',
});

export const NewNote: FC<NewNoteProps> = ({ sourceId, activitySource }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { reload, newNoteDefault, setNewNoteDefault } = useActivityLogContext();
  const [inFlight, setInFlight] = useState(false);
  const defaultFollowUp = useDefaultFollowUpValues();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: newNoteDefault
      ? newNoteDefault
      : {
          ...defaultFollowUp,
          ...defaultNote,
        },
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    return () => {
      setNewNoteDefault(methods.getValues());
    };
  }, []);

  const onSubmit = (data: any) => {
    setInFlight(true);

    createFollowUpTask(data, activitySource, sourceId)
      .then(() => {
        enqueueSnackbar('Follow up created successfully', successMessage);
      })
      .catch(() => {});

    const newNote = {
      ...data,
      activitySource,
      sourceId,
      activitySources: [{ activitySource, referencedEntityId: sourceId }],
    };

    const name = activity.get(activitySource) || '';

    communicationsAPI
      .saveNote(newNote)
      .then(() => {
        enqueueSnackbar(`Note for current ${name} saved successfully`, successMessage);
        reset({ ...defaultFollowUp, ...defaultNote });
        reload();
      })
      .catch(() => {
        enqueueSnackbar(`Can't save note for this ${name}`, errorMessage);
      })
      .then(() => {
        setInFlight(false);
      });
  };

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Box mx={2} my={1} minHeight={250}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Checkbox color="primary" label="Sales" name="involvesSales" data-testid="salesNote" />
            <Checkbox color="primary" label="Customer" name="involvesCustomer" data-testid="customerNote" />
            <Checkbox color="primary" label="Foreman" name="involvesForeman" data-testid="foremanNote" />
            <Checkbox color="primary" label="Dispatch" name="involvesDispatch" data-testid="dispatchNote" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput fullWidth multiline rows={5} label="Note" name="content" data-testid="textNote" />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <CreateFollowUpForm />
              <Button
                color="primary"
                variant="text"
                onClick={handleSubmit(onSubmit as any)}
                disabled={inFlight}
                loading={inFlight}
                data-testid="saveNote"
              >
                Add Note
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </FormProvider>
  );
};
