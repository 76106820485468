import React, { useEffect, useState } from 'react';

import { Button } from '@elromcoinc/react-shared';
import { AppBar, Box, LinearProgress, makeStyles, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

import {
  changeNotificationStatus,
  dismissAll,
  dismissNotification,
  fetchDismissedNotification,
  fetchNewNotification,
  getDismissedNotificationPageIndex,
  getIsFetchingDismissedNotifications,
  getIsFetchingNewNotifications,
  getIsInProgressChangeStatus,
  getIsInProgressReadDismissAll,
  getNotificationCount,
  getNotificationDismissed,
  getNotificationUnread,
  getUnreadNotificationPageIndex,
  markAllAsRead,
  readNotification,
  setNotificationCount,
  undoDismissNotification,
  unreadNotification,
} from 'admin/autodux/NotificationAutodux';
import { NotificationStatus } from 'admin/constants/NotificationStatus';
import { getAuthUser } from 'admin/selectors/auth';
import TabContainer from 'common/components/TabContainer';
import Tabs from 'common/components/Tabs';

import NotiMsgList from './NotiMsgList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    marginTop: `${theme.mixins.toolbar.minHeight}px`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${theme.mixins.toolbar.sm.minHeight}px)`,
      marginTop: `${theme.mixins.toolbar.sm.minHeight}px`,
    },
  },
  appBar: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dismissAllButton: {
    marginRight: '6px',
  },
  notiMsgListScroller: {
    height: 'calc(100vh - 146px)',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 156px)',
    },
  },
  notiMsgListScrollerForDismissed: {
    height: 'calc(100vh - 110px)',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 120px)',
    },
  },
  swipableView: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
}));

const pageSize = 50;
const { trunc } = Math;

function FullWidthTabs({ children }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);
  const notiData = useSelector(getNotificationUnread);
  const dismissed = useSelector(getNotificationDismissed);
  const count = useSelector(getNotificationCount);
  const dismissedNotificationPageIndex = useSelector(getDismissedNotificationPageIndex);
  const unreadNotificationPageIndex = useSelector(getUnreadNotificationPageIndex);
  const isInProgressChangeStatus = useSelector(getIsInProgressChangeStatus);
  const isInProgressReadDismissAll = useSelector(getIsInProgressReadDismissAll);
  const isFetchingDismissedNotifications = useSelector(getIsFetchingDismissedNotifications);
  const isFetchingNewNotifications = useSelector(getIsFetchingNewNotifications);
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [unreadPageIndex, setUnreadPageIndex] = useState(0);
  const [dismissedPageIndex, setDismissedPageIndex] = useState(0);

  useEffect(() => {
    if (currentUser.id) {
      dispatch(fetchNewNotification()).then(() => {
        dispatch(fetchDismissedNotification());
      });
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (unreadPageIndex) {
      dispatch(fetchNewNotification({ pageIndex: unreadNotificationPageIndex + 1 }));
    }
  }, [unreadPageIndex]);

  useEffect(() => {
    if (dismissedPageIndex) {
      dispatch(fetchDismissedNotification({ pageIndex: dismissedNotificationPageIndex + 1 }));
    }
  }, [dismissedPageIndex]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  const handleLoadMoreUnreadNotifications = () => setUnreadPageIndex((i) => i + 1);
  const handleLoadMoreDismissedNotifications = () => setDismissedPageIndex((i) => i + 1);

  const toggleReadStatus = (id) => {
    let notification = notiData.filter((it) => it.id === id)[0];

    if (notification) {
      notification = notification.set('read', !notification.read);
      const status = notification.read ? NotificationStatus.READ : NotificationStatus.PENDING;
      notification = notification.set('status', status);
      dispatch(notification.read ? readNotification(notification) : unreadNotification(notification));
      dispatch(changeNotificationStatus(notification));
    }
  };

  const toggleDismissedStatus = (id) => {
    let notification = notiData.filter((it) => it.id === id)[0] || dismissed.filter((it) => it.id === id)[0];

    if (notification) {
      if (notification.dismissed) {
        notification = notification.set('read', true);
      }

      notification = notification.set('dismissed', !notification.dismissed);
      const status = notification.dismissed ? NotificationStatus.DISMISSED : NotificationStatus.READ;
      notification = notification.set('status', status);
      dispatch(notification.dismissed ? dismissNotification(notification) : undoDismissNotification(notification));
      dispatch(setNotificationCount(count + (notification.dismissed ? -1 : 1)));
      dispatch(changeNotificationStatus(notification));
    }
  };

  const handleDismissAll = () => {
    dispatch(dismissAll());
  };

  const handleMarkAllAsRead = () => {
    dispatch(markAllAsRead());
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.appBar}>
        <Tabs tabs={['New', 'Dismissed']} value={value} onChange={handleChange} />
        {children}
      </AppBar>
      <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
        <TabContainer dir={theme.direction} className={classes.swipableView}>
          <div className={classes.buttonGroup}>
            <Button
              size="small"
              onClick={handleDismissAll}
              color="primary"
              variant="text"
              className={classNames(classes.dismissAllButton)}
              disabled={isInProgressReadDismissAll}
            >
              Dismiss all
            </Button>
            <Button
              size="small"
              onClick={handleMarkAllAsRead}
              color="primary"
              variant="text"
              disabled={isInProgressReadDismissAll}
            >
              Mark all as Read
            </Button>
          </div>
          {isFetchingNewNotifications && <LinearProgress />}
          <div className={classes.notiMsgListScroller}>
            {value === 0 ? (
              <>
                <NotiMsgList
                  dismissed={false}
                  notiData={notiData.filter((item) => !item.dismissed)}
                  toggleReadStatus={toggleReadStatus}
                  toggleDismissedStatus={toggleDismissedStatus}
                  isInProgressChangeStatus={isInProgressChangeStatus}
                  isInProgressReadDismissAll={isInProgressReadDismissAll}
                />
                {unreadNotificationPageIndex < trunc(notiData.length / pageSize) && (
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      color="primary"
                      onClick={handleLoadMoreUnreadNotifications}
                      loading={isFetchingNewNotifications}
                      disabled={isFetchingNewNotifications}
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </>
            ) : null}
          </div>
        </TabContainer>
        <TabContainer dir={theme.direction} className={classes.swipableView}>
          {isFetchingDismissedNotifications && <LinearProgress />}
          <div className={classes.notiMsgListScrollerForDismissed}>
            {value === 1 ? (
              <>
                <NotiMsgList
                  dismissed
                  notiData={dismissed}
                  toggleReadStatus={toggleReadStatus}
                  toggleDismissedStatus={toggleDismissedStatus}
                  isInProgressChangeStatus={isInProgressChangeStatus}
                  isInProgressReadDismissAll={isInProgressReadDismissAll}
                />
                {dismissedNotificationPageIndex < trunc(dismissed.length / pageSize) && (
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Button
                      color="primary"
                      onClick={handleLoadMoreDismissedNotifications}
                      disabled={isFetchingDismissedNotifications}
                      loading={isFetchingDismissedNotifications}
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </>
            ) : null}
          </div>
        </TabContainer>
      </SwipeableViews>
    </div>
  );
}

FullWidthTabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FullWidthTabs;
