import React, { ChangeEvent, FC, useState } from 'react';

import { BodyText, Modal, OverridableValue, Select, statusIds, useConfirm } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';
import { getNumberOfTrucksError } from 'admin/components/OrderWindow/utils';

const numbersForSelector = new Array(21).fill(0).map((_, idx) => idx);

interface CrewTruckChangeProps {
  title: string;
  onSave: (value: [string, any][]) => void;
  onCancel: () => void;
  name: string;
  value: OverridableValue;
  min?: number;
  max?: number;
}

const CrewTruckChange: FC<CrewTruckChangeProps> = ({ title, onSave, onCancel, value, name, min, max }) => {
  const { order } = useOrderState();
  const [manual, setManual] = useState<number>(value.value() || 0);
  const { isClosing } = useOrderClosingContext();

  let error = null;

  if (max != null && manual > max && !isClosing) {
    error = getNumberOfTrucksError(manual, max);
  }

  if (min != null && manual < min && !isClosing) {
    error = getNumberOfTrucksError(min, manual);
  }

  const numberForSelectorOptions = title.includes('Truck') ? numbersForSelector : numbersForSelector.slice(1);
  const handleChange = ({ target: { value: newValue } }: ChangeEvent<HTMLInputElement>) => setManual(Number(newValue));

  const allTruckAssignmentsForCurrentOrder = order?.services.reduce((acc, service) => {
    return acc + service.truckAssignments.size;
  }, 0) as number;

  const hasChanges = value.value() !== manual;

  const { confirm: confirmWarning, renderDialog: renderConfirmationWarningDialog } = useConfirm({
    title: 'Warning',
    message:
      'You currently do not have all your trucks scheduled.  Please schedule the correct number of trucks you have selected for this move.',
    confirmTitle: 'Ok',
    cancelTitle: 'Cancel',
  });

  const handleOnSave = async () => {
    const changes: [string, OverridableValue][] = [
      [name, value.setManual(manual !== value.calculated ? manual : null)],
    ];
    if (
      order?.getMoveStatus() === statusIds.CAN_BOOK_ONLINE &&
      name.includes('numberOfTrucks') &&
      allTruckAssignmentsForCurrentOrder < manual
    ) {
      const answer = await confirmWarning();
      if (answer) {
        onSave(changes);
      }
      return;
    }
    onSave(changes);
  };

  return (
    <Modal
      open
      maxWidth="xs"
      title={title}
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        {
          label: 'save',
          onClick: handleOnSave,
          disabled: !hasChanges || !!error,
          color: hasChanges ? 'primary' : 'default',
        },
      ]}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              fullWidth
              value={manual}
              name={name}
              onChange={handleChange}
              formError={error}
              data-testid="editCrewTruckSize"
            >
              {numberForSelectorOptions.map((v) => (
                <MenuItem key={v} value={v}>
                  <BodyText color={v === value.calculated ? 'default' : 'error'}>{v}</BodyText>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
      {renderConfirmationWarningDialog()}
    </Modal>
  );
};

export default CrewTruckChange;
