import React, { useState } from 'react';

import { BodyBigText, BodySmallText, BodyText, Button, Modal } from '@elromcoinc/react-shared';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import smsAPI from 'admin/api/SmsAPI';
import { BuyPhoneNumberModal } from 'admin/components/Settings/components/SmsSettings/modals';

const useStyles = makeStyles(({ spacing }) => ({
  card: {
    maxWidth: '12rem',
    minWidth: '12rem',
  },
  cardTitle: {
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  header: {
    padding: '10px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: '0 16px',
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconDelete: {
    padding: '3px',
  },
  primaryText: {
    fontSize: '14px',
  },
  listItem: {
    minWidth: 0,
  },
  denseItem: {
    padding: '3px 10px',
  },
  roundedCard: {
    '&.MuiCard-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

export const SmsPhoneNumber = ({
  isDisabledEnabledSms,
  twilioPhoneNumbers,
  isLoadingTwilioAccount, // eslint-disable-next-line
  allStripeCustomers,
  onBuyPhoneNumber,
  onDeletePhoneNumber,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openModal, setOpenModal] = useState(false);
  const [isFlight, setIsFlight] = useState(false);
  const [isOpenNoticeModal, setIsOpenNoticeModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = (res) => {
    if (res) {
      onBuyPhoneNumber(res);
    }
    setOpenModal(false);
  };

  const handleOpenNoticeModal = () => setIsOpenNoticeModal(true);
  const handleCloseNoticeModal = () => setIsOpenNoticeModal(false);

  const deletePhoneNumber = (data) => {
    setIsFlight(true);
    smsAPI
      .deleteTwilioPhoneNumber(data)
      .then(() => {
        setIsFlight(false);
        onDeletePhoneNumber(data);
      })
      .catch(() => {
        setIsFlight(false);
        enqueueSnackbar('Cannot delete phone number. Try again', { variant: 'error' });
        return {};
      })
      .then(handleCloseNoticeModal);
  };

  const sidNumber = getIn(twilioPhoneNumbers, [0, 'sid'], []);

  const actions = [
    {
      label: 'CANCEL',
      color: 'default',
      onClick: handleCloseNoticeModal,
    },
    {
      label: 'CONTINUE',
      color: 'primary',
      onClick: () => {
        deletePhoneNumber(sidNumber);
      },
    },
  ];

  return (
    <Card className={classes.roundedCard} classes={{ root: classes.card }}>
      <CardHeader
        title="SMS Phone number"
        classes={{ title: classes.cardTitle, content: classes.cardHeader, root: classes.header }}
      />
      {isLoadingTwilioAccount ? (
        <Box display="flex" justifyContent="center">
          <BodySmallText color="primary">Loading...</BodySmallText>
        </Box>
      ) : (
        <CardContent classes={{ root: classes.content }}>
          {twilioPhoneNumbers.length > 0 ? (
            <List disablePadding>
              {twilioPhoneNumbers.map((item) => {
                const labelId = `phoneNumber-${item.phoneNumber}`;
                return (
                  <ListItem
                    key={item.sid}
                    role={undefined}
                    dense
                    component="li"
                    classes={{ dense: classes.denseItem }}
                    divider
                  >
                    <ListItemText
                      id={labelId}
                      primary={`${item.friendlyName}`}
                      classes={{ primary: classes.primaryText }}
                    />
                    <ListItemIcon classes={{ root: classes.listItem }}>
                      <Tooltip title="Delete phone number">
                        <Box component="span">
                          <IconButton
                            classes={{ root: classes.iconDelete }}
                            disabled={isDisabledEnabledSms || isLoadingTwilioAccount}
                            onClick={handleOpenNoticeModal}
                          >
                            <DeleteIcon
                              color={isDisabledEnabledSms || isLoadingTwilioAccount ? 'default' : 'secondary'}
                              size="small"
                            />
                          </IconButton>
                        </Box>
                      </Tooltip>
                      {isOpenNoticeModal && (
                        <Modal open actions={actions} maxWidth="xs" title="Warning" onClose={handleCloseNoticeModal}>
                          <Box display="flex" justifyContent="center">
                            <BodyBigText>Do you really want to delete phone number</BodyBigText>
                          </Box>
                        </Modal>
                      )}
                    </ListItemIcon>
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <BodyText>You do not have phone number for using SMS. Please buy one</BodyText>
          )}
          {isFlight && (
            <Box mt={2}>
              <LinearProgress />
            </Box>
          )}
        </CardContent>
      )}
      <CardActions classes={{ root: classes.cardAction }}>
        <Button
          size="small"
          color="primary"
          onClick={handleOpenModal}
          disabled={isDisabledEnabledSms || twilioPhoneNumbers.length > 0 || isLoadingTwilioAccount}
          loading={isLoadingTwilioAccount || isFlight}
        >
          get phone number
        </Button>
        {openModal && <BuyPhoneNumberModal handleCloseModal={handleCloseModal} />}
      </CardActions>
    </Card>
  );
};

SmsPhoneNumber.propTypes = {
  isDisabledEnabledSms: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  twilioPhoneNumbers: pt.array,
  isLoadingTwilioAccount: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  allStripeCustomers: pt.array,
  onBuyPhoneNumber: pt.func.isRequired,
  onDeletePhoneNumber: pt.func.isRequired,
};

SmsPhoneNumber.defaultProps = {
  isDisabledEnabledSms: false,
  twilioPhoneNumbers: null,
  isLoadingTwilioAccount: false,
  allStripeCustomers: null,
};
