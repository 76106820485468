import React, { useContext, useEffect, useState } from 'react';

import { AccountsPermissions, BodyText, HeaderText, Select, useHasPermission } from '@elromcoinc/react-shared';
import { Box, InputAdornment, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import CircleIcon from '@material-ui/icons/Brightness1';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import AccountAPI from 'admin/api/AccountAPI';
import { SummaryAccountContext } from 'admin/components/AccountWindow/config';
import { ACCOUNT_ACCESS_ALLOWED } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { AccountActivityType } from 'admin/constants/AccountActivityType';
import Circle from 'common/components/Circle';

const useStyles = makeStyles((theme) => ({
  activityBox: {
    width: '100%',
    '& > div > div >.MuiSelect-selectMenu': {
      height: 0,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '100px',
      width: 'auto',
    },
  },
  hideSelectIcon: {
    '& .MuiSelect-iconFilled': {
      display: 'none',
    },
  },
}));

export const SelectActivity = () => {
  const classes = useStyles();
  const canChangeAccountStatus = useHasPermission(AccountsPermissions.PERM_CAN_CHANGE_ACCOUNT_STATUS);
  const canEditOtherUsersAccounts = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);
  const { customer, setCustomer } = useContext(SummaryAccountContext);
  const { ownerCustomerInfo } = customer;
  const { accountAccessAllowed, customerId } = ownerCustomerInfo;
  const [accountStatus, setAccountStatus] = useState((+accountAccessAllowed).toString());
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAccountStatus((+accountAccessAllowed).toString());
  }, [accountAccessAllowed]);

  const customerAccountActivity = [
    { id: '1', label: AccountActivityType.ACTIVE, color: `${theme.palette.success.main}` },
    { id: '0', label: AccountActivityType.NON_ACTIVE, color: `${theme.palette.error.main}` },
  ];

  const accountActivityItem = customerAccountActivity.find((item) => item.id === accountStatus);

  const accountActivityList = customerAccountActivity.map((item) => [
    item.id,
    <Box display="flex" key={item.id}>
      <Circle color={item.color} width="12px" height="12px" mr={1} />
      <BodyText>{item.label}</BodyText>
    </Box>,
  ]);

  const handleChangeStatus = ({ target: { value } }) => {
    setAccountStatus(value);
    if (value === '0') {
      AccountAPI.suspendAccount(customerId)
        .then((res) => {
          setCustomer({ ...customer, ownerCustomerInfo: res });
          enqueueSnackbar(`Account for an existing customer successfully deactivated/suspended`, {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar(`Can't deactivates/suspends the account for an existing customer`, { variant: 'error' });
        });
    }
    if (value === '1') {
      AccountAPI.reactivateAccount(customerId)
        .then((res) => {
          setCustomer({ ...customer, ownerCustomerInfo: res });
          enqueueSnackbar(`Account for an existing customer successfully reactivated`, {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar(`Can't reactivates the account for an existing customer`, { variant: 'error' });
        });
    }
  };

  return (
    <Box
      className={clsx(classes.activityBox, {
        [classes.hideSelectIcon]: !canChangeAccountStatus,
      })}
      p={0.5}
    >
      <Select
        fullWidth
        options={accountActivityList}
        size={!isMobile && 'small'}
        name={ACCOUNT_ACCESS_ALLOWED}
        data-testid="selectActivityStatus"
        InputProps={{
          startAdornment: (
            <Box display="flex">
              <Box color={accountActivityItem.color}>
                <InputAdornment position="start">
                  <CircleIcon />
                </InputAdornment>
              </Box>
              <InputAdornment position="start">
                <HeaderText>
                  <b>&nbsp;Status:&nbsp;</b>
                </HeaderText>
              </InputAdornment>
            </Box>
          ),
          disableUnderline: true,
          readOnly: !canChangeAccountStatus || !canEditOtherUsersAccounts,
        }}
        value={accountStatus}
        onChange={handleChangeStatus}
        primaryBackgroundOnSelectedItem
        SelectProps={{
          renderValue: (selected) => {
            const activity = customerAccountActivity.find(({ id }) => id === selected);
            return (
              <Box display="flex" position="relative" bottom={4}>
                <HeaderText>
                  <b data-testid={`activeStatus-${activity.label}`}>{`${activity.label}`.toUpperCase()}</b>{' '}
                </HeaderText>
              </Box>
            );
          },
        }}
      />
    </Box>
  );
};
