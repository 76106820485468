import React, { ChangeEvent, useEffect, useState } from 'react';

import { BodyText, Link, Order, OverridableValue, numberToCurrency } from '@elromcoinc/react-shared';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { FlexWithNegativeMargin, ValueTableCell } from 'admin/components/OrderWindow/blocks/MoveDetails/refactoring';
import { CurrencyInput } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import RateChange from 'admin/components/OrderWindow/modals/RateChange';
import { OrderWindowModal } from 'common-types';

const travelRateProperty = 'travelRateOverride';

const resetDecimalManual = (property: OverridableValue) => {
  return property?.setManual(property?.manual ? +property?.manual || 0 : null);
};

const useStyles = makeStyles(() => ({
  bloomingDaleDiscount: {
    textDecorationLine: 'line-through',
  },
}));

export const TravelRateRow = () => {
  const classes = useStyles();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const getServicePropertyName = useGetServicePropertyName();
  const { serviceIndex } = useOrderServiceIndex();
  const name = getServicePropertyName(travelRateProperty, 'travelRate');
  const quoteTravelRate = order.getIn(name.split('.')) as OverridableValue;
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const [travelRateOverride, setTravelRateOverride] = useState<OverridableValue>(quoteTravelRate);
  const { isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const discountTravelRate = order.beforeDiscountServiceQuotes.get(serviceIndex)?.[travelRateProperty].value() ?? 0;

  useEffect(() => {
    setTravelRateOverride(quoteTravelRate);
  }, [quoteTravelRate]);

  const handleOnBlur =
    (value: OverridableValue | string | number, previousValue?: OverridableValue | string | number) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name },
      } = event;

      if (value !== previousValue) {
        onChange({ name, value });
      }
    };

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOnMoveDetailsSave = (data: [string, any][]) => {
    data.forEach(([name, value]) => onChange({ name, value }));
    setOpenModal(null);
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Link disabled={isCompleted || isLockSales} onClick={handleOpenModal(OrderWindowModal.TRAVEL_RATE)}>
          Travel Hourly Rate
        </Link>
        {openModal === OrderWindowModal.TRAVEL_RATE && (
          <RateChange
            title="Edit Travel Rate"
            name={name}
            onSave={handleOnMoveDetailsSave}
            onCancel={handleOpenModal(null)}
            open
            rate={travelRateOverride}
            discountRate={discountTravelRate}
          />
        )}
      </TableCell>
      <ValueTableCell>
        <Box width={140} display="flex" alignItems="center">
          <FlexWithNegativeMargin $manual={!!travelRateOverride?.manual}>
            <CurrencyInput
              disabled={!order.disableAutoCalculation || isCompleted || isLockSales}
              onChange={(value: string) => setTravelRateOverride((tr) => tr.setManual(value as any).setExpiration())}
              value={travelRateOverride.value()}
              name={name}
              onBlur={handleOnBlur(resetDecimalManual(travelRateOverride))}
            />
          </FlexWithNegativeMargin>
          {!!discountTravelRate && !isClosing && (
            <BodyText color="textSecondary" className={classes.bloomingDaleDiscount}>
              {numberToCurrency(discountTravelRate)}
            </BodyText>
          )}
        </Box>
      </ValueTableCell>
    </TableRow>
  );
};
