import { isDate, isMatch, parse, format as formatDate } from 'date-fns';
import { BACKEND_DATE_FORMAT } from '@elromcoinc/react-shared';

const toDate = (date, format = BACKEND_DATE_FORMAT) => {
  if (isDate(date)) return date;
  if (isMatch(date, format)) return parse(date, format, new Date());
  return null;
};

const toDateString = (date, format = BACKEND_DATE_FORMAT) => {
  if (isDate(date)) {
    return formatDate(date, format);
  }

  return date;
};

export { toDate, toDateString };
