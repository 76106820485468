import React from 'react';

import { CurrencyInput, DatePicker, ExpirationInput, TextInput } from '@elromcoinc/react-shared';
import { Grid } from '@material-ui/core';

import {
  AMOUNT,
  CARDHOLDER_NAME,
  EXPIRATION,
  LAST_FOUR_DIGITS,
  NOTES,
  PAID_DATE,
  POSTAL_CODE,
  labels,
} from '../CreateCharge/Common';

const CreditCardRecordPaymentFormInputs = () => {
  return (
    <>
      <Grid item xs={12}>
        <CurrencyInput
          /** @ts-ignore */
          label={labels[AMOUNT]}
          fullWidth
          name={AMOUNT}
        />
      </Grid>
      <Grid item xs={12}>
        {/** @ts-ignore */}
        <DatePicker fullWidth label={labels[PAID_DATE]} name={PAID_DATE} />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={labels[CARDHOLDER_NAME]} fullWidth name={CARDHOLDER_NAME} />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={labels[LAST_FOUR_DIGITS]} fullWidth name={LAST_FOUR_DIGITS} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ExpirationInput label={labels[EXPIRATION]} fullWidth name={EXPIRATION} placeholder="MM/YY" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInput label={labels[POSTAL_CODE]} fullWidth name={POSTAL_CODE} />
      </Grid>
      <Grid item xs={12}>
        <TextInput label={labels[NOTES]} fullWidth name={NOTES} />
      </Grid>
    </>
  );
};

export { CreditCardRecordPaymentFormInputs };
