import { Order, Service, TruckAssignmentDto, isLongDistanceService, toDate } from '@elromcoinc/react-shared';
import { isWithinInterval } from 'date-fns';

export const canShowDeliveryTruck = (order: Order, serviceIndex: number, currentDate: Date) => {
  const service: Service = order?.services.get(serviceIndex);
  const index = order?.services.findIndex((s) => s.id === service.id) ?? -1;

  const hasDeliveryDates = service.quote.deliveryDateStart && service.quote.deliveryDateEnd;
  const hasDeliveryTime = service.quote.deliveryTimeEarliest;
  const hasArrivalTime = service.quote.deliveryTruckReturnTime;

  return !!(
    index > -1 &&
    isLongDistanceService(order?.getServiceType(index)) &&
    hasDeliveryDates &&
    hasDeliveryTime &&
    hasArrivalTime &&
    isWithinInterval(currentDate, {
      start: toDate(service.quote.deliveryDateStart)!,
      end: toDate(service.quote.deliveryDateEnd)!,
    }) &&
    !service.truckAssignments.filter((ta: TruckAssignmentDto) => ta.delivery).isEmpty()
  );
};
