import React, { useEffect, useMemo, useState } from 'react';

import {
  AccountsPermissions,
  BaseAddressInputs,
  BodyText,
  Link,
  Modal,
  PhoneFormGroup,
  PhoneInput,
  PhoneType,
  Select,
  Switch,
  TextInput,
  emailYUP,
  phoneNumberYUP,
  phoneYUP,
  useHasPermission,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { getIn, mergeDeep, setIn } from 'immutable';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { boolean, number, object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import {
  ACCOUNT_ACCESS_ALLOWED,
  ACCOUNT_ID,
  ACCOUNT_NAME,
  ACCOUNT_TYPE,
  ADDITIONAL_CONTACT_EMAIL,
  ADDITIONAL_CONTACT_FIRST_NAME,
  ADDITIONAL_CONTACT_LAST_NAME,
  ADDITIONAL_CONTACT_PHONE_NUMBER,
  ADDITIONAL_CONTACT_RECEIVE_EMAILS,
  ADDITIONAL_CONTACT_RECEIVE_SMS,
  ADDRESS,
  ADDRESS_STREET,
  APARTMENT_NUMBER,
  CITY,
  CONTACT_BEST_TIME,
  CONTACT_BEST_WAY,
  COUNTRY,
  ENABLE_RECEIVING_EMAILS,
  OWNER_CUSTOMER_INFO,
  POSTAL_CODE,
  STATE,
} from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { THIS_FUNCTIONAL_IS_COMING_SOON } from 'admin/constants';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import {
  ADDITIONAL_INFO,
  EMAIL,
  ENABLE_SMS_STATUS_UPDATES,
  FIRST_NAME,
  LAST_NAME,
  OTHER_PHONE,
  PHONE_EXTENSION,
  PHONE_NUMBER,
  PHONE_TYPE,
  PRIMARY_PHONE,
} from 'admin/constants/FieldNames';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';

const accountTypeOptions = Object.entries(AccountTypeName);
const accountWithoutPrivateType = accountTypeOptions.filter(([id]) => id !== AccountType.PRIVATE);

const asArray = (path) => path.split('.');
const addressFieldNames = { zipCode: 'postalCode', city: 'city', state: 'state', country: 'country' };

const SMS_CAPABILITY = 'smsCapability';
const PHONE_CARRIER_TYPE = 'phoneCarrierType';
const firstNameLabel = 'First Name';
const lastNameLabel = 'Last Name';
const emailAddressLabel = 'Email Address';
const phoneLabel = 'Phone';
const extension = 'Ext';

const addressLabels = {
  [CITY]: 'City',
  [STATE]: 'State',
  [POSTAL_CODE]: 'ZIP Code',
  [COUNTRY]: 'Country',
};

const primaryPhoneLabels = {
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_NUMBER]: phoneLabel,
  [PHONE_EXTENSION]: extension,
};

const otherPhoneLabels = {
  [PHONE_TYPE]: 'Additional Phone Type',
  [PHONE_NUMBER]: 'Additional Phone',
  [PHONE_EXTENSION]: extension,
};

const labels = {
  [ACCOUNT_TYPE]: 'Account Type',
  [ACCOUNT_NAME]: 'Account Name',
  [OWNER_CUSTOMER_INFO]: {
    [FIRST_NAME]: firstNameLabel,
    [LAST_NAME]: lastNameLabel,
    [EMAIL]: emailAddressLabel,
    [ENABLE_SMS_STATUS_UPDATES]: 'Enable SMS',
    [ENABLE_RECEIVING_EMAILS]: 'Receive Emails',
    [ACCOUNT_ACCESS_ALLOWED]: 'Account Page Active',
    [PRIMARY_PHONE]: {
      [PHONE_TYPE]: primaryPhoneLabels[PHONE_TYPE],
      [PHONE_NUMBER]: primaryPhoneLabels[PHONE_NUMBER],
      [PHONE_EXTENSION]: primaryPhoneLabels[PHONE_EXTENSION],
    },
    [OTHER_PHONE]: {
      [PHONE_TYPE]: otherPhoneLabels[PHONE_TYPE],
      [PHONE_NUMBER]: otherPhoneLabels[PHONE_NUMBER],
      [PHONE_EXTENSION]: otherPhoneLabels[PHONE_EXTENSION],
    },
    [ADDRESS]: {
      [CITY]: addressLabels[CITY],
      [STATE]: addressLabels[STATE],
      [POSTAL_CODE]: addressLabels[POSTAL_CODE],
      [COUNTRY]: addressLabels[COUNTRY],
      [ADDRESS_STREET]: 'Address',
      [APARTMENT_NUMBER]: 'Suite #',
    },
  },
  [ADDITIONAL_CONTACT_FIRST_NAME]: firstNameLabel,
  [ADDITIONAL_CONTACT_LAST_NAME]: lastNameLabel,
  [ADDITIONAL_CONTACT_EMAIL]: emailAddressLabel,
  [ADDITIONAL_CONTACT_PHONE_NUMBER]: phoneLabel,
  [ADDITIONAL_INFO]: 'Additional Info',
  [CONTACT_BEST_WAY]: 'Best Way to Contact',
  [CONTACT_BEST_TIME]: 'Best Time to Contact',
  [ADDITIONAL_CONTACT_RECEIVE_EMAILS]: 'Receive Emails',
  [ADDITIONAL_CONTACT_RECEIVE_SMS]: 'Receive SMSs',
};

const accountTypePath = `${ACCOUNT_TYPE}`;
const accountNamePath = `${ACCOUNT_NAME}`;
const ownerCustomerInfoFirstNamePath = `${OWNER_CUSTOMER_INFO}.${FIRST_NAME}`;
const ownerCustomerInfoLastNamePath = `${OWNER_CUSTOMER_INFO}.${LAST_NAME}`;
const ownerCustomerInfoEmailPath = `${OWNER_CUSTOMER_INFO}.${EMAIL}`;
const ownerCustomerInfoPrimaryPhonePath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}`;
const ownerCustomerInfoOtherPhonePath = `${OWNER_CUSTOMER_INFO}.${OTHER_PHONE}`;
const ownerCustomerInfoAddressPath = `${OWNER_CUSTOMER_INFO}.${ADDRESS}.${ADDRESS_STREET}`;
const ownerCustomerInfoApartmentNumberPath = `${OWNER_CUSTOMER_INFO}.${ADDRESS}.${APARTMENT_NUMBER}`;
const ownerCustomerInfoFullAddressPath = `${OWNER_CUSTOMER_INFO}.${ADDRESS}`;
const ownerCustomerInfoReceiveEmailsPath = `${OWNER_CUSTOMER_INFO}.${ENABLE_RECEIVING_EMAILS}`;
const ownerCustomerInfoAccountAccessAllowedPath = `${OWNER_CUSTOMER_INFO}.${ACCOUNT_ACCESS_ALLOWED}`;
const ownerCustomerInfoEnableSmsPath = `${OWNER_CUSTOMER_INFO}.${ENABLE_SMS_STATUS_UPDATES}`;
const additionalContactFirstNamePath = `${ADDITIONAL_CONTACT_FIRST_NAME}`;
const additionalContactLastNamePath = `${ADDITIONAL_CONTACT_LAST_NAME}`;
const additionalContactEmailPath = `${ADDITIONAL_CONTACT_EMAIL}`;
const additionalContactPhonePath = `${ADDITIONAL_CONTACT_PHONE_NUMBER}`;
const additionalContactReceiveEmailsPath = `${ADDITIONAL_CONTACT_RECEIVE_EMAILS}`;
const additionalContactReceiveSmsPath = `${ADDITIONAL_CONTACT_RECEIVE_SMS}`;
const contactBestWayPath = `${CONTACT_BEST_WAY}`;
const contactBestTimePath = `${CONTACT_BEST_TIME}`;
const additionalInfoPath = `${ADDITIONAL_INFO}`;
const primaryPhoneSmsCapabilityPath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${SMS_CAPABILITY}`;
const primaryPhoneCarrierTypePath = `${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${PHONE_CARRIER_TYPE}`;
const otherPhoneSmsCapabilityPath = `${OWNER_CUSTOMER_INFO}.${OTHER_PHONE}.${SMS_CAPABILITY}`;
const otherPhoneCarrierTypePath = `${OWNER_CUSTOMER_INFO}.${OTHER_PHONE}.${PHONE_CARRIER_TYPE}`;

const schema = object().shape(
  {
    [ACCOUNT_ID]: number().nullable().required(),
    [ACCOUNT_TYPE]: string().label(labels[ACCOUNT_TYPE]).required(),
    [ACCOUNT_NAME]: string().label(labels[ACCOUNT_NAME]).nullable().required().max(62).min(2),
    [OWNER_CUSTOMER_INFO]: object().shape({
      [FIRST_NAME]: string().label(labels[OWNER_CUSTOMER_INFO][FIRST_NAME]).required().min(2).max(32),
      [LAST_NAME]: string().label(labels[OWNER_CUSTOMER_INFO][LAST_NAME]).required().min(2).max(32),
      // currentEmail must be passed into YUP schema context
      [EMAIL]: emailYUP({ name: EMAIL, label: labels[EMAIL] }).when('$currentEmail', (currentEmail, s) => {
        return s.test({
          name: 'unique-email',
          message: 'This email already used',
          test: (value) =>
            emailYUP({ name: EMAIL, label: labels[EMAIL] }).isValidSync(value) && currentEmail !== value
              ? accountAPI.getByEmail(value).then((response) => !response)
              : Promise.resolve(true),
        });
      }),
      [PRIMARY_PHONE]: phoneYUP(primaryPhoneLabels),
      [OTHER_PHONE]: phoneYUP(otherPhoneLabels, { isOptionsPhoneNumber: true }),
      [ADDRESS]: object().shape(
        {
          [ADDRESS_STREET]: string()
            .label(labels[OWNER_CUSTOMER_INFO][ADDRESS][ADDRESS_STREET])
            .nullable()
            .when([POSTAL_CODE], (zip, s) => (zip ? s.required().max(64).min(5) : s)),
          [APARTMENT_NUMBER]: string().label(labels[APARTMENT_NUMBER]).nullable(),
          [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE)
            .label(addressLabels[POSTAL_CODE])
            .transform((v) => v || null)
            .nullable()
            .when([ADDRESS_STREET, `$${POSTAL_CODE}`], (street, zip, s) =>
              !zip && street ? s.required() : s.optional(),
            ),
        },
        [[POSTAL_CODE, ADDRESS_STREET]],
      ),
      [CONTACT_BEST_TIME]: number().label(labels[CONTACT_BEST_TIME]).nullable(),
      [CONTACT_BEST_WAY]: number().label(labels[CONTACT_BEST_WAY]).nullable(),
      [ACCOUNT_ACCESS_ALLOWED]: boolean().label(labels[ACCOUNT_ACCESS_ALLOWED]),
    }),
    [ADDITIONAL_CONTACT_FIRST_NAME]: string()
      .label(labels[ADDITIONAL_CONTACT_FIRST_NAME])
      .nullable()
      .when(
        [ADDITIONAL_CONTACT_LAST_NAME, ADDITIONAL_CONTACT_EMAIL, ADDITIONAL_CONTACT_PHONE_NUMBER],
        (lastName, email, phoneNumber, s) => (lastName || email || phoneNumber ? s.min(2).max(32).required() : s),
      ),
    [ADDITIONAL_CONTACT_LAST_NAME]: string()
      .label(labels[ADDITIONAL_CONTACT_LAST_NAME])
      .nullable()
      .when(
        [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_EMAIL, ADDITIONAL_CONTACT_PHONE_NUMBER],
        (firstName, email, phoneNumber, s) => (firstName || email || phoneNumber ? s.min(2).max(32).required() : s),
      ),
    [ADDITIONAL_CONTACT_EMAIL]: string()
      .transform((v) => v || null)
      .nullable()
      .when(
        [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_LAST_NAME, ADDITIONAL_CONTACT_PHONE_NUMBER],
        (firstName, lastName, phoneNumber, s) =>
          firstName || lastName || phoneNumber
            ? emailYUP({ label: labels[ADDITIONAL_CONTACT_EMAIL], name: ADDITIONAL_CONTACT_EMAIL })
            : s,
      ),
    [ADDITIONAL_CONTACT_PHONE_NUMBER]: string().when(
      [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_LAST_NAME, ADDITIONAL_CONTACT_EMAIL],
      (firstName, lastName, email) =>
        firstName || lastName || email
          ? phoneNumberYUP(labels[ADDITIONAL_CONTACT_PHONE_NUMBER]).required()
          : phoneNumberYUP(labels[ADDITIONAL_CONTACT_PHONE_NUMBER]),
    ),
    [ADDITIONAL_INFO]: string().nullable().label(labels[ADDITIONAL_INFO]).max(999),
  },
  [
    [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_LAST_NAME],
    [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_EMAIL],
    [ADDITIONAL_CONTACT_FIRST_NAME, ADDITIONAL_CONTACT_PHONE_NUMBER],
    [ADDITIONAL_CONTACT_LAST_NAME, ADDITIONAL_CONTACT_EMAIL],
    [ADDITIONAL_CONTACT_LAST_NAME, ADDITIONAL_CONTACT_PHONE_NUMBER],
    [ADDITIONAL_CONTACT_EMAIL, ADDITIONAL_CONTACT_PHONE_NUMBER],
  ],
);

const errorMessage = { variant: 'error' };
const successMessage = { variant: 'success' };

export const CustomerInformationModal = ({ handleCloseModal, customer, onSubmit, setCustomer, open }) => {
  const { ownerCustomerInfo, type } = customer;
  const [hasChanges, setHasChanges] = useState(false);
  const [isDisabledInProcessing, setIsDisabledInProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customerId } = ownerCustomerInfo;
  const canChangeAccountStatus = useHasPermission(AccountsPermissions.PERM_CAN_CHANGE_ACCOUNT_STATUS);
  const context = useMemo(() => ({ currentEmail: getIn(customer, ownerCustomerInfoEmailPath.split('.'), null) }), []);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    context,
    defaultValues: {
      ...customer,
      ownerCustomerInfo: {
        ...ownerCustomerInfo,
        address: ownerCustomerInfo.address || {},
        otherPhone: ownerCustomerInfo.otherPhone || { type: PhoneType.OTHER },
        enableSmsStatusUpdates: !!ownerCustomerInfo.enableSmsStatusUpdates,
      },
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
  } = formMethods;

  context[POSTAL_CODE] = watch(`${OWNER_CUSTOMER_INFO}.${ADDRESS}.${POSTAL_CODE}`);

  const primaryPhoneNumberValue = watch(`${OWNER_CUSTOMER_INFO}.${PRIMARY_PHONE}.${PHONE_NUMBER}`);
  const otherPhoneNumberValue = watch(`${OWNER_CUSTOMER_INFO}.${OTHER_PHONE}.${PHONE_NUMBER}`);

  useUpdatePhoneNumberType(primaryPhoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(primaryPhoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(primaryPhoneSmsCapabilityPath, value),
  });

  useUpdatePhoneNumberType(otherPhoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setCarrierType: (value) => setValue(otherPhoneCarrierTypePath, value),
    setSmsCapability: (value) => setValue(otherPhoneSmsCapabilityPath, value),
  });

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  const commonParts = { control };

  const handleSubmitForm = (data) => {
    let updateCustomer = mergeDeep(customer, data);

    const hasOtherPhoneNumber = getIn(updateCustomer, ['ownerCustomerInfo', 'otherPhone', 'number'], '');

    const hasPostalCode = getIn(updateCustomer, ['ownerCustomerInfo', 'address', 'postalCode'], '');
    const hasStreetAddress = getIn(updateCustomer, ['ownerCustomerInfo', 'address', 'street1'], '');

    if (!hasOtherPhoneNumber) {
      updateCustomer = setIn(updateCustomer, ['ownerCustomerInfo', 'otherPhone'], null);
    }

    if (!hasPostalCode || !hasStreetAddress) {
      updateCustomer = setIn(updateCustomer, ['ownerCustomerInfo', 'address'], null);
    }

    onSubmit(updateCustomer);

    setIsDisabledInProcessing(true);

    const activity = getIn(data, ownerCustomerInfoAccountAccessAllowedPath.split('.'), false);
    const previousActivity = getIn(customer, ownerCustomerInfoAccountAccessAllowedPath.split('.'), false);

    if (!activity && previousActivity) {
      accountAPI
        .suspendAccount(customerId)
        .catch(() => {
          enqueueSnackbar(`Can't deactivate/suspend the account for an existing customer`, errorMessage);
        })
        .then((res) => {
          setCustomer((state) => ({ ...state, ownerCustomerInfo: res }));
        });
    }
    if (!previousActivity && activity) {
      accountAPI
        .reactivateAccount(customerId)
        .catch(() => {
          enqueueSnackbar(`Can't reactivate the account for an existing customer`, errorMessage);
        })
        .then((res) => {
          setCustomer((state) => ({ ...state, ownerCustomerInfo: res }));
        });
    }

    accountAPI
      .updateAccountInfo({ ...updateCustomer, updated: null, created: null })
      .then(() => {
        enqueueSnackbar('Customer information saved successfully', successMessage);
      })
      .catch(() => {
        enqueueSnackbar(`Can't update customer information`, errorMessage);
      })
      .then(() => {
        handleCloseModal();
        setIsDisabledInProcessing(false);
      });
  };

  return (
    <Modal
      color="grey"
      open={open}
      title="Customer Information"
      maxWidth="md"
      disabledInProcessing={isDisabledInProcessing}
      onClose={handleCloseModal}
      actions={[
        {
          label: 'cancel',
          onClick: handleCloseModal,
          dataTestId: 'cancelAccountModalWindow',
        },
        {
          label: 'save',
          disabled: !hasChanges,
          loading: isDisabledInProcessing,
          color: !hasChanges ? 'default' : 'primary',
          onClick: handleSubmit(handleSubmitForm),
          dataTestId: 'saveAccountModalWindow',
        },
      ]}
    >
      <FormProvider {...formMethods}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6}>
            <Grid item sm={5}>
              <Select
                fullWidth
                data-testid="accountTypeSelect"
                label={getIn(labels, asArray(accountTypePath), '')}
                name={accountTypePath}
                primaryBackgroundOnSelectedItem
                {...commonParts}
                options={type !== AccountType.PRIVATE ? accountWithoutPrivateType : accountTypeOptions}
              />
            </Grid>
          </Grid>
          <Grid item sm={6} xs={6}>
            <TextInput
              fullWidth
              label={getIn(labels, asArray(accountNamePath), '')}
              name={accountNamePath}
              data-testid="accountName"
              {...commonParts}
            />
          </Grid>
        </Grid>
        <Box mb={1.5} mt={1.5}>
          <Box mb={1}>
            <BodyText>
              <b>Address Information</b>
            </BodyText>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10}>
              <TextInput
                fullWidth
                data-testid="ownerCustomerAddress"
                label={getIn(labels, asArray(ownerCustomerInfoAddressPath), '')}
                name={ownerCustomerInfoAddressPath}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextInput
                fullWidth
                type="number"
                data-testid="ownerCustomerApartmentNumber"
                label={getIn(labels, asArray(ownerCustomerInfoApartmentNumberPath), '')}
                name={ownerCustomerInfoApartmentNumberPath}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12}>
              <BaseAddressInputs
                fieldNames={addressFieldNames}
                labels={addressLabels}
                data-testid="ownerCustomerFullAddress"
                name={ownerCustomerInfoFullAddressPath}
                displayCountryField
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={1.5} mt={2.5}>
          <Box mb={1}>
            <BodyText>
              <b>Primary Contact</b>
            </BodyText>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                data-testid="ownerCustomerFirstName"
                label={getIn(labels, asArray(ownerCustomerInfoFirstNamePath), '')}
                name={ownerCustomerInfoFirstNamePath}
                {...commonParts}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                data-testid="ownerCustomerLastName"
                label={getIn(labels, asArray(ownerCustomerInfoLastNamePath), '')}
                name={ownerCustomerInfoLastNamePath}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                type="email"
                fullWidth
                data-testid="ownerCustomerEmail"
                label={getIn(labels, asArray(ownerCustomerInfoEmailPath), '')}
                name={ownerCustomerInfoEmailPath}
                {...commonParts}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <PhoneFormGroup
                labels={primaryPhoneLabels}
                data-testid="ownerCustomerPrimaryPhone"
                name={ownerCustomerInfoPrimaryPhonePath}
                displayPhoneCarrierIcon
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <PhoneFormGroup
                labels={otherPhoneLabels}
                data-testid="ownerCustomerOtherPhone"
                name={ownerCustomerInfoOtherPhonePath}
                displayPhoneCarrierIcon
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                primaryBackgroundOnSelectedItem
                label={getIn(labels, asArray(contactBestWayPath), '')}
                data-testid="bestWayContactSelect"
                name={contactBestWayPath}
                {...commonParts}
                options={[
                  ['Email', 'Email'],
                  ['Text', 'Text'],
                  [phoneLabel, phoneLabel],
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                primaryBackgroundOnSelectedItem
                label={getIn(labels, asArray(contactBestTimePath), '')}
                data-testid="bestTimeContactSelect"
                name={contactBestTimePath}
                options={[
                  ['Morning', 'Morning'],
                  ['Afternoon', 'Afternoon'],
                  ['Evening', 'Evening'],
                ]}
                {...commonParts}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                fullWidth
                label={getIn(labels, asArray(additionalInfoPath), '')}
                data-testid="ownerCustomerAdditionalInfo"
                name={additionalInfoPath}
                rowsMax={4}
                multiline
                {...commonParts}
              />
            </Grid>
          </Grid>
        </Box>
        <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
          <Box mt={2} mb={1} maxWidth="300px">
            <Link className="disabled" data-testid="sendResetPasswordSMS">
              Send reset password via SMS
            </Link>
          </Box>
        </Tooltip>
        <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
          <Box mt={1} mb={1} maxWidth="300px">
            <Link className="disabled" data-testid="sendResetPasswordEmail">
              Send reset password via Email
            </Link>
          </Box>
        </Tooltip>
        <Box display="flex" justifyContent="flex-start" mb={2} flexWrap="wrap">
          <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
            <Box mr={7}>
              <Switch
                color="primary"
                control={control}
                data-testid="ownerCustomerReceiveEmails"
                name={ownerCustomerInfoReceiveEmailsPath}
                label={getIn(labels, asArray(ownerCustomerInfoReceiveEmailsPath), '')}
              />
            </Box>
          </Tooltip>
          <Box mr={7}>
            <Switch
              color="primary"
              control={control}
              data-testid="ownerCustomerEnableSms"
              name={ownerCustomerInfoEnableSmsPath}
              label={getIn(labels, asArray(ownerCustomerInfoEnableSmsPath), '')}
            />
          </Box>
          <Box mr={7}>
            <Switch
              color="primary"
              data-testid="ownerCustomerAccountAccessAllowed"
              name={ownerCustomerInfoAccountAccessAllowedPath}
              control={control}
              disabled={!canChangeAccountStatus}
              label={getIn(labels, asArray(ownerCustomerInfoAccountAccessAllowedPath), '')}
            />
          </Box>
        </Box>
        <Box mb={1.5} mt={1.5}>
          <Box mb={1}>
            <BodyText>
              <b>Additional Contact</b>
            </BodyText>
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                data-testid="additionalContactFirstName"
                label={getIn(labels, asArray(additionalContactFirstNamePath), '')}
                name={additionalContactFirstNamePath}
                {...commonParts}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                data-testid="additionalContactLastName"
                label={getIn(labels, asArray(additionalContactLastNamePath), '')}
                name={additionalContactLastNamePath}
                {...commonParts}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                type="email"
                fullWidth
                data-testid="additionalContactEmail"
                label={getIn(labels, asArray(additionalContactEmailPath), '')}
                name={additionalContactEmailPath}
                {...commonParts}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PhoneInput
                fullWidth
                label={getIn(labels, asArray(additionalContactPhonePath), '')}
                data-testid="additionalContactPhone"
                name={additionalContactPhonePath}
                {...commonParts}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-start" mb={2} mt={2} flexWrap="wrap">
            <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
              <Box mr={7}>
                <Switch
                  color="primary"
                  control={control}
                  data-testid="additionalContactReceiveEmails"
                  name={additionalContactReceiveEmailsPath}
                  label={getIn(labels, asArray(additionalContactReceiveEmailsPath), '')}
                />
              </Box>
            </Tooltip>
            <Tooltip title={THIS_FUNCTIONAL_IS_COMING_SOON}>
              <Box mr={7}>
                <Switch
                  color="primary"
                  control={control}
                  data-testid="additionalContactReceiveSms"
                  name={additionalContactReceiveSmsPath}
                  label={getIn(labels, asArray(additionalContactReceiveSmsPath), '')}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </FormProvider>
    </Modal>
  );
};

CustomerInformationModal.propTypes = {
  handleCloseModal: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  customer: pt.object.isRequired,
  onSubmit: pt.func,
  open: pt.bool.isRequired,
};

CustomerInformationModal.defaultProps = {
  onSubmit: null,
};
