import React, { useCallback, useEffect } from 'react';

import { ActivitySourceType, PaymentCheckDto, PaymentStatus } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import { formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { CheckPaymentFormInputs } from '../Forms';
import { PaymentTypeSelector } from '../Forms/PaymentTypeSelector';
import { usePaymentSourceContext } from '../PaymentsSourceContext';
import { CashPaymentDto, CashPaymentProps, cashScema } from './CashPayment';
import { AMOUNT, CHECK_NUMBER, PAID_DATE, PAYMENT_TYPE, labels } from './Common';

export interface CheckPaymentDto extends CashPaymentDto {
  checkNumber: string;
}

const schema = cashScema.shape({
  [CHECK_NUMBER]: string().label(labels[CHECK_NUMBER]).nullable().required(),
});

interface CheckPaymentProps extends CashPaymentProps {}

const CheckPayment = ({
  amount: initialAmount,
  type,
  isDepositAvailable,
  onMount,
  onAmountChange,
  ifDepositUnavailableHint,
  onTypeChange,
  activitySources,
}: CheckPaymentProps) => {
  const { sourceId, activitySource } = usePaymentSourceContext();
  const methods = useForm<CheckPaymentDto>({
    resolver: yupResolver(schema),
    defaultValues: { amount: initialAmount as number, [PAID_DATE]: new Date(), [PAYMENT_TYPE]: type },
  });

  const amount = methods.watch(AMOUNT);

  useEffect(() => {
    onAmountChange?.(amount);
  }, [amount]);

  useEffect(() => {
    onTypeChange?.(methods.watch(PAYMENT_TYPE));
  }, [methods.watch(PAYMENT_TYPE)]);

  const createCheckPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: CheckPaymentDto) => {
          paymentActionsApi
            .createCheckPayment(
              PaymentCheckDto.createPaymentCheckDto({
                date: formatISO(data.date),
                amount: data.amount,
                message: data.message,
                type,
                status: PaymentStatus.PROCESSED,
                sourceId,
                activitySource,
                activitySources,
                checkNumber: data.checkNumber,
              }),
            )
            .then(resolve)
            .catch(reject);
        }, reject)();
      }),
    [methods.handleSubmit, sourceId, activitySource, type, activitySources?.length],
  );

  useEffect(() => {
    onMount?.(createCheckPayment);
  }, [createCheckPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        {activitySource === ActivitySourceType.ORDER && (
          <PaymentTypeSelector
            ifDepositUnavailableHint={ifDepositUnavailableHint}
            isDepositAvailable={isDepositAvailable}
          />
        )}
        <CheckPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { CheckPayment };
