import React, { useState } from 'react';

import {
  Discount as DiscountEntity,
  Link,
  Order,
  PriceAdjustment,
  PriceAdjustmentType,
  Service,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';
import { List } from 'immutable';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import DiscountModal from 'admin/components/OrderWindow/modals/Discount';
import { ZERO_MONEY } from 'admin/constants/DefaultValues';
import { getServiceClosingPropertyName } from 'admin/utils';

const formatMoney = (money: number) => `$${formatCurrency(money)}`;

enum Modals {
  DISCOUNT = 'DISCOUNT',
}

function calculateDiscountTotal(services: Immutable.List<Service>) {
  return services.some((s) => s.quote.discount.type === PriceAdjustmentType.PERCENT)
    ? services.map((s) => s.quote.discount.asHumanReadableDiscount() || ZERO_MONEY).join(', ')
    : formatMoney(services.reduce((accumulator, s) => accumulator + s.quote.discount.amount, 0));
}

export const Discount = () => {
  const { order } = useOrderState() as { order: Order };
  const { isClosing } = useOrderClosingContext();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const getServicePropertyName = useGetServicePropertyName();
  const { onChange } = useOrderChangeSet();
  const name = isClosing ? getServiceClosingPropertyName(serviceIndex, 'discount') : getServicePropertyName('discount');
  const discount = order.getIn(name.split('.')) as DiscountEntity;
  const [openModal, setOpenModal] = useState<Modals | null>(null);

  const handleDiscountChange = (value: PriceAdjustment) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const renderDiscountValue = () => {
    const services = order.services as List<Service>;
    const quote = order.getServiceQuote(serviceIndex);

    if (isSelectedAllServices) {
      return calculateDiscountTotal(services);
    }

    return quote.discount.asHumanReadableDiscount() || ZERO_MONEY;
  };

  const renderClosingDiscountValue = () => {
    const services = order.closingOrderDetail.services as List<Service>;
    const quote = services.get(serviceIndex)?.quote!;

    if (isSelectedAllServices) {
      return calculateDiscountTotal(services);
    }

    return quote.discount.asHumanReadableDiscount() || ZERO_MONEY;
  };

  const discountValue = isClosing ? renderClosingDiscountValue() : renderDiscountValue();

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <HighlightedTableRow data-testId="discount">
      <TableCell size="small" padding="none">
        <Link
          color={discountValue !== ZERO_MONEY && discountValue !== '0%' && !isSelectedAllServices ? 'error' : 'primary'}
          disabled={isSelectedAllServices}
          onClick={toggleOpenModal(Modals.DISCOUNT)}
        >
          Discount
        </Link>
        {Modals.DISCOUNT === openModal && (
          <DiscountModal open onSave={handleDiscountChange} onCancel={toggleOpenModal(null)} discount={discount} />
        )}
      </TableCell>
      <TableCell>
        <Text value={discountValue} data-testId={'discountValue'} />
      </TableCell>
    </HighlightedTableRow>
  );
};
