import React, { useEffect } from 'react';

import {
  BodyText,
  CreditCardPaymentProfileDto,
  OnlinePaymentTransactionDto,
  TextInput,
  cardTypeIcon,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { object, string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { NOTES, labels } from '../CreateCharge/Common';

const schema = object({
  [NOTES]: string().nullable().max(1000),
});

interface EditCreditCardOnlineProps {
  payment: OnlinePaymentTransactionDto;
  onMount?: (trigger: () => Promise<any>) => void;
}

const EditCreditCardOnline = ({ payment, onMount }: EditCreditCardOnlineProps) => {
  const methods = useForm<{ [NOTES]: string }>({
    resolver: yupResolver(schema),
    defaultValues: {
      [NOTES]: payment.message,
    },
  });

  useEffect(() => {
    onMount?.(
      () =>
        new Promise((resolve, reject) => {
          methods.handleSubmit((data: { [NOTES]: string }) => {
            paymentActionsApi.updateNoteOnCreditCardPayment(payment.id, data[NOTES]).then(resolve).catch(reject);
          })();
        }),
    );
  }, []);

  function renderCardDetails() {
    const paymentMethod: CreditCardPaymentProfileDto = payment.paymentMethod as CreditCardPaymentProfileDto;
    return (
      <>
        <Box display="flex" flexDirection="row" alignItems="center">
          {/* @ts-ignore*/}
          <Box mr={1}>{cardTypeIcon[(paymentMethod.cardType || 'Visa').toUpperCase()]}</Box>
          <BodyText>
            <b>{paymentMethod.cardType}</b>
          </BodyText>
          <BodyText>&nbsp;ending in {paymentMethod.lastFourDigits},&nbsp;</BodyText>
          <BodyText>Expires&nbsp;{format(parseISO(paymentMethod.expiration), 'MM/yyyy')}</BodyText>
        </Box>
      </>
    );
  }

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box my={1}>
            {renderCardDetails()}
            <BodyText paragraph>
              {payment.paymentMethod.firstName} {payment.paymentMethod.lastName}
            </BodyText>
            <BodyText paragraph>
              <b>Amount:</b> {`$${formatCurrency(payment.amount)}`}
            </BodyText>
            {payment.refundAmount > 0 && (
              <BodyText paragraph>
                <b>Refund amount:</b> {`$${formatCurrency(payment.refundAmount)}`}
              </BodyText>
            )}
            {!!payment.transactionId && (
              <BodyText paragraph>
                <b>Transaction ID:</b> {payment.transactionId}
              </BodyText>
            )}
            {!!payment.authorizationCode && (
              <BodyText paragraph>
                <b>Auth Code:</b> {payment.authorizationCode}
              </BodyText>
            )}
            {!!payment.description && (
              <BodyText paragraph>
                <b>Payment Description:</b> {payment.description}
              </BodyText>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextInput label={labels[NOTES]} fullWidth name={NOTES} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export { EditCreditCardOnline };
