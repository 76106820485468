const AccessGroupName = Object.freeze({
  ADMIN: 'ADMIN',
  ACCOUNTS: 'ACCOUNTS',
  CALENDAR: 'CALENDAR',
  COMMUNICATION: 'COMMUNICATION',
  EMAIL: 'EMAIL',
  ORDERS: 'ORDERS',
  PAYMENTS: 'PAYMENTS',
  REPORTS: 'REPORTS',
  SERVICES: 'SERVICES',
  TASKS: 'TASKS',
  DISPATCH: 'DISPATCH',
  BILL_OF_LADING: 'BILL_OF_LADING',
});

const AccessGroupNames = Object.freeze({
  ADMIN: 'Admin',
  ACCOUNTS: 'Accounts',
  CALENDAR: 'Calendar',
  COMMUNICATION: 'Communication',
  EMAIL: 'Email',
  ORDERS: 'Orders',
  PAYMENTS: 'Payments',
  REPORTS: 'Reports',
  SERVICES: 'Services',
  TASKS: 'Tasks',
  DISPATCH: 'Dispatch',
  BILL_OF_LADING: 'Bill of Lading',
});

const AccessGroupSortingOrder = [
  AccessGroupName.ACCOUNTS,
  AccessGroupName.ORDERS,
  AccessGroupName.ADMIN,
  AccessGroupName.PAYMENTS,
  AccessGroupName.SERVICES,
  AccessGroupName.REPORTS,
  AccessGroupName.COMMUNICATION,
  AccessGroupName.TASKS,
  AccessGroupName.CALENDAR,
  AccessGroupName.EMAIL,
  AccessGroupName.DISPATCH,
];

export { AccessGroupName, AccessGroupNames, AccessGroupSortingOrder };
