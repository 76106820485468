import React from 'react';
import { Modal } from '@elromcoinc/react-shared';
import pt from 'prop-types';
import { Box } from '@material-ui/core';

export const CurrentNoteModal = ({ handleCloseModal }) => {
  const actions = [
    {
      label: 'cancel',
      color: 'default',
      onClick: handleCloseModal
    },
    {
      label: 'add',
      color: 'primary',
      onClick: handleCloseModal
    }
  ];
  return (
    <Modal open title="View Note" onClose={handleCloseModal} actions={actions}>
      <Box>View Note</Box>
    </Modal>
  );
};

CurrentNoteModal.propTypes = {
  handleCloseModal: pt.func.isRequired
};
