import styled from 'styled-components';

const Date = styled.div<{ disabled?: boolean }>`
  position: relative;
  height: ${({ theme }) => theme.spacing(2)}px;
  ${({ disabled }) => disabled && 'pointer-events: none; opacity: 0.5;'}
  input {
    padding: 0;
    font-weight: bold;
  }
  button {
    padding: 2px;
  }
  fieldset {
    border: none;
  }
  svg {
    font-size: 16px;
  }
  .MuiFilledInput-underline {
    &:before,
    &:after {
      border: none;
    }
  }
  .MuiTextField-root {
    left: ${({ theme }) => theme.spacing(-5)}px;
  }
  .MuiFormHelperText-root {
    z-index: ${({ theme }) => theme.zIndex.modal + 1};
    margin-left: 120px;
    min-width: 75px;
    background-color: ${({ theme }) => theme.palette.common.white};
    margin-top: -17px;
    height: 18px;
  }
  @media (min-width: 1500px) {
    .MuiFormHelperText-root {
      width: 100%;
    }
  }
`;

export default Date;
