import { Fragment } from 'react';

import { BOLLaborHoursBy } from '@elromcoinc/moveboard-setting-react';
import { toDate } from '@elromcoinc/react-shared';
import { List } from 'immutable';

import { CrewStatusLog } from 'admin/components/CrewStatusLog';
import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { durationToMinutes } from 'admin/utils';

export const OrderCrewStatusLog = () => {
  const { order } = useOrderState();
  const { serviceIndex } = useOrderServiceIndex();
  const quote = order?.getServiceQuote(serviceIndex);
  const service = order?.services.get(serviceIndex);
  const bolServiceRoster = order?.orderBillOfLadingDetail?.serviceRosterBillOfLadingDtos?.get(serviceIndex);
  const bolQuote = order?.orderBillOfLadingDetail?.getServiceQuote(serviceIndex);
  const isTimerButton = (order?.laborHoursBy ?? BOLLaborHoursBy.TIME_INPUT) === BOLLaborHoursBy.START_BUTTON;
  const isUseAllSignatures = bolServiceRoster?.useLoadingUnloadingSignatures && !isTimerButton;
  const isCalculateDrivingTimeAsLabor = isUseAllSignatures && !bolQuote.isDrivingTimeAddedToTravel;

  const timeKeys = (
    quote.dispatchJob ? [...quote.dispatchJob?.loadingTimeList, ...quote.dispatchJob?.unloadingTimeList] : []
  )
    .map((t) => `${t.startDateTime}-${t.endDateTime}`)
    .join(',');

  return (
    <Fragment key={timeKeys}>
      <CrewStatusLog
        job={quote.dispatchJob}
        date={toDate(service.date)!}
        startTime={quote.startTimeEarliest}
        durationInSeconds={quote?.laborDurationRangeOverride?.durationMax?.originalSeconds}
        isCalculateDrivingTimeAsLabor={isCalculateDrivingTimeAsLabor}
        waypoints={bolQuote?.waypoints ?? List()}
        finalBillableTravelTimeInMinutes={(bolQuote?.finalBillableTravelTimeForQuote?.getRoundedSeconds() ?? 0) / 60}
        estimatedFinalBillableTravelTimeInMinutes={
          (quote?.finalBillableTravelTimeForQuote?.getRoundedSeconds() ?? 0) / 60
        }
        totalTimeInMinutes={durationToMinutes(bolQuote?.totalTimeRange?.durationMax)}
      />
    </Fragment>
  );
};
