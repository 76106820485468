import { BACKEND_DATE_FORMAT, statusIds } from '@elromcoinc/react-shared';
import { add, endOfMonth, format, startOfMonth } from 'date-fns';

import {
  ALL_UPCOMING_MOVES,
  BOOKED_THIS_MONTH,
  MOVES_THIS_MONTH,
} from 'admin/components/Orders/config/OrdersConstants';

const makeBookedFilters = (selectedBookedOption, initialFilters = {}) => {
  const startDate = format(startOfMonth(new Date()), BACKEND_DATE_FORMAT);
  const endDate = format(endOfMonth(new Date()), BACKEND_DATE_FORMAT);

  const bookedFilters = {
    status: statusIds.BOOKED,
    ...initialFilters,
  };

  if (selectedBookedOption === BOOKED_THIS_MONTH) {
    bookedFilters.statusUpdated = [startDate, endDate];
  }

  if (selectedBookedOption === MOVES_THIS_MONTH) {
    bookedFilters.moveStartDate = [startDate, endDate];
  }

  if (selectedBookedOption === ALL_UPCOMING_MOVES) {
    bookedFilters.moveStartDate = [
      format(new Date(), BACKEND_DATE_FORMAT),
      format(add(new Date(), { years: 5 }), BACKEND_DATE_FORMAT),
    ];
  }

  return bookedFilters;
};

export { makeBookedFilters };
