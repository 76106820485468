export const SALES = 'SALES';
export const FOREMAN = 'FOREMAN';
export const HELPER = 'HELPER';
export const DRIVER = 'DRIVER';
export const CUSTOMER_SERVICE = 'CUSTOMER_SERVICE';
export const MANAGER = 'MANAGER';
export const FINANCE = 'FINANCE';
export const DISPATCHER = 'DISPATCHER';

enum PositionTypes {
  SALES = 'SALES',
  FOREMAN = 'FOREMAN',
  HELPER = 'HELPER',
  DRIVER = 'DRIVER',
  CUSTOMER_SERVICE = 'CUSTOMER_SERVICE',
  MANAGER = 'MANAGER',
  FINANCE = 'FINANCE',
  DISPATCHER = 'DISPATCHER',
}

const PositionTypesNames = {
  SALES: 'Sales',
  FOREMAN: 'Foreman',
  HELPER: 'Helper',
  DRIVER: 'Driver',
  CUSTOMER_SERVICE: 'Customer Service',
  MANAGER: 'Manager',
  FINANCE: 'Finance',
  DISPATCHER: 'Dispatcher',
} as const;

const managersPositions = Object.keys(PositionTypes).filter(
  (position) =>
    position !== PositionTypes.FOREMAN && position !== PositionTypes.DRIVER && position !== PositionTypes.HELPER,
) as PositionTypes[];

export { PositionTypes, PositionTypesNames, managersPositions };
