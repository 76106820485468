import React from 'react';

import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import { Text } from 'admin/components/OrderWindow/components';
import { roundTo2 } from 'admin/utils';

export default function MoveSizeInfo({ Icon, weight, size }) {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={1}>
        <Text value={`${roundTo2(size)}/${roundTo2(weight)}`} data-testId="moveSizeInfo" />
      </Box>
      <Icon fontSize="small" />
    </Box>
  );
}

MoveSizeInfo.propTypes = {
  Icon: PropTypes.element.isRequired,
  size: PropTypes.number.isRequired,
  weight: PropTypes.number.isRequired,
};
