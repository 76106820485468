import React, { FC, useEffect, useMemo, useState } from 'react';

import {
  ActivitySourceType,
  AuthorizedImage,
  BodyBigText,
  Button,
  Modal,
  ModalProps,
  Password,
  PhoneFormGroup,
  PhoneInput,
  SignatureModal,
  TextInput,
  phoneExtensionYUP,
  phoneNumberYUP,
  phoneTypeYUP,
} from '@elromcoinc/react-shared';
import { AccountsPermissions, AdminPermissions, useHasPermission } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FileCallback } from 'react-dropzone-component';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';

import fileAPI from 'admin/api/FileAPI';
import userAPI from 'admin/api/UserAPI';
import { updateUser } from 'admin/autodux/UsersAutodux';
import {
  CONFIRM_PASSWORD,
  CUSTOMER_ID,
  DEFAULT_EMPLOYEE_PHOTO,
  EMPLOYEE_ACCOUNT_MESSAGE,
  EMPLOYEE_CONTACT_PHONE,
  EMPLOYEE_ID,
  EMPLOYEE_PROFILE,
  EMPLOYEE_PROFILE_CONTACT_PHONE,
  EMPLOYEE_PROFILE_MESSAGE,
  EMPLOYEE_PROFILE_PHOTO,
  EMPLOYEE_PROFILE_SIGNATURE,
  PASSWORD,
  PHONE,
  PHONE_EXTENSION,
  PHONE_TYPE,
  labels,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { getAuthUser } from 'admin/selectors/auth';
import { Checked, DropZone } from 'common/components';
import { fileToFormData } from 'common/utils';

interface EmployeeProfileModalProps {
  alertProps: ModalProps;
}

const useStyles = makeStyles({
  signatureImage: {
    width: '100%',
  },
});

const phoneFieldNames = { number: PHONE, extension: PHONE_EXTENSION, type: PHONE_TYPE };

const CONTEXT_PASSWORD = `$${PASSWORD}`;
const CONTEXT_CONFIRM_PASSWORD = `$${CONFIRM_PASSWORD}`;

const schema = object().shape({
  [PHONE_TYPE]: phoneTypeYUP(labels[PHONE_TYPE]),
  [PHONE]: phoneNumberYUP(labels[PHONE]).required(),
  [PHONE_EXTENSION]: phoneExtensionYUP(labels[PHONE_EXTENSION], PHONE_TYPE),
  [PASSWORD]: string()
    .label(labels[PASSWORD])
    .nullable()
    .when([CONTEXT_PASSWORD], (password: string, currentSchema) => {
      return password ? currentSchema.min(3).max(20).nullable().required() : currentSchema;
    }),
  [CONFIRM_PASSWORD]: string()
    .nullable() // @ts-ignore
    .when([PASSWORD, CONTEXT_CONFIRM_PASSWORD], (password, confirmPassword, schema) => {
      return password && password !== confirmPassword
        ? schema.test('match-password', 'The password and confirm password fields do not match', () => false)
        : schema;
    }),
  [EMPLOYEE_PROFILE]: object({
    [EMPLOYEE_CONTACT_PHONE]: phoneNumberYUP(labels[EMPLOYEE_CONTACT_PHONE]),
    [EMPLOYEE_ACCOUNT_MESSAGE]: string().nullable().max(1000),
  }).nullable(),
});

interface FormContext extends Record<typeof PASSWORD | typeof CONFIRM_PASSWORD, string> {}

const avatarProps = {
  alt: 'Employee avatar',
  style: { width: '105px', height: '105px' },
};

const activitySource = ActivitySourceType.EMPLOYEE;

const EmployeeProfileModal: FC<EmployeeProfileModalProps> = ({ alertProps }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inFlight, setInFlight] = useState(false);
  const user = useSelector(getAuthUser) || {};
  const context = useMemo<FormContext>(() => ({ [PASSWORD]: '', [CONFIRM_PASSWORD]: '' }), []);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    context,
  });

  const { reset, watch, setValue, handleSubmit } = methods;
  context[PASSWORD] = watch(PASSWORD);
  context[CONFIRM_PASSWORD] = watch(CONFIRM_PASSWORD);
  const photoId = watch(EMPLOYEE_PROFILE_PHOTO);
  const signatureId = watch(EMPLOYEE_PROFILE_SIGNATURE);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const canUpdateLoginPassword = useHasPermission(AdminPermissions.PERM_CAN_UPDATE_LOGIN_PASSWORD);
  const canUpdatePhone = useHasPermission(AccountsPermissions.PERM_CAN_EDIT_ACCOUNTS);

  const onSave = (data: any) => {
    setInFlight(true);

    Promise.all([
      userAPI.updateEmployeeProfile({
        ...(data[EMPLOYEE_PROFILE] || {}),
        [EMPLOYEE_ID]: data[CUSTOMER_ID],
      }),
      dispatch(updateUser(data)),
    ])
      .then(() => {
        enqueueSnackbar('Employee profile update successfully', { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar('Cannot update employee profile', { variant: 'error' });
      })
      .then(() => setInFlight(false));
  };

  if (alertProps?.actions?.[1]?.onClick) {
    alertProps.actions[1].onClick = handleSubmit(onSave as any);
  }

  const handleCloseSignatureModal = () => {
    setShowSignatureModal(false);
  };

  const handleAddedEmployeePhoto: FileCallback = (file) => {
    fileAPI.uploadFile(fileToFormData(file, { activitySource, sourceId: user.id })).then((dto) => {
      setValue(EMPLOYEE_PROFILE_PHOTO, (dto as unknown as FileDTO).id);
    });
  };

  const handleSaveSignature = (value: string, canvas?: HTMLCanvasElement) => {
    handleCloseSignatureModal();

    canvas?.toBlob((file) => {
      fileAPI
        .uploadFile(
          fileToFormData(file, {
            name: 'manager-signature' + user.id + '.png',
            type: 'PNG',
            activitySource,
            sourceId: user.id,
          }),
        )
        .then((dto) => {
          setValue(EMPLOYEE_PROFILE_SIGNATURE, (dto as unknown as FileDTO).id);
        });
    });
  };

  const handleShowSignatureModal = () => {
    setShowSignatureModal(true);
  };

  useEffect(() => {
    if (!user.id) {
      enqueueSnackbar('Please login as employee', { variant: 'warning' });
      return;
    }

    Promise.all([userAPI.retrieveEmployee(user.id), userAPI.getEmployeeProfile(user.id)]).then(
      ([employee, profile]) => {
        reset({ ...employee, [EMPLOYEE_PROFILE]: profile });
      },
    );
  }, [user.id, alertProps.open]);

  return (
    // @ts-ignore
    <FormProvider {...methods}>
      <Modal title="Profile" {...alertProps} maxWidth="md">
        <Box m={2} display="flex" flexDirection="column">
          <Box mt={-1} height={8}>
            {inFlight && <LinearProgress color="primary" />}
          </Box>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Box display="flex" mb={1}>
                <Checked value={watch(`${EMPLOYEE_PROFILE}.showInfoToCustomer`)} />
                <BodyBigText>Show to customer</BodyBigText>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="center">
                <AuthorizedImage
                  imageId={photoId}
                  defaultValue={DEFAULT_EMPLOYEE_PHOTO}
                  variant="avatar"
                  avatarProps={avatarProps}
                />
                <Box ml={1}>
                  <BodyBigText>
                    <b>{`${user.firstName} ${user.lastName}`}</b>
                  </BodyBigText>
                </Box>
              </Box>
              <Box mt={1}>
                <DropZone addedfile={handleAddedEmployeePhoto} text="Drop or click here to upload employee photo" />
              </Box>
              <Box>
                <Box mt={1} position="relative">
                  {signatureId ? (
                    <AuthorizedImage
                      imageId={signatureId}
                      imageProps={{ className: classes.signatureImage, alt: 'Employee signature' }}
                    />
                  ) : (
                    'No employee signature'
                  )}
                </Box>
                <Button variant="text" color="primary" textTransform="capitalize" onClick={handleShowSignatureModal}>
                  Add new signature
                </Button>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <PhoneInput
                /* @ts-ignore */
                name={EMPLOYEE_PROFILE_CONTACT_PHONE}
                label={labels[EMPLOYEE_CONTACT_PHONE]}
                fullWidth
              />
              <Box mt={2}>
                <TextInput
                  name={EMPLOYEE_PROFILE_MESSAGE}
                  label="Display Message"
                  fullWidth
                  multiline
                  placeholder="Entered message will be displayed on customer account page"
                  rows={5}
                />
              </Box>
            </Grid>
            <Grid item sm={12} xs={12}>
              <PhoneFormGroup disabled={!canUpdatePhone} labels={labels} fieldNames={phoneFieldNames} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Password /*@ts-ignore*/
                name={PASSWORD}
                label="New Password"
                fullWidth
                allowSeePassword={canUpdateLoginPassword}
                autoComplete="password"
                disabled={!canUpdateLoginPassword}
                showLockIcon={!canUpdateLoginPassword}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Password /* @ts-ignore */
                name={CONFIRM_PASSWORD}
                label="Confirm New Password"
                fullWidth
                allowSeePassword={canUpdateLoginPassword}
                autoComplete="new-password"
                disabled={!canUpdateLoginPassword}
                showLockIcon={!canUpdateLoginPassword}
              />
            </Grid>
          </Grid>
        </Box>
        <VideoButton video="XKNKpNoT9II" position="absolute" />
      </Modal>
      {showSignatureModal && <SignatureModal onSave={handleSaveSignature} onClose={handleCloseSignatureModal} />}
    </FormProvider>
  );
};

export { EmployeeProfileModal };
