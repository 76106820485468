import { isAfter, isBefore } from 'date-fns';

interface isNoOverlapTimeParam {
  startTimeEarliest: Date | number;
  startTimeEarliestToCompare: Date | number;
  endTime: Date | number;
  endTimeToCompare: Date | number;
}

export const isNoOverlapTime = ({
  startTimeEarliest,
  startTimeEarliestToCompare,
  endTime,
  endTimeToCompare,
}: isNoOverlapTimeParam) => {
  return isBefore(startTimeEarliest, startTimeEarliestToCompare) && isAfter(endTime, endTimeToCompare);
};
