import React from 'react';

import { Button, IconButton, useHoverTooltip } from '@elromcoinc/react-shared';
import { Box, Theme, Tooltip, createStyles, makeStyles } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { Task } from 'common-types/Task';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
    },
    arrow: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper,
    },
  }),
);

interface EditTaskButtonsProps {
  onEditTask: () => void;
  onDeleteTask: () => void;
  onToggleCompleted: () => void;
  task: Task;
}

export const EditTaskButtons = ({ task, onEditTask, onDeleteTask, onToggleCompleted }: EditTaskButtonsProps) => {
  const classes = useStyles();
  const tooltip = useHoverTooltip();
  const onClickMiddleware = (fn: () => void) => () => {
    fn();
    tooltip.tooltipProps.onClose();
  };

  const buttons = tooltip.renderTooltip(
    <Box display="flex" flexDirection="column" p={1}>
      <Button color="primary" variant="text" onClick={onClickMiddleware(onEditTask)} size="small">
        Edit
      </Button>
      <Button color="primary" variant="text" onClick={onClickMiddleware(onToggleCompleted)} size="small">
        {`Mark as ${task.isCompleted ? 'uncomplete' : 'complete'}`}
      </Button>
      <Button color="secondary" variant="text" onClick={onClickMiddleware(onDeleteTask)} size="small">
        Delete
      </Button>
    </Box>,
  );

  return (
    <Box display="flex">
      <Tooltip {...tooltip.tooltipProps} title={buttons} arrow classes={classes}>
        <Box>
          <IconButton color="primary">
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};
