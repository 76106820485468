import axios from 'axios';

import { GeneralStatsReportDTO } from 'admin/components/Reports/GeneralStatisticsTab';
import { ReportProps } from 'admin/components/Reports/config';
import { PriceRange } from 'common-types';
import { BasicReportsFilter } from 'common-types/BasicReportsFilter';
import { SalesByEmployeeStatsTotals } from 'common-types/SalesByEmployeeStatsTotals';
import { DateRange } from 'common-types/statistic/DateRange';

interface IncomeReportBody {
  branchIds?: number[];
  dateRange: {
    startDate: string;
    endDate: string;
  };
}

interface GeneralPeriodStats {
  totalOrdersInPeriod: number;
  totalBookedInPeriod: number;
  conversionRateForPeriod: ConversionRate;
  totalBookedOrderIds?: number[];
  totalOrderIds?: number[];
  closingTotal: number;
  closingGap: PriceRange;
  totalBookedEstimatedMax: number;
  totalBookedEstimatedMin: number;
  totalEstimateMax: number;
  totalEstimateMin: number;
}

interface GeneralPageDTO<T = any> {
  stats: T[];
  totalEntries: number;
  startDate: ReportProps;
  endDate: ReportProps;
  totalDaysInReport: number;
  total: GeneralPeriodStats;
}

interface PaymentPageDTO<T = any> {
  page: PageDTO<T>;
  totalForPeriod?: number;
}

class ReportsAPI {
  getPaymentsReport<T>(params: PaymentPageParams = {}) {
    return axios.get<PaymentPageDTO<T>, PaymentPageDTO<T>>('/api/moving/stats/reports/payments/', {
      params: { pageIndex: 0, pageSize: 25, sortBy: '', sortOrder: 'desc', ...params },
    });
  }

  downloadPaymentsReport(params: PaymentPageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/payments/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getSalesReport<T>(params: PageParams = {}, data: Partial<BasicReportsFilter>) {
    return axios.post<PageDTO<T, SalesByEmployeeStatsTotals>, PageDTO<T, SalesByEmployeeStatsTotals>>(
      '/api/moving/stats/reports/general/sales/',
      { branchIds: [1], ...data },
      {
        params: { ...params },
      },
    );
  }

  downloadSalesReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/sales/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getCompanyIncomeReport<T, K>(body: IncomeReportBody, params: PageParams = {}) {
    return axios.post<PageDTO<T, K>, PageDTO<T, K>>('/api/moving/stats/reports/income/', body, {
      params: { pageIndex: 0, pageSize: 25, sortBy: 'startDate', sortOrder: 'desc', ...params },
    });
  }

  downloadCompanyIncomeReport(body: IncomeReportBody) {
    return axios.post<Blob, Blob>('/api/moving/stats/reports/income/file/', body, {
      responseType: 'blob',
    });
  }

  getAllBranches(parentId: number) {
    return axios.get(`/api/admin/branches/${parentId}/`);
  }

  getAssignedEmployeeStatsReport<T>(params: Partial<PageParams> = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/assignments/', {
      params: { ...params },
    });
  }

  downloadAssignedEmployeeStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/assignments/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getMoveSizeStatsReport<T>(params: Partial<PageParams> = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/moveSizes/', {
      params: { ...params },
    });
  }

  downloadMoveSizeStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/moveSizes/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getServiceTypeStatsReport<T>(params: Partial<PageParams> = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/serviceTypes/', {
      params: { ...params },
    });
  }

  downloadServiceTypeStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/serviceTypes/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getMoveTypeStatsReport<T>(params: Partial<PageParams> = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/moveTypes/', {
      params: { ...params },
    });
  }

  downloadMoveTypeStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/moveTypes/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getEstimateTypeStatsReport<T>(params: Partial<PageParams> = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/estimateTypes/', {
      params: { ...params },
    });
  }

  downloadEstimateStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/estimateTypes/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getGeneralStatsReport(params: PageParams = {}) {
    return axios.get<GeneralStatsReportDTO, GeneralStatsReportDTO>('/api/moving/stats/reports/general/summary/', {
      params: { ...params },
    });
  }

  getSmsStatsReport<T>(params: PageParams = {}) {
    return axios.get<PageDTO<T>, PageDTO<T>>('/api/communications/sms/stats/reports/', {
      params: { pageIndex: 0, pageSize: 25, sortBy: '', sortOrder: 'desc', ...params },
    });
  }

  downloadSmsStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/communications/sms/stats/reports/file/', {
      params: { sortBy: 'statisticsDate', sortOrder: 'desc', ...params },
      responseType: 'blob',
    });
  }

  getLeadSourceStatsReport<T>(params: PageParams = {}) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/referredVia/', {
      params: { ...params },
    });
  }

  downloadLeadSourceStatsReport(params: PageParams = {}) {
    return axios.get<Blob, Blob>('/api/moving/stats/reports/general/referredVia/file/', {
      params: { ...params },
      responseType: 'blob',
    });
  }

  getSalesPerformanceStatistic<T>(params: DateRange) {
    return axios.get<GeneralPageDTO<T>, GeneralPageDTO<T>>('/api/moving/stats/reports/general/employee_performance', {
      params: { ...params },
    });
  }
}

export default new ReportsAPI();
