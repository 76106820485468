import { ActivitySourceType, CrewDto, JobDto, JobStatus } from '@elromcoinc/react-shared';
import axios from 'axios';

import { CrewManagementMode, EmployeeAvailabilityStatus } from 'admin/components/Dispatch/DispatchDayView/types';
import { JobAssignmentActivityDto } from 'admin/components/Dispatch/JobAssignment/types';
import { PositionTypes } from 'admin/constants/PositionTypes';

interface CrewsProps {
  startDate: string;
  endDate: string;
  modes: CrewManagementMode[];
}

// we don't care about rest params. I need only dispatchJob
interface ServiceSummaryDto {
  dispatchJob: JobDto;
}

class CrewDispatchApi {
  getCrews({ startDate, endDate, modes }: CrewsProps) {
    const url = `/api/moving/dispatch/schedule/crews/startDate/${startDate}/endDate/${endDate}/`;
    return axios.get<CrewsProps, CrewDto[]>(url, { data: {}, params: { modes } });
  }

  getCrewMembers() {
    const url = '/api/dispatch/unavailability/crew-members/';
    return axios.get<CrewsProps, EmployeeAvailabilityStatus[]>(url, {
      data: {},
      params: { 'employee.positionType': [PositionTypes.FOREMAN, PositionTypes.HELPER, PositionTypes.DRIVER] },
    });
  }

  getCrewLogs(crewScheduleDateId: number, activitySource: ActivitySourceType) {
    const url = `/api/moving/dispatch/schedule/crewScheduleDateId/${crewScheduleDateId}/activities/${activitySource}`;
    return axios.get<{ crewScheduleDateId: number }, PageDTO>(url, { data: {}, params: { pageSize: 100 } });
  }

  createCrewLog(activity: JobAssignmentActivityDto) {
    return axios.post<{ activitySource: ActivitySourceType }, PageDTO>(
      '/api/moving/order/jobAssignment/activity',
      activity,
    );
  }

  getCrewScheduleDateId(date: string) {
    const url = `/api/dispatch/crewScheduleDate/lookupId/${date}/`;
    return axios.get<string, number>(url, { data: {} });
  }

  saveCrew(crew: CrewDto) {
    const url = '/api/moving/dispatch/schedule/crew';
    return crew.id ? axios.put<CrewDto, CrewDto>(url, crew) : axios.post<CrewDto, CrewDto>(url, crew);
  }

  saveCrews(crews: CrewDto[]) {
    const url = '/api/moving/dispatch/schedule/crews';
    return axios.post<CrewDto[], CrewDto[]>(url, crews);
  }

  assignCrew(serviceId: number, jobDto: JobDto) {
    const url = `/api/moving/dispatch/jobs/service/${serviceId}`;
    return axios.post<ServiceSummaryDto, ServiceSummaryDto>(url, jobDto);
  }

  updateCrew(jobDto: JobDto) {
    const url = `/api/moving/dispatch/jobs/service`;
    return axios.put<ServiceSummaryDto, ServiceSummaryDto>(url, jobDto);
  }

  setStatus(jobId: number, status: JobStatus, isPickup: boolean) {
    const url = `/api/moving/dispatch/jobs/job/${jobId}/${isPickup ? 'status' : 'deliveryStatus'}/${status}`;
    return axios.put(url, {});
  }

  deleteCrew(crewId: number) {
    return axios.delete(`/api/moving/dispatch/schedule/crew/${crewId}`);
  }
}

export default new CrewDispatchApi();
