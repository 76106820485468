import React, { useEffect, useState } from 'react';

import { BodyText, CurrencyInput, Modal, Order } from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';
import { LocalAtm as CashIcon, CreditCard as CreditIcon } from '@material-ui/icons';

import { useOrderChangeSet, useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles(({ spacing }) => ({
  withoutBorder: {
    borderBottom: 'none',
  },
  smallInput: {
    '& input': {
      padding: `${spacing(0, 0.5)}!important`,
      height: spacing(3),
    },
  },
}));

interface TipsModalProps {
  onClose: () => void;
  pickupCashTipsName: string;
  pickupTipsName: string;
  deliveryCashTipsName: string;
  deliveryTipsName: string;
  isLongDistance: boolean;
}

export const TipsModal = ({
  onClose,
  pickupCashTipsName,
  pickupTipsName,
  deliveryCashTipsName,
  deliveryTipsName,
  isLongDistance,
}: TipsModalProps) => {
  const classes = useStyles();
  const { isCompleted } = useOrderClosingContext();
  const { onChange } = useOrderChangeSet();
  const { order } = useOrderState() as { order: Order };
  const pickupCashTipsValue = (order.getIn(pickupCashTipsName.split('.')) as number) ?? 0;
  const pickupTipsValue = (order.getIn(pickupTipsName.split('.')) as number) ?? 0;
  const deliveryCashTipsValue = (order.getIn(deliveryCashTipsName.split('.')) as number) ?? 0;
  const deliveryTipsValue = (order.getIn(deliveryTipsName.split('.')) as number) ?? 0;
  const [pickupCashTipsManual, setPickupCashTipsManual] = useState<string | number>(pickupCashTipsValue);
  const [pickupTipsManual, setPickupCreditCardTipsManual] = useState<string | number>(pickupTipsValue);
  const [deliveryCashTipsManual, setDeliveryCashTipsManual] = useState<string | number>(pickupCashTipsValue);
  const [deliveryTipsManual, setDeliveryCreditCardTipsManual] = useState<string | number>(deliveryTipsValue);

  useEffect(() => {
    setPickupCashTipsManual(pickupCashTipsValue);
  }, [pickupCashTipsValue]);

  useEffect(() => {
    setPickupCreditCardTipsManual(pickupTipsValue);
  }, [pickupTipsValue]);

  useEffect(() => {
    setDeliveryCashTipsManual(deliveryCashTipsValue);
  }, [deliveryCashTipsValue]);

  useEffect(() => {
    setDeliveryCreditCardTipsManual(deliveryTipsValue);
  }, [deliveryTipsValue]);

  const handleOnBlur = (value: number, name: string) => () => {
    onChange({ name, value });
  };

  const onPickupChangeCashTips = (grandTotal: string) => setPickupCashTipsManual(grandTotal);
  const onPickupChangeCreditTips = (grandTotal: string) => setPickupCreditCardTipsManual(grandTotal);
  const onDeliveryChangeCashTips = (grandTotal: string) => setDeliveryCashTipsManual(grandTotal);
  const onDeliveryChangeCreditTips = (grandTotal: string) => setDeliveryCreditCardTipsManual(grandTotal);

  const disabled = isCompleted;

  const actions = [
    {
      label: 'Close',
      onClick: onClose,
    },
  ];

  return (
    <Modal title="Manage Tips" open actions={actions} onClose={onClose}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" alignItems="center">
                <Box width={180}>
                  <BodyText>{isLongDistance ? 'Pickup Cash Tips:' : 'Cash Tips:'}</BodyText>
                </Box>
                <Box width={180} display="flex" alignItems="center" justifyContent="center">
                  <Box mr={2}>
                    <CashIcon />
                  </Box>
                  <CurrencyInput
                    className={classes.smallInput}
                    disabled={disabled}
                    size="small"
                    hiddenLabel
                    value={pickupCashTipsManual}
                    onChange={onPickupChangeCashTips}
                    onBlur={handleOnBlur(+pickupCashTipsManual, pickupCashTipsName)}
                    name={'pickupCashTips'}
                    data-testId={pickupCashTipsName}
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell size="small" className={classes.withoutBorder}>
              <Box display="flex" alignItems="center">
                <Box width={180}>
                  <BodyText>{isLongDistance ? 'Pickup Tips:' : 'Tips:'}</BodyText>
                </Box>
                <Box width={180} display="flex" alignItems="center" justifyContent="center">
                  <Box mr={2}>
                    <CreditIcon />
                  </Box>
                  <CurrencyInput
                    className={classes.smallInput}
                    disabled={disabled}
                    size="small"
                    hiddenLabel
                    value={pickupTipsManual}
                    onChange={onPickupChangeCreditTips}
                    onBlur={handleOnBlur(+pickupTipsManual, pickupTipsName)}
                    name={'pickupTips'}
                    data-testId={pickupTipsName}
                  />
                </Box>
              </Box>
            </TableCell>
          </TableRow>
          {isLongDistance && (
            <>
              <TableRow>
                <TableCell size="small" className={classes.withoutBorder}>
                  <Box display="flex" alignItems="center">
                    <Box width={180}>
                      <BodyText>Delivery Cash Tips:</BodyText>
                    </Box>
                    <Box width={180} display="flex" alignItems="center" justifyContent="center">
                      <Box mr={2}>
                        <CashIcon />
                      </Box>
                      <CurrencyInput
                        className={classes.smallInput}
                        disabled={disabled}
                        size="small"
                        hiddenLabel
                        value={deliveryCashTipsManual}
                        onChange={onDeliveryChangeCashTips}
                        onBlur={handleOnBlur(+deliveryCashTipsManual, deliveryCashTipsName)}
                        name={'deliveryCashTips'}
                        data-testId={pickupCashTipsName}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small" className={classes.withoutBorder}>
                  <Box display="flex" alignItems="center">
                    <Box width={180}>
                      <BodyText>Delivery Tips:</BodyText>
                    </Box>
                    <Box width={180} display="flex" alignItems="center" justifyContent="center">
                      <Box mr={2}>
                        <CreditIcon />
                      </Box>
                      <CurrencyInput
                        className={classes.smallInput}
                        disabled={disabled}
                        size="small"
                        hiddenLabel
                        value={deliveryTipsManual}
                        onChange={onDeliveryChangeCreditTips}
                        onBlur={handleOnBlur(+deliveryTipsManual, deliveryTipsName)}
                        name={'deliveryTips'}
                        data-testId={deliveryTipsName}
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </Modal>
  );
};
