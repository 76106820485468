import React, { useState } from 'react';

import {
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  IconButton,
  Link,
  Modal,
  useAlert,
} from '@elromcoinc/react-shared';
import { Box, Collapse, Tooltip, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

import ActivityType from 'admin/constants/ActivityType';
import useRefWithCallback from 'admin/hooks/useRefWithCallback';
import { CommunicationModality } from 'common-types';

import getActivityIcon from './getActivityIcon';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '8px 8px 8px 16px',
  },
}));

export default function ActivityItem({
  label,
  update,
  desc,
  activityType,
  type,
  actionIcon,
  id: itemId,
  onEdit,
  hiddenContent,
  expandedItems,
  text,
  subject,
}) {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const isManualEnteredActivity = activityType === ActivityType.MANUALLY_ENTERED_ACTIVITY;
  const onMouseOver = () => setHover(true);
  const onMouseOut = () => setHover(false);
  const isExpanded = expandedItems && expandedItems.has(itemId);
  const activityTypeToShow = isManualEnteredActivity ? type : activityType;
  const isEmailActivityLog = type === CommunicationModality.EMAIL;
  const { setShowAlert, alertProps } = useAlert({
    cancelTitle: 'Close',
  });
  const handleViewEmail = () => setShowAlert(true);
  const handleEdit = () => onEdit(itemId, activityType);
  const manualEnteredActivityProps = isManualEnteredActivity ? { onClick: handleEdit } : {};
  const defaultIcon = actionIcon || getActivityIcon(activityTypeToShow);
  const setListItemRef = useRefWithCallback(
    (node) => {
      node.addEventListener('mouseenter', onMouseOver);
      node.addEventListener('mouseleave', onMouseOut);
    },
    (node) => {
      node.removeEventListener('mouseenter', onMouseOver);
      node.removeEventListener('mouseleave', onMouseOut);
    },
  );

  return (
    <Box className={classes.listItem} ref={setListItemRef} position="relative">
      <Box flexGrow={1}>
        <BodyText wordBreak>{label}</BodyText>
        {!!desc && (
          <Box display="flex" alignItems="center">
            <BodySmallText>{desc}</BodySmallText>
            {update && <Link size="small">&nbsp;Appointment Update</Link>}
          </Box>
        )}
        <Collapse in={hiddenContent && isExpanded && !isEmailActivityLog}>
          <Box pt="5px" pb="10px">
            <BodyText wordBreak>{hiddenContent}</BodyText>
          </Box>
        </Collapse>
        {isEmailActivityLog && (
          <Box ml={1}>
            <BodyText>
              Subject:
              <Button size="small" textTransform="capitalize" color="primary" variant="text" onClick={handleViewEmail}>
                {subject}
              </Button>
            </BodyText>
          </Box>
        )}
      </Box>
      {!!activityType && (
        <Box display="flex" flexDirection="row" alignItems="start">
          <Box display="flex" flexDirection="row" alignItems="center">
            <BodyText>{activityTypeToShow}</BodyText>
            <Tooltip title={isManualEnteredActivity && hover ? 'Edit' : activityTypeToShow}>
              <div>
                <IconButton color="primary" variant="text" size="small" {...manualEnteredActivityProps}>
                  {isManualEnteredActivity && hover ? <EditIcon /> : defaultIcon}
                </IconButton>
              </div>
            </Tooltip>
          </Box>
        </Box>
      )}
      <Modal {...alertProps} title="Email" maxWidth="md">
        <BodyBigText gutterBottom>
          <b>{subject}</b>
        </BodyBigText>
        <Box dangerouslySetInnerHTML={{ __html: text }} />
      </Modal>
    </Box>
  );
}

ActivityItem.propTypes = {
  label: PropTypes.string.isRequired,
  desc: PropTypes.string,
  activityType: PropTypes.string,
  id: PropTypes.number,
  actionIcon: PropTypes.node,
  update: PropTypes.bool,
  hiddenContent: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  expandedItems: PropTypes.object,
  text: PropTypes.string,
  subject: PropTypes.string,
  onEdit: PropTypes.func,
};

ActivityItem.defaultProps = {
  update: false,
  activityType: null,
  desc: null,
  actionIcon: null,
  id: null,
  onEdit: null,
  expandedItems: null,
  hiddenContent: null,
};
