import { addHours, addSeconds, differenceInHours, isAfter, setMinutes, setSeconds } from 'date-fns';

const minimumHours = 6;

export const makeTimeLine = (
  firstTime: Date,
  lastTime: Date | null,
  durationInSeconds: number,
  leftHoursGap = 2,
  rightHoursGap = 2,
) => {
  const start = setMinutes(setSeconds(firstTime, 0), 0);
  let end = setMinutes(setSeconds(lastTime || start, 0), 0);

  if (isAfter(addSeconds(start, durationInSeconds), end)) {
    end = addSeconds(start, durationInSeconds);
  }

  const hours = Math.abs(differenceInHours(end, start));

  if (hours < minimumHours) {
    end = addHours(start, minimumHours);
  }

  const beginOfLine = addHours(start, leftHoursGap * -1);
  const totalHours = Math.abs(differenceInHours(beginOfLine, addHours(end, rightHoursGap)));

  if (!totalHours) {
    return [];
  }

  return new Array(totalHours).fill(0).map((_, index) => {
    return addHours(beginOfLine, index);
  });
};
