import React, { ChangeEvent, FC, SetStateAction, useEffect, useState } from 'react';

import { SettingName as SharedSettingNames } from '@elromcoinc/moveboard-setting-react';
import {
  BodyBigText,
  BodyText,
  Checkbox,
  CurrencyInput,
  Duration,
  EstimateType,
  LongDistanceTariffDetails,
  Modal,
  MoveTypeTypes,
  OverridableDurationValue,
  OverridableValue,
  Radio,
  RadioGroup,
  Select,
  isLongDistanceService,
  numberToCurrency,
  statusIds,
  toBackEndDate,
  toDate,
  toDateString,
  useConfirm,
} from '@elromcoinc/react-shared';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { isDate } from 'date-fns';
import styled from 'styled-components';

import { ratePerDistanceProperty, ratePerSizeUnitProperty } from 'admin/components/OrderWindow/OrderWindowContants';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderSettingUnits,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import {
  checkMaxOvernightDaysWarning,
  checkMaxPeriodInStorage,
  getNumberOfTrucksError,
} from 'admin/components/OrderWindow/utils';
import { ArrivalWindowByTime } from 'admin/constants/ArrivalWindowByTime';
import { ArrivalWindowToStartEndTime } from 'admin/constants/ArrivalWindowToStartEndTime';
import settingName from 'admin/constants/SettingName';
import SettingName from 'admin/constants/SettingName';
import { ArrivalWindow, ArrivalWindowEnum, DeliveryWindow, DeliveryWindowEnum } from 'admin/constants/StartEndTimes';
import { getRates } from 'admin/selectors';
import { getServiceClosingPropertyName } from 'admin/utils';
import { renderLaborForRate } from 'admin/utils/renderLaborForRate';
import { MovingAndStorageSetting } from 'common-types';

import DateInput from '../../CreateOrder/DateInput';
import useDurations from '../hooks/useDurations';
import useTimeDuration from '../hooks/useTimeDuration';

const OverridableItem = styled(Grid)<{ $manual?: boolean }>`
  input,
  p {
    color: ${(props) => {
      return props.$manual ? '#FF5B5B !important' : 'inherit';
    }};
  }
`;

const arrivalWindowOptions = Object.entries(ArrivalWindow);
const deliveryWindowOptions = Object.entries(DeliveryWindow);
const selectOptions = new Array(17).fill(0).map((_, i) => i);
enum TimeModes {
  SETTINGS = '0',
  CUSTOM = '1',
  TBD = '2',
}

const { GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES } = SharedSettingNames;

const CREW_SIZE_NAME = 'crewSize';
const TRUCK_SIZE_NAME = 'trucks';
const MOVE_DATE_NAME = 'moveDate';

interface MoveDetailsProps {
  onCancel: () => void;
  rates: ReturnType<typeof getRates>;
  onSave: (data: any) => void;
}

const onlySalesPropertyNames = {
  arrivalWindow: 'arrivalWindow',
  deliveryWindow: 'deliveryWindow',
  startTimeEarliest: 'startTimeEarliest',
  startTimeLatest: 'startTimeLatest',
  laborTimeMin: 'laborDurationRangeOverride.durationMin.originalSeconds',
  laborTimeMax: 'laborDurationRangeOverride.durationMax.originalSeconds',
};

const restPropertyNames = {
  ...onlySalesPropertyNames,
  longDistanceTariffDetails: 'longDistanceTariffDetails',
  longDistanceRatePerUnitOverride: 'longDistanceTariffDetails.longDistanceRatePerUnitOverride',
  laborRateOverride: 'laborRateOverride',
  travelRateOverride: 'travelRateOverride',
  numberOfMovers: 'numberOfMovers',
  numberOfTrucks: 'numberOfTrucks',
  finalBillableTravelTimeForQuote: 'finalBillableTravelTimeForQuote',
  doubleDrivingTimeOverride: 'doubleDrivingTimeOverride',
  calculatedLaborMinTime: 'laborDurationRange.durationMin.originalSeconds',
  calculatedLaborMaxTime: 'laborDurationRange.durationMax.originalSeconds',
  minLaborHours: 'minLaborHours',
  [ratePerSizeUnitProperty]: ratePerSizeUnitProperty,
  [ratePerDistanceProperty]: ratePerDistanceProperty,
};

const closingPropertyNames = {
  laborTime: 'laborTime',
  numberOfMovers: 'crewSize',
  laborRateOverride: 'laborRate',
  travelRateOverride: 'travelRate',
  finalBillableTravelTimeForQuote: 'finalBillableTravelTime',
};

type unionProperties = keyof typeof closingPropertyNames | keyof typeof restPropertyNames;

const useStyles = makeStyles((theme) => ({
  bloomingDaleDiscount: {
    textDecorationLine: 'line-through',
    marginLeft: theme.spacing(1),
    '&.el-typography': {
      color: `${theme.palette.text.secondary} !important`,
    },
  },
}));

const laborRateProperty = 'laborRateOverride';
const travelRateProperty = 'travelRateOverride';

const { MOVING_AND_STORAGE_SETTINGS } = SettingName;

const MoveDetails: FC<MoveDetailsProps> = ({ onCancel, rates, onSave }) => {
  const classes = useStyles();
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const { closingTitle, isClosing, isCompleted, isLockSales } = useOrderClosingContext();
  const disableInputs = isClosing || isCompleted || isLockSales;
  const { moveUnitLabelsShort, currentDistanceUnitLabel } = useOrderSettingUnits();
  const service = order?.services.get(serviceIndex);
  const quote = order?.getServiceQuote(serviceIndex);
  const serviceType = service?.getType();
  const [date, setDate] = useState(toDate(service.date)!);
  const getServicePropertyName = useGetServicePropertyName();
  const storageQuote = order?.getServiceStorageQuote(serviceIndex);
  const getCurrentPropertyNames = () => {
    const result = Object.entries(restPropertyNames).reduce((acc, [key, value]) => {
      acc[key as unionProperties] =
        isClosing &&
        (value === 'finalBillableTravelTimeForQuote' ||
          value === 'doubleDrivingTimeOverride' ||
          value === 'minLaborHours')
          ? getServiceClosingPropertyName(serviceIndex, value)
          : getServicePropertyName(value, closingPropertyNames[key as keyof typeof closingPropertyNames]);

      return acc;
    }, {} as Record<unionProperties, string>);

    result[closingPropertyNames.laborTime as unionProperties] = getServicePropertyName(closingPropertyNames.laborTime);

    return result;
  };
  const currentPropertyNames = getCurrentPropertyNames();
  const calculatedMin = order?.getIn(currentPropertyNames.calculatedLaborMinTime.split('.')) as number;
  const calculatedMax = order?.getIn(currentPropertyNames.calculatedLaborMaxTime.split('.')) as number;
  const originalLaborTimeMin = order?.getIn(currentPropertyNames.laborTimeMin.split('.')) as number;
  const originalLaborTimeMax = order?.getIn(currentPropertyNames.laborTimeMax.split('.')) as number;
  const originalArrivalWindow = order?.getIn(currentPropertyNames.arrivalWindow.split('.')) as ArrivalWindowEnum;
  const originalDeliveryWindow = order?.getIn(currentPropertyNames.deliveryWindow.split('.')) as DeliveryWindowEnum;
  const originalLongDistanceTariffDetails = order?.getIn(
    currentPropertyNames.longDistanceTariffDetails.split('.'),
  ) as LongDistanceTariffDetails;
  const originalLaborRateOverride = order?.getIn(currentPropertyNames.laborRateOverride.split('.')) as OverridableValue;
  const originalTravelRateOverride = order?.getIn(
    currentPropertyNames.travelRateOverride.split('.'),
  ) as OverridableValue;
  const originalNumberOfMovers = order?.getIn(currentPropertyNames.numberOfMovers.split('.')) as OverridableValue;
  const originalFinalBillableTravelTimeForQuote = order?.getIn(
    currentPropertyNames.finalBillableTravelTimeForQuote.split('.'),
  ) as Duration;
  const originalDoubleDrivingTimeOverride = order?.getIn(
    currentPropertyNames.doubleDrivingTimeOverride.split('.'),
  ) as Duration;
  const originalMinHours = order?.getIn(currentPropertyNames.minLaborHours.split('.')) as OverridableDurationValue;
  const originalNumberOfTrucks = order?.getIn(currentPropertyNames.numberOfTrucks.split('.')) as OverridableValue;
  const originalRatePerDistanceBase = order?.getIn(
    currentPropertyNames[ratePerDistanceProperty].split('.'),
  ) as OverridableValue;
  const originalRatePerSizeUnitBase = order?.getIn(
    currentPropertyNames[ratePerSizeUnitProperty].split('.'),
  ) as OverridableValue;
  const isLongDistance = isLongDistanceService(serviceType);

  const originalClosingLaborTime = order?.getIn(currentPropertyNames.laborTime.split('.')) as number;

  const { confirm: confirmLaborTimeChange, renderDialog: renderConfirmLaborTimeCharge } = useConfirm({
    title: 'Are you sure you want to set time manually?',
    message: <BodyText>Calculator will be turned off.</BodyText>,
    confirmTitle: `Yes, let's do it!`,
    cancelTitle: 'No, cancel pls!',
  });
  const [timeRangeOption, setTimeRangeOption] = useState<TimeModes | null>(null);
  const [arrivalWindow, setArrivalWindow] = useState(
    ArrivalWindowByTime[
      quote.startTimeEarliest && quote.startTimeLatest ? quote.startTimeEarliest + quote.startTimeLatest : 'null'
    ],
  );
  const [laborTimeMin, setLaborTimeMin] = useState(originalLaborTimeMin);
  const [laborTimeMax, setLaborTimeMax] = useState(originalLaborTimeMax);
  const [longDistanceRatePerCuFt, setLongDistanceRatePerCuFt] = useState<number>(
    originalLongDistanceTariffDetails.getEffectiveRate(),
  );
  const [laborRate, setLaborRate] = useState(originalLaborRateOverride.value());
  const [travelRate, setTravelRate] = useState(originalTravelRateOverride.value());
  const adjustedTravelTime = quote.adjustedTravelTime.getRoundedSeconds();
  const calculatedDoubleDrivingTime = quote.doubleDrivingTime.getRoundedSeconds();
  const [travelTime, setTravelTime] = useState(originalFinalBillableTravelTimeForQuote?.getRoundedSeconds());
  const [doubleDrivingTime, setDoubleDrivingTime] = useState(originalDoubleDrivingTimeOverride?.getRoundedSeconds());
  const [crewSize, setCrewSize] = useState(originalNumberOfMovers.value());
  const [trucks, setTrucks] = useState(originalNumberOfTrucks.value());
  const [ratePerDistance, setRatePerDistance] = useState(originalRatePerDistanceBase?.value());
  const [ratePerSizeUnit, setRatePerSizeUnit] = useState(originalRatePerSizeUnitBase?.value());
  const [minHours, setMinHours] = useState(originalMinHours.value() || Duration.createDuration({ originalSeconds: 0 }));
  const [startTime, setStartTime] = useState(quote.startTimeEarliest);
  const [endTime, setEndTime] = useState(quote.startTimeLatest);
  const [disableAutoCalculation, setDisableAutoCalculation] = useState<boolean>(
    order?.disableAutoCalculation as boolean,
  );
  const [truckError, setTruckError] = useState<string | null>(null);
  const [closingLaborTime, setClosingLaborTime] = useState<string | number>(originalClosingLaborTime);

  const [timeOptions, adjustedTimeOptions] = useTimeDuration(startTime);
  const [durationOptions30mins, durationOptions15mins] = useDurations(laborTimeMin);
  const [, travelTimeDuration15mins] = useDurations(null, 6);
  const settings = useOrderWindowSettings();
  const isTravelRateNotTheSameAsLaborRate = !settings?.[GR_TRAVEL_TIME_RATES_SAME_AS_LABOR_RATES];
  const isFlatRate = order?.getServiceQuote().estimateType === EstimateType.FLAT_RATE;
  const isTruckRequired =
    settings?.[settingName.LABOR_ONLY]?.find(
      (lo) => lo.serviceType === order?.getServiceType(serviceIndex) && lo.moveType === order?.moveType,
    )?.truckRequired ?? true;

  const beforeDiscountServiceQuotes = order?.beforeDiscountServiceQuotes;
  const discountTravelRate = beforeDiscountServiceQuotes?.get(serviceIndex)?.[travelRateProperty].value() ?? 0;
  const discountLaborRate = beforeDiscountServiceQuotes?.get(serviceIndex)?.[laborRateProperty].value() ?? 0;
  const discountRatePerSizeUnit =
    beforeDiscountServiceQuotes?.get(serviceIndex)?.longDistanceTariffDetails?.getEffectiveRate() ?? 0;
  const discountTravelRateToDisplay = isClosing || !discountTravelRate ? '' : numberToCurrency(discountTravelRate);
  const discountLaborRateToDisplay = isClosing || !discountLaborRate ? '' : numberToCurrency(discountLaborRate);
  const discountRatePerSizeUnitToDisplay =
    isClosing || !discountRatePerSizeUnit ? '' : numberToCurrency(discountRatePerSizeUnit);
  const currentStorageSetting = ((settings || {})[MOVING_AND_STORAGE_SETTINGS] || []).find(
    (serviceSettings: MovingAndStorageSetting) => serviceSettings.moveType === order?.moveType,
  );

  const { confirm: confirmWarning, renderDialog: renderConfirmationWarningDialog } = useConfirm({
    title: 'Warning',
    message:
      'You currently do not have all your trucks scheduled.  Please schedule the correct number of trucks you have selected for this move.',
    confirmTitle: 'Ok',
    cancelTitle: 'Cancel',
  });

  const { confirm: confirmStorageWarning, renderDialog: renderConfirmStorageWarning } = useConfirm({
    title: 'Warning',
    message:
      'The time in storage is more than the maximum allotted number of months for a storage tenant from your current settings. Do you wish to proceed?',
    maxWidth: 'sm',
    confirmTitle: 'Yes Proceed',
    cancelTitle: 'Don’t Proceed',
  });

  const allTruckAssignmentsForCurrentOrder = order?.services.reduce((acc, service) => {
    return acc + service.truckAssignments.size;
  }, 0) as number;

  const handleAutoCalculationSwitch = (value: boolean) => {
    if (!value) {
      // reset labor/travel time to calculated:
      setLaborTimeMin(originalLaborTimeMin);
      setLaborTimeMax(originalLaborTimeMax);
    }
    setDisableAutoCalculation(value);
  };

  useEffect(() => {
    if (quote.startTimeEarliest) {
      setTimeRangeOption(TimeModes.CUSTOM);
    } else {
      setTimeRangeOption(TimeModes.TBD);
    }
  }, []);

  const handleTimeRangeOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setTimeRangeOption(value as TimeModes);

    if (value === TimeModes.TBD) {
      setStartTime(null);
      setEndTime(null);
      setArrivalWindow(ArrivalWindowEnum.ANY);
    }
  };

  const handleOnChange = (func: SetStateAction<any>) => async (event: ChangeEvent<HTMLInputElement>) => {
    if (isDate(event)) {
      if (
        checkMaxPeriodInStorage(
          order?.storageMoveStage,
          //@ts-ignore Because event is Date.
          toBackEndDate(event),
          storageQuote?.start,
          storageQuote?.end,
          currentStorageSetting?.maxMonthsInStorage!,
          service?.getType(),
        )
      ) {
        if (!(await confirmStorageWarning())) {
          return;
        }
      }
    }

    if (event?.target?.name === TRUCK_SIZE_NAME && +event.target.value > crewSize! && !isClosing) {
      setTruckError(getNumberOfTrucksError(+event.target.value, crewSize!));
      return;
    }

    if (event?.target?.name === CREW_SIZE_NAME && +event.target.value < trucks! && !isClosing) {
      setTruckError(getNumberOfTrucksError(trucks!, +event.target.value));
      return;
    }

    event?.target ? func(event.target.value) : func(event);
  };

  const handleOnLaborTimeChange =
    (func: SetStateAction<any>, calculatedValue: number, disableAutoCalculationFlag: boolean) =>
    async (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      if (!disableAutoCalculationFlag && calculatedValue !== +value && (await confirmLaborTimeChange())) {
        handleAutoCalculationSwitch(true);
        func(value);
      } else {
        func(value);
      }
    };

  const handleOnClosingLaborTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setClosingLaborTime(value);
  };

  const handleStartTimeFromSettings = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const newArrivalWindow = value as ArrivalWindowEnum;
    setArrivalWindow(newArrivalWindow);

    const time = ArrivalWindowToStartEndTime[newArrivalWindow];

    if (time) {
      setStartTime(time.start);
      setEndTime(time.end);
    }
  };

  const maxDaysSettingNames = settingName.OVERNIGHT.MAX_DAYS[order?.moveType as MoveTypeTypes];
  const maxOvernightDays = settings?.[maxDaysSettingNames] || 0;
  const isDateChanged = toDateString(service.date) !== toDateString(date);
  const isLaborTimeChanged = originalLaborTimeMin !== laborTimeMin || originalLaborTimeMax !== laborTimeMax;
  const isLaborRateChanged = originalLaborRateOverride.value() !== laborRate;
  const isTravelRateChanged = originalTravelRateOverride.value() !== travelRate;
  const isCrewSizeChanged = originalNumberOfMovers.value() !== crewSize;
  const isTrucksChanged = originalNumberOfTrucks.value() !== trucks;
  const isTravelTimeChanged = originalFinalBillableTravelTimeForQuote?.getRoundedSeconds() !== travelTime;
  const isDoubleDrivingTimeChanged = originalDoubleDrivingTimeOverride?.getRoundedSeconds() !== doubleDrivingTime;
  const isStartTimeEarliestChanged = quote.startTimeEarliest !== startTime;
  const isStartTimeLatestChanged = quote.startTimeLatest !== endTime;
  const isMinHoursChanged = originalMinHours.value()?.originalSeconds !== minHours?.originalSeconds;
  const isDisableAutoCalculation = order?.disableAutoCalculation !== disableAutoCalculation;
  const maxOvernightDaysWarning = checkMaxOvernightDaysWarning(order!, maxOvernightDays);
  const isRatePerSizeUnitChanged = originalRatePerSizeUnitBase?.value() !== ratePerSizeUnit;
  const isRatePerDistanceChanged = originalRatePerDistanceBase?.value() !== ratePerDistance;
  const isLongDistanceRatePerCuFtChanged =
    originalLongDistanceTariffDetails.getEffectiveRate() !== longDistanceRatePerCuFt;
  const isClosingLaborTimeChanged = originalClosingLaborTime !== closingLaborTime;

  const handleOnSave = async () => {
    const changes = [];

    if (isTrucksChanged) {
      changes.push([currentPropertyNames.numberOfTrucks, originalNumberOfTrucks.setManual(trucks || null)]);
    }

    if (
      isTrucksChanged &&
      order?.getMoveStatus() === statusIds.CAN_BOOK_ONLINE &&
      allTruckAssignmentsForCurrentOrder < trucks!
    ) {
      const answer = await confirmWarning();

      if (!answer) {
        return;
      }
    }

    if (isDateChanged) {
      changes.push([`services.${serviceIndex}.date`, toBackEndDate(date)]);
    }
    if (isStartTimeEarliestChanged) {
      changes.push([currentPropertyNames.startTimeEarliest, startTime]);
    }
    if (isStartTimeLatestChanged) {
      changes.push([currentPropertyNames.startTimeLatest, endTime]);
    }
    if (isLaborRateChanged) {
      changes.push([currentPropertyNames.laborRateOverride, originalLaborRateOverride.setManual(laborRate || null)]);
    }
    if (isLaborTimeChanged) {
      changes.push([currentPropertyNames.laborTimeMin, laborTimeMin]);
      changes.push([currentPropertyNames.laborTimeMax, laborTimeMax]);
    }
    if (isTravelRateChanged) {
      changes.push([currentPropertyNames.travelRateOverride, originalTravelRateOverride.setManual(travelRate || null)]);
    }
    if (!isTravelRateNotTheSameAsLaborRate && isLaborRateChanged) {
      changes.push([currentPropertyNames.travelRateOverride, originalTravelRateOverride.setManual(laborRate || null)]);
    }
    if (isCrewSizeChanged) {
      changes.push([currentPropertyNames.numberOfMovers, originalNumberOfMovers.setManual(crewSize || null)]);
    }
    if (isTravelTimeChanged) {
      changes.push([`${currentPropertyNames.finalBillableTravelTimeForQuote}.originalSeconds`, travelTime]);
    }
    if (isDoubleDrivingTimeChanged) {
      changes.push([`${currentPropertyNames.doubleDrivingTimeOverride}.originalSeconds`, doubleDrivingTime]);
    }

    if (disableAutoCalculation !== order?.disableAutoCalculation) {
      changes.push(['disableAutoCalculation', disableAutoCalculation]);
    }
    if (isMinHoursChanged) {
      changes.push([currentPropertyNames.minLaborHours, originalMinHours.setManual(minHours)]);
    }
    if (isRatePerSizeUnitChanged) {
      changes.push([
        currentPropertyNames[ratePerSizeUnitProperty],
        originalRatePerSizeUnitBase?.setManual(ratePerSizeUnit || null),
      ]);
    }
    if (isRatePerDistanceChanged) {
      changes.push([
        currentPropertyNames[ratePerDistanceProperty],
        originalRatePerDistanceBase?.setManual(ratePerDistance || null),
      ]);
    }
    if (isLongDistanceRatePerCuFtChanged) {
      changes.push([currentPropertyNames.longDistanceRatePerUnitOverride, longDistanceRatePerCuFt]);
    }

    if (isClosingLaborTimeChanged) {
      changes.push([currentPropertyNames.laborTime, closingLaborTime]);
    }
    onSave(changes);
  };

  const hasChanges =
    isDateChanged ||
    isLaborRateChanged ||
    isTravelTimeChanged ||
    isLaborTimeChanged ||
    isTravelRateChanged ||
    isCrewSizeChanged ||
    isTrucksChanged ||
    isMinHoursChanged ||
    isStartTimeEarliestChanged ||
    isStartTimeLatestChanged ||
    isDisableAutoCalculation ||
    isRatePerSizeUnitChanged ||
    isRatePerDistanceChanged ||
    isDoubleDrivingTimeChanged ||
    isLongDistanceRatePerCuFtChanged ||
    isClosingLaborTimeChanged;

  const handleChangeMinHours = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setMinHours(Duration.createDuration({ originalSeconds: +value }));
  };

  const handleCloseErrorModal = () => {
    setTruckError(null);
  };

  return (
    <Modal
      open
      title={`Move Details${closingTitle}`}
      onClose={onCancel}
      maxWidth="sm"
      color="grey"
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: handleOnSave, disabled: !hasChanges },
      ]}
    >
      <Box mx={2}>
        <Grid container spacing={1}>
          <Grid item sm={6} xs={12}>
            <DateInput
              name={MOVE_DATE_NAME}
              label="Move Date"
              rates={rates[order?.moveType as MoveTypeTypes] || {}}
              value={date || null}
              onChange={handleOnChange(setDate)}
              className={clsx({ disabled: disableInputs })}
            />
            {maxOvernightDaysWarning && (
              <TableRow>
                {/* @ts-ignore */}
                <TableCell colspan={2} size="small" padding="none">
                  <Alert icon={false} severity="error">
                    {maxOvernightDaysWarning}
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Select
                disabled
                fullWidth
                label="Preferred Arrival Window"
                name="arrivalWindow"
                options={arrivalWindowOptions}
                value={originalArrivalWindow || ArrivalWindowEnum.ANY}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {isLongDistance && (
                <Box mt={1}>
                  <Select
                    disabled
                    fullWidth
                    label="Preferred Delivery Window"
                    name="deliveryWindow"
                    options={deliveryWindowOptions}
                    value={originalDeliveryWindow || DeliveryWindowEnum.ANY}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccessTimeIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              )}
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <Box mt={2}>
                    <BodyBigText>
                      <b>Set Time By</b>
                    </BodyBigText>
                  </Box>
                </FormLabel>
                <RadioGroup
                  direction="row"
                  aria-label="time-setting"
                  name="timeSetting"
                  value={timeRangeOption}
                  onChange={handleTimeRangeOptionChange}
                >
                  <Radio
                    disabled={disableInputs}
                    value={TimeModes.SETTINGS}
                    label="Select Time from Settings"
                    color="primary"
                  />
                  <Radio disabled={disableInputs} value={TimeModes.CUSTOM} label="Custom Time" color="primary" />
                  <Radio disabled={disableInputs} value={TimeModes.TBD} label="TBD" color="primary" />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {TimeModes.SETTINGS === timeRangeOption && (
            <Grid item xs={6}>
              <Select
                disabled={disableInputs}
                fullWidth
                label="Arrival Window"
                name="arrivalWindow"
                options={arrivalWindowOptions}
                value={arrivalWindow || ArrivalWindowEnum.ANY}
                onChange={handleStartTimeFromSettings}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {TimeModes.CUSTOM === timeRangeOption && (
            <>
              <Grid item xs={6}>
                <Select
                  disabled={disableInputs}
                  fullWidth
                  label="Arrival Window From"
                  name="startTimeEarliest"
                  options={timeOptions}
                  value={startTime || null}
                  onChange={handleOnChange(setStartTime)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  disabled={disableInputs}
                  fullWidth
                  label="To"
                  name="startTimeLatest"
                  options={adjustedTimeOptions}
                  value={endTime || null}
                  onChange={handleOnChange(setEndTime)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Box mt={1}>
          <Grid container spacing={1}>
            {isClosing ? (
              <Grid item sm={4} xs={4}>
                <Select
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label="Labor Time"
                  value={closingLaborTime || '0'}
                  onChange={handleOnClosingLaborTimeChange}
                >
                  {durationOptions15mins.map(([v, label]) => (
                    <MenuItem key={v} value={v}>
                      <BodyText color={v !== closingLaborTime ? 'error' : 'default'}>{label}</BodyText>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              <>
                <Grid item sm={4} xs={4}>
                  <Select
                    disabled={isCompleted || isLockSales}
                    fullWidth
                    label="Labor Time Range"
                    value={laborTimeMin || '0'}
                    onChange={handleOnLaborTimeChange(setLaborTimeMin, originalLaborTimeMin, disableAutoCalculation!)}
                  >
                    {durationOptions15mins.map(([v, label]) => (
                      <MenuItem key={v} value={v}>
                        <BodyText color={v !== calculatedMin ? 'error' : 'default'}>{label}</BodyText>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item sm={4} xs={4}>
                  <Select
                    disabled={isCompleted || isLockSales}
                    fullWidth
                    label="Labor Time Range"
                    onChange={handleOnLaborTimeChange(setLaborTimeMax, originalLaborTimeMax, disableAutoCalculation)}
                    value={laborTimeMax || '0'}
                  >
                    {durationOptions15mins
                      .filter((it) => it[0] >= laborTimeMin)
                      .map(([v, label]) => (
                        <MenuItem key={v} value={v}>
                          <BodyText color={v !== calculatedMax ? 'error' : 'default'}>{label}</BodyText>
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </>
            )}

            {quote.doubleDrivingTimeEnabled ? (
              <Grid item xs={4} sm={4}>
                <Select
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label={settings[settingName.DRIVING_DOUBLE_TRAVEL_NAME]}
                  name="travelTime"
                  onChange={handleOnChange(setDoubleDrivingTime)}
                  value={doubleDrivingTime}
                >
                  {travelTimeDuration15mins.map(([v, label]) => (
                    <MenuItem key={v} value={v} dense>
                      <BodyText color={v === calculatedDoubleDrivingTime ? 'default' : 'error'}>{label}</BodyText>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ) : (
              <Grid item xs={4} sm={4}>
                <Select
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label={settings[settingName.TRAVEL_TIME_LABEL]}
                  name="travelTime"
                  onChange={handleOnChange(setTravelTime)}
                  value={travelTime}
                >
                  {travelTimeDuration15mins.map(([v, label]) => (
                    <MenuItem key={v} value={v} dense>
                      <BodyText color={v === adjustedTravelTime ? 'default' : 'error'}>{label}</BodyText>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
          </Grid>
        </Box>
        {isFlatRate && !isLongDistance ? (
          <Box mt={1}>
            <Grid container spacing={1}>
              <OverridableItem item xs={6} $manual={originalRatePerSizeUnitBase?.calculated !== ratePerSizeUnit}>
                <CurrencyInput
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label={`Rate per ${moveUnitLabelsShort}`}
                  name="ratePerSizeUnit"
                  onChange={setRatePerSizeUnit}
                  value={ratePerSizeUnit || ''}
                  inputProps={{
                    allowNegativeValue: false,
                  }}
                />
              </OverridableItem>
              <OverridableItem item xs={6} $manual={originalRatePerDistanceBase?.calculated !== ratePerDistance}>
                <CurrencyInput
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label={`Rate per ${currentDistanceUnitLabel}`}
                  name="ratePerDistance"
                  onChange={setRatePerDistance}
                  value={ratePerDistance || ''}
                  inputProps={{
                    allowNegativeValue: false,
                  }}
                />
              </OverridableItem>
            </Grid>
          </Box>
        ) : (
          <>
            {!isLongDistance && (
              <Box mt={1}>
                <Grid container spacing={1}>
                  <OverridableItem
                    $manual={quote.laborRateOverride.calculated !== laborRate}
                    item
                    xs={isTravelRateNotTheSameAsLaborRate ? 6 : 12}
                  >
                    <CurrencyInput
                      disabled={isCompleted || isLockSales}
                      fullWidth
                      label={
                        <Box display="flex">
                          {isTravelRateNotTheSameAsLaborRate ? 'Labor Rate' : 'Hourly Rate'}
                          <BodyText className={classes.bloomingDaleDiscount}>{discountLaborRateToDisplay}</BodyText>
                        </Box>
                      }
                      name="laborRate"
                      onChange={setLaborRate}
                      value={laborRate || ''}
                      inputProps={{
                        allowNegativeValue: false,
                      }}
                    />
                  </OverridableItem>
                  {isTravelRateNotTheSameAsLaborRate && (
                    <OverridableItem $manual={originalLaborRateOverride.calculated !== travelRate} item xs={6}>
                      <CurrencyInput
                        disabled={isCompleted || isLockSales}
                        fullWidth
                        label={
                          <Box display="flex">
                            Travel Rate
                            <BodyText className={classes.bloomingDaleDiscount}>{discountTravelRateToDisplay}</BodyText>
                          </Box>
                        }
                        name="travelRate"
                        onChange={setTravelRate}
                        value={travelRate || ''}
                        inputProps={{
                          allowNegativeValue: false,
                        }}
                      />
                    </OverridableItem>
                  )}
                </Grid>
              </Box>
            )}
          </>
        )}
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={isFlatRate && !isLongDistance ? 4 : 6}>
              <Select
                disabled={isCompleted || isLockSales}
                fullWidth
                label="Crew Size"
                name={CREW_SIZE_NAME}
                value={crewSize || 0}
                onChange={handleOnChange(setCrewSize)}
              >
                {selectOptions.slice(1).map((v) => (
                  <MenuItem key={v} value={v}>
                    <BodyText color={v === originalNumberOfMovers.calculated ? 'initial' : 'error'}>{v}</BodyText>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={isFlatRate && !isLongDistance ? 4 : 6}>
              <Select
                disabled={isCompleted || isLockSales}
                fullWidth
                label={`Trucks ${isTruckRequired ? '' : '(not included)'}`}
                name={TRUCK_SIZE_NAME}
                value={trucks || 0}
                onChange={handleOnChange(setTrucks)}
              >
                {selectOptions.map((v) => (
                  <MenuItem key={v} value={v}>
                    {/* @ts-ignore */}
                    <BodyText color={v === originalNumberOfTrucks.calculated ? 'default' : 'error'}>{v}</BodyText>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {!isFlatRate && (
              <Grid item sm={4} xs={4}>
                <Select
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label="Min Hours"
                  onChange={handleChangeMinHours}
                  value={minHours.originalSeconds || '0'}
                >
                  {durationOptions30mins.map(([v, label]) => (
                    <MenuItem key={v} value={v}>
                      <BodyText
                        color={
                          quote.minLaborHours.calculated && v === quote.minLaborHours.calculated.originalSeconds
                            ? 'default'
                            : 'error'
                        }
                      >
                        {label}
                      </BodyText>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            {isLongDistance && (
              <OverridableItem item xs={4} $manual={originalLongDistanceTariffDetails.getOverrideRateOrNull() !== null}>
                <CurrencyInput
                  disabled={isCompleted || isLockSales}
                  fullWidth
                  label={
                    <Box display="flex">
                      {renderLaborForRate(originalLongDistanceTariffDetails, moveUnitLabelsShort)}
                      <BodyText className={classes.bloomingDaleDiscount}>{discountRatePerSizeUnitToDisplay}</BodyText>
                    </Box>
                  }
                  name={currentPropertyNames.longDistanceRatePerUnitOverride}
                  onChange={setLongDistanceRatePerCuFt}
                  value={longDistanceRatePerCuFt || '0'}
                  inputProps={{
                    allowNegativeValue: false,
                  }}
                />
              </OverridableItem>
            )}
          </Grid>
        </Box>
        {!isClosing && (
          <Box display="flex" justifyContent="flex-end" marginTop="8px" alignItems="center">
            <BodyText>Auto calculate</BodyText>
            <Checkbox
              color="primary"
              disabled={disableInputs}
              checked={!disableAutoCalculation}
              onChange={() => handleAutoCalculationSwitch(!disableAutoCalculation)}
            />
          </Box>
        )}
        {renderConfirmLaborTimeCharge()}
      </Box>
      {truckError && (
        <Modal
          maxWidth="xs"
          onClose={handleCloseErrorModal}
          title="Error"
          open
          actions={[{ label: 'Ok', onClick: handleCloseErrorModal }]}
        >
          <BodyText>{truckError}</BodyText>
        </Modal>
      )}
      {renderConfirmationWarningDialog()}
      {renderConfirmStorageWarning()}
    </Modal>
  );
};

export default MoveDetails;
