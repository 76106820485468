import NotificationIcon from 'common/imgs/logo_small.png';
import Notification from 'react-web-notification';
import React from 'react';
import pt from 'prop-types';

const FIVE_SECONDS_TIMEOUT = 5 * 1000;

const NativeNotification = ({ title, body, notSupported, onPermissionDenied, onShow, onClick }) => {
  const tag = Date.now();
  const options = {
    tag,
    body,
    lang: 'en',
    icon: NotificationIcon
  };

  const handleClick = event => {
    if (onClick) {
      onClick(event);
    }

    window.focus();
  };

  return (
    <Notification
      timeout={FIVE_SECONDS_TIMEOUT}
      notSupported={notSupported}
      onPermissionDenied={onPermissionDenied}
      onShow={onShow}
      onClick={handleClick}
      title={title}
      options={options}
    />
  );
};

NativeNotification.propTypes = {
  title: pt.string.isRequired,
  body: pt.string,
  onClick: pt.func,
  notSupported: pt.func,
  onPermissionDenied: pt.func,
  onShow: pt.func
};

NativeNotification.defaultProps = {
  body: '',
  notSupported: null,
  onPermissionDenied: () => {},
  onShow: null,
  onClick: null
};

export default NativeNotification;
