import React from 'react';
import { DayView, HeaderSurveyScheduler, WeekView } from 'admin/components/OrderWindow/SurveySchedulerBox';
import { Box, LinearProgress, Paper } from '@material-ui/core';
import pt from 'prop-types';
import { Order } from '@elromcoinc/react-shared';

export const SurveySchedulerPanel = ({
  dayMode,
  onDateChange,
  availableEstimatorsByDate,
  availableEstimatorsByWeek,
  toggleViewMode,
  dense,
  handleEstimatorClick,
  date,
  isLoadingEstimators,
  order,
  currentSurveyValue,
  isLoadingSurveyService,
  handleCloseModal,
}) => (
  <Paper>
    <HeaderSurveyScheduler date={date} onDateChange={onDateChange} toggleViewMode={toggleViewMode} />
    {isLoadingEstimators && isLoadingSurveyService && (
      <Box height={8} mb={-1}>
        <LinearProgress />
      </Box>
    )}
    {dayMode ? (
      <DayView
        estimators={availableEstimatorsByDate}
        date={date}
        dense={!dense}
        handleEstimatorClick={handleEstimatorClick}
        order={order}
        currentSurveyValue={currentSurveyValue}
        handleCloseModal={handleCloseModal}
      />
    ) : (
      <WeekView
        dense={dense}
        estimators={availableEstimatorsByWeek}
        date={date}
        handleEstimatorClick={handleEstimatorClick}
        order={order}
        currentSurveyValue={currentSurveyValue}
        handleCloseModal={handleCloseModal}
      />
    )}
  </Paper>
);

SurveySchedulerPanel.propTypes = {
  date: pt.instanceOf(Date).isRequired,
  dayMode: pt.bool.isRequired,
  onDateChange: pt.func,
  // eslint-disable-next-line react/forbid-prop-types
  availableEstimatorsByDate: pt.array,
  // eslint-disable-next-line react/forbid-prop-types
  availableEstimatorsByWeek: pt.array,
  toggleViewMode: pt.func,
  dense: pt.bool,
  handleEstimatorClick: pt.func,
  isLoadingEstimators: pt.bool,
  order: pt.instanceOf(Order).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentSurveyValue: pt.object.isRequired,
  isLoadingSurveyService: pt.bool,
  handleCloseModal: pt.func,
};

SurveySchedulerPanel.defaultProps = {
  availableEstimatorsByDate: [],
  availableEstimatorsByWeek: [],
  toggleViewMode: null,
  onDateChange: null,
  dense: true,
  handleEstimatorClick: null,
  isLoadingEstimators: false,
  isLoadingSurveyService: false,
  handleCloseModal: null,
};
