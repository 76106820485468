import { createContext } from 'react';

interface ValidateStorageOnBeContextProps {
  storageErrors: string[];
  validateStorageMoveSizeOnBe: (value: number) => void;
  handleCloseWarningModal: () => void;
}

const ValidateStorageOnBeContext = createContext({} as ValidateStorageOnBeContextProps);

export { ValidateStorageOnBeContext };
