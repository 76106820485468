import { FC, useState } from 'react';

import { Order } from '@elromcoinc/react-shared';

import { OrderStateContext } from './OrderStateContext';

const OrderStateProvider: FC = ({ children }) => {
  const [originalOrder, setOriginalOrder] = useState<Order | null>(null);
  const [lastUpdated, setLastUpdated] = useState<Order | null>(null);
  const [order, setOrder] = useState<Order | null>(null);

  return (
    <OrderStateContext.Provider
      value={{ originalOrder, setOriginalOrder, lastUpdated, setLastUpdated, order, setOrder }}
    >
      {children}
    </OrderStateContext.Provider>
  );
};

export { OrderStateProvider };
