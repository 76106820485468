import { TIME_STRING_FORMAT, UI_LOCALIZED_TIME_FORMAT, toDate } from '@elromcoinc/react-shared';
import { addHours, addMinutes, format, getHours, getMinutes, startOfDay } from 'date-fns';

const DEFAULT_TIME_VALUE = '00:00:00';
const MINUTES_IN_HOUR = 60;
const SECONDS_IN_HOUR = 3600;

export const convertLocalTimeToSeconds = (time) => {
  const timeParts = (time || DEFAULT_TIME_VALUE).split(':');
  return Number(Number(timeParts[0] * MINUTES_IN_HOUR) + Number(timeParts[1])) * MINUTES_IN_HOUR;
};

export const convertDurationToMinutes = (time) => {
  const timeParts = (time || DEFAULT_TIME_VALUE).split(':');
  return Number(Number(timeParts[0] * MINUTES_IN_HOUR) + Number(timeParts[1]));
};

export const convertLocalTimeToMinutes = (time) => {
  const dateTime = toDate(time || DEFAULT_TIME_VALUE, TIME_STRING_FORMAT);
  return getHours(dateTime) * MINUTES_IN_HOUR + getMinutes(dateTime);
};

export const formatTime = (time) => {
  const timeParts = (time || DEFAULT_TIME_VALUE).split(':');
  const hoursParts = addHours(startOfDay(new Date()), Number(timeParts[0]));
  return format(addMinutes(hoursParts, Number(timeParts[1])), UI_LOCALIZED_TIME_FORMAT);
};

export const convertSecondsToLocalTime = (secs) => {
  const secondsValue = parseInt(secs, 10);
  const hour = Math.floor(secondsValue / SECONDS_IN_HOUR);
  const minute = Math.floor(secondsValue / MINUTES_IN_HOUR) % MINUTES_IN_HOUR;
  const second = secondsValue % MINUTES_IN_HOUR;

  return [hour, minute, second].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};
