import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccountIcon from '@material-ui/icons/FolderShared';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import CreateAccount from 'admin/components/AddNewButtons/CreateAccount';
import CreateOrder from 'admin/components/CreateOrder';
import Tasks from 'admin/components/OrderWindow/modals/Tasks';

import CreateDraftOrder from './CreateDraftOrder';

const useStyles = makeStyles(() => ({
  addIcon: {
    fontSize: '2.5rem',
  },
}));

const IconButtonStyled = styled(IconButton)`
  && {
    padding: 5px;
    margin-right: 5px;
  }
`;

const ListItemIconStyled = styled(ListItemIcon)`
  && {
    color: #00b0d7;
    min-width: 20px;
  }
`;

const CREATE_DRAFT_ORDER_INDEX = 0;
const CREATE_NEW_ORDER_INDEX = 1;
const CREATE_NEW_ACCOUNT_INDEX = 2;
const CREATE_NEW_TASK_INDEX = 3;

const MenuStyled = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  }
  .MuiListItem-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    background: #f5f5f6;
    border-radius: 5px;
    margin: 5px;
    .MuiListItemText-primary {
      font-size: 18px;
      font-weight: bold;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
  .MuiMenu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 285px;
    padding: 5px;
  }
`;

const menus = [
  {
    title: 'New Order',
    icon: AssignmentIcon,
    dataTestId: 'newDraft',
  },
  {
    title: 'New Lead',
    icon: AssignmentIcon,
    dataTestId: 'newLead',
  },
  {
    title: 'New Account',
    icon: AccountIcon,
    dataTestId: 'newAccount',
  },
  {
    title: 'New Task',
    icon: CheckBoxIcon,
    dataTestId: 'newTask',
  },
];

const AddNewButtons = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModalIndex, setOpenModalIndex] = useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuItem = (index) => () => {
    setOpenModalIndex(index);
    handleClose();
  };

  const handleCloseModal = () => setOpenModalIndex(null);
  const handleSaveTask = () => {
    enqueueSnackbar('New task successfully created', { variant: 'success' });
    handleCloseModal();
  };

  return (
    <>
      {openModalIndex === CREATE_DRAFT_ORDER_INDEX && <CreateDraftOrder onClose={handleCloseModal} />}
      {openModalIndex === CREATE_NEW_ORDER_INDEX && <CreateOrder onClose={handleCloseModal} />}
      {openModalIndex === CREATE_NEW_ACCOUNT_INDEX && <CreateAccount onClose={handleCloseModal} />}
      {openModalIndex === CREATE_NEW_TASK_INDEX && <Tasks open onCancel={handleCloseModal} onSave={handleSaveTask} />}
      <IconButtonStyled
        color="inherit"
        data-testid="addNewButton"
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AddIcon className={classes.addIcon} />
      </IconButtonStyled>
      <MenuStyled
        id="more-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {menus.map((item, index) => (
          <MenuItem key={item.title} data-testid={item.dataTestId} onClick={handleClickMenuItem(index)}>
            <ListItemIconStyled>
              <item.icon fontSize="large" />
            </ListItemIconStyled>
            <ListItemText primary={item.title} />
          </MenuItem>
        ))}
      </MenuStyled>
    </>
  );
};

export default AddNewButtons;
