import React, { useRef } from 'react';

import { Button, Inventory, Modal, Order, useConfirm } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useOrderClosingContext, useValidateStorageOnBe } from 'admin/components/OrderWindow/context';
import { getInventorySize } from 'admin/components/OrderWindow/utils';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { useMobile } from 'admin/hooks/useMobile';

const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    minHeight: '80vh',
    flex: '1 1 auto',
    position: 'relative',
  },
  inventoryContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: 'auto',
    height: 'auto',
    [breakpoints.down(breakpoints.values.sm)]: {
      top: -6,
      left: -18,
      right: -18,
      bottom: -40,
    },
  },
  mobileSaveButton: {
    [breakpoints.down(breakpoints.values.sm)]: {
      height: '20px',
    },
  },
}));

const InventoryModal = ({ onSave, onCancel, order }) => {
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const { validateStorageMoveSizeOnBe } = useValidateStorageOnBe();
  const isMobile = useMobile();
  const classes = useStyles();
  const isDirtyRef = useRef(false);
  const onSaveClickedRef = useRef(false);
  const handleSaveRef = useRef(null);
  const { confirm, ConfirmationDialog: renderConfirm } = useConfirm({
    title: 'Save Changes',
    message: 'You have not saved your inventory changes. Do you wish to save your changes now?',
    confirmTitle: 'Yes, save',
    cancelTitle: 'No, cancel changes',
  });

  const onBeforeCancel = async () => {
    if (isDirtyRef.current && !onSaveClickedRef.current) {
      const result = await confirm();

      if (result) {
        handleSaveRef?.current?.();
        return;
      }
    }

    onCancel();
  };

  const handleOnSave = (updatedOrder) => {
    onSaveClickedRef.current = true;

    if (order.sizingStrategy !== updatedOrder.sizingStrategy) {
      onSave([{ name: 'sizingStrategy', value: updatedOrder.sizingStrategy }]);
    }

    onSave(
      updatedOrder.services.toJS().map((_, index) => {
        return { name: `services.${index}.quote.waypoints`, value: updatedOrder.getServiceWaypoints(index) };
      }),
    );
    const [inventoryCuFt] = getInventorySize(updatedOrder, updatedOrder?.cuFtToPounds);
    validateStorageMoveSizeOnBe(inventoryCuFt);
    onCancel();
  };

  return (
    <>
      <Inventory
        saveBtn={
          <Button onClick={handleOnSave} className={classes.mobileSaveButton} color="primary">
            Save
          </Button>
        }
        key={order.orderId}
        className={classes.inventoryContainer}
        isAdmin
        order={order}
        disabled={isCompleted || isLockSales}
      >
        {({ inventoryComponent, isDirty, onSave: getLatestWaypoints }) => {
          const handleClickSave = () => {
            handleOnSave(getLatestWaypoints());
            onCancel();
          };
          isDirtyRef.current = isDirty;
          handleSaveRef.current = handleClickSave;

          const actions = isMobile
            ? []
            : [
                { label: 'cancel', onClick: onBeforeCancel },
                {
                  label: 'save',
                  onClick: handleClickSave,
                  disabled: !isDirty,
                },
              ];

          return (
            <Modal
              open
              fullWidth
              fullScreen={isMobile}
              maxWidth="xl"
              title="Inventory"
              onClose={onBeforeCancel}
              scroll="paper"
              actions={actions}
            >
              <VideoButton position="absolute" video="pAASI7ZgA8s" />
              <Box className={classes.root}>{inventoryComponent}</Box>
            </Modal>
          );
        }}
      </Inventory>
      {renderConfirm()}
    </>
  );
};

InventoryModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  order: PropTypes.instanceOf(Order).isRequired,
};
export default InventoryModal;
