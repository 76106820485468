import { Waypoint } from '@elromcoinc/react-shared';
import { List } from 'immutable';

export const mergeStops = (companyStops: List<Waypoint>, customerStops: List<Waypoint>) => {
  let result = customerStops;

  if (companyStops.isEmpty()) {
    return result;
  }

  if (
    !Waypoint.isCompanyStop(customerStops.get(0) as Waypoint) &&
    !Waypoint.isCompanyStorage(customerStops.get(0) as Waypoint)
  ) {
    result = customerStops.insert(0, companyStops.first());
  }

  if (!Waypoint.isCompanyStop(customerStops.last()) && !Waypoint.isCompanyStorage(customerStops.last())) {
    result = result.push(companyStops.last());
  }

  return result;
};
