import axios from 'axios';

import { TaskDTO, TaskQuery } from 'common-types/Task';

class TasksApi {
  getAllForCurrent(params: Partial<TaskQuery> = {}) {
    return axios.get<PageDTO<TaskDTO>, PageDTO<TaskDTO>>('/api/moving/tasks/filtered', { params });
  }

  save(body: TaskDTO) {
    return axios.post<TaskDTO, TaskDTO>('/api/moving/task', body);
  }

  update(body: TaskDTO) {
    return axios.put<TaskDTO, TaskDTO>('/api/moving/task', body);
  }

  delete(id: number) {
    return axios.delete(`/api/moving/task/${id}`);
  }

  markAllAsCompleted() {
    return axios.put('/api/moving/tasks/markAllCompleted', {});
  }

  markAllAsRead() {
    return axios.put('/api/moving/tasks/markUnreadAsRead', {});
  }
}

export default new TasksApi();
