import React, { FC } from 'react';

import { BodyText, Link, Order } from '@elromcoinc/react-shared';
import { TableCell as LabelTableCell, TableRow } from '@material-ui/core';

import { ValueTableCell } from 'admin/components/OrderWindow/blocks/CommonComponents';
import { Text } from 'admin/components/OrderWindow/components';

interface AddContactProps {
  onClick: () => void;
  order: Order;
}

const AddContact: FC<AddContactProps> = ({ onClick, order }) => {
  const hasAddContact = !order.additionalContact.isEmpty();
  const additionalContacts = order.additionalContact.toJS();

  return (
    <TableRow>
      <LabelTableCell size="small" padding="none">
        {hasAddContact ? (
          <Link data-testId="additionalContact" onClick={onClick}>
            Add'l Contact
          </Link>
        ) : (
          <BodyText>Add'l Contact</BodyText>
        )}
      </LabelTableCell>
      <ValueTableCell>
        <LabelTableCell size="small" padding="none">
          <Text
            value={hasAddContact ? [additionalContacts.firstName, additionalContacts.lastName].join(' ') : 'None'}
          />
        </LabelTableCell>
      </ValueTableCell>
    </TableRow>
  );
};

export default AddContact;
