import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const InRouteIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M3665 4789 c-324 -36 -623 -205 -816 -462 -184 -244 -274 -584 -221 -838 77 -372 433 -896 1019 -1498 92 -94 101 -101 138 -101 38 0 46 6 155 118 570 583 936 1125 1006 1490 44 231 -26 530 -179 765 -237 364 -677 574 -1102 526z m254 -594 c209 -50 381 -227 426 -440 27 -130 11 -248 -51 -375 -48 -96 -152 -202 -244 -247 -328 -163 -714 3 -816 352 -27 91 -24 232 6 325 28 88 103 204 168 260 56 49 171 108 244 126 73 17 190 17 267 -1z" />
          <path d="M205 3188 c-48 -27 -59 -79 -26 -119 l19 -24 479 -5 478 -5 0 -1105 0 -1105 -478 -5 -479 -5 -19 -24 c-33 -41 -21 -93 28 -119 16 -9 188 -12 636 -12 l613 0 17 -53 c68 -215 304 -335 525 -268 140 42 245 155 287 307 2 8 43 13 117 15 104 4 117 6 168 35 60 33 112 99 130 162 16 58 13 758 -3 813 -13 43 -481 816 -539 891 -16 21 -54 49 -86 65 l-57 28 -347 3 -348 3 0 192 c-1 247 -14 286 -105 328 -37 17 -75 19 -515 18 -339 0 -481 -3 -495 -11z m1789 -702 c30 -12 86 -77 86 -98 0 -5 -101 -8 -225 -8 -249 0 -271 -4 -300 -60 -14 -26 -15 -75 -13 -335 3 -289 4 -305 24 -331 11 -15 33 -37 48 -48 27 -20 41 -21 482 -24 l454 -3 0 -335 c0 -368 -2 -379 -60 -409 -19 -10 -57 -15 -113 -15 l-84 0 -21 58 c-44 119 -115 197 -228 249 -78 36 -214 44 -295 18 -71 -23 -138 -66 -189 -121 -42 -45 -100 -154 -100 -186 0 -15 -10 -18 -70 -18 l-70 0 0 840 0 840 320 0 c253 0 328 -3 354 -14z" />
          <path d="M3737 1720 c-38 -30 -38 -90 -1 -119 25 -20 39 -21 293 -21 298 0 356 -8 467 -62 83 -40 186 -137 232 -219 38 -67 72 -192 72 -264 0 -250 -175 -477 -416 -540 -50 -13 -169 -15 -822 -15 -650 0 -767 -2 -791 -15 -48 -24 -56 -81 -16 -120 l24 -25 778 0 c858 0 850 0 985 63 186 87 322 239 385 432 22 68 26 98 27 205 1 139 -14 209 -65 315 -71 145 -179 253 -324 326 -139 69 -155 71 -495 76 -301 5 -306 4 -333 -17z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
