const SECONDARY_ID = 'secondaryId';
const MOVE_TYPE = 'moveType';
const SERVICES = 'services';
const QUOTE = 'quote';
const GENERAL_SERVICE = 'generalService';
const SERVICE_ID = 'id';
const COMPANY_NAME = 'companyName';
const ESTIMATE_TYPE = 'estimateType';
const ESTIMATE_TYPE_CAPTION = 'estimateTypeCaption';
const ORDER_NUMBER = 'orderNumber';
const MOVE_SIZE = 'moveSize';
const SIZE_DESCRIPTION = 'sizeDescription';
const ARRIVAL_WINDOW = 'arrivalWindow';

const labels = {
  [SECONDARY_ID]: 'Secondary ID',
  [COMPANY_NAME]: 'Branch',
  [MOVE_SIZE]: 'Move Size',
  [MOVE_TYPE]: 'Move Type',
};

const SERVICE_TYPE_NAME = 'Service Type';
const ESTIMATE_TYPE_NAME = 'Estimate Type';

export {
  SERVICE_TYPE_NAME,
  ESTIMATE_TYPE_NAME,
  labels,
  ORDER_NUMBER,
  SECONDARY_ID,
  COMPANY_NAME,
  MOVE_TYPE,
  MOVE_SIZE,
  SIZE_DESCRIPTION,
  SERVICES,
  QUOTE,
  GENERAL_SERVICE,
  SERVICE_ID,
  ESTIMATE_TYPE,
  ESTIMATE_TYPE_CAPTION,
  ARRIVAL_WINDOW,
};
