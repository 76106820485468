import React from 'react';
import pt from 'prop-types';
import { BodyBigText, BodySmallText, BodyText, formatPhoneNumber, statusesById } from '@elromcoinc/react-shared';
import Box from '@material-ui/core/Box';
import ItemBase from 'admin/components/Search/ItemBase';

const OrderItem = ({ onClick, item }) => {
  const { orderId, startDate, contactInfo, addresses, sizeDescription, orderNumber, orderStatus } = item;

  return (
    <ItemBase onClick={onClick} key={orderId}>
      <BodyBigText>
        <b>{`Order #${orderNumber}`}</b>
      </BodyBigText>
      <BodyText>{startDate}</BodyText>
      <BodyText>{`${contactInfo.firstName} ${contactInfo.lastName}`}</BodyText>
      <BodyText>{`${contactInfo.email} ${formatPhoneNumber(contactInfo.primaryPhone)}`}</BodyText>
      {!!addresses.length && (
        <BodySmallText>
          {addresses.length > 1 ? (
            <>
              From:&nbsp;
              {`${addresses[0].address.asSingleString} `}
              To:&nbsp;
              {addresses[addresses.length - 1].address.asSingleString}
            </>
          ) : (
            (addresses && addresses[0].address.asSingleString) || 'No address provided'
          )}
        </BodySmallText>
      )}
      <BodySmallText>{sizeDescription}</BodySmallText>
      <Box
        px={1}
        py={0.5}
        borderRadius={16}
        top={8}
        right={8}
        position="absolute"
        textAlign="center"
        minWidth={75}
        bgcolor={statusesById[orderStatus].color}
        color="white"
      >
        <BodyText noWrap>{statusesById[orderStatus].label}</BodyText>
      </Box>
    </ItemBase>
  );
};

OrderItem.propTypes = {
  onClick: pt.func.isRequired,
  item: pt.shape({
    orderId: pt.number.isRequired,
    startDate: pt.string.isRequired,
    contactInfo: pt.object.isRequired,
    addresses: pt.array.isRequired,
    sizeDescription: pt.string.isRequired,
    orderNumber: pt.string.isRequired,
    orderStatus: pt.string.isRequired,
  }).isRequired,
};

export default OrderItem;
