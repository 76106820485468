import { ActivitySourceType, InvoiceDto } from '@elromcoinc/react-shared';

import { emailAPI } from 'admin/api';
import templateApi from 'admin/api/templateApi';
import { EmailDto, Template } from 'common-types';

export const sendInvoiceEmail = (
  resultOfSendInvoice: { templates: Template[]; emailDTO: EmailDto; fromCompany?: boolean },
  data: InvoiceDto,
) => {
  const { emailDTO: form, templates: templatesToSend, fromCompany: fromEmployee = false } = resultOfSendInvoice;
  // we need to process invoice template by invoice. But we need to send it to actual activity source
  return Promise.all(
    templatesToSend.map((t) =>
      templateApi
        .preview(data.id!, ActivitySourceType.INVOICE, t.toDTO({ useExistingBody: true }))
        .then((preview) => t.set('body', preview)),
    ),
  )
    .then((response) =>
      response.map((template: Template) => {
        const fd = new FormData();
        fd.append(
          'emailDto',
          new Blob(
            [
              JSON.stringify(
                template.toEmailDto({ ...form, activitySource: data.activitySource, sourceId: data.sourceId! }),
              ),
            ],
            {
              type: 'application/json',
            },
          ),
        );

        return fd;
      }),
    )
    .then((emailsBody) => {
      emailsBody.map((email) =>
        !fromEmployee ? emailAPI.sendEmailFromCompany(email) : emailAPI.sendEmailFromCurrentEmployee(email),
      );
    });
};
