import React, { ChangeEvent, useState } from 'react';

import { BodyText, Link, Modal, Order, OverridableValue, Select, statusIds } from '@elromcoinc/react-shared';
import { Box, MenuItem, TableCell, TableRow } from '@material-ui/core';

import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import CrewTruckChange from 'admin/components/OrderWindow/modals/CrewTruckChange';
import { getNumberOfTrucksError } from 'admin/components/OrderWindow/utils';
import settingName from 'admin/constants/SettingName';
import { OrderWindowModal } from 'common-types';

import { FlexWithNegativeMargin, ValueTableCell } from './refactoring';

const numbersForSelector = new Array(21).fill(0).map((_, idx) => idx);

export const TruckCountRow = () => {
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const { serviceIndex } = useOrderServiceIndex();
  const settings = useOrderWindowSettings();
  const isDraft = order.status === statusIds.DRAFT;
  const getServicePropertyName = useGetServicePropertyName();
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const isTruckRequired =
    settings?.[settingName.LABOR_ONLY]?.find(
      (lo) => lo.serviceType === order?.getServiceType(serviceIndex) && lo.moveType === order.moveType,
    )?.truckRequired ?? true;
  const [error, setError] = useState<string | null>(null);
  const { closingTitle, isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const numberOfMoversName = getServicePropertyName('numberOfMovers', 'crewSize');
  const numberOfTrucksName = getServicePropertyName('numberOfTrucks');
  const numberOfTrucks = order.getIn(numberOfTrucksName.split('.')) as OverridableValue;
  const numberOfMovers = order.getIn(numberOfMoversName.split('.')) as OverridableValue;

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOnMoveDetailsSave = (data: [string, any][]) => {
    data.forEach(([name, value]) => onChange({ name, value }));

    setOpenModal(null);
  };

  const handleOnTruckMoverChange = (data: OverridableValue) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    const compareFieldName = name === numberOfMoversName ? numberOfTrucksName : numberOfMoversName;
    const compareFieldValue = (order.getIn(compareFieldName.split('.')) as OverridableValue)?.value();
    const numberOfMoversValue = name === numberOfMoversName ? value : compareFieldValue;
    const numberOfTrucksValue = name === numberOfTrucksName ? compareFieldValue : value;

    if (
      numberOfTrucksValue != null &&
      numberOfMoversValue != null &&
      numberOfTrucksValue > numberOfMoversValue &&
      !isClosing
    ) {
      setError(getNumberOfTrucksError(+numberOfTrucksValue, +numberOfMoversValue));
      return;
    }

    onChange({ name, value: data.setManual(+value !== data.calculated ? +value || 0 : null) });
  };

  const handleCloseErrorModal = () => {
    setError(null);
  };

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Box display="flex">
          <Link
            disabled={isCompleted || isLockSales}
            onClick={handleOpenModal(OrderWindowModal.TRUCK)}
            data-testid="truckCount"
          >
            Trucks
          </Link>
          {!isTruckRequired && (
            <Box ml={0.5}>
              <BodyText>(not included)</BodyText>
            </Box>
          )}
          {openModal === OrderWindowModal.TRUCK && (
            <CrewTruckChange
              name={numberOfTrucksName}
              title={`Edit Trucks ${isTruckRequired ? '' : '(not included)'}${closingTitle}`}
              onSave={handleOnMoveDetailsSave}
              onCancel={handleOpenModal(null)}
              value={numberOfTrucks}
              max={numberOfMovers.value()!}
            />
          )}
          {error && (
            <Modal
              maxWidth="xs"
              onClose={handleCloseErrorModal}
              title="Error"
              open
              actions={[{ label: 'Ok', onClick: handleCloseErrorModal }]}
            >
              <BodyText>{error}</BodyText>
            </Modal>
          )}
        </Box>
      </TableCell>
      <ValueTableCell>
        {isDraft && numberOfTrucks.value() === null ? (
          <Text value={0} />
        ) : (
          <FlexWithNegativeMargin
            $manual={numberOfTrucks.manual !== null && numberOfTrucks.manual !== numberOfTrucks.calculated}
          >
            <Select
              disabled={!order.disableAutoCalculation || isCompleted || isLockSales}
              value={numberOfTrucks.value()}
              hiddenLabel
              data-testvalue={`truckNumber-${numberOfTrucks.value()}`}
              InputProps={{
                disableUnderline: true,
              }}
              name={numberOfTrucksName}
              onChange={handleOnTruckMoverChange(numberOfTrucks)}
            >
              {numbersForSelector.map((value) => (
                <MenuItem key={value} value={value}>
                  <BodyText color={value === numberOfTrucks.calculated ? 'default' : 'error'}>{value}</BodyText>
                </MenuItem>
              ))}
            </Select>
          </FlexWithNegativeMargin>
        )}
      </ValueTableCell>
    </TableRow>
  );
};
