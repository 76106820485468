import {
  BACKEND_DATE_FORMAT,
  MoveType,
  OrderStatusIdType,
  TruckAssignmentDto,
  calculateJobDurationMinutes,
  calculateStartTimeMinutes,
  getDeliveryDaysRange,
  getDeliveryJobDurationMinutes,
  getDeliveryStartTimeMinutes,
  isIncludesDeliveryDate,
  theme,
  toDate,
} from '@elromcoinc/react-shared';
import { add, differenceInCalendarDays, endOfDay, format, isSameDay, parse } from 'date-fns';
import { List, Record } from 'immutable';

import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import { uuid } from 'admin/utils';

interface JobProps {
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  customerFirstName: string;
  customerLastName: string;
  customerName: string;
  customerPhone: string;
  date: Date | string;
  endTime: Date | string;
  fromAddress: string;
  jobTime: string;
  orderStatus: OrderStatusIdType | null;
  moveType: MoveType;
  numberOfMovers: number;
  numberOfTrucks: number;
  jobDuration: number;
  actualJobDuration: number;
  jobDurationMins: number;
  startTimeMins: number;
  orderId: number | null;
  orderNumber: string;
  serviceType: string;
  sizeDescription: string;
  startTimeEarliest: Date | string | null;
  startTimeLatest: Date | string | null;
  toAddress: string;
  truckIds: List<number>;
  deliveryTruckIds: List<number>;
  deliveryDateStart: Date | string | null;
  deliveryDateEnd: Date | string | null;
  deliveryTimeEarliest: Date | string | null;
  arrivalTimeEarliest: Date | string | null;
  arrivalTimeLatest: Date | string | null;
  deliveryTruckReturnTime: Date | string | null;
  uuid: string | null;
  color: string;
  level: { [key: string]: number };
  trucks: TruckAssignmentDto[];

  deliveryJob: boolean;
  deliveryJobStart: boolean;
  deliveryJobEnd: boolean;
}

export default class Job extends Record<JobProps>({
  contactFirstName: '',
  contactLastName: '',
  contactPhone: '',
  customerFirstName: '',
  customerLastName: '',
  customerName: '',
  customerPhone: '',
  date: new Date(),
  endTime: new Date(),
  fromAddress: '',
  jobTime: '',
  orderStatus: null,
  moveType: MoveType.RESIDENTIAL,
  numberOfMovers: 0,
  jobDuration: 0,
  actualJobDuration: 0,
  numberOfTrucks: 0,
  jobDurationMins: 0,
  startTimeMins: 0,
  orderId: null,
  orderNumber: '',
  serviceType: '',
  sizeDescription: '',
  startTimeEarliest: '',
  startTimeLatest: null,
  toAddress: '',
  truckIds: List(),
  deliveryTruckIds: List(),
  deliveryDateStart: null,
  deliveryDateEnd: null,
  deliveryTimeEarliest: null,
  arrivalTimeEarliest: null,
  arrivalTimeLatest: null,
  deliveryTruckReturnTime: null,
  // UI props
  uuid: null,
  color: theme.palette.primary.main,
  level: {},
  trucks: [],

  // UI props for displaying delivery job
  deliveryJob: false,
  deliveryJobStart: false,
  deliveryJobEnd: false,
}) {
  static create(data: Partial<JobProps> = {}, color = '') {
    const date = parse(data.date as string, BACKEND_DATE_FORMAT, new Date());
    const startTimeEarliest = parse(data.startTimeEarliest as string, TIME_STRING_FORMAT, date);
    const startTimeLatest = parse(data.startTimeLatest as string, TIME_STRING_FORMAT, date);
    const deliveryTimeEarliest = data.deliveryTimeEarliest
      ? parse(data.deliveryTimeEarliest as string, TIME_STRING_FORMAT, date)
      : null;
    const deliveryTruckReturnTime = data.deliveryTruckReturnTime
      ? parse(data.deliveryTruckReturnTime as string, TIME_STRING_FORMAT, date)
      : null;
    const arrivalTimeEarliest = data.arrivalTimeEarliest
      ? parse(data.arrivalTimeEarliest as string, TIME_STRING_FORMAT, date)
      : null;
    const arrivalTimeLatest = data.arrivalTimeLatest
      ? parse(data.arrivalTimeLatest as string, TIME_STRING_FORMAT, date)
      : null;
    const deliveryDateStart = data.deliveryDateStart ? toDate(data.deliveryDateStart) : null;
    const deliveryDateEnd = data.deliveryDateEnd ? endOfDay(toDate(data.deliveryDateEnd)!) : null;
    const truckIds = (data.trucks || []).map((truck) => !truck?.delivery && truck?.id).filter(Boolean) as number[];
    const deliveryTruckIds = (data.trucks || [])
      .map((truck) => truck?.delivery && truck?.id)
      .filter(Boolean) as number[];

    const startTimeMins = calculateStartTimeMinutes(date, { startTimeEarliest: data.startTimeEarliest as string });
    let endTime = add(startTimeEarliest, { seconds: +data.actualJobDuration! });
    endTime = differenceInCalendarDays(endTime, date) === 0 ? endTime : endOfDay(date);
    const jobDurationMins = calculateJobDurationMinutes(date, {
      startTimeEarliest: data.startTimeEarliest as string,
      actualJobDuration: data.actualJobDuration!,
    });
    const job = new Job(data)
      .set('uuid', uuid())
      .set('startTimeMins', startTimeMins)
      .set('jobDurationMins', jobDurationMins)
      .set('truckIds', List(truckIds || []))
      .set('deliveryTruckIds', List(deliveryTruckIds || []))
      .set('date', date)
      .set('startTimeEarliest', startTimeEarliest)
      .set('startTimeLatest', startTimeLatest)
      .set('endTime', endTime)
      .set('deliveryDateStart', deliveryDateStart)
      .set('deliveryDateEnd', deliveryDateEnd)
      .set('deliveryTimeEarliest', deliveryTimeEarliest)
      .set('arrivalTimeEarliest', arrivalTimeEarliest)
      .set('arrivalTimeLatest', arrivalTimeLatest)
      .set('deliveryTruckReturnTime', deliveryTruckReturnTime);
    return color ? job.setColor(color) : job;
  }

  setColor(color: string) {
    return color ? this.set('color', color) : this;
  }

  getInterval() {
    return { start: this.startTimeEarliest as Date, end: this.endTime as Date };
  }

  isSameMoveDate(date: Date) {
    return isSameDay(toDate(this.date)!, date);
  }

  isIncludesDeliveryDate(date: Date) {
    return isIncludesDeliveryDate(date, this);
  }

  getDeliveryDays() {
    return getDeliveryDaysRange(this);
  }

  checkIfDeliveryJob(date: Date) {
    return this.set('deliveryJob', this.isIncludesDeliveryDate(date))
      .set('deliveryJobStart', !!this.deliveryDateStart && isSameDay(toDate(this.deliveryDateStart)!, date))
      .set('deliveryJobEnd', !!this.deliveryDateEnd && isSameDay(toDate(this.deliveryDateEnd)!, date));
  }

  getTruckBlockDates() {
    if (!this.deliveryDateStart || !this.deliveryDateEnd) {
      return '';
    }

    return isSameDay(this.deliveryDateStart as Date, this.deliveryDateEnd as Date)
      ? format(this.deliveryDateStart as Date, 'P')
      : `${format(this.deliveryDateStart as Date, 'P')} - ${format(this.deliveryDateEnd as Date, 'P')}`;
  }

  getJobDurationMinutes() {
    if (!this.deliveryJob) {
      return this.jobDurationMins;
    }

    return getDeliveryJobDurationMinutes(this);
  }

  getStartTimeMinutes() {
    if (!this.deliveryJob) {
      return this.startTimeMins;
    }

    return getDeliveryStartTimeMinutes(this);
  }
}
