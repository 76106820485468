import React, { useEffect, useRef, useState } from 'react';

import { BodyText, IconButton } from '@elromcoinc/react-shared';
import { Backdrop, Box, Portal, makeStyles } from '@material-ui/core';
import MaximizeIcon from '@material-ui/icons/CallMadeTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import MinimizeIcon from '@material-ui/icons/Minimize';
import clsx from 'clsx';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchEnableVideoTutorials,
  getVideos,
  setEnableVideoTutorials,
  setVideos,
} from 'admin/autodux/PlayVideoAutodux';
import { useMobile } from 'admin/hooks/useMobile';
import { getAuthUser } from 'admin/selectors/auth';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
  dialog: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: theme.zIndex.modal + 100,
    position: 'fixed',
    background: theme.palette.common.white,
  },
  fullSizeDialog: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) !important',
    width: theme.spacing(100),
    height: theme.spacing(60),
  },
  minimizedDialog: {
    right: 10,
    bottom: 10,
    width: theme.spacing(50),
    height: theme.spacing(40),
    boxShadow: theme.shadows[5],
  },
  title: {
    height: theme.spacing(6),
    display: 'flex',
    width: '98%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&.draggable-handle': {
      cursor: 'move',
    },
  },
  fullScreen: {
    width: 'auto',
    height: 'auto',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflowY: 'auto',
    transform: 'none !important',
  },
}));

const defaultWidth = 800;
const defaultHeight = 450;
const VideoPlayer = () => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const classes = useStyles();
  const videos = useSelector(getVideos) as string[] | null;
  const ref = useRef<HTMLElement | null>(null);
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);
  const [, setClick] = useState(0);
  const [minimized, setMinimized] = useState(false);
  const user = useSelector(getAuthUser);
  const userId = user?.id;

  const handleCloseModal = () => {
    dispatch(setVideos(null));
  };

  const toggleWindow = () => {
    setMinimized((state) => !state);
  };

  useEffect(() => {
    if (userId) {
      setTimeout(() => {
        dispatch(fetchEnableVideoTutorials());
      }, 1000);
    } else {
      dispatch(setEnableVideoTutorials(false));
    }
  }, [userId]);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && videos?.[0]) {
        setWidth(ref.current?.clientWidth ?? defaultWidth);
        setHeight(ref.current?.clientHeight ?? defaultHeight);
      } else {
        setClick((state) => state + 1);
      }
    }, 0);
  }, [ref.current, minimized, videos?.[0]]);

  if (!videos) {
    return null;
  }

  return (
    <Portal>
      <Backdrop open={!minimized} className={classes.backdrop} />
      <Draggable handle=".draggable-handle">
        <Box
          className={clsx(classes.dialog, minimized ? classes.minimizedDialog : classes.fullSizeDialog, {
            [classes.fullScreen]: isMobile,
          })}
          px={1}
        >
          <Box className={clsx(classes.title, { 'draggable-handle': minimized })}>
            <BodyText>Video</BodyText>
            <Box>
              <IconButton onClick={toggleWindow} onTouchStart={toggleWindow} color="primary">
                {minimized ? <MaximizeIcon /> : <MinimizeIcon />}
              </IconButton>
              <IconButton onClick={handleCloseModal} onTouchStart={handleCloseModal} color="primary">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {/*  @ts-ignore */}
          <Box width="100%" height={minimized ? '80%' : '85%'} ref={ref}>
            <iframe
              width={width.toString()}
              height={height.toString()}
              src={`https://www.youtube.com/embed/${videos?.[0]!}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Box>
      </Draggable>
    </Portal>
  );
};

export default VideoPlayer;
