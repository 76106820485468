import { ServiceType } from '@elromcoinc/react-shared';

import { AutomationRuleName } from 'common-types/template-block';

const AutomationRuleByGeneralServiceTypeNonBinding = {
  [ServiceType.LOCAL_MOVING]: AutomationRuleName.WHEN_A_NEW_LOCAL_MOVE_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.OVERNIGHT_STORAGE]: AutomationRuleName.WHEN_A_NEW_OVERNIGHT_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.LOADING_HELP]: AutomationRuleName.WHEN_A_NEW_LOADING_HELP_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.UNLOADING_HELP]: AutomationRuleName.WHEN_A_NEW_UNLOADING_HELP_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.MOVING_AND_STORAGE]: AutomationRuleName.WHEN_A_NEW_MOVING_AND_STORAGE_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.PACKING_DAY]: AutomationRuleName.WHEN_A_NEW_PACKING_DAY_HOURLY_LEAD_IS_CREATED, // for packing day we have two automation rules
  // [ServiceType.JUNK_REMOVAL]: AutomationRuleName.WHEN_A_NEW_JUNK_REMOVAL_BY_TRUCK_LEAD_IS_CREATED,
  [ServiceType.LD_INTERSTATE]: AutomationRuleName.WHEN_A_NEW_LD_INTERSTATE_NON_BINDING_LEAD_IS_CREATED,
  [ServiceType.LD_INTRASTATE]: AutomationRuleName.WHEN_A_NEW_LD_INTRASTATE_NON_BINDING_LEAD_IS_CREATED,
};

export { AutomationRuleByGeneralServiceTypeNonBinding };
