import React, { useEffect, useState } from 'react';

import { ActivitySourceType, Modal } from '@elromcoinc/react-shared';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import { List, Set } from 'immutable';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import { taskAPI } from 'admin/api';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { increaseReloadAmount } from 'admin/autodux/ReloadAutodux';
import {
  TASKS_PAGE_SIZE,
  deleteTask,
  getIsUpdateInProgress,
  getLastUpdateDate,
  sortTasks,
  updateTask,
} from 'admin/autodux/TaskAutodux';
import TaskForm from 'admin/components/OrderWindow/modals/Tasks/TaskForm';
import { VideoButton } from 'admin/components/Settings/components/VideoButton';
import { COMPLETED_VARIANT } from 'admin/components/Tasks/DisplayTaskVariant';
import TaskList from 'admin/components/Tasks/TaskList';
import { Task, TaskStatus } from 'common-types/Task';
import TabContainer from 'common/components/TabContainer';
import Tabs from 'common/components/Tabs';

import { TaskSource } from './TaskSource';

const BlueTabs = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-right: -40px;
  .MuiButtonBase-root {
    color: ${({ theme }) => theme.palette.background.paper};
    padding: 5px 40px;
  }
`;
const TasksColumn = styled(Box)`
  max-height: 440px;
  overflow-y: auto;
  overflow-x: hidden;
`;
const HISTORY_TAB_NAME = 'HISTORY';
const tabs = ['ADD TASK', HISTORY_TAB_NAME];
const ADD_TASK_TAB_INDEX = 0;

interface TasksProps {
  onSave: () => void;
  onCancel: () => void;
  open: boolean;
  source?: TaskSource | null;
  task?: Task | null;
  activitySource?: ActivitySourceType;
}

const Tasks = ({ onSave, onCancel, open, source = null, task, activitySource }: TasksProps) => {
  const dispatch = useDispatch<DispatchPromise>();
  const { enqueueSnackbar } = useSnackbar();
  const [saveClickedCount, setSaveClickedCount] = useState(0);
  const handleOnSave = () => setSaveClickedCount((state) => state + 1);
  const [tab, setTab] = useState(ADD_TASK_TAB_INDEX);
  const handleChangeTab = (event: any, index: number) => setTab(index);
  const [isFetchingUncompleted, setIsFetchingUncompleted] = useState(false);
  const [uncompleted, setUncompleted] = useState<List<Task>>(List());
  const [uncompletedCount, setUncompletedCount] = useState(0);
  const [uncompletedPageIndex, setUncompletedPageIndex] = useState(0);
  const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);
  const [completed, setCompleted] = useState<List<Task>>(List());
  const [completedCount, setCompletedCount] = useState(0);
  const [orderCompletedPageIndex, setOrderCompletedPageIndex] = useState(0);
  const [selectedSource, setSelectedSource] = useState<Partial<TaskSource> | null>(source);
  const { orderId } = selectedSource || {};
  const sourceId = orderId || (selectedSource || {}).sourceId;
  const isUpdateInProgress = useSelector(getIsUpdateInProgress) as Set<number>;
  const tabsToDisplay = selectedSource ? tabs : tabs.filter((name) => name !== HISTORY_TAB_NAME);
  const lastUpdateDate = useSelector(getLastUpdateDate);
  const [defaultActivitySource, setDefaultActivitySource] = useState(activitySource);
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  const fetchCompletedTasks = (pageIndex = 0) => {
    setIsFetchingCompleted(true);
    return taskAPI
      .getAllForCurrent({
        'activitySources.sourceId': sourceId!,
        pageIndex,
        status: [TaskStatus.COMPLETED],
        pageSize: TASKS_PAGE_SIZE,
        sortBy: 'completed',
        'avtivitySources.activitySource': defaultActivitySource,
      })
      .then(({ totalElements, pageElements, pageIndex: pi }) => {
        setCompleted((state) =>
          pi === 0
            ? List(pageElements || []).map((item) => new Task(item))
            : state.concat(List(pageElements || []).map((item) => new Task(item))),
        );
        setCompletedCount(totalElements);
        setOrderCompletedPageIndex(pi);
      })
      .catch(() => {})
      .then(() => {
        setIsFetchingCompleted(false);
      });
  };

  const fetchUncompletedTasks = (pageIndex = 0) => {
    setIsFetchingUncompleted(true);
    return taskAPI
      .getAllForCurrent({
        'activitySources.sourceId': sourceId!,
        pageIndex,
        status: [TaskStatus.READ, TaskStatus.UNREAD],
        pageSize: TASKS_PAGE_SIZE,
        'avtivitySources.activitySource': defaultActivitySource,
      })
      .then(({ totalElements, pageElements, pageIndex: pi }) => {
        setUncompleted((state) =>
          pi === 0
            ? List(pageElements || []).map((item) => new Task(item))
            : state.concat(List(pageElements || []).map((item) => new Task(item))),
        );
        setUncompletedCount(totalElements);
        setUncompletedPageIndex(pi);
      })
      .catch(() => {})
      .then(() => {
        setIsFetchingUncompleted(false);
      });
  };

  const requestUncompletedTasks = (pageIndex: number) => fetchUncompletedTasks(pageIndex);
  const requestCompletedTasks = (pageIndex: number) => fetchCompletedTasks(pageIndex);

  const fetchTasks = () => {
    fetchUncompletedTasks(0);
    fetchCompletedTasks(0);
  };

  useEffect(() => {
    if ((selectedSource && !uncompletedPageIndex && !orderCompletedPageIndex) || !isSessionExpired) {
      setTimeout(() => {
        fetchTasks();
      }, 500);
    }
  }, [lastUpdateDate, selectedSource, isSessionExpired]);

  useEffect(() => {
    if (!isSessionExpired) {
      let loadUncompletedTasksInterval: ReturnType<typeof setInterval> | null = null;

      if (!uncompletedPageIndex && selectedSource) {
        loadUncompletedTasksInterval = setInterval(() => {
          requestUncompletedTasks(0);
        }, 30e3);
      }

      return () => {
        if (loadUncompletedTasksInterval) {
          clearInterval(loadUncompletedTasksInterval);
        }
      };
    }
  }, [uncompletedPageIndex, selectedSource, lastUpdateDate, isSessionExpired]);

  useEffect(() => {
    if (!isSessionExpired) {
      let loadCompletedTasksInterval: ReturnType<typeof setInterval> | null = null;

      if (!orderCompletedPageIndex && selectedSource) {
        loadCompletedTasksInterval = setInterval(() => {
          requestCompletedTasks(0);
        }, 30e3);
      }

      return () => {
        if (loadCompletedTasksInterval) {
          clearInterval(loadCompletedTasksInterval);
        }
      };
    }
  }, [orderCompletedPageIndex, selectedSource, lastUpdateDate, isSessionExpired]);

  const toggleCompleteStatus = (t: Task) => {
    const { isCompleted } = t;
    const newTask = t.setStatus(t.isCompleted ? TaskStatus.READ : TaskStatus.COMPLETED);

    setUncompletedCount((state) => state + (!isCompleted ? -1 : 1));
    setUncompleted((state) =>
      (!isCompleted
        ? state.filter((item) => item.id !== t.id)
        : state.filter((item) => item.id !== t.id).push(newTask)
      ).sort(sortTasks),
    );
    setCompletedCount((state) => state + (isCompleted ? -1 : 1));
    setCompleted((state) =>
      (isCompleted
        ? state.filter((item) => item.id !== t.id)
        : state.filter((item) => item.id !== t.id).push(newTask)
      ).sort(sortTasks),
    );

    dispatch(updateTask(newTask, t)).then(() => {
      enqueueSnackbar('Task is updated successfully', {
        variant: 'success',
      });
      dispatch(increaseReloadAmount());
    });
  };

  const handleDeleteTask = (t: Task) =>
    dispatch(deleteTask(t)).then(() => {
      setCompleted((state) => state.filter((item) => item.id !== t.id));
      setUncompleted((state) => state.filter((item) => item.id !== t.id));

      enqueueSnackbar('Task is deleted successfully', {
        variant: 'success',
      });
    });

  const handleLoadMoreUncompleted = () => requestUncompletedTasks(uncompletedPageIndex + 1);
  const handleLoadMoreCompleted = () => requestCompletedTasks(orderCompletedPageIndex + 1);
  const handleCancel = () => {
    if (sourceId) {
      fetchTasks();
    }
    setSaveClickedCount(0);
    onCancel();
  };
  const handleOnSaveForm = () => {
    setSaveClickedCount(0);
    onSave();
    dispatch(increaseReloadAmount());
  };

  return (
    <Modal
      color="grey"
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      title="Tasks"
      fullWidth
      maxWidth="md"
      onClose={handleCancel}
      actions={
        tab === ADD_TASK_TAB_INDEX
          ? [
              { label: 'cancel', onClick: handleCancel },
              { label: 'save', onClick: handleOnSave },
            ]
          : [{ label: 'cancel', onClick: handleCancel }]
      }
    >
      <VideoButton position="absolute" video="kucKHMayOZ4" />
      <Box mx={2}>
        <BlueTabs mb={2} hidden={Boolean(task && task.id)}>
          <Tabs tabs={tabsToDisplay} value={tab} onChange={handleChangeTab} />
        </BlueTabs>
        <SwipeableViews index={tab} onChangeIndex={setTab}>
          <TabContainer>
            {open && (
              <TaskForm
                source={source}
                task={task!}
                onSave={handleOnSaveForm}
                saveClickedCount={saveClickedCount}
                onSelectedSource={setSelectedSource}
                defaultActivitySource={defaultActivitySource}
                setDefaultActivitySource={setDefaultActivitySource}
              />
            )}
          </TabContainer>
          <TabContainer>
            {(isFetchingCompleted || isFetchingUncompleted) && <LinearProgress />}
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">{`To Do (${uncompletedCount})`}</Typography>
                <TasksColumn>
                  {uncompleted.size ? (
                    <TaskList
                      variant={COMPLETED_VARIANT}
                      data={uncompleted}
                      toggleCompleteStatus={toggleCompleteStatus}
                      isUpdateInProgress={isUpdateInProgress}
                      hasMoreItems={uncompletedCount > uncompleted.size}
                      isLoadingInProgress={isFetchingUncompleted}
                      onLoadMore={handleLoadMoreUncompleted}
                      onDelete={handleDeleteTask}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center">
                      No tasks to do
                    </Typography>
                  )}
                </TasksColumn>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">{`Completed (${completedCount})`}</Typography>
                <TasksColumn>
                  {completed.size ? (
                    <TaskList
                      variant={COMPLETED_VARIANT}
                      data={completed}
                      toggleCompleteStatus={toggleCompleteStatus}
                      isUpdateInProgress={isUpdateInProgress}
                      hasMoreItems={completedCount > completed.size}
                      isLoadingInProgress={isFetchingCompleted}
                      noMoreTasksText="No more completed tasks"
                      onLoadMore={handleLoadMoreCompleted}
                      onDelete={handleDeleteTask}
                    />
                  ) : (
                    <Typography variant="subtitle1" align="center">
                      It&apos;s time to complete some tasks
                    </Typography>
                  )}
                </TasksColumn>
              </Grid>
            </Grid>
          </TabContainer>
        </SwipeableViews>
      </Box>
    </Modal>
  );
};

export default Tasks;
