import React, { useState } from 'react';

import { BodyText, MoveTypeShort, MoveUnit, SurveyType, SurveyTypeName } from '@elromcoinc/react-shared';
import { Box, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import pt from 'prop-types';

import { useOrderSettingUnits, useOrderState } from 'admin/components/OrderWindow/context';
import { useOrderWindowSettings } from 'admin/components/OrderWindow/context/useOrderWindowSettings';
import { IconLabelButton } from 'common/components/Widgets';
import { ClipBoardServicesIcon } from 'common/components/icons';

import useOrderSizing from '../hooks/useOrderSizing';

export const ServicesButton = ({ setOpenModal, hasInHomeService }) => {
  const { order } = useOrderState();
  const moveType = order.moveType;
  const [anchorElOfServices, setAnchorElOfServices] = useState(null);
  const settings = useOrderWindowSettings();
  const { activeCuFt, activeWeight, currentSite } = useOrderSizing(order);
  const { moveUnit } = useOrderSettingUnits();
  const defaultMoveSize = settings?.[`inhome_survey.${MoveTypeShort[moveType]}.allowIfOverMoveSizeId`];
  const defaultCuFt = settings?.[`inhome_survey.${MoveTypeShort[moveType]}.requiredSurveyCuFt`];
  const activeWeightByMoveUnit = moveUnit === MoveUnit.CUBIC_FEET ? activeCuFt : activeWeight;
  const isDisabledServices = activeWeightByMoveUnit < defaultCuFt && currentSite?.id !== defaultMoveSize;

  const handleServicesClick = ({ currentTarget }) => {
    setAnchorElOfServices(currentTarget);
  };

  const handleServicesClose = (selectedItem) => () => {
    setOpenModal(selectedItem);
    setAnchorElOfServices(null);
  };

  return (
    <Box position="relative" display="flex" alignItems="center">
      <IconLabelButton
        color={hasInHomeService ? 'primary' : 'default'}
        startIcon={<ClipBoardServicesIcon />}
        onClick={handleServicesClick}
        data-testid="ServicesButton"
        disabled={isDisabledServices}
      >
        Services
      </IconLabelButton>
      <Popover
        open={Boolean(anchorElOfServices)}
        anchorEl={anchorElOfServices}
        id="services-list"
        onClose={() => setAnchorElOfServices(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List disablePadding>
          <ListItem button dense component="li" onClick={handleServicesClose(SurveyType.IN_HOME_ESTIMATE)}>
            <ListItemText
              primary={
                <>
                  <BodyText>{SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}</BodyText>
                </>
              }
            />
          </ListItem>
          <ListItem button dense component="li" onClick={handleServicesClose(SurveyType.VIRTUAL_ESTIMATE)}>
            <ListItemText
              primary={
                <>
                  <BodyText>{SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}</BodyText>
                </>
              }
            />
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
};

ServicesButton.propTypes = {
  setOpenModal: pt.func.isRequired,
};
