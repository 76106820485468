import React, { ChangeEvent, useState } from 'react';

import { BodyText, Link, Modal, Order, OverridableValue, Select, statusIds } from '@elromcoinc/react-shared';
import { MenuItem, TableCell, TableRow } from '@material-ui/core';

import { Text } from 'admin/components/OrderWindow/components';
import { useOrderChangeSet, useOrderClosingContext, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import CrewTruckChange from 'admin/components/OrderWindow/modals/CrewTruckChange';
import { getNumberOfTrucksError } from 'admin/components/OrderWindow/utils';
import { OrderWindowModal } from 'common-types';

import { FlexWithNegativeMargin, ValueTableCell } from './refactoring';

const numbersForSelector = new Array(21).fill(0).map((_, idx) => idx);

interface CrewSizeRowProps {
  name?: 'numberOfMovers' | 'numberOfDeliveryMovers';
}

export const CrewSizeRow = ({ name = 'numberOfMovers' }: CrewSizeRowProps) => {
  const { order } = useOrderState() as { order: Order };
  const isDraft = order.status === statusIds.DRAFT;
  const [error, setError] = useState<string | null>(null);
  const { onChange } = useOrderChangeSet();
  const getServicePropertyName = useGetServicePropertyName();
  const { closingTitle, isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const isDelivery = name === 'numberOfDeliveryMovers';
  const numberOfMoversName = getServicePropertyName(name, isDelivery ? 'deliveryCrewSize' : 'crewSize');
  const numberOfTrucksName = getServicePropertyName('numberOfTrucks');
  const [openModal, setOpenModal] = useState<OrderWindowModal | null>(null);
  const numberOfMovers = order.getIn(numberOfMoversName.split('.')) as OverridableValue;
  const numberOfTrucks = order.getIn(numberOfTrucksName.split('.')) as OverridableValue;

  const handleOpenModal = (modalName: OrderWindowModal | null) => () => {
    setOpenModal(modalName);
  };

  const handleOnTruckMoverChange = (data: OverridableValue) => (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    const compareFieldName = name === numberOfMoversName ? numberOfTrucksName : numberOfMoversName;
    const compareFieldValue = (order.getIn(compareFieldName.split('.')) as OverridableValue)?.value();
    const [numberOfMoversValue, numberOfTrucksValue] =
      name === numberOfMoversName ? [value, compareFieldValue] : [compareFieldValue, value];

    if (
      numberOfTrucksValue != null &&
      numberOfMoversValue != null &&
      numberOfTrucksValue > numberOfMoversValue &&
      !isClosing
    ) {
      setError(getNumberOfTrucksError(+numberOfTrucksValue, +numberOfMoversValue));
      return;
    }

    onChange({ name, value: data.setManual(+value !== data.calculated ? +value || 0 : null) });
  };

  const handleOnMoveDetailsSave = (data: [string, any][]) => {
    data.forEach(([name, value]) => onChange({ name, value }));
    setOpenModal(null);
  };

  const handleCloseErrorModal = () => {
    setError(null);
  };

  const deliveryLabel = isDelivery ? 'Delivery ' : '';

  return (
    <TableRow>
      <TableCell size="small" padding="none">
        <Link
          disabled={isCompleted || isLockSales}
          onClick={handleOpenModal(OrderWindowModal.CREW)}
          data-testid="crewSize"
        >
          {`${deliveryLabel}Crew Size`}
        </Link>
        {openModal === OrderWindowModal.CREW && (
          <CrewTruckChange
            name={numberOfMoversName}
            title={`Edit ${deliveryLabel}Crew Size ${closingTitle}`}
            onSave={handleOnMoveDetailsSave}
            onCancel={handleOpenModal(null)}
            value={numberOfMovers}
            min={numberOfTrucks.value()!}
          />
        )}
        {error && (
          <Modal
            maxWidth="xs"
            onClose={handleCloseErrorModal}
            title="Error"
            open
            actions={[{ label: 'Ok', onClick: handleCloseErrorModal }]}
          >
            <BodyText>{error}</BodyText>
          </Modal>
        )}
      </TableCell>
      <ValueTableCell>
        {isDraft && numberOfMovers.value() === null ? (
          <Text value={0} />
        ) : (
          <FlexWithNegativeMargin
            $manual={numberOfMovers.manual !== null && numberOfMovers.manual !== numberOfMovers.calculated}
          >
            <Select
              fullWidth={false}
              disabled={!order.disableAutoCalculation || isCompleted || isLockSales}
              value={numberOfMovers.value()}
              hiddenLabel
              data-testvalue={`crewNumber-${numberOfMovers.value()}`}
              InputProps={{
                disableUnderline: true,
              }}
              name={numberOfMoversName}
              onChange={handleOnTruckMoverChange(numberOfMovers)}
            >
              {numbersForSelector.slice(1).map((value) => (
                <MenuItem key={value} value={value}>
                  <BodyText color={value === numberOfMovers.calculated ? 'default' : 'error'}>{value}</BodyText>
                </MenuItem>
              ))}
            </Select>
          </FlexWithNegativeMargin>
        )}
      </ValueTableCell>
    </TableRow>
  );
};
