import React, { useState } from 'react';

import {
  BodySmallText,
  BodyText,
  IconButton,
  Order,
  SurveyStatusTypeName,
  SurveyType,
  SurveyTypeName,
  UI_LOCALIZED_TIME_FORMAT,
  toDate,
} from '@elromcoinc/react-shared';
import { Box, Grid, LinearProgress, Tooltip, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';

import { SurveyModal } from 'admin/components/OrderWindow/modals/SurveyModal';
import { TIME_STRING_FORMAT } from 'admin/constants/DateTimeFormats';
import useInHomeEstimatePermissions from 'admin/hooks/useInHomeEstimatePermissions';
import Card from 'common/components/Card';

const useStyles = makeStyles(() => ({
  servicesBox: {
    minHeight: '9rem',
    maxHeight: '9rem',
    position: 'relative',
    marginTop: '0.5rem',
  },
  servicesContainer: {
    position: 'absolute',
    maxHeight: '12rem',
    overflowY: 'auto',
  },
}));

const MODALS = {
  IN_HOME_ESTIMATE: SurveyType.IN_HOME_ESTIMATE,
  VIRTUAL_ESTIMATE: SurveyType.VIRTUAL_ESTIMATE,
};

export const Services = ({
  isLoadingSurveyServices,
  moveType,
  surveyServices,
  order,
  reloadSurveyServices,
  inHomeEstimateService,
  virtualEstimateService,
  ...rest
}) => {
  const classes = useStyles();
  const [isOpenEditModal, setIsOpenEditModal] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedService, setSelectedService] = useState('');
  const { canEdit, canAssign } = useInHomeEstimatePermissions();
  const canMakeChanges = canEdit && canAssign;
  const editOrViewEstimateService = canMakeChanges ? 'Edit' : 'View';

  const renderDate = (date) => format(toDate(date), 'MM/dd/yyyy');
  const renderTime = (time) => format(toDate(time, TIME_STRING_FORMAT), UI_LOCALIZED_TIME_FORMAT);
  const handleCloseEditModal = () => setIsOpenEditModal(null);

  const handleSaveEditModal = () => {
    setIsOpenEditModal(null);
    reloadSurveyServices();
  };

  const handleOpenEditModal = (orderServiceType) => () => {
    setSelectedService(orderServiceType);

    switch (orderServiceType) {
      case SurveyType.VIRTUAL_ESTIMATE:
        return setIsOpenEditModal(MODALS.VIRTUAL_ESTIMATE);
      case SurveyType.IN_HOME_ESTIMATE:
        return setIsOpenEditModal(MODALS.IN_HOME_ESTIMATE);
      default:
        return enqueueSnackbar('Under construction', { variant: 'info' });
    }
  };

  const renderEditModal = () => {
    switch (isOpenEditModal) {
      case MODALS.IN_HOME_ESTIMATE:
        return (
          <SurveyModal
            onSave={handleSaveEditModal}
            onCancel={handleCloseEditModal}
            open
            title={SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}
            subtitle={`${editOrViewEstimateService} ${SurveyTypeName[SurveyType.IN_HOME_ESTIMATE]}`}
            moveType={moveType}
            order={order}
            currentService={selectedService}
            surveyType={SurveyType.IN_HOME_ESTIMATE}
            existingEstimateService={inHomeEstimateService}
          />
        );
      case MODALS.VIRTUAL_ESTIMATE:
        return (
          <SurveyModal
            onSave={handleSaveEditModal}
            onCancel={handleCloseEditModal}
            open
            title={SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}
            subtitle={`${editOrViewEstimateService} ${SurveyTypeName[SurveyType.VIRTUAL_ESTIMATE]}`}
            moveType={moveType}
            order={order}
            currentService={selectedService}
            surveyType={SurveyType.VIRTUAL_ESTIMATE}
            existingEstimateService={virtualEstimateService}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box width="100%">
      <Card title="Services" {...rest}>
        <Box className={classes.servicesBox}>
          {isLoadingSurveyServices ? (
            <LinearProgress />
          ) : (
            <Grid container direction="column" classes={{ container: classes.servicesContainer }}>
              {surveyServices?.map(
                ({ id, orderServiceType, estimatorWork, surveyDate, assignedName, status, startTimeFrom }) => (
                  <React.Fragment key={id}>
                    <Grid container item xs={12}>
                      <Grid item xs={4}>
                        <Box display="flex" flexDirection="column">
                          <Box minHeight={34} display="flex" alignItems="center">
                            <BodyText>
                              <b>{SurveyTypeName[orderServiceType]}:</b>
                            </BodyText>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" alignItems="center" mb={0.5}>
                            <BodyText>
                              <b>{surveyDate && renderDate(surveyDate)},&nbsp;</b>
                              <b>&nbsp;{renderTime(startTimeFrom)}</b>
                            </BodyText>
                            <Tooltip title={`${editOrViewEstimateService} ${SurveyTypeName[orderServiceType]}`}>
                              <Box component="span">
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={handleOpenEditModal(orderServiceType)}
                                >
                                  {canMakeChanges ? <EditIcon /> : <VisibilityIcon />}
                                </IconButton>
                              </Box>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" flexDirection="column"></Box>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                      <Grid item xs={4}>
                        <BodySmallText>By:&nbsp; {assignedName}</BodySmallText>
                      </Grid>
                      <Grid item xs={4}>
                        <BodySmallText>
                          To: {`${estimatorWork?.estimatorFirstName} ${estimatorWork?.estimatorLastName}`}
                        </BodySmallText>
                      </Grid>
                      <Grid item xs={4}>
                        <BodySmallText>Status: {SurveyStatusTypeName[status]}</BodySmallText>
                      </Grid>
                    </Grid>
                    <Box minHeight={15}></Box>
                  </React.Fragment>
                ),
              )}
              {renderEditModal()}
            </Grid>
          )}
        </Box>
      </Card>
    </Box>
  );
};

Services.propTypes = {
  isLoadingSurveyServices: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  surveyServices: pt.array,
  moveType: pt.string.isRequired,
  order: pt.instanceOf(Order).isRequired,
  reloadSurveyServices: pt.func,
  inHomeEstimateService: pt.object,
  virtualEstimateService: pt.object,
};

Services.defaultProps = {
  isLoadingSurveyServices: false,
  surveyServices: [],
  reloadSurveyServices: null,
  inHomeEstimateService: null,
  virtualEstimateService: null,
};
