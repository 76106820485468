import React from 'react';

import {
  BodySmallText,
  BodyText,
  CurrencyInput,
  IconButton,
  PriceAdjustmentType,
  formatCurrency,
  roundNumberToFixedDigits,
  useUpdateEffect,
} from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

import { OrderPayroll } from 'admin/components/OrderWindow/modals/Payroll/Payroll';
import {
  EmployeeCompensationName,
  payrollForeman,
  payrollHelper,
  payrollSales,
} from 'admin/components/OrderWindow/modals/Payroll/PayrollConstants';
import { useMobile } from 'admin/hooks/useMobile';
import { EmployeeCommissionNames, EmployeeCommissionType } from 'common-types/payroll';

interface PayrollEmployeeCompensationProps {
  index: number;
  employeeIndex: number;
  remove: () => Promise<any>;
  name: typeof payrollSales | typeof payrollForeman | typeof payrollHelper;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      borderRight: `1px solid ${theme.palette.divider}`,
      borderTop: `1px solid ${theme.palette.divider}`,
      height: '37px',
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        height: '45px',
      },
    },
    amountInput: {
      '& input': {
        height: '14px',
      },
    },
    amountHourlyInput: {
      '& input': {
        paddingLeft: 0,
      },
      '& .MuiFilledInput-adornedStart': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
        },
      },
    },
  }),
);

const hourlyCompensations = [EmployeeCommissionType.HOURLY_RATE];
const noValueNeededCompensations = [EmployeeCommissionType.PER_JOB_BONUS];

export const PayrollEmployeeCompensation = ({
  index,
  employeeIndex,
  remove,
  name: positionName,
}: PayrollEmployeeCompensationProps) => {
  const isMobile = useMobile();
  const classes = useStyles();
  const { watch, setValue } = useFormContext<OrderPayroll>();
  const name = `${positionName}.${employeeIndex}.${EmployeeCompensationName}.${index}` as const;
  const compensation = watch(name);
  const isHourlyRate = hourlyCompensations.includes(compensation?.type);
  const isDisplayValue = !noValueNeededCompensations.includes(compensation?.type);

  useUpdateEffect(() => {
    if (!compensation) {
      return;
    }

    const amountAsNumber = +compensation.rate.amount || 0;
    const amount = compensation.rate.type === PriceAdjustmentType.ABSOLUTE ? amountAsNumber : amountAsNumber / 100;
    setValue(`${name}.total`, roundNumberToFixedDigits((+compensation.value || 0) * amount));
  }, [compensation?.value, compensation?.rate.amount]);

  if (!compensation) {
    return <></>;
  }

  return (
    <Grid container xs={12} alignItems="center">
      <Grid item xs={3} sm={5}>
        <Box className={classes.tableCell}>
          {isMobile ? (
            <BodySmallText>{EmployeeCommissionNames[compensation.type]}</BodySmallText>
          ) : (
            <BodyText>{EmployeeCommissionNames[compensation.type]}</BodyText>
          )}
        </Box>
      </Grid>
      <Grid item xs={3} sm={2}>
        <Box className={classes.tableCell}>
          <CurrencyInput
            hiddenLabel
            fullWidth
            name={`${name}.value`}
            className={classes.amountInput}
            InputProps={{
              disableUnderline: true,
            }}
            size="small"
          />
        </Box>
      </Grid>
      <Grid item xs={isHourlyRate ? 2 : 3} sm={isHourlyRate ? 1 : 2}>
        <Box className={classes.tableCell}>
          {isDisplayValue && (
            <CurrencyInput
              hiddenLabel
              fullWidth
              name={`${name}.rate.amount`}
              amountType={isHourlyRate ? null : compensation.rate.type}
              className={clsx(classes.amountInput, classes.amountHourlyInput)}
              InputProps={{
                disableUnderline: true,
              }}
              size="small"
            />
          )}
        </Box>
      </Grid>
      {isHourlyRate && (
        <Grid item xs={1}>
          <Box className={classes.tableCell} justifyContent="center">
            {isMobile ? <BodySmallText>hours</BodySmallText> : <BodyText>hours</BodyText>}
          </Box>
        </Grid>
      )}
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-end" className={classes.tableCell}>
          <BodyText>{`$${formatCurrency(+compensation.total)}`}</BodyText>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box display="flex" justifyContent="flex-end" className={classes.tableCell}>
          <IconButton color="secondary" onClick={remove}>
            <Delete />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};
