import React, { forwardRef } from 'react';

import {
  BACKEND_DATE_FORMAT,
  BodyBigText,
  BodyText,
  HeaderBigText,
  HeaderSmallText,
  InvoiceDto,
  InvoiceItemDto,
  PriceAdjustmentType,
  UI_DATE_FORMAT_SHORT,
  formatCurrency,
  toDate,
  uuid,
} from '@elromcoinc/react-shared';
import { Box, Divider, Grid, Paper, Theme, createStyles, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import CompanyLogo from 'admin/components/CompanyLogo';
import {
  ADDRESS,
  AMOUNT,
  DESCRIPTION,
  DISCOUNT,
  DUE_DATE,
  ISSUE_DATE,
  ITEMS,
  InvoicesItemLabels,
  PAID_AMOUNT,
  TAX,
  TOTAL,
  TYPE,
  UNIT_COST,
} from 'admin/components/OrderWindow/modals/Invoices/InvoicesItemLabels';
import { CONTACT_INFO, EMAIL, PRIMARY_PHONE } from 'admin/constants/FieldNames';
import { CompanyInfo } from 'common-types/CompanyInfo';

import { PrintInvoiceRow } from './PrintInvoiceRow';

const useStyles = makeStyles<Theme>(({ palette, spacing }) =>
  createStyles({
    form: {
      position: 'relative',
      zIndex: 1,
    },
    title: {
      color: palette.common.black,
      padding: spacing(1, 0),
    },
    totalInfo: {
      position: 'relative',
      maxWidth: spacing(45),
      width: spacing(45),
      padding: spacing(2.5, 1, 1.5),
      bottom: spacing(1),
      zIndex: 0,
    },
  }),
);
interface PrintInvoiceModalProps {
  title: string;
  values: InvoiceDto;
}

const PrintInvoice = forwardRef<HTMLElement, PrintInvoiceModalProps>(({ title, values }, invoiceRef) => {
  const classes = useStyles();
  const companyInfo = useSelector(getCompanyInfo) as CompanyInfo;
  const firstName = values?.[CONTACT_INFO]?.firstName;
  const lastName = values?.[CONTACT_INFO]?.lastName;
  const primaryPhoneNumber = values?.[CONTACT_INFO]?.[PRIMARY_PHONE];
  const primaryEmail = values?.[CONTACT_INFO]?.[EMAIL];
  const { street1, city, state, postalCode } = values?.[ADDRESS];
  const isNew = !values.id;
  const issueDateValue = !isNew ? values?.[ISSUE_DATE] : format(new Date(), BACKEND_DATE_FORMAT);
  const dueDateValue = !isNew ? values?.[DUE_DATE] : format(new Date(), BACKEND_DATE_FORMAT);
  const items = values?.[ITEMS] as InvoiceItemDto[];
  const formatIssueDate = format(toDate(issueDateValue)!, UI_DATE_FORMAT_SHORT);
  const formatDueDate = format(toDate(dueDateValue)!, UI_DATE_FORMAT_SHORT);
  const discountTypeValue = values?.[DISCOUNT]?.[TYPE];
  const discountAmountValue = values?.[DISCOUNT]?.[AMOUNT] || 0;
  const taxAmount = values?.[TAX]?.[AMOUNT] || 0;
  const paidAmount = values?.[PAID_AMOUNT] || 0;
  const subtotal = items.reduce(
    (acc, item) => acc + ((item.total.manual !== null ? +item.total.manual : +item.total.calculated!) || 0),
    0,
  );
  const discountValue =
    discountTypeValue === PriceAdjustmentType.PERCENT ? (subtotal / 100) * +discountAmountValue : +discountAmountValue;
  const taxValue =
    values?.[TAX]?.[TYPE] === PriceAdjustmentType.PERCENT
      ? ((subtotal - discountValue) / 100) * +taxAmount
      : +taxAmount;

  const keys = items.map(uuid);

  return (
    // @ts-ignore
    <Box ref={invoiceRef} className={invoiceRef ? 'print-section' : ''}>
      <Box px={3} py={3}>
        <Box pb={1}>
          <Grid container>
            <Grid xs={4}>
              <HeaderBigText>
                <b>Send To</b>
              </HeaderBigText>
            </Grid>
            <Grid xs={4}>
              <CompanyLogo />
            </Grid>
            <Grid xs={4}>
              <BodyBigText>{title}</BodyBigText>
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item xs={7}>
            <Grid item container>
              <Grid item xs={12}>
                <HeaderSmallText gutterBottom>
                  <b>Customer Information</b>
                </HeaderSmallText>
              </Grid>
              <Grid item xs={12}>
                <BodyText>Full Name</BodyText>
                <BodyBigText gutterBottom>
                  {firstName} {lastName}
                </BodyBigText>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <BodyText>Email</BodyText>
              <BodyBigText gutterBottom>{primaryEmail}</BodyBigText>
            </Grid>
            <Grid item xs={12}>
              <BodyText>Phone Number</BodyText>
              <BodyBigText gutterBottom>{primaryPhoneNumber}</BodyBigText>
            </Grid>
            <Grid item xs={12}>
              <BodyText>Address</BodyText>
              <BodyBigText gutterBottom>
                {street1}, {city}, {state}, {postalCode}
              </BodyBigText>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item container>
              <Grid item xs={12}>
                <HeaderSmallText gutterBottom>
                  <b>Company Information</b>
                </HeaderSmallText>
              </Grid>
              <Grid item xs={12}>
                <BodyText>Company Name</BodyText>
                <BodyBigText gutterBottom>{companyInfo.basicInfo?.companyName ?? ''}</BodyBigText>
              </Grid>
              <Grid item xs={12}>
                <BodyText>Company Email</BodyText>
                <BodyBigText gutterBottom>{companyInfo.basicInfo?.email ?? ''}</BodyBigText>
              </Grid>
              <Grid item xs={12}>
                <BodyText>Company Phone</BodyText>
                <BodyBigText gutterBottom>{companyInfo.basicInfo?.phone ?? ''}</BodyBigText>
              </Grid>
              <Grid item xs={6}>
                <BodyText>{InvoicesItemLabels[ISSUE_DATE]}</BodyText>
                <BodyBigText gutterBottom>{formatIssueDate}</BodyBigText>
              </Grid>
              <Grid item xs={6}>
                <BodyText>{InvoicesItemLabels[DUE_DATE]}</BodyText>
                <BodyBigText gutterBottom>{formatDueDate}</BodyBigText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box px={3}>
        <Box className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={1} square>
                <Grid container classes={{ root: classes.title }}>
                  <Grid item xs={3}>
                    <Box ml={2}>
                      <BodyText>
                        <b>Item</b>
                      </BodyText>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box ml={2}>
                      <BodyText>
                        <b>{InvoicesItemLabels[DESCRIPTION]}</b>
                      </BodyText>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box ml={2}>
                      <BodyText>
                        <b>{InvoicesItemLabels[UNIT_COST]}</b>
                      </BodyText>
                    </Box>
                  </Grid>
                  <Grid item xs={1}>
                    <Box ml={2}>
                      <BodyText>
                        <b>Qty</b>
                      </BodyText>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box ml={2}>
                      <BodyText>
                        <b>{InvoicesItemLabels[DISCOUNT]}</b>
                      </BodyText>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Box ml={2}>
                      <BodyText>
                        <b>{InvoicesItemLabels[TOTAL]}</b>
                      </BodyText>
                    </Box>
                  </Grid>
                </Grid>
                {items.map((item, index: number) => (
                  <PrintInvoiceRow key={keys[index]} item={item} />
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mr={3}>
        <Paper elevation={2} classes={{ root: classes.totalInfo }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <BodyText>Subtotal</BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyText align="right">{`$${formatCurrency(subtotal)}`}</BodyText>
            </Grid>
            {!!discountValue && (
              <>
                <Grid item xs={6}>
                  <BodyText>{InvoicesItemLabels[DISCOUNT]}</BodyText>
                </Grid>
                <Grid item xs={6}>
                  <BodyText align="right">{`$ ${formatCurrency(discountValue)}`}</BodyText>
                </Grid>
              </>
            )}
            {!!taxValue && (
              <>
                <Grid item xs={6}>
                  <BodyText>{InvoicesItemLabels[TAX]}</BodyText>
                </Grid>
                <Grid item xs={6}>
                  <BodyText align="right">{`$ ${formatCurrency(taxValue)}`}</BodyText>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <BodyText>
                <b>Total</b>
              </BodyText>
            </Grid>
            <Grid item xs={6}>
              <BodyText align="right">{`$ ${formatCurrency(subtotal - discountValue + taxValue)}`}</BodyText>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={5}>
              <BodyText>Paid</BodyText>
            </Grid>
            <Grid item xs={7}>
              <BodyText align="right">{paidAmount}</BodyText>
            </Grid>
            <Grid item xs={5}>
              <BodyText>
                <b>Balance Due (USD)</b>
              </BodyText>
            </Grid>
            <Grid item xs={7}>
              <BodyText align="right">
                <b>{`$ ${formatCurrency(subtotal - paidAmount - discountValue + taxValue)}`}</b>
              </BodyText>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
});

export { PrintInvoice };
