import React, { createRef, useRef, useState } from 'react';

import { TextInput } from '@elromcoinc/react-shared';
import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Popover,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SearchIcon from '@material-ui/icons/Search';
import { Set } from 'immutable';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.drawer,
    maxWidth: '174px',
    display: 'flex',
    alignItems: 'center',
  },
  subheader: {
    fontSize: '16px',
    fontWeight: '600',
    color: theme.palette.common.black,
  },
  textPopover: {
    textTransform: 'capitalize',
    cursor: 'pointer',
  },
  search: {
    borderRadius: '3px',
    border: `1px solid ${theme.palette.grey[100]}`,
    margin: '0 16px',
  },
  iconButton: {
    padding: '1px',
    marginLeft: '5px',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

export const SmsTemplatePopover = ({ disabled }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(Set());
  const anchorRef = useRef(null);
  const searchInputRef = createRef();
  const [search, setSearch] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = () => {
    setOpen(false);
    setChecked(checked.clear());
  };

  const handleChecked = (value) => () => {
    setChecked((oldSet) => (oldSet.includes(value) ? oldSet.delete(value) : oldSet.add(value)));
  };

  const templates = [
    { id: 1, name: 'new customer' },
    { id: 2, name: 'move update' },
    { id: 3, name: 'welcome message' },
    { id: 4, name: 'local move information' },
    { id: 5, name: 'crew data' },
  ];

  const smsTemplates = templates.map((item) => {
    const labelId = `list-label-${item.id}`;
    return (
      <ListItem
        key={item.id}
        role={undefined}
        dense
        selected={checked.includes(item.id)}
        onClick={handleChecked(item.id)}
      >
        <ListItemText id={labelId} primary={`${item.name}`} classes={{ primary: classes.textPopover }} />
      </ListItem>
    );
  });

  const handleChange = (_, value) => setSearch(value);

  const handleClick = () => {
    enqueueSnackbar('Under construction', { variant: 'info', autoHideDuration: 1000 });
  };

  return (
    <>
      <Tooltip title="SMS Quick Template">
        <Box component="span" className={classes.pointer}>
          <IconButton
            disabled={disabled}
            classes={{ root: classes.iconButton }}
            color="primary"
            onClick={handleToggle}
            ref={anchorRef}
          >
            <MoreHorizIcon />
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        disablePortal
        onClose={handleClose}
      >
        <List subheader={<ListSubheader classes={{ root: classes.subheader }}>SMS Templates</ListSubheader>}>
          <Box className={classes.search}>
            <TextInput
              value={search}
              placeholder="Search…"
              hiddenLabel
              ref={searchInputRef}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" classes={{ root: classes.pointer }}>
                    <SearchIcon color="primary" onClick={handleClick} />
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          </Box>
          {smsTemplates}
        </List>
      </Popover>
    </>
  );
};
