import { Record } from 'immutable';
import { uuid } from 'admin/utils';
import ShippingStatus from 'admin/constants/ShippingStatus';
import PackagingType from 'admin/constants/InventoryPackingType';
import SpecialHandling from 'admin/entities/SpecialHandling';
import Products from './Products';

export default class InventoryItem extends Record({
  extraServiceFee: null,
  roomId: -1,
  groupingId: null,
  packingType: null,
  totalSize: 0,
  inventoryItemSettingId: 0,
  assemblyRequired: null,
  disassemblyAssemblyCharge: null,
  woodCratingCharge: null,
  cratingRequired: null,
  wallDismountingRequired: null,
  wallDismountingCharge: null,
  minMoversRequired: null,
  additionalLaborTime: null,
  longDistanceBulkyItemPrice: null,
  packingProducts: null,
  groupName: null,
  quantity: null,
  name: '',
  size: null,
  weight: null,
  width: null,
  height: null,
  length: null,
  specialHandling: null,
  // helper props
  id: 0,
  uuid: null,
  waypointIndex: null,
  shippingStatus: ShippingStatus.UNSHIPPED,
  isDropOff: false,
  description: null
}) {
  static createInventoryItem(inventoryItem = {}) {
    const { packingProducts = null, inventoryItemSettingId = 0 } = inventoryItem;
    const groupingId = parseInt(inventoryItem.groupingId, 10) || parseInt(inventoryItem.groupName, 10) || null;
    return new InventoryItem({
      uuid: uuid(),
      ...inventoryItem,
      inventoryItemSettingId,
      id: inventoryItemSettingId,
      groupingId,
      assemblyRequired: Math.min(inventoryItem.assemblyRequired || 0, inventoryItem.quantity || 0),
      wallDismountingRequired: Math.min(inventoryItem.wallDismountingRequired || 0, inventoryItem.quantity || 0),
      cratingRequired: Math.min(inventoryItem.cratingRequired || 0, inventoryItem.quantity || 0)
    })
      .set('specialHandling', SpecialHandling.createSpecialHandling(inventoryItem.specialHandling))
      .set('packingProducts', packingProducts && Products.createProducts(packingProducts));
  }

  static createDropOffInventoryItem(inventoryItem = {}) {
    return InventoryItem.createInventoryItem({
      ...inventoryItem,
      longDistanceBulkyItemPrice: inventoryItem.longDistanceBulkyItemPrice || null,
      isDropOff: true
    });
  }

  static createNotShipInventoryItem(inventoryItem = {}) {
    return InventoryItem.createInventoryItem({
      ...inventoryItem,
      longDistanceBulkyItemPrice: inventoryItem.longDistanceBulkyItemPrice || null,
      packingType: null
    });
  }

  static createPickUpInventoryItem(item = {}) {
    return InventoryItem.createInventoryItem({
      ...item,
      longDistanceBulkyItemPrice: item.longDistanceBulkyItemPrice || null,
      shippingStatus: ShippingStatus.SHIPPED,
      packingType: item.packingType || PackagingType.PBO
    });
  }

  static hasSpecialHandling(item) {
    return item.assemblyRequired > 0 || item.wallDismountingRequired > 0 || item.cratingRequired > 0;
  }

  static getNumberOfItemsWithSpecialHandling(item) {
    return Math.max(item.assemblyRequired || 0, item.wallDismountingRequired || 0, item.cratingRequired || 0);
  }

  setWaypointIndex(index) {
    return this.set('waypointIndex', index);
  }

  equals(other) {
    if (!other) return false;
    if (this === other) return true;
    return (
      this.id === other.id &&
      this.roomId === other.roomId &&
      this.groupId === other.groupId &&
      this.waypointIndex === other.waypointIndex &&
      this.packingType === other.packingType
    );
  }
}
