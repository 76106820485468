import React, { useEffect, useState } from 'react';

import { ActivitySourceType } from '@elromcoinc/react-shared';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

import { getEdit, setEdit } from 'admin/autodux/TaskAutodux';
import Tasks from 'admin/components/OrderWindow/modals/Tasks';
import { Task } from 'common-types/Task';

interface TaskSource {
  orderId: number;
  sourceId: number;
  orderNumber: string;
  contactInfo: {
    firstName: string;
    lastName: string;
  };
}

const TaskEdit = () => {
  const dispatch = useDispatch();
  const task = useSelector(getEdit) as Task | null;
  const [source, setSource] = useState<TaskSource | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (task && [ActivitySourceType.ORDER, ActivitySourceType.CUSTOMER_ACCOUNT].includes(task.activitySource)) {
      const [firstName = '', lastName = ''] = (task.customerName || '').split(' ');

      setSource({
        orderId: task.sourceId!,
        sourceId: task.sourceId!,
        orderNumber: task.orderNumber,
        contactInfo: { firstName, lastName },
      });
    } else {
      setSource(null);
    }
  }, [task]);

  const onCancel = () => {
    dispatch(setEdit(null));
  };
  const onSave = () => {
    enqueueSnackbar('Task was update successfully', {
      variant: 'success',
      autoHideDuration: 1000,
    });
    onCancel();
  };

  return (
    <>
      {task && (
        <Tasks
          onSave={onSave}
          onCancel={onCancel}
          task={task}
          open
          source={source}
          activitySource={task.activitySource}
        />
      )}
    </>
  );
};

export default TaskEdit;
