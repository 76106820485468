import React, { FC } from 'react';

import { IconButton } from '@elromcoinc/react-shared';
import RefreshIcon from '@material-ui/icons/Autorenew';

interface ModalReloadButtonProps {
  handleReload: () => void;
  isDisabled: boolean;
}

const ModalReloadButton: FC<ModalReloadButtonProps> = ({ handleReload, isDisabled }) => {
  return (
    <IconButton color="primary" onClick={handleReload} disabled={isDisabled}>
      <RefreshIcon />
    </IconButton>
  );
};

export { ModalReloadButton };
