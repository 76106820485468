import { RangeValue } from '@elromcoinc/react-shared';

import { CompanyIncomeReport } from 'admin/components/Reports/IncomeTab';
import { IncomeTotals } from 'admin/components/Reports/IncomeTab/IncomeTotals';

const getTotalsWithPriceRange = (totals: IncomeTotals) => {
  return {
    ...totals,
    lineHaulCharge: RangeValue.createRangeValue(totals.totalLineHaul),
    laborCharge: RangeValue.createRangeValue(totals.totalLaborChargeBeforeTax),
    fuelCharge: RangeValue.createRangeValue(totals.totalFuelCharges),
    totalPrice: RangeValue.createRangeValue(totals.totalPrice),
    grandTotal: RangeValue.createRangeValue(totals.estimatedTotalPrice),
  };
};

const getTotalCompanyIncomeValues = (notCompletedTotals: IncomeTotals, completedTotals: IncomeTotals) => {
  const notCompleted = getTotalsWithPriceRange(notCompletedTotals);
  const completed = getTotalsWithPriceRange(completedTotals);

  return {
    moveDate: 'Totals',
    lineHaulCharge: notCompleted.lineHaulCharge.add(completed.lineHaulCharge),
    laborCharge: notCompleted.laborCharge.add(completed.laborCharge),
    flatRateCharge: (notCompletedTotals.totalFlatRateCharge || 0) + (completedTotals.totalFlatRateCharge || 0),
    travelCharge: (notCompletedTotals.totalTravelCharge || 0) + (completedTotals.totalTravelCharge || 0),
    fuelCharge: notCompleted.fuelCharge.add(completed.fuelCharge),
    valuation: (notCompletedTotals.totalValuation || 0) + (completedTotals.totalValuation || 0),
    packingCharge: (notCompletedTotals.totalPackingCharge || 0) + (completedTotals.totalPackingCharge || 0),
    additionalServicesCharge:
      (notCompletedTotals.totalAdditionalServicesCharge || 0) + (completedTotals.totalAdditionalServicesCharge || 0),
    additionalFees: (notCompletedTotals.totalFees || 0) + (completedTotals.totalFees || 0),
    taxes: (notCompletedTotals.totalOrderTaxes || 0) + (completedTotals.totalOrderTaxes || 0),
    grandTotal: notCompleted.grandTotal.add(completed.grandTotal),
    paymentReceived: (notCompletedTotals.totalPaymentReceived || 0) + (completedTotals.totalPaymentReceived || 0),
    balance: notCompleted.totalPrice
      .subtractNumber(notCompletedTotals.totalPaymentReceived)
      .add(completed.totalPrice.subtractNumber(completedTotals.totalPaymentReceived)),
    closingGrandTotal: RangeValue.createRangeValue({
      minValue: notCompleted.closingTotal + completed.closingTotal,
      maxValue: notCompleted.closingTotal + completed.closingTotal,
    }),
    numericGrandTotal: (notCompletedTotals.numericGrandTotal || 0) + (completedTotals.numericGrandTotal || 0),
    completed: false,
  } as CompanyIncomeReport;
};

export { getTotalCompanyIncomeValues };
