import React from 'react';

import { AccountsPermissions, AdminPermissions, Authorized, ReportsPermissions } from '@elromcoinc/react-shared';
import { Box, Divider, makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DashboardIcon from '@material-ui/icons/Assessment';
import OrderIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Build';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountsIcon from '@material-ui/icons/FolderShared';
import SupportIcon from '@material-ui/icons/LiveHelp';
import DispatchIcon from '@material-ui/icons/LocalShipping';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PropTypes from 'prop-types';

import { CommunicationIcon } from 'admin/components/Communication/CommunicationIcon';
import { TasksIcon } from 'admin/components/TasksPage';

import MenuItem from './MenuItem';

const useStyles = makeStyles(() => ({
  menu: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    height: '20px',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .MuiDivider-root': {
      height: '2px',
    },
  },
}));

const NavigationMenu = ({ closeNavigation }) => {
  const classes = useStyles();
  return (
    <List className={classes.menu}>
      <MenuItem
        to="/dashboard"
        onClick={closeNavigation}
        Icon={DashboardIcon}
        dataTestId="dashboard"
        tooltip="Dashboard"
      />
      <MenuItem to="/orders" onClick={closeNavigation} Icon={OrderIcon} dataTestId="orders" tooltip="Orders" />
      <Authorized permission={AccountsPermissions.PERM_CAN_SEE_ACCOUNTS_TAB}>
        <MenuItem
          to="/accounts"
          onClick={closeNavigation}
          Icon={AccountsIcon}
          dataTestId="accounts"
          tooltip="Accounts"
        />
      </Authorized>
      <Authorized permission={AdminPermissions.PERM_CAN_SEE_CALENDAR_TAB}>
        <MenuItem
          to="/calendar"
          onClick={closeNavigation}
          Icon={CalendarIcon}
          dataTestId="calendar"
          tooltip="Calendar"
        />
      </Authorized>
      <Authorized permission={AdminPermissions.PERM_CAN_SEE_COMMUNICATION_TAB}>
        <MenuItem
          to="/communications"
          onClick={closeNavigation}
          Icon={CommunicationIcon}
          dataTestId="communications"
          tooltip="Communications"
        />
      </Authorized>
      <MenuItem to="/tasks" onClick={closeNavigation} Icon={TasksIcon} dataTestId="tasks" tooltip="Tasks" />
      <Authorized permission={AdminPermissions.PERM_CAN_SEE_DISPATCH_TAB}>
        <MenuItem
          to="/dispatch"
          onClick={closeNavigation}
          Icon={DispatchIcon}
          dataTestId="dispatch"
          tooltip="Dispatch"
        />
      </Authorized>
      <Authorized permission={AdminPermissions.PERM_VIEW_PAYROLL_TAB}>
        <MenuItem
          to="/accounting"
          onClick={closeNavigation}
          Icon={AccountBalanceWalletIcon}
          dataTestId="accounting"
          tooltip="Accounting"
        />
      </Authorized>
      <Authorized permission={AdminPermissions.PERM_CAN_SEE_INVOICES_TAB}>
        <MenuItem
          to="/invoices"
          onClick={closeNavigation}
          Icon={DescriptionIcon}
          dataTestId="invoices"
          tooltip="Invoices"
        />
      </Authorized>
      <Authorized permission={ReportsPermissions.PERM_CAN_SEE_REPORTS_TAB_AS_ADMIN}>
        <MenuItem to="/reports" onClick={closeNavigation} Icon={ShowChartIcon} dataTestId="reports" tooltip="Reports" />
      </Authorized>
      <MenuItem to="/support" onClick={closeNavigation} Icon={SupportIcon} dataTestId="support" tooltip="Support" />
      <Box className={classes.divider}>
        <Divider />
      </Box>
      <Authorized permission={AdminPermissions.PERM_CAN_SEE_SETTINGS_TAB}>
        <MenuItem
          to="/settings"
          onClick={closeNavigation}
          Icon={SettingsIcon}
          dataTestId="settings"
          tooltip="Settings"
          className="main-setting-button"
        />
      </Authorized>
    </List>
  );
};

NavigationMenu.propTypes = {
  closeNavigation: PropTypes.func.isRequired,
};

export default NavigationMenu;
