import React, { useEffect, useState } from 'react';

import { SettingName as SharedSettingName } from '@elromcoinc/moveboard-setting-react';
import {
  BACKEND_DATE_FORMAT,
  Button,
  CustomerInfo,
  EstimateType,
  Modal,
  Radio,
  RadioGroup,
  Select,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, withMobileDialog } from '@material-ui/core';
import { add, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import OrderApi from 'admin/api/OrderAPI';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import useServiceEstimateTypeSetting from 'admin/components/Settings/hooks/useServiceEstimateTypeSetting';
import GeneralServiceTypes from 'admin/constants/GeneralServiceTypes';
import LeadDeliverySource from 'admin/constants/LeadDeliverySource';
import { MOVE_BOARD_LEAD_SOURCE } from 'admin/constants/MOVE_BOARD_LEAD_SOURCE';
import MoveType from 'admin/constants/MoveType';
import SettingName from 'admin/constants/SettingName';
import useSettings from 'admin/hooks/useSettings';
import { getMoveSizesWithAllTags } from 'admin/selectors';
import Form from 'common/components/Form';

const {
  GENERAL_SERVICES,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  UNIT_SIZE_SPEC,
} = SettingName;
const { SERVICE_ESTIMATE_TYPE_SETTING } = SharedSettingName;
const settingQueries = [
  GENERAL_SERVICES,
  ACCEPT_RESIDENTIAL_ORDERS,
  ACCEPT_COMMERCIAL_ORDERS,
  ACCEPT_MILITARY_ORDERS,
  UNIT_SIZE_SPEC,
  SERVICE_ESTIMATE_TYPE_SETTING,
];
const companyId = 1;
const MOVE_TYPE = 'moveType';
const SERVICE_ID = 'serviceId';
const SERVICE_TYPE_LABEL = 'Service type';

const schema = object().shape({
  [MOVE_TYPE]: string().label('Move type').nullable().required(),
  [SERVICE_ID]: string().label(SERVICE_TYPE_LABEL).nullable().required(),
});

const CreateDraftOrder = ({ onClose, fullScreen, ...rest }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(schema),
  });
  const settings = useSettings(...settingQueries) || {};
  const [inFlight, setInFlight] = useState(false);
  const [availableMoveTypes, setAvailableMoveTypes] = useState([]);
  const [serviceTypeOptions, setServiceTypeOptions] = useState([]);
  const moveSizes = getMoveSizesWithAllTags(settings);
  const moveType = watch(MOVE_TYPE);
  const serviceId = watch(SERVICE_ID);
  const serviceType = (settings[GENERAL_SERVICES] || []).find((it) => it.id === serviceId)?.serviceType;
  const [estimateType] = useServiceEstimateTypeSetting(settings, () => {}, moveType, serviceType);

  const handleClickCreate = (data) => {
    setInFlight(true);

    const moveType = data[MOVE_TYPE];
    const currentMoveSize = Object.values(moveSizes[moveType])[0];
    const defaultRooms = currentMoveSize.tags.filter((it) => it.size && it.isDefault && it.moveType === moveType);
    const roomsSuffix = defaultRooms.map((it) => it.name).join(', ');

    const draftCustomerInfo = new CustomerInfo({
      firstName: 'Draft',
      lastName: 'Draft',
      email: `${new Date().getTime()}@draftorder.com`,
      primaryPhone: {
        number: '2222222222',
      },
    });

    OrderApi.save({
      ...data,
      startDate: format(add(new Date(), { days: 10 }), BACKEND_DATE_FORMAT),
      moveDate: format(add(new Date(), { days: 10 }), BACKEND_DATE_FORMAT),
      companyId,
      estimateType: estimateType || EstimateType.NON_BINDING,
      moveSize: Math.round(defaultRooms.reduce((r, item) => r + (item ? item.size : 0), currentMoveSize.size || 0)),
      customerInfo: {
        ...draftCustomerInfo.toJS(),
        otherPhone: null,
        address: null,
      },
      sizeDescription: `${currentMoveSize.name}${roomsSuffix ? ` (${roomsSuffix})` : ''}`,
      leadSource: { referredVia: { name: MOVE_BOARD_LEAD_SOURCE }, leadDeliverySource: LeadDeliverySource.USER_DIRECT },
      draft: false,
      requestingAccountId: null,
    })
      .then((response) => {
        dispatch(openOrder(response.orderId));
        onClose();
      })
      .catch((error) => {
        if (error.errors && error.errors.length) {
          enqueueSnackbar(error.errors.map(({ message }) => message).join('\n'), { variant: 'error' });
        }
      })
      .then(() => {
        setInFlight(false);
      });
  };

  useEffect(() => {
    if (availableMoveTypes.length) {
      setValue(MOVE_TYPE, availableMoveTypes[0][0]);
    }
  }, [availableMoveTypes]);

  useEffect(() => {
    setServiceTypeOptions(
      (settings[GENERAL_SERVICES] || [])
        ?.filter((service) => service.serviceType !== GeneralServiceTypes.JUNK_REMOVAL && service.active)
        ?.map((it) => [it.id, it.name]),
    );
  }, [settings[GENERAL_SERVICES]]);

  useEffect(() => {
    setAvailableMoveTypes(
      [
        settings[ACCEPT_RESIDENTIAL_ORDERS] && MoveType.RESIDENTIAL,
        settings[ACCEPT_COMMERCIAL_ORDERS] && MoveType.COMMERCIAL,
        settings[ACCEPT_MILITARY_ORDERS] && MoveType.MILITARY,
      ]
        .filter(Boolean)
        .map((it) => [it, it[0] + it.slice(1).toLowerCase()]),
    );
  }, [settings[ACCEPT_RESIDENTIAL_ORDERS], settings[ACCEPT_COMMERCIAL_ORDERS], settings[ACCEPT_MILITARY_ORDERS]]);

  return (
    <Modal fullScreen={fullScreen} title="Create Order" onClose={onClose} maxWidth="xs" open {...rest} color="primary">
      <Form onSubmit={handleSubmit(handleClickCreate)}>
        <Box mt={1}>
          <RadioGroup aria-label="move-type" name={MOVE_TYPE} control={control} direction="row">
            {availableMoveTypes.map(([key, label], index) => (
              <Radio
                data-testid={label}
                key={label}
                value={key}
                label={label}
                autoFocus={index === 0}
                color="primary"
              />
            ))}
          </RadioGroup>
        </Box>
        <Box mt={1}>
          <Select
            fullWidth
            label={SERVICE_TYPE_LABEL}
            name={SERVICE_ID}
            control={control}
            options={serviceTypeOptions}
            data-testid="serviceType"
          />
        </Box>
        <Box mt={23} display="flex" justifyContent="center">
          <Box width={200}>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="create"
              rounded
              disableBoxShadow
              loading={inFlight}
              disabled={inFlight}
            >
              create
            </Button>
          </Box>
        </Box>
      </Form>
    </Modal>
  );
};

CreateDraftOrder.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(CreateDraftOrder);
