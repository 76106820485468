import React, { useEffect, useState } from 'react';

import { BACKEND_DATE_FORMAT, HeaderBigText, statusIds } from '@elromcoinc/react-shared';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { format, sub } from 'date-fns';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import OrderAPI from 'admin/api/OrderAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { MovesTable, SelectStatuses } from 'admin/components/AccountWindow/components';
import { allNewStatus } from 'admin/constants/OrderStatus';

const useStyles = makeStyles((theme) => ({
  selectBox: {
    minWidth: '12rem',
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
      minWidth: '9rem',
      maxWidth: '18rem',
      marginLeft: '2rem',
    },
  },
}));

export const PastMovesHistory = ({ accountId, orderWindowId, currentPage, setCurrentPage }) => {
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = useState([]);
  const [isFlight, setIsFlight] = useState(false);
  const startDate = format(sub(new Date(), { years: 100 }), BACKEND_DATE_FORMAT);
  const endDate = format(sub(new Date(), { days: 1 }), BACKEND_DATE_FORMAT);
  const [sortBy, setSortBy] = useState('moveStartDate');
  const [sortOrder, setSortOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderSummaries, setOrderSummaries] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isSessionExpired = useSelector(getIsSessionExpired);

  const onColumnSortChange = (changedColumn, direction) => {
    setSortBy(changedColumn);
    setSortOrder(direction);
  };

  const getPastMoves = () => {
    const queryFilters = {
      'account.id': accountId,
      moveStartDate: [startDate, endDate],
      status: currentStatus.includes(statusIds.NEW) ? [...currentStatus, ...allNewStatus] : currentStatus,
    };

    if (accountId) {
      setIsFlight(true);
      OrderAPI.getOrderSummariesWithFilters({
        pageSize: 1000,
        sortBy,
        sortOrder,
        ...queryFilters,
      }).promise.then(({ pageElements, totalElements }) => {
        setOrderSummaries([...pageElements]);
        setOrdersCount(totalElements);
        setIsFlight(false);
      });
    }
  };

  useEffect(() => {
    if (!isSessionExpired) {
      getPastMoves();
      const interval = setInterval(getPastMoves, 30e3);
      return () => clearInterval(interval);
    }
  }, [sortBy, sortOrder, startDate, endDate, currentStatus, isSessionExpired]);

  useEffect(() => {
    setPage(1);
  }, [currentStatus]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={isMobile && 'space-between'}
        mt={3}
        mb={2}
      >
        <Box>
          <HeaderBigText>
            <b>Past Moves</b>
          </HeaderBigText>
        </Box>
        <Box className={classes.selectBox}>
          <SelectStatuses currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} />
        </Box>
      </Box>
      <MovesTable
        orders={orderSummaries}
        isFlight={isFlight}
        onColumnSortChange={onColumnSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        ordersCount={ordersCount}
        rowsPerPage={rowsPerPage}
        page={page}
        orderWindowId={orderWindowId}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

PastMovesHistory.propTypes = {
  accountId: pt.number,
  setCurrentPage: pt.func.isRequired,
  currentPage: pt.string.isRequired,
  orderWindowId: pt.number.isRequired,
};

PastMovesHistory.defaultProps = {
  accountId: null,
};
