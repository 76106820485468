import React from 'react';

import { BodyText, Link } from '@elromcoinc/react-shared';

import { AssignmentStats } from 'admin/components/Reports/GeneralStatisticsTab';
import {
  DataTableColumnAttributes,
  DataTableColumnNames,
  SelectedOrderFiltersType,
  renderBoundaryValues,
} from 'admin/components/Reports/config';

const totalsEmployeeId = -1;
const isTotalEmployeeId = (employeeId: number) => employeeId === totalsEmployeeId;

const makeGeneralStatisticsAssignmentColumns = (
  rowsData: AssignmentStats[],
  { setSelectedOrderFilters }: { setSelectedOrderFilters: SelectedOrderFiltersType },
) => [
  {
    name: DataTableColumnAttributes.NAME,
    label: 'ASSIGNEE',
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { employeeName } = rowsData[rowIndex];

        return <BodyText>{employeeName}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS_OVERALL,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS_OVERALL],
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersOverall } = rowsData[rowIndex];

        return <BodyText>{totalOrdersOverall}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.ORDERS,
    label: DataTableColumnNames[DataTableColumnAttributes.ORDERS],
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalOrdersInPeriod, employeeId } = rowsData[rowIndex];
        const isTotal = isTotalEmployeeId(employeeId);

        const handleOpenModal = (event: React.SyntheticEvent) => {
          event.stopPropagation();
          if (totalOrdersInPeriod > 0) {
            setSelectedOrderFilters({ 'assignedTo.id': employeeId });
          }
        };

        if (isTotal) {
          return <BodyText className="total-column">{totalOrdersInPeriod}</BodyText>;
        }

        return totalOrdersInPeriod === 0 ? (
          <BodyText>{totalOrdersInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalOrdersInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.TOTAL_ESTIMATE_MIN,
    label: 'SALES ESTIMATE',
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalEstimateMin, totalEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalEstimateMin, totalEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_OVERALL,
    label: DataTableColumnNames[DataTableColumnAttributes.BOOKED_OVERALL],
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedOverall } = rowsData[rowIndex];

        return <BodyText>{totalBookedOverall}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_JOBS,
    label: <>BOOKED&nbsp;</>,
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedInPeriod, employeeId } = rowsData[rowIndex];
        const isTotal = isTotalEmployeeId(employeeId);

        const handleOpenModal = (event: React.SyntheticEvent): void => {
          event.stopPropagation();
          if (totalBookedInPeriod > 0) {
            setSelectedOrderFilters({ 'assignedTo.id': employeeId, status: ['BOOKED'] });
          }
        };

        return isTotal || totalBookedInPeriod === 0 ? (
          <BodyText>{totalBookedInPeriod}</BodyText>
        ) : (
          <Link onClick={handleOpenModal} underline="none">
            <BodyText>
              <b>{totalBookedInPeriod}</b>
            </BodyText>
          </Link>
        );
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.BOOKED_MIN,
    label: 'SALES BOOKED',
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { totalBookedEstimateMin, totalBookedEstimateMax } = rowsData[rowIndex];

        return <BodyText>{renderBoundaryValues(totalBookedEstimateMin, totalBookedEstimateMax)}</BodyText>;
      },
      showOnMobileAsExpanded: false,
    },
  },
  {
    name: DataTableColumnAttributes.CONVERSION,
    label: 'CONVERSION',
    options: {
      customBodyRender: (_: AssignmentStats[], { rowIndex }: { rowIndex: number }) => {
        const { conversionRateForPeriod } = rowsData[rowIndex];
        const { asPercentage } = conversionRateForPeriod;

        return <BodyText>{asPercentage}</BodyText>;
      },
      showOnMobileAsExpanded: true,
    },
  },
];

export { makeGeneralStatisticsAssignmentColumns };
