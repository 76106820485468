import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Navigation from 'admin/components/NavigationDrawer/NavigationMenu';

const drawerWidth = 62;

const useStyles = makeStyles(({ breakpoints, mixins, transitions }) => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: transitions.create('width', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    width: 0,
    [breakpoints.up('sm')]: {
      width: drawerWidth,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 8px',
    ...mixins.toolbar,
  },
}));

const NavigationDrawer = ({ open, closeNavigation }) => {
  const classes = useStyles();

  return (
    <>
      <Hidden smUp>
        <Drawer variant="temporary" classes={{ paper: classes.drawerPaper }} open={open} onClose={closeNavigation}>
          <Navigation closeNavigation={closeNavigation} />
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <Navigation closeNavigation={closeNavigation} />
        </Drawer>
      </Hidden>
    </>
  );
};

NavigationDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  closeNavigation: PropTypes.func.isRequired,
};

export default NavigationDrawer;
