import React, { useEffect } from 'react';

import { Badge, IconButton } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLogout, getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import {
  fetchIsMuted,
  fetchNewNotificationCount,
  getNotificationCount,
  getNotificationIsFetchingIsMuted,
  getNotificationIsFetchingNewNotificationCount,
  getNotificationIsMuted,
  getNotificationIsOpenDrawer,
  setNotificationIsOpenDrawer,
  toggleIsMute,
} from 'admin/autodux/NotificationAutodux';
import NotificationDrawer from 'admin/components/Notifications/NotificationDrawer';

const useStyle = makeStyles({
  iconButton: {
    fontSize: '1.75rem',
  },
});

const NotificationIcon = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const isMuted = useSelector(getNotificationIsMuted);
  const isOpenDrawer = useSelector(getNotificationIsOpenDrawer);
  const isFetchingActive = useSelector(getNotificationIsFetchingIsMuted);
  const [loadingCountTimeout, setLoadingTimeout] = React.useState(0);
  const currentUser = useSelector((s) => s.auth.user);
  const unreadNotificationsCount = useSelector(getNotificationCount);
  const isFetchingNewNotificationCount = useSelector(getNotificationIsFetchingNewNotificationCount);
  const isSessionExpired = useSelector(getIsSessionExpired);
  const isLogout = useSelector(getIsLogout);

  const toggleActiveNoti = () => {
    dispatch(toggleIsMute());
  };

  const toggleNotification = (shouldOpen) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    dispatch(setNotificationIsOpenDrawer(shouldOpen));
  };

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      setTimeout(() => {
        dispatch(fetchIsMuted());
      }, 1000);
    }
  }, [isSessionExpired, isLogout]);

  useEffect(() => {
    if (isMuted) {
      setLoadingTimeout(0);
    }
  }, [isMuted]);

  useEffect(() => {
    if (!isSessionExpired && !isLogout) {
      let timer = null;

      if (currentUser?.id && !isFetchingNewNotificationCount && !isMuted) {
        timer = setTimeout(() => {
          dispatch(fetchNewNotificationCount());
          setLoadingTimeout(30);
        }, loadingCountTimeout * 1000);
      }

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentUser?.id, isFetchingNewNotificationCount, isMuted, isSessionExpired]);

  return (
    <>
      <IconButton
        disabled={!currentUser?.id}
        color="inherit"
        className={classes.iconButton}
        variant="text"
        data-testid="notifications"
        onClick={toggleNotification(true)}
        size="small"
      >
        <Badge invisible={isMuted || !unreadNotificationsCount} badgeContent={unreadNotificationsCount}>
          {!isMuted ? <NotificationsActiveIcon fontSize="inherit" /> : <NotificationsOffIcon fontSize="inherit" />}
        </Badge>
      </IconButton>
      <NotificationDrawer
        open={isOpenDrawer}
        toggleNotification={toggleNotification}
        toggleActiveNoti={toggleActiveNoti}
        activeNoti={!isMuted}
        isFetchingActive={isFetchingActive}
      />
    </>
  );
};

export default NotificationIcon;
