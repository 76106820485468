import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

function TabContainer({ children, ...rest }) {
  return (
    <Typography component="div" {...rest}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default TabContainer;
