import React, { useState } from 'react';

import { BodyText, Fee, Link, Order } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { List } from 'immutable';

import { useOrderChangeSet, useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';
import { useGetServicePropertyName } from 'admin/components/OrderWindow/hooks';
import AdditionalFeesModal from 'admin/components/OrderWindow/modals/AdditionalFees';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  }),
);

enum Modals {
  ADDITIONAL_FEES = 'ADDITIONAL_FEES',
}

export const ClosingAdditionalFees = () => {
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();
  const { onChange } = useOrderChangeSet();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const getServicePropertyName = useGetServicePropertyName();
  const name = getServicePropertyName('fees');
  const fees = order.getIn(name.split('.')) as List<Fee>;

  const handleAdditionalFeesChange = (value: List<Fee>) => {
    onChange({ name, value });
    setOpenModal(null);
  };

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);

  return (
    <Grid item xs={12}>
      <Box className={classes.costBreakdownItem}>
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.ADDITIONAL_FEES)}>
          Other Fees
        </Link>
        <BodyText>
          {order.closingOrderDetail?.getFeesTotal(serviceIndex, isSelectedAllServices).asHumanReadableString()}
        </BodyText>
      </Box>
      {Modals.ADDITIONAL_FEES === openModal && (
        <AdditionalFeesModal fees={fees} onSave={handleAdditionalFeesChange} onCancel={toggleOpenModal(null)} open />
      )}
    </Grid>
  );
};
