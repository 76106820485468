import axios from 'axios';

import {
  AuthorizedRepresentative,
  BrandRegistration,
  BundleInfo,
  CompanyInfo,
  CustomerProfileAddress,
  CustomerProfilesEvaluations,
  EndUserInfo,
  TwilioBrand,
  TwilioCampaignMetadata,
  TwilioMessageService,
  TwilioMessagingServiceDto,
  TwilioSecondaryCustomerProfile,
  TwilioTrustProduct,
} from 'common-types/twilio';

const commonApi = '/api/communications/twilio/isvRegistration/secondaryCustomerProfile';
const trustProduct = '/api/communications/twilio/isvRegistration';
const brand = '/api/communications/twilio/isvRegistration/brand';
const messaging = '/api/communications/twilio/isvRegistration/messagingService';
const campaign = '/api/communications/twilio/isvRegistration/campaign';

class TwilioApi {
  getBundle() {
    return axios.get<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(commonApi);
  }

  /**
   * Creates an empty secondary customer profile bundle in Twilio app.
   * <p>
   * This is the first step.
   * Next, we will fill the bundle with various information about the company.
   */
  createNewBundle(data: BundleInfo) {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(`${commonApi}/new`, data);
  }

  /**
   * Creates end-user object of type: customer_profile_business_information.
   *
   * @param endUserInfo necessary information for creating "end-user" object in Twilio app
   */
  addBusinessInfo(endUserInfo: EndUserInfo) {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/businessInfo`,
      endUserInfo,
    );
  }

  /**
   * Creates required business representatives based on a list of authorized representatives.
   * Only the first two authorized representatives in the list will be processed.
   *
   * @param authorizedRepresentative an authorized representatives to be processed
   */
  addRequiredAuthorizedRepresentative(authorizedRepresentative: AuthorizedRepresentative) {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/authorizedRepresentative/required`,
      authorizedRepresentative,
    );
  }

  /**
   * Creates optional business representatives based on a list of authorized representatives.
   * Only the first two authorized representatives in the list will be processed.
   *
   * @param authorizedRepresentative an authorized representatives to be processed
   */
  addOptionalAuthorizedRepresentatives(authorizedRepresentative: AuthorizedRepresentative) {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/authorizedRepresentative/optional`,
      authorizedRepresentative,
    );
  }

  /**
   * Creates an object representing customer’s address information in Twilio app.
   *
   * @param customerProfileAddress company's address
   */
  addDocumentCustomerProfileAddress(customerProfileAddress: CustomerProfileAddress) {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/documentCustomerProfileAddress`,
      customerProfileAddress,
    );
  }

  /**
   * Assigns end-users, supporting document, and primary customer profile to the empty secondary customer profile.
   */
  assignInfoToCustomerBundle() {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/assignInfoToCustomerProfileBundle`,
      {},
    );
  }

  /**
   * Check that company's provided all the required Secondary Customer Profile information.
   */
  evaluationSecondaryCustomerProfile() {
    return axios.post<CustomerProfilesEvaluations, CustomerProfilesEvaluations>(
      `${commonApi}/evaluationSecondaryCustomerProfile`,
      {},
    );
  }

  submitCustomerProfileBundle() {
    return axios.post<TwilioSecondaryCustomerProfile, TwilioSecondaryCustomerProfile>(
      `${commonApi}/submitSecondaryCustomerProfile`,
      {},
    );
  }

  // I need call only this api instead of assignInfoToCustomerBundle, evaluationSecondaryCustomerProfile, submitCustomerProfileBundle
  assignAndSubmit() {
    return axios.get(`${commonApi}/assignAndSubmit`);
  }

  deleteBundle(sid: string) {
    return axios.delete(`${commonApi}/bundle/${sid}`);
  }

  createTrustBundle(data: BundleInfo) {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/trustProduct`, data);
  }

  createMessagingProfile(data: CompanyInfo) {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/messagingProfile`, data);
  }

  assignTrustProductEntity() {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/assignTrustProductEntity`, {});
  }

  assignSecondaryCustomerProfile() {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/assignSecondaryCustomerProfile`, {});
  }

  evaluateTrustProduct() {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/evaluateTrustProduct`, {});
  }

  submitTrustProduct() {
    return axios.post<TwilioTrustProduct, TwilioTrustProduct>(`${trustProduct}/submitTrustProduct`, {});
  }

  getBrand() {
    return axios.get<BrandRegistration, BrandRegistration>(brand);
  }

  createBrand() {
    return axios.post<TwilioBrand, TwilioBrand>(brand, {});
  }

  createMessagingService(messagingServiceDto: TwilioMessagingServiceDto) {
    return axios.post<TwilioMessagingServiceDto, TwilioMessagingServiceDto>(messaging, messagingServiceDto);
  }

  fetchMessagingRegistrationStatus() {
    return axios.get<TwilioMessageService, TwilioMessageService>(messaging);
  }

  createCampaign() {
    return axios.post<TwilioCampaignMetadata, TwilioCampaignMetadata>(campaign, {});
  }

  fetchCampaign() {
    return axios.get<TwilioCampaignMetadata, TwilioCampaignMetadata>(campaign);
  }
}

export default new TwilioApi();
