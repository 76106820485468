import { MoveType, Order, ParkingType, ParkingTypeOptions, Waypoint } from '@elromcoinc/react-shared';
import { Map } from 'immutable';

import { getElevationReduction, getParkingDistanceOptions } from 'admin/selectors';

import { EventProcessor } from './EventProcessor';
import createUpdatedValueDto, { UpdatedValueDto } from './UpdatedValueDto';

const getWaypointIdToWpMap = (order: Order): Map<string, Waypoint> =>
  order
    .getAddressWaypointsBasedOnService()
    .reduce((map, wp) => (wp.id !== null ? map.set(wp.id, wp) : map), Map<string, Waypoint>());

const auditWaypoint = (
  oldWaypoint: Waypoint,
  newWaypoint: Waypoint,
  moveType: MoveType,
  settings: Map<string, any>,
): UpdatedValueDto[] => {
  const updates: UpdatedValueDto[] = [];
  const oldAddress = oldWaypoint?.address?.fullAddressLine() ?? '';
  const newAddress = newWaypoint?.address?.fullAddressLine() ?? '';

  if (oldAddress !== newAddress) {
    updates.push(createUpdatedValueDto(oldAddress || '', newAddress || '', 'Address'));
  }

  if (oldWaypoint && oldWaypoint?.elevationId !== newWaypoint?.elevationId) {
    const elevationReduction = Map(getElevationReduction(settings)[moveType]);
    updates.push(
      createUpdatedValueDto(
        (elevationReduction.get(+(oldWaypoint.elevationId || 0)) as string) || '',
        (elevationReduction.get(+(newWaypoint.elevationId || 0)) as string) || '',
        'Entrance Type',
      ),
    );
  }

  if (
    oldWaypoint &&
    oldWaypoint?.parkingToEntranceSpeedReductionId !== newWaypoint?.parkingToEntranceSpeedReductionId
  ) {
    const parkingDistanceOptions = Map(getParkingDistanceOptions(settings)[moveType]);
    updates.push(
      createUpdatedValueDto(
        (parkingDistanceOptions.get(+(oldWaypoint.parkingToEntranceSpeedReductionId || 0)) as string) || '',
        (parkingDistanceOptions.get(+(newWaypoint.parkingToEntranceSpeedReductionId || 0)) as string) || '',
        'Parking Distance',
      ),
    );
  }

  if (oldWaypoint && oldWaypoint?.parkingType !== newWaypoint?.parkingType) {
    updates.push(
      createUpdatedValueDto(
        ParkingTypeOptions.find(([key]) => (key as ParkingType) === oldWaypoint.parkingType!)?.[1] || '',
        ParkingTypeOptions.find(([key]) => (key as ParkingType) === newWaypoint.parkingType!)?.[1] || '',
        'Parking Type',
      ),
    );
  }

  return updates.filter((it) => it.oldValue !== it.newValue);
};

const updatedAddress =
  (settings: Map<string, any>): EventProcessor =>
  (fieldPath, oldOrder, newOrder) => {
    if (fieldPath.indexOf('waypoints') > -1) {
      const oldAddressesMap = getWaypointIdToWpMap(oldOrder);
      return getWaypointIdToWpMap(newOrder)
        .entrySeq()
        .reduce<UpdatedValueDto[]>(
          (acc, [id, newWp]) => [
            ...acc,
            ...auditWaypoint(oldAddressesMap.get(id)!, newWp, oldOrder.moveType, settings),
          ],
          [],
        );
    }
    return null;
  };

export default updatedAddress;
