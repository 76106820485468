import {
  CustomerInfo,
  EstimateType,
  LaborType,
  LeadSource,
  MoveType,
  WaypointLocationType,
  isLoadingService,
  isMovingAndStorageService,
  isOvernightService,
  isUnloadingOrPackingService,
  toBackEndDate,
} from '@elromcoinc/react-shared';
import { format } from 'date-fns';
import { Map, Record } from 'immutable';

import MoveSize from 'admin/entities/MoveSize';

const filterWaypoints =
  (serviceTypeName) =>
  ({ laborType }) =>
    !(laborType === LaborType.LOADING && isUnloadingOrPackingService(serviceTypeName)) &&
    !(laborType === LaborType.UNLOADING && isLoadingService(serviceTypeName));

export class NewOrder extends Record({
  companyId: 1,
  customerInfo: new CustomerInfo({}),
  moveType: MoveType.RESIDENTIAL,
  moveDate: null,
  zipFrom: null,
  cityFrom: null,
  stateFrom: null,
  countryFrom: null,
  zipTo: null,
  cityTo: null,
  stateTo: null,
  countryTo: null,
  serviceId: null,
  serviceName: null,
  leadSource: new LeadSource(),
  estimateType: EstimateType.NON_BINDING,
  desiredDeliveryDate: null,
  moveSize: MoveSize.createMoveSize(),
  moveSizeExtra: new Map(),
  fromElevationId: null,
  toElevationId: null,
  parkingDistanceFrom: null,
  parkingDistanceTo: null,
  enableSmsCustom: true,
  requestingAccountId: null,
}) {
  constructor(data) {
    super({
      zipFrom: '',
      cityFrom: '',
      stateFrom: '',
      countryFrom: '',
      zipTo: '',
      cityTo: '',
      stateTo: '',
      countryTo: '',
      ...data,
      customerInfo: new CustomerInfo(data.customerInfo),
      moveSize: MoveSize.createMoveSize(data.moveSize),
      moveSizeExtra: new Map(data.moveSizeExtra),
      leadSource: new LeadSource(data.leadSource),
    });
  }

  toDTO() {
    const {
      companyId,
      customerInfo,
      moveType,
      moveDate,
      serviceId,
      moveSize,
      moveSizeExtra,
      zipFrom,
      cityFrom,
      stateFrom,
      countryFrom,
      zipTo,
      cityTo,
      stateTo,
      countryTo,
      parkingDistanceFrom,
      parkingDistanceTo,
      estimateType,
      fromElevationId,
      toElevationId,
      desiredDeliveryDate,
      leadSource,
      serviceName,
      enableSmsCustom,
      requestingAccountId,
    } = this;
    const roomsSuffix = moveSizeExtra
      .valueSeq()
      .filter((it) => it.size)
      .map((it) => it.name)
      .join(', ');
    const moveDateStr = format(moveDate, 'yyyy-MM-dd');
    return {
      requestingAccountId,
      companyId,
      customerInfo: {
        ...customerInfo.toJS(),
        otherPhone: null,
        address: null,
      },
      moveType,
      moveDate: moveDateStr,
      desiredDeliveryDate:
        isMovingAndStorageService(serviceName) || (isOvernightService(serviceName) && desiredDeliveryDate)
          ? toBackEndDate(desiredDeliveryDate)
          : null,
      serviceId,
      leadSource,
      moveSize: Math.round(
        Object.values(moveSizeExtra.toJS()).reduce((r, item) => r + (item ? item.size : 0), moveSize.size),
      ),
      sizeDescription: `${moveSize.name}${roomsSuffix ? ` (${roomsSuffix})` : ''}`,
      estimateType,
      waypointList: {
        waypoints: [
          {
            date: moveDateStr,
            locationType: WaypointLocationType.CUSTOMER_SITE,
            laborType: LaborType.LOADING,
            address: {
              postalCode: zipFrom,
              city: cityFrom,
              state: stateFrom,
              country: countryFrom,
            },
            elevationId: +fromElevationId,
            parkingToEntranceSpeedReductionId: +parkingDistanceFrom,
            sequenceOrder: 1,
          },
          {
            date: moveDateStr,
            locationType: WaypointLocationType.CUSTOMER_SITE,
            laborType: LaborType.UNLOADING,
            address: {
              postalCode: zipTo,
              city: cityTo,
              state: stateTo,
              country: countryTo,
            },
            elevationId: +toElevationId,
            parkingToEntranceSpeedReductionId: +parkingDistanceTo,
            sequenceOrder: 2,
          },
        ].filter(filterWaypoints(serviceName)),
      },
      arrivalWindow: 'ANY',
      deliveryWindow: 'ANY',
      enableSmsCustom,
    };
  }
}
