import { EmployeeCommissionType } from 'common-types/payroll/EmployeeCommissionType';

export const EmployeeCommissionNames = {
  [EmployeeCommissionType.HOURLY_RATE]: 'Hourly Rate',
  [EmployeeCommissionType.PACKING_COMMISSION]: 'Packing Commission',
  [EmployeeCommissionType.ADDITIONAL_SERVICES_COMMISSION]: 'Additional Services Commission',
  [EmployeeCommissionType.COMMISSION_FROM_THE_TOTAL]: 'Commission from Total',
  [EmployeeCommissionType.PER_DAY_BONUS]: 'Per Day Bonus',
  [EmployeeCommissionType.PER_JOB_BONUS]: 'Per Job Bonus',
  [EmployeeCommissionType.HOURLY_RATE_AS_HELPER]: 'Hourly Rate',
  [EmployeeCommissionType.DAILY_RATE]: 'Daily Rate',
  [EmployeeCommissionType.TIP]: 'Tip',
  [EmployeeCommissionType.RECEIPT]: 'Receipt',
};
