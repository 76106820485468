import { formatCurrency } from '@elromcoinc/react-shared';

import { StatisticsProps } from 'admin/components/Reports/config/ReportProps';

const concatNumbersSafe = (first?: number | null, second?: number | null): number => {
  return first && second ? first + second : first || second || 0;
};

const getTotalValues = (report: StatisticsProps[]) => {
  const totalStats = report?.reduce(
    (acc, order) => ({
      totalOrdersOverall: concatNumbersSafe(acc.totalOrdersOverall, order.totalOrdersOverall),
      totalBookedOverall: concatNumbersSafe(acc.totalBookedOverall, order.totalBookedOverall),
      totalOrdersInPeriod: concatNumbersSafe(acc.totalOrdersInPeriod, +order.totalOrdersInPeriod),
      totalEstimateMin: concatNumbersSafe(acc.totalEstimateMin, order.totalEstimateMin),
      totalEstimateMax: concatNumbersSafe(acc.totalEstimateMax, order.totalEstimateMax),
      totalBookedInPeriod: concatNumbersSafe(acc.totalBookedInPeriod, order.totalBookedInPeriod),
      totalBookedEstimateMin: concatNumbersSafe(acc.totalBookedEstimateMin, order.totalBookedEstimateMin),
      totalBookedEstimateMax: concatNumbersSafe(acc.totalBookedEstimateMax, order.totalBookedEstimateMax),
      multidayCount: concatNumbersSafe(acc.multidayCount, order.multidayCount),
      conversionRateForPeriod: {
        asPercentage: concatNumbersSafe(acc.totalOrdersInPeriod, +order.totalOrdersInPeriod)
          ? `${(
              (concatNumbersSafe(acc.totalBookedInPeriod, order.totalBookedInPeriod) /
                concatNumbersSafe(acc.totalOrdersInPeriod, +order.totalOrdersInPeriod)) *
              100
            ).toFixed(2)}%`
          : `${formatCurrency(0, 2)}%`,
      },
    }),
    {
      totalOrdersOverall: 0,
      totalBookedOverall: 0,
      totalOrdersInPeriod: 0,
      totalEstimateMin: 0,
      totalEstimateMax: 0,
      totalBookedInPeriod: 0,
      totalBookedEstimateMin: 0,
      totalBookedEstimateMax: 0,
      multidayCount: 0,
      conversionRateForPeriod: { asPercentage: `${formatCurrency(0, 2)}%` },
    },
  );

  return {
    totalOrdersOverall: totalStats?.totalOrdersOverall || 0,
    totalBookedOverall: totalStats?.totalBookedOverall || 0,
    totalOrdersInPeriod: totalStats?.totalOrdersInPeriod || 0,
    totalEstimateMin: totalStats?.totalEstimateMin || 0,
    totalEstimateMax: totalStats?.totalEstimateMax || 0,
    totalBookedInPeriod: totalStats?.totalBookedInPeriod || 0,
    totalBookedEstimateMin: totalStats?.totalBookedEstimateMin || 0,
    totalBookedEstimateMax: totalStats?.totalBookedEstimateMax || 0,
    multidayCount: totalStats?.multidayCount || 0,
    conversionRateForPeriod: {
      asPercentage: totalStats?.conversionRateForPeriod?.asPercentage || `${formatCurrency(0, 2)}%`,
    },
  };
};

export { getTotalValues };
