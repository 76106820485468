import React, { useEffect } from 'react';

import { BACKEND_DATE_FORMAT, BodyText, Button, Select } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { format, startOfDay } from 'date-fns';
import pt from 'prop-types';

import { dateTypes, filtersInputNames } from 'admin/components/Orders/config/OrdersConstants';
import { DATE_TYPE, OrdersLabels } from 'admin/components/Orders/config/OrdersLabels';

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: 'flex',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('xs')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
  selectFilters: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
  },
  filtersShrink: {
    transform: 'translate(14px, 2px) scale(0.75) !important',
  },
  filterBox: {
    minWidth: '10rem',
    maxWidth: '10rem',
    marginRight: '7px',
    marginLeft: '7px',
    [theme.breakpoints.down('md')]: {
      minWidth: '45%',
    },
  },
}));

const dateTypeKeys = Object.keys(dateTypes);

export const OrdersFilters = ({ filters, setFilters, dateRange, filtersOptions, setRowsChecked }) => {
  const classes = useStyles();
  const showClearAll = Boolean(
    Object.keys(filters).filter((key) => filtersInputNames.includes(key) && filters[key]).length,
  );
  const dateType = filters[DATE_TYPE];

  useEffect(() => {
    if (Object.keys(dateRange || {}).length > 1 && dateType) {
      setFilters((state) => {
        const result = { ...state };

        dateTypeKeys.forEach((name) => {
          result[name] = null;
        });

        const { startDate, endDate } = dateRange;
        const startDateSubOneDay = startOfDay(startDate);

        result[dateType] = [format(startDateSubOneDay, BACKEND_DATE_FORMAT), format(endDate, BACKEND_DATE_FORMAT)];

        return result;
      });
    }
  }, [dateRange, dateType]);

  const handleChange = ({ target: { name, value } }) => {
    setFilters((state) => ({ ...state, [name]: value }));
    setRowsChecked([]);
  };

  const handleClearAllFiltersClick = () => {
    setFilters((state) => {
      const result = { ...state };

      filtersInputNames.forEach((name) => {
        delete result[name];
      });

      dateTypeKeys.forEach((name) => {
        delete result[name];
      });

      return result;
    });
    setRowsChecked([]);
  };

  return (
    <Paper className={classes.container}>
      <Box className={classes.filterBox}>
        <Box className={classes.selectFilters}>
          <BodyText>
            <b>Select Filters</b>
          </BodyText>
        </Box>
      </Box>
      {showClearAll && (
        <Box className={classes.filterBox}>
          <Button
            onClick={handleClearAllFiltersClick}
            color="primary"
            variant="text"
            textTransform="capitalize"
            startIcon={<Close />}
          >
            Clear All Filters
          </Button>
        </Box>
      )}
      {filtersInputNames.map((name) => (
        <Box className={classes.filterBox} key={name}>
          <Select
            allowToClear
            defaultValue={null}
            name={name}
            options={filtersOptions[name]}
            onChange={handleChange}
            value={filters[name] || ''}
            fullWidth
            label={OrdersLabels[name]}
          />
        </Box>
      ))}
    </Paper>
  );
};

OrdersFilters.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dateRange: pt.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filters: pt.object.isRequired,
  setFilters: pt.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  filtersOptions: pt.object.isRequired,
  setRowsChecked: pt.func,
};

OrdersFilters.defaultProps = {
  setRowsChecked: null,
};
