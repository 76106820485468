import React, { useEffect, useRef, useState } from 'react';

import {
  BodyBigText,
  CircularProgress,
  HeaderSmallText,
  JobStatus,
  Modal,
  Order,
  OrderStatuses,
  SurveyType,
  Waypoint,
  statusIds,
  useAlert,
  useConfirm,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { List, Map, getIn } from 'immutable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import accountAPI from 'admin/api/AccountAPI';
import orderApi from 'admin/api/OrderAPI';
import servicesAPI from 'admin/api/ServicesAPI';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import { increaseReloadAmount } from 'admin/autodux/ReloadAutodux';
import { getManagerList } from 'admin/autodux/UsersAutodux';
import {
  applyChangeSetHandler,
  commitAndSaveOrder,
  updateOrderSandbox,
} from 'admin/components/OrderWindow/OrderSandboxManager';
import { ADD_SERVICE_KEY, DELETE_SERVICE_KEY } from 'admin/components/OrderWindow/OrderWindowConstants';
import { CurrentMovesHistory, PastMovesHistory } from 'admin/components/OrderWindow/blocks/HistoryOverview';
import {
  OrderChangeSetProvider,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
  useSendingTemplates,
  useValidateStorageOnBe,
} from 'admin/components/OrderWindow/context';
import { useUpdateOrderContext } from 'admin/components/OrderWindow/context/useUpdateOrderContext';
import { UpdateOrderDialog } from 'admin/components/OrderWindow/modals/UpdateOrderDialog';
import { sendMessagesToOrder } from 'admin/components/OrderWindow/sendMessagesToOrder';
import {
  EMAIL_SERVER_SETTINGS,
  USE_OUT_GOING_EMAIL,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import { OrderWindowPages } from 'admin/constants/OrderWindowPages';
import SettingName from 'admin/constants/SettingName';
import useCurrentUserId from 'admin/hooks/useCurrentUserId';
import { useFetchManagers } from 'admin/hooks/useFetchManagers';

import { usePreventPageClose } from '../../hooks/usePreventPageClose';
import ErrorMsg from '../ErrorMsg';
import MainPanel from './MainPanel';
import OrderView from './OrderView';
import OrderWindowContainer from './OrderWindowContainer';
import TitleBar from './TitleBar';
import audit from './audit';
import useWidthChange from './hooks/useElementSize';
import { useLockOrder } from './useLockOrder';

const useStyles = makeStyles((theme) => ({
  inProgress: {
    position: 'absolute',
    zIndex: 2001,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.action.disabledBackground,
  },
  releaseTitle: {
    textTransform: 'none !important',
  },
}));

function generateTitle(currentPage, order) {
  if (currentPage === OrderWindowPages.HISTORY) {
    return OrderWindowPages.HISTORY;
  }

  const { orderNumber = '' } = order || {};
  const serviceType = order?.getIn(['services', 0, 'serviceTypeName']) || '';
  const estimateType = SettingName.ESTIMATE_TYPES[order?.estimateType] || '';

  return `Order ID: ${orderNumber} - ${serviceType}${estimateType ? ` (${estimateType})` : ''}`;
}

const DEFAULT_ERROR = 'Unknown error occurred.';

const getExceptionMessage = (exception) => {
  const errors = getIn(exception, ['errors'], []);
  return getIn({ ...exception.response }, ['json', 'errors'], errors)
    .map((it) => it.message)
    .join('\n');
};

const laborTimeNameRegExp = new RegExp('laborDuration', 'i');

const requireTruckStatuses = [statusIds.BOOKED, statusIds.CAN_BOOK_ONLINE, statusIds.RESERVED];

function Main({ orderId, onClose, width: wWidth, minimized, onMinimize, ...rest }) {
  const [rootRef, width, height] = useWidthChange(wWidth, minimized);
  const settings = useOrderWindowSettings();
  const { serviceIndex } = useOrderServiceIndex();
  const { sendingTemplates, setSendingTemplates } = useSendingTemplates();
  const { applyOrderChangeSetRef, saveOrderRef } = useUpdateOrderContext();
  const { storageErrors, handleCloseWarningModal } = useValidateStorageOnBe();
  const orderViewRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(OrderWindowPages.ORDER);
  const [errorMsg, setError] = useState(null);
  const [changeSet, setChangeSet] = useState(new Map());
  const [inFlight, setInflight] = useState(false);
  const [inFlightRecalculateOrder, setInFlightRecalculateOrder] = useState(false);
  const { originalOrder, setOriginalOrder, lastUpdated, setLastUpdated, order, setOrder } = useOrderState();
  const [isLocked, setLocked] = useState(undefined);
  const currentUserId = useCurrentUserId();
  const [isLoading, setIsLoading] = useState(false);
  const [accountId, setAccountId] = useState(0);
  const [account, setAccount] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [surveyServices, setSurveyServices] = useState([]);
  const [isLoadingSurveyServices, setIsLoadingSurveyServices] = useState(false);
  const [saveStatusColor, setSaveStatusColor] = useState();
  const managersList = useSelector(getManagerList);
  const dispatch = useDispatch();
  const currentEmployee = useSelector(getCurrentEmployee);
  const dispatchJob = order?.getServiceQuote(serviceIndex)?.dispatchJob;

  const allTruckAssignmentsForCurrentOrder = order?.services.reduce((acc, service) => {
    return acc + service?.truckAssignments.filter((ta) => !ta.delivery).size;
  }, 0);

  const allTrucksCount = order?.services.reduce((acc, service) => {
    return acc + service?.quote?.numberOfTrucks.value();
  }, 0);

  useLockOrder(orderId);

  const onConfirm = (onClose) => {
    handleOnSave();
    onClose();
  };
  const { setShowAlert: setShowSaveDialog, alertProps: saveDialogProps } = useAlert({
    confirmTitle: 'Save',
    cancelTitle: 'Cancel',
    onConfirm,
  });
  const { confirm: confirmChoosingTrucks, renderDialog: renderChoosingTrucksWarning } = useConfirm({
    title: 'Warning!',
    message:
      'To save these changes, you need to assign a truck to your move. Navigate to the "Truck Assignment" section and select a truck.',
    confirmTitle: 'Close',
    maxWidth: 'xs',
    displayCancel: false,
  });
  const { confirm: confirmFillingStreet, renderDialog: renderFillingStreetWarning } = useConfirm({
    title: 'Warning!',
    message: 'To save these changes, you need to enter all street addresses in the "Addresses" section.',
    confirmTitle: 'Close',
    maxWidth: 'xs',
    displayCancel: false,
  });

  const { confirm: confirmAssignTrucks, renderDialog: renderAssignTrucksWarning } = useConfirm({
    title: 'Warning!',
    message: order?.isLongDistance()
      ? 'Please assign the correct number of trucks for both pickup and delivery'
      : `You need to assign more ${allTrucksCount - allTruckAssignmentsForCurrentOrder} trucks to save this changes`,
    confirmTitle: 'Close',
    maxWidth: 'xs',
    displayCancel: false,
  });
  const { confirm: confirmEqualAssignTrucks, renderDialog: renderEqualAssignTrucksWarning } = useConfirm({
    title: 'Warning!',
    message: 'Each job’s “number of trucks” needs to match the count of “trucks assigned”.',
    confirmTitle: 'Close',
    maxWidth: 'xs',
    displayCancel: false,
  });
  const { confirm: confirmUpdateDialog, renderDialog: renderUpdateWarning } = useConfirm({
    title: 'Warning!',
    message:
      'Your bill of lading has already been started. Making changes in closing and syncing to the bill of lading may cause items such as packing and additional services to be overwritten.',
    confirmTitle: 'Save',
    cancelTitle: 'Cancel',
    maxWidth: 'sm',
  });

  const showSaveDialog = async () => {
    if (
      requireTruckStatuses.includes(order.status) &&
      order.services.some((s) => s.truckAssignments.isEmpty()) &&
      (await confirmChoosingTrucks())
    ) {
      return;
    }

    if (
      requireTruckStatuses.includes(order.status) &&
      allTrucksCount > allTruckAssignmentsForCurrentOrder &&
      (await confirmAssignTrucks())
    ) {
      return;
    }

    if (
      requireTruckStatuses.includes(order.status) &&
      allTrucksCount !== allTruckAssignmentsForCurrentOrder &&
      (await confirmEqualAssignTrucks())
    ) {
      return;
    }

    if (
      order.status === statusIds.BOOKED &&
      !order
        .getAllServicesWaypoints()
        .filter(Waypoint.isCustomerStop)
        .filter((w) => !w?.address?.street1)
        .isEmpty() &&
      (await confirmFillingStreet())
    ) {
      return;
    }

    setShowSaveDialog(true);
  };

  const onConfirmRelease = (onClose) => {
    onClose();
    setLocked(false);
  };
  const { setShowAlert: setShowReleaseDialog, alertProps: releaseDialogProps } = useAlert({
    confirmTitle: 'Confirm',
    cancelTitle: 'Cancel',
    onConfirm: onConfirmRelease,
  });

  const showReleaseDialog = () => setShowReleaseDialog(true);

  const handlePromiseException =
    (callback, msgPrefix = '') =>
    (e) => {
      let exception = e;
      // need to show error message for easier bug tracking
      console.log(e);
      let changeSet = null;

      if (e?.changeSet) {
        exception = e.exception;
        changeSet = e.changeSet;
      }

      const message = getExceptionMessage(exception) || DEFAULT_ERROR;
      setError(`${msgPrefix && `${msgPrefix}: `}${exception.errorMessage || ''}\n${message}`);
      return callback ? callback(changeSet) : null;
    };

  const recalculateOrder = (orderToBeUpdated, lastUpdatedOrder) => {
    if (!orderToBeUpdated || !originalOrder || !lastUpdatedOrder) {
      return Promise.reject();
    }

    setInFlightRecalculateOrder(true);
    return updateOrderSandbox(originalOrder, lastUpdatedOrder, changeSet, orderToBeUpdated, settings)
      .catch(
        handlePromiseException((cs) => {
          return [lastUpdatedOrder, cs || changeSet];
        }),
      )
      .then(async ([updatedOrder, updatedChangeSet]) => {
        const isUpdateClosing =
          orderToBeUpdated.closingOrderDetail && updatedChangeSet.some((_, key) => key.includes('closingOrderDetail'));

        let isConfirmUpdateClosing = true;
        const currentStatusIdx = Object.values(JobStatus).findIndex((status) => status === dispatchJob?.jobStatus);
        const loadingStatusIdx = Object.values(JobStatus).findIndex((status) => status === JobStatus.LOADING);

        if (isUpdateClosing && currentStatusIdx >= loadingStatusIdx) {
          isConfirmUpdateClosing = await confirmUpdateDialog();
        }

        if (isUpdateClosing && isConfirmUpdateClosing) {
          try {
            let order = Order.createOrder(
              await orderApi.updateOrderClosing(orderId, updatedOrder.closingOrderDetail.toMoveBoardDto()),
            );

            if (order.orderBillOfLadingDetail) {
              let updatableOrder = updatedOrder;

              updatedChangeSet.forEach((_, key) => {
                const splittedKey = key.split('.');
                const index = +splittedKey[2] || 0;
                const serviceQuote = updatedOrder.getServiceQuote(index);
                const isWasNotCompleted = !serviceQuote?.dispatchJob?.jobStatuses.some(
                  (s) => s.jobStatus === JobStatus.COMPLETED,
                );

                if (key.includes('closingOrderDetail') && !key.includes('laborTime') && isWasNotCompleted) {
                  const keyForBol = key
                    .replace('closingOrderDetail', 'orderBillOfLadingDetail')
                    .replace('serviceRosterClosingsDto', 'serviceRosterBillOfLadingDtos');

                  if (key.includes('.waypoints')) {
                    const closingWaypoints = updatedOrder.getIn(key.split('.'));
                    const bolWaypoints = updatedOrder.getIn(keyForBol.split('.'));

                    // if add or remove waypoints create new waypoints in BOL. It'll be impossible if time is started
                    if (closingWaypoints.size !== bolWaypoints.size) {
                      updatableOrder = updatableOrder.setIn(
                        keyForBol.split('.'),
                        closingWaypoints.map((w) => w.set('entityId', null)),
                      );
                    } else {
                      updatableOrder = updatableOrder.setIn(
                        keyForBol.split('.'),
                        closingWaypoints.map((w, index) => w.set('entityId', bolWaypoints.getIn([index, 'entityId']))),
                      );
                    }
                  } else {
                    updatableOrder = updatableOrder.setIn(keyForBol.split('.'), updatedOrder.getIn(key.split('.')));
                  }
                }
              });

              order = Order.createOrder(
                await orderApi.updateOrderBillOfLading(
                  orderId,
                  updatableOrder.orderBillOfLadingDetail.toMoveBoardDto(),
                ),
              );
            }

            return [
              updatedOrder
                .set('closingOrderDetail', order.closingOrderDetail)
                .set('orderBillOfLadingDetail', order.orderBillOfLadingDetail),
              updatedChangeSet.filter((_, key) => !key.includes('closingOrderDetail')),
            ];
          } catch (e) {
            return [updatedOrder, updatedChangeSet];
          }
        } else if (isUpdateClosing && !isConfirmUpdateClosing) {
          return [
            updatedOrder
              .set('closingOrderDetail', lastUpdatedOrder.closingOrderDetail)
              .set('orderBillOfLadingDetail', lastUpdatedOrder.orderBillOfLadingDetail),
            updatedChangeSet.filter((_, key) => !key.includes('closingOrderDetail')),
          ];
        }

        return [updatedOrder, updatedChangeSet.filter((_, key) => !key.includes('closingOrderDetail'))];
      })
      .then(([resultingOrder, updatedChangeSet]) => {
        setOrder(resultingOrder);
        setLastUpdated(resultingOrder);
        setInFlightRecalculateOrder(false);
        setChangeSet(updatedChangeSet);
        return resultingOrder;
      });
  };

  const setLoadedOrder = (o) => {
    setOriginalOrder(() => o);
    setLastUpdated(o);
    setOrder(() => o);
    applyOrderChangeSetRef.current = null;
  };

  const cleanOldSandboxIfExist = (orderNumber) => {
    orderApi
      .loadSandbox(orderNumber)
      .then((sandbox) => {
        if (sandbox && sandbox.orderId) {
          orderApi.cancelOrderUpdates(sandbox.orderId).catch(() => {
            // I removed this because it mislead managers
          });
        }
      })
      .catch(handlePromiseException(null, 'Unable to check for staged changes'));
  };

  async function fetchOrderInfo() {
    setInflight(true);
    return await orderApi
      .getOrder(orderId)
      .then(Order.createOrder)
      .then((loadedOrder) => {
        cleanOldSandboxIfExist(loadedOrder.orderNumber);
        return loadedOrder;
      })
      .then(setLoadedOrder)
      .catch(handlePromiseException(null, 'Unable to retrieve order information.'))
      .then(() => setInflight(false));
  }

  useEffect(() => {
    if (orderId && order?.orderId === orderId) {
      setLocked(undefined);
    }
  }, [orderId, order?.orderId]);

  useEffect(() => {
    if (isLocked === undefined && !!order?.lockedByEmployeeId && order?.lockedByEmployeeId !== currentUserId) {
      setLocked(true);
    }
  }, [order?.lockedByEmployeeId, currentUserId, isLocked, orderId]);

  const lockedByEmployee = managersList.find((it) => it.id === order?.lockedByEmployeeId);

  useEffect(() => {
    if (orderId) {
      setOrder(null);
      setCurrentPage(OrderWindowPages.ORDER);
      fetchOrderInfo();
    }
  }, [orderId]);

  useEffect(() => {
    if (!changeSet.isEmpty()) {
      setOrder((prevOrder) => {
        if (!prevOrder) {
          return null;
        }

        const updated = applyChangeSetHandler(changeSet)(prevOrder);

        if (prevOrder.equals(updated) && !changeSet.has(ADD_SERVICE_KEY) && !changeSet.has(DELETE_SERVICE_KEY)) {
          return prevOrder;
        }

        applyOrderChangeSetRef.current = applyOrderChangeSetRef.current
          ? applyOrderChangeSetRef.current
              .catch(() => {})
              .then((recentOrder) => {
                // if last promise returns result, and it has orderId, then use this updated order for further updates
                // to avoid duplicate API calls
                if (recentOrder && recentOrder.orderId > 0) {
                  return recalculateOrder(updated, recentOrder);
                }
                return recalculateOrder(updated, lastUpdated);
              })
          : recalculateOrder(updated, lastUpdated);
        return updated;
      });
    }
  }, [changeSet]);

  useEffect(() => {
    if (order) {
      setAccountId(order.accountId);
    }
  }, [order]);

  useEffect(() => {
    if (accountId) {
      setIsLoading(true);

      accountAPI
        .getAccountSummary(accountId)
        .then((res) => {
          if (typeof res !== 'string') {
            setAccount(res);
          }
        })
        .catch(() => {
          enqueueSnackbar(`Can't get account information`, { variant: 'error' });
        })
        .then(() => setIsLoading(false));
    }
  }, [accountId]);

  useFetchManagers();

  const getAllSurveyServices = () => {
    if (order) {
      setIsLoadingSurveyServices(true);

      Promise.all([
        servicesAPI.getSurveyService(order.orderId, {
          order_service_type: SurveyType.IN_HOME_ESTIMATE,
        }),
        servicesAPI.getSurveyService(order.orderId, {
          order_service_type: SurveyType.VIRTUAL_ESTIMATE,
        }),
      ])
        .then((results) => {
          setSurveyServices(
            results.filter(Boolean).map((it) => ({
              ...it,
              assignedName: (managersList.find((manager) => manager.id === it.assignedBy) || {}).fullName,
            })),
          );
        })
        .catch(() => {})
        .then(() => {
          setIsLoadingSurveyServices(false);
        });
    }
  };

  useEffect(() => {
    if (!managersList.isEmpty()) {
      getAllSurveyServices();
    }
  }, [order?.orderId, managersList]);

  useEffect(() => {
    if (originalOrder) {
      const orderStatusType = OrderStatuses.find((it) => it.id === originalOrder.status);
      setSaveStatusColor(orderStatusType?.color ?? false);
    }
  }, [originalOrder?.status]);

  const saveEnabled =
    !inFlight &&
    !(
      order
        ?.set('closingOrderDetail', null)
        ?.set('orderBillOfLadingDetail', null)
        ?.equals(
          originalOrder
            ?.set('lastUpdated', order?.lastUpdated)
            ?.set('closingOrderDetail', null)
            ?.set('orderBillOfLadingDetail', null),
        ) ?? true
    );

  usePreventPageClose(saveEnabled);

  if (!orderId) {
    return null;
  }

  const isReady = !inFlight;

  const handleOnAddDay = (addServiceRequest, callback) => {
    setInflight(true);
    orderApi
      .addServiceToOrder(orderId, addServiceRequest)
      .then(Order.createOrder)
      .then(setOrder)
      .then(callback)
      .catch(handlePromiseException(null, 'Unable to add day to order'))
      .then(() => setInflight(false));
  };

  function handleOnSave() {
    setInflight(true);
    const updates = () => {
      return commitAndSaveOrder(order, originalOrder)
        .then(fetchOrderInfo)
        .then(() => setChangeSet((set) => set.clear()))
        .then(() => {
          const orderInfo = {
            orderId: order.orderId,
            email: order?.contactInfo?.email,
            phoneNumber: order?.contactInfo?.primaryPhone?.number,
          };

          sendMessagesToOrder(
            orderInfo,
            sendingTemplates,
            !!currentEmployee?.[EMAIL_SERVER_SETTINGS]?.[USE_OUT_GOING_EMAIL],
          );
          setSendingTemplates([]);
        })
        .then(() => dispatch(increaseReloadAmount()))
        .catch(handlePromiseException(null, 'Unable to save changes'))
        .then(() => setInflight(false));
    };

    const { disableAutoCalculation } = order;

    const auditData = audit(changeSet, originalOrder, order, settings);

    const savePromise =
      disableAutoCalculation !== originalOrder.disableAutoCalculation
        ? orderApi.setDisableAutoCalculation(orderId, disableAutoCalculation, auditData).then(updates)
        : updates();

    saveOrderRef.current = savePromise;

    return savePromise;
  }

  const applySingleChange = (event) => (changeSet) => {
    const { name, value } = event.target || event;
    const serviceIndex = name.split('.')[1];
    if (name === 'disableAutoCalculation' && !value) {
      // if we enable auto calculation, then we need to reset manually changed travel/labor time since they will be
      // reset on BE as well.
      return changeSet.filterNot((_, key) => laborTimeNameRegExp.test(key)).set(name, value);
    }
    // need to remove not saved truckAssignments if we changed move date.
    if (name === `services.${serviceIndex}.date`) {
      return changeSet.set(`services.${serviceIndex}.truckAssignments`, new List()).set(name, value);
    }

    return changeSet.set(name, value);
  };

  const handleChange = (changes) => {
    if (Array.isArray(changes)) {
      setChangeSet((set) => changes.reduce((changeSet, e) => applySingleChange(e)(changeSet), set));
    } else {
      setChangeSet(applySingleChange(changes));
    }
  };

  const inFlightAddOrRemoveService =
    inFlightRecalculateOrder && (changeSet.has(ADD_SERVICE_KEY) || changeSet.has(DELETE_SERVICE_KEY));

  return (
    <OrderChangeSetProvider
      changeSet={changeSet}
      setChangeSet={setChangeSet}
      showSaveDialog={showSaveDialog}
      onChange={handleChange}
      fetchOrderInfo={fetchOrderInfo}
      inFlight={inFlightRecalculateOrder}
    >
      <OrderWindowContainer orderId={orderId} height={height} width={wWidth} minimized={minimized} {...rest}>
        <Box className="applyRadius" ref={rootRef}>
          {(!isReady || inFlightAddOrRemoveService) && (
            <Box className={classes.inProgress}>
              <CircularProgress data-testid="circularProgressIcon" />
            </Box>
          )}
          <TitleBar
            inFlight={inFlight}
            minimized={minimized}
            onMinimize={onMinimize}
            parentWidth={width}
            saveEnabled={saveEnabled}
            onRelease={showReleaseDialog}
            isLocked={!!isLocked}
            onClose={onClose}
            hasInventory={order && order.hasInventory()}
            onSave={handleOnSave}
            orderId={orderId}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            title={generateTitle(currentPage, originalOrder)}
            orderViewRef={orderViewRef}
            order={order}
            originalOrder={originalOrder}
            saveStatusColor={saveStatusColor}
            inFlightRecalculateOrder={inFlightRecalculateOrder}
          />
          {!minimized && (
            <MainPanel className="applyBottomRadius" px={2} py={1} pb={3}>
              {currentPage === OrderWindowPages.ORDER ? (
                <Box ref={orderViewRef}>
                  <OrderView
                    windowWidth={width}
                    fetchOrderInfo={fetchOrderInfo}
                    onChange={handleChange}
                    onAddDay={handleOnAddDay}
                    order={order}
                    originalOrder={originalOrder}
                    isLocked={!!isLocked}
                    account={account}
                    setLoadedOrder={setLoadedOrder}
                    isLoading={isLoading}
                    setCurrentPage={setCurrentPage}
                    isLoadingSurveyServices={isLoadingSurveyServices}
                    surveyServices={surveyServices}
                    reloadSurveyServices={getAllSurveyServices}
                    inFlightRecalculateOrder={inFlightRecalculateOrder}
                  />
                </Box>
              ) : (
                <>
                  <CurrentMovesHistory
                    accountId={order.accountId}
                    account={account}
                    setAccount={setAccount}
                    orderWindowId={orderId}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                  <PastMovesHistory
                    accountId={order.accountId}
                    orderWindowId={orderId}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                  />
                </>
              )}
            </MainPanel>
          )}
        </Box>
        {errorMsg && <ErrorMsg open message={errorMsg} onClose={() => setError(null)} />}
        {saveDialogProps.open && (
          <UpdateOrderDialog
            alertProps={saveDialogProps}
            changeSet={changeSet}
            order={order}
            originalOrder={originalOrder}
            managersList={managersList}
          />
        )}
        <Modal
          title={
            <HeaderSmallText className={classes.releaseTitle}>
              <b>{`You are about to release this order from ${lockedByEmployee?.fullName || ''}`}</b>
            </HeaderSmallText>
          }
          {...releaseDialogProps}
        >
          <BodyBigText>
            Any changes not saved prior to releasing will be discarded. Only the most recent edits will be applied after
            saving.
          </BodyBigText>
        </Modal>
        {renderChoosingTrucksWarning()}
        {renderAssignTrucksWarning()}
        {renderFillingStreetWarning()}
        {renderEqualAssignTrucksWarning()}
        {renderUpdateWarning()}
        {!!storageErrors?.length && (
          <Modal
            title="Warning"
            open
            actions={[
              {
                label: 'Ok',
                onClick: handleCloseWarningModal,
              },
            ]}
          >
            {storageErrors?.map((errorText) => (
              <BodyBigText>{errorText}</BodyBigText>
            ))}
          </Modal>
        )}
      </OrderWindowContainer>
    </OrderChangeSetProvider>
  );
}

Main.propTypes = {
  orderId: PropTypes.number,
  minimized: PropTypes.bool,
  onMinimize: PropTypes.func,
  onClose: PropTypes.func,
};

Main.defaultProps = {
  orderId: null,
  minimized: false,
  onMinimize: () => false,
  onClose: () => false,
};

export { Main };
