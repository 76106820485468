import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  BaseAddressInputs,
  BodyBigText,
  BodySmallText,
  BodyText,
  Button,
  CubeIcon,
  LaborType,
  LaborTypeName,
  ParkingTypeOptions,
  PropertyTypeDto,
  Select,
  IconButton as SharedIconButton,
  TextInput,
  Waypoint,
  isLoadingOrPackingService,
  isMovingAndStorageService,
  isPackingService,
  isUnloadingService,
  zipCodeAsyncYUP,
  zipCodeSyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputBaseProps, MenuItem, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import RoomIcon from '@material-ui/icons/Room';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import clsx from 'clsx';
import { Map } from 'immutable';
import { FormProvider, useForm } from 'react-hook-form';
// @ts-ignore
import { debounce } from 'throttle-debounce';
import { number, object, string } from 'yup';

import geoApi from 'admin/api/GeoAPI';
import { COUNTRY } from 'admin/components/AccountWindow/config/AccountWindowLabels';
import { formatTime } from 'admin/components/OrderWindow/SurveySchedulerBox/config';
import DistanceBox from 'admin/components/OrderWindow/blocks/Addresses/DistanceBox';
import { ServiceWaypointNumber } from 'admin/components/OrderWindow/blocks/Addresses/ServiceWaypointNumber';
import {
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import { useOrderWindowEditAddressIndex } from 'admin/components/OrderWindow/context/useOrderWindowEditAddressIndex';
import { usePropertyTypeOptions } from 'admin/components/OrderWindow/hooks';
import {
  ACCESS_RESTRICTION_APPLIES,
  ACCESS_RESTRICTION_INFO,
  ADDRESS,
  APARTMENT,
  CITY,
  COI_RECIPIENT,
  CertificateOfInsuranceModal,
  ENTRANCE_TYPE,
  LATITUDE,
  LONGITUDE,
  PARKING_DISTANCE,
  PARKING_TYPE,
  POSTAL_CODE,
  PROPERTY_TYPE,
  REQUIRES_COI,
  STATE,
  STREET,
  Specification,
  TimeWindowRestriction,
  USE_CUSTOMER_FOR_COI,
  addressFieldNames,
  addressLabels,
  apartmentAddressPath,
  fullAddressPath,
  labels,
  propertyTypePath,
  startEndTitles,
  streetAddressPath,
} from 'admin/components/OrderWindow/modals/FullAddressesModal';
import { getElevationReduction, getParkingDistanceOptions } from 'admin/selectors';
import GoogleAutoComplete from 'common/components/GoogleAutoComplete';
import { ParkingIcon } from 'common/components/ParkingIcon';
import { BlueDeleteIcon, BluePenIcon, BlueRightArrorIcon, IconBlueFab } from 'common/components/Widgets';
import { COIIcon, DoorIcon, TimeWindowRestrictionIcon } from 'common/components/icons';

import DragHandle from './DragHandle';
import SortableElement from './SortableElement';

const zipCodeSchema = zipCodeSyncYUP();

enum Direction {
  ROW = 'row',
  COLUMN = 'column',
}

const useStyles = makeStyles(({ palette }) => ({
  cursorPointer: {
    cursor: 'pointer',
  },
  select: {
    '& .MuiFilledInput-input': {
      padding: '0px 28px 0px 0px',
    },
  },
  addressInput: {
    '& .MuiFilledInput-input': {
      paddingTop: '2px !important',
      paddingBottom: '0 !important',
      paddingLeft: '0 !important',
      marginTop: '0 !important',
      marginBottom: '0 !important',
      cursor: 'pointer',
    },
  },
  initialText: {
    fontWeight: 'initial',
  },
  bolderText: {
    fontWeight: 'bolder',
    color: palette.error.main,
    '&:hover': {
      backgroundColor: palette.common.white,
    },
  },
  swapStorageIcon: {
    fontSize: '30px',
  },
  button: {
    padding: '0px',
    textTransform: 'initial',
  },
}));

const schema = object().shape({
  [ADDRESS]: object().shape({
    [STREET]: string().label(labels[ADDRESS][STREET]).max(99).nullable(),
    [APARTMENT]: string().label(labels[ADDRESS][APARTMENT]).max(10, 'max 10 symbols').nullable(),
    [POSTAL_CODE]: zipCodeAsyncYUP(POSTAL_CODE).label(addressLabels[POSTAL_CODE]).required().nullable(),
    [LATITUDE]: number().nullable(),
    [LONGITUDE]: number().nullable(),
  }),
});

interface AddressBlockProps {
  data: Waypoint;
  disabled?: boolean;
  isDestination: boolean;
  onDelete: () => void;
  distanceFromPrevious: string | null;
  minWidth: number;
  dragDisabled: boolean;
  durationFromPrevious: string | null;
  nextNotHiddenWaypoint: number | null;
  onChange: (newWaypoint: Waypoint, index: number) => void;
  title: string;
  index: number;
  onClickOpenMap: (index: number, title: string) => () => void;
  onBlockWidthChange: (width: number) => void;
  serviceType: ServiceType;
  showLaborType: boolean;
  inFlightRecalculateOrder?: boolean;
  showLastAddStop: boolean;
  handleSwapStorage: () => void;
}

let geoCache = Map<string, { lat: number; lng: number }>();

const AddressBlock: FC<AddressBlockProps> = ({
  data: initialWP,
  disabled = false,
  isDestination = false,
  onDelete,
  showLastAddStop,
  index,
  title,
  distanceFromPrevious = null,
  minWidth = 140,
  showLaborType,
  serviceType,
  onBlockWidthChange,
  onClickOpenMap,
  onChange,
  dragDisabled = false,
  inFlightRecalculateOrder = false,
  durationFromPrevious = null,
  nextNotHiddenWaypoint = null,
  handleSwapStorage,
}) => {
  const [data, setData] = useState<Waypoint>(initialWP);
  const { address } = data;
  const classes = useStyles();
  const settings = useOrderWindowSettings();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { setEditWaypoint } = useOrderWindowEditAddressIndex();
  const { isCompleted, isLockSales } = useOrderClosingContext();
  const { order } = useOrderState();
  const moveType = order?.moveType!;
  const blockRef = useRef<HTMLDivElement>(null);
  const parkingDistanceOptions = getParkingDistanceOptions(settings)[moveType];
  const elevationReductionOptions = getElevationReduction(settings)[moveType];
  const elevationMap = elevationReductionOptions.reduce(
    (res: any, item: [string, string]) => ({ ...res, [item[0]]: item[1] }),
    {} as { [key: string]: string },
  );
  const titleSuffix =
    index === 0 || isDestination ? '' : ` (${LaborTypeName[data.laborType as keyof typeof LaborType]})`;
  const entranceOptions = (elevationReductionOptions || []).map(([id, label]: [id: number, label: string]) => [
    id.toString(),
    label,
  ]);
  const [openModal, setOpenModal] = useState<Specification | null>(null);
  const isOpenCOIModal = openModal === Specification.COI;
  const isOpenTWRModal = openModal === Specification.TWR;
  const serviceWaypoints = order?.getServiceWaypoints(serviceIndex);
  const propertyTypeOptions = usePropertyTypeOptions();

  useEffect(() => {
    if (!inFlightRecalculateOrder) {
      setData(initialWP);
    }
  }, [initialWP, inFlightRecalculateOrder]);

  const formMethods = useForm({
    defaultValues: data.toJS() as Waypoint,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    formMethods.reset(data.toJS() as Waypoint);
  }, [data]);

  const { setValue, watch, handleSubmit } = formMethods;

  const zipValue = watch(`${ADDRESS}.${POSTAL_CODE}`);
  const cityValue = watch(`${ADDRESS}.${CITY}`);
  const stateValue = watch(`${ADDRESS}.${STATE}`);
  const country = watch(`${ADDRESS}.${COUNTRY}`);

  const onSubmit = (newData: Waypoint) => {
    onChange(new Waypoint(newData), index);
  };

  const debouncedOnSubmit = useMemo(
    () =>
      debounce(1000, () => {
        handleSubmit(onSubmit)();
      }),
    [],
  );

  const onBlurAndSaveWithTimeout = () => {
    setTimeout(debouncedOnSubmit, 200);
  };

  useEffect(() => {
    if (zipCodeSchema.isValidSync(zipValue)) {
      let address = data.address;

      if (cityValue) {
        address = address.set([CITY], cityValue);
      }

      if (stateValue) {
        address = address.set([STATE], stateValue);
      }

      if (zipValue) {
        address = address.set([POSTAL_CODE], zipValue);
      }

      const fullAddress = address.fullAddressLine();

      if (geoCache.has(fullAddress)) {
        const { lat, lng } = geoCache.get(fullAddress)!;
        setValue(`${ADDRESS}.${LATITUDE}`, lat);
        setValue(`${ADDRESS}.${LONGITUDE}`, lng);
      } else {
        geoApi
          .getGeoAddress(fullAddress)
          .then((res) => {
            geoCache = geoCache.set(fullAddress, { lat: res[LATITUDE], lng: res[LONGITUDE] });
            setValue(`${ADDRESS}.${LATITUDE}`, res[LATITUDE]);
            setValue(`${ADDRESS}.${LONGITUDE}`, res[LONGITUDE]);
          })
          .catch(() => {});
      }
    }
  }, [zipValue, cityValue, stateValue]);

  useEffect(() => {
    if (blockRef?.current?.clientWidth) {
      onBlockWidthChange(blockRef.current.clientWidth);
    }
  }, [blockRef?.current?.clientWidth]);

  const handleChangeAddressWithGoogleAutocomplete = useCallback((address) => {
    Object.entries(address).forEach(([key, value]) => {
      setValue(`${ADDRESS}.${key}`, value);
    });
    debouncedOnSubmit();
  }, []);

  const onSetAddressIndex = (index: number) => () => {
    setEditWaypoint(
      index >= 0
        ? data
        : new Waypoint({
            waypointIndex: index,
            originalWaypointIndex: index,
            serviceIndex: data.serviceIndex,
            sequenceOrder: index,
          }),
    );
  };

  const getNewAddressIndex = (index: number) => {
    return (index + 1) * -1;
  };

  if (data.hidden) {
    return null;
  }

  const showIcon = isPackingService(serviceType);

  const handleCancel = () => {
    setOpenModal(null);
  };

  const handleChangeWaypoint = ({ target: { name, value: newValue } }: ChangeEvent<HTMLInputElement>) => {
    const newWaypoint = data.setIn(name.split('.'), newValue);
    onChange(newWaypoint, index);
  };

  const handleSaveTWR = (dataTWR: any) => {
    const result = {
      ...dataTWR,
      accessRestrictionInfo: {
        ...dataTWR.accessRestrictionInfo,
        startTime: formatTime(dataTWR?.accessRestrictionInfo?.startTime),
        endTime: formatTime(dataTWR?.accessRestrictionInfo?.endTime),
      },
    };
    const newWaypoint = data
      .setIn([ACCESS_RESTRICTION_INFO], result.accessRestrictionInfo)
      .setIn([ACCESS_RESTRICTION_APPLIES], true);
    onChange(newWaypoint, index);
    handleCancel();
  };

  const handleSaveCOI = (dataCOI: Waypoint) => {
    const customerCertificate = {
      ...dataCOI.coiRecipient,
      firstName: order?.contactInfo?.firstName,
      lastName: order?.contactInfo?.lastName,
      email: order?.contactInfo?.email,
      phoneNumber: order?.contactInfo?.primaryPhone.number,
    };
    const newWaypoint = data
      .setIn([COI_RECIPIENT], !dataCOI.useCustomerForCoi ? dataCOI.coiRecipient : customerCertificate)
      .setIn([USE_CUSTOMER_FOR_COI], dataCOI.useCustomerForCoi)
      .setIn([REQUIRES_COI], true);
    onChange(newWaypoint, index);
    handleCancel();
  };

  // this index is from customer waypoints, but we need to use it for service waypoint that's why we need to add 2 instead of 1
  const nextWaypoint = serviceWaypoints?.get(index + 2);
  const isSingleAddresses =
    isUnloadingService(serviceType) || isPackingService(serviceType) || isLoadingOrPackingService(serviceType);

  const disableInputs = isCompleted || isLockSales;

  const onChangePropertyType = (propertyType: PropertyTypeDto | null) => () => {
    setValue(PROPERTY_TYPE, propertyType);
  };

  const onChangeCity = () => {
    debouncedOnSubmit();
  };

  const onChangePostalCode: InputBaseProps['onChange'] = (e) => {
    if (zipCodeSchema.isValidSync(e.target.value)) {
      debouncedOnSubmit();
    }
  };

  const handleOpenModal = (mode: Specification) => () => {
    setOpenModal(mode);
  };

  return (
    <FormProvider {...(formMethods as any)}>
      {isSingleAddresses && index === 0 && (nextNotHiddenWaypoint || 0) < 1 && (
        <DistanceBox
          disabled={disabled || disableInputs}
          display="flex"
          flexDirection="column"
          alignItems="center"
          alignSelf="center"
        >
          {/*@ts-ignore added because styled component*/}
          <IconBlueFab
            size="small"
            iconSize="16px"
            buttonSize="20px"
            disableBoxShadow
            disabled={disableInputs}
            onClick={onSetAddressIndex(getNewAddressIndex(index))}
          />
        </DistanceBox>
      )}
      <SortableElement display="inline-flex" key={address.id} index={index} disabled={disabled || disableInputs}>
        <Box className="draggerBox">
          {!showIcon && (
            <IconButton onClick={onClickOpenMap(index, title)} size="small">
              <RoomIcon color="primary" />
            </IconButton>
          )}
          <DragHandle
            index={index}
            icon={CubeIcon}
            showIcon={showIcon}
            disabledDraggable={dragDisabled || disableInputs}
          />
        </Box>
        <Box
          className="detailsBox"
          // @ts-ignore added because of ref
          ref={blockRef}
          minWidth={minWidth}
          position="relative"
        >
          <Box display="flex">
            <Box className="addressSection" flexGrow="1" marginBottom="17px">
              <BodyBigText display="inline">
                <b>{title}</b>
              </BodyBigText>
              {showLaborType && (
                <BodyText display="inline" component="span">
                  <b>{titleSuffix}</b>
                </BodyText>
              )}
              <Box width="200px" display="flex" mb={1}>
                <Box width="165px" mr={1}>
                  <GoogleAutoComplete
                    country={country}
                    onChangeAddress={handleChangeAddressWithGoogleAutocomplete}
                    renderInput={(inputRef) => (
                      <TextInput
                        data-testid={`street-${index}`}
                        fullWidth
                        className={classes.addressInput}
                        placeholder="Street"
                        InputProps={{ disableUnderline: true, inputRef: inputRef }}
                        name={streetAddressPath}
                        onBlur={onBlurAndSaveWithTimeout}
                        disabled={disableInputs}
                      />
                    )}
                  />
                </Box>
                <Box width="50px">
                  <TextInput
                    InputProps={{ disableUnderline: true }}
                    className={classes.addressInput}
                    name={apartmentAddressPath}
                    placeholder="Apt#"
                    onBlur={onBlurAndSaveWithTimeout}
                    disabled={disableInputs}
                  />
                </Box>
              </Box>
              <Box width="200px">
                <BaseAddressInputs
                  fieldNames={addressFieldNames}
                  name={fullAddressPath}
                  hiddenLabel
                  disableUnderline
                  direction={Direction.COLUMN}
                  className={classes.addressInput}
                  spacing={1}
                  disabled={disableInputs}
                  onChangeCity={onChangeCity}
                  onChangePostalCode={onChangePostalCode}
                />
              </Box>
            </Box>
            <Box className="actionSection print-remove" display="flex" flexDirection="column" alignItems="center">
              {!disableInputs && (
                <IconButton size="small" onClick={onSetAddressIndex(index)} disabled={disableInputs}>
                  <BluePenIcon />
                </IconButton>
              )}
              {!disableInputs && !startEndTitles.includes(title) && !isSelectedAllServices && (
                <IconButton size="small" onClick={onDelete}>
                  <BlueDeleteIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box className="detailsSection">
            {!!propertyTypeOptions.length && (
              <Box width="200px" mb={1}>
                <Select
                  onBlur={onBlurAndSaveWithTimeout}
                  name={propertyTypePath}
                  placeholder="Property Type"
                  className={classes.select}
                  InputProps={{ disableUnderline: true }}
                  defaultValue={null}
                  SelectProps={{ displayEmpty: true }}
                  disabled={disableInputs}
                >
                  <MenuItem key={0} value={null!} onClick={onChangePropertyType(null)}>
                    Select property type
                  </MenuItem>
                  {propertyTypeOptions.map((pt) => (
                    <MenuItem key={pt.id} value={pt.name} onClick={onChangePropertyType(pt)}>
                      {pt.name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
            <Box mb={1} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 0, left: '-26px' }}>
                <DoorIcon color="primary" fontSize="inherit" />
              </Box>
              {!!elevationMap[data.elevationId as number] && (
                <Select
                  className={classes.select}
                  value={data.elevationId}
                  name={ENTRANCE_TYPE}
                  skipControl
                  onChange={handleChangeWaypoint}
                  InputProps={{ disableUnderline: true }}
                  options={entranceOptions || []}
                  disabled={disableInputs}
                />
              )}
            </Box>
            <Box mb={1} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 0, left: '-26px' }}>
                <DirectionsWalkIcon color="primary" fontSize="inherit" />
              </Box>
              <Select
                className={classes.select}
                value={data.parkingToEntranceSpeedReductionId || 0}
                name={PARKING_DISTANCE}
                skipControl
                onChange={handleChangeWaypoint}
                disabled={disableInputs}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem disabled key={0} value={0}>
                  Select distance from/to parking
                </MenuItem>
                {parkingDistanceOptions?.map(([key, value]: [key: string, value: string]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box mb={1} sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', top: 0, left: '-26px' }}>
                <ParkingIcon color="primary" fontSize="inherit" />
              </Box>
              <Select
                className={classes.select}
                value={data.parkingType || 0}
                name={PARKING_TYPE}
                skipControl
                onChange={handleChangeWaypoint}
                disabled={disableInputs}
                InputProps={{ disableUnderline: true }}
              >
                <MenuItem disabled key={0} value={0}>
                  Select parking
                </MenuItem>
                {ParkingTypeOptions.map(([key, value]) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box ml={-1} mb={0.5} sx={{ position: 'relative' }} className={classes.cursorPointer}>
              <Box sx={{ position: 'absolute', top: 5, left: -18 }}>
                <COIIcon color="primary" fontSize="inherit" />
              </Box>
              <Box padding="2px 8px">
                <Button
                  variant="text"
                  disabled={isCompleted && !data.requiresCoi}
                  color="primary"
                  className={clsx(classes.button, {
                    [classes.bolderText]: data.requiresCoi,
                    //need duplicate it, because cannot change main style
                    [classes.initialText]: !data.requiresCoi,
                  })}
                  onClick={handleOpenModal(Specification.COI)}
                >
                  {data.requiresCoi ? 'Requires COI' : 'No COI required'}
                </Button>
              </Box>
            </Box>
            <Box ml={-1} mb={0.5} sx={{ position: 'relative' }} className={classes.cursorPointer}>
              <Box sx={{ position: 'absolute', top: 5, left: -18 }}>
                <TimeWindowRestrictionIcon color="primary" fontSize="inherit" />
              </Box>
              <Box padding="2px 8px">
                <Button
                  variant="text"
                  disabled={isCompleted && !data.accessRestrictionApplies}
                  color="primary"
                  onClick={handleOpenModal(Specification.TWR)}
                  className={clsx(classes.button, {
                    [classes.bolderText]: data.accessRestrictionApplies,
                    //need duplicate it, because cannot change main style
                    [classes.initialText]: !data.accessRestrictionApplies,
                  })}
                >
                  {data.accessRestrictionApplies ? 'Time Window Restriction' : 'No time Window Restriction'}
                </Button>
              </Box>
            </Box>
          </Box>
          <ServiceWaypointNumber index={data.serviceIndex} />
        </Box>
      </SortableElement>
      {!(isUnloadingService(serviceType) && showLastAddStop) && !isDestination && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <DistanceBox disabled={disabled} display="flex" flexDirection="column" alignItems="center" alignSelf="center">
            {/*@ts-ignore added because styled component*/}
            <IconBlueFab
              size="small"
              iconSize="16px"
              buttonSize="20px"
              disableBoxShadow
              onClick={onSetAddressIndex(getNewAddressIndex(data.waypointIndex))}
              className="print-remove"
              disabled={disableInputs}
            />
            <BlueRightArrorIcon />
            {distanceFromPrevious && <BodySmallText>{distanceFromPrevious}</BodySmallText>}
            {nextWaypoint?.travelDurationFromPrevious?.humanReadable ? (
              <BodySmallText>{nextWaypoint?.travelDurationFromPrevious?.humanReadable}</BodySmallText>
            ) : (
              <BodySmallText>{durationFromPrevious}</BodySmallText>
            )}
            {isMovingAndStorageService(serviceType) && (
              <SharedIconButton color="primary" onClick={handleSwapStorage}>
                <SwapHorizIcon className={classes.swapStorageIcon} />
              </SharedIconButton>
            )}
          </DistanceBox>
        </Box>
      )}
      <CertificateOfInsuranceModal
        open={isOpenCOIModal}
        onSave={handleSaveCOI}
        onCancel={handleCancel}
        data={data.toJS() as Waypoint}
      />
      <TimeWindowRestriction
        open={isOpenTWRModal}
        onSave={handleSaveTWR}
        onCancel={handleCancel}
        data={data.toJS() as Waypoint}
      />
    </FormProvider>
  );
};
export default AddressBlock;
