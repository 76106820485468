import React, { useState } from 'react';

import {
  BodyBigText,
  BodyText,
  Button,
  JobCrewDto,
  JobType,
  Tab,
  TabPanel,
  Tabs,
  formatCurrency,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { List } from 'immutable';
import { useSelector } from 'react-redux';

import { getBasicEmployee } from 'admin/autodux/UsersAutodux';
import { useOrderServiceIndex, useOrderState, usePayrollContext } from 'admin/components/OrderWindow/context';
import Payroll from 'admin/components/OrderWindow/modals/Payroll';
import { useFetchUsers } from 'admin/hooks/useFetchUsers';
import { BasicEmployeeDto } from 'common-types';

const useStyles = makeStyles({
  button: {
    width: '160px',
  },
});

enum Modal {
  Payroll = 'Payroll',
}

interface CrewOrPayrollProps {
  type?: JobType;
}

const payrollLabels = {
  [JobType.PICK_UP]: 'Pickup Payroll',
  [JobType.DELIVERY]: 'Delivery Payroll',
};

export const CrewOrPayroll = ({ type = JobType.PICK_UP }: CrewOrPayrollProps) => {
  const classes = useStyles();
  const employees = useSelector(getBasicEmployee) as List<BasicEmployeeDto>;
  const [open, setOpen] = useState<Modal | null>(null);
  const [value, setValue] = useState(0);
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState();
  const { jobPayroll, deliveryJobPayroll } = usePayrollContext();
  const currentPayroll = type === JobType.PICK_UP ? jobPayroll : deliveryJobPayroll;
  const quote = order?.getServiceQuote(serviceIndex)!;
  const serviceType = order?.getServiceType(serviceIndex);
  const isLongDistance = isLongDistanceService(serviceType);

  const dispatchJob = quote.dispatchJob ?? order?.closingOrderDetail?.getServiceQuote(serviceIndex)?.dispatchJob;

  const crews = ((dispatchJob?.crews ?? []) as JobCrewDto[]).filter((c) => c.jobType === type);
  const toggleModal = (modal: Modal | null) => () => setOpen(modal);

  const payrollEmployeeIds = currentPayroll?.employeeJobPayrolls.map((emp) => emp.employeeId);
  const payrollEmployees = payrollEmployeeIds
    ?.map((id): BasicEmployeeDto | undefined => employees.find((it) => it.id === id))
    .filter(Boolean) as BasicEmployeeDto[];

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  useFetchUsers();

  const assignedTo = order?.assignedTo;
  const assignedSales = assignedTo ? employees.find((e) => e.id === assignedTo) ?? null : null;

  return (
    <>
      <Box position="relative">
        <Box position="absolute" top={-32} right={0}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            rounded
            onClick={toggleModal(Modal.Payroll)}
          >
            {isLongDistance ? payrollLabels[type] : 'Payroll'}
          </Button>
        </Box>
      </Box>
      <Box className="mt-1" />
      {!!assignedSales && !currentPayroll && (
        <Box display="flex" alignItems="center">
          <BodyText className="mb-h">
            <Box component="span" className="ml-h">
              <b>Sales: </b>
              {assignedSales.fullName}
            </Box>
          </BodyText>
        </Box>
      )}
      {!!currentPayroll ? (
        <>
          {currentPayroll.employeeJobPayrolls.map((payroll) => {
            const currentEmployee = payrollEmployees?.find((el) => el?.id === payroll.employeeId);
            return (
              <Box mb={1}>
                <BodyBigText>{`${payroll.positionType}: ${currentEmployee?.fullName} $${formatCurrency(
                  payroll.total,
                )}`}</BodyBigText>
              </Box>
            );
          })}
        </>
      ) : (
        <>
          {!!crews.length && (
            <>
              <Tabs indicatorColor="primary" inheritLightBackground value={value} onChange={handleChange as any}>
                {crews.map((c, index) => (
                  <Tab key={c.crew.id} value={index} label={`Crew ${index + 1}`} />
                ))}
              </Tabs>
              <Box className="mt-1" />
              {crews.map((c, index) => (
                <TabPanel key={c.crew.id} value={value} index={index}>
                  {c.crew.members.map(({ employee, foreman, driver }) => {
                    let label = 'Helper:';

                    if (foreman) {
                      label = 'Foreman:';
                    } else if (driver) {
                      label = 'Driver:';
                    }

                    return (
                      <Box key={employee.id} display="flex" alignItems="center">
                        <BodyText className="mb-h">
                          <b>{label}</b>
                          <Box component="span" className="ml-h">
                            {employee.fullName}
                          </Box>
                        </BodyText>
                        <Box className="ml-h">{driver && <LocalShippingIcon fontSize="small" />}</Box>
                      </Box>
                    );
                  })}
                </TabPanel>
              ))}
            </>
          )}
          {!crews.length && !assignedSales && <BodyBigText>No crews assigned yet</BodyBigText>}
        </>
      )}
      {open === Modal.Payroll && <Payroll type={type} onSave={toggleModal(null)} onClose={toggleModal(null)} />}
    </>
  );
};
