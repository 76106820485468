import React from 'react';

import { Card, CardContent, CardHeader, makeStyles } from '@material-ui/core';
import pt from 'prop-types';

import { ForwardingNumberForm } from 'admin/components/Settings/components/SmsSettings/forms';

const useStyle = makeStyles(({ spacing }) => ({
  card: {
    maxWidth: '15rem',
  },
  cardTitle: {
    fontSize: '0.85rem',
    fontWeight: '600',
  },
  header: {
    padding: '10px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: `0 16px 10px !important`,
  },
  roundedCard: {
    '&.MuiCard-root': {
      borderRadius: spacing(0.75),
    },
  },
}));

export const ForwardingSettings = ({ isDisabledEnabledSms, isLoadingTwilioAccount, defaultValues }) => {
  const classes = useStyle();

  return (
    <Card className={classes.roundedCard} classes={{ root: classes.card }}>
      <CardHeader
        title="Call Forwarding Settings"
        classes={{ title: classes.cardTitle, content: classes.cardHeader, root: classes.header }}
      />
      <CardContent classes={{ root: classes.content }}>
        <ForwardingNumberForm
          isDisabledEnabledSms={isDisabledEnabledSms}
          isLoadingTwilioAccount={isLoadingTwilioAccount}
          defaultValues={defaultValues}
        />
      </CardContent>
    </Card>
  );
};

ForwardingSettings.propTypes = {
  isDisabledEnabledSms: pt.bool,
  isLoadingTwilioAccount: pt.bool,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValues: pt.object.isRequired,
};

ForwardingSettings.defaultProps = {
  isDisabledEnabledSms: false,
  isLoadingTwilioAccount: false,
};
