import { FC } from 'react';

import { MoveUnit, useCurrentDistanceUnits, useCurrentMoveSizeUnit } from '@elromcoinc/react-shared';

import { useOrderState } from 'admin/components/OrderWindow/context/useOrderState';

import { OrderSettingUnitsContext } from './OrderSettingUnitsContext';

export const OrderSettingUnitsProvider: FC = ({ children }) => {
  const { order } = useOrderState();
  const distanceUnits = useCurrentDistanceUnits();
  const orderMoveUnit = order?.moveUnit === 'CUBIC_FEET' ? MoveUnit.CUBIC_FEET : MoveUnit.POUNDS;
  const sizeUnits = useCurrentMoveSizeUnit({
    'gci.moveUnit': orderMoveUnit,
  });

  return (
    <OrderSettingUnitsContext.Provider value={{ ...distanceUnits, ...sizeUnits }}>
      {children}
    </OrderSettingUnitsContext.Provider>
  );
};
