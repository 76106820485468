import { SvgIcon } from '@material-ui/core';

import { IconProps } from 'common/components/icons/IconProps';

export const LoadingIcon = (props: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 512 512" fill="none" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width="512.000000"
        height="512.000000"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,512.000000) scale(0.10000,-0.10000)" fill="#000000" stroke="none">
          <path d="M940 4899 c-503 -59 -903 -482 -936 -989 -8 -111 2 -154 49 -206 48 -56 99 -77 173 -72 50 3 72 10 106 34 65 45 88 96 98 213 16 176 68 290 189 412 88 88 200 150 316 174 103 22 1656 22 1760 0 213 -44 403 -217 478 -435 l22 -65 3 -1237 2 -1238 -1182 0 c-1268 0 -1260 0 -1359 50 -54 28 -142 111 -173 164 -44 75 -56 138 -56 293 0 174 -10 219 -62 278 -100 113 -276 88 -343 -49 l-27 -56 5 -197 c5 -224 18 -286 87 -428 94 -193 287 -363 483 -426 l76 -24 -6 -95 c-18 -293 149 -578 411 -705 120 -58 205 -78 336 -78 288 0 539 158 665 418 54 112 75 205 75 334 l0 101 430 0 430 0 0 -101 c0 -129 21 -222 75 -334 97 -200 260 -335 480 -396 108 -30 267 -30 378 0 261 69 469 282 538 552 8 32 14 105 14 182 0 97 3 127 13 127 28 0 173 63 229 99 180 116 306 282 370 486 l28 90 3 630 c4 700 1 747 -54 915 -103 311 -334 550 -643 667 -137 52 -168 55 -500 62 l-314 6 -22 78 c-107 370 -409 655 -793 749 -85 21 -109 22 -932 24 -465 1 -879 -2 -920 -7z m3200 -1279 c103 -17 186 -48 265 -102 96 -64 167 -143 216 -243 58 -119 69 -175 69 -352 l0 -153 -530 0 -530 0 0 430 0 430 223 0 c122 0 251 -5 287 -10z m550 -1518 c0 -268 -6 -313 -56 -398 -31 -53 -119 -136 -173 -164 -85 -43 -154 -50 -503 -50 l-328 0 0 430 0 430 530 0 530 0 0 -248z m-2990 -1087 c20 -96 -11 -194 -85 -274 -64 -69 -128 -95 -230 -96 -68 0 -89 4 -137 28 -62 31 -116 84 -149 149 -26 50 -37 177 -20 221 l11 27 299 0 300 0 11 -55z m2341 28 c17 -44 6 -171 -20 -221 -33 -65 -87 -118 -149 -149 -77 -38 -190 -39 -269 -4 -129 58 -211 212 -183 346 l11 55 300 0 299 0 11 -27z" />
          <path d="M1391 3914 c-106 -53 -145 -182 -88 -289 8 -16 104 -118 211 -225 l196 -195 -783 -5 -783 -5 -42 -28 c-60 -39 -93 -94 -99 -164 -7 -80 31 -154 102 -198 l48 -30 781 -3 780 -3 -206 -207 c-114 -114 -212 -220 -218 -237 -14 -37 -15 -121 -1 -157 19 -50 79 -106 133 -124 57 -19 110 -14 166 15 21 11 184 167 364 348 299 300 330 334 359 398 54 114 53 252 -3 373 -28 62 -673 715 -733 743 -57 27 -121 24 -184 -7z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
