import React from 'react';

import { BodySmallText, Flag, IconButton, Link } from '@elromcoinc/react-shared';
import { Box, LinkProps, Tooltip, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { List } from 'immutable';

import Circle from 'common/components/Circle';

const useStyles = makeStyles((theme) => ({
  flagsBox: {
    maxWidth: '50px',
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
    },
  },
}));

interface FlagCellProps extends Pick<LinkProps, 'onClick'> {
  flags: List<Flag> | Flag[];
}

export const FlagCell = ({ onClick, flags }: FlagCellProps) => {
  const classes = useStyles();
  const size = (flags as List<Flag>)?.size || (flags as Flag[])?.length;

  return (
    <Link onClick={onClick}>
      <Box className={classes.flagsBox}>
        {!size && (
          <Tooltip title="Add flag(s)">
            <Box component="span">
              <IconButton color="primary">
                <AddIcon />
              </IconButton>
            </Box>
          </Tooltip>
        )}
        {size > 3
          ? flags.map((item) => (
              <Box key={item.id} pr={0.5} pb={0.5}>
                <Tooltip title={item.name} arrow>
                  <Circle className="circle" color={item.rgbColor} width="12px" height="12px" />
                </Tooltip>
              </Box>
            ))
          : flags.map((item) => (
              <Box key={item.id} color={item.rgbColor}>
                <BodySmallText key={item.id} color="inherit">
                  {item.name}
                </BodySmallText>
              </Box>
            ))}
      </Box>
    </Link>
  );
};
