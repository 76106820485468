import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { LinearDeterminate } from './LinearDeterminate';

const useStyles = makeStyles((theme) => ({
  estimatedBlock: {
    position: 'absolute',
    zIndex: 2,
    bottom: theme.spacing(1),
    height: theme.spacing(10),
    border: `1px solid #5DCE83`,
    borderRadius: 5,
    pointerEvents: 'none',
    boxSizing: 'border-box',
  },
}));

interface EstimatedBlockProps {
  start: number;
  width: number;
  actualWidth: number;
  widthCoefficient: number;
  isTimeDone: boolean;
}

export const EstimatedBlock = ({
  widthCoefficient,
  start,
  width: widthInPixels,
  actualWidth: actualWidthInPixels,
  isTimeDone,
}: EstimatedBlockProps) => {
  const classes = useStyles();
  const offset = start * widthCoefficient;
  const width = widthInPixels * widthCoefficient;
  const actualWidth = actualWidthInPixels > widthInPixels ? width : actualWidthInPixels * widthCoefficient;

  return (
    <>
      <Box left={offset} width={width} className={clsx(classes.estimatedBlock)}>
        {!!actualWidth && <LinearDeterminate dontShowBuffer={isTimeDone} value={(actualWidth / width) * 100} />}
      </Box>
    </>
  );
};
