import React, { FC, FormEventHandler, useState } from 'react';

import { Modal, ModalProps, Tab, TabPanel, Tabs } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';

import { AccountUsersTab } from './components/AccountUsers/AccountUsersTab';
import { BillingHistoryTab } from './components/BillingHistory/BillingHistoryTab';

interface PlanSettingsModalProps {
  alertProps: ModalProps;
}

const PLAN_SETTINGS_TABS = [
  { label: 'Account Users', value: 0, type: 'accountUsers' },
  { label: 'Billing Info', value: 1, type: 'billingInfo' },
];

function a11yProps(index: string | null) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PlanSettingsModal: FC<PlanSettingsModalProps> = ({ alertProps }) => {
  const [tab, setTab] = useState<number>(0);

  const handleChangeTab = (event: FormEventHandler<HTMLButtonElement>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Modal title="Plan Settings" {...alertProps} maxWidth="sm">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Box pb="25px">
          <Tabs indicatorColor="primary" value={tab} onChange={handleChangeTab as any} inheritLightBackground>
            {PLAN_SETTINGS_TABS.map(({ label, type, value }) => (
              <Tab key={type} value={value} label={label} {...a11yProps(type)} />
            ))}
          </Tabs>
        </Box>
        <TabPanel value={tab} index={0}>
          <AccountUsersTab />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <BillingHistoryTab />
        </TabPanel>
      </Box>
    </Modal>
  );
};

export { PlanSettingsModal };
