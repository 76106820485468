import React from 'react';

import { BodyText, Order } from '@elromcoinc/react-shared';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

import { useOrderServiceIndex, useOrderState } from 'admin/components/OrderWindow/context';

const useStyles = makeStyles<Theme>(({ palette }) =>
  createStyles({
    costBreakdownItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    depositReceived: {
      color: palette.success.main,
    },
  }),
);

export const ClosingDeposit = () => {
  const { serviceIndex } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.costBreakdownItem, classes.depositReceived)}>
        <BodyText>Deposit Received</BodyText>
        <BodyText>{order?.orderBillOfLadingDetail?.getDepositReceived(serviceIndex).asHumanReadableString()}</BodyText>
      </Box>
    </Grid>
  );
};
