import React from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip, makeStyles } from '@material-ui/core';
import pt from 'prop-types';

import {
  LogoAmericanExpress,
  LogoDinersClub,
  LogoDiscover,
  LogoJCB,
  LogoMastercard,
  LogoUnionPay,
  LogoVisa,
} from 'common/components/icons';

const useStyles = makeStyles({
  listItem: {
    minWidth: '2rem',
  },
});

const typeCardValue = {
  visa: 'Visa',
  amex: 'American Express',
  mastercard: 'Mastercard',
  discover: 'Discover',
  jcb: 'JCB',
  unionpay: 'Union Pay',
  diners: 'Diners Club',
};

const cardTypeIcon = {
  visa: <LogoVisa />,
  amex: <LogoAmericanExpress />,
  mastercard: <LogoMastercard />,
  discover: <LogoDiscover />,
  jcb: <LogoJCB />,
  unionpay: <LogoUnionPay />,
  diners: <LogoDinersClub />,
};

export const ExistCardsList = ({ firstPaymentMethod }) => {
  const classes = useStyles();

  return (
    <List disablePadding>
      {!!firstPaymentMethod && (
        <Tooltip title={typeCardValue[firstPaymentMethod.cardType]} placement="top-start">
          <ListItem dense component="li" key={firstPaymentMethod.paymentMethodId}>
            <ListItemIcon classes={{ root: classes.listItem }}>
              {cardTypeIcon[firstPaymentMethod.cardType.toLowerCase()]}
            </ListItemIcon>
            <ListItemText primary={<BodyText>{`*****${firstPaymentMethod.last4}`}</BodyText>} />
          </ListItem>
        </Tooltip>
      )}
    </List>
  );
};

ExistCardsList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  firstPaymentMethod: pt.object.isRequired,
};
