import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import {
  DatePicker,
  GeneralService,
  Modal,
  MoveTypeTypes,
  Order,
  OverridableValue,
  QuoteRequest,
  Select,
  ServiceType,
  SettingNames,
  Waypoint,
  WaypointList,
  toDate,
} from '@elromcoinc/react-shared';
import { Grid } from '@material-ui/core';
import { addDays, max } from 'date-fns';
import { List } from 'immutable';
import { OrderWindowSettings } from 'redux-conf/orderWindowSettings/orderWindowSettings-state';

import { useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import GeneralServiceTypes from 'admin/constants/GeneralServiceTypes';
import { getRates } from 'admin/selectors';

const allowedServiceTypes = [
  ServiceType.LOCAL_MOVING,
  ServiceType.LOADING_HELP,
  ServiceType.UNLOADING_HELP,
  ServiceType.PACKING_DAY,
];

const isServiceAllowed = (service: GeneralService) => allowedServiceTypes.indexOf(service.serviceType) > -1;

interface AddServiceProps {
  onClose: () => void;
  onAdd: (value: QuoteRequest) => void;
}

function getServiceTypes(settings?: OrderWindowSettings) {
  return (
    settings?.[SettingNames.GENERAL_SERVICES]?.filter(
      (service) => service.serviceType !== GeneralServiceTypes.JUNK_REMOVAL,
    ) || []
  );
}

function getFutureDateForOrder(order: Order | null) {
  if (!order) {
    return new Date();
  }
  return addDays(
    order.services
      .map(({ date }) => toDate(date) as Date)
      .filter(Boolean)
      .reduce<Date>((dateA, dateB) => max([dateA, dateB])) || new Date(),
    1,
  );
}

const getQuoteRequestForNewService = (order: Order): QuoteRequest => {
  const {
    orderId,
    orderNumber,
    serviceId,
    companyId,
    estimateType,
    moveType,
    moveSize,
    sizeDescription,
    waypointList: { waypoints },
  } = order.getQuoteRequestForService(0);
  const moveDate = getFutureDateForOrder(order);
  const waypointList = new WaypointList({
    waypoints: waypoints
      .filter(Waypoint.isCustomerStop)
      .map((wp: Waypoint) => wp.clearInventory().set('id', null).set('entityId', null)),
  });
  return new QuoteRequest({
    orderId,
    companyId,
    customerInfo: null,
    estimateType,
    moveType,
    moveSize,
    moveDate,
    orderNumber,
    serviceId,
    sizeDescription,
    numberOfMovers: null,
    numberOfTrucks: null,
    travelRate: new OverridableValue(),
    laborRate: new OverridableValue(),
    waypointList,
  });
};

function filterWaypointsForService(waypoints: List<Waypoint>, serviceType: any) {
  if (waypoints && waypoints.size > 0) {
    switch (serviceType || '') {
      case 'LOADING_HELP':
      case 'PACKING_DAY':
        return waypoints.filter((wp) => wp.laborType === 'LOADING');
      case 'UNLOADING_HELP':
        return waypoints.filter((wp) => wp.laborType === 'UNLOADING');
      default:
        return waypoints;
    }
  }
  return waypoints;
}

function prependMissingWaypoint(waypoints: List<Waypoint>) {
  return (waypoints.size === 1 ? waypoints.push(waypoints.get(0)!) : waypoints)
    .setIn([0, 'laborType'], 'LOADING')
    .setIn([1, 'laborType'], 'UNLOADING');
}

const AddService: FC<AddServiceProps> = ({ onClose, onAdd }) => {
  const { order } = useOrderState();
  const [request, setRequest] = useState<QuoteRequest>(getQuoteRequestForNewService(order!));
  const settings = useOrderWindowSettings();
  const serviceTypes = useMemo(() => getServiceTypes(settings), [settings]);
  const serviceTypeOptions: [string, string][] = useMemo(
    () => serviceTypes.filter(isServiceAllowed).map(({ id, name }) => [`${id}`, name]),
    [serviceTypes],
  );
  const rates = getRates(settings)[order?.moveType as MoveTypeTypes] || {};

  const handleOnAdd = () => {
    const addServiceRequest = request.setIn(
      ['waypointList', 'waypoints'],
      filterWaypointsForService(
        prependMissingWaypoint(request.waypointList.waypoints),
        serviceTypes.filter((it) => it.id === request.serviceId)[0]?.serviceType,
      ).map((w, index) => w.set('sequenceOrder', index + 1)),
    );
    onAdd(addServiceRequest);
    onClose();
  };

  return (
    <Modal
      open
      color="grey"
      actions={[
        { label: 'cancel', onClick: onClose, color: 'default' },
        { label: 'add', onClick: handleOnAdd },
      ]}
      hideActionControls={false}
      onClose={onClose}
      title="Add Service"
      maxWidth="xs"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            rates={rates}
            fullWidth
            autoOk
            okLabel=""
            cancelLabel="Cancel"
            minDateMessage=""
            label="Service Date"
            showCalendarIcon={false}
            value={request.moveDate}
            onChange={(value) => setRequest((q) => q.set('moveDate', value as Date))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            label="Service Type"
            value={request.serviceId}
            onChange={({ target: { value } }: ChangeEvent<HTMLSelectElement>) =>
              setRequest((q) => q.set('serviceId', +value))
            }
            options={serviceTypeOptions}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export { AddService };
