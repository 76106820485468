import { createContext, Dispatch, SetStateAction } from 'react';
import { Template } from 'common-types';

interface SendingTemplatesContextProps {
  sendingTemplates: Template[];
  setSendingTemplates: Dispatch<SetStateAction<Template[]>>;
}

const SendingTemplatesContext = createContext<SendingTemplatesContextProps>({} as SendingTemplatesContextProps);

export { SendingTemplatesContext };
