import React, { useState } from 'react';

import {
  Link,
  Order,
  Valuation as ValuationEntity,
  ValuationType,
  formatCurrency,
  getValuationSetting,
  isLongDistanceService,
} from '@elromcoinc/react-shared';
import { TableCell } from '@material-ui/core';

import { HighlightedTableRow } from 'admin/components/OrderWindow/blocks/QuoteDetails/HighlightedTableRow';
import { Text } from 'admin/components/OrderWindow/components';
import {
  useOrderChangeSet,
  useOrderClosingContext,
  useOrderServiceIndex,
  useOrderState,
  useOrderWindowSettings,
} from 'admin/components/OrderWindow/context';
import ValuationModal from 'admin/components/OrderWindow/modals/Valuation';
import { ZERO_MONEY } from 'admin/constants/DefaultValues';

const valuationLabel = 'Valuation';

enum Modals {
  VALUATION = 'VALUATION',
}

const defaultTitleBasicValuation = '60 cents per pound';

export const Valuation = () => {
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();
  const { order } = useOrderState() as { order: Order };
  const { onChange } = useOrderChangeSet();
  const settings = useOrderWindowSettings();
  const service = order.services.get(serviceIndex);
  const isLongDistance = isLongDistanceService(service.getType());
  const titleBasicValuation = getValuationSetting(
    settings,
    isLongDistance,
    order.moveType,
    'titleBasicValuation',
    defaultTitleBasicValuation,
  );
  const titleThirdOption = getValuationSetting(settings, isLongDistance, order.moveType, 'titleThirdOption');
  const [openModal, setOpenModal] = useState<Modals | null>(null);
  const { isClosing } = useOrderClosingContext();

  const toggleOpenModal = (modal: Modals | null) => () => setOpenModal(modal);
  const handleOrderChange = (name: string, value: any) => {
    onChange({ name, value });
    setOpenModal(null);
  };
  const handleValuationChange = (value: ValuationEntity) =>
    handleOrderChange(isClosing ? 'closingOrderDetail.valuation' : 'valuation', value);
  function getValuationValue(titleForBasicValuation: string) {
    const valuation = (isClosing ? order.closingOrderDetail : order)?.valuation;

    if (ValuationType.FULL === valuation.type) {
      return `$${formatCurrency(valuation.cost)}`;
    }

    if (ValuationType.THIRD_OPTION === valuation.type) {
      return titleThirdOption;
    }

    return titleForBasicValuation || defaultTitleBasicValuation;
  }

  return (
    <HighlightedTableRow data-testId="valuation">
      <TableCell size="small" padding="none">
        <Link disabled={isSelectedAllServices} onClick={toggleOpenModal(Modals.VALUATION)}>
          {valuationLabel}
        </Link>
        {Modals.VALUATION === openModal && (
          <ValuationModal
            open
            onSave={handleValuationChange}
            onCancel={toggleOpenModal(null)}
            orderId={order.orderId}
            moveType={order.moveType}
            isLocal={!isLongDistance}
            valuation={(isClosing ? order.closingOrderDetail : order)?.valuation}
          />
        )}
      </TableCell>
      <TableCell>
        <Text value={getValuationValue(titleBasicValuation || ZERO_MONEY)} data-testId={'valuationValue'} />
      </TableCell>
    </HighlightedTableRow>
  );
};
