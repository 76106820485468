import React, { useCallback, useEffect } from 'react';

import {
  BodyBigText,
  PaymentCreditCardRecordDto,
  PaymentStatus,
  cardExpirationYUP,
  toBackEndDate,
  toDate,
  zipCodeAsyncYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { format, formatISO } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';
import { string } from 'yup';

import paymentActionsApi from 'admin/api/PaymentActionsApi';

import { cashScema } from '../CreateCharge/CashPayment';
import {
  AMOUNT,
  CARDHOLDER_NAME,
  EXPIRATION,
  LAST_FOUR_DIGITS,
  NOTES,
  PAID_DATE,
  POSTAL_CODE,
  labels,
} from '../CreateCharge/Common';
import { CreditCardRecordPaymentFormInputs } from '../Forms';
import { RefundCashPaymentDto } from './RefundCashPayment';

export interface RefundCreditCardRecordDto extends RefundCashPaymentDto {
  'cc-name': string;
  lastFourDigits: string;
  postalCode: string;
  'cc-exp': string;
}

const schema = cashScema.shape(
  {
    [CARDHOLDER_NAME]: string().label(labels[CARDHOLDER_NAME]).required(),
    [LAST_FOUR_DIGITS]: string().when(LAST_FOUR_DIGITS, (lastFourDigits, _schema) =>
      lastFourDigits?.length > 0
        ? _schema.label(labels[LAST_FOUR_DIGITS]).required().min(4).max(4)
        : _schema.optional(),
    ),
    [POSTAL_CODE]: string().when(POSTAL_CODE, (postalCode, _schema) =>
      postalCode?.length > 0 ? zipCodeAsyncYUP(POSTAL_CODE).label(labels[POSTAL_CODE]) : _schema.optional(),
    ),
    [EXPIRATION]: cardExpirationYUP({ label: labels[EXPIRATION] }),
  },
  [
    [POSTAL_CODE, POSTAL_CODE],
    [LAST_FOUR_DIGITS, LAST_FOUR_DIGITS],
  ],
);

interface EditCreditCardRecordProps {
  payment: PaymentCreditCardRecordDto;
  onMount?: (trigger: () => Promise<any>) => void;
}

const RefundCreditCardRecord = ({ payment, onMount }: EditCreditCardRecordProps) => {
  const methods = useForm<RefundCreditCardRecordDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      [AMOUNT]: payment.amount as number,
      [PAID_DATE]: new Date(),
      [CARDHOLDER_NAME]: payment.cardholderName,
      [LAST_FOUR_DIGITS]: payment.lastFourDigits,
      [POSTAL_CODE]: payment.postalCode,
      [EXPIRATION]: payment?.expiration ? format(toDate(payment.expiration)!, 'MM/yy') : '',
    },
  });

  const createPayment = useCallback(
    () =>
      new Promise((resolve, reject) => {
        methods.handleSubmit((data: RefundCreditCardRecordDto) => {
          paymentActionsApi
            .createCreditCardRecordPayment({
              ...payment,
              date: formatISO(data[PAID_DATE]),
              amount: data[AMOUNT],
              message: data[NOTES],
              status: PaymentStatus.REFUND,
              postalCode: data[POSTAL_CODE],
              cardholderName: data[CARDHOLDER_NAME],
              lastFourDigits: data[LAST_FOUR_DIGITS],
              expiration: toBackEndDate(toDate(`15/${data[EXPIRATION]}`, 'dd/MM/yy'))!,
            })
            .then(resolve)
            .catch(reject);
        })();
      }),
    [methods.handleSubmit],
  );

  useEffect(() => {
    onMount?.(createPayment);
  }, [createPayment]);

  return (
    /** @ts-ignore */
    <FormProvider {...methods}>
      <Box mb={1} p={1} borderRadius={4} bgcolor="rgb(255 120 63 / 15%)">
        <BodyBigText color="secondary">
          <b>Note: </b>This is to record your payment only, and will NOT process any electronic payments. If you wish to
          process a credit card payment, please use “Credit Online.”
        </BodyBigText>
      </Box>
      <Grid container spacing={2}>
        <CreditCardRecordPaymentFormInputs />
      </Grid>
    </FormProvider>
  );
};

export { RefundCreditCardRecord };
