import { Truck } from '@elromcoinc/react-shared';
import axios from 'axios';
import { format } from 'date-fns';

import Job from 'admin/components/OrderWindow/SchedulerBox/Job';

const DATE_ISO_FORMAT = 'yyyy-MM-dd';

interface TruckAvailability {
  UNAVAILABLE: string[];
  BUSY: string[];
}

class TruckApi {
  getTrucks() {
    return axios.get<Truck[], Truck[]>('/api/moving/trucks');
  }

  saveTruck(truck = {}) {
    return axios.put('/api/moving/truck/', truck);
  }

  createUnassigned() {
    return axios.post('/api/moving/trucks/unassigned', {});
  }

  deleteUnassigned() {
    return axios.delete('/api/moving/trucks/unassigned');
  }

  getJobs(startDate: Date, endDate: Date) {
    const controller = new AbortController();
    const signal = controller.signal;
    const start = format(startDate || new Date(), DATE_ISO_FORMAT);
    const end = format(endDate || new Date(), DATE_ISO_FORMAT);
    return {
      promise: axios.get<Job[], Job[]>(`/api/moving/jobsCalendar/jobs/startDate/${start}/endDate/${end}`, {
        params: { signal },
      }),
      cancel: () => controller.abort(),
    } as CancelablePromise<Job[]>;
  }

  setAssignedTrucks(orderId: number, serviceDate: string, truckIds: number[] = [], deliveryTrucks = false) {
    return axios.put(
      `/api/moving/jobsCalendar/job/order/${orderId}/serviceDate/${serviceDate}/trucks/assigned`,
      {},
      { params: { deliveryTrucks, truckIds: truckIds.join(',') } },
    );
  }

  truckUnavailableDatesForGivenDateRange(truckId: number, startDate: string, endDate: string) {
    return axios.get<TruckAvailability, TruckAvailability>(
      `/api/moving/truckUnavailabilityCalendar/unavailableDates/truck/${truckId}/startDate/${startDate}/endDate/${endDate}/`,
    );
  }

  trucksUnavailableDatesForGivenDateRange(startDate: string, endDate: string) {
    return axios
      .get<TruckAvailability[], { [key: string]: number[] }>(
        `/api/moving/truckUnavailabilityCalendar/unavailableDates/startDate/${startDate}/endDate/${endDate}/`,
      )
      .then((response) => {
        return Object.entries(response).reduce((accumulator, [dates, value]) => {
          return value.reduce((acc, truckId) => {
            return {
              ...acc,
              [truckId]: [...(acc[truckId] || []), dates],
            };
          }, accumulator);
        }, {} as { [key: string]: string[] });
      });
  }

  setTruckUnavailableDateForGivenDate(truckId: number, dates: string[]) {
    return axios.put(`/api/moving/truckUnavailabilityCalendar/unavailableDates/truck/${truckId}/dates/`, dates);
  }

  deleteTruckUnavailableDateForGivenDate(truckId: number, dates: string[]) {
    return axios.delete(`/api/moving/truckUnavailabilityCalendar/unavailableDates/truck/${truckId}/dates/`, {
      data: dates,
    });
  }

  setScheduleService(
    serviceId: number,
    startTimeEarliest: string,
    startTimeLatest: string,
    truckIds: number[] = [],
    deliveryTrucks = false,
  ) {
    return axios.put(
      `/api/moving/order/service/${serviceId}/schedule/startTimeEarliest/${startTimeEarliest}/startTimeLatest/${startTimeLatest}`,
      truckIds,
      { params: { deliveryTrucks } },
    );
  }
}

export default new TruckApi();
