import React from 'react';
import { HeaderText } from '@elromcoinc/react-shared';

const SearchList = ({ list, ItemComponent, title, onClick }) => {
  if (!list || !list.length) {
    return null;
  }

  return (
    <>
      <HeaderText>{title}</HeaderText>
      {list.map(item => (
        <ItemComponent key={item.id || item.orderId} item={item} onClick={onClick(item)} />
      ))}
    </>
  );
};

export default SearchList;
