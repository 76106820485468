import { MoveTypeTypes, MoveType } from '@elromcoinc/react-shared';

const moveTypes = Object.values(MoveType);

interface CommonSetting {
  id: number | null;
  moveType: MoveTypeTypes;
}

const copySettingsToOtherMoveTypes = (settings: CommonSetting[], moveType: MoveTypeTypes): CommonSetting[] => {
  const otherMoveTypes = moveTypes.filter((mt) => mt !== moveType);
  const currentMoveTypeSettings = settings.filter((setting) => setting.moveType === moveType);
  const updatedSetting = [
    ...currentMoveTypeSettings,
    ...otherMoveTypes
      .map((otherMoveType) => {
        return currentMoveTypeSettings.map((item) => {
          return {
            ...item,
            id: null,
            moveType: otherMoveType,
          } as CommonSetting;
        });
      })
      .reduce<CommonSetting[]>((accumulator, item) => [...accumulator, ...item], []),
  ];
  return updatedSetting;
};

export { copySettingsToOtherMoveTypes };
