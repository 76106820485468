import React from 'react';

import { Button, Modal, RoleName, TitleText, hasAnyRole, isAdminRole, useCurrentUser } from '@elromcoinc/react-shared';
import { Box } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { logout } from 'admin/autodux/AuthAutodux';
import { managersPositions } from 'admin/constants/PositionTypes';

const canSeeApp = (user) =>
  hasAnyRole(
    user,
    RoleName.ROLE_ELROMCO_ADMIN,
    RoleName.ROLE_ELROMCO_USER,
    RoleName.ROLE_COMPANY_ADMIN,
    RoleName.ROLE_COMPANY_EMPLOYEE,
  );

const HaveNoPermissionsModal = () => {
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const canLoginByPositionType =
    managersPositions.some((role) => user?.positionTypes.includes(role)) || isAdminRole(user?.role);

  const handleLogout = () => {
    dispatch(logout());
  };

  if (!user || (canSeeApp(user) && canLoginByPositionType)) {
    return null;
  }

  return (
    <Modal open color="primary">
      <Box mt={2} mb={4}>
        <TitleText align="center" paragraph>{`Hi ${user.getFullName()}. You have no permissions.`}</TitleText>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button onClick={handleLogout} color="primary" rounded>
          Logout
        </Button>
      </Box>
    </Modal>
  );
};

export { HaveNoPermissionsModal };
