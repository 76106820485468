import axios from 'axios';

const autoAssignmentAPI = '/api/moving/autoassignment';

class AutoAssignmentAPI {
  getCosts() {
    return axios.get(`${autoAssignmentAPI}/costs/`);
  }

  getSizes() {
    return axios.get(`${autoAssignmentAPI}/sizes/`);
  }

  getLeadSources() {
    return axios.get(`${autoAssignmentAPI}/leadSources/`);
  }

  getAllAutoAssignment(id) {
    return axios.get(`${autoAssignmentAPI}/employee/${id}/`);
  }

  updateAllAutoAssignment(id, data) {
    return axios.put(`${autoAssignmentAPI}/employee/${id}/`, data);
  }
}

export default new AutoAssignmentAPI();
