import React, { FC } from 'react';

import { AuthorizedImage, BodySmallText, BodyText, IconButton, Message, MessageTypes } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';

import { MessageActivityLog } from 'admin/components/OrderWindow/blocks/ActivityManager/MessageActivityLog';
import OrderLog from 'admin/entities/OrderLog';
import { CommunicationModality } from 'common-types';

const useStyles = makeStyles((theme) => ({
  listItem: {
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    padding: '8px 8px 8px 16px',
  },
  note: {
    display: 'flex',
    width: '65%',
    marginRight: theme.spacing(2.5),
  },
  imageActivity: {
    maxWidth: 300,
  },
}));

interface ActivityItemProps {
  desc?: string;
  label: string;
  hiddenContent?: string;
  type?: CommunicationModality | MessageTypes;
  isAvailableToEdit?: boolean;
  item?: OrderLog | null;
  onEdit?: () => void;
}

const ActivityItem: FC<ActivityItemProps> = ({
  label,
  desc = null,
  type,
  hiddenContent = null,
  item = null,
  isAvailableToEdit = false,
  onEdit = null,
}) => {
  const classes = useStyles();

  const isEmailActivityLog = type === CommunicationModality.EMAIL;

  const isMessage =
    type === MessageTypes.IN_APP ||
    type === MessageTypes.SMS ||
    type === MessageTypes.SMS_NOTIFICATION ||
    type === MessageTypes.EMAIL;

  const isImage = !!item?.image;

  let justifyNote;

  if (item && !isImage) {
    justifyNote = item?.createdByCustomer ? 'flex-start' : 'flex-end';
  }

  return (
    <Box className={clsx(classes.listItem)} position="relative">
      {isMessage ? (
        <MessageActivityLog message={item?.activity as unknown as Message} />
      ) : (
        <Box display="flex" flexGrow={1} justifyContent={justifyNote}>
          <Box className={clsx({ [classes.note]: !!item })}>
            {isImage && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <AuthorizedImage
                  imageId={item?.id!}
                  imageProps={{ className: classes.imageActivity, alt: 'MMS image' }}
                />
              </Box>
            )}
            <Box flexGrow={1}>
              <BodyText wordBreak>{label}</BodyText>
              {!!desc && (
                <Box display="flex" alignItems="center">
                  <BodySmallText>{desc}</BodySmallText>
                </Box>
              )}
              {hiddenContent && !isEmailActivityLog && (
                <Box pt="5px" pb="10px">
                  <BodyText wordBreak>{hiddenContent}</BodyText>
                </Box>
              )}
            </Box>
            {isAvailableToEdit && onEdit && (
              <Box>
                <IconButton color="primary" onClick={onEdit!}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ActivityItem;
