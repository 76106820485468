import React from 'react';

import {
  BodyText,
  CreditCardType,
  Modal,
  cardTypeIcon,
  getCardType,
  toBackEndDate,
  useAuthorize,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import { FormProvider, useForm } from 'react-hook-form';

import paymentActionsApi, { CustomerPaymentProfileDto } from 'admin/api/PaymentActionsApi';
import authorizeConfig from 'admin/config/Authorize';

import { usePaymentClientKey } from '../../../context';
import { convertExpirationStringToDate } from '../utils';
import { CARDHOLDER_NAME, CARD_NUMBER, CCV_CODE, EXPIRATION, addCreditCard } from './Common';
import CreditCardForm from './CreditCardForm';
import { CreditCardOnlineForm } from './CreditCardOnline';

const useStyles = makeStyles<Theme>(({ palette, shadows, spacing }) =>
  createStyles({
    creditCard: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      borderColor: palette.grey.A200,
      border: '1px solid',
      overflow: 'hidden',
      width: '450px',
      backgroundColor: palette.common.white,
      boxShadow: shadows[5],
    },
    creditCardHeader: {
      backgroundColor: palette.primary.main,
      display: 'flex',
      height: '50px',
      alignItems: 'center',
      '& svg': {
        fontSize: '30px',
        padding: '7px',
      },
    },
    creditCardWrapper: {
      padding: '0px 24px 0px 24px',
    },
    cardSection: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      marginBottom: spacing(3),
      marginTop: spacing(2),
    },
  }),
);

interface AddCardProps {
  onClose: (card?: CustomerPaymentProfileDto) => void;
  customerId: number;
  email: string;
  phoneNumber: string;
}

const AddCard: React.FC<AddCardProps> = ({ onClose, customerId }) => {
  const classes = useStyles();
  const publicClientKey = usePaymentClientKey();
  useAuthorize(authorizeConfig.authorizeAcceptUrl);
  const [inFlight, setInFlight] = React.useState(false);
  const [apiError, setApiError] = React.useState<Array<{ text: string }> | null>(null);
  const methods = useForm<CreditCardOnlineForm>({
    resolver: yupResolver(addCreditCard),
    defaultValues: {},
  });

  function createCreditPayment(data: CreditCardOnlineForm) {
    setInFlight(true);
    setApiError(null);
    const expDate = convertExpirationStringToDate(data[EXPIRATION]);
    const cardNumber = data[CARD_NUMBER];
    const cardData = {
      cardNumber,
      month: format(expDate, 'MM'),
      year: format(expDate, 'yyyy'),
      cardCode: data[CCV_CODE],
    };
    const authData = {
      clientKey: publicClientKey?.clientKey,
      apiLoginID: publicClientKey?.apiLoginId,
    };
    const secureData = {
      authData,
      cardData,
    };

    new Promise<CustomerPaymentProfileDto>((resolve, reject) => {
      // @ts-ignore
      Accept.dispatchData(secureData, (response) => {
        if (response.messages.resultCode === 'Error') {
          reject(response.messages.message);
          return;
        }

        paymentActionsApi
          .addPaymentMethod(customerId, {
            paymentToken: response.opaqueData.dataValue as string,
            billingAddress: {
              postalCode: data.postalCode,
            },
            cardholderName: data[CARDHOLDER_NAME],
            expiration: toBackEndDate(expDate)!,
            lastFourDigits: cardNumber.slice(-4),
            makeDefault: data.makeDefault || false,
            cardType: getCardType(cardNumber),
          })
          .then(resolve)
          .catch(reject);
      });
    })
      .then((card) => {
        onClose(card);
      })
      .catch((error) => {
        if (error) {
          setApiError(error);
        }
      })
      .then(() => {
        setInFlight(false);
      });
  }

  const cardNumber = methods.watch(CARD_NUMBER) || '';
  const cardType = getCardType(cardNumber);

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open
      color="grey"
      title="Add Card"
      onClose={handleClose}
      disabledInProcessing={inFlight}
      actions={[
        { label: 'Close', onClick: handleClose, variant: 'text' },
        {
          label: 'Add Card',
          loading: inFlight,
          onClick: methods.handleSubmit(createCreditPayment),
        },
      ]}
    >
      {apiError?.map((msg) => (
        <BodyText color="error">{`${msg.text}`}</BodyText>
      ))}
      {/** @ts-ignore */}
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <Box className={classes.cardSection}>
            <Box className={classes.creditCard}>
              <Box className={classes.creditCardHeader}>
                {cardType
                  ? cardTypeIcon[cardType as keyof typeof cardTypeIcon]
                  : Object.keys(CreditCardType).map((card) => cardTypeIcon[card as keyof typeof cardTypeIcon])}
              </Box>
              <Box className={classes.creditCardWrapper}>
                <Grid container spacing={2}>
                  <CreditCardForm />
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </FormProvider>
    </Modal>
  );
};

export default AddCard;
