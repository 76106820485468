import { useEffect, useState } from 'react';

import { BodyBigText, Modal, useAlert } from '@elromcoinc/react-shared';
import { Box, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { getIn } from 'immutable';
import { useSelector } from 'react-redux';

import { emailAPI } from 'admin/api';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { getCompanyInfo } from 'admin/autodux/CompanyInfoAutodux';
import { getCurrentEmployee } from 'admin/autodux/CurrentEmployeeAutodux';
import {
  USE_OUT_GOING_EMAIL,
  makeEmailServerSetting,
} from 'admin/components/Settings/components/Users/ContentPanels/UserFormConstants';
import EmployeeRecord from 'admin/entities/Employee';
import { getAuthUser } from 'admin/selectors/auth';
import { CompanyInfo } from 'common-types/CompanyInfo';

const fiveMinutesInMilliseconds = 5 * 60 * 1000;
const companySMTPError = 'companySMTPError';
const employeeSMTPError = 'employeeSMTPError';

export const SMTPErrors = () => {
  const theme = useTheme();
  const companyInfo = useSelector(getCompanyInfo) as CompanyInfo;
  const user = useSelector(getAuthUser);
  const [smtpError, setSmtpError] = useState({ [companySMTPError]: false, [employeeSMTPError]: false });
  const isShowCompanySMTPError = smtpError[companySMTPError];
  const isShowEmployeeSMTPError = smtpError[employeeSMTPError];
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentEmployee = useSelector(getCurrentEmployee) as EmployeeRecord | null;
  const isSessionExpired: boolean = useSelector(getIsSessionExpired);

  useEffect(() => {
    if (isMobile && (isShowCompanySMTPError || isShowEmployeeSMTPError)) {
      setShowSMTPAlert(true);
    }
  }, [isShowCompanySMTPError, isShowEmployeeSMTPError, isMobile]);

  const { setShowAlert: setShowSMTPAlert, alertProps: smtpAlertProps } = useAlert({
    confirmTitle: 'Close',
    displayCancel: false,
    onConfirm: () => {
      setShowSMTPAlert(false);
    },
  });

  const testCompanySMTP = () => {
    if (companyInfo?.sendingEmailAddress && user) {
      emailAPI
        .connectionTestForCompany()
        .then((res) => {
          if (res.success) {
            setSmtpError((state) => ({ ...state, [companySMTPError]: false }));
            return;
          }

          return Promise.reject(res);
        })
        .catch(() => {
          setSmtpError((state) => ({ ...state, [companySMTPError]: true }));
        });
    }
  };

  useEffect(() => {
    if (!isSessionExpired) {
      testCompanySMTP();
      const interval = setInterval(testCompanySMTP, fiveMinutesInMilliseconds);
      return () => clearInterval(interval);
    }
  }, [companyInfo?.sendingEmailAddress, companyInfo?.id, isSessionExpired]);

  const testEmployeeSMTP = () => {
    if (!user?.id || !currentEmployee?.id) {
      return;
    }

    const isUsedOutgoingEmail = getIn(currentEmployee, makeEmailServerSetting(USE_OUT_GOING_EMAIL).split('.'), null);

    if (isUsedOutgoingEmail) {
      emailAPI
        .connectionTestForEmployee(user.id)
        .then((res) => {
          if (res.success) {
            setSmtpError((state) => ({ ...state, [employeeSMTPError]: false }));
            return;
          }

          return Promise.reject(res);
        })
        .catch(() => {
          setSmtpError((state) => ({ ...state, [employeeSMTPError]: true }));
        });
    } else {
      setSmtpError((state) => ({ ...state, [employeeSMTPError]: false }));
    }
  };

  useEffect(() => {
    if (!isSessionExpired) {
      testEmployeeSMTP();
      const interval = setInterval(testEmployeeSMTP, fiveMinutesInMilliseconds);
      return () => clearInterval(interval);
    }
  }, [user?.id, currentEmployee?.id, isSessionExpired]);

  const smtpErrors = (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {isShowEmployeeSMTPError && (
        <BodyBigText align="center" color="error">
          <b>
            URGENT: Email connection failed. Please update your email SMTP settings immediately in order to resume
            sending emails.
          </b>
        </BodyBigText>
      )}
      {isShowCompanySMTPError && (
        <BodyBigText align="center" color="error">
          <b>
            URGENT: Email connection failed for company email. Please update your company SMTP settings immediately in
            order to resume sending emails.
          </b>
        </BodyBigText>
      )}
    </Box>
  );

  return (
    <>
      {(isShowEmployeeSMTPError || isShowCompanySMTPError) && <Hidden smDown>{smtpErrors}</Hidden>}
      <Modal title="SMTP error" {...smtpAlertProps}>
        {smtpErrors}
      </Modal>
    </>
  );
};
