export enum AccountType {
  CORPORATE = 'CORPORATE',
  AFFILIATE = 'AFFILIATE',
  PERSONAL = 'PERSONAL',
  PRIVATE = 'PRIVATE',
}

export enum AccountTypeName {
  CORPORATE = 'Corporate',
  AFFILIATE = 'Affiliate',
  PERSONAL = 'Personal',
  PRIVATE = 'Private',
}
