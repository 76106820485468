import React, { useState } from 'react';

import { statusIds } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

import { getDashboardStats } from 'admin/autodux/DashboardAutodux';
import { OrdersListModal } from 'admin/components/Calendar/orders/OrdersListModal';
import DashboardBlueCard from 'admin/components/Dashboard/DashboardCurrentMonthStatistic/DashboardBlueCard';
import { OrdersTypeSummary } from 'admin/constants';

import { DashboardLabels } from './DashboardLabels';

const useStyles = makeStyles(() => ({
  dashboardBlueCardWrapper: {
    marginTop: '0.5rem',
    marginLeft: '0px',
  },
}));

const blueCards = [
  { amount: 0, title: DashboardLabels.UNASSIGNED_LEADS },
  { amount: 0, title: DashboardLabels.POSTPONED },
  { amount: 0, title: DashboardLabels.CANCELED_JOBS },
  { amount: 0, title: DashboardLabels.INVENTORY_SUBMISSIONS },
];

const unassignedLeadsIndex = 0;
const postponedIndex = 1;
const canceledJobsIndex = 2;
const totalInventorySubmissionsIndex = 3;

interface OrderFilters {
  [unassignedLeadsIndex]: {
    unassigned: string;
    status: string[];
    created?: [startDate: string, endDate: string];
    statusUpdated?: [startDate: string, endDate: string];
  };
  [canceledJobsIndex]: {
    status: string[];
    created?: [startDate: string, endDate: string];
    statusUpdated?: [startDate: string, endDate: string];
  };
  [postponedIndex]: {
    status: string[];
    created?: [startDate: string, endDate: string];
    statusUpdated?: [startDate: string, endDate: string];
  };
  [totalInventorySubmissionsIndex]: {
    hasInventory: string;
    created?: [startDate: string, endDate: string];
    statusUpdated?: [startDate: string, endDate: string];
  };
}

const orderFilters: OrderFilters = {
  [unassignedLeadsIndex]: {
    unassigned: 'true',
    status: [
      statusIds.DRAFT,
      statusIds.NEW,
      statusIds.NEW_DATE,
      statusIds.NEW_LEAD_TRUCK,
      statusIds.NEW_LEAD_INFO,
      statusIds.CAN_BOOK_ONLINE,
      statusIds.RESERVED,
      statusIds.BOOKED,
      statusIds.POSTPONED,
    ],
  },
  [canceledJobsIndex]: {
    status: [statusIds.CANCELLED],
  },
  [postponedIndex]: {
    status: [statusIds.POSTPONED],
  },
  [totalInventorySubmissionsIndex]: {
    hasInventory: 'true',
  },
};

const makeDashboardMonthStats = (dashboard: ReturnType<typeof getDashboardStats>) => {
  const {
    current: {
      totalUnassigned = 0,
      cancelledLeads: { thisPeriod = [] } = { thisPeriod: [] },
      totalPostponedOrders = 0,
      dateRange = {
        startDate: '',
        endDate: '',
      },
    },
    submissionCount,
  } = dashboard;
  const totalCanceledJobs = thisPeriod
    .map(({ snapshotCount }: { snapshotCount: number }) => snapshotCount)
    .reduce((accumulator: number, value: number) => accumulator + value, 0);

  return {
    amount: [totalUnassigned, totalPostponedOrders, totalCanceledJobs, submissionCount],
    dateRange,
  };
};

const DashboardCurrentMonthStatistic = () => {
  const classes = useStyles();
  const [modalProps, setModalProps] = useState<{} | null>(null);
  const [openModal, setOpenModal] = useState<OrdersTypeSummary | null>(null);
  const dashboard = useSelector(getDashboardStats);
  const dashboardMonthStats = makeDashboardMonthStats(dashboard);

  dashboardMonthStats?.amount.forEach((value, index) => {
    blueCards[index].amount = value;
  });

  const handleClickBlueCard = (index: number) => () => {
    const currentFilter = orderFilters[index as keyof typeof orderFilters];

    const { dateRange } = dashboardMonthStats;

    if (index === totalInventorySubmissionsIndex) {
      currentFilter.created = [dateRange?.startDate, dateRange?.endDate];
    }

    if (index === canceledJobsIndex) {
      currentFilter.statusUpdated = [dateRange?.startDate, dateRange?.endDate];
    }

    if (index === totalInventorySubmissionsIndex) {
      setOpenModal(OrdersTypeSummary.SUBMISSIONS_ORDERS);
    } else {
      setOpenModal(OrdersTypeSummary.ALL_ORDERS);
    }

    setModalProps({ filters: currentFilter });
  };

  const handleCloseOrderListModal = () => {
    setOpenModal(null);
    setModalProps(null);
  };

  return (
    <>
      <Grid container lg={5} xs={12} spacing={2} className={classes.dashboardBlueCardWrapper}>
        {React.Children.toArray(
          [0, 1, 2, 3].map((value, index) => (
            <Grid lg={3} xs={6} item>
              <DashboardBlueCard onClick={handleClickBlueCard(index)} {...blueCards[value]} />
            </Grid>
          )),
        )}
      </Grid>
      {!!modalProps && <OrdersListModal {...modalProps} type={openModal} onClose={handleCloseOrderListModal} />}
    </>
  );
};

export default DashboardCurrentMonthStatistic;
