import React, { useState } from 'react';

import {
  AdminPermissions,
  BodySmallText,
  BodyText,
  ElromcoCircularProgress,
  HeaderSmallText,
  Link,
  formatPhoneNumber,
  useHasPermission,
} from '@elromcoinc/react-shared';
import { Box, ClickAwayListener, Tooltip, makeStyles, useTheme } from '@material-ui/core';
import { AccountBalance as AccountBalanceIcon } from '@material-ui/icons';
import pt from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAccountInformation, getAccountInformation } from 'admin/autodux/AccountInformationAutodux';
import { AccountType, AccountTypeName } from 'admin/constants/AccountType';
import Circle from 'common/components/Circle';

import { openAccount } from '../../../autodux/WindowsAutodux';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    color: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  customerOnline: {
    position: 'absolute',
    left: '-5px',
    top: '3px',
  },
  tooltip: {
    maxWidth: '12rem',
    width: '100%',
  },
}));

const skipEvents = (fn) => (event) => {
  event.preventDefault();
  event.stopPropagation();
  fn && fn(event);
};

const CustomerColumn = ({ row, setOpenEmailModal, isMobile }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const canSeeCustomerOnline = useHasPermission(AdminPermissions.PERM_CAN_SEE_CUSTOMERS_ONLINE);
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const accountInfo = useSelector(getAccountInformation);
  const { customerName, customerPhone, contactEmail, customerActive, orderId, accountType } = row;

  if (!customerName && !customerPhone && !contactEmail && !orderId) {
    return <></>;
  }

  const account = accountInfo.get(orderId) || null;
  const isLoadingAccountInfo = !account && accountInfo.has(orderId);
  const handleShowTooltip = () => setShowTooltip(true);
  const handleCloseTooltip = () => setShowTooltip(false);

  const handleShowAccountInfo = () => {
    if (!account) {
      dispatch(fetchAccountInformation(orderId));
    }
  };

  const handleTouchStart = () => {
    handleShowAccountInfo();
    handleShowTooltip();

    if (isMobile) {
      setTimeout(handleCloseTooltip, 5e3);
    }
  };

  const handleOpenEmailModal = () => {
    setOpenEmailModal(row);
  };

  const handleOpenAccountWindow = () => {
    if (account) {
      dispatch(openAccount(account.id));
    }
  };

  const accountDetails = account ? (
    <Box display="flex" flexDirection="column">
      <HeaderSmallText>{AccountTypeName[account.type]}&nbsp;Account</HeaderSmallText>
      <Link align="left" onClick={handleOpenAccountWindow}>
        <BodyText>
          <b>{account.name}</b>
        </BodyText>
      </Link>
      <BodyText>Account ID: {account.id}</BodyText>
      <Box style={{ textTransform: 'capitalize' }}>
        <BodyText>
          Contact:&nbsp;
          {`${account.ownerCustomerInfo.firstName} ${account.ownerCustomerInfo.lastName}`}
        </BodyText>
      </Box>
      <BodyText>Phone: {formatPhoneNumber(account.ownerCustomerInfo.primaryPhone.number)}</BodyText>
    </Box>
  ) : (
    <Box>Loading...</Box>
  );

  return (
    <Box className={classes.flexContainer} maxWidth={isMobile ? 80 : 120}>
      {canSeeCustomerOnline && (
        <Circle
          className={classes.customerOnline}
          color={customerActive ? theme.palette.success.main : theme.palette.grey.A100}
          width="10px"
          height="10px"
        />
      )}
      <Box ml={canSeeCustomerOnline ? 1 : 0} display="flex" justifyContent="space-between" alignItems="center">
        <Box style={{ textTransform: 'capitalize' }} component="span">
          <BodySmallText gutterBottom>{customerName}</BodySmallText>
        </Box>
        {(accountType === AccountType.CORPORATE || accountType === AccountType.AFFILIATE) && (
          <BodySmallText component="span">
            <ClickAwayListener onClickAway={handleCloseTooltip}>
              <Tooltip
                title={accountDetails}
                arrow
                {...(isMobile && {
                  disableFocusListener: true,
                  disableHoverListener: true,
                  disableTouchListener: true,
                  open: showTooltip,
                  onClose: handleCloseTooltip,
                })}
                enterTouchDelay={0}
                leaveDelay={1000}
                placement="bottom-start"
                classes={{ popper: classes.tooltip }}
              >
                <Box
                  onTouchStart={skipEvents(handleTouchStart)}
                  onClick={skipEvents(handleTouchStart)}
                  {...(!isMobile && { onMouseEnter: handleShowAccountInfo })}
                  fontSize={16}
                >
                  {!isLoadingAccountInfo ? (
                    <AccountBalanceIcon fontSize="inherit" color="primary" />
                  ) : (
                    <Box width={20} height={20}>
                      <ElromcoCircularProgress />
                    </Box>
                  )}
                </Box>
              </Tooltip>
            </ClickAwayListener>
          </BodySmallText>
        )}
      </Box>
      {customerPhone && (
        <a href={`tel:${formatPhoneNumber(customerPhone)}`}>
          <BodySmallText noWrap>{formatPhoneNumber(customerPhone)}</BodySmallText>
        </a>
      )}
      <Tooltip title={contactEmail} arrow>
        <Box component="span">
          <Link align="left" onClick={skipEvents(handleOpenEmailModal)}>
            <Box maxWidth={isMobile ? 80 : 100}>
              <BodySmallText noWrap>{contactEmail}</BodySmallText>
            </Box>
          </Link>
        </Box>
      </Tooltip>
    </Box>
  );
};

CustomerColumn.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  row: pt.object.isRequired,
  setOpenEmailModal: pt.func.isRequired,
  isMobile: pt.bool.isRequired,
};

export { CustomerColumn };
