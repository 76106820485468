import React, { ChangeEvent, useState } from 'react';

import {
  BodyText,
  CurrencyInput,
  Fee,
  FeeType,
  Modal,
  MoveTypeTypes,
  TextInput,
  allFeeTypeDescriptions,
  feeTypeDescriptions,
  formatCurrency,
  getFeesWithoutDuplicates,
} from '@elromcoinc/react-shared';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import clsx from 'clsx';
import { List } from 'immutable';

import { useOrderClosingContext, useOrderState, useOrderWindowSettings } from 'admin/components/OrderWindow/context';
import SettingName from 'admin/constants/SettingName';
import { ContentContainer, ContentTableContainer } from 'common/components/Widgets';
import { currencyInputAdapter } from 'common/utils';

const useStyles = makeStyles(({ spacing }) => ({
  removedItemRow: {
    '& td > p': {
      color: 'lightgrey',
    },
  },
  borderTopLeftRadius: {
    borderTopLeftRadius: spacing(0.75),
  },
  borderTopRightRadius: {
    borderTopRightRadius: spacing(0.75),
  },
}));

const { ORDERS_EXTRA_FEE_NAME } = SettingName;

interface AdditionalFeesProps {
  onSave: (fees: List<Fee>) => void;
  onCancel: () => void;
  open: boolean;
  fees: List<Fee>;
}

const AdditionalFees = ({ onSave, onCancel, open, fees }: AdditionalFeesProps) => {
  const { order } = useOrderState();
  const settings = useOrderWindowSettings();
  const extraFeeName = settings[ORDERS_EXTRA_FEE_NAME[order?.moveType as MoveTypeTypes]];
  const { closingTitle, isCompleted, isLockSales, isClosing } = useOrderClosingContext();
  const classes = useStyles();
  const [tmpFees, setTmpFees] = useState(fees);
  const total = tmpFees.filter((it) => it.enabled).reduce((result, fee) => result + fee.amount, 0);
  const currentDescription = order?.isLongDistance() ? allFeeTypeDescriptions : feeTypeDescriptions;

  const isLongDistance = order?.isLongDistance();

  currentDescription['EXTRA_FEE'] = extraFeeName;
  const feesWithoutDuplicates = getFeesWithoutDuplicates(tmpFees, isLongDistance);

  const handleToggleFee = (currentFee: Fee, isEnabled: boolean) => () => {
    setTmpFees((list) =>
      list.map((fee) =>
        currentDescription[fee.feeType as FeeType] === currentDescription[currentFee.feeType as FeeType]
          ? fee.set('enabled', isEnabled)
          : fee,
      ),
    );
  };

  const handleSaveClick = () => {
    onSave(tmpFees);
  };

  const onChange = (fee: Fee) => (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTmpFees((list) => {
      const index = list.findIndex((it) => it.feeType === fee.feeType);
      const updated = list.setIn([index, name], value);

      return updated.map((fee, i) => (index === i ? fee.calculateTotal() : fee));
    });
  };

  const handleBlur = (fee: Fee) => () => {
    setTmpFees((list) => {
      return list.map((f) =>
        f.feeType === fee.feeType ? f.set('rate', f.rate || 0).set('quantity', f.quantity || 0) : f,
      );
    });
  };

  return (
    <Modal
      open={open}
      title={`Other Fees${closingTitle}`}
      onClose={onCancel}
      actions={[
        { label: 'cancel', onClick: onCancel },
        { label: 'save', onClick: handleSaveClick, disabled: tmpFees.equals(fees) },
      ]}
      maxWidth="sm"
      color="grey"
    >
      <Box display="flex" pb={6.25}>
        <ContentContainer width="100%" display="flex" flexDirection="column" ml={1}>
          <ContentTableContainer component={Paper} elevation={4}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.borderTopLeftRadius}>
                    <BodyText>Item</BodyText>
                  </TableCell>
                  {isClosing && isLongDistance && (
                    <>
                      <TableCell>
                        <BodyText>Qnt.</BodyText>
                      </TableCell>
                      <TableCell>
                        <BodyText>Cost</BodyText>
                      </TableCell>
                    </>
                  )}
                  <TableCell width="100px">
                    <BodyText>Line Total</BodyText>
                  </TableCell>
                  <TableCell className={classes.borderTopRightRadius} width="40px" />
                </TableRow>
              </TableHead>
              <TableBody>
                {feesWithoutDuplicates.length ? (
                  feesWithoutDuplicates.map((item) => (
                    <TableRow className={clsx({ [classes.removedItemRow]: !item.enabled })} key={item.id}>
                      <TableCell>
                        <BodyText width="210px">{item.description}</BodyText>
                      </TableCell>
                      {isClosing && isLongDistance && (
                        <>
                          <TableCell>
                            <TextInput
                              type="number"
                              size="small"
                              hiddenLabel
                              fullWidth
                              value={item.quantity}
                              name="quantity"
                              disabled={isCompleted}
                              onChange={onChange(item)}
                              onBlur={handleBlur(item)}
                            />
                          </TableCell>
                          <TableCell>
                            <CurrencyInput
                              size="small"
                              hiddenLabel
                              fullWidth
                              name="rate"
                              value={item.rate}
                              InputProps={{
                                min: 0,
                              }}
                              inputProps={{ maxLength: 9 }}
                              disabled={isCompleted}
                              onChange={currencyInputAdapter(onChange(item))}
                              onBlur={handleBlur(item)}
                            />
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        <BodyText width="90px">{`$${formatCurrency(item.amount)}`}</BodyText>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          disabled={isCompleted || isLockSales}
                          size="small"
                          onClick={handleToggleFee(item, !item.enabled)}
                        >
                          {item.enabled ? <CloseIcon fontSize="default" /> : <ReplayIcon fontSize="default" />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={isClosing ? 5 : 3} className="applyBottomRadius">
                      <BodyText>No other fees available</BodyText>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ContentTableContainer>
          <Paper elevation={4} className="totalTag">
            <BodyText>
              <b>Total</b>
            </BodyText>
            <BodyText>{`$${formatCurrency(total)}`}</BodyText>
          </Paper>
        </ContentContainer>
      </Box>
    </Modal>
  );
};

export default AdditionalFees;
