import { Address, JobDto, TruckAssignmentDto } from '@elromcoinc/react-shared';
import { List, Record } from 'immutable';

import GeneralService from './GeneralService';
import RangeValue from './RangeValue';

interface ServiceSummaryProps {
  orderId: number;
  serviceRosterId: number;
  serviceDate: string;
  serviceName: string;
  generalService: GeneralService;
  createDate: string;
  updatedDate: string;
  crewSize: number;
  numberOfTrucks: number;
  fromAddress: Address;
  toAddress: Address;
  estimatedQuoteRange: RangeValue;
  startTimeEarliest: string;
  jobDuration: number;
  endTime: string;
  estimateType: string;
  truckAssignments: List<TruckAssignmentDto>;
  dispatchJob: JobDto | null;
  originalId: number;
}

export default class ServiceSummary extends Record<ServiceSummaryProps>({
  orderId: 0,
  serviceRosterId: 0,
  serviceDate: '',
  serviceName: '',
  generalService: new GeneralService(),
  createDate: '',
  updatedDate: '',
  crewSize: 0,
  numberOfTrucks: 0,
  fromAddress: Address.createAddress(),
  toAddress: Address.createAddress(),
  estimatedQuoteRange: RangeValue.createRangeValue(),
  startTimeEarliest: '',
  jobDuration: 0,
  endTime: '',
  estimateType: '',
  truckAssignments: List(),
  dispatchJob: null,
  originalId: 0,
}) {
  static createServiceSummary(summary: Partial<ServiceSummaryProps> = {}) {
    const {
      serviceDate,
      createDate,
      updatedDate,
      fromAddress,
      toAddress,
      estimatedQuoteRange,
      generalService,
      jobDuration,
      startTimeEarliest,
      estimateType,
      truckAssignments,
      dispatchJob,
      serviceRosterId,
      ...rest
    } = summary;
    return new ServiceSummary(rest)
      .set('serviceDate', serviceDate as string)
      .set('estimateType', estimateType as string)
      .set('jobDuration', jobDuration as number)
      .set('dispatchJob', dispatchJob as JobDto)
      .set('serviceRosterId', serviceRosterId as number)
      .set('startTimeEarliest', (startTimeEarliest as string) || '')
      .set('fromAddress', Address.createAddress(fromAddress))
      .set('toAddress', Address.createAddress(toAddress))
      .set('generalService', GeneralService.createGeneralService(generalService))
      .set('estimatedQuoteRange', RangeValue.createRangeValue(estimatedQuoteRange))
      .set('truckAssignments', List((truckAssignments || []).map(TruckAssignmentDto.createTruckAssignmentDto)));
  }
}
