import React from 'react';

import {
  BodySmallText,
  BodyText,
  Button,
  IconButton,
  InvoiceDto,
  InvoicesStatusType,
  InvoicesStatusTypeName,
  UI_DATE_FORMAT_SHORT,
  formatCurrency,
} from '@elromcoinc/react-shared';
import { Box, Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { format, parseISO } from 'date-fns';

import { InvoicesTypes } from 'admin/components/Invoices';
import { useInvoiceStatusColors } from 'admin/hooks/useInvoiceStatusColors';
import { InvoiceActionType } from 'common-types';
import Circle from 'common/components/Circle';

const actionsLabel = (
  <Box display="flex" justifyContent="end" pr={1}>
    <BodySmallText>
      <b>ACTIONS</b>
    </BodySmallText>
  </Box>
);

export const makeInvoicesModalColumns = (
  rowsData: InvoiceDto[],
  statusColor: ReturnType<typeof useInvoiceStatusColors>,
  sendOrResendInvoice: (status: InvoicesStatusType, invoice: InvoiceDto) => void,
  inFlight: boolean,
  handleInvoiceAction: (invoice: InvoiceDto, invoiceAction: InvoiceActionType) => void,
) => {
  return [
    {
      name: 'id',
      label: 'INVOICE',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { number } = rowsData[rowIndex];

          return <BodyText>{number}</BodyText>;
        },
      },
    },
    {
      name: 'order.orderNumber',
      label: 'ORDER  #',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { orderNumber } = rowsData[rowIndex];

          return <BodyText>{orderNumber}</BodyText>;
        },
      },
    },
    {
      name: 'status',
      label: 'STATUS',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { status } = rowsData[rowIndex];

          return (
            <Box display="flex" alignItems="center">
              <Circle
                className="circle"
                color={`${statusColor[status as InvoicesStatusType]}`}
                width="15px"
                minWidth="15px"
                height="15px"
                mr={1}
              />
              <BodyText color="inherit">{InvoicesStatusTypeName[status as InvoicesStatusType]}</BodyText>
            </Box>
          );
        },
      },
    },
    {
      name: 'description',
      label: 'DESCRIPTION',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { message } = rowsData[rowIndex];

          return (
            <Box maxWidth="292px">
              <BodyText align="justify" wordBreak>
                {message}
              </BodyText>
            </Box>
          );
        },
        sort: false,
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'created',
      label: 'DATE CREATED',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { dateCreated } = rowsData[rowIndex];

          return <BodyText>{format(parseISO(dateCreated as string), UI_DATE_FORMAT_SHORT)}</BodyText>;
        },
      },
    },
    {
      name: 'dueDate',
      label: 'DATE DUE',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { dueDate } = rowsData[rowIndex];

          return <BodyText>{format(parseISO(dueDate as string), UI_DATE_FORMAT_SHORT)}</BodyText>;
        },
      },
    },
    {
      name: 'total',
      label: 'AMOUNT',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { total } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(total)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'balance',
      label: 'BALANCE',
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { balance } = rowsData[rowIndex];

          return <BodyText>{`$${formatCurrency(balance)}`}</BodyText>;
        },
        showOnMobileAsExpanded: true,
      },
    },
    {
      name: 'actions',
      label: actionsLabel,
      options: {
        customBodyRender: (_: InvoicesTypes[], { rowIndex }: { rowIndex: number }) => {
          const { status } = rowsData[rowIndex];
          const isResendStatus =
            status === InvoicesStatusType.SENT ||
            status === InvoicesStatusType.RESENT ||
            status === InvoicesStatusType.PAID;

          const handleClickInvoice = (type: InvoiceActionType) => () => {
            if ([InvoiceActionType.VIEW, InvoiceActionType.EDIT, InvoiceActionType.DELETE].includes(type)) {
              handleInvoiceAction(rowsData[rowIndex], type);
            }
            if ([InvoiceActionType.RESEND, InvoiceActionType.SEND].includes(type)) {
              let updatedInvoiceStatus = InvoicesStatusType.SENT;
              if (isResendStatus) {
                if (status === InvoicesStatusType.PAID) {
                  updatedInvoiceStatus = InvoicesStatusType.PAID;
                } else {
                  updatedInvoiceStatus = InvoicesStatusType.RESENT;
                }
              }
              sendOrResendInvoice(updatedInvoiceStatus, rowsData[rowIndex]);
            }
          };

          return (
            <Box display="flex" justifyContent="end">
              <Box maxWidth={178}>
                <Grid container>
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      textTransform="initial"
                      color="primary"
                      onClick={handleClickInvoice(InvoiceActionType.VIEW)}
                    >
                      <BodyText>View</BodyText>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      textTransform="initial"
                      color="primary"
                      onClick={handleClickInvoice(InvoiceActionType.EDIT)}
                    >
                      <BodyText>Edit</BodyText>
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="text"
                      textTransform="initial"
                      color="primary"
                      disabled={inFlight}
                      onClick={handleClickInvoice(isResendStatus ? InvoiceActionType.RESEND : InvoiceActionType.SEND)}
                    >
                      <BodyText>{isResendStatus ? 'Resend' : 'Send'}</BodyText>
                    </Button>
                  </Grid>
                  {status !== InvoicesStatusType.PAID && status !== InvoicesStatusType.PARTIALLY_PAID ? (
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="center">
                        <IconButton
                          size="small"
                          color="primary"
                          data-testid="deleteItem"
                          disabled={inFlight}
                          onClick={handleClickInvoice(InvoiceActionType.DELETE)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  ) : (
                    <Grid item xs={3}>
                      <Box width="30px" />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          );
        },
        showOnMobileAsExpanded: true,
        sort: false,
      },
    },
  ];
};
