import React, { FC } from 'react';

import { IconButton } from '@elromcoinc/react-shared';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@material-ui/lab';

import ActivityItem from 'admin/components/OrderWindow/blocks/ActivityManager/ActivityItem';
import getActivityIcon from 'admin/components/OrderWindow/blocks/ActivityManager/getActivityIcon';
import ActivityType from 'admin/constants/ActivityType';
import OrderLog from 'admin/entities/OrderLog';

const useStyles = makeStyles((theme) => ({
  rootTimeLineDot: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  rootTimeLineSeparator: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-1),
    },
  },
  rootTimeLineConnector: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-2),
    },
  },
}));

interface ActivityLogTimelineProps {
  onEdit?: (id: number, activityType: ActivityType) => void;
  item: OrderLog;
}

export const ActivityLogTimeline: FC<ActivityLogTimelineProps> = ({ onEdit, item }) => {
  const classes = useStyles();
  const activityType = item.activityType as ActivityType;
  const isTask = activityType === ActivityType.TASK;
  const isNote = activityType === ActivityType.NOTE;
  const isAvailableToEdit = activityType === ActivityType.MANUALLY_ENTERED_ACTIVITY || isTask || isNote;
  const activityTypeToShow = isAvailableToEdit ? item.type || activityType : activityType;
  const handleEdit = () => onEdit?.(item.id!, activityType!);
  const defaultIcon = getActivityIcon(activityTypeToShow as ActivityType, false, item?.image);

  return (
    <TimelineItem key={item.id}>
      <TimelineOppositeContent className="hide" />
      <TimelineSeparator classes={{ root: classes.rootTimeLineSeparator }}>
        <TimelineDot classes={{ root: classes.rootTimeLineDot }}>
          {!!activityType && (
            <Box display="flex" flexDirection="row" alignItems="start">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Tooltip title={activityTypeToShow!}>
                  <div>
                    <IconButton color="primary" variant="text" size="small">
                      {defaultIcon}
                    </IconButton>
                  </div>
                </Tooltip>
              </Box>
            </Box>
          )}
        </TimelineDot>
        <TimelineConnector classes={{ root: classes.rootTimeLineConnector }} />
      </TimelineSeparator>
      <TimelineContent className={classes.content}>
        <ActivityItem
          label={item.label}
          desc={item.desc}
          type={item.type!}
          hiddenContent={item.hiddenContent!}
          isAvailableToEdit={isAvailableToEdit}
          onEdit={handleEdit}
          item={item}
        />
      </TimelineContent>
    </TimelineItem>
  );
};
