import { LongDistanceTariffDetails } from '@elromcoinc/react-shared';

import { mileageTariffTypeName } from 'admin/constants/MileageTariffType';

export function renderLaborForRate(
  longDistanceTariffDetails: LongDistanceTariffDetails,
  moveUnitLabelsShort: string,
  prefix = '',
) {
  const denominator =
    longDistanceTariffDetails.perNumberOfUnits > 1 ? `${longDistanceTariffDetails.perNumberOfUnits} ` : '';
  // @ts-ignore
  const units = mileageTariffTypeName[longDistanceTariffDetails.unitType] || moveUnitLabelsShort;
  return `${prefix}Rate per ${denominator}${units}`;
}
