import { FC, useEffect, useMemo, useState } from 'react';

import { Modal, Password, TextInput, emailYUP } from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, LinearProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import { useOrderState } from 'admin/components/OrderWindow/context';
import { AccountDto } from 'common-types';

interface AccountLoginModalProps {
  onClose: () => void;
}

const accountContactName = 'accountContactName';
const accountEmail = 'accountEmail';
const password = 'password';

const transformToNull = (value: string) => (!value ? null : value);

const wrongPasswordMessage = `
The password must satisfy the following requirements:\n
                        At least one digit [0-9]\n
                        At least one uppercase character [A-Z]\n
                        At least one lowercase character [a-z]\n
                        At least one special character [*.!@#$%^&(){}[]:;<>,.?/~_+-=|\\\\]\n
                       At least 8 characters in length, but no more than 32.`;

const schema = object().shape({
  [accountContactName]: string().label('Contact Name').nullable().transform(transformToNull).max(62).min(2),
  [accountEmail]: emailYUP({ label: 'Email' })
    .transform(transformToNull)
    .when(`$${accountEmail}`, (currentEmail, s) => {
      return s.test({
        name: 'unique-email',
        message: 'This email already used',
        test: (value: any) =>
          emailYUP({ label: '' }).isValidSync(value) && currentEmail !== value
            ? accountAPI.getByEmail(value).then((response) => !response)
            : Promise.resolve(true),
      });
    }),
  [password]: string()
    .nullable()
    .min(8)
    .max(32)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,32}$/, wrongPasswordMessage)
    .label('Password')
    .transform(transformToNull),
});

export const AccountLoginModal: FC<AccountLoginModalProps> = ({ onClose }) => {
  const { order } = useOrderState();
  const { enqueueSnackbar } = useSnackbar();
  const [inFlight, setInFlight] = useState(false);
  const [customer, setCustomer] = useState<AccountDto | null>(null);

  const context = useMemo(() => ({ [accountEmail]: order?.customerInfo?.email }), []);

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      [accountContactName]: '',
      [accountEmail]: order?.customerInfo?.email,
    },
    context,
  });

  useEffect(() => {
    setInFlight(true);

    accountAPI
      .getAccountSummary(order?.accountId)
      .then((res) => {
        const result = res as unknown as AccountDto;
        setCustomer(result);
        setValue(accountContactName, result?.name || '');
        setValue(accountEmail, result?.ownerCustomerInfo?.email || '');
      })
      .catch(() => {
        enqueueSnackbar(`Can't get an account for an existing customer`, { variant: 'error' });
      })
      .then(() => {
        setInFlight(false);
      });
  }, []);

  const handleSaveChanges = (data: any) => {
    accountAPI
      .updateAccountInfo({
        ...customer,
        ownerCustomerInfo: { ...customer?.ownerCustomerInfo, email: data[accountEmail] },
        name: data[accountContactName],
        updated: null,
        created: null,
      })
      .then(() => {
        enqueueSnackbar(`Account updated`, { variant: 'success' });
      })
      .then(() => {
        if (!data[password]) {
          return;
        }

        return accountAPI.updateAccountPassword(order?.customerInfo?.customerId, data[password]);
      })
      .then(onClose)
      .catch(() => {
        enqueueSnackbar(`Can't update an account`, { variant: 'error' });
      });
  };

  const actions = [
    {
      label: 'Cancel',
      onClick: onClose,
    },
    {
      label: 'Save',
      onClick: handleSubmit(handleSaveChanges as any),
    },
  ];

  return (
    <Modal open title="Account Login" actions={actions} onClose={onClose} maxWidth="xs">
      <Box mt={-1} height={8}>
        {inFlight && <LinearProgress />}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box my={1}>
          <TextInput
            fullWidth
            label="Account Contact Name"
            name={accountContactName}
            control={control}
            disabled={inFlight}
          />
        </Box>
        <Box my={1}>
          <TextInput fullWidth label="Account Email" name={accountEmail} control={control} disabled={inFlight} />
        </Box>
        <Box my={1}>
          <Password // @ts-ignore*
            fullWidth
            label="New password"
            name={password}
            control={control}
            disabled={inFlight}
            allowSeePassword
          />
        </Box>
      </Box>
    </Modal>
  );
};
