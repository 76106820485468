import autodux from 'autodux';
import { List, set } from 'immutable';

import departmentAPI from 'admin/api/DepartmentAPI';

const DEPARTMENTS_KEY = 'departments';

const {
  reducer,
  actions: { setDepartments, setIsFetching, updateDepartment, addDepartment, onDeleteDepartment },
  selectors: { getDepartments, getIsFetching },
} = autodux({
  slice: 'userDepartments',
  initial: {
    [DEPARTMENTS_KEY]: List(),
    isFetching: false,
  },
  actions: {
    updateDepartment: (state, payload) =>
      set(
        state,
        DEPARTMENTS_KEY,
        state[DEPARTMENTS_KEY].map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
      ),
    addDepartment: (state, payload) => set(state, DEPARTMENTS_KEY, state[DEPARTMENTS_KEY].push(payload)),
    onDeleteDepartment: (state, payload) =>
      set(
        state,
        DEPARTMENTS_KEY,
        state[DEPARTMENTS_KEY].filter((d) => d.id !== payload.id),
      ),
  },
});

export default reducer;

const fetchDepartments = () => (dispatch) => {
  dispatch(setIsFetching(true));

  return departmentAPI
    .listAllDepartmentsAPI()
    .catch(() => {})
    .then((response) => {
      dispatch(setIsFetching(false));

      if (response) {
        dispatch(setDepartments(List(response)));
      }
    });
};

const saveDepartment = (department) => (dispatch) =>
  departmentAPI
    .updateDepartmentAPI(department)
    .then((response) => {
      dispatch(updateDepartment(department));
      return response;
    })
    .catch(() => {});

const createDepartment = (name) => (dispatch) =>
  departmentAPI.createDepartmentAPI({ name }).then((response) => {
    dispatch(addDepartment({ ...response, defaultNotifiableEventTypes: [], defaultPermissions: [] }));
  });

const deleteDepartment = (department) => (dispatch) =>
  departmentAPI.deleteDepartment(department.id).then(() => {
    dispatch(onDeleteDepartment(department));
  });

export { getDepartments, getIsFetching, fetchDepartments, saveDepartment, createDepartment, deleteDepartment };
