import React, { useEffect, useMemo, useState } from 'react';

import {
  Button,
  CustomerInfo,
  Form,
  PhoneFormGroup,
  Radio,
  RadioGroup,
  TextInput,
  emailYUP,
  getFormErrorMessages,
  phoneYUP,
} from '@elromcoinc/react-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmailIcon from '@material-ui/icons/Email';
import { get, set } from 'immutable';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';

import accountAPI from 'admin/api/AccountAPI';
import orderApi from 'admin/api/OrderAPI';
import { openOrder } from 'admin/autodux/WindowsAutodux';
import CustomerOrders from 'admin/components/CreateOrder/CustomerOrders';
import FieldNames, { PHONE_CARRIER_TYPE, SMS_CAPABILITY } from 'admin/constants/FieldNames';
import OrderSummary from 'admin/entities/OrderSummary';
import useUpdatePhoneNumberType from 'admin/hooks/useUpdatePhoneNumberType';
import useDebounce from 'common/hooks/useDebounce';

const { PRIMARY_PHONE, PHONE_NUMBER, PHONE_TYPE, PHONE_EXTENSION } = FieldNames;

const useStyle = makeStyles(({ breakpoints, spacing }) => ({
  formContainer: {
    [breakpoints.up('sm')]: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  },
}));

const EMAIL = 'email';
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';

const PHONE_NUMBER_PATH = `${PRIMARY_PHONE}.${PHONE_NUMBER}`;
const PHONE_NUMBER_SMS_CAPABILITY_PATH = `${PRIMARY_PHONE}.${SMS_CAPABILITY}`;
const PHONE_NUMBER_CARRIER_TYPE_PATH = `${PRIMARY_PHONE}.${PHONE_CARRIER_TYPE}`;

const phoneLabels = {
  [PHONE_NUMBER]: 'Primary Phone',
  [PHONE_TYPE]: 'Phone Type',
  [PHONE_EXTENSION]: 'Ext',
};

const labels = {
  [EMAIL]: 'Email',
  [FIRST_NAME]: 'First Name',
  [LAST_NAME]: 'Last Name',
};

const schema = object().shape({
  [EMAIL]: emailYUP(labels[EMAIL]),
  [PRIMARY_PHONE]: phoneYUP(phoneLabels),
  [FIRST_NAME]: string().label(labels[FIRST_NAME]).required().nullable().min(2).max(32),
  [LAST_NAME]: string().label(labels[LAST_NAME]).required().nullable().min(2).max(32),
});

const DEBOUNCE_DELAY = 500;
const CREATE_NEW_ORDER_OPTION = 'Create New Order';
const CLONE_SELECTED_ORDER_OPTION = 'Clone selected order';
const CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_OPTION = 'Create new lead for selected customer';
const NEW_ORDER_OPTIONS = [
  CREATE_NEW_ORDER_OPTION,
  CLONE_SELECTED_ORDER_OPTION,
  CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_OPTION,
];
const CREATE_NEW_ORDER_INDEX = NEW_ORDER_OPTIONS.indexOf(CREATE_NEW_ORDER_OPTION);
const CLONE_SELECTED_ORDER_INDEX = NEW_ORDER_OPTIONS.indexOf(CLONE_SELECTED_ORDER_OPTION);
const CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_INDEX = NEW_ORDER_OPTIONS.indexOf(
  CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_OPTION,
);
const isDisableNewOrderOption = (selectedOrderId, index) =>
  (index === CLONE_SELECTED_ORDER_INDEX || index === CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_INDEX) && !selectedOrderId;

const CustomerInformation = ({ onSave, onClose, data, hidden }) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const [inFlight, setInFlight] = useState(false);
  const context = useMemo(() => ({}), []);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [createActionIndex, setCreateActionIndex] = useState(CREATE_NEW_ORDER_INDEX);
  const [criteria, setCriteria] = useState({
    [EMAIL]: null,
    [PRIMARY_PHONE]: null,
    [FIRST_NAME]: null,
    [LAST_NAME]: null,
  });
  const [inFlightFetchingOrders, setInFlightFetchingOrders] = useState(false);
  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [ordersPage, setOrdersPage] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const { enqueueSnackbar } = useSnackbar();
  const isEmptyCriteria = !Object.values(criteria).some(Boolean);

  const formMethods = useForm({
    defaultValues: data.customerInfo.toJS ? data.customerInfo.toJS() : data.customerInfo,
    resolver: yupResolver(schema),
    context,
  });
  const {
    getValues,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = formMethods;

  const formErrors = getFormErrorMessages(errors);
  const values = getValues();
  context.createActionIndex = createActionIndex;

  const phoneNumberValue = watch(PHONE_NUMBER_PATH);
  const debounceEmail = useDebounce(watch(EMAIL), DEBOUNCE_DELAY)?.trim();
  const debounceFirstName = useDebounce(watch(FIRST_NAME), DEBOUNCE_DELAY);
  const debounceLastName = useDebounce(watch(LAST_NAME), DEBOUNCE_DELAY);
  const debouncePrimaryPhone = useDebounce(phoneNumberValue, DEBOUNCE_DELAY);

  useUpdatePhoneNumberType(phoneNumberValue, {
    shouldUpdate: () => hasChanges,
    setSmsCapability: (value) => setValue(PHONE_NUMBER_SMS_CAPABILITY_PATH, value),
    setCarrierType: (value) => setValue(PHONE_NUMBER_CARRIER_TYPE_PATH, value),
  });

  useEffect(() => {
    if (isDirty && !hasChanges) {
      setHasChanges(isDirty);
    }
  }, [isDirty]);

  useEffect(() => {
    if (criteria[EMAIL] !== debounceEmail) {
      if (formErrors[EMAIL]) {
        setCriteria((state) => set(state, EMAIL, null));
      } else {
        setCriteria((state) => set(state, EMAIL, debounceEmail || null));
      }
    }
  }, [debounceEmail]);

  useEffect(() => {
    if (criteria[FIRST_NAME] !== debounceFirstName) {
      if (formErrors[FIRST_NAME]) {
        setCriteria((state) => set(state, FIRST_NAME, null));
      } else {
        setCriteria((state) => set(state, FIRST_NAME, debounceFirstName));
      }
    }
  }, [debounceFirstName]);

  useEffect(() => {
    if (criteria[LAST_NAME] !== debounceLastName) {
      if (formErrors[LAST_NAME]) {
        setCriteria((state) => set(state, LAST_NAME, null));
      } else {
        setCriteria((state) => set(state, LAST_NAME, debounceLastName));
      }
    }
  }, [debounceLastName]);

  useEffect(() => {
    if (criteria[PRIMARY_PHONE] !== debouncePrimaryPhone) {
      if (formErrors[PHONE_NUMBER_PATH]) {
        setCriteria((state) => set(state, PRIMARY_PHONE, null));
      } else {
        setCriteria((state) => set(state, PRIMARY_PHONE, debouncePrimaryPhone));
      }
    }
  }, [debouncePrimaryPhone]);

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);

    if (!order) {
      setCreateActionIndex(CREATE_NEW_ORDER_INDEX);
    }
  };

  useEffect(() => {
    if (inFlightFetchingOrders) {
      return;
    }

    handleSelectOrder(null);

    if (isEmptyCriteria) {
      setOrders([]);

      return;
    }

    setInFlightFetchingOrders(true);

    orderApi
      .findAllOrdersByCriteria(criteria, { ...ordersPage, pageIndex: ordersPage.pageIndex - 1 })
      .then(({ pageElements, totalElements }) => {
        setOrdersCount(totalElements);

        return pageElements.map(OrderSummary.createOrderSummary);
      })
      .then(setOrders)
      .catch(() => {})
      .then(() => setInFlightFetchingOrders(false));
  }, [ordersPage, criteria]);

  const handleCloneOrder = () => {
    setInFlight(true);
    orderApi
      .clone(selectedOrder.orderId)
      .catch(() => {
        enqueueSnackbar('Error with creating new lead from selected one', { variant: 'error' });
      })
      .then((response) => {
        setInFlight(false);

        if (!response) {
          return;
        }

        const { orderId } = response;

        dispatch(openOrder(orderId));
        onClose();
      });
  };

  const handleCreateNewLeadFromExistingCustomerInformation = (email) => {
    setInFlight(true);
    accountAPI
      .getByEmail(email)
      .catch(() => {})
      .then((customerInformation) => {
        setInFlight(false);

        if (!customerInformation) {
          enqueueSnackbar(
            'Problem with receiving customer information. Please try again or type information manually in form above',
            { variant: 'warning' },
          );

          return;
        }

        onSave(data.set('customerInfo', new CustomerInfo(customerInformation)), values);
      });
  };

  const handleOnSave = (formData) => {
    if (createActionIndex === CREATE_NEW_ORDER_INDEX) {
      onSave(data.set('customerInfo', new CustomerInfo(formData)));
    }

    if (createActionIndex === CLONE_SELECTED_ORDER_INDEX) {
      handleCloneOrder();
    }

    const email = get(selectedOrder, 'customerEmail');

    if (createActionIndex === CREATE_NEW_LEAD_FOR_SELECTED_CUSTOMER_INDEX && email) {
      handleCreateNewLeadFromExistingCustomerInformation(email);
    }
  };

  const handleCreateIndexChange = (event) => {
    setCreateActionIndex(+event.target.value);
  };

  return (
    <FormProvider {...formMethods}>
      <Box hidden={hidden}>
        <Form>
          <Grid className={classes.formContainer} container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                name={FIRST_NAME}
                label={labels[FIRST_NAME]}
                value={values[FIRST_NAME]}
                data-testid="firstName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                name={LAST_NAME}
                label={labels[LAST_NAME]}
                value={values[LAST_NAME]}
                data-testid="lastName"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextInput
                fullWidth
                name={EMAIL}
                label={labels[EMAIL]}
                value={values[EMAIL]}
                data-testid="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid data-testid="primaryPhone" item sm={6} xs={12}>
              <PhoneFormGroup name={PRIMARY_PHONE} labels={phoneLabels} displayPhoneCarrierIcon />
            </Grid>
          </Grid>
          <CustomerOrders
            onSelectOrder={handleSelectOrder}
            isEmpty={isEmptyCriteria}
            count={ordersCount}
            inFlight={inFlightFetchingOrders}
            data={orders}
            onPageChange={setOrdersPage}
            page={ordersPage}
          />
          <Grid container>
            <Grid item xs={12}>
              <Box mt={2}>
                <RadioGroup
                  skipControl
                  aria-label="create-order-from"
                  name="createActionIndex"
                  data-testid="createActionIndex"
                  value={createActionIndex}
                  onChange={handleCreateIndexChange}
                  direction="row"
                >
                  {NEW_ORDER_OPTIONS.map((label, index) => (
                    <Radio
                      color="primary"
                      key={label}
                      value={index}
                      disabled={isDisableNewOrderOption(get(selectedOrder, 'orderId'), index)}
                      label={label}
                      data-testid={index}
                      fullWidth={false}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  type="button"
                  onClick={createActionIndex === CREATE_NEW_ORDER_INDEX ? handleSubmit(handleOnSave) : handleOnSave}
                  variant="contained"
                  color="secondary"
                  rounded
                  loading={inFlight}
                  disabled={inFlight}
                  data-testid="CustomerInfoContinue"
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </FormProvider>
  );
};

CustomerInformation.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
};

export default CustomerInformation;
