import axios from 'axios';

class LocationApi {
  lookupPostalCodes({ city, stateProvince }) {
    return axios.get(`/api/geo/postalCode/?city=${city}&stateProvince=${stateProvince}`);
  }

  findAddressByPostalCode(addressQuery) {
    return axios.get(`/api/geo/address/${addressQuery}/`);
  }
}

export default new LocationApi();
