import React, { useState } from 'react';

import { ElromcoCircularProgress, statusIds } from '@elromcoinc/react-shared';
import { Grid, Paper, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { getDashboardStats, getInFlightStatistics } from 'admin/autodux/DashboardAutodux';
import DashboardCardTitle from 'admin/components/Dashboard/DashboardCardTitle';
import { listOfStatuses } from 'admin/constants/OrderStatus';
import Circle from 'common/components/Circle';

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    boxSizing: 'border-box',
    width: '100%',
    minHeight: '320px',
    display: 'flex',
    position: 'relative',
    marginBottom: '20px',

    '&.MuiPaper-rounded': {
      borderRadius: spacing(0.75),
    },
  },
  legendItem: {
    cursor: 'pointer',
    width: '190px',
    display: 'flex',
    marginBottom: '10px',
  },
}));

const chartStatusIds = ['NEW', 'FOLLOW_UP', 'CAN_BOOK_ONLINE', 'RESERVED', 'BOOKED'];
const statuses = listOfStatuses().filter((status) => chartStatusIds.includes(status.id));
const colors = statuses.map((status) => status.color);
const options = {
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 32,
      right: 32,
      top: 32,
      bottom: 32,
    },
  },
  responsive: true,
};

const SELECTED_BOOKED_OPTION = 'SELECTED_BOOKED_OPTION';
const BOOKED_THIS_MONTH = 'BOOKED_THIS_MONTH';

const DashboardStatusesChart = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const isFetching = useSelector(getInFlightStatistics);
  const dashboard = useSelector(getDashboardStats);
  const {
    current: { totalNew = 0, totalFollowUp = 0, totalCanBookOnline = 0, totalReserved = 0, bookedThisMonth = 0 },
  } = dashboard;
  const dashboardStatuses = [totalNew, totalFollowUp, totalCanBookOnline, totalReserved, bookedThisMonth];
  const [redirect, setRedirect] = useState(null);

  const data = {
    datasets: [
      {
        borderWidth: 0,
        data: dashboardStatuses,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
      },
    ],
    labels: statuses.map(({ label }) => label),
  };

  const onClickLegendItem = (id) => () => {
    if (id === statusIds.BOOKED) {
      localStorage.setItem(SELECTED_BOOKED_OPTION, BOOKED_THIS_MONTH);
    }
    setRedirect(id);
  };

  return (
    <Box height="320px" display="flex" flexDirection="column" marginBottom="25px">
      {redirect && <Redirect to={`/orders?status=${redirect}`} />}
      <Box display="flex">
        <DashboardCardTitle title="Current Statuses" />
      </Box>
      {isFetching ? (
        <Box position="relative" minHeight="320px" width="100%">
          <ElromcoCircularProgress />
        </Box>
      ) : (
        <Paper elevation={1} classes={{ root: classes.paper }}>
          <Grid container justifyContent="center">
            <Grid item md={7} sm={7} xs={12}>
              <Doughnut data={data} options={options} redraw={isMobile} />
            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              <Box display="flex" sx={{ pt: { xs: 0, sm: 12 } }}>
                <Grid container direction="column" alignItems="center">
                  {statuses.map((status, index) => (
                    <Box key={status.id} className={classes.legendItem} onClick={onClickLegendItem(status.id)}>
                      <Circle color={status.color} />
                      <Box ml="3px" color="#363747" fontSize="1em">
                        {`${status.label} (${dashboardStatuses[index] || 0})`}
                        {status.label !== 'Booked' ? null : (
                          <Box component="span" fontSize="0.75rem" fontWeight="300" ml="5px">
                            (this month)
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Box>
  );
};

export default DashboardStatusesChart;
