import React, { useState } from 'react';

import { DataTable, DataTableColumnDef, RadioGroup } from '@elromcoinc/react-shared';

import { DataOfCreditCards } from 'admin/components/PlanSettings/utils/DataOfCreditCards';

interface CreditCardsDataTableProps {
  columns: DataTableColumnDef[];
  data: DataOfCreditCards[];
  options: {
    selectableRowsHideCheckboxesCustom: boolean;
    smallPadding: boolean;
  };
}

export const CreditCardsDataTable = ({ data, columns, options }: CreditCardsDataTableProps) => {
  const [value, setValue] = useState<string>(data[0]?.id || '');

  const handleChange = ({ target: { value } }: { target: { value: string } }) => setValue(value);

  return (
    <RadioGroup value={value} onChange={handleChange}>
      <DataTable data={data} columns={columns} options={options} />
    </RadioGroup>
  );
};
