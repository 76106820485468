import { useEffect } from 'react';

import { storageUtil } from '@elromcoinc/react-shared';

export const useReloadPageIfLoginUnderDifferentCompanyId = (companyId: number) => {
  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('storage', onStorage);
    }

    function onStorage() {
      if (storageUtil.getTenantId() !== companyId) {
        window.location.reload();
      }
    }

    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [companyId]);
};
