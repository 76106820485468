import axios from 'axios';

class EventsApi {
  getLeadScoringSettings<T = any>() {
    return axios.get<T, T>('/api/events/leadscoring/settings');
  }

  toggleScoringEnabledStatus() {
    return axios.put('/api/events/leadscoring/settings/enabledStatus', {});
  }

  updateWarmThreshold(warmThreshold: string) {
    return axios.put(`/api/events/leadscoring/settings/warm/${warmThreshold}`, {});
  }

  updateHotThreshold(hotThreshold: string) {
    return axios.put(`/api/events/leadscoring/settings/hot/${hotThreshold}`, {});
  }

  updateEventTypeScore(eventTypeId: string, newScore: string) {
    return axios.put(`/api/events/leadscoring/settings/eventType/${eventTypeId}/score/${newScore}`, {});
  }
}
export default new EventsApi();
