import styled from 'styled-components';
import Box from '@material-ui/core/Box';

const StyledAddressBlock = styled(Box)`
  z-index: 2000;
  min-height: 140px;
  margin: 10px 0;
  background-color: white;
  .draggerBox {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #eaeaeb;
    width: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 5px 0px 0px 5px;
    justify-content: flex-start;
    align-items: center;
    svg {
      :last-child {
        position: absolute;
        top: 50%;
        margin-top: -12px;
      }
    }
  }
  .detailsBox {
    padding: 4px;
    .addressSection {
      p {
        padding-top: 0;
        padding-bottom: 0px;
      }
    }
    .actionSection {
      padding: 0 4px;
    }
    .detailsSection {
      p {
        padding: 1px 0;
      }
    }
  }
`;

export default StyledAddressBlock;
