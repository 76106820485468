import {
  convertLocalTimeToSeconds,
  convertSecondsToLocalTime,
} from 'admin/components/OrderWindow/SurveySchedulerBox/config';

export const getEstimatorWorks = (estimators, currentSurveyValue, surveyDate, order) => {
  const serviceTypeValue = order.toJS().services[0];
  const { serviceTypeName, quote } = serviceTypeValue;

  const estimatorWorks =
    estimators
      .filter((estimator) => estimator.works && estimator.works[surveyDate])
      .reduce((accumulator, item) => [...accumulator, ...item.works[surveyDate]], []) || [];

  if (
    !estimatorWorks.find(
      (it) => it.orderId === order.orderId && it.orderServiceType === currentSurveyValue.orderServiceType,
    ) &&
    currentSurveyValue.estimatorId
  ) {
    const seconds =
      convertLocalTimeToSeconds(currentSurveyValue.startTimeTo) -
      convertLocalTimeToSeconds(currentSurveyValue.startTimeFrom) +
      currentSurveyValue.duration;

    estimatorWorks.push({
      ...currentSurveyValue,
      ...currentSurveyValue.estimatorWork,
      startTime: currentSurveyValue.startTimeFrom,
      totalDuration: convertSecondsToLocalTime(seconds),
      status: currentSurveyValue.status,
      orderNumber: order.orderNumber,
      orderFirstName: order.contactInfo.firstName,
      orderLastName: order.contactInfo.lastName,
      orderAddress: order.fromAddress.asSingleString,
      orderId: order.orderId,
      serviceType: { rgbColor: quote.generalService.rgbColor, name: serviceTypeName },
    });
  }

  return estimatorWorks;
};
