import React from 'react';

import { BodySmallText, Order, Waypoint, isOvernightService, roundNumberToFixedDigits } from '@elromcoinc/react-shared';
import IconButton from '@material-ui/core/IconButton';
import { List, Set } from 'immutable';

import DistanceBox from 'admin/components/OrderWindow/blocks/Addresses/DistanceBox';
import { useOrderServiceIndex, useOrderSettingUnits, useOrderState } from 'admin/components/OrderWindow/context';
import { GOOGLE_DIRECTION } from 'admin/constants';
import { durationToHoursMinutes } from 'admin/utils';
import { mergeStops } from 'admin/utils/mergeStop';
import { BlueMapIcon } from 'common/components/Widgets';

export const SelectedServiceAddressDistance = () => {
  const { order } = useOrderState() as { order: Order };
  const { currentDistanceUnitLabel, isInMiles } = useOrderSettingUnits();
  const { serviceIndex, isSelectedAllServices } = useOrderServiceIndex();

  const handleOpenRoute = () => {
    const firstDayCompanyStops = order
      .getServiceWaypoints()
      .filter(Waypoint.isCompanyStop)
      .map((w) => w.setIn(['address', 'street2'], null));
    const waypoints = (
      isSelectedAllServices
        ? order.services.reduce((accumulator, service, index) => {
            const waypoints = order.getAddressWaypointsBasedOnService(index);

            const lastWaypoint = waypoints.last();
            const serviceType = order.getServiceType(index);
            const isLastOvernightWaypoint = isOvernightService(serviceType) && lastWaypoint?.serviceIndex === index;

            if (isLastOvernightWaypoint) {
              return accumulator;
            }

            return accumulator.concat(mergeStops(firstDayCompanyStops, waypoints));
          }, List())
        : mergeStops(firstDayCompanyStops, order.getAddressWaypointsBasedOnService(serviceIndex))
    ) as List<Waypoint>;

    const waypointsWithCompanyAddress = waypoints
      .map((it) => encodeURIComponent(it.address.fullAddressLine()))
      .join('/');

    window.open(`${GOOGLE_DIRECTION}${waypointsWithCompanyAddress}`, '_blank');
  };

  const serviceWaypoints = order.getAddressWaypointsBasedOnService(serviceIndex);
  const firstWaypoint = serviceWaypoints.first();
  const lastWaypoint = serviceWaypoints.last();
  const uniqueServiceIndexes = Set([firstWaypoint?.serviceIndex, lastWaypoint?.serviceIndex]);

  const totalInMeters = order.services
    .map((s, index) => {
      if (!uniqueServiceIndexes.includes(index) && !isSelectedAllServices) {
        return 0;
      }

      return isInMiles ? s.quote.totalCrewTravelDistance.miles : s.quote.totalCrewTravelDistance.kilometers;
    })
    .reduce((accumulator, it) => accumulator + it, 0);

  const totalDistance =
    totalInMeters >= 0 ? `${roundNumberToFixedDigits(totalInMeters)} ${currentDistanceUnitLabel.toLowerCase()}` : '';

  const totalTravelDurationHours = order
    .getAllServicesWaypoints()
    .filter((w) => isSelectedAllServices || uniqueServiceIndexes.includes(w.serviceIndex))
    .reduce((acc, w) => acc + w.travelDurationFromPrevious.getRoundedSeconds(), 0);

  const totalTravelDurationTime = durationToHoursMinutes(totalTravelDurationHours);

  return (
    <DistanceBox
      display="flex"
      flexDirection="row-reverse"
      alignItems="center"
      alignSelf="center"
      maxWidth={270}
      mx="auto"
      className="applyRadius"
    >
      <IconButton size="small" onClick={handleOpenRoute}>
        <BlueMapIcon />
      </IconButton>
      {totalDistance && (
        <>
          <BodySmallText>{totalDistance}</BodySmallText>
          <BodySmallText>{totalTravelDurationTime}</BodySmallText>
        </>
      )}
    </DistanceBox>
  );
};
