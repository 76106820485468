import React from 'react';

import { BodyText, LongDistanceTariffDetails, Modal } from '@elromcoinc/react-shared';
import { Box, Table, TableBody, TableCell, TableRow, makeStyles } from '@material-ui/core';

import { useOrderClosingContext, useOrderSettingUnits } from 'admin/components/OrderWindow/context';
import { ClosingLinehaulCharge } from 'admin/components/OrderWindow/modals/LinehaulCharge/ClosingLinehaulCharge';
import { normalizeCurrency } from 'common/utils/String';

const useStyles = makeStyles(() => ({
  withoutBorder: {
    borderBottom: 'none',
  },
}));

interface LinehaulChargeProps {
  onClose: () => void;
  moveSize: number;
  longDistanceDetails: LongDistanceTariffDetails;
  linehaulCharge: string;
}

const LinehaulCharge = ({ onClose, moveSize, longDistanceDetails, linehaulCharge }: LinehaulChargeProps) => {
  const { isClosing } = useOrderClosingContext();
  const classes = useStyles();
  const minSize = longDistanceDetails.minSize || 0;
  const basePrice = longDistanceDetails.basePrice || minSize * longDistanceDetails.getEffectiveRate();
  const oversize = moveSize > minSize ? moveSize - minSize : 0;
  const oversizePrice = oversize * longDistanceDetails.getEffectiveRate();
  const { moveUnitLabelsShort } = useOrderSettingUnits();

  return (
    <Modal
      open
      title="Linehaul Charges"
      onClose={onClose}
      actions={[{ label: 'done', onClick: onClose }]}
      maxWidth="xs"
    >
      <Box mb={1}>
        <BodyText paragraph>
          <b>Sales</b>
        </BodyText>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell size="small" className={classes.withoutBorder}>
                <BodyText>{`${minSize} ${moveUnitLabelsShort}`}</BodyText>
              </TableCell>
              <TableCell size="small" className={classes.withoutBorder}>
                <BodyText>{`$${normalizeCurrency(minSize > 0 ? basePrice : 0)}`}</BodyText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small">
                <BodyText>
                  {`${oversize} ${moveUnitLabelsShort} more inventory x $${longDistanceDetails.getEffectiveRate()}/${moveUnitLabelsShort}`}
                </BodyText>
              </TableCell>
              <TableCell size="small">
                <BodyText>{`$${normalizeCurrency(oversizePrice)}`}</BodyText>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small" className={classes.withoutBorder}>
                <BodyText>{`Origin ${moveSize} ${moveUnitLabelsShort}`}</BodyText>
              </TableCell>
              <TableCell size="small" className={classes.withoutBorder}>
                <BodyText>{linehaulCharge}</BodyText>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      {isClosing && <ClosingLinehaulCharge />}
    </Modal>
  );
};

export default LinehaulCharge;
