import React, { useEffect, useState } from 'react';

import { BodyText, CircularProgress, HeaderText, formatCurrency, statusIds } from '@elromcoinc/react-shared';
import { Box, Paper, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import pt from 'prop-types';
import { useSelector } from 'react-redux';

import AccountAPI from 'admin/api/AccountAPI';
import { getIsSessionExpired } from 'admin/autodux/AuthAutodux';
import { TotalOrdersView } from 'admin/components/AccountWindow/components';

const useStyles = makeStyles((theme) => ({
  total: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '11px 4px',
    },
  },
  totalInformation: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flexBasis: '85%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  infoPanel: {
    position: 'relative',
    flexBasis: '100%',
    minWidth: 'calc(100% - 8px)',
    padding: '4px',
    [theme.breakpoints.between('sm', 'md')]: {
      flexBasis: '39%',
      minWidth: '39%',
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: '400px',
      minWidth: '400px',
    },
  },
}));

export const TotalOrders = ({ summaryAccount }) => {
  const classes = useStyles();
  const { id } = summaryAccount;
  const [accountStatistics, setAccountStatics] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [isFlight, setIsFlight] = useState(false);
  const isSessionExpired = useSelector(getIsSessionExpired);

  const getStatistics = () => {
    setIsFlight(true);
    AccountAPI.getStatisticsByAccount(id)
      .catch(() => {
        enqueueSnackbar(`Can't get account statistics`, { variant: 'error' });
      })
      .then((res) => {
        if (res) {
          setAccountStatics(res);
        }
      })
      .then(() => {
        setIsFlight(false);
      });
  };

  useEffect(() => {
    if (!isSessionExpired) {
      getStatistics();
      const interval = setInterval(getStatistics, 3e4);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isSessionExpired]);

  const { statusCounts = [], totalPayments } = accountStatistics;

  const bookedJobs = (statusCounts.find(({ status }) => status === statusIds.BOOKED) || { count: 0 }).count;

  return (
    <Box className={classes.infoPanel}>
      <Paper classes={{ root: classes.total }} elevation={0} square>
        <Box>
          <HeaderText>
            <b>Totals</b>
          </HeaderText>
        </Box>
        <Box className={classes.totalInformation}>
          <TotalOrdersView key={id} id={id} accountStatistics={accountStatistics} isFlight={isFlight} />
          <Box display="flex" alignItems="center">
            <BodyText>Booked Jobs:&nbsp;</BodyText>
            {isFlight ? (
              <Box position="relative">
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box>
                <BodyText>
                  <b>{bookedJobs}</b>
                </BodyText>
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <BodyText>Total Paid:&nbsp;</BodyText>
            {isFlight ? (
              <CircularProgress size={20} />
            ) : (
              <BodyText>
                <b>{`$ ${formatCurrency(totalPayments, 2)}`}</b>
              </BodyText>
            )}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

TotalOrders.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  summaryAccount: pt.object.isRequired,
};
