import React, { useState } from 'react';
import { BodyText, Button, DatePicker, IconButton } from '@elromcoinc/react-shared';
import { Box, makeStyles } from '@material-ui/core';
import { addDays, addWeeks, endOfWeek, subDays, subWeeks } from 'date-fns';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classNames from 'classnames';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import pt from 'prop-types';
import { formatWeekRange } from 'admin/components/OrderWindow/SchedulerBox/Header';

const useStyles = makeStyles((theme) => ({
  datePickerInput: {
    cursor: 'pointer',
    minWidth: '11rem',
  },
  weekRange: {
    minWidth: '14rem',
    padding: '0 7px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '54px',
    padding: '0 1rem',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: '0.5rem 1rem',
    },
  },
  headerPicker: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export const HeaderSurveyScheduler = ({ onDateChange, toggleViewMode, date }) => {
  const classes = useStyles();
  const [currentOption, setCurrentOption] = useState('Day');
  const buttonData = ['Day', 'Week'];
  const isDayMode = currentOption === buttonData[0];
  const currentDate = isDayMode ? date : endOfWeek(date);

  const clickHandler = (option) => () => {
    if (currentOption === option) return;
    setCurrentOption(option);
    toggleViewMode();
  };

  return (
    <Box className={classes.header}>
      <Box className={classes.headerPicker}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="54px">
          <IconButton
            color="primary"
            size="small"
            variant="default"
            onClick={() => onDateChange((isDayMode ? subDays : subWeeks)(currentDate, 1))}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <Box minWidth={290}>
            {isDayMode && (
              <Box display="flex" justifyContent="center">
                <DatePicker
                  fullWidth={false}
                  value={currentDate || new Date()}
                  format="EEEE, MMM do, yyyy"
                  onChange={onDateChange}
                  hiddenLabel
                  InputProps={{
                    classes: { input: classNames(classes.datePickerInput) },
                    disableUnderline: true,
                  }}
                />
              </Box>
            )}
            {!isDayMode && (
              <Box className={classes.weekRange}>
                <BodyText align="center">{formatWeekRange(currentDate)}</BodyText>
              </Box>
            )}
          </Box>
          <IconButton
            size="small"
            color="primary"
            variant="default"
            onClick={() => onDateChange((isDayMode ? addDays : addWeeks)(currentDate, 1))}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" width={150}>
        {buttonData.map((item) => (
          <Box mr={1} key={item}>
            <Button
              rounded
              disableElevation
              color="primary"
              variant={currentOption === item ? 'contained' : 'text'}
              onClick={clickHandler(item)}
            >
              {item}
            </Button>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

HeaderSurveyScheduler.propTypes = {
  onDateChange: pt.func,
  toggleViewMode: pt.func.isRequired,
  date: pt.instanceOf(Date),
};

HeaderSurveyScheduler.defaultProps = {
  onDateChange: null,
  date: null,
};
