import createUpdatedValueDto from 'admin/components/OrderWindow/audit/UpdatedValueDto';
import { EventProcessor } from './EventProcessor';

const updateSecondaryId: EventProcessor = (fieldPath, oldOrder, newOrder) => {
  if (
    fieldPath.indexOf('secondaryId') === -1 ||
    Boolean(oldOrder.secondaryId) ||
    oldOrder.secondaryId === newOrder.secondaryId
  ) {
    return null;
  }
  return [createUpdatedValueDto('', newOrder.secondaryId || '', '')];
};

export default updateSecondaryId;
