import { FC, ReactElement } from 'react';

import { BodyText } from '@elromcoinc/react-shared';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    color: (props: { color?: string }) => props.color ?? theme.palette.text.primary,
    '& button': {
      marginLeft: theme.spacing(0.5),
    },
    '& button svg': {
      fontSize: theme.spacing(2.25),
      color: theme.palette.primary.main,
    },
  },
}));

interface TextProps {
  color?: string;
  value: ReactElement | string | number;
  'data-testId'?: string;
}

export const Text: FC<TextProps> = ({ color, value, 'data-testId': testId }) => {
  const classes = useStyles({ color });

  return (
    <BodyText className={classes.root} data-testId={testId}>
      {value}
    </BodyText>
  );
};
